import React, { Component } from 'react'
import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import ProcedureListFilter from './ProcedureListFilter'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage } from '../../../actions'
import { connect } from 'react-redux'

const ADD_PATH = '/he-thong/quy-trinh/them-moi'
const PAGE_NAME = 'Quản lý quy trình'
const PAGE_ICON = 'icon-quan-ly-quy-trinh'
const ACTIONS_BUTTON = [
  {
    link: ADD_PATH,
    name: 'Thêm Mới',
    icon: 'pt-icon pt-icon-small-plus',
  },
]

class ProcedurePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isError: false,
      listData: [],
    }
  }

  setFetchStatus = (type, res = null) => {
    let status
    if (type === 'success') {
      status = _.get(res, 'payload.status') === 200
    } else if (type === 'error') {
      status = false
    }
    this.setState({ isError: !status, isLoading: false })
  }

  fetchData = async (filter = null) => {
    await this.setState({
      isLoading: true,
    })
  }

  onSearch = async (filter, reset = false) => {
    let { tmpFilter } = this.state
    tmpFilter = reset ? {} : { ...tmpFilter, ...filter }
    this.setState({ tmpFilter })
    this.fetchData(tmpFilter)
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillReceiveProps(nextProps) {
    if (_.difference(this.props.procedure.list, nextProps.procedure.list)) {
      this.setState({
        listData: nextProps.procedure.list,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={PAGE_NAME}
          actionsButton={ACTIONS_BUTTON}
        />
        <ProcedureListFilter onSearch={this.onSearch} />
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  procedure: state.procedure,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
})

export default connect(mapStateToProps, mapDispathToProps)(ProcedurePage)
