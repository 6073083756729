import React, { Component } from 'react'

import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'

const PAGE_NAME = 'Thêm mới quy trình'
class AddProcedurePage extends Component {
  constructor() {
    super()
    this.state = {
      isWaitingSubmit: false,
    }
  }

  toggleAllowGoback = () => {
    const { isWaitingSubmit } = this.state
    this.setState({ isWaitingSubmit: !isWaitingSubmit })
  }

  render() {
    const { isWaitingSubmit } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <ContentHeader
              pageIcon="icon-quan-ly-quy-trinh"
              pageName={PAGE_NAME}
              showGoBackButton
              disabledGoBack={isWaitingSubmit}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

export default AddProcedurePage
