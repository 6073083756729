import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/SendDocument'
import { GridViewFilter } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../../components/auth'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false
class ListSendDocumentPage extends React.Component {
  constructor(props) {
    super(props)
    // Kiểm tra quyền đối với lãnh đạo và văn thư
    const isCheckLanhDaoVaVanThu =
      props.auth &&
      !Authorization.vaild(
        props.auth.permission,
        [Permission.CONGVIEC_PHONGBAN, Permission.CONGVANDEN_QUANLY],
        true
      )
        ? true
        : false
    this.state = {
      filter: {},
      filterColumn: {
        phongBanLapId: isCheckLanhDaoVaVanThu
          ? props.auth.mainDepartmentId
          : '',
      },
      sort: undefined,
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
      isShow: false,
      isLoadingThuHoi: false,
      isDisablePBLap: isCheckLanhDaoVaVanThu,
    }
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  static propTypes = {
    sendDocuments: PropTypes.object.isRequired,
    departments: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.props.actions.commonFetchDepartments()
    this.props.actions.commonFetchSignUserList()
    let state = this.state
    let preData = Tool.Utils.preData('ListSendDocumentPage')
    if (preData) {
      state.filter = preData.filter
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : null,
        soCongVan:
          preData.filterColumn && preData.filterColumn.soCongVan
            ? preData.filterColumn.soCongVan
            : '',
        trichYeu:
          preData.filterColumn && preData.filterColumn.trichYeu
            ? preData.filterColumn.trichYeu
            : '',
        phongBanLapId:
          preData.filterColumn && preData.filterColumn.phongBanLapId
            ? preData.filterColumn.phongBanLapId
            : '',
        nguoiKyId:
          preData.filterColumn && preData.filterColumn.nguoiKyId
            ? preData.filterColumn.nguoiKyId
            : '',
      }
    } else {
      state.filter.ngayCongVan = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
    this.props.actions.getFlowDocumentaryReceiveDocumentReset()
    this.props.actions.getMoreFlowDocumentaryReceiveDocumentReset()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.sendDocuments &&
      this.props.sendDocuments.list &&
      this.props.sendDocuments.list.total &&
      this.state.page >
        Math.ceil(this.props.sendDocuments.list.total / Types.PAGINATION_LIMIT)
    ) {
      this.setState(
        {
          page: Math.ceil(
            this.props.sendDocuments.list.total / Types.PAGINATION_LIMIT
          ),
        },
        () => {
          this.clickPagination(this.state.page)
        }
      )
    }
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      phongBanLapId:
        this.state.filterColumn.phongBanLapId &&
        this.state.filterColumn.phongBanLapId !== '#'
          ? this.state.filterColumn.phongBanLapId
          : null,
      nguoiKyId:
        this.state.filterColumn.nguoiKyId &&
        this.state.filterColumn.nguoiKyId !== '#'
          ? this.state.filterColumn.nguoiKyId
          : null,
    }

    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ListSendDocumentPage', {
        filter,
        page,
        sort,
        filterColumn,
      })
      // tạm thời để refresh = false
      this.props.actions
        .sendDocumentGet({ filter }, page, sort, REFRESH_FALSE, filterColumn)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          let object = false
          if (
            Object.values(filterColumn).find(item => item !== null) !==
            undefined
          ) {
            object = true
          }
          this.setState({ isLoading: false, isShow: object })
          this.props.actions.refreshSendDocument(false)
        })
    })
  }

  async handleSubmitRemoveItem(lyDo, id) {
    let total = 0
    let page = this.state.page
    if (
      this.props.sendDocuments &&
      this.props.sendDocuments.list &&
      this.props.sendDocuments.list.total
    ) {
      total = this.props.sendDocuments.list.total
    }

    await this.setState({ isLoadingThuHoi: true })
    await this.props.actions
      .sendDocumentDelete(id, lyDo)
      .then(res => {
        if (res.error) {
          this.setState({ isLoadingThuHoi: false })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_THUHOICONGVAN_KHONGTHANHCONG,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        // Refresh danh sach ky so di
        this.props.actions.refreshSendSignNumber(true)
        // Refresh danh sach cong van di
        this.props.actions.refreshSendDocument(true)
        this.submitFilter(this.state.filter, page, this.state.sort)
        this.setState({ isLoadingThuHoi: false })
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_THUHOICONGVAN_THANHCONG,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.setState({ isLoadingThuHoi: false })
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_THUHOICONGVAN_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    if (name === 'soCongVan' || name === 'trichYeu') {
      return this.setState({
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    }

    if (name !== 'soCongVan' || name !== 'trichYeu') {
      this.setState(
        {
          isShow: true,
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (name === 'phongBanLapId' || name === 'nguoiKyId') {
            this.submitFilter()
          }
        }
      )
    }
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
      })
    }
    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  handleReloadData = () => {
    this.submitFilter()
  }

  render() {
    const AuthElement = Authorization.Element
    const isDisablePBLap = this.state.isDisablePBLap

    return (
      <MasterLayout typeSidebar="documentary">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-Cong_van_di icon-heading"></span>
                  <span className="text-heading"> Công văn đi </span>
                </h3>
                <AuthElement permission={[Permission.CONGVANDI_QUANLY]}>
                  <Link
                    to="/cong-van/cong-van-di/them-moi"
                    className="btn-action pull-right hidden-btn"
                  >
                    <span className="name-btn">NHẬP CÔNG VĂN</span>
                    <span className="pt-icon pt-icon-small-plus"></span>
                  </Link>
                </AuthElement>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              signNumberDepartmentLeaders={this.props.signUserList}
              departments={this.props.departments}
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.sendDocuments.list.total / Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                actions={this.props.actions}
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                page={this.state.page}
                headers={headers.listSendDocumentPage}
                itemShape={ListItem}
                data={this.props.sendDocuments.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isError={this.state.isError}
                readOnly={this.props.readOnly}
                onChange={this.onChangeGridViewFilter}
                onChangeSelect={this.onChangeSelectGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                filterColumn={this.state.filterColumn}
                onRemoveText={this.onRemoveText}
                phongBanLapId={this.props.departments}
                nguoiKyId={this.props.signUserList}
                auth={this.props.auth}
                onSubmit={this.submitFilter}
                isType={false}
                isShow={this.state.isShow}
                isLoadingThuHoi={this.state.isLoadingThuHoi}
                reloadData={this.handleReloadData}
                isDisablePBLap={isDisablePBLap}
                quyensMenu={this.props.quyensMenu}
              />

              <Pagination
                current={this.state.page}
                total={Math.ceil(
                  this.props.sendDocuments.list.total / Types.PAGINATION_LIMIT
                )}
                onChange={this.clickPagination}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,

  sendDocuments: {
    ...state.sendDocuments,
    list: {
      ...state.sendDocuments.list,
      items: state.sendDocuments.list.items.map(
        id => state.entities.sendDocuments[id]
      ),
    },
  },

  departments: state.common.departments,

  signUserList: state.common.signUserList,

  readOnly: state.common.enableReadOnly,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListSendDocumentPage)
