import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter } from '../../components/System/service'
import { connect } from 'react-redux'
import { Button, Intent } from '@blueprintjs/core'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import moment from 'moment'
import FileDownload from 'file-saver'
import { StatisticalCollapseList } from './ThongKeKSTT'
import _ from 'lodash'
const REFRESH_FALSE = false
class ThongKeService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
      exporting: false,
      list: [],
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    list: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData('ThongKeService')
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    } else {
      state.filter.ngayTao = {
        from: undefined,
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination({ selected: this.state.page - 1 })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (_.difference(this.props.list, nextProps.list)) {
      this.setState({
        list: nextProps.list.items,
      })
    }
  }

  handleExport() {
    const { filter, page } = this.state
    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportThongKeService(filter, page)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          FileDownload.saveAs(res.payload.data, `thong-ke-service.xls`)
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    })
  }

  clickPagination(e) {
    let state = {
      page: e.selected + 1,
    }
    this.setState(state, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)

    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ThongKeService', { filter, page, sort })
      // tạm thời để refresh = false
      this.props.actions
        .thongKeServiceAPI({ filter }, page, sort, REFRESH_FALSE)
        .then(res => {
          let isEmpty = false
          let isError = false

          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length === 0
          ) {
            isEmpty = true
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshServiceDocument(false)
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
      })
    }
    await this.submitFilter(filter)
  }

  removeTextSearch(name) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: '',
        },
      },
      () => {
        this.submitFilter(this.state.filter)
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="page-utilities-container">
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-6">
                <h3 className="text-heading-page">
                  <span className="icon-Tong_Hop icon-heading"></span>
                  <span className="text-heading">Thống kê Service</span>
                </h3>
              </div>
              <div className="col-xs-6">
                <div className="button-heading-right float-right">
                  <Button
                    rightIconName="pt-icon icon-In_So_Cong_Van"
                    className="btn-action btn-top-heading btn-excel-export"
                    text="Xuất excel"
                    onClick={this.handleExport}
                    loading={this.state.exporting}
                    disabled={this.state.isEmpty || this.state.error}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />
            <div className="custom-table-container">
              <StatisticalCollapseList
                data={this.state.list}
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                onSearch={this.submitFilter}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
  list: state.serviceDocuments.list,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThongKeService)
