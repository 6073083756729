import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio, RadioGroup, Intent } from '@blueprintjs/core'
import { Permission } from 'components/auth'
import moment from 'moment'
import * as Tool from 'helpers'
import { intersection, size } from 'lodash'

import {
  LoadingTable,
  HeadingTableDragAndDrop,
  FilterTable,
  PopupConfirm,
} from 'components/common'
import { headers } from 'constants/Table'
import {
  trangThaiHoSoCongViec,
  trangThaiHoSoCongViecDonViVanThu,
  trangThaiHoSoCongViecDonViLĐVP,
} from 'constants/Signature'
import {
  MESSAGE_NOT_FOUND,
  MESSAGE_ERROR,
  DA_XAY_RA_LOI,
  TOATS_MESSAGE_DELETE_SUCCESS,
  TOATS_MESSAGE_DELETE_FAIL,
} from 'constants/MessageForm'
import {
  XOA_THUMUC_OR_HOSOCONGVIEC,
  TEXT_BUTTON_SUBMIT,
  TEXT_SUBMIT_BODY,
} from 'constants/Popup'
import { CapNhatHoSoCongViec, HoSoCongViecGroup } from 'components/tutailieu'
import { CABINET_UNIT, CABINET_DEPARTMENT } from 'constants/Api'

const isAll = false
const donVi = 2
const tatCa = 0
const checkBoxArray = [
  {
    id: 'TTL_DV',
    name: 'Đơn vị',
    value: CABINET_UNIT,
    disabled: false,
  },
  {
    id: 'TTL_PB',
    name: 'Phòng ban',
    value: CABINET_DEPARTMENT,
    disabled: false,
  },
  // {
  //   id: null,
  //   name: 'Hồ sơ công việc',
  //   value: HOSO_CONGVIEC,
  //   disabled: false,
  // },
]

class DanhSachTuTaiLieu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phongBanLapDS: [],
      radioData:
        this.props.keyTab !== 'TTL_DV' ? CABINET_DEPARTMENT : CABINET_UNIT,
      isLoading: false,
      isEmpty: false,
      isError: false,
      isLoadingButton: false,
      filterColumn: {
        nam: moment().format('YYYY'),
      },
      filter: {
        isDonVi: this.props.keyTab === 'TTL_DV' ? true : false,
        isChoXuLy: true,
        isTatCa: false,
        isDaLuuTru: false,
      },
      items: [],
      dataPopup: {
        textClose: 'Đóng',
        textSubmit: 'Thêm',
        isOpenPopup: false,
        idRow: -1,
        titlePopup: 'Thêm mới',
        typePopup: '',
      },
      dataPopupConfirm: {
        isLoadingButton: false,
        isOpenPopup: false,
        idRow: -1,
      },
      itemsActive: [],
      trangThaiXuLy:
        this.props.keyTab === 'TTL_DV' &&
        intersection(this.props.auth.permission, [
          Permission.HSCV_VANTHU,
          Permission.HSCV_PHONGBAN_DUYET,
          Permission.HSCV_VANPHONG_DUYET,
        ]).length !== 0
          ? '1'
          : this.props.keyTab === 'TTL_PB' &&
            intersection(this.props.auth.permission, [
              Permission.HSCV_PHONGBAN_DUYET,
            ]).length !== 0
          ? '1'
          : '0',
    }
  }

  async componentDidMount() {
    // this.getDSPhongBanLap();
    let state = this.state
    let { trangThaiXuLy } = this.state
    let preDataDanhDachTaiLieu = Tool.Utils.preData(
      `DS-HoSoCongViec-${this.props.keyTab}`
    )
    if (preDataDanhDachTaiLieu) {
      state.filter = preDataDanhDachTaiLieu.filter
      state.filter.isDonVi = this.props.keyTab === 'TTL_DV' ? true : false
      state.page = preDataDanhDachTaiLieu.page
      state.sorter = preDataDanhDachTaiLieu.sorter
      state.filterColumn = preDataDanhDachTaiLieu.filterColumn
      trangThaiXuLy = preDataDanhDachTaiLieu.trangThaiXuLy
      state.itemsActive = preDataDanhDachTaiLieu.itemsActive
    }

    if (
      this.props.typeApp &&
      this.props.typeApp !== '' &&
      this.props.typeApp === 'cho-xu-ly'
    ) {
      trangThaiXuLy = '1'
    }

    this.setState(state, () => {
      if (
        trangThaiXuLy === '1' &&
        this.props.keyTab === 'TTL_PB' &&
        intersection(this.props.auth.permission, [
          Permission.HSCV_PHONGBAN_DUYET,
        ]).length !== 0
      ) {
        // Tủ phòng ban và HSCV_PHONGBAN_DUYET set trạng thái lđpb chờ duyệt
        this.setState(
          {
            filterColumn: {
              ...this.state.filterColumn,
              trangThaiId: '1',
            },
            trangThaiXuLy,
          },
          () => {
            this.setFilterSelectBox(trangThaiXuLy)
            return this.submitFilter()
          }
        )
      } else if (
        trangThaiXuLy === '1' &&
        this.props.keyTab === 'TTL_PB' &&
        intersection(this.props.auth.permission, [Permission.HSCV_VANTHU])
          .length !== 0
      ) {
        // Tủ phòng ban và HSCV_PHONGBAN_DUYET set trạng thái lđpb chờ duyệt
        this.setState(
          {
            filterColumn: {
              ...this.state.filterColumn,
              trangThaiId: null,
            },
            trangThaiXuLy: '0',
          },
          () => {
            this.setFilterSelectBox(0)
            return this.submitFilter()
          }
        )
      } else if (
        trangThaiXuLy === '1' &&
        this.props.keyTab === 'TTL_DV' &&
        intersection(this.props.auth.permission, [Permission.HSCV_VANTHU])
          .length !== 0
      ) {
        // Tủ đơn vị và HSCV_VANTHU set trạng thái chờ tiếp nhận
        this.setState(
          {
            filterColumn: {
              ...this.state.filterColumn,
              trangThaiId: '2',
            },
            trangThaiXuLy,
          },
          () => {
            this.setFilterSelectBox(trangThaiXuLy)
            return this.submitFilter()
          }
        )
      } else if (
        trangThaiXuLy === '1' &&
        this.props.keyTab === 'TTL_DV' &&
        intersection(this.props.auth.permission, [
          Permission.HSCV_VANPHONG_DUYET,
        ]).length !== 0
      ) {
        // Tủ đơn vị và HSCV_VANPHONG_DUYET set trạng thái LĐVP chờ duyệt
        this.setState(
          {
            filterColumn: {
              ...this.state.filterColumn,
              trangThaiId: '3',
            },
            trangThaiXuLy,
          },
          () => {
            this.setFilterSelectBox(trangThaiXuLy)
            return this.submitFilter()
          }
        )
      } else {
        this.setFilterSelectBox(trangThaiXuLy)
        this.setState(
          {
            trangThaiXuLy,
          },
          () => {
            return this.submitFilter()
          }
        )
      }
    })
  }

  /**
   * Gọi api GET danh sách phòng ban lập cha con
   * @memberof DanhSachTuTaiLieu
   */
  getDSPhongBanLap = async () => {
    await this.props.actions
      .getDepartmentUnit(this.props.auth.mainUnitId, !isAll)
      .then(res => {
        if (res.payload && res.payload.data && res.payload.data.result) {
          let data = [
            {
              donViId: null,
              level: 0,
              maPhongBan: null,
              phongBanId: '00000000-0000-0000-0000-000000000000',
              stt: 0,
              tenPhongBan: null,
              tenVietTat: 'Tất cả',
            },
          ]
          res.payload.data.result.forEach(item => {
            if (item.phongBanId) {
              data = [...data, item]
            } else {
              this.props.actions
                .getDepartmentUnit(item.donViId, isAll)
                .then(res => {
                  data = [
                    ...data,
                    {
                      ...item,
                      dsPhongBanCon: res.payload.data.result,
                    },
                  ]
                })
                .then(() => {
                  this.setState({
                    phongBanLapDS: data,
                  })
                })
            }
          })
          this.setState({
            phongBanLapDS: data,
          })
        }
      })
  }

  hanleKeyActive = (id, actived) => {
    const { itemsActive } = this.state
    const dataStore = Tool.Utils.getData(`DS-HoSoCongViec-${this.props.keyTab}`)
    if (!size(itemsActive)) {
      return this.setState(
        {
          itemsActive: [
            {
              id: id,
              active: actived,
            },
          ],
        },
        () => {
          Tool.Utils.saveData(`DS-HoSoCongViec-${this.props.keyTab}`, {
            ...dataStore,
            itemsActive: this.state.itemsActive,
          })
        }
      )
    } else {
      if (itemsActive.filter(elm => elm.id === id).length !== 0) {
        this.setState(
          {
            itemsActive: itemsActive.map(elm => {
              if (elm.id === id) {
                return {
                  ...elm,
                  active: actived,
                }
              }

              return { ...elm }
            }),
          },
          () => {
            Tool.Utils.saveData(`DS-HoSoCongViec-${this.props.keyTab}`, {
              ...dataStore,
              itemsActive: this.state.itemsActive,
            })
          }
        )
      } else {
        this.setState(
          {
            itemsActive: [
              ...itemsActive,
              {
                id: id,
                active: actived,
              },
            ],
          },
          () => {
            Tool.Utils.saveData(`DS-HoSoCongViec-${this.props.keyTab}`, {
              ...dataStore,
              itemsActive: this.state.itemsActive,
            })
          }
        )
      }
    }
  }

  /**
   * Xử lý các action row table
   * @memberof DanhSachTuTaiLieu
   */
  handleAction = (key, data) => {
    switch (key) {
      case 'THEM-MOI-THU-MUC-CON':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Thêm',
            idRow: data.id,
            isOpenPopup: true,
            titlePopup: 'Thêm mới thư mục',
          },
        })
        break
      case 'THEM-MOI-HSCV-CON':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Thêm',
            idRow: data.id,
            isOpenPopup: true,
            titlePopup: 'Thêm mới hồ sơ công việc',
          },
        })
        break
      case 'THEM-MOI-THU-MUC':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Thêm',
            idRow: null,
            isOpenPopup: true,
            titlePopup: 'Thêm mới thư mục',
          },
        })
        break
      case 'THEM-MOI-HSCV':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Thêm',
            idRow: null,
            isOpenPopup: true,
            titlePopup: 'Thêm mới hồ sơ công việc',
          },
        })
        break
      case 'CHINH-SUA':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Cập nhật',
            isOpenPopup: true,
            titlePopup: 'Chỉnh sửa',
          },
        })
        break
      case 'XOA':
        this.setState({
          dataPopupConfirm: {
            ...this.state.dataPopupConfirm,
            idRow: data.id,
            isOpenPopup: true,
          },
        })
        break
      case 'DUYET-HOSO':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Duyệt',
            isOpenPopup: true,
            titlePopup: 'Duyệt hồ sơ',
          },
        })
        break
      case 'DUYET-LAI':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Duyệt lại',
            isOpenPopup: true,
            titlePopup: 'Duyệt lại hồ sơ',
          },
        })
        break
      case 'HUY-NOP':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Hủy nộp',
            isOpenPopup: true,
            titlePopup: 'Hủy nộp',
          },
        })
        break
      case 'TIEP-NHAN':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Tiếp nhận',
            isOpenPopup: true,
            titlePopup: 'Tiếp nhận',
          },
        })
        break
      case 'TIEP-NHAN-LAI':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Tiếp nhận lại',
            isOpenPopup: true,
            titlePopup: 'Tiếp nhận lại',
          },
        })
        break
      case 'DI-CHUYEN':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            data: data,
            type: key,
            textSubmit: 'Lưu',
            isOpenPopup: true,
            titlePopup: 'Di chuyển đến',
          },
        })
        break
      default:
        break
    }
  }

  /**
   * Đóng popup
   * @memberof DanhSachTuTaiLieu
   */
  handleClickPopup = () => {
    this.setState({
      dataPopup: {
        ...this.state.dataPopup,
        isOpenPopup: false,
      },
      dataPopupConfirm: {
        ...this.state.dataPopupConfirm,
        isOpenPopup: false,
      },
    })
  }

  /**
   * Xử lý khi hoàn thành submit popup
   * @memberof DanhSachTuTaiLieu
   */
  onSuccessPopup = () => {
    this.submitFilter()
  }

  /**
   * Xử lý khi thực hiện thao tác xóa hồ sơ công việc
   * @memberof DanhSachTuTaiLieu
   */
  handleSubmitRemoveItem = async () => {
    const { dataPopupConfirm } = this.state
    this.setState({
      dataPopupConfirm: {
        ...dataPopupConfirm,
        isLoadingButton: true,
      },
    })
    try {
      const id = dataPopupConfirm.idRow
      await this.props.actions.deleteHoSoCongViec(id).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_DELETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        dataPopupConfirm: {
          isOpenPopup: false,
          isLoadingButton: false,
        },
      })
      this.onSuccessPopup()
    }
  }

  /**
   * Render radio
   * @param {*} array danh sách radio button
   * @returns hiển thị danh sách các radio theo tab
   * @memberof DanhSachTuTaiLieu
   */
  renderRadio(array) {
    return array
      .filter(item => item.id === null || item.id === this.props.keyTab)
      .map((elm, index) => (
        <Radio
          key={index}
          className="pt-large pt-text"
          label={elm.name}
          value={elm.value}
        />
      ))
  }

  /**
   * Filter action
   * Change data filter
   * @memberof DanhSachTuTaiLieu
   */
  onChangeGridViewFilter = e => {
    const value = e.target.value
    const name = e.target.name
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (
          name === 'thoiHanBaoQuanId' ||
          name === 'phongBanLapId' ||
          name === 'nam'
        ) {
          return this.submitFilter()
        }

        if (name === 'trangThaiId') {
          this.setFilterSelectBox('0')
          this.setState(
            {
              trangThaiXuLy: '0',
            },
            () => {
              return this.submitFilter()
            }
          )
        }
      }
    )
  }

  /**
   * Filter action
   * Change data filter
   * @memberof DanhSachTuTaiLieu
   */
  onChangeDateGridViewFilter = (name, e) => {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  onChangeSelectTreeFilter = (name, value) => {
    if (value === tatCa && value !== donVi) {
      return this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: '00000000-0000-0000-0000-000000000000',
          },
        },
        () => {
          this.submitFilter()
        }
      )
    } else if (value !== tatCa && value !== donVi) {
      this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          this.submitFilter()
        }
      )
    }
  }

  /**
   * Filter action
   * Remove data filter
   * @memberof DanhSachTuTaiLieu
   */
  onRemoveText = name => {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  /**
   * Change radio button
   * @memberof DanhSachTuTaiLieu
   */
  handleChangeRadio = e => {
    const { target } = e
    this.setState(
      {
        [target.name]: target.value,
      },
      () => {
        this.props && this.props.handleChangeRadio(target.value)
      }
    )
  }

  /**
   * Xử lý filter dữ liệu
   * @memberof DanhSachTuTaiLieu
   */
  submitFilter = async (page = 1, sorter) => {
    this.setState({ isLoading: true })
    try {
      const { filter, trangThaiXuLy, itemsActive } = this.state
      const filterColumn = {
        ...this.state.filterColumn,
        ten:
          this.state.filterColumn.ten && this.state.filterColumn.ten !== ''
            ? this.state.filterColumn.ten.trim()
            : null,
        maKyHieu:
          this.state.filterColumn.maKyHieu &&
          this.state.filterColumn.maKyHieu !== ''
            ? this.state.filterColumn.maKyHieu.trim()
            : null,
        thoiHanBaoQuanId:
          this.state.filterColumn.thoiHanBaoQuanId &&
          this.state.filterColumn.thoiHanBaoQuanId !== ''
            ? this.state.filterColumn.thoiHanBaoQuanId
            : null,
        phongBanLapId:
          this.state.filterColumn.phongBanLapId &&
          this.state.filterColumn.phongBanLapId !==
            '00000000-0000-0000-0000-000000000000'
            ? this.state.filterColumn.phongBanLapId
            : null,
        nguoiChiuTrachNhiem:
          this.state.filterColumn.nguoiChiuTrachNhiem &&
          this.state.filterColumn.nguoiChiuTrachNhiem !== ''
            ? this.state.filterColumn.nguoiChiuTrachNhiem.trim()
            : null,
        trangThaiId:
          this.state.filterColumn.trangThaiId &&
          this.state.filterColumn.trangThaiId !== ''
            ? this.state.filterColumn.trangThaiId
            : null,
        nam:
          this.state.filterColumn.nam && this.state.filterColumn.nam !== ''
            ? this.state.filterColumn.nam
            : null,
      }
      this.setState(
        {
          filter,
          page,
          sorter,
        },
        () => {
          Tool.Utils.preData(`DS-HoSoCongViec-${this.props.keyTab}`, {
            filter,
            page,
            sorter,
            filterColumn,
            trangThaiXuLy,
            itemsActive,
          })
          Tool.Utils.preData(`List-CabinetDocuments-${this.props.type}`, {
            filter,
            page,
            sorter,
            saveTo: this.props.type,
            keyTabTuTaiLieu: this.props.keyTabTuTaiLieu,
            filterColumn,
            trangThaiXuLy,
          })
        }
      )
      await this.props.actions
        .getDanhSachHoSoCongViec({ filter }, page, sorter, filterColumn)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.items &&
            res.payload.data.result.items.length !== 0
          ) {
            this.setState({
              isEmpty: false,
              isError: false,
              items: res.payload.data.result.items,
            })
          } else {
            this.setState({ isEmpty: true })
          }
        })
    } catch (error) {
      this.setState({ isError: true })
    } finally {
      this.setState({ isLoading: false })
      // Scroll đến vị trí được lưu lịch sử trong store
      const scrollLocation = Tool.Utils.getData('scroll-hscv-location')
      if (scrollLocation) {
        window.scrollTo(0, scrollLocation)
        Tool.Utils.removeData('scroll-hscv-location')
      }
    }
  }

  handleSelectBoxXuLy = e => {
    const { target } = e
    const value = target.value
    this.setState(
      {
        [target.name]: value,
      },
      () => {
        this.setFilterSelectBox(value)
        if (
          value === '1' &&
          this.props.keyTab === 'TTL_PB' &&
          intersection(this.props.auth.permission, [
            Permission.HSCV_PHONGBAN_DUYET,
          ]).length !== 0
        ) {
          // Tủ phòng ban và HSCV_PHONGBAN_DUYET set trạng thái lđpb chờ duyệt
          this.setState(
            {
              filterColumn: {
                ...this.filterColumn,
                trangThaiId: '1',
                nam: moment().format('YYYY'),
              },
            },
            () => {
              return this.submitFilter()
            }
          )
        } else if (
          value === '1' &&
          this.props.keyTab === 'TTL_DV' &&
          intersection(this.props.auth.permission, [Permission.HSCV_VANTHU])
            .length !== 0
        ) {
          // Tủ đơn vị và HSCV_VANTHU set trạng thái chờ tiếp nhận
          this.setState(
            {
              filterColumn: {
                ...this.filterColumn,
                trangThaiId: '2',
                nam: moment().format('YYYY'),
              },
            },
            () => {
              return this.submitFilter()
            }
          )
        } else if (
          value === '1' &&
          this.props.keyTab === 'TTL_DV' &&
          intersection(this.props.auth.permission, [
            Permission.HSCV_VANPHONG_DUYET,
          ]).length !== 0
        ) {
          // Tủ đơn vị và HSCV_VANPHONG_DUYET set trạng thái LĐVP chờ duyệt
          this.setState(
            {
              filterColumn: {
                ...this.filterColumn,
                trangThaiId: '3',
                nam: moment().format('YYYY'),
              },
            },
            () => {
              this.submitFilter()
            }
          )
        } else {
          this.setState(
            {
              filterColumn: {
                ...this.filterColumn,
                trangThaiId: '',
                nam: moment().format('YYYY'),
              },
            },
            () => {
              return this.submitFilter()
            }
          )
        }
      }
    )
  }

  setFilterSelectBox = value => {
    switch (value) {
      case '0':
        this.setState({
          filter: {
            ...this.state.filter,
            isChoXuLy: false,
            isTatCa: true,
            isDaLuuTru: false,
            isViewAllDonVi: null,
          },
        })
        break
      case '1':
        this.setState({
          filter: {
            ...this.state.filter,
            isChoXuLy: true,
            isTatCa: false,
            isDaLuuTru: false,
            isViewAllDonVi: null,
          },
        })
        break
      case '2':
        this.setState({
          filter: {
            ...this.state.filter,
            isChoXuLy: false,
            isTatCa: false,
            isDaLuuTru: true,
            isViewAllDonVi: null,
          },
        })
        break
      case '3':
        this.setState({
          filter: {
            ...this.state.filter,
            isChoXuLy: false,
            isTatCa: false,
            isDaLuuTru: false,
            isViewAllDonVi: true,
          },
        })
        break
      default:
        break
    }
  }

  /**
   * Sắp xếp column table
   * @memberof DanhSachTuTaiLieu
   */
  handleSortTable = e => {}

  render() {
    const {
      radioData,
      items,
      dataPopup,
      dataPopupConfirm,
      filter,
      phongBanLapDS,
      trangThaiXuLy,
    } = this.state
    const { auth, thoiHanBaoQuanDanhSach } = this.props
    // Format danh sách thời hạn lưu từ api
    let thoiHanBaoQuanDS = []
    if (thoiHanBaoQuanDanhSach && thoiHanBaoQuanDanhSach.length !== 0) {
      thoiHanBaoQuanDanhSach.map(elm => {
        return thoiHanBaoQuanDS.push({
          name: elm.tenThoiHanLuu,
          value: elm.thoiHanLuuId,
        })
      })
    }
    // Check quyền action thêm mới
    let isDisabledThemMoi = true
    if (
      intersection(auth.permission, [Permission.TUTAILIEUPHONGBAN_QUANLY])
        .length !== 0 &&
      (filter.isChoXuLy === false ||
        filter.isChoXuLy === null ||
        filter.isTatCa === true)
    ) {
      isDisabledThemMoi = false
    }
    // Check hiển thị column phòng ban lập
    let phongBanLapHide = false
    if (this.props.keyTab === 'TTL_PB') {
      phongBanLapHide = true
    }
    // Check không hiển thị menu action ở thư mục khi checked checkbox chờ xử lý
    let isShowMenuActionThuMuc = true
    if (filter && filter.isChoXuLy === true) {
      isShowMenuActionThuMuc = false
    }
    // Check disabled trạng thái
    let trangThaiIdDisabledFilter = false
    if (
      (this.props.keyTab === 'TTL_DV' || this.props.keyTab === 'TTL_PB') &&
      filter.isTatCa === false
    ) {
      trangThaiIdDisabledFilter = true
    }
    // Tắt các bộ lọc khi không check chọn "hscv chờ xử lý" ở tab hồ sơ công việc - ttl đơn vị
    let trangThaiIdHide = false
    let controlHide = false
    if (
      this.props.keyTab === 'TTL_DV' &&
      (filter.isDaLuuTru === true || !!filter.isViewAllDonVi)
    ) {
      phongBanLapHide = true
      trangThaiIdHide = true
      controlHide = true
    }

    return (
      <div>
        <div className="row-radio-checkbox mb5">
          <RadioGroup
            className="tab-items-radio--group"
            name="radioData"
            onChange={this.handleChangeRadio}
            selectedValue={radioData}
          >
            {this.renderRadio(checkBoxArray)}
          </RadioGroup>

          <div className="d-flex align-center">
            {((this.props.keyTab === 'TTL_PB' &&
              intersection(this.props.auth.permission, [
                Permission.HSCV_PHONGBAN_DUYET,
              ]).length !== 0) ||
              (this.props.keyTab === 'TTL_DV' &&
                intersection(auth.permission, [
                  Permission.HSCV_VANPHONG_DUYET,
                  Permission.HSCV_VANTHU,
                ]).length !== 0)) && (
              <div className="mr20">
                <div className="select-box-item">
                  <select
                    name="trangThaiXuLy"
                    onChange={e => this.handleSelectBoxXuLy(e)}
                    value={trangThaiXuLy}
                  >
                    <option value="0">
                      {this.props.keyTab === 'TTL_DV'
                        ? 'HSCV đã xử lý'
                        : 'Tất cả'}
                    </option>
                    <option value="1">HSCV chờ xử lý</option>
                    {this.props.keyTab === 'TTL_DV' && (
                      <option value="2">HSCV đã lưu trữ</option>
                    )}
                    {this.props.keyTab === 'TTL_DV' &&
                      intersection(auth.permission, [
                        Permission.HSCV_VANPHONG_DUYET,
                        Permission.HSCV_VANTHU,
                      ]).length !== 0 && (
                        <option value="3">HSCV phòng ban đang lập</option>
                      )}
                  </select>
                </div>
              </div>
            )}
            {this.props.type === '2' && (
              <Button
                rightIconName="search"
                className="cpc-button cpc-button-green mr20"
                text="TÌM KIẾM"
                onClick={this.props.toggleShowDocumentSearch}
              />
            )}
          </div>
        </div>
        <div className="table-drag-and-drop">
          <div className="cpc-table-scroll ml--1 mr--1 mb--1">
            <div className="cpc-table-scroll-content">
              <div className="cpc-sortable">
                <div className="cpc-sortable-head uppercase">
                  <HeadingTableDragAndDrop
                    data={headers.TuTaiLieuDanhSach}
                    onSort={this.handleSortTable}
                    phongBanLapHide={phongBanLapHide}
                    // Ẩn các bộ lọc khi group theo phòng ban
                    trangThaiIdHide={trangThaiIdHide}
                    controlHide={controlHide}
                    // --------------------------------------
                  />
                </div>
                <div className="cpc-sortable-body">
                  {!this.state.isLoading && !this.state.isError && (
                    <div className="cpc-sortable-body--row">
                      <FilterTable
                        data={headers.TuTaiLieuDanhSach}
                        phongBanLapId={phongBanLapDS}
                        phongBanLapHide={phongBanLapHide}
                        // Ẩn các bộ lọc khi group theo phòng ban
                        trangThaiIdHide={trangThaiIdHide}
                        controlHide={controlHide}
                        // --------------------------------------
                        // Ẩn form lọc dữ liệu
                        // --------------------------------------
                        phongBanLapIdDisabledFilter={true}
                        trangThaiIdDisabledFilter={trangThaiIdDisabledFilter}
                        filterColumn={this.state.filterColumn}
                        thoiHanBaoQuanId={thoiHanBaoQuanDS}
                        trangThaiId={
                          this.props.keyTab === 'TTL_DV'
                            ? intersection(auth.permission, [
                                Permission.HSCV_VANTHU,
                              ]).length !== 0
                              ? trangThaiHoSoCongViecDonViVanThu
                              : trangThaiHoSoCongViecDonViLĐVP
                            : trangThaiHoSoCongViec
                        }
                        onChange={this.onChangeGridViewFilter}
                        onChangeDate={this.onChangeDateGridViewFilter}
                        onChangeSelectTree={this.onChangeSelectTreeFilter}
                        onRemoveText={this.onRemoveText}
                        onSubmit={this.submitFilter}
                      />
                    </div>
                  )}
                  {this.state.isLoading ? (
                    <div className="cpc-sortable-body--row-loading">
                      <LoadingTable />
                    </div>
                  ) : (
                    !this.state.isError &&
                    !this.state.isEmpty &&
                    items &&
                    items.length !== 0 && (
                      <HoSoCongViecGroup
                        auth={this.props.auth}
                        data={items}
                        handleAction={this.handleAction}
                        hanleKeyActive={this.hanleKeyActive}
                        history={this.props.history}
                        itemsActive={this.state.itemsActive}
                        keyTab={this.props.keyTab}
                        isShowMenuActionThuMuc={isShowMenuActionThuMuc}
                        filter={filter}
                      />
                    )
                  )}
                </div>
                {/* Dữ liệu rỗng */}
                {this.state.isEmpty &&
                  !this.state.isLoading &&
                  !this.state.isError && (
                    <div className="error-page-container bg-white">
                      <div className="error-content">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/empty-default.png'
                          }
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">{MESSAGE_NOT_FOUND}</p>
                      </div>
                    </div>
                  )}
                {/* Lỗi dữ liệu */}
                {this.state.isError &&
                  !this.props.isLoading &&
                  !this.state.isEmpty && (
                    <div className="error-page-container bg-white">
                      <div className="error-content">
                        <img
                          src={process.env.PUBLIC_URL + '/images/Server.png'}
                          alt="error"
                          className="img-thumb-error"
                        />
                        <p className="description-note">{MESSAGE_ERROR}</p>
                      </div>
                    </div>
                  )}
                {this.props.keyTab === 'TTL_PB' && !isDisabledThemMoi && (
                  <div className="cpc-sortable-footer pl20">
                    <div className="group-button">
                      <div className="group-button--content">
                        <Button
                          className="btn-blue text-uppercase"
                          loading={false}
                          disabled={isDisabledThemMoi}
                          onClick={() => this.handleAction('THEM-MOI-THU-MUC')}
                        >
                          Thêm mới thư mục
                          <span className="icon icon-Plus ml8"></span>
                        </Button>
                      </div>
                      <div className="group-button--content">
                        <Button
                          className="btn-blue text-uppercase"
                          loading={false}
                          disabled={isDisabledThemMoi}
                          onClick={() => this.handleAction('THEM-MOI-HSCV')}
                        >
                          Thêm mới hồ sơ công việc
                          <span className="icon icon-Plus ml8"></span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {dataPopup.isOpenPopup === true && (
          <CapNhatHoSoCongViec
            actions={this.props.actions}
            auth={this.props.auth}
            dataPopup={dataPopup}
            onSuccess={this.onSuccessPopup}
            onClose={this.handleClickPopup}
          />
        )}
        <PopupConfirm
          isOpen={dataPopupConfirm.isOpenPopup}
          isLoading={dataPopupConfirm.isLoadingButton}
          onClose={this.handleClickPopup}
          title={XOA_THUMUC_OR_HOSOCONGVIEC}
          text={TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitRemoveItem}
        >
          {TEXT_SUBMIT_BODY}
        </PopupConfirm>
      </div>
    )
  }
}

DanhSachTuTaiLieu.defaultProps = {
  keyTab: 'TTL_DV',
  nameTab: 'TTL ĐƠN VỊ',
  typeApp: '',
}

DanhSachTuTaiLieu.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  keyTab: PropTypes.string,
  nameTab: PropTypes.string,
  typeApp: PropTypes.string,
}

export default DanhSachTuTaiLieu
