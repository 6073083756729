import { schema } from 'normalizr'

const DepartmentWork = new schema.Entity(
  'departmentWorks',
  {},
  {
    idAttribute: departmentWork => departmentWork.congViecPhongBanId,
  }
)

export default DepartmentWork
