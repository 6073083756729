import React from 'react'
import { bindActionCreators } from 'redux'
import FileDownload from 'file-saver'
import { get } from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button, Intent } from '@blueprintjs/core'

import { MasterLayout } from '../../components/layout'
import { ListItem } from '../../components/ListGerenal/DocumentCabinet' // ListFooter
import { GridView } from '../../components/common' // HeadingPage
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import { Authorization, Permission } from '../../components/auth'
import * as Tool from '../../helpers'
import { DA_XAY_RA_LOI } from '../../constants/MessageForm'
import HeadingPanel from 'components/common/HeadingPanel'

import Pagination from 'components/Pagination'

class ListDocumentCabinetOverTime extends React.Component {
  constructor(props) {
    super(props)
    const isAdmin = Authorization.vaild(
      this.props.auth.permission,
      [Permission.SUPERADMIN, Permission.ADMIN],
      true
    )
    const type = get(this.props, 'match.params.type')
    const phongBanId = get(this.props, 'match.params.phongBanId')
    let state = {
      type,
      id: props.match.params.id,
      phongBanId,
      isEmpty: false,
      isLoading: false,
      page: 1,
      checkList: {},
      checkAll: false,
      isAdmin,
      startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    }
    this.state = state
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    this.setState(
      {
        startDate: get(
          location.state,
          'start_date',
          moment().subtract(1, 'months').format('YYYY-MM-DD')
        ),
        endDate: get(location.state, 'end_date', moment().format('YYYY-MM-DD')),
      },
      () => {
        this.clickPagination(1)
      }
    )
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.page, this.state.sort)
    })
  }

  submitFilter(page = 1, sort) {
    const { type, id, phongBanId } = this.state
    Tool.Utils.preData('thongKeDanhSachCongVanChuaLuu', this.state)
    this.setState({ page, sort, isLoading: true }, () => {
      this.props.actions
        .getDocumentCabinetOverTime(
          {
            phong_ban_id:
              type === 'phong-ban' ? id : phongBanId ? phongBanId : null,
            chuc_danh_id: type === 'ca-nhan' ? id : null,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          page,
          sort
        )
        .then(res => {
          if (res && !res.error && res.payload.data.result.total === 0) {
            return this.setState({ isEmpty: true })
          }

          this.setState({ isEmpty: false })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.page, sort)
  }

  handleClickGoBack = () => {
    this.props.history &&
      this.props.history.goBack({
        state: {
          isFromeDetailPage: true,
          isCheck: true,
        },
      })
    Tool.Utils.saveData('isSubmitStatisticCabinet', true)
  }

  handleCheckAll(e) {
    let checkList = {}
    if (this.props.documentCabinetOverTime.list.items) {
      this.props.documentCabinetOverTime.list.items.map(item => {
        return (checkList[item.congVanDenId] = e)
      })
    }

    this.setState({
      checkList,
      checkAll: e,
    })
  }

  handleCheck(id, e) {
    let state = {
      checkList: {
        ...this.state.checkList,
        [id]: e,
      },
    }

    if (!e) {
      state.checkAll = false
    }

    this.setState(state, () => {
      const listItems = this.props.documentCabinetOverTime.list.items.filter(
        item => this.state.checkList[item.congVanDenId]
      )
      if (
        listItems.length ===
        this.props.documentCabinetOverTime.list.items.length
      ) {
        this.setState({ checkAll: true })
      }
    })
  }

  // Xuất excel thống kê công văn chưa lưu
  exportData = async () => {
    const { actions } = this.props
    const { sort, type, id, phongBanId } = this.state
    const { xuatExcelDanhSachCongVanChuaLuu, commonAddToasterMessage } = actions
    this.setState({ isLoading: true })
    try {
      const response = await xuatExcelDanhSachCongVanChuaLuu({
        phong_ban_id:
          type === 'phong-ban' ? id : phongBanId ? phongBanId : null,
        chuc_danh_id: type === 'ca-nhan' ? id : null,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        sort,
        page: 1,
        limit: 0,
      })

      if (response.error) {
        return commonAddToasterMessage({
          message: 'Không xuất được file.',
          intent: Intent.DANGER,
        })
      }

      let preData = Tool.Utils.getData('thongKeDanhSachCongVanChuaLuu')
      FileDownload.saveAs(
        response.payload.data,
        `Thong-Ke-Cong-Van-Chua-Luu-${moment(preData.startDate).format(
          'L'
        )}-${moment(preData.endDate).format('L')}.xls`
      )
    } catch (error) {
      return commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const { isEmpty, isLoading, page } = this.state
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            className="mb10"
            goBack={this.handleClickGoBack}
            leftTitle="Tổng hợp công văn chưa lưu"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={() => this.exportData()}
                disabled={isLoading}
                loading={isLoading}
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container">
            <div className="table-container pb0">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table'}
                page={this.state.page ? this.state.page : 1}
                headers={headers.documentCabinetOverTime}
                itemShape={ListItem}
                data={this.props.documentCabinetOverTime.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onHandleClickItem={this.HandleClickItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                onCheck={this.handleCheck}
                onCheckAll={this.handleCheckAll}
                isEnabledAll={this.state.checkAll}
                checkList={this.state.checkList}
                isShowCheckBox={!this.state.isAdmin}
              />
              {!isLoading && !isEmpty && (
                <Pagination
                  current={page}
                  total={Math.ceil(
                    this.props.documentCabinetOverTime.list.total /
                      Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  typeSidebar: state.common.typeSidebar,
  documentCabinetOverTime: {
    ...state.documentsCabinet.documentCabinetOverTime,
    list: {
      ...state.documentsCabinet.documentCabinetOverTime.list,
      items: state.documentsCabinet.documentCabinetOverTime.list.items,
    },
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDocumentCabinetOverTime)
