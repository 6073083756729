import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Checkbox } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
//import { FILE_URL } from '../../../../constants/Api';

const LINE_HEIGHT = 18

class ListItemNotAssigned extends Component {
  constructor(props) {
    super(props)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleReadMore = this.handleReadMore.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    list: PropTypes.array,
  }

  state = {
    isEnabled: false,
    isRedirect: false,
    isOpenPopup: false,
    readMore: {
      trichYeu: false,
      giaoViec: false,
    },
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled })
    let type = ''
    let id = null
    if (this.props.item) {
      type = 'cong_van_den_id'
      id = this.props.item.congVanDenId
      if (this.props.item.congVanNoiBoId) {
        type = 'cong_van_noi_bo_id'
        id = this.props.item.congVanNoiBoId
      }
    }
    this.props.onCheck && this.props.onCheck(this.props.item, id, type)
  }

  componentDidMount() {
    if (
      this.props.list.find(
        item =>
          item.cong_viec_phong_ban_id === this.props.item.congViecPhongBanId
      )
    ) {
      this.setState({ isEnabled: true })
    }
    let newReadMore = { ...this.state.readMore }
    if (this.trichYeu && this.trichYeu.offsetHeight / LINE_HEIGHT > 5) {
      newReadMore = {
        ...newReadMore,
        trichYeu: true,
      }
    }
    if (this.giaoViec && this.giaoViec.offsetHeight / LINE_HEIGHT > 4) {
      newReadMore = {
        ...newReadMore,
        giaoViec: true,
      }
    }
    this.setState({
      readMore: newReadMore,
    })
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }

    this.props.onClickDetail &&
      this.props.onClickDetail({
        congViecPhongBanId: this.props.item.congViecPhongBanId,
        soCongVan: this.props.item.soCongVan,
        fileId: this.props.item.fileId,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
    this.props.onHandleSubmitRemoveItem(this.props.item.congViecPhongBanId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list !== this.props.list) {
      let isEnabled = false
      if (
        this.props.list.find(
          item =>
            item.cong_viec_phong_ban_id === this.props.item.congViecPhongBanId
        )
      ) {
        isEnabled = true
      }
      this.setState({ isEnabled: isEnabled })
    }
  }

  handleReadMore(name) {
    let newReadMore = { ...this.state.readMore }
    if (newReadMore[name]) {
      newReadMore = {
        ...newReadMore,
        [name]: false,
      }
      this.setState({ readMore: newReadMore })
    } else {
      this.handleClick()
    }
  }

  render() {
    //const token = this.props.auth && this.props.auth.token && this.props.auth.token.accessToken ? this.props.auth.token.accessToken : '';
    //let fileUrl = `${FILE_URL}/api/file/get/${item.fileId}?BearerToken=${token}`;
    let giaoViecMoi = false
    if (
      this.props.item &&
      !this.props.item.congVanDenId &&
      !this.props.item.congVanDiId &&
      !this.props.item.congVanNoiBoId
    ) {
      giaoViecMoi = true
    }
    const { item, number } = this.props
    return (
      <tr>
        <td>
          <Checkbox
            checked={this.state.isEnabled}
            onChange={this.handleEnabledChange}
            disabled={giaoViecMoi}
          />
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {number && number > 9 ? number : '0' + number}
          </Link>
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {item && item.daLuuTuTaiLieu && item.daLuuTuTaiLieu === true ? (
              <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet"></span>
            ) : (
              <span>&nbsp;</span>
            )}
          </Link>
        </td>
        <td onClick={this.handleClick}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {item && item.baoNham && (
              <span className="icon-type-work icon-attention-warning"></span>
            )}
          </Link>
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {item && item.soCongVan}
          </Link>
        </td>
        <td className="textarea-content" onClick={this.handleClick}>
          <div ref={el => (this.trichYeu = el)}>
            <p
              className={classnames({
                // 'overflow-hidden-90px': this.state.readMore.trichYeu
              })}
            >
              {item && item.trichYeu ? item.trichYeu : ''}
            </p>
            {/* {this.state.readMore.trichYeu && <i className="icon-Group-8" onClick={this.handleReadMore.bind(this, 'trichYeu')}></i>} */}
          </div>
        </td>
        {/*<td className="text-center">
          {item.fileId &&
            <a className="view-file-attach" href={fileUrl} target="_blank" rel="noopener noreferrer">
              <i className="pt-icon icon-attachment-file"></i>
            </a>
          }
        </td>*/}
        <td onClick={this.handleClick}>
          <div className="container-text-content">
            <p className="text-content">
              {item &&
              item.chiDao &&
              item.chiDao.nguoiChiDao &&
              item.chiDao.nguoiChiDao.tenNhanVien
                ? item.chiDao.nguoiChiDao.tenNhanVien
                : null}
            </p>
            <p className="text-content">
              {item && item.chiDao && item.chiDao.ngayChiDao
                ? moment(item.chiDao.ngayChiDao).format('DD/MM/YYYY HH:mm')
                : null}
            </p>
          </div>
          <div className="assign-content textarea-content">
            <div ref={el => (this.giaoViec = el)}>
              <p
                className={classnames({
                  // 'overflow-hidden-72px': this.state.readMore.giaoViec
                })}
              >
                {item && item.chiDao && item.chiDao.noiDung
                  ? item.chiDao.noiDung
                  : ''}
              </p>
              {/* {this.state.readMore.giaoViec && <i className="icon-Group-8" onClick={this.handleReadMore.bind(this, 'giaoViec')}></i>} */}
            </div>
          </div>
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {item && item.hanGiaiQuyet
              ? moment(item.hanGiaiQuyet).format('DD/MM/YYYY')
              : null}
          </Link>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            <span
              className={classnames('deadline-date', {
                'style-qh': item && item.soNgayConLai < 0,
                'style-gqh':
                  item && (item.soNgayConLai === 0 || item.soNgayConLai === 1),
                'style-color': item && item.soNgayConLai > 1,
              })}
            >
              {item && item.soNgayConLai !== null ? item.soNgayConLai : ''}
            </span>
          </Link>
        </td>
        <td
          style={{ padding: '0' }}
          onClick={this.handleClick}
          className="text-nowrap"
        >
          <Link
            className={classnames('table-link', {
              'color-blue': !!item.chuTri,
            })}
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {item && item.chuTri === false ? 'Phối hợp' : 'Chủ trì'}
          </Link>
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <Link
            className="table-link"
            to={`/cong-viec/phong-ban/chi-tiet/chua-giao/${this.props.item.congViecPhongBanId}`}
          >
            {item && item.noiGui}
          </Link>
        </td>
      </tr>
    )
  }
}

export default ListItemNotAssigned
