import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// import { Redirect } from 'react-router-dom';
// import classnames from 'classnames';

const EVN_TRANGTHAI_DANHAN = 3
class ListEvnItem extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    if (this.props.item.trangThai !== EVN_TRANGTHAI_DANHAN) {
      this.props.history.push({
        pathname: '/cong-van/cong-van-den/them-moi',
        state: {
          id: this.props.item.id,
          fileId: this.props.item.fileId,
          fileName:
            this.props.item && this.props.item.tenFile
              ? this.props.item.tenFile
              : 'FILE_NAME',
        },
      })
    } else {
      const id = this.props.item.congVanDenId
      this.props.history.push({
        pathname: `/cong-van/cong-van-evn/chi-tiet/${id}/evn`,
        state: {
          id,
          fileName:
            this.props.item && this.props.item.tenFile
              ? this.props.item.tenFile
              : 'FILE_NAME',
        },
      })
    }
  }

  render() {
    // if (this.state.isRedirect) {
    //   return (
    //     <Redirect
    //       push
    //       to={{
    //         pathname: '/cong-van/cong-van-den/them-moi',
    //         state: {
    //           id: this.props.item.id,
    //           fileId: this.props.item.fileId,
    //           fileName: this.props.item && this.props.item.tenFile ? this.props.item.tenFile : 'FILE_NAME'
    //         }
    //       }}
    //     />
    //   );
    // }

    if (!this.props.item) {
      return null
    }

    return (
      <tr
        onClick={this.handleClick}
        // className={
        //   classnames({
        //     'no-pointer': this.props.item.trangThai === EVN_TRANGTHAI_DANHAN
        //   })
        // }
      >
        <td>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td>{this.props.item.soCongVan}</td>
        <td>{moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')}</td>
        <td className="textarea-content">{this.props.item.trichYeu}</td>
        <td>{this.props.item.nguoiKy}</td>
      </tr>
    )
  }
}

ListEvnItem.propTypes = {
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  disableAction: PropTypes.bool,
}

export default ListEvnItem
