import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Types from '../../constants/Api'
import { Button } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { PopupConfirm } from '../common/'

class FeedbackItem extends Component {
  constructor(props) {
    super(props)
    this.handleShowForm = this.handleShowForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)
  }

  state = {
    gopYId: this.props.item ? this.props.item.gopYId : '',
    noiDung: this.props.item ? this.props.item.noiDung : '',
    loaiGopY: this.props.item ? this.props.item.loaiGopY : null,
    isOpenPopup: false,
  }

  static propType = {
    item: PropTypes.array.isRequired,
    auth: PropTypes.string,
  }

  componentWillReceiveProps(props) {
    if (props.item && !props.loading) {
      this.setState({
        gopYId: props.item ? props.item.gopYId : '',
        noiDung: props.item ? props.item.noiDung : '',
        loaiGopY: props.item ? props.item.loaiGopY : null,
      })
    }
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  handleGoBack = () => {
    this.setState({
      isOpenPopup: false,
      noiDung: this.props.item ? this.props.item.noiDung : '',
    })
    this.props.onChangeFeedback && this.props.onChangeFeedback()
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleShowForm = () => {
    this.props.onChangeFeedback &&
      this.props.onChangeFeedback(this.state.gopYId)
  }

  handleSubmit = () => {
    this.props.onSubmit &&
      this.props.onSubmit({
        gopYId: this.state.gopYId,
        noiDung: this.state.noiDung,
        loaiGopY: this.state.loaiGopY,
      })
  }

  copyToClipboard = e => {
    var textField = document.createElement('textarea')
    textField.innerText = this.state.noiDung
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.props.onShowMessageCopySuccess && this.props.onShowMessageCopySuccess()
  }

  render() {
    return (
      <div className="item-content-history">
        <div className="time-create">
          {this.props.item && this.props.item.ngayGopY
            ? moment(this.props.item.ngayGopY).format('DD/MM/YYYY HH:mm')
            : ''}
        </div>
        <div className="avatar-user">
          <span
            className="avatar"
            style={{
              backgroundImage: `url(${
                this.props.item &&
                this.props.item.nguoiGopY &&
                this.props.item.nguoiGopY.anhDaiDien
                  ? Types.IMAGE_URL + this.props.item.nguoiGopY.anhDaiDien
                  : '/images/default_avatar.png'
              })`,
            }}
          ></span>
          <span className="name_user">
            {this.props.item && this.props.item.nguoiGopY
              ? this.props.item.nguoiGopY.tenNhanVien
              : ''}
          </span>
        </div>
        <div className="info-content">
          <ul>
            <li>
              <div
                className={`box-feedback ${
                  this.props.className ? this.props.className : ''
                }`}
              >
                <span className="left-icon style-icon icon-Thong_Tin_GopY"></span>
                <div className="content-detail">
                  <span className="title-bold">Thông tin góp ý: </span>
                  <span className="detail textarea-content">
                    {this.props.item.noiDung}
                  </span>
                </div>
                {this.props.isOfSecretary === true && (
                  <span
                    className="left-icon icon-copy"
                    onClick={this.copyToClipboard}
                  ></span>
                )}
              </div>
            </li>
          </ul>
        </div>

        {this.props.item &&
          this.props.item.nguoiGopY &&
          this.props.auth === this.props.item.nguoiGopY.chucDanhId &&
          this.props.isCanUpdate === true &&
          this.props.feedbackId !== this.state.gopYId && (
            <div className="buttton-action-footer">
              <button
                className="pt-button btn-edit"
                onClick={this.handleShowForm}
                disabled={
                  (this.props.isLoadingData &&
                    Object.values(this.props.isLoadingData).find(
                      item => item === true
                    ) !== undefined) ||
                  (this.props.isLoading &&
                    Object.values(this.props.isLoading).find(
                      item => item === true
                    ) !== undefined)
                }
              >
                Chỉnh sửa Góp ý
                <span className="pt-icon pt-icon-large pt-icon-annotation"></span>
              </button>
            </div>
          )}

        {this.props.item &&
          this.props.item.nguoiGopY &&
          this.props.auth === this.props.item.nguoiGopY.chucDanhId &&
          this.props.isCanUpdate === true &&
          this.props.feedbackId === this.state.gopYId && (
            <div className="form-container">
              <div className="box-signnumber">
                <div className="form-input border-top">
                  <div className="pt-form-group">
                    <div className="row">
                      <label className="pt-label col-xs-12 pt0 mb10">Sửa</label>
                      <div className="pt-form-content col-xs-12">
                        <textarea
                          rows="4"
                          className="pt-input pt-fill"
                          placeholder="Nhập nội dung..."
                          dir="auto"
                          name="noiDung"
                          onChange={this.handleChange}
                          value={this.state.noiDung}
                          maxLength={this.props.maxLengthFeedback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="buttton-action-footer button-update-feedback">
                <Button
                  onClick={this.handleClickPopup}
                  className="pt-button btn-cancel"
                  disabled={this.props.loading}
                >
                  <span className="text-content">Quay về</span>
                  <span className=" pt-icon pt-icon-cross"></span>
                </Button>
                <Button
                  type="button"
                  loading={this.props.loading}
                  className="pt-button btn-save"
                  onClick={this.handleSubmit}
                >
                  LƯU
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          )}
        {
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.HUY_SUA_GOP_Y}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleGoBack}
          >
            {POPUP.TEXT_BODY}
          </PopupConfirm>
        }
      </div>
    )
  }
}

export default FeedbackItem
