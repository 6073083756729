import React, { memo } from 'react'
import moment from 'moment'
import csx from 'classnames'

const Timeline = ({ children = null, time, className }) => {
  return (
    <div className={csx('cpc-timeline-item', className)}>
      <span className={csx('cpc-timeline-time', 'font-weight-500')}>
        {moment(time).format('HH:mm DD/MM/YYYY')}
      </span>
      {children}
    </div>
  )
}

export default memo(Timeline)
