import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent, Button } from '@blueprintjs/core'
import { Redirect } from 'react-router-dom'
import moment from 'moment'

import { MasterLayout } from '../../components/layout'
import {
  ListFilterNotAssigned,
  ListFooterNotAssigned,
  ListItemNotAssigned,
} from '../../components/Work/DepartmentWork'
import { GridViewFilter } from '../../components/common'
import { Authorization, Permission } from '../../components/auth'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import * as MESSAGE from '../../constants/MessageForm'

const REFRESH_FALSE = false
const CONG_VAN_NOI_BO = 2
// const CHUA_GIAO = 0;
class DepartmentWorkNotAssigned extends React.Component {
  constructor(props) {
    super(props)
    let phongBanId = null
    if (this.props.auth) {
      phongBanId = this.props.auth.mainDepartmentId
    }

    this.state = {
      phongBanId,
      filter: {},
      filterColumn: {},
      page: 1,
      isEnabledAll: false,
      list: [],
      isRedirect: false,
      redirectTo: null,
      actionTo: '',
      roleFilter: [
        {
          name: 'Tất cả',
          value: undefined,
        },
        {
          name: 'Chỉ đạo',
          value: true,
        },
        {
          name: 'Phối hợp',
          value: false,
        },
      ],
      isEmpty: false,
      isLoading: false,
      workExpiringSoon: 0,
      workExpired: 0,
      // Dùng để chuyển sang trang chi tiết
      fileId: null,
      soCongVan: null,
      isError: false,
      isDone: false,
      styleFilter: null,
    }
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickComplete = this.handleClickComplete.bind(this)
    this.saveToCabinetDocument = this.saveToCabinetDocument.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.handleResetData = this.handleResetData.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    // Remove button check complete work
    // this.handleClickCompleteItem = this.handleClickCompleteItem.bind(this);
  }

  componentDidMount() {
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    let preData = Tool.Utils.preData('DepartmentWorkNotAssigned')
    let state = this.state
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        hanGiaiQuyet:
          preData.filterColumn && preData.filterColumn.hanGiaiQuyet
            ? moment(preData.filterColumn.hanGiaiQuyet)
            : null,
        coQuanBanHanhId:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId
            ? preData.filterColumn.coQuanBanHanhId
            : '',
        coQuanBanHanh:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId === '#'
            ? preData.filterColumn.coQuanBanHanh
            : null,
      }
      state.styleFilter = preData.styleFilter
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination = page => {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const { styleFilter } = this.state
    const filter = Object.assign({}, this.state.filter, e)
    let isCallRequestAgain = false
    const filterColumn = {
      ...this.state.filterColumn,
      hanGiaiQuyet: this.state.filterColumn.hanGiaiQuyet
        ? moment(this.state.filterColumn.hanGiaiQuyet).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId: this.state.filterColumn.coQuanBanHanhId
        ? this.state.filterColumn.coQuanBanHanhId
        : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? this.state.filterColumn.coQuanBanHanh
          : null,
      chiDao:
        this.state.filterColumn.chiDao && this.state.filterColumn.chiDao !== ''
          ? this.state.filterColumn.chiDao
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      vaiTro:
        this.state.filterColumn.vaiTro && this.state.filterColumn.vaiTro !== ''
          ? this.state.filterColumn.vaiTro
          : null,
    }
    const filterColumn1 = {
      ...this.state.filterColumn,
      hanGiaiQuyet: this.state.filterColumn.hanGiaiQuyet
        ? moment(this.state.filterColumn.hanGiaiQuyet).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      chiDao:
        this.state.filterColumn.chiDao && this.state.filterColumn.chiDao !== ''
          ? this.state.filterColumn.chiDao
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      vaiTro:
        this.state.filterColumn.vaiTro && this.state.filterColumn.vaiTro === '1'
          ? true
          : this.state.filterColumn.vaiTro === '0'
          ? false
          : null,
    }
    this.setState({ filter, page, sort, isLoading: true }, () => {
      Tool.Utils.preData('DepartmentWorkNotAssigned', {
        filter,
        page,
        sort,
        filterColumn,
        styleFilter,
      })
      // tạm thời để refresh = false
      this.props.actions
        .departmentWorkNotAssignGet(
          { filter },
          page,
          sort,
          REFRESH_FALSE,
          filterColumn1
        )
        .then(res => {
          if (
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort)
          }

          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }

          this.setState({ isEmpty, isError })
        })
        .then(() => {
          if (isCallRequestAgain === false) {
            this.setState({ isLoading: false })
            this.props.actions.refreshPage(false)
          }
        })
    })
  }

  handleSubmitRemoveItem = id => {
    this.props.actions.commonAddToasterMessage({
      message: 'Xóa công việc thành công!',
      intent: Intent.SUCCESS,
    })
  }

  handleCheckAll = () => {
    const list = []
    if (!this.state.isEnabledAll) {
      this.props.departmentWorks.list.items.forEach(item => {
        if (item.congVanNoiBoId) {
          list.push({
            cong_viec_phong_ban_id: item.congViecPhongBanId,
            cong_van_noi_bo_id: item.congVanNoiBoId,
          })
        } else {
          list.push({
            cong_viec_phong_ban_id: item.congViecPhongBanId,
            cong_van_den_id: item.congVanDenId,
          })
        }
      })
    }

    this.setState({ list, isEnabledAll: !this.state.isEnabledAll })
  }

  handleCheck = (congViecPhongBan, id, type) => {
    if (!congViecPhongBan) {
      return
    }

    let list = this.state.list
    let isEnabledAll = false
    if (
      this.state.list.find(
        item =>
          item.cong_viec_phong_ban_id === congViecPhongBan.congViecPhongBanId
      )
    ) {
      list = list.filter(
        s => s.cong_viec_phong_ban_id !== congViecPhongBan.congViecPhongBanId
      )
    } else {
      list.push({
        cong_viec_phong_ban_id: congViecPhongBan.congViecPhongBanId,
        [type]: id,
        fileId: congViecPhongBan.fileId,
        trichYeu: congViecPhongBan.trichYeu,
      })
    }

    if (list.length === this.props.departmentWorks.list.items.length) {
      isEnabledAll = true
    }

    this.setState({ list, isEnabledAll })
  }

  componentWillReceiveProps(props) {
    if (props.departmentWorkTotals) {
      this.setState({
        workExpiringSoon: props.departmentWorkTotals.workExpiringSoon || 0,
        workExpired: props.departmentWorkTotals.workExpired || 0,
      })
    }
  }

  handleClickAssign = () => {
    if (this.state.list.length > 1) {
      return this.setState({
        isRedirect: true,
        redirectTo: `/cong-viec/giao-viec-moi`,
        actionTo: 'assign',
      })
    }

    this.setState({
      isRedirect: true,
      redirectTo: `/cong-viec/phong-ban/chi-tiet/chua-giao/${this.state.list[0].cong_viec_phong_ban_id}`,
      actionTo: 'assign',
    })
  }

  handleClickComplete = () => {
    this.setState({ isDone: true }, () => {
      const dsCongViecPhongBanId = []
      this.state.list.forEach(item => {
        dsCongViecPhongBanId.push(item.cong_viec_phong_ban_id)
      })

      this.props.actions
        .workCompleteDepartment({
          ds_cong_viec_phong_ban_id: dsCongViecPhongBanId,
        })
        .then(res => {
          if (res.error || res.payload.status !== 200) {
            this.setState({ isDone: false })
            return this.props.actions.commonAddToasterMessage({
              message: 'Đã có lỗi xảy ra, vui lòng thử lại!',
              intent: Intent.DANGER,
            })
          }
          this.props.actions.refreshPage(true)
          this.props.actions.getNumberWorkPage(this.props.config.refresh)
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_WORK_COMPLETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.setState({
            isEnabledAll: false,
            list: [],
            isRedirect: false,
            redirectTo: null,
            actionTo: '',
            isDone: false,
          })

          this.submitFilter(this.state.filter, this.state.page, this.state.sort)
        })
    })
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }
    this.setState({
      isRedirect: true,
      redirectTo: `/cong-viec/phong-ban/chi-tiet/chua-giao/${data.congViecPhongBanId}`,
      actionTo: 'main',
      fileId: data.fileId,
      soCongVan: data.soCongVan,
    })
  }

  handleSort(sort) {
    this.submitFilter({}, this.state.page, sort)
  }

  saveToCabinetDocument = async () => {
    let listCabinetOfDocument = []
    if (this.state.list.length === 1) {
      if (this.state.list[0].cong_van_den_id) {
        await this.props.actions
          .getListCabinetOfDocument(this.state.list[0].cong_van_den_id)
          .then(res => {
            if (res.payload && res.payload.data && res.payload.data.result) {
              listCabinetOfDocument = res.payload.data.result
            }
          })
      }

      if (this.state.list[0].cong_van_noi_bo_id) {
        await this.props.actions
          .getListCabinetOfDocument(
            this.state.list[0].cong_van_noi_bo_id,
            CONG_VAN_NOI_BO
          )
          .then(res => {
            if (res.payload && res.payload.data && res.payload.data.result) {
              listCabinetOfDocument = res.payload.data.result
            }
          })
      }
    }

    this.props.history.push({
      pathname: `/tien-ich/luu-tu-tai-lieu`,
      state: {
        dsFileCongVan: this.state.list.map(item => {
          return {
            file_id: item.fileId,
            ten_tai_lieu: item.trichYeu,
            cong_van_den_id: item.cong_van_den_id,
            cong_van_noi_bo_id: item.cong_van_noi_bo_id,
          }
        }),
        listCabinetOfDocument,
      },
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
        // Thay đổi màu xanh nếu chọn vai trò chủ trì
        styleFilter: {
          vaiTro: {
            color: name === 'vaiTro' && value === '1' ? '#195FFF' : '#000000',
          },
        },
      },
      () => {
        if (name === 'coQuanBanHanhId' || name === 'vaiTro') {
          this.submitFilter()
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  clearFilterColumn(filter) {
    this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async handleResetData(filter) {
    await this.setState({
      list: [],
      filterColumn: {},
      styleFilter: null,
    })
    this.submitFilter(filter)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  render() {
    const { styleFilter } = this.state
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              list: this.state.list,
              phongBanId: this.state.phongBanId,
              action: this.state.actionTo,
              fileId: this.state.fileId,
              soCongVan: this.state.soCongVan,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }
    return (
      <MasterLayout typeSidebar="work">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-md-4 col-xs-12 col-sm-12">
                <h3 className="text-heading-page">
                  <span className="icon-Chua_Giao icon-heading"></span>
                  <span className="text-heading">Công việc chưa giao</span>
                </h3>
              </div>
              {this.state.list && this.state.list.length > 0 && (
                <div className="col-md-8 col-xs-12 col-sm-12">
                  <div className="button-gv pull-right">
                    <Button
                      className="btn-action pull-left"
                      onClick={this.handleClickAssign}
                      disabled={
                        this.props.auth &&
                        this.props.auth.permission &&
                        this.props.auth.permission.indexOf(
                          'CONGVIEC_GIAOVIEC'
                        ) === -1
                      }
                    >
                      <span className="name-btn">Giao Việc</span>
                      <span className="pt-icon pt-icon-small-plus"></span>
                    </Button>
                    <Authorization.Element
                      permission={[Permission.CONGVIEC_PHONGBAN_HOANTAT]}
                    >
                      <Button
                        className="btn-action pull-left"
                        onClick={this.handleClickComplete}
                        loading={this.state.isDone}
                      >
                        <span className="name-btn">Hoàn tất công việc</span>
                        <span className="pt-icon pt-icon-tick"></span>
                      </Button>
                    </Authorization.Element>
                    <Button
                      className="btn-action pull-left"
                      onClick={this.saveToCabinetDocument}
                    >
                      <span className="name-btn">Lưu tủ tài liệu</span>
                      <span className="pt-icon icon-Tu_Tai_Lieu"></span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Table List */}
          <div className="page-list-container">
            <ListFilterNotAssigned
              filter={this.state.filter}
              units={this.props.units}
              onSubmit={this.submitFilter}
              workExpired={this.state.workExpired}
              workExpiringSoon={this.state.workExpiringSoon}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              handleResetData={this.handleResetData}
              removeTextSearch={this.removeTextSearch}
            />
            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.departmentWorks.list.total /
                      Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                classNames={'pt-table fix-table-before'}
                headers={headers.listDepartmentWorkNotAssigned}
                itemShape={ListItemNotAssigned}
                data={this.props.departmentWorks.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                page={this.state.page ? this.state.page : 1}
                isEnabledAll={this.state.isEnabledAll}
                list={this.state.list}
                onCheckAll={this.handleCheckAll}
                onCheck={this.handleCheck}
                mainDepartmentId={this.state.phongBanId}
                onClickDetail={this.handleClickDetail}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                auth={this.props.auth}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                coQuanBanHanhId={this.props.units}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                pageCount={Math.ceil(
                  this.props.departmentWorks.list.total / Types.PAGINATION_LIMIT
                )}
                isType="CVPB"
                isCVPBCG={true}
                styleFilter={styleFilter}
              />
              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <ListFooterNotAssigned
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.departmentWorks.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    isType="CVPB"
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

DepartmentWorkNotAssigned.propTypes = {
  auth: PropTypes.object.isRequired,
  departmentWorkTotals: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  config: state.common.config,
  auth: state.auth,
  departmentWorks: {
    ...state.departmentWorks,
    list: {
      ...state.departmentWorks.list,
      items: state.departmentWorks.list.items.map(
        id => state.entities.departmentWorks[id]
      ),
    },
  },
  units: state.common.relativeUnits,
  departmentWorkTotals: state.common.departmentWorkTotals,
  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentWorkNotAssigned)
