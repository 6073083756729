import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'

class DetailStatus extends Component {
  static propTypes = {
    item: PropTypes.object,
  }

  state = {
    data: [],
  }

  componentDidMount() {
    const { requestComments } = this.props
    let data = []
    // LDPB duyet
    requestComments.lanhDaoPhongBanLap &&
      data.push({
        maPhongBan: requestComments.lanhDaoPhongBanLap.phongBanId
          ? requestComments.lanhDaoPhongBanLap.phongBanId
          : null,
        tenNhanVien: requestComments.lanhDaoPhongBanLap.tenNhanVien,
        trangThai: requestComments.lanhDaoPhongBanLap.trangThai,
        yKien: requestComments.lanhDaoPhongBanLap.yKien,
        thoiGianDuyet: requestComments.lanhDaoPhongBanLap.thoiGianDuyet
          ? moment(requestComments.lanhDaoPhongBanLap.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })

    // show thư ký lddv neu thu ky chua duyet
    requestComments.dsThuKy &&
      requestComments.dsThuKy.length > 0 &&
      data.push({
        maPhongBan: '',
        tenNhanVien: 'Thư ký lãnh đạo đơn vị',
        trangThai: 0,
        yKien: '',
        thoiGianDuyet: '',
      })

    // thư ký duyet
    requestComments.thuKy &&
      data.push({
        maPhongBan: requestComments.thuKy.phongBanId
          ? requestComments.thuKy.phongBanId
          : null,
        tenNhanVien: requestComments.thuKy.tenNhanVien,
        trangThai: requestComments.thuKy.trangThai,
        yKien: requestComments.thuKy.yKien,
        thoiGianDuyet: requestComments.thuKy.thoiGianDuyet
          ? moment(requestComments.thuKy.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })

    // LDDV duyet
    requestComments.lanhDaoDonVi &&
      data.push({
        maPhongBan: requestComments.lanhDaoDonVi.phongBanId
          ? requestComments.lanhDaoDonVi.phongBanId
          : null,
        tenNhanVien: requestComments.lanhDaoDonVi.tenNhanVien,
        trangThai: requestComments.lanhDaoDonVi.trangThai,
        yKien: requestComments.lanhDaoDonVi.yKien,
        thoiGianDuyet: requestComments.lanhDaoDonVi.thoiGianDuyet
          ? moment(requestComments.lanhDaoDonVi.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    this.setState({ data })
  }

  render() {
    if (
      !this.props.requestComments ||
      (!this.props.requestComments.lanhDaoPhongBanLap &&
        !this.props.requestComments.lanhDaoDonVi)
    ) {
      return null
    }

    return (
      <div className="detail-history-signnumber history-edit-document">
        <div className="heading">
          <label className="name-heading">
            <span className="text-content">Trạng thái góp ý</span>
          </label>
        </div>
        <div className="detail-status-box">
          <div className="table-status table-departments table-scroll table-scroll-hidden">
            <div className="heading-table">
              <div className="cell-table colum-stt">
                <p className="name-heading-table">STT</p>
              </div>
              <div className="cell-table column-department">
                <p className="name-heading-table">Phòng ban/Cá nhân</p>
              </div>
              <div className="cell-table colum-status">
                <p className="name-heading-table">Trạng Thái</p>
              </div>
              <div className="cell-table colum-status">
                <p className="name-heading-table">Thời gian ký duyệt</p>
              </div>
            </div>

            {this.state.data &&
              this.state.data.map((value, i) => (
                <div className="row-table" key={i}>
                  <div className="cell-table">
                    <p className="content">
                      {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                    </p>
                  </div>
                  <div className="cell-table column-department">
                    {value.maPhongBan && (
                      <p className="content department-name">
                        {value.maPhongBan}
                      </p>
                    )}
                    {value.tenNhanVien && (
                      <p className="content">{value.tenNhanVien}</p>
                    )}
                    {value.yKien && (
                      <div>
                        <span className="left-icon style-icon icon-ThongTinGopY color-gray"></span>
                        <span className="detail textarea-content ml5 department-comment">
                          {value.yKien}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={classnames('cell-table', {
                      'cell-status-warning':
                        this.props.isShowIconWarningForLeader,
                    })}
                  >
                    {value.trangThai === 0 && (
                      <span className="status-job no-process">Chưa đồng ý</span>
                    )}
                    {value.trangThai === 1 && (
                      <span className="status-job no-approval">
                        Không đồng ý
                      </span>
                    )}
                    {value.trangThai === 2 && (
                      <span className="status-job approved">Đồng ý</span>
                    )}
                  </div>
                  <div className="cell-table">
                    {value.thoiGianDuyet ? (
                      <p className="content">{value.thoiGianDuyet}</p>
                    ) : (
                      <p className="content">&nbsp;</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default DetailStatus
