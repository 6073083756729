import React from 'react'

class SubGroupItem extends React.Component {
  render() {
    return (
      <div className="collapse-block subcollapse-block">
        <div className="collapse-heading-content">
          <h4 className="subtable-title">{this.props.name}</h4>
        </div>
        <div className="subcollapse-panel-content">
          <div className="table-display">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default SubGroupItem
