import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import FileDownload from 'file-saver'
import { Button, Intent } from '@blueprintjs/core'

import { MasterLayout } from '../../components/layout'
import { GridView } from '../../components/common'
import { ListFilter, ListItem } from '../../components/Utilities/StatisticUser'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import { headers } from '../../constants/Table'
import HeadingPanel from 'components/common/HeadingPanel'

class ListStatisticUserPage extends Component {
  constructor(props) {
    super(props)

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)

    const donViId =
      this.props.match && this.props.match.params && this.props.match.params.id
    this.state = {
      isShowMessengStatistics: true,
      isLoading: false,
      isEmpty: false,
      isError: false,
      isExporting: false,
      filter: {
        startDate: '',
        endDate: moment().format('YYYY-MM-DD'),
      },
      page: 1,
      donViId,
    }
  }

  async componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      'ListStatisticUsers',
      null,
      'thong-ke-nhan-vien'
    )

    if (preData) {
      state.filter = preData.filter
      await this.setState(state)
    }

    if (this.props.location && this.props.location.state) {
      let state = this.state
      const { isCheck } = this.props.location.state
      if (isCheck) {
        this.submitFilter(state.filter, state.page)
      }
    }

    let isSubmitStatisticCabinet = Tool.Utils.getData('isSubmitStatisticUser')
    if (isSubmitStatisticCabinet) {
      Tool.Utils.saveData('isSubmitStatisticUser', false)
      this.props.history.replace({
        state: {
          isCheck: true,
        },
      })
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match !== this.props.match
    ) {
      this.setState({ donViId: nextProps.match.params.id })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.donViId !== this.state.donViId) {
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  submitFilter(e = {}) {
    const filter = Object.assign({}, this.state.filter, e)
    const filterSubmit = {
      ...filter,
      don_vi_id: this.state.donViId,
    }
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(
          'ListStatisticUsers',
          { filter },
          'thong-ke-nhan-vien'
        )
        this.props.actions
          .getStatisticUsers(filterSubmit, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }
            if (res.error || !res.payload || res.payload.status !== 200) {
              isError = false
            }
            this.setState({ isEmpty, isError })
          })
          .finally(() => {
            this.props.actions.refreshStatistic(true)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleExport() {
    let { filter, donViId } = this.state
    if (donViId) {
      filter.don_vi_id = donViId
    }
    this.setState({ isExporting: true }, () => {
      this.props.actions
        .exportStatisticUsers(filter)
        .then(res => {
          if (res.error || !res.payload || !res.payload.data) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData('ListStatisticUsers')
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-nhan-vien-${
              preData.filter.startDate
                ? moment(preData.filter.startDate).format('L')
                : ''
            }-${moment(preData.filter.endDate).format('L')}.xls`
          )
        })
        .finally(() => {
          this.setState({ isExporting: false })
        })
    })
  }

  handleClickGoBack = async () => {
    await this.props.actions.refreshStatistic(false)
    Tool.Utils.saveData('isSubmitStatisticUser', true)
    this.props.history && this.props.history.goBack()
  }

  render() {
    const {
      isShowMessengStatistics,
      isLoading,
      isEmpty,
      isError,
      isExporting,
      filter,
      page,
    } = this.state
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={this.state.donViId ? this.handleClickGoBack : null}
            leftTitle="Thống kê nhân viên"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={isExporting}
                disabled={isShowMessengStatistics || isLoading || isEmpty}
              />
            }
          />
          {/* Filter */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              filter={filter}
              actions={this.props.actions}
              isLoading={isLoading}
              isForChildUnit={typeof this.state.donViId === 'string'}
            />
            {/* Table List */}
            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={page}
                headers={headers.statisticUser}
                itemShape={ListItem}
                data={this.props.statisticUsers.units.list}
                isEmpty={isEmpty || this.props.statisticUsers.units.total === 0}
                isLoading={isLoading}
                isError={isError}
                isShowMessengStatistics={isShowMessengStatistics}
                auth={this.props.auth}
                filter={filter}
                history={this.props.history}
                actions={this.props.actions}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  statisticUsers: state.statisticUsers,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.readOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStatisticUserPage)
