import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Pagination from 'components/Pagination'

class ListFooter extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
  }

  render() {
    const { current, total, onChange } = this.props

    if ((total || 0) < 2) {
      return null
    }

    return (
      <div className="footer-list p-0">
        <Pagination current={current} total={total} onChange={onChange} />
      </div>
    )
  }
}

export default ListFooter
