import React from 'react'
import { MasterLayout } from '../../components/layout'
import { FormDepartmentCategory } from '../../components/System'
import { HeadingPage } from '../../components/common'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'

const SUA_PHONG_BAN = 'Sửa phòng ban'
const THEM_MOI_PHONG_BAN = 'Thêm mới phòng ban'
class AddDepartmentCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    phongBanId: this.props.match.params.id,
    departments: null,
    mainUnitId: null,
    departmentsSelect: [],
    unitSelect: [],
  }

  componentDidMount() {
    let donViId = Utils.getData('DepartmentCategoryPage', {})
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (this.props.match.params && this.props.match.params.id) {
      this.props.actions.getCommonFetchDeparthmentItems(this.state.phongBanId)
    }

    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    if (permission) {
      this.props.actions.getCategoryUnitMultiLevel()
    } else {
      this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
    }

    this.props.actions.getCategoryDepartmentMultiLevel(donViId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.departments.item !== this.props.departments.item) {
      this.setState({ departments: nextProps.departments.item })
    }

    if (
      nextProps.departmentsSelect.items !== this.props.departmentsSelect.items
    ) {
      this.setState({ departmentsSelect: nextProps.departmentsSelect.items })
    }

    if (nextProps.unit.items !== this.props.unit.items) {
      this.setState({ unitSelect: nextProps.unit.items })
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    return this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={
                this.state.departments ? SUA_PHONG_BAN : THEM_MOI_PHONG_BAN
              }
              iconPage="icon-departments"
            />
            <FormDepartmentCategory
              actions={this.props.actions}
              departments={this.state.departments}
              permission={this.props.auth}
              unit={this.state.unitSelect}
              departmentSelect={this.state.departmentsSelect}
              onSubmit={this.handleSubmit}
              match={this.props.match}
              history={this.props.history}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  departments: {
    ...state.common,
    item: state.common.departmentItem,
  },

  departmentsSelect: {
    ...state.category,
    items: state.category.categoryDepartmentMultiLevel,
  },

  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDepartmentCategoryPage)
