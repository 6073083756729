import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const TOTAL_NAME = 'Tổng cộng'

class ListItemPersonalInChildUnit extends Component {
  constructor(props) {
    super(props)

    this.convertNumber = this.convertNumber.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (_.isNumber(number)) {
      return number
    }
    return 0
  }

  render() {
    const { item, number } = this.props
    if (!item) {
      return
    }
    const isTotalRaw =
      item.tenNhanVien === null && item.tenPhongBan === TOTAL_NAME

    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td className="gerenal-list-utilities">{item.tenPhongBan}</td>
        <td className="gerenal-list-utilities">{item.tenNhanVien}</td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKySimDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKyTokenDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKyTayDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKySoDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKySimNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKyTokenNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKyTayNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongKySoNoiBo)}
        </td>
      </tr>
    )
  }
}

export default ListItemPersonalInChildUnit
