import React, { memo } from 'react'
import csx from 'classnames'

import { Card, Avatar } from '../index'

const InfoCard = ({
  needBaseUrl = false,
  src,
  name = '',
  department,
  unit,
  action = '',
  className,
  colorName = '',
  bgSize = 'cover',
  notRadius = false,
}) => {
  return (
    <Card className={csx('cpc-user-card', className)}>
      <Avatar
        src={src}
        className="mr10"
        needBaseUrl={needBaseUrl}
        bgSize={bgSize}
        notRadius={notRadius}
      />
      <div className="flex-auto">
        <div className="cpc-user-title">
          <span
            className="cpc-user-title mr5 font-size-13"
            style={{ color: colorName }}
          >
            {name}
          </span>
          <span className="cpc-user-action">{action}</span>
        </div>
        <div>
          <span className="cpc-user-sub">
            {department}
            {department && unit && ' - '}
            {unit}
          </span>
        </div>
      </div>
    </Card>
  )
}

export default memo(InfoCard)
