import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { KHONG_CO_FILE_TRONG_HE_THONG } from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
// cover test ở môi trường staging,
const DomainFileOld =
  'https://fileeoffice.cpc.vn/api/oldfile/downloadfile?filename='

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    const fileUrl = `${DomainFileOld}${this.props.item.url}`
    if (this.props.item.url) {
      return window.open(`${fileUrl}`, '_blank')
    } else {
      this.props.actions.commonAddToasterMessage({
        message: KHONG_CO_FILE_TRONG_HE_THONG,
        intent: Intent.DANGER,
      })
    }
  }

  render() {
    return (
      <tr>
        <td onClick={this.handleClick.bind(this)}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick.bind(this)}>
          {this.props.item.ngayCongVan
            ? moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
            : null}
        </td>
        <td onClick={this.handleClick.bind(this)}>
          {this.props.item.soCongVan}
        </td>
        <td onClick={this.handleClick.bind(this)}>
          {this.props.item.ngayDen
            ? moment(this.props.item.ngayDen).format('DD/MM/YYYY')
            : null}
        </td>
        <td onClick={this.handleClick.bind(this)}>{this.props.item.soDen}</td>
        <td onClick={this.handleClick.bind(this)} className="textarea-content">
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClick.bind(this)}>{this.props.item.noiGui}</td>
        <td></td>
      </tr>
    )
  }
}

ListItem.propTypes = {
  auth: PropTypes.object,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
}

export default ListItem
