import React from 'react'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { Scrollbars } from 'react-custom-scrollbars'
import * as Types from '../../constants/Api'
import _ from 'lodash'
import { LoadingItem, NotFound } from '../../components/common/'
import * as MESSAGE from '../../constants/MessageForm'
import { Checkbox } from '@blueprintjs/core'

const dealines = [
  { value: 1, key: 1 },
  { value: 2, key: 2 },
  { value: 3, key: 3 },
  { value: 7, key: 7 },
  { value: 0, key: 'Không chọn thời hạn' },
]

const DragHandle = SortableHandle(() => (
  <span className="icon-move-drap icon-drag"></span>
))

const SortableItem = SortableElement(
  ({
    onChangeSelectBox,
    onRemoveStep,
    onCheckMember,
    onCheckAllMenber,
    value,
    stepObject = {},
    membersProject = [],
    signWays = [],
    errorMembers = '',
    isLoading = false,
    stepIsSigning = null,
    isUpdate = false,
    statusSign = null,
    dealines = [],
    isChangeFileUpdate = false,
    onChangeText,
  }) => {
    const step = Number(value) + 1
    return (
      <div className="form-block form-block-2" id={`steps-${value}`}>
        <div className="bg-grey">
          <div className="form-subblock">
            <div className="form-row step-block-heading">
              <div className="form-cols-content">
                <div className="form-cols form-col4 heading-step">
                  <div className="form-group-item pt-intent-danger">
                    <label className="pt-label" htmlFor="duAn">
                      {/* Bước đã ký, đang ký sẽ không được di chuyển */}
                      {((stepIsSigning !== null && step > stepIsSigning) ||
                        (isUpdate === true && isChangeFileUpdate === true) ||
                        (stepIsSigning === null && isUpdate === false)) && (
                        <DragHandle />
                      )}
                      <span className="text">Bước {step}</span>
                    </label>
                  </div>
                </div>
                <div className="form-cols form-col4">
                  <div className="form-group-item pt-intent-danger">
                    <label className="pt-label" htmlFor="duAn">
                      Hình thức ký{' '}
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className="pt-input"
                          name={`hinhThuc-${value}`}
                          value={stepObject.hinhThuc}
                          onChange={onChangeSelectBox.bind(this)}
                          disabled={
                            stepIsSigning !== null &&
                            step <= stepIsSigning &&
                            statusSign !== null &&
                            statusSign !== Types.NOT_YET_AGREE &&
                            isChangeFileUpdate === false
                          }
                        >
                          {signWays &&
                            signWays.map((item, k) => (
                              <option key={k} value={item.hinhThuc}>
                                {item.tenHinhThuc}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {stepObject &&
                      stepObject.hinhThuc === Types.HTK_KY_NHAY_CO_CHU_KY && (
                        <div className="pt-form-content position-relative">
                          <input
                            name={`trangKy-${value}`}
                            value={
                              stepObject.trangKy !== null
                                ? stepObject.trangKy
                                : ''
                            }
                            className="pt-input"
                            placeholder="Trang ký nháy"
                            onChange={onChangeText.bind(this, 'input', value)}
                          />
                          {stepObject && stepObject.trangKy !== null && (
                            <i
                              className="icon-close darker-color position-absolute"
                              onClick={onChangeText.bind(this, 'remove', value)}
                            />
                          )}
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-cols form-col4">
                  <div className="form-group-item pt-intent-danger">
                    <label className="pt-label" htmlFor="duAn">
                      Thời hạn (ngày)
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className="pt-input"
                          name={`thoiHan-${value}`}
                          value={stepObject.thoiHan}
                          onChange={onChangeSelectBox.bind(this)}
                          disabled={
                            stepIsSigning !== null &&
                            step <= stepIsSigning &&
                            statusSign !== null &&
                            statusSign !== Types.NOT_YET_AGREE &&
                            isChangeFileUpdate === false
                          }
                        >
                          {dealines &&
                            dealines.map((item, k) => (
                              <option key={k} value={item.value}>
                                {item.key}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="selectbox-group pt-intent-danger">
              <div className="heading-group-select">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-10 form-item">
                    <label className="pt-label" htmlFor="duAn">
                      {stepObject &&
                      (stepObject.hinhThuc === Types.HTK_KY_CO_DAU ||
                        stepObject.hinhThuc === Types.HTK_KY_KHONG_DAU ||
                        stepObject.hinhThuc === Types.HTK_KY_THANH_VIEN)
                        ? `Thành viên (trong ngoặc là trường ký)`
                        : 'Thành viên'}
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-2 form-item">
                    <Checkbox
                      className="pt-inline float-right"
                      name={`thanhVien`}
                      member={''}
                      onChange={onCheckAllMenber.bind(
                        this,
                        value,
                        membersProject
                      )}
                      checked={
                        (membersProject && membersProject.length) ===
                        (stepObject &&
                          stepObject.dsThanhVien &&
                          stepObject.dsThanhVien.length)
                      }
                    >
                      <span className="name_checkbox">Chọn tất cả</span>
                    </Checkbox>
                  </div>
                </div>
              </div>
              <div className="container-checkbox">
                <div className="row checkboxgroup-container">
                  {membersProject &&
                    membersProject.map((item, k) => {
                      let isChecked = false
                      let isSigned = false
                      if (
                        stepObject &&
                        stepObject.dsThanhVien &&
                        _.findIndex(
                          stepObject.dsThanhVien,
                          s => s.chucDanhId === item.chucDanhId
                        ) > -1
                      ) {
                        isChecked = true
                      }
                      if (
                        stepObject &&
                        stepObject.dsThanhVien &&
                        _.findIndex(
                          stepObject.dsThanhVien,
                          s =>
                            s.chucDanhId === item.chucDanhId &&
                            s.trangThai !== undefined &&
                            s.trangThai !== Types.NOT_YET_AGREE
                        ) > -1
                      ) {
                        isSigned = true
                      }
                      return (
                        <div key={k} className="col-xs-6 col-sm-6 col-md-3">
                          <div className="item-checkbox disabled-checkbox">
                            <Checkbox
                              className="pt-inline"
                              name={`thanhVien-${value}-${k}`}
                              member={item}
                              onChange={onCheckMember.bind(this, value, item)}
                              disabled={
                                stepIsSigning !== null &&
                                (step < stepIsSigning ||
                                  (step === stepIsSigning && isSigned)) &&
                                isChangeFileUpdate === false
                              }
                              checked={isChecked}
                            >
                              <span className="name_checkbox">
                                {item.tenNhanVien}
                                <span className="color-blue">
                                  {stepObject &&
                                    (stepObject.hinhThuc ===
                                      Types.HTK_KY_CO_DAU ||
                                      stepObject.hinhThuc ===
                                        Types.HTK_KY_KHONG_DAU ||
                                      stepObject.hinhThuc ===
                                        Types.HTK_KY_THANH_VIEN) &&
                                    ` (${item.username})`}
                                </span>
                              </span>
                              <p className="name_checkbox name_checkbox-unit-department">
                                {item.maPhongBan} - {item.tenVietTat}
                              </p>
                            </Checkbox>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
            {errorMembers && errorMembers.length > 0 && (
              <div className="form-row">
                <div className="form-cols form-col-full form-group-item pt-form-group pt-intent-danger">
                  <div className="pt-form-helper-text">{errorMembers}</div>
                </div>
              </div>
            )}
          </div>
          {value > 0 &&
            !isLoading &&
            ((stepIsSigning !== null && step > stepIsSigning) ||
              (isUpdate === true && isChangeFileUpdate === true) ||
              (stepIsSigning === null && isUpdate === false)) && (
              <span
                className="pt-icon-close icon-close close-block-step"
                onClick={onRemoveStep.bind(this, value)}
              />
            )}
        </div>
      </div>
    )
  }
)

const SortableList = SortableContainer(
  ({
    onChangeSelectBox,
    onRemoveStep,
    onCheckMember,
    onCheckAllMenber,
    steps,
    membersProject,
    signWays,
    errorMembers = [],
    isLoading = false,
    stepIsSigning = null,
    isUpdate = false,
    statusSign = null,
    dealines = [],
    isChangeFileUpdate = false,
    onChangeText,
  }) => {
    return (
      <div className="sort-block-step" id="membersList">
        {steps.map((item, k) => (
          <SortableItem
            stt={k + 1}
            key={k}
            index={k}
            value={k}
            stepObject={item}
            membersProject={membersProject}
            signWays={signWays}
            onChangeSelectBox={onChangeSelectBox}
            onRemoveStep={onRemoveStep}
            errorMembers={errorMembers[k]}
            isLoading={isLoading}
            stepIsSigning={stepIsSigning}
            isUpdate={isUpdate}
            statusSign={statusSign}
            onCheckMember={onCheckMember}
            onCheckAllMenber={onCheckAllMenber}
            dealines={dealines}
            isChangeFileUpdate={isChangeFileUpdate}
            onChangeText={onChangeText}
          />
        ))}
      </div>
    )
  }
)

class DragProjectDigitalSignature extends React.Component {
  constructor(props) {
    super(props)
    this.handleMoveStep = this.handleMoveStep.bind(this)
    this.handleAddStep = this.handleAddStep.bind(this)
    this.handleRemoveStep = this.handleRemoveStep.bind(this)
    this.getSignFieldBySignWay = this.getSignFieldBySignWay.bind(this)
    this.handleScrollbarScrollToBottom =
      this.handleScrollbarScrollToBottom.bind(this)
    this.handleCheckMember = this.handleCheckMember.bind(this)
    this.handleChangSelect = this.handleChangSelect.bind(this)
    this.handleCheckAllMember = this.handleCheckAllMember.bind(this)
    this.handleChangeText = this.handleChangeText.bind(this)
  }

  state = {
    // Thành viên
    steps: [],
    // Thêm bước lần đầu:
    isAddStepFirstTime: true,
    isLoading: true,
  }

  handleScrollbarScrollToBottom() {
    this.refs.scrollbarsStepsList &&
      this.refs.scrollbarsStepsList.scrollToBottom()
  }

  // Di chuyển bước
  handleMoveStep = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }
    if (
      this.props.isUpdate &&
      this.props.stepIsSigning &&
      this.props.isChangeFileUpdate === false &&
      (oldIndex < this.props.stepIsSigning ||
        newIndex < this.props.stepIsSigning)
    ) {
      return this.props.onSubmit({}, false, MESSAGE.NOT_ALLOW_CHANGE_STEP)
    }
    this.setState(
      {
        steps: arrayMove(this.state.steps, oldIndex, newIndex),
      },
      () => {
        this.props.onPassData && this.props.onPassData(this.state.steps, true)
      }
    )
  }

  // Sinh truongKy theo hinhThucKy
  getSignFieldBySignWay = (signWay, username = '') => {
    switch (Number(signWay)) {
      case Types.HTK_KY_KHONG_DAU:
      case Types.HTK_KY_CO_DAU:
      case Types.HTK_KY_THANH_VIEN:
        return username

      case Types.HTK_CAP_SO:
        return 'socv, ngaycv'

      default:
        return ''
    }
  }

  // Thêm bước
  handleAddStep = (isChangeDataProjects = true) => {
    let { steps } = this.state
    if (!steps) {
      return
    }

    let hinhThuc = null
    let thoiHan = null
    let trangKy = null

    if (Types.SIGN_WAYS && Types.SIGN_WAYS[0]) {
      hinhThuc = Types.SIGN_WAYS[0].hinhThuc
    }

    if (dealines && dealines[0]) {
      thoiHan = dealines[0].value
    }

    if (hinhThuc === null || thoiHan === null) {
      return
    }

    steps.push({
      buoc: steps.length + 1,
      hinhThuc,
      thoiHan,
      dsThanhVien: [],
      trangKy,
    })

    const isAddStepFirstTime = this.state.isAddStepFirstTime
    this.setState({ steps, isAddStepFirstTime: false }, () => {
      this.props.onPassData &&
        this.props.onPassData(this.state.steps, isChangeDataProjects)
      if (!isAddStepFirstTime) {
        window.scrollTo(0, document.body.scrollHeight)
        this.handleScrollbarScrollToBottom()
      }
    })
  }

  // Xoá bước
  handleRemoveStep = index => {
    let { steps } = this.state
    if (_.isNumber(index) === false || !steps || !steps[index]) {
      return
    }

    steps.splice(index, 1)
    this.setState({ steps }, () => {
      this.props.onPassData && this.props.onPassData(this.state.steps, true)
    })
  }

  // Check member
  handleCheckMember = (indexStep, member, e) => {
    let { steps } = this.state
    indexStep = parseInt(indexStep, 10)

    if (
      _.isNumber(indexStep) === false ||
      !steps ||
      !steps[indexStep] ||
      !member
    ) {
      return
    }

    let dsThanhVien = steps[indexStep].dsThanhVien || []
    if (e.target.checked) {
      const username = member.username
      const tenNhanVien = member.tenNhanVien
      let truongKy = ''
      let hinhThuc = steps[indexStep].hinhThuc
      if (username && hinhThuc) {
        truongKy = this.getSignFieldBySignWay(
          hinhThuc,
          username,
          tenNhanVien
        ).toLowerCase()
      }

      dsThanhVien.push({
        chucDanhId: member.chucDanhId,
        thanhVienId: member.thanhVienId,
        nhanVienId: member.nhanVienId,
        truongKy,
        username,
        tenNhanVien,
      })
    } else {
      let indexMember = _.findIndex(
        dsThanhVien,
        s => s.chucDanhId === member.chucDanhId
      )
      dsThanhVien.splice(indexMember, 1)
    }

    steps[indexStep].dsThanhVien = dsThanhVien
    this.setState({ steps }, () => {
      this.props.onPassData && this.props.onPassData(this.state.steps, true)
    })
  }

  //checkAllMember
  handleCheckAllMember(indexStep, member = null, e) {
    let { steps } = this.state
    indexStep = parseInt(indexStep, 10)

    if (
      _.isNumber(indexStep) === false ||
      !steps ||
      !steps[indexStep] ||
      !member
    ) {
      return
    }

    let dsThanhVien = []
    if (e.target.checked) {
      let truongKy = ''
      let hinhThuc = steps[indexStep].hinhThuc
      member.forEach(item => {
        const username = item.username
        if (username && hinhThuc) {
          truongKy = this.getSignFieldBySignWay(
            hinhThuc,
            username
          ).toLowerCase()
        }
        dsThanhVien = [
          ...dsThanhVien,
          {
            chucDanhId: item.chucDanhId,
            thanhVienId: item.thanhVienId,
            nhanVienId: item.nhanVienId,
            truongKy,
            username,
          },
        ]
      })
    }

    steps[indexStep].dsThanhVien = dsThanhVien
    this.setState({ steps }, () => {
      this.props.onPassData && this.props.onPassData(this.state.steps, true)
    })
  }

  handleChangeText(type, index, e) {
    if (type === 'input') {
      let steps = this.state.steps
      let [fieldName, indexStep] = e.target.name.split('-')
      let value = e.target.value
      indexStep = parseInt(indexStep, 10)

      if (value === '') {
        value = null
      }

      if (
        _.isNumber(indexStep) === false ||
        !steps ||
        !steps[indexStep] ||
        isNaN(value)
      ) {
        return
      }

      steps[indexStep][fieldName] = value ? Number(value) : null

      this.setState({ steps }, () => {
        this.props.onPassData && this.props.onPassData(this.state.steps, true)
      })
    } else {
      let steps = this.state.steps
      let indexStep = parseInt(index, 10)

      if (_.isNumber(indexStep) === false || !steps || !steps[indexStep]) {
        return
      }

      steps[indexStep]['trangKy'] = null

      this.setState({ steps }, () => {
        this.props.onPassData && this.props.onPassData(this.state.steps, true)
      })
    }
  }

  // Thay đổi hình thức, thời hạn
  handleChangSelect = e => {
    let steps = this.state.steps
    let [fieldName, indexStep] = e.target.name.split('-')
    const value = parseInt(e.target.value, 10)
    indexStep = parseInt(indexStep, 10)

    if (_.isNumber(indexStep) === false || !steps || !steps[indexStep]) {
      return
    }

    steps[indexStep][fieldName] = value
    let dsThanhVien = steps[indexStep].dsThanhVien || []
    dsThanhVien.forEach(item => {
      item[fieldName] = value
      if (fieldName === 'hinhThuc') {
        item.truongKy = this.getSignFieldBySignWay(
          steps[indexStep].hinhThuc,
          item.username,
          item.tenNhanVien
        )
      }
    })
    steps[indexStep].dsThanhVien = dsThanhVien

    this.setState({ steps }, () => {
      this.props.onPassData && this.props.onPassData(this.state.steps, true)
    })
  }

  async componentDidMount() {
    if (
      this.props.membersProject &&
      this.props.membersProject.length > 0 &&
      !this.props.isUpdate
    ) {
      await this.handleAddStep(false)
      await this.setState({ isLoading: false })
    } else {
      this.setState({ isLoading: false })
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (
      nextProps.membersProject &&
      _.isEqual(nextProps.membersProject, this.props.membersProject) ===
        false &&
      nextProps.membersProject.length > 0 &&
      !nextProps.stepsFromEdit &&
      !nextProps.isUpdate
    ) {
      this.setState({ steps: [] }, () => {
        this.handleAddStep(false)
      })
    }

    if (
      nextProps.isUpdate === true &&
      nextProps.stepsFromEdit &&
      _.isEqual(nextProps.stepsFromEdit, this.props.stepsFromEdit) === false
    ) {
      await this.setState({ steps: nextProps.stepsFromEdit }, () => {
        this.props.onPassData && this.props.onPassData(this.state.steps, true)
      })
    }
  }

  render() {
    return (
      <div className="drag-signature-block">
        <div className="title-progress-sign">
          <div className="form-row">
            <div className="form-cols form-col-full">
              <div className="form-group-item pt-form-group">
                <div className="box-border-title-sign">
                  <label className="pt-label">Quy trình ký duyệt</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.props.isLoadingChangeProject === true ||
          this.props.isLoadingData === true ||
          this.state.isLoading === true) && (
          <div className="loading-container">
            <LoadingItem />
            <LoadingItem />
          </div>
        )}
        {this.props.isLoadingChangeProject === false &&
          this.props.isLoadingData === false &&
          this.state.isLoading === false &&
          (!this.props.membersProject ||
            this.props.membersProject.length === 0) && (
            <NotFound message="Hiện tại không có dự án" />
          )}
        {this.props.isLoadingChangeProject === false &&
          this.props.isLoadingData === false &&
          this.state.isLoading === false &&
          this.props.membersProject &&
          this.props.membersProject.length > 0 && (
            <Scrollbars
              ref="scrollbarsStepsList"
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={750}
            >
              <SortableList
                helperClass="SortableHelper-step"
                onSortEnd={this.handleMoveStep}
                lockAxis="y"
                useDragHandle={true}
                onChangeSelectBox={this.handleChangSelect}
                onRemoveStep={this.handleRemoveStep}
                steps={this.state.steps}
                membersProject={this.props.membersProject}
                signWays={Types.SIGN_WAYS}
                errorMembers={this.props.errorMembers}
                isLoading={this.props.isLoading}
                stepIsSigning={this.props.stepIsSigning}
                isUpdate={this.props.isUpdate}
                statusSign={this.props.statusSign}
                onCheckMember={this.handleCheckMember}
                onCheckAllMenber={this.handleCheckAllMember}
                dealines={dealines}
                isChangeFileUpdate={this.props.isChangeFileUpdate}
                onChangeText={this.handleChangeText}
              />
            </Scrollbars>
          )}
        {this.props.isLoadingChangeProject === false &&
          this.props.isLoadingData === false &&
          this.props.membersProject &&
          this.props.membersProject.length > 0 && (
            <div className="form-block form-row">
              <div className="form-cols form-col-full">
                <div className="form-group-item button-add-step-sign-number">
                  <button
                    type="button"
                    className="pt-button btn-add-more"
                    onClick={this.handleAddStep.bind(this, true)}
                    disabled={this.props.isLoading}
                  >
                    <span className="text-content">Thêm bước</span>
                    <span className="icon-bc icon-Plus" />
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    )
  }
}

export default DragProjectDigitalSignature
