import React, { useEffect, useState } from 'react'
import {
  LineChart as LineChartComp,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'

const LineChart = ({ dataSource }) => {
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    setChartData(
      dataSource?.map(elm => ({
        ...elm,
        name: `Tuần ${elm?.tuan}`,
      }))
    )
  }, [dataSource])

  const CustomizedYAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={15}
          width={50}
          textAnchor="middle"
          fill="rgba(32,32,32,0.5)"
          strokeWidth="0"
          stroke="transparent"
          fontSize={12}
        >
          <tspan textAnchor="middle" x="0">
            {payload?.value}
          </tspan>
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
          }}
        >
          <p style={{ margin: 0, fontSize: '13px' }}>{label}</p>
          {payload?.map((elm, index) => {
            let title = 'Hoàn thành'
            if (elm.dataKey === 'treHan') {
              title = 'Trễ hạn'
            }

            return (
              <p key={index} style={{ margin: 0, fontSize: '13px' }}>
                {title}: {elm?.value}
              </p>
            )
          })}
        </div>
      )
    }

    return null
  }

  const CustomLegend = props => {
    const payload = props?.payload
    return (
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <span
              className="legend-line-chart"
              style={{
                '--line-chart-color': entry.color,
              }}
            ></span>
            <span>{entry.value === 'treHan' ? 'Trễ hạn' : 'Hoàn thành'}</span>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={155}>
      <LineChartComp
        width={380}
        height={155}
        data={chartData}
        margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          axisLine={true}
          height={10}
          tickSize={0}
          tick={<CustomizedYAxisTick />}
        />
        <YAxis
          padding={{ top: 20 }}
          tick={{
            fill: 'rgba(32,32,32,0.5)',
            strokeWidth: 0,
            stroke: 'transparent',
            fontSize: 12,
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          align={'center'}
          verticalAlign={'bottom'}
          height={30}
          content={CustomLegend}
        />
        <Line type="monotone" dataKey="tong" stroke="rgba(32, 32, 32, 0.5)" />
        <Line type="monotone" dataKey="treHan" stroke="rgba(222, 48, 35, 1)" />
      </LineChartComp>
    </ResponsiveContainer>
  )
}

LineChart.defaultProps = {
  dataSource: [],
}

LineChart.propTypes = {
  dataSource: PropTypes.array,
}

export default LineChart
