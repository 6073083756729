import React from 'react'
import { Checkbox, Radio } from '@blueprintjs/core'

class InputFormBiddingSignNumber extends React.Component {
  render() {
    return (
      <div>
        <div className="addSignNumber">
          <div className="form-container">
            <div className="form-padding-box">
              <div className="pt-form-group pt-intent-danger">
                <div className="row group-form-item">
                  <div className="col-md-6 col-xs-6 col-sm-6 form-item">
                    <label className="pt-label">Số công văn</label>
                    <div className="pt-form-content">
                      <input
                        name="soCongVan"
                        className="pt-input"
                        placeholder="Nhập số công văn"
                        type="text"
                        dir="auto"
                        autoFocus={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-6 col-sm-6 form-item">
                    <label className="pt-label">Tên thành phố</label>
                    <div className="pt-form-content">
                      <input
                        name="soCongVan"
                        className="pt-input"
                        placeholder="Nhập số công văn"
                        type="text"
                        dir="auto"
                      />
                      <p className="text-noted-medium">
                        Thể hiện khi cấp số công văn, ngày công văn. Ví dụ: Đà
                        Nẵng, ngày...tháng...năm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-form-group pt-intent-danger">
                <div className="row group-form-item">
                  <div className="col-md-12 col-xs-12 form-item">
                    <label className="pt-label">Trích yếu</label>
                    <div className="pt-form-content">
                      <textarea
                        name="trichYeu"
                        rows="3"
                        className="pt-input pt-fill text-abridgment"
                        placeholder="Nội dung"
                        dir="auto"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-form-group pt-intent-danger">
                <div className="row group-form-item">
                  <div className="col-md-12 col-xs-12 col-sm-12 file-box form-item">
                    <label className="pt-label">File nội dung</label>
                    <div className="pt-form-content">
                      <label className="pt-file-upload">
                        <input type="file" name="fileName" />
                        <span className="pt-file-upload-input">
                          Chọn file đính kèm
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-form-group pt-intent-danger">
                <div className="row group-form-item">
                  <div className="col-md-12 col-xs-12 col-sm-12 file-box">
                    <label className="pt-label">Các file tham khảo</label>
                    <div className="pt-form-content">
                      <label className="pt-file-upload">
                        <input type="file" name="fileName" />
                        <span className="pt-file-upload-input">
                          Chọn file đính kèm
                        </span>
                      </label>
                      <button type="button" className="pt-button btn-add-file">
                        <span>Thêm file đính kèm</span>
                        <span className="icon-bc icon-Plus"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-md-6 col-xs-6 col-sm-6 form-item">
                    <label className="pt-label">Tổ đấu thầu</label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select name="toDauThau" className="select-item">
                          <option value="0">Tổ đấu thầu số 1</option>
                          <option value="1">Tổ đấu thầu số 2</option>
                          <option value="2">Tổ đấu thầu số 3</option>
                          <option value="3">Tổ đấu thầu số 4</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-6 col-sm-6 form-item">
                    <label className="pt-label">Tổ trưởng</label>
                    <div className="pt-form-content">
                      <input
                        name="soDenId"
                        className="pt-input"
                        placeholder="TB. Lê Hoàng Anh Dũng"
                        type="text"
                        dir="auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-form-group pt-intent-danger">
                <div className="row group-form-item">
                  <div className="col-md-12 col-xs-12 col-sm-12 form-item">
                    <div className="pt-form-content">
                      <div className="list-member-signnumber">
                        <div className="table">
                          <div className="heading-table">
                            <div className="cell-item">
                              <p className="name">Thành Viên</p>
                            </div>
                            <div className="cell-item">
                              <p className="name">Ký chính</p>
                            </div>
                            <div className="cell-item">
                              <p className="name">Ký nháy</p>
                            </div>
                          </div>
                          <div className="container-table">
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="PTB. Trương Văn Chương" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Nguyễn Văn Nam" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Nguyễn Tấn Sinh" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="PTB. Trương Văn Chương" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Ngô Hồng Ân" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Ngô Khắc Nam" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Đỗ Dũng" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Đặng Công" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                            <div className="row-item">
                              <div className="cell-item">
                                <Checkbox label="Chí Bình" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                              <div className="cell-item">
                                <Radio value="one" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-form-group pt-intent-danger">
                <div className="row group-form-item">
                  <div className="col-md-12 col-xs-12 col-sm-12 form-item">
                    <label className="pt-label">Nội dung trình</label>
                    <div className="pt-form-content">
                      <textarea
                        rows="4"
                        className="pt-input pt-fill"
                        dir="auto"
                        name="noiDungTrinh"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="selectbox-group">
                <label className="pt-control pt-checkbox pt-inline form-item">
                  <input type="checkbox" name="guiBanGiay" />
                  <span className="pt-control-indicator"></span>
                  <span className="name_checkbox">Gửi bản giấy</span>
                </label>
              </div>
            </div>
          </div>
          <div className="buttton-action-footer">
            <div className="row group-form-item">
              <div className="col-xs-12 form-item">
                <div className="pull-left full-mobile">
                  <a
                    tabIndex="0"
                    role="button"
                    type="button"
                    className="pt-button btn-view pull-left"
                    href="/"
                  >
                    Xem trước
                    <span className="pt-icon icon-Xemtruoc"></span>
                  </a>
                </div>
                <div className="pull-right full-mobile">
                  <button
                    onClick={this.handleClickPopup}
                    className="pt-button full-btn-left btn-cancel"
                    href="/"
                  >
                    <span className=" pt-icon icon-back"></span>
                    <span className="text-content">Quay lại</span>
                  </button>
                  <button
                    onClick={this.handleSubmit}
                    className="pt-button btn-save full-btn-right"
                  >
                    LƯU
                    <span className="pt-icon icon-save"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InputFormBiddingSignNumber
