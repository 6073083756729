import { Button } from '@blueprintjs/core'
import React, { Component } from 'react'

import DatePicker from 'react-datepicker'

const datePickerConfig = {
  readOnly: true,
  dateFormat: 'DD/MM/YYYY',
  peekNextMonth: true,
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
  className: 'input-datepicker input-icon-datepicker no-border',
  popperPlacement: 'auto',
  popperModifiers: {
    offset: {
      enabled: true,
      offset: '30px, 5px',
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
}

const initFilter = {
  text: '',
  date: {
    from: null,
    to: null,
  },
  select: {
    unit: '-1',
    department: '-1',
    procedureCategory: '-1',
    procedure: '-1',
  },
}

class SearchDispatchListFilter extends Component {
  constructor() {
    super()

    this.state = {
      filter: initFilter,
    }
  }

  dateChange = async (pos, date) => {
    await this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        date: {
          ...prevState.filter.date,
          [pos]: date,
        },
      },
    }))
    this.onSubmit()
  }

  inputChange = async e => {
    const { value } = e.target

    await this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        text: value,
      },
    }))

    if (this.props.textFilterChange) {
      this.props.textFilterChange(this.state.filter)
    }
  }

  clearInput = () => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        text: '',
      },
    }))
  }

  resetFilter = () => {
    this.setState({
      filter: initFilter,
    })
    if (this.props.resetFilter) {
      this.props.resetFilter()
    }
  }

  selectChange = async e => {
    const { name, value } = e.target
    await this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        select: {
          ...prevState.filter.select,
          [name]: value,
        },
      },
    }))
    this.onSubmit()
    if (
      this.props.dropSelectCallback &&
      (name === 'unit' || name === 'procedureCategory')
    ) {
      this.props.dropSelectCallback(name, value)
    }
  }

  onSubmit = () => {
    if (this.props.submitFilter) {
      this.props.submitFilter(this.state.filter)
    }
  }

  render() {
    const { filter } = this.state
    const { date, text, select } = filter
    const {
      dropdownData: {
        unit = [],
        department = [],
        procedureCategory = [],
        procedure = [],
      } = {},
    } = this.props

    return (
      <div className="search-container search-DocumentarySearch py-12 px-sm-15">
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <label className="search-label">Thời gian (Ngày lập)</label>
            <div className="mt5 form-input-filter date-picker-inline border-radius-3">
              <DatePicker
                {...datePickerConfig}
                isClearable={!!date.from}
                selectsStart
                startDate={date.from}
                endDate={date.to}
                maxDate={date.to}
                selected={date.from}
                value={date.from || 'Từ ngày'}
                onChange={date => this.dateChange('from', date)}
              />
              <DatePicker
                {...datePickerConfig}
                isClearable={!!date.to}
                selectsEnd
                startDate={date.from}
                endDate={date.to}
                minDate={date.from}
                selected={date.to}
                onChange={date => this.dateChange('to', date)}
                value={date.to || 'Đến ngày'}
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-9 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                placeholder="Tìm kiếm Số ký hiệu, trích yếu"
                maxLength={255}
                value={text || ''}
                onChange={this.inputChange}
                onKeyPress={e => e.key === 'Enter' && this.onSubmit()}
              />
              {text && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.clearInput}
                />
              )}
              <Button
                onClick={this.onSubmit}
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.resetFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
        <div className="row mt20">
          <div className="col-xs-12 col-md-3 mt20 mt-md-0">
            <label className="search-label">Đơn vị lập</label>
            <div className="form-input-filter flex-auto border-radius-3 select-arrow">
              <select
                className="input border-transparent appearance-none"
                name="unit"
                value={select.unit}
                onChange={this.selectChange}
              >
                <option default value={'-1'}>
                  {'Tất cả'}
                </option>
                {unit.map((i, index) => (
                  <option value={i.donViId} key={index}>
                    {i.tenDonVi}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-md-3 mt20 mt-md-0">
            <label className="search-label">Phòng ban lập</label>
            <div className="form-input-filter flex-auto border-radius-3 select-arrow">
              <select
                className="input border-transparent appearance-none"
                name="department"
                value={select.department}
                onChange={this.selectChange}
              >
                <option default value={'-1'}>
                  {'Tất cả'}
                </option>
                {department.map((i, index) => (
                  <option value={i.phongBanId} key={index}>
                    {i.tenPhongBan}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-md-3 mt20 mt-md-0">
            <label className="search-label">Danh mục quy trình</label>
            <div className="form-input-filter flex-auto border-radius-3 select-arrow">
              <select
                className="input border-transparent appearance-none"
                name="procedureCategory"
                value={select.procedureCategory}
                onChange={this.selectChange}
              >
                <option default value={'-1'}>
                  {'Tất cả'}
                </option>
                {procedureCategory.map((i, index) => (
                  <option value={i.id} key={index}>
                    {i.ten}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-md-3 mt20 mt-md-0">
            <label className="search-label">Quy trình</label>
            <div className="form-input-filter flex-auto border-radius-3 select-arrow">
              <select
                className="input border-transparent appearance-none"
                name="procedure"
                value={select.procedure}
                onChange={this.selectChange}
              >
                <option default value={'-1'}>
                  {'Tất cả'}
                </option>
                {procedure.map((i, index) => (
                  <option value={i.quyTrinhId} key={index}>
                    {i.tenQuyTrinh}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchDispatchListFilter
