/* eslint-disable max-len */
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

const LINK = '/lich-hop/quan-ly-lich-hop/chinh-sua'
const LINK_DETAIL = '/lich-hop/quan-ly-lich-hop/chi-tiet'

const TableItem = ({
  item,
  i,
  handleClickPopup,
  handleClickPopupCancel,
  handleSubmitGuiCuocHop,
  handleClickPopupKetThuc,
  history,
}) => {
  const renderTrangThai = key => {
    switch (key) {
      case 0:
        return 'Lưu nháp'
      case 1:
        return 'Chính thức'
      case 2:
        return 'Hủy'
      case 3:
        return 'Kết thúc'
      default:
        break
    }
  }

  const renderClassStatus = key => {
    switch (key) {
      case 0:
        return 'green'
      case 1:
        return 'blue'
      case 2:
        return 'red'
      case 3:
        return 'orange'
      default:
        break
    }
  }

  const isShowEditButton = moment(item?.batDau)
    .subtract(item?.nhacNho || 0, 'minutes')
    .isAfter(moment())
  return (
    <div
      className="table-rows"
      onClick={() => history?.push(`${LINK_DETAIL}/${item?.cuocHopId}`)}
    >
      <div className="table-cols-content width-col-60px text-center-col">
        <span className="order-content">{i < 10 ? '0' + i : i}</span>
      </div>
      <div className="table-cols-content width-col-150px">
        <span className="text-content">{item?.tieuDe}</span>
      </div>
      <div className="table-cols-content width-col-105px">
        <span className="order-content">{item?.theLoai?.ten}</span>
      </div>
      <div className="table-cols-content width-col-105px">
        <span className="order-content">{item?.mucDo?.ten}</span>
      </div>
      <div className="table-cols-content width-col-80px">
        <span className="text-content">
          {item?.batDau ? moment(item?.batDau).format('DD/MM/YYYY HH:mm') : ''}
        </span>
      </div>
      <div className="table-cols-content width-col-120px">
        <span className="text-content">{item?.diaDiem?.ten}</span>
      </div>
      <div className="table-cols-content width-col-105px">
        <span className="text-content">{item?.chuTri}</span>
      </div>
      <div className="table-cols-content width-col-80px text-center-col">
        <span className="text-content">{`${item?.tongMoiHop} / ${item?.tongThamGia}`}</span>
      </div>
      <div className="table-cols-content width-col-80px">
        <span
          className={`status-calendar text-content ${renderClassStatus(
            item?.trangThai
          )}`}
        >
          {renderTrangThai(item?.trangThai)}
        </span>
      </div>
      <div className="table-cols-content width-col-70px text-center-col">
        {item?.trangThai !== 3 && item?.trangThai !== 2 && (
          <Popover
            popoverClassName="pt-popover-content-sizing pt-minimal"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            handlePopoverClick={e => e.stopPropagation()}
            content={
              <div className="menu-popover">
                {item?.trangThai === 0 && (
                  <span
                    className="item"
                    onClick={() => handleSubmitGuiCuocHop(item?.cuocHopId)}
                  >
                    <span className="pt-icon icon-send-2"></span>
                    <span className="name_action">Gửi</span>
                  </span>
                )}
                {(item?.trangThai === 0 ||
                  (item?.trangThai === 1 && isShowEditButton)) && (
                  <Link className="item" to={`${LINK}/${item?.cuocHopId}`}>
                    <span className="pt-icon icon-edit-v2"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                )}
                {item?.trangThai === 1 && (
                  <span
                    className="item"
                    onClick={() => handleClickPopupCancel(item.cuocHopId)}
                  >
                    <span className="pt-icon icon-delete-v2"></span>
                    <span className="name_action">Hủy</span>
                  </span>
                )}
                {item?.trangThai === 1 && (
                  <span
                    className="item"
                    onClick={() => handleClickPopupKetThuc(item.cuocHopId)}
                  >
                    <span className="pt-icon icon2-check-circle"></span>
                    <span className="name_action">Kết thúc</span>
                  </span>
                )}
                {item?.trangThai === 0 && (
                  <span
                    className="item"
                    onClick={() => handleClickPopup(item.cuocHopId)}
                  >
                    <span className="pt-icon icon-delete-v2"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                )}
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
        )}
      </div>
    </div>
  )
}

export default TableItem
