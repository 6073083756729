import React, { memo } from 'react'
import moment from 'moment'
import csx from 'classnames'

const StatusDate = ({
  date = null,
  showDescription = true,
  descriptionInline = false,
  descriptionFlex = false,
  centerFlex = false,
  descriptionSize = 13,
  showIcon = false,
  isCompleted = false,
  className,
}) => {
  if (!date) return
  const today = moment().startOf('day')
  const diff = moment(date).startOf('day').diff(today, 'days')

  const daTre = diff < 0
  const ganTre = diff >= 0 && diff - 3 <= 0

  return (
    <span>
      <span
        className={csx(
          'font-roboto-bold',
          'font-size-13',
          {
            'text-danger': !isCompleted & daTre,
            'color-orange': !isCompleted & ganTre,
            'd-block': !descriptionInline,
            'd-flex': descriptionFlex,
            'align-item-center': centerFlex,
          },
          className
        )}
      >
        {showIcon && (
          <div className="wrapper-icon">
            <i className={csx('icon2-date-frames', 'mr5')} />
          </div>
        )}
        {moment(date).format('DD/MM/YYYY')}
      </span>
      {showDescription && !isCompleted && (ganTre || daTre) && (
        <span
          className={csx('disabled', `font-size-${descriptionSize}`, {
            ml5: descriptionInline,
          })}
        >
          {daTre && `(Đã trễ hạn ${Math.abs(diff)} ngày)`}
          {!daTre &&
            (ganTre && diff !== 0 ? `(Còn ${diff} ngày đến hạn)` : '(Đến hạn)')}
        </span>
      )}
    </span>
  )
}

export default memo(StatusDate)
