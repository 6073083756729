import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { CabinetTree, FileList, Fragment, JobProjectTree } from '../common'
import { Radio, RadioGroup, Button } from '@blueprintjs/core'
// import { PopupConfirm } from '../common/';
// import * as POPUP from '../../constants/Popup';
import * as Types from '../../constants/Api'
import { get, size, merge } from 'lodash'
import csx from 'classnames'
import { Utils } from '../../helpers'
import { fileType } from '../../helpers/validation'
import allowFileTypes from '../../constants/fileTypes'
import { ValidateForm } from '../../constants/MessageForm'

const maxMb = 25 * 2 ** 20
let fileId = 0

class SaveDocument extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
  }

  state = {
    params: {
      categoryType: Types.CABINET_PERSONAL,
      nganTuIds: [],
      tenTaiLieu: '',
      ghiChu: '',
      dsFileDinhKem: [
        {
          fileId: fileId++,
        },
      ],
    },
    fileValidate: {},
    isCollapse: false,
    isOpenPopup: false,
    isLoading: false,
  }

  static propTypes = {
    work: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    archives: PropTypes.array.isRequired,
    saveTo: PropTypes.array.isRequired,
    listCabinetOfDocument: PropTypes.array,
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.handleRadio({ target: { value: this.state.params.categoryType } })
    if (this.state.params.tenTaiLieu === '') {
      this.setState({
        params: {
          ...this.state.params,
          tenTaiLieu: get(this.props.work, 'trichYeu'),
        },
      })
    }
  }

  handleRadio = e => {
    this.setState({
      params: {
        ...this.state.params,
        categoryType: e.target.value,
        nganTuIds: [],
      },
    })

    this.props.onSelectSaveTo && this.props.onSelectSaveTo(e.target.value)
  }

  handleCheck(nganTuIds) {
    this.setState({
      params: {
        ...this.state.params,
        nganTuIds: nganTuIds,
      },
    })
  }

  handleSubmit = () => {
    if (!this.props.onSubmit) {
      return
    }

    if (!this.state.params.categoryType) {
      return this.props.onSubmit('Vui lòng chọn loại ngăn tủ')
    }

    if (!this.state.params.tenTaiLieu) {
      return this.props.onSubmit('Vui lòng nhập tên tài liệu')
    }

    if (this.state.params.nganTuIds.length < 1) {
      return this.props.onSubmit('Vui lòng chọn ngăn tủ')
    }

    this.setState({ isLoading: true })

    this.props.onSubmit &&
      this.props
        .onSubmit(false, {
          ...this.state.params,
          files: this.state.params.dsFileDinhKem
            .filter(f => f.data)
            .map(f => f.data),
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
  }

  handleClickShowResult = index => {
    this.props.archives.map((item, i) => {
      if (i === index) {
        item.isCollapse = !item.isCollapse
        this.setState({ isCollapse: item.isCollapse })
      } else {
        item.isCollapse = false
      }
      return true
    })
  }

  handleAreaChange = e => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: e.target.value,
      },
    })
  }

  addInputFile = () => {
    this.setState(prev => ({
      params: {
        ...prev.params,
        dsFileDinhKem: [
          ...prev.params.dsFileDinhKem,
          {
            fileId: fileId++,
          },
        ],
      },
    }))
  }

  removeInputFile = id => e => {
    e.preventDefault()
    const { params } = this.state
    let filesCheck = params.dsFileDinhKem.filter(file => file.fileId !== id)
    if (filesCheck && filesCheck.length === 0) {
      return this.setState({
        params: {
          ...this.state.params,
          dsFileDinhKem: [
            {
              fileId: 0,
              data: null,
            },
          ],
        },
      })
    }

    this.setState(prev => ({
      params: {
        ...prev.params,
        dsFileDinhKem: prev.params.dsFileDinhKem.filter(
          file => file.fileId !== id
        ),
      },
    }))
  }

  changeFile = id => e => {
    const reader = new FileReader()
    const { files } = e.target
    const targetFile = files[0]
    let error = false

    reader.onloadend = () => {
      if (targetFile) {
        const extension = Utils.getExtensionFile(targetFile.name)

        if (get(targetFile, 'size') > maxMb) {
          error = ValidateForm.MAX_FILE_SIZE(25)
        }

        if (fileType(extension, allowFileTypes)) {
          error = ValidateForm.FILE_TYPE(allowFileTypes)
        }

        if (!!error) {
          return this.setState(prev => ({
            fileValidate: {
              ...prev.fileValidate,
              [id]: error,
            },
          }))
        }
        this.setState(prev => ({
          params: {
            ...prev.params,
            dsFileDinhKem: prev.params.dsFileDinhKem.map(file =>
              file.fileId === id
                ? {
                    ...file,
                    data: targetFile,
                  }
                : file
            ),
          },
          fileValidate: {
            ...prev.validate,
            [id]: false,
          },
        }))
      }
    }

    if (targetFile && !error) {
      reader.readAsDataURL(targetFile)
    }
  }

  render() {
    const { params, fileValidate, isLoading } = this.state
    const { auth, work: congViecData = {}, saveTo } = this.props
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Lưu tủ tài liệu</h4>
        </div>
        <div className="form-container documents-cabinet">
          <div className="checkbox-document">
            <div className="row">
              <div className="col-md-5 col-sm-5 col-xs-12">
                <Scrollbars
                  autoHide
                  autoHideTimeout={100}
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={324}
                  autoHideDuration={200}
                >
                  <div className="type-document-list">
                    <RadioGroup
                      label="Lưu vào"
                      onChange={this.handleRadio.bind(this)}
                      selectedValue={this.state.params.categoryType}
                    >
                      {saveTo.map((item, i) => (
                        <Radio
                          key={i}
                          className="pt-large pt-text"
                          label={item.name}
                          value={item.id}
                          disabled={item.disabled}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </Scrollbars>
              </div>
              <div className="col-md-7 col-sm-7 col-xs-12">
                {params.categoryType === Types.HOSO_CONGVIEC ? (
                  <JobProjectTree
                    auth={auth}
                    data={this.props.archives}
                    listDisable={this.props.listCabinetOfDocument}
                    onCheck={this.handleCheck}
                  />
                ) : (
                  <CabinetTree
                    data={this.props.archives}
                    listDisable={this.props.listCabinetOfDocument}
                    onCheck={this.handleCheck}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="form-input form-save-document-content">
            <div className="pt-form-group">
              <div className="row">
                <label className="pt-label col-md-12 col-xs-12">Ghi Chú</label>
                <div className="pt-form-content col-md-12 col-xs-12">
                  <textarea
                    rows="1"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    name="ghiChu"
                    onChange={this.handleAreaChange}
                    defaultValue={this.state.params.ghiChu}
                  />
                </div>
              </div>
            </div>

            {!!size(
              merge(congViecData.fileDinhKemEvn, congViecData.fileThamKhao)
            ) && (
              <Fragment>
                {!!size(congViecData.fileDinhKemEvn) && (
                  <div className="row">
                    <label className="pt-label col-md-4 col-xs-12">
                      File đính kèm:
                    </label>
                    <div className="pt-form-content col-md-8 col-xs-12">
                      <FileList
                        auth={auth}
                        receiveDocument={congViecData}
                        field="fileDinhKemEvn"
                      />
                    </div>
                  </div>
                )}
                {Utils.checkBanTongHop(auth.mainDepartmentId) &&
                  this.props.filesThamKhao &&
                  this.props.filesThamKhao.length !== 0 && (
                    <div className="row">
                      <label className="pt-label col-md-4 col-xs-12 label-text-detail">
                        File tham khảo:
                      </label>
                      <div className="pt-form-content col-md-8 col-xs-12">
                        <FileList
                          auth={auth}
                          receiveDocument={{
                            fileThamKhao: this.props.filesThamKhao,
                          }}
                          field="fileThamKhao"
                        />
                      </div>
                    </div>
                  )}
              </Fragment>
            )}

            {params.categoryType === Types.HOSO_CONGVIEC && (
              <div className="pt-form-content">
                <label className="pt-label">
                  Thêm file đính kèm từ bên ngoài
                </label>
                {get(params, 'dsFileDinhKem', []).map((fileInput, index) => (
                  <div
                    key={index}
                    className={csx('pt-form-group pt-intent-danger', {
                      'upload-have-close': !!index,
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name={`dinhKem-${index}`}
                        onChange={this.changeFile(fileInput.fileId)}
                        disabled={isLoading}
                      />
                      <span className="pt-file-upload-input">
                        {get(fileInput, 'data.name') || 'Chọn file đính kèm'}
                      </span>
                    </label>
                    {fileInput &&
                      fileInput.data &&
                      get(fileInput, 'data.name') !== '' && (
                        <span
                          onClick={this.removeInputFile(fileInput.fileId)}
                          className="icon-bc icon-close icon-remove-file z-index-1"
                        />
                      )}
                    {get(fileInput, 'data.size') && (
                      <p className="pt-form-helper-text pt-form-helper-infomation">
                        Kích thước file đã upload:
                        <span className="size-file">
                          {Utils.getFileSize(get(fileInput, 'data.size'))}
                        </span>
                      </p>
                    )}
                    {get(fileValidate, fileInput.fileId) && (
                      <div className="pt-form-helper-text">
                        {get(fileValidate, fileInput.fileId)}
                      </div>
                    )}
                  </div>
                ))}
                <Button
                  className="btn-add-file mt0 mr10"
                  onClick={this.addInputFile}
                  disabled={isLoading}
                >
                  <span>Thêm file</span>
                  <span className="icon-bc icon-Plus" />
                </Button>
              </div>
            )}
          </div>
        </div>
        {!this.props.status && (
          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-main-color"
              onClick={this.handleSubmit}
              loading={isLoading}
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Lưu</span>
            </Button>
            <Button
              className="pt-button btn-cancel"
              onClick={this.props.onClickGoBack}
              disabled={isLoading}
            >
              <span className="pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>
        )}
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={isLoading}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-main-color"
            loading={isLoading}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.HUY_LUU_TU_TAI_LIEU}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.props.onClickGoBack}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
      </div>
    )
  }
}

export default SaveDocument
