import React from 'react'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { MasterLayout } from '../../../components/layout'
import { ListFilter, ListItem } from '../../../components/timkiemcongvanv61'
import { GridViewFilter, HeadingPage } from '../../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../../constants/table/TimKiemCongVanV61'
import {
  commonFetchUnitsRelative,
  commonAddToasterMessage,
  timKiemCongVan61,
  getFileInformation,
} from '../../../actions'
import * as Types from '../../../constants/Api'
import * as Tool from '../../../helpers'
// import { Authorization, Permission } from '../../../components/auth';
import moment from 'moment'

import Pagination from 'components/Pagination'

class DanhSachTimKiemCongVan extends React.Component {
  constructor(props) {
    super(props)
    let state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      isEmpty: false,
      exporting: false,
      isLoading: false,
      isPrint: false,
      page: 1,
      isError: false,
      setMaxDate: '2017-11-20',
    }
    this.state = state
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
  }

  componentDidMount() {
    let state = this.state
    // if (
    //   !Authorization.vaild(this.props.auth.permission, [
    //     Permission.CONGVAN_XEMTATCA,
    //   ]) &&
    //   Authorization.vaild(this.props.auth.permission, [
    //     Permission.CONGVAN_XEMPHONGBANKHAC,
    //   ])
    // ) {
    //   this.props.actions.commonFetchOtherDepartments(this.props.auth.roleId);
    // } else {
    //   this.props.actions.commonFetchDepartments();
    // }

    // this.props.actions.getCategoryCommonFetchRole(this.props.auth.mainUnitId);
    // this.props.actions.commonFetchObjects();
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    // this.props.actions.commonFetchDepartmentsLeader(0);
    let preData = Tool.Utils.preData('DocumentSearch61')
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : null,
        ngayDen:
          preData.filterColumn && preData.filterColumn.ngayDen
            ? moment(preData.filterColumn.ngayDen)
            : null,
        coQuanBanHanhId:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId
            ? preData.filterColumn.coQuanBanHanhId
            : '',
        coQuanBanHanh:
          preData.filterColumn && preData.filterColumn.coQuanBanHanh !== ''
            ? preData.filterColumn.coQuanBanHanh
            : null,
      }
    } else {
      state.filter.ngayCongVan = {
        from: moment(this.state.setMaxDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD'),
        to: this.state.setMaxDate,
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    // filterColumn để lưu ở localStorage
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayDen: this.state.filterColumn.ngayDen
        ? moment(this.state.filterColumn.ngayDen).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanh &&
        this.state.filterColumn.coQuanBanHanh !== ''
          ? this.state.filterColumn.coQuanBanHanh.trim()
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen.trim()
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu.trim()
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan.trim()
          : null,
    }
    const filterColumnPreData = {
      ...filterColumn,
      coQuanBanHanhId: this.state.filterColumn.coQuanBanHanhId
        ? this.state.filterColumn.coQuanBanHanhId
        : null,
    }
    this.setState(
      {
        filter,
        page,
        isLoading: true,
        sort,
      },
      () => {
        Tool.Utils.preData('DocumentSearch61', {
          filter,
          page,
          sort,
          filterColumnPreData,
        })
        this.props.actions
          .timKiemCongVan61({ filter }, page, sort, filterColumn)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              res &&
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.result &&
              res.payload.data.result.result.total === 0
            ) {
              isEmpty = true
            }

            if (
              res &&
              (res.error || (res.payload && res.payload.status !== 200))
            ) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.setState({ isLoading: false })
          })
      }
    )
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (name === 'coQuanBanHanhId') {
          return this.submitFilter()
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({ filterColumn: {} })
    }

    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: undefined,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  handleFilterDateChange = (name, pos, e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  handleFilterInputChange = e => {
    const target = e.target
    this.setState({
      filter: {
        ...this.state.filter,
        [target.name]: target.value,
      },
    })
  }

  handleFilterRemoveTextSearch = name => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: undefined,
        },
      },
      () => {
        return this.submitFilter()
      }
    )
  }

  handleClearFilter = () => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          textSearch: undefined,
          ngayCongVan: {
            from: moment(this.state.setMaxDate)
              .subtract(1, 'months')
              .format('YYYY-MM-DD'),
            to: this.state.setMaxDate,
          },
        },
        filterColumn: {},
      },
      () => {
        return this.submitFilter()
      }
    )
  }

  render() {
    const { isError, isEmpty, isLoading, page } = this.state
    const { danhSachTimKiemCongVan61 } = this.props
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container page-DocumentarySearch">
          <HeadingPage
            namePage="Tìm kiếm công văn 6.1"
            iconPage="icon-search-data"
          />
          <div className="page-list-container list-DocumentarySearch">
            <ListFilter
              onSubmit={this.submitFilter}
              filter={this.state.filter}
              handleFilterDateChange={this.handleFilterDateChange}
              handleFilterInputChange={this.handleFilterInputChange}
              handleFilterRemoveTextSearch={this.handleFilterRemoveTextSearch}
              handleClearFilter={this.handleClearFilter}
            />
            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    danhSachTimKiemCongVan61.total / Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                classNames="pt-table fix-table-before"
                page={this.state.page}
                headers={headers.timKiemCongVanV61}
                itemShape={ListItem}
                data={danhSachTimKiemCongVan61.list}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                coQuanBanHanhId={this.props.relativeUnits}
                filterColumn={this.state.filterColumn}
                actions={this.props.actions}
                history={this.props.history}
                auth={this.props.auth}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                pageCount={Math.ceil(
                  danhSachTimKiemCongVan61.total / Types.PAGINATION_LIMIT
                )}
                maxDate={this.state.setMaxDate}
              />

              {!isLoading && !isEmpty && !isError && (
                <Pagination
                  current={page}
                  total={Math.ceil(
                    danhSachTimKiemCongVan61.total / Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

DanhSachTimKiemCongVan.propTypes = {
  auth: PropTypes.object,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  receiveDocuments: {
    ...state.receiveDocuments,
    list: {
      ...state.receiveDocuments.list,
      items: state.receiveDocuments.list.items.map(
        id => state.entities.receiveDocuments[id]
      ),
    },
  },
  objects: state.common.objects,
  relativeUnits: state.common.relativeUnits,
  typeSidebar: state.common.typeSidebar,
  readOnly: state.common.enableReadOnly,
  danhSachTimKiemCongVan61: state.timKiemCongVan61.danhSachTimKiemCongVan61,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonFetchUnitsRelative,
      commonAddToasterMessage,
      timKiemCongVan61,
      getFileInformation,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DanhSachTimKiemCongVan)
