import classNames from 'classnames'
import moment from 'moment'
import React from 'react'

const TieuDeXuLy = ({ item, className }) => {
  return (
    <div className={classNames('do-collapse-title pl20', className)}>
      <p className="chuc_vu mr10">{item?.tENPB || ''}</p>
      <p
        className={classNames('vai_tro mr10', {
          red: item?.mAXULY === 'Chủ trì' && item?.kEYXULY,
        })}
      >
        ({item?.mAXULY || ''})
      </p>
      {item?.tTXULY === 'Hoàn thành' && (
        <div className="circle-border mr10">
          <span className="checkmark"></span>
        </div>
      )}
      <p className="thoi_gian">
        {item?.nGAYTAO
          ? moment(item?.nGAYTAO)
              .subtract(7, 'hours')
              .format('DD/MM/YYYY HH:mm')
          : ''}
      </p>
    </div>
  )
}

export default TieuDeXuLy
