import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import React, { PureComponent } from 'react'
import SignatureDocumentForm from './SignatureDocumentForm'

const PAGE_NAME = 'Chỉnh sửa văn bản ký duyệt'
const PAGE_ICON = 'icon-trinh-van-ban-ky-duyet'

class EditSignatureDocumentPage extends PureComponent {
  constructor() {
    super()
    this.state = {
      pageName: PAGE_NAME,
    }
  }

  changePageName = name =>
    this.setState({
      pageName: !!name ? name : PAGE_NAME,
    })

  render() {
    return (
      <MasterLayout typeSidebar="signNumber">
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <ContentHeader
              pageIcon={PAGE_ICON}
              pageName={this.state.pageName}
              showGoBackButton
            />
            <SignatureDocumentForm
              isEdit
              changePageName={this.changePageName}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

export default EditSignatureDocumentPage
