import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Popover, Position } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'

import { Input } from '../index'

const DropdownWrapper = ({
  children = null,
  className = '',
  disabled = false,
  inputClassName = '',
  onClear = () => {},
  onDropdownVisibleChange = () => {},
  open = false,
  placeholder = '',
  value: valueDisplay = '',
  leftIcon = null,
  clearable = true,
  popoverPosition = Position.BOTTOM_LEFT,
  styleInput = null,
  isShowRightElement = true,
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [isOpen, _setIsOpen] = useState(open)
  const [popoverWidth, _setPopoverWidth] = useState(0)

  const _toggleOpen = useCallback(
    newStatus => e => {
      if (newStatus) {
        const { width } = e.target.getBoundingClientRect()
        _mountedSet(_setPopoverWidth, width)
      }

      _mountedSet(_setIsOpen, newStatus)
      onDropdownVisibleChange(newStatus)
    },
    [onDropdownVisibleChange]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setIsOpen, open)
  }, [open])

  return (
    <Popover
      className={className}
      content={<div style={{ minWidth: popoverWidth }}>{children}</div>}
      isOpen={isOpen}
      onClose={_toggleOpen(false)}
      position={popoverPosition}
      popoverClassName="cpc-popover no-arrow mt0"
      rootElementTag="div"
    >
      <Input
        clearable={clearable}
        className={inputClassName}
        disabled={disabled}
        onClear={onClear}
        onClick={_toggleOpen(!isOpen)}
        readOnly
        rightElement={
          isShowRightElement ? (
            <i
              className={csx(
                {
                  'icon-arrow-dropup': isOpen,
                  'icon-arrow-dropdown': !isOpen,
                },
                'size-icon-6',
                'mr10'
              )}
              onClick={_toggleOpen(!isOpen)}
            />
          ) : null
        }
        placeholder={placeholder}
        value={valueDisplay}
        leftIcon={leftIcon}
        styleInput={styleInput}
      />
    </Popover>
  )
}

export default memo(DropdownWrapper)
