export const quantitySignature = {
  1: '1 người',
  2: 'Nhiều người',
}

export const signatureLocationType = {
  0: 'Kẻ ô vùng ký',
  1: 'Tìm theo vị trí cố định',
  2: 'Tìm theo tên người ký',
}

export const signatureType = {
  0: 'Xác nhận qua chương trình(không ký số)',
  1: 'Chữ ký không có hình ảnh con dấu',
  2: 'Chữ ký có hình ảnh con dấu',
  3: 'Chữ ký nháy',
  4: 'Không hiển thị hình ảnh chữ ký',
  6: 'Cấp số',
}

export const signSubject = {
  0: 'Theo danh sách thành viên',
  1: 'Theo phòng ban',
  2: 'Theo vai trò trong đơn vị',
  3: 'Theo vai trò trong phòng ban',
}

export const signRole = {
  GD: 'Giám đốc',
  PGD: 'Phó Giám đốc',
  CVP: 'Chánh văn phòng',
  LDPB: 'Lãnh đạo phòng ban',
  VT: 'Văn thư',
  CV: 'Chuyên viên',
}

export const thanhPhanKy = {
  0: 'Theo danh sách thành viên',
  1: 'Theo phòng ban hoặc vai trò',
}
