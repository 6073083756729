export const allowDocumentFileTypes = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
]
export const allowDocumentFileTypesLimit = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
export const allowImagesFileTypes = ['jpg', 'png']

const doc = [
  '.doc',
  '.DOC',
  '.docx',
  '.DOCX',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const pdf = ['.pdf', '.PDF', 'application/pdf']

const excel = [
  '.xls',
  '.XLS',
  '.xlsx',
  '.XLSX',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const powerpoint = [
  '.ppt',
  '.PPT',
  '.pptx',
  '.PPTX',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

const jpg = ['.jpg', '.JPG', '.jpeg', '.jpe', '.jfif', 'image/jpeg']

const png = ['.png', '.PNG', 'image/png']

// const rar = [
//   '.rar',
//   'application/x-rar-compressed',
//   'application/octet-stream',
// ];

// const zip = [
//   '.zip',
//   'application/zip',
//   'application/octet-stream',
//   'application/x-zip-compressed',
//   'multipart/x-zip',
// ];

export const documentTypes = [...doc, ...pdf, ...excel, ...powerpoint]

export const documentTypesLimit = [...doc, ...pdf, ...excel]

export const imagesTypes = [...jpg, ...png]

export default {
  allowDocumentFileTypes,
  allowDocumentFileTypesLimit,
  documentTypes,
  documentTypesLimit,
}
