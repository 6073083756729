import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Popover, Position } from '@blueprintjs/core'
import get from 'lodash/get'
import classNames from 'classnames'

import { Card } from 'components/newCommon'
import { Input } from '../index'

const InputSearch = ({
  wrapperRef,
  dataSource = [],
  value = '',
  open = false,
  required = false,
  label = '',
  disabled = false,
  wrapperClassName = '',
  iconClassName = '',
  popoverClassName = '',
  popoverHeight = 'unset',
  popoverPosition = Position.BOTTOM_LEFT,
  maxLength = 500,
  warning,
  onCardChange: onRowChange = () => {},
  onLoad: inputLoad = () => {},
  onChange: inputChange = () => {},
  onClear: inputClear = () => {},
  onEnter: inputEnter = () => {},
}) => {
  const inputWrapperRef = useRef()
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [isOpen, setIsOpen] = useState(open)
  const [compValue, setCompValue] = useState('')
  const [messageDelay, setMessageDelay] = useState('')
  const [popoverWidth, setPopoverWidth] = useState(0)

  const onChange = useCallback(
    value => {
      setCompValue(value)
      inputChange(value)
    },
    [inputChange]
  )

  const onClear = useCallback(() => {
    inputClear('')
    _mountedSet(setIsOpen, false)
  }, [inputClear])

  const onEnter = useCallback(
    value => {
      inputEnter(value)
      _mountedSet(setIsOpen, true)
    },
    [inputEnter]
  )

  const closeDropdown = useCallback(e => {
    _mountedSet(setIsOpen, false)
  }, [])

  const onItemChange = useCallback(
    value => {
      onRowChange(value)
      _mountedSet(setIsOpen, false)
    },
    [onRowChange]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (inputWrapperRef?.current && !popoverWidth) {
      const { width } = inputWrapperRef.current.getBoundingClientRect()
      _mountedSet(setPopoverWidth, width)
    }
  }, [popoverWidth])

  useEffect(() => {
    _mountedSet(setIsOpen, open)
  }, [open])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setMessageDelay(compValue)
    }, 800)
    return () => clearTimeout(timeOutId)
  }, [compValue, setMessageDelay])

  useEffect(() => {
    if (messageDelay) {
      inputLoad(messageDelay)
    }
  }, [inputLoad, messageDelay])

  return (
    <div
      ref={wrapperRef}
      className={classNames(wrapperClassName)}
      style={{ position: 'relative' }}
    >
      <Input
        wrapperRef={inputWrapperRef}
        disabled={disabled}
        label={label}
        required={required}
        iconClassName={iconClassName}
        placeholder="Nhập số hiệu văn bản"
        maxLength={maxLength}
        value={value}
        warning={warning}
        onChange={onChange}
        onClear={onClear}
        onEnter={onEnter}
      />
      <Popover
        content={
          <div
            style={{
              minWidth: popoverWidth,
              maxHeight: popoverHeight,
              overflowY: 'auto',
            }}
          >
            <Card
              className="popup"
              mainClassName="cpc-list"
              stypeMain={{ maxHeight: 300, maxWidth: 500 }}
            >
              {dataSource?.map((elm, index) => (
                <Card
                  key={index}
                  className={classNames(
                    'cpc-list-item',
                    'cpc-dropdown-item',
                    'border'
                  )}
                  onClick={() => onItemChange(elm)}
                >
                  {elm?.label}
                  {elm?.trichYeu ? ` - ${elm?.trichYeu}` : ''}
                </Card>
              ))}
            </Card>
          </div>
        }
        isOpen={isOpen}
        onClose={closeDropdown}
        position={popoverPosition}
        popoverClassName={classNames(
          'cpc-popover no-arrow m0',
          popoverClassName
        )}
        rootElementTag="div"
      >
        <div></div>
      </Popover>
    </div>
  )
}

export default memo(InputSearch)
