import { MasterLayout } from 'components/layout'
import { QLCV_LINK } from 'constants/Api'
import React from 'react'
import { connect } from 'react-redux'
import './style.scss'

const Qlcv = props => {
  const { auth } = props
  const url =
    QLCV_LINK +
    `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}
      &tokenType=${auth.token.tokenType}&refreshToken=${
      auth.token.refreshToken
    }&isEmbed=${true}`
  return (
    <MasterLayout typeSidebar="Chat">
      <div className="chat-wrap">
        <iframe
          allow="geolocation"
          title="ok"
          src={url}
          className="chat-module"
        ></iframe>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps, null)(Qlcv)
