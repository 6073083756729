import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const UIEmpty = ({ text, style, className }) => {
  return (
    <div
      className={classNames('ui-empty', {
        [`${className}`]: className,
      })}
      style={style}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/empty-default.png`}
        alt="empty"
      />
      {text ? <span className="name">{text}</span> : ''}
    </div>
  )
}

UIEmpty.defaultProps = {
  className: '',
  text: 'Không có dữ liệu',
  style: null,
}

UIEmpty.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
}

export default UIEmpty
