export const variables = {
  DATE_FORMAT: {
    DATE: 'YYYY-MM-DD',
  },
  PAGEMENUDETAIL: {
    CONGVANDITHUHOI: 'CONGVANDITHUHOI',
    CONGVANDI: 'CONGVANDI',
  },
  FUNC_KEY: {
    THU_HOI: 'THU_HOI',
    HUY_DE_XUAT: 'HUY_DE_XUAT',
  },
  CHAT_URL: '/chat',
  VALIDATE: {
    FILE_TYPE_ALLOW: allowTypeArray =>
      `Hệ thống hỗ trợ định dạng file ${allowTypeArray.join(', ')}`,
    FILE_TYPE_NOT_ALLOW: notAllowTypeArray =>
      `Hệ thống không hỗ trợ định dạng file ${notAllowTypeArray.join(', ')}`,
    FILE_SIZE_ALLOW: sizeAllow => `Dung lượng file vượt quá ${sizeAllow}MB`,
  },
}

export default variables
