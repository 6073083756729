import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import ExecutingViewBoxItems from './ExecutingViewBoxItems'
import { Collapse } from '@blueprintjs/core'

const CHUA_THUC_HIEN = 0
const DANG_THUC_HIEN = 1
const DA_HOAN_THANH = 2

class ExecutingViewSubItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  state = {
    isOpen: false,
    data: [],
    isLoading: false,
  }

  componentDidMount() {
    if (
      this.props.item &&
      this.props.item.phieuGiaoViecId &&
      this.props.item.chuTri
    ) {
      const id = this.props.item.phieuGiaoViecId
      this.handleClick(id)
    }
  }

  handleClick = (id, isOpen) => {
    if (this.props.item && !this.props.item.phieuGiaoViecId) {
      return
    }
    if (id && !isOpen) {
      this.setState({ isLoading: true }, () => {
        if (this.state.data && !this.state.data[id]) {
          this.props.actions &&
            this.props.actions.getExecutingViewAssign(id).then(res => {
              if (
                res &&
                res.payload &&
                res.payload.data &&
                res.payload.data.result
              ) {
                this.setState({
                  isOpen: true,
                  data: {
                    [id]: [res.payload.data.result],
                  },
                  isLoading: false,
                })
              }
            })
        } else {
          this.setState({ isLoading: false, isOpen: true })
        }
      })
    }
    if (isOpen) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const { item, auth } = this.props
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    return (
      <div className="box-level-sub item-box">
        <div
          className="avatar-user click-avatar click-avatar"
          onClick={this.handleClick.bind(
            this,
            item.phieuGiaoViecId,
            this.state.isOpen
          )}
        >
          <span
            className={classnames('pt-icon click-dropdown', {
              'pt-icon-chevron-up drop-down-items':
                item.phieuGiaoViecId && this.state.isOpen,
              'pt-icon-chevron-down drop-down-items':
                item.phieuGiaoViecId && !this.state.isOpen,
            })}
          ></span>
          <span
            className="avatar"
            style={{
              backgroundImage: `url(${
                item.caNhanThucHien && item.caNhanThucHien.anhDaiDien
                  ? Types.IMAGE_URL + item.caNhanThucHien.anhDaiDien
                  : '/images/default_avatar.png'
              })`,
            }}
          ></span>
          <span className="name_user">
            {item.caNhanThucHien && item.caNhanThucHien.tenNhanVien + ' '}
            {item.chuTri ? (
              item.chuTri === true && <span className="role">(Chủ trì)</span>
            ) : (
              <span className="role">(Phối hợp)</span>
            )}
          </span>
          <span
            className={classnames(
              'status-job pull-right position-relative ml65',
              {
                process: item.trangThai === DANG_THUC_HIEN,
                'no-process': item.trangThai === CHUA_THUC_HIEN,
                done: item.trangThai === DA_HOAN_THANH,
              }
            )}
          >
            {item.trangThai === DANG_THUC_HIEN
              ? 'Đang thực hiện'
              : item.trangThai === CHUA_THUC_HIEN
              ? 'Chưa thực hiện'
              : item.trangThai === DA_HOAN_THANH
              ? 'Đã hoàn thành'
              : ''}
          </span>
        </div>
        {item.trangThai !== CHUA_THUC_HIEN && (
          <div className="info-job info-sub-padding clearfix">
            {(item.ngayThucHien || item.ngayHoanThanh) && (
              <span className="title">Ngày:</span>
            )}
            <span className="content">
              {item.ngayThucHien &&
                ' ' +
                  moment(item.ngayThucHien).format('DD/MM/YYYY HH:mm') +
                  ' '}
            </span>
            {item.thucHien && <span className="title">- Thực hiện:</span>}
            <span className="content">
              {item.thucHien ? ' ' + item.thucHien : ''}
            </span>

            <div className="">
              {item.dsVanBanTraLoi &&
                item.dsVanBanTraLoi.length > 0 &&
                item.dsVanBanTraLoi.map((item, i) => (
                  <div className="" key={i}>
                    {item.congVanDi && (
                      <div className="">
                        <div className="title">Số công văn:</div>
                        {!item.congVanDi.fileCongVan.migrated && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${Types.FILE_URL}/api/file/get/${item.congVanDi.fileCongVan.fileId}?BearerToken=${token}`}
                          >
                            {item.congVanDi.soCongVan} -{' '}
                            {Tool.Utils.getFileSize(
                              item.congVanDi.fileCongVan.kichThuoc
                            )}
                          </a>
                        )}
                      </div>
                    )}
                    {item.documentStore && (
                      <div className="">
                        <div className="title">File:</div>
                        <span className="content">
                          {!item.documentStore.migrated && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${Types.FILE_URL}/api/file/get/${item.documentStore.fileId}?BearerToken=${token}`}
                            >
                              {item.documentStore.tenFile} -{' '}
                              {Tool.Utils.getFileSize(
                                item.documentStore.kichThuoc
                              )}
                            </a>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
        <Collapse isOpen={this.state.isOpen}>
          <div className={classnames({ 'loading-view': this.state.isLoading })}>
            {this.state.isLoading && <span>&nbsp;</span>}
            {!this.state.isLoading &&
              this.state.data[item.phieuGiaoViecId] &&
              this.state.data[item.phieuGiaoViecId].map((item, i) => (
                <ExecutingViewBoxItems
                  item={item}
                  key={i}
                  auth={auth}
                  actions={this.props.actions}
                />
              ))}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default ExecutingViewSubItem
