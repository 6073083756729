import React, { useCallback, useEffect, useState, memo, useMemo } from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import moment from 'moment/moment'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { MasterLayout } from 'components/layout'
import { Pagination, Table } from 'components/newCommon2'
import BackgroundSetting from '../TaskManager/Settings/Background'
import ChiTietCongVan from './ChiTietCongVan'
import TimKiemCongViecFilter from './TimKiemCongViecFilter'

const pageActions = {
  chiTietCongVan: 'chi-tiet-cong-van',
}

export const CongVanContext = React.createContext({})

const TimKiemCongVan = ({ history, match, allCookies, ...props }) => {
  const refData = React.createRef()
  const { action: currentAction } = match.params
  const mainPathname = useMemo(
    () => '/quan-ly-cong-viec-do/tim-kiem-cong-van',
    []
  )
  const { danhSachCongVan } = props

  const DEFAULT_FILTER = useMemo(
    () => ({
      IDChuyenVienXL: 0,
      IDPhongBanBanHanh: 0,
      IDPhongBanChuTri: 0,
      ID_LOAI_VB: 0,
      ID_SO_VB: 0,
      KY_HIEU: '',
      LoaiVB: 'all',
      MainKeyWord: '',
      DateFrom: moment().subtract(1, 'months'),
      DateTo: moment(),
    }),
    []
  )

  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  })
  const [dataSource, setDataSource] = useState([])
  const [isFetching] = useState(false)

  useEffect(() => {
    const { page, size } = pagination
    const firstItem = (page - 1) * size
    const lastItem = page * size
    const newData = danhSachCongVan.slice(firstItem, lastItem)
    setDataSource(newData)
  }, [danhSachCongVan, pagination])

  const handleRedirect = useCallback(() => {
    history.push('/quan-ly-cong-viec-do/tim-kiem-cong-van')
  }, [history])

  const actionBlock = useMemo(() => {
    const { chiTietCongVan } = pageActions
    const actionOverlays = {
      [chiTietCongVan]: ChiTietCongVan,
    }
    const ActionOverlay = actionOverlays[currentAction]
    if (!ActionOverlay) return <></>
    return <ActionOverlay onClose={handleRedirect} />
  }, [currentAction, handleRedirect])

  const goChiTiet = (id, action) => {
    const url = `${mainPathname}/${pageActions.chiTietCongVan}/${id}`
    if (!currentAction) return history.push(url)
    if (action !== currentAction) return history.replace(url)
  }

  const columns = [
    {
      key: 'kYHIEU',
      headClass: 'uppercase',
      className: 'align-center max-width-140 min-width-120',
      title: 'Số kí hiệu',
      width: 5,
      render: ({ kYHIEU }) => kYHIEU,
    },
    {
      key: 'tieuDe',
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Trích Yếu / Tiêu đề ',
      width: 50,
      render: ({ tRICHYEU }) => tRICHYEU,
    },
    {
      key: 'noiBanHanh',
      headClass: 'uppercase',
      className: 'align-center max-width-200',
      title: 'Nơi ban hành',
      width: 15,
      render: ({ nOIBANHANH }) => nOIBANHANH,
    },
    {
      key: 'ngayVB',
      headClass: 'uppercase',
      className: 'align-center max-width-120 min-width-120',
      title: 'Ngày VB',
      width: 5,
      render: ({ nGAYVB }) =>
        nGAYVB ? moment(nGAYVB).format('DD/MM/YYYY') : '',
    },
    {
      key: 'thaoTac',
      className: 'align-center max-width-80 min-width-80',
      width: 5,
      render: ({ iDVB }) => (
        <div onClick={e => e.stopPropagation()}>
          <div className="color-blue pointer" onClick={() => goChiTiet(iDVB)}>
            <span>Chi tiết</span>
          </div>
        </div>
      ),
    },
  ]

  const changeFilter =
    ({ name, reFetch = true }) =>
    value => {
      let newFilter = { ...filter }
      if (name === 'RESET') {
        newFilter = DEFAULT_FILTER
      } else {
        newFilter = {
          ...newFilter,
          [name]: value,
        }
      }
      setFilter(newFilter)
    }

  const checkLayout = keyName => {
    const data = [pageActions.chiTietCongVan]
    if (data.includes(keyName)) {
      return true
    }
    return false
  }

  const handleChangePage = value => {
    setPagination(prev => ({
      ...prev,
      page: value,
    }))
  }

  const contextValue = {
    filter,
    changeFilter,
  }

  return (
    <MasterLayout typeSidebar="taskManager" newLayout>
      <CongVanContext.Provider value={contextValue}>
        <div
          style={{ flex: 1, padding: 40, position: 'relative' }}
          ref={refData}
        >
          <header className="d-flex mb20">
            <b
              className={classNames('font-size-20 font-weight-600', {
                'color-light': get(
                  allCookies,
                  'background_image.is_light',
                  false
                ),
              })}
              style={{ marginRight: 'auto' }}
            >
              Tìm kiếm công văn D-Office
            </b>
            <span style={{ marginLeft: 'auto' }}>
              <BackgroundSetting />
            </span>
          </header>
          <div className="mb20">
            <TimKiemCongViecFilter />
          </div>
          <Table
            className="cpc-table-radius"
            autoHide={false}
            loading={isFetching}
            columns={columns}
            data={dataSource}
            tableMinWidth={600}
            onRowClick={({ iDVB }) => goChiTiet(iDVB)}
          />
          <div className="text-right mt15">
            <Pagination
              total={danhSachCongVan.length}
              current={get(pagination, 'page', 1)}
              pageSize={pagination.pageSize}
              onChange={handleChangePage}
            />
          </div>
        </div>
        {checkLayout(currentAction) && <div className="mask-layout"></div>}
        {actionBlock}
      </CongVanContext.Provider>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  tokenDO: state?.doffice?.toKenDO,
  danhSachCongVan: state?.doffice?.danhSachCongVan,
})

export default withCookies(connect(mapStateToProps)(memo(TimKiemCongVan)))
