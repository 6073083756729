import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'

import { Authorization, Permission } from '../auth'
import { LoadingItem, PopupConfirm, DetailFileDinhKem } from '../common/'
import * as POPUP from '../../constants/Popup'
import { FileList } from '../../components/common'
import { Utils } from 'helpers'

const CHUA_XU_LY = 0
class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chucDanhId: this.props.auth && this.props.auth.roleId,
      showBoxAction: false,
      receiveDocument: null,
      isOpenPopup: false,
    }
    this.openPopup = this.openPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  static propTypes = {
    onClickForwardAssignment: PropTypes.func.isRequired,
    onClickAssignment: PropTypes.func.isRequired,
    onClickProposeAssignment: PropTypes.func.isRequired,
    onClickSendMail: PropTypes.func.isRequired,
    onClickSaveArchives: PropTypes.func.isRequired,
    onClickExecutingView: PropTypes.func.isRequired,
    onClickAssignmentHistory: PropTypes.func.isRequired,
    onClickFlowdocumentary: PropTypes.func.isRequired,
    onClickInformationAssign: PropTypes.func.isRequired,
    onClickAdditionalAssignment: PropTypes.func.isRequired,
    receiveDocument: PropTypes.object.isRequired,
    assignmentHistory: PropTypes.array.isRequired,
    onHandleSubmit: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.receiveDocument &&
      nextProps.receiveDocument !== this.props.receiveDocument
    ) {
      this.setState({ receiveDocument: nextProps.receiveDocument })
    }
  }

  componentDidMount() {
    if (this.props.receiveDocument) {
      this.setState({ receiveDocument: this.props.receiveDocument })
    }
  }

  componentWillUnmount() {
    this.setState({
      showBoxAction: false,
      receiveDocument: null,
    })
  }

  openPopup() {
    this.setState({
      isOpenPopup: true,
    })
  }

  closePopup() {
    this.setState({
      isOpenPopup: false,
    })
  }

  handleSubmitRemoveItem() {
    this.closePopup()
    this.props.handleSubmitRemoveItem()
  }

  render() {
    let isCanUpdateAssignment = false
    let trinhLanhDao = false
    if (
      this.props.auth &&
      this.props.auth.roleId &&
      this.props.receiveDocument &&
      this.props.receiveDocument.nguoiChiDao === this.props.auth.roleId
    ) {
      isCanUpdateAssignment = true
    }
    if (
      this.props.auth &&
      this.props.auth.mainDepartmentId &&
      this.state.receiveDocument &&
      this.state.receiveDocument.trinhLanhDao &&
      this.state.receiveDocument.trinhLanhDao.phongBanId ===
        this.props.auth.mainDepartmentId
    ) {
      trinhLanhDao = true
    }

    if (
      this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined
    ) {
      return <LoadingItem />
    }

    return (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        {this.state.receiveDocument &&
          this.state.receiveDocument.fileDinhKem &&
          this.state.receiveDocument.fileDinhKem.length > 0 && (
            <DetailFileDinhKem {...this.props} />
          )}
        {/* Files tham khảo từ hội đồng thành viên  */}
        {Utils.checkBanTongHop(this.props.auth.mainDepartmentId) &&
          this.props.filesThamKhao &&
          this.props.filesThamKhao.length !== 0 && (
            <div className="pl20 pr20">
              <label className="pt-label label-text-detail">
                File tham khảo ({this.props.filesThamKhao.length}):
              </label>
              <div className="pt-form-content">
                <div className="pl10">
                  <FileList
                    auth={this.props.auth}
                    receiveDocument={{
                      fileThamKhao: this.props.filesThamKhao,
                    }}
                    field="fileThamKhao"
                  />
                </div>
              </div>
            </div>
          )}
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button onClick={this.showBoxAction.bind(this)}>
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          {!this.props.status && (
            <div className="row list-action-detail">
              {this.state.receiveDocument &&
                this.state.receiveDocument.trangThai === CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null &&
                trinhLanhDao === true && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_CHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickAssignment}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Chỉ đạo</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}

              {isCanUpdateAssignment &&
                this.state.receiveDocument &&
                this.state.receiveDocument.trangThai !== CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_CHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickUpdateAssignment}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Sửa/Hủy chỉ đạo</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}

              {this.state.receiveDocument &&
                this.state.receiveDocument.trangThai !== CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_CHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickAdditionalAssignment}
                      >
                        <i className="icon-Chidaobosung icon-cpc"></i>
                        <span className="action-name">Chỉ đạo bổ sung</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}

              {this.state.receiveDocument &&
                this.state.receiveDocument.trangThai === CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_CHUYENCHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickForwardAssignment}
                      >
                        <i className="icon-Chuyenchidao icon-cpc"></i>
                        <span className="action-name">Chuyển chỉ đạo</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}

              {this.state.receiveDocument &&
                this.state.receiveDocument.trangThai === CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null &&
                this.state.receiveDocument.daDeXuatChiDao === false && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_DEXUATCHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickProposeAssignment}
                      >
                        <i className="icon-dexuatchidao icon-cpc"></i>
                        <span className="action-name">Đề xuất chỉ đạo</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}

              {this.state.receiveDocument &&
                this.state.receiveDocument.trangThai === CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null &&
                this.state.receiveDocument.daDeXuatChiDao === true && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_DEXUATCHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickProposeAssignment}
                      >
                        <i className="icon-dexuatchidao icon-cpc"></i>
                        <span className="action-name">Sửa đề xuất chỉ đạo</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}

              {this.props.receiveDocument &&
                this.props.receiveDocument.trinhLanhDao && (
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickExecutingView}
                    >
                      <i className="icon-Xemthuchien icon-cpc"></i>
                      <span className="action-name">Xem thực hiện</span>
                    </button>
                  </div>
                )}

              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <button
                  className="click-item"
                  onClick={this.props.onClickSaveArchives}
                >
                  <i className="icon-Luututailieu icon-cpc"></i>
                  <span className="action-name">Lưu tủ tài liệu</span>
                </button>
              </div>

              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <button
                  className="click-item"
                  onClick={this.props.onClickSendMail}
                >
                  <i className="icon-Gmail icon-cpc"></i>
                  <span className="action-name">Gửi email</span>
                </button>
              </div>

              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <button
                  className="click-item"
                  onClick={this.props.onClickAssignmentHistory}
                >
                  <i className="icon-Lichsuchidao icon-cpc"></i>
                  <span className="action-name">Lịch sử chỉ đạo</span>
                </button>
              </div>

              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <button
                  className="click-item"
                  onClick={this.props.onClickDetailInfo}
                >
                  <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                  <span className="action-name">Thông tin công văn</span>
                </button>
              </div>

              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <a
                  href="#/"
                  className="click-item"
                  onClick={this.props.onClickFlowdocumentary}
                >
                  <i className="icon-organization icon-cpc"></i>
                  <span className="action-name">Luồng công văn</span>
                </a>
              </div>

              {/* CHANGED: Chuyển sang gọi api khi click action "Thông tin chỉ đạo" */}
              {/* { (this.props.informationSteering || this.props.parentInformationSteering) && (this.props.receiveDocument.trangThai !== 0 || this.props.receiveDocument.congVanCapTrenChiDaoId) &&
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button className="click-item" onClick={this.props.onClickInformationAssign}>
                    <i className="icon-Chidao icon-cpc"></i>
                  <span className="action-name">Thông tin chỉ đạo</span>
                  </button>
                </div>
              } */}
              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <button
                  className="click-item"
                  onClick={this.props.onClickInformationAssign}
                >
                  <i className="icon-Chidao icon-cpc"></i>
                  <span className="action-name">Thông tin chỉ đạo</span>
                </button>
              </div>
              <div className="col-xs-4 col-sm-6 col-md-6 item">
                <button className="click-item" onClick={this.props.sendToChat}>
                  <i className="icon-speech-bubble icon-cpc"></i>
                  <span className="action-name">Gửi Chat</span>
                </button>
              </div>
            </div>
          )}
          {this.props.status && (
            <div className="row list-action-detail">
              {isCanUpdateAssignment &&
                this.state.receiveDocument &&
                this.state.receiveDocument.trangThai !== CHUA_XU_LY &&
                this.state.receiveDocument.trinhLanhDao !== null && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_CHIDAO]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickUpdateAssignment}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Giao lại</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}
              {!get(this.props.auth, 'permission', []).includes(
                'CONGVANDEN_DEXUATCHIDAO'
              ) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button className="click-item" onClick={this.openPopup}>
                    <span
                      ref={el => {
                        if (el) {
                          el.style.setProperty(
                            'font-family',
                            'Icons20',
                            'important'
                          )
                        }
                      }}
                      className="pt-icon pt-icon-trash icon-cpc"
                    ></span>
                    <span className="action-name">Huỷ báo nhầm</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.closePopup}
          title={POPUP.HUY_CHUYEN_NHAM}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitRemoveItem}
        >
          {POPUP.HUY_BAO_NHAM_CONFIRM}
        </PopupConfirm>
      </div>
    )
  }
}

export default DetailBoxAction
