import React from 'react'
import { MasterLayout } from '../../components/layout'
import {
  DetailInfo,
  DetailBoxAction,
  SendAdditional,
  RestoreArchive,
  FlowSendDocumentary,
} from '../../components/SendDocument'
import { Permission } from '../../components/auth'
import {
  DetailFileContent,
  ExecutingView,
  ExecutingViewEvn,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../actions'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { SendMail } from '../../components/ReceiveDocument'
import { PopupConfirm } from '../../components/common/'
import {
  DE_XUAT_THU_HOI_THANH_CONG,
  DE_XUAT_THU_HOI_KHONG_THANH_CONG,
} from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { isValidCondition } from '../../helpers/Helper'
import { concat, isEmpty } from 'lodash'
import { variables } from 'constants/variables'

class DetailSendDocumentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showListAction: true,
      showDetailInfo: false,
      sendDocumentId: this.props.match.params.id,
      isShowMobileRestoreArchive: false,
      sendDocument: null,
      file: null,
      executingViews: [],
      executingViewsEvn: [],
      isLoadingData: {
        sendDocument: true,
      },
      isLoadingMigratedFile: true,
      isLoadingSubData: false,
      info_SendDocument: null,
      infoSendAdditional: null,
      isCheckApi: false,
      receiveUnits: null,
      attachedUnits: null,
      otherUnits: null,
      connectedUnit: null,
      connectedUnitEVN: null,
      departments: null,
      relativeUnits: null,
      isShowAttachedUnits: true,
      isShowOtherUnits: true,
      boxMenuFixTop: false,
      isOpenPopup: false,
      isCheckDeXuatThuHoi: true,
      popupModel: {
        title: POPUP.DE_XUAT_THU_HOI,
        sumary: '',
        textButton: POPUP.TEXT_BUTTON_SUBMIT,
        func: this.handleSubmitDeXuatThuHoi,
        textarea: true,
        showTextWarning: false,
        acceptSpace: true,
      },
      isShowConnectedUnitEVN: true,
      buttonLoading: false,
      filesThamKhao: [],
    }

    this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleClickSendAdditional = this.handleClickSendAdditional.bind(this)
    this.handleClickExecutingView = this.handleClickExecutingView.bind(this)
    this.handleClickRestoreArchive = this.handleClickRestoreArchive.bind(this)
    this.handleClickExecutingViewReceiveDocument =
      this.handleClickExecutingViewReceiveDocument.bind(this)
    this.handleClickExecutingViewEvn =
      this.handleClickExecutingViewEvn.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickFlowdocumentary = this.handleClickFlowdocumentary.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  componentDidMount() {
    // Thông tin đơn vị
    let isShowAttachedUnits = true
    let isShowOtherUnits = true
    let isShowConnectedUnit = true
    let isShowConnectedUnitEVN = true
    if (this.props.auth && this.props.auth.mainUnitId) {
      this.props.actions
        .getCommonFetchUnitItem(this.props.auth.mainUnitId)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            isShowAttachedUnits = res.payload.data.result.guiDonViTrucThuoc
            isShowOtherUnits = res.payload.data.result.guiDonViKhac
            isShowConnectedUnit = res.payload.data.result.guiDonViLienThong
            isShowConnectedUnitEVN =
              res.payload.data.result.guiDonViLienThongEvn
          }
          this.setState({
            isShowAttachedUnits,
            isShowOtherUnits,
            isShowConnectedUnit,
            isShowConnectedUnitEVN,
          })
        })
    }
    window.addEventListener('scroll', this.handleScroll)
    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }

    this.props.actions
      .sendDocumentDetail(this.state.sendDocumentId)
      .then(() => {
        if (this.props.sendDocuments && this.props.sendDocuments.item) {
          this.setState({
            sendDocument: this.props.sendDocuments.item,
            file: this.props.sendDocuments.item.fileNoiDung,
            isLoadingData: {
              ...this.state.isLoadingData,
              sendDocument: false,
            },
          })

          let params = {
            cong_van_di_id: this.state.sendDocumentId,
          }
          this.props.actions.checkCongVanDaDeXuatThuHoi(params).then(res => {
            if (res.error) {
              this.setState({
                isCheckDeXuatThuHoi: true,
              })
            } else {
              let checkPhongBanLap = []
              let isCheckDeXuatThuHoi = true
              checkPhongBanLap =
                this.props.auth.user &&
                this.props.auth.user.dsChucDanh &&
                this.props.auth.user.dsChucDanh.filter(
                  element =>
                    element.phongBan.phongBanId ===
                    this.props.sendDocuments.item.phongBanLap.phongBanId
                )

              if (
                isValidCondition(
                  this.props.auth.permission,
                  [Permission.CONGVANDI_QUANLY],
                  'AND'
                )
              ) {
                isCheckDeXuatThuHoi = res.payload.data
              } else if (
                isValidCondition(
                  this.props.auth.permission,
                  [Permission.DEXUAT_THUHOI],
                  'AND'
                ) &&
                checkPhongBanLap.length !== 0
              ) {
                isCheckDeXuatThuHoi = res.payload.data
              } else if (
                isValidCondition(
                  this.props.auth.permission,
                  [Permission.DEXUAT_THUHOI],
                  'AND'
                ) &&
                checkPhongBanLap.length === 0
              ) {
                isCheckDeXuatThuHoi = true
              }

              this.setState({
                isCheckDeXuatThuHoi,
              })
            }
          })
          // Gọi api get file tham khảo
          this.getFileThamKhao(this.props.sendDocuments.item).then(res => {
            if (res && res.length !== 0) {
              this.setState({
                filesThamKhao: res,
              })
            }
          })
        }
      })
    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.sendDocuments.item !== this.props.sendDocuments.item &&
      this.props.sendDocuments &&
      this.props.sendDocuments.item
    ) {
      this.setState({ sendDocument: this.props.sendDocuments.item })
    }

    if (
      prevProps.executingViews.items !== this.props.executingViews.items &&
      this.props.executingViews &&
      this.props.executingViews.items
    ) {
      this.setState({ executingViews: this.props.executingViews.items })
    }
  }

  // Get danh sách files tham khảo
  getFileThamKhao = async data => {
    let params = {
      loai: 1,
    }
    let id = -1
    if (data.congVanId) {
      params = {
        ...params,
        type: 'CONG_VAN',
      }
      id = data.congVanId
    } else {
      return []
    }

    let filesThamKhao = []
    await this.props.actions.getDanhSachFiles(id, params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length !== 0
      ) {
        res.payload.data.result.items.map(elm =>
          filesThamKhao.push({
            ...elm,
            is_existed: true,
          })
        )
      }
    })

    return filesThamKhao
  }

  handleClickGoBack = () => {
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickGoBackList = () => {
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'DanhSachDonViNhan',
    })
  }

  handleClickSendAdditional = async e => {
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'GuiBoSung',
    })
    window.scrollTo(0, 0)
    let {
      infoSendAdditional,
      departments,
      receiveUnits,
      attachedUnits,
      otherUnits,
      isShowOtherUnits,
      connectedUnit,
      isShowConnectedUnit,
      connectedUnitEVN,
      isShowConnectedUnitEVN,
    } = this.state

    if (
      !infoSendAdditional ||
      !departments ||
      !receiveUnits ||
      !attachedUnits ||
      !otherUnits ||
      !connectedUnit ||
      !connectedUnitEVN
    ) {
      Promise.all([
        !departments &&
          this.props.actions.commonFetchDepartments().then(res => {
            this.setState({
              departments: this.props.departments || null,
            })
          }),
        // Đơn vị nhận
        !receiveUnits &&
          this.props.actions
            .commonFetchReceiveUnits(this.props.auth.mainUnitId)
            .then(res => {
              this.setState({
                receiveUnits: this.props.receiveUnits || null,
              })
            }),
        // Đơn vị trực thuộc
        !attachedUnits &&
          this.props.actions
            .commonFetchAttachedUnits(this.props.auth.mainUnitId)
            .then(res => {
              this.setState({
                attachedUnits: this.props.attachedUnits || null,
              })
            }),
        // Đơn vị khác
        isShowOtherUnits &&
          !otherUnits &&
          this.props.actions
            .getOtherUnit(this.props.auth.mainUnitId)
            .then(res => {
              this.setState({
                otherUnits: this.props.otherUnits
                  ? this.props.otherUnits.items
                  : null,
              })
            }),
        // Đơn vị liên thông
        isShowConnectedUnit &&
          !connectedUnit &&
          this.props.actions
            .getConnectedUnit(this.props.auth.mainUnitId)
            .then(res => {
              this.setState({
                connectedUnit: this.props.connectedUnit
                  ? this.props.connectedUnit.items
                  : null,
              })
            }),
        // Đơn vị liên thông EVN
        isShowConnectedUnitEVN &&
          !connectedUnitEVN &&
          this.props.actions
            .getConnectedUnitEVN(this.props.auth.mainUnitId)
            .then(res => {
              this.setState({
                connectedUnitEVN: this.props.connectedUnitEVN
                  ? this.props.connectedUnitEVN.items
                  : null,
              })
            }),
        !infoSendAdditional &&
          this.props.actions
            .getSendDocumentAdditionalInformation(this.props.match.params.id)
            .then(res => {
              if (res.payload && res.payload.data && res.payload.data.result) {
                this.setState({
                  infoSendAdditional: res.payload.data.result,
                })
              }
            }),
      ]).then(() => {
        this.setState({
          isLoadingSubData: false,
          showResults: true,
        })
      })
    } else {
      this.setState({
        isLoadingSubData: false,
        showResults: true,
      })
    }
  }
  handleClickRestoreArchive = async () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'DanhSachDonViNhan',
    })
    window.scrollTo(0, 0)
    if (!this.state.infoSendAdditional) {
      await this.props.actions
        .getSendDocumentAdditionalInformation(this.props.match.params.id)
        .then(res => {
          let infoSendAdditional = null
          if (res.payload && res.payload.data && res.payload.data.result) {
            infoSendAdditional = res.payload.data.result
          }
          return this.setState({
            infoSendAdditional,
            showResults: true,
            isLoadingSubData: false,
          })
        })
    }
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
    })
  }

  handleClickExecutingView = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'XemThucHien',
    })
    window.scrollTo(0, 0)
  }

  handleClickExecutingViewReceiveDocument = id => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHienCVD',
    })
    window.scrollTo(0, 0)
    this.props.actions.getExecutingView(id).then(res => {
      this.setState({
        showResults: true,
        isLoadingSubData: false,
      })
    })
  }

  handleClickExecutingViewEvn = id => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHienEvn',
    })
    window.scrollTo(0, 0)
    this.props.actions.getExecutingViewEvn(id).then(res => {
      if (
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length > 0
      ) {
        this.setState({
          showResults: true,
          isLoadingSubData: false,
          executingViewsEvn: res.payload.data.result.items,
        })
      } else {
        this.setState({
          showResults: true,
          isLoadingSubData: false,
        })
      }
    })
  }

  handleClickDetailInfo = async () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinCongVan',
    })
    window.scrollTo(0, 0)
    if (!this.state.info_SendDocument) {
      await this.props.actions
        .getSendDocumentItem(this.props.match.params.id)
        .then(res => {
          return this.setState({
            showResults: true,
            isLoadingSubData: false,
            info_SendDocument: res.payload.data.result
              ? res.payload.data.result
              : null,
          })
        })
    }
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
    })
  }

  handleClickFlowdocumentary = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuongCongVan',
    })
    if (this.state.sendDocumentId && !this.state.isCheckApi) {
      await this.props.actions
        .getFlowDocumentarySendDocument(this.state.sendDocumentId)
        .then(() => {
          return this.setState({
            showResults: true,
            isLoadingSubData: false,
            isCheckApi: true,
          })
        })
    }
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  /**
   * click đề xuất thu hồi
   * params {e} prevent Default
   * @memberof DetailSendDocumentPage
   */
  handleClickDeXuatThuHoi = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  /**
   * Click close popup
   * @memberof DetailSendDocumentPage
   */
  handleClickClosePopup = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
  }

  /**
   * handle handleSubmitDeXuatThuHoi
   * @memberof DetailSendDocumentPage
   */
  handleSubmitDeXuatThuHoi = async (lyDo, e) => {
    e.preventDefault()
    try {
      this.setState({
        buttonLoading: true,
      })
      const params = {
        cong_van_di_id: this.props.match.params.id,
        ly_do_thu_hoi: lyDo,
      }
      const data = await this.props.actions.postDeXuatThuHoiCongVanDi(params)
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: DE_XUAT_THU_HOI_KHONG_THANH_CONG,
          intent: Intent.DANGER,
        })
      }

      const thongBaoThanhCong = this.props.actions.commonAddToasterMessage({
        message: DE_XUAT_THU_HOI_THANH_CONG,
        intent: Intent.SUCCESS,
      })
      if (thongBaoThanhCong) {
        this.props.actions.getTongSoCongVanThuHoi()
        return this.goBack()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DE_XUAT_THU_HOI_KHONG_THANH_CONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        buttonLoading: false,
        isOpenPopup: false,
      })
    }
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      isShowMobileRestoreArchive: !this.state.isShowMobileRestoreArchive,
    })
  }

  goBack() {
    this.props.history.goBack()
  }

  handleSubmit = params => {
    switch (params.title) {
      case 'ThuHoiCongVan':
        if (params.isSuccess !== undefined && params.message && params.intent) {
          if (params.isSuccess === false) {
            return this.props.actions.commonAddToasterMessage({
              message: params.message,
              intent: params.intent,
            })
          }
          // Refresh danh sach cong van di
          if (!this.props.config.refreshSendDocument) {
            this.props.actions.refreshSendDocument(true)
          }
          this.props.actions.commonAddToasterMessage({
            message: params.message,
            intent: params.intent,
          })
          this.props.actions
            .getSendDocumentAdditionalInformation(this.state.sendDocumentId)
            .then(res => {
              if (res.payload && res.payload.data && res.payload.data.result) {
                this.setState({
                  infoSendAdditional: res.payload.data.result,
                  isCheckApi: false,
                })
              }
            })
          // this.props.actions.getSendDocumentExecutingView(this.state.sendDocumentId);
        }
        break

      default:
        if (params.isSuccess !== undefined && params.message && params.intent) {
          if (params.isSuccess === false) {
            return this.props.actions.commonAddToasterMessage({
              message: params.message,
              intent: params.intent,
            })
          }
          window.scrollTo(0, 0)
          // Refresh danh sach cong van di
          if (!this.props.config.refreshSendDocument) {
            this.props.actions.refreshSendDocument(true)
          }
          this.props.actions.commonAddToasterMessage({
            message: params.message,
            intent: params.intent,
          })
          this.props.actions
            .getSendDocumentAdditionalInformation(this.state.sendDocumentId)
            .then(res => {
              if (res.payload && res.payload.data && res.payload.data.result) {
                this.setState({
                  infoSendAdditional: res.payload.data.result,
                  isCheckApi: false,
                })
              }
            })
          // this.props.actions.getSendDocumentExecutingView(this.state.sendDocumentId);
          if (params.sendEVN === undefined) {
            this.setState({
              showResults: false,
              showListAction: true,
              showSection: 'noContent',
            })
          }
        }
    }
  }

  handleSubmitMail = (title, msg, intent, isSuccess, id = 0) => {
    this.props.actions.commonAddToasterMessage({ message: msg, intent: intent })
    if (isSuccess) {
      this.props.actions.refreshPage(true)
      this.goBack()
    }
  }

  handleClickMoreDepartmentExecutingView = id => {
    this.props.actions.getMoreFlowDocumentaryReceiveDocument(id)
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    let { sendDocuments } = this.props
    if (sendDocuments && sendDocuments.item) {
      let fileNoiDung = sendDocuments.item.fileNoiDung
        ? sendDocuments.item.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = sendDocuments.item.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = sendDocuments.item.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url = `?fileId=${fileNoiDung}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    }
  }

  render() {
    let receiveUnits = this.state.receiveUnits
    if (this.state.isShowAttachedUnits === false) {
      receiveUnits = concat(this.state.receiveUnits, this.state.attachedUnits)
    }
    const { isCheckDeXuatThuHoi } = this.state
    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="detail-receive-document detail-send-document">
          {/* <DetailInfo
            item = {this.props.sendDocuments.item}
            showDetailInfo = {this.state.showDetailInfo}
            closeShowDetailInfo={this.closeShowDetailInfo}
          /> */}
          {this.state.isLoadingData &&
          Object.values(this.state.isLoadingData).find(
            item => item === true
          ) !== undefined ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">Công văn đi:</span>
                <span className="code">
                  {this.state.sendDocument && this.state.sendDocument.soCongVan}
                </span>
              </span>
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(this.state.file) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={this.state.file}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content">
                  {this.state.showListAction && (
                    <div className="full-box-group">
                      <PopupConfirm
                        isOpen={this.state.isOpenPopup}
                        onClose={this.handleClickClosePopup}
                        title={this.state.popupModel.title}
                        text={this.state.popupModel.textButton}
                        onSubmit={this.state.popupModel.func}
                        textarea={this.state.popupModel.textarea}
                        showTextWarning={this.state.popupModel.showTextWarning}
                        isLoading={this.state.buttonLoading}
                        acceptSpace={this.state.popupModel.acceptSpace}
                      >
                        {this.state.popupModel.sumary}
                      </PopupConfirm>
                      <DetailBoxAction
                        auth={this.props.auth}
                        onClickSendAdditional={this.handleClickSendAdditional}
                        onClickExecutingView={this.handleClickExecutingView}
                        onClickRestoreArchive={this.handleClickRestoreArchive}
                        onClickDetailInfo={this.handleClickDetailInfo}
                        isShowMobile={this.state.isShowMobileRestoreArchive}
                        closeModulesDetail={this.closeModulesDetail}
                        onClickGoBack={this.handleClickGoBack}
                        executingViews={this.state.sendDocument}
                        actions={this.props.actions}
                        onClickExecutingViewReceiveDocument={
                          this.handleClickExecutingViewReceiveDocument
                        }
                        sendDocumentId={this.state.sendDocumentId}
                        sendDocument={
                          this.props.sendDocuments &&
                          this.props.sendDocuments.item
                            ? this.props.sendDocuments.item
                            : {}
                        }
                        onSubmit={this.handleSubmit}
                        isLoadingData={this.state.isLoadingData}
                        onClickFlowdocumentary={this.handleClickFlowdocumentary}
                        guiEvn={
                          this.state.sendDocument &&
                          this.state.sendDocument.guiEvn
                        }
                        sendToChat={this.sendToChat}
                        onClickSendMail={this.handleClickSendMail}
                        onClickDeXuatThuHoi={this.handleClickDeXuatThuHoi}
                        isCheckDeXuatThuHoi={isCheckDeXuatThuHoi}
                        filesThamKhao={this.state.filesThamKhao}
                        quyensMenu={this.props.quyensMenu}
                      />
                    </div>
                  )}

                  {!this.state.showListAction &&
                    this.state.isLoadingSubData && (
                      <div className="loading-container">
                        <LoadingItem />
                      </div>
                    )}

                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'GuiBoSung' && (
                      <SendAdditional
                        onClickGoBack={this.handleClickGoBack}
                        receiveUnits={receiveUnits}
                        attachedUnits={this.state.attachedUnits}
                        otherUnits={this.state.otherUnits}
                        connectedUnit={this.state.connectedUnit}
                        connectedUnitEVN={this.state.connectedUnitEVN}
                        departments={this.state.departments}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        sendDocumentId={this.state.sendDocumentId}
                        sendDocument={this.state.infoSendAdditional}
                        isShowAttachedUnits={this.state.isShowAttachedUnits}
                        isShowOtherUnits={this.state.isShowOtherUnits}
                        isShowConnectedUnit={this.state.isShowConnectedUnit}
                        isShowConnectedUnitEVN={
                          this.state.isShowConnectedUnitEVN
                        }
                        mainUnitId={
                          this.props.auth && this.props.auth.mainUnitId
                        }
                        parentUnitId={
                          this.props.auth && this.props.auth.parentUnitId
                        }
                        auth={this.props.auth}
                      />
                    )}

                  {/* { this.state.showResults && this.state.showSection === 'XemThucHien' &&
                    <div>
                      <ExecutingViewInternalDocument
                        onClickGoBack={this.handleClickGoBack}
                        executingViews={this.state.executingViews}
                        onClickExecutingViewReceiveDocument={this.handleClickExecutingViewReceiveDocument}
                        actions={this.props.actions}
                      />
                    </div>
                  } */}

                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <div className="box-action-item">
                        <SendMail
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmitMail}
                          action={this.props.actions}
                          receiveDocument={
                            this.props.sendDocuments &&
                            this.props.sendDocuments.item
                              ? this.props.sendDocuments.item
                              : {}
                          }
                        />
                      </div>
                    )}

                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'XemThucHienCVD' && (
                      <div>
                        <ExecutingView
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBackList}
                          onClickDepartment={
                            this.handleClickDepartmentExecutingView
                          }
                          executingViews={
                            this.props.executingViewsReceiveDocument.items
                          }
                          actions={this.props.actions}
                        />
                      </div>
                    )}

                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'XemThucHienEvn' && (
                      <div>
                        <ExecutingViewEvn
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          executingViews={this.state.executingViewsEvn}
                          actions={this.props.actions}
                        />
                      </div>
                    )}

                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'ThongTinCongVan' && (
                      <div>
                        <DetailInfo
                          onClickGoBack={this.handleClickGoBack}
                          sendDocuments={this.state.info_SendDocument}
                          securities={this.props.securities}
                          priorities={this.props.priorities}
                          objects={this.props.objects}
                        />
                      </div>
                    )}

                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'DanhSachDonViNhan' && (
                      <RestoreArchive
                        onClickGoBack={this.handleClickGoBack}
                        isShowMobile={this.state.isShowMobileRestoreArchive}
                        onClickRestoreArchive={this.handleClickRestoreArchive}
                        closeModulesDetail={this.closeModulesDetail}
                        infoSendAdditional={this.state.infoSendAdditional}
                        onClickExecutingViewEvn={
                          this.handleClickExecutingViewEvn
                        }
                        onClickExecutingViewReceiveDocument={
                          this.handleClickExecutingViewReceiveDocument
                        }
                        actions={this.props.actions}
                        sendDocumentId={this.state.sendDocumentId}
                        onSubmit={this.handleSubmit}
                        auth={this.props.auth}
                        isShowConnectedUnit={this.state.isShowConnectedUnit}
                        isShowConnectedUnitEVN={
                          this.state.isShowConnectedUnitEVN
                        }
                      />
                    )}

                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'LuongCongVan' && (
                      <div className="box-action-item">
                        <FlowSendDocumentary
                          isLoadingDataSub={this.state.isLoadingDataSub}
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          flowDocument={
                            this.props.executingViews.flowSendDocument
                          }
                          closeModulesDetail={this.closeModulesDetail}
                          onClickExecutingView={this.handleClickExecutingView}
                          onClickDepartment={
                            this.handleClickMoreDepartmentExecutingView
                          }
                          flowReceiveDocument={
                            this.props.executingViews.flowDocuments
                          }
                          actions={this.props.actions}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

DetailSendDocumentPage.propTypes = {
  sendDocuments: PropTypes.object.isRequired,
  receiveUnits: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  config: state.common.config,
  auth: state.auth,
  sendDocuments: {
    ...state.sendDocuments,
    item: state.sendDocuments.detailId
      ? state.entities.sendDocuments[state.sendDocuments.detailId]
      : {},
  },
  executingViews: {
    ...state.executingViews,
    // items: state.executingViews.items_sendDocument !== null && state.executingViews.items_sendDocument,
    flowDocuments: Object.keys(state.executingViews.flowDocuments).map(key => {
      const item = state.executingViews.flowDocuments[key]
      return {
        ...item,
        nguoiTaoCongVan: state.entities.users[item.nguoiTaoCongVan],
        chiDao: {
          ...state.entities.executingViews[item.chiDao],
          dsDonViTrucThuocResponse: (() => {
            if (!state.entities.executingViews[item.chiDao]) {
              return []
            }
            const getDSDonVi = dsDonViTrucThuocResponse => {
              return dsDonViTrucThuocResponse.map(item => {
                let subItem = state.entities.executingViews[item.chiDaoId]
                let flowDocument =
                  state.executingViews.flowDocuments[item.chiDaoId]
                return {
                  ...item,
                  executingViews: [
                    subItem
                      ? {
                          ...subItem,
                          dsDonViTrucThuocResponse: getDSDonVi(
                            subItem.dsDonViTrucThuocResponse
                          ),
                        }
                      : undefined,
                  ],
                  flowDocument: {
                    ...flowDocument,
                    nguoiTaoCongVan: flowDocument
                      ? state.entities.users[flowDocument.nguoiTaoCongVan]
                      : undefined,
                  },
                }
              })
            }
            return getDSDonVi(
              state.entities.executingViews[item.chiDao]
                .dsDonViTrucThuocResponse
            )
          })(),
        },
      }
    }),
  },
  executingViewsReceiveDocument: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]
        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),
  },
  departments: state.common.departments,
  receiveUnits: state.common.receiveUnits,
  attachedUnits: state.common.attachedUnits,
  otherUnits: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
  connectedUnit: {
    items: state.category.categoryConnectedUnit,
  },
  connectedUnitEVN: {
    items: state.category.categoryConnectedUnitEVN,
  },
  securities: state.common.securities,
  priorities: state.common.priorities,
  objects: state.common.objects,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailSendDocumentPage)
