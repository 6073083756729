import * as types from '../constants/ActionTypes'

const initialState = {
  item: null,
}

export default function proposeAssignment(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENT_RECEIVE_FETCH_PROPOSE_ASSIGNMENT:
      return {
        ...state,
        item: action.payload.data.result,
      }

    default:
      return state
  }
}
