import React, {
  memo,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'
import classNames from 'classnames'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Menu,
  MenuItem,
  Icon,
  Button,
} from '@blueprintjs/core'

import requestAction from 'helpers/request'
import { WORK_STATUS, FILE_TYPE, WORK_MEETING_STATUS } from 'constants/Enum'
import { Toast } from 'constants/MessageForm'
import {
  getCongViec,
  showToast,
  getInformationWorkItemAction,
} from 'actions/task'
import Comment from './Comment'
import {
  StatusDate,
  Scrollbar,
  StatusTag,
  PrioritizeFlag,
  UserCard,
  CollapseMore,
  FileGroup,
  TagGroup,
  Loading,
  Icon as Icon2,
  Dialog,
} from 'components/newCommon'
import { Progress } from 'components/newCommon2'
import { ViewMoreDots } from 'components/common'
import Fragment from 'components/common/Fragment'

const dateFormat = 'DD/MM/YYYY'

const WorkMeetingDetail = ({ history, match, roleId, onClose = () => {} }) => {
  const pageActions = {
    flowWorks: 'flow-works',
    chinhSuaCongViec: 'chinh-sua-cong-viec',
    huyDuyet: 'huy-duyet',
    xoa: 'xoa',
    nhacViec: 'nhac-viec',
    tag: 'tag',
    xacNhan: 'xac-nhan',
    acceptHistory: 'lich-su-xac-nhan',
  }
  const mainPathname = useMemo(
    () => '/thong-bao-ket-luan-cuoc-hop/chi-tiet',
    []
  )
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const { actionId, id: meetingId } = match.params
  const [fetching, setFetching] = useState(false)
  const [detail, setDetail] = useState({})
  const [userMention, setUserMention] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [informationWorkItemAction, setInformationWorkItemAction] = useState({
    isAccept: false,
  })

  const _splitDetailFiles = useCallback(data => {
    return {
      ...data,
      referenceFile: (data?.files || []).filter(
        file => file.type === FILE_TYPE.FILE_THAM_KHAO
      ),
      files: (data?.files || []).filter(
        file => file.type === FILE_TYPE.FILE_DINH_KEM
      ),
    }
  }, [])

  const getUsersInTaskDetail = data => {
    return uniqBy(
      data?.map(e => ({
        id: e?.id,
        display: e?.name,
      })),
      'id'
    )
  }

  const fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getCongViec(actionId),
      afterResponse: (result = {}) => {
        mountedSet(setDetail, _splitDetailFiles(result))
        mountedSet(setUserMention, getUsersInTaskDetail(result?.relatedStaff))
      },
      afterAction: () => mountedSet(setFetching, false),
      afterError: () => {
        showToast({
          message: Toast.NOT_FOUND('công việc'),
          intent: Intent.WARNING,
        })
        onClose()
      },
    })
  }, [actionId, setDetail, _splitDetailFiles, onClose])

  const getDeadline = detail => {
    return get(detail, 'deadline') || get(detail, 'originalDeadline')
  }

  const goToAction = useCallback(
    (id, action) => () => {
      const url = `${mainPathname}/${meetingId}/${action}/${id}`
      return history.push({
        pathname: url,
        state: {
          showGoBack: true,
        },
      })
    },
    [history, mainPathname, meetingId]
  )

  const getMenu = useCallback(() => {
    const { creatorProfileId, approval, status, id } = detail
    const allowAction = creatorProfileId === roleId
    const { xoa, flowWorks, nhacViec, tag, acceptHistory } = pageActions
    let isApprove = false
    let isAbleDeleted = false
    if (approval === WORK_MEETING_STATUS.APPROVED) {
      isApprove = true
    }

    if (allowAction && !isApprove && status === WORK_STATUS.CHUA_THUC_HIEN) {
      isAbleDeleted = true
    }

    return [
      {
        text: (
          <>
            <i className="icon-organization font-size-13" /> Luồng công việc
          </>
        ),
        onClick: goToAction(id, flowWorks),
      },
      {
        text: (
          <>
            <i className="icon-thoigianluu font-size-15" /> Lịch sử xác nhận
          </>
        ),
        onClick: goToAction(id, acceptHistory),
      },
      ...(allowAction
        ? [
            {
              text: (
                <>
                  <i className="icon2-bell size-icon-15" /> Nhắc việc
                </>
              ),
              onClick: goToAction(id, nhacViec),
            },
            {
              text: (
                <>
                  <i className="icon-tag size-icon-10" /> Loại công việc (tags)
                </>
              ),
              onClick: goToAction(id, tag),
            },
          ]
        : []),
      ...(isAbleDeleted
        ? [
            {
              text: (
                <>
                  <i className="icon-bin" /> Xóa kết luận
                </>
              ),
              onClick: goToAction(id, xoa),
            },
          ]
        : []),
    ]
  }, [detail, roleId, pageActions, goToAction])

  const renderStatus = values => {
    return values?.status
  }

  const renderApproval = values => {
    let text = ''
    let type = ''
    if (values?.approval === 'APPROVED') {
      text = 'Đã Duyệt'
      type = 'APPROVED'
    } else if (values?.approval === 'WAITING_APPROVAL') {
      text = 'Chờ Duyệt'
      type = 'WAITING_APPROVAL'
    }

    return {
      type: type,
      text: text,
    }
  }

  const handleCheckWorkItemAction = useCallback(() => {
    setInformationWorkItemAction({
      isAccept: false,
    })
    requestAction({
      showToast: false,
      action: () => getInformationWorkItemAction(actionId),
      afterResponse: res => {
        setInformationWorkItemAction(res)
      },
    })
  }, [actionId])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    handleCheckWorkItemAction()
  }, [handleCheckWorkItemAction])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  const attachmentFilesNotFromDocument = !!size(get(detail, 'files'))
    ? detail.files.filter(elm => elm.application !== 'eOffice')
    : []
  const coordinations =
    detail?.responsibilities?.[1]?.workAssignments?.map(
      elm => elm.responsibility?.shortName
    ) || []
  const executor = detail?.responsibilities?.[0]?.workAssignments?.[0]
  const allowAction = detail?.creatorProfileId === roleId
  const isAccept = informationWorkItemAction?.isAccept
  let isGoBack = true
  let isApprove = false
  let isEdit = false
  let isAction = false

  if (detail?.approval === WORK_MEETING_STATUS.APPROVED && allowAction) {
    isApprove = true
  }

  if (allowAction && !isApprove) {
    isEdit = true
  }

  if (isGoBack || isApprove || isEdit || isAccept) {
    isAction = true
  }

  return (
    <div className={classNames('cpc-side-action', 'open')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>Chi tiết kết luận</span>
        <Icon2
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - (isAction ? 181 : 106)}>
        <div style={{ padding: 10 }}>
          {fetching ? (
            <Loading />
          ) : (
            <div className="cpc-detail">
              <div className="cpc-detail-block">
                {!!size(getMenu()) && (
                  <span style={{ float: 'right' }}>
                    <Popover
                      popoverClassName="cpc-popover no-arrow mr10"
                      position={Position.BOTTOM_RIGHT}
                      interactionKind={PopoverInteractionKind.CLICK}
                      content={
                        <Menu className="cpc-context-menu no-border">
                          {getMenu().map(
                            ({ text = '', onClick = () => {} }, index) => (
                              <MenuItem
                                key={index}
                                onClick={onClick}
                                text={
                                  <span className="cpc-context-menu-item">
                                    {text}
                                  </span>
                                }
                              />
                            )
                          )}
                        </Menu>
                      }
                    >
                      <Icon className="menu-button" iconName="more" />
                    </Popover>
                  </span>
                )}
                <div className={classNames('pb10', 'border-bottom', 'mb10')}>
                  {get(detail, 'workType') === 'MEETING' && (
                    <span
                      className={classNames(
                        'uppercase',
                        'font-size-10',
                        'font-weight-600',
                        'text-yellow'
                      )}
                    >
                      Từ thông báo kết luận cuộc họp
                    </span>
                  )}
                  <h3
                    className={classNames(
                      'font-size-13',
                      'font-weight-600',
                      'pr20'
                    )}
                  >
                    {get(detail, 'title')}
                  </h3>
                  <TagGroup
                    tags={get(detail, 'workUserTags', []).map(
                      ({ workTagName, backgroundColor }) => ({
                        name: workTagName,
                        color: backgroundColor,
                      })
                    )}
                    popoverPosition={Position.BOTTOM_RIGHT}
                    popoverClassName="mr15 mt5"
                    className="mb10"
                  />
                  {detail?.requireReport && (
                    <p className="font-size-13">
                      <Icon2
                        classIcon="icon-Bao_Cao"
                        className="font-size-10 mr5"
                      />
                      Yêu cầu báo cáo Hoàn thành
                    </p>
                  )}
                </div>
                <div
                  className={classNames(
                    'd-flex',
                    'pb10',
                    'border-bottom',
                    'mb10'
                  )}
                >
                  <div>
                    {(getDeadline(detail) ||
                      (get(detail, 'finishedDate') &&
                        get(detail, 'status') === 'DONE')) && (
                      <span className="cpc-detail-left" style={{ flex: 1 }}>
                        {getDeadline(detail) && (
                          <div>
                            <label className="cpc-detail-label">
                              Hạn thực hiện:
                            </label>
                            <StatusDate
                              className="cpc-detail-text"
                              date={getDeadline(detail)}
                              descriptionInline
                              showDescription={false}
                              isCompleted={get(detail, 'status') === 'DONE'}
                            />
                          </div>
                        )}
                      </span>
                    )}
                    <div>
                      {(size(coordinations || []) !== 0 || executor) && (
                        <div>
                          <ul className="d-flex">
                            {executor && (
                              <li className="pb10">
                                <div>
                                  <label
                                    className={classNames(
                                      'cpc-detail-label',
                                      'mb5'
                                    )}
                                  >
                                    Chủ trì
                                  </label>
                                  <div className="font-size-13">
                                    {executor?.responsibility?.shortName}
                                  </div>
                                </div>
                              </li>
                            )}
                            {size(coordinations || []) !== 0 && (
                              <li className="pb10 ml40">
                                <div>
                                  <label
                                    className={classNames(
                                      'cpc-detail-label',
                                      'mb5'
                                    )}
                                  >
                                    Phối hợp
                                  </label>
                                  <div className="font-size-13">
                                    {
                                      <ViewMoreDots
                                        data={coordinations}
                                        isTotal={true}
                                      />
                                    }
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="cpc-detail-right text-right">
                    <div className="mb10">
                      <StatusTag type={renderStatus(detail)} />
                      {(detail?.approval === 'WAITING_APPROVAL' ||
                        detail?.approval === 'APPROVED') && (
                        <StatusTag
                          {...renderApproval(detail)}
                          className="ml25"
                        />
                      )}
                      <PrioritizeFlag
                        type={get(detail, 'priority')}
                        className="ml10"
                      />
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <label className={classNames('cpc-detail-label')}>
                        Tiến độ
                      </label>
                      <div style={{ width: 32 }}>
                        <Progress progress={get(detail, 'progress', 0)} />
                      </div>
                    </div>
                  </span>
                </div>
                <CollapseMore
                  textShow="Xem thêm"
                  textHide="Thu gọn"
                  limitDisplay={0}
                  buttonSpace={false}
                  defaultOpen
                >
                  <div className={classNames('border-bottom', 'mb10')}>
                    <div className="mb10">
                      <label className={classNames('cpc-detail-label', 'mb5')}>
                        Người giao
                      </label>
                      <UserCard
                        needBaseUrl
                        src={detail?.assigner?.avatar}
                        name={
                          detail?.assigner?.type === 'PERSONAL'
                            ? detail?.assigner?.name
                            : detail?.assigner?.shortName
                        }
                        department={
                          detail?.assigner?.information?.department?.shortName
                        }
                        unit={
                          detail?.assigner?.information?.organization?.shortName
                        }
                      />
                    </div>
                    <div className={classNames('d-flex', 'mb10')}>
                      {moment(get(detail, 'assignDate')).isValid() && (
                        <span style={{ minWidth: '33.33%' }}>
                          <label className="cpc-detail-label">Ngày giao:</label>
                          <span className="cpc-detail-text">
                            {moment(get(detail, 'assignDate')).format(
                              dateFormat
                            )}
                          </span>
                        </span>
                      )}
                      {moment(get(detail, 'executionDate')).isValid() && (
                        <span style={{ minWidth: '33.33%' }}>
                          <label className="cpc-detail-label">
                            Ngày bẳt đầu:
                          </label>
                          <span className="cpc-detail-text">
                            {moment(get(detail, 'executionDate')).format(
                              dateFormat
                            )}
                          </span>
                        </span>
                      )}
                      {moment(get(detail, 'originalDeadline')).isValid() && (
                        <span style={{ minWidth: '33.33%' }}>
                          <label className="cpc-detail-label">
                            Hạn thực hiện:
                          </label>
                          <span className="cpc-detail-text">
                            {moment(get(detail, 'originalDeadline')).format(
                              dateFormat
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                    {!!get(detail, 'description') && (
                      <div className="mb10">
                        <label className="cpc-detail-label">Mô tả</label>
                        <p className="cpc-detail-norm">
                          {get(detail, 'description')}
                        </p>
                      </div>
                    )}
                  </div>
                  {(!!size(get(detail, 'files', [])) ||
                    !!size(get(detail, 'referenceFile', []))) && (
                    <div
                      className={classNames('pb10', 'border-bottom', 'mb10')}
                    >
                      {!!size(attachmentFilesNotFromDocument) && (
                        <div>
                          <label className="cpc-detail-label">
                            File đính kèm
                          </label>
                          <FileGroup list={attachmentFilesNotFromDocument} />
                        </div>
                      )}
                    </div>
                  )}
                </CollapseMore>
              </div>
              <Comment users={userMention} />
            </div>
          )}
        </div>
      </Scrollbar>
      {isAction && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: '#f8f9fb',
            boxShadow: '-1px 0px 4px rgba(0,0,0,0.1)',
          }}
        >
          <div className="p10">
            <div className="text-center mt10 mb10">
              <Button
                className={classNames(
                  'cpc-button',
                  'btn-cancel',
                  'uppercase',
                  'font-size-13',
                  'ph10',
                  'min-width-100',
                  'mr10'
                )}
                disabled={fetching}
                onClick={onClose}
              >
                <Icon2 classIcon="icon-back" className="mr5" />
                Quay lại
              </Button>
              {isEdit && (
                <Fragment>
                  <Button
                    className={classNames(
                      'cpc-button',
                      'uppercase',
                      'font-size-13',
                      'ph10',
                      'min-width-100',
                      'mr10'
                    )}
                    intent={Intent.PRIMARY}
                    disabled={fetching}
                    onClick={
                      detail?.status !== WORK_STATUS.HOAN_THANH &&
                      detail?.status !== WORK_STATUS.HOAN_THANH_CHAM
                        ? goToAction(actionId, pageActions.chinhSuaCongViec)
                        : () => setOpenDialog(true)
                    }
                  >
                    <Icon2 classIcon="icon-but" className="font-size-12" />
                    <span className="ml5">Sửa/Hủy giao việc</span>
                  </Button>
                  <Dialog
                    buttonUppercase
                    isOpen={openDialog}
                    cancelClassName="pl20 pr20"
                    cancelText="Hủy"
                    confirmClassName="pl20 pr20"
                    confirmText="Sửa/Hủy"
                    loading={false}
                    onCancel={() => setOpenDialog(false)}
                    onConfirm={goToAction(
                      actionId,
                      pageActions.chinhSuaCongViec
                    )}
                    onClose={() => setOpenDialog(false)}
                    title={'Thông báo'}
                    textCenter
                  >
                    {
                      'Công việc đang chọn đã hoàn thành. Bạn có chắc chắn muốn sửa/hủy hay không?'
                    }
                  </Dialog>
                </Fragment>
              )}
              {isAccept && (
                <Button
                  className={classNames(
                    'cpc-button',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  intent={Intent.PRIMARY}
                  disabled={fetching}
                  onClick={goToAction(actionId, pageActions.xacNhan)}
                >
                  <span>Xác nhận</span>
                  <Icon2
                    classIcon="icon-Bao_Cao"
                    className="icon-size--11 ml5"
                  />
                </Button>
              )}
              {isApprove && (
                <Button
                  className={classNames(
                    'cpc-button',
                    'red-border',
                    'uppercase',
                    'font-size-13',
                    'ph10',
                    'min-width-100',
                    'mr10'
                  )}
                  disabled={fetching}
                  onClick={goToAction(actionId, pageActions.huyDuyet)}
                >
                  <span>Hủy duyệt</span>
                  <Icon2 classIcon="icon-Huy" className="font-size-12 ml5" />
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

WorkMeetingDetail.propTypes = {
  roleId: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    roleId: state?.auth?.roleId,
  }
}

export default withRouter(connect(mapStateToProps)(memo(WorkMeetingDetail)))
