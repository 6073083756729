import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItemMessenger,
  ListItemNotMessenger,
  TableView,
} from '../../components/Utilities/StatisticMessenger'
import { HeadingPage } from '../../components/common'
import { connect } from 'react-redux'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { headers } from '../../constants/Table'
import FileDownload from 'file-saver'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'
import { Authorization, Permission } from '../../components/auth'

class StatisticMessengerPage extends React.Component {
  constructor(props) {
    super(props)

    let isAdmin =
      Authorization.vaild(this.props.auth.permission, [Permission.ADMIN]) &&
      !Authorization.vaild(this.props.auth.permission, [Permission.SUPERADMIN])
    this.state = {
      filter: {
        startDate: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
      },
      page: 1,
      isShowMessengerStatistics: true,
      exporting: {
        hasMessenger: false,
        notMessenger: false,
      },
      isLoading: {
        hasMessenger: false,
        notMessenger: false,
      },
      isEmpty: {
        hasMessenger: false,
        notMessenger: false,
      },
      isError: {
        hasMessenger: false,
        notMessenger: false,
      },
      isAdmin,
      mainUnitId: this.props.auth && this.props.auth.mainUnitId,
    }

    this.submitFilter = this.submitFilter.bind(this)
  }

  componentDidMount() {
    this.props.actions.commonFetchDepartments()
    let state = this.state
    let preData = Tool.Utils.preData(
      `ListStatisticMessenger`,
      null,
      'thong-ke-tin-nhan'
    )
    if (preData) {
      state.filter = preData.filter
    } else if (this.props.match && !this.props.match.params.type) {
      state.filter = {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }
    }

    //check go back
    let isStatisticSubmit = Tool.Utils.getData('isStatisticMessenger')
    if (isStatisticSubmit) {
      Tool.Utils.saveData('isStatisticMessenger', false)
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  submitFilter(e = {}) {
    let state = this.state
    state.filter = Object.assign({}, state.filter, e)
    state.isShowMessengerStatistics = false
    state.isLoading = {
      hasMessenger: true,
      notMessenger: true,
    }

    const apiRequests = [
      {
        request: this.props.actions.getStatisticMessenger,
        type: 'hasMessenger',
        isAllowed: true,
      },
      {
        request: this.props.actions.getStatisticNotMessenger,
        type: 'notMessenger',
        isAllowed: true,
      },
    ]

    this.setState(state, () => {
      const filter = Object.assign({}, this.state.filter, e)
      let filterSubmit = {
        ...filter,
      }

      if (this.state.isAdmin) {
        filterSubmit = {
          ...filter,
          donViId: this.state.mainUnitId,
        }
      }
      Tool.Utils.preData(
        `ListStatisticMessenger`,
        { filter },
        'thong-ke-tin-nhan'
      )

      apiRequests.forEach(async item => {
        if (!item.isAllowed) {
          return
        }
        let request = item.request
        await request(filterSubmit, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = state.isEmpty
            let isError = state.isError
            isEmpty[item.type] = false
            isError[item.type] = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty[item.type] = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError[item.type] = true
            }

            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(true)
            let isLoading = this.state.isLoading
            isLoading[item.type] = false
            this.setState({ isLoading })
          })
      })
    })
  }

  handleExport(type) {
    if (!type) {
      return
    }

    let { filter, exporting, isAdmin, mainUnitId } = this.state
    if (isAdmin) {
      filter = {
        ...filter,
        donViId: mainUnitId,
      }
    }
    if (type === 'hasMessenger') {
      exporting[type] = true
      this.setState({ exporting }, () => {
        this.props.actions
          .exportStatisticMessenger(filter)
          .then(res => {
            if (res.error || !res.payload || !res.payload.data) {
              return this.props.actions.commonAddToasterMessage({
                message: 'Không xuất được file.',
                intent: Intent.DANGER,
              })
            }
            let preData = Tool.Utils.getData(`ListStatisticMessenger`)
            FileDownload.saveAs(
              res.payload.data,
              `thong-ke-tin-nhan-da-gui-${moment(
                preData.filter.startDate
              ).format('L')}-${moment(preData.filter.endDate).format('L')}.xls`
            )
          })
          .finally(() => {
            this.setState({
              exporting: {
                ...this.state.exporting,
                [type]: false,
              },
            })
          })
      })
    }

    if (type === 'notMessenger') {
      exporting[type] = true
      this.setState({ exporting }, () => {
        this.props.actions
          .exportStatisticNotMessenger(filter)
          .then(res => {
            if (res.error || !res.payload || !res.payload.data) {
              return this.props.actions.commonAddToasterMessage({
                message: 'Không xuất được file.',
                intent: Intent.DANGER,
              })
            }
            let preData = Tool.Utils.getData(`ListStatisticMessenger`)
            FileDownload.saveAs(
              res.payload.data,
              `thong-ke-nhan-vien-chua-tung-gui-tin-nhan-${moment(
                preData.filter.startDate
              ).format('L')}-${moment(preData.filter.endDate).format('L')}.xls`
            )
          })
          .finally(() => {
            this.setState({
              exporting: {
                ...this.state.exporting,
                [type]: false,
              },
            })
          })
      })
    }
  }

  render() {
    const isLoading =
      Object.values(this.state.isLoading).findIndex(item => item === true) > -1
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container page-statistic-signnumber">
          {/* Heading */}
          <HeadingPage
            namePage="Thống kê tin nhắn"
            iconPage="icon-Tong_Hop"
          ></HeadingPage>
          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              isLoading={isLoading}
              filter={this.state.filter}
              isForChildUnit={false}
            />

            {this.state.isShowMessengerStatistics && (
              <div className="table-container">
                <div className="no-border-bt">
                  <div className="error-table-hover">
                    <div className="error-page-container">
                      <div className="error-content">
                        <img
                          src={process.env.PUBLIC_URL + '/images/calendar.png'}
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">
                          {MESSAGE.MESSAGE_REQUIRED_STATISTICS}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.state.isShowMessengerStatistics && (
              <TableView
                heading="Thống kê tổng số tin nhắn"
                page={this.state.page}
                header={headers.generalMessenger}
                itemShape={ListItemMessenger}
                data={this.props.statisticMessenger.messenger.list || []}
                isLoading={this.state.isLoading.hasMessenger}
                isEmpty={
                  this.state.isEmpty.hasMessenger ||
                  this.props.statisticMessenger.messenger.list === 0
                }
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError.hasMessenger}
                type={Types.TYPE_STATISTIC_MESSENGER}
                isExporting={this.state.exporting.hasMessenger}
                onExport={this.handleExport.bind(this, 'hasMessenger')}
                isForChildUnit={false}
              />
            )}

            {!this.state.isShowMessengerStatistics && (
              <TableView
                heading="Thống kê nhân viên chưa từng gửi tin nhắn"
                page={this.state.page}
                header={headers.generalNotMessenger}
                itemShape={ListItemNotMessenger}
                data={this.props.statisticMessenger.notMessenger.list || []}
                isLoading={this.state.isLoading.notMessenger}
                isEmpty={
                  this.state.isEmpty.notMessenger ||
                  this.props.statisticMessenger.notMessenger.list === 0
                }
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError.notMessenger}
                type={Types.TYPE_STATISTIC_NOT_MESSENGER}
                isExporting={this.state.exporting.notMessenger}
                onExport={this.handleExport.bind(this, 'notMessenger')}
                isForChildUnit={false}
              />
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  statisticMessenger: state.statisticMessenger,

  auth: state.auth,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticMessengerPage)
