import React, { memo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import csx from 'classnames'

const Scrollbar = ({
  children = null,
  maxHeight = 0,
  autoHide = false,
  className = '',
  ...scrollProps
}) => {
  return (
    <Scrollbars
      {...scrollProps}
      className={csx('cpc-scrollbar', className)}
      renderThumbVertical={props => <div {...props} className="scroll-thumb" />}
      autoHide={autoHide}
      autoHeight
      autoHeightMax={maxHeight}
    >
      {children}
    </Scrollbars>
  )
}

export default memo(Scrollbar)
