import { Procedure } from '../constants/ActionTypes'
import _ from 'lodash'

const initialState = {
  list: [],
  currentItem: {},
}

const ProcedureReducer = (state = initialState, action) => {
  const result = _.get(action, 'payload.data.result')
  switch (action.type) {
    case Procedure.COLLECTION_GROUP:
      return {
        ...state,
        list: result,
      }
    case Procedure.COLLECTION:
      return {
        ...state,
        list: result.items,
      }
    case Procedure.ITEM:
      return {
        ...state,
        currentItem: result,
      }
    case Procedure.CREATE:
      return state
    case Procedure.UPDATE:
      return {
        ...state,
        currentItem: {},
      }
    case Procedure.DELETE:
      const deleteId =
        !!action.meta && !!action.meta.previousAction
          ? action.meta.previousAction.id
          : null
      return {
        ...state,
        list: state.list.filter(item => item.quyTrinhId !== deleteId),
      }
    default:
      return state
  }
}

export default ProcedureReducer
