import React, { Component } from 'react'
import moment from 'moment'
import { NotFound } from '../../components/common/'

class DetailInfo extends Component {
  render() {
    const { sendDocuments } = this.props
    const doKhanId =
      sendDocuments && sendDocuments.doKhan && sendDocuments.doKhan.doKhanId
    const doKhan = this.props.priorities.find(
      item => item && item.doKhanId === doKhanId
    )
    const doBaoMatId =
      sendDocuments &&
      sendDocuments.doBaoMat &&
      sendDocuments.doBaoMat.doBaoMatId
    const doBaoMat = this.props.securities.find(
      item => item && item.doBaoMatId === doBaoMatId
    )
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin công văn</h4>
        </div>
        <div className="form-container section-box-info">
          {!sendDocuments && <NotFound />}
          {sendDocuments && (
            <div className="detail-info-container">
              <div className="list-items">
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Số công văn:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {sendDocuments.soCongVan ? sendDocuments.soCongVan : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Trích yếu:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content textarea-content">
                      {sendDocuments.trichYeu ? sendDocuments.trichYeu : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Ngày công văn:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {sendDocuments.ngayCongVan &&
                        moment(sendDocuments.ngayCongVan).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Mã sổ lưu:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {sendDocuments.soLuu && sendDocuments.soLuu.tenSoLuu
                        ? sendDocuments.soLuu.maSoLuu
                        : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Độ bảo mật:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {doBaoMat && doBaoMat.tenDoBaoMat
                        ? doBaoMat.tenDoBaoMat
                        : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Phòng ban lập:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {sendDocuments.phongBanLap &&
                      sendDocuments.phongBanLap.maPhongBan
                        ? sendDocuments.phongBanLap.maPhongBan
                        : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Đối tượng:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {sendDocuments.doiTuongCongVan
                        ? sendDocuments.doiTuongCongVan.tenDoiTuongCongVan
                        : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Độ khẩn:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {doKhan && doKhan.tenDoKhan ? doKhan.tenDoKhan : ''}
                    </span>
                  </div>
                </div>

                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Người ký:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">
                      {sendDocuments.nguoiKy &&
                      sendDocuments.nguoiKy.tenNhanVien
                        ? sendDocuments.nguoiKy.tenNhanVien
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default DetailInfo
