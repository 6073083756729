import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'

class AlertMistake extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  state = {
    error: {},
    content: '',
    isOpenPopup: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.onSubmit && this.props.onSubmit(this.state.content)
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>Báo nhầm</h4>
        </div>
        <div className="form-container form-send-mail">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-12 col-xs-12">
                Nội dung
                <span className="required-input">*</span>
              </label>
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nội dung…"
                    dir="auto"
                    name="content"
                    onChange={this.handleChange}
                    value={this.state.content}
                    maxLength={1000}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.props.isLoading}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-main-color"
            loading={this.props.isLoading}
          >
            <span className="text-content">Báo nhầm</span>
            <span className="pt-icon icon-attention-warning"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default AlertMistake
