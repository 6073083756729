import React from 'react'
import classnames from 'classnames'

import { MESSAGE_NOT_FOUND } from '../../constants/MessageForm'

const EmptyDataTable = ({ className, message = null }) => (
  <div className={classnames('error-page-container', className)}>
    <div className="error-content">
      <img
        src={`${process.env.PUBLIC_URL}/images/empty-default.png`}
        alt="search-result-not-found"
        className="img-thumb-error"
      />
      <p className="description-note">
        {message ? message : MESSAGE_NOT_FOUND}
      </p>
    </div>
  </div>
)

export default EmptyDataTable
