import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import { LoadingItem, NotFound } from '../../components/common/'

class UpdateHistory extends Component {
  constructor(props) {
    super(props)
    this.handleClickShowDetail = this.handleClickShowDetail.bind(this)
    this.handleClickArrow = this.handleClickArrow.bind(this)
  }

  static propTypes = {
    list: PropTypes.array,
  }

  handleClickArrow = () => {
    this.props.onGetHistoryList && this.props.onGetHistoryList()
  }

  handleClickShowDetail = (id, time) => {
    id &&
      time &&
      this.props.onClickShowHistoryDetail &&
      this.props.onClickShowHistoryDetail(id, time)
  }

  render() {
    return (
      <div className="history-steering-box history-edit-document">
        <div className="heading">
          <h3 className="name-heading">
            <span className="icon-hisory color-main"></span>
            <span onClick={this.handleClickArrow} className="text-content">
              Lịch sử chỉnh sửa văn bản
            </span>
            <span
              onClick={this.handleClickArrow}
              className={classnames('name_group_unit', {
                'pt-icon pt-icon-chevron-up': this.props.isOpenHistoryList,
                'pt-icon pt-icon-chevron-down': !this.props.isOpenHistoryList,
              })}
            ></span>
          </h3>
        </div>
        <Collapse isOpen={this.props.isOpenHistoryList}>
          <div className="list-history-document">
            {this.props.isLoadingData === true ? (
              <LoadingItem />
            ) : (
              <div className="detail-status-box">
                {(!this.props.list || this.props.list.length === 0) && (
                  <NotFound />
                )}
                {this.props.list && this.props.list.length > 0 && (
                  <div className="table-status table-scroll table-scroll-hidden">
                    <div className="heading-table">
                      <div className="cell-table colum-stt">
                        <p className="name-heading-table">STT</p>
                      </div>
                      <div className="cell-table sign-number-history-datetime">
                        <p className="name-heading-table">
                          Thời gian chỉnh sửa
                        </p>
                      </div>
                      <div className="cell-table sign-number-history-datetime">
                        <p className="name-heading-table">Thời gian trình ký</p>
                      </div>
                      <div className="cell-table">&nbsp;</div>
                    </div>
                    {this.props.list.map((item, i) => {
                      let id = null
                      id = item.vanBanKySoDiId
                        ? item.vanBanKySoDiId
                        : item.vanBanKySoNoiBoId
                      if (this.props.type === 'signNumberProject') {
                        id = item.vanBanKySoId
                      }
                      return (
                        <div className="row-table" key={i}>
                          <div className="cell-table">
                            <p className="content">
                              {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                            </p>
                          </div>
                          <div className="cell-table">
                            <p className="content">
                              {item.thoiGianChinhSua &&
                                moment(item.thoiGianChinhSua).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                            </p>
                          </div>
                          <div className="cell-table">
                            <p className="content">
                              {item.thoiGianChinhSua &&
                                moment(item.thoiGianTrinhKy).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                            </p>
                          </div>
                          <div className="cell-table text-center">
                            <Button
                              className="pt-button btn-edit"
                              onClick={this.handleClickShowDetail.bind(
                                this,
                                id,
                                item.thoiGianChinhSua
                              )}
                            >
                              <span className="text-content">Xem chi tiết</span>
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default UpdateHistory
