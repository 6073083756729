import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FileDownload from 'file-saver'
import { Button } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilterNew,
  ListItem,
} from '../../components/Utilities/DocumentarySearch'
import { GridViewFilter, HeadingPage, Print } from '../../components/common'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../../components/auth'
import moment from 'moment'
import parser from 'ua-parser-js'

import Pagination from 'components/Pagination'

const CONGVANDEN = '1'
const CONGVANDI = '2'

let check = new parser.UAParser().getBrowser().name

// let camino   = check.indexOf("Camino") > -1;
let explorer = check.indexOf('IE') > -1
let isFireFox = check.indexOf('Firefox') > -1
let isChrome = check.indexOf('Chrome') > -1
let isChromium = check.indexOf('Chromium') > -1
let isSafari = check.indexOf('Safari') > -1
let opera = check.toLowerCase().indexOf('op') > -1

if (isChrome && isSafari) isSafari = false
if (isChrome && opera) isChrome = false

class DocumentarySearchPage extends React.Component {
  constructor(props) {
    super(props)

    let state = {
      filter: {},
      filterColumn: {},
      checkList: {},
      sort: undefined,
      isEmpty: false,
      checkAll: false,
      exporting: false,
      isLoading: false,
      isPrint: false,
      page: 1,
      isHasPermission: true,
      listStatus: [
        {
          name: 'Chưa xử lý',
          value: 0,
        },
        {
          name: 'Đang xử lý',
          value: 1,
        },
        {
          name: 'Đã xử lý',
          value: 2,
        },
      ],
      documentType: [
        {
          name: 'Công văn đến',
          value: 1,
        },
        {
          name: 'Công văn đi',
          value: 2,
        },
      ],
      documentCabinetStatus: [
        {
          name: 'Chưa lưu',
          value: 0,
        },
        {
          name: 'Đã lưu',
          value: 1,
        },
      ],
      isError: false,
    }

    if (
      !Authorization.vaild(
        this.props.auth.permission,
        [Permission.CONGVAN_XEMTATCA, Permission.CONGVAN_XEMPHONGBANKHAC],
        true
      )
    ) {
      state.isHasPermission = false
      state.filter.phongBanThucHienId = this.props.auth.mainDepartmentId
    }

    this.state = state

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.saveToCabinetDocument = this.saveToCabinetDocument.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.beforePrint = this.beforePrint.bind(this)
    this.afterPrint = this.afterPrint.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  static propTypes = {
    departments: PropTypes.array.isRequired,
  }

  componentDidMount() {
    let state = this.state

    if (
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVAN_XEMTATCA,
      ]) &&
      Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVAN_XEMPHONGBANKHAC,
      ])
    ) {
      // state.filter.phongBanThucHienId = this.props.auth.mainDepartmentId;
      this.props.actions.commonFetchOtherDepartments(this.props.auth.roleId)
    } else {
      this.props.actions.commonFetchDepartments()
    }

    this.props.actions.getCategoryCommonFetchRole(this.props.auth.mainUnitId)
    this.props.actions.commonFetchObjects()
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    this.props.actions.commonFetchDepartmentsLeader(0)

    let preData = Tool.Utils.preData('DocumentSearch')
    if (preData) {
      state.filter = preData.filter
      state.page = preData?.page > 0 ? preData?.page : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : null,
        ngayDen:
          preData.filterColumn && preData.filterColumn.ngayDen
            ? moment(preData.filterColumn.ngayDen)
            : null,
        coQuanBanHanhId:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId
            ? preData.filterColumn.coQuanBanHanhId
            : '',
        coQuanBanHanh:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId === '#'
            ? preData.filterColumn.coQuanBanHanh
            : null,
        isFilterDonViKhac: this.state.filterColumn.coQuanBanHanhId === '#',
      }
    } else {
      state.filter.ngayCongVan = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page = 1) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    // filterColumn để lưu ở localStorage
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayDen: this.state.filterColumn.ngayDen
        ? moment(this.state.filterColumn.ngayDen).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId: this.state.filterColumn.coQuanBanHanhId
        ? this.state.filterColumn.coQuanBanHanhId
        : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? this.state.filterColumn.coQuanBanHanh
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      isFilterDonViKhac: this.state.filterColumn.coQuanBanHanhId === '#',
    }

    // filterColumn request API
    const filterColumn1 = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayDen: this.state.filterColumn.ngayDen
        ? moment(this.state.filterColumn.ngayDen).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      isFilterDonViKhac: this.state.filterColumn.coQuanBanHanhId === '#',
    }

    let filterParam = {
      filter: filter,
    }
    if (!this.state.isHasPermission) {
      filterParam = {
        filter: {
          ...filter,
          phongBanThucHienId: null,
        },
      }
    }

    this.setState(
      { filter, page, isLoading: true, sort, checkAll: false, checkList: {} },
      () => {
        Tool.Utils.preData('DocumentSearch', {
          filter,
          page,
          sort,
          filterColumn,
        })
        this.props.actions
          .utilityReceiveDocumentGet(filterParam, page, sort, filterColumn1)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.result &&
              res.payload.data.result.result.total === 0
            ) {
              isEmpty = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  handleExport() {
    const { filter, page, sort } = this.state

    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayDen: this.state.filterColumn.ngayDen
        ? moment(this.state.filterColumn.ngayDen).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      isFilterDonViKhac: this.state.filterColumn.coQuanBanHanhId === '#',
    }

    this.setState({ exporting: true })
    let filterParam = {
      filter: filter,
    }
    if (!this.state.isHasPermission) {
      filterParam = {
        filter: {
          ...filter,
          phongBanThucHienId: null,
        },
      }
    }

    this.props.actions
      .utilityExportReceiveDocumentGet(filterParam, page, sort, filterColumn)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: 'Không xuất được file.',
            intent: Intent.DANGER,
          })
        }
        FileDownload.saveAs(res.payload.data, 'tim-kiem-cong-van.xls')
      })
      .then(() => {
        this.setState({ exporting: false })
      })
  }

  handleCheckAll(e) {
    let checkList = {}

    if (this.props.receiveDocuments.list.items) {
      this.props.receiveDocuments.list.items.map(item => {
        return (checkList[item.id] = e)
      })
    }

    this.setState({
      checkList,
      checkAll: e,
    })
  }

  handleCheck(id, e) {
    let state = {
      checkList: {
        ...this.state.checkList,
        [id]: e,
      },
    }

    if (!e) {
      state.checkAll = false
    }

    this.setState(state)
  }

  saveToCabinetDocument = async () => {
    let listCabinetOfDocument = []
    const listItems = this.props.receiveDocuments.list.items.filter(
      item => this.state.checkList[item.id]
    )
    if (listItems.length === 1) {
      if (listItems[0].id) {
        await this.props.actions
          .getListCabinetOfDocument(listItems[0].congVanId)
          .then(res => {
            if (res.payload && res.payload.data && res.payload.data.result) {
              listCabinetOfDocument = res.payload.data.result
            }
          })
      }
    }

    this.props.history.push({
      pathname: `/tien-ich/luu-tu-tai-lieu`,
      state: {
        dsFileCongVan: listItems.map(item => {
          return {
            file_id: item.fileId,
            ten_tai_lieu: item.trichYeu,
            cong_van_den_id: item.id,
          }
        }),
        listCabinetOfDocument,
      },
    })
  }

  // saveToCabinetDocument() {
  //   const listItems = this.props.receiveDocuments.list.items.filter((item) => this.state.checkList[item.id]);
  //   this.props.history.push({
  //     pathname: `/tien-ich/luu-tu-tai-lieu`,
  //     state: {
  //       dsFileCongVan: listItems.map((item) => {
  //         return {
  //           file_id: item.fileId,
  //           ten_tai_lieu: item.trichYeu,
  //           cong_van_den_id: item.id
  //         }
  //       })
  //     }
  //   });
  // }

  beforePrint() {
    this.setState({ isPrint: true })
  }

  afterPrint() {
    this.setState({ isPrint: false })
  }

  handlePrint() {
    const _this = this
    const filter = this.state.filter
    const sort = this.state.sort
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayDen: this.state.filterColumn.ngayDen
        ? moment(this.state.filterColumn.ngayDen).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      isFilterDonViKhac: this.state.filterColumn.coQuanBanHanhId === '#',
    }

    let filterParam = {
      filter: filter,
    }
    if (!this.state.isHasPermission) {
      filterParam = {
        filter: {
          ...filter,
          phongBanThucHienId: null,
        },
      }
    }

    this.props.actions
      .printUtilityReceiveDocumentGet(
        filterParam,
        Types.PAGE,
        sort,
        10000,
        filterColumn
      )
      .then(res => {
        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print')
          mediaQueryList.addListener(function (mql) {
            if (mql.matches) {
              _this.beforePrint()
            } else {
              _this.afterPrint()
            }
          })
        }
        window.onbeforeprint = _this.beforePrint
        window.onafterprint = _this.afterPrint
        if (isChrome) {
          this.setState({ isPrint: true })
        }
        window.print()
      })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    if (name === 'soDen' && !isNaN(value)) {
      return this.setState({
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    }

    if (name !== 'soDen') {
      this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (name === 'coQuanBanHanhId') {
            this.submitFilter()
          }
        }
      )
    }
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({ filterColumn: {} })
    }
    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    const isViewOtherDepartments =
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVAN_XEMTATCA,
      ]) &&
      Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVAN_XEMPHONGBANKHAC,
      ])
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container page-DocumentarySearch">
          {/* Heading */}
          <HeadingPage namePage="Tìm kiếm công văn" iconPage="icon-search-data">
            <div className="button-heading-right pull-right">
              <Button
                rightIconName="pt-icon icon-Tu_Tai_Lieu"
                className="btn-action btn-top-heading"
                text="Lưu tủ tài liệu"
                disabled={
                  !this.state.checkList ||
                  Object.keys(this.state.checkList).length === 0 ||
                  Object.keys(this.state.checkList).findIndex(
                    id => this.state.checkList[id]
                  ) === -1
                }
                onClick={this.saveToCabinetDocument}
              />
              <Button
                rightIconName="pt-icon icon-In_soCongVan"
                className={classnames({
                  'loading-input': this.state.exporting,
                  'btn-action': true,
                  'btn-top-heading': true,
                })}
                text="Xuất excel"
                onClick={this.handleExport}
              />
              <Button
                rightIconName="pt-icon icon-In_soCongVan"
                className="btn-action btn-top-heading"
                text="In sổ"
                onClick={this.handlePrint}
                disabled={
                  this.state.filter.loaiCongVan !== CONGVANDEN &&
                  this.state.filter.loaiCongVan !== CONGVANDI
                }
              />
            </div>
          </HeadingPage>
          {/* Table List */}
          <div className="page-list-container list-DocumentarySearch">
            <ListFilterNew
              signNumberDepartmentLeaders={
                this.props.signNumberDepartmentLeaders
              }
              departments={this.props.departments}
              objects={this.props.objects}
              status={this.state.listStatus}
              documentType={this.state.documentType}
              documentCabinetStatus={this.state.documentCabinetStatus}
              relativeUnits={this.props.relativeUnits}
              leaderDepartments={this.props.leaderDepartments}
              onSubmit={this.submitFilter}
              auth={this.props.auth}
              filter={this.state.filter}
              isHasPermission={this.state.isHasPermission}
              readOnly={this.props.readOnly}
              removeDepartmentAll={isViewOtherDepartments}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.receiveDocuments.list.total /
                      Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                classNames={'pt-table fix-table-before'}
                page={this.state.page}
                headers={headers.documentarySearch}
                itemShape={ListItem}
                data={this.props.receiveDocuments.list.items}
                onFilter={this.handleSort}
                onCheckAll={this.handleCheckAll}
                onCheck={this.handleCheck}
                checkList={this.state.checkList}
                isEnabledAll={this.state.checkAll}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                coQuanBanHanhId={this.props.relativeUnits}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                pageCount={Math.ceil(
                  this.props.receiveDocuments.list.total /
                    Types.PAGINATION_LIMIT
                )}
              />

              {!this.state.isLoading &&
                !this.state.isError &&
                !this.state.isError && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.receiveDocuments.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
        <div
          className="container-printing"
          style={{ height: this.state.isPrint ? 637 : 0 }}
        ></div>
        <div
          className={classnames({
            'section-to-print': this.state.isPrint,
            'section-to-not-print': !this.state.isPrint,
          })}
        >
          <div
            className={classnames({
              'print-chrome-safari': isChrome || isSafari || isChromium,
              'print-fireFox': isFireFox,
              'print-ie': explorer,
            })}
          >
            <Print
              searchReceiveDocument={{
                data: this.props.printSearchReceiveDocument.items,
                title: 'Công Văn',
                header: [
                  'Ngày công văn',
                  'Số công văn',
                  'Ngày đến',
                  'Số đến',
                  'Trích yếu',
                  'Cơ quan ban hành',
                ],
              }}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  receiveDocuments: {
    ...state.receiveDocuments,
    list: {
      ...state.receiveDocuments.list,
      items: state.receiveDocuments.list.items.map(
        id => state.entities.receiveDocuments[id]
      ),
    },
  },

  printSearchReceiveDocument: {
    ...state.printSearchReceiveDocument,
    items: state.common.printSearchReceiveDocument,
  },

  departments: state.common.departments,

  objects: state.common.objects,

  relativeUnits: state.common.relativeUnits,

  leaderDepartments: state.common.leaderDepartments,

  categoryRole: state.common.categoryRole,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentarySearchPage)
