import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ListFilter, ListDecentralizationUser } from '../../components/System'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'

class DecentralizationUserPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isEmpty: false,
      isLoading: true,
      users: [],
      donViId: this.props.auth.mainUnitId,
      phongBanId: this.props.auth.mainDepartmentId,
      isUnit: false,
      isDepartment: false,
      isError: false,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleChangeUnit = this.handleChangeUnit.bind(this)
    this.handleClickDepartment = this.handleClickDepartment.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.disableLoading = this.disableLoading.bind(this)
  }

  componentDidMount() {
    let donViId = Utils.getData('Permission-Unit', {})
    let phongBanId = Utils.getData('Permission-Department', {})
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (donViId === undefined) {
      donViId = this.state.donViId
    }

    if (phongBanId === undefined) {
      phongBanId = this.state.phongBanId
    }

    this.setState({ donViId, phongBanId }, () => {
      if (permission) {
        this.props.actions.getCategoryUnitMultiLevel().then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
      } else {
        this.props.actions
          .getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
      }
      this.props.actions.getCategoryDepartmentMultiLevel(donViId).then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
        if (
          res.payload &&
          res.payload.data.result &&
          res.payload.data.result.length > 0
        ) {
          this.props.actions.getStaffByDepartmentId(phongBanId).then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
        }
      })
    })
  }

  componentWillReceiveProps(props) {
    if (
      !props.users ||
      !props.users.items ||
      props.users.items === this.state.users
    ) {
      return
    }

    this.setState(
      {
        users: props.users.items,
      },
      () => {
        this.disableLoading()
      }
    )
  }

  handleSubmitRemoveItem(id) {
    this.props.actions.deleteStaff(id).then(res => {
      if (res.payload && res.payload.data.result === true) {
        this.props.actions
          .getStaffByDepartmentId(this.state.phongBanId)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      }
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
        intent: Intent.DANGER,
      })
    })
  }

  componentDidUpdate(prevState, prevProps) {
    if (
      prevState.donViId !== this.state.donViId &&
      this.state.isUnit === true
    ) {
      this.setState({ isUnit: false })
      return this.props.actions
        .getCategoryDepartmentMultiLevel(this.state.donViId)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result.length > 0
          ) {
            Utils.saveData(
              'Permission-Department',
              res.payload.data.result[0].phongBanId
            )
            this.setState(
              {
                phongBanId: res.payload.data.result[0].phongBanId,
              },
              () => {
                this.props.actions
                  .getStaffByDepartmentId(this.state.phongBanId)
                  .then(res => {
                    if (
                      res.error ||
                      (res.payload && res.payload.status !== 200)
                    ) {
                      this.setState({ isError: true, isLoading: false })
                    }
                  })
              }
            )
          }
        })
    }

    if (
      prevState.phongBanId !== this.state.phongBanId &&
      this.state.isDepartment === true
    ) {
      this.setState({ isDepartment: false })
      return this.props.actions
        .getStaffByDepartmentId(this.state.phongBanId)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false }, () => {
              this.disableLoading()
            })
          }
        })
    }
  }

  handleChangeUnit(e) {
    Utils.saveData('Permission-Unit', e)
    this.setState(
      {
        donViId: e,
        users: [],
        isUnit: true,
        isLoading: true,
      },
      () => {
        this.props.actions.clearUsers(e)
      }
    )
  }

  handleClickDepartment(id) {
    Utils.saveData('Permission-Department', id)
    this.setState({
      phongBanId: id,
      users: [],
      isLoading: true,
      isDepartment: true,
    })
  }

  handleSort(users) {
    this.setState({ users }, () => {
      const listIds = users.map(item => {
        return item.chucDanhId
      })

      this.props.actions.updateArrayCommonFetchStaff(listIds).then(res => {
        if (res.payload && res.payload.data.result === true) {
          this.props.actions
            .getStaffByDepartmentId(this.state.phongBanId)
            .then(res => {
              if (res.error || (res.payload && res.payload.status !== 200)) {
                this.setState({ isError: true, isLoading: false })
              }
            })
          return this.props.actions.commonAddToasterMessage({
            message: 'Sắp xếp nhân viên thành công',
            intent: Intent.SUCCESS,
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: 'Sắp xếp nhân viên thất bại',
          intent: Intent.DANGER,
        })
      })
    })
  }

  disableLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 500)
  }

  render() {
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="listDecentralizationUser-container">
          <HeadingPage
            namePage="Phân quyền người dùng"
            iconPage="icon-decentralization-users"
          >
            <Link
              to="/he-thong/nguoi-dung/them-moi"
              className="btn-action pull-right"
            >
              <span className="name-btn">THÊM NGƯỜI DÙNG</span>
              <span className="pt-icon pt-icon-small-plus"></span>
            </Link>
            {permission && (
              <Link
                to="/he-thong/nguoi-dung/di-chuyen"
                className="btn-action pull-right mr10"
              >
                <span className="name-btn">DI CHUYỂN NGƯỜI DÙNG</span>
              </Link>
            )}
          </HeadingPage>
          <div className="page-list-container page-list-contact-container">
            <ListFilter
              units={this.props.unit.items}
              onChange={this.handleChangeUnit}
              defaultValue={this.state.donViId}
            />
            <ListDecentralizationUser
              onClickDepartment={this.handleClickDepartment}
              onSort={this.handleSort}
              departments={this.props.departments.items}
              users={this.state.users}
              isLoading={this.state.isLoading}
              isEmpty={this.state.users.length === 0}
              onSubmit={this.handleSubmitRemoveItem}
              auth={this.props.auth}
              DepartmentId={this.state.phongBanId}
              actions={this.props.actions}
              phongBanId={this.state.phongBanId}
              isError={this.state.isError}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  users: {
    ...state.users,
    items: state.users.items.map(item => state.entities.users[item]),
  },

  departments: {
    ...state.categoryDepartmentMultiLevel,
    items: state.category.categoryDepartmentMultiLevel,
  },

  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DecentralizationUserPage)
