import React, { memo } from 'react'
import csx from 'classnames'
import { isEmpty } from 'lodash'

const Card = ({
  children = null,
  // border
  bordered = false,
  borderTop = false,
  // padding
  padding: padProps = false,
  // dùng để loại 1 padding của 1 trục
  paddingVertical = false,
  paddingHorizontal = false,
  // bo viền
  rounded,
  shadow = false,
  className,
  mainClassName,
  title = '',
  cardRef,
  stypeMain = null,
  ...props
}) => {
  const padding = paddingHorizontal || paddingVertical || padProps

  return (
    <div
      {...props}
      className={csx(
        'cpc-card',
        {
          bordered,
          rounded,
          shadow,
          'border-top': borderTop,
        },
        className
      )}
      ref={cardRef}
    >
      {!isEmpty(title) && (
        <header
          className={csx('cpc-card-title', {
            padding,
            'padding-vertical': paddingVertical,
            'padding-horizontal': paddingHorizontal,
            bordered,
          })}
        >
          {title}
        </header>
      )}
      <main
        className={csx(
          {
            padding,
            'padding-vertical': paddingVertical,
            'padding-horizontal': paddingHorizontal,
          },
          mainClassName
        )}
        style={stypeMain}
      >
        {children}
      </main>
    </div>
  )
}

export default memo(Card)
