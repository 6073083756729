import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import classNames from 'classnames'
import {
  get,
  size,
  toPairs,
  isEqual,
  isEmpty,
  isString,
  uniq,
  uniqBy,
  differenceBy,
} from 'lodash'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import xlsx from 'xlsx'

import {
  Row,
  Col,
  Scrollbar,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
} from 'components/newCommon2'
import {
  postCongViec,
  postUpload,
  getCongViec,
  putCongViec,
  getThongKeTrangThaiCongViec,
  showToast,
} from 'actions/task'
import { priorities } from 'constants/task'
import { Action, Toast } from 'constants/MessageForm'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import {
  RESPONSIBILITY_TYPE,
  UNSET,
  WORK_STATUS,
  STATISTIC_WORK_RESPONSIBILITY,
  WORK_PRIORITY,
  FILE_TYPE,
  WORK_TYPE,
} from 'constants/Enum'
import { randomId } from 'helpers/string'
import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import { TaskContext } from '../AllTask'
import TemplateDialog from './_components/Templates'
import PhongBanVaDonViThucHien from './_components/PhongBanVaDonViThucHien'

const TEMPLATE_TITLE = 'Chọn từ mẫu có sẵn'
const chinhSua = 'chinh-sua'
const dateRequest = 'YYYY-MM-DD'
const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
const excelKeys = {
  title: 'Tiêu đề',
  deadline: 'Hạn thực hiện',
  description: 'Mô tả',
  priority: 'Ưu tiên',
}
const priorityKeys = {
  Không: UNSET,
  Thấp: WORK_PRIORITY.THAP,
  'Bình thường': WORK_PRIORITY.BINH_THUONG,
  Cao: WORK_PRIORITY.CAO,
}
const dayToMiliseconds = 24 * 60 * 60 * 1000
const CONFIRM_TITLE = 'Xóa công việc'
const CONFIRM_CONTENT = 'Anh/Chị muốn xóa công việc này?'

const Task = ({
  onClose = () => {},
  reloadList = () => {},
  match,
  history,
}) => {
  const { id, action, type } = match.params
  const isUpdate = action === chinhSua
  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )

  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const {
    roleId,
    favoriteGroups = [],
    tags = [],
    filter: { status = WORK_STATUS.CHUA_THUC_HIEN },
    isLoadingTree,
    dataTreeStore,
    mainDepartmentId,
    hasShowAllDeparment,
    dataAutoToggleDefault,
  } = useContext(TaskContext)
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const [fetching, setFetching] = useState(false)
  const [taskDetail, setTaskDetail] = useState(null)
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [showTemplateTaskId, setShowTemplateTaskId] = useState()
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [validateErrors, setValidateErrors] = useState({})
  const [files, setFiles] = useState({})
  const [dataAutoToggle, setDataAutoToggle] = useState([])
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    _mountedSet(
      setTasks,
      size(newTasks) === 1
        ? // hiện lại form nếu chỉ còn 1 task đang đóng
          newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )

    // Xóa file lưu tạm của công việc đó
    _mountedSet(setFiles, prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const onChangeRadio = useCallback(
    (values, taskId) => {
      setIsDisabledButtonDefault(false)
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: values,
                [PHOI_HOP]: !isPhongBanDonVi
                  ? elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id)
                  : isPhongBanDonVi
                  ? elm?.data?.[PHOI_HOP]?.filter(
                      e =>
                        (e.parentId !== values?.id && e.id !== values?.id) ||
                        (e.parentId === values?.id && !e.isLanhDaoPhongBan)
                    )
                  : elm?.data?.[PHOI_HOP],
                [THEO_DOI]: !isPhongBanDonVi
                  ? elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id)
                  : isPhongBanDonVi
                  ? elm?.data?.[THEO_DOI]?.filter(
                      e =>
                        (e.parentId !== values?.id && e.id !== values?.id) ||
                        (e.parentId === values?.id && !e.isLanhDaoPhongBan)
                    )
                  : elm?.data?.[THEO_DOI],
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isOtherWorkType =
        taskDetail?.workType === WORK_TYPE.MEETING || taskDetail?.referenceId
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      let treeItem = []
      if (isPhongBanDonVi) {
        treeItem =
          dataTreeStore?.find(e => e?.id === values?.id)?.children || []
        setDataAutoToggle([values?.id])
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData = elm?.data?.[name] || []
            newData = [...oldData, values]
            if (isPhongBanDonVi) {
              if (!isChecked) {
                newData = differenceBy(
                  newData || [],
                  [...treeItem, values],
                  'id'
                )
              } else {
                if (
                  (isOtherWorkType && !hasShowAllDeparment) ||
                  !isOtherWorkType
                ) {
                  newData = [...newData, ...(treeItem || [])]
                  newData = differenceBy(newData || [], [values], 'id')
                }
              }

              const dataChuTri = elm?.data?.[CHU_TRI]
                ? [elm?.data?.[CHU_TRI]]
                : []
              const dataPhoiHop = elm?.data?.[PHOI_HOP] || []
              const dataTheoDoi = elm?.data?.[THEO_DOI] || []
              if (
                name === PHOI_HOP &&
                (!isOtherWorkType || (isOtherWorkType && !hasShowAllDeparment))
              ) {
                newData = differenceBy(
                  newData,
                  [...dataChuTri, ...dataTheoDoi],
                  'id'
                )
              } else if (
                name === THEO_DOI &&
                (!isOtherWorkType || (isOtherWorkType && !hasShowAllDeparment))
              ) {
                newData = differenceBy(
                  newData,
                  [...dataChuTri, ...dataPhoiHop],
                  'id'
                )
              }

              if (
                (name === PHOI_HOP || name === THEO_DOI) &&
                size(dataChuTri) !== 0 &&
                dataChuTri?.some(
                  e =>
                    e?.id === values?.id &&
                    (e?.type === PHONG_BAN || e?.type === DON_VI)
                )
              ) {
                newData = newData?.filter(
                  e =>
                    e.parentId !== values?.id ||
                    (e.parentId === values?.id && !e?.isLanhDaoPhongBan)
                )
              }
            } else {
              if (!isChecked) {
                newData = differenceBy(newData || [], [values], 'id')
              }
            }

            newData = differenceBy(newData, [{ id: roleId }], 'id')

            const chuTri =
              name !== CHU_TRI &&
              elm?.data?.[CHU_TRI]?.id === values?.id &&
              ((elm?.data?.[CHU_TRI]?.type !== PHONG_BAN &&
                elm?.data?.[CHU_TRI]?.type !== DON_VI &&
                !isOtherWorkType) ||
                isOtherWorkType)
                ? null
                : elm?.data?.[CHU_TRI]
            const phoiHop =
              name === PHOI_HOP
                ? uniqBy(newData, 'id')
                : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id) || []
            const theoDoi =
              name === THEO_DOI
                ? uniqBy(newData, 'id')
                : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id) || []

            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: chuTri,
                [PHOI_HOP]: phoiHop,
                [THEO_DOI]: theoDoi,
              },
            }
          }

          return elm
        })
      )
    },
    [
      setTasks,
      setDataAutoToggle,
      dataTreeStore,
      taskDetail,
      hasShowAllDeparment,
      roleId,
    ]
  )

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        dataTreeStore?.find(e => e?.id === record?.id)?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        const dataKey = taskItem?.data?.[key]?.findIndex(
          e => e?.id === record?.id
        )
        if (
          (size(parentItem) !== 0 &&
            size(parentItem) === size(taskItemDataKey)) ||
          dataKey === 0 ||
          (dataKey && dataKey !== -1)
        ) {
          isChecked = true
        }
      }

      return isChecked
    },
    [dataTreeStore]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (
        dataKey &&
        (record?.type === PHONG_BAN || record?.type === DON_VI) &&
        hasChecked
      ) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const handleCheckDisable = useCallback(
    (key, taskId, record) => {
      let isDisable = false
      if ((key === PHOI_HOP || key === THEO_DOI) && roleId === record?.id) {
        isDisable = true
      }

      const dataChuTri =
        tasks?.find(e => e?.id === taskId)?.data?.[CHU_TRI] || null
      if (
        (key === PHOI_HOP || key === THEO_DOI) &&
        !isEmpty(dataChuTri) &&
        record?.parentId === dataChuTri?.id &&
        record?.isLanhDaoPhongBan
      ) {
        isDisable = true
      }

      return isDisable
    },
    [tasks, roleId]
  )

  const column = [
    {
      title: 'Phòng ban/đơn vị',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: (record, _, __, taskId) => {
        const taskItem = tasks?.find(
          elm => elm.id === taskId && elm?.data?.[CHU_TRI]?.id === record?.id
        )
        let isChecked = false
        if (!isEmpty(taskItem)) {
          isChecked = true
        }

        if (
          (!hasShowAllDeparment && record?.id === mainDepartmentId) ||
          !record.type
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(record, taskId)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: (record, _, __, taskId) => {
        const isDisable = handleCheckDisable(PHOI_HOP, taskId, record)
        const isChecked = handleCheckActive(PHOI_HOP, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          PHOI_HOP,
          tasks,
          taskId,
          record
        )
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable || false}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
    {
      title: 'TD',
      render: (record, _, __, taskId) => {
        const isDisable = handleCheckDisable(THEO_DOI, taskId, record)
        const isChecked = handleCheckActive(THEO_DOI, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          THEO_DOI,
          tasks,
          taskId,
          record
        )
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable || false}
            onChange={e =>
              onChangeCheckbox(THEO_DOI, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  // Ẩn/hiện toàn bộ công việc
  const toggleCollapseTask = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
              showExtra: false,
            }
          : {
              ...task,
              showTask: false,
            }
      )
    )
  }

  // Ẩn/hiện thông tin thêm của công việc
  const toggleCollapseExtra = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  // Thêm công việc
  const addTask = () => {
    _mountedSet(setTasks, [
      ...tasks?.map(t => ({ ...t, showTask: false, showExtra: false })),
      { ...DEFAULT_TASK, id: randomId() },
    ])
  }

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  // Thay đổi giá trị của một thành phần trong form
  const _changeFormValue = ({ id, name, value }) => {
    _mountedSet(setTasks, prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const _changeFormValidate = ({ id, name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [id]: {
        ...(prev?.[id] || {}),
        [name]: value,
      },
    }))
  }

  const changeField = (id, name) => value => {
    setIsDisabledButtonDefault(false)
    _changeFormValidate({
      id,
      name,
      value: false,
    })

    _changeFormValue({
      id,
      name,
      value,
    })
  }

  const changeFile = id => fileList => {
    setIsDisabledButtonDefault(false)
    _mountedSet(setFiles, {
      ...files,
      [id]: fileList,
    })
  }

  // Xóa file
  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      // Xóa file đã upload lên server
      _mountedSet(setTasks, prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      // Xóa file chọn từ file upload
      _mountedSet(setFiles, {
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  // Import dữ liệu công việc từ excel
  const importExcel = e => {
    const { files } = e.target
    if (!files || !files[0]) {
      return
    }

    const reader = new FileReader()

    reader.onload = e => {
      const bstr = e.target.result
      const wb = xlsx.read(bstr, { type: 'binary' })

      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]

      const data = xlsx.utils.sheet_to_json(ws)

      _mountedSet(
        setTasks,
        data.map(record => ({
          ...DEFAULT_TASK,
          id: randomId(),
          data: {
            title: record[excelKeys.title] || '',
            deadline:
              record[excelKeys.deadline] &&
              moment(record[excelKeys.deadline] * dayToMiliseconds),
            description: record[excelKeys.description] || '',
            priority: priorityKeys[record[excelKeys.priority] || 'Không'],
          },
        }))
      )
    }

    reader.readAsBinaryString(files[0])
  }

  // Ẩn/hiện danh sách mẫu đã lưu
  const toggleShowTemplate = (id = null) => {
    _mountedSet(setShowTemplateTaskId, id)
  }

  const _validateRequired = (name, key) => {
    let success = true
    const tasksNew = [...tasks]
    tasksNew.forEach(({ id, data }) => {
      let isCheck = isString(data[name]) && !data[name].trim()
      if (key) {
        isCheck = isString(data?.[name]?.[key]) && !data?.[name]?.[key].trim()
      }

      if (isEmpty(data[name]) || isCheck) {
        success = false
        _changeFormValidate({ id, name, value: true })
      } else {
        _changeFormValidate({ id, name, value: false })
      }
    })

    return success
  }

  const _validateTitle = () => {
    return _validateRequired('title')
  }

  const _validate = () => {
    const success = _validateTitle()
    const isHasChuTri = _validateRequired(CHU_TRI, 'id')
    if (!success || !isHasChuTri) {
      throw new Error('warning')
    }
  }

  const _convertDataType = (values, type) => ({
    responsibilityId: get(values, 'id'),
    assignType: get(values, 'type'),
    responsibilityType: type,
  })

  const _getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        workUserTags: get(originData, 'tags', []).map(item => ({
          workTagId: item,
        })),
        attachmentFileIds: get(originData, 'attachmentFiles', []).map(
          file => file.id
        ),
        referenceFileIds: get(originData, 'referenceFiles', []).map(
          file => file.id
        ),
        isSaveTemplate: get(originData, 'isSaveTemplate'),
        requireReport: originData?.requireReport || false,
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.executionDate = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.workReminder = {
          type: workReminder.type,
          reminderTime: workReminder.date.format(dateTimeRequest),
        }
      }

      const executor = get(originData, CHU_TRI)
        ? [
            {
              responsibilityId: get(originData, `${CHU_TRI}.id`),
              assignType: get(originData, `${CHU_TRI}.type`),
              responsibilityType: RESPONSIBILITY_TYPE.CHU_TRI,
            },
          ]
        : []
      const coordination = get(originData, PHOI_HOP, [])?.map(elm =>
        _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
      )

      const watcher = get(originData, THEO_DOI, [])?.map(elm =>
        _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
      )
      newData.workAssignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  // Upload file
  const _upload = async () => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(files).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  // Thêm dữ liệu file vào data
  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload()
    return (cloneTasks || []).map(task =>
      toDecamelizeKeys({
        ...(task?.data || {}),
        attachmentFileIds: [
          ...(task?.data?.attachmentFileIds || []),
          ...(responseFilesObject[task.id] || []).map(file => file.id),
        ],
      })
    )
  }

  // Thực hiện tạo công việc
  const submitCreate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validate()
      },
      action: async () => {
        const data = await _getDataWithUpload()
        return postCongViec(data)
      },
      afterResponse: () => {
        if (status === WORK_STATUS.CHUA_THUC_HIEN) {
          reloadList({ page: 1 })
        }

        reloadThongKe()
        history.replace('/quan-ly-cong-viec/toi-giao')
      },
      afterAction: () => {
        _mountedSet(setSubmitLoading, false)
      },
      successCode: 201 || 200,
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  // Thực hiện cập nhật công việc
  const submitUpdate = async () => {
    let requestData = []
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validate()
      },
      action: async () => {
        requestData = await _getDataWithUpload()
        return putCongViec(id, requestData[0])
      },
      afterResponse: res => {
        showToast({
          message: res?.message,
          intent: res?.result ? Intent.SUCCESS : Intent.DANGER,
        })

        if (!res?.result) {
          return
        }

        if (status === WORK_STATUS.CHUA_THUC_HIEN) {
          reloadList()
        }

        reloadThongKe()
        onClose()
      },
      afterAction: () => {
        _mountedSet(setSubmitLoading, false)
        _mountedSet(setIsDisabledButtonDefault, true)
      },
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  // Tải lại các counter khi thao tác xong
  const reloadThongKe = () => {
    if (type === 'duoc-giao') {
      getThongKeTrangThaiCongViec({
        responsibilityType: STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME,
      })
    }
  }

  const handleDataParent = useCallback(
    data => {
      const newParent = []
      const mainResponsibility = data?.mainResponsibility?.information
      if (mainResponsibility) {
        const departmentId = mainResponsibility?.department?.id
        const organizationId = mainResponsibility?.organization?.id
        if (departmentId) {
          newParent.push(departmentId)
        }

        if (organizationId) {
          newParent.push(organizationId)
        }
      }

      const coordinations = data?.responsibilities?.[1]?.workAssignments || []
      if (size(coordinations) !== 0) {
        coordinations.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }

      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      if (size(watchers) !== 0) {
        watchers.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }
      const newParentConvert = uniq(newParent)
      if (size(newParentConvert) !== 0) {
        setDataAutoToggle(newParentConvert)
      }
    },
    [setDataAutoToggle]
  )

  const _convertToStateData = useCallback(data => {
    const coordinations = get(data, 'responsibilities[1].workAssignments', [])
    const originCoordinations = coordinations?.map(coordination => ({
      id: get(coordination, 'responsibilityId'),
      type: get(coordination, 'assignType'),
      parentId: get(coordination, 'responsibility.information.department.id'),
      isLanhDaoPhongBan: coordination?.responsibility?.isLanhDaoPhongBan,
    }))
    const watchers = get(data, 'responsibilities[2].workAssignments', [])
    const originWatchers = watchers?.map(watcher => ({
      id: get(watcher, 'responsibilityId'),
      type: get(watcher, 'assignType'),
      parentId: get(watcher, 'responsibility.information.department.id'),
      isLanhDaoPhongBan: watcher?.responsibility?.isLanhDaoPhongBan,
    }))

    const referenceFiles = (data?.files || []).filter(
      file => file?.type === FILE_TYPE.FILE_THAM_KHAO
    )
    const attachmentFiles = (data?.files || [])
      .filter(file => file?.type === FILE_TYPE.FILE_DINH_KEM)
      .map(file => ({ ...file, showClose: file.application !== 'eOffice' }))
    return {
      title: get(data, 'title') || '',
      deadline:
        get(data, 'deadline') && moment(get(data, 'deadline')).isValid()
          ? moment(get(data, 'deadline'))
          : moment(get(data, 'originalDeadline')).isValid()
          ? moment(get(data, 'originalDeadline'))
          : null,
      requireReport: data?.requireReport || false,
      executionDate:
        get(data, 'executionDate') &&
        moment(get(data, 'executionDate')).isValid()
          ? moment(get(data, 'executionDate'))
          : null,
      description: get(data, 'description') || '',
      workReminder: get(data, 'workReminder')
        ? {
            type: get(data, 'workReminder.type'),
            date: moment(get(data, 'workReminder.reminderTime')),
          }
        : undefined,
      priority: get(data, 'priority'),
      tags: get(data, 'workUserTags', []).map(tag => tag.workTagId),
      [CHU_TRI]: get(data, 'mainResponsibilityId')
        ? {
            id: get(data, 'mainResponsibilityId'),
            type: get(data, 'mainResponsibility.type'),
          }
        : null,
      [PHOI_HOP]: originCoordinations,
      [THEO_DOI]: originWatchers,
      workType: data?.workType,
      referenceId: data?.referenceId,
      referenceFiles,
      attachmentFiles,
    }
  }, [])

  // Get chi tiết khi cập nhật
  const _fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getCongViec(id),
      afterResponse: data => {
        setTaskDetail(data)
        handleDataParent(data)
        _mountedSet(setTasks, [
          {
            ...DEFAULT_TASK,
            id,
            data: _convertToStateData(data),
          },
        ])
      },
      afterAction: () => _mountedSet(setFetching, false),
    })
    // eslint-disable-next-line
  }, [id, _convertToStateData, DEFAULT_TASK])

  // Tách files đính kèm từ công văn và files từ ngoài công văn
  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (isUpdate) {
      _fetchDetail()
    }
  }, [_fetchDetail, isUpdate])

  return (
    <>
      <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
        <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
          <span>{isUpdate ? 'Sửa công việc' : 'Tạo công việc'}</span>
          <Icon
            classIcon="icon-Huy"
            className={'has-event font-size-12 ml10'}
            onClick={onClose}
          />
        </h1>
        <Scrollbar maxHeight={window.innerHeight - (isUpdate ? 172 : 233)}>
          <main>
            {tasks.map(({ id, showTask, showExtra, data }, index) => (
              <Fragment key={id}>
                {!!index && (
                  <span
                    style={{
                      display: 'block',
                      height: 5,
                      background: '#DFE6EA',
                    }}
                  />
                )}
                <div style={{ padding: 10 }}>
                  {size(tasks) > 1 && (
                    <header className="d-flex">
                      <Button
                        className={classNames(
                          Classes.MINIMAL,
                          'cpc-button',
                          'cpc-form-button-left',
                          'font-weight-600',
                          'uppercase',
                          'text-left'
                        )}
                        intent={Intent.PRIMARY}
                        onClick={toggleCollapseTask(id)}
                        style={{ lineHeight: 1.3 }}
                      >
                        {`Công việc ${index + 1}`}
                        <Icon
                          classIcon={
                            showTask ? 'icon2-arrow-down' : 'icon2-arrow-up'
                          }
                          className={classNames(
                            'ml5',
                            'size-icon-10',
                            'd-flex'
                          )}
                        />
                      </Button>
                      <Button
                        className={classNames(
                          Classes.MINIMAL,
                          'cpc-button',
                          'cpc-form-button-right'
                        )}
                        intent={Intent.DANGER}
                        onClick={removeTask(id)}
                      >
                        <Icon classIcon="icon-bin" />
                      </Button>
                    </header>
                  )}

                  <Collapse isOpen={showTask}>
                    <Input
                      required
                      disabled={fetching}
                      label="Tiêu đề"
                      labelButton={
                        <div className="cpc-form-button-right">
                          <div
                            className="mr10"
                            style={{ display: 'inline-block' }}
                          >
                            <Checkbox
                              className={classNames(Classes.SMALL, 'mb0')}
                              checked={get(data, 'requireReport', false)}
                              onChange={({ target: { checked } }) =>
                                changeField(id, 'requireReport')(checked)
                              }
                            >
                              <span className="font-size-13 font-weight-300">
                                Yêu cầu báo cáo Hoàn thành
                              </span>
                            </Checkbox>
                          </div>
                          <Button
                            className={classNames(
                              Classes.MINIMAL,
                              'cpc-button',
                              'font-size-13',
                              'qlcv-green-color'
                            )}
                            onClick={() => toggleShowTemplate(id)}
                            rightIconName="chevron-down"
                          >
                            {TEMPLATE_TITLE}
                          </Button>
                        </div>
                      }
                      onChange={changeField(id, 'title')}
                      placeholder="Nhập tiêu đề/trích yếu"
                      type="textarea"
                      rows={2}
                      maxLength={500}
                      autoResize={true}
                      value={get(data, 'title')}
                      wrapperClassName="mb15"
                      warning={get(validateErrors, `${id}.title`)}
                    />
                    {/* Phòng ban và đơn vị thực hiện */}
                    <PhongBanVaDonViThucHien
                      className="mb10"
                      isRequired={true}
                      isLoading={fetching || isLoadingTree}
                      column={column}
                      data={dataTreeStore}
                      dataFavorite={favoriteGroups}
                      isDisplayFavoriteGroup={
                        !taskDetail?.workType ||
                        (taskDetail?.workType &&
                          (taskDetail?.workType === WORK_TYPE.NORMAL ||
                            (taskDetail?.workType === WORK_TYPE.EOFFICE &&
                              !hasShowAllDeparment) ||
                            (taskDetail?.workType === WORK_TYPE.MEETING &&
                              !hasShowAllDeparment) ||
                            (taskDetail?.workType === WORK_TYPE.DOFFICE &&
                              !hasShowAllDeparment)))
                      }
                      dataAutoToggle={[
                        ...dataAutoToggle,
                        ...dataAutoToggleDefault,
                      ]}
                      taskId={id}
                      warning={get(validateErrors, `${id}.${CHU_TRI}`)}
                      isHideChild={
                        hasShowAllDeparment &&
                        (taskDetail?.workType === WORK_TYPE.MEETING ||
                          taskDetail?.workType === WORK_TYPE.EOFFICE)
                      }
                    />

                    <Row gutterVertical className="mb10">
                      <Col grid="half">
                        <FormDatePicker
                          textLabel="Ngày bắt đầu"
                          disabled={fetching}
                          inputClearable
                          inputIconClassName="icon2-date-frames"
                          inputWrapperClassName="mb10"
                          onChange={value => {
                            changeField(id, 'executionDate')(value)
                          }}
                          minimumDate={moment().toDate()}
                          maximumDate={
                            get(data, 'deadline')
                              ? moment(get(data, 'deadline')).toDate()
                              : undefined
                          }
                          placeholder="Chọn ngày bắt đầu"
                          popoverStretch={false}
                          popoverPosition={Position.TOP_LEFT}
                          selected={get(data, 'executionDate')}
                        />
                      </Col>
                      <Col grid="half">
                        <DateAdvanced
                          textLabel="Hạn thực hiện"
                          disabled={fetching}
                          inputClearable
                          inputIconClassName="icon2-date-frames"
                          inputWrapperClassName="mb10"
                          placeholder="Chọn hạn thực hiện"
                          popoverStretch={false}
                          selected={get(data, 'deadline')}
                          inputWarning={get(validateErrors, `${id}.deadline`)}
                          minimumDate={
                            get(data, 'executionDate')
                              ? moment(get(data, 'executionDate')).toDate()
                              : moment().toDate()
                          }
                          popoverPosition={Position.TOP_RIGHT}
                          onChange={value => {
                            changeField(id, 'deadline')(value)
                          }}
                        />
                      </Col>
                    </Row>

                    {/* Thông tin bổ sung */}
                    <Collapse isOpen={showExtra}>
                      <Input
                        disabled={fetching}
                        label="Mô tả"
                        onChange={changeField(id, 'description')}
                        placeholder="Nhập..."
                        rows={2}
                        maxLength={500}
                        autoResize={true}
                        style={{ maxHeight: 'unset' }}
                        type="textarea"
                        value={get(data, 'description')}
                        wrapperClassName="mb10"
                      />
                      <Row gutterVertical className="mb10">
                        <Col grid="half">
                          <Select
                            textLabel="Mức độ ưu tiên"
                            dataSource={toPairs(priorities).map(
                              ([value, label]) => ({ value, label })
                            )}
                            disabled={fetching}
                            inputIconClassName="icon-flag"
                            onChange={changeField(id, 'priority')}
                            placeholder="Chọn mức độ ưu tiên"
                            value={get(data, 'priority')}
                          />
                        </Col>
                        <Col grid="half">
                          <RemindSelect
                            textLabel="Thời gian nhắc việc"
                            inputIconClassName="icon2-date-frames"
                            deadline={get(data, 'deadline')}
                            disabled={fetching}
                            onChange={changeField(id, 'workReminder')}
                            value={get(data, 'workReminder')}
                            placeholder="Thời gian nhắc việc"
                            popoverPosition={Position.BOTTOM_RIGHT}
                          />
                        </Col>
                      </Row>

                      <CheckboxSelect
                        textLabel="Loại công việc"
                        disabled={fetching}
                        inputClearable
                        inputIconClassName="icon2-tag"
                        inputWrapperClassName="mb10"
                        placeholder="Loại công việc"
                        onChange={changeField(id, 'tags')}
                        dataSource={tags.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                        value={get(data, 'tags', [])}
                      />

                      {!!size(get(data, 'referenceFiles', [])) && (
                        <>
                          <label className="cpc-detail-label">
                            File công văn
                          </label>
                          <FileGroup list={get(data, 'referenceFiles')} />
                        </>
                      )}

                      {!!size(get(data, 'attachmentFiles', [])) &&
                        !!size(
                          attachmentFilesFromDocument(
                            get(data, 'attachmentFiles', []),
                            true
                          )
                        ) && (
                          <>
                            <label className="cpc-detail-label">
                              File đính kèm từ công văn
                            </label>
                            <FileGroup
                              list={attachmentFilesFromDocument(
                                get(data, 'attachmentFiles', []),
                                true
                              )}
                              onClose={removeAttachmentFiles(id)}
                            />
                          </>
                        )}

                      {((!!size(get(data, 'attachmentFiles', [])) &&
                        !!size(
                          attachmentFilesFromDocument(
                            get(data, 'attachmentFiles', []),
                            false
                          )
                        )) ||
                        !!size(files[id])) && (
                        <>
                          <label className="cpc-detail-label">
                            File đính kèm từ bên ngoài
                          </label>
                          <FileGroup
                            list={attachmentFilesFromDocument(
                              get(data, 'attachmentFiles', []),
                              false
                            )}
                            listUpload={files[id]}
                            onClose={removeAttachmentFiles(id)}
                            showClose
                          />
                        </>
                      )}

                      <Upload
                        textLabel="File đính kèm"
                        accept={documentTypes.toString()}
                        fileList={get(files, id, [])}
                        wrapperClassName="mb10"
                        textSumary={
                          <p className="mt5 font-style-italic font-size-12">
                            Tải file có dung lượng tối đa 100MB, định dạng .pdf,
                            .docs, .doc, .xls, .xlsx, .ppt, .pptx
                          </p>
                        }
                        isShowList={false}
                        disabled={fetching}
                        allowFileTypes={allowDocumentFileTypes}
                        onChange={changeFile(id)}
                      />

                      {!isUpdate && (
                        <Checkbox
                          className={Classes.SMALL}
                          onChange={({ target: { checked } }) =>
                            changeField(id, 'isSaveTemplate')(checked)
                          }
                          checked={get(data, 'isSaveTemplate', false)}
                        >
                          <span className="font-size-13">Lưu mẫu</span>
                        </Checkbox>
                      )}
                    </Collapse>

                    <Button
                      className={classNames(
                        Classes.MINIMAL,
                        'cpc-button',
                        'cpc-form-button-left'
                      )}
                      intent={Intent.PRIMARY}
                      onClick={toggleCollapseExtra(id)}
                    >
                      {showExtra ? 'Thu gọn' : 'Mở rộng'}
                      <Icon
                        classIcon={
                          showExtra ? 'icon2-arrow-up' : 'icon2-arrow-down'
                        }
                        className={classNames('ml5', 'size-icon-10', 'd-flex')}
                      />
                    </Button>
                  </Collapse>
                </div>
              </Fragment>
            ))}
          </main>
        </Scrollbar>

        {!isUpdate && (
          <div className={classNames('d-flex', 'pv10 ph15', 'border-top')}>
            <Button
              className={classNames(
                Classes.MINIMAL,
                'cpc-button',
                'qlcv-green-color',
                'cpc-form-button-left',
                'font-size-13'
              )}
              onClick={addTask}
            >
              <Icon classIcon="icon-Plus" className="size-icon-12 mr5" /> Thêm
              công việc
            </Button>
            {false && (
              <span>
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="apply-excel"
                  style={{ display: 'none' }}
                  onChange={importExcel}
                />
                <Button
                  className={classNames(
                    Classes.MINIMAL,
                    'cpc-button',
                    'qlcv-green-color',
                    'cpc-form-button-right',
                    'font-size-13'
                  )}
                  onClick={() => {
                    const ele = document.getElementById('apply-excel')
                    ele && ele.click()
                  }}
                >
                  <Icon classIcon="icon2-excel" className="mr5" />
                  Nhập từ excel
                </Button>
              </span>
            )}
          </div>
        )}

        <div
          className={classNames(
            'element-center',
            'pv10 ph15',
            'mb10',
            'border-top'
          )}
        >
          <Button
            className={classNames(
              'cpc-button',
              'btn-cancel',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100',
              'mr15'
            )}
            disabled={fetching}
            onClick={onClose}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={classNames(
              'cpc-button',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100'
            )}
            disabled={fetching || isDisabledButtonDefault}
            intent={Intent.PRIMARY}
            onClick={isUpdate ? submitUpdate : submitCreate}
            loading={submitLoading}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
      </div>

      {/* Dialog chọn mẫu có sẵn */}
      <TemplateDialog
        isOpen={showTemplateTaskId}
        onClose={() => toggleShowTemplate()}
        onConfirm={values => {
          toggleShowTemplate()
        }}
      />

      <Dialog
        buttonUppercase
        isOpen={isShowDialog}
        cancelClassName="pl20 pr20"
        cancelText="Hủy"
        confirmClassName="pl20 pr20"
        confirmText="Xóa"
        onCancel={onCloseDialog}
        onConfirm={onDeleteTask}
        onClose={onCloseDialog}
        title={CONFIRM_TITLE}
        textCenter
      >
        <Card className="mt20 mb20">{CONFIRM_CONTENT}</Card>
      </Dialog>
    </>
  )
}

export default withRouter(memo(Task))
