import React from 'react'
import { Intent, Checkbox, Button } from '@blueprintjs/core'
import classnames from 'classnames'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'
import { SelectTree } from '../common/'

const PATH = '/he-thong/so-luu/chinh-sua/:id'
const ADMIN = 'ADMIN'
const SO_DEN = 1
const SO_CUOI = 0

class FormAddUrgencyCategory extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleUnit = this.handleUnit.bind(this)
  }

  state = {
    soLuuId: '',
    maSo: '',
    tenSo: '',
    loai: SO_DEN,
    isActive: true,
    soCuoi: SO_CUOI,
    isLoading: false,
    error: {},
    path: null,
    stt: null,
    isOpenPopup: false,
    saveCode: null,
    donViId: this.props.auth.mainUnitId,
    disabledSelect: false,
    isLoadingData: {
      maSo: true,
      tenSo: true,
      loaiSo: true,
      donViId: true,
      isActive: true,
    },
    unitSelect: [],
  }

  componentDidMount() {
    let isUnit = Utils.getData('SaveNote', {})

    this.setState({ donViId: isUnit })
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    } else if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    }
    if (e.target.name === 'loai') {
      this.setState({ loai: e.target.value })
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.auth !== this.props.auth) {
      if (
        nextProps.auth &&
        nextProps.auth.permission &&
        nextProps.auth.permission.length === 0
      ) {
        return
      }

      const disabledSelect =
        nextProps.auth &&
        nextProps.auth.permission &&
        nextProps.auth.permission.find(item => item === ADMIN) === undefined
      this.setState({ disabledSelect })
    }

    if (nextProps.saveCode !== this.props.saveCode) {
      if (!nextProps.saveCode) {
        return
      }
      this.setState(
        {
          soLuuId: nextProps.saveCode.soLuuId || '',
          maSo: nextProps.saveCode.maSoLuu || '',
          tenSo: nextProps.saveCode.tenSoLuu || '',
          isActive: nextProps.saveCode.isActive ? true : false,
          soCuoi: nextProps.saveCode.soCuoi || SO_CUOI,
          donViId: nextProps.saveCode.donViId || '',
          loai: nextProps.saveCode.loai || SO_DEN,
          saveCode: nextProps.saveCode,
          stt: nextProps.saveCode.stt,
        },
        () => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              maSo: false,
              tenSo: false,
              loaiSo: false,
              donViId: false,
              isActive: false,
            },
          })
        }
      )
    }

    if (nextProps.unitSelect !== this.props.unitSelect) {
      await this.setState({
        unitSelect: nextProps.unitSelect,
        isLoadingData: {
          ...this.state.isLoadingData,
          donViId: false,
        },
      })
    }

    if (
      nextProps.saveCode === this.props.saveCode &&
      this.props.match.path !== PATH
    ) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          maSo: false,
          tenSo: false,
          loaiSo: false,
          isActive: false,
        },
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const error = {}

    if (this.state.maSo.trim().length === 0) {
      error.maSo = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenSo.trim().length === 0) {
      error.tenSo = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    Utils.saveData('SaveNote', this.state.donViId)
    this.setState({ isLoading: true })
    if (this.props.saveCode) {
      this.props.actions
        .updateCommonFetchSaveCodes({
          ma_so_luu: this.state.maSo,
          ten_so_luu: this.state.tenSo,
          is_active: this.state.isActive,
          so_luu_id: this.state.soLuuId,
          don_vi_id: this.state.donViId,
          loai: this.state.loai,
          so_cuoi: this.state.soCuoi,
          stt: this.state.stt,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.props.actions
        .postCommonFetchSaveCodes({
          ma_so_luu: this.state.maSo,
          ten_so_luu: this.state.tenSo,
          is_active: this.state.isActive,
          don_vi_id: this.state.donViId,
          loai: this.state.loai,
          so_cuoi: this.state.soCuoi,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  handleUnit(e) {
    if (e !== undefined) {
      this.setState({
        donViId: e,
      })
    }
  }

  render() {
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="maSo"
                      >
                        Mã sổ
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.maSo}
                          autoFocus={true}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="maSo"
                          placeholder="Nhập mã sổ"
                          onChange={this.handleChange}
                        />
                        {this.state.error.maSo !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maSo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenSo"
                      >
                        Tên sổ
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenSo}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="tenSo"
                          placeholder="Nhập tên sổ"
                          onChange={this.handleChange}
                        />
                        {this.state.error.tenSo !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenSo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-cols form-col5">
                      <div className="form-group-item pt-form-group pt-intent-danger">
                        <label
                          className="pt-label pt-label-inline"
                          htmlFor="tenVietTat"
                        >
                          Đơn vị
                        </label>
                        <div className="input-form-inline pt-form-content">
                          <SelectTree
                            className="select-tree"
                            data={this.state.unitSelect}
                            type="Unit"
                            permission={this.state.disabledSelect}
                            value={
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) === undefined
                                ? this.state.donViId
                                : ''
                            }
                            handleChange={this.handleUnit}
                            isLoading={
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-cols form-col5">
                      <div className="form-group-item pt-form-group pt-intent-danger">
                        <label
                          className="pt-label pt-label-inline"
                          htmlFor="tenVietTat"
                        >
                          Loại sổ
                        </label>
                        <div className="input-form-inline pt-form-content">
                          <div
                            className={classnames({
                              'pt-select':
                                Object.values(this.state.isLoadingData).find(
                                  item => item === true
                                ) === undefined,
                            })}
                          >
                            <select
                              className={classnames('pt-input', {
                                'loading-input':
                                  this.state.isLoadingData.loaiSo,
                              })}
                              disabled={
                                Object.values(this.state.isLoadingData).find(
                                  item => item === true
                                ) !== undefined
                              }
                              name="loai"
                              onChange={this.handleChange}
                              value={this.state.loai}
                            >
                              <option value={1}>Sổ đến</option>
                              <option value={2}>Sổ đi</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-cols form-col5">
                      <div className="form-group-item form-group-inline">
                        <label
                          className="pt-label pt-label-inline"
                          htmlFor="isActive"
                        >
                          Sử dụng
                        </label>
                        <div className="input-form-inline">
                          <Checkbox
                            name="isActive"
                            disabled={
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined
                            }
                            onChange={this.handleChange}
                            checked={this.state.isActive}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading === true ? true : false}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-main-color"
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined
                  }
                  loading={this.state.isLoading}
                  onClick={this.handleSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default FormAddUrgencyCategory
