import * as types from '../constants/ActionTypes'

const initialState = {
  dsLoaiCuocHop: [],
  dsCapDoCuocHop: [],
  dsDiaDiemHop: [],
  dsNhomVatTu: [],
  dsLoaiVatTu: [],
}

export default function lichHop(state = initialState, action) {
  switch (action.type) {
    case types.GET_DANH_SACH_LOAI_CUOC_HOP:
      return {
        ...state,
        dsLoaiCuocHop: action?.payload?.data?.result?.items || [],
      }
    case types.GET_DANH_SACH_CAP_DO_CUOC_HOP:
      return {
        ...state,
        dsCapDoCuocHop: action?.payload?.data?.result?.items || [],
      }
    case types.GET_DANH_SACH_DIA_DIEM_HOP:
      return {
        ...state,
        dsDiaDiemHop: action?.payload?.data?.result?.items || [],
      }
    case types.GET_DANH_SACH_NHOM_VAT_TU:
      return {
        ...state,
        dsNhomVatTu: action?.payload?.data?.result?.items || [],
      }
    case types.GET_DANH_SACH_LOAI_VAT_TU:
      return {
        ...state,
        dsLoaiVatTu: {
          items: action?.payload?.data?.result?.items || [],
          page: action?.payload?.data?.result?.page,
          total: action?.payload?.data?.result?.total,
        },
      }
    case types.GET_DANH_SACH_LICH_HOP:
      return {
        ...state,
        dsLichHop: {
          items: action?.payload?.data?.result?.items || [],
          page: action?.payload?.data?.result?.page,
          total: action?.payload?.data?.result?.total,
        },
      }
    default:
      return state
  }
}
