import React, { memo, useCallback, useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import { connect } from 'react-redux'
import size from 'lodash/size'

import requestAction from 'helpers/request'
import { getDOCongViec } from 'actions/doffice'
import FlowDOfficeItem from './FlowDOfficeItem'

const FlowDOffice = ({ data, match }) => {
  const { id: taskId } = match.params
  const [dataDetail, setDataDetail] = useState(null)

  const getDODetail = useCallback(() => {
    if (taskId) {
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDOCongViec(taskId),
        afterResponse: response => {
          const newData = response?.result || null
          setDataDetail(newData)
        },
      })
    }
  }, [taskId])

  useEffect(() => {
    getDODetail()
  }, [getDODetail])

  return (
    <div className="mb20">
      <h1
        className="text-uppercase font-size-12 font-weight-500"
        style={{ color: '#738A95' }}
      >
        Quá trình xử lý
      </h1>
      <div className="mt20">
        <span className="font-size-13 font-weight-700">
          {dataDetail?.creator?.fullName || ''}
        </span>
        <div className="font-size-13">
          <strong>Ngày tạo</strong>:{' '}
          {moment(dataDetail?.creationTime).isValid()
            ? moment(dataDetail?.creationTime).format('DD/MM/YYYY HH:mm')
            : ''}
        </div>
      </div>
      {data &&
        size(data) !== 0 &&
        data?.map((item, key) => {
          return <FlowDOfficeItem key={key} data={item} />
        })}
    </div>
  )
}

export default withRouter(connect(null)(memo(FlowDOffice)))
