import React, { memo } from 'react'
import { MentionsInput, Mention as MentionList } from 'react-mentions'

import DefaultStyle from './DefaultStyle'

const Mention = ({
  users = [],
  dataValue = '',
  onChange = () => {},
  singleLine = false,
  className = '',
}) => {
  const handleChangeMention = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    onChange(event.target.value, mentions)
  }
  const renderSuggestion = (
    suggestion,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div key={index} className={`user ${focused ? 'focused' : ''}`}>
        {highlightedDisplay}
      </div>
    )
  }
  return (
    <MentionsInput
      style={DefaultStyle}
      className={className}
      singleLine={singleLine}
      value={dataValue}
      onChange={handleChangeMention}
      placeholder={'Nhập ý kiến'}
      a11ySuggestionsListLabel={'Suggested mentions'}
    >
      <MentionList
        markup="@@[__display__](__id__)@@"
        trigger="@"
        data={users}
        renderSuggestion={renderSuggestion}
        appendSpaceOnAdd
        displayTransform={(id, display) => `@${display}`}
      />
    </MentionsInput>
  )
}

export default memo(Mention)
