import {
  GET_STATISTIC_MESSENGER,
  GET_STATISTIC_NOT_MESSENGER,
} from '../constants/ActionTypes'

const initialState = {
  // Thống kê tin nhan
  messenger: {
    list: [],
    total: 0,
  },
  notMessenger: {
    list: [],
    total: 0,
  },
}

export default function statisticMessenger(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTIC_MESSENGER:
      return {
        ...state,
        messenger: {
          list: action.payload.data || [],
          total: action.payload.data ? action.payload.data.length : 0,
        },
      }

    case GET_STATISTIC_NOT_MESSENGER:
      return {
        ...state,
        notMessenger: {
          list: action.payload.data || [],
          total: action.payload.data ? action.payload.data.length : 0,
        },
      }

    default:
      return state
  }
}
