import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { size, get } from 'lodash'
import moment from 'moment'

import { MasterLayout } from 'components/layout'
// import { ListFooter } from 'components/ListGerenal/DocumentCabinet';
import { GridView } from 'components/common'
import * as Actions from 'actions'
import { PAGINATION_LIMIT } from 'constants/Api'
import HeadingPanel from 'components/common/HeadingPanel'

import Pagination from 'components/Pagination'

const HSCVTraLai = ({
  actions: { getDanhSachHSCVTraLai },
  history,
  location,
  match,
  typeSidebar,
}) => {
  const { id, type } = match.params
  const { state } = location
  const [dataTraLai, setDataTraLai] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  // const [isError, setIsError] = useState(false);
  // const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [totalItem, setTotalItem] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const headersTable = [
    {
      classNames: 'colum-stt-first',
      value: 'Stt',
    },
    {
      classNames: '',
      value: 'Tên Ban/Văn phòng',
    },
    {
      classNames: 'white-space-normal',
      value: 'Chuyên viên Lập HSCV',
    },
    {
      classNames: 'white-space-normal',
      value: 'Tên hồ sơ công việc',
    },
    {
      classNames: 'white-space-normal',
      value: 'Thời gian hồ sơ',
    },
    {
      classNames: 'white-space-normal',
      value: 'Thời gian nộp hồ sơ',
    },
    {
      classNames: 'white-space-normal',
      value: 'Thời hạn bảo quản',
    },
    {
      classNames: 'white-space-normal',
      value: 'Lý do trả lại',
    },
    {
      classNames: 'white-space-normal',
      value: 'Người trả lại',
    },
  ]

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const getData = (page = 1) => {
    setIsLoading(true)
    const params = {
      start_date: get(state, 'filter.start_date'),
      end_date: get(state, 'filter.end_date'),
      don_vi_id: type === 'don-vi' ? id : null,
      phong_ban_id: type === 'phong-ban' ? id : null,
      chuc_danh_id: type === 'ca-nhan' ? id : null,
      page,
      limit: PAGINATION_LIMIT,
    }
    getDanhSachHSCVTraLai(params).then(res => {
      setIsLoading(false)
      if (res && size(get(res, 'payload.data.result.items')) !== 0) {
        setIsEmpty(false)
        setTotalItem(get(res, 'payload.data.result.total'), 0)
        setDataTraLai(get(res, 'payload.data.result.items'))
      } else {
        setIsEmpty(true)
        setTotalItem(0)
        setDataTraLai([])
      }
    })
  }

  // const exportData = () => {};

  const onPagination = page => {
    setCurrentPage(page)
    getData(page)
  }

  const onGoBack = () => {
    return history.goBack()
  }

  const renderRow = ({ item, number: index }) => {
    return (
      <tr>
        <td className="cursor-default">{index > 9 ? index : '0' + index}</td>
        <td className="cursor-default">{get(item, 'tenPhongBanLap')}</td>
        <td className="cursor-default">
          {get(item, 'tenNguoiChiuTrachNhiem')}
        </td>
        <td className="cursor-default">{get(item, 'tenHoSo')}</td>
        <td className="cursor-default">{get(item, 'thoiGianHoSo')}</td>
        <td className="cursor-default">
          {get(item, 'thoiGianNop')
            ? moment(item.thoiGianNop).format('DD/MM/YYYY')
            : ''}
        </td>
        <td className="cursor-default">{get(item, 'tenThoiHanBaoQuan')}</td>
        <td className="cursor-default">{get(item, 'lyDoTraLai')}</td>
        <td className="cursor-default">{get(item, 'tenNguoiTraLai')}</td>
      </tr>
    )
  }

  return (
    <MasterLayout typeSidebar={typeSidebar}>
      <div>
        <div className="page-utilities-container">
          <HeadingPanel
            className="mb10"
            goBack={onGoBack}
            leftTitle="Tổng số HSCV lập sai quy định"
            leftIcon="icon-Tong_Hop"
          />
        </div>
        {/* Table List */}
        <div className="page-list-container">
          <div className="table-container pb0">
            <GridView
              classNames="pt-table"
              isLoading={isLoading}
              isEmpty={isEmpty}
              isError={false}
              headers={headersTable}
              page={currentPage}
              data={dataTraLai}
              itemShape={renderRow}
            />

            {!isLoading && !isEmpty && (
              <Pagination
                current={currentPage}
                total={Math.ceil(totalItem / PAGINATION_LIMIT)}
                onChange={onPagination}
              />
            )}
          </div>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  typeSidebar: state.common.typeSidebar,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(HSCVTraLai)
