import { camelize, decamelize } from 'humps'
import { toPairs, isPlainObject, isArray } from 'lodash'

export const toCamelizeKeys = object => {
  const newObject = {}
  toPairs(object).forEach(([key, value]) => {
    let newValue = value
    if (isPlainObject(value)) {
      newValue = toCamelizeKeys(value)
    }
    if (isArray(value)) {
      newValue = value.map(item => toCamelizeKeys(item))
    }
    newObject[camelize(key)] = newValue
  })

  return newObject
}

export const toDecamelizeKeys = (object, exceptKeys = []) => {
  let newObject = object
  if (isPlainObject(object)) {
    newObject = {}
    toPairs(object).forEach(([key, value]) => {
      let newValue = value
      if (isPlainObject(value)) {
        newValue = toDecamelizeKeys(value)
      }
      if (isArray(value)) {
        newValue = value.map(item => toDecamelizeKeys(item))
      }
      if (!exceptKeys.includes(key)) {
        newObject[decamelize(key)] = newValue
      } else {
        newObject[key] = newValue
      }
    })
  }
  if (isArray(object)) {
    newObject = []
    newObject = object.map(item => toDecamelizeKeys(item))
  }
  return newObject
}

// convert string - first letter uppercase
export const capitalizeFisrtLetter = string => {
  return string[0].toUpperCase() + string.slice(1)
}
