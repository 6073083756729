import React from 'react'
class ItemFormAdd extends React.Component {
  render() {
    return (
      <div className="form-block bg-grey">
        <div className="form-subblock">
          <div className="form-row">
            <div className="form-cols form-col5">
              <div className="form-group-item">
                <label className="pt-label pt-label-inline" htmlFor="donvi">
                  Đơn vị
                </label>
                <div className="input-form-inline">
                  <div className="pt-select">
                    <select
                      name="donViId"
                      onChange={this.handleChange}
                      value={this.state.donViCha}
                    >
                      <option value=""></option>
                      {this.state.unit.map((item, i) => (
                        <option key={i} value={item.donViId}>
                          {item.tenDonVi}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-cols form-col5">
              <div className="form-group-item">
                <label className="pt-label pt-label-inline" htmlFor="phongban">
                  Phòng ban
                </label>
                <div className="input-form-inline">
                  <div className="pt-select">
                    <select
                      name="phongBanId"
                      onChange={this.handleChange}
                      value={this.state.donViCha}
                    >
                      <option value=""></option>
                      {this.state.departments.map((item, i) => (
                        <option key={i} value={item.donViId}>
                          {item.tenDonVi}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-cols form-col5">
              <div className="form-group-item">
                <label className="pt-label pt-label-inline" htmlFor="chucvu">
                  Chức vụ
                </label>
                <div className="input-form-inline">
                  <div className="pt-select">
                    <select
                      name="chucVuId"
                      onChange={this.handleChange}
                      value={this.state.donViCha}
                    >
                      <option value=""></option>
                      {this.state.position.map((item, i) => (
                        <option key={i} value={item.donViId}>
                          {item.tenDonVi}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-cols form-col5">
              <div className="form-group-item">
                <label className="pt-label pt-label-inline" htmlFor="loaiquyen">
                  Loại quyền
                </label>
                <div className="input-form-inline">
                  <div className="pt-select">
                    <select
                      name="loaiQuyenId"
                      onChange={this.handleChange}
                      value={this.state.donViCha}
                    >
                      <option value=""></option>
                      {this.state.role.map((item, i) => (
                        <option key={i} value={item.donViId}>
                          {item.tenDonVi}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-cols form-col5">
              <div className="form-group-item">
                <label
                  className="pt-label pt-label-inline"
                  htmlFor="chukycodau"
                >
                  Chữ ký có dấu
                </label>
                <div className="input-form-inline">
                  <label className="pt-file-upload">
                    <input type="file" name="fileName" />
                    <span className="pt-file-upload-input">
                      Chọn file đính kèm
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-cols form-col5">
              <div className="form-group-item">
                <label
                  className="pt-label pt-label-inline"
                  htmlFor="chukykhongdau"
                >
                  Chữ ký không dấu
                </label>
                <div className="input-form-inline">
                  <label className="pt-file-upload">
                    <input type="file" name="fileName" />
                    <span className="pt-file-upload-input">
                      Chọn file đính kèm
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ItemFormAdd
