import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Intent, Button } from '@blueprintjs/core'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import * as MESSAGE from '../../constants/MessageForm'
import { FileList } from '../../components/common'

class SendMail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      emailTo: '',
      title: '',
      content: '',
      file:
        this.props.tuTaiLieuData && this.props.tuTaiLieuData.fileNoiDung
          ? this.props.tuTaiLieuData.fileNoiDung.fileId
          : this.props.tuTaiLieuData.fileCongVan
          ? this.props.tuTaiLieuData.fileCongVan.fileId
          : this.props.tuTaiLieuData.fileThamKhao
          ? this.props.tuTaiLieuData.fileThamKhao.fileId
          : '',
      fileDinhKem:
        this.props.tuTaiLieuData && this.props.tuTaiLieuData.fileDinhKemEvn
          ? this.props.tuTaiLieuData.fileDinhKemEvn
          : this.props.tuTaiLieuData.fileDinhKem
          ? this.props.tuTaiLieuData.fileDinhKem
          : [],
      dataSelect: '',
      isOpenPopup: false,
      isLoading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleMailTo = val => {
    let data = val.filter(item => item.value.trim() !== '')
    this.setState({ emailTo: data })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.emailTo !== this.state.emailTo) {
      this.setState({ error: this.state.error })
      const error = {}
      const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.state.emailTo.forEach(item => {
        if (
          item.value &&
          item.value.trim().length > 0 &&
          !re.test(item.value)
        ) {
          error.validationEmailTo = MESSAGE.TEXT_MESSAGE_TYPE_EMAIL
        }
      })
      this.setState({ error: error })
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ error: this.state.error })
    const error = {}
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (this.state.emailTo.length === 0) {
      error.emailTo = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    for (let index = 0; index < this.state.emailTo.length; index++) {
      const element = this.state.emailTo[index]
      if (
        element.value &&
        element.value.trim().length > 0 &&
        !re.test(element.value.trim())
      ) {
        error.validationEmailTo = MESSAGE.TEXT_MESSAGE_TYPE_EMAIL
        break
      }
    }
    if (this.state.title.trim().length === 0) {
      error.title = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error: error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      this.props.onSubmit(
        'GuiEmail',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false,
        null
      )
      return
    }

    const MailTo = []
    this.state.emailTo.map(item => MailTo.push(item.value))
    this.setState({ isLoading: true })
    // Convert data fileDinhKem
    let fileDinhKem = []
    ;(await this.state.fileDinhKem) &&
      this.state.fileDinhKem.length !== 0 &&
      this.state.fileDinhKem.forEach(item => {
        return fileDinhKem.push({
          file_id: item.fileId,
          ten_file: item.tenFile,
          url: item.url,
        })
      })
    await this.props.action
      .postMail({
        email_to: MailTo,
        title: this.state.title,
        content: this.state.content,
        file_id: this.state.file,
        file_dinh_kem: fileDinhKem,
      })
      .then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.setState({ isLoading: false })
          this.props.onSubmit(
            'GuiEmail',
            MESSAGE.TOATS_SEND_MAIL_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          this.setState({ isLoading: false })
          this.props.onSubmit(
            'GuiEmail',
            MESSAGE.TOATS_SEND_MAIL_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }

  handleTextCreator(input) {
    return `Kết quả tìm kiếm: "${input}"`
  }

  getOptions = (input, callback) => {
    const checkInput = input.indexOf('.')
    if (input.trim().length > 2 && checkInput < 0) {
      if (this.promiseGetEmail) {
        clearTimeout(this.promiseGetEmail)
      }
      this.promiseGetEmail = setTimeout(() => {
        this.props.action
          .getMail(`${input}`)
          .then(response => {
            return response.payload.data.result.items
          })
          .then(json => {
            const data = json.map(item => ({
              value: item.email,
              label: ['<', item.email, ' ', item.tenNhanVien, '>'],
            }))
            callback(null, {
              options: data,
              complete: true,
            })
          })
      }, 800)
    } else if (input.length < 3) {
      callback(null, {
        options: [],
        complete: true,
      })
    } else {
      callback(null, {
        options: [],
        complete: true,
      })
    }
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>Gửi email</h4>
        </div>
        <div className="form-container form-send-mail">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-4 col-xs-12">
                Gửi đến email
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="pt-form-content col-md-8 col-xs-12">
                <div className="pt-form-content">
                  <Select.AsyncCreatable
                    name="form-field-name"
                    value={this.state.emailTo}
                    loadOptions={this.getOptions}
                    onChange={this.handleMailTo}
                    autofocus={true}
                    autoload={false}
                    multi={true}
                    placeholder="Nhập email"
                    searchPromptText="Nhập email...."
                    loadingPlaceholder="Đang tìm kiếm...."
                    promptTextCreator={this.handleTextCreator}
                    noResultsText="Không tìm thấy dữ liệu..."
                    clearValueText="Xóa dữ liệu"
                  />
                </div>
                {this.state.error.emailTo !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.emailTo}
                  </div>
                )}
                {this.state.error.validationEmailTo !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.validationEmailTo}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-4 col-xs-12">
                Tiêu đề
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="pt-form-content col-md-8 col-xs-12">
                <div className="pt-form-content">
                  <input
                    className="pt-input"
                    placeholder="Nhập tiêu đề"
                    type="text"
                    dir="auto"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.title}
                  />
                </div>
                {this.state.error.title !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.title}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-4 col-xs-12">Nội dung</label>
              <div className="pt-form-content col-md-8 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nội dung…"
                    dir="auto"
                    name="content"
                    onChange={this.handleChange}
                    value={this.state.content}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            {/* File đính kèm */}
            {this.props.tuTaiLieuData &&
              this.props.tuTaiLieuData.fileDinhKem &&
              this.props.tuTaiLieuData.fileDinhKem.length !== 0 && (
                <div className="row">
                  <label className="pt-label col-md-4 col-xs-12">
                    File đính kèm:
                  </label>
                  <div className="pt-form-content col-md-8 col-xs-12">
                    <FileList
                      auth={this.props.auth}
                      receiveDocument={this.props.tuTaiLieuData}
                    />
                  </div>
                </div>
              )}
            {/* File tham khảo */}
            {this.props.tuTaiLieuData &&
              this.props.tuTaiLieuData.fileThamKhao &&
              this.props.tuTaiLieuData.fileThamKhao.length !== 0 && (
                <div className="row">
                  <label className="pt-label col-md-4 col-xs-12">
                    File tham khảo:
                  </label>
                  <div className="pt-form-content col-md-8 col-xs-12">
                    <FileList
                      auth={this.props.auth}
                      receiveDocument={this.props.tuTaiLieuData}
                      field="fileThamKhao"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-main-color"
            loading={this.state.isLoading}
          >
            <span className="text-content">Gửi email</span>
            <span className="pt-icon icon-Send-Email"></span>
          </Button>
        </div>
      </div>
    )
  }
}

SendMail.propTypes = {
  onClickGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tuTaiLieuData: PropTypes.object,
  action: PropTypes.object.isRequired,
}

export default SendMail
