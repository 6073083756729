import React, { memo, useState, useCallback, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import { connect } from 'react-redux'
import classNames from 'classnames'
import FileDownload from 'file-saver'
import toPairs from 'lodash/toPairs'
import uniqBy from 'lodash/uniqBy'
import size from 'lodash/size'
import moment from 'moment'
import { toDecamelizeKeys } from 'helpers/key'

import requestAction from 'helpers/request'
import { Action, Toast } from 'constants/MessageForm'
import { priorities } from 'constants/task'
import { Icon, Row, Col, FileGroup, Scrollbar } from 'components/newCommon'
import {
  Input,
  Select,
  DateAdvanced,
  FormDatePicker,
} from 'components/newCommon2'
import PhongBanVaDonViThucHien from './_components/PhongBanVaDonViThucHien'
import { showToast, getDODowloadFile } from 'actions/task'
import {
  getDOCongViec,
  // getDOToken,
  getPhongBanThucHienNotToken,
  getDonViTrucThuocNotToken,
  putDOGiaoViec,
  getDODonViIdByUsername,
} from 'actions/doffice'

const GiveTaskFormDOffice = ({
  match,
  userName,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const FORM_TITLE = 'Giao việc'
  const PHOI_HOP = 'PHOI_HOP'
  const CHU_TRI_DO = 'CHU_TRI_DO'
  const PHOI_HOP_DO = 'PHOI_HOP_DO'
  const THEO_DOI_DO = 'THEO_DOI_DO'
  const { id, action } = match.params
  const showTitle =
    action === 'sua-giao-viec-d-office' ? 'Sửa giao việc' : FORM_TITLE
  const [dataOrganization, setDataOrganization] = useState([])
  const [dataDepartment, setDataDepartment] = useState([])
  const [dataAutoToggle] = useState([-2])
  const [dataForm, setDataForm] = useState({})
  const [validateErrors, setValidateErrors] = useState({})
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [donViId, setDonViId] = useState(null)

  const validate = useCallback(() => {
    setValidateErrors({})
    const success = size(dataForm?.[PHOI_HOP]) !== 0
    if (!success) {
      setValidateErrors({
        [PHOI_HOP]: true,
      })
      throw new Error('warning')
    }
  }, [dataForm])

  const getRequestData = useCallback(() => {
    const phoiHop =
      dataForm?.[PHOI_HOP]?.map(elm => ({
        responsibilityId: elm?.id,
        deadline: dataForm?.deadline,
        executionDate: dataForm?.executionDate,
      })) || []
    return phoiHop.map(user => toDecamelizeKeys(user))
  }, [dataForm])

  const submit = useCallback(async () => {
    let requestData = []
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      beforeAction: () => {
        setIsActionLoading(true)
        validate()
      },
      action: async () => {
        requestData = await getRequestData()
        return putDOGiaoViec(id, userName, requestData)
      },
      afterResponse: res => {
        if (res?.code === 200) {
          return showToast({
            message: Toast.SUCCESS(Action.ASSIGN),
            intent: Intent.SUCCESS,
          })
        }

        return showToast({
          message: Toast.FAIL(Action.ASSIGN),
          intent: Intent.WARNING,
        })
      },
      afterAction: () => {
        setIsActionLoading(false)
        reloadList()
        onClose()
      },
    })
  }, [id, userName, getRequestData, validate, onClose, reloadList])

  const changeField = name => value => {
    setDataForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDownload = useCallback(e => {
    const params = {
      fileDoId: e?.fileId,
      workType: 'DOFFICE',
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDODowloadFile(params),
      afterResponse: async response => {
        if (response) {
          FileDownload.saveAs(response, e?.name)
        }
      },
    })
  }, [])

  const onChangeAll = useCallback(
    (name, isIndeterminate, event) => {
      const isChecked = event?.target?.checked
      setDataForm(prev => ({
        ...prev,
        [name]:
          isChecked && (isIndeterminate || !isIndeterminate)
            ? dataOrganization
            : [],
      }))
    },
    [dataOrganization]
  )

  const onChangeCheckbox = useCallback((name, values, event) => {
    const isChecked = event?.target?.checked
    setDataForm(prev => ({
      ...prev,
      [name]: uniqBy(
        [
          ...(prev?.[name]
            ? isChecked
              ? [...prev?.[name], values]
              : prev?.[name]?.filter(e => e.id !== values?.id)
            : []),
          ...(!prev?.[name] ? [values] : []),
        ],
        'id'
      ),
    }))
  }, [])

  const handleCheckAllActive = useCallback(
    name => {
      const sizeCheck = size(dataForm?.[name])
      const sizeOrigin = size(dataOrganization)
      if (sizeCheck === sizeOrigin) {
        return true
      }

      return false
    },
    [dataForm, dataOrganization]
  )

  const handleCheckIndeterminate = useCallback(
    name => {
      let isChecked = false
      const dataKey = size(dataForm?.[name]) !== 0
      if (dataKey) {
        isChecked = true
      }

      return isChecked
    },
    [dataForm]
  )

  const handleCheckActive = useCallback(
    (name, record) => {
      let isChecked = false
      const dataKey = dataForm?.[name]?.findIndex(e => e?.id === record?.id)
      if (dataKey === 0 || (dataKey && dataKey !== -1)) {
        isChecked = true
      }

      return isChecked
    },
    [dataForm]
  )

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: record => {
        const isChecked = handleCheckActive(CHU_TRI_DO, record)
        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            disabled={true}
          />
        )
      },
    },
    {
      title: 'PH',
      render: record => {
        const isChecked = handleCheckActive(PHOI_HOP_DO, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            disabled={true}
          />
        )
      },
    },
    {
      title: 'TD',
      render: record => {
        const isChecked = handleCheckActive(THEO_DOI_DO, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            disabled={true}
          />
        )
      },
    },
  ]

  const columnDonVi = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'PH',
      renderTitle: () => {
        const isChecked = handleCheckAllActive(PHOI_HOP)
        const isIndeterminate = handleCheckIndeterminate(PHOI_HOP)
        return (
          <div className="reset-margin">
            <Checkbox
              className={Classes.SMALL}
              label="PH"
              checked={isChecked}
              indeterminate={isIndeterminate && !isChecked}
              onChange={e => onChangeAll(PHOI_HOP, isIndeterminate, e)}
            />
          </div>
        )
      },
      render: record => {
        const isChecked = handleCheckActive(PHOI_HOP, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            onChange={e => onChangeCheckbox(PHOI_HOP, record, e)}
          />
        )
      },
    },
  ]

  const getThongTinCongViec = useCallback(() => {
    if (!id) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDOCongViec(id),
      afterResponse: response => {
        const newData = response?.result || null
        setDataForm(
          newData
            ? {
                description: newData?.description,
                executionDate: newData?.executionDate
                  ? moment(newData?.executionDate)
                  : moment(),
                deadline: newData?.deadline ? moment(newData?.deadline) : null,
                priority: newData?.priority,
                files: newData?.files,
                [CHU_TRI_DO]:
                  newData?.responsibilities?.[0]?.workAssignments?.map(e => ({
                    ...e,
                    id: e?.responsibilityId,
                    type: e?.assignType,
                  })) || [],
                [PHOI_HOP_DO]:
                  newData?.responsibilities?.[1]?.workAssignments?.map(e => ({
                    ...e,
                    id: e?.responsibilityId,
                    type: e?.assignType,
                  })),
                [THEO_DOI_DO]:
                  newData?.responsibilities?.[2]?.workAssignments?.map(e => ({
                    ...e,
                    id: e?.responsibilityId,
                    type: e?.assignType,
                  })),
                ...(action === 'sua-giao-viec-d-office'
                  ? {
                      [PHOI_HOP]:
                        newData?.responsibilities?.[1]?.workAssignments
                          ?.filter(f => f.assignType === 'ORGANIZATION')
                          ?.map(e => ({
                            ...e,
                            id: e?.responsibilityId,
                            type: e?.assignType,
                          })),
                    }
                  : {}),
              }
            : null
        )
      },
    })
  }, [id, action])

  const getPhongBan = useCallback(() => {
    if (!donViId) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getPhongBanThucHienNotToken(donViId),
      afterResponse: response => {
        if (size(response?.result) !== 0) {
          const khoiLanhDao =
            response?.result
              ?.filter(f => f?.phongBanLanhDao)
              ?.map(elm => ({
                id: elm?.phongBanId,
                type: 'DEPARTMENT',
                name: elm?.maPhongBan,
                code: elm?.maPhongBan,
                parentId: -1,
              })) || []
          const khoiPhongBan =
            response?.result
              ?.filter(f => !f?.phongBanLanhDao)
              ?.map(elm => ({
                id: elm?.phongBanId,
                type: 'DEPARTMENT',
                name: elm?.maPhongBan,
                code: elm?.maPhongBan,
                parentId: -2,
              })) || []
          const newData = [
            {
              id: -1,
              type: 'ALL',
              name: 'Khối lãnh đạo',
              code: 'Khối lãnh đạo',
              parentId: null,
              children: khoiLanhDao,
            },
            {
              id: -2,
              type: 'ALL',
              name: 'Khối phòng ban',
              code: 'Khối phòng ban',
              parentId: null,
              children: khoiPhongBan,
            },
          ]
          setDataDepartment(newData)
        }
      },
    })
  }, [donViId])

  const getDonVi = useCallback(() => {
    if (!donViId) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDonViTrucThuocNotToken(donViId),
      afterResponse: response => {
        if (size(response?.result) !== 0) {
          setDataOrganization(
            response?.result?.map(elm => {
              return {
                ...elm,
                id: elm?.donViId,
                type: 'ORGANIZATION',
                name: elm?.tenVietTat,
                code: elm?.tenVietTat,
                parentId: elm?.donViCha,
              }
            })
          )
        }
      },
    })
  }, [donViId])

  useEffect(() => {
    getThongTinCongViec()
  }, [getThongTinCongViec])

  // useEffect(() => {
  //   requestAction({
  //     showToast: false,
  //     codeCheck: false,
  //     getResult: false,
  //     action: () => getDOToken(userName),
  //   });
  // }, [userName]);

  useEffect(() => {
    getPhongBan()
  }, [getPhongBan])

  useEffect(() => {
    getDonVi()
  }, [getDonVi])

  useEffect(() => {
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDODonViIdByUsername(userName),
      afterResponse: response => {
        if (response?.result) {
          setDonViId(response?.result)
        }
      },
    })
  }, [userName])

  return (
    <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>{showTitle}</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 170}>
        <div className="cpc-form p10">
          <Input
            label="Nội dung chỉ đạo"
            required
            placeholder="Nhập nội dung chỉ đạo..."
            autoResize={true}
            type="textarea"
            rows={2}
            maxLength={500}
            wrapperClassName="mb10"
            disabled={true}
            value={dataForm?.description}
            onChange={changeField('description')}
            warning={validateErrors?.description}
          />
          <Row gutterVertical>
            <Col grid="half">
              <DateAdvanced
                textLabel="Hạn giải quyết"
                disabled={true}
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                placeholder="Chọn hạn giải quyết"
                popoverStretch={false}
                selected={dataForm?.deadline}
                inputWarning={validateErrors?.deadline}
                popoverPosition={Position.BOTTOM_RIGHT}
                onChange={value => {
                  changeField('deadline')(value)
                }}
              />
            </Col>
            <Col grid="half">
              <Select
                textLabel="Độ khẩn"
                isShowArrowDropdown={true}
                dataSource={toPairs(priorities).map(([value, label]) => ({
                  value,
                  label,
                }))}
                disabled={true}
                inputIconClassName="icon-flag"
                onChange={changeField('priority')}
                placeholder="Chọn độ khẩn"
                value={dataForm?.priority}
              />
            </Col>
          </Row>
          <div className="mb15">
            <label className="cpc-detail-label">File công văn</label>
            <FileGroup
              list={dataForm?.files || []}
              isLink={false}
              onChange={handleDownload}
            />
          </div>
          <PhongBanVaDonViThucHien
            className="mb15"
            headStyle={{
              backgroundColor: '#E8E8E8',
              color: '#31434B',
            }}
            label="Phòng ban thực hiện"
            isDisplayFavoriteGroup={false}
            isRequired={true}
            column={column}
            data={dataDepartment}
            dataAutoToggle={dataAutoToggle}
          />
          <PhongBanVaDonViThucHien
            className="mb15"
            headStyle={{
              backgroundColor: '#E8E8E8',
              color: '#31434B',
            }}
            label="Đơn vị trực thuộc"
            isDisplayFavoriteGroup={false}
            isNoTree={true}
            isRequired={true}
            column={columnDonVi}
            data={dataOrganization}
            dataAutoToggle={[]}
            warning={validateErrors?.[PHOI_HOP]}
          />
          <Row gutterVertical>
            <Col grid="half">
              <FormDatePicker
                textLabel="Ngày bắt đầu"
                disabled={false}
                inputClearable
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                popoverPosition={Position.TOP_LEFT}
                onChange={value => {
                  changeField('executionDate')(value)
                }}
                minimumDate={moment().toDate()}
                maximumDate={
                  dataForm?.deadline
                    ? moment(dataForm?.deadline).toDate()
                    : undefined
                }
                placeholder="Chọn ngày bắt đầu"
                popoverStretch={false}
                selected={dataForm?.executionDate}
              />
            </Col>
            <Col grid="half">
              <DateAdvanced
                textLabel="Hạn thực hiện"
                disabled={false}
                inputClearable
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                placeholder="Chọn hạn thực hiện"
                popoverStretch={false}
                selected={dataForm?.deadline}
                inputWarning={validateErrors?.deadline}
                minimumDate={
                  dataForm?.executionDate
                    ? moment(dataForm?.executionDate).toDate()
                    : moment().toDate()
                }
                popoverPosition={Position.TOP_RIGHT}
                onChange={value => {
                  changeField('deadline')(value)
                }}
              />
            </Col>
          </Row>
          <p
            className="font-size-13"
            style={{ fontStyle: 'italic', color: '#738A95' }}
          >
            Nếu không chọn LĐ PB chủ trì sẽ xác định
          </p>
        </div>
      </Scrollbar>
      <div className="border-left border-top border-right ph15 text-center">
        <Button
          className={classNames(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={isActionLoading}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={classNames(
            'cpc-button',
            'uppercase',
            'font-size-13',
            'min-width-100'
          )}
          intent={Intent.PRIMARY}
          loading={isActionLoading}
          disabled={isActionLoading}
          onClick={submit}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userName: state?.auth?.user?.username,
  }
}

export default withRouter(
  connect(mapStateToProps, null)(memo(GiveTaskFormDOffice))
)
