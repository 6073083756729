import React, { memo, useState, useEffect } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Collapse } from '@blueprintjs/core'
import size from 'lodash/size'

import { StatusTag } from 'components/newCommon'

const FlowDOfficeItem = ({ data }) => {
  const [dataView, setDataView] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const isHasChildren = size(data?.children) !== 0
  const onToggle = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    setDataView({
      status:
        data?.ttXuly === 'Hoàn thành'
          ? 'DONE'
          : data?.ttXuly === 'Đã chuyển'
          ? 'DELIVERED'
          : null,
      textStatus: data?.ttXuly,
    })
  }, [data])

  return (
    <div className="mt20 ml20">
      <div className="d-flex d-flex-wrap">
        <div
          style={{ width: 20, height: 20, lineHeight: '20px' }}
          className={classnames('mr5 text-center', {
            open: isOpen,
          })}
        >
          {isHasChildren && (
            <span
              className={classnames(
                'pt-icon font-size-13 color-blue cursor-pointer',
                {
                  'pt-icon-chevron-up': isOpen,
                  'pt-icon-chevron-down': !isOpen,
                }
              )}
              onClick={() => onToggle()}
            />
          )}
        </div>
        <div>
          <div className="d-flex d-flex-wrap font-size-13">
            <h3 className="font-size-13 font-weight-700">{data?.tenPb}</h3>
            <span className="color-blue font-weight-500 ml5">
              ({data?.maXuly})
            </span>
          </div>
          <div className="d-flex d-flex-wrap font-size-13 mt10">
            {dataView?.status && (
              <StatusTag
                type={dataView?.status}
                className="round mr10"
                text={dataView?.textStatus}
              />
            )}
            <p>
              <strong>Ngày:</strong>
              {moment(data?.ngayTao).isValid()
                ? moment(data?.ngayTao).format('DD/MM/YYYY HH:mm')
                : ''}
            </p>
          </div>
          {data.ndungXuly && (
            <div
              className="font-size-13 mt10"
              dangerouslySetInnerHTML={{ __html: data.ndungXuly }}
            ></div>
          )}
        </div>
      </div>
      {isHasChildren &&
        data?.children?.map((item, index) => (
          <Collapse key={index} isOpen={isOpen}>
            <FlowDOfficeItem data={item} />
          </Collapse>
        ))}
    </div>
  )
}

export default memo(FlowDOfficeItem)
