import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'

const UIMenuPopover = ({ dataSource }) => {
  return (
    <Popover
      popoverClassName="pt-minimal ui-menu-popover"
      position={Position.BOTTOM_RIGHT}
      interactionKind={PopoverInteractionKind.HOVER}
      openOnTargetFocus={true}
      content={
        <ul className="ui-menu-popover-menu">
          {dataSource.map(({ icon, text, onClick }, index) => (
            <li key={index} onClick={onClick}>
              <span className={classNames('icon', icon)}></span>
              <span className="name">{text}</span>
            </li>
          ))}
        </ul>
      }
    >
      <span
        className="icon-More size-icon-24"
        onClick={e => e.stopPropagation()}
      />
    </Popover>
  )
}

UIMenuPopover.defaultProps = {
  dataSource: [],
}

UIMenuPopover.propTypes = {
  dataSource: PropTypes.array,
}

export default UIMenuPopover
