import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFooter,
  ListItem,
  ListFilter,
} from '../../components/AlertMistake/Work'
import { GridView } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import * as MESSAGE from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'

const CONG_VIEC = 2
const REFRESH_FALSE = false
class ListAlertMistakeWorkPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {
        textSearch: '',
      },
      isEmpty: false,
      isLoading: false,
      page: 1,
      isError: false,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
  }

  static propTypes = {
    alertMistakes: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let preData = Tool.Utils.preData('listAlertMistakeWork')
    let state = this.state

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    }

    this.setState({ state }, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(filter = {}, page = 1, sort) {
    this.setState({ filter, page, sort, isLoading: true }, () => {
      Tool.Utils.preData('listAlertMistakeWork', { filter, page, sort })
      // tạm thời để refresh bang false
      this.props.actions
        .getAlertMistakes(filter, CONG_VIEC, page, sort, REFRESH_FALSE)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshPage(false)
        })
    })
  }

  handleSubmitRemoveItem = (id, check, noiDung) => {
    let total = 0
    let page = this.state.page
    if (
      this.props.alertMistakes &&
      this.props.alertMistakes.list &&
      this.props.alertMistakes.list.total
    ) {
      total = this.props.alertMistakes.list.total
    }

    if (check === 1) {
      this.props.actions
        .deleteAlertMistake(id, CONG_VIEC)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
              intent: Intent.DANGER,
            })
          }
          if (total > 0) {
            total -= 1
          }
          if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
            page = Math.ceil(total / Types.PAGINATION_LIMIT)
          }

          if (page === 0) {
            page = 1
          }

          this.props.actions.refreshPage(true)
          this.props.actions.getNumberWorkPage(this.props.config.refresh)
          this.submitFilter(this.state.filter, page, this.state.sort)
          // this.props.actions.commonFetchAlertMistakeTotal(Types.ALERT_MISTAKE_WORK);
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
        .catch(err => {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
            intent: Intent.DANGER,
          })
        })
    }

    if (check === 2) {
      this.props.actions
        .postAlertMistakeWork(id, {
          noi_dung: noiDung,
          cong_van_cong_viec_id: id,
        })
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
              intent: Intent.DANGER,
            })
          }
          if (total > 0) {
            total -= 1
          }
          if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
            page = Math.ceil(total / Types.PAGINATION_LIMIT)
          }

          if (page === 0) {
            page = 1
          }

          this.props.actions.refreshPage(true)
          this.props.actions.getNumberWorkPage(this.props.config.refresh)
          this.submitFilter(this.state.filter, page, this.state.sort)
          // this.props.actions.commonFetchAlertMistakeTotal(Types.ALERT_MISTAKE_WORK);
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_BAO_NHAM_CAP_TREN_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
        .catch(err => {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
            intent: Intent.DANGER,
          })
        })
    }
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar="work">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-attention-warning icon-heading"></span>
                  <span className="text-heading">
                    Danh sách chuyển nhầm công việc
                  </span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              filter={this.state.filter}
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              readOnly={false}
              removeTextSearch={this.removeTextSearch}
            />
            <div className="table-container">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table'}
                page={this.state.page}
                headers={headers.listAlertMistakeWork}
                itemShape={ListItem}
                data={this.props.alertMistakes.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onHandleClickItem={this.HandleClickItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isError={this.state.isError}
              />

              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <ListFooter
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.alertMistakes.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,

  auth: state.auth,

  alertMistakes: {
    ...state.alertMistakes,
    list: {
      ...state.alertMistakes.list,
      items: state.alertMistakes.list.items.map(
        id => state.entities.alertMistakes[id]
      ),
    },
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAlertMistakeWorkPage)
