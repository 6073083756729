import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { HoSoCongViecItem } from '../../components/tutailieu'

class HoSoCongViecGroup extends Component {
  handleAction = (key, data) => {
    this.props && this.props.handleAction(key, data)
  }

  render() {
    const { data, itemsActive } = this.props
    return data.map((elm, index) => {
      return (
        <HoSoCongViecItem
          {...this.props}
          key={index}
          auth={this.props.auth}
          data={elm}
          classLevel={`row-padding-multilevel-${elm.level}`}
          handleAction={this.handleAction}
          hanleKeyActive={this.props.hanleKeyActive}
          history={this.props.history}
          itemsActive={itemsActive}
          keyTab={this.props.keyTab}
          isShowMenuActionThuMuc={this.props.isShowMenuActionThuMuc}
          filter={this.props.filter}
        />
      )
    })
  }
}

HoSoCongViecGroup.defaultProps = {
  data: [],
}

HoSoCongViecGroup.propTypes = {
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
}

export default HoSoCongViecGroup
