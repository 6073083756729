import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'

import DanhSachCapDoCuocHop from './DanhSachCapDoCuocHop'
import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import ListFilter from './ListFilter'

const LINK = '/lich-hop/danh-muc/cap-do-cuoc-hop/them-moi'

class DanhSachCapDoCuocHopPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dsCapDoCuocHop: [],
      isLoading: true,
      isError: false,
      textSearch: '',
    }

    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
  }

  componentDidMount() {
    this.props.actions.getDanhSachCapDoCuocHop().then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dsCapDoCuocHop.items !== this.props.dsCapDoCuocHop.items) {
      this.setState(
        {
          dsCapDoCuocHop: nextProps.dsCapDoCuocHop.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }
  }

  removeTextSearch(name) {
    this.setState({
      [name]: '',
    })
  }

  submitFilter(textSearch) {
    this.setState({ textSearch, isLoading: true }, () => {
      const filter = {
        textSearch: textSearch?.trim() || undefined,
      }
      // tạm thời để refesh bằng false
      this.props.actions
        .getDanhSachCapDoCuocHop(filter)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshPage(false)
        })
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarCalendar">
        <HeadingPage
          namePage="Danh mục cấp độ cuộc họp"
          iconPage="icon-level-meeting"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <ListFilter
          textSearch={this.state.textSearch}
          onSubmit={this.submitFilter}
          actions={this.props.actions}
          readOnly={false}
          removeTextSearch={this.removeTextSearch}
        />
        <div className="listrole-container">
          <DanhSachCapDoCuocHop
            items={this.state.dsCapDoCuocHop}
            actions={this.props.actions}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          />
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  dsCapDoCuocHop: {
    ...state.dsCapDoCuocHop,
    items: state.lichHop.dsCapDoCuocHop,
  },

  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DanhSachCapDoCuocHopPage)
