import React, { PureComponent } from 'react'
import { Dialog, Button } from '@blueprintjs/core'
import { get } from 'lodash'

import { STATUS_HSCV } from '../../constants/Api'

class TiepNhan extends PureComponent {
  constructor() {
    super()
    this.state = {
      form: {
        ghiChu: '',
      },
      loading: false,
    }
  }

  chaneInput = e => {
    const { value, name } = e.target
    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: value,
      },
    }))
  }

  submit = type => async () => {
    const { submit } = this.props
    const {
      form: { ghiChu },
    } = this.state
    this.setState({ loading: true })
    await submit({
      trangThai: type,
      ghiChu,
    })
    this.setState({ loading: false })
  }

  render() {
    const { onClose, information } = this.props
    const { form, loading } = this.state

    return (
      <Dialog
        isOpen
        title="Tiếp nhận"
        onClose={onClose}
        isCloseButtonShown={!loading}
        className="size-container-dialog pb10"
      >
        <div className="pt-dialog-body container-UpdateDrawers wrapper-content-dialog">
          <div className="form-container">
            <div className="pt-form-group">
              <div className="row item-row-input">
                <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                  <label className="pt-label label-inline-input">
                    Tên hồ sơ
                  </label>
                </div>
                <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                  <span>{get(information, 'ten')}</span>
                </div>
              </div>
            </div>
            <div className="pt-form-group">
              <div className="row item-row-input">
                <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                  <label className="pt-label label-inline-input">Ghi chú</label>
                </div>
                <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                  <textarea
                    name="ghiChu"
                    rows="4"
                    className="pt-input w-100"
                    placeholder="Nhập ghi chú"
                    dir="auto"
                    maxLength={255}
                    onChange={this.chaneInput}
                    value={get(form, 'ghiChu', '')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-dialog-footer pt-dialog-footer-group-button">
          <div className="group-button center">
            <div className="group-button--content">
              <Button
                className="btn-red-bg-while"
                loading={loading}
                disabled={loading}
                onClick={this.submit(STATUS_HSCV.TRA_LAI)}
              >
                Trả lại
              </Button>
            </div>
            <div className="group-button--content">
              <Button
                className="btn-blue"
                loading={loading}
                disabled={loading}
                onClick={this.submit(STATUS_HSCV.VT_DA_TIEP_NHAN)}
              >
                Tiếp nhận
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default TiepNhan
