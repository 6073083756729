import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Tool from '../../../helpers'
import * as Types from '../../../constants/Api'

export default class FileDinhKem extends Component {
  kieuFile = extension => {
    let extensionArray = [
      'doc',
      'docx',
      'pdf',
      'xlsx',
      'xls',
      'ppt',
      'pptx',
      'ppsx',
      'pps',
      'zip',
    ]
    let extensionResult = extensionArray.filter(item => item === extension)
    if (extensionResult.length !== 0) {
      return 'DOCUMENT'
    }

    return 'IMAGE'
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    let { files } = this.props
    return (
      <div className="service-file-block">
        {files &&
          files.map((item, key) => {
            let extensionTK = item.fileName
              .slice(((item.fileName.lastIndexOf('.') - 1) >>> 0) + 2)
              .toLowerCase()
            if (this.kieuFile(extensionTK) === 'DOCUMENT') {
              return (
                <a
                  key={key}
                  className="file-document"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${Types.FILE_URL}/api/filechat/get/${item.fileId}?fileName=${item.fileName}&BearerToken=${token}`}
                >
                  <div
                    className="file-kieufile"
                    style={{
                      backgroundImage: `url(${Tool.Utils.typeFile(
                        extensionTK
                      )})`,
                    }}
                  />
                  <div className="file-info">
                    <p>{item.tenFile}</p>
                    <span>{Tool.Utils.getFileSize(item.kichThuoc)}</span>
                  </div>
                </a>
              )
            }

            return (
              <div key={key} className="file-images">
                <a
                  key={key}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${Types.FILE_URL}/api/filechat/get/${item.fileId}?fileName=${item.fileName}&BearerToken=${token}`}
                >
                  <img
                    alt={item.fileName}
                    src={`${Types.FILE_URL}/api/filechat/get/${item.fileId}?w=250&fileName=${item.fileName}&BearerToken=${token}`}
                    target="_blank"
                  />
                </a>
              </div>
            )
          })}
      </div>
    )
  }
}

FileDinhKem.defaultProps = {
  files: [],
}

FileDinhKem.propTypes = {
  files: PropTypes.array.isRequired,
}
