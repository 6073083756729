import PopupModal from 'components/common/PopupModal'
import React from 'react'
import * as Types from 'constants/Api'

const ModalDanhSachNguoiThamGia = ({
  isOpen,
  onClose,
  title = 'Danh sách người mời họp',
  data,
}) => {
  const totalNguoiThamGia =
    data?.filter(item => item.thamGia === 1)?.length || 0
  const totalKhongThamGia =
    data?.filter(item => item.thamGia === 2)?.length || 0
  const totalChuaXacNhan = data?.filter(item => item.thamGia === 0)?.length || 0
  return (
    <PopupModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      contentClass="popup-list-participants"
    >
      <div className="wrap-content">
        <p className="total-participants">
          {title}: {data?.length}
        </p>
        <div className="statistical">
          <div className="statistical-item">
            <span className="statistical-item__value blue">
              {totalNguoiThamGia}
            </span>
            <span className="statistical-item__title">Tham gia</span>
          </div>
          <div className="statistical-item">
            <span className="statistical-item__value red">
              {totalKhongThamGia}
            </span>
            <span className="statistical-item__title">Không tham gia</span>
          </div>
          <div className="statistical-item">
            <span className="statistical-item__value orange">
              {totalChuaXacNhan}
            </span>
            <span className="statistical-item__title">Chưa xác nhận</span>
          </div>
        </div>
        <div className="list-participants">
          {data?.map((item, index) => (
            <div key={index} className="item-participant">
              {item?.anhDaiDien ? (
                <div
                  className="selection-item-avatar"
                  style={{
                    backgroundImage: `url(${
                      item?.anhDaiDien
                        ? `${Types.IMAGE_URL}${item?.anhDaiDien}`
                        : '/images/default_avatar.png'
                    })`,
                  }}
                ></div>
              ) : (
                <div className="avatar-text">
                  {item?.tenNhanVien?.substring(0, 1)}
                </div>
              )}
              <div className="user-infor">
                <div className="wrap-name">
                  <span className="wrap-name__position">{item?.maChucVu}.</span>
                  <span className="wrap-name__name">{item?.tenNhanVien}</span>
                </div>
                <span className="user-infor__unit">
                  {item?.phongBan?.tenDonVi}
                </span>
                <span className="user-infor__department">
                  {item?.phongBan?.tenPhongBan}
                </span>
              </div>
              {item.thamGia === 1 && (
                <span className="pt-icon icon2-check"></span>
              )}
              {item.thamGia === 2 && <span className="pt-icon icon-Huy"></span>}
            </div>
          ))}
        </div>
      </div>
    </PopupModal>
  )
}

export default ModalDanhSachNguoiThamGia
