import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItem,
} from '../../components/Utilities/PrintSendDocument'
import { GridView, HeadingPage, Print } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import classnames from 'classnames'
import parser from 'ua-parser-js'
import moment from 'moment'
import FileDownload from 'file-saver'
import { Intent } from '@blueprintjs/core'

import Pagination from 'components/Pagination'

let check = new parser.UAParser().getBrowser().name

// let camino   = check.indexOf("Camino") > -1;
let explorer = check.indexOf('IE') > -1
let isFireFox = check.indexOf('Firefox') > -1
let isChrome = check.indexOf('Chrome') > -1
let isChromium = check.indexOf('Chromium') > -1
let isSafari = check.indexOf('Safari') > -1
let opera = check.toLowerCase().indexOf('op') > -1

if (isChrome && isSafari) isSafari = false
if (isChrome && opera) isChrome = false

class PrintSendDocumentPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {
        ngayCongVan: {
          from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
      },
      checkList: {},
      sort: undefined,
      isEmpty: false,
      isLoading: false,
      checkAll: false,
      isPrint: false,
      page: 1,
      isError: false,
      exporting: false,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.beforePrint = this.beforePrint.bind(this)
    this.afterPrint = this.afterPrint.bind(this)
    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    departments: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.clickPagination(1)
    this.props.actions.commonFetchDepartments()
    this.props.actions.commonFetchObjects()
  }

  clickPagination(page = 1) {
    this.submitFilter({}, page, this.state.sort)
    this.setState({ page })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    this.setState({ filter, page, isLoading: true, sort }, () => {
      this.props.actions
        .utilityPrintSendDocumentGet({ filter }, page, sort)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  handleCheckAll(e) {
    let checkList = {}

    if (this.props.sendDocuments.list.items) {
      this.props.sendDocuments.list.items.map(item => {
        return (checkList[item.id] = e)
      })
    }

    this.setState({
      checkList,
      checkAll: e,
    })
  }

  handleCheck(id, e) {
    let state = {
      checkList: {
        ...this.state.checkList,
        [id]: e,
      },
    }

    if (!e) {
      state.checkAll = false
    }

    this.setState(state)
  }

  beforePrint() {
    this.setState({ isPrint: true })
  }

  afterPrint() {
    this.setState({ isPrint: false })
  }

  handlePrint() {
    const _this = this
    const filter = this.state.filter
    const sort = this.state.sort

    this.props.actions
      .printUtilityPrintSendDocumentGet(
        { filter },
        Types.PAGE,
        sort,
        Types.LIMIT_ZERO
      )
      .then(res => {
        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print')
          mediaQueryList.addListener(function (mql) {
            if (mql.matches) {
              _this.beforePrint()
            } else {
              _this.afterPrint()
            }
          })
        }
        window.onbeforeprint = _this.beforePrint
        window.onafterprint = _this.afterPrint
        if (isChrome) {
          this.setState({ isPrint: true })
        }
        window.print()
      })
  }

  handleExport() {
    const { filter, sort } = this.state

    this.setState({ exporting: true })

    this.props.actions
      .exportSendDocument(filter, sort)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: 'Không xuất được file.',
            intent: Intent.DANGER,
          })
        }

        FileDownload.saveAs(res.payload.data, 'so-cong-van-di.xls')
      })
      .then(() => {
        this.setState({ exporting: false })
      })
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          {/* Heading */}
          <HeadingPage
            namePage="In sổ công văn đi"
            iconPage="icon-In_So_Cong_Van"
          >
            <div className="button-heading-right pull-right">
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất Excel"
                onClick={this.handleExport}
              />
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="In sổ"
                onClick={this.handlePrint}
              />
            </div>
          </HeadingPage>
          {/* Table List */}
          <div className="page-list-container page-printReceiveDocument">
            <ListFilter
              signNumberDepartmentLeaders={
                this.props.signNumberDepartmentLeaders
              }
              departments={this.props.departments}
              objects={this.props.objects}
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
            />

            <div className="table-container">
              <GridView
                classNames={'pt-table'}
                page={this.state.page}
                headers={headers.printSendDocument}
                itemShape={ListItem}
                data={this.props.sendDocuments.list.items}
                onFilter={this.handleSort}
                onCheckAll={this.handleCheckAll}
                onCheck={this.handleCheck}
                checkList={this.state.checkList}
                isEnabledAll={this.state.checkAll}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                isError={this.state.isError}
              />

              {!this.state.isLoading &&
                !this.state.isError &&
                !this.state.isError && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props?.sendDocuments?.list?.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
        <div
          className="container-printing"
          style={{ height: this.state.isPrint ? 637 : 0 }}
        ></div>
        <div
          className={classnames({
            'section-to-print': this.state.isPrint,
            'section-to-not-print': !this.state.isPrint,
          })}
        >
          <div
            className={classnames({
              'print-chrome-safari': isChrome || isSafari || isChromium,
              'print-fireFox': isFireFox,
              'print-ie': explorer,
            })}
          >
            <Print
              sendDocument={{
                data: this.props.printSendDocument.items,
                title: 'SỔ CÔNG VĂN ĐI',
                header: [
                  'Ngày',
                  'Số, ký hiệu CV',
                  'Trích yếu',
                  'Nơi nhận',
                  'Người ký',
                ],
              }}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  sendDocuments: {
    ...state.sendDocuments,
    list: {
      ...state.sendDocuments.list,
      items: state.sendDocuments.list.items.map(id => {
        state.entities.sendDocuments[id].id =
          state.entities.sendDocuments[id].congVanDiId

        return state.entities.sendDocuments[id]
      }),
    },
  },

  printSendDocument: {
    ...state.printSendDocument,
    items: state.common.printSendDocument,
  },

  departments: state.common.departments,

  objects: state.common.objects,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintSendDocumentPage)
