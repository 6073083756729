import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import requestAction from 'helpers/request'
import { Dialog, Card } from 'components/newCommon'
import {
  TOATS_MESSAGE_DELETE_FAIL,
  TOATS_MESSAGE_DELETE_SUCCESS,
} from 'constants/MessageForm'
import { Intent } from '@blueprintjs/core'

const Delete = ({ actions, dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const onDeleteTask = useCallback(() => {
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      beforeAction: () => {
        setLoading(true)
      },
      action: () => actions.deleteNotification(id),
      afterResponse: response => {
        if (!response?.result) {
          actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
          return onSuccess()
        }

        actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        onClose()
        return onSuccess()
      },
      afterAction: () => {
        setLoading(false)
        setShowDialog(false)
      },
    })
  }, [actions, id, onClose, onSuccess])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText="Xóa"
      loading={loading}
      onCancel={onClose}
      onConfirm={onDeleteTask}
      onClose={onClose}
      title={'Xóa thông báo'}
      textCenter
    >
      <Card className="ph10">{'Anh/chị có muốn thực hiện thao tác này?'}</Card>
    </Dialog>
  )
}

Delete.defaultProps = {
  actions: null,
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

Delete.propTypes = {
  actions: PropTypes.object,
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(Delete)
