import React, { memo, useContext } from 'react'
import { Button, Tooltip } from '@blueprintjs/core'
import {
  ResetButton,
  SearchInput,
  Select,
  SelectSearch,
} from 'components/newCommon'
import { DatePicker, Input } from 'components/newCommon2'
import { useState } from 'react'
import moment from 'moment/moment'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { CongVanContext } from './TimKiemCongVan'

const minDate = moment(`01/04/${moment().year()}`, 'DD/MM/YYYY')

const optionLoaiTimKiem = [
  {
    label: 'Văn bản đến',
    value: 'vbde',
  },
  {
    label: 'Văn bản đi',
    value: 'vbdi',
  },
  {
    label: 'Văn bản nội bộ',
    value: 'vbnb',
  },
]

const TimKiemCongViecFilter = ({ tokenDO, ...props }) => {
  const [openFilter] = useState(false)
  const [receiveOrganization] = useState([])

  const filterKeys = {
    MainKeyWord: 'MainKeyWord',
    IDPhongBanChuTri: 'IDPhongBanChuTri',
    ID_LOAI_VB: 'ID_LOAI_VB',
    ID_SO_VB: 'ID_SO_VB',
    LoaiVB: 'LoaiVB',
    NAM: 'NAM',
    KY_HIEU: 'KY_HIEU',
    IDChuyenVienXL: 'IDChuyenVienXL',
    IDLanhDaoChiDao: 'IDLanhDaoChiDao',
    NoiNhan: 'NoiNhan',
    DateFrom: 'DateFrom',
    DateTo: 'DateTo',
    SO_DEN: 'SO_DEN',
    NoiGui: 'NoiGui',
    NguoiKyVB: 'NguoiKyVB',
    IDPhongBanBanHanh: 'IDPhongBanBanHanh',
  }

  const {
    listCongVan,
    listPhongBan,
    listSoVanBan,
    listPhongBanXuLy,
    listLanhDao,
    listChuyenVienXuLy,
  } = props

  const {
    changeFilter = () => {},
    filter: {
      [filterKeys.LoaiVB]: LoaiVB = 'all',
      [filterKeys.MainKeyWord]: MainKeyWord = '',
      [filterKeys.IDLanhDaoChiDao]: IDLanhDaoChiDao = '0',
      [filterKeys.IDPhongBanChuTri]: IDPhongBanChuTri = 0,
      [filterKeys.NguoiKyVB]: NguoiKyVB = '',
      [filterKeys.NoiNhan]: NoiNhan = [],
      [filterKeys.ID_SO_VB]: ID_SO_VB = 0,
      [filterKeys.ID_LOAI_VB]: ID_LOAI_VB = 0,
      [filterKeys.NoiGui]: NoiGui = '',
      [filterKeys.IDPhongBanBanHanh]: IDPhongBanBanHanh = 0,
      [filterKeys.IDChuyenVienXL]: IDChuyenVienXL = 0,
      [filterKeys.DateFrom]: DateFrom = undefined,
      [filterKeys.DateTo]: DateTo = undefined,
    },
  } = useContext(CongVanContext)

  return (
    <div className="layout-filter">
      <div className="flex-basis-20 layout-filter-col min-width-280 max-width-280">
        <div className="d-flex cpc-input-v2-filter">
          <DatePicker
            clearable={false}
            placeholder="dd/mm/yyyy"
            valueDisplay={DateFrom ? DateFrom : null}
            minimumDate={minDate}
            maximumDate={DateTo ? moment(DateTo) : null}
            onChange={value => {
              const result = value ? moment(value).utc().format() : undefined
              changeFilter({ name: filterKeys.DateFrom, reFetch: false })(
                result
              )
            }}
          />
          <DatePicker
            clearable={false}
            minimumDate={DateFrom ? moment(DateFrom) : minDate}
            placeholder="dd/mm/yyyy"
            valueDisplay={DateTo ? DateTo : null}
            onChange={value => {
              const result = value ? moment(value).utc().format() : undefined
              changeFilter({ name: filterKeys.DateTo, reFetch: false })(result)
            }}
          />
        </div>
      </div>
      <div className="flex-basis-20 layout-filter-col width-180 max-width-180">
        <div className="cpc-input-v2-filter">
          <Select
            value={LoaiVB}
            clearable={false}
            placeholder="Loại tìm kiếm"
            dataSource={[
              { label: 'Tất cả loại văn bản', value: 'all' },
              ...optionLoaiTimKiem,
            ]}
            dropdownClassName="dropdown-300"
            onChange={changeFilter({
              name: filterKeys.LoaiVB,
              reFetch: false,
            })}
          />
        </div>
      </div>
      <div className="layout-filter-col">
        <div className="cpc-input-v2-filter-button">
          <SearchInput
            placeholder="Nhập trích yếu / số ký hiệu"
            leftIcon="icon2-search"
            onChange={changeFilter({
              name: filterKeys.MainKeyWord,
              reFetch: false,
            })}
            value={MainKeyWord}
            onSearch={changeFilter({ name: filterKeys.MainKeyWord })}
          />
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton
              className="ml10"
              onClick={changeFilter({ name: 'RESET' })}
            />
          </Tooltip>
          <Tooltip content={<span className="font-size-12">Tìm kiếm</span>}>
            <Button className="cpc-button-filter-clear pt-intent-btn-white ml10 mr5">
              <i className="icon2-filter icon-Loading mr0" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className={`box-filter mt15 ${openFilter ? 'open' : 'close'}`}>
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Lãnh đạo chỉ đạo</div>
            <Select
              placeholder="Chọn"
              clearable={false}
              dataSource={[
                { label: 'Tất cả', value: '0' },
                ...(listLanhDao?.map?.(e => ({
                  label: e?.tENPB,
                  value: e?.iDPB,
                })) || []),
              ]}
              value={IDLanhDaoChiDao}
              dropdownClassName="dropdown-300"
              onChange={changeFilter({
                name: filterKeys.IDLanhDaoChiDao,
                reFetch: false,
              })}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Ban phòng ban hành</div>
            <Select
              placeholder="Chọn"
              clearable={false}
              dataSource={[
                { label: 'Tất cả', value: 0 },
                ...(listPhongBan?.map?.(e => ({
                  label: e?.tENPB,
                  value: e?.iDPB,
                })) || []),
              ]}
              value={IDPhongBanBanHanh}
              dropdownClassName="dropdown-300"
              onChange={changeFilter({
                name: filterKeys.IDPhongBanBanHanh,
                reFetch: false,
              })}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <Input
              label="Người ký ban hành"
              isDOInputNumber
              disableHeightLabel
              labelClassname="cpc-input-label"
              wrapperClassName="cpc-input-filter"
              placeholder="Nhập"
              value={NguoiKyVB}
              allowEmptyEnter={false}
              onEnter={changeFilter({
                name: filterKeys.NguoiKyVB,
                reFetch: true,
              })}
              onChange={changeFilter({
                name: filterKeys.NguoiKyVB,
                reFetch: false,
              })}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Đơn vị nhận</div>
            <SelectSearch
              isMutiple={false}
              dataSource={[
                { label: 'Tất cả', value: 'ALL' },
                ...receiveOrganization,
              ]}
              onClear={changeFilter({
                name: filterKeys.NoiNhan,
                reFetch: false,
              })}
              onChange={changeFilter({
                name: filterKeys.NoiNhan,
                reFetch: false,
              })}
              onClose={changeFilter({
                name: filterKeys.NoiNhan,
                reFetch: false,
              })}
              placeholder="Chọn"
              selectedAllText="Tất cả"
              value={NoiNhan}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Sổ văn bản</div>
            <Select
              dropdownClassName="dropdown-300"
              clearable={false}
              placeholder="Chọn"
              value={ID_SO_VB}
              dataSource={[
                { label: 'Tất cả', value: 0 },
                ...(listSoVanBan?.map?.(e => ({
                  label: e?.tENSOVB,
                  value: e?.iDSOVB,
                })) || []),
              ]}
              onChange={changeFilter({
                name: filterKeys.ID_SO_VB,
                reFetch: false,
              })}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Loại văn bản</div>
            <Select
              placeholder="Chọn"
              clearable={false}
              value={ID_LOAI_VB}
              dataSource={[
                { label: 'Tất cả', value: 0 },
                ...(listCongVan?.map?.(e => ({
                  label: e?.tENLOAIVB,
                  value: e?.iDLOAIVB,
                })) || []),
              ]}
              onChange={changeFilter({
                name: filterKeys.ID_LOAI_VB,
                reFetch: false,
              })}
              dropdownClassName="dropdown-300"
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <Input
              label="Đơn vị ban hành"
              isDOInputNumber
              value={NoiGui}
              disableHeightLabel
              labelClassname="cpc-input-label"
              wrapperClassName="cpc-input-filter"
              placeholder="Nhập"
              allowEmptyEnter={false}
              onEnter={changeFilter({
                name: filterKeys.NoiGui,
                reFetch: true,
              })}
              onChange={changeFilter({
                name: filterKeys.NoiGui,
                reFetch: false,
              })}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Phòng ban xử lý</div>
            <Select
              dropdownClassName="dropdown-300"
              clearable={false}
              placeholder="Chọn"
              value={IDPhongBanChuTri}
              dataSource={[
                { label: 'Tất cả', value: 0 },
                ...(listPhongBanXuLy?.map?.(e => ({
                  label: e?.tENPB,
                  value: e?.iDPB,
                })) || []),
              ]}
              onChange={changeFilter({
                name: filterKeys.IDPhongBanChuTri,
                reFetch: false,
              })}
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 mb20">
            <div className="cpc-input-label">Chuyên viên xử lý</div>
            <Select
              dropdownClassName="dropdown-300"
              clearable={false}
              placeholder="Chọn"
              value={IDChuyenVienXL}
              dataSource={[
                { label: 'Tất cả', value: 0 },
                ...(listChuyenVienXuLy?.map?.(e => ({
                  label: e?.nAME,
                  value: e?.iDNV,
                })) || []),
              ]}
              onChange={changeFilter({
                name: filterKeys.IDChuyenVienXL,
                reFetch: false,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  tokenDO: state?.doffice?.toKenDO,
  listCongVan: state?.doffice?.listCongVan,
  listPhongBan: state?.doffice?.listPhongBan,
  listSoVanBan: state?.doffice?.listSoVanBan,
  listPhongBanXuLy: state?.doffice?.listPhongBanXuLy,
  listLanhDao: state?.doffice?.listLanhDao,
  listChuyenVienXuLy: state?.doffice?.listChuyenVienXuLy,
})

export default withCookies(
  connect(mapStateToProps)(memo(TimKiemCongViecFilter))
)
