import React from 'react'
import classnames from 'classnames'

import { MESSAGE_NOT_FOUND } from 'constants/MessageForm'
import { Card } from '../index'
const publicPath = process.env.PUBLIC_URL
const imageDefault = `${publicPath}/images/empty-default.png`
const imageChartEmpty = `${publicPath}/images/chart-empty.svg`

const SIZE = {
  NHO: 'SMALL',
  BINH_THUONG: 'NOMARL',
  TO: 'BIG',
}

const Empty = ({
  className,
  size = SIZE.BINH_THUONG,
  type = 'DEFAULT',
  message = MESSAGE_NOT_FOUND,
  image = imageDefault,
}) => {
  const renderComponent = () => {
    switch (type) {
      case 'ROUND':
        return (
          <div className={classnames('card-empty__round', className)}>
            {message && (
              <p className="font-size-13 font-roboto card-empty-small__text">
                {message}
              </p>
            )}
          </div>
        )
      case 'ROUND_CHART':
        return <img src={imageChartEmpty} alt="empty" />
      default:
        return (
          <div className={classnames(className)}>
            {image && <img src={image} alt="empty" />}
            {message && (
              <p className="font-size-13 font-roboto card-empty-small__text">
                {message}
              </p>
            )}
          </div>
        )
    }
  }

  return (
    <Card
      mainClassName={classnames('card-empty text-center', {
        'card-empty-small': size === SIZE.NHO,
      })}
    >
      {renderComponent()}
    </Card>
  )
}

export default Empty
