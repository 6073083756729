import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import ListFilter from './ListFilter'
import DanhSachLoaiVatTu from './DanhSachLoaiVatTu'
import ListFooter from './ListFooter'
import * as Types from 'constants/Api'

const LINK = '/lich-hop/danh-muc/loai-vat-tu/them-moi'

class DanhSachLoaiVatTuPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dsLoaiVatTu: [],
      isLoading: true,
      isError: false,
      textSearch: '',
      nhomVatTu: '',
      page: props.dsLoaiVatTu.page || 1,
    }

    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
  }

  componentDidMount() {
    this.props.actions.getDanhSachNhomVatTu({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
    this.props.actions.getDanhSachLoaiVatTu().then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dsLoaiVatTu.items !== this.props.dsLoaiVatTu.items) {
      this.setState(
        {
          dsLoaiVatTu: nextProps.dsLoaiVatTu.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }
  }

  removeTextSearch(name) {
    this.setState({
      [name]: '',
    })
  }

  submitFilter(textSearch, nhomVatTu, page) {
    this.setState({ textSearch, isLoading: true }, () => {
      const filter = {
        textSearch: textSearch?.trim() || undefined,
        nhomVatTuId: nhomVatTu || undefined,
        page: page || 1,
        limit: Types.PAGINATION_LIMIT,
      }
      // tạm thời để refesh bằng false
      this.props.actions
        .getDanhSachLoaiVatTu(filter)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshPage(false)
        })
    })
  }

  clickPagination(page) {
    this.setState({ page: page, isLoading: true }, () => {
      const filter = {
        textSearch: undefined,
        nhomVatTuId: undefined,
        page: page || 1,
        limit: Types.PAGINATION_LIMIT,
      }
      // tạm thời để refesh bằng false
      this.props.actions
        .getDanhSachLoaiVatTu(filter)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshPage(false)
        })
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarCalendar">
        <HeadingPage
          namePage="Danh mục loại vật tư"
          iconPage="icon-suplier-type"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <ListFilter
          textSearch={this.state.textSearch}
          nhomVatTu={this.state.nhomVatTu}
          onSubmit={this.submitFilter}
          actions={this.props.actions}
          readOnly={false}
          removeTextSearch={this.removeTextSearch}
          dsNhomVatTuCombobox={this.props.dsNhomVatTuCombobox}
        />
        <div className="listrole-container">
          <DanhSachLoaiVatTu
            items={this.state.dsLoaiVatTu}
            actions={this.props.actions}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
            page={this.state.page}
            textSearch={this.state.textSearch}
            nhomVatTuId={this.state.nhomVatTu}
          />
          {!this.state?.isLoading &&
            !this.props?.isEmpty &&
            !this.props?.isError && (
              <ListFooter
                current={this?.state?.page}
                total={Math.ceil(
                  this?.props?.dsLoaiVatTu?.total / Types.PAGINATION_LIMIT
                )}
                onChange={this.clickPagination}
              />
            )}
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  dsNhomVatTuCombobox: state?.lichHop?.dsNhomVatTu?.map(item => ({
    value: item?.vatTuId,
    name: item?.ten,
  })),
  dsLoaiVatTu: {
    ...state.dsLoaiVatTu,
    items: state.lichHop.dsLoaiVatTu?.items,
    total: state.lichHop.dsLoaiVatTu?.total,
    page: state.lichHop.dsLoaiVatTu?.page,
  },
  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DanhSachLoaiVatTuPage)
