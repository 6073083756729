import React, { PureComponent } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'
import * as actions from '../../../actions'
import moment from 'moment'
import FileDownload from 'file-saver'
import { Utils } from '../../../helpers'
import { MasterLayout } from '../../../components/layout'
import { GridView, HeadingPage } from '../../../components/common'
import { headers } from '../../../constants/Table'
import { DA_XAY_RA_LOI } from '../../../constants/MessageForm'

import Pagination from 'components/Pagination'

export class JobProjectUnsavedDocument extends PureComponent {
  constructor() {
    super()
    this.state = {
      loading: true,
      empty: false,
      error: false,
      data: [],
      exporting: false,
      page: {
        current: 1,
        total: 1,
      },
      sort: null,
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  renderRow =
    () =>
    ({ item, number: index }) => {
      return (
        <tr>
          <td className="cursor-default">{index < 10 ? `0${index}` : index}</td>
          <td className="cursor-default">{item.soCongVan}</td>
          <td className="cursor-default">
            {item.ngayCongVan && moment(item.ngayCongVan).format('DD/MM/YYYY')}
          </td>
          <td className="cursor-default">
            <span className="hover-over-time">{item.trichYeu}</span>
          </td>
          <td className="cursor-default">{item.coQuanBanHanh}</td>
          <td className="cursor-default">
            {get(item, 'nguoiChuTri.tenNhanVien')}
          </td>
        </tr>
      )
    }

  fetchData = async () => {
    const { location, actions } = this.props
    const { state } = location
    const { sort, page } = this.state
    try {
      this.setState({ loading: true })
      Utils.preData('thongKeDanhSachCongVanChuaLuu', state)
      const response = await actions.getDanhSachCongVanChuaLuu({
        ...state,
        sort,
        page: page.current,
      })

      if (response.error || get(response, 'payload.status') !== 200) {
        return this.setState({
          error: true,
          loading: false,
        })
      }
      this.setState({
        data: get(response, 'payload.data.result.items'),
        empty: !get(response, 'payload.data.result.total'),
        page: {
          current: get(response, 'payload.data.result.page'),
          total: Math.ceil(get(response, 'payload.data.result.total') / 10),
        },
        loading: false,
      })
    } catch (err) {
      this.setState({
        error: true,
        loading: false,
      })
    }
  }

  exportData = async () => {
    const { location, actions } = this.props
    const { state } = location
    const { sort, page } = this.state
    const { xuatExcelDanhSachCongVanChuaLuu, commonAddToasterMessage } = actions
    this.setState({ exporting: true })
    try {
      const response = await xuatExcelDanhSachCongVanChuaLuu({
        ...state,
        sort,
        page: page.current,
      })

      if (response.error) {
        return commonAddToasterMessage({
          message: 'Không xuất được file.',
          intent: Intent.DANGER,
        })
      }

      let preData = Utils.getData('thongKeDanhSachCongVanChuaLuu')
      FileDownload.saveAs(
        response.payload.data,
        `Thong-Ke-Cong-Van-Chua-Luu-${moment(preData.start_date).format(
          'L'
        )}-${moment(preData.end_date).format('L')}.xls`
      )
    } catch (error) {
      return commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        exporting: false,
      })
    }
  }

  pageChange = async page => {
    await this.setState(prev => ({ page: { ...prev.page, current: page } }))
    this.fetchData()
  }

  sort = async sort => {
    await this.setState({ sort })
    this.fetchData()
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const { loading, empty, error, data, exporting, page } = this.state

    return (
      <MasterLayout typeSidebar="utilities">
        <div className="page-utilities-container">
          <HeadingPage
            namePage="Tổng hợp công văn chưa lưu"
            iconPage="icon-Tong_Hop"
          >
            <div className="pull-right ml5 mt5">
              <Button
                className="pt-button pt-button-back"
                onClick={this.goBack}
                disabled={loading}
              >
                <span className="pt-icon pt-icon-chevron-left" />
                <span className="text-content">Quay lại</span>
              </Button>
            </div>
            <div className="pull-right">
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={() => this.exportData()}
                disabled={loading || empty}
                loading={exporting}
              />
            </div>
          </HeadingPage>
          <div className="page-list-container page-list-GeneralDocumentary">
            <div className="table-container list-GeneralDocumentary pb0">
              <GridView
                onFilter={this.sort}
                classNames="pt-table"
                isLoading={loading}
                isEmpty={empty}
                isError={error}
                headers={headers.congVanChuaLuu}
                page={page.current}
                data={data}
                itemShape={this.renderRow()}
              />
              {!loading && !error && !empty && (
                <Pagination
                  current={page.current}
                  total={page.total}
                  onChange={this.pageChange}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobProjectUnsavedDocument)
