import React, { Component } from 'react'

class NotFoundFileNoiDung extends Component {
  render() {
    return (
      <div className="not-found-content" style={{ height: '1000px' }}>
        <div className="thumb-icon-content">
          <span className="icon icon-folder"></span>
        </div>
        <p className="description">
          {this.props.message
            ? this.props.message
            : 'Phiếu lấy ý kiến này không có file nội dung'}
        </p>
      </div>
    )
  }
}

export default NotFoundFileNoiDung
