import React, { useEffect, useCallback } from 'react'
import { MasterLayout } from 'components/layout'
import { connect } from 'react-redux'
import './style.scss'
import { FE_CHAT_URL } from 'constants/Api'
// const FE_CHAT_URL = 'http://localhost:3000'

const ChatModule = props => {
  const history = props?.history
  const search = props?.location?.search
  const trimmedQueryString = search.substring(1)
  const { auth } = props
  const url = `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`

  const sendMessageToIframe = useCallback(() => {
    const iframe = document.getElementById('js-chat-iframe')
    if (iframe) {
      window.addEventListener(
        'message',
        res => {
          if (
            res?.data?.message === '/login' &&
            !res?.data?.auth?.token?.accessToken
          ) {
            history.push('/dang-nhap')
          }
        },
        false
      )
    }
  }, [history])

  useEffect(() => {
    sendMessageToIframe()
    return () => {
      window.removeEventListener('message', sendMessageToIframe, false)
    }
  }, [sendMessageToIframe])

  return (
    <MasterLayout typeSidebar="Chat">
      <div className="chat-wrap">
        <iframe
          id="js-chat-iframe"
          allow="geolocation"
          title="ok"
          src={
            search
              ? `${FE_CHAT_URL}${url}&${trimmedQueryString}&isEmbed=${true}`
              : `${FE_CHAT_URL}${url}&isEmbed=${true}`
          }
          className="chat-module"
        />
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps, null)(ChatModule)
