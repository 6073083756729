import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { LoadingItem } from '../common/'
import * as Types from '../../constants/Api'
import { Utils } from '../../helpers'
import { get, isPlainObject } from 'lodash'
import csx from 'classnames'

class FileList extends Component {
  render() {
    const {
      auth,
      isLoadingData = {},
      maxHeightScroll,
      className,
      receiveDocument,
      field,
    } = this.props
    const token = get(auth, 'token.accessToken', '')
    if (Object.values(isLoadingData).includes(true)) {
      return <LoadingItem />
    }

    let files = get(receiveDocument, field, [])
    if (isPlainObject(files)) {
      files = [files]
    }

    return (
      <div className="content">
        <div className="detail-file">
          <Scrollbars
            autoHeight
            autoHeightMin={0}
            autoHeightMax={maxHeightScroll}
          >
            <div className={csx('detail', className)}>
              {files.map((file, index) => {
                let extension
                const path = `${`${Types.FILE_URL}/api/file/get/${file.fileId}`}?BearerToken=${token}`
                const fileName = get(file, 'fileName')

                if (fileName) {
                  extension = fileName.toLowerCase().split('.').slice(-1)[0]
                }

                return (
                  <div className="file-items" key={index}>
                    <a
                      style={{
                        backgroundImage: `url(${Utils.typeFile(extension)})`,
                      }}
                      className="link-file pt5 pb5"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={path}
                    >
                      {`${file.tenFile} - ${Utils.getFileSize(file.kichThuoc)}`}
                    </a>
                  </div>
                )
              })}
            </div>
          </Scrollbars>
        </div>
      </div>
    )
  }
}

FileList.defaultProps = {
  className: 'file-list-box-detail',
  field: 'fileDinhKem',
  maxHeightScroll: 200,
}

export default FileList
