import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { LoadingItem, DetailFileDinhKem } from '../common/'

class DetailBoxAction extends Component {
  static propTypes = {
    onClickSendAdditional: PropTypes.func,
    onClickExecutingView: PropTypes.func,
    onClickDetailInfo: PropTypes.func,
  }

  state = {
    showBoxAction: false,
  }

  showBoxAction(e) {
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  render() {
    return this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined ? (
      <LoadingItem />
    ) : (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        {this.props.listInternalDocuments &&
          this.props.listInternalDocuments.fileDinhKem &&
          this.props.listInternalDocuments.fileDinhKem.length > 0 && (
            <DetailFileDinhKem
              receiveDocument={{
                fileDinhKem: this.props.listInternalDocuments.fileDinhKem,
              }}
              auth={this.props.auth}
            />
          )}
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button
              className="click-item"
              onClick={this.showBoxAction.bind(this)}
            >
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row">
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickExecutingView}
              >
                <i className="icon-Xemthuchien icon-cpc"></i>
                <span className="action-name">Xem thực hiện</span>
              </button>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickDetailInfo}
              >
                <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                <span className="action-name">Thông tin công văn</span>
              </button>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickFlowdocumentary}
              >
                <i className="icon-organization icon-cpc"></i>
                <span className="action-name">Luồng công văn</span>
              </button>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickSendMail}
              >
                <i className="icon-Gmail icon-cpc"></i>
                <span className="action-name">Gửi email</span>
              </button>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button className="click-item" onClick={this.props.sendToChat}>
                <i className="icon-speech-bubble icon-cpc"></i>
                <span className="action-name">Gửi Chat</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DetailBoxAction
