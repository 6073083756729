import { GET_STATISTIC_USER } from '../constants/ActionTypes'

const initialState = {
  // Thong ke nguoi dung
  units: {
    list: [],
    total: 0,
  },
}

export default function statisticUsers(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTIC_USER:
      return {
        ...state,
        units: {
          list: action.payload.data || [],
          total: action.payload.data ? action.payload.data.length : 0,
        },
      }

    default:
      return state
  }
}
