import React, { memo, useCallback, useEffect, useState } from 'react'
import csx from 'classnames'
import { isArray, isFunction, size, some } from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars'

import styles from './styles.module.scss'

const Index = memo(
  ({
    visibibleSinglePage = false,
    position = 'center',
    current: currentPage,
    total: totalPage,
    onChange,
  }) => {
    const [current, setCurrent] = useState(1)
    const [total, setTotal] = useState(1)
    const [groupVisible, setGroupVisible] = useState(null)

    const itemClick = useCallback(
      page => {
        setCurrent(page)

        if (isFunction(onChange)) {
          onChange(page)
        }
      },
      [onChange]
    )

    const renderGroup = useCallback(
      array => {
        return (array || []).map((val, index) =>
          isArray(val) ? (
            <div key={`group-${index}`} className={styles.paginationSelect}>
              <div
                className={csx(styles.paginationItem, styles.paginationGroup)}
                onClick={() => setGroupVisible(`group-${index}`)}
              >
                ...
              </div>
              <div
                className={csx(styles.list, {
                  [styles.visible]: `group-${index}` === groupVisible,
                })}
              >
                <Scrollbars
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={150}
                  autoHide
                >
                  {val.map(item => (
                    <div
                      key={item}
                      className={styles.item}
                      onClick={() => itemClick(item)}
                    >
                      {item}
                    </div>
                  ))}
                </Scrollbars>
              </div>
            </div>
          ) : (
            <div
              key={val}
              className={csx(styles.paginationItem, {
                [styles.active]: val === current,
              })}
              onClick={() => itemClick(val)}
            >
              {val}
            </div>
          )
        )
      },
      [itemClick, current, groupVisible]
    )

    const closeGroupSelect = useCallback(e => {
      if (
        some(document.querySelectorAll(`.${styles.paginationGroup}`), item =>
          [e?.target?.childNodes?.[0]?.parentNode].includes(item)
        )
      ) {
        return
      }

      setGroupVisible(null)
    }, [])

    const renderPage = useCallback(() => {
      let leftGroup = []
      let rightGroup = []

      const pageGroups = new Array(total)
        .fill(null)
        .map((v, i) => i + 1)
        .reduce((res, v) => {
          if (v > 1 && v < current - 1) {
            leftGroup = [...leftGroup, v]
            return res
          }
          if (v === current - 1 && size(leftGroup) > 0) {
            return [...res, leftGroup, v]
          }
          if (v < total && v > current + 1) {
            rightGroup = [...rightGroup, v]
            return res
          }
          if (v === total && size(rightGroup) > 0) {
            return [...res, rightGroup, v]
          }

          return [...res, v]
        }, [])

      return (
        <>
          <div
            className={csx(styles.paginationItem, {
              [styles.disabled]: current === 1,
            })}
            onClick={() => current !== 1 && itemClick(current - 1)}
          >
            <span className={csx('icon2-arrow-dart-left', styles.icon)} />
          </div>

          {renderGroup(pageGroups)}

          <div
            className={csx(styles.paginationItem, {
              [styles.disabled]: current === total,
            })}
            onClick={() => current !== total && itemClick(current + 1)}
          >
            <span className={csx('icon2-arrow-dart-right', styles.icon)} />
          </div>
        </>
      )
    }, [renderGroup, itemClick, total, current])

    useEffect(() => {
      if (currentPage > 0) {
        setCurrent(currentPage)
      }
    }, [currentPage])

    useEffect(() => {
      if (totalPage > 0) {
        setTotal(totalPage)
      }
    }, [totalPage])

    useEffect(() => {
      document.addEventListener('click', closeGroupSelect)
      return () => {
        document.removeEventListener('click', closeGroupSelect)
      }
    }, [closeGroupSelect])

    if (!visibibleSinglePage && total === 1) {
      return null
    }

    return (
      <div className={csx(styles.pagination, styles[position])}>
        <div className={styles.paginationList}>{renderPage()}</div>
      </div>
    )
  }
)

export default Index
