import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import { FILE_URL } from 'constants/Api'
import UIFile from 'components/ui/file'
import { Utils } from 'helpers'
import { removeLastBlankLine } from 'helpers/Helper'

const NotificationItem = ({ auth, dataSource, onClick }) => {
  const contentRef = useRef(null)
  const token = auth?.token?.accessToken
  const [isToogleHeight, setIsToogleHeight] = useState(false)
  const [hasViewMore, setHasViewMore] = useState(false)

  const onItemClick = useCallback(() => {
    onClick(dataSource)
  }, [dataSource, onClick])

  const onToogleHeight = useCallback(() => {
    setIsToogleHeight(prev => !prev)
  }, [])

  useEffect(() => {
    if (contentRef?.current) {
      if (contentRef.current?.offsetHeight > 90) {
        setHasViewMore(true)
      }
    }
  }, [contentRef])

  return (
    <div
      className={classNames('layout-notifications-content__item', {
        is_unread: !dataSource?.daDoc,
      })}
      onClick={e => {
        e.stopPropagation()
        !dataSource?.daDoc && onItemClick()
      }}
    >
      <h4 className="text-15-600-18">{dataSource?.tieuDe}</h4>
      <div
        ref={contentRef}
        className={classNames('layout-notifications-content__content mt5', {
          auto_height: isToogleHeight || !hasViewMore,
        })}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: removeLastBlankLine(dataSource?.noiDung),
          }}
        />
        {dataSource?.dsFileDinhKem?.map((item, index) => {
          const size = Utils.getFileSize(item?.size)
            ? ` - ${Utils.getFileSize(item?.size)}`
            : ''
          return (
            <UIFile
              key={index}
              className="mt5"
              name={`${item?.name}${size}`}
              extension={item?.extension}
              onClick={() =>
                window.open(
                  `${FILE_URL}/api/file/get/${item?.id}?BearerToken=${token}`
                )
              }
            />
          )
        })}
      </div>
      <p
        className="m0 layout-notifications-content__view-more cursor-pointer"
        onClick={e => {
          e.stopPropagation()
          onToogleHeight()
          !dataSource?.daDoc && onItemClick()
        }}
      >
        {hasViewMore && `${isToogleHeight ? 'Thu gọn' : 'Xem thêm'}`}
      </p>
      {dataSource?.ngayTao ? (
        <p className="text-12-300-17 opacity-05 mt5 d-flex align-items-center gap-5">
          <span className="icon-time"></span>
          <span>{moment(dataSource.ngayTao).format('DD/MM/YYYY HH:mm')}</span>
        </p>
      ) : (
        ''
      )}
    </div>
  )
}

NotificationItem.defaultProps = {
  dataSource: null,
  onClick: () => {},
}

NotificationItem.propTypes = {
  dataSource: PropTypes.object,
  onClick: PropTypes.func,
}

export default NotificationItem
