import moment from 'moment/moment'
import React from 'react'

const TimeLineItem = ({ itemData, type }) => {
  const typeTimeLine = 'NGUOI_TRINH'
  const ngayTao =
    type === typeTimeLine ? itemData?.nGAYTRINH : itemData?.nGAYDUYET
  return (
    <li className="timeline_item">
      <div className="d-flex justify-space-between mb10">
        <p className="phong_ban">{itemData?.tENPB || ''}</p>
        <p className="time">
          {ngayTao
            ? moment(ngayTao).subtract(7, 'hours').format('DD/MM/YYYY HH:mm')
            : ''}
        </p>
      </div>
      <div className="d-flex align-center">
        <span className="avatar-user" />
        <div className="ml10">
          <div className="name">
            {itemData?.fIRSTNAME || ''}
            {itemData?.tTPD === 'DA_DUYET' && (
              <div className="circle-border ml10">
                <span className="checkmark"></span>
              </div>
            )}
          </div>
          <p className="chuc_vu">
            {type === typeTimeLine ? 'Soạn thảo' : itemData?.tINHTRANG || ''}
          </p>
        </div>
      </div>
    </li>
  )
}

export default TimeLineItem
