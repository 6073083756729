import React, { memo, useContext } from 'react'
import { Icon, Checkbox } from '@blueprintjs/core'
import { size, uniq, isArray, get, some } from 'lodash'
import csx from 'classnames'

import { Card, Collapse, Avatar } from 'components/newCommon'
import { SelectContext } from '../UserTree'

const CheckboxItem = ({
  level = 0,
  avatar = null,
  showAvatar = false,
  label = '',
  value: itemValue,
  children,
  departmentShortname = null,
}) => {
  const {
    selected = [],
    onChange = () => {},
    showKeys = [],
    toggleShow = () => {},
    disabledList = [],
  } = useContext(SelectContext)
  const isShow = showKeys.includes(itemValue)

  // TODO: mới chỉ check 1 cấp, cần suy nghĩ đệ quy
  // change Checkbox
  const changeCheckbox = e => {
    const { checked } = e.target
    // level = 0 thì lấy tất cả giá trị con
    const values =
      !level && size(children)
        ? (children || []).map(child => child.value)
        : [itemValue]
    const newList = checked
      ? uniq([...selected, ...values])
      : selected.filter(v => !values.includes(v))
    onChange(newList, itemValue)
  }

  const checkActive = () => {
    const values =
      !level && size(children)
        ? (children || []).map(child => child.value)
        : [itemValue]
    return !!size(selected) && values.every(value => some(selected, value))
  }

  const content = (
    <>
      {showAvatar && <Avatar src={avatar} className="mr10" needBaseUrl />}
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
        }}
      >
        <span className={csx('font-size-13', 'text-color-333')}>{label}</span>
        {departmentShortname && (
          <span
            className={csx(
              'font-size-11',
              'text-color-333',
              'text-uppercase',
              'font-weight-600'
            )}
            style={{
              marginTop: 3,
            }}
          >
            {departmentShortname}
          </span>
        )}
      </div>
      {children && (
        <span className="action show">
          <Icon
            iconName={isShow ? 'chevron-up' : 'chevron-down'}
            className="m0"
            onClick={toggleShow(itemValue)}
          />
        </span>
      )}
    </>
  )

  return (
    <>
      <Card className="cpc-list-item" paddingHorizontal>
        <Checkbox
          className={csx(
            'cpc-checkbox',
            `ml${level * 20}`,
            'pt10 pb10',
            'font-size-13'
          )}
          checked={checkActive()}
          disabled={some(disabledList, itemValue)}
          onChange={changeCheckbox}
        >
          {content}
        </Checkbox>
      </Card>
      <Collapse isOpen={isShow} keepChildrenMounted>
        {isArray(children) &&
          children.map(item => (
            <CheckboxItem
              {...item}
              key={get(item, 'value.id')}
              level={level + 1}
            />
          ))}
      </Collapse>
    </>
  )
}

export default memo(CheckboxItem)
