// const vnChar = [
//   '[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐ',
//   'eEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlL',
//   'mMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsS',
//   'tTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ1234567890 ]+',
// ].join('');

const nameRegex = new RegExp(/@@\[(.*?)\]/, 'gi') // @@[text]
const mentionRegex = new RegExp(/(?=\]\()(.*?)(?=)@@/, 'gi') // ](text)@@

// chuyển chuỗi sang html
export const convertStringWithNameTag = (content = '') => {
  return content
    .replace(mentionRegex, ']') // replace ](text)@@ => ]
    .replace(nameRegex, match =>
      match
        .replace('@@[', '<span class="text-primary">@')
        .replace(/.$/, '</span>')
    )
    .replace(/\n/g, '<br />')
}

// hàm xóa dấu - dùng cho tìm kiếm
export const removeAccents = (str = '') => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

// Lấy chuỗi hợp lệ - không khoảng trắng, lowcase
export const getInvalidString = (str = '') => {
  return str.trim().toLocaleLowerCase()
}

// Tìm kiếm không dấu
export const checkSearchNoAccents = (src = '', target = '') => {
  return removeAccents(getInvalidString(src)).includes(
    removeAccents(getInvalidString(target))
  )
}

export const splitString = (str = '', size = 5) => {
  const checkRegex = new RegExp(`.{1,${size}}`, 'g')
  return str.match(checkRegex)
}

export const randomId = () => {
  return splitString(btoa(Math.random()).substring(0, 25)).join('-')
}

export default {
  convertStringWithNameTag,
  removeAccents,
  getInvalidString,
  checkSearchNoAccents,
  splitString,
  randomId,
}
