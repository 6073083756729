import React from 'react'
import { Checkbox, Radio } from '@blueprintjs/core'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'

class DepartmentPerform extends React.Component {
  constructor(props) {
    super(props)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleEnabledChangeCheckbox1 =
      this.handleEnabledChangeCheckbox1.bind(this)
    this.handleEnabledChangeCheckbox2 =
      this.handleEnabledChangeCheckbox2.bind(this)
    this.handleCheckAllCheckbox1 = this.handleCheckAllCheckbox1.bind(this)
    this.handleCheckAllCheckbox2 = this.handleCheckAllCheckbox2.bind(this)
  }

  static propTypes = {
    options: PropTypes.array,
    dsPhoiHop: PropTypes.array,
    dsXemDeBiet: PropTypes.array,
    chuTriId: PropTypes.string,
    disabledListCompositive: PropTypes.array,
    disabledListOnlySee: PropTypes.array,
    titles: PropTypes.array,
    isHasRadio: PropTypes.bool.isRequired,
    selectedCompositiveDepartments: PropTypes.array,
    selectedOnlySeeDepartments: PropTypes.array,
  }

  state = {
    valueRadioChecked: null,
    valueCheckbox1: [],
    valueCheckbox2: [],
    isCheckAllCheckBox1: false,
    isCheckAllCheckBox2: false,
    disabledOptionsCompositive: [],
    disabledOptionsOnlySee: [],
  }

  handleRadio = e => {
    const newSelection = e.target.id
    const valueCheckbox1 = this.state.valueCheckbox1.filter(
      s => s !== newSelection
    )
    const valueCheckbox2 = this.state.valueCheckbox2.filter(
      s => s !== newSelection
    )
    let valueRadioChecked = this.state.valueRadioChecked

    if (this.state.valueRadioChecked === e.target.id) {
      valueRadioChecked = null
    } else {
      valueRadioChecked = e.target.id
    }
    this.setState({
      valueRadioChecked,
      valueCheckbox1,
      valueCheckbox2,
    })
  }

  handleEnabledChangeCheckbox1 = e => {
    let valueCheckbox1
    let valueCheckbox2 = this.state.valueCheckbox2
    let valueRadioChecked =
      this.state.valueRadioChecked === e.target.id
        ? null
        : this.state.valueRadioChecked
    const newSelection = e.target.id
    if (
      this.state.valueCheckbox1.length > 0 &&
      this.state.valueCheckbox1.indexOf(newSelection) > -1
    ) {
      valueCheckbox1 = this.state.valueCheckbox1.filter(s => s !== newSelection)
    } else {
      valueCheckbox1 = [...this.state.valueCheckbox1, newSelection]
      valueCheckbox2 = this.state.valueCheckbox2.filter(s => s !== newSelection)
    }
    this.setState({
      valueCheckbox1,
      valueCheckbox2,
      valueRadioChecked,
    })
  }

  handleEnabledChangeCheckbox2 = e => {
    let valueCheckbox1 = this.state.valueCheckbox1
    let valueCheckbox2
    let valueRadioChecked =
      this.state.valueRadioChecked === e.target.id
        ? null
        : this.state.valueRadioChecked
    const newSelection = e.target.id
    if (
      this.state.valueCheckbox2.length > 0 &&
      this.state.valueCheckbox2.indexOf(newSelection) > -1
    ) {
      valueCheckbox2 = this.state.valueCheckbox2.filter(s => s !== newSelection)
    } else {
      valueCheckbox2 = [...this.state.valueCheckbox2, newSelection]
      valueCheckbox1 = this.state.valueCheckbox1.filter(s => s !== newSelection)
    }
    this.setState({
      valueCheckbox1,
      valueCheckbox2,
      valueRadioChecked,
    })
  }

  handleCheckAllCheckbox1 = () => {
    let valueCheckbox1 = [...this.state.valueCheckbox1]
    let isCheckAllCheckBox1 = !this.state.isCheckAllCheckBox1
    if (this.state.isCheckAllCheckBox1) {
      valueCheckbox1 = []
    } else {
      this.props.options.forEach(item => {
        if (
          this.state.valueCheckbox1.indexOf(item.value) === -1 &&
          this.state.disabledOptionsCompositive.indexOf(item.value) === -1 &&
          this.state.valueRadioChecked !== item.value
        ) {
          valueCheckbox1.push(item.value)
        }
      })
    }

    if (valueCheckbox1.length === 0) {
      isCheckAllCheckBox1 = false
    }

    this.setState({
      isCheckAllCheckBox1,
      valueCheckbox1,
      valueCheckbox2: [],
    })
  }

  handleCheckAllCheckbox2 = () => {
    let valueCheckbox2 = [...this.state.valueCheckbox2]
    let isCheckAllCheckBox2 = !this.state.isCheckAllCheckBox2
    if (this.state.isCheckAllCheckBox2) {
      valueCheckbox2 = []
    } else {
      this.props.options.forEach(item => {
        if (
          this.state.valueCheckbox2.indexOf(item.value) === -1 &&
          this.state.disabledOptionsOnlySee.indexOf(item.value) === -1 &&
          this.state.valueRadioChecked !== item.value &&
          this.state.valueCheckbox1.indexOf(item.value) === -1
        ) {
          valueCheckbox2.push(item.value)
        }
      })
    }

    if (valueCheckbox2.length === 0) {
      isCheckAllCheckBox2 = false
    }

    this.setState({
      valueCheckbox2,
      isCheckAllCheckBox2,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dsPhoiHop) {
      this.setState({ valueCheckbox1: nextProps.dsPhoiHop })
    }

    if (nextProps.dsXemDeBiet) {
      this.setState({ valueCheckbox2: nextProps.dsXemDeBiet })
    }

    if (nextProps.chuTriId !== undefined) {
      this.setState({ valueRadioChecked: nextProps.chuTriId })
    }

    if (
      nextProps.options &&
      nextProps.disabledListCompositive &&
      nextProps.disabledListCompositive !==
        this.state.disabledOptionsCompositive
    ) {
      let disabledOptionsCompositive = nextProps.disabledListCompositive.filter(
        s => nextProps.options.find(item => item.value === s) !== undefined
      )
      this.setState({ disabledOptionsCompositive })
    }
    if (
      nextProps.options &&
      nextProps.disabledListOnlySee &&
      nextProps.disabledListOnlySee !== this.state.disabledOptionsOnlySee
    ) {
      let disabledOptionsOnlySee = nextProps.disabledListOnlySee.filter(
        s => nextProps.options.find(item => item.value === s) !== undefined
      )
      this.setState({ disabledOptionsOnlySee })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.valueCheckbox1 !== this.state.valueCheckbox1) {
      let isCheckAllCheckBox1 = false
      let countRadioChecked = this.state.valueRadioChecked ? 1 : 0
      if (
        this.state.valueCheckbox1.length +
          countRadioChecked +
          this.state.disabledOptionsCompositive.length ===
          this.props.options.length &&
        this.props.options.length > 0 &&
        this.state.valueCheckbox1.length > 0
      ) {
        isCheckAllCheckBox1 = true
      }

      this.setState(
        { isCheckAllCheckBox1 },
        this.props.onSubmit &&
          this.props.onSubmit({
            dsPhoiHop: this.state.valueCheckbox1,
            dsXemDeBiet: this.state.valueCheckbox2,
            chuTriId: this.state.valueRadioChecked,
          })
      )
    }

    if (prevState.valueCheckbox2 !== this.state.valueCheckbox2) {
      let isCheckAllCheckBox2 = false
      let countRadioChecked = this.state.valueRadioChecked ? 1 : 0
      if (
        this.state.valueCheckbox2.length +
          countRadioChecked +
          this.state.disabledOptionsOnlySee.length ===
          this.props.options.length &&
        this.props.options.length > 0 &&
        this.state.valueCheckbox2.length > 0
      ) {
        isCheckAllCheckBox2 = true
      }

      this.setState(
        { isCheckAllCheckBox2 },
        this.props.onSubmit &&
          this.props.onSubmit({
            dsPhoiHop: this.state.valueCheckbox1,
            dsXemDeBiet: this.state.valueCheckbox2,
            chuTriId: this.state.valueRadioChecked,
          })
      )
    }

    if (
      prevState.valueRadioChecked !== this.state.valueRadioChecked &&
      this.state.valueRadioChecked
    ) {
      this.setState(
        { isCheckAllCheckBox1: false, isCheckAllCheckBox2: false },
        this.props.onSubmit &&
          this.props.onSubmit({
            dsPhoiHop: this.state.valueCheckbox1,
            dsXemDeBiet: this.state.valueCheckbox2,
            chuTriId: this.state.valueRadioChecked,
          })
      )
    }
  }

  render() {
    let type = 'staff'
    if (this.props.type === 'Phòng ban') {
      type = 'department'
    }

    return (
      <div
        className={classnames('department-perform-table', {
          'no-scroll-DepartmentPerform': this.props.classCustomStyle,
        })}
      >
        <table className="pt-table table-container">
          <thead className="heading-table">
            <tr>
              <th>{this.props.type ? this.props.type : 'Phòng ban'}</th>
              {this.props.isHasRadio && (
                <th className="head-table-checkall-content">
                  {this.props.titles && this.props.titles[0]
                    ? this.props.titles[0]
                    : 'CT'}
                </th>
              )}
              <th className="head-table-checkall-content">
                <Checkbox
                  className="checkall"
                  name="allCompositive"
                  checked={this.state.isCheckAllCheckBox1}
                  onChange={this.handleCheckAllCheckbox1}
                  disabled={
                    !this.props.options.length ||
                    this.props.options.length ===
                      this.state.disabledOptionsCompositive.length ||
                    this.props.isDisabledAll
                  }
                ></Checkbox>
                <span className="text-content">
                  {this.props.titles && this.props.titles[1]
                    ? this.props.titles[1]
                    : 'PH'}
                </span>
              </th>
              <th className="head-table-checkall-content">
                <Checkbox
                  className="checkall"
                  name={`allOnlySee-${type}`}
                  checked={this.state.isCheckAllCheckBox2}
                  onChange={this.handleCheckAllCheckbox2}
                  disabled={
                    !this.props.options.length ||
                    this.props.options.length ===
                      this.state.disabledOptionsOnlySee.length ||
                    this.props.isDisabledAll
                  }
                ></Checkbox>
                <span className="text-content">
                  {this.props.titles && this.props.titles[2]
                    ? this.props.titles[2]
                    : 'XĐB'}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.options.map((item, i) => (
              <tr key={i}>
                <td className="cell-content">{item.content}</td>
                {this.props.isHasRadio && (
                  <td className="cell-content">
                    <Radio
                      className="item-radio"
                      name={`radio-${type}`}
                      checked={
                        this.state.valueRadioChecked === item.value &&
                        this.state.disabledOptionsCompositive.indexOf(
                          item.value
                        ) === -1
                      }
                      onChange={this.handleRadio}
                      id={item.value}
                      disabled={
                        ((this.state.disabledOptionsCompositive.indexOf(
                          item.value
                        ) > -1 ||
                          this.props.isDisabledAll) &&
                          (!this.props.userRoleId || !this.props.isOutDate)) ||
                        ((this.state.disabledOptionsCompositive.indexOf(
                          item.value
                        ) > -1 ||
                          this.props.isDisabledAll) &&
                          this.props.userRoleId &&
                          this.props.isOutDate &&
                          item.value !== this.props.userRoleId)
                      }
                    />
                  </td>
                )}
                <td className="cell-content">
                  <Checkbox
                    className="item-checkbox disabled-checkbox"
                    onChange={this.handleEnabledChangeCheckbox1}
                    name={`checbox1-${type}`}
                    id={item.value}
                    checked={
                      (this.state.valueCheckbox1.indexOf(item.value) > -1 &&
                        this.state.disabledOptionsCompositive.indexOf(
                          item.value
                        ) === -1) ||
                      (this.props.selectedCompositiveDepartments &&
                        this.props.selectedCompositiveDepartments.indexOf(
                          item.value
                        ) > -1)
                    }
                    disabled={
                      this.state.disabledOptionsCompositive.indexOf(
                        item.value
                      ) > -1 || this.props.isDisabledAll
                    }
                  />
                </td>
                <td className="cell-content">
                  <Checkbox
                    className="item-checkbox disabled-checkbox"
                    onChange={this.handleEnabledChangeCheckbox2}
                    name={`checbox2-${type}`}
                    id={item.value}
                    checked={
                      (this.state.valueCheckbox2.indexOf(item.value) > -1 &&
                        this.state.disabledOptionsOnlySee.indexOf(
                          item.value
                        ) === -1) ||
                      (this.props.selectedOnlySeeDepartments &&
                        this.props.selectedOnlySeeDepartments.indexOf(
                          item.value
                        ) > -1)
                    }
                    disabled={
                      this.state.disabledOptionsOnlySee.indexOf(item.value) >
                        -1 || this.props.isDisabledAll
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default DepartmentPerform
