import React from 'react'
import * as Types from 'constants/Api'

const UserItem = ({ name, nhanVien, active }) => {
  return (
    <div className="item-user-meeting">
      {nhanVien?.anhDaiDien ? (
        <div
          className="selection-item-avatar"
          style={{
            backgroundImage: `url(${
              nhanVien?.anhDaiDien
                ? `${Types.IMAGE_URL}${nhanVien?.anhDaiDien}`
                : '/images/default_avatar.png'
            })`,
          }}
        >
          {active && (
            <img
              src={`${process.env.PUBLIC_URL}/images/check.svg`}
              alt="check"
              className="check-green"
            />
          )}
        </div>
      ) : (
        <div className="avatar-text">
          {name?.substring(0, 1)}
          {active && (
            <img
              src={`${process.env.PUBLIC_URL}/images/check.svg`}
              alt="check"
              className="check-green"
            />
          )}
        </div>
      )}
      <span className="item-user-meeting__title">{name}</span>
    </div>
  )
}

export default UserItem
