import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InformationSteeringItem from './InformationSteeringItem'
class InformationSteering extends Component {
  static propTypes = {
    informationSteering: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className="container-form-action">
        {!this.props.isShowMobile ? (
          <div className="heading noborder-bottom">
            <h3 className="name-heading">
              <span className="icon-Chidao"></span>
              <span className="text-content">Thông tin chỉ đạo</span>
            </h3>
          </div>
        ) : (
          <div className="heading">
            <span
              className="click-go-back"
              onClick={this.props.closeModulesDetail}
            >
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Thông tin chỉ đạo</h4>
          </div>
        )}
        <div className="form-container">
          <InformationSteeringItem
            informationSteering={this.props.informationSteering}
          />
        </div>
      </div>
    )
  }
}

export default InformationSteering
