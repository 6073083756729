import { Checkbox } from '@blueprintjs/core'
import React from 'react'
import * as Types from 'constants/Api'

const ItemCheckbox = ({ checked, data, title, handleCheckboxChange }) => {
  return (
    <div className="selection-item">
      <Checkbox
        checked={checked}
        onChange={e => handleCheckboxChange(data, e.target.checked)}
      />
      {data?.anhDaiDien ? (
        <div
          className="selection-item-avatar"
          style={{
            backgroundImage: `url(${
              data?.anhDaiDien
                ? `${Types.IMAGE_URL}${data?.anhDaiDien}`
                : '/images/default_avatar.png'
            })`,
          }}
        ></div>
      ) : (
        <div className="avatar-text">{title?.substring(0, 1)}</div>
      )}

      <span className="selection-item-title">{title}</span>
    </div>
  )
}

export default ItemCheckbox
