import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EvnDocumentItem from './EvnDocumentItem'
import NotFound from './NotFound'
import { Scrollbars } from 'react-custom-scrollbars'

class EvnDocumentList extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    onClickHidden: PropTypes.func.isRequired,
    onClickShowDetail: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClickShowDetail = this.handleClickShowDetail.bind(this)
  }

  handleClickShowDetail = params => {
    this.props.onClickShowDetail && this.props.onClickShowDetail(params)
  }

  render() {
    return (
      <div className="ad-digitizing-container">
        <h3 className="heading">
          <span
            className="left-heading-goback"
            onClick={this.props.onClickHidden}
          >
            <span className="pt-icon pt-icon-arrow-left"></span>
          </span>
          <span>Văn bản EVN</span>
          <span> ({this.props.list.length})</span>
        </h3>
        <div className="list-digitizing">
          {this.props.list.length > 0 && (
            <Scrollbars
              autoHideTimeout={100}
              autoHideDuration={300}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={545}
            >
              {this.props.list.map((item, i) => (
                <EvnDocumentItem
                  key={i}
                  item={item}
                  onClickShowDetail={this.handleClickShowDetail}
                />
              ))}
            </Scrollbars>
          )}

          {this.props.list.length === 0 && <NotFound />}
        </div>
      </div>
    )
  }
}

export default EvnDocumentList
