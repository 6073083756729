import React, { memo } from 'react'
import csx from 'classnames'

const Loading = ({ column = 1 }) => {
  return (
    <div className="cpc-loading-wrapper">
      {Array(column)
        .fill(null)
        .map((block, bIndex) => (
          <div className="cpc-loading-block" key={bIndex}>
            {Array(5)
              .fill(null)
              .map((item, iIndex) => (
                <span
                  className={csx('cpc-loading-bar', `item-${iIndex}`)}
                  key={iIndex}
                />
              ))}
          </div>
        ))}
    </div>
  )
}

export default memo(Loading)
