import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getIconKieuFile } from 'helpers/file'

const UIFile = ({
  className,
  name,
  extension,
  hasClose,
  onClick,
  onRemove,
}) => {
  return (
    <div
      className={classNames('ui-file', {
        [`${className}`]: className,
      })}
    >
      <div className="ui-file-name" onClick={onClick}>
        <span
          className="icon"
          style={{ backgroundImage: `url(${getIconKieuFile(extension)})` }}
        ></span>
        <span className="name">{name}</span>
      </div>
      {hasClose ? (
        <span
          className="ui-file-close icon-delete-v2"
          onClick={onRemove}
        ></span>
      ) : (
        ''
      )}
    </div>
  )
}

UIFile.defaultProps = {
  className: '',
  name: '',
  extension: '',
  hasClose: false,
  onClick: () => {},
  onRemove: () => {},
}

UIFile.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  extension: PropTypes.string,
  hasClose: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
}

export default UIFile
