import React, { Component } from 'react'
import { MasterLayout } from '../../components/layout'
import { FormAddService } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const TAO_SERVICE = 'Tạo Service'
const SUA_SERVICE = 'Chỉnh sửa Service'
class AddService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) || undefined,
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <div className="heading-block">
              <span className="headding-inline">
                <span className="icon-headding icon-s-pen"></span>
                <span className="text-headding">
                  {this.state.id ? SUA_SERVICE : TAO_SERVICE}
                </span>
              </span>
            </div>
            <FormAddService
              match={this.props.match}
              history={this.props.history}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddService)
