import { Position, Tooltip } from '@blueprintjs/core'
import classNames from 'classnames'
import { TruncateTextTooltip, ViewMoreDots } from 'components/common'
import { Icon, Input, TagGroup } from 'components/newCommon'
import { DatePicker, Progress, SelectTree, Table } from 'components/newCommon2'
import { WORK_STATUS } from 'constants/Enum'
import { size } from 'lodash'
import moment from 'moment/moment'
import React, { useState, useContext } from 'react'
import { ThongKeKetLuanCuocHopContext } from './ThongKeKetLuanCuocHop'

const ThongKeKetLuanCuocHopChiTiet = () => {
  const DATA_FORMAT = 'DD/MM/YYYY'
  const requestDateFormat = 'YYYY-MM-DD'
  const filterKeys = {
    title: 'title',
    chuTri: 'mainResponsibilityId',
    phoiHop: 'coordinationId',
    ngayBatDau: 'executionDate',
    hanThucHien: 'deadline',
    trangThai: 'status',
    trangThaiDuyet: 'approval',
    page: 'page',
  }
  const {
    onChangeFilterDetail = () => {},
    treeData,
    dataTableDetail,
    filter: {
      [filterKeys.ngayBatDau]: ngayBatDau = null,
      [filterKeys.phoiHop]: phoiHop = null,
      [filterKeys.chuTri]: chuTri = null,
      [filterKeys.title]: title = '',
      [filterKeys.page]: page = 1,
    } = {},
  } = useContext(ThongKeKetLuanCuocHopContext)

  const [fetching] = useState(false)
  const [fetchingFail] = useState(false)

  const columns = () => {
    return [
      {
        key: 'stt',
        width: 5,
        headClass: 'uppercase',
        className: 'align-center',
        title: 'Stt',
        render: (v, r, index) => {
          const num = (page - 1) * 10 + index + 1
          return `${num}`.padStart(num < 10 ? 2 : 1, '0')
        },
      },
      {
        key: 'title',
        width: 25,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Nội dung kết luận',
        filter: (
          <Input
            clearable
            onChange={onChangeFilterDetail({
              name: filterKeys.title,
              reFetch: true,
            })}
            onClear={onChangeFilterDetail({
              name: filterKeys.title,
              reFetch: true,
            })}
            onSend={onChangeFilterDetail({
              name: filterKeys.title,
              reFetch: true,
            })}
            placeholder="Nhập"
            value={title}
          />
        ),
        render: ({
          title,
          status,
          workUserTags = [],
          referenceId,
          reference,
        }) => (
          <div>
            <span
              className={classNames({ 'color-red': status === 'DONE_SLOW' })}
            >
              {title?.length > 20 ? (
                <TruncateTextTooltip content={title} />
              ) : (
                title
              )}
            </span>
            <TagGroup
              popoverClassName="ml15 mb5"
              popoverPosition={Position.TOP_LEFT}
              className={classNames({ mt5: !!size(workUserTags) })}
              tags={workUserTags.map(
                ({ workTagId, workTagName, backgroundColor }) => ({
                  id: workTagId,
                  name: workTagName,
                  color: backgroundColor,
                })
              )}
              preChildren={
                <>
                  {!!referenceId && (
                    <>
                      {reference?.daLuuTuPhongBan && (
                        <Tooltip
                          content={
                            <span className="font-size-13">
                              Công việc đã lưu tủ tài liệu
                            </span>
                          }
                          position={Position.BOTTOM}
                        >
                          <Icon
                            classIcon="icon-Save-DocumentCabinet"
                            className={classNames(
                              'icon-color-primary',
                              'size-icon-18',
                              'mr10 mb5',
                              'd-block'
                            )}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        content={
                          <span className="font-size-13">
                            Công việc Ký số, Công văn
                          </span>
                        }
                        position={Position.BOTTOM}
                      >
                        <Icon
                          classIcon="icon2-e-office-label"
                          className={classNames(
                            'icon-color-primary',
                            'size-icon-18',
                            'mr10 mb5',
                            'd-block'
                          )}
                        />
                      </Tooltip>
                    </>
                  )}
                </>
              }
            />
          </div>
        ),
      },
      {
        key: 'presiding_name',
        width: 20,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Đơn vị chủ trì',
        filter: (
          <SelectTree
            inputWrapperClassName={
              'input-not-ph filter-column-border-hide right-bottom'
            }
            dataSource={[
              { label: 'Tất cả', value: 'ALL' },
              ...treeData?.map?.(e => {
                if (e?.type === 'ALL') {
                  return {
                    ...e,
                    noCheck: true,
                  }
                }
                return e
              }),
            ]}
            keySource={['label', 'value']}
            placeholder="Phòng ban/ Đơn vị"
            className="w-100"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '35px', lineHeight: '35px' }}
            isShowArrowDropdown
            radioName={filterKeys.chuTri}
            value={chuTri}
            onChange={select => {
              const { value } = select
              onChangeFilterDetail({
                name: filterKeys.chuTri,
                reFetch: true,
              })(value)
            }}
          />
        ),
        render: ({ mainResponsibility, status }) => (
          <span className={classNames({ 'color-red': status === 'DONE_SLOW' })}>
            {mainResponsibility?.name || '-'}
          </span>
        ),
      },
      {
        key: 'coordination_name',
        width: 20,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Đơn vị phối Hợp',
        filter: (
          <SelectTree
            inputWrapperClassName={
              'input-not-ph filter-column-border-hide right-bottom'
            }
            dataSource={[
              { label: 'Tất cả', value: 'ALL' },
              ...treeData?.map?.(e => {
                if (e?.type === 'ALL') {
                  return {
                    ...e,
                    noCheck: true,
                  }
                }
                return e
              }),
            ]}
            keySource={['label', 'value']}
            placeholder="Phòng ban/ Đơn vị"
            className="w-100"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '35px', lineHeight: '35px' }}
            isShowArrowDropdown
            radioName={filterKeys.phoiHop}
            value={phoiHop}
            onChange={select => {
              const { value } = select
              onChangeFilterDetail({
                name: filterKeys.phoiHop,
                reFetch: true,
              })(value)
            }}
          />
        ),
        render: ({ coordination, status }) => {
          const arrayData = coordination?.map?.(item => item.name)
          return (
            <span
              className={classNames({ 'color-red': status === 'DONE_SLOW' })}
            >
              {size(arrayData) !== 0 ? (
                <ViewMoreDots data={arrayData} isTotal={true} />
              ) : (
                '-'
              )}
            </span>
          )
        },
      },
      {
        key: 'executionDate',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center',
        title: 'Ngày Bắt đầu',
        filter: (
          <DatePicker
            leftIcon=""
            clearable={true}
            value={ngayBatDau}
            valueDisplay={null}
            onChange={value => {
              const result = value
                ? moment(value).format(requestDateFormat)
                : ''
              onChangeFilterDetail({
                name: filterKeys.ngayBatDau,
                reFetch: true,
              })(result)
            }}
          />
        ),
        render: ({ executionDate, status }) => (
          <span className={classNames({ 'color-red': status === 'DONE_SLOW' })}>
            {moment(executionDate).isValid()
              ? moment(executionDate).format(DATA_FORMAT)
              : '-'}
          </span>
        ),
      },
      {
        key: 'deadline',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center',
        title: 'Hạn thực hiện',
        filter: (
          <DatePicker
            leftIcon=""
            popoverPosition={Position.BOTTOM_RIGHT}
            clearable={true}
            valueDisplay={null}
            onChange={value => {
              const result = value
                ? moment(value).format(requestDateFormat)
                : ''
              onChangeFilterDetail({
                name: filterKeys.hanThucHien,
                reFetch: true,
              })(result)
            }}
          />
        ),
        render: ({ deadline, status }) => {
          const today = moment().startOf('day')
          const diff = moment(deadline).startOf('day').diff(today, 'days')
          const daTre = diff < 0
          const ganTre = diff >= 0 && diff - 3 <= 0
          const isChuaHoanThanh =
            status === WORK_STATUS.CHUA_THUC_HIEN ||
            status === WORK_STATUS.DANG_THUC_HIEN

          return (
            <div>
              <p
                className={classNames({
                  'color-red': status === 'DONE_SLOW',
                  'font-weight-600': isChuaHoanThanh && (daTre || ganTre),
                  'text-danger': isChuaHoanThanh && daTre,
                  'color-orange': isChuaHoanThanh && ganTre,
                })}
              >
                {moment(deadline).isValid()
                  ? moment(deadline).format(DATA_FORMAT)
                  : '-'}
              </p>
              {isChuaHoanThanh &&
              (daTre || ganTre) &&
              moment(deadline).isValid() ? (
                <span className={classNames('font-size-11 disabled')}>
                  {daTre
                    ? `Đã trễ hạn ${Math.abs(diff)} ngày`
                    : ganTre && diff !== 0
                    ? `(Còn ${diff} ngày đến hạn)`
                    : `(Đến hạn)`}
                </span>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        key: 'tienDo',
        className: 'min-width-80 element-center align-center',
        headClass: 'uppercase',
        width: 5,
        title: 'Tiến độ',
        dataIndex: 'progress',
        render: (value = 0, record) => (
          <div>
            {value === 100 ? (
              <Icon className="icon-Bao_Cao font-size-13 color-icon-green font-weight-600" />
            ) : (
              <div style={{ width: 32 }}>
                <Progress progress={value} />
              </div>
            )}
          </div>
        ),
      },
    ]
  }

  return (
    <Table
      className="cpc-table-radius"
      autoHide={false}
      columns={columns()}
      data={dataTableDetail}
      loading={fetching}
      error={fetchingFail}
      tableHeightMax={window.innerHeight - 180}
      tableMinWidth={1200}
    />
  )
}

export default ThongKeKetLuanCuocHopChiTiet
