import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RenderRoutes } from '../routes'
import '../../node_modules/flexboxgrid/dist/flexboxgrid.css'
import '@blueprintjs/core/dist/blueprint.css'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../node_modules/rc-tree-select/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-quill/dist/quill.snow.css'
import '../assets/styles/main.css'
import { connect } from 'react-redux'
import { Position, Toaster } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import * as Actions from '../actions'
import * as Actions2 from '../actions/procedure'
import { Utils } from '../helpers'
import { Promise } from 'es6-promise'
import { WEB_NAME, VERSION, FOLDER_NAME } from 'constants/Api'

const TIEN_ICH_CV_DEN = [
  '/tien-ich/tim-kiem-cong-van/danh-sach',
  '/tien-ich/tim-kiem-cong-van-v61/danh-sach',
  '/tien-ich/in-so-cong-van-di',
  '/tien-ich/in-so-cong-van-den',
  '/tien-ich/tim-kiem-cong-van-do/danh-sach',
]
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toaster: null,
      isLoading: false,
    }
  }

  static propTypes = {
    route: PropTypes.object.isRequired,
    toaster: PropTypes.object,
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  componentDidMount() {
    document.title = WEB_NAME + ' ' + VERSION
    this.handleFavicon()
  }

  handleFavicon = () => {
    const link =
      document.querySelector('link[rel="shortcut icon"]') ||
      document.createElement('link')
    link.rel = 'shortcut icon'
    link.href = `/config/${FOLDER_NAME}/images/favicon.png`
    document.head.appendChild(link)
  }

  async componentWillMount() {
    if (
      this.props.auth &&
      this.props.auth.roleId &&
      this.props.location.search === ''
    ) {
      this.fetchCommonData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.auth || !prevProps.auth.roleId) &&
      this.props.auth &&
      this.props.auth.roleId &&
      this.props.location.search === ''
    ) {
      this.fetchCommonData()
    }
  }

  fetchCommonData = () => {
    this.setState({
      isLoading: true,
    })
    Promise.all([
      this.props.actions.userAuthenticated(this.props.auth.roleId),
      this.props.actions.userPermission(this.props.auth.roleId),
      this.props.actions.commonFetchPriorities(),
      this.props.actions.commonFetchSecurities(),
      this.props.actions.commonFetchObjects(),
      this.props.actions.commonFetchDepartmentsLeader(),
      this.props.actions.getNumberDocumentPage(),
      this.props.actions.getNumberSignNumberPage(),
      this.props.actions.getNumberProjectSignNumberPage(),
      this.props.actions.getNumberPhieuYKien(),
      this.props.actions.getNumberWorkPage(),
      this.props.actions.getTongSoCongVanThuHoi(),
    ]).then(() =>
      this.setState({
        isLoading: false,
      })
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.toaster) {
      this.setState({ toaster: nextProps.toaster })
    }

    if (nextProps.location) {
      let project =
        nextProps.location.pathname.indexOf('/ky-so-mo-rong/du-an') === -1
      if (project) {
        Utils.saveData('Project-Unit', this.props.auth.mainUnitId)
      }

      let department =
        nextProps.location.pathname.indexOf('/he-thong/phong-ban') === -1
      if (department) {
        const header = [{ id: '', tenVietTat: 'Danh mục' }]
        Utils.saveData('DepartmentCategoryPage', this.props.auth.mainUnitId)
        Utils.saveData('DepartmentCategoryPage-Header', header)
      }

      let permission =
        nextProps.location.pathname.indexOf('/he-thong/nguoi-dung') === -1
      if (permission) {
        Utils.saveData('Permission-Unit', this.props.auth.mainUnitId)
        Utils.saveData(
          'Permission-Department',
          this.props.auth.mainDepartmentId
        )
      }

      let Contact = nextProps.location.pathname.indexOf('/danh-ba') === -1
      if (Contact) {
        Utils.saveData('Contact-Unit', this.props.auth.mainUnitId)
        Utils.saveData('Contact-Department', this.props.auth.mainDepartmentId)
      }

      let Role = nextProps.location.pathname.indexOf('/he-thong/vai-tro') === -1
      if (Role) {
        Utils.saveData('Role', this.props.auth.mainUnitId)
      }

      let Unit = nextProps.location.pathname.indexOf('/he-thong/don-vi') === -1
      if (Unit) {
        const header = [{ id: '', tenVietTat: 'Danh mục' }]
        Utils.saveData('UnitCategoryPage-Header', header)
      }

      let Regency =
        nextProps.location.pathname.indexOf('/he-thong/chuc-vu') === -1
      if (Regency) {
        Utils.saveData('RegencyCategory', this.props.auth.mainUnitId)
      }

      let saveNote =
        nextProps.location.pathname.indexOf('/he-thong/so-luu') === -1
      if (saveNote) {
        Utils.saveData('SaveNote', this.props.auth.mainUnitId)
      }

      let object =
        nextProps.location.pathname.indexOf('/he-thong/doi-tuong') === -1
      if (object) {
        Utils.saveData('Object', this.props.auth.mainUnitId)
      }

      let RolePermissionPage =
        nextProps.location.pathname.indexOf('/he-thong/phan-quyen-vai-tro') ===
        -1
      if (RolePermissionPage) {
        Utils.saveData('RolePermissionPage', this.props.auth.mainUnitId)
      }

      let typeSidebar = Utils.getData('typeSidebar')
        ? Utils.getData('typeSidebar')
        : 'utilities'
      let sidebarForUltilities = sessionStorage.getItem('sidebarForUltilities')
        ? sessionStorage.getItem('sidebarForUltilities')
        : typeSidebar
      if (nextProps.location.pathname.indexOf('/cong-van/') > -1) {
        typeSidebar = 'documentary'
        sidebarForUltilities = 'documentary'
      }
      if (nextProps.location.pathname.indexOf('/ky-so/') > -1) {
        typeSidebar = 'signNumber'
        sidebarForUltilities = 'signNumber'
      }
      if (nextProps.location.pathname.indexOf('/cong-viec/') > -1) {
        typeSidebar = 'work'
        sidebarForUltilities = 'work'
      }
      if (nextProps.location.pathname.indexOf('/tu-tai-lieu/') > -1) {
        typeSidebar = 'documentsCabinet'
        sidebarForUltilities = 'documentsCabinet'
      }
      if (nextProps.location.pathname.indexOf('danh-ba') > -1) {
        typeSidebar = 'listContact'
        sidebarForUltilities = 'listContact'
      }
      if (nextProps.location.pathname.indexOf('/he-thong/') > -1) {
        typeSidebar = 'sidebarSystem'
      }
      if (
        nextProps.location.pathname.indexOf('/tien-ich/') > -1 &&
        nextProps.location.pathname !==
          '/tien-ich/tim-kiem-cong-van/danh-sach' &&
        nextProps.location.pathname.indexOf(
          '/tien-ich/tim-kiem-cong-van/chi-tiet'
        ) === -1 &&
        TIEN_ICH_CV_DEN.findIndex(
          link => link === nextProps.location.pathname
        ) === -1 &&
        nextProps.location.pathname !==
          '/tien-ich/tim-kiem-cong-van-do/danh-sach' &&
        nextProps.location.pathname.indexOf(
          '/tien-ich/tim-kiem-cong-van-do/chi-tiet'
        ) === -1
      ) {
        typeSidebar = 'utilities'
      }
      if (typeSidebar !== Utils.getData('typeSidebar')) {
        Utils.saveData('typeSidebar', typeSidebar)
      }
      if (
        nextProps.location.pathname ===
          '/tien-ich/tim-kiem-cong-van/danh-sach' ||
        nextProps.location.pathname.indexOf(
          '/tien-ich/tim-kiem-cong-van/chi-tiet'
        ) !== -1
      ) {
        typeSidebar = 'documentary'
      }
      if (
        sidebarForUltilities !== sessionStorage.getItem('sidebarForUltilities')
      ) {
        sessionStorage.setItem('sidebarForUltilities', sidebarForUltilities)
      }
      this.props.actions.commonSaveTypeSidebar(typeSidebar)
    }

    window.previousLocation = this.props.location
  }

  componentWillUpdate = async (nextProps, nextState) => {
    if (
      nextState.toaster !== null &&
      nextState.toaster !== this.state.toaster
    ) {
      await this.toaster.show({
        message: nextState.toaster.message,
        intent: nextState.toaster.intent,
        autoFocus: true,
        timeout: 2000,
      })
      this.props.actions.commonRemoveToasterMessage()
      this.setState({ toaster: null })
    }
  }

  render() {
    return (
      <div>
        <RenderRoutes routes={this.props.route.routes} />
        <Toaster
          className="message-popup-delete"
          position={Position.TOP_RIGHT}
          ref={this.refHandlers.toaster}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  toaster: state.common.toaster,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions, ...Actions2 }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
