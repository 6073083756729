import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const UIInputUpload = ({
  name,
  label,
  text,
  summary,
  theme,
  hasRequire,
  isDisabled,
  error,
  onChange,
}) => {
  return (
    <div
      className={classNames('ui-input-upload', {
        [`theme-${theme}`]: theme,
      })}
    >
      {label ? (
        <label
          className={classNames('ui-input-upload-label', {
            required: hasRequire,
          })}
        >
          <span>{label}</span>
        </label>
      ) : (
        ''
      )}
      <div className="ui-input-upload-content">
        <div className="ui-input-upload-button">
          <div>
            <span className="icon-upload1"></span>
            <span className="font">{text}</span>
          </div>
          <input
            type="file"
            className="font"
            multiple
            name={name}
            disabled={isDisabled}
            onChange={onChange}
          />
        </div>
        <div>
          {summary ? (
            <p className="ui-input-upload-summary font">{summary}</p>
          ) : (
            ''
          )}
          {error ? <p className="ui-input-upload-error font">{error}</p> : ''}
        </div>
      </div>
    </div>
  )
}

UIInputUpload.defaultProps = {
  name: '',
  label: '',
  text: 'Đính kèm',
  summary: 'Hỗ trợ dung lượng không được quá 5MB',
  theme: 'default',
  error: '',
  hasRequire: false,
  isDisabled: false,
  onChange: () => {},
}

UIInputUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  summary: PropTypes.string,
  theme: PropTypes.string,
  error: PropTypes.string,
  hasRequire: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default UIInputUpload
