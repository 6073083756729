import React, { memo, useRef, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { deleteCongViec } from 'actions/task'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import {
  DELETE_WORK_MEETING_TITLE,
  DELETE_WORK_MEETING_CONTENT,
} from 'constants/Popup'

const DeleteWorkMeeting = ({
  match,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const { actionId } = match.params
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const deleteWork = () => {
    requestAction({
      beforeAction: () => _mountedSet(setLoading, true),
      action: () => deleteCongViec(actionId),
      afterResponse: () => {
        _mountedSet(setShowDialog, false)
        reloadList()
        onClose()
      },
      afterAction: () => _mountedSet(setLoading, false),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      // confirmDanger
      confirmText="Xóa"
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteWork}
      onClose={onClose}
      title={DELETE_WORK_MEETING_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{DELETE_WORK_MEETING_CONTENT}</Card>
    </Dialog>
  )
}

export default memo(withRouter(DeleteWorkMeeting))
