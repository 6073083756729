import { schema } from 'normalizr'

const RequestCommentItemSchema = new schema.Entity(
  'requestComments',
  {},
  {
    idAttribute: requestComments => requestComments,
  }
)

export default RequestCommentItemSchema
