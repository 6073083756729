import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Button, Checkbox, Collapse } from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  CheckboxGroup,
  ListUnitRadioGroupTwoColumns,
  // ListUnitTree
  // PopupConfirm
} from '../common/'
import { Intent } from '@blueprintjs/core'
import { Redirect } from 'react-router-dom'
// import * as POPUP from '../../constants/Popup';
import * as MESSAGE from '../../constants/MessageForm'
import 'moment/locale/vi'
import * as Tool from '../../helpers'
import DatePicker from 'react-datepicker'

class InputForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    signNumberId: PropTypes.string,
    personalLeaderSignNumber: PropTypes.array.isRequired,
    isShow: PropTypes.bool.isRequired,
    departments: PropTypes.array.isRequired,
    childDepartments: PropTypes.array.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    internalDocument: PropTypes.object,
    internalDocumentId: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleClickCancel = this.handleClickCancel.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleChangeCollapse = this.handleChangeCollapse.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleDepartment = this.handleDepartment.bind(this)
    this.handleCheckAllDepartments = this.handleCheckAllDepartments.bind(this)
    this.handleCheckAllChildDepartments =
      this.handleCheckAllChildDepartments.bind(this)
    this.handleCheckChildDepartment = this.handleCheckChildDepartment.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    this.getUploadProgress = this.getUploadProgress.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handleCheckDepartmentOtherUnits =
      this.handleCheckDepartmentOtherUnits.bind(this)
    this.checkReceivedDepartmentUnit =
      this.checkReceivedDepartmentUnit.bind(this)
  }

  state = {
    isOpenPopup: false,
    isRedirect: false,
    redirectTo: null,
    isCollapse: [],
    error: {},
    vanBanKySoId: '',
    soCongVan: '',
    ngayCongVan: moment(),
    trichYeu: '',
    fileName: '',
    fileNoiDung: null,
    fileUpload: null,
    nguoiKyId: '',
    phongBanNhan: [],
    phongBanCapTren: [],
    toNhomNhan: [],
    departmentSelections: [],
    isCheckAllDepartments: false,
    isCheckAllParentDepartments: false,
    childDepartmentSelections: [],
    isCheckAllChildDepartment: false,
    isLoading: false,
    sizeFile: null,
    //isHideButton: false,
    progress: 0,
    dateToToString: moment().format('DD/MM/YYYY'),
    selectedDepartmentOtherUnits: {}, // Luu phong ban theo donViId
    phongBanDonViKhac: [],
    receivedCheckedDataTypeList: [],
    parentDepartmentSelections: [],
  }

  getUploadProgress = () => progress => {
    this.setState({
      progress: Math.floor((progress.loaded * 100) / progress.total),
    })
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  handleClickCancel = () => {
    this.setState({
      isRedirect: true,
      redirectTo: '/cong-van/cong-van-noi-bo/danh-sach',
    })
  }

  handleChangeCollapse = (i, e) => {
    const isCollapse = this.state.isCollapse
    isCollapse[i] = !isCollapse[i]
    this.setState({ isCollapse })
  }

  handleChange = e => {
    const name = e.target.name
    const error = this.state.error
    delete error[name]

    this.setState({
      [e.target.name]: e.target.value,
      error,
    })
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (
      this.props.internalDocument &&
      this.props.internalDocument.ngayCongVan
    ) {
      oldValue = moment(this.props.internalDocument.ngayCongVan)
      oldValueToString = moment(this.props.internalDocument.ngayCongVan).format(
        'DD/MM/YYYY'
      )
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleFileChange = e => {
    const error = this.state.error
    delete error.fileName

    const fileName = e.target.value
    const extension =
      fileName &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
    let sizeFile = e.target.files && e.target.files[0] && e.target.files[0].size

    if (!fileName.trim().length && this.state.fileName.length === 0) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (
      extension &&
      extension !== 'pdf' &&
      extension !== 'doc' &&
      extension !== 'docx'
    ) {
      error.fileName = MESSAGE.TEXT_TYPE_FILE_UPLOAD
    } else if (sizeFile > 1024 * 1024 * 25) {
      error.fileName = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    }

    this.setState({ error })

    if (!error.fileName) {
      let reader = new FileReader()
      let file = e.target.files[0]

      reader.onloadend = () => {
        this.setState({
          fileUpload: file,
          fileName: file.name,
          fileNoiDung: null,
          sizeFile,
        })
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  handleCheckAllDepartments = (
    disabledList = [],
    nameSelectionList,
    nameStateList,
    nameIsCheckAllState
  ) => {
    let selectionList = this.state[nameSelectionList] || []
    let list = this.state[nameStateList] || []
    let isCheckAll = !this.state[nameIsCheckAllState]
    if (isCheckAll) {
      selectionList &&
        selectionList.forEach(item => {
          if (
            list.indexOf(item.value) === -1 &&
            disabledList.indexOf(item.value) === -1
          ) {
            list.push(item.value)
          }
        })
    } else {
      list = []
    }
    this.setState({
      [nameStateList]: list,
      [nameIsCheckAllState]: isCheckAll,
    })
  }

  handleDepartment = (nameStateList, e) => {
    const newSelection = e.target.value
    let list = this.state[nameStateList]
    if (list.length > 0 && list.indexOf(newSelection) > -1) {
      list = list.filter(s => s !== newSelection)
    } else {
      list = [...list, newSelection]
    }
    this.setState({
      [nameStateList]: list,
    })
  }

  handleCheckDepartmentOtherUnits = (selectedListName, data) => {
    if (!data) {
      return
    }
    const newSelection = data.phongBanId
    let selectedDataById = []
    let selectedData = this.state[selectedListName]
    if (selectedData && selectedData[data.donViId]) {
      selectedDataById = selectedData[data.donViId]
    }
    if (
      selectedDataById &&
      selectedDataById.length > 0 &&
      selectedDataById.indexOf(newSelection) > -1
    ) {
      selectedDataById = []
    } else {
      selectedDataById = [newSelection]
    }
    selectedData = {
      ...selectedData,
      [data.donViId]: selectedDataById,
    }
    this.setState({
      [selectedListName]: selectedData,
    })
  }

  checkReceivedDepartmentUnit(data) {
    if (!data) {
      return
    }
    let { phongBanDonViKhac } = this.state
    let selectedDataById = []
    if (
      this.state.selectedDepartmentOtherUnits &&
      this.state.selectedDepartmentOtherUnits[data.donViId]
    ) {
      selectedDataById = this.state.selectedDepartmentOtherUnits[data.donViId]
    }
    phongBanDonViKhac = phongBanDonViKhac.filter(
      item => selectedDataById.indexOf(item) === -1
    )
    if (
      selectedDataById &&
      selectedDataById.length > 0 &&
      selectedDataById.indexOf(data.phongBanId) > -1
    ) {
      phongBanDonViKhac = phongBanDonViKhac.filter(
        item => item !== data.phongBanId
      )
    } else {
      phongBanDonViKhac.push(data.phongBanId)
    }
    this.setState({ phongBanDonViKhac }, () => {
      this.handleCheckDepartmentOtherUnits('selectedDepartmentOtherUnits', data)
    })
  }

  handleCheckAllChildDepartments = () => {
    this.setState({
      isCheckAllChildDepartment: !this.state.isCheckAllChildDepartment,
    })
    let toNhomNhan = this.state.toNhomNhan
    if (!this.state.isCheckAllChildDepartment) {
      this.state.childDepartmentSelections.forEach(item => {
        if (toNhomNhan.indexOf(item.value) === -1) {
          toNhomNhan.push(item.value)
        }
      })
    } else {
      toNhomNhan = []
    }
    this.setState({ toNhomNhan })
  }

  handleCheckChildDepartment = e => {
    const newSelection = e.target.value
    let toNhomNhan
    if (
      this.state.toNhomNhan.length > 0 &&
      this.state.toNhomNhan.indexOf(newSelection) > -1
    ) {
      toNhomNhan = this.state.toNhomNhan.filter(s => s !== newSelection)
    } else {
      toNhomNhan = [...this.state.toNhomNhan, newSelection]
    }
    this.setState({ toNhomNhan })
  }

  handleSubmit = async e => {
    this.setState({ error: {} })

    const error = {}
    let sizeFile = this.state.sizeFile

    if (!this.state.soCongVan || this.state.soCongVan.trim().length === 0) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.trichYeu || this.state.trichYeu.trim().length === 0) {
      error.trichYeu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.nguoiKyId || this.state.nguoiKyId.trim().length === 0) {
      error.nguoiKyId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.fileName || this.state.fileName.trim().length === 0) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
      sizeFile = null
    }

    this.setState({ error, sizeFile, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      if (
        (!this.state.fileName || this.state.fileName.length < 0) &&
        this.props.isShow === true
      ) {
        return this.props.onSubmit(
          MESSAGE.TEXT_MESSAGE_REQUIRE_FILE,
          Intent.WARNING
        )
      }
      return this.props.onSubmit(MESSAGE.TOATS_MESSAGE_WARNING, Intent.WARNING)
    }
    this.setState({ isLoading: true })
    this.props.onLoadSubmit && this.props.onLoadSubmit()
    if (this.state.fileUpload) {
      await this.props.actions
        .fileUpload(this.state.fileUpload, this.getUploadProgress())
        .then(res => {
          if (res.error || !res.payload.data.result) {
            error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
            return this.setState({ error, isLoading: false })
          }

          let fileNoiDung = null
          if (
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          ) {
            fileNoiDung = {
              file_id: res.payload.data.result[0].id,
              ten_file: res.payload.data.result[0].originalName,
              url: res.payload.data.result[0].path,
              kieu_file: res.payload.data.result[0].type,
              kich_thuoc: res.payload.data.result[0].length,
            }
          }
          this.setState({ fileNoiDung, isLoading: false })
        })
    } else if (this.props.isUpdate === false && !this.state.fileNoiDung) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
      this.setState({ error, isLoading: false })
      return this.props.onSubmit(MESSAGE.TOATS_MESSAGE_WARNING, Intent.WARNING)
    }

    this.setState({ isLoading: true })
    if (!this.state.fileNoiDung) {
      error.fileName = MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL
      this.setState({ error, isLoading: false })
      return this.props.onSubmit(
        MESSAGE.TOATS_MESSAGE_FAIL,
        Intent.DANGER,
        false
      )
    }

    this.setState({ isLoading: true })
    let ngayCongVan = ''
    if (
      this.state.ngayCongVan &&
      this.state.ngayCongVan.toString().length > 0
    ) {
      ngayCongVan = moment(this.state.ngayCongVan)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }
    let dataRequest = {
      van_ban_ky_so_id: this.state.vanBanKySoId,
      so_cong_van: this.state.soCongVan,
      ngay_cong_van: ngayCongVan,
      trich_yeu: this.state.trichYeu,
      file_noi_dung: this.state.fileNoiDung,
      nguoi_ky_id: this.state.nguoiKyId,
      phong_ban_nhan: this.state.phongBanNhan,
      to_nhom_nhan: this.state.toNhomNhan,
      phong_ban_don_vi_khac: this.state.phongBanDonViKhac,
      phong_ban_cap_tren: this.state.phongBanCapTren,
    }
    if (!this.props.isUpdate) {
      this.props.actions.internalDocumentAdd(dataRequest).then(res => {
        if (res.payload && res.payload.status === 200) {
          return this.props.onSubmit(
            MESSAGE.CREATE_SUCCESS_DOCUMENT,
            Intent.SUCCESS,
            true
          )
        }
        this.setState({ isLoading: false })
        this.props.onSubmit(MESSAGE.TOATS_MESSAGE_FAIL, Intent.DANGER, false)
      })
    } else {
      dataRequest = {
        ...dataRequest,
        cong_van_noi_bo_id: this.props.internalDocumentId,
      }
      this.props.actions
        .internalDocumentUpdate(this.state.congVanNoiBoId, dataRequest)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            return this.props.onSubmit(
              MESSAGE.TOATS_MESSAGE_SUCCESS,
              Intent.SUCCESS,
              true
            )
          }
          this.setState({ isLoading: false })
          this.props.onSubmit(MESSAGE.TOATS_MESSAGE_FAIL, Intent.DANGER, false)
        })
    }
  }

  handleClickIsCollapse = () => {
    this.setState({ isCollapse: !this.state.isCollapse })
  }

  componentDidMount() {
    this.setState({ error: {} })

    if (
      this.props.signNumberId &&
      this.props.signNumberId.length &&
      this.props.signNumberDocuments[this.props.signNumberId] !== undefined
    ) {
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan
        )
        dateToToString = moment(
          this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      let nguoiKyId = this.state.nguoiKyId
      if (
        this.props.signNumberDocuments[this.props.signNumberId].lanhDaoDonVi
      ) {
        nguoiKyId =
          this.props.signNumberDocuments[this.props.signNumberId].lanhDaoDonVi
            .chucDanhId
      }
      // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
      // let fileNoiDung = this.state.fileNoiDung;
      // let fileName = this.state.fileName;
      // if (this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung) {
      //   fileNoiDung = {
      //     file_id: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.fileId,
      //     ten_file: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.tenFile,
      //     url: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.url,
      //     kieu_file: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.kieuFile,
      //     kich_thuoc: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.kichThuoc
      //   }
      //   fileName = this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.tenFile;
      // }

      this.setState({
        vanBanKySoId: this.props.signNumberId,
        soCongVan:
          this.props.signNumberDocuments[this.props.signNumberId].soVanBan,
        dateToToString,
        ngayCongVan,
        nguoiKyId,
        // fileNoiDung,
        // fileName,
        trichYeu:
          this.props.signNumberDocuments[this.props.signNumberId].trichYeu,
      })
    }

    if (this.props.departments && this.props.departments.length) {
      const departmentSelections = []
      this.props.departments.forEach(item => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ departmentSelections })
    }

    if (this.props.parentDepartments && this.props.parentDepartments.length) {
      const parentDepartmentSelections = []
      this.props.parentDepartments.forEach(item => {
        parentDepartmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ parentDepartmentSelections })
    }

    if (
      this.props.personalLeaderSignNumber &&
      this.props.personalLeaderSignNumber.length
    ) {
      this.setState({
        nguoiKyId:
          this.props.personalLeaderSignNumber[0] &&
          this.props.personalLeaderSignNumber[0].chucDanhId
            ? this.props.personalLeaderSignNumber[0].chucDanhId
            : '',
      })
    }

    if (this.props.childDepartments && this.props.childDepartments.length) {
      const childDepartmentSelections = []
      const isCollapse = new Array(this.props.childDepartments.length)
      isCollapse.fill(false)
      this.props.childDepartments.forEach((item, i) => {
        childDepartmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ childDepartmentSelections, isCollapse })
    }

    if (this.props.signNumberFile) {
      this.setState({
        fileNoiDung: this.props.signNumberFile,
        fileName: this.props.signNumberFile.file_name,
      })
    }

    // window.addEventListener('scroll', this.handleScroll);
    // let windowSize = window.innerHeight;
    // let gb = document.getElementById("groupButton");
    // let self = this;
    // setTimeout(function() {
    //   if (windowSize > gb.getBoundingClientRect().top) {
    //     self.setState({
    //       isHideButton: false
    //     });
    //   } else {
    //     self.setState({
    //       isHideButton: true
    //     });
    //   }
    // }, 100);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.departments !== this.props.departments &&
      nextProps.departments
    ) {
      const departmentSelections = []
      nextProps.departments.forEach((item, i) => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ departmentSelections })
    }

    if (
      nextProps.parentDepartments !== this.props.parentDepartments &&
      nextProps.parentDepartments
    ) {
      const parentDepartmentSelections = []
      nextProps.parentDepartments.forEach((item, i) => {
        parentDepartmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ parentDepartmentSelections })
    }

    if (
      nextProps.personalLeaderSignNumber !==
        this.props.personalLeaderSignNumber &&
      nextProps.personalLeaderSignNumber
    ) {
      this.setState({
        nguoiKyId:
          nextProps.personalLeaderSignNumber[0] &&
          nextProps.personalLeaderSignNumber[0].chucDanhId
            ? nextProps.personalLeaderSignNumber[0].chucDanhId
            : '',
      })
    }

    if (
      nextProps.childDepartments !== this.props.childDepartments &&
      nextProps.childDepartments
    ) {
      const childDepartmentSelections = []
      const isCollapse = new Array(nextProps.childDepartments.length)
      isCollapse.fill(false)
      nextProps.childDepartments.forEach((item, i) => {
        childDepartmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ childDepartmentSelections, isCollapse })
    }

    if (
      nextProps.signNumberFile !== this.props.signNumberFile &&
      nextProps.signNumberFile
    ) {
      this.setState({
        fileNoiDung: nextProps.signNumberFile,
        fileName: nextProps.signNumberFile.file_name,
      })
    }

    if (
      nextProps.signNumberId &&
      nextProps.signNumberId.length > 0 &&
      this.props.signNumberDocuments &&
      this.props.signNumberDocuments[nextProps.signNumberId] !== undefined &&
      nextProps.signNumberId !== this.props.signNumberId
    ) {
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan
        )
        dateToToString = moment(
          this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      let nguoiKyId = this.state.nguoiKyId
      if (this.props.signNumberDocuments[nextProps.signNumberId].lanhDaoDonVi) {
        nguoiKyId =
          this.props.signNumberDocuments[nextProps.signNumberId].lanhDaoDonVi
            .chucDanhId
      }

      // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
      // let fileNoiDung = this.state.fileNoiDung;
      // let fileName = this.state.fileName;
      // if (this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung) {
      //   fileNoiDung = {
      //     file_id: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.fileId,
      //     ten_file: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.tenFile,
      //     url: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.url,
      //     kieu_file: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.kieuFile,
      //     kich_thuoc: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.kichThuoc
      //   }
      //   fileName = this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.tenFile;
      // }

      this.setState({
        vanBanKySoId: nextProps.signNumberId,
        soCongVan:
          this.props.signNumberDocuments[nextProps.signNumberId].soVanBan,
        dateToToString,
        ngayCongVan,
        nguoiKyId,
        // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
        // fileNoiDung,
        // fileName,
        trichYeu:
          this.props.signNumberDocuments[nextProps.signNumberId].trichYeu,
      })
    }

    // CHANGED: Tao cong van thanh cong => reset form
    if (nextProps.isSubmitSuccess && nextProps.isSubmitSuccess === true) {
      let nguoiKyId = this.state.nguoiKyId
      if (
        this.props.personalLeaderSignNumber &&
        this.props.personalLeaderSignNumber.length
      ) {
        nguoiKyId = nextProps.personalLeaderSignNumber[0].chucDanhId
      }

      this.setState({
        error: {},
        soCongVan: '',
        vanBanKySoId: '',
        ngayCongVan: moment(),
        trichYeu: '',
        nguoiKyId,
        phongBanNhan: [],
        selectedDepartmentOtherUnits: {}, // Luu phong ban theo donViId
        phongBanDonViKhac: [],
        phongBanCapTren: [],
        toNhomNhan: [],
        isCheckAllDepartments: false,
        fileNoiDung: null,
        fileUpload: null,
        fileName: '',
        isLoading: false,
        sizeFile: null,
        progress: 0,
        dateToToString: moment().format('DD/MM/YYYY'),
        isCheckAllChildDepartment: false,
        isCheckAllParentDepartment: false,
        receivedCheckedDataTypeList: [],
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.phongBanNhan !== this.state.phongBanNhan &&
      this.state.phongBanNhan
    ) {
      if (
        this.state.phongBanNhan.length + 1 ===
        this.state.departmentSelections.length
      ) {
        this.setState({ isCheckAllDepartments: true })
      } else {
        this.setState({ isCheckAllDepartments: false })
      }
    }

    if (
      prevState.phongBanCapTren !== this.state.phongBanCapTren &&
      this.state.phongBanCapTren
    ) {
      if (
        this.state.phongBanCapTren.length ===
        this.state.parentDepartmentSelections.length
      ) {
        this.setState({ isCheckAllParentDepartments: true })
      } else {
        this.setState({ isCheckAllParentDepartments: false })
      }
    }

    if (
      prevState.toNhomNhan !== this.state.toNhomNhan &&
      this.state.toNhomNhan
    ) {
      if (
        this.state.toNhomNhan.length ===
        this.state.childDepartmentSelections.length
      ) {
        this.setState({ isCheckAllChildDepartment: true })
      } else {
        this.setState({ isCheckAllChildDepartment: false })
      }
    }

    if (
      this.props.isUpdate &&
      this.props.internalDocument &&
      this.props.internalDocument !== prevProps.internalDocument
    ) {
      let ngayCongVan = undefined
      let dateToToString = ''
      let nguoiKyId = this.state.nguoiKyId
      let phongBanNhan = []
      let toNhomNhan = []
      let fileName = ''
      let fileNoiDung = this.state.fileNoiDung
      let phongBanDonViKhac = []
      let phongBanCapTren = []
      let selectedDepartmentOtherUnits = {}
      let receivedCheckedDataTypeList = []

      if (
        this.props.internalDocument.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.internalDocument.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(this.props.internalDocument.ngayCongVan)
        dateToToString = moment(this.props.internalDocument.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }
      if (this.props.internalDocument.nguoiKy) {
        nguoiKyId = this.props.internalDocument.nguoiKy.chucDanhId
      }
      if (this.props.internalDocument.phongBanNhan) {
        this.props.internalDocument.phongBanNhan.forEach(item => {
          phongBanNhan.push(item.phongBanId)
        })
      }
      if (this.props.internalDocument.phongBanCapTren) {
        this.props.internalDocument.phongBanCapTren.forEach(item => {
          phongBanCapTren.push(item.phongBanId)
        })
      }
      if (this.props.internalDocument.toNhomNhan) {
        this.props.internalDocument.toNhomNhan.forEach(item => {
          toNhomNhan.push(item.phongBanId)
        })
      }
      if (this.props.internalDocument.fileNoiDung) {
        fileNoiDung = {
          file_id: this.props.internalDocument.fileNoiDung.fileId,
          ten_file: this.props.internalDocument.fileNoiDung.tenFile,
          url: this.props.internalDocument.fileNoiDung.url,
          kieu_file: this.props.internalDocument.fileNoiDung.kieuFile,
          kich_thuoc: this.props.internalDocument.fileNoiDung.kichThuoc,
        }
        fileName = this.props.internalDocument.fileNoiDung.tenFile
      }
      if (this.props.internalDocument.phongBanDonViKhac) {
        this.props.internalDocument.phongBanDonViKhac.forEach(item => {
          if (item.dsPhongBan && item.donViId) {
            let tmp = []
            item.dsPhongBan.forEach(subItem => {
              tmp.push(subItem.phongBanId)
            })
            selectedDepartmentOtherUnits[item.donViId] = tmp
            phongBanDonViKhac = phongBanDonViKhac.concat(tmp)
            receivedCheckedDataTypeList.push(item.donViId)
          }
        })
      }

      this.setState({
        congVanNoiBoId: this.props.internalDocument.congVanNoiBoId,
        vanBanKySoId: this.props.internalDocument.vanBanKySoId,
        soCongVan: this.props.internalDocument.soCongVan,
        dateToToString,
        ngayCongVan,
        trichYeu: this.props.internalDocument.trichYeu,
        nguoiKyId: nguoiKyId,
        phongBanNhan,
        phongBanCapTren,
        toNhomNhan,
        fileNoiDung,
        fileName,
        selectedDepartmentOtherUnits,
        phongBanDonViKhac,
        receivedCheckedDataTypeList,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    let windowSize = window.innerHeight
    let gb = document.getElementById('groupButton')
    if (windowSize >= gb.getBoundingClientRect().top) {
      this.setState({
        isHideButton: false,
      })
    } else {
      this.setState({
        isHideButton: true,
      })
    }
  }

  handleResetDepartmentOtherUnit = donViId => {
    let { selectedDepartmentOtherUnits, phongBanDonViKhac } = this.state
    let removeIds = []
    if (selectedDepartmentOtherUnits && selectedDepartmentOtherUnits[donViId]) {
      removeIds = removeIds.concat(selectedDepartmentOtherUnits[donViId])
    }
    phongBanDonViKhac = phongBanDonViKhac.filter(
      item => removeIds.indexOf(item) === -1
    )
    this.setState({
      selectedDepartmentOtherUnits: {
        ...selectedDepartmentOtherUnits,
        [donViId]: [],
      },
      phongBanDonViKhac,
    })
  }

  render() {
    return (
      <div>
        <div
          className={classnames({
            'add-receive-document': !this.props.isShow,
            'ad-digitizing': this.props.isShow,
          })}
        >
          <div className="form-container">
            <div className="form-padding-box">
              <div className="pt-form-group pt-intent-danger">
                <div className="row form-numberDocument">
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Số công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <input
                        name="soCongVan"
                        className={classnames('pt-input', {
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.internalDocument === true,
                        })}
                        value={this.state.soCongVan}
                        placeholder="Nhập số…"
                        type="text"
                        dir="auto"
                        onChange={this.handleChange}
                        autoFocus={true}
                        disabled={
                          this.props.isLoadingData &&
                          this.props.isLoadingData.internalDocument === true
                        }
                      />
                    </div>
                    {this.state.error.soCongVan !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.soCongVan}
                      </div>
                    )}
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Ngày công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-input-group">
                        <DatePicker
                          isClearable={this.state.dateToToString ? true : false}
                          readOnly={this.props.readOnly}
                          selected={this.state.ngayCongVan}
                          onChange={this.handleDateChange.bind(
                            this,
                            'ngayCongVan',
                            'dateToToString'
                          )}
                          value={this.state.dateToToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateToToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayCongVan',
                              'dateToToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Chọn ngày..."
                          disabled={
                            this.props.isLoadingData &&
                            this.props.isLoadingData.internalDocument === true
                          }
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                    {this.state.error.ngayCongVan !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.ngayCongVan}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                    <label className="pt-label">
                      Trích yếu
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <textarea
                        name="trichYeu"
                        className={classnames(
                          'pt-input pt-fill text-abridgment',
                          {
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.internalDocument ===
                                true,
                          }
                        )}
                        value={this.state.trichYeu}
                        rows="3"
                        placeholder="Nội dung…"
                        dir="auto"
                        onChange={this.handleChange}
                        disabled={
                          this.props.isLoadingData &&
                          this.props.isLoadingData.internalDocument === true
                        }
                      ></textarea>
                    </div>
                    {this.state.error.trichYeu !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.trichYeu}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  {!this.props.isShow && (
                    <div className="col-xs-6 col-sm-6 col-md-6 form-item file-box">
                      <label className="pt-label">
                        File nội dung
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content">
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name="fileName"
                            onChange={this.handleFileChange}
                            onClick={event => {
                              event.target.value = null
                            }}
                            disabled={
                              this.state.isLoading ||
                              (this.props.isLoadingData &&
                                this.props.isLoadingData.internalDocument ===
                                  true) ||
                              this.props.isUpdate
                            }
                          />
                          <span className="pt-file-upload-input">
                            {this.state.fileName.length === 0
                              ? 'Chọn file đính kèm'
                              : this.state.fileName}
                          </span>
                        </label>
                        {this.state.sizeFile && (
                          <p className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file đã upload:
                            <span className="size-file">
                              {' '}
                              {Tool.Utils.getFileSize(this.state.sizeFile)}
                            </span>
                          </p>
                        )}
                        {this.state.error.fileName !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.fileName}
                          </div>
                        )}
                        {this.state.isLoading && this.state.progress > 0 && (
                          <div className="progress-upload-content">
                            <span className="percent-content">
                              {this.state.progress}%
                            </span>
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div
                                className="pt-progress-meter"
                                style={{ width: this.state.progress + '%' }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Người ký
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.nguoiKyId === true,
                          })}
                          name="nguoiKyId"
                          value={this.state.nguoiKyId}
                          onChange={this.handleChange}
                          disabled={
                            this.props.isLoadingData &&
                            this.props.isLoadingData.nguoiKyId === true
                          }
                        >
                          {this.props.personalLeaderSignNumber.map(
                            (item, i) => (
                              <option key={i} value={item.chucDanhId}>
                                {item.tenNhanVien}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    {this.state.error.nguoiKyId !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.nguoiKyId}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Chuyển đến phòng ban*/}
              <div className="selectbox-group pt-intent-danger pt-form-group">
                <div className="heading-group-select">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                      <span className="pull-left name_group">
                        Chuyển đến phòng ban
                      </span>
                      <Checkbox
                        className="pull-right"
                        name="allDepartment"
                        checked={this.state.isCheckAllDepartments}
                        onChange={this.handleCheckAllDepartments.bind(
                          this,
                          [this.props.mainDepartmentId],
                          'departmentSelections',
                          'phongBanNhan',
                          'isCheckAllDepartments'
                        )}
                        disabled={
                          !this.state.departmentSelections ||
                          this.state.departmentSelections.length === 0
                        }
                      >
                        <span className="name_checkbox">Chọn tất cả</span>
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="container-checkbox">
                  <CheckboxGroup
                    name="phongBanNhan"
                    options={this.state.departmentSelections}
                    selectedOptions={this.state.phongBanNhan}
                    onChange={this.handleDepartment.bind(this, 'phongBanNhan')}
                    disabledList={[this.props.mainDepartmentId]}
                    isNotCheckDisabledItem
                    isLoadingData={
                      this.props.isLoadingData &&
                      this.props.isLoadingData.departments
                    }
                    colum="col-xs-6 col-sm-6 col-md-3"
                  />
                </div>
              </div>
              {/* Chuyển đến phòng ban cấp trên (PC)*/}
              {this.props.isPc === false &&
                this.props.isAllowShowDepartmentsOfOtherUnits === true && (
                  <div className="selectbox-group pt-intent-danger">
                    <div className="heading-group-select">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                          <span className="pull-left name_group">
                            Chuyển đến phòng ban cấp trên (PC)
                          </span>
                          <Checkbox
                            className="pull-right"
                            name="allDepartment"
                            checked={this.state.isCheckAllParentDepartments}
                            onChange={this.handleCheckAllDepartments.bind(
                              this,
                              [],
                              'parentDepartmentSelections',
                              'phongBanCapTren',
                              'isCheckAllParentDepartments'
                            )}
                            disabled={
                              !this.state.parentDepartmentSelections ||
                              this.state.parentDepartmentSelections.length === 0
                            }
                          >
                            <span className="name_checkbox">Chọn tất cả</span>
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="container-checkbox">
                      <CheckboxGroup
                        name="phongBanCapTren"
                        options={this.state.parentDepartmentSelections}
                        selectedOptions={this.state.phongBanCapTren}
                        onChange={this.handleDepartment.bind(
                          this,
                          'phongBanCapTren'
                        )}
                        disabledList={[this.props.mainDepartmentId]}
                        isNotCheckDisabledItem
                        isLoadingData={
                          this.props.isLoadingData &&
                          this.props.isLoadingData.parentDepartments
                        }
                        colum="col-xs-6 col-sm-6 col-md-3"
                      />
                    </div>
                  </div>
                )}
              {this.state.childDepartmentSelections &&
                this.state.childDepartmentSelections.length > 0 && (
                  <div className="checkbox-forward-department">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                        <div className="heading-group-select">
                          <span className="name_group">Chuyển đến tổ/nhóm</span>
                          <Checkbox
                            className="pull-right"
                            checked={this.state.isCheckAllChildDepartment}
                            onChange={this.handleCheckAllChildDepartments}
                            disabled={
                              !this.state.childDepartmentSelections.length
                            }
                          >
                            <span className="name_checkbox">Chọn tất cả</span>
                          </Checkbox>
                        </div>
                        <div className="forward-department">
                          <Scrollbars
                            autoHide
                            autoHideTimeout={100}
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={286}
                            autoHideDuration={200}
                          >
                            <div className="list-checkbox-level">
                              {this.state.childDepartmentSelections.map(
                                (item, i) => (
                                  <div className="item-checkbox" key={i}>
                                    <div className="sub-level-primary">
                                      <Checkbox
                                        label={item.content}
                                        value={item.value}
                                        checked={
                                          this.state.toNhomNhan.indexOf(
                                            item.value
                                          ) > -1
                                        }
                                        className="name-checkbox"
                                        onChange={
                                          this.handleCheckChildDepartment
                                        }
                                      />
                                      <span
                                        className="pt-icon pull-right pt-icon-chevron-down"
                                        onClick={this.handleChangeCollapse.bind(
                                          this,
                                          i
                                        )}
                                      ></span>
                                    </div>
                                    <Collapse isOpen={this.state.isCollapse[i]}>
                                      {/* Hiện tại chỉ xét 1 cấp phòng ban con*/}
                                      {/*<div className="dropdown-check-box-group">
                                      <div className="sub-level-primary">
                                        <Checkbox label='Văn phòng đại diện 2' className="name-checkbox"/>
                                        <span className="pt-icon pull-right pt-icon-chevron-down"></span>
                                        <div className="dropdown-check-box-group">
                                          <div className="item-checkbox">
                                            <Checkbox label='Văn phòng đại diện 3' className="name-checkbox"/>
                                          </div>
                                          <div className="item-checkbox">
                                            <Checkbox label='Văn phòng đại diện 3' className="name-checkbox"/>
                                          </div>
                                          <div className="item-checkbox">
                                            <Checkbox label='Văn phòng đại diện 3' className="name-checkbox"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>*/}
                                    </Collapse>
                                  </div>
                                )
                              )}
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/* <ListUnitTree
                  data={this.props.electricUnits}
                  actions={this.props.actions}
                  onCheckDepartment={this.checkReceivedDepartmentUnit}
                  selectedList={this.state.phongBanDonViKhac}
                  selectedListObject={this.state.selectedDepartmentOtherUnits}
                  mainUnitId={this.props.mainUnitId}
                /> */}
              {this.props.isAllowShowDepartmentsOfOtherUnits === true && (
                <div className="selectbox-group pt-intent-danger">
                  <div className="heading-group-select">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                        <span className="pull-left name_group">
                          {this.props.isPc
                            ? 'Danh sách phòng ban đơn vị khác'
                            : 'Danh sách phòng ban đơn vị khác'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="container-list-other-units">
                    <ListUnitRadioGroupTwoColumns
                      className=""
                      data={this.props.electricUnits}
                      requestApi={this.props.actions.commonFetchDepartments}
                      onCheckDepartmentList2={this.checkReceivedDepartmentUnit}
                      selectedList={this.state.phongBanDonViKhac}
                      checkedDataTypeList2={
                        this.state.receivedCheckedDataTypeList
                      }
                      selectedListObject={
                        this.state.selectedDepartmentOtherUnits
                      }
                      mainUnitId={this.props.mainUnitId}
                      conditionField="phongBanLanhDao"
                      conditionValue={true}
                      isCanShowCompositive={false}
                      isLoadingData={this.props.isLoadingData.electricUnits}
                      onResetDepartmentOtherUnit={
                        this.handleResetDepartmentOtherUnit
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div id="groupButton" className="buttton-action-footer">
            <Button
              onClick={this.handleClickCancel}
              className="pt-button btn-cancel"
              disabled={this.state.isLoading === true ? true : false}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-save"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
              disabled={
                Object.values(this.props.isLoadingData).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>

          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-main-color"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
              disabled={
                Object.values(this.props.isLoadingData).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Lưu</span>
            </Button>
            <Button
              className="pt-button btn-cancel"
              onClick={this.handleClickCancel}
              disabled={this.state.isLoading === true ? true : false}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>

          {/*<PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={this.props.isUpdate ? POPUP.HUY_SUA_CONG_VAN_NOI_BO : POPUP.HUY_TAO_CONG_VAN_NOI_BO}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleClickCancel}
          >
            {POPUP.TEXT_BODY}
          </PopupConfirm>*/}
          {this.state.isRedirect && (
            <Redirect
              push
              to={{
                pathname: this.state.redirectTo,
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

export default InputForm
