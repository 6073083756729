import { ListFilter } from '../../../components/common'
import React from 'react'

const ProcedureListFilter = props => (
  <ListFilter
    inputPlaceholder="Nhập tên quy trình"
    onRemoveFilter={props.onSearch}
    onSearch={props.onSearch}
  />
)

export default ProcedureListFilter
