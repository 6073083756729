import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { get } from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'

import { StatusDate, Input, Avatar } from 'components/newCommon'
import requestAction from 'helpers/request'
import { Table, Pagination } from 'components/newCommon2'
import { ASSIGN_TYPE } from 'constants/Enum'
import { StatisticContext } from '../Statistic'
import { getWorksOverdue } from 'actions/task'

const dateFormat = 'DD/MM/YYYY'
const tabsType = {
  DONE: 'DONE',
}
const PAGE_SIZE = 10
const filterKeys = {
  title: 'FilterColumnWorkItem.Title',
}

const ThongKeCongViecDanhSach = () => {
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const [filter, setFilter] = useState({})
  const [loadingList, setLoadingList] = useState(false)
  const [errorList, setErrorList] = useState(false)
  const [data, setData] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const getDeadline = detail => {
    return (
      get(
        get(detail, 'responsibilities[0].workAssignments', []).slice(-1)[0],
        'deadline'
      ) || detail.deadline
    )
  }

  const { filterConvert, dataTableDetail } = useContext(StatisticContext)

  const getData = useCallback(
    currentPage => {
      requestAction({
        showToast: false,
        codeCheck: 200,
        beforeAction: () => {
          setLoadingList(true)
          mountedSet(setCurrentPage, currentPage)
        },
        action: () => {
          let params = {
            ...filterConvert,
            ...dataTableDetail,
            IsFromEoffice: dataTableDetail.IsFromEoffice
              ? dataTableDetail.IsFromEoffice === '0'
                ? null
                : dataTableDetail.IsFromEoffice === '1'
                ? true
                : false
              : null,
            IsSelfAssigned: dataTableDetail.IsSelfAssigned
              ? dataTableDetail.IsSelfAssigned === '0'
                ? null
                : dataTableDetail.IsSelfAssigned === '1'
                ? true
                : false
              : null,
            ...filter,
            WorkDeadlineStatus: 'OVERDUE',
            page: currentPage,
            limit: PAGE_SIZE,
          }
          return getWorksOverdue(params)
        },
        afterResponse: ({ items = [], totalCount }) => {
          mountedSet(setData, items)
          mountedSet(setTotalPage, totalCount)
        },
        afterError: () => {
          mountedSet(setErrorList, true)
        },
        afterAction: () => {
          setLoadingList(false)
        },
      })
    },
    [filter, dataTableDetail, filterConvert]
  )

  const changeFilter =
    ({ name }) =>
    value => {
      let newFilter = { ...filter }
      newFilter = {
        ...newFilter,
        [name]: value,
      }
      mountedSet(setFilter, newFilter)
    }

  const changePage = page => {
    getData(page)
  }

  // Cấu trúc bảng
  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (currentPage - 1) * PAGE_SIZE + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'trichYeu',
      width: 30,
      headClass: 'uppercase',
      className: 'align-center min-width-100',
      title: 'Trích yếu / Tiêu đề',
      filter: (
        <Input
          clearable
          onClear={changeFilter({ name: filterKeys.title })}
          onSend={changeFilter({ name: filterKeys.title })}
          placeholder="Nhập"
          value={get(filter, 'title', '')}
        />
      ),
      render: ({ title }) => (
        <div>
          <span>{title}</span>
        </div>
      ),
    },
    {
      key: 'nguoiGiao',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-100 align-center',
      title: 'Người giao',
      dataIndex: 'assigner',
      render: value => {
        if (get(value, 'type') === ASSIGN_TYPE.CA_NHAN) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={get(value, 'avatar')}
                className="mr10 tiny"
                needBaseUrl
              />
              {get(value, 'name')}
            </div>
          )
        }

        return get(value, 'shortName')
      },
    },
    {
      key: 'nguoiThucHien',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-100 align-center',
      title: 'Người thực hiện',
      dataIndex: 'mainResponsibility',
      render: value => {
        if (get(value, 'type') === ASSIGN_TYPE.CA_NHAN) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={get(value, 'avatar')}
                className="mr10 tiny"
                needBaseUrl
              />
              {get(value, 'name')}
            </div>
          )
        }

        return get(value, 'shortName')
      },
    },
    {
      key: 'hanThucHien',
      width: 15,
      headClass: 'uppercase',
      className: 'min-width-120 align-center',
      title: 'Hạn thực hiện',
      render: ({ status }, record) => (
        <StatusDate
          date={getDeadline(record)}
          isCompleted={status === tabsType.DONE}
          descriptionSize={11}
        />
      ),
    },
    {
      key: 'ngayHoanThanh',
      width: 15,
      headClass: 'uppercase',
      className: 'min-width-120 align-center',
      title: 'Ngày hoàn thành',
      dataIndex: 'finishedDate',
      render: data =>
        moment(data).isValid() ? moment(data).format(dateFormat) : data,
    },
  ]

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    getData(1)
  }, [getData])

  return (
    <>
      <Table
        loading={loadingList}
        error={errorList}
        className="cpc-table-radius"
        autoHide={false}
        columns={columns}
        data={data}
        tableMinWidth={600}
      />
      <div className="mt10" style={{ position: 'relative' }}>
        <div
          className="footer-pagination mt20"
          style={{
            position: 'relative',
            padding: 0,
            justifyContent: 'flex-end',
            bottom: 0,
          }}
        >
          <Pagination
            total={totalPage}
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={changePage}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({})

export default withRouter(connect(mapStateToProps)(ThongKeCongViecDanhSach))
