import React, { memo } from 'react'
import csx from 'classnames'

const gridClass = {
  full: 'col-xs-12 col-sm-12 col-md-12',
  half: 'col-xs-12 col-sm-12 col-md-6',
  halfSp: 'col-xs-6 col-sm-6 col-md-6',
  quarter: 'col-xs-12 col-sm-6 col-md-3',
  threeQuarters: 'col-xs-12 col-sm-6 col-md-9',
  quarterSmFull: 'col-xs-12 col-sm-12 col-md-3',
  threeQuartersSmFull: 'col-xs-12 col-sm-12 col-md-9',
  oneThird: 'col-xs-12 col-sm-6 col-md-4',
  twoThirds: 'col-xs-12 col-sm-6 col-md-8',
  oneSixth: 'col-xs-12 col-sm-6 col-md-2',
  fiveSixth: 'col-xs-12 col-sm-6 col-md-10',
}

const Col = ({ children, grid = 'full', className, colRef, ...props }) => {
  return (
    <div {...props} className={csx(gridClass[grid], className)} ref={colRef}>
      {children}
    </div>
  )
}

export default memo(Col)
