import React, { useState, useCallback, memo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Intent } from '@blueprintjs/core'

import { showToast } from 'actions/task'
import PopupModal from 'components/common/PopupModal'
import { authLogout, changePassword } from 'actions'

const Index = ({ actions, onClose }) => {
  const [dataForm, setDataForm] = useState(null)
  const [isCheckValidate, setIsCheckValidate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitChangePassword = useCallback(() => {
    const handleLogout = () => {
      let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
      let user_login = localStorage.getItem('user_login')
      localStorage.clear()
      localStorage.setItem('user_login', user_login)
      localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
      sessionStorage.clear()
      actions.authLogout()
    }
    setIsCheckValidate(true)
    if (
      dataForm?.currentPassword?.trim() &&
      dataForm?.newPassword?.trim() &&
      dataForm?.newPassword?.trim() === dataForm?.confirmPassword?.trim()
    ) {
      setIsLoading(true)
      const payload = {
        current_password: dataForm?.currentPassword?.trim(),
        new_password: dataForm?.newPassword?.trim(),
      }
      actions.changePassword(payload).then(res => {
        setIsLoading(false)
        if (res.error) {
          showToast({
            message: res.error.response.data.message,
            intent: Intent.DANGER,
          })
        } else {
          showToast({
            message: 'Đổi mật khẩu thành công',
            intent: Intent.SUCCESS,
          })
          setDataForm(null)
          setIsCheckValidate(false)
          handleLogout()
          onClose()
        }
      })
    }
  }, [actions, dataForm, onClose])

  const onFromChange = e => {
    setIsCheckValidate(false)
    const target = e?.target
    const name = target?.name
    const value = target?.value
    setDataForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <PopupModal
      isOpen={true}
      title="Đổi mật khẩu"
      contentClass="width-350"
      onClose={onClose}
    >
      <div className="change-password p-24">
        <div className="input-group">
          <label htmlFor="currentPassword" className="input-group__label">
            Mật khẩu hiện tại
            <span className="input-required">*</span>
          </label>
          <input
            type="password"
            id="currentPassword"
            name="currentPassword"
            onChange={onFromChange}
            placeholder="Nhập"
            autoFocus={true}
            className={classNames('pt-input', {
              error: !dataForm?.currentPassword?.trim() && isCheckValidate,
            })}
          />
          {!dataForm?.currentPassword?.trim() && isCheckValidate && (
            <span className="error-message">Nhập mật khẩu hiện tại</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="newPassword" className="input-group__label">
            Mật khẩu mới
            <span className="input-required">*</span>
          </label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            onChange={onFromChange}
            placeholder="Nhập mật khẩu"
            className={classNames('pt-input', {
              error: !dataForm?.newPassword?.trim() && isCheckValidate,
            })}
          />
          {!dataForm?.newPassword?.trim() && isCheckValidate && (
            <span className="error-message">Nhập mật khẩu mới</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="confirmPassword" className="input-group__label">
            Xác nhận mật khẩu
            <span className="input-required">*</span>
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            onChange={onFromChange}
            placeholder="Xác nhận mật khẩu"
            className={classNames('pt-input', {
              error:
                dataForm?.newPassword?.trim() &&
                dataForm?.confirmPassword?.trim() &&
                dataForm?.newPassword?.trim() !==
                  dataForm?.confirmPassword?.trim() &&
                isCheckValidate,
            })}
          />
          {dataForm?.newPassword?.trim() &&
            dataForm?.confirmPassword?.trim() &&
            dataForm?.newPassword?.trim() !==
              dataForm?.confirmPassword?.trim() &&
            isCheckValidate && (
              <span className="error-message">
                không trùng khớp với mật khẩu mới
              </span>
            )}
        </div>
      </div>
      <div className="action-change-password">
        <button className="cancel" onClick={onClose}>
          <span className="pt-icon icon-Huy"></span>
          HỦY
        </button>
        <button
          className="save"
          onClick={onSubmitChangePassword}
          disabled={isLoading}
        >
          LƯU
          <span className="pt-icon icon-save"></span>
        </button>
      </div>
    </PopupModal>
  )
}

Index.defaultProps = {
  onClose: () => {},
}

Index.propTypes = {
  onClose: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ changePassword, authLogout }, dispatch),
})

export default connect(null, mapDispatchToProps)(memo(Index))
