import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const UIStatus = ({ name, color, theme }) => {
  return (
    <div
      className={classNames('ui-status', {
        [`theme-${theme}`]: theme,
      })}
      style={{
        color: color,
        '--status-color': color,
      }}
    >
      {name}
    </div>
  )
}

UIStatus.defaultProps = {
  name: '',
  color: '',
  theme: 'LEFT_LINE',
}

UIStatus.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  theme: 'LEFT_LINE',
}

export default UIStatus
