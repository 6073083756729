import { schema } from 'normalizr'

const SignNumber = new schema.Entity(
  'signNumbers',
  {},
  {
    idAttribute: signNumber => signNumber.kySoId,
  }
)

export default SignNumber
