import React, { Component } from 'react'

const LINE_TO_SHOW = 10

export default class TruncateText extends Component {
  state = {
    readMore: true,
    isReadMoreText: true,
  }

  componentDidMount() {
    this.checkHandleReadMore()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.content !== this.props.content) {
      this.checkHandleReadMore()
    }
  }

  checkHandleReadMore = () => {
    if (this.content) {
      let elLineHeight = this.getComputedLineHeight(this.content)
      if (elLineHeight <= 91) {
        this.setState({
          readMore: false,
        })
      }

      if (elLineHeight < 91) {
        this.setState({
          isReadMoreText: false,
        })
      }
    }
  }

  toggleReadMore = () => {
    this.setState({ readMore: !this.state.readMore })
  }

  getComputedLineHeight = element => {
    let countHeight = 0
    let heightSet = 0
    // get tat ca the con ben trong
    let selectorAll = element.querySelectorAll('div:first-child')
    for (let i = 0; i <= LINE_TO_SHOW; i++) {
      if (selectorAll[0].children[i]) {
        countHeight += selectorAll[0].children[i].offsetHeight
      }
    }

    countHeight > 91 ? (heightSet = 91) : (heightSet = countHeight)
    return heightSet
  }

  render() {
    return (
      <div>
        <div
          style={{
            maxHeight:
              this.state.readMore === true
                ? null
                : `${
                    this.content
                      ? this.getComputedLineHeight(this.content)
                      : 'auto'
                  }px`,
          }}
          ref={el => (this.content = el)}
          className="truncate-text__content"
        >
          <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
        </div>
        {this.state.readMore !== undefined && this.state.isReadMoreText && (
          <span
            className="truncate-text__read-more"
            onClick={this.toggleReadMore}
          >
            {this.state.readMore === false ? 'Xem thêm' : 'Thu gọn'}
          </span>
        )}
      </div>
    )
  }
}
