import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Authorization, Permission } from '../../components/auth'
import { Paginate } from '../../components/common'

class ListFooterOld extends Component {
  static propTypes = {
    initialPage: PropTypes.number,
    pageCount: PropTypes.number,
    pageRange: PropTypes.number,
    marginPage: PropTypes.number,
    onPageClick: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      initialPage: props.initialPage || 0,
      forcePage: props.forcePage || 0,
      pageCount: props.pageCount || 1,
      pageRange: props.pageRange || 2,
      marginPage: props.marginPage || 1,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      initialPage: props.initialPage || 0,
      forcePage: props.forcePage || 0,
      pageCount: props.pageCount || 1,
      pageRange: props.pageRange || 2,
      marginPage: props.marginPage || 1,
    })
  }

  handlePageClick(e) {
    this.props.onPageClick && this.props.onPageClick(e)
  }

  render() {
    const AuthElement = Authorization.Element
    if (this.props.isEmpty === true) {
      return null
    }
    return (
      <div className="footer-list">
        {this.props.type !== 'EVN' && (
          <AuthElement permission={[Permission.CONGVANDEN_QUANLY]}>
            <div
              className={classnames('note-issues', {
                'note-issues-none': this.state.pageCount,
              })}
            >
              <span className="name-note">Chú Thích</span>
              <ul className="list-note">
                <li className="note-item">
                  <span className="pt-icon-star pt-icon check-star"></span>Công
                  văn mới
                </li>
              </ul>
            </div>
          </AuthElement>
        )}
        {this.state.pageCount > 1 && (
          <Paginate
            forcePage={this.state.forcePage}
            initialPage={this.state.initialPage}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={this.state.marginPage}
            pageRangeDisplayed={this.state.pageRange}
            onPageChange={this.handlePageClick.bind(this)}
          />
        )}
      </div>
    )
  }
}

export default ListFooterOld
