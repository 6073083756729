import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './containers/Root'
import { unregister } from './registerServiceWorker'
import configureStore from './store/configureStore'
import { createBrowserHistory } from 'history'
import { routes } from './routes'
import { syncHistoryWithStore } from 'react-router-redux'
import ReactGA from 'react-ga'

export const store = configureStore()
const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, store)

// Add Google Analytics
ReactGA.initialize('UA-109825796-2')
ReactGA.pageview('/dang-nhap')

window.name = 'eoffice'

ReactDOM.render(
  <Root store={store} routes={routes} history={history} />,
  document.getElementById('root')
)
unregister()
