import React from 'react'
import { GridView } from '../../../components/common'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'

class TableView extends React.Component {
  constructor(props) {
    super(props)

    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    heading: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    header: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    isExporting: PropTypes.bool.isRequired,
    itemShape: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    type: PropTypes.number.isRequired,
    onExport: PropTypes.func.isRequired,
    isForChildUnit: PropTypes.bool.isRequired,
  }

  handleExport() {
    if (!this.props.type) {
      return
    }
    this.props.onExport && this.props.onExport(this.props.type)
  }

  render() {
    const {
      heading,
      page,
      header,
      isLoading,
      isEmpty,
      isError,
      isExporting,
      itemShape,
      data,
      filter,
      history,
      type,
    } = this.props
    return (
      <div className="table-container">
        <div className="heading-content">
          {!this.props.isForChildUnit && (
            <h2 className="heading-icon-17 main-heading">
              <span className="icon-left icon-double-file"></span>
              <span className="heading-text">{heading}</span>
              <div className="button-heading-right">
                <Button
                  rightIconName="pt-icon icon-In_So_Cong_Van"
                  className="btn-action btn-top-heading"
                  text="Xuất excel"
                  onClick={this.handleExport}
                  loading={isExporting}
                  disabled={isLoading || !data || data.length === 0}
                />
              </div>
            </h2>
          )}
        </div>
        <GridView
          classNames={'pt-table'}
          page={page}
          headers={header}
          itemShape={itemShape}
          data={data}
          isLoading={isLoading}
          isEmpty={isEmpty}
          filter={filter}
          history={history}
          isError={isError}
          type={type}
        />
      </div>
    )
  }
}

export default TableView
