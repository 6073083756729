import React, { memo } from 'react'
import csx from 'classnames'
import { get } from 'lodash'
import { WORK_PRIORITY } from 'constants/Enum'

const types = {
  [WORK_PRIORITY.THAP]: {
    color: 'text-color',
    text: 'Thấp',
  },
  [WORK_PRIORITY.BINH_THUONG]: {
    color: 'text-color normal',
    text: 'Bình thường',
  },
  [WORK_PRIORITY.CAO]: {
    color: 'text-color danger',
    text: 'Cao',
  },
}

const PrioritizeFlag = ({ type = 'default', showText = false, className }) => {
  if (['default', WORK_PRIORITY.NORMAL].includes(type)) {
    return null
  }

  return (
    <span
      className={csx('font-size-13', get(types, `${type}.color`), className)}
    >
      <i className={csx('icon-flag-line', 'mr5')} />
      {showText && get(types, `[${type}].text`)}
    </span>
  )
}

export default memo(PrioritizeFlag)
