import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../../helpers/'

class ListFilterNotAssigned extends Component {
  static propTypes = {
    units: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    dateUpdate: moment(),
    isOpen: false,
    workExpiringSoon: 0,
    workExpired: 0,
    filter: {
      quaHan: false,
      ganQuaHan: false,
      textSearch: '',
      ngayChiDao: {
        from: '',
        to: '',
      },
      hanGiaiQuyet: {
        from: '',
        to: '',
      },
      vaiTro: undefined,
      noiGuiId: undefined,
    },
    isActions: {
      quaHan: undefined,
      ganQuaHan: undefined,
    },
    isShowInput: false,
    dateFromToString: '',
    dateToToString: '',
    dealineFromToString: '',
    dealineToToString: '',
  }

  clearFilter(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      quaHan: false,
      ganQuaHan: false,
      textSearch: '',
      ngayChiDao: {
        from: '',
        to: '',
      },
      hanGiaiQuyet: {
        from: '',
        to: '',
      },
      vaiTro: undefined,
      noiGuiId: '',
      isActions: {
        quaHan: undefined,
        ganQuaHan: undefined,
      },
    }
    this.setState(
      {
        filter,
        dateFromToString: '',
        dateToToString: '',
        dealineFromToString: '',
        dealineToToString: '',
      },
      () => {
        const filter1 = {
          ...this.state.filter,
          ngayChiDao: {
            from: this.state.filter.ngayChiDao.from
              ? moment(this.state.filter.ngayChiDao.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.ngayChiDao.to
              ? moment(this.state.filter.ngayChiDao.to).format('YYYY-MM-DD')
              : '',
          },
          hanGiaiQuyet: {
            from: this.state.filter.hanGiaiQuyet.from
              ? moment(this.state.filter.hanGiaiQuyet.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.hanGiaiQuyet.to
              ? moment(this.state.filter.hanGiaiQuyet.to).format('YYYY-MM-DD')
              : '',
          },
        }
        this.props.handleResetData(filter1)
        this.props.actions.getNumberWorkPage()
      }
    )
  }

  componentWillReceiveProps(props) {
    const { filter, workExpiringSoon, workExpired } = props

    if (props) {
      this.setState({
        workExpiringSoon: workExpiringSoon || 0,
        workExpired: workExpired || 0,
      })
    }

    if (filter) {
      const { ngayChiDao, hanGiaiQuyet } = filter
      this.setState({
        filter: {
          quaHan: filter.quaHan || false,
          ganQuaHan: filter.ganQuaHan || false,
          textSearch: filter.textSearch || '',
          ngayChiDao: {
            from:
              ngayChiDao && ngayChiDao.from && ngayChiDao.from.trim().length > 0
                ? moment(ngayChiDao.from)
                : '',
            to:
              ngayChiDao && ngayChiDao.to && ngayChiDao.to.trim().length > 0
                ? moment(ngayChiDao.to)
                : '',
          },
          hanGiaiQuyet: {
            from:
              hanGiaiQuyet &&
              hanGiaiQuyet.from &&
              hanGiaiQuyet.from.trim().length > 0
                ? moment(hanGiaiQuyet.from)
                : '',
            to:
              hanGiaiQuyet &&
              hanGiaiQuyet.to &&
              hanGiaiQuyet.to.trim().length > 0
                ? moment(hanGiaiQuyet.to)
                : '',
          },
          vaiTro: filter.vaiTro || '',
          noiGui: filter.noiGui || '',
          noiGuiId: filter.noiGuiId || '',
        },
        dateFromToString:
          ngayChiDao && ngayChiDao.from && ngayChiDao.from.trim().length > 0
            ? moment(ngayChiDao.from)
            : '',
        dateToToString:
          ngayChiDao && ngayChiDao.to && ngayChiDao.to.trim().length > 0
            ? moment(ngayChiDao.to)
            : '',
        dealineFromToString:
          hanGiaiQuyet &&
          hanGiaiQuyet.from &&
          hanGiaiQuyet.from.trim().length > 0
            ? moment(hanGiaiQuyet.from)
            : '',
        dealineToToString:
          hanGiaiQuyet && hanGiaiQuyet.to && hanGiaiQuyet.to.trim().length > 0
            ? moment(hanGiaiQuyet.to)
            : '',
        isShowInput: filter.noiGuiId === '#' ? true : false,
      })
    }
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let isShowInput = false
    let { noiGuiId, noiGui } = this.state.filter

    if (
      (name === 'noiGuiId' && value === '#') ||
      (noiGuiId === '#' && name !== 'noiGuiId')
    ) {
      isShowInput = true
    }

    if (name === 'noiGuiId' && value !== '#') {
      noiGui = ''
    }

    this.setState({
      isShowInput,
      filter: {
        ...this.state.filter,
        noiGui,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayChiDao: {
        from: this.state.filter.ngayChiDao.from
          ? moment(this.state.filter.ngayChiDao.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayChiDao.to
          ? moment(this.state.filter.ngayChiDao.to).format('YYYY-MM-DD')
          : '',
      },
      hanGiaiQuyet: {
        from: this.state.filter.hanGiaiQuyet.from
          ? moment(this.state.filter.hanGiaiQuyet.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.hanGiaiQuyet.to
          ? moment(this.state.filter.hanGiaiQuyet.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  filterWork(type, e) {
    e.preventDefault()
    if (type === 'quaHan') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            quaHan: !this.state.filter.quaHan,
            ganQuaHan: false,
          },
        },
        () => {
          this.props.onSubmit && this.props.onSubmit(this.state.filter)
        }
      )
    } else if (type === 'ganQuaHan') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            quaHan: false,
            ganQuaHan: !this.state.filter.ganQuaHan,
          },
        },
        () => {
          this.props.onSubmit && this.props.onSubmit(this.state.filter)
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.workExpired !== this.props.workExpired) {
      this.setState({ workExpired: this.props.workExpired })
    }

    if (prevProps.workExpiringSoon !== this.props.workExpiringSoon) {
      this.setState({ workExpiringSoon: this.props.workExpiringSoon })
    }
    if (prevState.filter !== this.state.filter) {
      if (this.state.filter.quaHan === true) {
        this.setState({
          isActions: {
            quaHan: true,
            ganQuaHan: false,
          },
        })
      } else if (this.state.filter.ganQuaHan === true) {
        this.setState({
          isActions: {
            quaHan: false,
            ganQuaHan: true,
          },
        })
      } else if (
        this.state.filter.quaHan === false &&
        this.state.filter.ganQuaHan === false
      ) {
        this.setState({
          isActions: {
            quaHan: false,
            ganQuaHan: false,
          },
        })
      }
    }
  }

  render() {
    const check =
      (isNaN(this.state.workExpired) || this.state.workExpired < 1) &&
      (isNaN(this.state.workExpiringSoon) || this.state.workExpiringSoon < 1)
    return (
      <div
        className={classnames('search-container search-work', {
          'search-work-other': this.state.isShowInput,
        })}
      >
        <form className="form-search-basic" onSubmit={this.handleSubmit}>
          <div className="seach-form">
            <div className="row end-xs">
              {/* khi nào mở action get number thì xoá cái false && đi */}
              {false && !check && (
                <div className="col-xs-12 col-sm-3 col-md-3 statistic-work-left-content">
                  <div className="statistic-work">
                    <div className="row">
                      {!isNaN(this.state.workExpired) &&
                        this.state.workExpired > 0 && (
                          <div className="item">
                            <div
                              className={classnames('item-status', {
                                'active-filter-qh':
                                  this.state.isActions.quaHan === true,
                              })}
                              onClick={this.filterWork.bind(this, 'quaHan')}
                            >
                              <span className="number number-past">
                                {/*this.state.workExpired*/}
                              </span>
                              <span className="past-deadline">
                                Công việc <br /> quá hạn
                              </span>
                            </div>
                          </div>
                        )}

                      {!isNaN(this.state.workExpiringSoon) &&
                        this.state.workExpiringSoon > 0 && (
                          <div className="item">
                            <div
                              className={classnames('item-status', {
                                'active-filter-gqh':
                                  this.state.isActions.ganQuaHan === true,
                              })}
                              onClick={this.filterWork.bind(this, 'ganQuaHan')}
                            >
                              <span className="number number-near">
                                {/*this.state.workExpiringSoon*/}
                              </span>
                              <span className="near-deadline">
                                Công việc <br /> gần quá hạn
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
              <div className="col-xs-12 col-sm-9 col-md-9 search-header-right-content">
                <div className="search-header-group">
                  {/* <div className="search-filter-select-department">
                    <label className="pt-label-inline">
                      <span className="text-label"> Từ ngày </span>
                    </label>
                    <div className="group-datetime">
                      <div className="daterangepicker-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          isClearable={this.state.dateFromToString ? true : false}
                          selected={this.state.filter.ngayChiDao.from}
                          selectsStart
                          startDate={this.state.filter.ngayChiDao.from}
                          endDate={this.state.filter.ngayChiDao.to}
                          onChange={this.handleDateChange.bind(this, 'ngayChiDao', 'from', 'dateFromToString')}
                          value={this.state.dateFromToString}
                          onChangeRaw={(e) => this.handleChangeRaw('dateFromToString', e.target.value)}
                          onBlur={e => this.focousOut('ngayChiDao', 'from', 'dateFromToString', e.target.value)}
                          format="DD/MM/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Từ ngày"
                          maxDate={this.state.filter.ngayChiDao.to}
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px'
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport'
                            }
                          }}
                        />
                      </div>
                    </div>
                    <label className="pt-label-inline">
                      <span className="text-label"> Đến ngày </span>
                    </label>
                    <div className="group-datetime">
                      <div className="daterangepicker-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          isClearable={this.state.dateToToString ? true : false}
                          selected={this.state.filter.ngayChiDao.to}
                          selectsEnd
                          startDate={this.state.filter.ngayChiDao.from}
                          endDate={this.state.filter.ngayChiDao.to}
                          onChange={this.handleDateChange.bind(this, 'ngayChiDao', 'to', 'dateToToString')}
                          value={this.state.dateToToString}
                          onChangeRaw={(e) => this.handleChangeRaw('dateToToString', e.target.value)}
                          onBlur={e => this.focousOut('ngayChiDao', 'to', 'dateToToString', e.target.value)}
                          format="DD/MM/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Đến ngày"
                          minDate={this.state.filter.ngayChiDao.from}
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px'
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport'
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="search-keyword">
                    <input
                      autoFocus={false}
                      autoComplete="off"
                      name="textSearch"
                      className="search"
                      type="text"
                      value={this.state.filter.textSearch}
                      onChange={this.handleInputChange}
                      placeholder="Tìm số cv, trích yếu (hoặc số cv:trích yếu)..."
                    />
                  </div>
                  <div className="btn-reload-group">
                    <Button className="btn-search" type="submit">
                      <span className="pt-icon pt-icon-search"></span>
                    </Button>
                    <Button
                      type="button"
                      className="btn-reload"
                      onClick={this.clearFilter}
                    >
                      <span className="icon-Loading"></span>
                    </Button>
                    <Button
                      type="submit"
                      className="btn-toogle"
                      onClick={this.handleClick}
                    >
                      <span
                        className={classnames('pt-icon', {
                          'pt-icon-double-chevron-up': this.state.isOpen,
                          'pt-icon-double-chevron-down': !this.state.isOpen,
                        })}
                      ></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="search-advanced">
              <div className="row">
                <div className="group-datetime">
                  <div className="daterangepicker-group">
                    <label className="pt-label label-datepicker">
                      Ngày chỉ đạo
                    </label>
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={this.state.dateFromToString ? true : false}
                      selected={this.state.filter.ngayChiDao.from}
                      name="ngayChiDao"
                      selectsStart
                      startDate={this.state.filter.ngayChiDao.from}
                      endDate={this.state.filter.ngayChiDao.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'ngayChiDao',
                        'from',
                        'dateFromToString'
                      )}
                      value={this.state.dateFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateFromToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'ngayChiDao',
                          'from',
                          'dateFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.ngayChiDao.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={this.state.dateToToString ? true : false}
                      selected={this.state.filter.ngayChiDao.to}
                      name="ngayChiDao"
                      selectsEnd
                      startDate={this.state.filter.ngayChiDao.from}
                      endDate={this.state.filter.ngayChiDao.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'ngayChiDao',
                        'to',
                        'dateToToString'
                      )}
                      value={this.state.dateToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'ngayChiDao',
                          'to',
                          'dateToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.ngayChiDao.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="group-datetime">
                  <label className="pt-label label-datepicker">
                    Hạn giải quyết
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={
                        this.state.dealineFromToString ? true : false
                      }
                      selected={this.state.filter.hanGiaiQuyet.from}
                      name="hanGiaiQuyet"
                      selectsStart
                      startDate={this.state.filter.hanGiaiQuyet.from}
                      endDate={this.state.filter.hanGiaiQuyet.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'from',
                        'dealineFromToString'
                      )}
                      value={this.state.dealineFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw(
                          'dealineFromToString',
                          e.target.value
                        )
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'from',
                          'dealineFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.hanGiaiQuyet.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={this.state.dealineToToString ? true : false}
                      selected={this.state.filter.hanGiaiQuyet.to}
                      name="hanGiaiQuyet"
                      selectsEnd
                      startDate={this.state.filter.hanGiaiQuyet.from}
                      endDate={this.state.filter.hanGiaiQuyet.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'to',
                        'dealineToToString'
                      )}
                      value={this.state.dealineToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw(
                          'dealineToToString',
                          e.target.value
                        )
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'to',
                          'dealineToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.hanGiaiQuyet.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Vai trò
                    <div className="pt-select">
                      <select
                        name="vaiTro"
                        onChange={this.handleInputChange}
                        value={this.state.filter.vaiTro}
                      >
                        <option value="">Tất cả</option>
                        <option value={true}>Chủ trì</option>
                        <option value={false}>Phối hợp</option>
                      </select>
                    </div>
                  </label>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Cơ quan ban hành
                    <div className="pt-select">
                      <select
                        name="noiGuiId"
                        onChange={this.handleInputChange}
                        value={this.state.filter.noiGuiId}
                      >
                        <option value="">Tất cả</option>
                        {this.props.units.map((item, i) => (
                          <option key={i} value={item.donViId}>
                            {item.tenVietTat}
                          </option>
                        ))}
                        <option value="#">Khác</option>
                      </select>
                    </div>
                  </label>
                </div>
                {this.state.isShowInput && (
                  <div className="input-element-other">
                    <label className="pt-label">
                      <span className="emty-nbsp">&nbsp;</span>
                      <div className="pt-form-content">
                        <input
                          name="noiGui"
                          className="pt-input"
                          onChange={this.handleInputChange}
                          value={
                            this.state.filter && this.state.filter.noiGui
                              ? this.state.filter.noiGui
                              : ''
                          }
                          placeholder="Nhập cơ quan ban hành khác..."
                          type="text"
                          dir="auto"
                        />
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilterNotAssigned
