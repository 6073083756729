import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { InputFormSignNumberProject } from '../../components/SignNumber'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Intent } from '@blueprintjs/core'
import * as MESSAGE from '../../constants/MessageForm'
import { get, isArray } from 'lodash'
import * as Types from '../../constants/Api'

class InputSignNumberProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickCancel = this.handleClickCancel.bind(this)
    this.handleChangeProject = this.handleChangeProject.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
    membersProject: PropTypes.object.isRequired,
    signNumberProject: PropTypes.object.isRequired,
    unitItem: PropTypes.object,
  }

  state = {
    isUpdate: false,
    signNumberProjectId: null,
    signNumberProject: null,
    isLoadingData: {
      duAnId: true,
      tenThanhPho: true,
      signNumberProject: true,
      members: false,
    },
    isLoadingChangeProject: false,
    isSubmitFail: false,
    contentFileProgress: [0],
    attachFilesProgress: [0],
    stepsFromEdit: null,
    stepsIsAgreed: null,
    membersIsAgreed: null,
    fileTrinhKy: null,
  }

  handleClickCancel = () => {
    this.props.history && this.props.history.push('/ky-so/du-an/danh-sach')
  }

  handleChangeProject = async id => {
    if (!id) {
      return
    }
    await this.setState({
      isLoadingChangeProject: true,
      stepsFromEdit: null,
    })
    await this.props.actions.signNumberProjectGetListMember(id)
    await this.setState({ isLoadingChangeProject: false })
  }

  getUploadProgress = (name, i) => progress => {
    let state = this.state
    if (!isNaN(i) && name) {
      state[name][i] = Math.floor((progress.loaded * 100) / progress.total)
    }
    this.setState(state)
  }

  handleSubmit = async (data, isValidData, message) => {
    if (!isValidData) {
      if (message) {
        return this.props.actions.commonAddToasterMessage({
          message: message,
          intent: Intent.WARNING,
        })
      }
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    await this.setState({ isSubmitFail: false })
    let contentFile = data.file_noi_dung
    let attachFiles =
      data.file_tham_khao && data.file_tham_khao.filter(s => s !== undefined)
    let attachFilesProgress = []

    if (data.files && data.files.contentFile) {
      try {
        await this.props.actions
          .fileUpload(
            data.files.contentFile,
            this.getUploadProgress('contentFileProgress', 0)
          )
          .then(res => {
            contentFile = {
              file_id: res.payload.data.result[0].id,
              kieu_file: res.payload.data.result[0].type,
              ten_file: res.payload.data.result[0].originalName,
              url: res.payload.data.result[0].path,
              kich_thuoc: res.payload.data.result[0].length,
            }
          })
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }

    delete data.files.contentFile

    if (data.files && data.files.attachFiles && data.files.attachFiles.length) {
      attachFilesProgress = Array(data.files.attachFiles.length).fill(0)
      await this.setState({ attachFilesProgress })
      try {
        await Promise.all(
          data.files.attachFiles.map(async (item, i) => {
            const data = await this.props.actions.fileUpload(
              item,
              this.getUploadProgress('attachFilesProgress', i)
            )
            return attachFiles.push({
              file_id: data.payload.data.result[0].id,
              kieu_file: data.payload.data.result[0].type,
              ten_file: data.payload.data.result[0].originalName,
              url: data.payload.data.result[0].path,
              kich_thuoc: data.payload.data.result[0].length,
            })
          })
        )
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }

    delete data.files

    let dataRequest = {
      ...data,
      file_noi_dung: contentFile,
      file_tham_khao: attachFiles,
    }

    if (!this.state.isUpdate) {
      this.props.actions
        .signNumberProjectCreate(dataRequest)
        .then(res => {
          if (res.error) {
            this.setState({ isSubmitFail: true })
            let error = MESSAGE.TOATS_MESSAGE_FAIL
            if (
              res.error &&
              res.error.response &&
              res.error.response.data &&
              res.error.response.data.message
            ) {
              error = res.error.response.data.message
            }
            return this.props.actions.commonAddToasterMessage({
              message: error,
              intent: Intent.DANGER,
            })
          }
          this.setState({ signNumberProject: null })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.history.push('/ky-so/du-an/danh-sach')
        })
        .catch(err => {
          this.setState({ isSubmitFail: true })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        })
    } else {
      this.props.actions
        .signNumberProjectUpdate(this.state.signNumberProjectId, dataRequest)
        .then(res => {
          if (res.error) {
            this.setState({ isSubmitFail: true })
            let error = MESSAGE.TOATS_MESSAGE_FAIL
            if (
              res.error &&
              res.error.response &&
              res.error.response.data &&
              res.error.response.data.message
            ) {
              error = res.error.response.data.message
            }
            return this.props.actions.commonAddToasterMessage({
              message: error,
              intent: Intent.DANGER,
            })
          }
          this.setState({ signNumberProject: null })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.history.goBack()
        })
        .catch(err => {
          this.setState({ isSubmitFail: true })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        })
    }
  }

  componentWillMount() {
    let state = this.state
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      state.signNumberProjectId = this.props.match.params.id
      state.isUpdate = true
    } else {
      state.isLoadingData.signNumberProject = false
    }
    this.setState(state)
  }

  async componentDidMount() {
    // Thông tin đơn vị
    let state = this.state
    if (this.props.auth && this.props.auth.mainUnitId) {
      await this.props.actions
        .getCommonFetchUnitItem(this.props.auth.mainUnitId)
        .finally(res => {
          state.isLoadingData.tenThanhPho = false
        })
    } else {
      state.isLoadingData.tenThanhPho = false
    }
    // TODO: gọi api get danh sách dự án theo chucDanhId của người tạo
    // => gọi api get danh sách thành viên trong dự án đầu tiên
    if (this.props.auth && this.props.auth.roleId) {
      await this.props.actions
        .projectsListByMemberIdGet(this.props.auth.roleId)
        .finally(async res => {
          if (
            this.props.projects &&
            this.props.projects.list &&
            this.props.projects.list.items &&
            this.props.projects.list.items[0] &&
            !this.state.isUpdate &&
            !this.state.signNumberProjectId
          ) {
            await this.props.actions
              .signNumberProjectGetListMember(
                this.props.projects.list.items[0].duAnId
              )
              .finally(res => {
                state.isLoadingData.members = false
              })
          }
          state.isLoadingData.duAnId = false
        })
    } else {
      state.isLoadingData.duAnId = false
    }
    await this.setState(state)

    // Gọi api get chi tiết ký số dự án khi chỉnh sửa
    if (this.state.isUpdate && this.state.signNumberProjectId) {
      let state = this.state
      await this.props.actions
        .signNumberProjectGetDetailUpdate(this.state.signNumberProjectId)
        .then(async res => {
          if (
            res &&
            res.payload &&
            res.payload.status === 200 &&
            this.props.signNumberProject &&
            this.props.signNumberProject.item
          ) {
            let isSuccess = false
            await this.props.actions
              .signNumberProjectGetListMember(
                this.props.signNumberProject.item.duAnId
              )
              .then(resSub => {
                if (resSub && resSub.payload && resSub.payload.status === 200) {
                  isSuccess = true
                }
                state.isLoadingData.members = false
              })
            if (isSuccess) {
              if (
                this.props.signNumberProject &&
                this.props.signNumberProject.item
              ) {
                state.signNumberProject = this.props.signNumberProject.item
                const { dsBuoc } = this.props.signNumberProject.item
                if (dsBuoc && isArray(dsBuoc)) {
                  state.signNumberProject = {
                    ...state.signNumberProject,
                    stepsFromEdit: dsBuoc,
                  }
                  state.stepsFromEdit = dsBuoc
                }
              }
            }
          }
        })
      state.isLoadingData.members = false
      state.isLoadingData.signNumberProject = false
      this.setState(state)
    }
  }

  handlePreview = async data => {
    const { postFileTrinhKySoDuAn } = this.props.actions
    let contentFile = data.file_noi_dung
    let attachFiles =
      data.file_tham_khao && data.file_tham_khao.filter(s => s !== undefined)
    const dataRequest = {
      ...data,
      file_noi_dung: contentFile,
      file_tham_khao: attachFiles,
    }
    let response = null
    let fileTrinhKy = null
    response = await postFileTrinhKySoDuAn({
      data: JSON.stringify(dataRequest),
      file: data.files.contentFile,
    })

    if (get(response, 'payload.data')) {
      fileTrinhKy = get(response, 'payload.data')
    }

    this.setState({
      fileTrinhKy,
    })

    return true
  }

  render() {
    const { fileTrinhKy } = this.state
    // Không được sửa văn bản đã duyệt
    if (
      this.state.isUpdate &&
      this.state.signNumberProject &&
      this.state.signNumberProject.tinhTrang === Types.PASSED
    ) {
      return null
    }

    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={
                this.state.isUpdate
                  ? 'Sửa tờ trình, phiếu trình'
                  : 'Tạo tờ trình, phiếu trình'
              }
              iconPage="icon-Tao_Du_An"
            />
            <InputFormSignNumberProject
              onClickCancel={this.handleClickCancel}
              onChangeProject={this.handleChangeProject}
              onSubmit={this.handleSubmit}
              isLoadingData={this.state.isLoadingData}
              isLoadingChangeProject={this.state.isLoadingChangeProject}
              projects={this.props.projects.list.items}
              membersProject={this.props.membersProject.items}
              cityName={this.props.unitItem && this.props.unitItem.thanhPho}
              isSubmitFail={this.state.isSubmitFail}
              contentFileProgress={this.state.contentFileProgress}
              attachFilesProgress={this.state.attachFilesProgress}
              signNumberProject={this.state.signNumberProject}
              stepsFromEdit={this.state.stepsFromEdit}
              isUpdate={this.state.isUpdate}
              handlePreview={this.handlePreview}
              fileTrinhKy={fileTrinhKy}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  projects: {
    ...state.projects,
    list: {
      ...state.projects.list,
      items: state.projects.list.items.map(id => state.entities.projects[id]),
    },
  },

  membersProject: {
    ...state.signNumberProjects.listMemberByProject,
    items: state.signNumberProjects.listMemberByProject.items.map(
      item => state.entities.signNumberProjectMembers[item]
    ),
  },

  signNumberProject: {
    ...state.signNumberProjects,
    item: state.signNumberProjects.detailId
      ? state.entities.signNumberProjects[state.signNumberProjects.detailId]
      : {},
  },

  unitItem: state.common.unitItem,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputSignNumberProjectPage)
