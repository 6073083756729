import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import Feedback from './Feedback'
import PropTypes from 'prop-types'
import { Intent, Button } from '@blueprintjs/core'
import 'moment/locale/vi'
import moment from 'moment'
import { LoadingItem } from '../../components/common/'
import * as MESSAGE from '../../constants/MessageForm'
import DetailStatusProject from './DetailStatusProject'
import UpdateHistory from './UpdateHistory'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import DatePicker from 'react-datepicker'

const VNPT = 1
const VIETTEL = 0
class DetailBoxActionProject extends Component {
  constructor(props) {
    super(props)
    this.sendFeedback = this.sendFeedback.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickAgreeBySIM = this.handleClickAgreeBySIM.bind(this)
    this.handleClickAgreeByTOKEN = this.handleClickAgreeByTOKEN.bind(this)
    this.handleClickDisagree = this.handleClickDisagree.bind(this)
    this.handleClickReserve = this.handleClickReserve.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.sendProgression = this.sendProgression.bind(this)
    this.handleUpdateFeedback = this.handleUpdateFeedback.bind(this)
    this.updateFeedback = this.updateFeedback.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    feedbacks: PropTypes.array,
    signNumber: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    isLoadingData: PropTypes.object.isRequired,
  }

  state = {
    error: {},
    feedbackContent: '',
    chucDanhId: null,
    signNumberId: null,
    isLoading: {
      agreeBySIM: false,
      agreeBySIMVNPT: false,
      agreeByTOKEN: false,
      disagree: false,
      sendFeedback: false,
      progression: false,
      checkProgression: false,
      reverse: false,
    },
    isShowButton: true,
    soCongVan: '',
    ngayCongVan: moment(),
    truongKy: null,
    loaiKy: null,
    feedbackId: null,
    hrefToken: null,
    isDisagree: false,
    isAgree: false,
    isNotAgree: false,
    isReverse: false,
    isProgression: false,
    isSubmitFeedbackSuccess: null,
    ngayCongVanToString: moment()
      .format('YYYY-MM-DD')
      .toString()
      .replace(/-/g, ''),
    dateToToString: moment().format('DD/MM/YYYY'),
    buoc: null,
    isCreator: false,
  }

  handleDateChange = e => {
    const error = this.state.error
    delete error.ngayCongVan

    let ngayCongVan = undefined
    let ngayCongVanToString = ''
    let dateToToString = ''
    let hrefProgression = this.state.hrefProgression

    if (e) {
      ngayCongVan = e
      ngayCongVanToString = moment(e)
        .format('YYYY-MM-DD')
        .toString()
        .replace(/-/g, '')
      dateToToString = moment(e).format('DD/MM/YYYY')
      hrefProgression = `eofficecpc:id_vb=${this.state.signNumberId};id_nv=${this.state.chucDanhId};loaiky=capso;socv=${this.state.soCongVan};ngaycv=${ngayCongVanToString}`
    }

    this.setState({
      ngayCongVan,
      ngayCongVanToString,
      error,
      dateToToString,
      hrefProgression,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleInputChange = e => {
    const name = e.target.name
    let value = e.target.value
    const error = this.state.error
    let hrefProgression = this.state.hrefProgression
    delete error[name]

    if (name === 'soCongVan') {
      hrefProgression = `eofficecpc:id_vb=${this.state.signNumberId};id_nv=${this.state.chucDanhId};loaiky=capso;socv=${value};ngaycv=${this.state.ngayCongVanToString}`
    }

    this.setState({
      [e.target.name]: value,
      hrefProgression,
      error,
    })
  }

  updateFeedback = params => {
    if (!params) {
      return
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    this.props.actions
      .signNumberProjectFeedbackUpdate(this.state.signNumberId, params.gopYId, {
        gop_y_id: params.gopYId,
        noi_dung: params.noiDung,
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            feedbackId: null,
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          feedbackId: null,
          isLoading: {
            ...this.state.isLoading,
            sendFeedback: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'GopY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  sendFeedback(type = null) {
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    if (this.state.feedbackContent.length === 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          sendFeedback: false,
        },
        isSubmitFeedbackSuccess: false,
      })
      if (type !== 'NotShowMessage') {
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'GopY',
            isSuccess: false,
            message: MESSAGE.TEXT_MESSAGE_GOPY,
            intent: Intent.WARNING,
          })
        )
      }
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    this.props.actions
      .signNumberProjectFeedbackCreate(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
            isSubmitFeedbackSuccess: false,
          })
          if (type !== 'NotShowMessage') {
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GopY',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            )
          }
        }

        this.setState({
          isReverse: false,
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            sendFeedback: false,
          },
          isSubmitFeedbackSuccess: true,
        })

        if (type !== 'NotShowMessage') {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        }
        return (
          this.props.onSubmit &&
          this.props.onSubmit({ title: 'GopY', isSuccess: true })
        )
      })
  }

  sendProgression = async e => {
    let error = {}
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        progression: true,
      },
    })

    if (
      !this.state.soCongVan ||
      this.state.soCongVan.toString().trim().length === 0
    ) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.soCongVan &&
      (isNaN(Number(this.state.soCongVan)) || Number(this.state.soCongVan) <= 0)
    ) {
      error.soCongVan = MESSAGE.TEXT_GIATRI_LONHON_0
    }
    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error })
    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          progression: false,
        },
      })
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'CapSo',
          isSuccess: false,
          message: MESSAGE.TOATS_MESSAGE_WARNING,
          intent: Intent.WARNING,
        })
      )
    }

    await this.setState(
      {
        isLoading: {
          ...this.state.isLoading,
          progression: true,
        },
      },
      () => {
        this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
      }
    )
    return (window.location.href = this.state.hrefProgression)
  }

  handleClickAgreeBySIM = async (type = VIETTEL) => {
    if (type === VIETTEL) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          agreeBySIM: true,
        },
      })
    } else {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          agreeBySIMVNPT: true,
        },
      })
    }
    this.props.actions
      .signNumberProjectAgreeBySIM(this.state.signNumberId, type, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          if (type === VIETTEL) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIM: false,
              },
            })
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIMVNPT: false,
              },
            })
          }
          let error = MESSAGE.TOATS_MESSAGE_FAIL
          if (
            res.error &&
            res.error.response &&
            res.error.response.data &&
            res.error.response.data.message
          ) {
            error = res.error.response.data.message
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }

        if (type === VIETTEL) {
          this.setState({
            isReverse: false,
            feedbackContent: '',
            isLoading: {
              ...this.state.isLoading,
              agreeBySIM: false,
            },
          })
        } else {
          this.setState({
            isReverse: false,
            feedbackContent: '',
            isLoading: {
              ...this.state.isLoading,
              agreeBySIMVNPT: false,
            },
          })
        }
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKySoBangSIM',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleClickAgreeByTOKEN = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        agreeByTOKEN: true,
      },
    })

    // Gui gop y:
    if (this.state.feedbackContent.length > 0) {
      this.sendFeedback('NotShowMessage', () => {
        if (
          !this.state.isSubmitFeedbackSuccess ||
          this.state.isSubmitFeedbackSuccess === false
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agreeByTOKEN: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangTOKEN',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }
      })
    }

    this.setState(
      {
        isLoading: {
          ...this.state.isLoading,
          agreeByTOKEN: true,
        },
      },
      () => {
        this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
      }
    )
    window.location.href = this.state.hrefToken
  }

  handleClickDisagree = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        disagree: true,
      },
    })

    // Validate
    if (this.state.feedbackContent.length === 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          disagree: false,
        },
      })
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'KhongDongY',
          isSuccess: false,
          message: MESSAGE.TEXT_MESSAGE_DISSAGREE_SIGNNUMBER,
          intent: Intent.WARNING,
        })
      )
    }

    this.props.actions
      .signNumberProjectDisagree(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              disagree: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'KhongDongY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            disagree: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'KhongDongY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleClickReserve = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        reverse: true,
      },
    })

    this.props.actions
      .signNumberProjectReverse(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              reverse: false,
            },
          })
          let error = MESSAGE.TOATS_MESSAGE_FAIL
          if (
            res.error &&
            res.error.response &&
            res.error.response.data &&
            res.error.response.data.message
          ) {
            error = res.error.response.data.message
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'BaoLuu',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            reverse: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKySoBangSIM',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleUpdateFeedback = (feedbackId = null) => {
    this.setState({ feedbackId: feedbackId })
  }

  componentDidMount() {
    let state = this.state
    state.chucDanhId = this.props.auth ? this.props.auth.roleId : null
    state.ngayCongVan = moment()
    state.ngayCongVanToString = moment()
      .format('YYYY-MM-DD')
      .toString()
      .replace(/-/g, '')

    if (this.props.signNumberProject) {
      if (
        this.props.signNumberProject.nguoiLap &&
        this.props.signNumberProject.nguoiLap.chucDanhId
      ) {
        state.isCreator =
          this.props.signNumberProject.nguoiLap.chucDanhId === state.chucDanhId
      }
      state.signNumberId = this.props.signNumberProject.kySoId
      state.isShowButton = false
      let { buoc, tinhTrang, soVanBan, ngayCongVan, dsThanhVien } =
        this.props.signNumberProject
      if (
        tinhTrang === 6 &&
        soVanBan &&
        !isNaN(Number(soVanBan)) &&
        Number(soVanBan) > 0
      ) {
        state.soCongVan = soVanBan
      }
      if (
        tinhTrang === 6 &&
        ngayCongVan &&
        Tool.Utils.isValidDate(moment(ngayCongVan).format('DD/MM/YYYY'))
      ) {
        state.ngayCongVan = moment(ngayCongVan)
        state.ngayCongVanToString = moment(ngayCongVan)
          .format('YYYY-MM-DD')
          .toString()
          .replace(/-/g, '')
        state.dateToToString = moment(ngayCongVan).format('DD/MM/YYYY')
      }

      let thanhVien = undefined
      if (dsThanhVien) {
        // thanhVien = dsThanhVien.find(item => item.chucDanhId === state.chucDanhId && item.buoc < buoc);
        // thanhVien = dsThanhVien.find(item => item.chucDanhId === state.chucDanhId && item.buoc === buoc) || thanhVien;
        dsThanhVien.forEach(item => {
          if (item.chucDanhId === state.chucDanhId && item.buoc < buoc) {
            return (thanhVien = item)
          }
        })
        dsThanhVien.forEach(item => {
          if (item.chucDanhId === state.chucDanhId && item.buoc === buoc) {
            return (thanhVien = item)
          }
        })
      }
      if (thanhVien) {
        switch (thanhVien.hinhThuc) {
          case Types.HTK_KY_CO_DAU:
            state.loaiky = 'codau'
            break

          case Types.HTK_KY_KHONG_DAU:
            state.loaiky = 'khongdau'
            break

          case Types.HTK_KY_THANH_VIEN:
            state.loaiky = 'thanhvien'
            break

          default:
            state.loaiky = 'kynhay'
        }
        state.buoc = thanhVien.buoc
        state.truongKy = thanhVien.truongKy
        const trangKy = thanhVien.trangKy ? thanhVien.trangKy : 0
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};loaiky=${state.loaiky};noiky=${state.truongKy};page=${trangKy}`

        // Nêú là thành viên trong bước
        // + hoặc là tinhTrang !== 1
        // hoặc là chính là người không đồng ý
        // được ký duyệt thì hiển thị button
        if (
          tinhTrang !== Types.DISAGREE ||
          (tinhTrang === Types.DISAGREE &&
            thanhVien.trangThai === Types.DISAGREE)
        ) {
          state.isShowButton = true
        }
        // Nếu trạng thái ký của user === 0 thì enabled 3 button/cấp số
        if (thanhVien.trangThai === Types.NOT_YET_AGREE) {
          state.isNotAgree = true
        }
        // Nếu trạng thái ký của user === 1 thì enabled 2 button ký duyệt
        if (thanhVien.trangThai === Types.DISAGREE) {
          state.isDisagree = true
        }
        // Nếu trạng thái ký của user === 2 thì disabled 3 button
        if (thanhVien.trangThai === Types.AGREED) {
          state.isAgree = true
        }
        // Nếu trạng thái ký của user === 3 thì disabled 3 button
        if (thanhVien.trangThai === Types.REVERSE) {
          state.isReverse = true
        }
        // Nếu hình thức ký là cấp số thì hiển thị form cấp s ố thay cho 2 button ký
        if (thanhVien.hinhThuc === Types.HTK_CAP_SO) {
          state.isProgression = true
          state.hrefProgression = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};loaiky=capso;socv=${state.soCongVan};ngaycv=${state.ngayCongVanToString}`
        }
      }
      this.setState(state)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.signNumberProject !== this.props.signNumberProject &&
      this.props.signNumberProject
    ) {
      const state = this.state
      state.error = {}
      state.signNumberId = this.props.signNumberProject.kySoId
      state.isShowButton = false
      state.hrefToken = null
      state.isDisagree = false
      state.isAgree = false
      state.isNotAgree = false
      state.isProgression = false
      state.loaiky = null
      state.truongKy = null
      state.feedbackId = null
      state.feedbackContent = ''
      state.soCongVan = ''
      state.ngayCongVan = moment()
      state.ngayCongVanToString = moment()
        .format('YYYY-MM-DD')
        .toString()
        .replace(/-/g, '')
      state.dateToToString = moment().format('DD/MM/YYYY')
      state.isSubmitFeedbackSuccess = null

      if (
        this.props.signNumberProject.nguoiLap &&
        this.props.signNumberProject.nguoiLap.chucDanhId
      ) {
        state.isCreator =
          this.props.signNumberProject.nguoiLap.chucDanhId === state.chucDanhId
      }
      let { buoc, tinhTrang, soVanBan, ngayCongVan, dsThanhVien } =
        this.props.signNumberProject
      if (
        tinhTrang === 6 &&
        soVanBan &&
        !isNaN(Number(soVanBan)) &&
        Number(soVanBan) > 0
      ) {
        state.soCongVan = soVanBan
      }
      if (
        tinhTrang === 6 &&
        ngayCongVan &&
        Tool.Utils.isValidDate(moment(ngayCongVan).format('DD/MM/YYYY'))
      ) {
        state.ngayCongVan = moment(ngayCongVan)
        state.ngayCongVanToString = moment(ngayCongVan)
          .format('YYYY-MM-DD')
          .toString()
          .replace(/-/g, '')
        state.dateToToString = moment(ngayCongVan).format('DD/MM/YYYY')
      }

      let thanhVien = undefined
      if (dsThanhVien) {
        // thanhVien = dsThanhVien.find(item => item.chucDanhId === state.chucDanhId && item.buoc < buoc);
        // thanhVien = dsThanhVien.find(item => item.chucDanhId === state.chucDanhId && item.buoc === buoc) || thanhVien;
        dsThanhVien.forEach(item => {
          if (item.chucDanhId === state.chucDanhId && item.buoc < buoc) {
            return (thanhVien = item)
          }
        })
        dsThanhVien.forEach(item => {
          if (item.chucDanhId === state.chucDanhId && item.buoc === buoc) {
            return (thanhVien = item)
          }
        })
      }
      if (thanhVien) {
        switch (thanhVien.hinhThuc) {
          case Types.HTK_KY_CO_DAU:
            state.loaiky = 'codau'
            break

          case Types.HTK_KY_KHONG_DAU:
            state.loaiky = 'khongdau'
            break

          case Types.HTK_KY_THANH_VIEN:
            state.loaiky = 'thanhvien'
            break

          default:
            state.loaiky = 'kynhay'
        }
        state.buoc = thanhVien.buoc
        state.truongKy = thanhVien.truongKy
        const trangKy = thanhVien.trangKy ? thanhVien.trangKy : 0
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};loaiky=${state.loaiky};noiky=${state.truongKy};page=${trangKy}`

        // Nêú là thành viên trong bước
        // + hoặc là tinhTrang !== 1
        // hoặc là chính là người không đồng ý
        // được ký duyệt thì hiển thị button
        if (
          tinhTrang !== Types.DISAGREE ||
          (tinhTrang === Types.DISAGREE &&
            thanhVien.trangThai === Types.DISAGREE)
        ) {
          state.isShowButton = true
        }
        // Nếu trạng thái ký của user === 0 thì enabled 3 button/cấp số
        if (thanhVien.trangThai === Types.NOT_YET_AGREE) {
          state.isNotAgree = true
        }
        // Nếu trạng thái ký của user === 1 thì enabled 2 button ký duyệt
        if (thanhVien.trangThai === Types.DISAGREE) {
          state.isDisagree = true
        }
        // Nếu trạng thái ký của user === 2 thì disabled 3 button
        if (thanhVien.trangThai === Types.AGREED) {
          state.isAgree = true
        }
        if (thanhVien.trangThai === Types.REVERSE) {
          state.isReverse = true
        }
        // Nếu trạng thái ký của user === 3 thì disabled 3 button
        // Nếu hình thức ký là cấp số thì hiển thị form cấp s ố thay cho 2 button ký
        if (thanhVien.hinhThuc === Types.HTK_CAP_SO) {
          state.isProgression = true
          state.hrefProgression = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};loaiky=capso;socv=${state.soCongVan};ngaycv=${state.ngayCongVanToString}`
        }
      }
      this.setState(state)
    }
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''

    let extension = null
    const fileNoiDungKhongDau =
      this.props.signNumberProject &&
      this.props.signNumberProject.fileNoiDungKhongDau
    if (fileNoiDungKhongDau && fileNoiDungKhongDau.fileName) {
      extension = fileNoiDungKhongDau.fileName
        .slice(
          ((this.props.signNumberProject.fileNoiDungKhongDau.fileName.lastIndexOf(
            '.'
          ) -
            1) >>>
            0) +
            2
        )
        .toLowerCase()
    }

    let feedbacks = []
    // Người lập được xem tất cả góp ý
    // Khi tình trạng đã duyệt đc xem tất cả
    if (
      (this.props.feedbacks && this.state.isCreator) ||
      (this.props.signNumberProject &&
        this.props.signNumberProject.tinhTrang === Types.PASSED)
    ) {
      feedbacks = this.props.feedbacks
    }

    // Người ký chỉ được xem góp ý thành viên bước đã ký và của mình ở bước đang ký
    if (
      this.props.signNumberProject &&
      this.props.feedbacks &&
      !this.state.isCreator &&
      this.state.isShowButton &&
      this.props.signNumberProject.tinhTrang !== Types.PASSED
    ) {
      feedbacks = this.props.feedbacks.filter(item => {
        const chucDanhId = item.nguoiGopY && item.nguoiGopY.chucDanhId
        return (
          item.buoc < this.state.buoc ||
          (item.buoc === this.state.buoc &&
            this.state.chucDanhId === chucDanhId) ||
          item.buoc < this.props.signNumberProject.buoc
        )
      })
    }
    return this.props.isLoadingData &&
      this.props.isLoadingData.signNumberProject === true ? (
      <LoadingItem />
    ) : (
      <div className="list-action list-action-sign-number">
        <div className="title-divid">
          <p className="title">Thực Hiện</p>
          <button className="guichat" onClick={this.props.sendToChat}>
            <i className="icon-bubble-cuoc-tro-chuyen"></i>
            <span className="action-name">Gửi Chat</span>
          </button>
        </div>
        <span className="icon-More show-th-mobile" tabIndex="0"></span>
        <div className="detail-info-container section-box-info">
          <div className="list-items">
            {this.props.signNumberProject &&
              this.props.signNumberProject.trichYeu && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Trích yếu:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content textarea-content">
                      {this.props.signNumberProject &&
                        this.props.signNumberProject.trichYeu}
                    </span>
                  </div>
                </div>
              )}

            {this.props.signNumberProject &&
              this.props.signNumberProject.noiDungTrinh && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Nội dung trình:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content textarea-content">
                      {this.props.signNumberProject &&
                        this.props.signNumberProject.noiDungTrinh}
                    </span>
                  </div>
                </div>
              )}

            {this.props.signNumberProject &&
              this.props.signNumberProject.fileThamKhao &&
              this.props.signNumberProject.fileThamKhao.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">
                      File tham khảo (
                      {this.props.signNumberProject.fileThamKhao.length}):
                    </span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.signNumberProject.fileThamKhao.map(
                          (value, item) => {
                            let extensionTK = null
                            const fileThamKhao = value.fileName
                            if (fileThamKhao) {
                              extensionTK = fileThamKhao
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  {!value.migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extensionTK
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    >
                                      {value.tenFile} -{' '}
                                      {Tool.Utils.getFileSize(value.kichThuoc)}
                                    </a>
                                  )}
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </div>

        {/* Form gop y*/}
        {this.props.signNumberProject &&
          this.props.signNumberProject.tinhTrang !== Types.PASSED &&
          this.state.isShowButton &&
          !this.state.isAgree &&
          !this.state.isProgression &&
          this.state.buoc === this.props.signNumberProject.buoc && (
            // !this.state.isReverse &&
            <div className="form-container">
              <div className="box-signnumber">
                <div className="form-input border-top">
                  <div className="pt-form-group">
                    <div className="row">
                      <label className="pt-label col-xs-12 pt0 mb10">
                        Góp ý/Ghi chú
                      </label>
                      <div className="pt-form-content col-xs-12">
                        <textarea
                          rows="4"
                          className="pt-input pt-fill"
                          placeholder="Nhập nội dung..."
                          dir="auto"
                          name="feedbackContent"
                          onChange={this.handleInputChange}
                          value={this.state.feedbackContent}
                          disabled={
                            this.state.isAgree === true ||
                            this.state.buoc !==
                              this.props.signNumberProject.buoc
                            // this.state.isReverse === true
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/*Button ky duyet*/}
        {this.props.signNumberProject &&
          this.props.signNumberProject.tinhTrang !== Types.PASSED &&
          this.state.isShowButton &&
          !this.state.isProgression && (
            <div className="box-actions-right">
              <div className="box-action">
                <div className="group-button-action">
                  {this.props.auth &&
                    this.props.auth.user &&
                    this.props.auth.user.suDungUsbToken && (
                      <div className="button-content">
                        <Button
                          className="pt-button btn-action-sign-number btn-action-blue display-flex"
                          onClick={this.handleClickAgreeByTOKEN}
                          disabled={
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined ||
                            this.state.isAgree === true ||
                            // this.state.isReverse === true ||
                            (this.state.buoc !==
                              this.props.signNumberProject.buoc &&
                              this.props.signNumberProject.tinhTrang === 6)
                          }
                        >
                          <span style={{ display: 'none' }}>
                            {this.state.hrefToken}
                          </span>
                          Đồng ý ký số Token
                        </Button>
                      </div>
                    )}

                  <div className="button-content">
                    {this.props.auth &&
                      this.props.auth.user &&
                      this.props.auth.user.soDienThoaiKySo && (
                        <div className="button-content-2">
                          <Button
                            className="pt-button btn-action-sign-number btn-action-blue display-flex"
                            onClick={this.handleClickAgreeBySIM.bind(
                              this,
                              VIETTEL
                            )}
                            loading={this.state.isLoading.agreeBySIM}
                            disabled={
                              Object.values(this.props.isLoadingData).find(
                                item => item === true
                              ) !== undefined ||
                              Object.values(this.state.isLoading).find(
                                item => item === true
                              ) !== undefined ||
                              this.state.isAgree === true ||
                              // this.state.isReverse === true ||
                              (this.state.buoc !==
                                this.props.signNumberProject.buoc &&
                                this.props.signNumberProject.tinhTrang === 6)
                            }
                          >
                            {this.props.auth.user.soDienThoaiKySoVnpt ? (
                              <p> Đồng ý ký số VIETTEL</p>
                            ) : (
                              <p>
                                Đồng ý ký số SIM
                                {/*<span class="pt-icon icon-DongYKysoSim"></span>*/}
                              </p>
                            )}
                          </Button>
                        </div>
                      )}
                    {this.props.auth &&
                      this.props.auth.user &&
                      this.props.auth.user.soDienThoaiKySoVnpt && (
                        <div className="button-content-2">
                          <Button
                            className="pt-button btn-action-sign-number btn-action-blue display-flex"
                            onClick={this.handleClickAgreeBySIM.bind(
                              this,
                              VNPT
                            )}
                            loading={this.state.isLoading.agreeBySIMVNPT}
                            disabled={
                              Object.values(this.props.isLoadingData).find(
                                item => item === true
                              ) !== undefined ||
                              Object.values(this.state.isLoading).find(
                                item => item === true
                              ) !== undefined ||
                              this.state.isAgree === true ||
                              // this.state.isReverse === true ||
                              (this.state.buoc !==
                                this.props.signNumberProject.buoc &&
                                this.props.signNumberProject.tinhTrang === 6)
                            }
                          >
                            {this.props.auth.user.soDienThoaiKySo ? (
                              <p>Đồng ý ký số VNPT</p>
                            ) : (
                              <p>
                                Đồng ý ký số SIM
                                {/*<span class="pt-icon icon-DongYKysoSim"></span>*/}
                              </p>
                            )}
                          </Button>
                        </div>
                      )}
                  </div>

                  {this.state.isDisagree === false &&
                    this.state.isReverse === false && (
                      <div className="button-content">
                        <Button
                          className="pt-button btn-action-sign-number btn-action-red display-flex"
                          onClick={this.handleClickDisagree}
                          loading={this.state.isLoading.disagree}
                          disabled={
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined ||
                            // this.state.isDisagree === true ||
                            this.state.isAgree === true ||
                            // this.state.isReverse === true ||
                            this.state.buoc !==
                              this.props.signNumberProject.buoc
                          }
                        >
                          Không đồng ý
                          <span className="pt-icon pt-icon-large pt-icon-cross"></span>
                        </Button>
                      </div>
                    )}

                  {(this.state.isDisagree === true ||
                    this.state.isReverse === true) && (
                    <div className="button-content">
                      <Button
                        className="pt-button btn-action-sign-number btn-action-orange display-flex"
                        onClick={this.handleClickReserve}
                        loading={this.state.isLoading.reverse}
                        disabled={
                          Object.values(this.props.isLoadingData).find(
                            item => item === true
                          ) !== undefined ||
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined ||
                          this.state.isAgree === true ||
                          this.state.isReverse === true ||
                          this.state.buoc !== this.props.signNumberProject.buoc
                        }
                      >
                        Bảo lưu
                        <span className="pt-icon icon-Bao_Luu"></span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

        {/* Cap so */}
        {this.props.signNumberProject &&
          this.state.isShowButton &&
          this.state.isProgression && (
            <div className="form-progression">
              <div className="form-signnumber-submit">
                <div className="form-container box-signnumber">
                  <div className="form-input">
                    <div className="pt-form-group pt-intent-danger">
                      <label className="pt-label">
                        Số công văn
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content">
                        <input
                          name="soCongVan"
                          value={this.state.soCongVan}
                          className="pt-input"
                          placeholder="Nhập số công văn"
                          type="text"
                          dir="auto"
                          onChange={this.handleInputChange}
                          autoFocus={true}
                          disabled={
                            this.state.buoc !==
                            this.props.signNumberProject.buoc
                          }
                        />
                        {this.state.error.soCongVan !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.soCongVan}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="pt-form-group pt-intent-danger">
                      <label className="pt-label">
                        Ngày công văn
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content">
                        <div className="pt-input-group">
                          <DatePicker
                            isClearable={
                              this.state.dateToToString ? true : false
                            }
                            readOnly={
                              this.props.readOnly ||
                              this.state.buoc !==
                                this.props.signNumberProject.buoc
                            }
                            selected={this.state.ngayCongVan}
                            onChange={this.handleDateChange}
                            value={this.state.dateToToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dateToToString',
                                e.target.value
                              )
                            }
                            onBlur={e =>
                              this.focousOut(
                                'ngayCongVan',
                                'dateToToString',
                                e.target.value
                              )
                            }
                            format="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Chọn ngày..."
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                      {this.state.error.ngayCongVan !== undefined && (
                        <div className="pt-form-helper-content">
                          <p className="pt-form-helper-text">
                            {this.state.error.ngayCongVan}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="buttton-action-footer">
                <Button
                  type="button"
                  disabled={
                    this.state.isLoading.progression ||
                    this.state.buoc !== this.props.signNumberProject.buoc
                  }
                  loading={this.state.isLoading.checkProgression}
                  className="pt-button btn-save"
                  onClick={this.sendProgression}
                >
                  <span style={{ display: 'none' }}>
                    {this.state.hrefProgression}
                  </span>
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>

              <div className="form-container">
                <div className="box-signnumber">
                  <div className="form-input border-top">
                    <div className="pt-form-group">
                      <label className="pt-label pt0 mb10">Góp ý/Ghi chú</label>
                      <div className="pt-form-content">
                        <textarea
                          rows="4"
                          className="pt-input pt-fill"
                          placeholder="Nhập nội dung..."
                          dir="auto"
                          name="feedbackContent"
                          onChange={this.handleInputChange}
                          value={this.state.feedbackContent}
                          disabled={
                            this.state.isAgree === true ||
                            this.state.isDisagree === true ||
                            this.state.buoc !==
                              this.props.signNumberProject.buoc ||
                            this.state.isReverse === true
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box-action">
                <div className="group-button-action">
                  <div className="button-content">
                    <Button
                      className="pt-button btn-action-sign-number btn-action-red display-flex"
                      onClick={this.handleClickDisagree}
                      loading={this.state.isLoading.disagree}
                      disabled={
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined ||
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined ||
                        this.state.isDisagree === true ||
                        this.state.isAgree === true
                      }
                    >
                      Không đồng ý
                      <span className="pt-icon pt-icon-large pt-icon-cross"></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Download file khong dau */}
        {this.props.signNumberProject &&
          this.props.signNumberProject.tinhTrang === Types.PASSED &&
          this.props.signNumberProject.fileNoiDungKhongDau && (
            <div className="detail-history-signnumber">
              <div className="info-file">
                <span className="info">
                  {!this.props.signNumberProject.fileNoiDungKhongDau
                    .migrated && (
                    <a
                      className="link-file"
                      style={{
                        backgroundImage: `url(${Tool.Utils.typeFile(
                          extension
                        )})`,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${Types.FILE_URL}/api/file/get/${this.props.signNumberProject.fileNoiDungKhongDau.fileId}?BearerToken=${token}`}
                    >
                      File không dấu dùng để in và đóng dấu gửi ra bên ngoài -{' '}
                      {Tool.Utils.getFileSize(
                        this.props.signNumberProject.fileNoiDungKhongDau
                          .kichThuoc
                      )}
                    </a>
                  )}
                </span>
              </div>
            </div>
          )}

        {/* Trang thai */}
        {this.props.signNumberProject && (
          <DetailStatusProject
            item={this.props.signNumberProject}
            auth={this.props.auth}
          />
        )}

        {/* Danh sach gop y */}
        {this.props.isLoadingData &&
          this.props.isLoadingData.feedbacks === false &&
          feedbacks &&
          feedbacks.length > 0 && (
            <Feedback
              title="Góp ý/Ghi chú"
              list={feedbacks}
              auth={this.state.chucDanhId}
              isCanUpdate={
                this.props.signNumberProject &&
                this.props.signNumberProject.tinhTrang !== Types.PASSED
              }
              onSubmit={this.updateFeedback}
              loading={this.state.isLoading.sendFeedback}
              onChangeFeedback={this.handleUpdateFeedback}
              feedbackId={this.state.feedbackId}
              isLoading={this.state.isLoading}
              isLoadingData={this.props.isLoadingData}
            />
          )}

        {/* Lich su gop y*/}
        {this.props.isLoadingData &&
          this.props.isLoadingData.signNumberProject === false &&
          this.props.isLoadingData.feedbacks === false &&
          this.props.isShowHistoryDetail === false && (
            <UpdateHistory
              list={this.props.listHistoryUpdate}
              onClickShowHistoryDetail={this.props.onClickShowHistoryDetail}
              onGetHistoryList={this.props.onGetHistoryList}
              isLoadingData={
                this.props.isLoadingData && this.props.isLoadingData.historyList
              }
              isOpenHistoryList={this.props.isOpenHistoryList}
              type={this.props.type}
            />
          )}
      </div>
    )
  }
}

export default DetailBoxActionProject
