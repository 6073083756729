import * as MESSAGE from '../../../constants/MessageForm'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import React, { Component } from 'react'
import { Checkbox } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { commonAddToasterMessage } from '../../../actions'
import classnames from 'classnames'
import * as Tool from '../../../helpers'

class AddDonViDtxd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isEmptyData: false,
      isValidate: false,
      form: {
        tenDonVi: '',
        email: '',
        soDienThoaiViettel: '',
        soDienThoaiVnpt: '',
        donViNgoaiType: 1,
        isActive: false,
        tokenSerial: '',
        chuKy: '',
      },
      isAdd: [1],
      fileEdit: [],
      errorChuKyCoDau: [],
      chuKyCoDau: [],
      nameFileCoDau: [],
      sizeFileCoDau: [],
      isDeleteChuKyCodau: [false],
      isLoadingData: {
        chuKyCoDau: true,
      },
    }
  }

  handleFile = (type, i, e) => {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    let reader = new FileReader()
    let file = e.target.files[0]

    const errorChuKyCoDau = this.state.errorChuKyCoDau
    const fileEdit = this.state.fileEdit
    const attachFiles = e.target.value
    const extension =
      attachFiles &&
      attachFiles
        .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    reader.onloadend = () => {
      const base64 = reader.result.substring(
        reader.result.lastIndexOf(',') + 1,
        reader.result.trim().length
      )
      state[name][id] = base64
      state.nameFileCoDau[id] = file.name
      state.sizeFileCoDau[id] = file.size
      this.setState({ state }, () => {
        this.setState({
          isDeleteChuKyCodau: {
            ...this.state.isDeleteChuKyCodau,
            [id]: false,
          },
        })
      })
    }

    if (file) {
      reader.readAsDataURL(file)
      if (extension && extension !== 'png' && extension !== 'jpg') {
        errorChuKyCoDau[i] = MESSAGE.TEXT_TYPE_FILE_IMAGE_UPLOAD
      } else if (state.sizeFile > 1024 * 1024 * 25) {
        errorChuKyCoDau[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      } else {
        delete errorChuKyCoDau[i]
        fileEdit.splice(i, 1)
      }
      this.setState({ errorChuKyCoDau: errorChuKyCoDau, fileEdit })
    }
  }

  onSubmit = () => {
    const { onSubmit } = this.props
    let { form } = this.state
    form.chuKy =
      this.state.nameFileCoDau[0] && this.state.errorChuKyCoDau[0] === undefined
        ? this.state.chuKyCoDau[0]
        : ''
    debugger

    const error = {}
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (form.email.trim().length > 0 && !re.test(form.email)) {
      error.validationEmail = MESSAGE.TEXT_MESSAGE_TYPE_EMAIL
    }

    if (form.email.trim().length === 0) {
      error.validationEmail = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (form.tokenSerial.trim().length === 0) {
      error.tenDangNhap = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (form.chuKy.trim().length === 0) {
      error.tenDangNhap = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    !!onSubmit && onSubmit(Tool.Utils.toDecamelizeKeys(form))
    return this.setState({
      isLoading: false,
      isEmptyData: false,
      isValidate: false,
      form: {
        tenDonVi: '',
        email: '',
        isActive: false,
        tokenSerial: '',
        chuKy: '',
      },
      isAdd: [1],
      fileEdit: [],
      errorChuKyCoDau: [],
      chuKyCoDau: [],
      nameFileCoDau: [],
      sizeFileCoDau: [],
      isDeleteChuKyCodau: [false],
      isLoadingData: {
        chuKyCoDau: true,
      },
    })
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name
    let state = this.state
    state.form[name] = value

    this.setState(state)
  }

  handleEventRemoveFileItemRight = i => {
    const chuKyCoDau = this.state.chuKyCoDau
    const errorChuKyCoDau = this.state.errorChuKyCoDau
    const nameFileCoDau = this.state.nameFileCoDau
    const sizeFileCoDau = this.state.sizeFileCoDau

    delete chuKyCoDau[i]
    delete errorChuKyCoDau[i]
    delete nameFileCoDau[i]
    delete sizeFileCoDau[i]

    this.setState({
      chuKyCoDau,
      errorChuKyCoDau,
      nameFileCoDau,
      sizeFileCoDau,
      isDeleteChuKyCodau: {
        ...this.state.isDeleteChuKyCodau,
        [i]: true,
      },
    })
  }

  handleCheckbox = () => {
    let state = this.state
    state.form.isActive = !state.form.isActive
    this.setState(state)
  }

  componentDidMount() {}

  render() {
    const { isDialogOpen: isOpen = false, closeDialog, title } = this.props
    return (
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title || ''}
        className="size-container-dialog employee-dialog"
      >
        <div
          className={classnames(
            'container-UpdateDrawers',
            'wrapper-content-dialog',
            'employee-dialog-content'
          )}
        >
          <div className="form-container form-bloc">
            <div className="pt-form-group pt-intent-danger">
              <br />
              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <label className="pt-label">
                  Tên đơn vị
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="tenDonVi"
                    className="pt-input"
                    placeholder="Nhập tên đơn vị"
                    type="text"
                    dir="auto"
                    value={this.state.form.tenDonVi}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <label className="pt-label">
                  Email
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="email"
                    className="pt-input"
                    placeholder="Nhập email"
                    type="text"
                    dir="auto"
                    value={this.state.form.email}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <label className="pt-label">Số điện thoại ký số Viettel</label>
                <div className="pt-form-content">
                  <input
                    name="soDienThoaiViettel"
                    className="pt-input"
                    placeholder="Nhập số điện thoai sim CA Viettel"
                    type="text"
                    dir="auto"
                    value={this.state.form.soDienThoaiViettel}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <label className="pt-label">Số điện thoại ký số VNPT</label>
                <div className="pt-form-content">
                  <input
                    name="soDienThoaiVnpt"
                    className="pt-input"
                    placeholder="Nhập số điện thoại sim CA VNPT"
                    type="text"
                    dir="auto"
                    value={this.state.form.soDienThoaiVnpt}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>

              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <label className="pt-label">
                  Đơn vị ngoài(0: ký số thẩm tra thẩm định, 1: ký số giao nhận
                  vật tư )
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="donViNgoaiType"
                    className="pt-input"
                    placeholder="Nhập số điện thoai sim CA Viettel"
                    type="number"
                    dir="auto"
                    value={this.state.form.donViNgoaiType}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <label className="pt-label">
                  Serial USB token
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="tokenSerial"
                    className="pt-input"
                    placeholder="Nhập serial USB token"
                    type="text"
                    dir="auto"
                    value={this.state.form.tokenSerial}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>
              <br />
              <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                <div className="pt-form-content">
                  <Checkbox
                    className="pull-left"
                    name="isActive"
                    checked={this.state.form.isActive}
                    onChange={this.handleCheckbox}
                  ></Checkbox>
                  <label
                    className="pt-label pt-label-inline lable-users"
                    htmlFor="sudung"
                  >
                    Sử dụng
                  </label>
                </div>
              </div>
              <br />
              {this.state.isAdd.map((item, i) => (
                <div className="form-block" key={i}>
                  <div className="col-md-12 col-xs-12 col-sm-12 colcus-mb-100">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="chukycodau"
                      >
                        Hình ảnh chữ ký người đại diện
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div
                        className={classnames(
                          'form-item file-box input-form-inline pt-form-content',
                          {
                            'upload-have-close':
                              this.state.errorChuKyCoDau[i] === undefined &&
                              this.state.nameFileCoDau[i],
                          }
                        )}
                      >
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name={`chuKyCoDau-${i}`}
                            onChange={this.handleFile.bind(this, 'coDau', i)}
                            className="pt-input"
                            //disabled={Object.values(this.state.isLoadingData).find(item => item === true) !== undefined}
                            onClick={event => {
                              event.target.value = null
                            }}
                          />
                          <span className="pt-file-upload-input">
                            {this.state.chuKyCoDau[i] &&
                            this.state.nameFileCoDau[i] &&
                            this.state.errorChuKyCoDau[i] === undefined
                              ? this.state.nameFileCoDau[i]
                              : this.state.chuKyCoDau[i] &&
                                !this.state.nameFileCoDau[i] &&
                                this.state.errorChuKyCoDau[i] === undefined
                              ? 'Đã có file tải lên'
                              : 'Chọn file tải lên'}
                          </span>
                        </label>
                        {this.state.chuKyCoDau.length > 0 &&
                          this.state.errorChuKyCoDau[i] === undefined &&
                          (this.state.nameFileCoDau[i] ||
                            this.state.chuKyCoDau[i]) && (
                            <span
                              onClick={this.handleEventRemoveFileItemRight.bind(
                                this,
                                i
                              )}
                              className="icon-bc icon-close icon-remove-file"
                            ></span>
                          )}
                      </div>
                    </div>
                    <div className="pt-form-group pt-intent-danger">
                      {this.state.chuKyCoDau.length > 0 &&
                        this.state.chuKyCoDau[i] &&
                        this.state.errorChuKyCoDau[i] === undefined && (
                          <div className="pt-form-signature">
                            <img
                              src={`data:image/jpeg;base64,${this.state.chuKyCoDau[i]}`}
                              alt={
                                this.state.nameFileCoDau[i]
                                  ? this.state.nameFileCoDau[i]
                                  : ''
                              }
                            />
                          </div>
                        )}
                      {this.state.sizeFileCoDau[i] !== undefined &&
                        this.state.errorChuKyCoDau[i] === undefined && (
                          <div className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file:
                            <span className="size-file">
                              {' '}
                              {Tool.Utils.getFileSize(
                                this.state.sizeFileCoDau[i]
                              )}
                            </span>
                          </div>
                        )}
                      <div className="pt-form-helper-text">
                        {this.state.errorChuKyCoDau[i] !== undefined && (
                          <div className="pt-form-helper-text-content">
                            {this.state.errorChuKyCoDau[i]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pt-dialog-footer dialog-footer-employee">
          <div className="pt-dialog-footer-actions">
            <div className="box-button">
              <Button
                className="button-blue btn-save-employee"
                text="Thêm"
                intent={Intent.PRIMARY}
                onClick={this.onSubmit}
                // disabled={isLoading || isValidate}
              />
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
})

export default connect(mapStateToProps, mapDispathToProps)(AddDonViDtxd)
