import React, { memo, useCallback, useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { withRouter } from 'react-router'
import moment from 'moment'
import { Position } from '@blueprintjs/core'

import { getThreadMeetings, getThreadWork, getMeeting } from 'actions/task'
import requestAction from 'helpers/request'
import { getTypeResponsipility } from 'helpers/Helper'
import {
  Scrollbar,
  UserCard,
  Icon,
  Loading,
  FileGroup,
  StatusTag,
  TagGroup,
  StatusDate,
  PrioritizeFlag,
} from 'components/newCommon'
import { IMAGE_URL } from 'constants/Api'
import { WORK_TYPE } from 'constants/Enum'
import FlowItem from './FlowItem'
import FlowDoc from './FlowDoc'
import FlowDOffice from './FlowDOffice'

const FlowWorks = ({ onClose = () => {}, match, showProcessOnly = false }) => {
  const { actionId, id } = match.params
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const [fetching, setFetching] = useState(false)
  const [dataFlowWork, setDataFlowWork] = useState({})
  const [dataFlowWorkDO, setDataFlowWorkDO] = useState({})
  const [dataDetailMetting, setDataDetailMetting] = useState({})

  const getDataStatus = useCallback(values => {
    if (isEmpty(values)) {
      return null
    }

    let result = null
    switch (values?.type) {
      case 'DOING':
      case 'PENDING':
        result = {
          status: values?.type,
          textStatus: 'Đang thực hiện',
          labelDate: null,
          dateValue: null,
        }
        break
      case 'DONE':
        result = {
          status: values?.type,
          textStatus: 'Hoàn thành',
          labelDate: 'Ngày',
          dateValue: values?.finishedDate,
        }
        break
      case 'DONE_SLOW':
        result = {
          status: values?.type,
          textStatus: 'Hoàn thành chậm',
          labelDate: 'Ngày',
          dateValue: values?.finishedDate,
        }
        break
      default:
        break
    }

    return result
  }, [])

  const getDataApproval = useCallback(values => {
    if (isEmpty(values)) {
      return null
    }

    let result = null
    switch (values?.type) {
      case 'WAITING_APPROVAL':
        result = {
          status: values?.type,
          labelDate: null,
          dateValue: null,
        }
        break
      case 'APPROVED':
        result = {
          status: values?.type,
          labelDate: 'Ngày duyệt',
          dateValue: values?.approvalDate,
        }
        break

      default:
        break
    }

    return result
  }, [])

  const renderUserInfos = useCallback(
    values => {
      if (isEmpty(values)) {
        return
      }

      const statusInfo = getDataStatus(values?.status)
      const approvalInfo = getDataApproval(values?.approval)
      return (
        <div className="mb15">
          <div className="click-avatar-work-flow mb5">
            <span
              className="avatar-user"
              style={{
                backgroundImage: `url(${values?.user?.avatar})`,
              }}
            />
            <span className="name-user pl65">
              {values?.user?.name}
              {values?.user?.responsibilityType && (
                <span className="role">
                  ({getTypeResponsipility(values?.user?.responsibilityType)})
                </span>
              )}
            </span>
          </div>
          {values?.creationTime && (
            <div className="info-user mb15 pl55">
              <span className="ml10">
                <strong>Ngày tạo</strong>:{' '}
                {values?.creationTime && moment(values.creationTime).isValid()
                  ? moment(values?.creationTime).format('DD/MM/YYYY HH:mm')
                  : ''}
              </span>
            </div>
          )}
          {values?.assignerDate && (
            <div className="info-user mb15 pl55">
              <span className="ml10">
                <strong>Ngày giao</strong>:{' '}
                {moment(values?.assignerDate).isValid()
                  ? moment(values?.assignerDate).format('DD/MM/YYYY HH:mm')
                  : ''}
              </span>
            </div>
          )}
          {!isEmpty(statusInfo) && (
            <div className="mb15 ml60">
              <StatusTag
                className="round mr10"
                type={statusInfo?.status}
                text={statusInfo?.textStatus}
              />
              {statusInfo?.labelDate && (
                <span className="font-size-13">
                  <strong>{statusInfo?.labelDate}</strong>:{' '}
                  {statusInfo?.dateValue}
                </span>
              )}
            </div>
          )}
          {!isEmpty(approvalInfo) && (
            <div className="mb15 ml60">
              <StatusTag
                className="round mr10"
                type={approvalInfo?.status}
                text={approvalInfo?.textStatus}
              />
              {approvalInfo?.labelDate && (
                <span className="font-size-13">
                  <strong>{approvalInfo?.labelDate}</strong>:{' '}
                  {approvalInfo?.dateValue}
                </span>
              )}
            </div>
          )}
          {size(values?.workReports) !== 0 && (
            <FlowDoc data={values?.workReports} />
          )}
        </div>
      )
    },
    [getDataStatus, getDataApproval]
  )

  const fetchDetail = useCallback(id => {
    if (!id) {
      return
    }

    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getMeeting(id),
      afterResponse: response => {
        setDataDetailMetting(response)
      },
      afterAction: () => mountedSet(setFetching, false),
    })
  }, [])

  const fetchFlowDO = useCallback(() => {
    let workId = actionId
    if (!actionId) {
      workId = id
    }

    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getThreadWork(workId),
      afterResponse: response => {
        const newResponseLanhDaoDO = response?.luongCongViecDo?.lanhdao || []
        const newResponsePhongBanDO = response?.luongCongViecDo?.phongban || []
        setDataFlowWorkDO([...newResponseLanhDaoDO, ...newResponsePhongBanDO])
      },
      afterAction: () => mountedSet(setFetching, false),
    })
  }, [actionId, id])

  const fetchList = useCallback(() => {
    let workId = actionId
    if (!actionId) {
      workId = id
    }

    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getThreadMeetings(workId),
      afterResponse: response => {
        const newResponse = response?.[0] || []
        setDataFlowWork(newResponse)
        if (newResponse?.workType === 'MEETING' && newResponse?.parentId) {
          fetchDetail(newResponse?.parentId)
        }

        if (newResponse?.workType === 'DOFFICE') {
          fetchFlowDO()
        }
      },
      afterAction: () => mountedSet(setFetching, false),
    })
  }, [actionId, id, fetchDetail, fetchFlowDO])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  const getItemFlowWork = data => {
    if (isEmpty(data)) {
      return null
    }

    return data?.map((item, index) => (
      <FlowItem key={index} data={item}>
        {!isEmpty(item.workItems) && getItemFlowWork(item.workItems)}
      </FlowItem>
    ))
  }

  return (
    <div
      className={
        showProcessOnly
          ? ''
          : classnames('cpc-side-action', 'open', 'large-size')
      }
    >
      {fetching ? (
        <Loading />
      ) : (
        <div>
          {!showProcessOnly && (
            <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
              <span>Luồng công việc</span>
              <Icon
                classIcon="icon-Huy"
                className={'has-event font-size-12 ml10'}
                onClick={onClose}
              />
            </h1>
          )}
          <Scrollbar maxHeight={window.innerHeight - 106}>
            <main className={`${!showProcessOnly ? 'main-flowwork' : ''}`}>
              <div
                className={`pt15 pb15 pl10 pr10 ${
                  showProcessOnly ? '' : 'flow-work-box'
                }`}
              >
                {!isEmpty(dataDetailMetting) && !showProcessOnly && (
                  <div className="border-bottom mb15">
                    <div className="item-flow">
                      <UserCard
                        needBaseUrl
                        src={dataFlowWork?.creator.avatar}
                        name={dataFlowWork?.creator?.fullName || ''}
                      />
                      <div className="info-user pl50">
                        <strong>Ngày tạo</strong>:{' '}
                        {moment(dataFlowWork?.creationTime).isValid()
                          ? moment(dataFlowWork?.creationTime).format(
                              'DD/MM/YYYY HH:mm'
                            )
                          : ''}
                      </div>
                    </div>
                    <div className="font-size-14 mb10 mt10">
                      {dataDetailMetting?.title && (
                        <div className="d-flex align-items-center mt5">
                          <div className="font-weight-600 min-width-120 mt5 mr10">
                            Tên cuộc họp:
                          </div>
                          <div className="mt5">
                            {dataDetailMetting?.title || ''}
                          </div>
                        </div>
                      )}
                      {dataDetailMetting?.mainResponsibility && (
                        <div className="d-flex align-items-center mt5">
                          <div className="font-weight-600 min-width-120 mt5 mr10">
                            Lãnh đạo chủ trì:
                          </div>
                          <div className="mt5">
                            <UserCard
                              needBaseUrl
                              src={dataDetailMetting.mainResponsibility.avatar}
                              name={dataDetailMetting?.mainResponsibility?.name}
                              unit={
                                dataDetailMetting?.mainResponsibility
                                  ?.information?.organization?.shortName
                              }
                            />
                          </div>
                        </div>
                      )}
                      {dataDetailMetting?.files && (
                        <div className="d-flex align-items-center mt5">
                          <div className="font-weight-600 min-width-120 mt5 mr10">
                            File văn bản:
                          </div>
                          <div className="mt5">
                            <div className="d-flex flex-column">
                              <FileGroup list={dataDetailMetting?.files} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!showProcessOnly && (
                  <div className="border-bottom pb15 mb15">
                    {dataFlowWork?.workType === WORK_TYPE.MEETING && (
                      <span
                        className={classnames(
                          'uppercase',
                          'font-size-10',
                          'font-weight-600',
                          'text-yellow'
                        )}
                      >
                        Công việc Thông báo KLCH
                      </span>
                    )}
                    {dataFlowWork?.workType === WORK_TYPE.EOFFICE && (
                      <span
                        className={classnames(
                          'uppercase',
                          'font-size-10',
                          'font-weight-600',
                          'text-primary'
                        )}
                      >
                        Công việc Ký số, Công văn
                      </span>
                    )}
                    {dataFlowWork?.workType === WORK_TYPE.DOFFICE && (
                      <span
                        className={classnames(
                          'uppercase',
                          'font-size-10',
                          'font-weight-600',
                          'text-danger'
                        )}
                      >
                        Công việc D-Office
                      </span>
                    )}
                    {get(dataFlowWork, 'title') && (
                      <h4
                        className={classnames(
                          'font-size-13',
                          'font-weight-600',
                          'mb10'
                        )}
                      >
                        {get(dataFlowWork, 'title')}
                      </h4>
                    )}
                    {size(dataFlowWork?.workUserTags) !== 0 && (
                      <div className="mb15">
                        <TagGroup
                          tags={get(dataFlowWork, 'workUserTags', []).map(
                            ({ workTagName, backgroundColor }) => ({
                              name: workTagName,
                              color: backgroundColor,
                            })
                          )}
                          popoverPosition={Position.BOTTOM_RIGHT}
                          popoverClassName="mr15 mt5"
                        />
                      </div>
                    )}
                    {(dataFlowWork?.deadline ||
                      dataFlowWork?.originalDeadline ||
                      dataFlowWork?.executionDate ||
                      dataFlowWork?.priority) && (
                      <ul className="d-flex justify-content-space-between">
                        {dataFlowWork?.executionDate && (
                          <li>
                            <div>
                              <label className="cpc-detail-label">
                                Ngày bắt đầu:
                              </label>
                              <StatusDate
                                className="cpc-detail-text"
                                date={dataFlowWork?.executionDate}
                                descriptionInline
                                showDescription={false}
                                isCompleted={true}
                              />
                            </div>
                          </li>
                        )}
                        {(dataFlowWork?.deadline ||
                          dataFlowWork?.originalDeadline) && (
                          <li>
                            <div>
                              <label className="cpc-detail-label">
                                Hạn thực hiện:
                              </label>
                              <StatusDate
                                className="cpc-detail-text"
                                date={
                                  dataFlowWork?.deadline ||
                                  dataFlowWork?.originalDeadline
                                }
                                descriptionInline
                                showDescription={false}
                                isCompleted={
                                  get(dataFlowWork, 'status') === 'DONE'
                                }
                              />
                            </div>
                          </li>
                        )}
                        {dataFlowWork?.priority && (
                          <li>
                            <PrioritizeFlag type={dataFlowWork?.priority} />
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {dataFlowWork?.workType === 'DOFFICE' && !showProcessOnly && (
                  <div className="border-bottom mb15">
                    <FlowDOffice data={dataFlowWorkDO} />
                  </div>
                )}
                {/* item đầu */}
                <div className="item-flow">
                  {renderUserInfos({
                    user: {
                      avatar:
                        dataFlowWork?.creator && dataFlowWork?.creator?.avatar
                          ? IMAGE_URL + dataFlowWork?.creator.avatar
                          : '/images/default_avatar.png',
                      name: dataFlowWork?.creator?.fullName,
                      responsibilityType:
                        size(dataFlowWork?.workItems) === 0 &&
                        dataFlowWork?.workType !== 'MEETING'
                          ? dataFlowWork?.responsibilityType
                          : null,
                    },
                    creationTime: dataFlowWork?.creationTime,
                    ...(size(dataFlowWork?.workItems) === 0 && {
                      assignerDate: dataFlowWork?.assignDate,
                      status: {
                        type: dataFlowWork?.status,
                        finishedDate: moment(
                          dataFlowWork?.finishedDate
                        ).isValid()
                          ? moment(dataFlowWork?.finishedDate).format(
                              'DD/MM/YYYY HH:mm'
                            )
                          : '',
                      },
                      approval: {
                        type: dataFlowWork?.approval,
                        approvalDate: moment(
                          dataFlowWork?.approvalDate
                        ).isValid()
                          ? moment(dataFlowWork?.approvalDate).format(
                              'DD/MM/YYYY HH:mm'
                            )
                          : '',
                      },
                    }),
                    ...(size(dataFlowWork?.workItems) !== 0
                      ? {
                          approval: {
                            type: dataFlowWork?.approval,
                            approvalDate: moment(
                              dataFlowWork?.approvalDate
                            ).isValid()
                              ? moment(dataFlowWork?.approvalDate).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                              : '',
                          },
                        }
                      : {}),
                    workReports: dataFlowWork?.workReports || [],
                  })}
                  {getItemFlowWork(dataFlowWork?.workItems)}
                </div>
              </div>
            </main>
          </Scrollbar>
        </div>
      )}
    </div>
  )
}

export default withRouter(memo(FlowWorks))
