import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Intent } from '@blueprintjs/core'
import { LoadingItem } from '../../components/common/'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import { decamelizeKeys } from 'humps'
import TableDetailBox from './TableDetailBox'
import { Permission } from '../../components/auth'

const VNPT = 1
const VIETTEL = 0

class DetailBoxAction2 extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      gopY: undefined,
      isLoading: {
        isToKen: false,
        isVNPT: false,
        isVietTel: false,
        isKhongDongY: false,
        isDongY: false,
      },
      hrefToken: `eofficecpc:id_vb=${_.get(
        props,
        'data.phieuLayYKienId'
      )};id_nv=${_.get(
        props,
        'data.lanhDaoPhongBanChuTri.chucDanhId'
      )};type=0;app=hdtv`,
    }
  }

  handleInputChange = e => {
    this.setState({ gopY: e.target.value })
  }

  handleLDPBDongY = type => {
    const phieuLayYKienId = _.get(this.props, 'data.phieuLayYKienId')
    const data = {
      noiDung: this.state.gopY,
    }

    this.props.actions
      .postLDPBDongY(phieuLayYKienId, decamelizeKeys(data))
      .then(res => {
        if (type === VIETTEL) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isVietTel: false,
            },
          })
        } else {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isVNPT: false,
            },
          })
        }

        if (_.get(res, 'error') || !_.get(res, 'payload.data.result')) {
          const error =
            _.get(res, 'response.data.message') || MESSAGE.TOATS_MESSAGE_FAIL
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }

        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKySoBangSIM',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleClickAgreeByTOKEN = async e => {
    e.preventDefault()

    const chucDanhLanhDaoPhongBanId = _.get(
      this.props,
      'data.lanhDaoPhongBanChuTri.chucDanhId'
    )
    const chucDanhId = _.get(this.props, 'auth.roleId')

    if (chucDanhLanhDaoPhongBanId !== chucDanhId) {
      return
    }

    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        isToken: true,
      },
    })

    if (_.trim(this.state.gopY).length > 0) {
      const phieuLayYKienId = _.get(this.props, 'data.phieuLayYKienId')
      const data = {
        noiDung: _.trim(this.state.gopY),
      }

      const response = await this.props.actions.postGopYHDTVLDPB(
        phieuLayYKienId,
        decamelizeKeys(data)
      )

      if (!_.isEmpty(response.error)) {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
        return this.setState({
          isLoading: {
            ...this.state.isLoading,
            isToken: false,
          },
        })
      }

      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    if (this.props.onRemoveFileInfo) {
      this.props.onRemoveFileInfo()
    }
    window.location.href = this.state.hrefToken
  }

  handleClickAgreeBySIM = type => async e => {
    e.preventDefault()

    if (type === VIETTEL) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          isVietTel: true,
        },
      })
    } else {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          isVNPT: true,
        },
      })
    }

    this.handleLDPBDongY(type)
  }

  handleClickKhongDongY = async e => {
    e.preventDefault()

    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        isKhongDongY: true,
      },
    })

    const phieuLayYKienId = _.get(this.props, 'data.phieuLayYKienId')
    const data = {
      noiDung: this.state.gopY,
    }

    this.props.actions
      .postLDPBKhongDongY(phieuLayYKienId, decamelizeKeys(data))
      .then(res => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isKhongDongY: false,
          },
        })

        if (_.get(res, 'error') || !_.get(res, 'payload.data.result')) {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'KhongDongY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'KhongDongY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  /**
   * Dong y ky duyet phieu y kien
   * @memberof DetailBoxAction2
   */
  handleClickDongY = async e => {
    e.preventDefault()
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        isDongY: true,
      },
    })
    const phieuLayYKienId = _.get(this.props, 'data.phieuLayYKienId')
    const data = {
      noiDung: this.state.gopY,
    }
    this.props.actions
      .postLDPBDongY(phieuLayYKienId, decamelizeKeys(data))
      .then(res => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isDongY: false,
          },
        })
        if (_.get(res, 'payload.status') !== 200) {
          const error =
            _.get(res, 'response.data.message') || MESSAGE.TOATS_MESSAGE_FAIL
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongY',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }

        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  /**
   * Check truong ban tong hop (TB TH) voi quyen HDTV_LAYYKIEN_KYDUYET
   * @memberof DetailBoxAction2
   */
  isCheckTBTH = () => {
    return (
      this.props.auth &&
      this.props.auth.permission &&
      this.props.auth.permission.indexOf(Permission.HDTV_LAYYKIEN_KYDUYET) !==
        -1
    )
  }

  render() {
    const detail = _.get(this.props, 'data', {})
    const token = _.get(this.props, 'auth.token.accessToken', '')
    return this.props.isLoadingData &&
      this.props.isLoadingData.signNumber === true ? (
      <LoadingItem />
    ) : (
      <div className="list-action list-action-sign-number">
        <p className="title">Thực Hiện</p>
        <span className="icon-More show-th-mobile" tabIndex="0"></span>
        <div className="detail-info-container section-box-info">
          <div className="list-items">
            <div className="row item-value">
              <div className="col-xs-4 left-content">
                <span className="label-text">Trích yếu:</span>
              </div>
              <div className="col-xs-8 right-content">
                <span className="content textarea-content">
                  {detail.trichYeu}
                </span>
              </div>
            </div>
            <div className="row item-value">
              <div className="col-xs-4 left-content">
                <span className="label-text">Nội dung trình HĐTV:</span>
              </div>
              <div className="col-xs-8 right-content">
                <span className="content textarea-content">
                  {detail.noiDungTrinh}
                </span>
              </div>
            </div>
            <div className="row item-value">
              <div className="col-xs-4 left-content">
                <span className="label-text">
                  File báo cáo của Ban TH ({detail.fileBaoCao ? 1 : 0}):
                </span>
              </div>
              <div className="col-xs-8 right-content">
                {detail.fileBaoCao && (
                  <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
                    <span className="detail">
                      {[{ ...detail.fileBaoCao }].map((value, item) => {
                        let extensionTK = null
                        const dsFileBaoCao = value.fileName
                        if (dsFileBaoCao) {
                          extensionTK = dsFileBaoCao
                            .slice(
                              ((value.fileName.lastIndexOf('.') - 1) >>> 0) + 2
                            )
                            .toLowerCase()
                        }
                        return (
                          <div className="info-file" key={item}>
                            <span className="info">
                              {!value.migrated && (
                                <a
                                  style={{
                                    backgroundImage: `url(${Tool.Utils.typeFile(
                                      extensionTK
                                    )})`,
                                  }}
                                  className="link-file"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                >
                                  {value.tenFile} -{' '}
                                  {Tool.Utils.getFileSize(value.kichThuoc)}
                                </a>
                              )}
                            </span>
                          </div>
                        )
                      })}
                    </span>
                  </Scrollbars>
                )}
              </div>
            </div>
            <div className="row item-value">
              <div className="col-xs-4 left-content">
                <span className="label-text">
                  File đính kèm
                  {detail.dsFileDinhKem.length > 0 &&
                    ` (${detail.dsFileDinhKem.length})`}
                  :
                </span>
              </div>
              <div className="col-xs-8 right-content">
                <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
                  <span className="detail">
                    {detail.dsFileDinhKem.map((value, item) => {
                      let extensionTK = null
                      const dsFileDinhKem = value.fileName
                      if (dsFileDinhKem) {
                        extensionTK = dsFileDinhKem
                          .slice(
                            ((value.fileName.lastIndexOf('.') - 1) >>> 0) + 2
                          )
                          .toLowerCase()
                      }
                      return (
                        <div className="info-file" key={item}>
                          <span className="info">
                            {!value.migrated && (
                              <a
                                style={{
                                  backgroundImage: `url(${Tool.Utils.typeFile(
                                    extensionTK
                                  )})`,
                                }}
                                className="link-file"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                              >
                                {value.tenFile} -{' '}
                                {Tool.Utils.getFileSize(value.kichThuoc)}
                              </a>
                            )}
                          </span>
                        </div>
                      )
                    })}
                  </span>
                </Scrollbars>
              </div>
            </div>

            <div className="row item-value">
              <div className="col-xs-4 left-content">
                <span className="label-text">
                  File tham khảo
                  {detail.dsFileThamKhao.length > 0 &&
                    ` (${detail.dsFileThamKhao.length})`}
                  :
                </span>
              </div>
              <div className="col-xs-8 right-content">
                <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
                  <span className="detail">
                    {detail.dsFileThamKhao.map((value, item) => {
                      let extensionTK = null
                      const dsFileThamKhao = value.fileName
                      if (dsFileThamKhao) {
                        extensionTK = dsFileThamKhao
                          .slice(
                            ((value.fileName.lastIndexOf('.') - 1) >>> 0) + 2
                          )
                          .toLowerCase()
                      }
                      return (
                        <div className="info-file" key={item}>
                          <span className="info">
                            {!value.migrated && (
                              <a
                                style={{
                                  backgroundImage: `url(${Tool.Utils.typeFile(
                                    extensionTK
                                  )})`,
                                }}
                                className="link-file"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                              >
                                {value.tenFile} -{' '}
                                {Tool.Utils.getFileSize(value.kichThuoc)}
                              </a>
                            )}
                          </span>
                        </div>
                      )
                    })}
                  </span>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>

        <div className="form-container">
          <div className="box-signnumber">
            <div className="form-input">
              <div className="pt-form-group">
                <div className="row">
                  <label className="pt-label col-xs-12 pt0 mb10">
                    Nội dung góp ý
                  </label>
                  <div className="pt-form-content col-xs-12">
                    <textarea
                      rows="4"
                      className="pt-input pt-fill"
                      placeholder="Nhập nội dung..."
                      dir="auto"
                      name="feedbackContent"
                      onChange={this.handleInputChange}
                      value={this.state.gopY}
                      disabled={
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined ||
                        _.includes([2, 6], _.get(detail, 'tinhTrang'))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box-actions-right">
          <div className="box-action">
            <div className="group-button-action">
              {!this.isCheckTBTH() && (
                <div className="button-content">
                  <Button
                    className="pt-button btn-action-sign-number btn-action-blue text-uppercase display-flex"
                    onClick={this.handleClickAgreeByTOKEN}
                    loading={this.state.isLoading.isToKen}
                    disabled={
                      Object.values(this.state.isLoading).find(
                        item => item === true
                      ) !== undefined ||
                      _.includes([2, 6], _.get(detail, 'tinhTrang'))
                    }
                  >
                    <span style={{ display: 'none' }}>
                      {this.state.hrefToken}
                    </span>
                    Đồng ý ký số Token
                    <span className="pt-icon icon-DongYKysoToken color-main"></span>
                  </Button>
                </div>
              )}

              {!this.isCheckTBTH() && (
                <div className="button-content">
                  {_.get(this.props, 'auth.user.soDienThoaiKySo', false) && (
                    <div className="button-content-2">
                      <Button
                        className="pt-button btn-action-sign-number btn-action-blue display-flex text-uppercase"
                        onClick={this.handleClickAgreeBySIM(VIETTEL)}
                        loading={this.state.isLoading.isVietTel}
                        disabled={
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined ||
                          _.includes([2, 6], _.get(detail, 'tinhTrang'))
                        }
                      >
                        {_.get(
                          this.props,
                          'auth.user.soDienThoaiKySoVnpt',
                          false
                        ) ? (
                          <p> Đồng ý ký số VIETTEL</p>
                        ) : (
                          <p>
                            Đồng ý ký số SIM
                            <span className="pt-icon icon-DongYKysoSim color-main"></span>
                          </p>
                        )}
                      </Button>
                    </div>
                  )}
                  {_.get(
                    this.props,
                    'auth.user.soDienThoaiKySoVnpt',
                    false
                  ) && (
                    <div className="button-content-2">
                      <Button
                        className="pt-button btn-action-sign-number btn-action-blue display-flex text-uppercase"
                        onClick={this.handleClickAgreeBySIM(VNPT)}
                        loading={this.state.isLoading.isVNPT}
                        disabled={
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined ||
                          _.includes([2, 6], _.get(detail, 'tinhTrang'))
                        }
                      >
                        {_.get(
                          this.props,
                          'auth.user.soDienThoaiKySo',
                          false
                        ) ? (
                          <p>Đồng ý ký số VNPT</p>
                        ) : (
                          <p>
                            Đồng ý ký số SIM
                            <span className="pt-icon icon-DongYKysoSim color-main"></span>
                          </p>
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {this.isCheckTBTH() && (
                <div className="button-content">
                  <Button
                    className="pt-button btn-action-sign-number btn-opinion-sign btn-action-blue text-uppercase display-flex"
                    onClick={this.handleClickDongY}
                    loading={this.state.isLoading.isDongY}
                    disabled={
                      Object.values(this.state.isLoading).find(
                        item => item === true
                      ) !== undefined ||
                      _.get(detail, 'lanhDaoPhongBanChuTri.trangThai') === 6 ||
                      _.includes([2, 6], _.get(detail, 'tinhTrang'))
                    }
                  >
                    Đồng ý
                    <span className="pt-icon pt-icon-large icon-Bao_Cao"></span>
                  </Button>
                </div>
              )}

              <div className="button-content">
                <Button
                  className="pt-button btn-action-sign-number btn-opinion-sign btn-action-red text-uppercase display-flex"
                  onClick={this.handleClickKhongDongY}
                  loading={this.state.isLoading.isKhongDongY}
                  disabled={
                    Object.values(this.state.isLoading).find(
                      item => item === true
                    ) !== undefined ||
                    _.get(detail, 'lanhDaoPhongBanChuTri.trangThai') === 1 ||
                    _.get(detail, 'tinhTrang') === 1 ||
                    _.includes([2, 6], _.get(detail, 'tinhTrang'))
                  }
                >
                  Không đồng ý
                  <span className="pt-icon pt-icon-large pt-icon-cross"></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="box-action-block table-thanhvien">
          <TableDetailBox detail={detail} auth={this.props.auth} />
        </div>
      </div>
    )
  }
}

DetailBoxAction2.propTypes = {
  auth: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  isLoadingData: PropTypes.object.isRequired,
  data: PropTypes.object,
}

DetailBoxAction2.defaultProps = {
  data: {},
}

export default DetailBoxAction2
