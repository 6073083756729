// import { Permission } from '../../components/auth';
export const headers = {
  timKiemCongVanV61: [
    {
      classNames: 'column-6',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: null,
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayCongVan',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số Công Văn',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày đến',
      sort: null,
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayDen',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số đến',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soDen',
    },
    {
      classNames: 'column-35',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'CQBH',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'coQuanBanHanh',
    },
    {
      style: [],
      value: '',
      sort: null,
      permission: [],
      typeGridView: '',
      nameGridView: '',
    },
  ],
}
