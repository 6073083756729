import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { UrgencyCategory } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const LINK = '/he-thong/do-khan/them-moi'

class UrgencyCategoryPage extends React.Component {
  state = {
    priorities: [],
    isLoading: true,
    isError: false,
  }

  componentDidMount() {
    this.props.actions.commonFetchPriorities(null, true).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.priorities.items !== this.props.priorities.items) {
      this.setState(
        {
          priorities: nextProps.priorities.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Danh mục độ khẩn"
          iconPage="icon-flag"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div className="listrole-container">
          <UrgencyCategory
            items={this.state.priorities}
            actions={this.props.actions}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          ></UrgencyCategory>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  priorities: {
    ...state.priorities,
    items: state.common.priorities,
  },

  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UrgencyCategoryPage)
