import React, { memo } from 'react'
import File from './File'

import { size } from 'lodash'

const FileGroup = ({ list = [], listUpload = [], ...props }) => {
  return (
    <span className="cpc-file-group">
      {list.map((item, index) => (
        <File
          {...props}
          key={index || item.uid || item.id}
          displayBlock
          file={item}
          showClose={
            item.showClose !== undefined ? item.showClose : props.showClose
          }
        />
      ))}
      {!!size(listUpload) &&
        listUpload.map((item, index) => (
          <File
            {...props}
            key={index || item.uid || item.id}
            displayBlock
            file={item}
            showClose={
              item.showClose !== undefined ? item.showClose : props.showClose
            }
          />
        ))}
    </span>
  )
}

export default memo(FileGroup)
