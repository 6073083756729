import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'

// trang thai phieu nhan
const CHUA_CO_Y_KIEN = 0
const KHONG_DONG_Y = 1
const DONG_Y = 2
const KHONG_CO_Y_KIEN = 3

class ListItemThongKeGopY extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { item, number, auth } = this.props
    const token =
      auth.auth && auth.auth.token && auth.auth.token.accessToken
        ? auth.auth.token.accessToken
        : ''
    if (!item || !number) {
      return
    }
    let extensionTK = null
    if (item.fileGopY) {
      const fileDinhKem = item.fileGopY.fileName
      if (fileDinhKem) {
        extensionTK = fileDinhKem
          .slice(((item.fileGopY.fileName.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      }
    }

    let trangThai = ''
    switch (item.trangThaiGopY) {
      case CHUA_CO_Y_KIEN:
        trangThai = 'Chưa có ý kiên'
        break
      case KHONG_DONG_Y:
        trangThai = 'Không thống nhất'
        break
      case DONG_Y:
        trangThai = 'Thống nhất'
        break
      case KHONG_CO_Y_KIEN:
        trangThai = 'Không có ý kiến'
        break
      default:
        trangThai = ''
    }
    return (
      <tr>
        <td>{number > 9 ? number : '0' + number}</td>
        <td>{item.donViGopY ? item.donViGopY : ''}</td>
        <td>{item.noiDungGopY ? item.noiDungGopY : ''}</td>
        <td className="textarea-content">
          <span
            className={classnames('status-job position-relative', {
              'lddv-chuaduyet': item.trangThaiGopY === CHUA_CO_Y_KIEN,
              'khong-duyet': item.trangThaiGopY === KHONG_DONG_Y,
              'dang-gop-y': item.trangThaiGopY === DONG_Y,
              'khong-y-kien': item.trangThaiGopY === KHONG_CO_Y_KIEN,
            })}
          >
            {trangThai}
          </span>
        </td>
        <td className="textarea-content">
          {item.thoiGianHoanThanh
            ? moment(item.thoiGianHoanThanh).get('year') > 2018
              ? moment(item.thoiGianHoanThanh).format('DD/MM/YYYY HH:mm')
              : ''
            : ''}
          <br />
          {item.quaHan && (
            <span className="khong-duyet status-job position-relative">
              QUÁ HẠN
            </span>
          )}
        </td>
        <td>
          {item.fileGopY && (
            <span className="info">
              {/* eslint-disable */}
              <a
                className="link-file"
                target="_blank"
                rel="noopener noreferrer"
                href={`${Types.FILE_URL}/api/file/get/${item.fileGopY.fileId}?BearerToken=${token}`}
                style={{
                  backgroundImage: `url(${Tool.Utils.typeFile(extensionTK)})`,
                }}
              ></a>
            </span>
          )}
        </td>
      </tr>
    )
  }
}

export default ListItemThongKeGopY
