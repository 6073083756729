import { ProcedureCategory } from '../constants/ActionTypes'
import _ from 'lodash'

const initialState = {
  list: [],
  currentItem: {},
}

const ProcedureCategoryReducer = (state = initialState, action) => {
  const result = _.get(action, 'payload.data.result', null)
  switch (action.type) {
    case ProcedureCategory.COLLECTION:
      return {
        ...state,
        list: result.items || [],
      }
    case ProcedureCategory.ITEM:
      return {
        ...state,
        currentItem: result,
      }
    case ProcedureCategory.CREATE:
      return state
    case ProcedureCategory.UPDATE:
      return {
        ...state,
        currentItem: {},
      }
    case ProcedureCategory.DELETE:
      const deleteId = _.get(action, 'meta.previousAction.id', null)
      return {
        ...state,
        list: state.list.filter(item => item.id !== deleteId),
      }
    case ProcedureCategory.SORT:
      const idOrderList = _.get(
        action,
        'meta.previousAction.payload.request.data',
        []
      )
      const orderList = _.sortBy(state.list, item =>
        idOrderList.indexOf(item.id)
      )
      return {
        ...state,
        list: orderList,
      }
    default:
      return state
  }
}

export default ProcedureCategoryReducer
