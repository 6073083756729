import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'

class DetailStatusPhieuNhanGopY extends Component {
  static propTypes = {
    item: PropTypes.object,
  }

  state = {
    data: [],
  }

  componentDidMount() {
    const { requestComments } = this.props
    let data = []
    // LDPB duyet
    requestComments.lanhDaoPhongBanXacNhan &&
      data.push({
        maPhongBan: requestComments.lanhDaoPhongBanXacNhan.phongBanId
          ? requestComments.lanhDaoPhongBanXacNhan.phongBanId
          : null,
        tenNhanVien: requestComments.lanhDaoPhongBanXacNhan.tenNhanVien,
        trangThai: requestComments.lanhDaoPhongBanXacNhan.trangThai,
        yKien: requestComments.lanhDaoPhongBanXacNhan.yKien,
        thoiGianDuyet: requestComments.lanhDaoPhongBanXacNhan.thoiGian
          ? moment(requestComments.lanhDaoPhongBanXacNhan.thoiGian).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    // LDDV duyet
    requestComments.lanhDaoDonViXacNhan &&
      data.push({
        maPhongBan: requestComments.lanhDaoDonViXacNhan.phongBanId
          ? requestComments.lanhDaoDonViXacNhan.phongBanId
          : null,
        tenNhanVien: requestComments.lanhDaoDonViXacNhan.tenNhanVien,
        trangThai: requestComments.lanhDaoDonViXacNhan.trangThai,
        yKien: requestComments.lanhDaoDonViXacNhan.yKien,
        thoiGianDuyet: requestComments.lanhDaoDonViXacNhan.thoiGian
          ? moment(requestComments.lanhDaoDonViXacNhan.thoiGian).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    this.setState({ data })
  }

  render() {
    if (
      !this.props.requestComments ||
      this.props.requestComments.loai === 1 ||
      (!this.props.requestComments.lanhDaoPhongBanXacNhan &&
        !this.props.requestComments.lanhDaoDonViXacNhan)
    ) {
      return null
    }

    return (
      <div className="history-steering-box history-edit-document">
        <div className="detail-status-box">
          <div className="table-status table-scroll table-scroll-hidden">
            <div className="heading-table">
              <div className="cell-table colum-stt">
                <p className="name-heading-table">STT</p>
              </div>
              <div className="cell-table column-department">
                <p className="name-heading-table">Phòng ban/Cá nhân</p>
              </div>
              <div className="cell-table colum-status">
                <p className="name-heading-table">Trạng Thái</p>
              </div>
              {this.props.requestComments &&
                this.props.requestComments.tinhTrang > 0 && (
                  <div className="cell-table colum-status">
                    <p className="name-heading-table">Thời gian ký duyệt</p>
                  </div>
                )}
            </div>

            {this.state.data &&
              this.state.data.map((value, i) => (
                <div className="row-table" key={i}>
                  <div className="cell-table">
                    <p className="content">
                      {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                    </p>
                  </div>
                  <div className="cell-table column-department">
                    {value.maPhongBan && (
                      <p className="content department-name">
                        {value.maPhongBan}
                      </p>
                    )}
                    {value.tenNhanVien && (
                      <p className="content">{value.tenNhanVien}</p>
                    )}
                    {value.yKien && (
                      <div>
                        <span className="left-icon style-icon icon-Thong_Tin_GopY"></span>
                        <span className="detail textarea-content  ml5 department-comment">
                          {value.yKien}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={classnames('cell-table', {
                      'cell-status-warning':
                        this.props.isShowIconWarningForLeader,
                    })}
                  >
                    {value.trangThai === 0 && (
                      <span className="status-job no-process">Chưa đồng ý</span>
                    )}
                    {value.trangThai === 1 && (
                      <span className="status-job no-approval">
                        Không đồng ý
                      </span>
                    )}
                    {value.trangThai === 2 && (
                      <span className="status-job approved">Đồng ý</span>
                    )}
                  </div>
                  {this.props.requestComments &&
                    this.props.requestComments.tinhTrang > 0 && (
                      <div className="cell-table">
                        {value.thoiGianDuyet ? (
                          <p className="content">{value.thoiGianDuyet}</p>
                        ) : (
                          <p className="content">&nbsp;</p>
                        )}
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default DetailStatusPhieuNhanGopY
