import React, { Component } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as MESSAGER from '../../../constants/MessageForm'
import * as Tool from '../../../helpers'

class ListFilter extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
  }

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    filter: PropTypes.object,
    actions: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isForChild: PropTypes.bool.isRequired,
  }

  state = {
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
  }

  componentWillReceiveProps(props) {
    if (props.filter !== this.props.filter) {
      this.setState({
        startDate:
          props.filter.startDate && props.filter.startDate.trim().length > 0
            ? moment(props.filter.startDate)
            : undefined,
        endDate:
          props.filter.endDate && props.filter.endDate.trim().length > 0
            ? moment(props.filter.endDate)
            : undefined,
        dateFromToString:
          props.filter.startDate && props.filter.startDate.trim().length > 0
            ? moment(props.filter.startDate).format('DD/MM/YYYY')
            : '',
        dateToToString:
          props.filter.endDate && props.filter.endDate.trim().length > 0
            ? moment(props.filter.endDate).format('DD/MM/YYYY')
            : '',
      })
    }
  }

  handleChange(name, nameToString, e) {
    if (name === 'startDate' || name === 'endDate') {
      let value = ''
      if (e) {
        value = moment(e).format('DD/MM/YYYY')
      }

      this.setState({
        [name]: e,
        [nameToString]: value,
      })
    }
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      name === 'startDate' &&
      !this.state.endDate &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'startDate' &&
      this.state.endDate &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: moment(moment(this.state.endDate).format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    if (
      name === 'endDate' &&
      !this.state.startDate &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'endDate' &&
      this.state.startDate &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment(this.state.startDate).format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const startDate = this.state.startDate
      ? moment(this.state.startDate).format('YYYY-MM-DD')
      : undefined
    const endDate = this.state.endDate
      ? moment(this.state.endDate).format('YYYY-MM-DD')
      : undefined

    if (!startDate || !endDate) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TEXT_MESSAGE_TIME,
        intent: Intent.WARNING,
      })
    }
    this.props.onSubmit && this.props.onSubmit({ startDate, endDate })
  }

  render() {
    return (
      <div className="search-container search-statistic-documentCabinet">
        <form className="search-list-utilities search-utilities-nodropdown">
          <div className="search-advanced seach-form-container">
            <div className="row row-box-container">
              <div className="col-sm-8 col-xs-12 col-md-8 search-padding0">
                <div className="group-datetime pull-left">
                  <label className="pt-label label-datepicker">
                    Thời gian (ngày công văn)
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      isClearable={this.state.dateFromToString ? true : false}
                      readOnly={this.props.readOnly}
                      disabled={this.props.isForChild}
                      selected={this.state.startDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChange.bind(
                        this,
                        'startDate',
                        'dateFromToString'
                      )}
                      value={this.state.dateFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateFromToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'startDate',
                          'dateFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.endDate}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      isClearable={this.state.dateToToString ? true : false}
                      readOnly={this.props.readOnly}
                      disabled={this.props.isForChild}
                      selected={this.state.endDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChange.bind(
                        this,
                        'endDate',
                        'dateToToString'
                      )}
                      value={this.state.dateToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'endDate',
                          'dateToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.startDate}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              {!this.props.isForChild && (
                <div className="col-sm-4 col-xs-12 col-md-4 search-padding0">
                  <div className="group-btn-search">
                    <Button
                      rightIconName="pt-icon pt-icon-search"
                      className="btn-item btn-search"
                      text="Thống kê"
                      onClick={this.handleSubmit}
                      loading={this.props.isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ListFilter
