import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { MoveUsersUnit } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Utils } from '../../helpers'

const PATH = '/he-thong/nguoi-dung/di-chuyen'
const PUSH = '/he-thong/nguoi-dung/phan-quyen'
class MoveUsersUnitPage extends React.Component {
  state = {
    staff: null,
    donViId: '',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.staff.item !== this.props.staff.item) {
      this.setState({ staff: nextProps.staff.item })
    }
  }

  componentDidMount() {
    let donViId = Utils.getData('Permission-Unit', {})
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    this.setState({ donViId }, () => {
      if (permission) {
        this.props.actions.getCategoryUnitMultiLevel()
      } else {
        this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
      }
      if (this.props.match.path !== PATH) {
        this.props.actions.getCategoryDepartmentMultiLevel(donViId)
        this.props.actions.commonFetchPosition(donViId)
        this.props.actions.getCommonFetchRole(donViId, 1)
      }
    })

    if (this.props.match.params && this.props.match.params.id) {
      return this.props.actions.getStaffItem(this.props.match.params.id)
    }
  }

  handleGoBack = () => {
    if (this.props.match.path === PATH) {
      return this.props.history.goBack()
    }

    this.props.history.push(PUSH)
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage namePage="DI CHUYỂN NGƯỜI DÙNG" />
            <MoveUsersUnit
              unit={this.props.unit.items}
              position={this.props.position.items}
              departments={this.props.departments.items}
              role={this.props.role.items}
              actions={this.props.actions}
              auth={this.props.auth}
              staff={this.state.staff}
              handleGoBack={this.handleGoBack}
              match={this.props.match}
              onResetSignNumberToken={this.handleResetSignNumberToken}
              resetLoading={this.state.resetLoading}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },

  position: {
    ...state.common,
    items: state.common.position,
  },

  departments: {
    ...state.category,
    items: state.category.categoryDepartmentMultiLevel,
  },

  role: {
    ...state.common,
    items: state.common.role,
  },

  staff: {
    ...state.common,
    item: state.common.staffItem,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoveUsersUnitPage)
