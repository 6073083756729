import React, { useState } from 'react'
import classnames from 'classnames'
import { Collapse } from '@blueprintjs/core'
import moment from 'moment'
import { FileGroup } from 'components/newCommon'

const FlowDoc = ({ className, data }) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={classnames('info-user', className)}>
      <div className="click-report mb10" onClick={() => handleClick()}>
        Xem báo cáo{' '}
        <span
          className={classnames('pt-icon click-dropdown drop-down-items', {
            'pt-icon-chevron-up': isOpen,
            'pt-icon-chevron-down': !isOpen,
          })}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="box-level-sub item-box">
          {/* Báo cáo */}
          <div className="report-flow">
            {data?.map?.((item, index) => (
              <div key={index} className="item-report-flow">
                <p className="mb5 font-size-14 font-weight-600">
                  <i className="icon-new-calendar mr10" />{' '}
                  {moment(item.creationTime).isValid
                    ? moment(item.creationTime).format('DD/MM/YYYY HH:mm')
                    : ''}
                </p>
                <p className="info-report-flow mb5">
                  {item?.description || ''}
                </p>
                <div className="info-report-flow d-flex align-items-center link">
                  <FileGroup list={item?.files} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default FlowDoc
