import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const StatictisItem = ({ history, dataSource }) => {
  const onGoPage = () => {
    if (!dataSource?.value || dataSource?.value === 0) {
      return
    }

    history.push(dataSource.path)
  }

  return (
    <div
      className={classNames('statictis-item', {
        'cursor-pointer': dataSource?.value && dataSource?.value !== 0,
      })}
      onClick={onGoPage}
    >
      <div className="d-flex align-items-center gap-5">
        <span
          className={classNames('icon', {
            [`${dataSource?.icon}`]: dataSource?.icon,
          })}
        ></span>
        <div>
          <span className="text-uppercase font2 text-14-600-21 opacity-07">
            {dataSource?.name}
          </span>
          <p className="text-uppercase font2 text-14-600-21 m0 opacity-07">
            {dataSource?.name2}
          </p>
        </div>
      </div>
      <span className="font2 text-29-700-32 color-text-title">
        {dataSource?.value}
      </span>
    </div>
  )
}

StatictisItem.defaultProps = {
  history: null,
  dataSource: null,
}

StatictisItem.propTypes = {
  history: PropTypes.object,
  dataSource: PropTypes.object,
}

export default StatictisItem
