import React, { memo } from 'react'
import { Button } from '@blueprintjs/core'
import { Icon } from 'components/newCommon'

const CheckboxButton = ({
  disabled = false,
  styleIcon = null,
  onClick = () => {},
}) => {
  const _onClick = e => {
    e.preventDefault()
    onClick()
  }

  return (
    <Button className="checkbox-button" disabled={disabled} onClick={_onClick}>
      <Icon style={styleIcon} classIcon="icon-Hoan_Thanh" />
    </Button>
  )
}

export default memo(CheckboxButton)
