import React, { memo, useState } from 'react'
import classNames from 'classnames'
import { Tab2, Tabs2 } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment/moment'
import { CollapseMore, FileGroup, Scrollbar } from 'components/newCommon'
import PopupModal from 'components/common/PopupModal'
import QuaTrinhXuLy from './QuaTrinhXuLy'
import QuanLyPheDuyet from './QuanLyPheDuyet'

const ChiTietCongVan = ({
  onClose = () => {},
  match,
  listDonViVanBanDO,
  ...props
}) => {
  const { chiTietVanBan, tokenDO, listVanBan } = props
  const { id } = match.params
  const [loaiVanBan] = useState(null)
  const [dataVanBan] = useState(null)
  const [filePhuLuc] = useState(null)
  const [lichSuPhanPhoi] = useState(null)
  const [quaTrinhXyLy] = useState(null)

  const tabs = [
    {
      id: 'QuaTrinhXuLy',
      key: 'QuaTrinhXuLy',
      name: 'Quá trình xử lý',
      content: (
        <QuaTrinhXuLy
          data={lichSuPhanPhoi}
          dataDonViVanBan={listDonViVanBanDO}
          tokenDO={tokenDO}
          id={id}
        />
      ),
    },
    {
      id: 'QuanLyPheDuyet',
      key: 'QuanLyPheDuyet',
      name: 'Quá trình phê duyệt',
      content: <QuanLyPheDuyet data={quaTrinhXyLy} />,
    },
  ]

  const getTextDoKhan = text => {
    if (!text) return ''
    switch (text) {
      case 'k':
        return 'Khẩn'
      case 't':
        return 'Thượng khẩn'
      case 'h':
        return 'Hỏa tốc'
      default:
        return 'Bình thường'
    }
  }

  const getDoiTuong = text => {
    if (!text) return ''
    switch (text) {
      case 'VBDI':
        return 'Văn bản đi'
      case 'VBDE':
        return 'Văn bản đến'
      case 'VBNB':
        return 'Văn bản nội bộ'
      default:
        return ''
    }
  }

  const changeTab = () => {}

  return (
    <PopupModal
      isOpen={true}
      title="Thông tin công văn"
      contentClass="full-max theme-grey pb0"
      onClose={onClose}
    >
      <div className="d-flex" style={{ backgroundColor: '#fff' }}>
        <div className={classNames('open border')} style={{ width: '650px' }}>
          <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
            <span>Quá trình phân phối</span>
          </h1>
          <Scrollbar maxHeight={window.innerHeight - 190}>
            <div className="cpc-detail p15">
              <div className="cpc-detail-block font-size-13 pt15 pr15 pl15 pb0">
                <div className="border-bottom">
                  <div className="d-flex justify-space-between">
                    <p>
                      Số văn bản:{' '}
                      <span className="font-weight-600">
                        {chiTietVanBan?.sOVB || ''}
                      </span>
                    </p>
                    <p>
                      Số ký hiệu:{' '}
                      <span className="font-weight-600">
                        {chiTietVanBan?.kYHIEU || ''}
                      </span>
                    </p>
                  </div>
                  <p className="font-weight-600 mt15">
                    {chiTietVanBan?.tRICHYEU || ''}
                  </p>
                  <div className="row item-row-input mt15 mb10">
                    <div className="col-xs-6 col-sm-6 col-md-6">
                      <label className="detail-label">File văn bản</label>
                      <FileGroup
                        isFileSizeDisplay={false}
                        list={listVanBan
                          ?.filter?.(item => item.fILECHINH)
                          .map?.(item => ({
                            ...item,
                            id: item.iDFILE,
                            name: item.tENFILE,
                          }))}
                        isDoDownLoad={false}
                        isLink={false}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6">
                      <label className="detail-label">Phụ lục</label>
                      <FileGroup
                        isFileSizeDisplay={false}
                        list={filePhuLuc?.map?.(item => ({
                          ...item,
                          id: item.idFile,
                          name: item.tenFile,
                        }))}
                        isDoDownLoad={false}
                        isLink={false}
                      />
                    </div>
                  </div>
                </div>
                <CollapseMore
                  textShow="Xem thêm"
                  textHide="Thu gọn"
                  limitDisplay={0}
                  className="border-top pt5 pb5"
                  buttonSpace={false}
                >
                  <div className="font-size-14 mb10">
                    <div className="row">
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Ngày văn bản:</label>
                        <p className="detail-text">
                          {chiTietVanBan?.nGAYVB
                            ? moment(chiTietVanBan?.nGAYVB)
                                .subtract(7, 'hours')
                                .format('DD/MM/YYYY')
                            : ''}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Ngày nhận:</label>
                        <p className="detail-text">
                          {dataVanBan?.nGAYNHAN
                            ? moment(dataVanBan?.nGAYNHAN)
                                .subtract(7, 'hours')
                                .format('DD/MM/YYYY HH:mm')
                            : ''}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Đối tượng:</label>
                        <p className="detail-text">
                          {getDoiTuong(loaiVanBan?.lOAISOVB)}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">
                          Cơ quan ban hành:
                        </label>
                        <p className="detail-text">
                          {chiTietVanBan?.nOIBANHANH || ''}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Sổ văn bản:</label>
                        <p className="detail-text">
                          {loaiVanBan?.lOAISOVB || ''}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Loại văn bản:</label>
                        <p className="detail-text">
                          {loaiVanBan?.tENLOAIVB || ''}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Độ khẩn:</label>
                        <p className="detail-text">
                          {getTextDoKhan(chiTietVanBan?.mADK)}
                        </p>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                        <label className="detail-label">Độ mật:</label>
                        <p className="detail-text">
                          {getTextDoKhan(chiTietVanBan?.mADM)}
                        </p>
                      </div>
                    </div>
                  </div>
                </CollapseMore>
              </div>
              <div className="cpc-detail-block">
                <Tabs2
                  className="tabs-custom"
                  contentBordered
                  scrollable
                  onChange={changeTab}
                >
                  {tabs.map(tab => (
                    <Tab2
                      key={tab.id}
                      id={tab.id}
                      title={tab.name}
                      panel={tab.content}
                    />
                  ))}
                </Tabs2>
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
    </PopupModal>
  )
}

const mapStateToProps = state => ({
  tokenDO: state?.doffice?.toKenDO,
  chiTietVanBan: state?.doffice?.chiTietVanBan,
  listVanBan: state?.doffice?.listVanBan,
  listDonViVanBanDO: state?.doffice?.listDonViVanBanDO,
})

export default withRouter(connect(mapStateToProps)(memo(ChiTietCongVan)))
