import { Button } from '@blueprintjs/core'
import { STATUS_CALENDAR } from 'constants/Enum'
import React from 'react'
import DatePicker from 'react-datepicker'

const ListFilter = props => {
  const {
    filter,
    textSearch,
    handleChangeFilter,
    hanldeChangeTextSearch,
    handleClearFilter,
    handleClickSearch,
  } = props
  return (
    <div className="search-calendar-management">
      <div className="row">
        <div className="col-xs-3">
          <div className="form-input-filter date-picker-inline border-radius-3">
            <DatePicker
              readOnly
              isClearable
              selected={filter.fromDate}
              selectsStart
              startDate={filter.fromDate}
              endDate={filter.toDate}
              onChange={date => handleChangeFilter('fromDate', date)}
              format="DD/MM/YYYY"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
              placeholderText="Từ ngày"
              maxDate={filter.toDate}
              popperPlacement="auto"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: '5px, 10px',
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
            <DatePicker
              readOnly
              isClearable
              selected={filter.toDate}
              selectsEnd
              startDate={filter.fromDate}
              endDate={filter.toDate}
              onChange={date => handleChangeFilter('toDate', date)}
              format="DD/MM/YYYY"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
              placeholderText="Đến ngày"
              minDate={filter.fromDate}
              popperPlacement="auto"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: '5px, 10px',
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </div>
        </div>
        <div className="col-xs-2">
          <div className="pt-select">
            <select
              className="input form-input-filter"
              value={filter.theLoaiId}
              onChange={e => handleChangeFilter('theLoaiId', e.target.value)}
              style={{
                width: '100%',
                marginRight: '10px',
                border: '1px solid #d2d2d2',
                borderRadius: '4px',
                backgroundColor: '#fff',
                boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
              }}
            >
              <option key="default" value="">
                Tất cả loại cuộc họp
              </option>
              {props?.dsLoaiCuocHopCombobox?.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="pt-select">
            <select
              className="input form-input-filter"
              value={filter.mucDoId}
              onChange={e => handleChangeFilter('mucDoId', e.target.value)}
              style={{
                width: '100%',
                marginRight: '10px',
                border: '1px solid #d2d2d2',
                borderRadius: '4px',
                backgroundColor: '#fff',
                boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
              }}
            >
              <option key="default" value="">
                Tất cả cấp độ
              </option>
              {props?.dsCapDoCuocHopCombobox?.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="pt-select">
            <select
              className="input form-input-filter"
              value={filter.trangThai}
              onChange={e => handleChangeFilter('trangThai', e.target.value)}
              style={{
                width: '100%',
                marginRight: '10px',
                border: '1px solid #d2d2d2',
                borderRadius: '4px',
                backgroundColor: '#fff',
                boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
              }}
            >
              <option key="default" value="">
                Tất cả trạng thái
              </option>
              {STATUS_CALENDAR.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xs-3 align-items-end action-filter">
          <div
            className="form-input-filter border-radius-3 position-gridview"
            style={{ flex: 1 }}
          >
            <input
              autoFocus={false}
              autoComplete="off"
              placeholder="Nhập tiêu đề"
              type="text"
              className="input border-transparent pr-40 input-search-filter"
              onChange={e => hanldeChangeTextSearch(e.target.value)}
              name="textSearch"
              value={textSearch}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleClickSearch()
                }
              }}
            />
            <Button
              className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              onClick={() => handleClickSearch()}
            >
              <span className="pt-icon pt-icon-search mr0"></span>
            </Button>
          </div>
          <Button
            onClick={handleClearFilter}
            type="button"
            className="btn-icon flex-solid ml10 pt-intent-btn-white"
          >
            <span className="pt-icon icon-Loading mr0"></span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ListFilter
