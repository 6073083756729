import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Intent, Button } from '@blueprintjs/core'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import { FileList } from '../../components/common'

class SendMail extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    receiveDocument: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  state = {
    error: {},
    emailTo: '',
    title: '',
    content: '',
    file:
      this.props.receiveDocument &&
      this.props.receiveDocument.fileNoiDung &&
      this.props.receiveDocument.fileNoiDung.fileId
        ? this.props.receiveDocument.fileNoiDung.fileId
        : this.props.receiveDocument.fileCongVan
        ? this.props.receiveDocument.fileCongVan.fileId
        : '',
    fileDinhKem:
      this.props.receiveDocument && this.props.receiveDocument.fileDinhKem
        ? this.props.receiveDocument.fileDinhKem
        : this.props.receiveDocument &&
          this.props.receiveDocument.fileDinhKemEvn
        ? this.props.receiveDocument.fileDinhKemEvn
        : [],
    dataSelect: '',
    isOpenPopup: false,
    isLoading: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleMailTo = val => {
    let data = val.filter(item => item.value.trim() !== '')
    this.setState({ emailTo: data })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.emailTo !== this.state.emailTo) {
      this.setState({ error: this.state.error })
      const error = {}
      const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      this.state.emailTo.forEach(item => {
        if (
          item.value &&
          item.value.trim().length > 0 &&
          !re.test(item.value)
        ) {
          error.validationEmailTo = MESSAGE.TEXT_MESSAGE_TYPE_EMAIL
        }
      })
      this.setState({ error: error })
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ error: this.state.error })

    const error = {}
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (this.state.emailTo.length === 0) {
      error.emailTo = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    for (let index = 0; index < this.state.emailTo.length; index++) {
      const element = this.state.emailTo[index]
      if (
        element.value &&
        element.value.trim().length > 0 &&
        !re.test(element.value.trim())
      ) {
        error.validationEmailTo = MESSAGE.TEXT_MESSAGE_TYPE_EMAIL
        break
      }
    }
    if (this.state.title.trim().length === 0) {
      error.title = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error: error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      this.props.onSubmit(
        'GuiEmail',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false,
        null
      )
      return
    }

    const MailTo = []
    this.state.emailTo.map(item => MailTo.push(item.value))

    this.setState({ isLoading: true })
    // Convert data fileDinhKem
    let fileDinhKem = []
    ;(await this.state.fileDinhKem) &&
      this.state.fileDinhKem.length !== 0 &&
      this.state.fileDinhKem.forEach(item => {
        return fileDinhKem.push({
          file_id: item.fileId,
          ten_file: item.tenFile,
          url: item.url,
        })
      })
    await this.props.action
      .postMail({
        email_to: MailTo,
        title: this.state.title,
        content: this.state.content,
        file_id: this.state.file,
        file_dinh_kem: fileDinhKem,
      })
      .then(res => {
        let msg = ''
        if (res && res.payload && res.payload.status === 200) {
          msg = 'Gửi thành công!'
          this.setState({ isLoading: false })
          this.props.onSubmit('GuiEmail', msg, Intent.SUCCESS, true, null)
        } else {
          msg = 'Gửi không thành công'
          this.setState({ isLoading: false })
          this.props.onSubmit('GuiEmail', msg, Intent.DANGER, false, null)
        }
      })
  }

  handleTextCreator(input) {
    return `Kết quả tìm kiếm: "${input}"`
  }

  getOptions = (input, callback) => {
    const checkInput = input.indexOf('.')
    if (input.trim().length > 2 && checkInput < 0) {
      if (this.promiseGetEmail) {
        clearTimeout(this.promiseGetEmail)
      }
      this.promiseGetEmail = setTimeout(() => {
        this.props.action
          .getMail(`${input}`)
          .then(response => {
            return response.payload.data.result.items
          })
          .then(json => {
            const data = json.map(item => ({
              value: item.email,
              label: ['<', item.email, ' ', item.tenNhanVien, '>'],
            }))
            callback(null, {
              options: data,
              complete: true,
            })
          })
      }, 800)
    } else if (input.length < 3) {
      callback(null, {
        options: [],
        complete: true,
      })
    } else {
      callback(null, {
        options: [],
        complete: true,
      })
    }
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const fileNoiDungFull =
      this.props.receiveDocument.fileNoiDung ||
      this.props.receiveDocument.fileCongVan
    const extension =
      fileNoiDungFull &&
      fileNoiDungFull.tenFile &&
      fileNoiDungFull.tenFile
        .slice(((fileNoiDungFull.tenFile.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>Gửi email</h4>
        </div>
        <div className="form-container container-detail">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-12 col-xs-12">
                Gửi đến email
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <Select.AsyncCreatable
                    name="form-field-name"
                    value={this.state.emailTo}
                    loadOptions={this.getOptions}
                    onChange={this.handleMailTo}
                    autofocus={true}
                    autoload={false}
                    multi={true}
                    placeholder="Nhập email"
                    searchPromptText="Nhập email...."
                    loadingPlaceholder="Đang tìm kiếm...."
                    promptTextCreator={this.handleTextCreator}
                    noResultsText="Không tìm thấy dữ liệu..."
                    clearValueText="Xóa dữ liệu"
                  />
                </div>
                {this.state.error.emailTo !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.emailTo}
                  </div>
                )}
                {this.state.error.validationEmailTo !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.validationEmailTo}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-12 col-xs-12">
                Tiêu đề
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <input
                    className="pt-input"
                    placeholder="Nhập tiêu đề"
                    type="text"
                    dir="auto"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.title}
                  />
                </div>
                {this.state.error.title !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.title}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-12 col-xs-12">Nội dung</label>
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nội dung…"
                    dir="auto"
                    name="content"
                    onChange={this.handleChange}
                    value={this.state.content}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            {fileNoiDungFull && (
              <div className="row">
                <label className="pt-label col-md-12 col-xs-12">
                  File tham khảo:
                </label>
                <div className="pt-form-content col-md-12 col-xs-12">
                  <div className="pt-form-content">
                    <span className="info">
                      {!fileNoiDungFull.migrated && (
                        <a
                          style={{
                            backgroundImage: `url(${Tool.Utils.typeFile(
                              extension
                            )})`,
                          }}
                          className="link-file"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${Types.FILE_URL}/api/file/get/${fileNoiDungFull.fileId}?BearerToken=${token}`}
                        >
                          {fileNoiDungFull.tenFile +
                            ' - ' +
                            Tool.Utils.getFileSize(fileNoiDungFull.kichThuoc)}
                        </a>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* File đính kèm */}
            {this.props.receiveDocument &&
              this.props.receiveDocument.fileDinhKem &&
              this.props.receiveDocument.fileDinhKem.length !== 0 && (
                <div className="row">
                  <label className="pt-label col-md-12 col-xs-12">
                    File đính kèm:
                  </label>
                  <div className="pt-form-content col-md-12 col-xs-12">
                    <FileList
                      auth={this.props.auth}
                      receiveDocument={this.props.receiveDocument}
                    />
                  </div>
                </div>
              )}
            {/* File đính kèm EVN */}
            {this.props.receiveDocument &&
              this.props.receiveDocument.fileDinhKemEvn &&
              this.props.receiveDocument.fileDinhKemEvn.length !== 0 && (
                <div className="row">
                  <label className="pt-label col-md-12 col-xs-12">
                    File đính kèm:
                  </label>
                  <div className="pt-form-content col-md-12 col-xs-12">
                    <FileList
                      auth={this.props.auth}
                      receiveDocument={this.props.receiveDocument}
                      field="fileDinhKemEvn"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className="pt-icon icon-back"></span>
            Quay lại
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-main-color"
            loading={this.state.isLoading}
          >
            <span className="text-content">Gửi email</span>
            <span className="pt-icon icon-Send-Email"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default SendMail
