import { schema } from 'normalizr'

const AssignmentInformation = new schema.Entity(
  'assignmentInformation',
  {},
  {
    idAttribute: assignmentInformation => assignmentInformation.phieuGiaoViecId,
  }
)

export default AssignmentInformation
