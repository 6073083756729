import { Button, Intent } from '@blueprintjs/core'
import { UploadMultiple } from 'components/common'
import { MasterLayout } from 'components/layout'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import ChonDsNguoiThamGia from './ChonDsNguoiThamGia'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { TIME_REMINDER } from 'constants/Enum'
import { debounce, get, isEmpty, last, uniqBy } from 'lodash'
import { allowDocumentFileTypesLimit } from 'constants/fileTypes'
import { showToast } from 'actions/task'
import * as MESSAGE from 'constants/MessageForm'
import { renderClassStatus, renderTrangThai } from 'helpers/Helper'
import ModalDanhSachNguoiThamGia from './ModalDanhSachNguoiThamGia'
import Select from 'react-select'
import Title from 'components/ui/title'

const ThemMoiLichHopPage = props => {
  const [formData, setFormData] = useState({
    theLoaiId: '',
    mucDoId: '',
    batDau: null,
    ketThuc: null,
    loaiThongBao: 0,
    loaiNhacNho: 0,
    nhacNho: 15,
    chuTri: '',
    cuocHopThanhPhan: [],
    tieuDe: '',
    diaDiemId: '',
    noiDung: '',
    fileDinhKem: [],
    cuocHopHauCan: [],
    notiEmail: false,
    notiSystem: false,
  })
  const [dsNguoiThamGia, setDsNguoiThamGia] = useState([])
  const [dsNguoiDamTrach, setDsNguoiDamTrach] = useState([])
  const [dsNguoiThamGiaSelected, setDsNguoiThamGiaSelected] = useState([])
  const [dsNguoiDamTrachSelected, setDsNguoiDamTrachSelected] = useState([])
  const [dsNguoiXacNhanThamGia, setDsNguoiXacNhanThamGia] = useState([])
  const [dataFiles, setDataFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSubmitSend, setIsLoadingSubmitSend] = useState(false)
  const [error, setError] = useState(null)
  const [status, setStatus] = useState(0)
  const [showPopupXnThamGia, setShowPopupXnThamGia] = useState(false)
  const [treeChonNguoiThamGia, setTreeChonNguoiThamGia] = useState([])
  const [textSearch, setTextSearch] = useState('')
  const [idsToggle, setIdsToggle] = useState([])
  const [dsDonViPhongBanSelected, setDsDonViPhongBanSelected] = useState([])
  const [showDropNguoiChuTri, setShowDropNguoiChuTri] = useState(false)
  const [listEmployees, setListEmployees] = useState([])

  const idDetail = props.match.params.id
  const donViId = props.auth.mainUnitId
  const [donViSearch, setDonViSearch] = useState(donViId)
  const ref = useRef()
  const refInputChuTri = useRef()

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        refInputChuTri.current &&
        !refInputChuTri.current.contains(event.target) &&
        showDropNguoiChuTri
      ) {
        setShowDropNguoiChuTri(false)
      }
    }
    if (showDropNguoiChuTri) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, refInputChuTri, showDropNguoiChuTri])

  const handleChangeValue = (name, value) => {
    if (name === 'chuTri' && !showDropNguoiChuTri) {
      setShowDropNguoiChuTri(true)
    }
    setFormData({
      ...formData,
      [name]: value,
    })
    setError(null)
  }

  const getAllIdsDvPb = tree => {
    let ids = []

    const traverse = item => {
      if (item.donViId) {
        ids.push(item.donViId)
      }
      if (item.phongBanId) {
        ids.push(item.phongBanId)
      }
      if (item.dsDonViCon) {
        item.dsDonViCon.forEach(subItem => traverse(subItem))
      }
      if (item.dsPhongBan) {
        item.dsPhongBan.forEach(subItem => traverse(subItem))
      }
      if (item.dsPhongBanCon) {
        item.dsPhongBanCon.forEach(subItem => traverse(subItem))
      }
    }

    tree.forEach(rootItem => traverse(rootItem))
    return ids
  }

  const handleChangeTextsearch = debounce(value => {
    setTextSearch(value)
  }, 500)

  const handleChangeNguoiDamTrach = value => {
    setDsNguoiDamTrachSelected(value)
  }

  const handleCheckboxChange = (item, checked) => {
    let listIdsDonViPhongBan = dsDonViPhongBanSelected
    let listNhanVien = dsNguoiThamGiaSelected

    const processItem = (item, checked) => {
      if (checked) {
        if (item?.donViId && !item?.phongBanId) {
          listIdsDonViPhongBan.push(item.donViId)
        }
        if (item?.phongBanId) {
          listIdsDonViPhongBan.push(item.phongBanId)
        }
        if (item?.dsNhanVien) {
          listNhanVien = [...listNhanVien, ...item.dsNhanVien]
        }
        if (item?.nhanVienId) {
          listNhanVien = [...listNhanVien, item]
        }
      } else {
        if (item?.donViId && !item?.phongBanId) {
          listIdsDonViPhongBan = listIdsDonViPhongBan.filter(
            id => id !== item.donViId
          )
        }
        if (item?.phongBanId) {
          listIdsDonViPhongBan = listIdsDonViPhongBan.filter(
            id => id !== item.phongBanId
          )
        }
        if (item?.dsNhanVien) {
          listNhanVien = listNhanVien.filter(
            nv => !item.dsNhanVien.some(i => i.nhanVienId === nv.nhanVienId)
          )
        }
        if (item?.nhanVienId) {
          listNhanVien = listNhanVien?.filter(
            nv => nv?.nhanVienId !== item?.nhanVienId
          )
        }
      }

      if (item?.dsDonViCon) {
        item.dsDonViCon.forEach(dv => processItem(dv, checked))
      }
      if (item?.dsPhongBan) {
        item.dsPhongBan.forEach(pb => processItem(pb, checked))
      }
      if (item?.dsPhongBanCon) {
        item.dsPhongBanCon.forEach(pb => processItem(pb, checked))
      }
    }

    processItem(item, checked)
    setDsDonViPhongBanSelected(uniqBy(listIdsDonViPhongBan))
    setDsNguoiThamGiaSelected(uniqBy(listNhanVien, 'nhanVienId'))
  }

  const handleRemoveNguoiThamGia = nhanVienId => {
    setDsNguoiThamGiaSelected(
      dsNguoiThamGiaSelected?.filter(item => item?.nhanVienId !== nhanVienId)
    )
  }

  const handleRemoveAllNguoiThamGia = () => {
    setDsNguoiThamGiaSelected([])
  }

  const handleToggle = (toggle, id) => {
    if (toggle) {
      setIdsToggle([...idsToggle, id])
    } else {
      setIdsToggle(idsToggle?.filter(item => item !== id))
    }
  }

  const handleGetDsDonViMultilevel = () => {
    props.actions.getCategoryUnitMultiLevel(props.auth.mainUnitId).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsLoading(false)
      }
    })
  }

  const hanldeGetDsNguoiThamGia = () => {
    const params = {
      page: 1,
      limit: 999,
      quyen: 'LICHHOP_HAUCAN',
    }
    props.actions.getHoiDongThanhVien(donViId, params).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsLoading(false)
      } else {
        setDsNguoiThamGia(res?.payload?.data?.result?.items)
        setDsNguoiDamTrach(
          res?.payload?.data?.result?.items?.map(item => ({
            value: item?.nhanVienId,
            label: item?.tenNhanVien,
          }))
        )
      }
    })
  }

  const handleGetDanhDonViPhongBanNhanVienById = async id => {
    const response = await props.actions.getDanhDonViPhongBanNhanVienById(id)
    const dataChild = response?.payload?.data?.result
    return dataChild
  }

  const getAllEmployees = tree => {
    let employees = []

    const traverse = node => {
      if (!node) return

      if (node.dsNhanVien) {
        employees = employees.concat(node.dsNhanVien)
      }

      if (node.dsPhongBan) {
        node.dsPhongBan.forEach(dept => traverse(dept))
      }

      if (node.dsPhongBanCon) {
        node.dsPhongBanCon.forEach(subDept => traverse(subDept))
      }

      if (node.dsDonViCon) {
        node.dsDonViCon.forEach(subUnit => traverse(subUnit))
      }
    }

    tree.forEach(rootNode => traverse(rootNode))
    return employees
  }

  const handleGetDsDonViCapMot = async () => {
    props.actions.getDanhSachDonViCapMot().then(async res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsLoading(false)
      } else {
        const dataDonViCapMot = res.payload.data.result
        if (!isEmpty(dataDonViCapMot)) {
          const resultPromises = dataDonViCapMot?.map(async item => {
            const data = await handleGetDanhDonViPhongBanNhanVienById(
              item?.donViId
            )
            if (data) {
              return {
                ...item,
                ...data,
              }
            }
            return item
          })
          const result = await Promise.all(resultPromises)
          setListEmployees(getAllEmployees(result))
          setTreeChonNguoiThamGia(result)
          setIdsToggle(getAllIdsDvPb(result))
        }
      }
    })
  }

  const handleRemoveFile = index => {
    const result = dataFiles?.filter((item, i) => index !== i)
    setDataFiles(result)
  }

  const handleFileInput = e => {
    let dataFilesNew = []
    let errorFile = false
    Array.from(e.target.files).forEach(item => {
      const extension = last(item.name.split('.'))
      if (extension && allowDocumentFileTypesLimit.includes(extension)) {
        dataFilesNew.push(item)
      } else {
        errorFile = true
      }
    })
    setDataFiles([...dataFilesNew, ...dataFiles])
    if (errorFile) {
      showToast({
        message: MESSAGE.TEXT_TYPE_FILE_UPLOAD_REFERENCES,
        intent: Intent.DANGER,
      })
    }
  }

  useEffect(() => {
    props.actions.getDanhSachLoaiCuocHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsLoading(false)
      }
    })
    props.actions.getDanhSachCapDoCuocHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsLoading(false)
      }
    })
    props.actions.getDanhSachDiaDiemHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsLoading(false)
      }
    })
    handleGetDsDonViMultilevel()
    handleGetDsDonViCapMot()
    // eslint-disable-next-line
  }, [])

  const handleBack = () => {
    props.history.goBack()
  }

  const findDepartmentAndUnitIds = (tree, listNhanVien = []) => {
    const employeeIds = new Set(listNhanVien.map(nv => nv.nhanVienId))
    let result = []

    const traverseTree = nodes => {
      nodes.forEach(node => {
        let allEmployeesInUnit = true
        if (node.dsPhongBan) {
          node.dsPhongBan.forEach(phongBan => {
            const allEmployeesInPhongBan = phongBan.dsNhanVien.every(nv =>
              employeeIds.has(nv.nhanVienId)
            )
            if (allEmployeesInPhongBan) {
              result.push(phongBan.phongBanId)
            } else {
              allEmployeesInUnit = false
            }
          })
        }
        if (
          allEmployeesInUnit &&
          node.dsPhongBan &&
          node.dsPhongBan.length > 0
        ) {
          result.push(node.donViId)
        }
        if (node.dsDonViCon) {
          traverseTree(node.dsDonViCon)
        }
      })
    }

    traverseTree(tree)
    return result
  }

  const handleGetChiTietLichHop = async () => {
    const res = await props.actions.getChiTietLichHop(idDetail)
    if (!res.error && res?.payload?.data?.result) {
      const dataDetail = res.payload.data.result
      setFormData({
        theLoaiId: dataDetail?.theLoai?.theLoaiId,
        mucDoId: dataDetail?.mucDo?.mucDoId,
        batDau: dataDetail?.batDau ? moment(dataDetail?.batDau) : null,
        ketThuc: dataDetail?.ketThuc ? moment(dataDetail?.ketThuc) : null,
        loaiThongBao: dataDetail?.loaiThongBao,
        notiEmail:
          dataDetail?.loaiThongBao === 1 || dataDetail?.loaiThongBao === 3,
        notiSystem:
          dataDetail?.loaiThongBao === 2 || dataDetail?.loaiThongBao === 3,
        loaiNhacNho: dataDetail?.loaiNhacNho === 2,
        nhacNho: dataDetail?.nhacNho,
        chuTri: dataDetail?.chuTri,
        tieuDe: dataDetail?.tieuDe,
        diaDiemId: dataDetail?.diaDiem?.diaDiemId,
        noiDung: dataDetail?.noiDung,
      })
      setStatus(dataDetail?.trangThai)
      const listNv = dataDetail?.cuocHopThanhPhan?.map(item => item?.nhanVien)
      setDsNguoiThamGiaSelected(listNv)
      setDsNguoiDamTrachSelected(
        dataDetail?.cuocHopHauCan?.map(item => ({
          label: item?.nhanVien?.tenNhanVien,
          value: item?.nhanVien?.nhanVienId,
        }))
      )
      setDataFiles(dataDetail?.dsFileDinhKem ?? [])
      setDsNguoiXacNhanThamGia(
        dataDetail?.cuocHopThanhPhan?.map(item => ({
          ...item?.nhanVien,
          thamGia: item.thamGia,
        }))
      )
    }
  }

  const handleSubmit = async (status = 0, type) => {
    const setLoading = type === 'save' ? setIsLoading : setIsLoadingSubmitSend
    const error = {}
    if (!formData?.theLoaiId) {
      error.theLoaiId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (!formData?.mucDoId) {
      error.mucDoId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (!formData?.batDau) {
      error.thoiGianDienRa = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (!formData?.chuTri || formData?.chuTri?.trim().length === 0) {
      error.chuTri = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (isEmpty(dsNguoiThamGiaSelected)) {
      error.cuocHopThanhPhan = 'Chọn người tham gia'
    }
    if (!formData?.tieuDe || formData?.tieuDe?.trim().length === 0) {
      error.tieuDe = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (!formData?.diaDiemId) {
      error.diaDiemId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    setLoading(true)
    setError(error)
    if (Object.keys(error).length !== 0) {
      setLoading(false)
      return
    }
    if (!isEmpty(dataFiles)) {
      const filesUploaded = dataFiles?.filter(item => item.id)
      const filesNews = dataFiles?.filter(item => !item.id)
      setLoading(true)
      if (!isEmpty(filesNews)) {
        await props.actions.fileUpload(filesNews, null).then(res => {
          if (
            res.error ||
            !res.payload ||
            !res.payload.data ||
            res.payload.data.result === false
          ) {
            setLoading(false)
            return showToast({
              message:
                res?.error?.response?.data?.message ||
                MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }

          const dataRequest = {
            trang_thai: status,
            dia_diem_id: formData.diaDiemId,
            tieu_de: formData.tieuDe?.trim(),
            the_loai_id: formData.theLoaiId,
            muc_do_id: formData.mucDoId,
            chu_tri: formData.chuTri?.trim(),
            bat_dau: formData.batDau
              ? moment(formData.batDau).format()
              : undefined,
            ket_thuc: formData.ketThuc
              ? moment(formData.ketThuc).format()
              : undefined,
            noi_dung: formData.noiDung?.trim(),
            file_dinh_kem: [
              ...get(res.payload, 'data.result'),
              ...filesUploaded,
            ],
            loai_thong_bao:
              formData.notiEmail && formData.notiSystem
                ? 3
                : formData.notiEmail
                ? 1
                : formData.notiSystem
                ? 2
                : 0,
            loai_nhac_nho: formData.loaiNhacNho ? 2 : 0,
            nhac_nho: formData.loaiNhacNho ? Number(formData.nhacNho) : 0,
            cuoc_hop_thanh_phan: dsNguoiThamGiaSelected?.map(item => ({
              nhan_vien_id: item.nhanVienId,
            })),
            cuoc_hop_hau_can: dsNguoiDamTrachSelected?.map(item => ({
              nhan_vien_id: item.value,
            })),
          }
          if (!props.match.params.id) {
            return props.actions
              .postLichHop(dataRequest)
              .then(res => {
                if (
                  res.error ||
                  !res.payload ||
                  !res.payload.data ||
                  res.payload.data.result === false
                ) {
                  setLoading(false)
                  return showToast({
                    message:
                      res?.error?.response?.data?.message ||
                      MESSAGE.TOATS_MESSAGE_FAIL,
                    intent: Intent.DANGER,
                  })
                }
                showToast({
                  message: MESSAGE.TOATS_MESSAGE_SUCCESS,
                  isSuccess: true,
                  intent: Intent.SUCCESS,
                })
                return handleBack()
              })
              .catch(e => {
                return showToast({
                  message: MESSAGE.TOATS_MESSAGE_FAIL,
                  intent: Intent.DANGER,
                })
              })
          }
          return props.actions
            .putLichHop(idDetail, dataRequest)
            .then(res => {
              if (
                res.error ||
                !res.payload ||
                !res.payload.data ||
                res.payload.data.result === false
              ) {
                setLoading(false)
                return showToast({
                  message:
                    res?.error?.response?.data?.message ||
                    MESSAGE.TOATS_MESSAGE_FAIL,
                  intent: Intent.DANGER,
                })
              }
              showToast({
                message: MESSAGE.TOATS_MESSAGE_SUCCESS,
                isSuccess: true,
                intent: Intent.SUCCESS,
              })
              return handleBack()
            })
            .catch(e => {
              return showToast({
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            })
        })
      } else {
        const dataRequest = {
          trang_thai: status,
          dia_diem_id: formData.diaDiemId,
          tieu_de: formData.tieuDe?.trim(),
          the_loai_id: formData.theLoaiId,
          muc_do_id: formData.mucDoId,
          chu_tri: formData.chuTri?.trim(),
          bat_dau: formData.batDau
            ? moment(formData.batDau).format()
            : undefined,
          ket_thuc: formData.ketThuc
            ? moment(formData.ketThuc).format()
            : undefined,
          noi_dung: formData.noiDung?.trim(),
          ds_file_dinh_kem: [...filesUploaded],
          loai_thong_bao:
            formData.notiEmail && formData.notiSystem
              ? 3
              : formData.notiEmail
              ? 1
              : formData.notiSystem
              ? 2
              : 0,
          loai_nhac_nho: formData.loaiNhacNho ? 2 : 0,
          nhac_nho: formData.loaiNhacNho ? Number(formData.nhacNho) : 0,
          cuoc_hop_thanh_phan: dsNguoiThamGiaSelected?.map(item => ({
            nhan_vien_id: item.nhanVienId,
          })),
          cuoc_hop_hau_can: dsNguoiDamTrachSelected?.map(item => ({
            nhan_vien_id: item.value,
          })),
        }
        if (!idDetail) {
          return props.actions
            .postLichHop(dataRequest)
            .then(res => {
              if (
                res.error ||
                !res.payload ||
                !res.payload.data ||
                res.payload.data.result === false
              ) {
                setLoading(false)
                return showToast({
                  message:
                    res?.error?.response?.data?.message ||
                    MESSAGE.TOATS_MESSAGE_FAIL,
                  intent: Intent.DANGER,
                })
              }
              showToast({
                message: MESSAGE.TOATS_MESSAGE_SUCCESS,
                isSuccess: true,
                intent: Intent.SUCCESS,
              })
              return handleBack()
            })
            .catch(e => {
              return showToast({
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            })
        }
        return props.actions
          .putLichHop(idDetail, dataRequest)
          .then(res => {
            if (
              res.error ||
              !res.payload ||
              !res.payload.data ||
              res.payload.data.result === false
            ) {
              setLoading(false)
              return showToast({
                message:
                  res?.error?.response?.data?.message ||
                  MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            }
            showToast({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              isSuccess: true,
              intent: Intent.SUCCESS,
            })
            return handleBack()
          })
          .catch(e => {
            return showToast({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      }
    } else {
      const dataRequest = {
        trang_thai: status,
        dia_diem_id: formData.diaDiemId,
        tieu_de: formData.tieuDe?.trim(),
        the_loai_id: formData.theLoaiId,
        muc_do_id: formData.mucDoId,
        chu_tri: formData.chuTri?.trim(),
        bat_dau: formData.batDau ? moment(formData.batDau).format() : undefined,
        ket_thuc: formData.ketThuc
          ? moment(formData.ketThuc).format()
          : undefined,
        noi_dung: formData.noiDung?.trim(),
        ds_file_dinh_kem: null,
        loai_thong_bao:
          formData.notiEmail && formData.notiSystem
            ? 3
            : formData.notiEmail
            ? 1
            : formData.notiSystem
            ? 2
            : 0,
        loai_nhac_nho: formData.loaiNhacNho ? 2 : 0,
        nhac_nho: formData.loaiNhacNho ? Number(formData.nhacNho) : 0,
        cuoc_hop_thanh_phan: dsNguoiThamGiaSelected?.map(item => ({
          nhan_vien_id: item.nhanVienId,
        })),
        cuoc_hop_hau_can: dsNguoiDamTrachSelected?.map(item => ({
          nhan_vien_id: item.value,
        })),
      }
      if (!idDetail) {
        return props.actions
          .postLichHop(dataRequest)
          .then(res => {
            if (
              res.error ||
              !res.payload ||
              !res.payload.data ||
              res.payload.data.result === false
            ) {
              setLoading(false)
              return showToast({
                message:
                  res?.error?.response?.data?.message ||
                  MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            }
            showToast({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              isSuccess: true,
              intent: Intent.SUCCESS,
            })
            return handleBack()
          })
          .catch(e => {
            return showToast({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      }
      return props.actions
        .putLichHop(idDetail, dataRequest)
        .then(res => {
          if (
            res.error ||
            !res.payload ||
            !res.payload.data ||
            res.payload.data.result === false
          ) {
            setLoading(false)
            return showToast({
              message:
                res?.error?.response?.data?.message ||
                MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
          showToast({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            isSuccess: true,
            intent: Intent.SUCCESS,
          })
          return handleBack()
        })
        .catch(e => {
          return showToast({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        })
    }
  }

  useEffect(() => {
    if (donViId) {
      hanldeGetDsNguoiThamGia()
    }
    // eslint-disable-next-line
  }, [donViId])

  useEffect(() => {
    if (idDetail) {
      handleGetChiTietLichHop()
    }
    // eslint-disable-next-line
  }, [idDetail])

  useEffect(() => {
    if (!isEmpty(treeChonNguoiThamGia)) {
      setDsDonViPhongBanSelected(
        findDepartmentAndUnitIds(treeChonNguoiThamGia, dsNguoiThamGiaSelected)
      )
    }
  }, [treeChonNguoiThamGia, dsNguoiThamGiaSelected])

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name={idDetail ? 'Chỉnh sửa lịch họp' : 'Tạo mới lịch họp'}
        icon={idDetail ? 'icon-edit-v2' : 'icon-s-plus'}
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper form-calendar mt10">
        <div className="form-container">
          <h3 className="title-block-form">Thông tin cuộc họp</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">
                  Tiêu đề
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="tieuDe"
                    value={formData?.tieuDe}
                    className="pt-input"
                    placeholder="Nhập tiêu đề"
                    type="text"
                    dir="auto"
                    onChange={e => handleChangeValue('tieuDe', e.target.value)}
                    maxLength={250}
                  />
                </div>
                {error?.tieuDe !== undefined && (
                  <div className="pt-form-helper-text">{error.tieuDe}</div>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">
                  Địa điểm
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="diaDiemId"
                      onChange={e =>
                        handleChangeValue('diaDiemId', e.target.value)
                      }
                      value={formData?.diaDiemId}
                    >
                      <option value="">Chọn địa điểm họp</option>
                      {props?.dsDiaDiemHopCombobox?.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {error?.diaDiemId !== undefined && (
                  <div className="pt-form-helper-text">{error.diaDiemId}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">
                  Loại
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="theLoaiId"
                      onChange={e =>
                        handleChangeValue('theLoaiId', e.target.value)
                      }
                      value={formData?.theLoaiId}
                    >
                      <option value="">Chọn loại cuộc họp</option>
                      {props?.dsLoaiCuocHopCombobox?.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {error?.theLoaiId !== undefined && (
                  <div className="pt-form-helper-text">{error.theLoaiId}</div>
                )}
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">
                  Cấp độ
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="mucDoId"
                      onChange={e =>
                        handleChangeValue('mucDoId', e.target.value)
                      }
                      value={formData?.mucDoId}
                    >
                      <option value="">Chọn cấp độ cuộc họp</option>
                      {props?.dsCapDoCuocHopCombobox?.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {error?.mucDoId !== undefined && (
                  <div className="pt-form-helper-text">{error.mucDoId}</div>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">
                  Thời gian diễn ra
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <div className="pt-input-group ranger-picker">
                    <DatePicker
                      selected={formData?.batDau}
                      onChange={date => handleChangeValue('batDau', date)}
                      selectsStart
                      startDate={formData?.batDau}
                      endDate={formData?.ketThuc}
                      maxDate={formData?.ketThuc}
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      showTimeSelect
                      dateFormat="DD/MM/YYYY HH:mm"
                      timeFormat="HH:mm"
                    />
                    <DatePicker
                      selected={formData?.ketThuc}
                      onChange={date => handleChangeValue('ketThuc', date)}
                      selectsEnd
                      startDate={formData?.batDau}
                      endDate={formData?.ketThuc}
                      minDate={formData?.batDau}
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      showTimeSelect
                      dateFormat="DD/MM/YYYY HH:mm"
                      timeFormat="HH:mm"
                    />
                  </div>
                </div>
                {error?.thoiGianDienRa !== undefined && (
                  <div className="pt-form-helper-text">
                    {error.thoiGianDienRa}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">
                  Chủ trì
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content host-person">
                  <input
                    name="chuTri"
                    value={formData?.chuTri}
                    className="pt-input"
                    placeholder="Nhập hoặc chọn danh sách"
                    type="text"
                    dir="auto"
                    onChange={e => handleChangeValue('chuTri', e.target.value)}
                    maxLength="100"
                    onClick={() => {
                      setShowDropNguoiChuTri(!showDropNguoiChuTri)
                    }}
                    ref={refInputChuTri}
                    autoComplete="off"
                  />
                  {showDropNguoiChuTri && (
                    <div ref={ref} className="host-person-drop">
                      {listEmployees
                        ?.filter(i =>
                          i.tenNhanVien
                            ?.toLowerCase()
                            ?.includes(formData?.chuTri?.toLowerCase())
                        )
                        ?.map((item, index) => (
                          <div
                            className="host-person-item"
                            key={index}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                chuTri: item?.tenNhanVien,
                              })
                              setShowDropNguoiChuTri(false)
                            }}
                          >
                            {item?.tenNhanVien}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                {error?.chuTri !== undefined && (
                  <div className="pt-form-helper-text">{error.chuTri}</div>
                )}
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Cài đặt thông báo</label>
                <div className="pt-form-content">
                  <div className="pt-input-group ranger-picker">
                    <div className="selectbox-group">
                      <label className="pt-control pt-checkbox pt-inline form-item-wrap">
                        <input
                          type="checkbox"
                          name="thongBaoEmail"
                          checked={formData?.notiEmail}
                          onChange={e =>
                            handleChangeValue('notiEmail', e.target.checked)
                          }
                        />
                        <span className="pt-control-indicator"></span>
                        <span className="name_checkbox">Thông báo email</span>
                      </label>
                    </div>
                    <div className="selectbox-group">
                      <label className="pt-control pt-checkbox pt-inline form-item-wrap">
                        <input
                          type="checkbox"
                          name="thongBaoHeThong"
                          checked={formData?.notiSystem}
                          onChange={e =>
                            handleChangeValue('notiSystem', e.target.checked)
                          }
                        />
                        <span className="pt-control-indicator"></span>
                        <span className="name_checkbox">Thông báo mobile</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <div className="pt-form-content">
                  <div className="pt-input-group reminder-selection">
                    <div className="selectbox-group">
                      <label className="pt-control pt-checkbox pt-inline form-item-wrap">
                        <input
                          type="checkbox"
                          name="loaiNhacNho"
                          checked={formData?.loaiNhacNho}
                          onChange={e =>
                            handleChangeValue('loaiNhacNho', e.target.checked)
                          }
                        />
                        <span className="pt-control-indicator"></span>
                        <span className="name_checkbox font-bold">
                          Nhắc nhở
                        </span>
                      </label>
                    </div>
                    <div className="pt-select">
                      <select
                        name="nhacNho"
                        onChange={e =>
                          handleChangeValue('nhacNho', e.target.value)
                        }
                        value={formData?.nhacNho}
                        disabled={!formData?.loaiNhacNho}
                      >
                        {TIME_REMINDER?.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Nội dung</label>
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    value={formData?.noiDung}
                    rows="5"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung"
                    dir="auto"
                    onChange={e => handleChangeValue('noiDung', e.target.value)}
                    maxLength={5000}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <UploadMultiple
                dataFiles={dataFiles}
                handleFileInput={handleFileInput}
                handleRemoveFile={handleRemoveFile}
              />
            </div>
            <div className="row">
              {!!idDetail && (
                <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                  <label className="pt-label">Trạng thái</label>
                  <div className="pt-form-content">
                    <span
                      className={`status-calendar text-content ${renderClassStatus(
                        status
                      )}`}
                    >
                      {renderTrangThai(status)}
                    </span>
                  </div>
                </div>
              )}
              {status === 1 && (
                <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                  <label className="pt-label">Danh sách người mời họp</label>
                  <div className="pt-form-content">
                    <span className="total-person">
                      <span className="total-person__number">
                        {dsNguoiXacNhanThamGia?.length || 0}
                      </span>
                      {dsNguoiXacNhanThamGia?.length > 0 && (
                        <span
                          className="total-person__more"
                          onClick={() => setShowPopupXnThamGia(true)}
                        >
                          Xem danh sách
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                  <label className="pt-label">
                    Người tham gia
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <ChonDsNguoiThamGia
                      dsNguoiThamGia={dsNguoiThamGia}
                      dsNguoiThamGiaSelected={dsNguoiThamGiaSelected}
                      handleRemoveNguoiThamGia={handleRemoveNguoiThamGia}
                      handleChangeTextsearch={handleChangeTextsearch}
                      textSearch={textSearch}
                      treeChonNguoiThamGia={treeChonNguoiThamGia}
                      handleToggle={handleToggle}
                      idsToggle={idsToggle}
                      handleCheckboxChange={handleCheckboxChange}
                      dsDonViPhongBanSelected={dsDonViPhongBanSelected}
                      units={props.unit.items}
                      donViSearch={donViSearch}
                      setDonViSearch={setDonViSearch}
                      handleRemoveAllNguoiThamGia={handleRemoveAllNguoiThamGia}
                    />
                    {error?.cuocHopThanhPhan !== undefined && (
                      <div className="pt-form-helper-text">
                        {error.cuocHopThanhPhan}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className="title-block-form bt">Thông tin hậu cần</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Người chuẩn bị</label>
                <Select
                  multi
                  options={dsNguoiDamTrach}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Chọn người chuẩn bị"
                  isSearchable={true}
                  value={dsNguoiDamTrachSelected}
                  onChange={e => handleChangeNguoiDamTrach(e)}
                  noResultsText="Không có kết quả"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={isLoading || isLoadingSubmitSend}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <div>
            {(!idDetail || (idDetail && status !== 1)) && (
              <Button
                className="pt-button btn-main-color"
                onClick={() => handleSubmit(idDetail ? status : 0, 'save')}
                loading={isLoading}
                disabled={isLoadingSubmitSend}
              >
                <span className="text-content">Lưu</span>
                <span className="pt-icon icon-save"></span>
              </Button>
            )}
            <Button
              className="pt-button btn-green-color"
              onClick={() => handleSubmit(1, 'send')}
              loading={isLoadingSubmitSend}
              disabled={isLoading}
            >
              <span className="text-content">Lưu & gửi</span>
              <span className="pt-icon icon-send-2"></span>
            </Button>
          </div>
        </div>
      </div>
      <ModalDanhSachNguoiThamGia
        data={dsNguoiXacNhanThamGia}
        isOpen={showPopupXnThamGia}
        onClose={() => setShowPopupXnThamGia(false)}
      />
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
  dsLoaiCuocHopCombobox: state?.lichHop?.dsLoaiCuocHop?.map(item => ({
    value: item?.theLoaiId,
    name: item?.ten,
  })),
  dsCapDoCuocHopCombobox: state?.lichHop?.dsCapDoCuocHop?.map(item => ({
    value: item?.mucDoId,
    name: item?.ten,
  })),
  dsDiaDiemHopCombobox: state?.lichHop?.dsDiaDiemHop?.map(item => ({
    value: item?.diaDiemId,
    name: item?.ten,
  })),
  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThemMoiLichHopPage)
