import React, { useState } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import DatePicker from 'react-datepicker'
import Classnames from 'classnames'

import { documentType, yearsOptions } from './datatypes'

const Filter = ({
  filterKeys,
  listLanhDao,
  listPhongBan,
  listDonVi,
  listSoVanBan,
  listCongVan,
  listPhongBanXuLy,
  listChuyenVienXuLy,
  filter,
  onChange = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="search-container search-DocumentarySearch py-12 pr15 pl15">
      <div className="row">
        <div className="col-xs-12 col-sm-3 col-md-3 mt15">
          <label className="search-label">Năm</label>
          <div className="mt5 form-input-filter select-arrow border-radius-3">
            <select
              className="input border-transparent appearance-none"
              name={filterKeys?.nam}
              onChange={e =>
                onChange({ name: filterKeys?.nam })(e?.target?.value)
              }
              value={filter?.[filterKeys?.nam]}
            >
              <option value="">Tất cả</option>
              {yearsOptions()?.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3 mt15">
          <label className="search-label">Loại công văn</label>
          <div className="mt5 form-input-filter select-arrow border-radius-3">
            <select
              className="input border-transparent appearance-none"
              name={filterKeys?.loaiCongVan}
              onChange={e =>
                onChange({ name: filterKeys?.loaiCongVan })(e?.target?.value)
              }
              value={filter?.[filterKeys?.loaiCongVan]}
            >
              <option value="">Tất cả</option>
              {documentType.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 align-items-end mt15">
          <div className="mt5 form-input-filter flex-auto border-radius-3 position-gridview">
            <input
              autoFocus={false}
              autoComplete="off"
              className="input border-transparent input-search-filter"
              placeholder="Nhập trích yếu / số ký hiệu"
              type="text"
              dir="auto"
              name={filterKeys?.mainKeyWord}
              onChange={e =>
                onChange({ name: filterKeys?.mainKeyWord })(e?.target?.value)
              }
              value={filter?.[filterKeys?.mainKeyWord] ?? ''}
              maxLength={255}
            />
            {filter?.[filterKeys?.mainKeyWord] && (
              <i
                className="icon-close pt-close-gridview-search"
                onClick={() => onChange({ name: filterKeys?.mainKeyWord })('')}
              ></i>
            )}
            <Button
              type="button"
              className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              onClick={onChange({ name: 'SUBMIT', isReload: true })}
            >
              <span className="pt-icon pt-icon-search mr0"></span>
            </Button>
          </div>
          <Button
            onClick={onChange({ name: 'RESET' })}
            className="btn-icon flex-solid ml10 mr10 pt-intent-btn-white"
          >
            <span className="pt-icon icon-Loading mr0"></span>
          </Button>
          <Button
            onClick={() => setIsOpen(prev => !prev)}
            className="btn-icon flex-solid ml0 pt-intent-btn-white"
          >
            <span
              className={Classnames('pt-icon m0', {
                'pt-icon-double-chevron-up': isOpen,
                'pt-icon-double-chevron-down': !isOpen,
              })}
            ></span>
          </Button>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Lãnh đạo chỉ đạo</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.idLanhDaoChiDao}
                onChange={e =>
                  onChange({ name: filterKeys?.idLanhDaoChiDao })(
                    e?.target?.value
                  )
                }
                value={filter?.[filterKeys?.idLanhDaoChiDao]}
              >
                <option value={0}>Tất cả</option>
                {listLanhDao?.map((item, i) => (
                  <option key={i} value={item.iDPB}>
                    {item.tENPB}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Ban phòng ban hành</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.idPhongBanBanHanh}
                onChange={e =>
                  onChange({ name: filterKeys?.idPhongBanBanHanh })(
                    e?.target?.value
                  )
                }
                value={filter?.[filterKeys?.idPhongBanBanHanh]}
              >
                <option value={0}>Tất cả</option>
                {listPhongBan?.map((item, i) => (
                  <option key={i} value={item.iDPB}>
                    {item.tENPB}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Người ký ban hành</label>
            <div className="mt5 form-input-filter">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent"
                placeholder="Nhập trích yếu / số ký hiệu"
                type="text"
                dir="auto"
                name={filterKeys?.nguoiKyVB}
                onChange={e =>
                  onChange({ name: filterKeys?.nguoiKyVB })(e?.target?.value)
                }
                value={filter?.[filterKeys?.nguoiKyVB] ?? ''}
                maxLength={255}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Đơn vị nhận</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.noiNhan}
                onChange={e =>
                  onChange({ name: filterKeys?.noiNhan })(e?.target?.value)
                }
                value={filter?.[filterKeys?.noiNhan]}
              >
                <option value={'ALL'}>Tất cả</option>
                {listDonVi?.map((item, i) => (
                  <option key={i} value={item.mADV}>
                    {item.tENDV}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Từ ngày</label>
            <div className="mt5 form-input-filter border-radius-3">
              <DatePicker
                readOnly
                isClearable
                selected={filter?.[filterKeys?.dateFrom]}
                selectsStart
                startDate={filter?.[filterKeys?.dateFrom]}
                endDate={filter?.[filterKeys?.dateFrom]}
                onChange={value =>
                  onChange({ name: filterKeys?.dateFrom })(value)
                }
                format="DD/MM/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                placeholderText="Từ ngày"
                maxDate={filter?.[filterKeys?.dateFrom]}
                popperPlacement="auto"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Đến ngày</label>
            <div className="mt5 form-input-filter border-radius-3">
              <DatePicker
                readOnly
                isClearable
                selected={filter?.[filterKeys?.dateTo]}
                selectsStart
                startDate={filter?.[filterKeys?.dateTo]}
                endDate={filter?.[filterKeys?.dateTo]}
                onChange={value =>
                  onChange({ name: filterKeys?.dateTo })(value)
                }
                format="DD/MM/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                placeholderText="Đến ngày"
                maxDate={filter?.[filterKeys?.dateTo]}
                popperPlacement="auto"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Sổ văn bản</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.idSoVB}
                onChange={e =>
                  onChange({ name: filterKeys?.idSoVB })(e?.target?.value)
                }
                value={filter?.[filterKeys?.idSoVB]}
              >
                <option value={0}>Tất cả</option>
                {listSoVanBan?.map((item, i) => (
                  <option key={i} value={item.iDSOVB}>
                    {item.tENSOVB}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Loại văn bản</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.idLoaiVB}
                onChange={e =>
                  onChange({ name: filterKeys?.idLoaiVB })(e?.target?.value)
                }
                value={filter?.[filterKeys?.idLoaiVB]}
              >
                <option value={0}>Tất cả</option>
                {listCongVan?.map((item, i) => (
                  <option key={i} value={item.iDLOAIVB}>
                    {item.tENLOAIVB}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Đơn vị ban hành</label>
            <div className="mt5 form-input-filter">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent"
                placeholder="Nhập"
                type="text"
                dir="auto"
                name={filterKeys?.noiGui}
                onChange={e =>
                  onChange({ name: filterKeys?.noiGui })(e?.target?.value)
                }
                value={filter?.[filterKeys?.noiGui] ?? ''}
                maxLength={255}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Số đến</label>
            <div className="mt5 form-input-filter">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent"
                placeholder="Nhập"
                type="text"
                dir="auto"
                name={filterKeys?.soDen}
                onChange={e =>
                  onChange({ name: filterKeys?.soDen })(e?.target?.value)
                }
                value={filter?.[filterKeys?.soDen] ?? ''}
                maxLength={255}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Phòng ban xử lý</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.idPhongBanChuTri}
                onChange={e =>
                  onChange({ name: filterKeys?.idPhongBanChuTri })(
                    e?.target?.value
                  )
                }
                value={filter?.[filterKeys?.idPhongBanChuTri]}
              >
                <option value={0}>Tất cả</option>
                {listPhongBanXuLy?.map((item, i) => (
                  <option key={i} value={item.iDPB}>
                    {item.tENPB}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt15">
            <label className="search-label">Chuyên viên xử lý</label>
            <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
              <select
                className="input border-transparent appearance-none"
                name={filterKeys?.idChuyenVienXL}
                onChange={e =>
                  onChange({ name: filterKeys?.idChuyenVienXL })(
                    e?.target?.value
                  )
                }
                value={filter?.[filterKeys?.idChuyenVienXL]}
              >
                <option value={0}>Tất cả</option>
                {listChuyenVienXuLy?.map((item, i) => (
                  <option key={i} value={item.iDNV}>
                    {item.nAME}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default Filter
