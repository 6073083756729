import React from 'react'
import TimeLineItem from './TimeLineItem'

const QuanLyPheDuyet = ({ data }) => {
  return (
    <div>
      <ul className="cpc-timeline">
        {data?.nGUOITRINH && (
          <TimeLineItem itemData={data?.nGUOITRINH} type="NGUOI_TRINH" />
        )}
        {data?.nGUOIDUYET?.map?.((item, index) => (
          <TimeLineItem key={index} itemData={item} />
        ))}
      </ul>
    </div>
  )
}

export default QuanLyPheDuyet
