import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { Radio, RadioGroup, Button } from '@blueprintjs/core'
// import { PopupConfirm } from '../../common/';
// import * as POPUP from '../../../constants/Popup';
import * as Types from '../../../constants/Api'
import { CabinetTree, JobProjectTree } from '../../common'
import * as Tool from '../../../helpers'
import { CHON_NGAN_TU, CHON_LOAI_NGAN_TU } from 'constants/MessageForm'

class SaveToDocumentCabinet extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
  }

  state = {
    params: {
      loaiTuTaiLieu: Types.CABINET_PERSONAL,
      dsNganTu: [],
    },
    isCollapse: false,
    isOpenPopup: false,
    isLoading: false,
  }

  static propTypes = {
    files: PropTypes.array.isRequired,
    archives: PropTypes.array.isRequired,
    saveTo: PropTypes.array.isRequired,
    listCabinetOfDocument: PropTypes.array,
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  componentDidMount() {
    this.handleRadio({ target: { value: this.state.params.loaiTuTaiLieu } })
  }

  handleRadio = e => {
    this.setState({
      params: {
        ...this.state.params,
        loaiTuTaiLieu: e.target.value,
        dsNganTu: [],
      },
    })
    this.props.onSelectSaveTo && this.props.onSelectSaveTo(e.target.value)
  }

  handleCheck(dsNganTu) {
    this.setState({
      params: {
        ...this.state.params,
        dsNganTu,
      },
    })
  }

  handleSubmit = () => {
    if (!this.state.params.loaiTuTaiLieu) {
      return this.props.onSubmit(CHON_LOAI_NGAN_TU)
    }
    if (this.state.params.dsNganTu.length < 1) {
      return this.props.onSubmit(CHON_NGAN_TU)
    }

    this.setState({ isLoading: true })
    this.props.onSubmit &&
      this.props.onSubmit(false, this.state.params).then(() => {
        this.setState({ isLoading: false })
      })
  }

  handleClickShowResult = index => {
    this.props.archives.map((item, i) => {
      if (i === index) {
        item.isCollapse = !item.isCollapse
        this.setState({ isCollapse: item.isCollapse })
      } else {
        item.isCollapse = false
      }
      return true
    })
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Lưu tủ tài liệu</h4>
        </div>
        <div className="form-container documents-cabinet">
          <div className="checkbox-document">
            <div className="row">
              <div className="col-md-5 col-sm-5 col-xs-12">
                <Scrollbars
                  autoHide
                  autoHideTimeout={100}
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={324}
                  autoHideDuration={200}
                >
                  <div className="type-document-list">
                    <RadioGroup
                      label="Lưu vào"
                      onChange={this.handleRadio.bind(this)}
                      selectedValue={this.state.params.loaiTuTaiLieu}
                    >
                      {this.props.saveTo.map((item, i) => (
                        <Radio
                          key={i}
                          className="pt-large pt-text"
                          label={item.name}
                          value={item.id}
                          disabled={item.disabled}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </Scrollbars>
              </div>
              <div className="col-md-7 col-sm-7 col-xs-12">
                {this.state.params.loaiTuTaiLieu === Types.HOSO_CONGVIEC ? (
                  <JobProjectTree
                    auth={this.props.auth}
                    data={this.props.archives}
                    listDisable={[]}
                    onCheck={this.handleCheck}
                  />
                ) : (
                  <CabinetTree
                    data={this.props.archives}
                    listDisable={this.props.listCabinetOfDocument}
                    onCheck={this.handleCheck}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="form-input form-save-document-content">
            {/* {
              this.props.storage &&
              <p className="item-info-file">
                <span className="label-item">
                  Dung lượng đã sử dụng:
                </span>
                <span className="info">
                  {this.props.storage.used}/{this.props.storage.capacity}
                </span>
              </p>
            } */}
            <p className="item-info-file">
              <span className="label-item">File công văn:</span>
            </p>
            {this.props.files &&
              this.props.files.map((file, k) => {
                let extension =
                  file &&
                  file.tenFile &&
                  file.tenFile
                    .slice(((file.tenFile.lastIndexOf('.') - 1) >>> 0) + 2)
                    .toLowerCase()
                let data = null
                data = (
                  <div className="info" key={k}>
                    {!file.migrated && (
                      <a
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extension
                          )})`,
                        }}
                        className="link-file"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.FILE_URL}/api/file/get/${file.fileId}?BearerToken=${token}`}
                      >
                        {file.tenFile} - {file.kichThuocStr}
                      </a>
                    )}
                  </div>
                )
                return data
              })}
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-main-color"
            loading={this.state.isLoading === true ? true : false}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.HUY_LUU_TU_TAI_LIEU}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.props.onClickGoBack}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
      </div>
    )
  }
}

export default SaveToDocumentCabinet
