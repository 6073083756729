import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ThongTinXacNhan extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleAgree = this.handleAgree.bind(this)
    this.handleNotAgree = this.handleNotAgree.bind(this)
  }

  state = {
    error: {},
    typeSubmit: '',
    yKien: '',
    isOpenPopup: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAgree = e => {
    e.preventDefault()
    let content = {
      y_kien: this.state.yKien,
      trang_thai: 2,
    }
    this.handleSubmit(content)
  }

  handleNotAgree = e => {
    e.preventDefault()
    let content = {
      y_kien: this.state.yKien,
      trang_thai: 1,
    }
    this.handleSubmit(content)
  }

  handleSubmit = content => {
    this.props.onSubmit && this.props.onSubmit(this.state.typeSubmit, content)
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>Thông tin xác nhận</h4>
        </div>
        {/*<div className="form-container form-send-mail">*/}
        {/*  <div className="pt-form-group pt-intent-danger">*/}
        {/*    <div className="row">*/}
        {/*      <label className="pt-label col-md-12 col-xs-12">*/}
        {/*        Góp ý*/}
        {/*      </label>*/}
        {/*    </div>*/}
        {/*    <div className="row">*/}
        {/*      <div className="pt-form-content col-md-12 col-xs-12">*/}
        {/*        <div className="pt-form-content">*/}
        {/*          <textarea*/}
        {/*            rows="4"*/}
        {/*            className="pt-input pt-fill"*/}
        {/*            placeholder="Nhập nội dung…"*/}
        {/*            dir="auto" name="yKien"*/}
        {/*            onChange={this.handleChange}*/}
        {/*            value={this.state.yKien}*/}
        {/*            disabled={true}*/}
        {/*          ></textarea>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="buttton-action-footer button-content">*/}
        {/*  <Button*/}
        {/*      onClick={this.handleAgree}*/}
        {/*      className="pt-button btn-action-blue btn-confirm-comment"*/}
        {/*      loading={this.props.isLoading.isLoadingDuyetGopYDongY}*/}
        {/*      disabled={true}*/}
        {/*  >*/}
        {/*    Đồng ý*/}
        {/*    <span className="pt-icon pt-icon-large icon-Bao_Cao"></span>*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*      onClick={this.handleNotAgree}*/}
        {/*      className="pt-button  btn-action-red btn-cancel-steering"*/}
        {/*      loading={this.props.isLoading.isLoadingDuyetGopYKhongDongY}*/}
        {/*      disabled={true}*/}
        {/*  >*/}
        {/*    Không đồng ý*/}
        {/*    <span className="pt-icon pt-icon-large pt-icon-cross"></span>*/}
        {/*  </Button>*/}

        {/*</div>*/}
      </div>
    )
  }
}

export default ThongTinXacNhan
