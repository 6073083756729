import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import csx from 'classnames'
import { get, isEqual } from 'lodash'

import DropdownWrapper from './DropdownWrapper'
import { Card } from 'components/newCommon'

const Select = ({
  dataSource = [],
  onChange: selectChange = () => {},
  value: selectValue,
  onClear,
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [selected, _setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    _mountedSet(_setDropdownOpen, open)
  }, [])

  const _onChange = useCallback(
    value => () => {
      _mountedSet(_setSelected, value)
      _toggleDropdown(false)
      selectChange(value)
    },
    [selectChange, _toggleDropdown]
  )

  const _onClear = useCallback(() => {
    _mountedSet(_setSelected)
    _toggleDropdown(false)
    onClear()
  }, [onClear, _toggleDropdown])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      value={dataSource
        .filter(({ value }) => isEqual(selected, value))
        .map(({ label }) => label)
        .join(', ')}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
      onClear={_onClear}
    >
      <Card
        className="popup"
        mainClassName="cpc-list"
        stypeMain={{ maxHeight: 300 }}
      >
        {dataSource.map(({ value, label }) => (
          <Card
            className={csx('cpc-list-item', 'cpc-dropdown-item', {
              active: isEqual(selected, value),
            })}
            onClick={_onChange(value)}
            key={value}
          >
            {label}
          </Card>
        ))}
      </Card>
    </DropdownWrapper>
  )
}

export default memo(Select)
