import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import UIStatus from 'components/ui/status'

const TaskItem = ({ dataSource, history }) => {
  const onGoPage = () => {
    const theLoai = Number(dataSource?.theLoai)
    let path = ''
    if (theLoai === 0) {
      path = '/cong-van/cong-van-den/chi-tiet/'
    } else if (theLoai === 1) {
      path = '/ky-so/cong-van-di/chi-tiet/'
    } else if (theLoai === 2) {
      if (dataSource?.trangThaiCongViecCaNhan === 0) {
        path = '/cong-viec/ca-nhan/chi-tiet/chua-thuc-hien/'
      } else if (dataSource?.trangThaiCongViecCaNhan === 1) {
        path = '/cong-viec/ca-nhan/chi-tiet/dang-thuc-hien/'
      } else if (dataSource?.trangThaiCongViecCaNhan === 2) {
        path = '/cong-viec/ca-nhan/chi-tiet/da-thuc-hien/'
      }
    } else if (theLoai === 3) {
      if (dataSource?.trangThaiCongViecPhongBan === 0) {
        path = '/cong-viec/phong-ban/chi-tiet/chua-giao/'
      } else if (dataSource?.trangThaiCongViecPhongBan === 1) {
        path = '/cong-viec/phong-ban/chi-tiet/da-giao/'
      }
    }

    if (path) {
      return history.push(`${path}${dataSource?.id}`)
    }
  }

  const statusName = useMemo(() => {
    const theLoai = Number(dataSource?.theLoai)
    let result = ''
    switch (theLoai) {
      case 0:
        result = 'Công văn'
        break
      case 1:
        result = 'Ký số'
        break
      case 2:
        result = 'Công việc'
        break
      case 3:
        result = 'Công việc'
        break
      default:
        result = 'Công việc'
        break
    }

    return result
  }, [dataSource])

  const colorCode = useMemo(() => {
    const theLoai = Number(dataSource?.theLoai)
    let result = ''
    switch (theLoai) {
      case 0:
        result = '#1677ff'
        break
      case 1:
        result = '#df8e0a'
        break
      case 2:
        result = '#57c22d'
        break
      case 3:
        result = '#57c22d'
        break
      default:
        result = '#57c22d'
        break
    }

    return result
  }, [dataSource])

  const titleDate = useMemo(() => {
    const theLoai = Number(dataSource?.theLoai)
    let result = ''
    switch (theLoai) {
      case 0:
      case 1:
        result = 'Ngày công văn'
        break
      case 2:
      case 3:
        result = 'Thời hạn'
        break
      default:
        result = ''
        break
    }

    return result
  }, [dataSource])

  const date = useMemo(() => {
    const theLoai = Number(dataSource?.theLoai)
    let result = ''
    switch (theLoai) {
      case 0:
      case 1:
        result = dataSource?.ngayCongVan
          ? moment(dataSource?.ngayCongVan).format('DD/MM/YYYY')
          : ''
        break
      case 2:
      case 3:
        result = dataSource?.hanGiaiQuyet
          ? moment(dataSource?.hanGiaiQuyet).format('DD/MM/YYYY')
          : ''
        break
      default:
        result = ''
        break
    }

    return result
  }, [dataSource])

  return (
    <div className="task-item" onClick={onGoPage}>
      <div className="d-flex align-items-center justify-content-space-between task-item-head-top-wrapper">
        <div className="task-item-head-left">
          <div>
            <UIStatus name={statusName} color={colorCode} />
          </div>
          {dataSource?.soCongVan ? (
            <div className="color-main-text2 font-size-14">
              <span className="opacity-05">Số/Ký hiệu:</span>
              <span className="ml5">{dataSource?.soCongVan}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        {date ? (
          <div className="color-main-text2 font-size-14">
            <span className="opacity-05">{titleDate}:</span>
            <span className="ml5">{date}</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="text-14-300-21 mt5">{dataSource?.trichYeu}</div>
    </div>
  )
}

TaskItem.defaultProps = {
  dataSource: null,
  history: null,
}

TaskItem.propTypes = {
  dataSource: PropTypes.object,
  history: PropTypes.object,
}

export default TaskItem
