import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import { PopupConfirm } from '../../common/'
import { Authorization, Permission } from '../../auth'
import * as POPUP from '../../../constants/Popup'
import * as Types from '../../../constants/Api'
import { Link } from 'react-router-dom'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      random: Math.random(),
    }
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  state = {
    isOpenPopup: false,
    isLoading: false,
  }

  componentDidMount() {
    this.setState({
      random: Math.random(),
    })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleEnabledChange = e => {
    e.preventDefault()
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props
          .onHandleSubmitRemoveItem(this.props.item.nhanVienId)
          .then(res => {
            if (res === undefined) {
              this.setState({
                isLoading: false,
                isOpenPopup: !this.state.isOpenPopup,
              })
            }
          })
      }
    )
  }

  goEdit = () => {
    this.props.history.push(
      `/he-thong/service/chinh-sua/${this.props.item.nhanVienId}`
    )
  }

  render() {
    const { item, number } = this.props
    if (!item || !number) {
      return
    }
    return (
      <tr>
        <td>{number > 9 ? number : '0' + number}</td>
        <td>
          <div className="avatar-background--box">
            <div
              className="avatar-background"
              style={{
                backgroundImage: `url(${
                  item.anhDaiDien
                    ? Types.IMAGE_URL +
                      item.anhDaiDien +
                      `?preventCache=${this.state.random}`
                    : '/images/default_avatar.png'
                })`,
              }}
            />
          </div>
        </td>
        <td className="pointer">
          <Link to={`/he-thong/service/thong-ke/${item.nhanVienId}`}>
            <span className="text-url font-medium">
              {item.tenNhanVien ? item.tenNhanVien : ''}
            </span>
          </Link>
        </td>
        <td className="textarea-content">{item.moTa ? item.moTa : ''}</td>
        <td>{item.username ? item.username : ''}</td>
        <td>{item.tenNguoiDuocCap ? item.tenNguoiDuocCap : ''}</td>
        <td>
          <div className="center">
            <Checkbox
              checked={item.suDung}
              onChange={this.handleEnabledChange}
              className="checkbox-inline opacity-05 cursor-default"
            />
          </div>
        </td>
        <td>
          <Authorization.Element permission={[Permission.CONGVANDI_QUANLY]}>
            <Popover
              popoverClassName="pt-popover-content-sizing pt-minimal"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <span className="item popover-item" onClick={this.goEdit}>
                    <span className="icon icon-s-edit"></span>
                    <span className="name">Chỉnh sửa</span>
                  </span>
                  <span
                    className="item popover-item"
                    onClick={this.handleClickPopup}
                  >
                    <span className="icon icon-s-delete"></span>
                    <span className="name">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
            <PopupConfirm
              isOpen={this.state.isOpenPopup}
              onClose={this.handleClickPopup}
              title={POPUP.XOA_SERVICE}
              text={POPUP.TEXT_BUTTON_SUBMIT}
              onSubmit={this.handleSubmitRemoveItem}
              isLoading={this.state.isLoading}
            >
              {POPUP.TEXT_SUBMIT_BODY}
            </PopupConfirm>
          </Authorization.Element>
        </td>
      </tr>
    )
  }
}

export default ListItem
