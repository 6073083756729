import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AssignmentHistoryItem from './AssignmentHistoryItem'
import { NotFound } from '../common'

class AssignmentHistory extends Component {
  static propTypes = {
    assignmentHistory: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Lịch sử chỉ đạo</h4>
        </div>
        <div className="form-container history-steering-box">
          {this.props.assignmentHistory.map((item, i) => (
            <AssignmentHistoryItem
              key={i}
              assignmentHistory={item}
              onSubmit={this.props.onSubmit}
              onHandleSubmit={this.props.onHandleSubmit}
              departments={this.props.departments}
            />
          ))}
          {this.props.assignmentHistory.length === 0 && <NotFound />}
        </div>
      </div>
    )
  }
}

export default AssignmentHistory
