import React, { Component } from 'react'
import { Collapse, Radio, Checkbox } from '@blueprintjs/core'
import { PropTypes } from 'prop-types'
import { NotFound, LoadingItem } from '../common/'
import classnames from 'classnames'
import { PAGE } from '../../constants/Api'
import async from 'async'
import _ from 'lodash'

class ListUnitRadioGroupTwoColumns extends Component {
  constructor(props) {
    super(props)
    let isOpen = {}
    let isLoaded = {}
    let isLoading = {}
    if (props.data) {
      let tmp = []
      props.data.forEach(item => {
        tmp[item.donViId] = false
      })
      isOpen = tmp
      isLoaded = tmp
      isLoading = tmp
    }

    this.state = {
      departments: {},
      isOpen,
      isLoaded,
      isLoading,
      isLoadFirstTime: false,
      checkedDataTypeList1: props.checkedDataTypeList1 || [],
      checkedDataTypeList2: props.checkedDataTypeList2 || [],
    }

    this.handleGetDepartment = this.handleGetDepartment.bind(this)
    this.handleCheckRadio = this.handleCheckRadio.bind(this)
    this.toggleCheckBox = this.toggleCheckBox.bind(this)
    this.handleResetUnit = this.handleResetUnit.bind(this)
  }

  static propTypes = {
    data: PropTypes.array,
    requestApi: PropTypes.func.isRequired,
    onCheckDepartmentList1: PropTypes.func,
    onCheckDepartmentList2: PropTypes.func,
    selectedList: PropTypes.array,
    disabledList1: PropTypes.array,
    disabledList2: PropTypes.array,
    checkedDataTypeList1: PropTypes.array,
    checkedDataTypeList2: PropTypes.array,
    selectedListObject: PropTypes.object,
    mainUnitId: PropTypes.string,
    conditionField: PropTypes.string,
    conditionValue: PropTypes.any,
    disabledAll: PropTypes.bool,
    disabledAllList1: PropTypes.bool,
    disabledAllList2: PropTypes.bool,
    isShowUnitCode: PropTypes.bool,
    isCanShowCompositive: PropTypes.bool,
    isLoadingData: PropTypes.bool,
    onResetDepartmentOtherUnit: PropTypes.func,
  }

  async componentDidMount() {
    if (
      this.props.data &&
      this.props.data.length > 0 &&
      !this.state.isLoadFirstTime
    ) {
      let state = this.state
      state.isLoadFirstTime = true
      this.props.data.forEach(item => {
        if (state.isOpen && state.isOpen[item.donViId] === undefined) {
          state.isOpen[item.donViId] = false
        }
        if (state.isLoaded && state.isLoaded[item.donViId] === undefined) {
          state.isLoaded[item.donViId] = false
        }
        if (state.isLoading && state.isLoading[item.donViId] === undefined) {
          state.isLoading[item.donViId] = false
        }
      })
      await this.setState(state)
    }

    // Get phòng ban của đơn vị đã được chọn khi chỉnh sửa
    if (
      this.props.selectedListObject &&
      Object.values(this.props.selectedListObject).length > 0
    ) {
      const selectedListObject = this.props.selectedListObject
      const keyList = Object.keys(selectedListObject)
      async.eachSeries(keyList, async (donViId, callback) => {
        if (donViId && !this.state.isLoaded[donViId]) {
          await this.setState(
            {
              isLoading: {
                ...this.state.isLoading,
                [donViId]: true,
              },
              isOpen: {
                ...this.state.isOpen,
                [donViId]: true,
              },
              isLoaded: {
                ...this.state.isLoaded,
                [donViId]: true,
              },
            },
            async () => {
              await this.props
                .requestApi(PAGE, donViId)
                .then(res => {
                  let isLoading = this.state.isLoading
                  let departments = this.state.departments
                  if (!res.payload || res.error) {
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.result &&
                    res.payload.data.result.items
                  ) {
                    let dataResult = res.payload.data.result.items
                    if (
                      this.props.conditionField !== undefined &&
                      this.props.conditionValue !== undefined
                    ) {
                      dataResult = dataResult.filter(
                        item =>
                          item[this.props.conditionField] ===
                          this.props.conditionValue
                      )
                    }
                    departments = {
                      ...departments,
                      [donViId]: dataResult,
                    }
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  this.setState({ departments, isLoading })
                })
                .then(callback(null))
            }
          )
        }
      })
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (
      nextProps.data &&
      !_.isEqual(nextProps.data, this.props.data) &&
      !this.state.isLoadFirstTime
    ) {
      let state = this.state
      state.isLoadFirstTime = true
      await nextProps.data.forEach(item => {
        if (state.isOpen && state.isOpen[item.donViId] === undefined) {
          state.isOpen[item.donViId] = false
        }
        if (state.isLoaded && state.isLoaded[item.donViId] === undefined) {
          state.isLoaded[item.donViId] = false
        }
        if (state.isLoading && state.isLoading[item.donViId] === undefined) {
          state.isLoading[item.donViId] = false
        }
      })
      await this.setState(state)
    }

    // Get phòng ban của đơn vị đã được chọn khi chỉnh sửa
    if (
      nextProps.selectedListObject &&
      !_.isEqual(nextProps.selectedListObject, this.props.selectedListObject)
    ) {
      const selectedListObject = nextProps.selectedListObject
      const keyList = Object.keys(selectedListObject)
      async.eachSeries(keyList, async (donViId, callback) => {
        if (donViId && !this.state.isLoaded[donViId]) {
          await this.setState(
            {
              isLoading: {
                ...this.state.isLoading,
                [donViId]: true,
              },
              isOpen: {
                ...this.state.isOpen,
                [donViId]: true,
              },
              isLoaded: {
                ...this.state.isLoaded,
                [donViId]: true,
              },
            },
            async () => {
              await this.props
                .requestApi(PAGE, donViId)
                .then(res => {
                  let isLoading = this.state.isLoading
                  let departments = this.state.departments
                  if (!res.payload || res.error) {
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.result &&
                    res.payload.data.result.items
                  ) {
                    let dataResult = res.payload.data.result.items
                    if (
                      nextProps.conditionField !== undefined &&
                      nextProps.conditionValue !== undefined
                    ) {
                      dataResult = dataResult.filter(
                        item =>
                          item[nextProps.conditionField] ===
                          nextProps.conditionValue
                      )
                    }
                    departments = {
                      ...departments,
                      [donViId]: dataResult,
                    }
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  this.setState({ departments, isLoading })
                })
                .then(callback(null))
            }
          )
        }
      })
    }
    if (
      !_.isEqual(
        nextProps.checkedDataTypeList1,
        this.props.checkedDataTypeList1
      )
    ) {
      this.setState({ checkedDataTypeList1: nextProps.checkedDataTypeList1 })
    }
    if (
      !_.isEqual(
        nextProps.checkedDataTypeList2,
        this.props.checkedDataTypeList2
      )
    ) {
      this.setState({ checkedDataTypeList2: nextProps.checkedDataTypeList2 })
    }
  }

  handleGetDepartment = async (donViId, isCustomeIsOpen = null, e) => {
    let isLoaded = this.state.isLoaded
    let isLoading = this.state.isLoading
    let departments = this.state.departments
    if (isLoading && Object.values(isLoading).find(item => item === true)) {
      return
    }
    let isOpenDonViId = !this.state.isOpen[donViId]
    if (isCustomeIsOpen !== null) {
      isOpenDonViId = isCustomeIsOpen
    }
    await this.setState({
      isOpen: {
        ...this.state.isOpen,
        [donViId]: isOpenDonViId,
      },
    })

    if (
      donViId &&
      isLoaded[donViId] !== true &&
      this.state.isOpen[donViId] === true
    ) {
      isLoading = {
        ...isLoading,
        [donViId]: true,
      }
      await this.setState({ isLoading })
      await this.props
        .requestApi(PAGE, donViId)
        .then(res => {
          if (!res.payload || res.error) {
            isLoading = {
              ...isLoading,
              [donViId]: false,
            }
          }
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.items
          ) {
            let dataResult = res.payload.data.result.items
            if (
              this.props.conditionField !== undefined &&
              this.props.conditionValue !== undefined
            ) {
              dataResult = dataResult.filter(
                item =>
                  item[this.props.conditionField] === this.props.conditionValue
              )
            }
            departments = {
              ...departments,
              [donViId]: dataResult,
            }
            isLoading = {
              ...isLoading,
              [donViId]: false,
            }
            isLoaded = {
              ...isLoaded,
              [donViId]: true,
            }
          }
        })
        .catch(e => {
          isLoading = {
            ...isLoading,
            [donViId]: false,
          }
        })
      this.setState({ isLoading, isLoaded, departments })
    }
  }

  handleCheckRadio = (donViId, phongBanId) => {
    let { checkedDataTypeList1, checkedDataTypeList2 } = this.state
    if (
      checkedDataTypeList1 &&
      checkedDataTypeList1.indexOf(donViId) > -1 &&
      (!this.props.disabledList1 ||
        this.props.disabledList1.indexOf(donViId) === -1)
    ) {
      return (
        this.props.onCheckDepartmentList1 &&
        this.props.onCheckDepartmentList1({
          donViId,
          phongBanId,
        })
      )
    }
    if (
      checkedDataTypeList2 &&
      checkedDataTypeList2.indexOf(donViId) > -1 &&
      (!this.props.disabledList2 ||
        this.props.disabledList2.indexOf(donViId) === -1)
    ) {
      return (
        this.props.onCheckDepartmentList2 &&
        this.props.onCheckDepartmentList2({
          donViId,
          phongBanId,
        })
      )
    }
    if (
      this.props.isCanShowCompositive &&
      (!this.props.disabledList1 ||
        this.props.disabledList1.indexOf(donViId) === -1) &&
      !this.props.disabledAllList1
    ) {
      return this.toggleCheckBox('checkedDataTypeList1', donViId, phongBanId)
    }
    if (
      (!this.props.disabledList2 ||
        this.props.disabledList2.indexOf(donViId) === -1) &&
      !this.props.disabledAllList2
    ) {
      return this.toggleCheckBox('checkedDataTypeList2', donViId, phongBanId)
    }
  }

  handleResetUnit = donViId => {
    this.props.onResetDepartmentOtherUnit &&
      this.props.onResetDepartmentOtherUnit(donViId)
  }

  toggleCheckBox = async (
    checkedDataTypeListName,
    donViId,
    phongBanId = null
  ) => {
    let isChecked = false
    let checkedDataTypeList = this.state[checkedDataTypeListName]
    if (checkedDataTypeList && checkedDataTypeList.indexOf(donViId) === -1) {
      checkedDataTypeList.push(donViId)
      isChecked = true
    } else {
      checkedDataTypeList = checkedDataTypeList.filter(item => item !== donViId)
    }
    await this.handleGetDepartment(donViId, isChecked)
    await this.setState(
      {
        [checkedDataTypeListName]: checkedDataTypeList,
      },
      () => {
        let departments = this.state.departments
        if (isChecked) {
          if (
            departments &&
            departments[donViId] &&
            departments[donViId].length > 0
          ) {
            if (
              phongBanId &&
              departments[donViId].filter(
                item => item.phongBanId === phongBanId
              )
            ) {
              return this.handleCheckRadio(donViId, phongBanId)
            }
            this.handleCheckRadio(donViId, departments[donViId][0].phongBanId)
          }
        } else {
          this.handleResetUnit(donViId)
        }
      }
    )
  }

  render() {
    const isLoadingAll =
      this.state.isLoading &&
      Object.values(this.state.isLoading).find(item => item === true) !==
        undefined
    return (
      <div className="wrapper-panel-collapse wapper-checkbox-group-two-columns">
        {this.props.isLoadingData && (
          <div className="loading-container">
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
          </div>
        )}
        {this.props.data &&
          this.props.data.length > 0 &&
          this.props.isLoadingData !== true && (
            <div className="box-panel-heading">
              <div className="panel-heading">
                <h3 className="panel-title">ĐƠN VỊ/PHÒNG BAN</h3>
                {this.props.isCanShowCompositive && (
                  <span className="text-content col-second">KÝ PHỐI HỢP</span>
                )}
                <span className="text-content">CHUYỂN ĐẾN</span>
              </div>
            </div>
          )}
        {this.props.data &&
          this.props.data.length > 0 &&
          this.props.isLoadingData !== true &&
          this.props.data.map((item, index) => {
            if (!item) {
              return null
            }
            return (
              <div className="panel panel-item" key={index}>
                <div className="panel-heading" disabled={isLoadingAll}>
                  <h3
                    className="panel-title"
                    onClick={this.handleGetDepartment.bind(
                      this,
                      item.donViId,
                      null
                    )}
                  >
                    <span
                      className={classnames('pull-left icon-dropdown-panel', {
                        'icon-arrow-dropup': this.state.isOpen[item.donViId],
                        'icon-arrow-dropdown': !this.state.isOpen[item.donViId],
                      })}
                    ></span>
                    {item.tenVietTat}
                    {this.props.isShowUnitCode &&
                      this.props.isCanShowCompositive && (
                        <span className="text-normal">
                          {` (${
                            item.maKySo
                              ? item.maKySo
                              : item.maDonVi && item.maDonVi.toLowerCase()
                          })`}
                        </span>
                      )}
                  </h3>
                  {this.props.isCanShowCompositive && (
                    <span className="text-content col-second">
                      <Checkbox
                        className="item-checkbox disabled-checkbox text-content"
                        onChange={this.toggleCheckBox.bind(
                          this,
                          'checkedDataTypeList1',
                          item.donViId,
                          null
                        )}
                        name={`checkbox1-${index}`}
                        checked={
                          this.state.checkedDataTypeList1 &&
                          this.state.checkedDataTypeList1.indexOf(
                            item.donViId
                          ) > -1
                        }
                        disabled={
                          (this.state.isLoading &&
                            this.state.isLoading[item.donViId] === false &&
                            this.state.isOpen &&
                            this.state.isOpen[item.donViId] === true &&
                            (!this.state.departments ||
                              !this.state.departments[item.donViId] ||
                              !this.state.departments[item.donViId].length)) ||
                          (this.state.checkedDataTypeList2 &&
                            this.state.checkedDataTypeList2.indexOf(
                              item.donViId
                            ) > -1) ||
                          this.props.disabledAll ||
                          this.props.disabledAllList1
                        }
                      />
                    </span>
                  )}
                  <span className="text-content">
                    <Checkbox
                      className="item-checkbox disabled-checkbox"
                      onChange={this.toggleCheckBox.bind(
                        this,
                        'checkedDataTypeList2',
                        item.donViId,
                        null
                      )}
                      name={`checkbox2-${index}`}
                      checked={
                        (this.state.checkedDataTypeList2 &&
                          this.state.checkedDataTypeList2.indexOf(
                            item.donViId
                          ) > -1) ||
                        (this.state.checkedDataTypeList1 &&
                          this.state.checkedDataTypeList1.indexOf(
                            item.donViId
                          ) > -1)
                      }
                      disabled={
                        (this.state.isLoading &&
                          this.state.isLoading[item.donViId] === false &&
                          this.state.isOpen &&
                          this.state.isOpen[item.donViId] === true &&
                          (!this.state.departments ||
                            !this.state.departments[item.donViId] ||
                            !this.state.departments[item.donViId].length)) ||
                        (this.state.checkedDataTypeList1 &&
                          this.state.checkedDataTypeList1.indexOf(
                            item.donViId
                          ) > -1) ||
                        this.props.disabledAll ||
                        this.props.disabledAllList2
                      }
                    />
                  </span>
                </div>
                <Collapse isOpen={this.state.isOpen[item.donViId]}>
                  <div className="panel-body">
                    <div className="selectbox-group">
                      <div className="row checkboxgroup-container">
                        {this.state.isLoading &&
                          this.state.isLoading[item.donViId] === true && (
                            <div className="col-xs-12 col-sm-12 col-md-12">
                              <div className="loading-container">
                                <LoadingItem />
                                <LoadingItem />
                              </div>
                            </div>
                          )}
                        {this.state.isLoading &&
                          this.state.isLoading[item.donViId] === false &&
                          this.state.departments &&
                          this.state.departments[item.donViId] &&
                          this.state.departments[item.donViId].map(
                            (subItem, subIndex) => (
                              <div
                                className="col-xs-12 col-sm-12 col-md-12"
                                key={subIndex}
                              >
                                <div className="item-checkbox disabled-checkbox">
                                  <Radio
                                    className="pt-inline"
                                    checked={
                                      this.props.selectedList &&
                                      this.props.selectedList.indexOf(
                                        subItem.phongBanId
                                      ) > -1
                                    }
                                    disabled={
                                      this.props.disabledAll ||
                                      (this.props.disabledList1 &&
                                        this.props.disabledList1.indexOf(
                                          item.donViId
                                        ) > -1) ||
                                      (this.props.disabledList2 &&
                                        this.props.disabledList2.indexOf(
                                          item.donViId
                                        ) > -1)
                                    }
                                    onChange={this.handleCheckRadio.bind(
                                      this,
                                      item.donViId,
                                      subItem.phongBanId
                                    )}
                                  >
                                    <span className="name_checkbox">
                                      {subItem.tenPhongBan}
                                    </span>
                                  </Radio>
                                </div>
                              </div>
                            )
                          )}
                        {this.state.isLoading &&
                          this.state.isLoading[item.donViId] === false &&
                          this.state.isOpen &&
                          this.state.isOpen[item.donViId] === true &&
                          (!this.state.departments ||
                            !this.state.departments[item.donViId] ||
                            !this.state.departments[item.donViId].length) && (
                            <div className="col-xs-12 col-sm-12 col-md-12">
                              <NotFound />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            )
          })}
        {(!this.props.data || this.props.data.length === 0) &&
          !this.props.isLoadingData && (
            <div className="col-xs-12 col-sm-12 col-md-12">
              <NotFound />
            </div>
          )}
      </div>
    )
  }
}

export default ListUnitRadioGroupTwoColumns
