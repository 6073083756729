import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    items: [],
    total: 0,
    page: 0,
  },
}

export default function evnDocuments(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENTS_EVN_FETCH_LIST:
      return {
        ...state,
        list: {
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
        },
      }

    default:
      return state
  }
}
