import React, { Component } from 'react'
import moment from 'moment'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'

class DetailInfo extends Component {
  render() {
    const { requestComments, type } = this.props
    let trangThai = ''
    if (type === 'layYKienNhan') {
      switch (requestComments.tinhTrang) {
        case TRANGTHAI.LDDV_CHUA_GIAO:
          trangThai = 'LĐĐV chưa giao'
          break
        case TRANGTHAI.LDPB_CHUA_GIAO:
          trangThai = 'LĐPB chưa giao'
          break
        case TRANGTHAI.CHUA_GOP_Y:
          trangThai = 'chưa góp ý'
          break
        case TRANGTHAI.LDPB_CHUA_XAC_NHAN:
          trangThai = 'LĐPB chưa xác nhận'
          break
        case TRANGTHAI.LDPB_TU_CHOI_XAC_NHAN:
          trangThai = 'LĐPB từ chối xác nhận'
          break
        case TRANGTHAI.LDDV_CHUA_XAC_NHAN:
          trangThai = 'LĐĐV chưa xác nhận'
          break
        case TRANGTHAI.LDDV_TU_CHOI_XAC_NHAN:
          trangThai = 'LĐĐV từ chối xác nhận'
          break
        case TRANGTHAI.TU_CHOI_XAC_NHAN:
          trangThai = 'từ chối xác nhận'
          break
        case TRANGTHAI.DA_GOP_Y:
          trangThai = 'đã góp ý'
          break
        default:
          trangThai = ''
      }
    } else {
      switch (requestComments.tinhTrang) {
        case TRANGTHAI.LDPB_CHUA_DUYET:
          trangThai = 'LĐPB chưa duyệt'
          break
        case TRANGTHAI.KHONG_DUYET:
          trangThai = 'Không duyệt'
          break
        case TRANGTHAI.LDDV_CHUA_DUYET:
          trangThai = 'LĐĐV chưa duyệt'
          break
        case TRANGTHAI.THU_KY_CHUA_DUYET:
          trangThai = 'TK chưa duyệt'
          break
        case TRANGTHAI.DANG_GOP_Y:
          trangThai = 'Đang góp ý'
          break
        case TRANGTHAI.DA_HOAN_THANH:
          trangThai = 'đã hoàn thành'
          break
        default:
          trangThai = ''
      }
    }

    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin góp ý</h4>
        </div>
        <div className="form-container section-box-info">
          <div className="detail-info-container">
            <div className="list-items">
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Số ký hiệu:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">{requestComments.soKyHieu}</span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Ngày lập:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {moment(requestComments.ngayLap).format('DD/MM/YYYY')}
                  </span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Thời hạn:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {moment(requestComments.thoiHan).format('DD/MM/YYYY')}
                  </span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Người lập:</span>
                </div>
                <div className="col-xs-9 right-content">
                  {requestComments.nguoiLap && (
                    <span className="content">
                      {requestComments.nguoiLap.tenNhanVien}
                    </span>
                  )}
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Trạng thái:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">{trangThai}</span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Tiêu đề:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content textarea-content">
                    {requestComments.trichYeu}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DetailInfo
