import React from 'react'
import { MasterLayout } from '../../components/layout'
import { FormRoleCategory } from '../../components/System'
import { HeadingPage } from '../../components/common'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'

const SUA_DO_KHAN = 'Sửa vai trò'
const THEM_MOI_DO_KHAN = 'Thêm mới vai trò'
class AddRoleCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    doiTuongId: this.props.match.params.id,
    role: null,
  }

  componentDidMount() {
    this.props.actions.getCategoryUnitMultiLevel()

    if (
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.auth.mainUnitId
    ) {
      this.props.actions.getCommonFetchRoleItem(
        this.props.auth.mainUnitId,
        this.state.doiTuongId
      )
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    return this.props.history.goBack()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.role.item !== this.props.role.item) {
      this.setState({ role: nextProps.role.item })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={this.state.role ? SUA_DO_KHAN : THEM_MOI_DO_KHAN}
              iconPage="icon-Danh_Muc_Vai_Tro"
            />
            <FormRoleCategory
              actions={this.props.actions}
              role={this.state.role}
              unitSelect={this.props.unitSelect.items}
              auth={this.props.auth}
              onSubmit={this.handleSubmit}
              match={this.props.match}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  role: {
    ...state.roleItem,
    item: state.common.roleItem,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleCategoryPage)
