import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Intent } from '@blueprintjs/core'
import FileDownload from 'file-saver'
import moment from 'moment'

import { MasterLayout } from '../../components/layout'
import { ListFooter, ListItem } from '../../components/ListGerenal/WorkDocument'
import { GridView } from '../../components/common'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import HeadingPanel from 'components/common/HeadingPanel'

class ListGerenalDocumentOverTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ngayGiaoViec: {
        from: '',
        to: '',
      },
      loaiCongViec: '',
      isEmpty: false,
      isLoading: false,
      page: 1,
      isExporting: false,
      type: '',
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    personalWorks: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let state = this.state
    state.nhanVienId = this.props.match.params.nhanVienId
    state.phongBanId = this.props.match.params.phongBanId

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.type
    ) {
      state.type = this.props.match.params.type
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.ngayGiaoViec
    ) {
      state.ngayGiaoViec = {
        from: this.props.location.state.ngayGiaoViec.from,
        to: this.props.location.state.ngayGiaoViec.to,
      }
      state.loaiCongViec = this.props.location.state.loaiCongViec
    } else {
      state.ngayGiaoViec = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }
    this.setState(state, () => {
      this.clickPagination({ selected: 0 })
    })
  }

  clickPagination(e) {
    let state = {
      page: e.selected + 1,
    }

    this.setState(state, () => {
      this.submitFilter(this.state.page, this.state.sort)
    })
  }

  submitFilter(page = 1, sort) {
    this.setState({ page, isLoading: true }, () => {
      this.props.actions
        .getWorkDocumentOverTime(
          {
            phongBanId: this.state.phongBanId,
            ngayGiaoViec: this.state.ngayGiaoViec,
            nhanVienId: this.state.nhanVienId,
            loaiCongViec: this.state.loaiCongViec,
          },
          page,
          sort,
          this.state.type.split('-').join('')
        )
        .then(res => {
          if (!res.error && res.payload.data.result.result.total === 0) {
            return this.setState({ isEmpty: true })
          }

          this.setState({ isEmpty: false })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.page, sort)
  }

  handleClickGoBack = () => {
    this.props.history.goBack()
    Tool.Utils.saveData('isSubmitStatisticWork', true)
  }

  handleExport() {
    this.setState({ isExporting: true }, () => {
      this.props.actions
        .exportStatisticExpireWork(
          {
            phongBanId: this.state.phongBanId,
            ngayGiaoViec: this.state.ngayGiaoViec,
            nhanVienId: this.state.nhanVienId,
          },
          this.state.type.split('-').join('')
        )
        .then(res => {
          if (res.error || !res.payload || !res.payload.data) {
            this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          FileDownload.saveAs(
            res.payload.data,
            `tong-hop-cong-viec-${this.state.type}.xls`
          )
        })
        .finally(() => {
          this.setState({ isExporting: false })
        })
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={this.handleClickGoBack}
            leftTitle={`Tổng hợp công việc ${
              this.state.type === 'qua-han' ? 'quá hạn' : 'cần báo cáo'
            }`}
            leftIcon="icon-Chua_Thuc_Hien"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.isExporting}
                disabled={this.state.isLoading || this.state.isEmpty}
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container">
            <div className="table-container">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table'}
                page={this.state.page ? this.state.page : 1}
                headers={headers.listGerenalPersonalWorkPage}
                itemShape={ListItem}
                data={this.props.personalWorks.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onHandleClickItem={this.HandleClickItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
              />
              {!this.state.isLoading && (
                <ListFooter
                  forcePage={this.state.page - 1}
                  pageCount={Math.ceil(
                    this.props.personalWorks.list.total / Types.PAGINATION_LIMIT
                  )}
                  onPageClick={this.clickPagination}
                  isEmpty={this.state.isEmpty}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  typeSidebar: state.common.typeSidebar,

  personalWorks: {
    ...state.personalWorks,
    list: {
      ...state.personalWorks.list,
      items: state.personalWorks.list.items.map(
        id => state.entities.personalWorks[id]
      ),
    },
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListGerenalDocumentOverTime)
