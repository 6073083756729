import GoBackButton from '../Button/GoBackButton'
import { Link } from 'react-router-dom'
import React from 'react'
import csx from 'classnames'
import { isFunction } from 'lodash'

const ContentHeader = props => {
  return (
    <div className="heading-page">
      <div className="row">
        <div className="col-xs-12">
          <h3
            className={csx('text-heading-page', props.className)}
            style={{ width: '100%' }}
          >
            {!!props.showGoBackButton && (
              <GoBackButton disabled={props.disabledGoBack} />
            )}
            <span className={`${props.pageIcon} icon-heading`} />
            <span
              className="text-heading"
              style={{ display: 'inline', whiteSpace: 'normal' }}
            >
              {props.pageName}
            </span>
            {!!props.actionsButton &&
              !!props.actionsButton.length &&
              props.actionsButton.map((button, index) => {
                return (
                  <Link
                    to={button.link || '#'}
                    className={csx(
                      'btn-action',
                      'pull-right',
                      'hidden-btn',
                      'btn-action-header',
                      {
                        'btn-disabled': button.disabled,
                      }
                    )}
                    key={index}
                    onClick={e => {
                      if (isFunction(button.onClick) && !button.disabled) {
                        e.preventDefault()
                        button.onClick()
                      }
                    }}
                  >
                    <span className="name-btn">{button.name}</span>
                    {!!button.icon && <span className={button.icon} />}
                  </Link>
                )
              })}
          </h3>
        </div>
      </div>
    </div>
  )
}

export default ContentHeader
