import React, { Component } from 'react'

class HeadingTableDragAndDrop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sort: {},
    }
  }

  handleClickSort(i) {
    let state = this.state
    for (var v in state.sort) {
      if (v === i) {
        continue
      }

      delete state.sort[v]
    }
    state.sort[i] = !state.sort[i] || state.sort[i] === 'ASC' ? 'DESC' : 'ASC'
    this.setState(state, () => {
      let sort
      for (let sortField in this.state.sort) {
        sort = `${sortField} ${this.state.sort[sortField]}`
      }

      this.props.onSort && this.props.onSort(sort)
    })
  }
  render() {
    const { data } = this.props
    return (
      <div className="cpc-sortable-body--row">
        {data
          .filter(
            elm =>
              !this.props[elm.nameHide] || this.props[elm.nameHide] !== true
          )
          .map((item, i) => {
            return (
              <div
                key={i}
                className={`cpc-sortable-body--cell ${item.classNames}`}
              >
                <span className="head-label">{item.value}</span>
                {item.sort && (
                  <span
                    className={`pt-icon ${
                      this.state.sort[item.sort] &&
                      this.state.sort[item.sort] === 'DESC'
                        ? 'pt-icon-chevron-up'
                        : 'pt-icon-chevron-down'
                    }`}
                    onClick={this.handleClickSort.bind(this, item.sort)}
                  />
                )}
              </div>
            )
          })}
      </div>
    )
  }
}

export default HeadingTableDragAndDrop
