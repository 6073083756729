import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import * as Types from '../../constants/Api'

class ListItemSynthesis extends Component {
  render() {
    const token =
      this.props.auth &&
      this.props.auth.auth.token &&
      this.props.auth.auth.token.accessToken
        ? this.props.auth.auth.token.accessToken
        : ''
    const { item, number } = this.props
    let tmp
    switch (item.trangThai) {
      case 0:
        tmp = <span className="status-job no-process">Chưa có ý kiến</span>
        break
      case 1:
        tmp = <span className="status-job no-accept">Không đồng ý</span>
        break
      case 2:
        tmp = <span className="status-job process">Đồng ý</span>
        break
      case 3:
        tmp = <span className="status-job black">Ý kiến khác</span>
        break
      default:
        tmp = <span>&nbsp;</span>
    }
    const trangThai = tmp
    return (
      <tr>
        <td>{number > 9 ? number : '0' + number}</td>
        <td>{_.get(item, 'tenNhanVien', '')}</td>
        <td>{trangThai}</td>
        <td>{_.get(item, 'noiDungYKien', '')}</td>
        <td>
          {item &&
            moment(item.thoiGian).isValid() &&
            moment(item.thoiGian).format('DD/MM/YYYY HH:mm')}
        </td>
        <td>
          <a
            className="link-file"
            target="_blank"
            rel="noopener noreferrer"
            href={
              _.get(item, 'tenNhanVien', false) &&
              `${Types.FILE_URL}/api/file/get/${item.fileYKienId}?BearerToken=${token}`
            }
          >
            <span className="icon icon-list-check color-blue"></span>
          </a>
        </td>
      </tr>
    )
  }
}

ListItemSynthesis.defaultProps = {
  item: {},
}

ListItemSynthesis.propTypes = {
  item: PropTypes.object,
}

export default ListItemSynthesis
