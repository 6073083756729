import { schema } from 'normalizr'

const ReportWork = new schema.Entity(
  'reportWork',
  {},
  {
    idAttribute: reportWork => reportWork.congViecCaNhanId,
  }
)

export default ReportWork
