import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const DEFAULT_ID = '00000000-0000-0000-0000-000000000000'
const TOTAL_NAME = 'Tổng cộng'
const TYPE_DEFAULT = 0
const TYPE_UNIT = 1
const TYPE_DEPARTMENT = 2

class ListItem extends Component {
  constructor(props) {
    super(props)

    this.convertNumber = this.convertNumber.bind(this)
    this.redirectToStatisticChild = this.redirectToStatisticChild.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (_.isNumber(number)) {
      return number
    }
    return 0
  }

  redirectToStatisticChild = item => {
    if (
      !item ||
      !item.loai ||
      (item.loai !== TYPE_UNIT && item.loai !== TYPE_DEPARTMENT) ||
      !item.id
    ) {
      return
    }
    this.props.actions.refreshStatistic(false)
    let pathName = `/tien-ich/thong-ke-bao-nham/chi-tiet/don-vi/${item.id}`
    if (item.loai === TYPE_DEPARTMENT) {
      pathName = `/tien-ich/thong-ke-bao-nham/chi-tiet/phong-ban/${item.id}`
    }
    return (
      this.props.history &&
      this.props.history.push({
        pathname: pathName,
        state: {
          isCheck: true,
        },
      })
    )
  }

  render() {
    let { item, number } = this.props
    if (!item) {
      return null
    }

    const isTotalRaw =
      item.id === DEFAULT_ID &&
      item.name === TOTAL_NAME &&
      item.loai === TYPE_DEFAULT
    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td
          onClick={this.redirectToStatisticChild.bind(this, item)}
          className={classnames('gerenal-list-utilities', {
            'over-time':
              (item.loai === TYPE_UNIT || item.loai === TYPE_DEPARTMENT) &&
              !isTotalRaw,
          })}
        >
          <span className="hover-over-time">{item.name}</span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.soLanChiDaoNham)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.soLanGiaoViecNham)}
        </td>
      </tr>
    )
  }
}

export default ListItem
