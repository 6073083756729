import React, { memo, Fragment, useRef, useState, useEffect } from 'react'
import csx from 'classnames'
import { get } from 'lodash'

import { Row } from '../Grid'
import { Collapse } from './index'

const CollapseBlock = ({
  children = null,
  defaultOpen = true,
  onChange = () => {},
  showBlockCollapse = true,
  title = '',
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [showCollapse, _setShowCollapse] = useState(defaultOpen)

  const toggleCollapse = () => {
    _mountedSet(_setShowCollapse, !showCollapse)
    onChange(!showCollapse)
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Fragment>
      {showBlockCollapse && (
        <Row className="d-flex" onClick={toggleCollapse}>
          <span className={csx('flex-auto', 'mr10', 'font-size-13')}>
            {title}
          </span>
          <i
            className={csx(
              {
                'icon-arrow-dropup': showCollapse,
                'icon-arrow-dropdown': !showCollapse,
              },
              'ml5',
              'size-icon-6',
              'align-center'
            )}
          />
        </Row>
      )}
      <Collapse isOpen={showCollapse}>{children}</Collapse>
    </Fragment>
  )
}

export default memo(CollapseBlock)
