import { find } from 'lodash'

import {
  GET_THONGKE_QUANLY_CONGVIEC_LOAI_GIAOVIEC,
  GET_NOTIFICATION_COUNTER,
} from 'constants/ActionTypes'
import { RESPONSIBILITY_FILTER } from 'constants/Enum'

const counters = {
  task: {
    notification: 0,
    assignToMe: 0,
    watchedByMe: 0,
  },
}

const counter = (state = counters, { type, payload }) => {
  switch (type) {
    case GET_THONGKE_QUANLY_CONGVIEC_LOAI_GIAOVIEC:
      const result = payload?.data?.result || []
      const followTask = find(result || [], {
        responsibilityType: RESPONSIBILITY_FILTER.TOI_DUOC_GIAO,
      })
      const watchedTask = find(result || [], {
        responsibilityType: RESPONSIBILITY_FILTER.TOI_THEO_DOI,
      })
      const assignByMeTask = find(result || [], {
        responsibilityType: RESPONSIBILITY_FILTER.TOI_GIAO,
      })

      return {
        ...state,
        task: {
          ...state.task,
          assignToMe: followTask?.totalCount || 0,
          watchedByMe: watchedTask?.totalCount || 0,
          assignByMe: assignByMeTask?.totalCount || 0,
        },
      }

    case GET_NOTIFICATION_COUNTER:
      return {
        ...state,
        task: {
          ...state.task,
          notification: payload?.data?.result || 0,
        },
      }

    default:
      return state
  }
}

export default counter
