import React, { memo } from 'react'
import { Button } from '@blueprintjs/core'
import csx from 'classnames'

const ResetButton = ({ onClick = () => {}, className }) => {
  return (
    <Button
      className={csx(
        'cpc-button-filter-clear-old',
        'pt-intent-btn-white',
        className
      )}
      onClick={onClick}
    >
      <i className="pt-icon icon-Loading mr0" />
    </Button>
  )
}

export default memo(ResetButton)
