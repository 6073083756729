import { CHAT_GET, CHAT_COUNT } from 'constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  count: 0,
}

export default function chats(state = initialState, action) {
  switch (action.type) {
    case CHAT_GET:
      return {
        ...state,
        list: {
          page: action.payload.data.result.page,
          total: action.payload.data.result.total,
          items:
            action.payload.data.result && action.payload.data.result.items
              ? action.payload.data.result.items
              : [],
        },
      }
    case CHAT_COUNT:
      return {
        ...state,
        count: action.payload?.data?.result || 0,
      }
    default:
      return state
  }
}
