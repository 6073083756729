import React, { useContext } from 'react'
import classnames from 'classnames'
import { size } from 'lodash'

import { StatisticContext } from '../Statistic'
import { Table } from 'components/newCommon2'
import { ASSIGN_TYPE, GUID_EMPTY } from 'constants/Enum'

const ThongKeCongViec = () => {
  const {
    dataTable = [],
    filterConvert,
    isLoadingTable = false,
    isErrorTable = false,
    changeFilter = () => {},
    onclickQuaHan = () => {},
  } = useContext(StatisticContext)

  // Cấu trúc bảng
  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Stt',
      render: (v, r, index) => {
        let numberStart = 1
        if (size(dataTable) > 1) {
          numberStart = 0
        }

        const num = 0 * 10 + index + numberStart
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'phongBan',
      width: 30,
      headClass: 'uppercase',
      className: 'min-width-100 align-center',
      title: !filterConvert['SearchRequest.Type']
        ? 'Đơn vị'
        : filterConvert['SearchRequest.Type'] === ASSIGN_TYPE.DON_VI
        ? 'Phòng ban'
        : filterConvert['SearchRequest.Type'] === ASSIGN_TYPE.PHONG_BAN
        ? 'Chuyên viên'
        : 'Chuyên viên',
      render: ({ id, name, type }) => {
        let isClick = false
        if (
          (type === ASSIGN_TYPE.DON_VI || type === ASSIGN_TYPE.PHONG_BAN) &&
          id !== GUID_EMPTY
        ) {
          isClick = true
        }

        let nameFilter = 'caNhan'
        if (type === ASSIGN_TYPE.DON_VI) {
          nameFilter = 'donVi'
        } else if (type === ASSIGN_TYPE.PHONG_BAN) {
          nameFilter = 'phongBan'
        }

        return (
          <span
            className={classnames('', {
              'color-blue': isClick,
              'cursor-pointer': isClick,
              uppercase: id === GUID_EMPTY,
            })}
            onClick={() =>
              isClick
                ? changeFilter({ name: nameFilter, isRowChange: true })(id)
                : null
            }
          >
            {name}
          </span>
        )
      },
    },
    {
      key: 'tongSoCongViecChuaHoanThanh',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-100 align-center justify-center',
      title: 'Chưa hoàn thành',
      dataIndex: 'tongSoCongViecChuaHoanThanh',
    },
    {
      key: 'tongSoCongViecDaHoanThanh',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-100 align-center justify-center',
      title: 'Đã hoàn thành',
      dataIndex: 'tongSoCongViecDaHoanThanh',
    },
    {
      key: 'quanHanGiaiQuyet',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-100 align-center justify-center',
      title: 'Quá hạn giải quyết',
      // dataIndex: 'quanHanGiaiQuyet',
      render: data => {
        let isClick = false
        if (data.quanHanGiaiQuyet !== 0) {
          isClick = true
        }

        return (
          <span
            className={classnames('', {
              'color-blue-important': isClick,
              'cursor-pointer': isClick,
            })}
            onClick={() => (isClick ? onclickQuaHan(data) : null)}
          >
            {data.quanHanGiaiQuyet}
          </span>
        )
      },
    },
    {
      key: 'tongSoCongViec',
      width: 10,
      headClass: 'uppercase',
      className: 'align-center justify-center',
      title: 'Tổng',
      dataIndex: 'tongSoCongViec',
      render: value => {
        return <span className="font-weight-600">{value}</span>
      },
    },
  ]

  return (
    <Table
      loading={isLoadingTable}
      error={isErrorTable}
      className="cpc-table-radius"
      autoHide={false}
      columns={columns}
      data={dataTable}
      tableMinWidth={600}
      isSpecialFirstRow
    />
  )
}

export default ThongKeCongViec
