import * as types from '../constants/ActionTypes'

const initialState = {
  items: [],
}

export default function reportWork(state = initialState, action) {
  switch (action.type) {
    case types.WORK_GET_REPORT:
      return {
        ...state,
        items:
          action.payload.data.result &&
          action.payload.data.result.result &&
          action.payload.data.result.result.items
            ? action.payload.data.result.result.items
            : [],
      }

    default:
      return state
  }
}
