import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: '',
  categories: [],
  items: [],
  fileItems: [],
  storage: {
    capacity: '2GB',
    used: 0,
  },
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  type: {
    unit: [],
    department: [],
    personal: [],
    profile: [],
  },
  typeItems: {
    unit: {
      page: 1,
      total: 0,
      items: [],
    },
    department: {
      page: 1,
      total: 0,
      items: [],
    },
    personal: {
      page: 1,
      total: 0,
      items: [],
    },
  },
  documentCabinetOverTime: {
    list: {
      page: 1,
      total: 0,
      items: [],
    },
  },
  path: '',
}

export default function documentsCabinet(state = initialState, action) {
  switch (action.type) {
    case types.SET_DOCUMENT_CABINET_PATH:
      return {
        ...state,
        path: action.path,
      }
    case types.DOCUMENTS_CABINET_ITEM_SET_INITIAL:
      return {
        ...state,
        list: {
          page: 1,
          total: 0,
          items: [],
        },
      }

    case types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL:
      return {
        ...state,
        categories: [],
      }

    case types.DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES:
      return {
        ...state,
        type: {
          ...state.type,
          department: action.payload.data.entities.documentCabinetCategories
            ? Object.keys(
                action.payload.data.entities.documentCabinetCategories
              )
            : [],
        },
        categories: action.payload.data.entities.documentCabinetCategories
          ? Object.keys(action.payload.data.entities.documentCabinetCategories)
          : [],
      }

    case types.DOCUMENTS_CABINET_PERSONAL_CATEGORIES:
      return {
        ...state,
        type: {
          ...state.type,
          personal: action.payload.data.entities.documentCabinetCategories
            ? Object.keys(
                action.payload.data.entities.documentCabinetCategories
              )
            : [],
        },
        categories: action.payload.data.entities.documentCabinetCategories
          ? Object.keys(action.payload.data.entities.documentCabinetCategories)
          : [],
      }

    case types.DOCUMENTS_CABINET_UNIT_CATEGORIES:
      return {
        ...state,
        type: {
          ...state.type,
          unit: action.payload.data.entities.documentCabinetCategories
            ? Object.keys(
                action.payload.data.entities.documentCabinetCategories
              )
            : [],
        },
        categories: action.payload.data.entities.documentCabinetCategories
          ? Object.keys(action.payload.data.entities.documentCabinetCategories)
          : [],
      }

    case types.DOCUMENTS_CABINET_PROFILE_CATEGORIES:
      return {
        ...state,
        type: {
          ...state.type,
          profile: action.payload.data.entities.documentCabinetCategories
            ? Object.keys(
                action.payload.data.entities.documentCabinetCategories
              )
            : [],
        },
        categories: action.payload.data.entities.documentCabinetCategories
          ? Object.keys(action.payload.data.entities.documentCabinetCategories)
          : [],
      }

    case types.DOCUMENTS_CABINET_BY_PERSONAL:
      return {
        ...state,
        typeItems: {
          ...state.typeItems,
          personal: {
            page: action.payload.data.result.result.page,
            total: action.payload.data.result.result.total,
            items: action.payload.data.entities.documentsCabinet
              ? Object.keys(action.payload.data.entities.documentsCabinet)
              : [],
          },
        },
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items: action.payload.data.entities.documentsCabinet
            ? Object.keys(action.payload.data.entities.documentsCabinet)
            : [],
        },
      }

    case types.DOCUMENTS_CABINET_BY_UNIT:
      return {
        ...state,
        typeItems: {
          ...state.typeItems,
          unit: {
            page: action.payload.data.result.result.page,
            total: action.payload.data.result.result.total,
            items: action.payload.data.entities.documentsCabinet
              ? Object.keys(action.payload.data.entities.documentsCabinet)
              : [],
          },
        },
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items: action.payload.data.entities.documentsCabinet
            ? Object.keys(action.payload.data.entities.documentsCabinet)
            : [],
        },
      }

    case types.DOCUMENTS_CABINET_BY_DEPARTMENT:
      return {
        ...state,
        typeItems: {
          ...state.typeItems,
          department: {
            page: action.payload.data.result.result.page,
            total: action.payload.data.result.result.total,
            items: action.payload.data.entities.documentsCabinet
              ? Object.keys(action.payload.data.entities.documentsCabinet)
              : [],
          },
        },
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items: action.payload.data.entities.documentsCabinet
            ? Object.keys(action.payload.data.entities.documentsCabinet)
            : [],
        },
      }

    case types.DOCUMENTS_CABINET_GET_ITEMS_PERSONAL:
      return {
        ...state,
        items: action.payload.data.entities.documentsCabinet
          ? Object.keys(action.payload.data.entities.documentsCabinet)
          : [],
      }

    case types.DOCUMENTS_CABINET_GET_ITEMS_UNIT:
      return {
        ...state,
        items: action.payload.data.entities.documentsCabinet
          ? Object.keys(action.payload.data.entities.documentsCabinet)
          : [],
      }

    case types.DOCUMENTS_CABINET_GET_ITEMS_DEPARTMENT:
      return {
        ...state,
        items: action.payload.data.entities.documentsCabinet
          ? Object.keys(action.payload.data.entities.documentsCabinet)
          : [],
      }

    case types.STORAGE_CAPACITY:
      return {
        ...state,
        storage: {
          ...state.storage,
          used: action.payload.data.result || 0,
        },
      }

    case types.DOCUMENTS_CABINET_GET_ITEM_UNIT:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.DOCUMENTS_CABINET_GET_ITEM_DEPARTMENT:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.DOCUMENTS_CABINET_GET_ITEM_PERSONAL:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.DOCUMENT_RECEIVE_FILE_INFO:
      return {
        ...state,
        fileItems: action.payload.data.entities.files
          ? Object.keys(action.payload.data.entities.files)
          : [],
      }

    case types.DOCUMENTS_CABINET_OVERTIME:
      return {
        ...state,
        documentCabinetOverTime: {
          ...state.documentCabinetOverTime,
          list: {
            page: action.payload.data.result.page,
            total: action.payload.data.result.total,
            items: action.payload.data.result.items,
          },
        },
      }

    default:
      return state
  }
}
