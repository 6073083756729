import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExecutingViewBoxItem from '../common/ExecutingViewBoxItem'

class ExecutingView extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    executingViews: PropTypes.array,
  }

  render() {
    return (
      <div>
        {this.props.executingViews &&
          this.props.executingViews.map((item, i) => (
            <ExecutingViewBoxItem
              isInternalDoc={true}
              item={item}
              key={i}
              auth={this.props.auth}
              actions={this.props.actions}
            />
          ))}
      </div>
    )
  }
}

export default ExecutingView
