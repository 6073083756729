import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-container">
        <div className="info-footer">
          <p className="version">kOffice - Nền tảng văn phòng số</p>
          <p className="copyright">
            &copy; {new Date().getFullYear()} CÔNG TY CỔ PHẦN CÔNG NGHỆ KCTECH
          </p>
        </div>
      </footer>
    )
  }
}
export default Footer
