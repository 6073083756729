import {
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
  API_REQUEST_ERROR,
  API_NOTIFICATION_COUNTER,
} from 'constants/ActionTypes'

export const getDSThongBaoChung = params => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/thongbaochung/quanly',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const deleteNotification = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/thongbaochung/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const notificationCreate = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/thongbaochung`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const notificationUpdate = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/thongbaochung`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const fetchNotificationItem = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/thongbaochung/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const fetchNotificationCounter = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_NOTIFICATION_COUNTER, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/thongbaochung/counter/chuadoc',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getNotificationList = params => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/thongbaochung',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const postNotificationRead = params => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/thongbaochung/dadoc',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const postNotificationReadAll = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/thongbaochung/doctatca',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })
