import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
class NotificationGeneralPage extends React.Component {
  render() {
    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <div className="dashboard-container">
          <HeadingPage
            namePage="Thông báo chung"
            iconPage="icon-Thong_Bao_Chung"
            className="mb0"
          />
          <div className="general-notification-utility-container">
            <div className="general-notification-utility-content">
              <ul className="row list-general-utility">
                <li className="col-xs-12 col-sm-12 col-md-6 col-lg-6 item-list">
                  <div className="utility-media-block">
                    <div className="image-content">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/thumb-procedure.png'
                        }
                        alt="Chương trình quản lý văn bản và nhắn tin nhắc việc: "
                        height="126"
                        width="154"
                        className="image"
                      />
                    </div>
                    <div className="utility-main-content">
                      <h3 className="main-heading">
                        Chương trình quản lý văn bản và nhắn tin nhắc việc:{' '}
                      </h3>
                      <ul className="utility-info-list">
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Hệ thống các kho văn bản điện tử tập trung
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Quản lý, xử lý và luân chuyển văn bản trực tiếp từ
                              đơn vị này đến đơn vị khác
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Hỗ trợ khả năng điều hành và phân luồng công việc
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Cung cấp cho người dùng khả năng làm việc từ xa.
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Xây dựng một văn phòng không giấy tờ, giúp cho đơn
                              vị tiết kiệm chi phí
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="col-xs-12 col-sm-12 col-md-6 col-lg-6 item-list">
                  <div className="utility-media-block">
                    <div className="image-content">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/images/thumb-general-notification.png'
                        }
                        alt="Thông báo toàn đơn vị"
                        height="115"
                        width="118"
                        className="image"
                      />
                    </div>
                    <div className="utility-main-content">
                      <h3 className="main-heading">Thông báo toàn đơn vị</h3>
                      <ul className="utility-info-list">
                        <li className="item">
                          <p className="desc desc-video">
                            <a className="text-link" href="/#">
                              Video hướng dẫn ký số nội bộ
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc desc-video">
                            <a className="text-link" href="/#">
                              Video hướng dẫn sử dụng chữ ký số
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Hướng dẫn tạo file ký số
                            </a>
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            <a className="text-link" href="/#">
                              Giới thiệu một số tính năng mới của Global-eOffice
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="col-xs-12 col-sm-12 col-md-6 col-lg-6 item-list">
                  <div className="utility-media-block">
                    <div className="image-content">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/thumb-setting.png'
                        }
                        alt="Cài đặt chương trình"
                        height="139"
                        width="138"
                        className="image"
                      />
                    </div>
                    <div className="utility-main-content">
                      <h3 className="main-heading">Cài đặt chương trình</h3>
                      <ul className="utility-info-list">
                        <li className="item">
                          <p className="desc">
                            Anh/Chị hãy{' '}
                            <a className="text-link-highline" href="/#">
                              Nhấn vào đây
                            </a>{' '}
                            để cài đặt chương trình Global-eOffice Chat. Chương
                            trình sẽ tự động cài đặt nếu trên máy của Anh/Chị
                            chưa có.{' '}
                          </p>
                        </li>
                        <li className="item">
                          <p className="desc">
                            Download hướng dẫn sử dụng{' '}
                            <a className="text-link-highline" href="/#">
                              Global-eOffice
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}
export default NotificationGeneralPage
