import {
  CONTACT_FETCH_DEPARTMENTS,
  CONTACT_FETCH_UNITS,
  COMMON_FETCH_GET_UNIT_ITEM,
  GET_LIST_CONTACT,
} from '../constants/ActionTypes'

const initialState = {
  departments: {},
  units: [],
  unitItem: null,
  listContact: [],
}

export default function contact(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_CONTACT:
      return {
        ...state,
        listContact: action.payload.data.result,
      }

    case CONTACT_FETCH_DEPARTMENTS:
      const items = state.departments
      items[action.meta.previousAction.unitId] =
        action.payload.data.result.items
      return {
        ...state,
        departments: items,
      }

    case CONTACT_FETCH_UNITS:
      return {
        ...state,
        units: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_UNIT_ITEM:
      return {
        ...state,
        unitItem: action.payload.data.result,
      }
    default:
      return state
  }
}
