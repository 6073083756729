/* eslint-disable max-len */
import React, { memo, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { calendarConfig } from 'constants/config'

const Calendar = ({
  calendarRef,
  filterKeys,
  dataSource,
  viewType,
  setFilter,
  onEventItemClick,
}) => {
  const eventDateFormat = 'YYYY-MM-DD'

  const changeFilterHandle = useCallback(
    ({ start: startProps, end: endProps } = {}) => {
      const start = calendarRef.current?.getApi().view.activeStart || startProps
      const end = calendarRef.current?.getApi().view.activeEnd || endProps
      const values = {
        [filterKeys.from]: moment(start).format(eventDateFormat),
        [filterKeys.to]: moment(end).format(eventDateFormat),
      }
      setFilter(values)
    },
    [calendarRef, filterKeys, setFilter, eventDateFormat]
  )

  const customButtons = useMemo(() => {
    return {
      prev: {
        click: () => {
          calendarRef.current.getApi().prev()
          changeFilterHandle()
        },
      },
      next: {
        click: () => {
          calendarRef.current.getApi().next()
          changeFilterHandle()
        },
      },
    }
  }, [calendarRef, changeFilterHandle])

  const eventDatas = useMemo(
    () =>
      dataSource.map(item => ({
        ...item,
        eventId: item.cuocHopId,
        date: item.batDau
          ? moment(item.batDau).format(eventDateFormat)
          : undefined,
        title: `${moment(item?.batDau).format('DD/MM/YYYY HH:mm')} - ${
          item?.tieuDe
        }`,
      })),
    [dataSource, eventDateFormat]
  )
  return (
    <div className="cpc-calendar bg-white">
      <FullCalendar
        {...calendarConfig}
        dayMaxEvents={viewType === 'dayGridMonth' ? 3 : 10}
        dayHeaderFormat={
          viewType === 'dayGridMonth'
            ? { weekday: 'long' }
            : {
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
                omitCommas: true,
              }
        }
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        customButtons={customButtons}
        initialView="dayGridMonth"
        eventClick={item => onEventItemClick(item?.event?._def?.extendedProps)}
        initialEvents={changeFilterHandle}
        events={eventDatas}
      />
    </div>
  )
}

Calendar.propTypes = {
  calendarRef: PropTypes.object,
  filterKeys: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  dataSource: PropTypes.array.isRequired,
  viewType: PropTypes.string,
  changeFilter: PropTypes.func,
  onEventItemClick: PropTypes.func,
}

Calendar.defaultProps = {
  calendarRef: null,
  filterKeys: null,
  match: null,
  history: null,
  dataSource: [],
  viewType: 'dayGridMonth',
  changeFilter: () => {},
  onEventItemClick: () => {},
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(memo(Calendar))
