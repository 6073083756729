import { Checkbox, Collapse } from '@blueprintjs/core'
import React, { Component } from 'react'

import classnames from 'classnames'

export default class UnitRecursive extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      selectedUnits: [],
    }
  }

  handleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  onChangeUnit = e => {
    this.props.onChange(e.target.value)
  }

  render() {
    const { data, unit, selected, defaultSelected } = this.props

    const displayUnit = JSON.stringify({
      don_vi_id: unit.donViId,
      ten_viet_tat: unit.tenVietTat,
      ten_don_vi: unit.tenDonVi,
    })

    const isSelectedDefault =
      defaultSelected &&
      defaultSelected.findIndex(item => item.don_vi_id === unit.donViId) !== -1
    const isSelected =
      selected &&
      selected.findIndex(item => item.don_vi_id === unit.donViId) !== -1

    return (
      <div className={classnames('department-tree')}>
        <div className="department-tree__item" onClick={this.handleCollapse}>
          {!!unit.dsDonViCon ? (
            this.state.isOpen ? (
              <span className="cpc department-tree__icon icon-round-delete-button" />
            ) : (
              <span className="cpc department-tree__icon icon-rounded-add-button" />
            )
          ) : (
            <span className="cpc department-tree__icon no-icon" />
          )}
          <Checkbox
            disabled={isSelectedDefault}
            checked={isSelectedDefault || isSelected}
            className="department-tree__checkbox-form department-tree__checkbox-form--department"
            value={displayUnit || ''}
            onChange={e => this.onChangeUnit(e)}
            label={unit.tenVietTat}
          />
        </div>
        <Collapse className="department-tree__child" isOpen={this.state.isOpen}>
          {data &&
            data.length > 0 &&
            data.map(item => (
              <UnitRecursive
                onChange={this.props.onChange}
                key={item.donViId}
                data={item.dsDonViCon}
                unit={item}
                selected={selected}
                defaultSelected={defaultSelected}
              />
            ))}
        </Collapse>
      </div>
    )
  }
}
