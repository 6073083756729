import React, { useEffect, useState } from 'react'
import size from 'lodash/size'
import QuaTrinhXuLyItem from './QuaTrinhXuLyItem'
import QuaTrinhXuLyDonVi from './QuaTrinhXuLyDonVi'

const QuaTrinhXuLy = ({ data, dataDonViVanBan = [], id, tokenDO }) => {
  const [newData, setNewData] = useState([])

  useEffect(() => {
    if (size(dataDonViVanBan) !== 0) {
      // 251 đối với đơn vị điện lực miền trung
      const newData = dataDonViVanBan?.reduce((result, item) => {
        if (
          result &&
          result.some(e => item?.idDvCha === e?.idDv && e.idDv !== 251)
        ) {
          return result
        }

        return [...result, item]
      }, [])
      setNewData(newData)
    }
  }, [dataDonViVanBan])

  return (
    <>
      {/* Lãnh đạo */}
      {data?.lANHDAO?.map?.((item, index) => (
        <QuaTrinhXuLyItem
          key={index}
          hasChild={item.isChildren}
          isLeader={item?.isChidao ?? false}
          id={id}
          idDv={tokenDO?.nhanVien?.idDv}
          tokenDO={tokenDO}
          itemData={item}
        />
      ))}
      {/* Phòng ban */}
      {data?.pHONGBAN?.map?.((item, index) => (
        <QuaTrinhXuLyItem
          key={index}
          hasChild={item.isChildren}
          isLeader={item?.isChidao ?? false}
          id={id}
          idDv={tokenDO?.nhanVien?.idDv}
          tokenDO={tokenDO}
          itemData={item}
        />
      ))}
      {/* Đơn vị */}
      {newData &&
        newData?.map((item, index) => (
          <QuaTrinhXuLyDonVi
            key={index}
            id={id}
            idDv={item?.idDv}
            hasChild={true}
            tokenDO={tokenDO}
            data={item}
            childData={
              dataDonViVanBan?.filter(
                e => e.idDvCha === item?.idDv && e.idDvCha !== 251
              ) || []
            }
          />
        ))}
    </>
  )
}

export default QuaTrinhXuLy
