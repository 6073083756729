import React, { PureComponent } from 'react'

import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import UsingGroupList from './UsingGroupList'
import UsingGroupListFilter from './UsingGroupListFilter'
import _ from 'lodash'
import { connect } from 'react-redux'

const PAGE_NAME = 'Danh sách quy trình ký số'
const PAGE_ICON = 'icon-danh-muc-quy-trinh'

class UsingGroupPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      isLoading: true,
      isError: false,
    }
  }

  onSearch = (filter, reset = false) => {
    if (reset) {
      return this.setProcedureUnit(this.props)
    }
    this.setProcedureUnit(this.props, filter, reset)
  }

  componentDidMount() {
    if (this.props.unitUserId) {
      this.setProcedureUnit(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.unitUserId !== nextProps.unitUserId) {
      this.setProcedureUnit(nextProps)
    }
  }

  render() {
    const { isLoading, isError, list } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader pageIcon={PAGE_ICON} pageName={PAGE_NAME} />
        <UsingGroupListFilter onSearch={this.onSearch} />
        <UsingGroupList data={list} isLoading={isLoading} isError={isError} />
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  unitUserId: _.get(state, 'auth.user.dsChucDanh[0].donViId', null),
})
export default connect(mapStateToProps, null)(UsingGroupPage)
