import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import { TEXT_BUTTON_CANCEL, TEXT_BUTTOM_CLOSE } from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import classnames from 'classnames'
import * as POPUP from '../../constants/Popup'

const THU_HOI_CONG_VAN_DI =
  'Hệ thống sẽ thu hồi công văn đã gửi cho các đơn vị và hủy tất cả luồng thực hiện công việc liên quan'
class PopupConfirm extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  state = {
    lyDo: this.props.textLyDo.length !== 0 ? this.props.textLyDo : '',
    error: {},
  }

  componentWillReceiveProps(props) {
    if (props.textLyDo) {
      this.setState({
        lyDo: props.textLyDo,
      })
    }
  }

  handleChange(e) {
    if (e.target.name === 'lyDo') {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.props.textarea === true) {
      this.setState({ error: {} })
      const error = {}
      if (
        (!this.props.acceptSpace && this.state.lyDo.trim().length === 0) ||
        (this.props.acceptSpace && this.state.lyDo.length === 0)
      ) {
        error.lyDo = MESSAGE.TEXT_MESSAGE_DEFAULT
      }

      this.setState({ error })
      if (Object.keys(error).length !== 0) {
        return
      }

      let param = this.state.lyDo.trim()
      if (this.state.lyDo.trim() === '' && this.props.acceptSpace) {
        param = ' '
      }

      return this.props.onSubmit(param, e)
    }

    this.props.onSubmit(e)
  }

  handleClosePopup = e => {
    this.setState(
      {
        lyDo: '',
        error: {},
      },
      () => {
        this.props.onClose && this.props.onClose(e)
      }
    )
  }

  render() {
    const { textPlaceholder } = this.props
    return (
      <div>
        <Dialog
          iconName={this.props.iconName}
          isOpen={this.props.isOpen}
          onClose={this.handleClosePopup}
          title={this.props.title}
          enforceFocus={true}
          hasBackdrop={false}
          canOutsideClickClose={false}
          {...this.state}
        >
          <form onSubmit={this.handleSubmit}>
            <div
              className={classnames('pt-dialog-body', {
                'popup-warning-body': this.props.isWarning === true,
              })}
            >
              {!this.props.textarea && this.props.children}
              {this.props.textarea === true && !this.props.actionPage && (
                <div className="container-popup">
                  {!this.props.isCheck && this.props.showTextWarning && (
                    <div className="div-warning-popup mb10">
                      <span className="span-warining-popup">Cảnh báo: </span>
                      <span className="span-messenger-popup">
                        {' '}
                        {THU_HOI_CONG_VAN_DI}{' '}
                      </span>
                    </div>
                  )}
                  <label className="pt-label">
                    <div className="span-label pt0 mt0">
                      <span>
                        {this.props.isCheck ? 'Nội dung' : 'Lý do thu hồi'}
                      </span>
                      <span className="required-input">*</span>
                    </div>
                    <textarea
                      className="pt-input"
                      style={{ resize: 'vertical' }}
                      name="lyDo"
                      cols="36"
                      rows="3"
                      value={this.state.lyDo}
                      onChange={this.handleChange}
                      placeholder={textPlaceholder}
                      dir="auto"
                      maxLength="500"
                    ></textarea>
                  </label>
                </div>
              )}
              {this.props.textarea === true &&
                this.props.actionPage === POPUP.XOA_KY_SO_CONG_VAN_DI && (
                  <div className="container-popup">
                    <label className="pt-label">
                      <div className="span-label">
                        <span>Lý do xoá</span>
                        <span className="required-input">*</span>
                      </div>
                      <textarea
                        className="pt-input"
                        name="lyDo"
                        cols="36"
                        rows="3"
                        value={this.state.lyDo}
                        onChange={this.handleChange}
                        placeholder={textPlaceholder}
                        dir="auto"
                      ></textarea>
                    </label>
                  </div>
                )}
              {this.state.error.lyDo !== undefined && (
                <div className="pt-form-helper-text">
                  {this.state.error.lyDo}
                </div>
              )}
              {this.props.error && (
                <div className="error">{this.props.error}</div>
              )}
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                {!this.props.isNotification && (
                  <div className="box-button">
                    <Button
                      className={`button-exit${this.props.classNameButton}`}
                      text={this.props.textCancel || TEXT_BUTTON_CANCEL}
                      onClick={this.handleClosePopup}
                      disabled={this.props.isLoading}
                    />
                    <Button
                      className={`button-blue${this.props.classNameButton}`}
                      text={this.props.text}
                      intent={Intent.PRIMARY}
                      type="submit"
                      loading={this.props.isLoading}
                      disabled={this.props.isLoading}
                    />
                  </div>
                )}
                {this.props.isNotification && (
                  <div className="box-button">
                    <Button
                      className={`button-blue${this.props.classNameButton}`}
                      text={TEXT_BUTTOM_CLOSE}
                      onClick={this.handleClosePopup}
                      disabled={this.props.isLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    )
  }
}

PopupConfirm.defaultProps = {
  isNotification: false,
  showTextWarning: true,
  acceptSpace: false,
  textLyDo: '',
  classNameButton: '',
  textPlaceholder: 'Nhập nội dung…',
}

PopupConfirm.propTypes = {
  acceptSpace: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isWarning: PropTypes.bool,
  isNotification: PropTypes.bool,
  showTextWarning: PropTypes.bool,
  textLyDo: PropTypes.string,
  classNameButton: PropTypes.string,
  textPlaceholder: PropTypes.string,
}

export default PopupConfirm
