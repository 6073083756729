import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import PropTypes from 'prop-types'
import { Authorization, Permission } from '../auth'
import { Intent } from '@blueprintjs/core'
import 'moment/locale/vi'
import { LoadingItem, PopupConfirm } from '../../components/common/'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import classnames from 'classnames'
import * as POPUP from '../../constants/Popup'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.handleClickPopupHuyXacNhan = this.handleClickPopupHuyXacNhan.bind(this)
    this.handleSubmitHuyXacNhan = this.handleSubmitHuyXacNhan.bind(this)
    this.handleClickPopupHoanThanhGopY =
      this.handleClickPopupHoanThanhGopY.bind(this)
    this.handleSubmitHoanThanhGopY = this.handleSubmitHoanThanhGopY.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    feedbacks: PropTypes.array,
    requestComments: PropTypes.object,
    onClickDuyetPhieuYeuCauGopY: PropTypes.func.isRequired,
    onClickDetailInfo: PropTypes.func.isRequired,
    onClickTongHopGopY: PropTypes.func.isRequired,
    onClickLuongGopY: PropTypes.func.isRequired,
    onClickChiDaoGopY: PropTypes.func.isRequired,
    onClickGiaoGopY: PropTypes.func.isRequired,
    onClickThucHienGopY: PropTypes.func.isRequired,
    onClickDuyetGopY: PropTypes.func.isRequired,
    onClickThongTinXacNhan: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    isLoadingData: PropTypes.object.isRequired,
    onRemoveFileInfo: PropTypes.func,
  }

  state = {
    isOpenPopup: false,
    isLoadingHuyXacNhan: false,
    isOpenPopupHoanThanh: false,
    isLoadingHoanThanh: false,
    typePopup: null,
    titlePopup: '',
    error: {},
    feedbackContent: '',
    feedbackForLeader: '',
    chucDanhId: null,
    phongBanId: null,
    maPhongBan: null,
    maKySo: null,
    isLoading: {
      disagree: false,
      sendFeedback: false,
      progression: false,
    },
    feedbackId: null,
    hrefToken: null,
    isDisagree: false,
    userDisagree: null,
    userAgrees: [],
    isAgree: false,
    otherSecretaryId: null,
    leaderCompositiveDepartment: null,
    popup_title: '',
    popup_content: '',
  }

  handleClickPopupHoanThanhGopY() {
    this.setState({
      isOpenPopupHoanThanh: !this.state.isOpenPopupHoanThanh,
      error: '',
    })
  }

  handleSubmitHoanThanhGopY(e) {
    e.preventDefault()
    this.setState({
      isOpenPopupHoanThanh: false,
      isLoadingHoanThanh: true,
    })
    let idPhieu = this.props.requestComments.item.id
    if (this.props.type === 'layYKienGoi') {
      this.props.actions.requestCommentHoanThanh(idPhieu).then(
        res => {
          if (res.payload && res.payload.status === 200) {
            this.props.history.goBack()
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERT_HOAN_THANH_THANHCONG,
              intent: Intent.SUCCESS,
            })
          } else {
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERT_HOAN_THANH_KHONGTHANHCONG,
              intent: Intent.DANGER,
            })
            this.setState({
              isOpenPopupHoanThanh: false,
              isLoadingHoanThanh: false,
            })
          }
        },
        () => {
          this.setState({
            isOpenPopupHoanThanh: false,
            isLoadingHoanThanh: false,
          })
        }
      )
    }
  }

  handleClickPopupHuyXacNhan() {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitHuyXacNhan(e) {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
      isLoadingHuyXacNhan: true,
    })
    let idPhieu = this.props.requestComments.item.id
    if (this.props.type === 'layYKienGoi') {
      this.props.actions.requestCommentHuyDuyet(idPhieu).then(
        res => {
          if (res.payload && res.payload.status === 200) {
            this.props.history.goBack()
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERT_CANCEL_AGREE_THANHCONG,
              intent: Intent.SUCCESS,
            })
          } else {
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERT_CANCEL_AGREE_KHONGTHANHCONG,
              intent: Intent.DANGER,
            })
          }
        },
        () => {
          this.setState({
            isLoadingHuyXacNhan: false,
          })
        }
      )
    } else {
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang === 1
      ) {
        this.props.actions.receivedCommentHuyChiDao(idPhieu).then(
          res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_HUY_CHI_DAO_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_HUY_CHI_DAO_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
          },
          () => {
            this.setState({
              isLoadingHuyXacNhan: false,
            })
          }
        )
      }
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang === 2
      ) {
        this.props.actions.receivedCommentHuyGiaoGopY(idPhieu).then(
          res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_HUY_GIAO_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_HUY_GIAO_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
          },
          () => {
            this.setState({
              isLoadingHuyXacNhan: false,
            })
          }
        )
      }
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang === 3
      ) {
        this.props.actions.receivedCommentHuyGopY(idPhieu).then(
          res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_HUY_GOP_Y_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_HUY_GOP_Y_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
          },
          () => {
            this.setState({
              isLoadingHuyXacNhan: false,
            })
          }
        )
      }
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang === 4
      ) {
        this.props.actions.receivedCommentHuyXacNhan(idPhieu).then(
          res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_CANCEL_APPROVE_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message:
                  MESSAGE.TEXT_MESSAGE_ALERT_CANCEL_APPROVE_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
          },
          () => {
            this.setState({
              isLoadingHuyXacNhan: false,
            })
          }
        )
      }
    }
    this.setState({
      isLoadingHuyXacNhan: false,
    })
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.setState({
      error: {},
      feedbackContent: '',
      feedbackForLeader: '',
      isLeaderDepartment: false,
      hrefToken: null,
    })
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const nhanVienId =
      this.props.auth && this.props.auth.user
        ? this.props.auth.user.nhanVienId
        : ''
    const chucDanhId =
      this.props.auth && this.props.auth.mainDepartmentId
        ? this.props.auth.mainDepartmentId
        : ''
    const tinhTrang =
      this.props.requestComments && this.props.requestComments.item
        ? this.props.requestComments.item.tinhTrang
        : 0
    const isLanhDaoPhongBanXacNhanPhieuGoi =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoPhongBanLap
        ? this.props.requestComments.item.lanhDaoPhongBanLap.nhanVienId
        : '')
    const isLanhDaoDonViXacNhanPhieuGoi =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoDonVi
        ? this.props.requestComments.item.lanhDaoDonVi.nhanVienId
        : '')

    //nếu thu ky chua duyet thi tim trong danh sach thu ky duyet
    const thuKyXacNhanPhieuGoi =
      this.props.requestComments &&
      this.props.requestComments.item &&
      this.props.requestComments.item.dsThuKy &&
      this.props.requestComments.item.dsThuKy.filter(
        item => item.nhanVienId === nhanVienId
      )
    //nếu thu ky da duyet thì có trường thuKy
    const isthuKyXacNhanPhieuGoi =
      nhanVienId ===
      (this.props.requestComments.item && this.props.requestComments.item.thuKy
        ? this.props.requestComments.item.thuKy.nhanVienId
        : '')

    const isNguoiLap =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.nguoiLap
        ? this.props.requestComments.item.nguoiLap.nhanVienId
        : '')
    const isLanhDaoDonViChiDaoPhieuNhan =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoDonViChiDao
        ? this.props.requestComments.item.lanhDaoDonViChiDao.nhanVienId
        : '')
    const isLanhDaoPhongBanGiaoViecPhieuNhan =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoPhongBanGiaoViec
        ? this.props.requestComments.item.lanhDaoPhongBanGiaoViec.nhanVienId
        : '')
    const isLanhDaoPhongBanXacNhanPhieuNhan =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoPhongBanXacNhan
        ? this.props.requestComments.item.lanhDaoPhongBanXacNhan.nhanVienId
        : '')
    const isLanhDaoXacNhanPhieuNhan =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoDonViXacNhan
        ? this.props.requestComments.item.lanhDaoDonViXacNhan.nhanVienId
        : '')
    const iscaNhanChuTriPhieuNhan =
      nhanVienId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.caNhanChuTri
        ? this.props.requestComments.item.caNhanChuTri.nhanVienId
        : '')
    const isPhongBanGiaoGopY =
      chucDanhId ===
      (this.props.requestComments.item &&
      this.props.requestComments.item.phongBanId
        ? this.props.requestComments.item.phongBanId
        : '')

    //const  trangThaiPhongBanDuyetPhieuNhan = this.props.requestComments.item&&this.props.requestComments.item.lanhDaoPhongBanXacNhan ? this.props.requestComments.item.lanhDaoPhongBanXacNhan.trangThai : '';
    //const  trangThaiDonViDuyetPhieuNhan = this.props.requestComments.item&&this.props.requestComments.item.lanhDaoDonViXacNhan ? this.props.requestComments.item.lanhDaoDonViXacNhan.trangThai : '';
    const tinhTrangLanhDaoPhongBanXacNhanPhieuGoi =
      this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoPhongBanLap
        ? this.props.requestComments.item.lanhDaoPhongBanLap.trangThai
        : 0
    const tinhTrangThuKyXacNhanPhieuGoi =
      this.props.requestComments.item && this.props.requestComments.item.thuKy
        ? this.props.requestComments.item.thuKy.trangThai
        : 0
    const tinhTrangLanhDaoDonViXacNhanPhieuGoi =
      this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoDonVi
        ? this.props.requestComments.item.lanhDaoDonVi.trangThai
        : 0

    const tinhTrangLanhDaoPhongBanXacNhanPhieuNhan =
      this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoPhongBanXacNhan
        ? this.props.requestComments.item.lanhDaoPhongBanXacNhan.trangThai
        : 0
    const tinhTrangLanhDaoDonViXacNhanPhieuNhan =
      this.props.requestComments.item &&
      this.props.requestComments.item.lanhDaoDonViXacNhan
        ? this.props.requestComments.item.lanhDaoDonViXacNhan.trangThai
        : 0

    // const showXacNhanGopYNhan = this.props.type === 'layYKienNhan' && ((tinhTrang === TRANGTHAI.LDPB_CHUA_XAC_NHAN && isLanhDaoPhongBanXacNhanPhieuNhan)
    //     || (tinhTrang === TRANGTHAI.LDDV_CHUA_XAC_NHAN && isLanhDaoXacNhanPhieuNhan)
    //     ||(tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN && isLanhDaoPhongBanXacNhanPhieuNhan && trangThaiPhongBanDuyetPhieuNhan === TRANGTHAI.LAYYKIEN_KHONG_DONGY)
    //     || (tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN && isLanhDaoXacNhanPhieuNhan && trangThaiDonViDuyetPhieuNhan === TRANGTHAI.LAYYKIEN_KHONG_DONGY));

    const showXacNhanGopYGoi =
      this.props.type === 'layYKienGoi' &&
      tinhTrang === TRANGTHAI.LDPB_CHUA_DUYET &&
      isLanhDaoPhongBanXacNhanPhieuGoi
    const quaHan =
      this.props.requestComments.item && this.props.requestComments.item.quaHan

    let popup_title = ''
    let popup_content = ''
    if (this.props.type === 'layYKienGoi') {
      popup_title = POPUP.HUY_XAC_NHAN_YEU_CAU_GOP_Y
      popup_content = POPUP.COMFIRM_HUY_YEU_CAU_GOP_Y
    } else {
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang === TRANGTHAI.LDPB_CHUA_GIAO
      ) {
        popup_title = POPUP.HUY_CHI_DAO_GOP_Y
        popup_content = POPUP.COMFIRM_HUY_CHI_DAO_GOP_Y
      }
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang === TRANGTHAI.CHUA_GOP_Y
      ) {
        popup_title = POPUP.HUY_GIAO_GOP_Y
        popup_content = POPUP.COMFIRM_HUY_GIAO_GOP_Y
      }
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang ===
          TRANGTHAI.LDPB_CHUA_XAC_NHAN
      ) {
        popup_title = POPUP.HUY_GOP_Y
        popup_content = POPUP.COMFIRM_HUY_GOP_Y_THUC_HIEN
      }
      if (
        this.props.requestComments &&
        this.props.requestComments.item &&
        this.props.requestComments.item.tinhTrang ===
          TRANGTHAI.LDDV_CHUA_XAC_NHAN
      ) {
        popup_title = POPUP.HUY_XAC_NHAN_GOP_Y
        popup_content = POPUP.COMFIRM_HUY_GOP_Y
      }
    }

    return this.props.isLoadingData &&
      this.props.isLoadingData.detailBoxAction === true ? (
      <LoadingItem />
    ) : (
      <div className="list-action list-action-sign-number">
        <span className="icon-More show-th-mobile" tabIndex="0"></span>
        <div className="detail-info-container section-box-info">
          <div className="list-items">
            {this.props.requestComments &&
              this.props.requestComments.item &&
              this.props.requestComments.item.fileDinhKem &&
              this.props.requestComments.item.fileDinhKem.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">
                      File đính kèm (
                      {this.props.requestComments.item.fileDinhKem.length}):
                    </span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.requestComments.item.fileDinhKem.map(
                          (value, item) => {
                            let extensionTK = null
                            const fileDinhKem = value.fileName
                            if (fileDinhKem) {
                              extensionTK = fileDinhKem
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div
                                className="info-file"
                                style={{ marginTop: '0px !important' }}
                                key={item}
                              >
                                <span className="info">
                                  <a
                                    className="link-file"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    style={{
                                      backgroundImage: `url(${Tool.Utils.typeFile(
                                        extensionTK
                                      )})`,
                                    }}
                                  >
                                    {value.tenFile} -{' '}
                                    {Tool.Utils.getFileSize(value.kichThuoc)}
                                  </a>
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}
            {this.props.requestComments &&
              this.props.requestComments.item &&
              this.props.requestComments.item.fileThamKhao &&
              this.props.requestComments.item.fileThamKhao.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">
                      File tham khảo (
                      {this.props.requestComments.item.fileThamKhao.length}):
                    </span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.requestComments.item.fileThamKhao.map(
                          (value, item) => {
                            let extensionTK = null
                            const fileThamKhao = value.fileName
                            if (fileThamKhao) {
                              extensionTK = fileThamKhao
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div
                                className="info-file"
                                style={{ marginTop: '0px !important' }}
                                key={item}
                              >
                                <span className="info">
                                  <a
                                    className="link-file"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    style={{
                                      backgroundImage: `url(${Tool.Utils.typeFile(
                                        extensionTK
                                      )})`,
                                    }}
                                  >
                                    {value.tenFile} -{' '}
                                    {Tool.Utils.getFileSize(value.kichThuoc)}
                                  </a>
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </div>
        <p className="title">Thực Hiện</p>
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button>
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row list-action-detail">
            {showXacNhanGopYGoi && (
              <Authorization.Element
                permission={[Permission.LAYYKIENDONVI_XACNHAN_PHONGBAN]}
              >
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickDuyetPhieuYeuCauGopY}
                  >
                    <i className="icon-xac-nhan-lai icon-cpc"></i>
                    <span className="action-name">Xác nhận</span>
                  </button>
                </div>
              </Authorization.Element>
            )}
            {this.props.type === 'layYKienGoi' &&
              tinhTrang === TRANGTHAI.KHONG_DUYET &&
              ((isLanhDaoPhongBanXacNhanPhieuGoi &&
                tinhTrangLanhDaoPhongBanXacNhanPhieuGoi ===
                  TRANGTHAI.LAYYKIEN_KHONG_DONGY) ||
                (isthuKyXacNhanPhieuGoi &&
                  tinhTrangThuKyXacNhanPhieuGoi ===
                    TRANGTHAI.LAYYKIEN_KHONG_DONGY)) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickDuyetPhieuYeuCauGopY}
                  >
                    <i className="icon-xac-nhan-lai icon-cpc"></i>
                    <span className="action-name">Xác nhận lại</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienGoi' &&
              tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET && (
                <Authorization.Element
                  permission={[Permission.LAYYKIENDONVI_XACNHAN_DONVI]}
                >
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickDuyetPhieuYeuCauGopY}
                    >
                      <i className="icon-xac-nhan-lai icon-cpc"></i>
                      <span className="action-name">Xác nhận</span>
                    </button>
                  </div>
                </Authorization.Element>
              )}
            {this.props.type === 'layYKienGoi' &&
              ((thuKyXacNhanPhieuGoi &&
                thuKyXacNhanPhieuGoi.length > 0 &&
                tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET) ||
                (isthuKyXacNhanPhieuGoi &&
                  tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET)) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickDuyetPhieuYeuCauGopY}
                  >
                    <i className="icon-xac-nhan-lai icon-cpc"></i>
                    <span className="action-name">Xác nhận</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienGoi' &&
              tinhTrang === TRANGTHAI.KHONG_DUYET &&
              isLanhDaoDonViXacNhanPhieuGoi &&
              tinhTrangLanhDaoDonViXacNhanPhieuGoi ===
                TRANGTHAI.LAYYKIEN_KHONG_DONGY && (
                <Authorization.Element
                  permission={[Permission.LAYYKIENDONVI_XACNHAN_DONVI]}
                >
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickDuyetPhieuYeuCauGopY}
                    >
                      <i className="icon-xac-nhan-lai icon-cpc"></i>
                      <span className="action-name">Xác nhận lại</span>
                    </button>
                  </div>
                </Authorization.Element>
              )}
            {this.props.type === 'layYKienGoi' &&
              ((tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET &&
                isLanhDaoPhongBanXacNhanPhieuGoi) ||
                (tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET &&
                  isthuKyXacNhanPhieuGoi)) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.handleClickPopupHuyXacNhan}
                  >
                    <i className="icon-Huy icon-cpc"></i>
                    <span className="action-name">Hủy xác nhận</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienGoi' &&
              tinhTrang === TRANGTHAI.DANG_GOP_Y &&
              isNguoiLap &&
              quaHan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.handleClickPopupHoanThanhGopY}
                  >
                    <i className="icon-list-check icon-cpc"></i>
                    <span className="action-name">Hoàn thành phiếu góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.LDDV_CHUA_GIAO && (
                <Authorization.Element
                  permission={[Permission.LAYYKIENDONVI_CHIDAO]}
                >
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickChiDaoGopY}
                    >
                      <i className="icon-edit icon-cpc"></i>
                      <span className="action-name">Chỉ đạo góp ý</span>
                    </button>
                  </div>
                </Authorization.Element>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.LDPB_CHUA_GIAO &&
              isLanhDaoDonViChiDaoPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickChiDaoGopY}
                  >
                    <i className="icon-edit icon-cpc"></i>
                    <span className="action-name">Sửa chỉ đạo góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.LDPB_CHUA_GIAO &&
              isLanhDaoDonViChiDaoPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.handleClickPopupHuyXacNhan}
                  >
                    <i className="icon-Huy icon-cpc"></i>
                    <span className="action-name">Hủy chỉ đạo góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.LDPB_CHUA_GIAO &&
              isPhongBanGiaoGopY && (
                <Authorization.Element
                  permission={[Permission.LAYYKIENDONVI_GIAOVIEC]}
                >
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickGiaoGopY}
                    >
                      <i className="icon-edit icon-cpc"></i>
                      <span className="action-name">Giao góp ý</span>
                    </button>
                  </div>
                </Authorization.Element>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.CHUA_GOP_Y &&
              isLanhDaoPhongBanGiaoViecPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickGiaoGopY}
                  >
                    <i className="icon-edit icon-cpc"></i>
                    <span className="action-name">Sửa giao góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.CHUA_GOP_Y &&
              isLanhDaoPhongBanGiaoViecPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.handleClickPopupHuyXacNhan}
                  >
                    <i className="icon-Huy icon-cpc"></i>
                    <span className="action-name">Hủy giao góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.CHUA_GOP_Y &&
              iscaNhanChuTriPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickThucHienGopY}
                  >
                    <i className="icon-edit icon-cpc"></i>
                    <span className="action-name">Góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              (tinhTrang === TRANGTHAI.LDPB_CHUA_XAC_NHAN ||
                tinhTrang === TRANGTHAI.LDDV_CHUA_XAC_NHAN) &&
              iscaNhanChuTriPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickThucHienGopY}
                  >
                    <i className="icon-edit icon-cpc"></i>
                    <span className="action-name">Sửa Góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.LDPB_CHUA_XAC_NHAN &&
              iscaNhanChuTriPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.handleClickPopupHuyXacNhan}
                  >
                    <i className="icon-Huy icon-cpc"></i>
                    <span className="action-name">Hủy góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN &&
              iscaNhanChuTriPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickThucHienGopY}
                  >
                    <i className="icon-edit icon-cpc"></i>
                    <span className="action-name">Góp ý lại</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              ((tinhTrang === TRANGTHAI.LDPB_CHUA_XAC_NHAN &&
                isLanhDaoPhongBanXacNhanPhieuNhan) ||
                (tinhTrang === TRANGTHAI.LDDV_CHUA_XAC_NHAN &&
                  isLanhDaoXacNhanPhieuNhan)) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickDuyetGopY}
                  >
                    <i className="icon-xac-nhan-lai icon-cpc"></i>
                    <span className="action-name">Xem và xác nhận góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              ((tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN &&
                isLanhDaoXacNhanPhieuNhan &&
                tinhTrangLanhDaoDonViXacNhanPhieuNhan ===
                  TRANGTHAI.LAYYKIEN_KHONG_DONGY) ||
                (tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN &&
                  isLanhDaoPhongBanXacNhanPhieuNhan &&
                  tinhTrangLanhDaoPhongBanXacNhanPhieuNhan ===
                    TRANGTHAI.LAYYKIEN_KHONG_DONGY)) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickDuyetGopY}
                  >
                    <i className="icon-xac-nhan-lai icon-cpc"></i>
                    <span className="action-name">Xác nhận lại</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienNhan' &&
              tinhTrang === TRANGTHAI.LDDV_CHUA_XAC_NHAN &&
              isLanhDaoPhongBanXacNhanPhieuNhan && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.handleClickPopupHuyXacNhan}
                  >
                    <i className="icon-Huy icon-cpc"></i>
                    <span className="action-name">Hủy xác nhận góp ý</span>
                  </button>
                </div>
              )}
            {this.props.type === 'layYKienGoi' &&
              (tinhTrang === TRANGTHAI.DANG_GOP_Y ||
                tinhTrang === TRANGTHAI.DA_HOAN_THANH) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickTongHopGopY}
                  >
                    <i className="icon-Van_Ban_Tra_Loi icon-cpc"></i>
                    <span className="action-name">Tổng hợp góp ý</span>
                  </button>
                </div>
              )}
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickLuongGopY}
              >
                <i className="icon-organization icon-cpc"></i>
                <span className="action-name">Luồng góp ý</span>
              </button>
            </div>

            {this.props.type === 'layYKienGoi' &&
              (tinhTrang === TRANGTHAI.DANG_GOP_Y ||
                tinhTrang === TRANGTHAI.DA_HOAN_THANH ||
                ((tinhTrang === TRANGTHAI.KHONG_DUYET ||
                  tinhTrang === TRANGTHAI.LDPB_CHUA_DUYET ||
                  tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET ||
                  tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET) &&
                  isNguoiLap) ||
                ((tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET ||
                  tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET) &&
                  isLanhDaoPhongBanXacNhanPhieuGoi) ||
                (tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET &&
                  isthuKyXacNhanPhieuGoi) ||
                (tinhTrang === TRANGTHAI.KHONG_DUYET &&
                  isthuKyXacNhanPhieuGoi &&
                  tinhTrangThuKyXacNhanPhieuGoi !==
                    TRANGTHAI.LAYYKIEN_KHONG_DONGY) ||
                (tinhTrang === TRANGTHAI.KHONG_DUYET &&
                  isLanhDaoPhongBanXacNhanPhieuGoi &&
                  tinhTrangLanhDaoPhongBanXacNhanPhieuGoi !==
                    TRANGTHAI.LAYYKIEN_KHONG_DONGY)) && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickThongTinXacNhan}
                  >
                    <i className="icon-xac-nhan-lai icon-cpc"></i>
                    <span className="action-name">Thông tin xác nhận</span>
                  </button>
                </div>
              )}
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickSendMail}
              >
                <i className="icon-Gmail icon-cpc"></i>
                <span className="action-name">Gửi email</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button className="click-item" onClick={this.props.sendToChat}>
                <i className="icon-speech-bubble icon-cpc"></i>
                <span className="action-name">Gửi Chat</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickDetailInfo}
              >
                <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                <span className="action-name">Thông tin góp ý</span>
              </button>
            </div>
          </div>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopupHuyXacNhan}
            title={popup_title}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitHuyXacNhan}
            isLoading={this.state.isLoadingHuyXacNhan}
          >
            {popup_content}
          </PopupConfirm>
          <PopupConfirm
            isOpen={this.state.isOpenPopupHoanThanh}
            onClose={this.handleClickPopupHoanThanhGopY}
            title={POPUP.HOAN_THANH_YEU_CAU_GOP_Y}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitHoanThanhGopY}
            isLoading={this.state.isLoadingHoanThanh}
          >
            {POPUP.COMFIRM_HOAN_THANH_CAU_GOP_Y}
          </PopupConfirm>
        </div>
      </div>
    )
  }
}

export default DetailBoxAction
