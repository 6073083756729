import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DigitizingItem from './DigitizingItem'
import NotFound from './NotFound'
import { Scrollbars } from 'react-custom-scrollbars'

class DigitizingList extends Component {
  constructor(props) {
    super(props)
    this.handleClickShowDetail = this.handleClickShowDetail.bind(this)
  }

  handleClickShowDetail = (id, fileName) => {
    this.props.onClickShowDetail && this.props.onClickShowDetail(id, fileName)
  }

  render() {
    const isGoBack = this.props.isGoBack
    return (
      <div className="ad-digitizing-container">
        <h3 className="heading">
          {isGoBack && (
            <span
              className="left-heading-goback"
              onClick={this.props.onClickHidden}
            >
              <span className="pt-icon pt-icon-arrow-left"></span>
            </span>
          )}
          <span>Văn bản số hoá</span>
          <span> ({this.props.list.length})</span>
        </h3>
        <div className="list-digitizing">
          {this.props.list.length > 0 && (
            <Scrollbars
              autoHideTimeout={100}
              autoHideDuration={300}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={this.props.sizeHeight}
            >
              {this.props.list.map((item, i) => (
                <DigitizingItem
                  key={i}
                  item={item}
                  onClickShowDetail={this.handleClickShowDetail}
                  onHandleSubmitRemoveItem={this.props.onHandleSubmitRemoveItem}
                />
              ))}
            </Scrollbars>
          )}

          {this.props.list.length === 0 && <NotFound />}
        </div>
      </div>
    )
  }
}

DigitizingList.defaultProps = {
  isGoBack: true,
}

DigitizingList.propTypes = {
  list: PropTypes.array.isRequired,
  onClickHidden: PropTypes.func,
  onClickShowDetail: PropTypes.func.isRequired,
}

export default DigitizingList
