import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio, Button, Intent } from '@blueprintjs/core'
import { LoadingItem, DataTable } from '../../components/common/'
import { Scrollbars } from 'react-custom-scrollbars'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import _ from 'lodash'
import * as MESSAGE from '../../constants/MessageForm'
import { decamelizeKeys } from 'humps'

const VNPT = 1
const VIETTEL = 0

const DONG_Y = '2'
const KHONG_DONG_Y = '1'
const KHONG_Y_KIEN = '3'

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)

    this.state = {
      yKien: '',
      isLoading: {
        isToKen: false,
        isVNPT: false,
        isVietTel: false,
      },
      trangThai: DONG_Y,
      hrefToken: null,
      chucDanhNguoiKyId: null,
    }
  }

  handleInputChange = e => {
    this.setState({ yKien: e.target.value })
  }

  onClickNeddSignNumber = value => () => {
    this.setState({ trangThai: value })
  }

  onSubmit = type => {
    const phieuLayYKienId = _.get(this.props, 'data.phieuLayYKienId')
    const data = {
      noiDungYKien: this.state.yKien,
      trangThai: this.state.trangThai,
    }

    const params = {
      loai: type,
    }

    this.props.actions
      .postKySim(phieuLayYKienId, decamelizeKeys(data), decamelizeKeys(params))
      .then(res => {
        if (type === VIETTEL) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isVietTel: false,
            },
          })
        } else {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isVNPT: false,
            },
          })
        }

        if (_.get(res, 'error') || !_.get(res, 'payload.data.result')) {
          const error =
            _.get(res, 'response.data.message') || MESSAGE.TOATS_MESSAGE_FAIL
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }

        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKySoBangSIM',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleClickAgreeBySIM = type => async e => {
    e.preventDefault()
    if (this.state.trangThai === KHONG_Y_KIEN && _.isEmpty(this.state.yKien)) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.NHAP_NOI_DUNG_Y_KIEN,
        intent: Intent.WARNING,
      })
    }
    if (type === VIETTEL) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          isVietTel: true,
        },
      })
    } else {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          isVNPT: true,
        },
      })
    }

    this.onSubmit(type)
  }

  handleCheckKySo = () => {
    const detail = _.get(this.props, 'data', {})
    let isKySo = true
    const newData = detail.dsThanhVien.find(
      item => item.chucDanhId === _.get(this.props, 'auth.roleId', '')
    )
    if (_.get(newData, 'trangThai') === 0) {
      isKySo = false
    }

    return isKySo
  }

  handleClickAgreeByTOKEN = async e => {
    e.preventDefault()
    if (this.state.trangThai === KHONG_Y_KIEN && _.isEmpty(this.state.yKien)) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.NHAP_NOI_DUNG_Y_KIEN,
        intent: Intent.WARNING,
      })
    }
    const chucDanhNguoiKyId = _.get(this.state, 'chucDanhNguoiKyId')
    const chucDanhId = _.get(this.props, 'auth.roleId')
    if (chucDanhNguoiKyId !== chucDanhId) {
      return
    }

    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        isToken: true,
      },
    })

    const phieuLayYKienId = _.get(this.props, 'data.phieuLayYKienId')
    const data = {
      noiDungYKien: _.trim(this.state.yKien),
      trangThai: this.state.trangThai,
    }
    const response = await this.props.actions.postYKienHDTV(
      phieuLayYKienId,
      decamelizeKeys(data)
    )
    if (!_.isEmpty(response.error)) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        intent: Intent.DANGER,
      })
      return this.setState({
        isLoading: {
          ...this.state.isLoading,
          isToken: false,
        },
      })
    }

    window.location.href = this.state.hrefToken

    if (this.props.onRemoveFileInfo) {
      this.props.onRemoveFileInfo()
      this.props.updateDetail && (await this.props.updateDetail())
    }
  }

  componentDidMount() {
    const employeeId = _.get(this.props, 'auth.roleId')
    const memberList = _.get(this.props, 'data.dsThanhVien')
    if (memberList.length) {
      const user = _.find(memberList, { chucDanhId: employeeId })
      const signStatus = _.get(user, 'trangThai')
      this.setState({
        hrefToken: `eofficecpc:id_vb=${_.get(
          this.props,
          'data.phieuLayYKienId'
        )};id_nv=${_.get(user, 'chucDanhId')};type=1;app=hdtv`,
        trangThai: !!signStatus ? signStatus.toString() : '2',
        chucDanhNguoiKyId: _.get(user, 'chucDanhId') || null,
      })
    }
  }

  render() {
    const detail = _.get(this.props, 'data', {})
    const token = _.get(this.props, 'auth.token.accessToken', '')
    return this.props.isLoadingData &&
      this.props.isLoadingData.signNumber === true ? (
      <LoadingItem />
    ) : (
      <div className="box-action-wrapper">
        <div className="box-action-block list-action list-action-sign-number">
          <p className="title">Thực Hiện</p>
          <span className="icon-More show-th-mobile" tabIndex="0"></span>
          {this.props.data && (
            <ul className="data-column">
              <li>
                <div className="data-column-items">
                  <label>Trích yếu:</label>
                  <div className="content">{detail.trichYeu}</div>
                </div>
              </li>

              <li>
                <div className="data-column-items">
                  <label>Nội dung trình HĐTV:</label>
                  <div className="content">{detail.noiDungTrinh}</div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>
                      File báo cáo của Ban TH ({detail.fileBaoCao ? 1 : 0}):
                    </label>
                  </div>
                  <div className="col-xs-8 right-content">
                    {detail.fileBaoCao && (
                      <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={200}
                      >
                        <span className="detail">
                          {[{ ...detail.fileBaoCao }].map((value, item) => {
                            let extensionTK = null
                            const dsFileBaoCao = value.fileName
                            if (dsFileBaoCao) {
                              extensionTK = dsFileBaoCao
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  {!value.migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extensionTK
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    >
                                      {value.tenFile} -{' '}
                                      {Tool.Utils.getFileSize(value.kichThuoc)}
                                    </a>
                                  )}
                                </span>
                              </div>
                            )
                          })}
                        </span>
                      </Scrollbars>
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>
                      File đính kèm
                      {detail.dsFileDinhKem.length > 0 &&
                        ` (${detail.dsFileDinhKem.length})`}
                      :
                    </label>
                  </div>
                  <div className="col-xs-8 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {detail.dsFileDinhKem.map((value, item) => {
                          let extensionTK = null
                          const dsFileDinhKem = value.fileName
                          if (dsFileDinhKem) {
                            extensionTK = dsFileDinhKem
                              .slice(
                                ((value.fileName.lastIndexOf('.') - 1) >>> 0) +
                                  2
                              )
                              .toLowerCase()
                          }
                          return (
                            <div className="info-file" key={item}>
                              <span className="info">
                                {!value.migrated && (
                                  <a
                                    style={{
                                      backgroundImage: `url(${Tool.Utils.typeFile(
                                        extensionTK
                                      )})`,
                                    }}
                                    className="link-file"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                  >
                                    {value.tenFile} -{' '}
                                    {Tool.Utils.getFileSize(value.kichThuoc)}
                                  </a>
                                )}
                              </span>
                            </div>
                          )
                        })}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>
                      File tham khảo
                      {detail.dsFileThamKhao.length > 0 &&
                        ` (${detail.dsFileThamKhao.length})`}
                      :
                    </label>
                  </div>
                  <div className="col-xs-8 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {detail.dsFileThamKhao.map((value, item) => {
                          let extensionTK = null
                          const dsFileThamKhao = value.fileName
                          if (dsFileThamKhao) {
                            extensionTK = dsFileThamKhao
                              .slice(
                                ((value.fileName.lastIndexOf('.') - 1) >>> 0) +
                                  2
                              )
                              .toLowerCase()
                          }
                          return (
                            <div className="info-file" key={item}>
                              <span className="info">
                                {!value.migrated && (
                                  <a
                                    style={{
                                      backgroundImage: `url(${Tool.Utils.typeFile(
                                        extensionTK
                                      )})`,
                                    }}
                                    className="link-file"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                  >
                                    {value.tenFile} -{' '}
                                    {Tool.Utils.getFileSize(value.kichThuoc)}
                                  </a>
                                )}
                              </span>
                            </div>
                          )
                        })}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              </li>
            </ul>
          )}
          <ul className="data-column">
            <li>
              <div className="data-column-items data-rows-items">
                <label>Ý kiến</label>
                <div>
                  <textarea
                    className="pt-input"
                    name="yKien"
                    value={this.state.yKien}
                    rows="4"
                    cols="100"
                    placeholder="Nhập ý kiến"
                    onChange={this.handleInputChange}
                    disabled={
                      Object.values(this.state.isLoading).find(
                        item => item === true
                      ) !== undefined || this.handleCheckKySo()
                    }
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="data-column-items data-rows-items">
                <label>Ký số</label>
                <div className="kyso-block row">
                  <div className="kyso-radio col-sm-5">
                    <div>
                      <Radio
                        className="pt-inline"
                        name="canKySo"
                        checked={this.state.trangThai === DONG_Y}
                        onChange={this.onClickNeddSignNumber(DONG_Y)}
                        disabled={
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined || this.handleCheckKySo()
                        }
                      >
                        <span className="name_checkbox blue">Đồng ý</span>
                      </Radio>
                    </div>
                    <div>
                      <Radio
                        className="pt-inline"
                        name="canKySo"
                        checked={this.state.trangThai === KHONG_DONG_Y}
                        onChange={this.onClickNeddSignNumber(KHONG_DONG_Y)}
                        disabled={
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined || this.handleCheckKySo()
                        }
                      >
                        <span className="name_checkbox red">Không đồng ý</span>
                      </Radio>
                    </div>
                    <div>
                      <Radio
                        className="pt-inline"
                        name="canKySo"
                        checked={this.state.trangThai === KHONG_Y_KIEN}
                        onChange={this.onClickNeddSignNumber(KHONG_Y_KIEN)}
                        disabled={
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined || this.handleCheckKySo()
                        }
                      >
                        <span className="name_checkbox default">
                          Ý kiến khác
                        </span>
                      </Radio>
                    </div>
                  </div>
                  <div className="kyso-button col-sm-7">
                    {_.get(this.props, 'auth.user.soDienThoaiKySo', false) && (
                      <div className="button-item mb10 width-165">
                        <Button
                          className="btn-default w-100"
                          onClick={this.handleClickAgreeBySIM(VIETTEL)}
                          loading={this.state.isLoading.isVietTel}
                          disabled={
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined || this.handleCheckKySo()
                          }
                        >
                          {_.get(
                            this.props,
                            'auth.user.soDienThoaiKySoVnpt',
                            false
                          ) ? (
                            <p className="w-100 "> Ký sim VIETTEL</p>
                          ) : (
                            <p className="w-100 ">Ký SIM</p>
                          )}
                        </Button>
                      </div>
                    )}

                    {_.get(
                      this.props,
                      'auth.user.soDienThoaiKySoVnpt',
                      false
                    ) && (
                      <div className="button-item mb10 width-165">
                        <Button
                          className="btn-default w-100"
                          onClick={this.handleClickAgreeBySIM(VNPT)}
                          loading={this.state.isLoading.isVNPT}
                          disabled={
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined || this.handleCheckKySo()
                          }
                        >
                          {_.get(
                            this.props,
                            'auth.user.soDienThoaiKySo',
                            false
                          ) ? (
                            <p className="w-100 "> Ký sim VNPT </p>
                          ) : (
                            <p className="w-100 ">Ký SIM</p>
                          )}
                        </Button>
                      </div>
                    )}
                    <div className="button-item mb10 width-165">
                      <Button
                        className="btn-default w-100"
                        onClick={this.handleClickAgreeByTOKEN}
                        loading={this.state.isLoading.isToKen}
                        disabled={
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined || this.handleCheckKySo()
                        }
                      >
                        <p className="w-100 ">Ký Token</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="box-action-block">
          <DataTable detail={detail} />
        </div>
      </div>
    )
  }
}

DetailBoxAction.propTypes = {
  auth: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  isLoadingData: PropTypes.object.isRequired,
  data: PropTypes.object,
}

DetailBoxAction.defaultProps = {
  data: {},
}

export default DetailBoxAction
