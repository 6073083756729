import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  listFeedbacks: {
    page: 1,
    total: 0,
    items: [],
  },
  listHistoryUpdate: {
    items: [],
  },
  checkProgression: null,
}

export default function signNumbers(state = initialState, action) {
  switch (action.type) {
    case types.API_REQUEST_SEND_SIGNNUMBER:
      return {
        ...state,
        list: initialState.list,
      }

    case types.SIGN_NUMBER_INTERNAL:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.SIGN_NUMBER_SEND:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.SIGN_NUMBER_RELEASED_SEND:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.SIGN_NUMBER_DETAIL:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.SIGN_NUMBER_INTERNAL_DETAIL:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.SIGN_NUMBER_DETAIL_FEEDBACK:
      return {
        ...state,
        listFeedbacks: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.SIGN_NUMBER_SEND_FETCH_HISTORY:
    case types.SIGN_NUMBER_INTERNAL_FETCH_HISTORY:
      return {
        ...state,
        listHistoryUpdate: {
          items: action.payload.data.result.items,
        },
      }

    case types.SIGN_NUMBER_CHECK_PROGRESSION:
      return {
        ...state,
        checkProgression: action.payload.data,
      }
    default:
      return state
  }
}
