import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExecutingViewBoxItem from './ExecutingViewBoxItem'
import ExecutingView from './ExecutingView'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import moment from 'moment'
import * as Types from '../../constants/Api'

class ExecutingViewBox extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpen: true,
    openSub: {},
  }

  componentDidMount() {
    if (
      this.props.item &&
      this.props.item.dsDonViTrucThuocResponse &&
      this.props.item.dsDonViTrucThuocResponse.length > 0
    ) {
      this.props.item.dsDonViTrucThuocResponse.forEach(item => {
        if (item.chuTri === true) {
          this.setState({
            openSub: {
              ...this.state.openSub,
              [item.congVanDenId]: !this.state.openSub[item.congVanDenId],
            },
          })
          return (
            this.props.onClickDepartment &&
            this.props.onClickDepartment(item.congVanDenId)
          )
        }
      })
    }
  }

  handleClick = () => {
    if (
      (this.props.item &&
        this.props.item.dsCongViecPhongBan &&
        this.props.item.dsCongViecPhongBan.length > 0) ||
      (this.props.item &&
        this.props.item.dsDonViTrucThuocResponse &&
        this.props.item.dsDonViTrucThuocResponse.length > 0)
    ) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleClickDepartment(item) {
    if (!item.chiDaoId && !item.phongBanTrinhId) {
      return
    }
    this.setState({
      openSub: {
        ...this.state.openSub,
        [item.congVanDenId]: !this.state.openSub[item.congVanDenId],
      },
    })
    if (!this.state.openSub[item.congVanDenId]) {
      return (
        this.props.onClickDepartment &&
        this.props.onClickDepartment(item.congVanDenId)
      )
    }
  }

  render() {
    const { item, onClickDepartment, auth } = this.props
    return (
      <div className="view-job-done">
        <div className="box-level-primary item-box">
          <div className="avatar-user">
            <div className="click-avatar" onClick={this.handleClick}>
              <span
                className={classnames(
                  'pt-icon click-dropdown drop-down-items',
                  {
                    'pt-icon-chevron-up':
                      ((item.dsCongViecPhongBan &&
                        item.dsCongViecPhongBan.length > 0) ||
                        (item.dsDonViTrucThuocResponse &&
                          item.dsDonViTrucThuocResponse.length > 0)) &&
                      this.state.isOpen,
                    'pt-icon-chevron-down':
                      ((item.dsCongViecPhongBan &&
                        item.dsCongViecPhongBan.length > 0) ||
                        (item.dsDonViTrucThuocResponse &&
                          item.dsDonViTrucThuocResponse.length > 0)) &&
                      !this.state.isOpen,
                  }
                )}
              ></span>
              <span
                className="avatar"
                style={{
                  backgroundImage: `url(${
                    item.nguoiChiDao && item.nguoiChiDao.anhDaiDien
                      ? Types.IMAGE_URL + item.nguoiChiDao.anhDaiDien
                      : '/images/default_avatar.png'
                  })`,
                }}
              ></span>
              <span className="name_user">
                {item.nguoiChiDao && item.nguoiChiDao.tenNhanVien}
              </span>
            </div>
          </div>
          <div className="info-job">
            {item.ngayChiDao && (
              <span className="info-job-item">
                <span className="title">Ngày:</span>
                <span className="content">
                  {item.ngayChiDao &&
                    ' ' + moment(item.ngayChiDao).format('DD/MM/YYYY HH:mm')}
                </span>
              </span>
            )}
            {item.noiDung && (
              <span className="info-job-item">
                <span className="title">&nbsp; - &nbsp; Nội dung chỉ đạo:</span>
                <span className="content">
                  {item.noiDung ? ' ' + item.noiDung : ''}
                </span>
              </span>
            )}
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className="scroll-ExecutingViewBox">
            {item.dsCongViecPhongBan &&
              item.dsCongViecPhongBan.map((item, i) => (
                <ExecutingViewBoxItem
                  item={item}
                  key={i}
                  auth={this.props.auth}
                  actions={this.props.actions}
                />
              ))}
            {item.dsPhongBanXemDeBiet &&
              item.dsPhongBanXemDeBiet.map((item, i) => (
                <div className="box-level-sub item-box" key={i}>
                  <div className="click-avatar drop-down-sub department-only-see">
                    <span className="pt-icon click-dropdown"></span>
                    <span className="name-department">
                      {item.maPhongBan ? item.maPhongBan : ''}
                    </span>
                    <span className="role">(Xem để biết)</span>
                  </div>
                </div>
              ))}
            {item.dsDonViTrucThuocResponse &&
              item.dsDonViTrucThuocResponse.map((item, i) => (
                <div className="box-level-sub item-box" key={i}>
                  <div
                    className="drop-down-sub"
                    onClick={this.handleClickDepartment.bind(this, item)}
                  >
                    <div className="click-avatar">
                      {item.phongBanTrinhId && (
                        <span
                          className={classnames('pt-icon click-dropdown', {
                            'pt-icon-chevron-up':
                              this.state.openSub[item.congVanDenId],
                            'pt-icon-chevron-down':
                              !this.state.openSub[item.congVanDenId],
                          })}
                        ></span>
                      )}
                      <span className="name-department">
                        {item.tenVietTat ? item.tenVietTat : ''}
                      </span>
                      {item.chuTri ? (
                        item.chuTri === true && (
                          <span className="role">(Chủ trì)</span>
                        )
                      ) : (
                        <span className="role">(Phối hợp)</span>
                      )}
                    </div>
                  </div>
                  {this.state.openSub[item.congVanDenId] &&
                    item.flowDocument &&
                    item.flowDocument.nguoiTaoCongVan && (
                      <div className="box-level-primary item-box">
                        <div className="avatar-user">
                          <div className="click-avatar">
                            <span
                              className="avatar"
                              style={{
                                backgroundImage: `url(${
                                  item.flowDocument.nguoiTaoCongVan &&
                                  item.flowDocument.nguoiTaoCongVan.anhDaiDien
                                    ? Types.IMAGE_URL +
                                      item.flowDocument.nguoiTaoCongVan
                                        .anhDaiDien
                                    : '/images/default_avatar.png'
                                })`,
                              }}
                            ></span>
                            <span className="name_user">
                              {item.flowDocument.nguoiTaoCongVan &&
                                item.flowDocument.nguoiTaoCongVan.tenNhanVien}
                            </span>
                          </div>
                        </div>
                        <div className="info-job">
                          {item.flowDocument.nguoiTaoCongVan.ngayTao && (
                            <span className="info-job-item">
                              <span className="title">Ngày:</span>
                              <span className="content">
                                {item.flowDocument.nguoiTaoCongVan.ngayTao &&
                                  ' ' +
                                    moment(
                                      item.flowDocument.nguoiTaoCongVan.ngayTao
                                    ).format('DD/MM/YYYY HH:mm')}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  {this.state.openSub[item.congVanDenId] &&
                    item.flowDocument &&
                    item.flowDocument.chuyenChiDao &&
                    item.flowDocument.chuyenChiDao.length > 0 &&
                    item.flowDocument.chuyenChiDao.map((item, i) => (
                      <div className="box-level-primary item-box" key={i}>
                        <div className="avatar-user">
                          <div className="click-avatar">
                            <span
                              className="avatar"
                              style={{
                                backgroundImage: `url(${
                                  item.nguoiChuyenChiDao &&
                                  item.nguoiChuyenChiDao.anhDaiDien
                                    ? Types.IMAGE_URL +
                                      item.nguoiChuyenChiDao.anhDaiDien
                                    : '/images/default_avatar.png'
                                })`,
                              }}
                            ></span>
                            <span className="name_user">
                              {item.nguoiChuyenChiDao &&
                                item.nguoiChuyenChiDao.tenNhanVien}
                            </span>
                          </div>
                        </div>
                        <div className="info-job">
                          {item.nguoiChuyenChiDao.ngayTao && (
                            <span className="info-job-item">
                              <span className="title">Ngày:</span>
                              <span className="content">
                                {item.nguoiChuyenChiDao.ngayTao &&
                                  ' ' +
                                    moment(
                                      item.nguoiChuyenChiDao.ngayTao
                                    ).format('DD/MM/YYYY HH:mm')}
                              </span>
                              <span className="title">
                                &nbsp; - &nbsp; Chuyển chỉ đạo:
                              </span>
                              <span className="content">
                                {item.phongBanChuyenDen &&
                                  item.phongBanChuyenDen.tenPhongBan}
                              </span>
                              <span className="title">
                                &nbsp; - &nbsp; Nội dung:
                              </span>
                              <span className="content">
                                {item.noiDung ? item.noiDung : ''}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  {this.state.openSub[item.congVanDenId] &&
                    item.executingViews &&
                    item.chiDaoId && (
                      <ExecutingView
                        hiddenName
                        auth={auth}
                        onClickDepartment={onClickDepartment}
                        executingViews={item.executingViews}
                        actions={this.props.actions}
                      />
                    )}
                  {this.state.openSub[item.congVanDenId] &&
                    !item.chiDaoId &&
                    item.phongBanTrinhId && (
                      <div className="box-level-sub item-box">
                        <div className="drop-down-sub">
                          <div className="click-avatar">
                            <span className="name-department">
                              {item.flowDocument &&
                              item.flowDocument.phongBanTrinh &&
                              item.flowDocument.phongBanTrinh.maPhongBan
                                ? item.flowDocument.phongBanTrinh.maPhongBan
                                : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ))}
            {item.dsDonViXemDeBiet &&
              item.dsDonViXemDeBiet.map((item, i) => (
                <div className="box-level-sub item-box" key={i}>
                  <div className="click-avatar drop-down-sub department-only-see">
                    <span className="pt-icon click-dropdown"></span>
                    <span className="name-department">
                      {item.tenVietTat ? item.tenVietTat : ''}
                    </span>
                    <span className="role">(Xem để biết)</span>
                  </div>
                </div>
              ))}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default ExecutingViewBox
