import React from 'react'
import { Checkbox } from '@blueprintjs/core'
import classnames from 'classnames'
import { LoadingTable } from '../../../components/common'
import { Collapse } from '@blueprintjs/core'

const PhongBanXDB = ({
  list = [],
  selected = [],
  toggleSelect,
  toggleSelectAll,
  loading,
  showContent,
  toggleShowContent,
}) => (
  <div className={classnames('form-container sign-step-new-style')}>
    <div className="form-row form-header">
      <div className="form-cols form-col-full">
        <div className="form-cols p0">
          <label className="pt-label">
            Phòng ban xem để biết
            <span
              className={classnames('pt-icon  collapse-btn', {
                'pt-icon-chevron-up': showContent,
                'pt-icon-chevron-down': !showContent,
              })}
              onClick={toggleShowContent}
              style={{ cursor: 'pointer', marginLeft: '5px' }}
            />
          </label>
        </div>
        {showContent && (
          <div className="form-cols p0 m0 select-all-checkbox">
            <Checkbox
              name="selectAll"
              checked={!!list.length && list.length === selected.length}
              onChange={toggleSelectAll}
            >
              Chọn tất cả
            </Checkbox>
          </div>
        )}
      </div>
    </div>
    <Collapse isOpen={showContent}>
      <div className="form-row form-body">
        <div className="form-cols form-col-full">
          <div className="pt-form-group">
            <div className="select-group-container">
              {!!loading ? (
                <LoadingTable style={{ width: '100%', padding: 0 }} />
              ) : (
                list.map(item => {
                  return (
                    <div
                      name="member"
                      className="form-col4 form-col-inline"
                      key={item.phongBanId}
                    >
                      <Checkbox
                        checked={selected.includes(item.phongBanId)}
                        value={item.phongBanId}
                        onChange={toggleSelect}
                      >
                        {item.maPhongBan}
                      </Checkbox>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  </div>
)

export default PhongBanXDB
