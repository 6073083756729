import {
  ContentHeader,
  EmptyDataTable,
  ErrorLoadingTable,
  LoadingTable,
} from '../../../components/common'
import React, { PureComponent } from 'react'

import GoBackButton from '../../../components/Button/GoBackButton'
import { MasterLayout } from '../../../components/layout'
import UsingGroupDetail from './UsingGroupDetail'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage, fileUpload } from '../../../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const PAGE_NAME = 'Quy Trình'
const PAGE_ICON = 'icon-danh-muc-quy-trinh'

class UsingGroupDetailPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isError: false,
      isEmptyData: false,
      listDeleteGroupId: [],
      isDialogOpen: false,
      currentGroupId: null,
      currentGroupList: [],
    }
  }

  toggleDialog = groupId => {
    const { groupList } = this.props
    const { isDialogOpen } = this.state
    this.setState({
      currentGroupId: !isDialogOpen ? groupId : null,
      currentMemberList: !isDialogOpen
        ? _.get(_.find(groupList, { nhomId: groupId }), 'dsThanhVien', [])
        : [],
      isDialogOpen: !isDialogOpen,
    })
  }

  fetchUnitGroup = async () => {
    const { id } = this.props.match.params
    const { fetchGroup } = this.props
    await this.setState({ isLoading: true })
    fetchGroup(id)
      .then(res => {
        const status = !!(_.get(res, 'payload.status') === 200)
        this.setState({
          isError: !status,
        })
      })
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      )
  }

  convertFile = file => {
    return {
      file_id: file.id,
      ten_file: file.originalName,
      url: file.path,
      kieu_file: file.extension,
      kich_thuoc: file.length,
      is_existed: true,
    }
  }

  componentDidMount() {
    this.fetchUnitGroup()
  }

  render() {
    const { groupList, title } = this.props
    const { isLoading, isError, isDataEmpty } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={`${PAGE_NAME} ${!isLoading ? title : ''}`}
          showGoBackButton
        />
        <div className="group-page-container">
          <div className="container-title">NHÓM THÀNH VIÊN</div>
          <div className="container-body">
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              !!groupList.length &&
              groupList.map((group, index) => (
                <UsingGroupDetail
                  key={index}
                  group={group}
                  index={index}
                  openMemberDialog={this.toggleDialog}
                  token={_.get(this.props, 'auth.token.accessToken', '')}
                />
              ))
            )}
          </div>
          <div className="container-footer">
            <GoBackButton />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  groupList: state.procedureAuthority.groupList,
  title: state.procedureAuthority.title,
  auth: state.auth,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  uploadFile: bindActionCreators(fileUpload, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withRouter(UsingGroupDetailPage))
