import React, { memo, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import { DateAdvanced } from 'components/newCommon2'

const Examples = () => {
  const [dataForm, setDataForm] = useState({
    date: moment(),
  })

  const changeField = useCallback(
    type => value => {
      setDataForm(prev => ({
        ...prev,
        [type]: value,
      }))
    },
    []
  )

  return (
    <div className="container">
      <div className="row">
        <div className="col-3">
          <DateAdvanced
            inputClearable
            inputIconClassName="icon2-date-frames"
            inputWrapperClassName="mb10"
            placeholder="Component mới"
            popoverStretch={false}
            selected={get(dataForm, 'date')}
            inputWarning={false}
            onChange={value => {
              changeField('date')(value)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(null)(memo(Examples)))
