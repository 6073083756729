import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const UIButton = ({
  className,
  type,
  name,
  text,
  icon,
  theme,
  color,
  isReverse,
  isLoading,
  isDisabled,
  onClick,
}) => {
  return (
    <button
      type={type}
      name={name}
      className={classNames('ui-button', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
        [`color-${color}`]: color,
        reverse: isReverse,
      })}
      disabled={isDisabled}
      onClick={onClick}
    >
      {text ? <span className="name">{text}</span> : ''}
      {!isLoading && icon ? <span className={classNames('icon', icon)} /> : ''}
      {isLoading ? <span className="ui-button-loading" /> : ''}
    </button>
  )
}

UIButton.defaultProps = {
  className: '',
  type: 'button',
  name: '',
  text: '',
  icon: '',
  theme: 'default',
  color: '',
  isReverse: false,
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
}

UIButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  theme: PropTypes.string,
  color: PropTypes.string,
  isReverse: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default UIButton
