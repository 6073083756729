import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { get, size } from 'lodash'

const ThongKeTiLeCongViec = ({ dataDoughnut, colors }) => {
  if (!dataDoughnut) {
    return <></>
  }

  const checkData = get(dataDoughnut, 'datasets[0].data', []).reduce(
    (a, b) => a + b,
    0
  )
  let newData = dataDoughnut
  let isNotData = false
  if (checkData === 0) {
    // Nếu không có dữ liệu thì set giá trị biểu đồ thành 100% để hiển thị nền màu xám
    isNotData = true
    newData = {
      ...newData,
      datasets: [
        {
          ...newData.datasets[0],
          backgroundColor: [colors.grey, colors.grey],
          data: [100, 0],
        },
      ],
      labels: [],
    }
  }

  return (
    <div className="chart-doughnut-wrapper">
      <div className="chart-doughnut-block">
        <div className="chart-doughnut-round">
          <Doughnut
            data={newData}
            height={300}
            options={{
              responsive: true,
              tooltips: {
                enabled: !isNotData,
                position: 'nearest',
                callbacks: {
                  title: function (tooltipItem, data) {
                    return data['labels'][tooltipItem[0]['index']]
                  },
                  label: function (tooltipItem, data) {
                    return (
                      data['datasets'][0]['data'][tooltipItem['index']] +
                      ' Công việc'
                    )
                  },
                },
                backgroundColor: colors.white,
                titleFontColor: colors.black,
                bodyFontColor: colors.black,
                borderColor: colors.black,
                borderWidth: 0,
                caretSize: 5,
              },
              legend: {
                display: false,
              },
              cutoutPercentage: 80,
              rotation: -0.5 * Math.PI - (0 / 180) * Math.PI,
              pieceLabel: {
                render: 'label',
                arc: true,
                fontColor: '#000',
                position: 'outside',
              },
            }}
          />
          <div className="chart-doughnut-inner">
            <p style={{ color: isNotData ? colors.grey : '#09B424' }}>
              {get(dataDoughnut, 'datasets[0].data', []).reduce(
                (a, b) => a + b,
                0
              )}
            </p>
            <span>Công việc</span>
          </div>
        </div>
        <div className="chart-doughnut-percent">
          {get(dataDoughnut, 'datasets[0].data', []).map((elm, index) => {
            if (elm === 0) {
              return true
            }

            // Tính tổng các giá trị
            const total = get(dataDoughnut, 'datasets[0].data', []).reduce(
              (a, b) => a + b,
              0
            )
            const giaTriConLai = total - elm
            let phanTram = Math.round((elm / total) * 100)
            const phanTramGiaTriConLai = Math.round(
              (giaTriConLai / total) * 100
            )
            const tongPhanTram = phanTram + phanTramGiaTriConLai
            let phanTramDu = 0
            let phanTramThieu = 0
            if (tongPhanTram > 100) {
              phanTramDu = tongPhanTram - 100
            } else if (tongPhanTram < 100) {
              phanTramThieu = 100 - tongPhanTram
            }

            if (index === 1 && phanTramDu !== 0) {
              phanTram = phanTram - phanTramDu
            } else if (index === 1 && phanTramThieu !== 0) {
              phanTram = phanTram + phanTramThieu
            }

            let locationDefault = {
              top: 'auto',
              bottom: 'auto',
              left: 'auto',
              right: 'auto',
            }
            let location = null
            if (index === 0) {
              location = {
                ...locationDefault,
                top: 0,
                right: '-10px',
              }
            } else if (index === 1) {
              location = {
                ...locationDefault,
                bottom: 0,
                left: '-10px',
              }
            }

            return (
              <span
                key={index}
                style={{
                  color: get(
                    dataDoughnut,
                    `datasets[0].backgroundColor[${index}]`
                  ),
                  ...location,
                }}
              >
                {elm > 0 ? phanTram + '%' : '0%'}
              </span>
            )
          })}
        </div>
      </div>
      {size(get(newData, 'labels')) > 0 && (
        <ul className="chart-legends mt15">
          {get(newData, 'labels', []).map((elm, index) => (
            <li key={index}>
              <span
                style={{
                  backgroundColor: get(
                    newData,
                    `datasets[0].backgroundColor[${index}]`
                  ),
                }}
              />
              {elm}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default ThongKeTiLeCongViec
