import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { size, get } from 'lodash'
import { Intent } from '@blueprintjs/core'
import { Utils } from '../../helpers'
import { acceptMIMETypes } from '../../constants/Api'
import {
  ValidateForm,
  TEXT_MESSAGE_UPLOAD_FAIL,
} from '../../constants/MessageForm'
import allowFileTypes from '../../constants/fileTypes'
import { fileType } from '../../helpers/validation'

let fileId = 0
class ChinhSuaTaiLieu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        id: props.data.id,
        ghiChu: props.data.ghiChu,
        tenTaiLieu: props.data.tenTaiLieu || '',
        dsFileDinhKem: [],
        fileDinhKem: [
          {
            fileId: fileId++,
            fileOld: false,
          },
        ],
        fileDinhKemBenNgoai: [
          {
            fileId: fileId++,
            fileOld: false,
          },
        ],
      },
      validate: {},
      submitLoading: false,
      uploadProgress: {
        dinhKem: 0,
        dinhKemBenNgoai: 0,
      },
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { data } = this.props
    if (data) {
      let fileDinhKem = []
      let fileDinhKemBenNgoai = []
      data.fileDinhKem &&
        data.fileDinhKem.length !== 0 &&
        data.fileDinhKem.map((elm, index) => {
          return fileDinhKem.push({
            fileId: index,
            data: {
              name: elm.tenFile,
              size: elm.kichThuoc,
            },
            fileInfo: Utils.convertFileOld(elm),
            fileOld: true,
            type: 'eoffice',
          })
        })
      data.fileDinhKemBenNgoai &&
        data.fileDinhKemBenNgoai.length !== 0 &&
        data.fileDinhKemBenNgoai.map((elm, index) => {
          return fileDinhKemBenNgoai.push({
            fileId: index,
            data: {
              name: elm.tenFile,
              size: elm.kichThuoc,
            },
            fileInfo: Utils.convertFileOld(elm),
            fileOld: true,
          })
        })

      this.setState({
        form: {
          ...this.state.form,
          fileDinhKem: fileDinhKem,
          fileDinhKemBenNgoai: fileDinhKemBenNgoai,
        },
      })
    }
  }

  showValidateMessage = (message, danger = false, success = false) => {
    const { actions } = this.props
    return actions.commonAddToasterMessage({
      message,
      intent: success
        ? Intent.SUCCESS
        : danger
        ? Intent.DANGER
        : Intent.WARNING,
    })
  }

  addInputFile = name => () => {
    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: [
          ...prev.form[name],
          {
            fileId: fileId++,
          },
        ],
      },
    }))
  }

  // removeInputFile = (name, id) => (e) => {
  //   e.preventDefault();
  //   const { form } = this.state;
  //   let files = form[name].filter((file) => file.fileId !== id);
  //   if (files && files.length === 0) {
  //     return this.setState({
  //       form: {
  //         ...this.state.form,
  //         fileDinhKem: [
  //           {
  //             fileId: 0,
  //             fileOld: false
  //           },
  //         ],
  //       }
  //     });
  //   }

  //   this.setState((prev) => ({
  //     form: {
  //       ...prev.form,
  //       [name]: prev.form[name].filter((file) => file.fileId !== id),
  //     },
  //   }));
  // };

  removeInputFileBenNgoai = (name, id) => e => {
    e.preventDefault()
    const { form } = this.state
    let files = form[name].filter(file => file.fileId !== id)
    if (files && files.length === 0) {
      return this.setState({
        form: {
          ...this.state.form,
          fileDinhKemBenNgoai: [
            {
              fileId: 0,
              fileOld: false,
            },
          ],
        },
      })
    }

    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: prev.form[name].filter(file => file.fileId !== id),
      },
    }))
  }

  // change files cũ
  // changeFile = (name, id) => (e) => {
  //   const reader = new FileReader();
  //   const { files } = e.target;
  //   const targetFile = files[0];
  //   let validateSuccess = true;
  //   reader.onloadend = () => {
  //     if (targetFile) {
  //       const extension = Utils.getExtensionFile(targetFile.name);
  //       if (
  //         (name === 'fileDinhKem' && fileType(extension, allowFileTypes)) ||
  //         (name === 'fileNoiDung' &&
  //           fileType(extension, ['pdf', 'doc', 'docx']))
  //       ) {
  //         validateSuccess = false;
  //         return this.setState((prev) => ({
  //           validate: {
  //             ...prev.validate,
  //             [name]: {
  //               ...prev.validate[name],
  //               [id]: true,
  //             },
  //           },
  //         }));
  //       }

  //       this.setState((prev) => ({
  //         form: {
  //           ...prev.form,
  //           [name]: prev.form[name].map((file) =>
  //             file.fileId === id
  //               ? {
  //                   ...file,
  //                   data: targetFile,
  //                 }
  //               : file
  //           ),
  //         },
  //         validate: {
  //           ...prev.validate,
  //           [name]: {
  //             ...prev.validate[name],
  //             [id]: false,
  //           },
  //         },
  //       }));
  //     }
  //   };
  //   if (targetFile && validateSuccess) {
  //     reader.readAsDataURL(targetFile);
  //   }
  // };

  changeFile = (name, id) => e => {
    const reader = new FileReader()
    const { files } = e.target
    const targetFile = files[0]
    let validateSuccess = true
    reader.onloadend = () => {
      if (targetFile) {
        const extension = Utils.getExtensionFile(targetFile.name)
        if (
          (name === 'fileDinhKemBenNgoai' &&
            fileType(extension, allowFileTypes)) ||
          (name === 'fileNoiDung' &&
            fileType(extension, ['pdf', 'doc', 'docx']))
        ) {
          validateSuccess = false
          return this.setState(prev => ({
            validate: {
              ...prev.validate,
              [name]: {
                ...prev.validate[name],
                [id]: true,
              },
            },
          }))
        }

        this.setState(prev => ({
          form: {
            ...prev.form,
            [name]: prev.form[name].map(file =>
              file.fileId === id
                ? {
                    ...file,
                    data: targetFile,
                  }
                : file
            ),
          },
          validate: {
            ...prev.validate,
            [name]: {
              ...prev.validate[name],
              [id]: false,
            },
          },
        }))
      }
    }
    if (targetFile && validateSuccess) {
      reader.readAsDataURL(targetFile)
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  changeUploadProgress = name => progress => {
    this.setState(prev => ({
      uploadProgress: {
        ...prev.uploadProgress,
        [name]: ~~((progress.loaded * 100) / progress.total),
      },
    }))
  }

  // Upload files cũ
  // uploadFile = async () => {
  //   const { actions } = this.props;
  //   const { fileDinhKem } = this.state.form;
  //   let responseDK = {};
  //   if (size(fileDinhKem.filter((f) => f.data && !f.fileOld))) {
  //     responseDK = await actions.fileUpload(
  //       fileDinhKem.filter((f) => f.data && !f.fileOld).map((f) => f.data),
  //       this.changeUploadProgress('dinhKem')
  //     );
  //   }

  //   if (responseDK.error) {
  //     return this.showValidateMessage(TEXT_MESSAGE_UPLOAD_FAIL, true);
  //   }

  //   return [...get(responseDK, 'payload.data.result', [])].map((file) =>
  //     Utils.convertFileResponse(file)
  //   );
  // };

  uploadFile = async () => {
    const { actions } = this.props
    const { fileDinhKemBenNgoai } = this.state.form
    let responseDK = {}
    if (size(fileDinhKemBenNgoai.filter(f => f.data && !f.fileOld))) {
      responseDK = await actions.fileUpload(
        fileDinhKemBenNgoai.filter(f => f.data && !f.fileOld).map(f => f.data),
        this.changeUploadProgress('dinhKemBenNgoai')
      )
    }

    if (responseDK.error) {
      return this.showValidateMessage(TEXT_MESSAGE_UPLOAD_FAIL, true)
    }

    return [...get(responseDK, 'payload.data.result', [])].map(file =>
      Utils.convertFileResponse(file)
    )
  }

  // Function cũ
  // async handleSubmit() {
  //   this.setState({
  //     submitLoading: true,
  //   });
  //   const { form } = this.state;
  //   let fileDinhKemOld = form.fileDinhKem
  //     .filter((f) => f.fileInfo && f.fileOld === true)
  //     .map((f) => f.fileInfo);
  //   const files = await this.uploadFile();
  //   let fileRequest = [...fileDinhKemOld, ...files];
  //   this.setState(
  //     {
  //       form: {
  //         ...this.state.form,
  //         dsFileDinhKem: fileRequest,
  //       },
  //       submitLoading: false,
  //     },
  //     () => {
  //       this.props.onSubmit && this.props.onSubmit(this.state.form);
  //     }
  //   );
  // }

  async handleSubmit() {
    this.setState({
      submitLoading: true,
    })
    const { form } = this.state
    let fileDinhKemBenNgoaiOld = form.fileDinhKemBenNgoai
      .filter(f => !!f.fileInfo)
      .map(f => f.fileInfo)
    const files = await this.uploadFile()
    let fileRequest = [...fileDinhKemBenNgoaiOld, ...files]
    this.setState(
      {
        form: {
          ...this.state.form,
          dsFileDinhKem: fileRequest,
        },
        submitLoading: false,
      },
      () => {
        const params = {
          id: get(this.state.form, 'id'),
          tenTaiLieu: get(this.state.form, 'tenTaiLieu'),
          ghiChu: get(this.state.form, 'ghiChu'),
          dsFileDinhKem: get(this.state.form, 'dsFileDinhKem', []),
        }
        this.props.onSubmit && this.props.onSubmit(params)
      }
    )
  }

  render() {
    const { validate, uploadProgress, submitLoading, form } = this.state
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Sửa tài liệu</h4>
        </div>
        <div className="form-container form-EditDocumentsCabinet">
          <div className="pt-form-group">
            <div className="item-form">
              <label className="pt-label">Tên tài liệu</label>
              <div className="pt-form-content">
                <div className="pt-form-content">
                  <textarea
                    rows="1"
                    className="pt-input pt-fill text-abridgment"
                    name="tenTaiLieu"
                    dir="auto"
                    value={get(this.state.form, 'tenTaiLieu', '')}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group">
            <div className="item-form">
              <label className="pt-label">Ghi chú</label>
              <div className="pt-form-content">
                <div className="pt-form-content">
                  <textarea
                    rows="1"
                    className="pt-input pt-fill"
                    name="ghiChu"
                    dir="auto"
                    value={get(this.state.form, 'ghiChu', '')}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pt-form-group">
            <div className="item-form">
              <label className="pt-label">
                File đính kèm
              </label>
              <div className="pt-form-content">
                {form.fileDinhKem.map((file, i) => (
                  <div key={i} className="mb5">
                    <label className="pt-file-upload pt-input">
                      <input
                        type="file"
                        name="fileDinhKem"
                        onChange={this.changeFile('fileDinhKem', file.fileId)}
                        accept={acceptMIMETypes}
                      />
                      <span className="pt-file-upload-input">
                        {file.data ? file.data.name : 'Chọn file đính kèm'}
                      </span>
                      {size(form.fileDinhKem) > 0 && file.data && (
                        <span
                          className="icon-bc icon-close icon-remove-file icon-remove-file-input"
                          onClick={this.removeInputFile(
                            'fileDinhKem',
                            file.fileId
                          )}
                        />
                      )}
                    </label>
                    {file.data && (
                      <span>
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <strong>{Utils.getFileSize(file.data.size)}</strong>
                        </p>
                      </span>
                    )}
                    {get(validate, `fileDinhKem[${file.fileId}]`) && (
                      <div className="pt-form-helper-text">
                        {ValidateForm.FILE_TYPE(allowFileTypes)}
                      </div>
                    )}
                  </div>
                ))}
                {submitLoading && uploadProgress.dinhKem > 0 && (
                  <div className="progress-upload-content">
                    <span className="percent-content">
                      {uploadProgress.dinhKem}%
                    </span>
                    <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                      <div
                        className="pt-progress-meter"
                        style={{
                          width: uploadProgress.dinhKem + '%',
                        }}
                      />
                    </div>
                  </div>
                )}
                <button
                  onClick={this.addInputFile('fileDinhKem')}
                  className="pt-button ttlpb__more-button"
                >
                  <span className="text-content">
                    <strong>Thêm file</strong>
                  </span>
                  <span className="pt-icon icon-Plus mr0"></span>
                </button>
              </div>
            </div>
          </div> */}

          {/* File đính kèm từ bên ngoài */}
          <div className="pt-form-group">
            <div className="item-form">
              <label className="pt-label">File đính kèm từ bên ngoài</label>
              <div className="pt-form-content">
                {form.fileDinhKemBenNgoai &&
                  form.fileDinhKemBenNgoai.map((file, i) => (
                    <div key={i} className="mb5">
                      <label className="pt-file-upload pt-input">
                        <input
                          type="file"
                          name="fileDinhKemBenNgoai"
                          onChange={this.changeFile(
                            'fileDinhKemBenNgoai',
                            file.fileId
                          )}
                          accept={acceptMIMETypes}
                        />
                        <span className="pt-file-upload-input">
                          {file.data ? file.data.name : 'Chọn file đính kèm'}
                        </span>
                        {size(form.fileDinhKemBenNgoai) > 0 && file.data && (
                          <span
                            className="icon-bc icon-close icon-remove-file icon-remove-file-input"
                            onClick={this.removeInputFileBenNgoai(
                              'fileDinhKemBenNgoai',
                              file.fileId
                            )}
                          />
                        )}
                      </label>
                      {file.data && (
                        <span>
                          <p className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file đã upload:
                            <strong>{Utils.getFileSize(file.data.size)}</strong>
                          </p>
                        </span>
                      )}
                      {get(validate, `fileDinhKemBenNgoai[${file.fileId}]`) && (
                        <div className="pt-form-helper-text">
                          {ValidateForm.FILE_TYPE(allowFileTypes)}
                        </div>
                      )}
                    </div>
                  ))}
                {submitLoading && uploadProgress.dinhKemBenNgoai > 0 && (
                  <div className="progress-upload-content">
                    <span className="percent-content">
                      {uploadProgress.dinhKemBenNgoai}%
                    </span>
                    <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                      <div
                        className="pt-progress-meter"
                        style={{
                          width: uploadProgress.dinhKemBenNgoai + '%',
                        }}
                      />
                    </div>
                  </div>
                )}
                <button
                  onClick={this.addInputFile('fileDinhKemBenNgoai')}
                  className="pt-button ttlpb__more-button"
                >
                  <span className="text-content">
                    <strong>Thêm file</strong>
                  </span>
                  <span className="pt-icon icon-Plus mr0"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </button>
          <button onClick={this.handleSubmit} className="pt-button btn-save">
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </button>
        </div>
      </div>
    )
  }
}

ChinhSuaTaiLieu.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ChinhSuaTaiLieu
