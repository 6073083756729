import { schema } from 'normalizr'

const DigitalDocuments = new schema.Entity(
  'digitalDocuments',
  {},
  {
    idAttribute: digitalDocuments => digitalDocuments.vanBanSoHoaId,
  }
)

export default DigitalDocuments
