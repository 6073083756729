import { AppliedUnit } from '../constants/ActionTypes'

const initialState = {
  list: [],
  currentItem: {},
}

const AppliedUnitReducer = (state = initialState, action) => {
  const result =
    !!action.payload && !!action.payload.data && action.payload.data.result

  switch (action.type) {
    case AppliedUnit.COLLECTION:
      return {
        ...state,
        list: result.items,
      }
    case AppliedUnit.CREATE:
      return {
        ...state,
      }
    case AppliedUnit.DELETE:
      const deleteId =
        !!action.meta && !!action.meta.previousAction
          ? action.meta.previousAction.donViId
          : null

      return {
        ...state,
        list: state.list.filter(item => item.donViId !== deleteId),
      }
    default:
      return state
  }
}

export default AppliedUnitReducer
