import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { checkPermissionMenu } from 'helpers/Helper'
import { Authorization } from 'components/auth'
import HeaderMenuItem from './header-v2-menu-item'

const HeaderMenu = ({ quyensMenu, dataSource, history, onMenuItemClick }) => {
  const [data, setData] = useState()
  const Permission = (pers = []) => {
    return Authorization.Component(pers)(HeaderMenuItem)
  }

  useEffect(() => {
    setData(
      dataSource?.filter(
        e =>
          !e?.permissionMenu ||
          (e?.permissionMenu &&
            checkPermissionMenu(quyensMenu, e.permissionMenu))
      )
    )
  }, [dataSource, quyensMenu])

  return (
    <ul className="header-v2-menu">
      {data?.map((item, index) => {
        const Auth = Permission(item.permission)
        return (
          <Auth
            key={index}
            history={history}
            onClick={onMenuItemClick}
            dataSource={item}
          />
        )
      })}
    </ul>
  )
}

HeaderMenu.defaultProps = {
  auth: null,
  dataSource: [],
  quyensMenu: [],
  onMenuItemClick: () => {},
}

HeaderMenu.propTypes = {
  auth: PropTypes.object,
  dataSource: PropTypes.array,
  quyensMenu: PropTypes.array,
  onMenuItemClick: PropTypes.func,
}

const mapStateToProps = state => ({
  auth: state.auth,
  quyensMenu: state.common.quyensMenu,
})

export default connect(mapStateToProps)(HeaderMenu)
