import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { Button, Intent } from '@blueprintjs/core'
import {
  ListFilter,
  // ListFooter,
  ListItem,
} from '../../components/Utilities/StatisticDocumentCabinet'
import { GridView } from '../../components/common'
import { connect } from 'react-redux'
import * as Actions from '../../actions'
// import * as Types from '../../constants/Api';
import { headers } from '../../constants/Table'
import FileDownload from 'file-saver'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../../components/auth'
import moment from 'moment'
import { get } from 'lodash'
import HeadingPanel from 'components/common/HeadingPanel'

class ListStatisticDocumentCabinetPage extends React.Component {
  constructor(props) {
    super(props)
    let isSuperAdmin = Authorization.vaild(this.props.auth.permission, [
      Permission.SUPERADMIN,
    ])
    const id =
      this.props.match && this.props.match.params && this.props.match.params.id
    const type = get(this.props, 'match.params.type')
    this.state = {
      filter: {},
      // sort: undefined,
      isEmpty: false,
      page: 1,
      isError: false,
      isLoading: false,
      isShowMessengStatistics: true,
      id,
      type,
      isSuperAdmin,
    }

    // this.clickPagination = this.clickPagination.bind(this);
    this.submitFilter = this.submitFilter.bind(this)
    // this.handleSort = this.handleSort.bind(this);
    this.handleExport = this.handleExport.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
  }

  // clickPagination(e) {
  //   this.submitFilter({}, e.selected + 1, this.state.sort);
  //   this.setState({page: e.selected + 1});
  // }

  async componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      'ListStatisticDocumentCabinet',
      null,
      'thong-ke-tu-tai-lieu'
    )

    if (preData) {
      state.filter = preData.filter
      await this.setState(state)
    }

    if (this.props.location && this.props.location.state) {
      let state = this.state
      const { isCheck } = this.props.location.state
      if (isCheck) {
        this.submitFilter(state.filter, state.page)
      }
    }

    let isSubmitStatisticCabinet = Tool.Utils.getData(
      'isSubmitStatisticCabinet'
    )
    if (isSubmitStatisticCabinet) {
      Tool.Utils.saveData('isSubmitStatisticCabinet', false)
      this.props.history.replace({
        state: {
          isCheck: true,
        },
      })
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match !== this.props.match
    ) {
      this.setState({
        id: get(nextProps, 'match.params.id'),
        type: get(nextProps, 'match.params.type'),
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  submitFilter(e = {}) {
    const filter = Object.assign({}, this.state.filter, e)
    const { type } = this.state
    const filterSubmit = {
      ...filter,
      don_vi_id: type === 'don-vi' ? this.state.id : null,
      phong_ban_id: type === 'phong-ban' ? this.state.id : null,
    }
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(
          'ListStatisticDocumentCabinet',
          { filter },
          'thong-ke-tu-tai-lieu'
        )
        this.props.actions
          .getStatisticCabinetDocument(
            filterSubmit,
            this.props.config.refreshStatistic
          )
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              res &&
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }

            if (
              res &&
              (res.error || (res.payload && res.payload.status !== 200))
            ) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(false)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  // handleSort(sort) {
  //   this.submitFilter(this.state.filter, this.state.page, sort);
  // }

  handleExport() {
    const { filter, page, id, type } = this.state
    if (id && type === 'phong-ban') {
      filter.phong_ban_id = id
    } else if (id && type === 'don-vi') {
      filter.don_vi_id = id
    }

    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportStatisticCabinetDocument(filter, page)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData('ListStatisticDocumentCabinet')
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-tu-tai-lieu-${moment(preData.filter.start_date).format(
              'L'
            )}-${moment(preData.filter.end_date).format('L')}.xls`
          )
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    })
  }

  handleClickGoBack() {
    this.props.history && this.props.history.goBack()
    Tool.Utils.saveData('isSubmitStatisticCabinet', true)
  }

  render() {
    // TODO: show title "Đơn vị/Phòng ban" thay cho check từng case
    // const isShowForUnit = this.state.isSuperAdmin && !this.state.id;
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            className="mb10"
            goBack={this.state.id ? this.handleClickGoBack : null}
            leftTitle="Thống kê Lưu HSCV"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={
                  this.state.isShowMessengStatistics ||
                  this.state.isLoading ||
                  this.state.isEmpty
                }
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              isLoading={this.state.isLoading}
              filter={this.state.filter}
              isForChildUnit={typeof this.state.id === 'string'}
            />

            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={this.state.page}
                // headers={isShowForUnit ? headers.statisticDocumentCabinet : headers.statisticDocumentCabinetDepartment}
                headers={headers.statisticDocumentCabinet}
                itemShape={ListItem}
                data={this.props.statistic.items}
                // onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                isShowMessengStatistics={this.state.isShowMessengStatistics}
                auth={this.props.auth}
                filter={this.state.filter}
                history={this.props.history}
              />
              {/* { !this.state.isLoading &&
                <ListFooter
                  pageCount={Math.ceil(this.props.totalStatistic.item / Types.PAGINATION_LIMIT)}
                  onPageClick={this.clickPagination}
                  forcePage={this.state.page - 1}
                />
              } */}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  statistic: {
    ...state.statisticsCabinetDocument,
    items: state.common.statisticsCabinetDocument,
  },

  totalStatistic: {
    ...state.totalStatisticCabinetDocument,
    item: state.common.totalStatisticCabinetDocument,
  },

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStatisticDocumentCabinetPage)
