import { API_NOTIFICATION_COUNTER } from 'constants/ActionTypes'

const initialState = {
  counter: 0,
}

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case API_NOTIFICATION_COUNTER:
      return {
        ...state,
        counter: action.payload?.data?.result ?? 0,
      }
    default:
      return state
  }
}
