import { get } from 'lodash'

import {
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
  API_REQUEST_ERROR,
  COMMON_ADD_TOASTER_MESSAGE,
  GET_THONGKE_QUANLY_CONGVIEC_TRANG_THAI,
  GET_NOTIFICATION_COUNTER,
  CONTACT_FETCH_UNITS_EQUAL,
  COMMON_ADD_TREE_PHONGBAN_DONVI_NHANVIEN,
  GET_LANHDAO_DONVI,
  GET_THONGTIN_DONVI,
  COMMON_ADD_TREE_DEPARTMENT_ORGANIZATION,
  GET_WORK_TYPES,
  GET_PHIEU_LAY_Y_KIEN,
} from '../constants/ActionTypes'
import {
  QLCV_API_URL,
  UPLOAD_API,
  API_URL,
  QLCV_NOTIFICATION,
} from '../constants/Api'
import { store } from '../index'
import { parseParams } from 'helpers/request'

const taskRequest = ({
  type: reduxSuccessType = API_REQUEST_SUCCESS,
  baseURL = QLCV_API_URL,
  client,
  endpoint,
  method = 'GET',
  params = {},
  data,
  ...otherOptions
}) => {
  const { dispatch, getState } = store
  const token = get(getState(), 'auth.token', {})

  return dispatch({
    types: [API_REQUEST_SEND, reduxSuccessType, API_REQUEST_ERROR],
    payload: {
      client,
      request: {
        ...otherOptions,
        baseURL,
        url: endpoint,
        method,
        headers: {
          Authorization: `${token.tokenType} ${token.accessToken}`,
        },
        params,
        data,
      },
    },
  })
}

// SHOW TOAST - HIỂN THỊ THÔNG BÁO
export const showToast = data => {
  const { dispatch } = store
  return dispatch({
    type: COMMON_ADD_TOASTER_MESSAGE,
    data,
  })
}

// UPLOAD FILE
export const postUpload = (files = []) => {
  const data = files.reduce(
    (result, file, index) => ({
      ...result,
      [`images-${index}`]: file,
    }),
    {}
  )

  return taskRequest({
    baseURL: UPLOAD_API,
    endpoint: '/api/files',
    client: 'upload',
    method: 'POST',
    data,
  })
}

// GET danh sách đơn vị ngang cấp
export const getCayDonViNgangCap = () => {
  const { getState } = store
  const { mainUnitId } = get(getState(), 'auth')
  return taskRequest({
    type: CONTACT_FETCH_UNITS_EQUAL,
    baseURL: API_URL,
    endpoint: `/api/donvi/${mainUnitId}/ngangcap`,
  })
}

// GET danh sách đơn vị/phòng ban
export const saveTree = data => {
  const { dispatch } = store
  return dispatch({
    type: COMMON_ADD_TREE_PHONGBAN_DONVI_NHANVIEN,
    data,
  })
}
export const saveTree2 = data => {
  const { dispatch } = store
  return dispatch({
    type: COMMON_ADD_TREE_DEPARTMENT_ORGANIZATION,
    data,
  })
}
export const getPhongBanDonVi = (mainUnitId, params) => {
  return taskRequest({
    endpoint: `api/remote/phongban-donvi/${mainUnitId}`,
    params,
  })
}
export const getPhongBan = params => {
  return taskRequest({
    endpoint: '/api/remote/phongban',
    params,
  })
}

// DANH SÁCH PHÒNG BAN DẠNG CÂY
export const getCayPhongBan = () => {
  const { getState } = store
  const { mainUnitId } = get(getState(), 'auth')

  return taskRequest({
    baseURL: API_URL,
    endpoint: 'api/phongban/multilevel',
    params: {
      donViId: mainUnitId,
    },
  })
}

// DANH SÁCH NHÂN VIÊN TRONG PHÒNG BAN
export const getDsNhanVien = id => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: `api/phongban/${id}/nhanvien`,
    params: {
      include_phong_ban_con: false,
      limit: 0,
    },
  })
}

// CÔNG VIỆC
export const postCongViec = data => {
  return taskRequest({
    endpoint: '/api/works',
    method: 'POST',
    data,
  })
}

export const getDsCongViec = (params, endPoint) => {
  return taskRequest({
    endpoint: endPoint,
    params,
    paramsSerializer: parseParams,
  })
}

export const getDsCongViecKanban = params => {
  return taskRequest({
    endpoint: '/api/v2/works/kanban',
    params,
    paramsSerializer: parseParams,
  })
}

export const sortWorkItem = (id, data) => {
  return taskRequest({
    endpoint: `/api/works/${id}/sort`,
    method: 'PATCH',
    data: data,
  })
}

export const patchTienDoKanban = (id, data) => {
  return taskRequest({
    endpoint: `/api/works/${id}`,
    method: 'PATCH',
    data,
  })
}

export const getCongViec = id => {
  return taskRequest({
    endpoint: `/api/works/${id}`,
    method: 'GET',
  })
}

export const putCongViec = (id, data) => {
  return taskRequest({
    endpoint: `/api/works/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteCongViec = id => {
  return taskRequest({
    endpoint: `/api/works/${id}`,
    method: 'DELETE',
  })
}

// TAG
export const getDsTag = params => {
  return taskRequest({
    endpoint: '/api/works/tags',
    method: 'GET',
    params,
  })
}

export const getTag = id => {
  return taskRequest({
    endpoint: `/api/works/tags/${id}`,
    method: 'GET',
  })
}

export const postCreateTag = data => {
  return taskRequest({
    endpoint: '/api/works/tags',
    method: 'POST',
    data,
  })
}

export const putEditTag = data => {
  return taskRequest({
    endpoint: `/api/works/tags/${data.id}`,
    method: 'PUT',
    data,
  })
}

export const deleteTag = id => {
  return taskRequest({
    endpoint: `/api/works/tags/${id}`,
    method: 'DELETE',
  })
}

// COMMENT - BÌNH LUẬN
export const getDsBinhLuan = (taskId, params) => {
  return taskRequest({
    endpoint: `/api/works/${taskId}/comments`,
    method: 'GET',
    params,
  })
}

export const postBinhLuan = data => {
  return taskRequest({
    endpoint: '/api/works/comments',
    method: 'POST',
    data,
  })
}

export const putBinhLuan = (id, data) =>
  taskRequest({
    endpoint: `/api/works/comments/${id}`,
    method: 'PUT',
    data,
  })

export const deleteBinhLuan = id =>
  taskRequest({
    endpoint: `/api/works/comments/${id}`,
    method: 'DELETE',
  })

// API GIAO VIỆC
export const postGiaoViec = (id, data) => {
  return taskRequest({
    endpoint: `/api/works/${id}/assign`,
    method: 'POST',
    data,
  })
}

// WORK CHECKLIST - CÔNG VIỆC CON
export const postCongViecCon = data =>
  taskRequest({
    endpoint: '/api/works/checklist',
    method: 'POST',
    data,
  })

export const getDsCongViecCon = workId =>
  taskRequest({
    endpoint: `/api/works/${workId}/checklist`,
  })

export const putCongViecCon = ({ id, ...data }) =>
  taskRequest({
    endpoint: `/api/works/checklist/${id}`,
    method: 'PUT',
    data,
  })

export const deleteCongViecCon = id =>
  taskRequest({
    endpoint: `/api/works/checklist/${id}`,
    method: 'DELETE',
  })

// CẬP NHẬT TIẾN ĐỘ THỰC HIỆN
export const patchTienDo = (id, data) =>
  taskRequest({
    endpoint: `/api/works/${id}`,
    method: 'PATCH',
    data,
  })

// POST Tags công việc
export const postTagCongViec = (id, data) =>
  taskRequest({
    endpoint: `/api/works/${id}/user-tags`,
    method: 'POST',
    data,
  })

// GET danh sách báo cáo
export const getDsBaoCao = (id, params) => {
  return taskRequest({
    endpoint: `/api/works/${id}/reports`,
    method: 'GET',
    params,
  })
}

// POST báo cáo
export const postBaoCaoCongViec = (id, data) =>
  taskRequest({
    endpoint: `/api/works/${id}/report`,
    method: 'POST',
    data,
  })

// GET danh sách banner sliders
export const getDanhSachSlider = params => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: '/api/sliders',
    method: 'GET',
    params,
  })
}

// POST banner sliders
export const postSliders = data =>
  taskRequest({
    baseURL: API_URL,
    endpoint: '/api/sliders',
    method: 'POST',
    data,
  })

// GET chi tiết banner sliders
export const getChiTietSlider = id => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: `/api/sliders/${id}`,
    method: 'GET',
  })
}

// PUT cập nhật banner sliders
export const putSliders = data =>
  taskRequest({
    baseURL: API_URL,
    endpoint: `/api/sliders/${data.id}`,
    method: 'PUT',
    data,
  })

// DELETE banner sliders
export const deleteSliders = id =>
  taskRequest({
    baseURL: API_URL,
    endpoint: `/api/sliders/${id}`,
    method: 'DELETE',
  })

// NOTIFICATION - THÔNG BÁO
export const getDsThongBao = params =>
  taskRequest({
    baseURL: QLCV_NOTIFICATION,
    endpoint: '/api/notifications',
    params,
  })

// PUT cập nhật thông báo - đánh dấu đã xem
export const markAsRead = id =>
  taskRequest({
    baseURL: QLCV_NOTIFICATION,
    endpoint: `/api/notifications/${id}`,
    method: 'PUT',
  })

// PUT đánh dấu xem tất cả thông báo chung
export const markAllRead = params =>
  taskRequest({
    baseURL: QLCV_NOTIFICATION,
    endpoint: `/api/notifications`,
    method: 'PUT',
    params,
  })

// GET thống kê trạng thái công việc
export const getThongKeTrangThaiCongViec = params => {
  return taskRequest({
    type: GET_THONGKE_QUANLY_CONGVIEC_TRANG_THAI,
    endpoint: '/api/works/counter/status',
    method: 'GET',
    params,
  })
}

// LƯU MẪU - WORK TEMPLATE
export const getDsMau = params => {
  return taskRequest({
    endpoint: '/api/works/templates',
    method: 'GET',
    params,
  })
}

export const postMau = data => {
  return taskRequest({
    endpoint: '/api/works/templates',
    method: 'POST',
    data,
  })
}

export const deleteMau = id => {
  return taskRequest({
    endpoint: `/api/works/templates/${id}`,
    method: 'DELETE',
  })
}

// NHẮC VIỆC - WORK REMINDER
export const patchReminder = (id, data) => {
  return taskRequest({
    endpoint: `/api/works/${id}/reminder`,
    method: 'PUT',
    data,
  })
}

export const getNotificationCounter = () => {
  return taskRequest({
    baseURL: QLCV_NOTIFICATION,
    endpoint: `/api/notifications/counter`,
    type: GET_NOTIFICATION_COUNTER,
  })
}

export const getNotificationDetailCounter = () => {
  return taskRequest({
    baseURL: QLCV_NOTIFICATION,
    endpoint: `/api/notifications/counter-detail`,
  })
}

// Đọc tất cả công việc
export const postWorkReadAll = data => {
  return taskRequest({
    endpoint: '/api/works/read-all',
    method: 'POST',
    data,
  })
}

// NHÓM THƯỜNG GIAO VIỆC
export const getFavoriteGroups = params => {
  return taskRequest({
    endpoint: '/api/works/favorites/all',
    method: 'GET',
    params,
  })
}

// GET nhóm thường giao việc cá nhân
export const getMyFavoriteGroups = params => {
  return taskRequest({
    endpoint: '/api/works/favorites',
    method: 'GET',
    params,
  })
}

// GET chi tiết nhóm thường giao việc
export const getFavoriteGroup = id => {
  return taskRequest({
    endpoint: `/api/works/favorites/${id}`,
    method: 'GET',
  })
}

// POST nhóm thườn giao việc
export const postFavoriteGroup = data => {
  return taskRequest({
    endpoint: '/api/works/favorites',
    method: 'POST',
    data,
  })
}

// PUT cập nhật nhóm thường giao việc
export const putFavoriteGroup = ({ id, ...data }) => {
  return taskRequest({
    endpoint: `/api/works/favorites/${id}`,
    method: 'PUT',
    data,
  })
}

// DELETE xóa nhóm thường giao việc
export const deleteFavoriteGroup = id => {
  return taskRequest({
    endpoint: `/api/works/favorites/${id}`,
    method: 'DELETE',
  })
}

// GET Thống kê công việc
export const getWorkStatistics = params => {
  return taskRequest({
    endpoint: '/api/works/statistics',
    method: 'GET',
    params,
  })
}

// GET Thống kê công việc - xuất excel
export const getWorkStatisticsExportExcel = params => {
  return taskRequest({
    client: 'download',
    endpoint: '/api/works/statistics/export',
    method: 'GET',
    params,
  })
}

// GET danh sách công việc quá hạn
export const getWorksOverdue = params => {
  return taskRequest({
    endpoint: '/api/works/statistics-status',
    method: 'GET',
    params,
  })
}

// Thông báo kết luận cuộc họp
export const getMeetings = params => {
  return taskRequest({
    endpoint: '/api/meetings',
    method: 'GET',
    params,
    paramsSerializer: parseParams,
  })
}

export const getThongTinDonVi = params => {
  return taskRequest({
    type: GET_THONGTIN_DONVI,
    endpoint: `/api/remote/donvi`,
    method: 'GET',
    params,
  })
}

export const getLanhDaoChuTri = (id, params) => {
  return taskRequest({
    type: GET_LANHDAO_DONVI,
    endpoint: `/api/remote/lanhdaochutri/${id}`,
    method: 'GET',
    params,
  })
}

export const postMeeting = data => {
  return taskRequest({
    endpoint: '/api/meetings',
    method: 'POST',
    data,
  })
}

export const putMeeting = (id, data) => {
  return taskRequest({
    endpoint: `/api/meetings/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteMeeting = ticketId => {
  return taskRequest({
    endpoint: `/api/meetings/${ticketId}`,
    method: 'DELETE',
  })
}

export const getWorksMeeting = (id, params) => {
  return taskRequest({
    endpoint: `/api/meetings/works/${id}`,
    method: 'GET',
    params,
  })
}

export const postWorksMeeting = data => {
  return taskRequest({
    endpoint: '/api/meetings/works',
    method: 'POST',
    data,
  })
}

export const patchWorksMeeting = (id, data) => {
  return taskRequest({
    endpoint: `/api/meetings/works/${id}`,
    method: 'PATCH',
    data,
  })
}

export const getMeeting = id => {
  return taskRequest({
    endpoint: `/api/meetings/${id}`,
    method: 'GET',
  })
}

export const putApprovalStatusWork = (id, params) => {
  return taskRequest({
    endpoint: `/api/works/update-approval-status/${id}`,
    method: 'PUT',
    params,
  })
}

export const putCancelApprovalStatusWork = id => {
  return taskRequest({
    endpoint: `/api/works/cancel-approval/${id}`,
    method: 'PUT',
  })
}

export const getMeetingStatistics = params => {
  return taskRequest({
    endpoint: `/api/meetings/statistics`,
    method: 'GET',
    params,
    paramsSerializer: parseParams,
  })
}

export const getMeetingWorkStatistics = params => {
  return taskRequest({
    endpoint: `/api/meetings/works/statistics`,
    method: 'GET',
    params,
    paramsSerializer: parseParams,
  })
}

export const exportMeetingStatistics = params => {
  return taskRequest({
    client: 'download',
    endpoint: `/api/meetings/statistics/export`,
    method: 'GET',
    params,
    paramsSerializer: parseParams,
  })
}

export const getMeetingsExport = params => {
  return taskRequest({
    client: 'download',
    endpoint: '/api/meetings/export',
    method: 'GET',
    params,
  })
}

export const getThreadMeetings = id => {
  return taskRequest({
    endpoint: `/api/meetings/threadmeetings/${id}`,
    method: 'GET',
  })
}

export const getThreadWork = id => {
  return taskRequest({
    endpoint: `/api/works-doffice/workfolow/${id}`,
    method: 'GET',
  })
}

export const getInformationWorkItemAction = workItemId => {
  return taskRequest({
    endpoint: `/api/works/check-information-work-item/${workItemId}`,
    method: 'GET',
  })
}

export const getPhongBanNhanVien = params => {
  return taskRequest({
    endpoint: '/api/remote/phongbanvanhanvienphongban',
    method: 'GET',
    params,
  })
}

export const getNavigationNotification = params => {
  return taskRequest({
    endpoint: '/api/works/navigation-notification',
    method: 'GET',
    params,
  })
}

export const getDODowloadFile = params => {
  return taskRequest({
    client: 'download',
    endpoint: '/api/remote-file',
    params,
  })
}

export const getWorkTypes = () => {
  return taskRequest({
    endpoint: '/api/works/work-types',
    type: GET_WORK_TYPES,
    method: 'GET',
  })
}

export const postAccept = data => {
  return taskRequest({
    endpoint: '/api/works/accept-history',
    method: 'POST',
    data,
  })
}

export const getAccept = params => {
  return taskRequest({
    endpoint: '/api/works/accept-history',
    params,
  })
}

export const getDSPhieuLayYKien = params => {
  return taskRequest({
    baseURL: API_URL,
    type: GET_PHIEU_LAY_Y_KIEN,
    endpoint: '/api/phieulayykien',
    method: 'GET',
    params,
  })
}
