import React from 'react'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  state = {
    isOpenPopup: false,
    nhanVienPhongBanId: null,
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      nhanVienPhongBanId: id,
    })
  }

  handleSubmitRemoveItem() {
    if (this.state.nhanVienPhongBanId) {
      this.props.onSubmit && this.props.onSubmit(this.state.nhanVienPhongBanId)
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  render() {
    return (
      <div className="table-rows table-row-nolink">
        <div className="table-cols-content width-col-10">
          <span className="order-content">
            {this.props.i < 10 ? '0' + this.props.i : this.props.i}
          </span>
        </div>
        <div className="table-cols-content width-col-10">
          <span className="text-content">{this.props.maNhanVien}</span>
        </div>
        <div className="table-cols-content width-col-20">
          <span className="text-content">{this.props.name}</span>
        </div>
        <div className="table-cols-content width-col-20">
          <span className="text-content">{this.props.phongBan}</span>
        </div>
        <div className="table-cols-content width-col-10">
          <span className="text-content">{this.props.chucvu}</span>
        </div>
        <div className="table-cols-content width-col-10">
          <span className="text-content">{this.props.username}</span>
        </div>
        <div className="table-cols-content width-col-10">
          <span className="text-content">{this.props.quyen}</span>
        </div>
        <div className="table-cols-content width-col-10">
          <Popover
            popoverClassName="pt-popover-content-sizing pt-minimal"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                <Link
                  className="item"
                  to={`/he-thong/nguoi-dung/chinh-sua/${this.props.data.nhanVienId}`}
                >
                  <span className="pt-icon pt-icon-annotation"></span>
                  <span className="name_action">Chỉnh sửa</span>
                </Link>
                <span
                  className="item"
                  onClick={this.handleClickPopup.bind(
                    this,
                    this.props.data.chucDanhId
                  )}
                >
                  <span className="pt-icon pt-icon-trash"></span>
                  <span className="name_action">Xóa</span>
                </span>
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.XOA_NGUOI_DUNG}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitRemoveItem}
          >
            {POPUP.TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </div>
      </div>
    )
  }
}

export default ListItem
