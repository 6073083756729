import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const UIInput = ({
  type,
  name,
  label,
  placeholder,
  minLength,
  maxLength,
  theme,
  error,
  isDisabled,
  hasRequire,
  readOnly,
  value,
  onChange,
}) => {
  return (
    <div
      className={classNames('ui-input', {
        [`theme-${theme}`]: theme,
      })}
    >
      {label ? (
        <label
          className={classNames('ui-input-label', {
            required: hasRequire,
          })}
        >
          <span>{label}</span>
        </label>
      ) : (
        ''
      )}
      {type === 'textarea' ? (
        <textarea
          name={name}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          disabled={isDisabled}
          readOnly={readOnly}
          onChange={onChange}
        >
          {value}
        </textarea>
      ) : (
        <input
          name={name}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          value={value || ''}
          disabled={isDisabled}
          readOnly={readOnly}
          onChange={onChange}
        />
      )}
      {error ? <p className="ui-input-error">{error}</p> : ''}
    </div>
  )
}

UIInput.defaultProps = {
  type: 'text',
  placeholder: 'Nhập...',
  minLength: 255,
  maxLength: 500,
  name: '',
  label: '',
  theme: 'default',
  error: '',
  hasRequire: false,
  isLoading: false,
  readOnly: false,
  onChange: () => {},
}

UIInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.string,
  error: PropTypes.string,
  hasRequire: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
}

export default UIInput
