import React, { memo } from 'react'
import csx from 'classnames'

import Input from './Input'

const InlineInput = ({
  onCancel: inputCancel = () => {},
  showCancel = false,
  wrapperClassName = '',
  ...props
}) => {
  return (
    <div className={csx('cpc-input-inline', wrapperClassName)}>
      <Input
        {...props}
        showSendButton
        sendButtonClassName={csx('pl20 pr20', 'full-button')}
      />
      {showCancel && (
        <i
          className={csx('icon-Huy', 'size-icon-10', 'has-event', 'ml10')}
          onClick={inputCancel}
        />
      )}
    </div>
  )
}

export default memo(InlineInput)
