import React from 'react'
import classnames from 'classnames'
import RcPagination from 'rc-pagination'
import { Icon } from 'components/newCommon'

const Pagination = ({
  onChange = () => {},
  current = 1,
  total = 0,
  pageSize = 10,
  className,
  hideOnSinglePage = true,
}) => {
  return (
    <RcPagination
      className={classnames('cpc-pagination', className)}
      current={current}
      total={total}
      locale="vi_VN"
      defaultCurrent={1}
      defaultPageSize={pageSize}
      hideOnSinglePage={hideOnSinglePage}
      prevIcon={props => {
        return (
          <Icon
            classIcon="icon2 icon2-arrow-dart-left"
            disabled={props?.current <= 1}
          />
        )
      }}
      nextIcon={props => {
        const totalPage = props?.total / props?.defaultPageSize || 1
        const totalPageCeil = Number(Math.ceil(totalPage))
        return (
          <Icon
            classIcon="icon2 icon2-arrow-dart-right"
            disabled={props?.current >= totalPageCeil}
          />
        )
      }}
      jumpPrevIcon={<span className="jumpIcon"></span>}
      jumpNextIcon={<span className="jumpIcon"></span>}
      // jumpPrevIcon={<Icon classIcon="icon2 icon2-arrow-double-left" />}
      // jumpNextIcon={<Icon classIcon="icon2 icon2-arrow-double-right" />}
      onChange={onChange}
    />
  )
}

export default Pagination
