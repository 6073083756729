import React from 'react'
import { MasterLayout } from '../../../components/layout'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { headers } from '../../../constants/Table'
import { get, keys, isEmpty } from 'lodash'
import { getCategoryUnitMultiLevel, getDepartment } from '../../../actions'

import SearchDispatchListFilter from './SearchDispatchListFilter'
import SearchDispatchListItem from './SearchDispatchListItem'
import SearchDispatchGridViewFilter from './SearchDispatchGridViewFilter'

import Pagination from 'components/Pagination'

class SearchDispatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resetFilter: false,
      tmpFilter: {},
      loading: {
        tableData: false,
      },
      error: {
        tableData: false,
      },
      data: {
        table: [],
        page: {
          current: 1,
          total: 1,
        },
        dropdown: {
          unit: [],
          department: [],
          procedureCategory: [],
          procedure: [],
        },
      },
    }
  }

  textFilterChangeCallback = (filter, filterType) => {
    if (filterType === 'filter') {
      this.setFilter(filter)
    } else if (filterType === 'column') {
      this.setFilter({ filterColumn: filter })
    }
  }

  goToDetails = id => {
    this.props.history.push(`/ky-so-tap-trung/tim-kiem-van-ban/chi-tiet/${id}`)
  }

  pageChange = async page => {
    await this.setState(prev => ({ tmpFilter: { ...prev.tmpFilter, page } }))
  }

  setFilter = filter => {
    this.setState(prev => ({ tmpFilter: { ...prev.tmpFilter, ...filter } }))
  }

  preFilter = (filter = {}) => {
    const { text = '', select = {}, date = {} } = filter
    return {
      textSearch: !!text.trim() ? text : undefined,
      ngayLap: {
        from: date.from ? date.from.format('YYYY-MM-DD') : undefined,
        to: date.to ? date.to.format('YYYY-MM-DD') : undefined,
      },
      donVi: select.unit !== '-1' ? select.unit : undefined,
      phongBan: select.department !== '-1' ? select.department : undefined,
      danhMuc:
        select.procedureCategory !== '-1'
          ? select.procedureCategory
          : undefined,
      quyTrinh: select.procedure !== '-1' ? select.procedure : undefined,
    }
  }

  preFilterColumn = (filter = {}) => {
    const { text = {}, date = {} } = filter
    return {
      ...text,
      ngayLap: date.ngayLap ? date.ngayLap.format('YYYY-MM-DD') : undefined,
    }
  }

  preFilterSort = (filter = {}) => {
    return isEmpty(filter)
      ? undefined
      : `${keys(filter)[0]} ${filter[keys(filter)[0]]}`
  }

  setDropdownData = (name, data = []) => {
    this.setState(prev => ({
      data: {
        ...prev.data,
        dropdown: {
          ...prev.data.dropdown,
          [name]: data,
        },
      },
    }))
  }

  flatUnit = arrayUnit => {
    let tmpList = []
    arrayUnit.forEach(i => {
      tmpList = [...tmpList, i]
      if (i.dsDonViCon) {
        tmpList = [...tmpList, ...this.flatUnit(i.dsDonViCon)]
      }
    })
    return tmpList
  }

  setUnitDropdownData = () => {
    const unitId = get(this.props, 'auth.mainUnitId')
    this.props.fetchUnit(unitId).then(res => {
      let list = get(res, 'payload.data.result', [])
      list = this.flatUnit(list)
      this.setDropdownData('unit', list)
    })
  }

  setProcedureCategoryDropdownData = () => {}

  dropSelectCallback = (name, id) => {
    if (name === 'unit') {
      if (id === '-1') {
        this.setDropdownData('department', [])
      } else {
        this.props.fetchDepartment(id).then(res => {
          const data = get(res, 'payload.data.result')
          this.setDropdownData('department', data)
        })
      }
    }
  }

  resetFilter = async () => {
    await this.setState({ resetFilter: true })
    this.setState({ resetFilter: false })
  }

  async componentDidMount() {
    this.setUnitDropdownData()
    this.setProcedureCategoryDropdownData()
  }

  render() {
    const {
      data: { table, page, dropdown },
      loading,
      error,
      resetFilter,
    } = this.state

    return (
      <MasterLayout typeSidebar="signNumber">
        <div>
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-danh-sach-tap-trung icon-heading" />
                  <span className="text-heading">Danh sách ký duyệt</span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container list-sign-number signature-document-custom-list">
            <SearchDispatchListFilter
              submitFilter={this.fetchTableData}
              resetFilter={this.resetFilter}
              dropdownData={dropdown}
              dropSelectCallback={this.dropSelectCallback}
              textFilterChange={filter =>
                this.textFilterChangeCallback(filter, 'filter')
              }
            />

            <div
              className="table-container pb0"
              style={{
                border: 0,
                background: page.total > 1 ? '#fff' : '#eff3f5',
              }}
            >
              <SearchDispatchGridViewFilter
                data={table}
                resetFilter={resetFilter}
                classNames={'pt-table'}
                currentPage={page.current}
                header={headers.timKiemVanBanKySo}
                itemShape={SearchDispatchListItem}
                goToDetails={this.goToDetails}
                submitFilter={filter => this.fetchTableData(filter, 'column')}
                textFilterChange={filter =>
                  this.textFilterChangeCallback(filter, 'column')
                }
                loading={loading.tableData}
                error={error.tableData}
              />
              {!loading.tableData &&
                !error.tableData &&
                !!table.length &&
                page.total > 1 && (
                  <Pagination
                    current={page.current}
                    total={page.total}
                    onChange={this.pageChange}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  fetchUnit: bindActionCreators(getCategoryUnitMultiLevel, dispatch),
  fetchDepartment: bindActionCreators(getDepartment, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchDispatch)
