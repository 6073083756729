import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    page: 1,
    total: 0,
    items: [],
  },
}

export default function requestComments(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_COMMENTS:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.REQUEST_COMMENT_SUMMARY:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.REQUEST_COMMENT_DETAIL:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.FLOW_REQUEST_COMMENTS:
      return {
        ...state,
        flowRequestComment: action.payload.data.result,
      }
    case types.FLOW_RECEIVE_COMMENT:
      return {
        ...state,
        flowReceiveComment: {
          ...state.flowReceiveComment,
          [action.payload.data.result.id]: action.payload.data.result,
        },
      }
    default:
      return state
  }
}
