import React, { memo } from 'react'
import csx from 'classnames'

const Row = ({
  children,
  gutter = false,
  gutterVertical = false,
  className,
  onClick,
}) => {
  return (
    <div
      className={csx(
        'row',
        'cpc-row',
        {
          'cpc-row-gutter': gutter,
          'cpc-row-gutter-vertical': gutterVertical,
          'has-event': onClick,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default memo(Row)
