import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PopupConfirm } from '../common/'
import classnames from 'classnames'
import { Intent } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import { Authorization, Permission } from '../auth'
import _ from 'lodash'
// import { DONVI_LIENTHONG_STATUS } from '../../constants/RequestCommentStatus'

class RestoreArchive extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func,
    infoSendAdditional: PropTypes.object,
    sendDocumentId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleExecutingView = this.handleExecutingView.bind(this)
    this.resendEVN = this.resendEVN.bind(this)
  }

  state = {
    isOpenPopup: false,
    titlePopUp: '',
    congVanDenId: null,
    donViId: null,
    congVanDiId: this.props.sendDocumentId,
    isResendEVN: false,
    isLoading: false,
  }

  handleClickPopup = (type, id) => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })

    switch (type) {
      case 'THCV':
        this.setState({
          titlePopUp: POPUP.THU_HOI_CONG_VAN,
          donViId: id,
        })
        break

      case 'GL':
        this.setState({
          titlePopUp: POPUP.GUI_LAI,
          donViId: id,
        })
        break

      case 'GL_EVN':
        this.setState({
          titlePopUp: POPUP.GUI_LAI,
          isResendEVN: true,
        })
        break

      default:
    }
  }

  handleExecutingView(id) {
    this.props.onClickExecutingViewReceiveDocument &&
      this.props.onClickExecutingViewReceiveDocument(id)
  }

  handleExecutingViewEvn(id) {
    this.props.onClickExecutingViewEvn && this.props.onClickExecutingViewEvn(id)
  }

  handleSubmit = async () => {
    await this.setState({ isLoading: true })
    switch (this.state.titlePopUp) {
      case POPUP.THU_HOI_CONG_VAN:
        await this.props.actions
          .sendDocumentRetrieve(this.state.congVanDiId, this.state.donViId)
          .then(res => {
            if (res.error || res.payload.status !== 200) {
              return (
                this.props.onSubmit &&
                this.props.onSubmit({
                  title: 'ThuHoiCongVan',
                  isSuccess: false,
                  message: MESSAGE.TEXT_THUHOICONGVAN_KHONGTHANHCONG,
                  intent: Intent.DANGER,
                })
              )
            }
            this.props.onSubmit &&
              this.props.onSubmit({
                title: 'ThuHoiCongVan',
                isSuccess: true,
                message: MESSAGE.TEXT_THUHOICONGVAN_THANHCONG,
                intent: Intent.SUCCESS,
              })
          })
        break

      case POPUP.GUI_LAI:
        await this.props.actions
          .postReSend(this.state.congVanDiId, this.state.donViId)
          .then(res => {
            if (res.error || res.payload.status !== 200) {
              return (
                this.props.onSubmit &&
                this.props.onSubmit({
                  title: 'GuiLai',
                  isSuccess: false,
                  message: MESSAGE.TEXT_GUILAI_KHONGTHANHCONG,
                  intent: Intent.DANGER,
                })
              )
            }
            this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GuiLai',
                isSuccess: true,
                message: MESSAGE.TEXT_GUILAI_THANHCONG,
                intent: Intent.SUCCESS,
              })
          })
        break

      default:
    }
    await this.setState({ isOpenPopup: false, isLoading: false })
  }

  resendEVN() {
    this.setState({ isLoading: true }, () => {
      this.props.actions
        .resendEVNSendDocument(this.state.congVanDiId)
        .then(res => {
          if (!res || res.error || !res.payload || res.payload.status !== 200) {
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GuiLai',
                isSuccess: false,
                message: MESSAGE.TEXT_GUILAI_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            )
          }
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiLai',
              isSuccess: true,
              message: MESSAGE.TEXT_GUILAI_THANHCONG,
              intent: Intent.SUCCESS,
            })
        })
        .catch(e => {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiLai',
              isSuccess: false,
              message: MESSAGE.TEXT_GUILAI_KHONGTHANHCONG,
              intent: Intent.DANGER,
            })
          )
        })
        .then(() => {
          this.setState({ isLoading: false, isOpenPopup: false })
        })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sendDocumentId) {
      this.setState({ congVanDiId: nextProps.sendDocumentId })
    }
  }

  render() {
    const count =
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.guiEvn === true
        ? 1
        : 0
    let danhSachDonVi = []
    // let danhSachDonViLienThong = []
    // let danhSachDonViLienThongEVN = []
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViNhan
    ) {
      danhSachDonVi = _.concat(
        danhSachDonVi,
        this.props.infoSendAdditional.dsDonViNhan
      )
    }
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViTrucThuoc
    ) {
      danhSachDonVi = _.concat(
        danhSachDonVi,
        this.props.infoSendAdditional.dsDonViTrucThuoc
      )
    }
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViKhac
    ) {
      danhSachDonVi = _.concat(
        danhSachDonVi,
        this.props.infoSendAdditional.dsDonViKhac
      )
    }
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViLienThong
    ) {
      // danhSachDonViLienThong = this.props.infoSendAdditional.dsDonViLienThong
    }
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViLienThongEvn
    ) {
      // danhSachDonViLienThongEVN =
      //   this.props.infoSendAdditional.dsDonViLienThongEvn
    }

    // const checkTongCongTy = this.props.auth.user && this.props.auth.user.dsChucDanh && this.props.auth.user.dsChucDanh.filter(item => item.donVi.donViCha === null);
    return (
      <div>
        <div
          className={classnames(
            'history-steering container-form-action list-receivingunit',
            {
              'history-steering-showmobile container-form-action list-receivingunit':
                this.props.isShowMobile,
            }
          )}
        >
          <div className="heading">
            <span className="click-go-back" onClick={this.props.onClickGoBack}>
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Danh sách các đơn vị nhận</h4>
          </div>
          <div className="form-container container-detail">
            <div className="table-container table-list-receivingunit">
              <div className="table-scroll">
                <table className="pt-table table-center-td">
                  <thead className="table-heading">
                    <tr className="row-item">
                      <th className="colum-first">STT</th>
                      <th className="colum-second">ĐƠN VỊ</th>
                      <Authorization.Element
                        permission={[Permission.CONGVANDI_QUANLY]}
                      >
                        <th className="colum-fixed-width">Gửi LẠI</th>
                      </Authorization.Element>
                      <th className="colum-fixed-width">Xem THỰC HIỆN</th>
                      {/* <Authorization.Element permission={[Permission.CONGVANDI_QUANLY]}>
                        <th className="colum-fixed-width">THU HỒI</th>
                      </Authorization.Element> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.infoSendAdditional &&
                      this.props.infoSendAdditional.guiEvn === true && (
                        <tr className="row-item">
                          <td className="cell-content colum-first colmn-none-cursor">
                            01
                          </td>
                          <td className="cell-content colum-second colmn-none-cursor">
                            EVN
                          </td>
                          <Authorization.Element
                            permission={[Permission.CONGVANDI_QUANLY]}
                          >
                            <td
                              className={classnames(
                                'cell-content colum-fixed-width',
                                {
                                  'colmn-none-cursor':
                                    this.props.infoSendAdditional
                                      .vanBanEvnId !== null,
                                }
                              )}
                            >
                              {this.props.infoSendAdditional.vanBanEvnId ? (
                                <span>&nbsp;</span>
                              ) : (
                                <span
                                  className="onclick-confirm"
                                  title=""
                                  onClick={this.handleClickPopup.bind(
                                    this,
                                    'GL_EVN'
                                  )}
                                >
                                  <span className="hover-icon icon-forward"></span>
                                </span>
                              )}
                            </td>
                          </Authorization.Element>
                          <Authorization.Element
                            permission={[Permission.CONGVANDI_QUANLY]}
                          >
                            <td className="cell-content colum-fixed-width">
                              {this.props.infoSendAdditional.vanBanEvnId !==
                                null && (
                                <span
                                  className="onclick-confirm"
                                  title=""
                                  onClick={this.handleExecutingViewEvn.bind(
                                    this,
                                    this.props.sendDocumentId
                                  )}
                                >
                                  <span className="hover-icon icon-visible"></span>
                                </span>
                              )}
                            </td>
                          </Authorization.Element>
                          <td className="cell-content colum-fixed-width colmn-none-cursor">
                            <span>&nbsp;</span>
                          </td>
                        </tr>
                      )}
                    {danhSachDonVi.map((item, i) => (
                      <tr className="row-item" key={i}>
                        <td className="cell-content colum-first">
                          {count + i + 1 < 10
                            ? '0' + (count + i + 1)
                            : count + i + 1}
                        </td>
                        <td className="cell-content colum-second">
                          {' '}
                          {item.tenVietTat}{' '}
                        </td>
                        <Authorization.Element
                          permission={[Permission.CONGVANDI_QUANLY]}
                        >
                          <td className="cell-content colum-fixed-width">
                            {item.congVanDenId === null ? (
                              <span
                                className="onclick-confirm"
                                title=""
                                onClick={this.handleClickPopup.bind(
                                  this,
                                  'GL',
                                  item.donViId
                                )}
                              >
                                <span className="hover-icon icon-forward"></span>
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                        </Authorization.Element>
                        <td className="cell-content colum-fixed-width">
                          {item.congVanDenId !== null ? (
                            <span
                              className="onclick-confirm"
                              title=""
                              onClick={this.handleExecutingView.bind(
                                this,
                                item.congVanDenId
                              )}
                            >
                              <span className="hover-icon icon-visible"></span>
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                        {/* <Authorization.Element permission={[Permission.CONGVANDI_QUANLY]}>
                          <td className="cell-content colum-fixed-width">
                            { item.congVanDenId !== null && checkTongCongTy.length !== 0 ? (
                              <span className="onclick-confirm" title="" onClick={this.handleClickPopup.bind(this, 'THCV', item.donViId)}>
                                <span className="hover-icon icon-refreshing"></span>
                              </span>
                              ) : ''
                            }
                          </td>
                        </Authorization.Element> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={this.state.titlePopUp}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={
              this.state.isResendEVN ? this.resendEVN : this.handleSubmit
            }
            isLoading={this.state.isLoading}
          >
            {POPUP.TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </div>
      </div>
    )
  }
}

export default RestoreArchive
