import React from 'react'
import PropTypes from 'prop-types'
import { Intent } from '@blueprintjs/core'
import { MasterLayout } from '../../components/layout'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { HeadingPage } from '../../components/common'
import { AddDocumentsCabinet } from '../../components/DocumentCabinet'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as MESSAGE from '../../constants/MessageForm'
import classnames from 'classnames'
import {
  acceptMIMETypes,
  CABINET_UNIT,
  CABINET_DEPARTMENT,
  CABINET_PERSONAL,
} from '../../constants/Api'

class AddFileDocumentsCabinet extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    let type
    switch (this.props.match.params.type) {
      case 'ca-nhan':
        type = CABINET_PERSONAL
        break
      case 'phong-ban':
        type = CABINET_DEPARTMENT
        break
      default:
        type = CABINET_UNIT
    }

    this.state = {
      type,
      isRedirect: false,
      isSaving: false,
      redirectTo: '/tu-tai-lieu/danh-sach',
      progress: 0,
    }

    this.updateCategories = this.updateCategories.bind(this)
    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
  }

  getUploadProgress = () => progress => {
    this.setState({
      progress: Math.floor((progress.loaded * 100) / progress.total),
    })
  }

  handleGoBack() {
    // this.props.history.goBack();
    this.props.history.push({
      pathname: this.state.redirectTo,
      state: {
        type: this.state.type,
      },
    })
  }

  handleChangeType(type) {
    this.setState({ type }, this.updateCategories)
  }

  updateCategories() {
    switch (this.state.type) {
      case CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal()
        break

      case CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment()
        break

      default:
        this.props.actions.documentsCabinetGetListCategoriesUnit()
    }
  }

  handleSubmit(data) {
    if (isNaN(Number(data.stt))) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập đúng định dạng số thứ tự',
        intent: Intent.WARNING,
      })
    }

    if (!data.soCongVan) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập số công văn',
        intent: Intent.WARNING,
      })
    }

    if (!data.ngayCongVan) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn ngày công văn',
        intent: Intent.WARNING,
      })
    }

    if (!data.coQuanBanHanh) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập cơ quan ban hành',
        intent: Intent.WARNING,
      })
    }

    if (!data.tenTaiLieu) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập trích yếu',
        intent: Intent.WARNING,
      })
    }

    if (!this.state.type) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn loại ngăn tủ',
        intent: Intent.WARNING,
      })
    }

    if (!data.dsNganTu || data.dsNganTu.length === 0) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn ngăn tủ',
        intent: Intent.WARNING,
      })
    }

    if (!data.fileNoiDung) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn file nội dung',
        intent: Intent.WARNING,
      })
    } else {
      const attachFiles = data.fileNoiDung

      const extension =
        attachFiles &&
        attachFiles.name
          .slice(((attachFiles.name.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      let msg
      if (acceptMIMETypes.indexOf(extension) === -1) {
        msg = MESSAGE.TEXT_MESSAGE_TYPE_FILE
        return this.props.actions.commonAddToasterMessage({
          message: msg,
          intent: Intent.DANGER,
        })
      } else if (attachFiles.size > 1024 * 1024 * 25) {
        msg = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
        return this.props.actions.commonAddToasterMessage({
          message: msg,
          intent: Intent.DANGER,
        })
      }
    }

    this.setState({ isSaving: true })

    let request
    let file = data.fileNoiDung
    let dsNganTu = data.dsNganTu
    delete data.fileNoiDung
    delete data.dsNganTu
    data = Tool.Utils.linkObjectKey(data)
    ;(async () => {
      await this.props.actions
        .fileUpload(file, this.getUploadProgress())
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL,
              intent: Intent.DANGER,
            })
          }

          data.ds_ngan_tu = dsNganTu
          data.file_noi_dung = {
            file_id: res.payload.data.result[0].id,
            kieu_file: res.payload.data.result[0].extension,
            ten_file: res.payload.data.result[0].originalName,
            url: res.payload.data.result[0].path,
            kich_thuoc: res.payload.data.result[0].length,
          }

          switch (this.state.type) {
            case CABINET_DEPARTMENT:
              request =
                this.props.actions.documentsCabinetNewItemDepartment(data)
              break
            case CABINET_PERSONAL:
              request = this.props.actions.documentsCabinetNewItemPersonal(data)
              break
            default:
              request = this.props.actions.documentsCabinetNewItemUnit(data)
          }

          request
            .then(res => {
              if (res.error) {
                return this.props.actions.commonAddToasterMessage({
                  message: 'Lưu tài liệu không thành công',
                  intent: Intent.DANGER,
                })
              }

              // Refresh danh sach tai tieu
              this.props.actions.refreshDocumentCabinet(true)
              this.handleGoBack()
              this.props.actions.commonAddToasterMessage({
                message: 'Lưu tài liệu thành công',
                intent: Intent.SUCCESS,
              })
            })
            .then(() => {
              this.setState({ isSaving: false })
            })
        })
    })()
  }

  render() {
    const { type, progress } = this.state

    let cabinetCategories = this.props.documentsCabinet.type.personal

    switch (this.state.type) {
      case CABINET_UNIT:
        cabinetCategories = this.props.documentsCabinet.type.unit
        break
      case CABINET_DEPARTMENT:
        cabinetCategories = this.props.documentsCabinet.type.department
        break
      default:
    }

    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <div className="detail-receive-document page-DetailDocumentsCabinet">
          <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== CABINET_UNIT,
                    })}
                    role="tab"
                    aria-selected={type === CABINET_UNIT}
                  >
                    TTL ĐƠN VỊ
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== CABINET_DEPARTMENT,
                    })}
                    role="tab"
                    aria-selected={type === CABINET_DEPARTMENT}
                  >
                    TTL PHÒNG BAN
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== CABINET_PERSONAL,
                    })}
                    role="tab"
                    aria-selected={type === CABINET_PERSONAL}
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>
            <AddDocumentsCabinet
              auth={this.props.auth}
              goBack={this.handleGoBack}
              type={type}
              isSaving={this.state.isSaving}
              onSubmit={this.handleSubmit}
              onChangeType={this.handleChangeType}
              categories={cabinetCategories}
              uploadFileprogress={progress}
              readOnly={this.props.readOnly}
              acceptMIMETypes={acceptMIMETypes}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  documentsCabinet: {
    ...state.documentsCabinet,
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
  },

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFileDocumentsCabinet)
