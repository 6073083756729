import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    page: 1,
    total: 0,
    items: [],
  },
}

export default function sendDocuments(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENT_SEND:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.DOCUMENT_SEND_DETAIL:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    default:
      return state
  }
}
