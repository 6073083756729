import React from 'react'

import { ListFooter, ListItem } from '../../components/DocumentCabinet'
import { GridViewFilter } from '../../components/common'
import { DOCUMENTS_CABINET } from 'constants/Enum'

class TableCabinet extends React.Component {
  static defaultProps = {
    categories: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      listCheckIds: [],
      sort: undefined,
      page: 1,
    }

    this.onPageClick = this.onPageClick.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  onPageClick(e) {
    this.props.onPageClick && this.props.onPageClick(e)
  }

  handleSort(e) {
    this.props.onSort && this.props.onSort(e)
  }

  handleCheck(e, item) {
    this.props.onCheck && this.props.onCheck(e, item)
  }

  renderHeaderModel = type => {
    const dataHeader = [
      {
        classNames: 'custom-checkbox',
        style: [],
        value: '',
        checkAll: true,
        permission: [],
        typeGridView: null,
        nameGridView: '',
        keyCheck: 'CHECK_ALL',
      },
      {
        classNames: 'column-10',
        style: [],
        value: 'Số công văn',
        permission: [],
        sort: 'SoCongVan',
        typeGridView: 'input',
        nameGridView: 'soCongVan',
      },
      {
        classNames: 'column-18',
        style: [],
        value: 'Ngày công văn',
        permission: [],
        sort: 'NgayCongVan',
        typeGridView: 'date',
        nameGridView: 'ngayCongVan',
      },
      {
        classNames: 'column-18',
        style: [],
        value: 'Ngày lưu',
        sort: 'NgayTao',
        typeGridView: 'date',
        nameGridView: 'ngayLuu',
      },
      {
        classNames: 'column-20',
        style: [],
        value: 'Tên tài liệu',
        permission: [],
        sort: 'TenTaiLieu',
        typeGridView: 'input',
        nameGridView: 'tenTaiLieu',
      },
      {
        classNames: 'column-13',
        style: [],
        value: 'CQBH',
        permission: [],
        sort: 'NoiGui',
        typeGridView: 'select',
        nameGridView: 'coQuanBanHanhId',
      },
      {
        classNames: 'column-10',
        style: [],
        value: 'Người lưu',
        permission: [],
        sort: 'TenNhanVien',
        typeGridView: 'input',
        nameGridView: 'nguoiLuu',
      },
      {
        classNames: 'column-5',
        style: [],
        value: '',
        sort: null,
        typeGridView: null,
        nameGridView: null,
      },
    ]
    if (type === DOCUMENTS_CABINET.CA_NHAN) {
      return [
        {
          classNames: 'custom-checkbox',
          style: [],
          value: '',
          sort: null,
          checkAll: true,
          typeGridView: null,
          nameGridView: '',
        },
        {
          classNames: 'column-10',
          style: [],
          value: 'Số công văn',
          sort: 'SoCongVan',
          typeGridView: 'input',
          nameGridView: 'soCongVan',
        },
        {
          classNames: 'column-18',
          style: [],
          value: 'Ngày công văn',
          sort: 'NgayCongVan',
          typeGridView: 'date',
          nameGridView: 'ngayCongVan',
        },
        {
          classNames: 'column-18',
          style: [],
          value: 'Ngày lưu',
          sort: 'NgayTao',
          typeGridView: 'date',
          nameGridView: 'ngayLuu',
        },
        {
          classNames: 'column-50',
          style: [],
          value: 'Tên tài liệu',
          sort: 'TenTaiLieu',
          typeGridView: 'input',
          nameGridView: 'tenTaiLieu',
        },
        {
          classNames: 'column-5',
          style: [],
          value: '',
          sort: null,
          typeGridView: null,
          nameGridView: null,
        },
      ]
    } else {
      return dataHeader
    }
  }

  render() {
    const { type } = this.props
    return (
      <div className="col-main-content">
        {this.props.children}
        <div
          className="table-container"
          style={{
            paddingBottom: 0,
            border: 0,
            background: this.props.pageCount > 1 ? '#fff' : '#eff3f5',
          }}
        >
          <GridViewFilter
            {...this.props}
            type={this.props.type}
            headers={this.renderHeaderModel(type)}
            classNames={'pt-table fix-table-before'}
            page={this.props.page}
            itemShape={ListItem}
            data={this.props.list.items}
            onFilter={this.handleSort}
            onCheck={this.handleCheck}
            isLoading={this.props.isLoading}
            isEmpty={this.props.isEmpty}
            phongBanId={this.props.phongBanId}
            isError={this.props.isError}
            actionPermissions={this.props.actionPermissions}
            coQuanBanHanhId={this.props.units}
            TTL={true}
            handleCheckAll={this.props.handleCheckAll}
            listCheckIds={this.props.listCheckIds}
            isCheckAll={this.props.isCheckAll}
            isCheckAllData={this.props.isCheckAllData}
            keyTabTuTaiLieu={this.props.keyTabTuTaiLieu}
            filter={this.props.filter}
            filterColumn={this.props.filterColumn}
          />
          {!this.props.isLoading && (
            <ListFooter
              pageCount={Math.ceil(this.props.list.total / 10)}
              onPageClick={this.onPageClick}
              forcePage={this.props.forcePage}
            />
          )}
        </div>
      </div>
    )
  }
}

export default TableCabinet
