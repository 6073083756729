import React from 'react'
import { Button, Intent, Checkbox, Dialog } from '@blueprintjs/core'
import * as MESSAGE from '../../constants/MessageForm'
import { FILE_URL, IMAGE_URL } from '../../constants/Api'
import async from 'async'
import classnames from 'classnames'
import * as Tool from '../../helpers'
import { SelectTree } from '../common/'
import AvatarEditor from 'react-avatar-editor'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const PATH = '/he-thong/nguoi-dung/chinh-sua/:id'
const ValueEmpty = '00000000-0000-0000-0000-000000000000'
const avatarDataImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAM1BMVEVGRkb////09PR0dHTR0dGjo6OMjIxSUlLd3d1dXV2urq7Gxsbp6elpaWm6urqAgICXl5cpFdX+AAAEpklEQVR4nO2cCZaqMBBFZVZEdP+rbRAcGgmkBrvez6+7AE+e1JhUcjg4juM4juM4juM4juM4juPAUlR9mc10ZVtfrBfEoanP2Qd5f7VeF5Gi/1Qxa2lP1ouLpyhDMu6cC+sFxnFasamllH/hq1T5ro6BynqZezTbVvXiiB3CLlGfY6K2XuwGdbyMgd56uUFoOgbzaqxXvA5VB6oSug5MJQVDB6KSEyFevVNaL3zJkacjy1rrlf+m4uoAyycXvo4sRyq82IY1crRe/QtO5H0DpoJsmBHrAYxxCTx94mytYEb4QQYwekahh4xgpEVRyJpB+CSSHPIE4ZO0GkIygMDVqQixbxdVLGvIJdY65ElkxnwzNXb/Zw/zpKikw9y2lFwkM7cthbQ+Y9wqavm6eVui5esDyQixrbf0dGS3VITYdryKQmwr4FSEnBSFmIatZIRompYLcSFJClEsUVyICnplvLGQWypCeIe5qxjvNiYjRGMLe8L44Epn63fE+Hj3qibEeoJLfl41Y6zjsD/CGIf5EYmWbdkfUivZlrWLHA7BYWUSnbUMrXYXYUpIpQK2tyydegtjskbhk2BMbck/ifV51QNxUrRPIhPc0cznB4EZNhV2vBgecieVYUCZcSHkkCeC410UT59ht4pIhnWHmRZhRjOfNDyHRxieW8BSAhR5XzCUQOpgKAHVcaC2i7g6SPkkh0qEH1xizauEqRRDRFWQue3oSRyp3DseKLallIBZMMSpDdbDPbaTf3LtV7Sca3gfX+NSt+Vz5rzsK1STurb3kjeXG0sxfb2yNZia/WU5rchgmrdm5o+f6vjwZcmt22WJ9ndPdaxFV37BsfYuwZ881VGvXxnJmSVgvR6nuy9XlE0VvvnCOhoI9/pd9b1I3Wy/FnIk2/Zps8TMvyVl99ETqnkFzOpdyhdkFDHXqSjVYERxORiYuttH7u/G1+e3yN1J3Y/SxDexcf9h1Ped6BU9hbatsF+m77z4tEDxjQvqUU656fU1dVdS7SoZY2M3bwOp/hLuVMIonV4zxzS69rqwiebaMm+SqlSSjeAaa3eubsWdqjpLfkfDTTRHSdkoBGHVKwl85KW93rSfCLG/Sx/Z0EJ8hq13+VOItEHRG4gVIjxsBHH1EZm7a06/C5Hte8NYltC2GuvVvyOJWzAxa0QSt3RmSJWQPDqi8+yJEoKZWqDgO8IPwFAuInESkILxAb9w1LyepwD/SoP1ypdwdei936IE9+wCzNf53g7m63xvB/N1vreDdLkvmEP0UKXvBK8AVryJqwXvtAQuaHHDFsQW4294G45wQYsbtoD69Qe8vt161WtwdIB1VROc3gow+vLiL9De3AvOLh1g9OXFX62r6qpwDngB0wgvkViveRVO/Wu95nXoOiCjLyf+JiMEMo1wEglkGuEkEsg0wkkkkGmEk0igjkZe0A9JrFccgqoDshsZoe4IgaYReiJJRghoGqEnkmSEgOZDekYEzYf0jAi4OzdB3aOzXm+Y/1QIbGKnbjbC5kNqRkxGCGijO0JrdmETOzW1JyMEtkKh1iiwFQq1RklGCOjWwwht+8F6tVu4EDRcCBqJC/kBC3hPSkzNuXAAAAAASUVORK5CYII='
const MAX_WARNING_UPLOAD_AVATAR_SIZE = 200.0
const viettelImgStyle = {
  width: '35px',
  height: '35px',
  marginLeft: '20px',
}
const VNPTImgStyle = {
  width: '35px',
  height: '35px',
  marginLeft: '20px',
}

class UpdateDecentralizationUser extends React.Component {
  constructor(props) {
    super(props)
    this.toggleChoseFile = this.toggleChoseFile.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
    this.handleUploadAvatar = this.handleUploadAvatar.bind(this)
    this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this)
    this.uploadFromLocal = this.uploadFromLocal.bind(this)
    this.onAddBtnClick = this.onAddBtnClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleChangeGroupInput = this.handleChangeGroupInput.bind(this)
    this.handleRemoveItem = this.handleRemoveItem.bind(this)
    this.handleFile = this.handleFile.bind(this)
    this.handleFileProfile = this.handleFileProfile.bind(this)
    this.clearProfile = this.clearProfile.bind(this)
    this.handleEventRemoveFileItemRight =
      this.handleEventRemoveFileItemRight.bind(this)
    this.handleEventRemoveFileItemLeft =
      this.handleEventRemoveFileItemLeft.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  toggleDialog = () => {
    let { avatar, isOpenCropImage, isChangeDefaultAvatar } = this.state
    if (isOpenCropImage && isChangeDefaultAvatar) {
      avatar = {
        image: null,
        name: '',
        size: null,
        fileRequest: null,
        error: null,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        preview: {},
        width: 201,
        height: 268,
      }
    }
    isOpenCropImage = !isOpenCropImage
    this.setState({
      isOpenCropImage,
      avatar,
    })
  }

  handleUploadAvatar = async e => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    if (!files || !files[0]) {
      return
    }

    let { avatar } = this.state
    avatar.error = null

    // Validate: max = 2Mb
    const fileName = e.target.value
    const extension =
      fileName &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
    if (!fileName.trim().length && avatar.name.length === 0) {
      avatar.error = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (
      extension &&
      extension !== 'png' &&
      extension !== 'jpg' &&
      extension !== 'jpeg'
    ) {
      avatar.error = MESSAGE.TEXT_TYPE_FILE_UPLOAD_IMAGE
    }

    if (avatar.error) {
      return this.setState({ avatar })
    }

    // Set new upload avatar
    avatar.image = files[0]
    avatar.name = files[0].name.replace(/ /g, '')

    // Reset cropped avatar
    avatar.fileRequest = null
    avatar.error = null
    avatar.position = { x: 0.5, y: 0.5 }
    avatar.scale = 1
    avatar.preview = {}
    avatar.width = 201
    avatar.height = 268

    const reader = new FileReader()
    reader.onload = async () => {
      await this.setState({ avatar })
    }
    await reader.readAsDataURL(files[0])
    await this.toggleDialog()
  }

  handleRemoveAvatar = () => {
    this.setState({
      avatar: {
        url: '',
        image: null,
        name: '',
        size: null,
        fileRequest: null,
        error: null,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        preview: {},
        width: 201,
        height: 268,
      },
    })
  }

  handleSaveAvatar = data => {
    let img = this.editor.getImageScaledToCanvas().toDataURL()
    const rect = this.editor.getCroppingRect()
    let { avatar } = this.state
    fetch(img)
      .then(res => res.blob())
      .then(blob => {
        avatar.fileRequest = Tool.Utils.blobToFile(blob, this.state.avatar.name)
        avatar.size = blob.size
        this.setState({ avatar })
      })
    avatar.preview = {
      img,
      rect,
      scale: this.state.avatar.scale,
      width: this.state.avatar.width,
      height: this.state.avatar.height,
    }
    this.setState({
      avatar,
      isOpenCropImage: false,
      isChangeDefaultAvatar: true,
    })
  }

  handleScaleAvatar = e => {
    let { avatar } = this.state
    avatar.scale = parseFloat(e.target.value)
    this.setState({ avatar })
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChangeAvatar = position => {
    let { avatar } = this.state
    avatar.position = position
    this.setState({ avatar })
  }

  onAddBtnClick(event) {
    const donViId = this.state.donViId
    const phongBanId = this.state.phongBanId
    const chucVuId = this.state.chucVuId
    const loaiQuyenId = this.state.loaiQuyenId
    const phongBan = this.state.phongBan
    const chucVu = this.state.chucVu
    const loaiQuyen = this.state.loaiQuyen
    const isDeleteChuKyCodau = this.state.isDeleteChuKyCodau
    const isDeleteChuKyKhongdau = this.state.isDeleteChuKyKhongdau
    const isDeleteChuKyNhay = this.state.isDeleteChuKyNhay
    const isDeleteChuKyThanhVien = this.state.isDeleteChuKyThanhVien
    const suDungEoffice = this.state.suDungEoffice
    const hienThiChat = this.state.hienThiChat

    donViId.push(this.state.donViId[0])
    phongBanId.push(this.state.phongBanId[0])
    chucVuId.push(this.state.chucVuId[0])
    loaiQuyenId.push(this.state.loaiQuyenId[0])
    phongBan.push(this.state.phongBan[0])
    chucVu.push(this.state.chucVu[0])
    loaiQuyen.push(this.state.loaiQuyen[0])
    isDeleteChuKyCodau.push(this.state.isDeleteChuKyCodau[0])
    isDeleteChuKyKhongdau.push(this.state.isDeleteChuKyKhongdau[0])
    isDeleteChuKyNhay.push(this.state.isDeleteChuKyNhay[0])
    isDeleteChuKyThanhVien.push(this.state.isDeleteChuKyThanhVien[0])
    suDungEoffice.push(true)
    hienThiChat.push(false)

    this.setState({
      donViId,
      phongBanId,
      chucVuId,
      loaiQuyenId,
      phongBan,
      loaiQuyen,
      chucVu,
      isDeleteChuKyCodau,
      isDeleteChuKyKhongdau,
      isDeleteChuKyNhay,
      isDeleteChuKyThanhVien,
      suDungEoffice,
      hienThiChat,
      isAdd: this.state.isAdd.concat(this.state.isAdd.length + 1),
    })
  }

  handleRemoveItem(i) {
    const isAdd = this.state.isAdd
    const donViId = this.state.donViId
    const phongBanId = this.state.phongBanId
    const chucVuId = this.state.chucVuId
    const loaiQuyenId = this.state.loaiQuyenId
    const chuKyCoDau = this.state.chuKyCoDau
    const chuKyKhongDau = this.state.chuKyKhongDau
    const chuKyNhay = this.state.chuKyNhay
    const chuKyThanhVien = this.state.chuKyThanhVien
    const phongBan = this.state.phongBan
    const chucVu = this.state.chucVu
    const loaiQuyen = this.state.loaiQuyen
    const isDeleteChuKyCodau = this.state.isDeleteChuKyCodau
    const isDeleteChuKyKhongdau = this.state.isDeleteChuKyKhongdau
    const isDeleteChuKyNhay = this.state.isDeleteChuKyNhay
    const isDeleteChuKyThanhVien = this.state.isDeleteChuKyThanhVien
    const suDungEoffice = this.state.suDungEoffice
    const hienThiChat = this.state.hienThiChat

    isAdd.splice(i, 1)
    donViId.splice(i, 1)
    phongBanId.splice(i, 1)
    chucVuId.splice(i, 1)
    loaiQuyenId.splice(i, 1)
    chuKyCoDau.splice(i, 1)
    chuKyKhongDau.splice(i, 1)
    chuKyNhay.splice(i, 1)
    chuKyThanhVien.splice(i, 1)
    phongBan.splice(i, 1)
    chucVu.splice(i, 1)
    loaiQuyen.splice(i, 1)
    isDeleteChuKyCodau.splice(i, 1)
    isDeleteChuKyKhongdau.splice(i, 1)
    isDeleteChuKyNhay.splice(i, 1)
    isDeleteChuKyThanhVien.splice(i, 1)
    suDungEoffice.splice(i, 1)
    hienThiChat.splice(i, 1)

    this.setState({
      isAdd,
      donViId,
      phongBanId,
      chucVuId,
      loaiQuyenId,
      chuKyCoDau,
      chuKyKhongDau,
      chuKyNhay,
      chuKyThanhVien,
      phongBan,
      loaiQuyen,
      chucVu,
      isDeleteChuKyCodau,
      isDeleteChuKyKhongdau,
      isDeleteChuKyNhay,
      isDeleteChuKyThanhVien,
      suDungEoffice,
      hienThiChat,
    })
  }

  state = {
    avatar: {
      url: '',
      image: null,
      name: '',
      size: null,
      fileRequest: null,
      error: null,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      preview: {},
      width: 150,
      height: 200,
    },
    profile: {
      file: null,
      url: '',
      name: '',
      size: null,
      isLoadingFile: true,
      error: '',
    },
    isHideBtnGroup: true,
    isOpenDialogChoseFile: false,
    isOpenCropImage: false,
    isChangeDefaultAvatar: true,
    tenDangNhap: '',
    password: '',
    maNhanVien: '',
    tenNhanVien: '',
    ngaySinh: null,
    soDienThoaiKhac: '',
    soDienThoai: '',
    soDienThoaiKySo: '',
    soDienThoaiKySoVnpt: '',
    suDungUsbToken: false,
    email: '',
    donViId: [this.props.auth.mainUnitId],
    phongBanId: [],
    chucVuId: [],
    loaiQuyenId: [],
    isLoading: false,
    error: {},
    isOpenPopup: false,
    isAdd: [1],
    dsChucDanh: [],
    errorChuKyCoDau: [],
    errorChuKyKhongDau: [],
    errorChuKyNhay: [],
    errorChuKyThanhVien: [],
    fileEdit: [],
    chuKyCoDau: [],
    chuKyKhongDau: [],
    chuKyNhay: [],
    chuKyThanhVien: [],
    nameFileCoDau: [],
    nameFileKhongDau: [],
    nameFileChuKyNhay: [],
    nameFileChuKyThanhVien: [],
    sizeFileCoDau: [],
    sizeFileKhongDau: [],
    sizeFileChuKyNhay: [],
    sizeFileChuKyThanhVien: [],
    permission: undefined,
    nhanVienId: '',
    chucDanhId: [],
    phongBan: [],
    chucVu: [],
    loaiQuyen: [],
    isDeleteChuKyCodau: [false],
    isDeleteChuKyKhongdau: [false],
    isDeleteChuKyNhay: [false],
    isDeleteChuKyThanhVien: [false],
    isUnit: undefined,
    isDepartment: undefined,
    isData: true,
    quanLyTTL: [false],
    hienThiChat: [true],
    suDungEoffice: [true],
    random: Math.random(),
    isLoadingData: {
      tenDangNhap: true,
      password: true,
      maNhanVien: true,
      tenNhanVien: true,
      ngaySinh: true,
      soDienThoaiKhac: true,
      soDienThoai: true,
      soDienThoaiKySo: true,
      soDienThoaiKySoVnpt: true,
      suDungUsbToken: false,
      email: true,
      donViId: true,
      phongBanId: true,
      chucVuId: true,
      loaiQuyenId: true,
      chuKyCoDau: true,
      chuKyKhongDau: true,
      chuKyNhay: true,
      chuKyThanhVien: true,
      quanLyTTL: true,
      hienThiChat: true,
      suDungEoffice: true,
    },
  }

  componentDidMount() {
    let isUnit = Tool.Utils.getData('Permission-Unit', {})
    let isDepartment = Tool.Utils.getData('Permission-Department', {})

    this.setState({ donViId: [isUnit], isDepartment, random: Math.random() })

    document.addEventListener('click', () =>
      this.setState({ isHideBtnGroup: true })
    )
    this.dropdown.addEventListener('click', e => {
      e.stopPropagation()
      this.setState({ isHideBtnGroup: !this.state.isHideBtnGroup })
    })
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.unit !== this.props.unit && !this.props.staff) {
      if (nextProps.unit.length === 0) {
        return
      }
    }

    if (
      nextProps.departments !== this.props.departments &&
      !this.props.staff &&
      this.state.isAdd.length === 1 &&
      this.state.isData
    ) {
      const phongBanId =
        this.state.isDepartment !== undefined
          ? this.state.isDepartment
          : this.props.auth.mainDepartmentId
      const check =
        nextProps.departments.find(item => phongBanId === item.phongBanId) !==
        undefined
      const phongBan = nextProps.departments.map(item => item)
      await this.setState({
        phongBanId: [check ? phongBanId : ''],
        phongBan: [phongBan],
        isLoadingData: {
          ...this.state.isLoadingData,
          phongBanId: false,
        },
      })
    }

    if (
      nextProps.position !== this.props.position &&
      !this.props.staff &&
      this.state.isData
    ) {
      const chucVuId = nextProps.position[0] && nextProps.position[0].chucVuId
      await this.setState({
        chucVuId: [chucVuId],
        chucVu: [nextProps.position],
        isLoadingData: {
          ...this.state.isLoadingData,
          chucVuId: false,
        },
      })
    }

    if (
      nextProps.role !== this.props.role &&
      !this.props.staff &&
      this.state.isData
    ) {
      const doiTuongId = nextProps.role[0] && nextProps.role[0].doiTuongId
      await this.setState({
        loaiQuyenId: [doiTuongId],
        loaiQuyen: [nextProps.role],
        isLoadingData: {
          ...this.state.isLoadingData,
          loaiQuyenId: false,
        },
      })
    }

    if (
      nextProps.staff === this.props.staff &&
      this.props.match.path !== PATH
    ) {
      await this.setState({
        profile: {
          ...this.state.profile,
          isLoadingFile: false,
        },
        isLoadingData: {
          ...this.state.isLoadingData,
          tenDangNhap: false,
          password: false,
          maNhanVien: false,
          tenNhanVien: false,
          ngaySinh: false,
          soDienThoaiKhac: false,
          soDienThoai: false,
          soDienThoaiKySo: false,
          soDienThoaiKySoVnpt: false,
          suDungUsbToken: false,
          email: false,
          donViId: false,
          chuKyCoDau: false,
          chuKyKhongDau: false,
          chuKyNhay: false,
          chuKyThanhVien: false,
          quanLyTTL: false,
          suDungEoffice: false,
          hienThiChat: false,
        },
      })
    }

    if (nextProps.auth !== this.props.auth) {
      if (nextProps.auth.permission.length === 0) {
        return
      }
      const permission =
        nextProps.auth.permission.find(item => item === 'SUPERADMIN') !==
        undefined
      this.setState({ permission })
    }

    if (nextProps.staff !== this.props.staff) {
      const nhanVienId = nextProps.staff.nhanVienId || ''
      const maNhanVien = nextProps.staff.maNhanVien || ''
      const email = nextProps.staff.email || ''
      const tenNhanVien = nextProps.staff.tenNhanVien || ''
      const ngaySinh = nextProps.staff.ngaySinh
        ? moment(nextProps.staff.ngaySinh)
        : null
      const tenDangNhap = nextProps.staff.username || ''
      const password = nextProps.staff.password || ''
      const soDienThoai = nextProps.staff.soDienThoai || ''
      const soDienThoaiKhac = nextProps.staff.soDienThoaiKhac || ''
      const soDienThoaiKySo = nextProps.staff.soDienThoaiKySo || ''
      const soDienThoaiKySoVnpt = nextProps.staff.soDienThoaiKySoVnpt || ''
      const suDungUsbToken = nextProps.staff.suDungUsbToken || false
      const donViId = []
      const chucVuId = []
      const loaiQuyenId = []
      const phongBanId = []
      const phongBanChinh = []
      const chuKyCoDau = []
      const chuKyKhongDau = []
      const chuKyNhay = []
      const chuKyThanhVien = []
      const chucDanhId = []
      const isAdd = []
      const phongBan = []
      const loaiQuyen = []
      const chucVu = []
      const quanLyTTL = []
      const suDungEoffice = []
      const hienThiChat = []

      // Default avatar
      let avatar = this.state.avatar
      if (nextProps.staff.anhDaiDien) {
        avatar.url = nextProps.staff.anhDaiDien
      }
      let { profile } = this.state
      profile.url = nextProps.staff.urlFileDinhKem || ''
      profile.size = nextProps.staff.kichThuocFileDinhKem || null
      profile.name = nextProps.staff.tenFileDinhKem || ''

      async.eachSeries(
        nextProps.staff.dsChucDanh,
        (item, next) => {
          donViId.push(item.donViId)
          loaiQuyenId.push(item.doiTuongId)
          chucVuId.push(item.chucVuId)
          phongBanId.push(item.phongBanId)
          phongBanChinh.push(item.phongBanChinh)
          chuKyCoDau.push(item.chuKyCoDau)
          chuKyKhongDau.push(item.chuKyKhongDau)
          chuKyNhay.push(item.chuKyNhayCaNhan)
          chuKyThanhVien.push(item.chuKyThanhVienDuAn)
          chucDanhId.push(item.chucDanhId)
          quanLyTTL.push(item.quanLyNganTuPhongBan)
          suDungEoffice.push(item.suDungEoffice)
          hienThiChat.push(item.hienThiChat)
          isAdd.push(1)
          this.props.actions
            .getCategoryDepartmentMultiLevel(item.donViId)
            .then(res => {
              phongBan.push(res.payload.data.result)
            })
            .then(() => {
              this.props.actions
                .commonFetchPosition(item.donViId)
                .then(res => {
                  chucVu.push(res.payload.data.result.items)
                })
                .then(() => {
                  this.props.actions
                    .getCommonFetchRole(item.donViId, 1)
                    .then(res => {
                      loaiQuyen.push(res.payload.data.result.items)
                    })
                    .then(next)
                })
            })
        },
        () => {
          if (this.props.match.path === PATH) {
            this.setState(
              {
                avatar,
                profile,
                nhanVienId,
                maNhanVien,
                email,
                tenNhanVien,
                ngaySinh,
                tenDangNhap,
                password,
                soDienThoai,
                soDienThoaiKhac,
                soDienThoaiKySo,
                soDienThoaiKySoVnpt,
                suDungUsbToken,
                donViId,
                chucVuId,
                loaiQuyenId,
                phongBanId,
                phongBanChinh,
                chuKyCoDau,
                chuKyKhongDau,
                chuKyNhay,
                chuKyThanhVien,
                chucDanhId,
                isAdd,
                phongBan,
                loaiQuyen,
                chucVu,
                quanLyTTL,
                suDungEoffice,
                hienThiChat,
              },
              () => {
                this.setState({
                  isChangeDefaultAvatar: false,
                  profile: {
                    ...this.state.profile,
                    isLoadingFile: false,
                  },
                  isLoadingData: {
                    ...this.state.isLoadingData,
                    tenDangNhap: false,
                    password: false,
                    maNhanVien: false,
                    tenNhanVien: false,
                    ngaySinh: false,
                    soDienThoaiKhac: false,
                    soDienThoai: false,
                    soDienThoaiKySo: false,
                    soDienThoaiKySoVnpt: false,
                    suDungUsbToken: false,
                    email: false,
                    donViId: false,
                    chuKyCoDau: false,
                    chuKyKhongDau: false,
                    chuKyNhay: false,
                    chuKyThanhVien: false,
                    phongBanId: false,
                    chucVuId: false,
                    loaiQuyenId: false,
                    quanLyTTL: false,
                    suDungEoffice: false,
                    hienThiChat: false,
                  },
                })
              }
            )
          }
        }
      )
    }
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    }

    if (e.target.type === 'email') {
      this.setState({ [e.target.name]: e.target.value })
    }

    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    }
  }

  handleChangeDate(date) {
    this.setState({ ngaySinh: date })
  }

  handleCheckbox(i, e) {
    if (e.target.name === 'suDungEoffice') {
      const suDungEoffice = this.state.suDungEoffice
      suDungEoffice[i] = e.target.checked
      return this.setState({ suDungEoffice })
    }

    if (e.target.name === 'hienThiChat') {
      const hienThiChat = this.state.hienThiChat
      hienThiChat[i] = e.target.checked
      return this.setState({ hienThiChat })
    }

    if (e.target.type === 'checkbox') {
      this.setState({
        [e.target.name]: {
          ...this.state.quanLyTTL,
          [i]: e.target.checked,
        },
      })
      if (i > 1) {
        this.setState({
          [e.target.name]: {
            ...this.state.quanLyTTL,
            [i]: false,
          },
        })
      }
    }
  }

  handleFileProfile = e => {
    if (e.target.files[0]) {
      let { profile } = this.state
      if (e.target.files[0].size > 1024 * 1024 * 25) {
        profile.error = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
        this.setState({ profile })
      } else {
        profile.file = e.target.files[0]
        profile.size = e.target.files[0].size
        profile.name = e.target.files[0].name
        profile.error = ''
        this.setState({ profile })
      }
    }
  }

  clearProfile = () => {
    let { profile } = this.state
    profile.file = null
    profile.url = ''
    profile.name = ''
    profile.size = null
    this.setState({ profile })
  }

  handleFile = (type, i, e) => {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    let reader = new FileReader()
    let file = e.target.files[0]

    const errorChuKyCoDau = this.state.errorChuKyCoDau
    const errorChuKyKhongDau = this.state.errorChuKyKhongDau
    const errorChuKyNhay = this.state.errorChuKyNhay
    const errorChuKyThanhVien = this.state.errorChuKyThanhVien
    const fileEdit = this.state.fileEdit
    const attachFiles = e.target.value
    const extension =
      attachFiles &&
      attachFiles
        .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()

    switch (type) {
      case 'coDau':
        reader.onloadend = () => {
          const base64 = reader.result.substring(
            reader.result.lastIndexOf(',') + 1,
            reader.result.trim().length
          )
          state[name][id] = base64
          state.nameFileCoDau[id] = file.name
          state.sizeFileCoDau[id] = file.size
          this.setState({ state }, () => {
            this.setState({
              isDeleteChuKyCodau: {
                ...this.state.isDeleteChuKyCodau,
                [id]: false,
              },
            })
          })
        }

        if (file) {
          reader.readAsDataURL(file)
          if (extension && extension !== 'png' && extension !== 'jpg') {
            errorChuKyCoDau[i] = MESSAGE.TEXT_TYPE_FILE_IMAGE_UPLOAD
          } else if (state.sizeFile > 1024 * 1024 * 25) {
            errorChuKyCoDau[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
          } else {
            delete errorChuKyCoDau[i]
            fileEdit.splice(i, 1)
          }
          this.setState({ errorChuKyCoDau: errorChuKyCoDau, fileEdit })
        }
        break

      case 'khongDau':
        reader.onloadend = () => {
          const base64 = reader.result.substring(
            reader.result.lastIndexOf(',') + 1,
            reader.result.trim().length
          )
          state[name][id] = base64
          state.nameFileKhongDau[id] = file.name
          state.sizeFileKhongDau[id] = file.size
          this.setState({ state }, () => {
            this.setState({
              isDeleteChuKyKhongdau: {
                ...this.state.isDeleteChuKyKhongdau,
                [id]: false,
              },
            })
          })
        }

        if (file) {
          reader.readAsDataURL(file)
          if (
            extension &&
            extension.toLowerCase() !== 'png' &&
            extension !== 'jpg'
          ) {
            errorChuKyKhongDau[i] = MESSAGE.TEXT_TYPE_FILE_IMAGE_UPLOAD
          } else if (state.sizeFile > 1024 * 1024 * 25) {
            errorChuKyKhongDau[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
          } else {
            delete errorChuKyKhongDau[i]
            fileEdit.splice(i, 1)
          }
          this.setState({ errorChuKyKhongDau: errorChuKyKhongDau, fileEdit })
        }
        break

      case 'nhay':
        reader.onloadend = () => {
          const base64 = reader.result.substring(
            reader.result.lastIndexOf(',') + 1,
            reader.result.trim().length
          )
          state[name][id] = base64
          state.nameFileChuKyNhay[id] = file.name
          state.sizeFileChuKyNhay[id] = file.size
          this.setState({ state }, () => {
            this.setState({
              isDeleteChuKyNhay: {
                ...this.state.isDeleteChuKyNhay,
                [id]: false,
              },
            })
          })
        }

        if (file) {
          reader.readAsDataURL(file)
          if (
            extension &&
            extension.toLowerCase() !== 'png' &&
            extension !== 'jpg'
          ) {
            errorChuKyNhay[i] = MESSAGE.TEXT_TYPE_FILE_IMAGE_UPLOAD
          } else if (state.sizeFile > 1024 * 1024 * 25) {
            errorChuKyNhay[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
          } else {
            delete errorChuKyNhay[i]
            fileEdit.splice(i, 1)
          }
          this.setState({ errorChuKyNhay, fileEdit })
        }
        break

      case 'thanhvien':
        reader.onloadend = () => {
          const base64 = reader.result.substring(
            reader.result.lastIndexOf(',') + 1,
            reader.result.trim().length
          )
          state[name][id] = base64
          state.nameFileChuKyThanhVien[id] = file.name
          state.sizeFileChuKyThanhVien[id] = file.size
          this.setState({ state }, () => {
            this.setState({
              isDeleteChuKyThanhVien: {
                ...this.state.isDeleteChuKyThanhVien,
                [id]: false,
              },
            })
          })
        }

        if (file) {
          reader.readAsDataURL(file)
          if (
            extension &&
            extension.toLowerCase() !== 'png' &&
            extension !== 'jpg'
          ) {
            errorChuKyThanhVien[i] = MESSAGE.TEXT_TYPE_FILE_IMAGE_UPLOAD
          } else if (state.sizeFile > 1024 * 1024 * 25) {
            errorChuKyThanhVien[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
          } else {
            delete errorChuKyThanhVien[i]
            fileEdit.splice(i, 1)
          }
          this.setState({ errorChuKyThanhVien, fileEdit })
        }
        break
      default:
    }
  }

  handleEventRemoveFileItemRight = i => {
    const chuKyCoDau = this.state.chuKyCoDau
    const errorChuKyCoDau = this.state.errorChuKyCoDau
    const nameFileCoDau = this.state.nameFileCoDau
    const sizeFileCoDau = this.state.sizeFileCoDau

    delete chuKyCoDau[i]
    delete errorChuKyCoDau[i]
    delete nameFileCoDau[i]
    delete sizeFileCoDau[i]

    this.setState({
      chuKyCoDau,
      errorChuKyCoDau,
      nameFileCoDau,
      sizeFileCoDau,
      isDeleteChuKyCodau: {
        ...this.state.isDeleteChuKyCodau,
        [i]: true,
      },
    })
  }

  handleEventRemoveFileItemLeft = i => {
    const chuKyKhongDau = this.state.chuKyKhongDau
    const errorChuKyKhongDau = this.state.errorChuKyKhongDau
    const nameFileKhongDau = this.state.nameFileKhongDau
    const sizeFileKhongDau = this.state.sizeFileKhongDau

    delete chuKyKhongDau[i]
    delete errorChuKyKhongDau[i]
    delete nameFileKhongDau[i]
    delete sizeFileKhongDau[i]

    this.setState({
      chuKyKhongDau,
      errorChuKyKhongDau,
      nameFileKhongDau,
      sizeFileKhongDau,
      isDeleteChuKyKhongdau: {
        ...this.state.isDeleteChuKyKhongdau,
        [i]: true,
      },
    })
  }

  handleEventRemoveFileChuKyNhay = i => {
    const chuKyNhay = this.state.chuKyNhay
    const errorChuKyNhay = this.state.errorChuKyNhay
    const nameFileChuKyNhay = this.state.nameFileChuKyNhay
    const sizeFileChuKyNhay = this.state.sizeFileChuKyNhay

    delete chuKyNhay[i]
    delete errorChuKyNhay[i]
    delete nameFileChuKyNhay[i]
    delete sizeFileChuKyNhay[i]

    this.setState({
      chuKyNhay,
      errorChuKyNhay,
      nameFileChuKyNhay,
      sizeFileChuKyNhay,
      isDeleteChuKyNhay: {
        ...this.state.isDeleteChuKyNhay,
        [i]: true,
      },
    })
  }

  handleEventRemoveFileChuKyThanhVien = i => {
    const chuKyThanhVien = this.state.chuKyThanhVien
    const errorChuKyThanhVien = this.state.errorChuKyThanhVien
    const nameFileChuKyThanhVien = this.state.nameFileChuKyThanhVien
    const sizeFileChuKyThanhVien = this.state.sizeFileChuKyThanhVien

    delete chuKyThanhVien[i]
    delete errorChuKyThanhVien[i]
    delete nameFileChuKyThanhVien[i]
    delete sizeFileChuKyThanhVien[i]

    this.setState({
      chuKyThanhVien,
      errorChuKyThanhVien,
      nameFileChuKyThanhVien,
      sizeFileChuKyThanhVien,
      isDeleteChuKyThanhVien: {
        ...this.state.isDeleteChuKyThanhVien,
        [i]: true,
      },
    })
  }

  handleChangeGroupInput = (i, e) => {
    const [name, id] = e.target.name.split('-')
    const state = this.state
    state[name][id] = e.target.value
    this.setState(state)
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ isLoading: true })
    const dsChucDanh = []
    const state = this.state
    for (let i = 0; i < state.isAdd.length; i++) {
      let chucDanh = {}
      chucDanh = {
        chuc_danh_id: state.chucDanhId[i],
        don_vi_id: state.donViId[i],
        phong_ban_id: state.phongBanId[i],
        chuc_vu_id: state.chucVuId[i],
        doi_tuong_id: state.loaiQuyenId[i],
        chu_ky_co_dau:
          state.nameFileCoDau[i] && state.errorChuKyCoDau[i] === undefined
            ? state.chuKyCoDau[i]
            : '',
        chu_ky_khong_dau:
          state.nameFileKhongDau[i] && state.errorChuKyKhongDau[i] === undefined
            ? state.chuKyKhongDau[i]
            : '',
        chu_ky_nhay_ca_nhan:
          state.nameFileChuKyNhay[i] && state.errorChuKyNhay[i] === undefined
            ? state.chuKyNhay[i]
            : '',
        chu_ky_thanh_vien_du_an:
          state.nameFileChuKyThanhVien[i] &&
          state.errorChuKyThanhVien[i] === undefined
            ? state.chuKyThanhVien[i]
            : '',
        quan_ly_ngan_tu_phong_ban: state.quanLyTTL[i],
        phong_ban_chinh: i === 0 ? true : false,
        is_active: true,
        is_deleted_chu_ky_co_dau: state.isDeleteChuKyCodau[i],
        is_deleted_chu_ky_khong_dau: state.isDeleteChuKyKhongdau[i],
        is_deleted_chu_ky_nhay_ca_nhan: state.isDeleteChuKyNhay[i],
        is_deleted_thanh_vien_du_an: state.isDeleteChuKyThanhVien[i],
        su_dung_eoffice: state.suDungEoffice[i],
        hien_thi_chat: state.hienThiChat[i],
      }
      dsChucDanh.push(chucDanh)
    }
    await this.setState({ dsChucDanh, error: {}, isLoading: false })

    const error = {}
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (this.state.email.trim().length > 0 && !re.test(this.state.email)) {
      error.validationEmail = MESSAGE.TEXT_MESSAGE_TYPE_EMAIL
    }

    if (this.state.email.trim().length === 0) {
      error.validationEmail = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenDangNhap.trim().length === 0) {
      error.tenDangNhap = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.password.trim().length === 0 && !this.props.staff) {
      error.password = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenNhanVien.trim().length === 0) {
      error.tenNhanVien = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    // if (this.state.soDienThoai.trim().length === 0) {
    //   error.soDienThoai = MESSAGE.TEXT_MESSAGE_DEFAULT;
    // }

    if (this.state.donViId && this.state.donViId.indexOf(undefined) > -1) {
      error.donViId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.phongBanId &&
      this.state.phongBanId.indexOf(undefined) > -1
    ) {
      error.phongBanId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.chucVuId && this.state.chucVuId.indexOf(undefined) > -1) {
      error.chucVuId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.loaiQuyenId &&
      this.state.loaiQuyenId.indexOf(undefined) > -1
    ) {
      error.loaiQuyenId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.chucVuId && this.state.chucVuId.indexOf(ValueEmpty) > -1) {
      error.chucVuId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.ngaySinh) {
      error.ngaySinh = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.loaiQuyenId &&
      this.state.loaiQuyenId.indexOf(ValueEmpty) > -1
    ) {
      error.loaiQuyenId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.DANGER,
      })
    }

    this.setState({ isLoading: true })
    if (this.state.avatar.fileRequest) {
      await this.props.actions
        .avatarUpload(this.state.avatar.fileRequest, this.state.tenDangNhap)
        .then(res => {
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length !== 0
          ) {
            let { avatar } = this.state
            avatar.url = res.payload.data.result[0].url
            this.setState({ avatar }, () =>
              this.props.actions.clearCacheAvatar(this.state.avatar.url)
            )
          } else {
            this.setState({ isLoading: false })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.MESSAGE_UPLOAD_AVATAR_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
      if (!this.state.isLoading) return
    }

    if (this.state.profile.file) {
      await this.props.actions
        .profileFileUpload(this.state.profile.file)
        .then(res => {
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length !== 0
          ) {
            let { profile } = this.state
            profile.url = res.payload.data.result[0].url
            this.setState({ profile })
          } else {
            this.setState({ isLoading: false })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.MESSAGE_UPLOAD_PROFILE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
      if (!this.state.isLoading) return
    }
    if (this.props.staff) {
      this.props.actions
        .updateStaffItem({
          url_file_dinh_kem: this.state.profile.url
            ? this.state.profile.url
            : null,
          ten_file_dinh_kem: this.state.profile.name
            ? this.state.profile.name
            : null,
          kich_thuoc_file_dinh_kem: this.state.profile.size
            ? this.state.profile.size
            : 0,
          avatar: this.state.avatar.url ? this.state.avatar.url : null,
          nhan_vien_id: this.state.nhanVienId,
          username: this.state.tenDangNhap,
          password: this.state.password,
          ma_nhan_vien: this.state.maNhanVien,
          ten_nhan_vien: this.state.tenNhanVien,
          so_dien_thoai: this.state.soDienThoai,
          so_dien_thoai_khac: this.state.soDienThoaiKhac,
          so_dien_thoai_ky_so: this.state.soDienThoaiKySo,
          so_dien_thoai_ky_so_vnpt: this.state.soDienThoaiKySoVnpt,
          su_dung_usb_token: this.state.suDungUsbToken,
          email: this.state.email,
          ds_chuc_danh: this.state.dsChucDanh,
          ngay_sinh: moment(this.state.ngaySinh)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format(),
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length === 0
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length > 0
          ) {
            let data = []
            this.state.phongBan.forEach(items => {
              res.payload.data.result.forEach(item => {
                let value = items.find(value => {
                  return value.phongBanId === item && value.maPhongBan
                })
                if (value) {
                  data.push(` ${value.maPhongBan}`)
                }
              })
            })
            this.setState({ isLoading: false })
            return this.props.actions.commonAddToasterMessage({
              message: `Anh/Chị chưa hoàn thành CV cá nhân tại phòng ban ${_.split(
                _.uniq(data),
                ','
              )}`,
              intent: Intent.DANGER,
            })
          } else if (
            res.payload &&
            res.payload.data &&
            res.payload.data.code &&
            res.payload.data.code === 409
          ) {
            error.tenDangNhap = MESSAGE.TEXT_MESSAGE_INPUT_DUPLICATE
            this.setState({ error, isLoading: true })
            if (Object.keys(error).length !== 0) {
              this.setState({ isLoading: false })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TOATS_MESSAGE_WARNING,
                intent: Intent.DANGER,
              })
            }
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.props.actions
        .postAddStaff({
          url_file_dinh_kem: this.state.profile.url,
          ten_file_dinh_kem: this.state.profile.name,
          kich_thuoc_file_dinh_kem: this.state.profile.size
            ? this.state.profile.size
            : 0,
          avatar: this.state.avatar.url,
          username: this.state.tenDangNhap,
          password: this.state.password,
          ma_nhan_vien: this.state.maNhanVien,
          ten_nhan_vien: this.state.tenNhanVien,
          so_dien_thoai: this.state.soDienThoai,
          so_dien_thoai_khac: this.state.soDienThoaiKhac,
          so_dien_thoai_ky_so: this.state.soDienThoaiKySo,
          so_dien_thoai_ky_so_vnpt: this.state.soDienThoaiKySoVnpt,
          su_dung_usb_token: this.state.suDungUsbToken,
          email: this.state.email,
          ds_chuc_danh: this.state.dsChucDanh,
          ngay_sinh: moment(this.state.ngaySinh)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format(),
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else if (
            res.payload &&
            res.payload.data &&
            res.payload.data.code &&
            res.payload.data.code === 409
          ) {
            error.tenDangNhap = MESSAGE.TEXT_MESSAGE_INPUT_DUPLICATE
            this.setState({ error, isLoading: true })
            if (Object.keys(error).length !== 0) {
              this.setState({ isLoading: false })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TOATS_MESSAGE_WARNING,
                intent: Intent.DANGER,
              })
            }
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  handleChangeSelect(type, i, e) {
    if (type === 'Department') {
      if (e !== undefined) {
        const state = this.state
        state.phongBanId[i] = e
        this.setState(state)
      }
    }

    if (type === 'Unit') {
      if (e !== undefined) {
        const state = this.state
        state.donViId[i] = e
        this.setState({ state, isData: false }, () => {
          this.props.actions
            .getCategoryDepartmentMultiLevel(this.state.donViId[i])
            .then(res => {
              if (res.error) {
                return
              } else if (res.payload && res.payload.data.result) {
                const data = res.payload.data.result
                const { phongBan, phongBanId } = this.state

                phongBan[i] = data
                phongBanId[i] = data.length > 0 ? data[0].phongBanId : []
                this.setState({
                  phongBan,
                  phongBanId,
                })
              }
            })
          this.props.actions
            .commonFetchPosition(this.state.donViId[i])
            .then(res => {
              if (res.error) {
                return
              } else if (res.payload && res.payload.data.result.items) {
                const data = res.payload.data.result.items
                const { chucVu, chucVuId } = this.state

                chucVu[i] = data
                chucVuId[i] = data.length > 0 ? data[0].chucVuId : []
                this.setState({
                  chucVu,
                  chucVuId,
                })
              }
            })
          this.props.actions
            .getCommonFetchRole(this.state.donViId[i], 1)
            .then(res => {
              if (res.error) {
                return
              } else if (res.payload && res.payload.data.result.items) {
                const data = res.payload.data.result.items
                const { loaiQuyen, loaiQuyenId } = this.state

                loaiQuyen[i] = data
                loaiQuyenId[i] = data.length > 0 ? data[0].doiTuongId : []
                this.setState({
                  loaiQuyen,
                  loaiQuyenId,
                })
              }
            })
        })
      }
    }
  }

  toggleChoseFile = () => {
    this.setState({ isOpenDialogChoseFile: !this.state.isOpenDialogChoseFile })
  }

  uploadFromLocal = () => {
    document.getElementById('input-avatar').click()
    this.toggleChoseFile()
  }

  render() {
    let backgroundAvatar = this.state.avatar.url
      ? `${IMAGE_URL}${this.state.avatar.url}?preventCache=${this.state.random}`
      : avatarDataImage
    if (this.state.avatar.preview && this.state.avatar.preview.img) {
      backgroundAvatar = this.state.avatar.preview.img
    }
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock has-uline">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tendangnhap"
                      >
                        Hình đại diện
                      </label>
                      <div className="form-item file-avatar-box pt-form-content mr15">
                        <input
                          id="input-avatar"
                          type="file"
                          name={'avatar'}
                          onChange={this.handleUploadAvatar}
                          className="pt-input input-hidden"
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          onClick={event => {
                            event.target.value = null
                          }}
                          accept=".jpg, .jpeg, .png"
                        />
                        <div
                          className="box-cropped-image"
                          style={{
                            backgroundImage: `url(${backgroundAvatar})`,
                          }}
                        >
                          <div
                            ref={el => (this.dropdown = el)}
                            className="hover-upload"
                            style={
                              !this.state.isHideBtnGroup ? { opacity: 1 } : null
                            }
                          >
                            <span className="pt-icon icon-upload-file mb15"></span>
                            <p>Thay đổi hình ảnh đại diện</p>
                          </div>
                          <div
                            className={classnames('upload-group-btn', {
                              'd-none': this.state.isHideBtnGroup,
                            })}
                          >
                            <Button
                              type="button"
                              className="btn-choose-file"
                              onClick={this.uploadFromLocal}
                            >
                              Tải ảnh lên
                            </Button>
                            {(this.state.avatar.url ||
                              this.state.avatar.preview.img) && (
                              <Button
                                type="button"
                                className="btn-delete-file"
                                onClick={this.handleRemoveAvatar}
                              >
                                Xóa hình đại diện
                              </Button>
                            )}
                          </div>
                        </div>
                        {this.state.avatar.error !== undefined && (
                          <div className="pt-form-helper-text">
                            <div className="pt-form-ontent pt-form-helper-text">
                              {this.state.avatar.error}
                            </div>
                          </div>
                        )}
                        <div className="box-dialog-image">
                          <Dialog
                            iconName="inbox"
                            isOpen={this.state.isOpenCropImage}
                            onClose={this.toggleDialog}
                            title="Chỉnh sửa ảnh đại diện"
                          >
                            <div className="pt-dialog-body box-dialog-image-body flex-center">
                              <AvatarEditor
                                ref={this.setEditorRef}
                                scale={parseFloat(this.state.avatar.scale)}
                                width={this.state.avatar.width}
                                height={this.state.avatar.height}
                                position={this.state.avatar.position}
                                disableDrop={true}
                                onPositionChange={
                                  this.handlePositionChangeAvatar
                                }
                                onSave={this.handleSaveAvatar}
                                image={this.state.avatar.image}
                                style={{
                                  margin: '0 auto',
                                  border: 0,
                                  background: 'transparent',
                                }}
                                border={20}
                                color={[10, 30, 50, 0.4]}
                              />
                              <div className="box-custom-avatar">
                                <input
                                  name="scale"
                                  type="range"
                                  className="range"
                                  onChange={this.handleScaleAvatar}
                                  min="1"
                                  max="2"
                                  step="0.01"
                                  defaultValue="1"
                                />
                              </div>
                            </div>
                            <div className="pt-dialog-footer">
                              <div className="pt-dialog-footer-actions">
                                <Button
                                  intent={Intent.PRIMARY}
                                  onClick={this.handleSaveAvatar}
                                  text="Sử dụng ảnh"
                                />
                              </div>
                            </div>
                          </Dialog>
                        </div>
                      </div>
                      {this.state.avatar &&
                        this.state.avatar.size &&
                        (this.state.avatar.size / 1024).toFixed(2) >
                          MAX_WARNING_UPLOAD_AVATAR_SIZE && (
                          <span className="warning-size-avatar">
                            Anh/chị nên sử dụng hình ảnh có dung lượng nhỏ hơn
                            200KB
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block">
              <div className="form-subblock has-uline">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tendangnhap"
                      >
                        Tên đăng nhập
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          autoFocus={true}
                          value={this.state.tenDangNhap}
                          name="tenDangNhap"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập tên đăng nhập"
                        />
                        {this.state.error.tenDangNhap !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenDangNhap}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tendangnhap"
                      >
                        Mật khẩu
                        {!this.props.staff && (
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        )}
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          autoFocus={true}
                          value={this.state.password}
                          name="password"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập mật khẩu"
                        />
                        {this.state.error.password !== undefined &&
                          !this.props.staff && (
                            <div className="pt-form-content pt-form-helper-text">
                              {this.state.error.password}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {this.props.staff && (
                    <div className="form-cols form-col5">
                      <div className="form-group-item pull-right">
                        <Button
                          className="btn-add-more"
                          onClick={this.props.onResetSignNumberToken}
                          loading={this.props.resetLoading}
                        >
                          <span className="text-content">
                            Reset ký số token
                          </span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-subblock has-uline">
                <div className="form-row">
                  {/* <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label pt-label-inline" htmlFor="manhanvien">Mã nhân viên<span className="pt-text-muted required-input">*</span></label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.maNhanVien}
                          name="maNhanVien"
                          onChange={this.handleChange}
                          className={
                            classnames('pt-input', {
                              'loading-input': Object.values(this.state.isLoadingData).find(item => item === true) !== undefined
                          })}
                          disabled={Object.values(this.state.isLoadingData).find(item => item === true) !== undefined}
                          placeholder="Nhập mã nhân viên"
                        />
                        { this.state.error.maNhanVien !== undefined &&
                          <div className="pt-form-content pt-form-helper-text">{this.state.error.maNhanVien}</div>
                        }
                      </div>
                    </div>
                  </div> */}
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tennhanvien"
                      >
                        Tên nhân viên
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenNhanVien}
                          name="tenNhanVien"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập tên nhân viên"
                        />
                        {this.state.error.tenNhanVien !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenNhanVien}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tennhanvien"
                      >
                        Ngày sinh
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <DatePicker
                          selected={this.state.ngaySinh}
                          onChange={this.handleChangeDate}
                          format="DD/MM/YYYY"
                          maxDate={moment()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Chọn ngày..."
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          opperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                        {this.state.error.ngaySinh !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.ngaySinh}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="email"
                      >
                        Email
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="email"
                          value={this.state.email}
                          name="email"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập địa chỉ Email"
                        />
                        {this.state.error.validationEmail !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.validationEmail}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="chukycodau"
                      >
                        Thông tin đính kèm
                      </label>
                      <div
                        className={classnames(
                          'form-item file-box input-form-inline pt-form-content',
                          {
                            'upload-have-close': this.state.profile.name,
                          }
                        )}
                      >
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name="profile"
                            onChange={this.handleFileProfile}
                            onClick={event => {
                              event.target.value = null
                            }}
                            className="pt-input"
                            disabled={
                              this.state.profile.isLoadingFile ||
                              this.state.profile.name
                            }
                          />
                          {!this.state.profile.name && (
                            <span className="pt-file-upload-input">
                              Chọn file đính kèm
                            </span>
                          )}
                          {this.state.profile.name && (
                            <div className="profile-file-info">
                              <div className="content">
                                <a
                                  className="file-name mr10"
                                  href={`${FILE_URL}${this.state.profile.url}`}
                                  download
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={
                                    !this.state.profile.url
                                      ? { pointerEvents: 'none', color: '#000' }
                                      : null
                                  }
                                >
                                  <span className="name">
                                    {this.state.profile.name.slice(
                                      0,
                                      this.state.profile.name.lastIndexOf('.')
                                    )}
                                  </span>
                                  <span className="extention">
                                    .
                                    {Tool.Utils.getExtensionFile(
                                      this.state.profile.name
                                    )}
                                  </span>
                                </a>
                                <span className="file-size">
                                  {Tool.Utils.getFileSize(
                                    this.state.profile.size
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </label>
                        {this.state.profile.name && !this.state.isLoading && (
                          <span
                            onClick={this.clearProfile}
                            className="icon-bc icon-close profile-close"
                          ></span>
                        )}
                      </div>
                    </div>
                    <div className="pt-form-group pt-intent-danger">
                      <div className="pt-form-helper-text">
                        {this.state.profile.error && (
                          <div className="pt-form-helper-text-content">
                            {this.state.profile.error}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sodienthoai"
                      >
                        Số điện thoại
                        {/*<span className="pt-text-muted required-input">*</span>*/}
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.soDienThoai}
                          name="soDienThoai"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập số điện thoại"
                        />
                        {/* this.state.error.soDienThoai !== undefined &&
                          <div className="pt-form-content pt-form-helper-text">{this.state.error.soDienThoai}</div>
                        */}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sodienthoaikhac"
                      >
                        Số điện thoại khác
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.soDienThoaiKhac}
                          name="soDienThoaiKhac"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập số điện thoại khác"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline d-flex between-xs pt0"
                        htmlFor="sodienthoaikyso"
                      >
                        <span style={{ maxWidth: '99px' }}>
                          Số điện thoại ký số
                        </span>
                        <img
                          style={viettelImgStyle}
                          src={
                            process.env.PUBLIC_URL + '/images/Logo_Viettel.png'
                          }
                          alt="Viettel Logo"
                        />
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.soDienThoaiKySo}
                          name="soDienThoaiKySo"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập số điện thoại ký số"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline d-flex between-xs pt0"
                        htmlFor="sodienthoaikyso"
                      >
                        <span style={{ maxWidth: '99px' }}>
                          Số điện thoại ký số
                        </span>
                        <img
                          style={VNPTImgStyle}
                          src={process.env.PUBLIC_URL + '/images/Logo_VNPT.png'}
                          alt="VNPT Logo"
                        />
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.soDienThoaiKySoVnpt}
                          name="soDienThoaiKySoVnpt"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập số điện thoại ký số"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isAdd.map((item, i) => (
              <div className="form-block" key={i}>
                {i === 1 && <div className="title-content"> Kiêm Nhiệm</div>}
                <div className="bg-grey">
                  {i > 0 && (
                    <span
                      className="pt-icon-close icon-close close-block-grey"
                      onClick={this.handleRemoveItem.bind(this, i)}
                    ></span>
                  )}
                  <div className="form-block">
                    <div className="form-subblock">
                      <div className="form-row">
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="donvi"
                            >
                              Đơn vị
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <SelectTree
                                className="select-tree"
                                name={`donViId-${i}`}
                                data={this.props.unit}
                                type="Unit"
                                permission={false}
                                value={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) === undefined
                                    ? this.state.donViId[i]
                                    : ''
                                }
                                handleChange={this.handleChangeSelect.bind(
                                  this,
                                  'Unit',
                                  i
                                )}
                                isLoading={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) !== undefined
                                }
                              />
                            </div>
                            {this.state.error.donViId !== undefined && (
                              <div className="pt-form-content pt-form-helper-text">
                                {this.state.error.donViId}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="phongban"
                            >
                              Phòng ban
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <SelectTree
                                className="select-tree"
                                name={`phongBanId-${i}`}
                                data={
                                  this.state.phongBan[i]
                                    ? this.state.phongBan[i]
                                    : []
                                }
                                type="Department"
                                permission={false}
                                value={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) === undefined
                                    ? this.state.phongBanId[i]
                                    : ''
                                }
                                handleChange={this.handleChangeSelect.bind(
                                  this,
                                  'Department',
                                  i
                                )}
                                isLoading={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) !== undefined
                                }
                              />
                            </div>
                            {this.state.error.phongBanId !== undefined && (
                              <div className="pt-form-content pt-form-helper-text">
                                {this.state.error.phongBanId}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="chucvu"
                            >
                              Chức vụ
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <div
                                className={classnames({
                                  'pt-select':
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) === undefined,
                                })}
                              >
                                <select
                                  name={`chucVuId-${i}`}
                                  onChange={this.handleChangeGroupInput.bind(
                                    this,
                                    i
                                  )}
                                  value={
                                    this.state.chucVuId.length > 0
                                      ? this.state.chucVuId[i]
                                      : ''
                                  }
                                  className={classnames('pt-input', {
                                    'loading-input':
                                      Object.values(
                                        this.state.isLoadingData
                                      ).find(item => item === true) !==
                                      undefined,
                                  })}
                                >
                                  {this.state.chucVu &&
                                    this.state.chucVu.length > 0 &&
                                    this.state.chucVu[i] &&
                                    this.state.chucVu[i].map((item, i) => (
                                      <option key={i} value={item.chucVuId}>
                                        {item.tenChucVu}
                                      </option>
                                    ))}
                                  {this.state.chucVuId[i] === ValueEmpty && (
                                    <option value={ValueEmpty}></option>
                                  )}
                                </select>
                              </div>
                              {this.state.error.chucVuId !== undefined && (
                                <div className="pt-form-content pt-form-helper-text">
                                  {this.state.error.chucVuId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="loaiquyen"
                            >
                              Vai trò
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <div
                                className={classnames({
                                  'pt-select':
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) === undefined,
                                })}
                              >
                                <select
                                  name={`loaiQuyenId-${i}`}
                                  onChange={this.handleChangeGroupInput.bind(
                                    this,
                                    i
                                  )}
                                  value={
                                    this.state.loaiQuyenId.length > 0
                                      ? this.state.loaiQuyenId[i]
                                      : ''
                                  }
                                  className={classnames('pt-input', {
                                    'loading-input':
                                      this.state.isLoadingData.loaiQuyenId,
                                  })}
                                >
                                  {this.state.loaiQuyen &&
                                    this.state.loaiQuyen.length > 0 &&
                                    this.state.loaiQuyen[i] &&
                                    this.state.loaiQuyen[i].map((item, i) => (
                                      <option key={i} value={item.doiTuongId}>
                                        {item.tenDoiTuong}
                                      </option>
                                    ))}
                                  {this.state.loaiQuyenId[i] === ValueEmpty && (
                                    <option value={ValueEmpty}></option>
                                  )}
                                </select>
                              </div>
                              {this.state.error.loaiQuyenId !== undefined && (
                                <div className="pt-form-content pt-form-helper-text">
                                  {this.state.error.loaiQuyenId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="chukycodau"
                            >
                              Chữ ký có dấu
                            </label>
                            <div
                              className={classnames(
                                'form-item file-box input-form-inline pt-form-content',
                                {
                                  'upload-have-close':
                                    this.state.errorChuKyCoDau[i] ===
                                      undefined && this.state.nameFileCoDau[i],
                                }
                              )}
                            >
                              <label className="pt-file-upload">
                                <input
                                  type="file"
                                  name={`chuKyCoDau-${i}`}
                                  onChange={this.handleFile.bind(
                                    this,
                                    'coDau',
                                    i
                                  )}
                                  className="pt-input"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onClick={event => {
                                    event.target.value = null
                                  }}
                                />
                                <span className="pt-file-upload-input">
                                  {this.state.chuKyCoDau[i] &&
                                  this.state.nameFileCoDau[i] &&
                                  this.state.errorChuKyCoDau[i] === undefined
                                    ? this.state.nameFileCoDau[i]
                                    : this.state.chuKyCoDau[i] &&
                                      !this.state.nameFileCoDau[i] &&
                                      this.state.errorChuKyCoDau[i] ===
                                        undefined
                                    ? 'Đã có file tải lên'
                                    : 'Chọn file tải lên'}
                                </span>
                              </label>
                              {this.state.chuKyCoDau.length > 0 &&
                                this.state.errorChuKyCoDau[i] === undefined &&
                                (this.state.nameFileCoDau[i] ||
                                  this.state.chuKyCoDau[i]) && (
                                  <span
                                    onClick={this.handleEventRemoveFileItemRight.bind(
                                      this,
                                      i
                                    )}
                                    className="icon-bc icon-close icon-remove-file"
                                  ></span>
                                )}
                            </div>
                          </div>
                          <div className="pt-form-group pt-intent-danger">
                            {this.state.chuKyCoDau.length > 0 &&
                              this.state.chuKyCoDau[i] &&
                              this.state.errorChuKyCoDau[i] === undefined && (
                                <div className="pt-form-signature">
                                  <img
                                    src={`data:image/jpeg;base64,${this.state.chuKyCoDau[i]}`}
                                    alt={
                                      this.state.nameFileCoDau[i]
                                        ? this.state.nameFileCoDau[i]
                                        : ''
                                    }
                                  />
                                </div>
                              )}
                            {this.state.sizeFileCoDau[i] !== undefined &&
                              this.state.errorChuKyCoDau[i] === undefined && (
                                <div className="pt-form-helper-text pt-form-helper-infomation">
                                  Kích thước file:
                                  <span className="size-file">
                                    {' '}
                                    {Tool.Utils.getFileSize(
                                      this.state.sizeFileCoDau[i]
                                    )}
                                  </span>
                                </div>
                              )}
                            <div className="pt-form-helper-text">
                              {this.state.errorChuKyCoDau[i] !== undefined && (
                                <div className="pt-form-helper-text-content">
                                  {this.state.errorChuKyCoDau[i]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="chukykhongdau"
                            >
                              Chữ ký không dấu
                            </label>
                            <div
                              className={classnames(
                                'form-item file-box input-form-inline pt-form-content',
                                {
                                  'upload-have-close':
                                    this.state.errorChuKyKhongDau[i] ===
                                      undefined &&
                                    this.state.nameFileKhongDau[i],
                                }
                              )}
                            >
                              <label className="pt-file-upload">
                                <input
                                  type="file"
                                  name={`chuKyKhongDau-${i}`}
                                  onChange={this.handleFile.bind(
                                    this,
                                    'khongDau',
                                    i
                                  )}
                                  className="pt-input"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onClick={event => {
                                    event.target.value = null
                                  }}
                                />
                                <span className="pt-file-upload-input">
                                  {this.state.chuKyKhongDau[i] &&
                                  this.state.nameFileKhongDau[i] &&
                                  this.state.errorChuKyKhongDau[i] === undefined
                                    ? this.state.nameFileKhongDau[i]
                                    : this.state.chuKyKhongDau[i] &&
                                      !this.state.nameFileKhongDau[i] &&
                                      this.state.errorChuKyKhongDau[i] ===
                                        undefined
                                    ? 'Đã có file tải lên'
                                    : 'Chọn file tải lên'}
                                </span>
                              </label>
                              {this.state.chuKyKhongDau.length > 0 &&
                                this.state.errorChuKyKhongDau[i] ===
                                  undefined &&
                                (this.state.nameFileKhongDau[i] ||
                                  this.state.chuKyKhongDau[i]) && (
                                  <span
                                    onClick={this.handleEventRemoveFileItemLeft.bind(
                                      this,
                                      i
                                    )}
                                    className="icon-bc icon-close icon-remove-file"
                                  ></span>
                                )}
                            </div>
                          </div>
                          <div className="pt-form-group pt-intent-danger">
                            {this.state.chuKyKhongDau.length > 0 &&
                              this.state.chuKyKhongDau[i] &&
                              this.state.errorChuKyKhongDau[i] ===
                                undefined && (
                                <div className="pt-form-signature">
                                  <img
                                    src={`data:image/jpeg;base64,${this.state.chuKyKhongDau[i]}`}
                                    alt={
                                      this.state.nameFileKhongDau[i]
                                        ? this.state.nameFileKhongDau[i]
                                        : ''
                                    }
                                  />
                                </div>
                              )}
                            {this.state.sizeFileKhongDau[i] !== undefined &&
                              this.state.errorChuKyKhongDau[i] ===
                                undefined && (
                                <div className="pt-form-helper-text pt-form-helper-infomation">
                                  Kích thước file:
                                  <span className="size-file">
                                    {' '}
                                    {Tool.Utils.getFileSize(
                                      this.state.sizeFileKhongDau[i]
                                    )}
                                  </span>
                                </div>
                              )}
                            <div className="pt-form-helper-text">
                              {this.state.errorChuKyKhongDau[i] !==
                                undefined && (
                                <div className="pt-form-helper-text-content">
                                  {this.state.errorChuKyKhongDau[i]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="chukynhay"
                            >
                              Chữ ký nháy
                            </label>
                            <div
                              className={classnames(
                                'form-item file-box input-form-inline pt-form-content',
                                {
                                  'upload-have-close':
                                    this.state.errorChuKyNhay[i] ===
                                      undefined &&
                                    this.state.nameFileChuKyNhay[i],
                                }
                              )}
                            >
                              <label className="pt-file-upload">
                                <input
                                  type="file"
                                  name={`chuKyNhay-${i}`}
                                  onChange={this.handleFile.bind(
                                    this,
                                    'nhay',
                                    i
                                  )}
                                  className="pt-input"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onClick={event => {
                                    event.target.value = null
                                  }}
                                />
                                <span className="pt-file-upload-input">
                                  {this.state.chuKyNhay[i] &&
                                  this.state.nameFileChuKyNhay[i] &&
                                  this.state.errorChuKyNhay[i] === undefined
                                    ? this.state.nameFileChuKyNhay[i]
                                    : this.state.chuKyNhay[i] &&
                                      !this.state.nameFileChuKyNhay[i] &&
                                      this.state.errorChuKyNhay[i] === undefined
                                    ? 'Đã có file tải lên'
                                    : 'Chọn file tải lên'}
                                </span>
                              </label>
                              {this.state.chuKyNhay.length > 0 &&
                                this.state.errorChuKyNhay[i] === undefined &&
                                (this.state.nameFileChuKyNhay[i] ||
                                  this.state.chuKyNhay[i]) && (
                                  <span
                                    onClick={this.handleEventRemoveFileChuKyNhay.bind(
                                      this,
                                      i
                                    )}
                                    className="icon-bc icon-close icon-remove-file"
                                  ></span>
                                )}
                            </div>
                          </div>
                          <div className="pt-form-group pt-intent-danger">
                            {this.state.chuKyNhay.length > 0 &&
                              this.state.chuKyNhay[i] &&
                              this.state.errorChuKyNhay[i] === undefined && (
                                <div className="pt-form-signature">
                                  <img
                                    src={`data:image/jpeg;base64,${this.state.chuKyNhay[i]}`}
                                    alt={
                                      this.state.nameFileChuKyNhay[i]
                                        ? this.state.nameFileChuKyNhay[i]
                                        : ''
                                    }
                                  />
                                </div>
                              )}
                            {this.state.sizeFileChuKyNhay[i] !== undefined &&
                              this.state.errorChuKyNhay[i] === undefined && (
                                <div className="pt-form-helper-text pt-form-helper-infomation">
                                  Kích thước file:
                                  <span className="size-file">
                                    {' '}
                                    {Tool.Utils.getFileSize(
                                      this.state.sizeFileChuKyNhay[i]
                                    )}
                                  </span>
                                </div>
                              )}
                            <div className="pt-form-helper-text">
                              {this.state.errorChuKyNhay[i] !== undefined && (
                                <div className="pt-form-helper-text-content">
                                  {this.state.errorChuKyNhay[i]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="chukynhay"
                            >
                              Chữ ký khác
                            </label>
                            <div
                              className={classnames(
                                'form-item file-box input-form-inline pt-form-content',
                                {
                                  'upload-have-close':
                                    this.state.errorChuKyThanhVien[i] ===
                                      undefined &&
                                    this.state.nameFileChuKyThanhVien[i],
                                }
                              )}
                            >
                              <label className="pt-file-upload">
                                <input
                                  type="file"
                                  name={`chuKyThanhVien-${i}`}
                                  onChange={this.handleFile.bind(
                                    this,
                                    'thanhvien',
                                    i
                                  )}
                                  className="pt-input"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onClick={event => {
                                    event.target.value = null
                                  }}
                                />
                                <span className="pt-file-upload-input">
                                  {this.state.chuKyThanhVien[i] &&
                                  this.state.nameFileChuKyThanhVien[i] &&
                                  this.state.errorChuKyThanhVien[i] ===
                                    undefined
                                    ? this.state.nameFileChuKyThanhVien[i]
                                    : this.state.chuKyThanhVien[i] &&
                                      !this.state.nameFileChuKyThanhVien[i] &&
                                      this.state.errorChuKyThanhVien[i] ===
                                        undefined
                                    ? 'Đã có file tải lên'
                                    : 'Chọn file tải lên'}
                                </span>
                              </label>
                              {this.state.chuKyThanhVien.length > 0 &&
                                this.state.errorChuKyThanhVien[i] ===
                                  undefined &&
                                (this.state.nameFileChuKyThanhVien[i] ||
                                  this.state.chuKyThanhVien[i]) && (
                                  <span
                                    onClick={this.handleEventRemoveFileChuKyThanhVien.bind(
                                      this,
                                      i
                                    )}
                                    className="icon-bc icon-close icon-remove-file"
                                  ></span>
                                )}
                            </div>
                          </div>
                          <div className="pt-form-group pt-intent-danger">
                            {this.state.chuKyThanhVien.length > 0 &&
                              this.state.chuKyThanhVien[i] &&
                              this.state.errorChuKyThanhVien[i] ===
                                undefined && (
                                <div className="pt-form-signature">
                                  <img
                                    src={`data:image/jpeg;base64,${this.state.chuKyThanhVien[i]}`}
                                    alt={
                                      this.state.nameFileChuKyThanhVien[i]
                                        ? this.state.nameFileChuKyThanhVien[i]
                                        : ''
                                    }
                                  />
                                </div>
                              )}
                            {this.state.sizeFileChuKyThanhVien[i] !==
                              undefined &&
                              this.state.errorChuKyThanhVien[i] ===
                                undefined && (
                                <div className="pt-form-helper-text pt-form-helper-infomation">
                                  Kích thước file:
                                  <span className="size-file">
                                    {' '}
                                    {Tool.Utils.getFileSize(
                                      this.state.sizeFileChuKyThanhVien[i]
                                    )}
                                  </span>
                                </div>
                              )}
                            <div className="pt-form-helper-text">
                              {this.state.errorChuKyThanhVien[i] !==
                                undefined && (
                                <div className="pt-form-helper-text-content">
                                  {this.state.errorChuKyThanhVien[i]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {i === 0 && (
                        <div className="form-row">
                          <div className="form-cols form-col3">
                            <div className="form-group-item pt-form-group pt-intent-danger checkbox-container-users">
                              <div className="input-form-inline checkbox-users">
                                <Checkbox
                                  name="suDungEoffice"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onChange={this.handleCheckbox.bind(this, i)}
                                  checked={this.state.suDungEoffice[i]}
                                />
                              </div>
                              <label
                                className="pt-label pt-label-inline lable-users"
                                htmlFor="chukycodau"
                              >
                                Sử dụng eOffice
                              </label>
                            </div>
                          </div>
                          <div className="form-cols form-col3">
                            <div className="form-group-item pt-form-group pt-intent-danger checkbox-container-users">
                              <div className="input-form-inline checkbox-users">
                                <Checkbox
                                  name="hienThiChat"
                                  disabled={true}
                                  onChange={this.handleCheckbox.bind(this, i)}
                                  checked={this.state.hienThiChat[i]}
                                />
                              </div>
                              <label
                                className="pt-label pt-label-inline lable-users"
                                htmlFor="chukycodau"
                              >
                                Hiển thị danh bạ Chat
                              </label>
                            </div>
                          </div>
                          <div className="form-cols form-col3">
                            <div className="form-group-item pt-form-group pt-intent-danger checkbox-container-users">
                              <div className="input-form-inline checkbox-users">
                                <Checkbox
                                  name="quanLyTTL"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onChange={this.handleCheckbox.bind(this, i)}
                                  checked={this.state.quanLyTTL[i]}
                                />
                              </div>
                              <label
                                className="pt-label pt-label-inline lable-users"
                                htmlFor="chukycodau"
                              >
                                Quản lý TTL phòng ban
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {i > 0 && (
                        <div className="form-row">
                          <div className="form-cols form-col3">
                            <div className="form-group-item pt-form-group pt-intent-danger checkbox-container-users">
                              <div className="input-form-inline checkbox-users">
                                <Checkbox
                                  name="suDungEoffice"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onChange={this.handleCheckbox.bind(this, i)}
                                  checked={this.state.suDungEoffice[i]}
                                />
                              </div>
                              <label
                                className="pt-label pt-label-inline lable-users"
                                htmlFor="chukycodau"
                              >
                                Sử dụng eOffice
                              </label>
                            </div>
                          </div>
                          <div className="form-cols form-col3">
                            <div className="form-group-item pt-form-group pt-intent-danger checkbox-container-users">
                              <div className="input-form-inline checkbox-users">
                                <Checkbox
                                  name="hienThiChat"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onChange={this.handleCheckbox.bind(this, i)}
                                  checked={this.state.hienThiChat[i]}
                                />
                              </div>
                              <label
                                className="pt-label pt-label-inline lable-users"
                                htmlFor="chukycodau"
                              >
                                Hiển thị danh bạ Chat
                              </label>
                            </div>
                          </div>
                          <div className="form-cols form-col3">
                            <div className="form-group-item pt-form-group pt-intent-danger checkbox-container-users">
                              <div className="input-form-inline checkbox-users">
                                <Checkbox
                                  name="quanLyTTL"
                                  disabled={
                                    Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) !== undefined
                                  }
                                  onChange={this.handleCheckbox.bind(this, i)}
                                  checked={this.state.quanLyTTL[i]}
                                />
                              </div>
                              <label
                                className="pt-label pt-label-inline lable-users"
                                htmlFor="chukycodau"
                              >
                                Quản lý TTL phòng ban
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {this.state.inputList}
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-group-item">
                  <Button className="btn-add-more" onClick={this.onAddBtnClick}>
                    <span className="text-content">Thêm kiêm nhiệm</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-main-color"
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined || this.state.profile.isLoadingFile
                  }
                  loading={this.state.isLoading}
                  onClick={this.handleSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default UpdateDecentralizationUser
