import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  state = {
    isOpenPopup: false,
  }

  render() {
    const { item, number } = this.props
    return (
      <tr className="gerenal-list-utilities">
        <td className="gerenal-list-utilities">
          {number > 9 ? number : '0' + number}
        </td>
        <td className="gerenal-list-utilities">{item.soCongVan}</td>
        <td className="gerenal-list-utilities textarea-content">
          {item && item.trichYeu}
        </td>
        <td className="gerenal-list-utilities">
          {item.ngayChiDao && moment(item.ngayChiDao).format('DD/MM/YYYY')}
        </td>
        <td className="gerenal-list-utilities">
          {item.hanGiaiQuyet && moment(item.hanGiaiQuyet).format('DD/MM/YYYY')}
        </td>
        <td className="gerenal-list-utilities">
          {item.ngayGiaoViec && moment(item.ngayGiaoViec).format('DD/MM/YYYY')}
        </td>
        <td className="gerenal-list-utilities">
          {item.ngayHoanThanh &&
            moment(item.ngayHoanThanh).format('DD/MM/YYYY')}
        </td>
        <td className="gerenal-list-utilities">{item.tinhTrang}</td>
        <td className="gerenal-list-utilities">{item.nguoiChuTri}</td>
      </tr>
    )
  }
}

export default ListItem
