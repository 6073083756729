import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Checkbox, Button, Intent, Collapse } from '@blueprintjs/core'
import { Redirect } from 'react-router'
import {
  CheckboxGroup,
  PopupConfirm,
  DepartmentPerform,
  CheckBoxTree,
  LoadingItem,
  NotFound,
} from '../common/'
import 'moment/locale/vi'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import { MAX_SIZE_FILE_SUBMISSION } from '../../constants/Api'
import * as Tool from '../../helpers'
import { Scrollbars } from 'react-custom-scrollbars'
import DatePicker from 'react-datepicker'
import _ from 'lodash'

class InputForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    objects: PropTypes.array.isRequired,
    securities: PropTypes.array.isRequired,
    priorities: PropTypes.array.isRequired,
    saveCodes: PropTypes.array.isRequired,
    receiveUnits: PropTypes.array.isRequired,
    attachedUnits: PropTypes.array.isRequired,
    otherUnits: PropTypes.array,
    connectedUnit: PropTypes.array,
    connectedUnitEVN: PropTypes.array,
    departments: PropTypes.array.isRequired,
    isShow: PropTypes.bool.isRequired,
    soLuuId: PropTypes.string,
    signNumberId: PropTypes.string,
    digitalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    signUserList: PropTypes.array.isRequired,
    checkCongVanKySo: PropTypes.bool,
    leaderDepartments: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.handleClickCancel = this.handleClickCancel.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleGetValueDepartment = this.handleGetValueDepartment.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    // Change checkbox group to grid
    // this.handleCompositiveDepartmentSelection = this.handleCompositiveDepartmentSelection.bind(this);
    // this.handleCheckAllCompositive = this.handleCheckAllCompositive.bind(this);
    // this.handleCheckAllOnlySee = this.handleCheckAllOnlySee.bind(this);
    // this.handleOnlySeeDepartmentSelection = this.handleOnlySeeDepartmentSelection.bind(this);
    this.getUploadProgress = this.getUploadProgress.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handlePostDocument = this.handlePostDocument.bind(this)
    this.handleClickPopupConfirmDocumentNumber =
      this.handleClickPopupConfirmDocumentNumber.bind(this)
    this.handleClickArrow = this.handleClickArrow.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleChangeOtherUnits = this.handleChangeOtherUnits.bind(this)
    this.handleChangeConnectedUnit = this.handleChangeConnectedUnit.bind(this)
    this.handleChangeConnectedUnitEVN =
      this.handleChangeConnectedUnitEVN.bind(this)
    this.handleClickPopupResendEVN = this.handleClickPopupResendEVN.bind(this)
    this.handleResendEVN = this.handleResendEVN.bind(this)
  }

  state = {
    isOpenPopup: false,
    isOpenPopupConfirmDocumentNumber: false,
    isOpenPopupConfirmResendEVN: false,
    isRedirect: false,
    redirectTo: null,
    error: {},
    congVanDiId: null,
    vanBanKySoId: '',
    vanBanSoHoaId: '',
    soCongVanPhanDau: '',
    soCongVanPhanSau: '',
    ngayCongVan: moment(),
    doiTuongCongVanId: '',
    soLuuId: this.props.soLuuId || '',
    doKhanId: '',
    doBaoMatId: '',
    phongBanLapId: '',
    trichYeu: '',
    fileNoiDung: null,
    fileUpload: null,
    fileName: '',
    nguoiKyId: '',
    dsPhoiHop: [],
    dsXemDeBiet: [],
    dsDonViNhan: [],
    dsDonViTrucThuoc: [],
    dsDonViKhac: [],
    dsDonViLienThong: [],
    dsDonViLienThongLoc: this.props.connectedUnit || [],
    dsDonViLienThongEVN: [],
    dsDonViLienThongLocEVN: this.props.connectedUnitEVN || [],
    noiNhan: '',
    // docHanChe: false,
    guiEvn: false,
    departmentSelections: [],
    receiveUnitSelection: [],
    attachedUnitSelection: [],
    // isCheckedAllCompositive: false,
    // isCheckedAllOnlySee: false,
    isCheckAllReceiveUnits: false,
    isCheckedAllAttachedUnits: false,
    fileDefault: {
      file_id: '9a217faa-b823-4290-bce6-779ee83f2c2d',
      ten_file: 'SoHoaChuaCoFileNoiDung.docx',
      url: '/AttachmentFile/20171005083456899_9a217faa-b823-4290-bce6-779ee83f2c2d.docx',
      kieu_file:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      kich_thuoc: 4234,
    },
    isLoading: false,
    isLoadingResendEVN: false,
    sizeFile: null,
    //isHideButton: false,
    progress: 0,
    dateToToString: moment().format('DD/MM/YYYY'),
    isOpen: false,
    isOpenConnectedUnit: false,
    isOpenConnectedUnitEVN: true,
    receiveUnitSelectionIds: [],
    attachedUnitSelectionIds: [],
    sendDocumentIdForResendEVN: null,
    isCheckCongVanKySo: this.props.checkCongVanKySo || true,
    checkTrinhLanhDao: false,
    trinhLanhDaoId: '',
    timDonViLienThong: '',
    timDonViLienThongEVN: '',
    filesThamKhaoTuKySo: _.get(this.props, 'filesThamKhaoTuKySo', []),
  }

  componentWillReceiveProps(props) {
    if (props.filesThamKhaoTuKySo && props.filesThamKhaoTuKySo.length !== 0) {
      this.setState({
        filesThamKhaoTuKySo: props.filesThamKhaoTuKySo,
      })
    }
  }

  handleClickPopupConfirmDocumentNumber = () => {
    let isLoading = this.state.isLoading
    if (this.state.isOpenPopupConfirmDocumentNumber) {
      isLoading = false
      this.props.onLoadSubmit && this.props.onLoadSubmit()
    }
    this.setState({
      isOpenPopupConfirmDocumentNumber:
        !this.state.isOpenPopupConfirmDocumentNumber,
      isLoading,
    })
  }

  handleClickPopupResendEVN = () => {
    if (this.state.isOpenPopupConfirmResendEVN) {
      this.setState({
        isOpenPopupConfirmResendEVN: !this.state.isOpenPopupConfirmResendEVN,
      })
      return this.props.onSubmit(null, null, true, this.state.soLuuId)
    }
    this.setState({
      isOpenPopupConfirmResendEVN: !this.state.isOpenPopupConfirmResendEVN,
    })
  }

  // Gui lai EVN
  handleResendEVN = () => {
    this.setState({ isLoadingResendEVN: true }, () => {
      this.props.actions
        .resendEVNSendDocument(this.state.sendDocumentIdForResendEVN)
        .then(res => {
          if (res && res.payload && res.payload && res.payload.status === 200) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.MESSAGE_SEND_EVN_SUCCESS,
              intent: Intent.SUCCESS,
            })
          }
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.MESSAGE_SEND_EVN_FAIL,
            intent: Intent.DANGER,
          })
        })
        .catch(e => {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.MESSAGE_SEND_EVN_FAIL,
            intent: Intent.DANGER,
          })
        })
        .then(() => {
          this.setState({
            isLoadingResendEVN: false,
            isOpenPopupConfirmResendEVN: false,
          })
        })
    })
    return this.props.onSubmit(null, null, true, this.state.soLuuId)
  }

  handlePostDocument = async isFromPopup => {
    let { isOpenPopupConfirmDocumentNumber } = this.state
    if (isFromPopup) {
      isOpenPopupConfirmDocumentNumber = false
    }
    await this.setState({
      isLoading: true,
      isOpenPopupConfirmDocumentNumber,
    })
    const error = this.state.error
    if (this.state.fileUpload) {
      await this.props.actions
        .fileUpload(this.state.fileUpload, this.getUploadProgress())
        .then(res => {
          if (res.error || !res.payload.data.result) {
            error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
            return this.setState({ error, isLoading: false })
          }

          let fileNoiDung = null
          if (
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          ) {
            fileNoiDung = {
              file_id: res.payload.data.result[0].id,
              ten_file: res.payload.data.result[0].originalName,
              url: res.payload.data.result[0].path,
              kieu_file: res.payload.data.result[0].type,
              kich_thuoc: res.payload.data.result[0].length,
            }
          }
          this.setState({ fileNoiDung, isLoading: false })
        })
    } else if (this.props.isUpdate === false && !this.state.fileNoiDung) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
      this.setState({ error, isLoading: false })
      return this.props.onSubmit(MESSAGE.TOATS_MESSAGE_WARNING, Intent.WARNING)
    }

    if (!this.state.fileNoiDung) {
      error.fileName = MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL
      this.setState({ error, isLoading: false })
      return this.props.onSubmit(
        MESSAGE.TOATS_MESSAGE_FAIL,
        Intent.DANGER,
        false,
        null
      )
    }

    let ngayCongVan = ''
    if (
      this.state.ngayCongVan &&
      this.state.ngayCongVan.toString().length > 0
    ) {
      ngayCongVan = moment(this.state.ngayCongVan)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dsDonViLienThongRequest = []
    if (this.state.dsDonViLienThong.length) {
      dsDonViLienThongRequest = this.state.dsDonViLienThong.map(item => {
        let itemArray = item.split(',')
        return {
          don_vi_id: itemArray[0],
          ma_don_vi: itemArray[1],
          ten_don_vi: itemArray[2],
        }
      })
    }

    let dsDonViLienThongEVNRequest = []
    if (this.state.dsDonViLienThongEVN.length) {
      dsDonViLienThongEVNRequest = this.state.dsDonViLienThongEVN.map(item => {
        let itemArray = item.split(',')
        return {
          don_vi_id: itemArray[0],
          ma_don_vi: itemArray[1],
          ten_don_vi: itemArray[2],
        }
      })
    }
    this.setState({ isLoading: true })
    let dataRequest = {
      van_ban_ky_so_id: this.state.vanBanKySoId,
      van_ban_so_hoa_id: this.state.vanBanSoHoaId,
      so_cong_van:
        this.state.soCongVanPhanDau + '/' + this.state.soCongVanPhanSau,
      ngay_cong_van: ngayCongVan,
      doi_tuong_cong_van_id: this.state.doiTuongCongVanId
        ? this.state.doiTuongCongVanId
        : null,
      so_luu_id: this.state.soLuuId,
      do_khan_id: this.state.doKhanId,
      do_bao_mat_id: this.state.doBaoMatId.length
        ? this.state.doBaoMatId
        : null,
      phong_ban_lap_id: this.state.phongBanLapId,
      trich_yeu: this.state.trichYeu,
      file_noi_dung: this.state.fileNoiDung,
      nguoi_ky_id: this.state.nguoiKyId,
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      ds_don_vi_nhan: this.state.dsDonViNhan,
      ds_don_vi_truc_thuoc: this.state.dsDonViTrucThuoc,
      ds_don_vi_khac: this.state.dsDonViKhac,
      ds_don_vi_lien_thong: dsDonViLienThongRequest,
      ds_don_vi_lien_thong_evn: dsDonViLienThongEVNRequest,
      noi_nhan: this.state.noiNhan,
      // doc_han_che: this.state.docHanChe,
      gui_evn: this.state.guiEvn,
      phong_ban_trinh_id: this.state.trinhLanhDaoId,
    }

    if (!this.state.checkTrinhLanhDao) {
      dataRequest.phong_ban_trinh_id = ''
    }

    if (
      this.state.filesThamKhaoTuKySo &&
      this.state.filesThamKhaoTuKySo.length !== 0
    ) {
      let fileThamKhaoTuKySo = []
      this.state.filesThamKhaoTuKySo.map(elm => {
        return fileThamKhaoTuKySo.push(
          Tool.Utils.toDecamelizeKeys({
            fileId: elm.fileId,
            tenFile: elm.tenFile,
            url: elm.url,
            fileName: elm.fileName,
            kieuFile: elm.kieuFile,
            migrated: elm.migrated,
            kichThuoc: elm.kichThuoc,
          })
        )
      })

      dataRequest.ds_file_tham_khao = fileThamKhaoTuKySo
    }

    if (!this.props.isUpdate) {
      this.props.actions
        .sendDocumentAdd(dataRequest)
        .then(async res => {
          if (res.payload && res.payload.status === 200) {
            let lengthUnit = 0
            if (this.state.guiEvn) {
              lengthUnit++
            }
            if (this.state.dsDonViNhan) {
              lengthUnit += this.state.dsDonViNhan.length
            }
            if (this.state.dsDonViTrucThuoc) {
              lengthUnit += this.state.dsDonViTrucThuoc.length
            }
            if (this.state.dsDonViKhac) {
              lengthUnit += this.state.dsDonViKhac.length
            }
            if (this.state.dsDonViLienThong) {
              lengthUnit += this.state.dsDonViLienThong.length
            }
            if (this.state.dsDonViLienThongEVN) {
              lengthUnit += this.state.dsDonViLienThongEVN.length
            }
            const message = `Đã gửi thành công ${lengthUnit}/${lengthUnit} đơn vị. Anh/ Chị có thể nhập văn bản tiếp theo`
            return this.props.onSubmit(
              message,
              Intent.SUCCESS,
              true,
              this.state.soLuuId
            )
          }
          // gui EVN khong thanh cong
          if (
            res.payload &&
            res.payload.status === 202 &&
            res.payload.data &&
            res.payload.data.id &&
            res.payload.data.message === MESSAGE.MESSAGE_SEND_EVN_FAIL
          ) {
            await this.setState({
              sendDocumentIdForResendEVN: res.payload.data.id,
            })
            await this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            return this.handleClickPopupResendEVN()
          }

          this.setState({ isLoading: false })
          this.props.onSubmit(
            MESSAGE.TOATS_SEND_MAIL_FAIL,
            Intent.DANGER,
            false,
            null
          )
        })
        .catch(e => {
          this.setState({ isLoading: false })
          this.props.onSubmit(
            MESSAGE.TOATS_SEND_MAIL_FAIL,
            Intent.DANGER,
            false,
            null
          )
        })
    } else {
      dataRequest = {
        ...dataRequest,
        cong_van_di_id: this.state.congVanDiId,
      }
      this.props.actions
        .sendDocumentUpdate(this.state.congVanDiId, dataRequest)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            return this.props.onSubmit(
              MESSAGE.TOATS_MESSAGE_SUCCESS,
              Intent.SUCCESS,
              true,
              this.state.soLuuId
            )
          }
          this.setState({ isLoading: false })
          this.props.onSubmit(
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        })
    }
  }

  getUploadProgress = () => progress => {
    this.setState({
      progress: Math.floor((progress.loaded * 100) / progress.total),
    })
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  handleClickCancel = () => {
    this.setState({
      isRedirect: true,
      redirectTo: '/cong-van/cong-van-di/danh-sach',
    })
  }

  handleChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }

    const name = e.target.name
    let value = e.target.value
    let state = this.state
    delete state.error[name]

    if (name === 'soCongVanPhanDau' || name === 'soCongVanPhanDau') {
      delete state.error.soCongVan
    }

    if (name === 'soCongVanPhanDau' || name === 'soCongVanPhanSau') {
      delete state.error.soCongVan
    }

    if (name === 'phongBanLapId') {
      state.dsPhoiHop = state.dsPhoiHop.filter(item => item !== value)
      state.dsXemDeBiet = state.dsXemDeBiet.filter(item => item !== value)
    }
    state[e.target.name] = value
    this.setState(state)
  }

  handleChangeTrinhLanhDao = e => {
    this.setState(
      {
        checkTrinhLanhDao: e.target.checked,
      },
      () => {
        if (this.state.checkTrinhLanhDao) {
          this.setState({
            dsPhoiHop: [],
            dsXemDeBiet: [],
          })
        }
      }
    )
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (this.props.sendDocument && this.props.sendDocument.ngayCongVan) {
      oldValue = moment(this.props.sendDocument.ngayCongVan)
      oldValueToString = moment(this.props.sendDocument.ngayCongVan).format(
        'DD/MM/YYYY'
      )
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleFileChange = e => {
    const error = this.state.error
    delete error.fileName

    const fileName = e.target.value
    const extension =
      fileName &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
    let sizeFile = e.target.files && e.target.files[0] && e.target.files[0].size

    if (!fileName.trim().length && this.state.fileName.length === 0) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (extension && extension !== 'pdf') {
      error.fileName = MESSAGE.TEXT_TYPE_FILE_UPLOAD_SIGNNUMBER
    } else if (sizeFile > MAX_SIZE_FILE_SUBMISSION) {
      error.fileName = MESSAGE.TEXT_MAXIMUM_SIZE_FILE_SUBMISSION
    }

    this.setState({ error })

    if (!error.fileName) {
      let reader = new FileReader()
      let file = e.target.files[0]

      reader.onloadend = () => {
        this.setState({
          fileUpload: file,
          fileName: file.name,
          fileNoiDung: null,
          sizeFile,
          isCheckCongVanKySo: false,
        })
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }
  // Change checkbox group to grid
  // handleCheckAllCompositive = () => {
  //   this.setState({isCheckedAllCompositive: !this.state.isCheckedAllCompositive});
  //   let dsPhoiHop = this.state.dsPhoiHop;
  //   if (!this.state.isCheckedAllCompositive) {
  //     this.state.departmentSelections.forEach(item => {
  //       if (dsPhoiHop.indexOf(item.value) === -1 && this.state.dsXemDeBiet.indexOf(item.value) === -1 && this.state.phongBanLapId !== item.value) {
  //         dsPhoiHop.push(item.value);
  //       }
  //     });
  //   } else {
  //     dsPhoiHop = [];
  //   }
  //   this.setState({dsPhoiHop});
  // }

  // handleCompositiveDepartmentSelection(e) {
  //   const newSelection = e.target.value;
  //   let dsPhoiHop;
  //   if (this.state.dsPhoiHop.length > 0 && this.state.dsPhoiHop.indexOf(newSelection) > -1) {
  //     dsPhoiHop = this.state.dsPhoiHop.filter(s => s !== newSelection);
  //   } else {
  //     dsPhoiHop = [...this.state.dsPhoiHop, newSelection];
  //   }
  //   this.setState({dsPhoiHop});
  // }

  // handleCheckAllOnlySee = () => {
  //   this.setState({isCheckedAllOnlySee: !this.state.isCheckedAllOnlySee});
  //   let dsXemDeBiet = this.state.dsXemDeBiet;
  //   if (!this.state.isCheckedAllOnlySee) {
  //     this.state.departmentSelections.forEach(item => {
  //       if (dsXemDeBiet.indexOf(item.value) === -1 && this.state.dsPhoiHop.indexOf(item.value) === -1 && this.state.phongBanLapId !== item.value) {
  //         dsXemDeBiet.push(item.value);
  //       }
  //     });
  //   } else {
  //     dsXemDeBiet = [];
  //   }
  //   this.setState({dsXemDeBiet});
  // }

  // handleOnlySeeDepartmentSelection = (e) => {
  //   const newSelection = e.target.value;
  //   let dsXemDeBiet;
  //   if (this.state.dsXemDeBiet.length > 0 && this.state.dsXemDeBiet.indexOf(newSelection) > -1) {
  //     dsXemDeBiet = this.state.dsXemDeBiet.filter(s => s !== newSelection);
  //   } else {
  //     dsXemDeBiet = [...this.state.dsXemDeBiet, newSelection];
  //   }
  //   this.setState({dsXemDeBiet: dsXemDeBiet});
  // }

  handleCheckAll = (
    nameCheckBoxAll,
    nameSelectedArray,
    nameOptionsArray,
    disabledArray = []
  ) => {
    let list = this.state[nameSelectedArray]
    let disabledList = []
    if (_.isArray(disabledArray)) {
      disabledList = _.uniq(disabledArray)
    }
    if (!this.state[nameCheckBoxAll]) {
      this.state[nameOptionsArray].forEach(item => {
        if (
          list.indexOf(item.value) === -1 &&
          disabledList.indexOf(item.value) === -1
        ) {
          list.push(item.value)
        }
      })
    } else {
      list = []
    }
    this.setState({
      [nameSelectedArray]: list,
      [nameCheckBoxAll]: !this.state[nameCheckBoxAll],
    })
  }

  handleCheck = (
    nameCheckBoxAll,
    nameSelectedArray,
    nameSelectionsArray,
    disabledArray = [],
    e
  ) => {
    const newSelection = e.target.value
    let newList = []
    let oldList = this.state[nameSelectedArray]
    let isCheckAll = false
    let selectionArray = this.state[nameSelectionsArray]

    // Aray options chỉ chứa id
    let selectionsList = []
    selectionArray &&
      selectionArray.forEach(item => {
        selectionsList.push(item.value)
      })

    // Array disabled chỉ nằm trong danh sách options
    let disabledList = []
    if (_.isArray(disabledArray)) {
      disabledList = _.uniq(disabledArray)
      disabledList = disabledList.filter(s => selectionsList.indexOf(s) > -1)
    }

    if (oldList && oldList.length > 0 && oldList.indexOf(newSelection) > -1) {
      newList = oldList.filter(s => s !== newSelection)
    } else {
      newList = [...oldList, newSelection]
    }
    if (
      selectionsList &&
      newList.length + disabledList.length === selectionsList.length
    ) {
      isCheckAll = true
    }
    this.setState({
      [nameSelectedArray]: newList,
      [nameCheckBoxAll]: isCheckAll,
    })
  }

  handleSubmit = async e => {
    this.setState({ error: {} })

    const error = {}
    let sizeFile = this.state.sizeFile

    if (
      !this.state.soCongVanPhanDau ||
      !this.state.soCongVanPhanSau ||
      this.state.soCongVanPhanDau.trim().length === 0 ||
      this.state.soCongVanPhanSau.trim().length === 0
    ) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.trichYeu || this.state.trichYeu.trim().length === 0) {
      error.trichYeu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.soLuuId || this.state.soLuuId.trim().length === 0) {
      error.soLuuId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.doKhanId || this.state.doKhanId.trim().length === 0) {
      error.doKhanId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.phongBanLapId ||
      this.state.phongBanLapId.trim().length === 0
    ) {
      error.phongBanLapId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.nguoiKyId || this.state.nguoiKyId.trim().length === 0) {
      error.nguoiKyId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.fileName || this.state.fileName.trim().length === 0) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
      sizeFile = null
    }

    let soCongVan =
      this.state.soCongVanPhanDau + '/' + this.state.soCongVanPhanSau
    if (soCongVan.trim().length > 50) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_MAX_LENGHT
      this.setState({ error })
      return this.props.onSubmit(
        MESSAGE.TEXT_MESSAGE_MAX_LENGHT_NUMBER_DOCUMENT,
        Intent.WARNING
      )
    }

    this.setState({ error, sizeFile, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      if (
        (!this.state.fileName || this.state.fileName.length < 0) &&
        this.props.isShow === true
      ) {
        return this.props.onSubmit(
          MESSAGE.TEXT_MESSAGE_REQUIRE_FILE,
          Intent.WARNING
        )
      }
      return this.props.onSubmit(MESSAGE.TOATS_MESSAGE_WARNING, Intent.WARNING)
    }
    this.setState({ isLoading: true })
    this.props.onLoadSubmit && this.props.onLoadSubmit()

    // TODO: Goi api kiem tra so cong van trung
    const isFromPopup = false
    // Kiem tra so cong van da ton tai chua
    let isValidDocumentNumber = false
    // Kiem tra goi api check so cong van thanh cong hay khong?
    let isCallValidDocumentNumberSuccess = true
    // Ngay cong van chuyen sang dinh dang YYYYMMDD
    const ngayCongVanToString =
      this.state.ngayCongVan &&
      moment(this.state.ngayCongVan)
        .format('YYYY-MM-DD')
        .toString()
        .replace(/-/g, '')
    await this.props.actions
      .sendDocumentCheckDocumentNumber(
        this.state.congVanDiId,
        this.state.soCongVanPhanDau,
        ngayCongVanToString
      )
      .then(res => {
        if (
          res.error ||
          !res ||
          !res.payload ||
          res.payload.data === undefined
        ) {
          this.setState({ isLoading: false })
          isCallValidDocumentNumberSuccess = false
          return (
            this.props.onSubmit &&
            this.props.onSubmit(
              MESSAGE.TOATS_SEND_MAIL_FAIL,
              Intent.DANGER,
              false,
              null
            )
          )
        }
        isValidDocumentNumber = res.payload.data
      })
      .catch(err => {
        this.setState({ isLoading: false })
        isCallValidDocumentNumberSuccess = false
        return (
          this.props.onSubmit &&
          this.props.onSubmit(
            MESSAGE.TOATS_SEND_MAIL_FAIL,
            Intent.DANGER,
            false,
            null
          )
        )
      })

    if (
      isValidDocumentNumber === true &&
      isCallValidDocumentNumberSuccess === true
    ) {
      return this.handlePostDocument(isFromPopup)
    }

    if (isCallValidDocumentNumberSuccess === false) {
      return
    }

    this.handleClickPopupConfirmDocumentNumber()
  }

  handleGetValueDepartment = data => {
    if (data && Object.keys(data).length > 0) {
      this.setState({
        dsPhoiHop: data.dsPhoiHop,
        dsXemDeBiet: data.dsXemDeBiet,
      })
    }
  }

  handleChangeOtherUnits = data => {
    if (data) {
      this.setState({ dsDonViKhac: data })
    }
  }

  handleChangeConnectedUnit = data => {
    if (data) {
      this.setState({ dsDonViLienThong: data })
    }
  }

  handleChangeConnectedUnitEVN = data => {
    if (data) {
      this.setState({ dsDonViLienThongEVN: data })
    }
  }

  componentDidMount() {
    this.setState({ error: {} })
    if (this.props.departments && this.props.departments.length) {
      const departmentSelections = []
      this.props.departments.forEach((item, i) => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })

      // TODO: Thay đổi: Không chọn option đầu tiên làm nữa định
      this.setState({
        // phongBanLapId: this.props.departments[0].phongBanId,
        departmentSelections,
      })
    }

    if (this.props.priorities && this.props.priorities.length) {
      this.setState({ doKhanId: this.props.priorities[0].doKhanId })
    }

    if (this.props.securities && this.props.securities.length) {
      this.setState({ doBaoMatId: this.props.securities[0].doBaoMatId })
    }

    if (this.props.objects && this.props.objects.length) {
      this.setState({
        doiTuongCongVanId: this.props.objects[0].doiTuongCongVanId,
      })
    }

    if (this.props.saveCodes && this.props.saveCodes.length) {
      let soLuuId = this.state.soLuuId
      if (
        this.props.soLuuId &&
        this.props.soLuuId.length &&
        this.props.saveCodes.find(item => item.soLuuId === this.props.soLuuId)
      ) {
        soLuuId = this.props.soLuuId
      } else {
        soLuuId = this.props.saveCodes[this.props.saveCodes.length - 1].soLuuId
      }
      this.setState({ soLuuId })
    }

    if (this.props.signUserList && this.props.signUserList.length) {
      this.setState({ nguoiKyId: this.props.signUserList[0].chucDanhId })
    }

    // Đơn vị nhận
    if (this.props.receiveUnits && this.props.receiveUnits.length) {
      const receiveUnitSelection = []
      const receiveUnitSelectionIds = []
      this.props.receiveUnits.forEach((item, i) => {
        receiveUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        receiveUnitSelectionIds.push(item.donViId)
      })
      this.setState({ receiveUnitSelection, receiveUnitSelectionIds })
    }

    // Đơn vị trục thuộc
    if (this.props.attachedUnits && this.props.attachedUnits.length) {
      const attachedUnitSelection = []
      const attachedUnitSelectionIds = []
      this.props.attachedUnits.forEach((item, i) => {
        attachedUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        attachedUnitSelectionIds.push(item.donViId)
      })
      this.setState({ attachedUnitSelection, attachedUnitSelectionIds })
    }

    if (
      this.props.digitalId &&
      this.props.digitalId.toString().length > 0 &&
      this.props.digitalDocuments[this.props.digitalId] !== undefined &&
      this.props.digitalDocuments
    ) {
      let soCongVanPhanDau = this.state.soCongVanPhanDau
      let soCongVanPhanSau = this.state.soCongVanPhanSau
      let trichYeu = ''
      if (this.props.digitalDocuments[this.props.digitalId].soCongVan) {
        let index =
          this.props.digitalDocuments[this.props.digitalId].soCongVan.indexOf(
            '/'
          )
        soCongVanPhanDau = this.props.digitalDocuments[
          this.props.digitalId
        ].soCongVan.slice(0, index)
        soCongVanPhanSau = this.props.digitalDocuments[
          this.props.digitalId
        ].soCongVan.slice(index + 1)
      }
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.digitalDocuments[this.props.digitalId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.digitalDocuments[this.props.digitalId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.digitalDocuments[this.props.digitalId].ngayCongVan
        )
        dateToToString = moment(
          this.props.digitalDocuments[this.props.digitalId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      if (this.props.digitalDocuments[this.props.digitalId].trichYeu) {
        trichYeu = this.props.digitalDocuments[this.props.digitalId].trichYeu
      }
      this.setState({
        vanBanSoHoaId: this.props.digitalId,
        soCongVanPhanDau,
        soCongVanPhanSau,
        ngayCongVan,
        dateToToString,
        trichYeu,
      })
    }

    if (
      this.props.signNumberId &&
      this.props.signNumberId.length > 0 &&
      this.props.signNumberDocuments[this.props.signNumberId] !== undefined &&
      this.props.signNumberDocuments
    ) {
      const soCongVanPhanDau = this.props.signNumberDocuments[
        this.props.signNumberId
      ].soVanBan
        ? this.props.signNumberDocuments[this.props.signNumberId].soVanBan
        : ''
      const soCongVanPhanSau =
        this.props.signNumberDocuments[this.props.signNumberId].soKyHieu
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan
        )
        dateToToString = moment(
          this.props.signNumberDocuments[this.props.signNumberId].ngayCongVan
        ).format('DD/MM/YYYY')
      }
      let phongBanLapId = this.state.phongBanLapId
      if (
        this.props.signNumberDocuments[this.props.signNumberId].phongBanLap &&
        this.props.departments &&
        this.props.departments
          .filter(s => s.phongBanLanhDao === false)
          .find(
            item =>
              item.phongBanId ===
              this.props.signNumberDocuments[this.props.signNumberId]
                .phongBanLap.phongBanId
          )
      ) {
        phongBanLapId =
          this.props.signNumberDocuments[this.props.signNumberId].phongBanLap
            .phongBanId
      }

      let nguoiKyId = this.state.nguoiKyId
      if (
        this.props.signNumberDocuments[this.props.signNumberId].lanhDaoDonVi
      ) {
        nguoiKyId =
          this.props.signNumberDocuments[this.props.signNumberId].lanhDaoDonVi
            .chucDanhId
      }

      // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
      // let fileNoiDung = this.state.fileNoiDung;
      // let fileName = this.state.fileName;
      // if (this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung) {
      //   fileNoiDung = {
      //     file_id: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.fileId,
      //     ten_file: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.tenFile,
      //     url: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.url,
      //     kieu_file: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.kieuFile,
      //     kich_thuoc: this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.kichThuoc
      //   }
      //   fileName = this.props.signNumberDocuments[this.props.signNumberId].fileNoiDung.tenFile;
      // }

      let dsPhoiHop = this.state.dsPhoiHop
      if (this.props.signNumberDocuments[this.props.signNumberId].phoiHop) {
        this.props.signNumberDocuments[this.props.signNumberId].phoiHop.forEach(
          item => {
            dsPhoiHop.push(item.phongBanId)
          }
        )
      }

      this.setState({
        vanBanKySoId: this.props.signNumberId,
        soCongVanPhanDau,
        soCongVanPhanSau,
        ngayCongVan,
        dateToToString,
        phongBanLapId,
        nguoiKyId,
        // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
        // fileNoiDung,
        // fileName,
        trichYeu:
          this.props.signNumberDocuments[this.props.signNumberId].trichYeu,
        dsPhoiHop,
      })
    }

    if (this.props.signNumberId && this.props.ksttDocuments) {
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.ksttDocuments.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.ksttDocuments.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(this.props.ksttDocuments.ngayCongVan)
        dateToToString = moment(this.props.ksttDocuments.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }

      this.setState({
        vanBanKySoId: this.props.signNumberId,
        ngayCongVan,
        dateToToString,
        trichYeu: this.props.ksttDocuments.trichYeu,
      })
    }

    if (this.props.digitalFile) {
      this.setState({
        fileUpload: this.props.digitalFile,
        fileName: this.props.digitalFile.name,
      })
    }

    if (this.props.signNumberFile) {
      this.setState({
        fileNoiDung: this.props.signNumberFile,
        fileName: this.props.signNumberFile.file_name,
      })
    }

    // window.addEventListener('scroll', this.handleScroll);
    // let windowSize = window.innerHeight;
    // let gb = document.getElementById("groupButton");
    // let self = this;
    // setTimeout(function() {
    //   if (windowSize > gb.getBoundingClientRect().top) {
    //     self.setState({
    //       isHideButton: false
    //     });
    //   } else {
    //     self.setState({
    //       isHideButton: true
    //     });
    //   }
    // }, 100);
  }

  componentWillReceiveProps = async nextProps => {
    if (
      nextProps.connectedUnit === this.props.connectedUnit &&
      this.props.connectedUnit.length
    ) {
      this.setState({ dsDonViLienThongLoc: this.props.connectedUnit })
    }
    if (
      nextProps.connectedUnitEVN === this.props.connectedUnitEVN &&
      this.props.connectedUnitEVN.length
    ) {
      this.setState({ dsDonViLienThongLocEVN: this.props.connectedUnitEVN })
    }

    if (
      nextProps.departments !== this.props.departments &&
      nextProps.departments.length
    ) {
      const departmentSelections = []
      nextProps.departments.forEach((item, i) => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      // TODO: Thay đổi: Không chọn option đầu tiên làm nữa định
      this.setState({
        // phongBanLapId: nextProps.departments[0].phongBanId,
        departmentSelections,
      })
    }

    if (
      nextProps.priorities !== this.props.priorities &&
      nextProps.priorities.length
    ) {
      this.setState({ doKhanId: nextProps.priorities[0].doKhanId })
    }

    if (
      nextProps.securities !== this.props.securities &&
      nextProps.securities.length
    ) {
      this.setState({ doBaoMatId: nextProps.securities[0].doBaoMatId })
    }

    if (nextProps.objects !== this.props.objects && nextProps.objects.length) {
      this.setState({
        doiTuongCongVanId: nextProps.objects[0].doiTuongCongVanId,
      })
    }

    if (
      nextProps.saveCodes !== this.props.saveCodes &&
      nextProps.saveCodes &&
      nextProps.saveCodes.length
    ) {
      let soLuuId = this.state.soLuuId
      if (nextProps.saveCodes[nextProps.saveCodes.length - 1]) {
        soLuuId = nextProps.saveCodes[nextProps.saveCodes.length - 1].soLuuId
      }
      await this.setState({ soLuuId })
    }

    if (
      nextProps.soLuuId &&
      nextProps.soLuuId !== this.props.soLuuId &&
      nextProps.soLuuId.length
    ) {
      if (
        this.props.saveCodes.find(item => item.soLuuId === nextProps.soLuuId)
      ) {
        let soLuuId = nextProps.soLuuId
        await this.setState({ soLuuId })
      }
    }

    if (
      nextProps.signUserList !== this.props.signUserList &&
      nextProps.signUserList.length
    ) {
      this.setState({ nguoiKyId: nextProps.signUserList[0].chucDanhId })
    }

    // Đơn vị nhận
    if (
      nextProps.receiveUnits !== this.props.receiveUnits &&
      nextProps.receiveUnits.length
    ) {
      const receiveUnitSelection = []
      const receiveUnitSelectionIds = []
      nextProps.receiveUnits.forEach((item, i) => {
        receiveUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        receiveUnitSelectionIds.push(item.donViId)
      })
      this.setState({ receiveUnitSelection, receiveUnitSelectionIds })
    }

    // Đơn vị trực thuộc
    if (
      nextProps.attachedUnits !== this.props.attachedUnits &&
      nextProps.attachedUnits.length
    ) {
      const attachedUnitSelection = []
      const attachedUnitSelectionIds = []
      nextProps.attachedUnits.forEach((item, i) => {
        attachedUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        attachedUnitSelectionIds.push(item.donViId)
      })
      this.setState({ attachedUnitSelection, attachedUnitSelectionIds })
    }

    if (
      nextProps.digitalFile !== this.props.digitalFile &&
      nextProps.digitalFile
    ) {
      this.setState({
        fileUpload: nextProps.digitalFile,
        fileName: nextProps.digitalFile.name,
      })
    }

    if (
      nextProps.signNumberFile !== this.props.signNumberFile &&
      nextProps.signNumberFile &&
      nextProps.isSubmitSuccess &&
      nextProps.isSubmitSuccess === false
    ) {
      this.setState({
        fileNoiDung: nextProps.signNumberFile,
        fileName: nextProps.signNumberFile.file_name,
      })
    }

    if (
      nextProps.digitalId &&
      nextProps.digitalId.toString().length > 0 &&
      this.props.digitalDocuments &&
      this.props.digitalDocuments[nextProps.digitalId] !== undefined &&
      nextProps.digitalId !== this.props.digitalId
    ) {
      let soCongVanPhanDau = this.state.soCongVanPhanDau
      let soCongVanPhanSau = this.state.soCongVanPhanSau
      let trichYeu = ''
      if (this.props.digitalDocuments[nextProps.digitalId].soCongVan) {
        let index =
          this.props.digitalDocuments[nextProps.digitalId].soCongVan.indexOf(
            '/'
          )
        soCongVanPhanDau = this.props.digitalDocuments[
          nextProps.digitalId
        ].soCongVan.slice(0, index)
        soCongVanPhanSau = this.props.digitalDocuments[
          nextProps.digitalId
        ].soCongVan.slice(index + 1)
      }
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.digitalDocuments[nextProps.digitalId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.digitalDocuments[nextProps.digitalId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.digitalDocuments[nextProps.digitalId].ngayCongVan
        )
        dateToToString = moment(
          this.props.digitalDocuments[nextProps.digitalId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      if (this.props.digitalDocuments[nextProps.digitalId].trichYeu) {
        trichYeu = this.props.digitalDocuments[nextProps.digitalId].trichYeu
      }
      this.setState({
        vanBanSoHoaId: nextProps.digitalId,
        soCongVanPhanDau,
        soCongVanPhanSau,
        ngayCongVan,
        dateToToString,
        trichYeu,
      })
    }

    if (
      nextProps.signNumberId &&
      nextProps.signNumberId.length > 0 &&
      this.props.signNumberDocuments &&
      this.props.signNumberDocuments[nextProps.signNumberId] !== undefined &&
      nextProps.signNumberId !== this.props.signNumberId
    ) {
      const soCongVanPhanDau = this.props.signNumberDocuments[
        nextProps.signNumberId
      ].soVanBan
        ? this.props.signNumberDocuments[nextProps.signNumberId].soVanBan
        : ''
      const soCongVanPhanSau =
        this.props.signNumberDocuments[nextProps.signNumberId].soKyHieu
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan
        )
        dateToToString = moment(
          this.props.signNumberDocuments[nextProps.signNumberId].ngayCongVan
        ).format('DD/MM/YYYY')
      }
      let phongBanLapId = this.state.phongBanLapId
      if (
        nextProps.signNumberDocuments[nextProps.signNumberId].phongBanLap &&
        nextProps.departments &&
        nextProps.departments
          .filter(s => s.phongBanLanhDao === false)
          .find(
            item =>
              item.phongBanId ===
              nextProps.signNumberDocuments[nextProps.signNumberId].phongBanLap
                .phongBanId
          )
      ) {
        phongBanLapId =
          nextProps.signNumberDocuments[nextProps.signNumberId].phongBanLap
            .phongBanId
      }

      let nguoiKyId = this.state.nguoiKyId
      if (this.props.signNumberDocuments[nextProps.signNumberId].lanhDaoDonVi) {
        nguoiKyId =
          this.props.signNumberDocuments[nextProps.signNumberId].lanhDaoDonVi
            .chucDanhId
      }

      // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
      // let fileNoiDung = this.state.fileNoiDung;
      // let fileName = this.state.fileName;
      // if (this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung) {
      //   fileNoiDung = {
      //     file_id: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.fileId,
      //     ten_file: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.tenFile,
      //     url: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.url,
      //     kieu_file: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.kieuFile,
      //     kich_thuoc: this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.kichThuoc
      //   }
      //   fileName = this.props.signNumberDocuments[nextProps.signNumberId].fileNoiDung.tenFile;
      // }

      let dsPhoiHop = this.state.dsPhoiHop
      if (this.props.signNumberDocuments[nextProps.signNumberId].phoiHop) {
        this.props.signNumberDocuments[nextProps.signNumberId].phoiHop.forEach(
          item => {
            dsPhoiHop.push(item.phongBanId)
          }
        )
      }

      this.setState({
        vanBanKySoId: nextProps.signNumberId,
        soCongVanPhanDau,
        soCongVanPhanSau,
        ngayCongVan,
        dateToToString,
        phongBanLapId,
        nguoiKyId,
        // TODO: Dùng api-verion: 2.0 đổi fileNoiDung => fileId
        // fileNoiDung,
        // fileName,
        trichYeu:
          this.props.signNumberDocuments[nextProps.signNumberId].trichYeu,
        dsPhoiHop,
      })
    }

    if (
      nextProps.signNumberId !== this.props.signNumberId ||
      nextProps.ksttDocuments !== this.props.ksttDocuments
    ) {
      let ngayCongVan = undefined
      let dateToToString = ''
      if (
        this.props.ksttDocuments.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.ksttDocuments.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(this.props.ksttDocuments.ngayCongVan)
        dateToToString = moment(this.props.ksttDocuments.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }

      this.setState({
        vanBanKySoId: this.props.signNumberId,
        ngayCongVan,
        dateToToString,
        trichYeu: this.props.ksttDocuments.trichYeu,
      })
    }

    // CHANGED: Tao cong van thanh cong => reset form
    if (nextProps.isSubmitSuccess && nextProps.isSubmitSuccess === true) {
      let doiTuongCongVanId = this.state.doiTuongCongVanId
      let doKhanId = this.state.doKhanId
      let doBaoMatId = this.state.doBaoMatId
      let phongBanLapId = ''
      let nguoiKyId = this.state.nguoiKyId

      if (this.props.priorities && this.props.priorities.length) {
        doKhanId = this.props.priorities[0].doKhanId
      }

      if (this.props.securities && this.props.securities.length) {
        doBaoMatId = this.props.securities[0].doBaoMatId
      }

      if (this.props.objects && this.props.objects.length) {
        doiTuongCongVanId = this.props.objects[0].doiTuongCongVanId
      }

      if (this.props.signUserList && this.props.signUserList.length) {
        nguoiKyId = this.props.signUserList[0].chucDanhId
      }

      this.setState({
        error: {},
        soCongVanPhanDau: '',
        soCongVanPhanSau: '',
        vanBanSoHoaId: '',
        vanBanKySoId: '',
        ngayCongVan: moment(),
        doiTuongCongVanId,
        doKhanId,
        doBaoMatId,
        phongBanLapId,
        nguoiKyId,
        dsPhoiHop: [],
        dsXemDeBiet: [],
        dsDonViNhan: [],
        dsDonViTrucThuoc: [],
        dsDonViKhac: [],
        dsDonViLienThong: [],
        dsDonViLienThongLoc: this.props.connectedUnit || [],
        dsDonViLienThongEVN: [],
        dsDonViLienThongLocEVN: this.props.connectedUnitEVN || [],
        trichYeu: '',
        noiNhan: '',
        guiEvn: false,
        isCheckAllReceiveUnits: false,
        isCheckedAllAttachedUnits: false,
        fileNoiDung: null,
        fileUpload: null,
        fileName: '',
        isLoading: false,
        sizeFile: null,
        progress: 0,
        dateToToString: moment().format('DD/MM/YYYY'),
        isOpenPopupConfirmDocumentNumber: false,
        checkTrinhLanhDao: false,
      })
    }

    if (
      nextProps.templateData &&
      !_.isEqual(this.props.templateData, nextProps.templateData)
    ) {
      this.setState({
        soCongVanPhanDau: nextProps.templateData.soCongVan || null,
        ngayCongVan: moment(nextProps.templateData.ngayCongVan),
        trichYeu: nextProps.templateData.trichYeu,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Change checkbox group to grid
    // if (prevState.dsPhoiHop !== this.state.dsPhoiHop) {
    //   if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + 1 === this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllCompositive: true});
    //   } else {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    // }

    // if (prevState.dsXemDeBiet !== this.state.dsXemDeBiet) {
    //   if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + 1 === this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllOnlySee: true});
    //   } else {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    // }

    // Change checkbox group to grid
    // if (prevState.isCheckedAllOnlySee !== this.state.isCheckedAllOnlySee) {
    //   if (this.state.dsPhoiHop.length) {
    //     this.setState({isCheckedAllCompositive: true});
    //   } else {
    //     this.setState({isCheckedAllCompositive: false});
    //   }

    //   if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + 1 !== this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    // }

    // if (prevState.isCheckedAllCompositive !== this.state.isCheckedAllCompositive) {
    //   if (this.state.dsXemDeBiet.length) {
    //     this.setState({isCheckedAllOnlySee: true});
    //   } else {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }

    //   if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + 1 !== this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    // }

    if (
      this.props.isUpdate &&
      this.props.sendDocument &&
      this.props.sendDocument !== prevProps.sendDocument
    ) {
      let soCongVanPhanDau = this.state.soCongVanPhanDau
      let soCongVanPhanSau = this.state.soCongVanPhanSau
      if (this.props.sendDocument.soCongVan) {
        let index = this.props.sendDocument.soCongVan.indexOf('/')
        soCongVanPhanDau = this.props.sendDocument.soCongVan.slice(0, index)
        soCongVanPhanSau = this.props.sendDocument.soCongVan.slice(index + 1)
      }

      let doBaoMatId = ''
      let soLuuId = this.state.soLuuId
      let doiTuongCongVanId = ''
      let doKhanId = this.state.doKhanId
      let phongBanLapId = this.state.phongBanLapId
      let nguoiKyId = this.state.nguoiKyId
      let ngayCongVan = undefined
      let dateToToString = ''
      let fileName = ''
      let fileNoiDung = this.state.fileNoiDung
      let dsPhoiHop = []
      let dsXemDeBiet = []
      let dsDonViNhan = []
      let dsDonViTrucThuoc = []
      let dsDonViKhac = []
      let dsDonViLienThong = []
      let dsDonViLienThongEVN = []
      let isOpen = false
      let isOpenConnectedUnit = false
      let isOpenConnectedUnitEVN = true

      if (this.props.sendDocument.doBaoMat) {
        doBaoMatId = this.props.sendDocument.doBaoMat.doBaoMatId
      }
      if (this.props.sendDocument.soLuu) {
        soLuuId = this.props.sendDocument.soLuu.soLuuId
      }
      if (this.props.sendDocument.doiTuongCongVan) {
        doiTuongCongVanId =
          this.props.sendDocument.doiTuongCongVan.doiTuongCongVanId
      }
      if (this.props.sendDocument.doKhan) {
        doKhanId = this.props.sendDocument.doKhan.doKhanId
      }
      if (
        this.props.sendDocument.phongBanLap &&
        this.props.sendDocument.phongBanLap.phongBanId &&
        this.props.departments &&
        this.props.departments
          .filter(s => s.phongBanLanhDao === false)
          .find(
            item =>
              item.phongBanId === this.props.sendDocument.phongBanLap.phongBanId
          )
      ) {
        phongBanLapId = this.props.sendDocument.phongBanLap.phongBanId
      }
      if (this.props.sendDocument.nguoiKy) {
        nguoiKyId = this.props.sendDocument.nguoiKy.chucDanhId
      }
      if (
        this.props.sendDocument.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.sendDocument.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(this.props.sendDocument.ngayCongVan)
        dateToToString = moment(this.props.sendDocument.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }
      if (this.props.sendDocument.dsPhoiHop) {
        this.props.sendDocument.dsPhoiHop.forEach(item => {
          dsPhoiHop.push(item.phongBanId)
        })
      }

      if (this.props.sendDocument.dsXemDeBiet) {
        this.props.sendDocument.dsXemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.phongBanId)
        })
      }

      if (this.props.sendDocument.dsDonViNhan) {
        this.props.sendDocument.dsDonViNhan.forEach(item => {
          if (
            _.indexOf(this.state.receiveUnitSelectionIds, item.donViId) > -1
          ) {
            dsDonViNhan.push(item.donViId)
          } else {
            dsDonViTrucThuoc.push(item.donViId)
          }
        })
      }

      if (this.props.sendDocument.dsDonViTrucThuoc) {
        this.props.sendDocument.dsDonViTrucThuoc.forEach(item => {
          if (
            _.indexOf(this.state.attachedUnitSelectionIds, item.donViId) > -1
          ) {
            dsDonViTrucThuoc.push(item.donViId)
          } else {
            dsDonViNhan.push(item.donViId)
          }
        })
      }

      if (this.props.sendDocument.dsDonViKhac) {
        this.props.sendDocument.dsDonViKhac.forEach(item => {
          dsDonViKhac.push(item.donViId)
        })
        if (this.props.sendDocument.dsDonViKhac.length) {
          isOpen = true
        }
      }

      if (this.props.sendDocument.dsDonViLienThong) {
        this.props.sendDocument.dsDonViLienThong.forEach(item => {
          dsDonViLienThong.push(item.donViId)
        })
        if (this.props.sendDocument.dsDonViLienThong.length) {
          isOpen = true
        }
      }

      if (this.props.sendDocument.dsDonViLienThongEVN) {
        this.props.sendDocument.dsDonViLienThongEVN.forEach(item => {
          dsDonViLienThongEVN.push(item.donViId)
        })
        if (this.props.sendDocument.dsDonViLienThongEVN.length) {
          isOpen = true
        }
      }

      if (this.props.sendDocument.fileNoiDung) {
        fileNoiDung = {
          file_id: this.props.sendDocument.fileNoiDung.fileId,
          ten_file: this.props.sendDocument.fileNoiDung.tenFile,
          url: this.props.sendDocument.fileNoiDung.url,
          kieu_file: this.props.sendDocument.fileNoiDung.kieuFile,
          kich_thuoc: this.props.sendDocument.fileNoiDung.kichThuoc,
        }
        fileName = this.props.sendDocument.fileNoiDung.tenFile
      }
      const disabledListDsDonViNhan = _.intersection(
        _.concat(this.props.mainUnitId, dsDonViKhac, dsDonViTrucThuoc),
        this.state.receiveUnitSelectionIds
      )
      const disabledListDsDonViTrucThuoc = _.intersection(
        _.concat(this.props.mainUnitId, dsDonViKhac, dsDonViNhan),
        this.state.attachedUnitSelectionIds
      )
      this.setState({
        congVanDiId: this.props.sendDocument.congVanDiId,
        trichYeu: this.props.sendDocument.trichYeu,
        vanBanSoHoaId: this.props.sendDocument.vanBanSoHoaId,
        vanBanKySoId: this.props.sendDocument.vanBanKySoId,
        noiNhan: this.props.sendDocument.noiNhan,
        // docHanChe: this.props.sendDocument.docHanChe,
        guiEvn: this.props.sendDocument.guiEvn,
        soCongVanPhanDau: soCongVanPhanDau,
        soCongVanPhanSau,
        doBaoMatId,
        doiTuongCongVanId,
        doKhanId,
        soLuuId,
        phongBanLapId,
        nguoiKyId,
        ngayCongVan,
        dateToToString,
        dsPhoiHop,
        dsXemDeBiet,
        dsDonViNhan,
        dsDonViTrucThuoc,
        dsDonViKhac,
        dsDonViLienThong,
        dsDonViLienThongLoc: this.props.connectedUnit || [],
        dsDonViLienThongEVN,
        dsDonViLienThongLocEVN: this.props.connectedUnitEVN || [],
        fileName,
        fileNoiDung,
        isOpen,
        isOpenConnectedUnit,
        isOpenConnectedUnitEVN,
        isCheckAllReceiveUnits:
          dsDonViNhan.length + disabledListDsDonViNhan.length ===
          this.props.receiveUnits.length
            ? true
            : false,
        isCheckedAllAttachedUnits:
          dsDonViTrucThuoc.length + disabledListDsDonViTrucThuoc.length ===
          this.props.attachedUnits.length
            ? true
            : false,
      })
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll (event) {
  //   let windowSize = window.innerHeight;
  //   let gb = document.getElementById("groupButton");
  //   if (windowSize >= gb.getBoundingClientRect().top) {
  //     this.setState({
  //       isHideButton: false
  //     });
  //   }else{
  //     this.setState({
  //       isHideButton: true
  //     });
  //   }
  // }

  handleClickArrow = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  handleClickArrowConnectedUnit = () => {
    this.setState({ isOpenConnectedUnit: !this.state.isOpenConnectedUnit })
  }
  handleClickArrowConnectedUnitEVN = () => {
    this.setState({
      isOpenConnectedUnitEVN: !this.state.isOpenConnectedUnitEVN,
    })
  }

  handleSearchTree = (searchTxt, arr) => {
    let newArray = arr.reduce((curArr, curVal) => {
      if (
        curVal.tenDonVi.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1
      ) {
        return [...curArr, curVal]
      } else {
        if (
          curVal.dsDonViCon &&
          curVal.dsDonViCon.length &&
          curVal.dsDonViCon !== null
        ) {
          let childArray = this.handleSearchTree(searchTxt, curVal.dsDonViCon)
          if (childArray.length) {
            return [...curArr, { ...curVal, dsDonViCon: childArray }]
          } else {
            return [...curArr]
          }
        } else {
          return [...curArr]
        }
      }
    }, [])
    return [...newArray]
  }

  handleKeyUpConnectedUnit = e => {
    const name = e.target.name
    this.setState({
      [name]: e.target.value,
    })
    this.deboundKeyUpConnectedUnit(e.target)
  }

  removeTextSearch(name) {
    this.setState({
      [name]: '',
    })
    if (name === 'timDonViLienThong') {
      this.setState({ dsDonViLienThongLoc: this.props.connectedUnit })
    }
    if (name === 'timDonViLienThongEVN') {
      this.setState({ dsDonViLienThongLocEVN: this.props.connectedUnitEVN })
    }
  }

  deboundKeyUpConnectedUnit = _.debounce(input => {
    const value = input.value
    const name = input.name

    if (value.trim().length >= 3) {
      if (name === 'timDonViLienThong') {
        this.setState({
          dsDonViLienThongLoc: this.handleSearchTree(
            value,
            this.props.connectedUnit
          ),
        })
      }
      if (name === 'timDonViLienThongEVN') {
        this.setState({
          dsDonViLienThongLocEVN: this.handleSearchTree(
            value,
            this.props.connectedUnitEVN
          ),
        })
      }
    } else {
      if (name === 'timDonViLienThong') {
        this.setState({ dsDonViLienThongLoc: this.props.connectedUnit })
      }
      if (name === 'timDonViLienThongEVN') {
        this.setState({ dsDonViLienThongLocEVN: this.props.connectedUnitEVN })
      }
    }
  }, 500)

  render() {
    let isUpdateDigitalDocument = false
    let isUpdateSignNumberDocument = false
    if (
      this.props.isUpdate &&
      this.props.sendDocument &&
      this.props.sendDocument.vanBanSoHoaId
    ) {
      isUpdateDigitalDocument = true
    }

    if (
      this.props.isUpdate &&
      this.props.sendDocument &&
      this.props.sendDocument.vanBanKySoId
    ) {
      isUpdateSignNumberDocument = true
    }

    const checkTongCongTy =
      this.props.auth.user &&
      this.props.auth.user.dsChucDanh &&
      this.props.auth.user.dsChucDanh.filter(
        item => item.donVi.donViCha === null
      )
    return (
      <div
        className={classnames({
          'add-receive-document': !this.props.isShow,
          'ad-digitizing': this.props.isShow,
        })}
      >
        <div className="form-container">
          <div className="form-padding-box">
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-6 full-input">
                  <label className="pt-label">
                    Số công văn
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="row row-input-numberdocument">
                      <div className="col-xs-6 col-sm-6 padding-left-none">
                        <input
                          name="soCongVanPhanDau"
                          value={this.state.soCongVanPhanDau}
                          className={classnames('pt-input', {
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.sendDocument === true,
                          })}
                          placeholder="Nhập số…"
                          type="text"
                          dir="auto"
                          onChange={this.handleChange}
                          autoFocus={true}
                          disabled={
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.sendDocument === true) ||
                            (!isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (!isUpdateDigitalDocument &&
                              isUpdateSignNumberDocument &&
                              this.props.isUpdate) ||
                            (isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign)
                          }
                        />
                      </div>
                      <div className="col-xs-1 col-sm-1">
                        <span className="symbol-documentary">/</span>
                      </div>
                      <div className="col-xs-5 col-sm-5 padding-right-none">
                        <input
                          name="soCongVanPhanSau"
                          value={this.state.soCongVanPhanSau}
                          className={classnames('pt-input', {
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.sendDocument === true,
                          })}
                          type="text"
                          dir="auto"
                          onChange={this.handleChange}
                          disabled={
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.sendDocument === true) ||
                            (!isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (!isUpdateDigitalDocument &&
                              isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.error.soCongVan !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.soCongVan}
                    </div>
                  )}
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 full-input">
                  <label className="pt-label">
                    Ngày công văn
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-input-group">
                      <DatePicker
                        isClearable={this.state.dateToToString ? true : false}
                        readOnly={this.props.readOnly}
                        selected={this.state.ngayCongVan}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayCongVan',
                          'dateToToString'
                        )}
                        value={this.state.dateToToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw('dateToToString', e.target.value)
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayCongVan',
                            'dateToToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Chọn ngày..."
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.sendDocument === true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isUpdate) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </div>
                  </div>
                  {this.state.error.ngayCongVan !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.ngayCongVan}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">
                    Mã sổ lưu
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        name="soLuuId"
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.soLuuId === true,
                        })}
                        onChange={this.handleChange}
                        value={this.state.soLuuId}
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.soLuuId === true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                      >
                        {this.props.saveCodes.map((item, i) => (
                          <option key={i} value={item.soLuuId}>
                            {item.tenSoLuu}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.error.soLuuId !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.soLuuId}
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">Đối tượng</label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        name="doiTuongCongVanId"
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.doiTuongCongVanId === true,
                        })}
                        onChange={this.handleChange}
                        value={this.state.doiTuongCongVanId}
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.doiTuongCongVanId ===
                              true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                      >
                        <option value=""></option>
                        {this.props.objects
                          ?.filter(item => item?.isActive)
                          ?.map((item, i) => (
                            <option key={i} value={item.doiTuongCongVanId}>
                              {item.tenDoiTuongCongVan}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">Độ bảo mật</label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        name="doBaoMatId"
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.doBaoMatId === true,
                        })}
                        onChange={this.handleChange}
                        value={this.state.doBaoMatId}
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.doBaoMatId === true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                      >
                        <option value=""></option>
                        {this.props.securities.map((item, i) => (
                          <option key={i} value={item.doBaoMatId}>
                            {item.tenDoBaoMat}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">
                    Độ khẩn
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        name="doKhanId"
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.doKhanId === true,
                        })}
                        onChange={this.handleChange}
                        value={this.state.doKhanId}
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.doKhanId === true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                      >
                        {this.props.priorities.map((item, i) => (
                          <option key={i} value={item.doKhanId}>
                            {item.tenDoKhan}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.error.doKhanId !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.doKhanId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">
                    Phòng ban lập
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.phongBanLapId === true,
                        })}
                        name="phongBanLapId"
                        value={this.state.phongBanLapId}
                        onChange={this.handleChange}
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.phongBanLapId === true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                      >
                        <option value=""></option>
                        {this.props.departments &&
                          this.props.departments
                            // .filter(s => s.phongBanLanhDao === false)
                            .map((item, i) => (
                              <option key={i} value={item.phongBanId}>
                                {item.maPhongBan}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  {this.state.error.phongBanLapId !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.phongBanLapId}
                    </div>
                  )}
                </div>

                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">
                    Người Ký
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        name="nguoiKyId"
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.nguoiKyId === true,
                        })}
                        value={this.state.nguoiKyId}
                        onChange={this.handleChange}
                        disabled={
                          this.props.status !== 'convergeSignature' &&
                          ((this.props.isLoadingData &&
                            this.props.isLoadingData.nguoiKyId === true) ||
                            (!isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (!isUpdateDigitalDocument &&
                              isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (this.state.isCheckCongVanKySo &&
                              this.state.vanBanKySoId) ||
                            (this.state.vanBanKySoId &&
                              isUpdateSignNumberDocument))
                        }
                      >
                        {this.props.signUserList.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.error.nguoiKyId !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.nguoiKyId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/*<div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-md-6 col-xs-6 col-sm-6">
                  <label className="pt-label">
                    Đối tượng
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        className={
                          classnames({
                            'loading-input': this.props.isLoadingData && this.props.isLoadingData.doiTuongCongVanId === true
                          })
                        }
                        name="doiTuongCongVanId"
                        onChange={this.handleChange}
                        value={this.state.doiTuongCongVanId}
                      >
                        <option value=""></option>
                        {this.props.objects.map((item, i) => (
                          <option key={i} value={item.doiTuongCongVanId}>
                            {item.tenDoiTuongCongVan}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}

            {/* Design Update*/}

            {/* Phối hợp*/}
            {/*<div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Phối hợp</span>
                <Checkbox
                  className="pull-right"
                  name="allCompositive"
                  checked={this.state.isCheckedAllCompositive }
                  onChange={this.handleCheckAllCompositive}
                  disabled={this.state.dsXemDeBiet.length + 1 === this.state.departmentSelections.length || !this.state.departmentSelections.length}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>

              <CheckboxGroup
                name="phoiHop"
                options={this.state.departmentSelections}
                selectedOptions={this.state.dsPhoiHop}
                onChange={this.handleCompositiveDepartmentSelection}
                disabledList={this.state.dsXemDeBiet.concat(this.state.phongBanLapId)}
                colum="col-xs-6 col-sm-6 col-md-4"
              />
            </div>*/}

            {/* Xem để biết*/}
            {/*<div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Xem để biết</span>
                <Checkbox
                  className="pull-right"
                  name="allOnlySee"
                  checked={this.state.isCheckedAllOnlySee}
                  onChange={this.handleCheckAllOnlySee}
                  disabled={this.state.dsPhoiHop.length + 1 === this.state.departmentSelections.length || !this.state.departmentSelections.length}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>

              <CheckboxGroup
                name="xemDeBiet"
                options={this.state.departmentSelections}
                selectedOptions={this.state.dsXemDeBiet}
                onChange={this.handleOnlySeeDepartmentSelection}
                disabledList={this.state.dsPhoiHop.concat(this.state.phongBanLapId)}
                colum="col-xs-6 col-sm-6 col-md-4"
              />
            </div>*/}
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <label className="pt-label">
                    Trích yếu
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <textarea
                      name="trichYeu"
                      className={classnames('pt-input pt-fill font-20', {
                        'loading-input':
                          this.props.isLoadingData &&
                          this.props.isLoadingData.sendDocument === true,
                      })}
                      value={this.state.trichYeu}
                      rows="3"
                      placeholder="Nội dung…"
                      dir="auto"
                      onChange={this.handleChange}
                      disabled={
                        (this.props.isLoadingData &&
                          this.props.isLoadingData.sendDocument === true) ||
                        (!isUpdateDigitalDocument &&
                          !isUpdateSignNumberDocument &&
                          this.props.isAssign) ||
                        (!isUpdateDigitalDocument &&
                          isUpdateSignNumberDocument &&
                          this.props.isAssign) ||
                        (isUpdateDigitalDocument &&
                          !isUpdateSignNumberDocument &&
                          this.props.isAssign)
                      }
                    ></textarea>
                  </div>
                  {this.state.error.trichYeu !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.trichYeu}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <label className="pt-label">Nơi nhận</label>
                  <div className="pt-form-content">
                    <textarea
                      name="noiNhan"
                      className={classnames('pt-input', {
                        'loading-input':
                          this.props.isLoadingData &&
                          this.props.isLoadingData.sendDocument === true,
                      })}
                      value={this.state.noiNhan}
                      rows="3"
                      placeholder="Đơn vị ngoài ngành"
                      dir="auto"
                      onChange={this.handleChange}
                      disabled={
                        (this.props.isLoadingData &&
                          this.props.isLoadingData.sendDocument === true) ||
                        (!isUpdateDigitalDocument &&
                          !isUpdateSignNumberDocument &&
                          this.props.isAssign) ||
                        (!isUpdateDigitalDocument &&
                          isUpdateSignNumberDocument &&
                          this.props.isAssign) ||
                        (isUpdateDigitalDocument &&
                          !isUpdateSignNumberDocument &&
                          this.props.isAssign)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-form-group pt-intent-danger mb35 mt20">
              <div className="row">
                {!this.props.isShow && (
                  <div className="col-md-6 col-xs-12 col-sm-12 file-box">
                    <label className="pt-label">
                      File nội dung
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <label className="pt-file-upload">
                        <input
                          type="file"
                          name="fileName"
                          onChange={this.handleFileChange}
                          onClick={event => {
                            event.target.value = null
                          }}
                          disabled={
                            this.state.isLoading ||
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            this.props.isUpdate ||
                            (!isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (!isUpdateDigitalDocument &&
                              isUpdateSignNumberDocument &&
                              this.props.isAssign) ||
                            (isUpdateDigitalDocument &&
                              !isUpdateSignNumberDocument &&
                              this.props.isAssign)
                          }
                        />
                        <span className="pt-file-upload-input">
                          {this.state.fileName.length === 0
                            ? 'Chọn file đính kèm'
                            : this.state.fileName}
                        </span>
                      </label>
                      {this.state.sizeFile && (
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(this.state.sizeFile)}
                          </span>
                        </p>
                      )}
                      {this.state.error.fileName !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.fileName}
                        </div>
                      )}
                      {this.state.isLoading && this.state.progress > 0 && (
                        <div className="progress-upload-content">
                          <span className="percent-content">
                            {this.state.progress}%
                          </span>
                          <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                            <div
                              className="pt-progress-meter"
                              style={{ width: this.state.progress + '%' }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6 col-xs-12 col-sm-12">
                  <label className="pt-label pt0">&nbsp;</label>
                  <div className="pt-form-content">
                    {/* Hiển thị nếu là tổng công ty */}
                    {checkTongCongTy && checkTongCongTy.length !== 0 && (
                      <div className="selectbox-group mb0">
                        <Checkbox
                          className="pt-inline"
                          name="trinhLanhDao"
                          checked={this.state.checkTrinhLanhDao}
                          onChange={this.handleChangeTrinhLanhDao}
                        >
                          <span className="name_checkbox">Trình TGĐ</span>
                        </Checkbox>
                      </div>
                    )}
                    <div className="pt-select" style={{ display: 'none' }}>
                      {/* TODO: Check là tổng giám đốc, cần check lại */}
                      <select
                        name="trinhLanhDaoId"
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.trinhLanhDaoId === true,
                        })}
                        value={
                          this.state.trinhLanhDaoId
                            ? this.state.trinhLanhDaoId
                            : '57e758f7-f183-e711-80cd-40167e7b9c6a'
                        }
                        onChange={this.handleChange}
                        disabled={
                          !this.state.checkTrinhLanhDao ||
                          this.state.checkTrinhLanhDao
                        }
                      >
                        {this.props.leaderDepartments &&
                          this.props.leaderDepartments.map((item, i) => (
                            <option key={i} value={item.phongBanId}>
                              {item.maPhongBan}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row group-table-DepartmentPerform">
              <div
                className={classnames(
                  'col-xs-12 col-sm-6 box-DepartmentPerform pt-form-group item-box',
                  {
                    'col-md-4': this.props.isShowAttachedUnits === true,
                    'col-md-6': this.props.isShowAttachedUnits === false,
                  }
                )}
              >
                <label className="pt-label pt0">Phòng ban phối hợp</label>
                {this.props.isLoadingData &&
                  this.props.isLoadingData.phongBanLapId === true && (
                    <div className="loading-container">
                      <LoadingItem />
                    </div>
                  )}
                {this.props.isLoadingData &&
                  this.props.isLoadingData.phongBanLapId === false && (
                    <Scrollbars
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={422}
                    >
                      <DepartmentPerform
                        options={this.state.departmentSelections}
                        onSubmit={this.handleGetValueDepartment}
                        dsPhoiHop={this.state.dsPhoiHop}
                        dsXemDeBiet={this.state.dsXemDeBiet}
                        disabledListCompositive={[this.state.phongBanLapId]}
                        disabledListOnlySee={[this.state.phongBanLapId]}
                        selectedCompositiveDepartments={[]}
                        selectedOnlySeeDepartments={[]}
                        titles={['', 'PH', 'XĐB']}
                        isHasRadio={false}
                        type="Phòng ban"
                        classCustomStyle
                        isDisabledAll={
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          this.state.checkTrinhLanhDao
                        }
                      />
                    </Scrollbars>
                  )}
              </div>

              {/* Đơn vị trực thuộc */}
              {this.props.isShowAttachedUnits === true && (
                <div className="col-xs-12 col-sm-6 col-md-4 box-selectbox-dvn item-box">
                  <div className="selectbox-group">
                    <div className="heading-group-select disabled-checkbox">
                      <span className="pull-left name_group name_group_unit">
                        Đơn vị trực thuộc
                      </span>
                      <Checkbox
                        className="pull-right"
                        name="allAttachedUnits"
                        checked={this.state.isCheckedAllAttachedUnits}
                        onChange={this.handleCheckAll.bind(
                          this,
                          'isCheckedAllAttachedUnits',
                          'dsDonViTrucThuoc',
                          'attachedUnitSelection',
                          _.concat(
                            this.props.mainUnitId,
                            this.state.dsDonViKhac,
                            this.state.dsDonViNhan
                          )
                        )}
                        disabled={
                          !this.state.attachedUnitSelection ||
                          !this.state.attachedUnitSelection.length ||
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.attachedUnits === true) ||
                          (!isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (!isUpdateDigitalDocument &&
                            isUpdateSignNumberDocument &&
                            this.props.isAssign) ||
                          (isUpdateDigitalDocument &&
                            !isUpdateSignNumberDocument &&
                            this.props.isAssign)
                        }
                      >
                        <span className="name_checkbox">Tất cả</span>
                      </Checkbox>
                    </div>
                    <div className="container-checkbox">
                      {this.props.isLoadingData &&
                        this.props.isLoadingData.attachedUnits === true && (
                          <div className="loading-container">
                            <LoadingItem />
                          </div>
                        )}
                      {this.props.isLoadingData &&
                        this.props.isLoadingData.attachedUnits === false &&
                        this.state.attachedUnitSelection &&
                        this.state.attachedUnitSelection.length > 0 && (
                          <Scrollbars
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={422}
                            autoHide
                          >
                            <CheckboxGroup
                              name="donViTrucThuoc"
                              options={this.state.attachedUnitSelection}
                              selectedOptions={this.state.dsDonViTrucThuoc}
                              onChange={this.handleCheck.bind(
                                this,
                                'isCheckedAllAttachedUnits',
                                'dsDonViTrucThuoc',
                                'attachedUnitSelection',
                                _.concat(
                                  this.props.mainUnitId,
                                  this.state.dsDonViKhac,
                                  this.state.dsDonViNhan
                                )
                              )}
                              disabledList={_.concat(
                                this.props.mainUnitId,
                                this.state.dsDonViKhac,
                                this.state.dsDonViNhan
                              )}
                              isNotCheckDisabledItem
                              colum="col-xs-12 col-sm-12 col-md-12"
                              isDisabledAll={
                                (!isUpdateDigitalDocument &&
                                  !isUpdateSignNumberDocument &&
                                  this.props.isAssign) ||
                                (!isUpdateDigitalDocument &&
                                  isUpdateSignNumberDocument &&
                                  this.props.isAssign) ||
                                (isUpdateDigitalDocument &&
                                  !isUpdateSignNumberDocument &&
                                  this.props.isAssign)
                              }
                            />
                          </Scrollbars>
                        )}
                      {this.props.isLoadingData &&
                        this.props.isLoadingData.attachedUnits === false &&
                        (!this.state.attachedUnitSelection ||
                          this.state.attachedUnitSelection.length === 0) && (
                          <NotFound />
                        )}
                    </div>
                  </div>
                </div>
              )}

              {/* Đơn vị nhân */}
              <div
                className={classnames('col-xs-12 box-selectbox-dvn item-box', {
                  'col-sm-6 col-md-4 col-xs-12':
                    this.props.isShowAttachedUnits === true,
                  'col-sm-6 col-md-6 col-xs-12':
                    this.props.isShowAttachedUnits === false,
                })}
              >
                <div className="selectbox-group">
                  <div className="heading-group-select disabled-checkbox">
                    <span className="pull-left name_group name_group_unit">
                      Đơn vị nhận
                    </span>
                    <Checkbox
                      className="pull-right"
                      name="allUnit"
                      checked={this.state.isCheckAllReceiveUnits}
                      onChange={this.handleCheckAll.bind(
                        this,
                        'isCheckAllReceiveUnits',
                        'dsDonViNhan',
                        'receiveUnitSelection',
                        _.concat(
                          this.props.mainUnitId,
                          this.state.dsDonViKhac,
                          this.state.dsDonViTrucThuoc
                        )
                      )}
                      disabled={
                        !this.state.receiveUnitSelection ||
                        !this.state.receiveUnitSelection.length ||
                        (this.props.isLoadingData &&
                          (this.props.isLoadingData.receiveUnits === true ||
                            this.props.isLoadingData.attachedUnits === true)) ||
                        (!isUpdateDigitalDocument &&
                          !isUpdateSignNumberDocument &&
                          this.props.isAssign) ||
                        (!isUpdateDigitalDocument &&
                          isUpdateSignNumberDocument &&
                          this.props.isAssign) ||
                        (isUpdateDigitalDocument &&
                          !isUpdateSignNumberDocument &&
                          this.props.isAssign)
                      }
                    >
                      <span className="name_checkbox">Tất cả</span>
                    </Checkbox>
                  </div>
                  <div className="container-checkbox">
                    {this.props.isLoadingData &&
                      (this.props.isLoadingData.receiveUnits === true ||
                        this.props.isLoadingData.attachedUnits === true) && (
                        <div className="loading-container">
                          <LoadingItem />
                        </div>
                      )}
                    {this.props.isLoadingData &&
                      this.props.isLoadingData.receiveUnits === false &&
                      this.props.isLoadingData.attachedUnits === false &&
                      this.state.receiveUnitSelection &&
                      this.state.receiveUnitSelection.length > 0 && (
                        <Scrollbars
                          autoHideTimeout={1000}
                          autoHideDuration={200}
                          autoHeight
                          autoHeightMin={0}
                          autoHeightMax={422}
                          autoHide
                        >
                          <CheckboxGroup
                            name="donViNhan"
                            options={this.state.receiveUnitSelection}
                            selectedOptions={this.state.dsDonViNhan}
                            onChange={this.handleCheck.bind(
                              this,
                              'isCheckAllReceiveUnits',
                              'dsDonViNhan',
                              'receiveUnitSelection',
                              _.concat(
                                this.props.mainUnitId,
                                this.state.dsDonViKhac,
                                this.state.dsDonViTrucThuoc
                              )
                            )}
                            disabledList={_.concat(
                              this.props.mainUnitId,
                              this.state.dsDonViKhac,
                              this.state.dsDonViTrucThuoc
                            )}
                            isNotCheckDisabledItem
                            colum="col-xs-6 col-sm-12 col-md-6"
                            isDisabledAll={
                              (!isUpdateDigitalDocument &&
                                !isUpdateSignNumberDocument &&
                                this.props.isAssign) ||
                              (!isUpdateDigitalDocument &&
                                isUpdateSignNumberDocument &&
                                this.props.isAssign) ||
                              (isUpdateDigitalDocument &&
                                !isUpdateSignNumberDocument &&
                                this.props.isAssign)
                            }
                          />
                        </Scrollbars>
                      )}
                    {this.props.isLoadingData &&
                      this.props.isLoadingData.receiveUnits === false &&
                      this.props.isLoadingData.attachedUnits === false &&
                      (!this.state.receiveUnitSelection ||
                        this.state.receiveUnitSelection.length === 0) && (
                        <NotFound />
                      )}
                  </div>
                </div>
              </div>
            </div>

            {(this.props.parentUnitId === null ||
              this.props.isShowOtherUnits === true) && (
              <div className="pt-form-group">
                <div className="row">
                  {/* Đơn vị khác */}
                  {this.props.isShowOtherUnits === true && (
                    <div className="col-md-6 col-xs-12 col-sm-12 checkbox-tree-section">
                      <div className="heading-group-select disabled-checkbox">
                        <span
                          className="pull-left name_group name_group_unit"
                          onClick={this.handleClickArrow}
                        >
                          Đơn vị khác
                        </span>
                        <span
                          onClick={this.handleClickArrow}
                          className={classnames('name_group_unit', {
                            'pt-icon pt-icon-chevron-up': this.state.isOpen,
                            'pt-icon pt-icon-chevron-down': !this.state.isOpen,
                          })}
                        ></span>
                      </div>
                      <Collapse isOpen={this.state.isOpen}>
                        {this.state.isOpen &&
                          this.props.isLoadingData &&
                          this.props.isLoadingData.otherUnits === true && (
                            <div className="loading-container">
                              <LoadingItem />
                            </div>
                          )}
                        {this.props.isLoadingData &&
                          this.props.isLoadingData.otherUnits === false &&
                          this.props.otherUnits &&
                          this.props.otherUnits.length > 0 && (
                            <CheckBoxTree
                              disabledClass="disabled-tree-node-child"
                              checkStrictly={true}
                              data={
                                this.props.otherUnits &&
                                this.props.otherUnits[0]
                                  ? [this.props.otherUnits[0]]
                                  : []
                              }
                              onChange={this.handleChangeOtherUnits}
                              checkedList={this.state.dsDonViKhac}
                              disabledList={_.concat(
                                this.state.receiveUnitSelectionIds,
                                this.state.attachedUnitSelectionIds,
                                this.props.mainUnitId
                              )}
                              isLoadingData={
                                this.props.isLoadingData &&
                                this.props.isLoadingData.otherUnits
                              }
                              isDisabledAll={
                                (!isUpdateDigitalDocument &&
                                  !isUpdateSignNumberDocument &&
                                  this.props.isAssign) ||
                                (!isUpdateDigitalDocument &&
                                  isUpdateSignNumberDocument &&
                                  this.props.isAssign) ||
                                (isUpdateDigitalDocument &&
                                  !isUpdateSignNumberDocument &&
                                  this.props.isAssign)
                              }
                            />
                          )}
                        {this.props.isLoadingData &&
                          this.props.isLoadingData.otherUnits === false &&
                          (!this.props.otherUnits ||
                            this.props.otherUnits.length === 0) && <NotFound />}
                      </Collapse>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="groupButton" className="buttton-action-footer">
          <Button
            onClick={this.handleClickCancel}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-save"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
            disabled={
              Object.values(this.props.isLoadingData).find(
                item => item === true
              ) !== undefined ||
              (!isUpdateDigitalDocument &&
                !isUpdateSignNumberDocument &&
                this.props.isAssign) ||
              (!isUpdateDigitalDocument &&
                isUpdateSignNumberDocument &&
                this.props.isAssign) ||
              (isUpdateDigitalDocument &&
                !isUpdateSignNumberDocument &&
                this.props.isAssign)
            }
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>

        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-main-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
            disabled={
              Object.values(this.props.isLoadingData).find(
                item => item === true
              ) !== undefined ||
              (!isUpdateDigitalDocument &&
                !isUpdateSignNumberDocument &&
                this.props.isAssign) ||
              (!isUpdateDigitalDocument &&
                isUpdateSignNumberDocument &&
                this.props.isAssign) ||
              (isUpdateDigitalDocument &&
                !isUpdateSignNumberDocument &&
                this.props.isAssign)
            }
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
          <Button
            className="pt-button btn-cancel"
            onClick={this.handleClickCancel}
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
        </div>

        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={this.props.isUpdate ? POPUP.HUY_SUA_CONG_VAN_DI : POPUP.HUY_TAO_CONG_VAN_DI}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleClickCancel}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
        {/* Popup confirm nhập số công văn đi trùng */}
        <PopupConfirm
          isOpen={this.state.isOpenPopupConfirmDocumentNumber}
          onClose={this.handleClickPopupConfirmDocumentNumber}
          title={
            this.props.isUpdate ? POPUP.SUA_CONG_VAN_DI : POPUP.TAO_CONG_VAN_DI
          }
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handlePostDocument}
        >
          {POPUP.XAC_NHAN_SO_CONG_VAN_TRUNG}
        </PopupConfirm>
        <PopupConfirm
          isOpen={this.state.isOpenPopupConfirmResendEVN}
          onClose={this.handleClickPopupResendEVN}
          title={POPUP.GUI_LAI_EVN}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleResendEVN}
          isWarning
          isLoading={this.state.isLoadingResendEVN}
        >
          {POPUP.XAC_NHAN_GUI_LAI_EVN}
        </PopupConfirm>
        {this.state.isRedirect && <Redirect push to={this.state.redirectTo} />}
      </div>
    )
  }
}

export default InputForm
