import { schema } from 'normalizr'

const EvnDocuments = new schema.Entity(
  'evnDocuments',
  {},
  {
    idAttribute: evnDocuments => evnDocuments.id,
  }
)

export default EvnDocuments
