import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import 'react-select/dist/react-select.css'
import { get } from 'lodash'
import { Permission } from '../../components/auth'
import { TEXT_BUTTOM_CLOSE, TEXT_SUBMIT_BODY } from '../../constants/Popup'
import {
  DA_XAY_RA_LOI,
  TOATS_MESSAGE_SUCCESS,
  TOATS_MESSAGE_FAIL,
  TEXT_MESSAGE_DEFAULT,
  UPDATE_lY_DO_THU_hOI,
} from '../../constants/MessageForm'
class CapNhatChiTietHoSoCongViec extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingButton: false,
      params: {},
      errorData: {},
      nhanVienPhongBanPhanTheoQuyen: [],
    }
  }

  componentDidMount() {
    this.getDataSelect()
  }

  componentWillReceiveProps(props) {
    // Reset dữ liệu form khi tắt/mở popup
    if (props.dataPopup.isOpenPopup) {
      this.setState({
        params: {},
        errorData: {},
      })
    }
  }

  /**
   * Reset params khi change radio
   * @memberof CapNhatChiTietHoSoCongViec
   */
  handleResetParams = () => {
    this.setState({
      params: {},
      errorData: {},
    })
  }

  /**
   * Get data select option
   * @memberof CapNhatChiTietHoSoCongViec
   */
  getDataSelect = async () => {
    const { auth } = this.props
    const params = {
      quyen: Permission.HSCV_PHONGBAN_DUYET,
      limit: 0,
    }
    await this.props.actions
      .getLanhDaoPhongBan(auth.mainDepartmentId, params)
      .then(res => {
        if (
          res &&
          !res.error &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.items
        ) {
          this.setState({
            nhanVienPhongBanPhanTheoQuyen: res.payload.data.result.items,
          })
        }
      })
  }

  /**
   * Change data input
   * @memberof CapNhatChiTietHoSoCongViec
   */
  handleInputChange = e => {
    const { target } = e
    this.setState({
      params: {
        ...this.state.params,
        [target.name]: target.value,
      },
    })
  }

  /**
   * Submit form data
   * @memberof CapNhatChiTietHoSoCongViec
   */
  handleSubmit = e => {
    e.preventDefault()
    const { dataPopup } = this.props
    switch (dataPopup.key) {
      case 'NOP_HOSO':
        this.checkDataNopHoSoCongViec()
        break
      case 'DUYET_HOSO':
        this.xuLyDuyetHoSo()
        break
      case 'HUY_NOP':
        this.xuLyHuyNopHoSo()
        break
      case 'DUYET_LAI':
        this.xuLyDuyetLaiHoSo()
        break
      case 'TIEP_NHAN_LAI':
        this.xuLyTiepNhanLaiHoSo()
        break
      default:
        break
    }
  }

  /**
   * Check data nộp hồ sơ công việc
   * @memberof CapNhatChiTietHoSoCongViec
   */
  checkDataNopHoSoCongViec = () => {
    const { params } = this.state
    let errorData = {}
    let isValidateSucess = true
    if (get(params, 'nguoiDuyetId', '') === '') {
      errorData.nguoiDuyetId = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }

    this.setState({
      errorData: {
        ...errorData,
      },
    })
    if (isValidateSucess) {
      this.submitNopHoSoCongViec()
    }
  }

  /**
   * PUT nộp hồ sơ công việc
   * @memberof CapNhatChiTietHoSoCongViec
   */
  submitNopHoSoCongViec = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 1,
        nguoi_duyet_id: params.nguoiDuyetId,
        ghi_chu: params.ghiChu,
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess('GO_BACK')
    }
  }

  // PUT duyệt hồ sơ
  xuLyDuyetHoSo = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      let trangThai = 2
      if (dataPopup.data.trangThai === 3) {
        trangThai = 4
      }

      const data = {
        trang_thai: trangThai,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess('GO_BACK')
    }
  }

  // PUT hủy nộp hồ sơ
  xuLyHuyNopHoSo = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 0,
        nguoi_duyet_id: get(auth, 'roleId'),
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess('GO_BACK')
    }
  }

  // PUT duyệt lại hồ sơ
  xuLyDuyetLaiHoSo = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: dataPopup.data.trangThaiTruoc + 1,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess('GO_BACK')
    }
  }

  // PUT tiếp nhận lại hồ sơ
  xuLyTiepNhanLaiHoSo = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 3,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess('GO_BACK')
    }
  }

  checkActionTraLai = e => {
    e.preventDefault()
    const { params } = this.state
    if (get(params, 'ghiChu', '').trim() === '') {
      return this.setState({
        errorData: {
          ghiChu: UPDATE_lY_DO_THU_hOI,
        },
      })
    }

    this.xuLyTraLaiHoSoCongViec()
  }

  xuLyTraLaiHoSoCongViec = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 5,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false, errorData: {} })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess('GO_BACK')
    }
  }

  render() {
    const { dataPopup } = this.props
    const {
      params,
      isLoadingButton,
      errorData,
      nhanVienPhongBanPhanTheoQuyen,
    } = this.state
    return (
      <Dialog
        isOpen={dataPopup.isOpenPopup}
        onClose={this.props.onClose}
        title={dataPopup.titlePopup}
        className={classnames('size-container-dialog pb10', {
          'size-container-dialog--width-auto': dataPopup.key === 'HUY_NOP',
        })}
      >
        <form onSubmit={this.handleSubmit}>
          <div className="pt-dialog-body container-UpdateDrawers wrapper-content-dialog border-bottom-none m0 pb10">
            <div className="form-container">
              {(get(dataPopup, 'key') === 'NOP_HOSO' ||
                get(dataPopup, 'key') === 'DUYET_HOSO' ||
                get(dataPopup, 'key') === 'DUYET_LAI' ||
                get(dataPopup, 'key') === 'TIEP_NHAN_LAI') && (
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Tên hồ sơ
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <span>{get(dataPopup.data, 'ten')}</span>
                    </div>
                  </div>
                </div>
              )}
              {get(dataPopup, 'key') === 'NOP_HOSO' && (
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Người duyệt
                        <span className="required-input">*</span>
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <div className="pt-select">
                        <select
                          className="content-select"
                          name="nguoiDuyetId"
                          onChange={this.handleInputChange}
                          value={get(params, 'nguoiDuyetId', '')}
                        >
                          <option value="">Chọn</option>
                          {nhanVienPhongBanPhanTheoQuyen &&
                            nhanVienPhongBanPhanTheoQuyen.length !== 0 &&
                            nhanVienPhongBanPhanTheoQuyen.map((item, k) => (
                              <option key={k} value={item.chucDanhId}>
                                {item.tenNhanVien}
                              </option>
                            ))}
                        </select>
                      </div>
                      {get(errorData, 'nguoiDuyetId') !== undefined && (
                        <div className="pt-form-helper-text">
                          {errorData.nguoiDuyetId}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {get(dataPopup, 'key') === 'HUY_NOP' && (
                <div>{TEXT_SUBMIT_BODY}</div>
              )}
              {(get(dataPopup, 'key') === 'NOP_HOSO' ||
                get(dataPopup, 'key') === 'DUYET_HOSO' ||
                get(dataPopup, 'key') === 'DUYET_LAI' ||
                get(dataPopup, 'key') === 'TIEP_NHAN_LAI') && (
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Ghi chú
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <textarea
                        style={{ width: '100%' }}
                        name="ghiChu"
                        rows="4"
                        className="pt-input"
                        placeholder="Nhập ghi chú"
                        dir="auto"
                        maxLength={255}
                        onChange={this.handleInputChange}
                        value={get(params, 'ghiChu', '')}
                      />
                      {get(errorData, 'ghiChu') !== undefined && (
                        <div className="pt-form-helper-text">
                          {errorData.ghiChu}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pt-dialog-footer pt-dialog-footer-group-button">
            <div className="group-button center">
              {get(dataPopup, 'key') !== 'DUYET_HOSO' && (
                <div className="group-button--content">
                  <Button
                    loading={false}
                    disabled={isLoadingButton}
                    onClick={this.props.onClose}
                  >
                    {dataPopup.textClose || TEXT_BUTTOM_CLOSE}
                  </Button>
                </div>
              )}
              {get(dataPopup, 'key') === 'DUYET_HOSO' && (
                <div className="group-button--content">
                  <Button
                    className="btn-red-bg-while"
                    loading={isLoadingButton}
                    disabled={isLoadingButton}
                    onClick={this.checkActionTraLai}
                  >
                    Trả lại
                  </Button>
                </div>
              )}
              <div className="group-button--content">
                <Button
                  className="btn-blue"
                  loading={isLoadingButton}
                  disabled={isLoadingButton}
                  type="submit"
                >
                  {dataPopup.textSubmit}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    )
  }
}

CapNhatChiTietHoSoCongViec.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  dataPopup: PropTypes.object.isRequired,
}

// const mapStateToProps = (state) => ({
//   nhanVienPhongBanPhanTheoQuyen: state.common.nhanVienPhongBanPhanTheoQuyen,
// });

export default connect(null, null)(CapNhatChiTietHoSoCongViec)
