import React, { memo, useState, useEffect } from 'react'
import csx from 'classnames'

import DropdownWrapper from './DropdownWrapper'
import { defaultLabel, qlcvLabel } from 'constants/color'

const ColorSelect = ({
  onChange = () => {},
  value: selectValue,
  sampleText,
  ...props
}) => {
  const [selected, setSelected] = useState(defaultLabel)
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = open => {
    _setDropdownOpen(open)
  }

  const onSelect = value => () => {
    setSelected(value)
    onChange(value)
    _toggleDropdown(false)
  }

  useEffect(() => {
    setSelected(selectValue || defaultLabel)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
      value={selected}
      inputType="color"
      inputStyle={{ pointerEvents: 'none' }}
      popoverHeight={200}
      inputWrapperClassName={csx('has-event', props.inputWrapperClassName)}
    >
      <div
        className="cpc-dropdown-color"
        style={{
          width: 360,
        }}
      >
        {qlcvLabel.map(color => (
          <div
            key={color}
            className={csx('cpc-dropdown-color-item', {
              active: selected === color,
            })}
            onClick={onSelect(color)}
          >
            <div
              className="cpc-dropdown-color-text"
              style={{ background: color }}
            >
              {sampleText || color}
            </div>
          </div>
        ))}
      </div>
    </DropdownWrapper>
  )
}

export default memo(ColorSelect)
