import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TinChuyenTiepItem } from '../../../components/System/service'

export default class TinChuyenTiep extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let { data } = this.props
    return (
      <div>
        {data &&
          data.map((message, key) => (
            <TinChuyenTiepItem key={key} message={message} />
          ))}
      </div>
    )
  }
}

TinChuyenTiep.propTypes = {
  data: PropTypes.array.isRequired,
}
