import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import { TEXT_BUTTOM_CLOSE } from '../../constants/Popup'
import * as Types from '../../constants/Api'

class UpdateDrawers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        ...props.item,
        nganTuChaId: props.item && props.item.nganTuChaId,
      },
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    staffs: [],
    item: {},
  }

  componentWillReceiveProps(props) {
    if (this.props.item !== props.item) {
      this.setState({
        params: {
          ...props.item,
        },
      })
    }

    if (this.props.nganTuChaId !== props.nganTuChaId) {
      this.setState({
        params: {
          ...this.state.params,
          nganTuChaId: props.nganTuChaId,
        },
      })
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      params: {
        ...this.state.params,
        [name]: value ? value : undefined,
      },
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (
      this.state.params &&
      this.state.params.stt &&
      this.state.params.stt.length > 0 &&
      isNaN(Number(this.state.params.stt))
    ) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập đúng định dạng số thứ tự',
        intent: Intent.DANGER,
      })
    }

    // if (
    //   this.state.params
    //   && this.state.params.thoiGianLuu
    //   && this.state.params.thoiGianLuu.length > 0
    //   && isNaN(Number(this.state.params.thoiGianLuu))
    // ) {
    //   return this.props.actions.commonAddToasterMessage(
    //     {
    //       message: 'Vui lòng nhập đúng định dạng thời gian lưu', intent: Intent.DANGER
    //     }
    //   );
    // }

    if (!this.state.params.tenNganTu) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập tên hồ sơ',
        intent: Intent.DANGER,
      })
    }

    ;(await this.props.onSubmit) && this.props.onSubmit(this.state.params)
    this.setState({
      params: {
        stt: '',
        kyMaHieu: '',
        tenNganTu: '',
        nguoiChiuTrachNhiemId: '',
        phuongPhap: '',
        thoiGianLuu: '',
      },
    })
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={this.props.title}
        className="size-container-dialog"
      >
        <form onSubmit={this.handleSubmit}>
          <div className="pt-dialog-body container-UpdateDrawers wrapper-content-dialog">
            <div className="form-container">
              <div className="pt-form-group">
                <div className="row item-row-input">
                  <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                    <label className="pt-label label-inline-input">
                      Số thứ tự
                    </label>
                  </div>
                  <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                    <input
                      name="stt"
                      className="pt-input"
                      placeholder="Nhập số"
                      type="text"
                      maxLength={5}
                      dir="auto"
                      value={this.state.params.stt || ''}
                      onChange={this.handleInputChange}
                      min={0}
                    />
                  </div>
                </div>
              </div>
              {/*form-group*/}
              {this.props.type !== Types.CABINET_PERSONAL && (
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Mã ký hiệu
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <input
                        name="kyMaHieu"
                        className="pt-input"
                        placeholder="Nhập mã ký hiệu"
                        type="text"
                        dir="auto"
                        value={this.state.params.kyMaHieu || ''}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/*form-group*/}
              <div className="pt-form-group">
                <div className="row item-row-input">
                  <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                    <label className="pt-label label-inline-input">
                      Tên hồ sơ
                      <span className="required-input">*</span>
                    </label>
                  </div>
                  <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                    <input
                      name="tenNganTu"
                      className="pt-input"
                      placeholder="Nhập tên hồ sơ"
                      type="text"
                      dir="auto"
                      onChange={this.handleInputChange}
                      value={this.state.params.tenNganTu || ''}
                    />
                  </div>
                </div>
              </div>
              {/*form-group*/}
              {this.props.type !== Types.CABINET_PERSONAL && (
                <div>
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Chịu trách nhiệm
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <div className="pt-select">
                          <select
                            className="content-select"
                            name="nguoiChiuTrachNhiemId"
                            onChange={this.handleInputChange}
                            value={this.state.params.nguoiChiuTrachNhiemId}
                          >
                            <option value="">Không ai cả</option>
                            {this.props.staffs.map((item, k) => (
                              <option key={k} value={item.chucDanhId}>
                                {item.tenNhanVien}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*form-group*/}
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Phương pháp
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <input
                          name="phuongPhap"
                          className="pt-input"
                          placeholder="Nhập nội dung"
                          type="text"
                          dir="auto"
                          onChange={this.handleInputChange}
                          value={this.state.params.phuongPhap || ''}
                        />
                      </div>
                    </div>
                  </div>
                  {/*form-group*/}
                  {/*form-group*/}
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Thời gian lưu
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <input
                          name="thoiGianLuu"
                          className="pt-input"
                          placeholder="Nhập thời gian lưu"
                          type="text"
                          maxLength={255}
                          dir="auto"
                          onChange={this.handleInputChange}
                          value={this.state.params.thoiGianLuu || ''}
                        />
                      </div>
                    </div>
                  </div>
                  {/*form-group*/}
                </div>
              )}
            </div>
          </div>
          <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions">
              <div className="box-button">
                <Button
                  className="button-exit"
                  text={TEXT_BUTTOM_CLOSE}
                  onClick={this.props.onClose}
                />
                <Button
                  className="button-blue"
                  text={this.props.buttonAction}
                  intent={Intent.PRIMARY}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    )
  }
}

export default UpdateDrawers
