import React, { useContext, useEffect, useState } from 'react'
import { Button, Tooltip } from '@blueprintjs/core'
import DatePicker from 'react-datepicker'
import classnames from 'classnames'
import moment from 'moment'
import { get } from 'lodash'

import { StatisticContext } from '../Statistic'
import { isValidCondition } from 'helpers/Helper'
import { Permission } from 'components/auth'
import { ResetButton, Icon } from 'components/newCommon'
import { Select } from 'components/newCommon2'

const datePickerProps = {
  readOnly: true,
  isClearable: false,
  format: 'DD/MM/YYYY',
  peekNextMonth: true,
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
  className: 'input-datepicker input-icon-datepicker',
  popperPlacement: 'auto',
  popperModifiers: {
    offset: {
      enabled: true,
      offset: '5px, 10px',
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
}
const requestDateFormat = 'YYYY-MM-DD'
const filterKeys = {
  from: 'FromDate',
  to: 'ToDate',
  phongBan: 'phongBan',
  donVi: 'donVi',
}

const StatisticFilter = ({ auth }) => {
  const [checkPermission, setCheckPermission] = useState({})
  const {
    changeFilter = () => {},
    onClear = () => {},
    units = [],
    departments = [],
    filter: {
      [filterKeys.from]: from = moment()
        .startOf('month')
        .format(requestDateFormat),
      [filterKeys.to]: to = moment().endOf('month').format(requestDateFormat),
      [filterKeys.phongBan]: phongBan = null,
      [filterKeys.donVi]: donVi = null,
    } = {},
  } = useContext(StatisticContext)
  useEffect(() => {
    let newCheck = {}
    if (isValidCondition(auth.permission, [Permission.SUPERADMIN], 'AND')) {
      newCheck = {
        ...newCheck,
        isSuperAdmin: true,
      }
    }

    if (
      isValidCondition(auth.permission, [Permission.CONGVANDEN_CHIDAO], 'AND')
    ) {
      newCheck = {
        ...newCheck,
        isLanhDaoDonVi: true,
      }
    }

    setCheckPermission(newCheck)
  }, [auth])

  return (
    <div className="layout-filter layout-filter2 mb20">
      <div className="d-flex flex-wrap">
        <div className="layout-filter-limit-width" style={{ minWidth: 210 }}>
          <div className="cpc-input-v2-filter">
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              <DatePicker
                {...datePickerProps}
                placeholderText="Từ ngày"
                selectsStart
                disabled={false}
                selected={moment(from).isValid() ? moment(from) : null}
                startDate={moment(from).isValid() ? moment(from) : null}
                endDate={moment(to).isValid() ? moment(to) : null}
                value={moment(from).isValid() ? moment(from) : null}
                maxDate={moment(to).isValid() ? moment(to) : null}
                onChange={value => {
                  let valueFormat = null
                  if (value) {
                    valueFormat = moment(value).format(requestDateFormat)
                  } else {
                    valueFormat = null
                  }

                  changeFilter({ name: [filterKeys.from] })(valueFormat)
                }}
                onChangeRaw={() => {}}
                onBlur={() => {}}
              />
              <DatePicker
                {...datePickerProps}
                placeholderText="Đến ngày"
                selectsEnd
                disabled={false}
                selected={moment(to).isValid() ? moment(to) : null}
                startDate={moment(from).isValid() ? moment(from) : null}
                endDate={moment(to).isValid() ? moment(to) : null}
                value={moment(to).isValid() ? moment(to) : null}
                minDate={moment(from).isValid() ? moment(from) : null}
                onChange={value => {
                  let valueFormat = null
                  if (value) {
                    valueFormat = moment(value).format(requestDateFormat)
                  } else {
                    valueFormat = null
                  }

                  changeFilter({ name: [filterKeys.to] })(valueFormat)
                }}
                onChangeRaw={() => {}}
                onBlur={() => {}}
              />
            </div>
          </div>
        </div>
        {get(checkPermission, 'isSuperAdmin') && (
          <div className="layout-filter-limit-width">
            <div>
              <Select
                inputWrapperClassName={'border-radius-3 input-not-ph'}
                dataSource={[
                  { donViId: '0', tenVietTat: 'Tất cả đơn vị' },
                  ...units,
                ].map(donVi => ({
                  value: donVi.donViId,
                  label: donVi.tenVietTat,
                }))}
                inputIconClassName="icon2-documents"
                onChange={changeFilter({ name: filterKeys.donVi })}
                placeholder="Tất cả đơn vị"
                // inputClearable
                onClear={onClear(filterKeys.donVi)}
                wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
                inputStyle={{ height: '35px', lineHeight: '35px' }}
                isShowArrowDropdown
                value={donVi}
              />
            </div>
          </div>
        )}
        <div className="layout-filter-limit-width">
          {(get(checkPermission, 'isLanhDaoDonVi') ||
            get(checkPermission, 'isSuperAdmin')) && (
            <div>
              <Select
                inputWrapperClassName={'border-radius-3 input-not-ph'}
                dataSource={[
                  { phongBanId: '0', maPhongBan: 'Tất cả phòng ban' },
                  ...departments,
                ].map(department => ({
                  value: department.phongBanId,
                  label: department.maPhongBan,
                }))}
                inputIconClassName="icon2-documents"
                onChange={changeFilter({ name: filterKeys.phongBan })}
                placeholder="Tất cả phòng ban"
                // inputClearable
                onClear={onClear(filterKeys.phongBan)}
                wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
                inputStyle={{ height: '35px', lineHeight: '35px' }}
                isShowArrowDropdown
                value={phongBan}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="cpc-input-v2-filter-button justify-content-end">
          <Button
            className={classnames(
              'cpc-button-filter-clear',
              'cpc-button green',
              'color-white'
            )}
            onClick={changeFilter({ name: 'RELOADS' })}
          >
            <Icon classIcon={'icon2-search'} />
            {/* icon2-pie-chart */}
          </Button>
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton
              className="ml10"
              onClick={changeFilter({ name: 'RESET' })}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default StatisticFilter
