import { Button, Intent } from '@blueprintjs/core'
import { PopupConfirm, UploadMultiple } from 'components/common'
import { MasterLayout } from 'components/layout'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { last } from 'lodash'
import { allowDocumentFileTypesLimit } from 'constants/fileTypes'
import { showToast } from 'actions/task'
import * as MESSAGE from 'constants/MessageForm'
import { renderClassStatus, renderTrangThai } from 'helpers/Helper'
import UserItem from './UserItem'
import ModalDanhSachNguoiThamGia from './ModalDanhSachNguoiThamGia'
import Title from 'components/ui/title'
import * as MESSAGER from 'constants/MessageForm'
import * as POPUP from 'constants/Popup'

const ChiTietLichHopPage = props => {
  const [detail, setDetail] = useState(null)
  const [isOpenPopupKetThuc, setIsOpenPopupKetThuc] = useState(false)

  const [dataFiles, setDataFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(0)
  const [showPopupThamGia, setShowPopupThamGia] = useState(false)

  const idDetail = props.match.params.id
  const handleRemoveFile = index => {
    const result = dataFiles?.filter((item, i) => index !== i)
    setDataFiles(result)
  }

  const handleFileInput = e => {
    let dataFilesNew = []
    let errorFile = false
    Array.from(e.target.files).forEach(item => {
      const extension = last(item.name.split('.'))
      if (extension && allowDocumentFileTypesLimit.includes(extension)) {
        dataFilesNew.push(item)
      } else {
        errorFile = true
      }
    })
    setDataFiles([...dataFilesNew, ...dataFiles])
    if (errorFile) {
      showToast({
        message: MESSAGE.TEXT_TYPE_FILE_UPLOAD,
        intent: Intent.DANGER,
      })
    }
  }

  const handleBack = () => {
    props.history.goBack()
  }

  const handleGetChiTietLichHop = async () => {
    const res = await props.actions.getChiTietLichHop(idDetail)
    if (!res.error && res?.payload?.data?.result) {
      const dataDetail = res.payload.data.result
      setDetail(dataDetail)
      setStatus(dataDetail?.trangThai)
      setDataFiles(dataDetail?.dsFileDinhKem ?? [])
    }
  }

  const handleSubmitKetThuc = () => {
    setIsLoading(true)
    props.actions.putKetThucCuocHop(detail?.cuocHopId).then(res => {
      if (res.payload && res.payload.data.result === true) {
        setIsLoading(false)
        handleBack()
        return props.actions.commonAddToasterMessage({
          message: 'Kết thúc cuộc họp thành công',
          intent: Intent.SUCCESS,
        })
      } else {
        setIsLoading(false)
        props.actions.commonAddToasterMessage({
          message:
            res?.error?.response?.data?.message || MESSAGER.TOATS_ERROR_SYSTEM,
          intent: Intent.DANGER,
        })
      }
    })
  }

  const handleClickPopupCancel = () => {
    setIsOpenPopupKetThuc(!isOpenPopupKetThuc)
  }

  useEffect(() => {
    if (idDetail) {
      handleGetChiTietLichHop()
    }
    // eslint-disable-next-line
  }, [idDetail])

  const dsNguoiThamGia = detail?.cuocHopThanhPhan?.map(item => ({
    ...item?.nhanVien,
    thamGia: item.thamGia,
  }))
  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name="Chi tiết lịch họp"
        icon="icon-info-circle"
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper form-calendar mt10">
        <div className="form-container">
          <h3 className="title-block-form">Thông tin cuộc họp</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">Tiêu đề</label>
                <div className="pt-form-view">{detail?.tieuDe}</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">Địa điểm</label>
                <div className="pt-form-view">{detail?.diaDiem?.ten}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Loại</label>
                <div className="pt-form-view">{detail?.theLoai?.ten}</div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Cấp độ</label>
                <div className="pt-form-view">{detail?.mucDo?.ten}</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                <label className="pt-label">Thời gian diễn ra</label>
                <div className="pt-form-view">
                  {detail?.ketThuc
                    ? moment(detail?.batDau).format('DD/MM/YYYY HH:mm') +
                      ' - ' +
                      moment(detail?.ketThuc).format('DD/MM/YYYY HH:mm')
                    : moment(detail?.batDau).format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                <label className="pt-label">Chủ trì</label>
                <div className="pt-form-view">{detail?.chuTri}</div>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                <label className="pt-label">Thông báo email</label>
                <div className="pt-form-view">
                  {detail?.loaiThongBao === 1 || detail?.loaiThongBao === 3
                    ? 'Có'
                    : 'Không'}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Thông báo hệ thống</label>
                <div className="pt-form-view">
                  {detail?.loaiThongBao === 2 || detail?.loaiThongBao === 3
                    ? 'Có'
                    : 'Không'}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Thời gian nhắc nhở</label>
                <div className="pt-form-view">
                  {detail?.nhacNho ? `Trước ${detail?.nhacNho} phút` : ''}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Nội dung</label>
                <div className="pt-form-view">{detail?.noiDung}</div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <UploadMultiple
                isDetail
                dataFiles={dataFiles}
                handleFileInput={handleFileInput}
                handleRemoveFile={handleRemoveFile}
              />
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                <label className="pt-label">Trạng thái</label>
                <div className="pt-form-content">
                  <span
                    className={`status-calendar text-content ${renderClassStatus(
                      status
                    )}`}
                  >
                    {renderTrangThai(status)}
                  </span>
                </div>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                <label className="pt-label">Danh sách người mời họp</label>
                <div className="pt-form-content">
                  <span className="total-person">
                    <span className="total-person__number">
                      {dsNguoiThamGia?.length || 0}
                    </span>
                    {dsNguoiThamGia?.length > 0 && (
                      <span
                        className="total-person__more"
                        onClick={() => setShowPopupThamGia(true)}
                      >
                        Xem danh sách
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h3 className="title-block-form bt">Thông tin hậu cần</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Người chuẩn bị</label>
                <div className="pt-form-content">
                  <div className="row">
                    {detail?.cuocHopHauCan?.map((item, i) => (
                      <div key={i} className="col-xs-3 col-sm-3 col-md-3">
                        <UserItem name={item?.nhanVien?.tenNhanVien} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={false}
            loading={isLoading}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          {status === 1 && (
            <Button
              className="pt-button btn-orange-color"
              onClick={() => setIsOpenPopupKetThuc(true)}
              loading={isLoading}
            >
              <span className="text-content">Kết thúc</span>
              <span className="pt-icon icon2-check-circle"></span>
            </Button>
          )}
        </div>
      </div>
      <ModalDanhSachNguoiThamGia
        data={dsNguoiThamGia}
        isOpen={showPopupThamGia}
        onClose={() => setShowPopupThamGia(false)}
      />
      <PopupConfirm
        isOpen={isOpenPopupKetThuc}
        onClose={handleClickPopupCancel}
        title={POPUP.KET_THUC_LICH_HOP}
        text={POPUP.TEXT_BUTTON_SUBMIT}
        onSubmit={handleSubmitKetThuc}
        isLoading={isLoading}
      >
        {POPUP.TEXT_SUBMIT_BODY}
      </PopupConfirm>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChiTietLichHopPage)
