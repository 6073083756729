import React from 'react'
import { Link } from 'react-router-dom'

class GroupItem extends React.Component {
  render() {
    return (
      <div className="collapse-block">
        <div className="collapse-heading-content">
          <h4 className="table-title">
            <Link
              className="link"
              to={`/he-thong/nguoi-dung/phong-ban/${this.props.phongBanId}`}
            >
              <span className="name-department">Phòng ban:</span>
              <span className="full-name">{this.props.name}</span>
            </Link>
          </h4>
          <span className="btn-icon-collapse"></span>
        </div>
        <div className="collapse-panel-content">
          <div className="table-display">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default GroupItem
