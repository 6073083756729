import React from 'react'
import moment from 'moment'

const SearchDispatchListItem = ({ index, data, handleClick }) => (
  <tr onClick={() => handleClick(data.vanBanKySoId)}>
    <td>{index > 9 ? index : '0' + index}</td>
    <td>{data.tenQuyTrinh}</td>
    <td>{data.soKyHieu}</td>
    <td>{data.ngayLap ? moment(data.ngayLap).format('DD/MM/YYYY') : ''}</td>
    <td>{data.nguoiLap && data.nguoiLap.tenNhanVien}</td>
    <td className="textarea-content">{data.trichYeu}</td>
  </tr>
)

export default SearchDispatchListItem
