import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/SignNumber'
import { GridViewFilter } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { GUID_EMPTY } from 'constants/Enum'

import { get } from 'lodash'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false
const isAll = false
const donVi = 2
const tatCa = 0
class ListInternalSignNumberPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      filterColumn: {
        phongBanLapId: GUID_EMPTY,
      },
      sort: undefined,
      listStatus: [
        {
          name: 'Chưa duyệt',
          value: 0,
        },
        {
          name: 'Không duyệt',
          value: 1,
        },
        {
          name: 'LĐPB CT đã duyệt',
          value: 2,
        },
        {
          name: 'LĐPB PH đang duyệt',
          value: 20,
        },
        {
          name: 'LĐPB PH đã duyệt',
          value: 3,
        },
        {
          name: 'LĐ ĐL PH đang duyệt',
          value: 30,
        },
        {
          name: 'LĐ ĐL PH đã duyệt',
          value: 31,
        },
        {
          name: 'LĐ PH đã duyệt',
          value: 10,
        },
        {
          name: 'TK LĐĐV đã duyệt',
          value: 4,
        },
        {
          name: 'LĐĐV đã duyệt',
          value: 5,
        },
        {
          name: 'Đã cấp số',
          value: 6,
        },
        {
          name: 'Đã phát hành',
          value: 7,
        },
        {
          name: 'LĐ PB PH ĐV Cấp trên đang duyệt',
          value: 21,
        },
        {
          name: 'LĐ PB PH ĐV Cấp trên đã duyệt',
          value: 8,
        },
        {
          name: 'Thư ký LĐ ĐV Cấp trên đã duyệt',
          value: 9,
        },
      ],
      isEmpty: false,
      page: 1,
      isLoading: false,
      isNeedSignNumber: true,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soKyHieu: null,
      isError: false,
      departments: [],
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickNeddSignNumber = this.handleClickNeddSignNumber.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.onChangeSelectTreeFilter = this.onChangeSelectTreeFilter.bind(this)
  }
  static propTypes = {
    signNumbers: PropTypes.object.isRequired,
  }

  async componentDidMount() {
    // this.props.actions.commonFetchDepartmentsLeader();
    // this.props.actions.commonFetchStaffByUnitId(this.props.auth.mainUnitId);
    await this.setState({
      isLoading: true,
    })
    await this.props.actions
      .getDepartmentUnit(this.props.auth.mainUnitId, !isAll)
      .then(res => {
        if (res.payload && res.payload.data && res.payload.data.result) {
          let data = [
            {
              donViId: null,
              level: 0,
              maPhongBan: null,
              phongBanId: GUID_EMPTY,
              stt: 0,
              tenPhongBan: null,
              tenVietTat: 'Tất cả',
            },
          ]
          res.payload.data.result.forEach(item => {
            if (item.phongBanId) {
              data = [...data, item]
            } else {
              this.props.actions
                .getDepartmentUnit(item.donViId, isAll)
                .then(res => {
                  data = [
                    ...data,
                    {
                      ...item,
                      dsPhongBanCon: res.payload.data.result,
                    },
                  ]
                })
                .then(() => {
                  this.setState({
                    departments: data,
                  })
                })
            }
          })
          this.setState({
            departments: data,
          })
        }
      })

    let preData = Tool.Utils.preData('ListInternalSignNumberPage')
    let state = this.state

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.isNeedSignNumber = preData.canKySo
      state.filterColumn = {
        ...preData.filterColumn,
        ngayLap:
          preData.filterColumn && preData.filterColumn.ngayLap
            ? moment(preData.filterColumn.ngayLap)
            : null,
        phongBanLapId: get(preData, 'filterColumn.phongBanLapId') || GUID_EMPTY,
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.setState({ page })
    this.submitFilter({}, page, this.state.sort, this.state.isNeedSignNumber)
  }

  handleClickNeddSignNumber = () => {
    if (this.state.isNeedSignNumber) {
      return this.setState(
        {
          isNeedSignNumber: false,
          filterColumn: {
            phongBanLapId: GUID_EMPTY,
          },
          filter: {
            ...this.state.filter,
            textSearch: '',
            ngayLap: {
              from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
              to: moment().format('YYYY-MM-DD'),
            },
          },
        },
        () => {
          this.submitFilter(
            this.state.filter,
            Types.PAGE,
            this.state.sort,
            this.state.isNeedSignNumber
          )
        }
      )
    }
    this.setState(
      {
        isNeedSignNumber: true,
        filterColumn: {
          phongBanLapId: GUID_EMPTY,
        },
        filter: {
          ...this.state.filter,
          textSearch: '',
          ngayLap: {
            from: undefined,
            to: undefined,
          },
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          Types.PAGE,
          this.state.sort,
          this.state.isNeedSignNumber
        )
      }
    )
  }

  submitFilter(e = {}, page = 1, sort, canKySo = this.state.isNeedSignNumber) {
    const filter = Object.assign({}, this.state.filter, e)

    const filterColumn = {
      ...this.state.filterColumn,
      soKyHieu:
        this.state.filterColumn.soKyHieu &&
        this.state.filterColumn.soKyHieu !== ''
          ? this.state.filterColumn.soKyHieu
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      nguoiLap:
        this.state.filterColumn.nguoiLap &&
        this.state.filterColumn.nguoiLap !== ''
          ? this.state.filterColumn.nguoiLap
          : null,
      phongBanLapId:
        this.state.filterColumn.phongBanLapId &&
        this.state.filterColumn.phongBanLapId !== GUID_EMPTY
          ? this.state.filterColumn.phongBanLapId
          : null,
      tinhTrang:
        this.state.filterColumn.tinhTrang &&
        this.state.filterColumn.tinhTrang !== ''
          ? this.state.filterColumn.tinhTrang
          : null,
      ngayLap: this.state.filterColumn.ngayLap
        ? moment(this.state.filterColumn.ngayLap).format('YYYY-MM-DD')
        : null,
    }
    let isCallRequestAgain = false
    this.setState({ filter, page, isLoading: true }, () => {
      Tool.Utils.preData('ListInternalSignNumberPage', {
        filter,
        page,
        sort,
        canKySo,
        filterColumn,
      })
      // tạm thời để refresh bang false
      this.props.actions
        .signNumberInternalGet(
          { filter },
          page,
          sort,
          canKySo,
          Types.PAGINATION_LIMIT,
          REFRESH_FALSE,
          filterColumn
        )
        .then(res => {
          if (
            res &&
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort, canKySo)
          }
          let isEmpty = false
          let isError = false
          if (
            res &&
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (
            res &&
            (res.error || (res.payload && res.payload.status !== 200))
          ) {
            isError = true
          }

          if (!res) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          if (isCallRequestAgain === false) {
            this.setState({ isLoading: false, isNeedSignNumber: canKySo })
          }
          this.props.actions.refreshInternalSignNumber(false)
        })
    })
  }

  handleSubmitRemoveItem = (lydo, id) => {
    let total = 0
    let page = this.state.page
    if (
      this.props.signNumbers &&
      this.props.signNumbers.list &&
      this.props.signNumbers.list.total
    ) {
      total = this.props.signNumbers.list.total
    }

    this.props.actions
      .deleteSignNumberInternal(lydo, id)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        // this.props.actions.commonFetchStatistics();
        // Refresh danh sach ky so noi bo
        this.props.actions.refreshInternalSignNumber(true)
        this.props.actions.getNumberSignNumberPage()
        this.submitFilter(
          this.state.filter,
          page,
          this.state.sort,
          this.state.isNeedSignNumber
        )
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.setState({ sort })
    this.submitFilter(
      this.state.filter,
      this.state.page,
      sort,
      this.state.isNeedSignNumber
    )
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }
    this.setState({
      isRedirect: true,
      redirectTo: `/ky-so/cong-van-noi-bo/chi-tiet/${data.kySoId}`,
      fileId: data.fileId,
      soKyHieu: data.soKyHieu,
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (name === 'phongBanLapId' || name === 'tinhTrang') {
          this.submitFilter(
            this.state.filter,
            this.state.page,
            this.state.sort,
            this.state.isNeedSignNumber
          )
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          this.state.page,
          this.state.sort,
          this.state.isNeedSignNumber
        )
      }
    )
  }

  async clearFilterColumn(filter, type = null) {
    let isNeedSignNumber = this.state.isNeedSignNumber
    if (type === 'reset') {
      this.props.actions.getNumberSignNumberPage()
      isNeedSignNumber = true
      await this.setState({
        filterColumn: {
          phongBanLapId: GUID_EMPTY,
        },
      })
    }
    this.submitFilter(
      filter,
      this.state.page,
      this.state.sort,
      isNeedSignNumber
    )
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          this.state.page,
          this.state.sort,
          this.state.isNeedSignNumber
        )
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  onChangeSelectTreeFilter(name, value) {
    if (!value) {
      return
    }
    if (value === tatCa && value !== donVi) {
      return this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: GUID_EMPTY,
          },
        },
        () => {
          this.submitFilter(
            this.state.filter,
            this.state.page,
            this.state.sort,
            this.state.isNeedSignNumber
          )
        }
      )
    } else if (value !== tatCa && value !== donVi) {
      this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          this.submitFilter(
            this.state.filter,
            this.state.page,
            this.state.sort,
            this.state.isNeedSignNumber
          )
        }
      )
    }
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soKyHieu: this.state.soKyHieu,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }

    return (
      <MasterLayout typeSidebar="signNumber">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-Kyso_Congvandi icon-heading"></span>
                  <span className="text-heading">Công văn nội bộ ký số</span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              staffs={this.props.staffs}
              status={this.state.listStatus}
              isNeedSignNumber={this.state.isNeedSignNumber}
              onClickNeddSignNumber={this.handleClickNeddSignNumber}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.signNumbers.list.total / Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                type="cong-van-noi-bo"
                page={this.state.page}
                headers={headers.listSendSignNumberPage}
                itemShape={ListItem}
                data={this.props.signNumbers.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onClickDetail={this.handleClickDetail}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                auth={this.props.auth}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                tinhTrang={this.state.listStatus}
                phongBanLapId={this.state.departments}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                match={this.props.match}
                onChangeSelectTree={this.onChangeSelectTreeFilter}
              />
              {!this.state.isLoading && (
                <Pagination
                  current={this.state.page}
                  total={Math.ceil(
                    this.props.signNumbers.list.total / Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,

  signNumbers: {
    ...state.signNumbers,
    list: {
      ...state.signNumbers.list,
      items: state.signNumbers.list.items.map(
        id => state.entities.signNumbers[id]
      ),
    },
  },

  auth: state.auth,

  staffs: state.common.staffs,

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListInternalSignNumberPage)
