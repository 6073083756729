import React, { memo, useState, useEffect, useRef } from 'react'
import { get } from 'lodash'
import { Dialog } from '@blueprintjs/core'
import { Document, Page, pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

import { EmptyDataTable } from 'components/common'
import { Loading } from 'components/newCommon'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
const PrevewFileTrinhKy = ({
  isOpenDialog = false,
  file = null,
  closeDialog = () => {},
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const [isOpen, setIsOpen] = useState(false)
  const [fileBase64, setFileBase64] = useState(null)

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
  }

  useEffect(() => {
    if (file) {
      getBase64(file, result => {
        setFileBase64(result)
      })
    } else {
      setFileBase64(undefined)
    }
  }, [file])

  const toggleDialog = () => {
    mountedSet(setIsOpen, false)
    closeDialog()
  }

  const renderError = type => {
    if (type === 'LOADING') {
      return <Loading />
    }

    return (
      <EmptyDataTable className={'small'} message="File trình ký ko hợp lệ" />
    )
  }

  const [numPages, setNumPages] = useState(null)
  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    mountedSet(setNumPages, nextNumPages)
  }

  useEffect(() => {
    mountedSet(setIsOpen, isOpenDialog)
  }, [isOpenDialog])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      isOpen={isOpen}
      onClose={toggleDialog}
      title={'Preview file nội dung trình ký'}
      className="size-container-dialog size-container-dialog--width-limit"
    >
      <div onContextMenu={e => e.preventDefault()} className="iframe-pdf">
        <div className="iframe-pdf-block">
          <Document
            className="iframe-pdf-box"
            file={`${fileBase64}`}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: 'cmaps/',
              cMapPacked: true,
              maxImageSize: -1,
            }}
            error={() => renderError('ERROR')}
            noData={() => renderError('NODATA')}
            loading={() => renderError('LOADING')}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                width={800}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </div>
    </Dialog>
  )
}

export default memo(PrevewFileTrinhKy)
