import { schema } from 'normalizr'

const ReceiveDocuments = new schema.Entity(
  'receiveDocuments',
  {},
  {
    idAttribute: receiveDocuments =>
      receiveDocuments.id || receiveDocuments.congVanDenId,
  }
)

export default ReceiveDocuments
