import React, { memo } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { isEmpty } from 'lodash'

const CustomProgress = ({ progress = 0, status = {} }) => {
  return (
    <CircularProgressbar
      value={progress}
      maxValue={100}
      text={`${progress}%`}
      strokeWidth={8}
      background
      styles={buildStyles({
        rotation: 0,
        strokeLinecap: 'round',
        textSize: '25px',
        pathTransitionDuration: 0.5,
        textColor: !isEmpty(status) && status.read ? '#195FFF' : '#000',
        pathColor: '#00B051', // Màu tiến trình đã hoạt động
        trailColor: '#ffffff', // Màu thanh tiến trình còn thiếu
        backgroundColor: '#F8FAFF',
      })}
    />
  )
}

export default memo(CustomProgress)
