import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../../components/layout'
import ListFilter from './ListFilter'
import StatisticItem from './StatisticItem'
import StatisticList from './StatisticList'
import { HeadingPage } from '../../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../../constants/Table'
import moment from 'moment'
import { get } from 'lodash'
import { commonAddToasterMessage } from '../../../actions'
import { TEXT_MESSAGE_TIME } from '../../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'

const today = () => moment()
const oneMonthAgo = () => moment().subtract(1, 'months')

class KsttStatisticPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        date: {
          start: oneMonthAgo(),
          end: today(),
        },
      },
      loading: {
        statistic: false,
      },
      error: {
        statistic: false,
      },
      statisticList: [],
    }
  }

  setCurrentSelectDate = () => {
    const startDate = localStorage.getItem('thongkeKstt-start')
    const endDate = localStorage.getItem('thongkeKstt-end')
    const start = startDate && endDate ? moment(startDate) : oneMonthAgo()
    const end = endDate && endDate ? moment(endDate) : today()
    this.setState({
      filter: {
        date: {
          start,
          end,
        },
      },
    })
  }

  componentDidMount() {
    const { match } = this.props
    const unitId = get(match, 'params.id')
    this.setCurrentSelectDate()
    this.getStatistic(unitId)

    window.onbeforeunload = () => {
      this.resetDate()
      return
    }
  }

  resetDate = () => {
    const { match } = this.props
    const unitId = get(match, 'params.id')
    if (!unitId) {
      this.storageDate('start', oneMonthAgo())
      this.storageDate('end', today())
    }
  }

  dateChange = (type, selectedDate) => {
    if (type) {
      const {
        filter: { date },
      } = this.state
      this.setState({
        filter: {
          date: {
            ...date,
            [type]: selectedDate,
          },
        },
      })
      this.storageDate(type, selectedDate)
    }
  }

  storageDate = (type, date) => {
    localStorage.setItem(
      `thongkeKstt-${type}`,
      date ? date.format('MM/DD/YYYY') : ''
    )
  }

  getStatistic = async unitId => {
    const {
      filter: { date },
    } = this.state
    const { showToast } = this.props

    if (!date.start || !date.end) {
      return showToast({ message: TEXT_MESSAGE_TIME, intent: Intent.WARNING })
    }
    await this.setState({
      loading: {
        statistic: true,
      },
    })
    await this.setState({
      loading: {
        statistic: false,
      },
    })
  }

  render() {
    const {
      filter: { date },
      loading,
      error,
      statisticList,
    } = this.state
    const { match, history } = this.props
    const id = get(match, 'params.id')

    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="page-utilities-container">
          <HeadingPage
            namePage="Thống kê ký số tập trung"
            iconPage="icon-Tong_Hop"
          >
            {id && (
              <div
                className="button-heading-right pull-right"
                style={{ margin: 6 }}
              >
                <button
                  className="pt-button pt-button-back"
                  type="button"
                  onClick={history.goBack}
                >
                  <span className="pt-icon pt-icon-chevron-left pt-align-light"></span>
                  <span className="text-content">Quay lại</span>
                </button>
              </div>
            )}
          </HeadingPage>
          <div className="page-list-container page-list-GeneralDocumentary">
            <ListFilter
              submit={this.getStatistic}
              date={date}
              dateChange={this.dateChange}
              loading={loading.statistic}
              match={match}
            />
            <div className="table-container list-GeneralDocumentary">
              <StatisticList
                classNames="pt-table"
                header={headers.thongKeKstt}
                itemShape={StatisticItem}
                data={statisticList}
                loading={loading.statistic}
                error={error.statistic}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
})

export default connect(null, mapDispatchToProps)(KsttStatisticPage)
