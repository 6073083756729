import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  dataDetail: {},
}

export default function opinions(state = initialState, action) {
  switch (action.type) {
    case types.API_REQUEST_OPINION:
      return {
        ...state,
        list: initialState.list,
      }
    case types.OPINION_SEND:
      return {
        ...state,
        list: {
          page: action.payload.data.result.page,
          total: action.payload.data.result.total,
          items:
            action.payload.data.result && action.payload.data.result.items
              ? action.payload.data.result.items
              : [],
        },
      }
    case types.OPINION_DETAIL:
      return {
        ...state,
        dataDetail: action.payload.data.result,
      }
    default:
      return state
  }
}
