import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Classes, Intent } from '@blueprintjs/core'
import { get, isArray, size } from 'lodash'
import { withRouter } from 'react-router-dom'
import csx from 'classnames'

import {
  CollapseMore,
  TimelineItem,
  FileGroup,
  Upload,
  Loading,
} from 'components/newCommon'
import { Input } from 'components/newCommon2'
import requestAction from 'helpers/request'
import { postUpload, postBaoCaoCongViec, getDsBaoCao } from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'
import { documentTypes } from 'constants/fileTypes'

const NEW_ID = 'NEW'

const Report = ({ match, allowAction }) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const { id: taskId } = match.params

  const [fetching, setFetching] = useState(true)
  const [reports, setReports] = useState([])
  const [editId, setEditId] = useState(null)
  const [loading, setLoading] = useState(false)

  const [description, setDescription] = useState('')
  const [validateErrors, setValidateErrors] = useState(null)
  const [files, setFiles] = useState([])

  const toggleEdit = useCallback(
    (id = null) =>
      () => {
        if (!id) {
          _mountedSet(setDescription, '')
          _mountedSet(setFiles, [])
        }
        if (!loading) _mountedSet(setEditId, id)
      },
    [loading]
  )

  const changeFile = fileList => {
    _mountedSet(setFiles, fileList)
  }

  const _validate = () => {
    if (!description.trim() && !size(files)) {
      _mountedSet(setValidateErrors, {
        description: true,
      })
      throw new Error('warning')
    }
  }

  const _upload = async () => {
    if (!size(files)) {
      return []
    }

    let responseFiles = []
    const success = await requestAction({
      action: () => postUpload(files),
      afterResponse: (result = []) => {
        responseFiles = result
      },
      showToastSucess: false,
      codeCheck: false,
      errorMessage: Toast.FAIL(Action.UPLOAD),
    })

    if (!success) {
      throw new Error()
    }

    return responseFiles
  }

  const createSubmit = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setLoading, true)
        _validate()
      },
      action: async () => {
        _mountedSet(setValidateErrors, null)
        const responseFiles = await _upload()
        return postBaoCaoCongViec(taskId, {
          description: description ? description.trim() : null,
          files: responseFiles.map(file => file.id),
        })
      },
      afterResponse: result => {
        _mountedSet(setEditId, null)
        _mountedSet(setDescription, '')
        _mountedSet(setFiles, [])
        _mountedSet(setReports, [result, ...reports])
      },
      afterAction: () => {
        _mountedSet(setLoading, false)
      },
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.REPORT),
      errorMessage: Toast.FAIL(Action.REPORT),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const _fetchReportList = useCallback(async () => {
    if (!taskId) {
      return
    }
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getDsBaoCao(taskId),
      afterResponse: ({ items = [] }) => _mountedSet(setReports, items),
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [taskId])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchReportList()
  }, [_fetchReportList])

  if (!allowAction && !size(reports)) {
    return null
  }

  return (
    <div className="cpc-detail-block">
      <label
        className={csx(
          'd-block',
          'font-size-13',
          'font-weight-600',
          'uppercase'
        )}
      >
        Báo cáo
      </label>

      {fetching ? (
        <Loading />
      ) : (
        <CollapseMore textShow="Xem tất cả" textHide="Thu gọn">
          {isArray(reports) &&
            reports.map(({ id, creationTime, description, files }) => (
              <TimelineItem
                className="border-bottom"
                key={id}
                time={creationTime}
              >
                {!!description && (
                  <p className={csx('cpc-timeline-description', 'mt5')}>
                    {description && (
                      <span
                        className="word-break-word"
                        dangerouslySetInnerHTML={{
                          __html: description?.replace(/\n/g, '<br>'),
                        }}
                      />
                    )}
                  </p>
                )}
                {!!size(files) && (
                  <div className="font-size-13 d-flex">
                    <FileGroup list={files || []} />
                  </div>
                )}
              </TimelineItem>
            ))}
        </CollapseMore>
      )}

      {editId === NEW_ID && (
        <div className="ph10 cpc-form">
          <Input
            wrapperClassName="mb10"
            disabled={loading}
            label="Mô tả"
            maxLength={500}
            onChange={text => _mountedSet(setDescription, text)}
            placeholder="Nhập..."
            rows={3}
            type="textarea"
            value={description}
            warning={get(validateErrors, 'description')}
          />
          <div className="align-center">
            <span>
              <Button
                intent={Intent.PRIMARY}
                loading={loading}
                className={csx(
                  'cpc-button',
                  'text-uppercase',
                  'font-size-13',
                  'ph10',
                  'mr10'
                )}
                onClick={createSubmit}
              >
                Lưu
              </Button>
              <Button
                className={csx(
                  'cpc-button',
                  'default',
                  'no-border',
                  'text-uppercase',
                  'font-size-13',
                  'ph10',
                  'mr10'
                )}
                disabled={loading}
                onClick={toggleEdit()}
              >
                Hủy
              </Button>
            </span>

            <Upload
              onChange={changeFile}
              accept={documentTypes.toString()}
              fileList={files || []}
              wrapperClassName="flex-auto"
            />
          </div>
        </div>
      )}

      {allowAction && (
        <Button
          className={csx(Classes.MINIMAL, 'cpc-button', 'no-hover', 'pv0')}
          intent={Intent.PRIMARY}
          iconName="plus"
          text="Thêm"
          onClick={toggleEdit(NEW_ID)}
        />
      )}
    </div>
  )
}

export default memo(withRouter(Report))
