import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FileDownload from 'file-saver'
import { Intent, Button } from '@blueprintjs/core'
import moment from 'moment'

import * as Tool from '../../helpers'
import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItem,
} from '../../components/Utilities/GerenalDocumentary'
import { GridView } from '../../components/common'
import * as Actions from '../../actions'
import { headers } from '../../constants/Table'
import HeadingPanel from 'components/common/HeadingPanel'
import { WEB_NAME } from 'constants/Api'

class GerenalDocumentaryPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      sort: undefined,
      isEmpty: false,
      page: 1,
      isLoading: false,
      isError: false,
      isShowMessengStatistics: true,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      `ListGeneralDocument`,
      null,
      'thong-ke-cong-van'
    )
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    } else if (this.props.match && !this.props.match.params.id) {
      state.filter = {
        start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      if (this.props.match && this.props.match.params.id) {
        const filter = {
          start_date: this.state.filter.start_date,
          end_date: this.state.filter.end_date,
          don_vi_id: this.props.match.params.id,
        }
        this.setState({ filter }, () => {
          this.submitFilter(this.state.filter, this.state.page, this.state.sort)
        })
      } else {
        let isLoadPage = Tool.Utils.getData('isLoadPage')
        if (isLoadPage) {
          const filter = {
            start_date: this.state.filter.start_date,
            end_date: this.state.filter.end_date,
          }
          this.setState({ filter }, () => {
            Tool.Utils.saveData('isLoadPage', false)
            this.submitFilter(
              this.state.filter,
              this.state.page,
              this.state.sort
            )
          })
        } else {
          const filter = {
            start_date: this.state.filter.start_date,
            end_date: this.state.filter.end_date,
          }
          this.setState({ filter })
        }
      }
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    this.setState(
      { filter, page, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(
          `ListGeneralDocument`,
          { filter, page, sort },
          'thong-ke-cong-van'
        )
        this.props.actions
          .getGeneralDocument(
            filter,
            page,
            sort,
            this.props.config.refreshStatistic
          )
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(false)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  handleExport() {
    const { filter, page } = this.state

    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportGeneralDocument(filter, page)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData(`ListGeneralDocument`)
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-cong-van-${moment(preData.filter.start_date).format(
              'L'
            )}-${moment(preData.filter.end_date).format('L')}.xls`
          )
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    })
  }

  goBack() {
    this.props.history.goBack()
    Tool.Utils.saveData('isLoadPage', true)
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.id
                ? this.goBack
                : null
            }
            leftTitle="Thống kê công văn"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={this.state.isShowMessengStatistics}
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container page-list-GeneralDocumentary">
            <ListFilter
              signNumberDepartmentLeaders={
                this.props.signNumberDepartmentLeaders
              }
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              filter={this.state.filter}
              readOnly={this.props.readOnly}
              isLoading={this.state.isLoading}
              match={this.props.match}
            />

            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={this.state.page}
                headers={
                  WEB_NAME === 'Darsitec'
                    ? headers.generalDocumentDarsitec
                    : headers.generalDocument
                }
                itemShape={ListItem}
                data={this.props.generalDocument.items}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError}
                isShowMessengStatistics={this.state.isShowMessengStatistics}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  generalDocument: {
    ...state.generalDocument,
    items: state.common.generalDocument,
  },

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GerenalDocumentaryPage)
