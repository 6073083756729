import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'

class ConvergeSignatureItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClickShowDetail: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClickShowDetail = this.handleClickShowDetail.bind(this)
  }

  state = {
    vanBanKySoId: '',
    fileId: null,
  }

  handleClickShowDetail = () => {
    this.props.onClickShowDetail &&
      this.props.onClickShowDetail(
        this.state.vanBanKySoId,
        this.state.fileId,
        'ConvergeSignature',
        get(this.props.item.nguoiLap, 'chucDanhId')
      )
  }

  componentDidMount() {
    this.setState({
      vanBanKySoId: this.props.item.vanBanKySoId,
      fileId: this.props.item.fileId,
    })
  }

  render() {
    const date = this.props.item.ngayCongVan
      ? moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
      : null
    return (
      <div className="item">
        {date && (
          <p className="date-update" onClick={this.handleClickShowDetail}>
            {date}
          </p>
        )}
        <p className="code" onClick={this.handleClickShowDetail}>
          {this.props.item.soVanBan}
        </p>

        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Ký hiệu:</span>
          <span className="info">{this.props.item.soCongVan}</span>
        </p>

        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Trích yếu:</span>
          <span className="info textarea-content">
            {this.props.item.trichYeu}
          </span>
        </p>

        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Người lập:</span>
          <span className="info">
            {this.props.item.nguoiLap
              ? this.props.item.nguoiLap.tenNhanVien
              : ''}
          </span>
        </p>
      </div>
    )
  }
}

export default ConvergeSignatureItem
