import React, { Component } from 'react'
import { Checkbox, Button, Dialog, Intent } from '@blueprintjs/core'
import { SelectTree, PhongBanVSCaNhan } from '../../../components/common'
import { IMAGE_URL } from '../../../constants/Api'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../../actions'
import { Utils } from '../../../helpers'
import AvatarEditor from 'react-avatar-editor'
import classnames from 'classnames'
import * as MESSAGE from '../../../constants/MessageForm'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import { checkExtension } from '../../../helpers/Helper'
import { FILE_URL } from '../../../constants/Api'

class FormAddService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) || undefined,
      donViId: this.props.auth.mainUnitId,
      isLoadingSelect: true,
      isLoadingButton: false,
      isDisabledSubmit: false,
      isOpenDialog: false,
      isLoadingData: false,
      fileDinhKem: {
        fileUpload: null,
        fileName: '',
        sizeFile: null,
        isLoadingFile: false,
        url: '',
      },
      data: {
        email: null,
        tenNhanVien: '',
        username: '',
        suDung: true,
        nguoiDuocCapId: '',
      },
      file: {
        name: '',
        size: null,
        scale: 1,
        rotate: 0,
        fileRequest: null,
        preview: {},
        url: '',
      },
      random: Math.random(),
      error: {},
      PhongBanDS: [],
      donViData: [],
      phongBanChecked: [],
      users: [],
      userChecked: [],
      tenNguoiDuocCap: '',
      showPopup: false,
    }
  }

  componentDidMount() {
    if (this.state.id) {
      this.getDetail()
    }

    // Gọi api get danh sách phòng ban theo đơn vị
    this.getPhongBanTheoDonVi()
    // Get danh sach don vi
    this.props.actions.getCategoryUnitMultiLevel()
    // Get don vi id
    let donViId = Utils.getData('Role', {})
    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    this.setState({ donViId })
    if (this.props.unitSelect.items.lenght !== 0) {
      this.setState({
        isLoadingSelect: false,
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.userChecked !== this.state.userChecked) {
      let donViChecked = []
      this.state.userChecked.forEach(nhanVienId => {
        let user = this.state.users.find(nv => nv.nhanVienId === nhanVienId)
        if (donViChecked.findIndex(id => id === user.donViId) === -1) {
          donViChecked.push(user.donViId)
        }
      })
      this.setState({
        donViChecked,
      })
    }
  }

  getPhongBanTheoDonVi = async (id, key) => {
    if (id && key) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          [key]: true,
        },
      })
    }
    let donViId = id ? this.state[id] : this.state.donViId
    this.props.actions.getCategoryDepartmentMultiLevel(donViId).then(res => {
      if (res && res.error) {
        return
      } else if (res.payload && res.payload.data.result) {
        const data = res.payload.data.result
        let phongBanData = this.getPhongBanData(data, donViId)
        let donViData = [...this.state.donViData]
        if (donViData.filter(item => item.donViId === donViId).length === 0) {
          donViData.push({
            donViId: donViId,
            dsPhongBan: phongBanData,
          })
        }
        if (!key && !id) {
          this.setState({
            donViData: donViData,
            phongBan: data,
            phongBanId: data.length > 0 ? data[0].phongBanId : null,
            isLoading: {
              ...this.state.isLoading,
              phongBan: false,
            },
          })
        } else {
          this.setState({
            donViData: donViData,
            [key]: data,
            [`${key}Id`]: data.length > 0 ? data[0].phongBanId : null,
            isLoading: {
              ...this.state.isLoading,
              [key]: false,
            },
          })
        }
      }
    })
  }

  getPhongBanData = (arr, donViId) => {
    let phongBanData = []
    let phongBanConData = []
    arr.forEach(item => {
      phongBanData.push({ ...item, donViId })
      if (item.dsPhongBanCon)
        phongBanConData = this.getPhongBanData(item.dsPhongBanCon, donViId)
      phongBanConData.forEach(pbCon => {
        if (
          phongBanData.findIndex(pb => pb.phongBanId === pbCon.phongBanId) ===
          -1
        ) {
          phongBanData.push(pbCon)
        }
      })
    })
    return phongBanData
  }

  addToPhongBanChecked = async phongBanId => {
    if (typeof phongBanId === 'object') {
      let dsIdPhongBanUnchecked = []
      phongBanId.forEach(item => {
        if (this.state.phongBanChecked.findIndex(id => id === item) === -1) {
          dsIdPhongBanUnchecked.push(item)
        }
      })
      this.setState(state => ({
        phongBanChecked: [...state.phongBanChecked, ...dsIdPhongBanUnchecked],
      }))
    } else {
      let newPhongBanChecked = [...this.state.phongBanChecked]
      if (
        this.state.phongBanChecked.filter(item => item === phongBanId)
          .length === 0
      ) {
        newPhongBanChecked.push(phongBanId)
      }
      await this.setState({
        phongBanChecked: newPhongBanChecked,
      })
    }
  }

  addToUserChecked = async (nhanVienId, multiple) => {
    if (typeof nhanVienId === 'object') {
      let dsIdNhanVienUnchecked = []
      nhanVienId.forEach(item => {
        if (
          this.state.userChecked &&
          this.state.userChecked.findIndex(id => id === item) === -1
        ) {
          dsIdNhanVienUnchecked.push(item)
        }
      })
      this.setState(state => ({
        userChecked: [...state.userChecked, ...dsIdNhanVienUnchecked],
      }))
    } else {
      let newUserChecked = [...this.state.userChecked]
      if (
        this.state.userChecked.filter(item => item === nhanVienId).length === 0
      ) {
        newUserChecked.push(nhanVienId)
        if (multiple && multiple === 1) {
          if (
            this.state.users &&
            this.state.users.find(item => item.nhanVienId === nhanVienId)
          ) {
            let userChoose = this.state.users.find(
              item => item.nhanVienId === nhanVienId
            )
            await this.setState({
              data: {
                ...this.state.data,
                nguoiDuocCapId: nhanVienId,
              },
              tenNguoiDuocCap: userChoose.tenNhanVien,
            })
          }

          await this.setState({
            userChecked: [nhanVienId],
            showPopup: false,
          })
        } else {
          await this.setState({
            userChecked: newUserChecked,
            showPopup: false,
          })
        }
      }
    }
  }

  removeFromUserChecked = async (nhanVienId, multiple) => {
    if (typeof nhanVienId === 'object') {
      let newUserChecked = [...this.state.userChecked]
      nhanVienId.forEach(item => {
        if (
          this.state.userChecked &&
          this.state.userChecked.findIndex(id => id === item) !== -1
        ) {
          newUserChecked = newUserChecked.filter(id => id !== item)
        }
      })
      this.setState(state => ({ userChecked: newUserChecked }))
    } else {
      let newUserChecked =
        this.state.userChecked &&
        this.state.userChecked.filter(item => item !== nhanVienId)

      if (multiple && multiple === 1) {
        if (
          this.state.users &&
          this.state.users.find(item => item.nhanVienId === nhanVienId)
        ) {
          let userChoose = this.state.users.find(
            item => item.nhanVienId === nhanVienId
          )
          await this.setState({
            data: {
              ...this.state.data,
              nguoiDuocCapId: nhanVienId,
            },
            tenNguoiDuocCap: userChoose.tenNhanVien,
          })
        }

        await this.setState({
          userChecked: [nhanVienId],
          showPopup: false,
        })
      } else {
        await this.setState({
          userChecked: newUserChecked,
          showPopup: false,
        })
      }
    }
  }

  addUserToTree = users => {
    let usersList = [...this.state.users]
    users.forEach(item => {
      if (
        this.state.users.findIndex(
          user => user.nhanVienId === item.nhanVienId
        ) === -1
      ) {
        usersList.push({
          ...item,
          maPhongBan: this.getMaPhongBan(item.phongBanId),
          donViId: this.state.donViId,
          tenVietTat: this.getTenVietTat(
            this.props.unitSelect.items,
            this.state.donViId
          ),
        })
      }
    })
    this.setState({
      users: usersList,
    })
  }

  getMaPhongBan = phongBanId => {
    let donVi = this.state.donViData.find(
      dv => dv.donViId === this.state.donViId
    )
    let phongBan = donVi.dsPhongBan.find(item => item.phongBanId === phongBanId)
    return phongBan.maPhongBan
  }

  getTenVietTat = (arr, donViId) => {
    let tenVietTat = ''
    arr.some(item => {
      if (item.dsDonViCon) {
        tenVietTat = this.getTenVietTat(item.dsDonViCon, donViId)
      }
      if (item.donViId === donViId) {
        tenVietTat = item.tenVietTat
      }
      if (tenVietTat) {
        return true
      } else return false
    })
    if (tenVietTat) return tenVietTat
  }

  togglePopupPhongban = e => {
    this.setState({ showPopup: !this.state.showPopup })
  }

  /**
   * Get chi tiết service
   * @memberof FormAddService
   */
  getDetail = async () => {
    this.setState({ isLoadingData: true })
    await this.props.actions.serviceDetailAPI(this.state.id)
    await this.setState(
      {
        data: this.props.dataDetail,
        tenNguoiDuocCap: _.get(this.props.dataDetail, 'tenNguoiDuocCap') || '',
      },
      () => {
        this.setState({ isLoadingData: false })
      }
    )
  }

  /**
   * Get data input và Set data
   * @memberof FormAddService
   */
  handleInputChange = e => {
    let target = e.target
    let name = target.name
    let value = target.value
    let checked = target.checked
    if (target.type === 'checkbox') {
      return this.setState({ data: { ...this.state.data, [name]: checked } })
    }

    this.setState({ data: { ...this.state.data, [name]: value } })
  }

  toggleDialog = () => {
    let { isOpenDialog } = this.state
    isOpenDialog = !isOpenDialog
    this.setState({
      isOpenDialog,
    })
  }

  /**
   * Bấm chọn file và open popup edit file ảnh
   * @memberof FormAddService
   */
  handleUploadAvatar = e => {
    let file = e.target.files[0]
    let checkFileType = checkExtension(['jpg', 'jpeg', 'png'], file.name)
    if (file.name && file.size !== 0) {
      if (!checkFileType) {
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_TYPE_FILE_UPLOAD_IMAGE,
          intent: Intent.DANGER,
        })
      }

      this.setState(
        {
          isOpenDialog: true,
        },
        () => {
          this.setState({
            file: {
              name: window.URL.createObjectURL(file),
            },
          })
        }
      )
    }
  }
  // Scale image
  handleScaleAvatar = e => {
    let { file } = this.state
    file.scale = parseFloat(e.target.value)
    this.setState({ file })
  }
  // Rotate image
  handleRotateAvatar = e => {
    let { file } = this.state
    file.rotate = parseFloat(e.target.value)
    this.setState({ file })
  }
  // Func default library
  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  /**
   * Chọn ảnh đã được scaled
   * @memberof FormAddService
   */
  handleSaveFile = () => {
    let { file } = this.state
    let img = this.editor.getImageScaledToCanvas().toDataURL()
    fetch(img)
      .then(res => res.blob())
      .then(blob => {
        file.fileRequest = Utils.blobToFile(blob, this.state.file.name)
        file.size = blob.size
        file.url = img
        this.setState({ file })
      })
    file.preview = {
      img,
      scale: file.scale,
    }
    this.setState({
      file,
    })
    this.toggleDialog()
  }

  /**
   * Choose file document upload
   * @memberof FormAddService
   */
  handleFileChange = e => {
    const error = this.state.error
    delete error.fileName
    const fileName = e.target.value
    let sizeFile = e.target.files && e.target.files[0] && e.target.files[0].size
    if (
      !fileName.trim().length &&
      this.state.fileDinhKem.fileName.length === 0
    ) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (
      !checkExtension(['pdf', 'doc', 'docx', 'xls', 'xlsx'], fileName)
    ) {
      error.fileName = MESSAGE.TEXT_TYPE_FILE_UPLOAD_REFERENCES
    } else if (sizeFile > 1024 * 1024 * 25) {
      error.fileName = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    }

    this.setState({ error })
    if (!error.fileName) {
      let reader = new FileReader()
      let file = e.target.files[0]
      reader.onloadend = () => {
        this.setState({
          fileDinhKem: {
            fileUpload: file,
            fileName: file.name,
            sizeFile,
          },
        })
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  /**
   * Chọn đơn vị
   * @memberof FormAddService
   */
  handleUnit = e => {
    if (e !== undefined) {
      this.setState(
        {
          donViId: e,
        },
        () => {
          this.getPhongBanTheoDonVi('donViId', 'phongBan')
        }
      )
    }
  }

  /**
   * POST dữ liệu
   * @memberof FormAddService
   */
  handleSubmit = async () => {
    this.setState({
      isLoadingButton: true,
    })
    let { data, file, fileDinhKem } = this.state
    let params = {
      ten_nhan_vien: data.tenNhanVien,
      username: data.username,
      mo_ta: data.moTa,
      su_dung: data.suDung,
      anh_dai_dien: data.anhDaiDien,
      nguoi_duoc_cap_id: data.nguoiDuocCapId,
      email: data.email,
      url_file_dinh_kem: data.urlFileDinhKem,
      ten_file_dinh_kem: data.tenFileDinhKem,
      kich_thuoc_file_dinh_kem: data.kichThuocFileDinhKem,
    }
    const error = {}
    if (data.tenNhanVien.trim().length === 0) {
      error.tenNhanVien = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (data.username.trim().length === 0) {
      error.username = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoadingButton: false })
      return await this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.DANGER,
      })
    }

    // Gọi hàm upload hình ảnh nếu có file hình
    if (file && file.url) {
      await this.props.actions
        .avatarUpload(file.fileRequest, data.username)
        .then(res => {
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length !== 0
          ) {
            file.url = res.payload.data.result[0].url
            this.setState({ file }, () =>
              this.props.actions.clearCacheAvatar(this.state.file.url)
            )
          } else {
            this.setState({ isLoading: false })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.MESSAGE_UPLOAD_AVATAR_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }

    //  Gọi hàm upload nếu có file tài liệu
    if (fileDinhKem && fileDinhKem.fileUpload) {
      await this.props.actions
        .profileFileUpload(fileDinhKem.fileUpload)
        .then(res => {
          if (res && (res.error || !res.payload.data.result)) {
            error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
            return this.setState({ error, isLoadingFile: false })
          }
          if (
            res &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          ) {
            fileDinhKem = {
              ...this.state.fileDinhKem,
              url: res.payload.data.result[0].url,
            }
          }
          this.setState({ fileDinhKem, isLoadingFile: false })
        })
    } else if (this.props.isUpdate === false) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
      this.setState({ error, isLoadingFile: false })
      return this.props.onSubmit(MESSAGE.TOATS_MESSAGE_WARNING, Intent.WARNING)
    }

    if (file && file.url) {
      params = {
        ...params,
        anh_dai_dien: file.url,
      }
    }

    if (fileDinhKem && fileDinhKem.fileUpload) {
      params = {
        ...params,
        url_file_dinh_kem: fileDinhKem.url ? fileDinhKem.url : null,
        ten_file_dinh_kem: fileDinhKem.fileName ? fileDinhKem.fileName : null,
        kich_thuoc_file_dinh_kem: fileDinhKem.sizeFile
          ? fileDinhKem.sizeFile
          : null,
      }
    }

    if (this.state.id) {
      params = {
        ...params,
        nhan_vien_id: data.nhanVienId,
      }
      return this.updateService(params)
    }

    this.addService(params)
  }

  /**
   * Func tạo service data
   * @memberof FormAddService
   */
  addService = params => {
    this.setState({
      isDisabledSubmit: true,
    })
    this.props.actions.serviceAddAPI(params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.status === 200 &&
        res.payload.data.result === true
      ) {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.MESSAGE_SERVICE_CREATE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        return this.goBack()
      } else if (
        res.payload &&
        res.payload.data &&
        res.payload.data.code &&
        res.payload.data.code === 409
      ) {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
        this.setState({
          error: {
            username: res.payload.data.message,
          },
          isLoadingButton: false,
          isDisabledSubmit: false,
        })
      } else {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }

      this.setState({
        isLoadingButton: false,
        isDisabledSubmit: false,
      })
    })
  }

  /**
   * Func cập nhật service data
   * @memberof FormAddService
   */
  updateService = params => {
    this.setState({
      isDisabledSubmit: true,
    })
    this.props.actions.serviceUpdateAPI(params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.status === 200 &&
        res.payload.data.code === 0
      ) {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        return this.goBack()
      } else if (
        res.payload &&
        res.payload.data &&
        res.payload.data.code &&
        res.payload.data.code === 409
      ) {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
        this.setState({
          error: {
            username: res.payload.data.message,
          },
          isLoadingButton: false,
          isDisabledSubmit: false,
        })
      } else {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    })
  }

  handleEventRemoveFile = e => {
    e.preventDefault()
    this.setState({
      fileDinhKem: {},
      data: {
        ...this.state.data,
        tenFileDinhKem: null,
        urlFileDinhKem: null,
        kichThuocFileDinhKem: 0,
      },
    })
  }

  goBack = () => {
    return this.props.history.push('/he-thong/service/danh-sach')
  }

  render() {
    let acceptFile = '.jpg, .jpeg, .png'
    let acceptFileDinhKem = '.pdf, .doc, .docx, .xls, .xlsx'
    const { file, data } = this.state
    let backgroundAvatar = this.state.file.url
      ? `${IMAGE_URL}${this.state.file.url}?preventCache=${this.state.random}`
      : `${IMAGE_URL}${data.anhDaiDien}?preventCache=${this.state.random}`
    if (this.state.file.preview && this.state.file.preview.img) {
      backgroundAvatar = this.state.file.preview.img
    }

    let departmentItemList = []
    let donVi = this.state.donViData.filter(
      item => item.donViId === this.state.donViId
    )
    let dsPhongBan =
      donVi && donVi[0] && donVi[0].dsPhongBan ? donVi[0].dsPhongBan : []
    if (dsPhongBan && dsPhongBan.length > 0) {
      dsPhongBan.forEach(
        item =>
          !item.phongBanCha &&
          departmentItemList.push(
            <PhongBanVSCaNhan
              dsPhongBan={dsPhongBan}
              userChecked={this.state.userChecked}
              phongBanChecked={this.state.phongBanChecked}
              usersList={this.state.users}
              addUserToTree={this.addUserToTree}
              actions={this.props.actions}
              key={item.phongBanId}
              isFirstChild={departmentItemList.length === 0}
              item={item}
              type="phongBan"
              addToPhongBanChecked={this.addToPhongBanChecked}
              addToUserChecked={this.addToUserChecked}
              removeFromUserChecked={this.removeFromUserChecked}
              level="1"
              multiple={1}
            />
          )
      )
    }

    const extension =
      data &&
      data.tenFileDinhKem &&
      data.tenFileDinhKem
        .slice(((data.tenFileDinhKem.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    return (
      <div className="systerm-main-content">
        <div className="form-container">
          <form className="main-form">
            <div className="form-block place-block">
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="label">Ảnh đại diện Service</label>
                    <div className="avatar-box">
                      <span
                        className="img-show"
                        style={{ backgroundImage: `url(${backgroundAvatar})` }}
                      ></span>
                      <span
                        className={classnames('icon', {
                          'bg-opacity': this.state.file.url || data.anhDaiDien,
                        })}
                      >
                        Tải lên
                      </span>
                      <input
                        type="file"
                        name="avatar"
                        onChange={this.handleUploadAvatar}
                        className="input-hidden"
                        onClick={event => {
                          event.target.value = null
                        }}
                        accept={acceptFile}
                      />
                    </div>
                    <div className="box-dialog-image">
                      <Dialog
                        iconName="inbox"
                        isOpen={this.state.isOpenDialog}
                        onClose={this.toggleDialog}
                        title="Chỉnh sửa ảnh đại diện"
                        className="pt-dialog-button"
                      >
                        <div className="pt-dialog-body box-dialog-image-body flex-center">
                          <AvatarEditor
                            ref={this.setEditorRef}
                            image={file.name}
                            width={250}
                            height={250}
                            border={20}
                            color={[10, 30, 50, 0.4]}
                            scale={file.scale}
                            // rotate={file.rotate}
                            style={{
                              margin: '0 auto',
                              border: 0,
                              background: 'transparent',
                            }}
                          />
                          <div className="box-custom-avatar">
                            <input
                              name="scale"
                              type="range"
                              className="range"
                              onChange={this.handleScaleAvatar}
                              min="1"
                              max="2"
                              step="0.01"
                              defaultValue="1"
                            />
                            {/* <input
                              name="rotate"
                              type="range"
                              className="range"
                              onChange={this.handleRotateAvatar}
                              min="0"
                              max="270"
                              step="10"
                              defaultValue="0"
                            /> */}
                          </div>
                          <div className="pt-dialog-footer">
                            <div className="pt-dialog-footer-actions">
                              <Button
                                intent={Intent.PRIMARY}
                                onClick={this.handleSaveFile}
                                text="Sử dụng ảnh"
                              />
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="form-group">
                    <label className="label required">Tên Service</label>
                    <input
                      type="text"
                      name="tenNhanVien"
                      className="input-control"
                      placeholder="Nhập tên"
                      onChange={this.handleInputChange}
                      value={data.tenNhanVien || ''}
                    />
                    {this.state.error.tenNhanVien !== undefined && (
                      <div className="form-helper-text">
                        {this.state.error.tenNhanVien}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <div className="form-group">
                    <label className="label required">Username</label>
                    <input
                      type="text"
                      name="username"
                      className="input-control"
                      placeholder="Nhập tên đăng nhập"
                      onChange={this.handleInputChange}
                      value={data.username || ''}
                    />
                    {this.state.error.username !== undefined && (
                      <div className="form-helper-text">
                        {this.state.error.username}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="label">Mô tả</label>
                    <textarea
                      name="moTa"
                      className="input-control"
                      placeholder="Nhập mô tả"
                      rows={4}
                      onChange={this.handleInputChange}
                      value={data.moTa || ''}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="form-group">
                    <label className="label">Thông tin đính kèm</label>
                    {!data.tenFileDinhKem ? (
                      <div>
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name="fileName"
                            onChange={this.handleFileChange}
                            onClick={event => {
                              event.target.value = null
                            }}
                            disabled={
                              this.state.isLoadingFile ||
                              this.state.isLoadingData
                            }
                            accept={acceptFileDinhKem}
                          />
                          <span className="pt-file-upload-input">
                            {this.state.fileDinhKem.fileName ||
                            data.tenFileDinhKem
                              ? this.state.fileDinhKem.fileName ||
                                data.tenFileDinhKem
                              : 'Chọn file đính kèm'}
                          </span>
                          {this.state.fileDinhKem.fileName && (
                            <span
                              onClick={this.handleEventRemoveFile}
                              className="xoa-file icon-close"
                            />
                          )}
                        </label>
                        {this.state.fileDinhKem.sizeFile > 0 && (
                          <p className="helper-text helper-infomation">
                            Kích thước file đã upload:
                            <span className="size-file">
                              {''}
                              {Utils.getFileSize(
                                this.state.fileDinhKem.sizeFile
                              )}
                            </span>
                          </p>
                        )}
                        {this.state.error.fileName !== undefined && (
                          <div className="helper-text">
                            {this.state.error.fileName}
                          </div>
                        )}
                      </div>
                    ) : (
                      <a
                        className="form-show-file"
                        target={'_blank'}
                        href={`${FILE_URL}${data.urlFileDinhKem}`}
                      >
                        <div className="name">
                          <span
                            style={{
                              backgroundImage: `url(${Utils.typeFile(
                                extension
                              )})`,
                            }}
                          />
                          {data.tenFileDinhKem}
                        </div>
                        <span
                          className="xoa"
                          onClick={this.handleEventRemoveFile}
                        >
                          x
                        </span>
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <div className="form-group">
                    <label className="label">Người được cấp</label>
                    <div className="chon-nguoiduoccap-block">
                      <input
                        type="text"
                        className="input-control"
                        placeholder="Chọn người được cấp"
                        readOnly
                        value={this.state.tenNguoiDuocCap}
                        onClick={this.togglePopupPhongban}
                      />
                      <span
                        className={classnames(
                          'arrow-dropdown icon-arrow-dropdown',
                          {
                            'icon-arrow-dropup': this.state.showPopup,
                          }
                        )}
                        onClick={this.togglePopupPhongban}
                      />
                      <div
                        className={classnames('popup-phongban-canhan', {
                          show: this.state.showPopup,
                        })}
                      >
                        <div className="popup-phongban-canhan-select">
                          <SelectTree
                            className="select-tree"
                            data={this.props.unitSelect.items || []}
                            type="selectCustom"
                            permission={false}
                            value={
                              this.props.unitSelect.items.length !== 0
                                ? this.state.donViId
                                : ''
                            }
                            handleChange={this.handleUnit}
                            isLoadingSelect={this.state.isLoadingSelect}
                          />
                        </div>
                        <div style={{ height: 300 }}>
                          <Scrollbars
                            autoHide
                            autoHideTimeout={100}
                            autoHideDuration={200}
                          >
                            {departmentItemList}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group form-group-inline mt20">
                    <label className="label mr10">Sử dụng</label>
                    <Checkbox
                      name="suDung"
                      checked={data.suDung}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block">
              <div className="buttton-action-footer">
                {this.state.id && (
                  <Button
                    type="button"
                    onClick={this.goBack}
                    className="pt-button button-default"
                    loading={this.state.isLoadingButton}
                    disabled={this.state.isDisabledSubmit}
                  >
                    <span className="text-content">Hủy</span>
                    <span className="pt-icon icon-Huy"></span>
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={this.handleSubmit}
                  className="pt-button btn-main-color"
                  loading={this.state.isLoadingButton}
                  disabled={this.state.isDisabledSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

FormAddService.defaultProps = {
  dataDetail: {},
}

FormAddService.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
  dataDetail: state.serviceDocuments.dataDetail,
  unitItem: state.contact.unitItem,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormAddService)
