import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import arrayToTree from 'array-to-tree'
import { Scrollbars } from 'react-custom-scrollbars'

class CabinetTree extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    listDisable: PropTypes.array,
  }

  static defaultProps = {
    listDisable: [],
    data: [],
  }

  constructor(props) {
    super(props)
    this.handleClickShowResult = this.handleClickShowResult.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
  }

  state = {
    isShow: {},
    listChecked: [],
  }

  componentDidUpdate(props) {
    if (
      this.state.listChecked.length === 0 ||
      _.isEqual(this.props.data, props.data)
    ) {
      return
    }

    this.setState({
      listChecked: [],
    })
  }

  handleClickShowResult = id => {
    this.setState({
      isShow: {
        ...this.state.isShow,
        [id]: !this.state.isShow[id],
      },
    })
  }

  handleEnabledChange = e => {
    let { listChecked } = this.state
    if (e.target.checked) {
      listChecked.push(e.target.value)
    } else {
      if (listChecked.indexOf(e.target.value) !== -1) {
        delete listChecked[listChecked.indexOf(e.target.value)]
      }
    }

    this.setState(
      {
        listChecked: _.compact(listChecked),
      },
      () => {
        this.props.onCheck && this.props.onCheck(this.state.listChecked)
      }
    )
  }

  renderSub(items) {
    return items.map((item, k) => {
      if (item.children) {
        item.dsNganTuCon = item.children
      }

      return (
        <div key={item.id} className="list-select-sub">
          {item.dsNganTuCon && (
            <div
              className="pt-save-document"
              onClick={this.handleClickShowResult.bind(this, item.id)}
            >
              <span
                className={classnames('pt-icon pull-left', {
                  'pt-icon-chevron-up': this.state.isShow[item.id],
                  'pt-icon-chevron-down': !this.state.isShow[item.id],
                })}
              />
            </div>
          )}
          <label className="pt-control pt-checkbox pt-checkbox-save-document">
            <input
              type="checkbox"
              onChange={this.handleEnabledChange.bind(this)}
              disabled={_.indexOf(this.props.listDisable, item.id) !== -1}
              value={item.id}
              checked={this.state.listChecked.indexOf(item.id) !== -1}
            />
            <span className="pt-control-indicator"></span>
            <span className="name_checkbox">{item.tenNganTu}</span>
          </label>
          {this.state.isShow[item.id] &&
            item.dsNganTuCon &&
            this.renderSub(item.dsNganTuCon)}
        </div>
      )
    })
  }

  render() {
    const categories = arrayToTree(this.props.data, {
      parentProperty: 'nganTuChaId',
    })
    return (
      <div className="group-save-document">
        <Scrollbars
          autoHideTimeout={100}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={324}
          autoHideDuration={200}
        >
          {categories.map((item, i) => {
            if (item.children) {
              item.dsNganTuCon = item.children
            }

            return (
              <div key={item.id} className="selectbox-group">
                <div className="list-select-document">
                  <div
                    className={classnames('checkbox-primary name_checkbox', {
                      'not-icon-chevron': !item.dsNganTuCon,
                    })}
                  >
                    {item.dsNganTuCon && (
                      <div
                        className="pt-save-document"
                        onClick={this.handleClickShowResult.bind(this, item.id)}
                      >
                        <span
                          className={classnames('pt-icon pull-left', {
                            'pt-icon-chevron-up': this.state.isShow[item.id],
                            'pt-icon-chevron-down': !this.state.isShow[item.id],
                          })}
                        ></span>
                      </div>
                    )}
                    <label className="pt-control pt-checkbox pt-checkbox-save-document">
                      <input
                        type="checkbox"
                        onChange={this.handleEnabledChange.bind(this)}
                        disabled={
                          _.indexOf(this.props.listDisable, item.id) !== -1
                        }
                        value={item.id}
                        checked={this.state.listChecked.indexOf(item.id) !== -1}
                      />
                      <span className="pt-control-indicator" />
                    </label>
                    <span className="name_checkbox">{item.tenNganTu}</span>
                  </div>
                  {this.state.isShow[item.id] &&
                    item.dsNganTuCon &&
                    this.renderSub(item.dsNganTuCon)}
                </div>
              </div>
            )
          })}
        </Scrollbars>
      </div>
    )
  }
}

export default CabinetTree
