import React from 'react'
import { MasterLayout } from '../../components/layout'
import { InputFormBiddingSignNumber } from '../../components/SignNumber'

class AddBiddingSignNumber extends React.Component {
  render() {
    return (
      <MasterLayout typeSidebar="signNumber">
        <div className="row center-xs format-center page-AddBiddingSignNumber">
          <div className="col-md-8 col-xs-12 col-sm-12 padding-0 container-AddBiddingSignNumber">
            <div className="heading-page bottom-add-receive-document">
              <h3 className="pull-left text-heading-page">
                <span className="icon-TaoCVDiKyso icon-heading"></span>
                <span className="text-heading">Tạo công đấu thầu ký số</span>
              </h3>
            </div>
            <InputFormBiddingSignNumber />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

export default AddBiddingSignNumber
