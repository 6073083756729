import React, { Component } from 'react'

import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import ProcedureCategoryListFilter from './ProcedureCategoryListFilter'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage } from '../../../actions'
import { connect } from 'react-redux'

const ADD_PATH = '/he-thong/danh-muc-quy-trinh/them-moi'
const PAGE_NAME = 'Danh mục quy trình'
const PAGE_ICON = 'icon-danh-muc-quy-trinh'
const ACTIONS_BUTTON = [
  {
    link: ADD_PATH,
    name: 'Thêm Mới',
    icon: 'pt-icon pt-icon-small-plus',
  },
]

class ProcedureCategoryPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      listData: [],
      isLoading: true,
      isError: false,
    }
  }

  setFetchStatus = (type, res = null) => {
    let status
    if (type === 'success') {
      status = _.get(res, 'payload.status') === 200
    } else if (type === 'error') {
      status = false
    }
    this.setState({ isError: !status, isLoading: false })
  }

  onSearch = async filter => {
    await this.setState({ isLoading: true })
    this.fetchData(filter)
  }

  fetchData = (filter = null) => {
    this.props
      .fetchAll(filter)
      .then(res => {
        this.setFetchStatus('success', res)
      })
      .catch(() => this.setFetchStatus('error'))
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillReceiveProps(nextProps) {
    if (
      _.difference(
        this.props.procedureCategory.list,
        nextProps.procedureCategory.list
      )
    ) {
      this.setState({
        listData: nextProps.procedureCategory.list,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={PAGE_NAME}
          actionsButton={ACTIONS_BUTTON}
        />
        <ProcedureCategoryListFilter onSearch={this.onSearch} />
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  procedureCategory: state.procedureCategory,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispathToProps
)(ProcedureCategoryPage)
