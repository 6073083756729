import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, union, intersection } from 'lodash'
import { Checkbox, Button, Intent, Spinner } from '@blueprintjs/core'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import moment from 'moment'
import classnames from 'classnames'

import * as Tool from '../../helpers'
import { headers } from '../../constants/Table'
import {
  MESSAGE_NOT_FOUND,
  MESSAGE_ERROR,
  DA_XAY_RA_LOI,
  TOATS_MESSAGE_DELETE_SUCCESS,
  TOATS_MESSAGE_DELETE_FAIL,
  SAPXEP_THANHCONG,
  SAPXEP_KHONGTHANHCONG,
} from '../../constants/MessageForm'
import {
  HeadingTableDragAndDrop,
  FilterTable,
  PopupConfirm,
} from '../../components/common'
import {
  XOA_TAI_LIEU,
  TEXT_BUTTON_SUBMIT,
  TEXT_SUBMIT_BODY,
} from '../../constants/Popup'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'

class DanhSachTaiLieu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadMore: false,
      isError: false,
      isEmpty: false,
      isLoadingButton: false,
      isCheckDragAndDrop: false,
      data: [],
      dataCheckbox: [],
      isMove: false,
      filterColumn: {},
      dataPopupConfirm: {
        isLoadingButton: false,
        isOpenPopup: false,
      },
      load: {
        current: 1,
        total: 1,
      },
      total: 0,
    }
  }

  async componentDidMount() {
    await this.submitFilter()
  }

  handleEnabledChange = (e, id) => {
    let { data, dataCheckbox } = this.state
    const { target } = e
    const value = target.type === 'checkbox' ? target.checked : target.value
    if (target.type === 'checkbox') {
      if (value === true) {
        dataCheckbox.push(id)
      }

      if (value === false) {
        dataCheckbox = dataCheckbox.filter(res => res !== id)
      }

      this.setState({
        dataCheckbox: union(dataCheckbox),
        data: data.map(elm => {
          if (elm.id === id) {
            return {
              ...elm,
              checked: value,
            }
          }

          return elm
        }),
      })
    }
  }

  /**
   * Xóa tài liệu hồ sơ công việc
   * @memberof DanhSachTaiLieu
   */
  handleXoaTaiLieuHSCV = async () => {
    const { dataCheckbox } = this.state
    this.setState({
      dataPopupConfirm: {
        ...this.state.dataPopupConfirm,
        isLoadingButton: true,
      },
    })
    try {
      await this.props.actions
        .deleteTaiLieuHoSoCongViec(dataCheckbox)
        .then(res => {
          if (res && res.payload && res.payload.status === 200) {
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_DELETE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            return this.submitFilter()
          }

          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        dataPopupConfirm: {
          ...this.state.dataPopupConfirm,
          isLoadingButton: false,
        },
        dataCheckbox: [],
      })
      this.handleClickPopupConfirm()
    }
  }

  /**
   * Change filter input
   * @memberof DanhSachTaiLieu
   */
  onChangeGridViewFilter = e => {
    const value = e.target.value
    const name = e.target.name
    this.setState({
      filterColumn: {
        ...this.state.filterColumn,
        [name]: value,
      },
    })
  }

  /**
   * Change filter date
   * @memberof DanhSachTaiLieu
   */
  onChangeDateGridViewFilter = (name, value) => {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  onRemoveText = name => {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: null,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  /**
   * Get submit filter
   * @memberof DanhSachTaiLieu
   */
  submitFilter = async (page = 1, sorter, isMore = false) => {
    this.setState({ isLoadMore: true })
    try {
      const { filterColumn } = this.state
      const { actions, id } = this.props

      const filter = {
        ...filterColumn,
        ngayCongVan: filterColumn.ngayCongVan
          ? moment(filterColumn.ngayCongVan).format('YYYY-MM-DD')
          : null,
        soCongVan: filterColumn.soCongVan
          ? filterColumn.soCongVan.trim()
          : null,
        coQuanBanHanh: filterColumn.coQuanBanHanh
          ? filterColumn.coQuanBanHanh.trim()
          : null,
        tenTaiLieu: filterColumn.tenTaiLieu
          ? filterColumn.tenTaiLieu.trim()
          : null,
        nguoiLuu: filterColumn.nguoiLuu ? filterColumn.nguoiLuu.trim() : null,
      }
      Tool.Utils.preData('DSTaiLieuHSCV', {
        page,
        sorter,
        filterColumn,
      })
      const response = await actions.getDanhSachTaiLieuHoSoCongViec(
        id,
        page,
        sorter,
        filter
      )
      const result = get(response, 'payload.data.result', {})
      if (!get(result, 'total')) {
        return this.setState({
          isEmpty: true,
          total: get(result, 'total'),
        })
      }
      this.setState(prev => ({
        data: isMore
          ? [...prev.data, ...get(result, 'items', [])]
          : get(result, 'items', []),
        load: {
          current: get(result, 'page', 1),
          total: Math.ceil(get(result, 'total', 1) / 10),
        },
        total: get(result, 'total'),
        isEmpty: false,
      }))
    } catch (error) {
      this.setState({ isError: true })
    } finally {
      this.setState({ isLoadMore: false })
    }
  }

  /**
   * Get more data in table
   * @memberof DanhSachTaiLieu
   */
  loadMore = () => {
    const { load } = this.state
    this.submitFilter(get(load, 'current', 1) + 1, null, true)
  }

  /**
   * Sắp xếp column DESC/ASC
   * @memberof DanhSachTaiLieu
   */
  handleSortTable = sort => {
    this.setState(
      {
        sorter: sort,
      },
      () => {
        this.submitFilter(1, this.state.sorter, false)
      }
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { data } = this.state
    let item = data.filter((elm, index) => index === oldIndex)
    const id = item[0].id
    if (oldIndex !== newIndex) {
      this.setState(
        {
          data: arrayMove(this.state.data, oldIndex, newIndex),
          isMove: true,
        },
        () => {
          const array = []
          this.state.data.map(item => array.push(item.id))
          if (array.length > 0 && this.state.isMove === true) {
            try {
              const params = {
                oldSTT: oldIndex + 1,
                newSTT: newIndex + 1,
                hoSoCongViecId: this.props.params.id,
              }
              this.props.actions.putSapXepTaiLieuHSCV(id, params).then(res => {
                if (
                  res &&
                  res.payload &&
                  res.payload.status &&
                  res.payload.status === 200
                ) {
                  return this.props.actions.commonAddToasterMessage({
                    message: SAPXEP_THANHCONG,
                    intent: Intent.SUCCESS,
                  })
                }

                return this.props.actions.commonAddToasterMessage({
                  message: SAPXEP_KHONGTHANHCONG,
                  intent: Intent.DANGER,
                })
              })
            } catch (error) {
              this.props.actions.commonAddToasterMessage({
                message: DA_XAY_RA_LOI,
                intent: Intent.DANGER,
              })
            }
          }
        }
      )
    }
  }

  handleClickPopupConfirm = () => {
    this.setState({
      dataPopupConfirm: {
        isLoadingButton: false,
        isOpenPopup: !this.state.dataPopupConfirm.isOpenPopup,
      },
    })
  }

  handleCheckboxXoa = data => {
    if (!data) {
      return true
    }

    const { auth, dataHoSoCongViec } = this.props
    if (
      (dataHoSoCongViec.trangThai === 0 || dataHoSoCongViec.trangThai === 5) &&
      ((dataHoSoCongViec.nguoiChiuTrachNhiem &&
        intersection(
          [auth.roleId],
          [dataHoSoCongViec.nguoiChiuTrachNhiem.chucDanhId]
        ).length !== 0) ||
        (data.nguoiLuu &&
          intersection([auth.roleId], [data.nguoiLuu.chucDanhId]).length !== 0))
    ) {
      return false
    }

    return true
  }

  /**
   * Vào trang thêm file
   * @memberof DanhSachTaiLieu
   */
  goThemFile = () => {
    const {
      match: { params = {} },
    } = this.props
    if (!params || !params.id) {
      return
    }

    this.props.history.push({
      pathname: `/ho-so-cong-viec/chi-tiet/${params.type}/${params.id}/them-file`,
    })
  }

  /**
   * Vào chi tiết tài liệu
   * @memberof DanhSachTaiLieu
   */
  goDetail = id => {
    if (!id) {
      return
    }

    const { type, dataHoSoCongViec } = this.props
    this.props.history.push({
      pathname: `/ho-so-cong-viec/tai-lieu/chi-tiet/${type}/${id}`,
      state: {
        data: {
          nguoiChiuTrachNhiem: get(dataHoSoCongViec, 'nguoiChiuTrachNhiem'),
          dsNguoiPhoiHop: get(dataHoSoCongViec, 'dsNguoiPhoiHop', []),
          trangThai: get(dataHoSoCongViec, 'trangThai', ''),
        },
      },
    })
  }

  render() {
    const {
      data,
      isLoadingButton,
      dataCheckbox,
      dataPopupConfirm,
      load,
      total,
      isLoadMore,
    } = this.state
    const { coQuanBanHanh, disabledButton, type, isDisableDocurmentList } =
      this.props
    let isDisbleButtonXoaTaiLieu = true
    if (dataCheckbox && dataCheckbox.length !== 0) {
      isDisbleButtonXoaTaiLieu = false
    }
    // Icon move
    const DragHandle = SortableHandle(() => (
      <span className="icon-move-drap"></span>
    ))
    // Items move
    const SortableItem = SortableElement(({ i, data }) => {
      return (
        <div className="cpc-sortable-body--row cpc-sortable-body--row-striped">
          {disabledButton === false && (
            <div className="cpc-sortable-body--cell cell-border-right center">
              <DragHandle />
            </div>
          )}
          <div className="cpc-sortable-body--cell center">
            <Checkbox
              className="checkbox-item inline"
              onChange={e => this.handleEnabledChange(e, data.id)}
              disabled={this.handleCheckboxXoa(data) || isDisableDocurmentList}
              defaultChecked={get(data, 'checked')}
            />
          </div>
          <div
            className={classnames('cpc-sortable-body--cell flex-grow-3', {
              pointer: !isDisableDocurmentList,
            })}
            onClick={
              isDisableDocurmentList ? null : () => this.goDetail(data.id)
            }
          >
            <span>{get(data, 'soCongVan')}</span>
          </div>
          <div
            className={classnames('cpc-sortable-body--cell flex-grow-3', {
              pointer: !isDisableDocurmentList,
            })}
            onClick={
              isDisableDocurmentList ? null : () => this.goDetail(data.id)
            }
          >
            <span>
              {moment(get(data, 'ngayCongVan')).isValid() &&
                moment(data.ngayCongVan).format('DD/MM/YYYY')}
            </span>
          </div>
          <div
            className={classnames('cpc-sortable-body--cell flex-grow-7', {
              pointer: !isDisableDocurmentList,
            })}
            onClick={
              isDisableDocurmentList ? null : () => this.goDetail(data.id)
            }
          >
            <span>{get(data, 'tenTaiLieu')}</span>
          </div>
          <div
            className={classnames('cpc-sortable-body--cell flex-grow-3', {
              pointer: !isDisableDocurmentList,
            })}
            onClick={
              isDisableDocurmentList ? null : () => this.goDetail(data.id)
            }
          >
            <span>{get(data, 'coQuanBanHanh')}</span>
          </div>
          <div
            className={classnames('cpc-sortable-body--cell flex-grow-3', {
              pointer: !isDisableDocurmentList,
            })}
            onClick={
              isDisableDocurmentList ? null : () => this.goDetail(data.id)
            }
          >
            <span>{get(data.nguoiLuu, 'tenNhanVien')}</span>
          </div>
        </div>
      )
    })
    // Danh sách dữ liệu
    const SortableList = SortableContainer(({ data }) => {
      return (
        <div className="sortableList">
          {data.map((elm, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              data={elm}
              i={index + 1}
            />
          ))}
        </div>
      )
    })
    return (
      <div className="panel-wrapper border">
        <div className="panel-heading">
          <h1 className="text-uppercase mb5">
            Danh sách tài liệu trong hồ sơ công việc {`${`(${total})`}`}
          </h1>
          {type !== 'don-vi' && (
            <div className="panel-heading-right">
              <div className="group-button">
                <div className="group-button--content">
                  <Button
                    className="btn-blue text-uppercase"
                    disabled={disabledButton || isLoadingButton}
                    onClick={this.goThemFile}
                  >
                    Thêm tài liệu
                    <span className="icon icon-upload ml8"></span>
                  </Button>
                </div>
                <div className="group-button--content">
                  <Button
                    className="btn-blue text-uppercase"
                    loading={isLoadingButton}
                    disabled={isDisbleButtonXoaTaiLieu || isLoadingButton}
                    onClick={this.handleClickPopupConfirm}
                  >
                    Xóa tài liệu
                    <span className="icon icon-trash ml8"></span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <PopupConfirm
          isOpen={dataPopupConfirm.isOpenPopup}
          isLoading={dataPopupConfirm.isLoadingButton}
          onClose={this.handleClickPopupConfirm}
          title={XOA_TAI_LIEU}
          text={TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleXoaTaiLieuHSCV}
        >
          {TEXT_SUBMIT_BODY}
        </PopupConfirm>
        <div className="panel-body">
          <div className="table-drag-and-drop">
            <div className="cpc-table-scroll ml--1 mr--1 mb--1">
              <div className="cpc-table-scroll-content">
                <div className="cpc-sortable">
                  <div className="cpc-sortable-head uppercase">
                    <HeadingTableDragAndDrop
                      data={headers.TaiLieuDanhSach}
                      sapXep={disabledButton}
                      onSort={this.handleSortTable}
                    />
                  </div>
                  <div
                    className="cpc-sortable-body"
                    style={{ position: 'relative' }}
                  >
                    {!this.state.isError && data && (
                      <div className="cpc-sortable-body--row">
                        <FilterTable
                          data={headers.TaiLieuDanhSach}
                          coQuanBanHanhId={coQuanBanHanh}
                          filterColumn={this.state.filterColumn}
                          sapXep={disabledButton}
                          onChange={this.onChangeGridViewFilter}
                          onChangeDate={this.onChangeDateGridViewFilter}
                          onRemoveText={this.onRemoveText}
                          onSubmit={this.submitFilter}
                        />
                      </div>
                    )}
                    {!this.state.isError &&
                      !this.state.isEmpty &&
                      data &&
                      data.length !== 0 && (
                        <div
                          style={{
                            height: 350,
                            overflowY: 'auto',
                          }}
                        >
                          <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={
                              !~~(load.current / load.total) && !isLoadMore
                            }
                            useWindow={false}
                            // loader={
                            //   <div key={0} className="bg-white">
                            //     <Spinner intent={Intent.PRIMARY} />
                            //   </div>
                            // }
                          >
                            <SortableList
                              helperClass="SortableHelper"
                              data={data}
                              onSortEnd={this.onSortEnd}
                              useDragHandle
                              lockAxis="y"
                            />
                          </InfiniteScroll>
                          {isLoadMore && data && data.length !== 0 && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                              }}
                            >
                              <Spinner className="spinner-loading small" />
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                  {/* Dữ liệu rỗng */}
                  {this.state.isEmpty && !isLoadMore && !this.state.isError && (
                    <div className="error-page-container bg-white">
                      <div className="error-content">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/empty-default.png'
                          }
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">{MESSAGE_NOT_FOUND}</p>
                      </div>
                    </div>
                  )}
                  {/* Lỗi dữ liệu */}
                  {this.state.isError && !isLoadMore && !this.state.isEmpty && (
                    <div className="error-page-container bg-white">
                      <div className="error-content">
                        <img
                          src={process.env.PUBLIC_URL + '/images/Server.png'}
                          alt="error"
                          className="img-thumb-error"
                        />
                        <p className="description-note">{MESSAGE_ERROR}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DanhSachTaiLieu.defaultProps = {
  dataHoSoCongViec: {},
  disabledButton: false,
  coQuanBanHanh: [],
  params: {},
  isDisableDocurmentList: false,
}

DanhSachTaiLieu.propTypes = {
  dataHoSoCongViec: PropTypes.object,
  disabledButton: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  coQuanBanHanh: PropTypes.array,
  params: PropTypes.object,
  isDisableDocurmentList: PropTypes.bool,
}

export default withRouter(DanhSachTaiLieu)
