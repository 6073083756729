import React, { useRef, useState, useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import classnames from 'classnames'
import { Intent } from '@blueprintjs/core'
import FileDownload from 'file-saver'
import pdfSupport from 'browser-pdf-support'
import { Document, Page } from 'react-pdf'
import print from 'print-js'
import size from 'lodash/size'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { isMobile as isMobileDetect } from 'react-device-detect'

import { FILE_URL } from 'constants/Api'
import { Icon } from 'components/newCommon'
import { NotFound, LoadingItem } from 'components/common'
import { Toast } from 'constants/MessageForm'
const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  maxImageSize: -1,
}
const controlHeight = 50
const documentOuterPart = 140
const scaleDefault = 0.75
const scaleSize = 0.2

const DetailFileContentV2 = ({ auth, actions, file, bufferHeight = 0 }) => {
  const hasPDFViewer = pdfSupport()
  let moveMouseData = {
    moveStartX: 0,
    moveStartY: 0,
    moveStartOffsetX: 0,
    moveStartOffsetY: 0,
    currentAction: 'NONE',
  }
  let offsetData = {
    offsetX: 0,
    offsetY: 0,
  }
  const refHandlers = useRef(null)
  const refDocumentBox = useRef(null)
  const refDocument = useRef(null)
  const refs = useRef([])
  const [documentPanel, setDocumentPanel] = useState(null)
  const [fullscreen, setFullscreen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageScale, setPageScale] = useState(scaleDefault)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [fileBase64, setFileBase64] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)
  const [defaultMoveMouseData, setDefaultMoveMouseData] = useState({
    offsetX: 0,
    offsetY: 0,
  })

  const refsPage = el => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el)
    }
  }

  const handleScrollbars = scrollData => {
    if (scrollData && refs && !!size(refs.current)) {
      refs.current.forEach(function (elm, index) {
        const itemIndex = index + 1
        // get location elm
        const refsElm = elm.getBoundingClientRect()
        // Chiều cao elm
        const heightElm = refsElm.height
        // Tính bottom elm
        const bottomElm = Math.abs(heightElm * itemIndex)
        // Tính top elm
        const topElm = Math.abs(bottomElm - heightElm)
        if (
          Math.abs(scrollData.scrollTop) >= topElm &&
          Math.abs(scrollData.scrollTop) <= bottomElm
        ) {
          setCurrentPage(itemIndex)
        }
      })
    }
  }

  const handleFullScreen = () => {
    setFullscreen(!fullscreen)
  }

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  const documentHeight = () => {
    if (fullscreen && documentPanel?.documentWidth) {
      return window.innerHeight - controlHeight
    }

    return (
      window.innerHeight - (documentOuterPart + controlHeight + bufferHeight)
    )
  }

  const handleResizeWidow = () => {
    window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false)
  }

  const onViewRootFile = () => {
    const token = get(auth, 'token.accessToken', '')
    let fileUrl = `${FILE_URL}/api/file/get/${file.fileId}?BearerToken=${token}`

    return window.open(fileUrl, '_blank')
  }

  const getTransform = ({ x = null, y = null }) => {
    const transforms = []
    if (x !== null || y !== null) {
      transforms.push(`translate3d(${x || 0}px,${y || 0}px,0)`)
    }

    return {
      transform: transforms.length === 0 ? 'none' : transforms.join(' '),
    }
  }

  const imageStyle = () => {
    const transform = getTransform({
      x: defaultMoveMouseData.offsetX,
      y: defaultMoveMouseData.offsetY,
    })
    return {
      ...transform,
      cursor: pageScale <= scaleDefault ? 'auto' : 'move',
    }
  }

  const handleMoveStart = ({ x: clientX, y: clientY }) => {
    moveMouseData = {
      moveStartX: clientX,
      moveStartY: clientY,
      moveStartOffsetX: offsetData.offsetX,
      moveStartOffsetY: offsetData.offsetY,
      currentAction: 'MOVE',
    }
  }

  const handleMove = ({ x: clientX, y: clientY }) => {
    if (moveMouseData.currentAction !== 'MOVE' || pageScale <= scaleDefault) {
      return
    }

    const newOffsetX =
      -(moveMouseData.moveStartX - clientX) + moveMouseData.moveStartOffsetX
    const newOffsetY =
      -(moveMouseData.moveStartY - clientY) + moveMouseData.moveStartOffsetY
    if (
      moveMouseData.offsetX !== newOffsetX ||
      moveMouseData.offsetY !== newOffsetY
    ) {
      offsetData = {
        offsetX: newOffsetX,
        offsetY: newOffsetY,
      }
      setDefaultMoveMouseData({
        offsetX: newOffsetX,
        offsetY: newOffsetY,
      })
    }
  }

  const handleMoveEnd = () => {
    moveMouseData = {
      moveStartX: 0,
      moveStartY: 0,
      moveStartOffsetX: 0,
      moveStartOffsetY: 0,
      currentAction: 'NONE',
    }
  }

  const handlePointerEvent = event => {
    switch (event.type) {
      case 'pointerdown':
        handleMoveStart(event)
        break
      case 'pointermove':
        event.preventDefault()
        handleMove(event)
        break
      case 'pointerup':
      case 'pointercancel':
        handleMoveEnd(event)
        break
      default:
        break
    }
  }

  const onPageScale = scale => {
    setPageScale(scale)
    if (scale <= scaleDefault) {
      setDefaultMoveMouseData({
        offsetX: 0,
        offsetY: 0,
      })
    }
  }

  const printError = () => {
    return actions.commonAddToasterMessage({
      message: Toast.NOT_FOUND('file in'),
      intent: Intent.WARNING,
    })
  }

  const blobToBase64 = blob => {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  const onDownload = async () => {
    if (isEmpty(file)) {
      return
    }

    const token = get(auth, 'token.accessToken', '')
    let fileUrl = `${FILE_URL}/api/file/get/${file.fileId}?BearerToken=${token}`

    const blob = await fetch(fileUrl).then(r => r.blob())
    if (blob.size !== 0) {
      FileDownload.saveAs(blob, file.fileName)
    } else {
      return actions.commonAddToasterMessage({
        message: Toast.NOT_FOUND('file download'),
        intent: Intent.WARNING,
      })
    }
  }

  const onPrint = async () => {
    let extensionFile = null
    if (get(file, 'url')) {
      extensionFile = file.url
        .slice(((file.url.lastIndexOf('.') - 1) >>> 0) + 2)
        .toUpperCase()
    }

    if (get(file, 'fileId') && extensionFile !== 'PDF') {
      return blobToBase64(fileBase64).then(res => {
        const base64String = res.replace('data:application/pdf;base64,', '')
        print({
          printable: base64String,
          type: 'pdf',
          base64: true,
          onError: printError,
        })
      })
    } else {
      const token = get(auth, 'token.accessToken', '')
      let fileUrl = `${FILE_URL}/api/file/get/${file.fileId}?BearerToken=${token}`

      print({
        printable: fileUrl,
        type: 'pdf',
        onError: printError,
      })
    }
  }

  const renderLoading = () => {
    if (isLoading) {
      return <LoadingItem />
    } else if (!isLoading && !fileBase64 && !fileUrl) {
      return <NotFound />
    }
  }

  useEffect(() => {
    let documentRefValue = null
    window.addEventListener('pointerdown', handlePointerEvent)
    if (get(refDocumentBox, 'current')) {
      documentRefValue = refDocumentBox.current
      documentRefValue.addEventListener('pointermove', handlePointerEvent)
    }

    window.addEventListener('pointerup', handlePointerEvent)
    window.addEventListener('pointercancel', handlePointerEvent)

    return () => {
      window.removeEventListener('pointerdown', handlePointerEvent)
      if (documentRefValue) {
        documentRefValue.removeEventListener('pointermove', handlePointerEvent)
      }

      window.removeEventListener('pointerup', handlePointerEvent)
      window.removeEventListener('pointercancel', handlePointerEvent)
    }
    // eslint-disable-next-line
  }, [pageScale, refDocumentBox.current])

  useEffect(() => {
    if (refHandlers?.current) {
      setDocumentPanel(prev => ({
        ...prev,
        documentWidth: refHandlers?.current?.offsetWidth,
      }))
    }
  }, [])

  const handleCheckHasFile = async fileUrl => {
    const response = await fetch(fileUrl)
    if (get(response, 'status') === 404) {
      return false
    }

    return setFileUrl(fileUrl)
  }

  useEffect(() => {
    let extensionFile = null
    if (get(file, 'url')) {
      extensionFile = file.url
        .slice(((file.url.lastIndexOf('.') - 1) >>> 0) + 2)
        .toUpperCase()
    }

    setIsLoading(true)
    if (!isEmpty(file) && extensionFile !== 'PDF' && actions) {
      actions.fileConvertPDF({ fileId: file.fileId }).then(res => {
        if (get(res, 'payload.data')) {
          if (!hasPDFViewer || isMobileDetect) {
            setFileBase64(res.payload.data)
          } else {
            setFileBase64(URL.createObjectURL(res.payload.data))
          }
        }

        setIsLoading(false)
      })
    } else if (!isEmpty(file) && extensionFile === 'PDF') {
      const token = get(auth, 'token.accessToken', '')
      let fileUrl = `${FILE_URL}/api/file/get/${file.fileId}?BearerToken=${token}`

      handleCheckHasFile(fileUrl)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [get(file, 'fileId')])

  useEffect(() => {
    window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false)
    window.addEventListener('resize', handleResizeWidow)
    return () => {
      window.removeEventListener('resize', handleResizeWidow)
    }
  }, [])

  return (
    <div>
      <p
        className={classnames('button-view-file-mobile mb15', {
          'is-mobile': isMobile,
        })}
        onClick={handleFullScreen}
      >
        <Icon
          classIcon="icon-Xem_thuc_hien"
          className="mr5 font-size-28 left-icon"
        />
        <span>Xem chi tiết công văn</span>
      </p>
      <div
        className={classnames('viewer-panel', {
          'viewer-panel__fullscreen': fullscreen,
          'is-mobile': isMobile,
        })}
        ref={refHandlers}
      >
        <div className="control-block">
          <div></div>
          <div>
            <ul>
              {numPages && (
                <li>
                  <div>
                    <span>Trang</span>
                    <span className="ml5">
                      {currentPage}/{numPages}
                    </span>
                  </div>
                </li>
              )}
              <li>
                <div className="pointer" onClick={onViewRootFile}>
                  <Icon
                    classIcon="icon-File_Tham_Khao"
                    className="mr5 font-size-16"
                  />
                  <span>XEM VĂN BẢN GỐC</span>
                </div>
              </li>
              {numPages && (
                <li>
                  <div>
                    <Icon
                      classIcon="icon-zoom-out"
                      className="font-size-16 pointer"
                      onClick={
                        pageScale < 0.3
                          ? null
                          : () => onPageScale(pageScale - scaleSize)
                      }
                    />
                    <Icon
                      classIcon="icon-zoom-in"
                      className="ml10 font-size-16 pointer"
                      onClick={
                        pageScale > 3
                          ? null
                          : () => onPageScale(pageScale + scaleSize)
                      }
                    />
                    <Icon
                      classIcon="icon-Loading"
                      className="ml10 font-size-14 pointer"
                      onClick={() => onPageScale(scaleDefault)}
                    />
                  </div>
                </li>
              )}
              <li>
                <div>
                  <Icon
                    classIcon={classnames({
                      'icon-expand-arrows': !fullscreen,
                      'icon-minimize': fullscreen,
                    })}
                    className="font-size-16 pointer"
                    onClick={handleFullScreen}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div>
            {(fileBase64 || fileUrl) && numPages && (
              <ul>
                <li>
                  <div>
                    <Icon
                      classIcon="icon2-download"
                      className="font-size-14 pointer"
                      onClick={onDownload}
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <Icon
                      classIcon="icon-In_So_Cong_Van"
                      className="font-size-15 pointer"
                      onClick={onPrint}
                    />
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="viewer-panel-document" ref={refDocumentBox}>
          {!hasPDFViewer || isMobileDetect ? (
            <Scrollbars
              style={{ width: '100%', height: '100%' }}
              onScrollFrame={handleScrollbars}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={documentHeight()}
              autoHide
            >
              <div
                className="p15"
                style={pageScale > scaleDefault ? imageStyle() : {}}
              >
                <Document
                  onContextMenu={e => e.preventDefault()}
                  file={fileBase64 || fileUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={options}
                  loading={<LoadingItem />}
                  noData={!isLoading && <NotFound />}
                  error={!isLoading && <NotFound />}
                  inputRef={refDocument}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      className="viewer-panel-document__page"
                      pageNumber={index + 1}
                      inputRef={refsPage}
                      scale={pageScale}
                      width={1123}
                    />
                  ))}
                </Document>
              </div>
            </Scrollbars>
          ) : (
            <div>
              {renderLoading()}
              {!isLoading && (fileBase64 || fileUrl) && (
                <div
                  className="iframe-document"
                  style={{ height: documentHeight() }}
                >
                  <iframe
                    frameBorder="0"
                    scrolling="no"
                    src={fileBase64 || fileUrl}
                    title="File"
                    allowFullScreen
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DetailFileContentV2
