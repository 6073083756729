import { schema } from 'normalizr'

const AssignmentHistory = new schema.Entity(
  'assignmentHistory',
  {},
  {
    idAttribute: assignmentHistory => assignmentHistory.chiDaoId,
  }
)

export default AssignmentHistory
