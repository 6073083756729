import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Intent, Classes, Checkbox } from '@blueprintjs/core'
import { get } from 'lodash'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import { Input, Icon, Tag, Scrollbar } from 'components/newCommon'
import { Input as Input2, ColorSelect } from 'components/newCommon2'
import {
  postCreateTag,
  deleteTag,
  putEditTag,
  getCongViec,
  postTagCongViec,
} from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { checkSearchNoAccents } from 'helpers/string'
import { toDecamelizeKeys } from 'helpers/key'

import { MeetingDetailContext } from '../MeetingDetail'

const FORM_TITLE = 'Quản lý loại công việc'
const NEW_ID = 'NEW'

const TagForm = ({ onClose, onSubmit, defaultFormValue, loading }) => {
  const [formData, setFormData] = useState(
    defaultFormValue || {
      backgroundColor: '#f1f1f1',
      name: '',
    }
  )

  const onChange = name => value => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const _onSubmit = () => {
    onSubmit(formData)
  }

  return (
    <div className="align-center">
      <div style={{ width: 60 }}>
        <ColorSelect
          inputWrapperClassName="p5"
          onChange={onChange('backgroundColor')}
          value={get(formData, 'backgroundColor')}
          popoverClassName="ml15"
          sampleText={get(formData, 'name')}
        />
      </div>
      <Input2
        type="text"
        onChange={onChange('name')}
        value={get(formData, 'name', '')}
        placeholder="Nhập tên loại công việc"
        wrapperClassName={classNames('mr15', 'ph5 pv10')}
        wrapperStyle={{ flex: 1 }}
      />
      <Icon
        classIcon="icon-Huy"
        className={classNames('has-event', 'size-icon-10', 'mr15', 'd-flex')}
        onClick={onClose}
      />
      <Button
        className={classNames('cpc-button', 'text-uppercase', 'font-size-13')}
        intent={Intent.PRIMARY}
        onClick={_onSubmit}
        loading={loading}
        disabled={!get(formData, 'name', '').trim()}
      >
        <Icon classIcon="icon-save" />
      </Button>
    </div>
  )
}

const WorkMeetingTags = ({ onClose = () => {}, match }) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const { tags, onTagsUpdate, onTagsSucess } = useContext(MeetingDetailContext)
  const { actionId: taskId } = match.params
  const [isFetching, setIsFetching] = useState(false)
  const [showTags, setShowTags] = useState([])
  const [editId, setEditId] = useState(null)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [selected, setSelected] = useState([])

  const toggleEdit = useCallback(
    (id = null) =>
      () => {
        if (!loadingEdit) _mountedSet(setEditId, id)
      },
    [loadingEdit]
  )

  const editProps = useMemo(
    () => ({
      onCancel: toggleEdit(),
      loading: loadingEdit,
    }),
    [toggleEdit, loadingEdit]
  )

  const _fetchTag = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setIsFetching, true),
      action: () => getCongViec(taskId),
      afterResponse: ({ workUserTags = [] }) => {
        _mountedSet(
          setSelected,
          workUserTags.map(taskTag => taskTag.workTagId)
        )
      },
      afterAction: () => _mountedSet(setIsFetching, false),
    })
  }, [taskId])

  const changeSearch = text => {
    _mountedSet(
      setShowTags,
      tags.filter(({ name }) => checkSearchNoAccents(name, text))
    )
  }

  const onCreate = formValues => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setLoadingEdit, true)
      },
      action: () =>
        postCreateTag(toDecamelizeKeys({ ...formValues, isActive: true })),
      afterResponse: result => {
        onTagsUpdate([result, ...tags])
        _mountedSet(setEditId, null)
      },
      afterAction: () => _mountedSet(setLoadingEdit, false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const onEdit = tagId => formValues => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setLoadingEdit, true)
      },
      action: () =>
        putEditTag(
          toDecamelizeKeys({ ...formValues, id: tagId, isActive: true })
        ),
      afterResponse: () => {
        const newTags =
          tags?.map(elm => {
            if (elm?.id === tagId) {
              return {
                ...elm,
                ...formValues,
              }
            }

            return elm
          }) || []
        onTagsUpdate(newTags)
        _mountedSet(setEditId, null)
      },
      afterAction: () => _mountedSet(setLoadingEdit, false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
      logError: true,
    })
  }

  const closeUpdateTag = () => {
    _mountedSet(setEditId, null)
  }

  const onDelete = tagId => e => {
    e.preventDefault()
    requestAction({
      action: () => deleteTag(tagId),
      afterResponse: () => onTagsUpdate(tags.filter(tag => tag.id !== tagId)),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  const toggleUse = tagId => e => {
    const { checked } = e.target
    const newValue = checked
      ? [...selected, tagId]
      : selected.filter(id => id !== tagId)
    _mountedSet(setSelected, newValue)
  }

  const onSave = useCallback(() => {
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => setIsFetching(true),
      action: () =>
        postTagCongViec(
          taskId,
          selected.map(select => ({ work_tag_id: select }))
        ),
      afterResponse: () => {
        onTagsSucess({
          id: taskId,
          newTags: tags
            .filter(({ id }) => selected.includes(id))
            .map(tag => ({
              ...tag,
              workTagName: tag.name,
              workTagId: tag.id,
            })),
        })
        onClose()
      },
      afterAction: () => setIsFetching(false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }, [taskId, tags, selected, onTagsSucess, onClose])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchTag()
  }, [_fetchTag])

  useEffect(() => {
    _mountedSet(setShowTags, tags)
  }, [tags])

  return (
    <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>{FORM_TITLE}</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 106}>
        <div style={{ padding: 10 }} className="border-bottom">
          <Input
            className="cpc-input-search mb5"
            leftIconName="search"
            onChange={changeSearch}
            placeholder="Tìm kiếm"
          />
          <div className="cpc-list">
            {showTags.map(({ id, name, backgroundColor }) =>
              editId === id ? (
                <TagForm
                  {...editProps}
                  key="tag-edit"
                  onSubmit={onEdit(id)}
                  onClose={closeUpdateTag}
                  defaultFormValue={{
                    name,
                    backgroundColor,
                  }}
                />
              ) : (
                <div className={classNames('cpc-list-item', 'mh10')} key={id}>
                  <Checkbox
                    className="cpc-checkbox"
                    onChange={toggleUse(id)}
                    checked={selected.includes(id)}
                    disabled={isFetching}
                  >
                    <Tag name={name} color={backgroundColor} />
                    <span className="action">
                      <i
                        className="icon-but size-icon-12 mr10 icon-color-primary"
                        onClick={toggleEdit(id)}
                      />
                      <i
                        className="icon-bin size-icon-12 icon-color-danger"
                        onClick={onDelete(id)}
                      />
                    </span>
                  </Checkbox>
                </div>
              )
            )}
          </div>
        </div>
        <div style={{ padding: 10 }}>
          {editId === NEW_ID ? (
            <TagForm
              {...editProps}
              onClose={closeUpdateTag}
              onSubmit={onCreate}
            />
          ) : (
            <Button
              className={classNames(
                Classes.MINIMAL,
                'cpc-form-button-left',
                'cpc-button'
              )}
              intent={Intent.PRIMARY}
              iconName="plus"
              text="Thêm"
              onClick={toggleEdit(NEW_ID)}
            />
          )}
        </div>
        <div
          className={classNames('element-center', 'pt10', 'pb10', 'border-top')}
        >
          <Button
            className={classNames(
              'cpc-button',
              'btn-cancel',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100',
              'mr15'
            )}
            disabled={isFetching}
            onClick={onClose}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={classNames(
              'cpc-button',
              'uppercase',
              'font-size-13',
              'ph10'
            )}
            disabled={isFetching}
            intent={Intent.PRIMARY}
            onClick={onSave}
            loading={loadingEdit}
          >
            Lưu
          </Button>
        </div>
      </Scrollbar>
    </div>
  )
}

export default memo(withRouter(WorkMeetingTags))
