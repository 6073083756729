import React, { memo } from 'react'
import { MasterLayout } from 'components/layout'
import { HeadingPage } from 'components/common'

const index = memo(() => {
  return (
    <MasterLayout typeSidebar="sidebarSystem">
      <HeadingPage
        namePage="Phân quyền chức năng"
        iconPage="icon-decentralization"
      />
      <div className="table-container table-container-full pb0"></div>
    </MasterLayout>
  )
})

export default index
