import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'
import _ from 'lodash'

import { MasterLayout } from '../../../components/layout'
import {
  DetailBoxActionKSTT,
  UpdateHistoryDetail,
} from '../../../components/SignNumber'
import {
  DetailFileContent,
  NotFound,
  LoadingLine,
  LoadingItem,
} from '../../../components/common'
import * as Actions from '../../../actions'
import * as Actions2 from '../../../actions/procedure'

const ACTIONS_VIEW = {
  TAO_CONG_VIEC: 'TAO_CONG_VIEC',
}

class ChiTietKySoTapTrung extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mainUnitId: _.get(props, 'auth.user.mainUnitId', null),
      showListAction: true,
      showDetailInfo: false,
      vanBanKySoId: _.get(props, 'match.params.id', null),
      ctKySoTapTrung: null,
      file: null,
      feedbacks: null,
      isLoadingData: {
        ctKySoTapTrung: true,
        feedbacks: true,
        historyItemSignNumber: false,
        historyItemFeedbacks: false,
        file: true,
        documentNumber: true,
        historyList: false,
        isAgreeNotNeedSignNumber: true,
      },
      listHistoryUpdate: null,
      isShowHistoryDetail: false,
      historyItem: {
        ctKySoTapTrung: null,
        feedbacks: null,
        time: null,
      },
      isLoadingMigratedFile: true,
      fileFromList: null, // File từ danh sách
      documentNumberFromList: null, // Số công văn từ danh sách
      isOpenHistoryList: false,
      isLoadedApi: {
        historyList: false,
      },
      boxMenuFixTop: false,
      isAgreeNotNeedSignNumber: false,
      searchReadOnly: false,
      showSection: null,
      documentDetail: null,
    }

    this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.handleClickShowHistoryDetail =
      this.handleClickShowHistoryDetail.bind(this)
    this.handleClickGoback = this.handleClickGoback.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleGetHistoryList = this.handleGetHistoryList.bind(this)
    this.handleRemoveFileInfo = this.handleRemoveFileInfo.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  refHandlers = {
    detailFileContent: ref => (this.detailFileContent = ref),
  }

  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll)
    // Nếu có fileId, soCongVan lấy từ danh sách
    if (this.props.location && this.props.location.state) {
      // Gọi api get thông tin file => state.fileFromList
      let fileFromList = null
      let documentNumberFromList = null
      let { isLoadingData } = this.state

      if (_.get(this.props, 'location.state.fileId', null)) {
        await this.props.actions
          .getFileInformation(_.get(this.props, 'location.state.fileId', null))
          .then(res => {
            const result = _.get(res, 'payload.data.result', null)
            if (!_.isEmpty(result)) {
              fileFromList = result
              isLoadingData = {
                ...isLoadingData,
                file: false,
              }
            }
          })
      }

      // thong tin don vi
      if (this.state.mainUnitId) {
        this.props.actions
          .getCommonFetchUnitItem(this.state.mainUnitId)
          .then(res => {
            const result = _.get(res, 'payload.data.result', {}) || {}
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isAgreeNotNeedSignNumber: false,
              },
              isAgreeNotNeedSignNumber: result.dongYKhongCanKySo,
            })
          })
      }

      // Lấy số công văn từ danh sách
      if (_.get(this.props, 'location.state.soKyHieu', null)) {
        documentNumberFromList = _.get(
          this.props,
          'location.state.soKyHieu',
          null
        )
        isLoadingData = {
          ...isLoadingData,
          documentNumber: false,
        }
      }

      if (fileFromList || documentNumberFromList) {
        await this.setState({
          fileFromList,
          documentNumberFromList,
          isLoadingData,
        })
      }
    }

    if (
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.includes('/tim-kiem-van-ban')
    ) {
      this.setState({ searchReadOnly: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleGetHistoryList = async () => {
    await this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        historyList: true,
      },
      isOpenHistoryList: !this.state.isOpenHistoryList,
    })

    await this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        historyList: false,
      },
    })
  }

  handleSubmit = async params => {
    if (params.isSuccess !== undefined && params.title) {
      if (params.isSuccess === false && params.message && params.intent) {
        return this.props.actions.commonAddToasterMessage({
          message: params.message,
          intent: params.intent,
        })
      }
      if (params.message && params.intent) {
        this.props.actions.commonAddToasterMessage({
          message: params.message,
          intent: params.intent,
        })
      }

      // Nếu ký sim thành công thì redirect về danh sách tương ứng
      if (
        (params.title === 'DongYKySoBangSIM' ||
          params.title === 'DongYKhongCanKySo') &&
        this.props.history
      ) {
        this.goBack()
      }
    }
  }

  handleClickGoback = () => {
    this.setState({ isShowHistoryDetail: false })
  }

  handleClickShowHistoryDetail = async (id, timeUpdate) => {
    if (!id || !timeUpdate) {
      return
    }
    const historyNode = ReactDOM.findDOMNode(this.refs.historyNode)

    await this.setState(
      {
        isShowHistoryDetail: true,
        isLoadingData: {
          ...this.state.isLoadingData,
          historyItemSignNumber: true,
          historyItemFeedbacks: true,
        },
        historyItem: {
          ...this.state.historyItem,
          time: timeUpdate,
        },
      },
      () => {
        historyNode.scrollIntoView({
          alignToTop: true,
          block: 'start',
          inline: 'start',
        })
      }
    )
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showReportWork: !this.state.showReportWork,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  goBack() {
    this.props.history.goBack()
  }

  handleRemoveFileInfo = () => {
    if (this.props.location && this.props.location.state) {
      this.props.history.replace({
        state: {
          ...this.props.location.state,
          fileId: null,
        },
      })
    }
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.ctKySoTapTrung &&
      this.state.ctKySoTapTrung !== prevState.ctKySoTapTrung
    ) {
      const documentDetail = {
        kySoTapTrungId: this.state.ctKySoTapTrung?.vanBanKySoId || null,
        trichYeu: this.state.ctKySoTapTrung?.trichYeu || null,
        fileThamKhao: this.state.ctKySoTapTrung?.fileNoiDung || null,
        fileDinhKemEvn: this.state.ctKySoTapTrung?.dsFileDinhKem || [],
      }
      this.setState({ documentDetail })
    }
  }

  goGiaoViecPhienBanMoi = () => {
    this.setState({
      showSection: ACTIONS_VIEW.TAO_CONG_VIEC,
    })
  }

  goBackGiaoViecPhienBanMoi = () => {
    this.setState({
      showSection: null,
    })
  }

  render() {
    const { showSection } = this.state
    const fileDetail = this.state.fileFromList || this.state.file
    let { ctKySoTapTrung, documentNumberFromList } = this.state
    if (!documentNumberFromList && _.get(ctKySoTapTrung, 'soKyHieu', false)) {
      documentNumberFromList = ctKySoTapTrung.soKyHieu
    }

    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="detail-receive-document" ref="historyNode">
          {this.state.isLoadingData &&
          this.state.isLoadingData.documentNumber ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">Số ký hiệu:</span>
                <span className="code">{documentNumberFromList}</span>
              </span>
            </div>
          )}

          {fileDetail &&
            this.state.isLoadingData &&
            !this.state.isLoadingData.file &&
            !this.state.isLoadingMigratedFile && (
              <span
                className="btn-view-file-detail"
                onClick={() => {
                  this.detailFileContent.handleFullScreen(true)
                }}
              >
                <span className="left-icon icon-Xem_thuc_hien"></span>
                <span className="text-content">Xem chi tiết công văn</span>
              </span>
            )}

          {!fileDetail &&
            this.state.isLoadingData &&
            this.state.isLoadingData.file &&
            this.state.isLoadingMigratedFile && (
              <p className="btn-view-file-detail">Đang tải file ....</p>
            )}

          <div className="detail-container detail-container-sign-number">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {this.state.isLoadingData && !this.state.isLoadingData.file && (
                  <div className="pd-none">
                    {fileDetail ? (
                      <DetailFileContent
                        handleClickShowInfoMobile={
                          this.handleClickShowInfoMobile
                        }
                        file={fileDetail}
                        actions={this.props.actions}
                        ref={this.refHandlers.detailFileContent}
                        auth={this.props.auth.user}
                        onFinishLoadingFile={
                          this.handleFinishLoadingMigratedFile
                        }
                      />
                    ) : (
                      <NotFound />
                    )}
                  </div>
                )}
                {this.state.isLoadingData && this.state.isLoadingData.file && (
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                )}
              </div>
              <div
                className="box-right"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="full-box-content">
                  {!this.state.isShowHistoryDetail && !showSection && (
                    <DetailBoxActionKSTT
                      auth={this.props.auth.user}
                      feedbacks={this.state.feedbacks}
                      ctKySoTapTrung={this.state.ctKySoTapTrung}
                      onSubmit={this.handleSubmit}
                      actions={this.props.actions}
                      isLoadingData={this.state.isLoadingData}
                      onClickShowHistoryDetail={
                        this.handleClickShowHistoryDetail
                      }
                      listHistoryUpdate={this.state.listHistoryUpdate}
                      isShowHistoryDetail={this.state.isShowHistoryDetail}
                      readOnly={this.props.readOnly}
                      onGetHistoryList={this.handleGetHistoryList}
                      isOpenHistoryList={this.state.isOpenHistoryList}
                      type="signNumberProject"
                      onRemoveFileInfo={this.handleRemoveFileInfo}
                      isAgreeNotNeedSignNumber
                      isKstt
                      searchReadOnly={this.state.searchReadOnly}
                    />
                  )}
                  {this.state.isShowHistoryDetail && !showSection && (
                    <UpdateHistoryDetail
                      historyItem={this.state.historyItem}
                      onClickGoBack={this.handleClickGoback}
                      auth={this.props.auth.user}
                      isLoadingData={this.state.isLoadingData}
                      type="signNumberProject"
                      isKstt
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state,
    user: state.auth,
  },

  readOnly: state.common.enableReadOnly,

  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions, ...Actions2 }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChiTietKySoTapTrung)
