import { HeadingTableSort, PopupConfirm } from 'components/common'
import { MESSAGE_ERROR, MESSAGE_NOT_FOUND } from 'constants/MessageForm'
import { headers } from 'constants/Table'
import React from 'react'
import * as POPUP from 'constants/Popup'
import ItemLoading from './ItemLoading'
import TableItem from './TableItem'
import { isEmpty } from 'lodash'
import ListFooter from './ListFooter'
import ListFilter from './ListFilter'

const DanhSachLichHop = props => {
  const {
    listData,
    isError,
    isLoading,
    current,
    total,
    handleClickPage,
    isOpenPopupConfirm,
    isOpenPopupConfirmCancel,
    isOpenPopupKetThuc,
    handleClickPopupCancel,
    handleClickPopup,
    handleClickPopupKetThuc,
    dsLoaiCuocHopCombobox,
    dsCapDoCuocHopCombobox,
    filter,
    textSearch,
    handleChangeFilter,
    hanldeChangeTextSearch,
    handleClearFilter,
    handleClickSearch,
    handleSubmitRemoveItem,
    handleSubmitCancelItem,
    handleSubmitKetThucItem,
    handleSubmitGuiCuocHop,
    history,
  } = props
  return (
    <div className="page-list-container page-list-role-container">
      <div className="table-container table-subcollap-container">
        <div className="table-scroll">
          <div className="collapse-panel-content">
            <div className="table-display">
              <div className="table-display-container">
                <ListFilter
                  dsLoaiCuocHopCombobox={dsLoaiCuocHopCombobox}
                  dsCapDoCuocHopCombobox={dsCapDoCuocHopCombobox}
                  filter={filter}
                  textSearch={textSearch}
                  handleChangeFilter={handleChangeFilter}
                  hanldeChangeTextSearch={hanldeChangeTextSearch}
                  handleClearFilter={handleClearFilter}
                  handleClickSearch={handleClickSearch}
                />
                <div>
                  <HeadingTableSort data={headers.dsLichHop} />
                  <div className="table-body-container">
                    {isLoading && (
                      <div className="table-loading-system">
                        <ItemLoading />
                        <ItemLoading />
                        <ItemLoading />
                      </div>
                    )}
                    {!isLoading && !isError && !isEmpty(listData) && (
                      <div className="sortableList">
                        {listData?.map((item, index) => (
                          <TableItem
                            key={`item-${index}`}
                            i={(filter.page - 1) * 10 + index + 1}
                            item={item}
                            handleClickPopup={handleClickPopup}
                            handleClickPopupCancel={handleClickPopupCancel}
                            handleSubmitGuiCuocHop={handleSubmitGuiCuocHop}
                            handleClickPopupKetThuc={handleClickPopupKetThuc}
                            history={history}
                          />
                        ))}
                      </div>
                    )}
                    {isEmpty(listData) && !isLoading && !isError && (
                      <div className="error-page-container">
                        <div className="error-content">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/empty-default.png'
                            }
                            alt="search-result-not-found"
                            className="img-thumb-error"
                          />
                          <p className="description-note">
                            {MESSAGE_NOT_FOUND}
                          </p>
                        </div>
                      </div>
                    )}
                    {isError && !isLoading && (
                      <div className="error-page-container">
                        <div className="error-content">
                          <img
                            src={process.env.PUBLIC_URL + '/images/Server.png'}
                            alt="error"
                            className="img-thumb-error"
                          />
                          <p className="description-note">{MESSAGE_ERROR}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!isLoading && !isEmpty(listData) && !isError && (
                  <ListFooter
                    current={current}
                    total={total}
                    onChange={handleClickPage}
                  />
                )}
                <PopupConfirm
                  isOpen={isOpenPopupConfirm}
                  onClose={handleClickPopup}
                  title={POPUP.XOA_LICH_HOP}
                  text={POPUP.TEXT_BUTTON_SUBMIT}
                  onSubmit={handleSubmitRemoveItem}
                >
                  {POPUP.TEXT_SUBMIT_BODY}
                </PopupConfirm>
                <PopupConfirm
                  isOpen={isOpenPopupConfirmCancel}
                  onClose={handleClickPopupCancel}
                  title={POPUP.HUY_LICH_HOP}
                  text={POPUP.TEXT_BUTTON_SUBMIT}
                  onSubmit={handleSubmitCancelItem}
                >
                  {POPUP.TEXT_SUBMIT_BODY}
                </PopupConfirm>
                <PopupConfirm
                  isOpen={isOpenPopupKetThuc}
                  onClose={handleClickPopupKetThuc}
                  title={POPUP.KET_THUC_LICH_HOP}
                  text={POPUP.TEXT_BUTTON_SUBMIT}
                  onSubmit={handleSubmitKetThucItem}
                  isLoading={isLoading}
                >
                  {POPUP.TEXT_SUBMIT_BODY}
                </PopupConfirm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DanhSachLichHop
