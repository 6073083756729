import { schema } from 'normalizr'

const File = new schema.Entity(
  'files',
  {},
  {
    idAttribute: file => file.fileId,
  }
)

export default File
