import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Intent, Radio, RadioGroup } from '@blueprintjs/core'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'
import * as POPUP from '../../constants/Popup'
import { PopupConfirm } from '../common'
import * as MESSAGE from '../../constants/MessageForm'

class DuyetGopY extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleAgree = this.handleAgree.bind(this)
    this.handleNotAgree = this.handleNotAgree.bind(this)
    this.handleSubmitXacNhan = this.handleSubmitXacNhan.bind(this)
  }

  state = {
    error: {},
    typeSubmit: 'DuyetGopY',
    yKien: '',
    trangThai: '',
    isOpenPopup: false,
    popup_title: '',
    popup_content: '',
  }

  handleSubmitXacNhan() {
    let content = {
      y_kien: this.state.yKien,
      trang_thai: this.state.trangThai,
    }
    this.handleSubmit(content)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAgree = e => {
    e.preventDefault()
    this.setState({
      popup_content: POPUP.COMFIRM_DONG_Y_XAC_NHAN,
      trangThai: TRANGTHAI.LAYYKIEN_DONGY,
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleNotAgree = e => {
    e.preventDefault()
    if (this.state.yKien.trim().length === 0) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_ALERT_DISSAGREE_REQUIRE_WARNING,
        intent: Intent.WARNING,
      })
    }
    this.setState({
      popup_content: POPUP.COMFIRM_DONG_KHONG_DONG_Y_XAC_NHAN,
      trangThai: TRANGTHAI.LAYYKIEN_KHONG_DONGY,
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmit = content => {
    this.props.onSubmit && this.props.onSubmit(this.state.typeSubmit, content)
  }

  render() {
    const { requestComments, auth } = this.props
    const token =
      auth.auth && auth.auth.token && auth.auth.token.accessToken
        ? auth.auth.token.accessToken
        : ''
    if (!requestComments) {
      return
    }
    let extensionTK = null
    if (requestComments.fileGopY) {
      const fileDinhKem = requestComments.fileGopY.fileName
      if (fileDinhKem) {
        extensionTK = fileDinhKem
          .slice(
            ((requestComments.fileGopY.fileName.lastIndexOf('.') - 1) >>> 0) + 2
          )
          .toLowerCase()
      }
    }
    const isXacNhanLai =
      requestComments &&
      requestComments.tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN

    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>{isXacNhanLai ? 'Xác nhận lại' : 'Xác nhận góp ý'}</h4>
        </div>
        <div className="form-container container-detail form-report-work">
          <div className="pt-form-group pt-intent-danger">
            <div className="row full-row">
              <div className="col-md-12 col-xs-12">
                <label className="pt-label">Trạng thái góp ý</label>
              </div>
            </div>
            <div className="row full-row">
              <div className="col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <RadioGroup
                    selectedValue={`${requestComments.trangThaiGopY}`}
                    name="trang_thai_gop_y"
                  >
                    <Radio
                      label="Thống nhất"
                      value="2"
                      className="pt-large pt-inline name-radio"
                      disabled={true}
                    />
                    <Radio
                      label="Không thống nhất"
                      value="1"
                      className="pt-large pt-inline name-radio"
                      disabled={true}
                    />
                    <Radio
                      label="Không ý kiến"
                      value="3"
                      className="pt-large pt-inline name-radio"
                      disabled={true}
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="row full-row">
              <div className="col-md-12 col-xs-12">
                <label className="pt-label">
                  {`Nội dung góp ý: ${requestComments.noiDungGopY}`}
                </label>
              </div>
              <div className="col-md-12 col-xs-12">
                <label className="pt-label">
                  File góp ý:
                  {requestComments.fileGopY && (
                    <span className="text-content">
                      <a
                        className="link-file"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.FILE_URL}/api/file/get/${requestComments.fileGopY.fileId}?BearerToken=${token}`}
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extensionTK
                          )})`,
                        }}
                      >
                        {requestComments.fileGopY.tenFile} -{' '}
                        {Tool.Utils.getFileSize(
                          requestComments.fileGopY.kichThuoc
                        )}
                      </a>
                    </span>
                  )}
                </label>
              </div>
              <div className="col-md-12 col-xs-12">
                <label className="pt-label">
                  {`Người góp ý: ${
                    requestComments.caNhanChuTri &&
                    requestComments.caNhanChuTri.tenNhanVien
                  }`}
                </label>
              </div>
            </div>
            <div className="row">
              <label className="pt-label col-md-12 col-xs-12">
                Ý kiến duyệt góp ý
              </label>
            </div>
            <div className="row">
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    name="yKien"
                    onChange={this.handleChange}
                    value={this.state.yKien}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer button-content">
          <Button
            onClick={this.handleAgree}
            className="pt-button btn-action-blue btn-confirm-comment"
            loading={this.props.isLoading.isLoadingDuyetGopYDongY}
          >
            Đồng ý<span className="pt-icon pt-icon-large icon-Bao_Cao"></span>
          </Button>
          <Button
            onClick={this.handleNotAgree}
            className="pt-button  btn-action-red btn-cancel-steering"
            loading={this.props.isLoading.isLoadingDuyetGopYKhongDongY}
            disabled={isXacNhanLai}
          >
            Không đồng ý
            <span className="pt-icon pt-icon-large pt-icon-cross"></span>
          </Button>
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleAgree}
          title={POPUP.XAC_NHAN_PHIEU_GOP_Y}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitXacNhan}
          isLoading={
            this.state.isLoadingDuyetGopYKhongDongY ||
            this.props.isLoading.isLoadingDuyetGopYDongY
          }
        >
          {this.state.popup_content}
        </PopupConfirm>
      </div>
    )
  }
}

export default DuyetGopY
