import React, { Component } from 'react'
import classnames from 'classnames'
import { Checkbox, Collapse } from '@blueprintjs/core'

export default class DepartmentItem extends Component {
  state = {
    isOpen: false,
    checked: false,
    loading: false,
    checking: false,
  }

  toggleUsers = async () => {
    let isHavaUser =
      this.props.usersList.filter(
        item => item.phongBanId === this.props.item.phongBanId
      ).length > 0
    if (!isHavaUser && !this.state.isOpen && !this.state.loading) {
      await this.setState({
        loading: true,
      })
      this.props.actions
        .fetchStaffByDepartmentId(this.props.item.phongBanId)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            this.props.addUserToTree(res.payload.data.result)
            this.setState({
              loading: false,
              isOpen: !this.state.isOpen,
            })
          }
        })
    } else {
      this.setState({
        isOpen: !this.state.isOpen,
        loading: false,
      })
    }
  }

  fetchUser = async id => {
    await this.props.actions.fetchStaffByDepartmentId(id).then(res => {
      if (res && res.payload && res.payload.data && res.payload.data.result) {
        this.props.addUserToTree(res.payload.data.result)
      }
    })
  }

  handleCheckUserIdList = (dataPb, checked) => {
    dataPb.forEach(async phongBanId => {
      let tempUser =
        this.props.usersList.filter(user => user.phongBanId === phongBanId) ||
        []
      if (tempUser.length === 0) {
        await this.fetchUser(phongBanId)
        tempUser =
          this.props.usersList.filter(item => item.phongBanId === phongBanId) ||
          []
      }
      if (checked) {
        this.props.removeFromUserChecked(tempUser.map(item => item.nhanVienId))
      } else {
        this.props.addToUserChecked(tempUser.map(item => item.nhanVienId))
      }
    })
    this.setState({
      checking: false,
    })
  }

  handleCheck = async () => {
    if (this.props.type === 'nhanVien') {
      if (this.state.checked) {
        this.props.removeFromUserChecked(this.props.item.nhanVienId)
      } else {
        this.props.addToUserChecked(this.props.item.nhanVienId)
      }
    } else {
      await this.setState({
        checking: true,
      })
      let dataPb = await this.getDsPhongBanId(this.props.item.phongBanId)
      if (this.state.checked) {
        this.handleCheckUserIdList(dataPb, this.state.checked)
        this.props.removeFromPhongBanChecked(dataPb)
      } else {
        this.handleCheckUserIdList(dataPb, this.state.checked)
        this.props.addToPhongBanChecked(dataPb)
      }
    }
  }

  getDsPhongBanId = phongBanId => {
    let pb = this.props.dsPhongBan.find(item => item.phongBanId === phongBanId)
    if (!pb.dsPhongBanCon) {
      return [phongBanId]
    } else {
      let dsPhongBanCon = [phongBanId]
      pb.dsPhongBanCon.forEach(item => {
        dsPhongBanCon = [
          ...dsPhongBanCon,
          ...this.getDsPhongBanId(item.phongBanId),
        ]
      })
      return dsPhongBanCon
    }
  }

  componentDidMount() {
    if (this.props.type === 'nhanVien') {
      if (
        this.props.userChecked.findIndex(
          id => id === this.props.item.nhanVienId
        ) !== -1
      ) {
        this.setState({
          checked: true,
        })
      }
    } else {
      if (
        this.props.phongBanChecked.findIndex(
          id => id === this.props.item.phongBanId
        ) !== -1
      ) {
        this.setState({
          checked: true,
        })
      }
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.userChecked !== this.props.userChecked ||
      prevProps.phongBanChecked !== this.props.phongBanChecked
    ) {
      if (this.props.type === 'nhanVien') {
        let checked =
          this.props.userChecked.findIndex(
            id => id === this.props.item.nhanVienId
          ) !== -1
        this.setState({
          checked: checked,
        })
      } else {
        let checked =
          this.props.phongBanChecked.findIndex(
            id => id === this.props.item.phongBanId
          ) !== -1
        this.setState({
          checked: checked,
        })
        let dsNhanVien =
          this.props.usersList.filter(
            item => item.phongBanId === this.props.item.phongBanId
          ) || []
        let dsNhanVienChecked = dsNhanVien.length
          ? dsNhanVien.filter(
              item =>
                this.props.userChecked.findIndex(
                  id => id === item.nhanVienId
                ) !== -1
            )
          : []
        let dsPhongBanCon = []
        let dsPhongBanConChecked = []
        if (this.props.item.dsPhongBanCon) {
          dsPhongBanCon = this.props.dsPhongBan.filter(
            item => item.phongBanCha === this.props.item.phongBanId
          )
          dsPhongBanConChecked = dsPhongBanCon.filter(
            item =>
              this.props.phongBanChecked.findIndex(
                id => id === item.phongBanId
              ) !== -1
          )
        }
        if (this.state.checked) {
          let isNotCheckedAllNv = false
          let isNotCheckedAllPb = false
          if (
            dsNhanVien.length !== 0 &&
            dsNhanVienChecked.length !== dsNhanVien.length
          ) {
            isNotCheckedAllNv = true
          }
          if (
            dsPhongBanCon &&
            dsPhongBanCon.length !== 0 &&
            dsPhongBanConChecked.length !== dsPhongBanCon.length
          ) {
            isNotCheckedAllPb = true
          }
          if (isNotCheckedAllNv || isNotCheckedAllPb) {
            this.props.removeFromPhongBanChecked(this.props.item.phongBanId)
          }
        } else {
          if (dsNhanVien.length === 0 && dsPhongBanCon.length === 0) {
            return
          }
          let isCheckedAllNv = false
          let isCheckedAllPb = false
          if (
            dsNhanVien.length !== 0 &&
            dsNhanVienChecked.length === dsNhanVien.length
          ) {
            isCheckedAllNv = true
          }
          if (
            dsPhongBanCon &&
            dsPhongBanCon.length !== 0 &&
            dsPhongBanConChecked.length === dsPhongBanCon.length
          ) {
            isCheckedAllPb = true
          }
          if (
            (dsNhanVien.length &&
              dsPhongBanCon.length &&
              isCheckedAllNv &&
              isCheckedAllPb) ||
            (dsNhanVien.length && isCheckedAllNv && !dsPhongBanCon.length) ||
            (dsPhongBanCon.length && isCheckedAllPb && !dsNhanVien.length)
          ) {
            this.props.addToPhongBanChecked(this.props.item.phongBanId)
          }
        }
      }
    }
  }

  render() {
    let phongBanCon = []
    let dsNhanVien = []
    let paddingLeft
    if (this.props.item.dsPhongBanCon) {
      phongBanCon =
        this.props.dsPhongBan &&
        this.props.dsPhongBan.filter(
          item => item.phongBanCha === this.props.item.phongBanId
        )
    }
    dsNhanVien =
      this.props.usersList &&
      this.props.usersList.filter(
        item => item.phongBanId === this.props.item.phongBanId
      )
    if (this.props.type === 'nhanVien') {
      paddingLeft = parseInt(this.props.level, 10) * 20 + 30
    } else {
      paddingLeft = parseInt(this.props.level, 10) * 20 - 5
    }
    return (
      <div>
        <div
          style={{ paddingLeft: paddingLeft + 'px' }}
          className={classnames('d-flex middle-xs pb10 pr15 pt15', {
            'border-top': !this.props.isFirstChild,
            // 'pl40': this.props.level && this.props.level === '2',
            // 'pl60': this.props.isUserLvl2,
            // 'pl80': this.props.level && this.props.level === '3' && !this.props.isUserLvl2
          })}
        >
          {this.props.type === 'phongBan' && (
            <span
              className={classnames('icon-blue mr10 cursor-pointer', {
                'icon-rounded-add-button1 cursor-pointer': !this.state.isOpen,
                'cursor-progress animate-spin': this.state.loading,
                'icon-round-delete-button1 cursor-pointer': this.state.isOpen,
              })}
              onClick={this.toggleUsers}
            ></span>
          )}
          <Checkbox
            disabled={this.state.checking}
            checked={this.state.checked}
            onChange={this.handleCheck}
            className="m0"
            large="true"
          />
          <span className="font-size-14 font-bold">
            {this.props.type === 'phongBan'
              ? this.props.item.tenPhongBan
              : this.props.item.tenNhanVien}
          </span>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {dsNhanVien
            ? dsNhanVien.map((item, index) => (
                <DepartmentItem
                  handleCheckUser={this.props.handleCheckUser}
                  actions={this.props.actions}
                  key={item.nhanVienId}
                  isFirstChild={index === 0}
                  item={item}
                  userChecked={this.props.userChecked}
                  type="nhanVien"
                  addToUserChecked={this.props.addToUserChecked}
                  removeFromUserChecked={this.props.removeFromUserChecked}
                  level={this.props.item.level}
                />
              ))
            : null}
          {this.props.item.dsPhongBanCon
            ? phongBanCon &&
              phongBanCon.map((item, index) => (
                <DepartmentItem
                  phongBanChecked={this.props.phongBanChecked}
                  usersList={this.props.usersList}
                  dsPhongBan={this.props.dsPhongBan}
                  actions={this.props.actions}
                  key={item.phongBanId}
                  isFirstChild={index === 0 && !(dsNhanVien.length > 0)}
                  item={item}
                  handleCheckUser={this.props.handleCheckUser}
                  addUserToTree={this.props.addUserToTree}
                  userChecked={this.props.userChecked}
                  addToPhongBanChecked={this.props.addToPhongBanChecked}
                  removeFromPhongBanChecked={
                    this.props.removeFromPhongBanChecked
                  }
                  addToUserChecked={this.props.addToUserChecked}
                  removeFromUserChecked={this.props.removeFromUserChecked}
                  forceUpdate={this.props.forceUpdate}
                  level={item.level}
                  type="phongBan"
                />
              ))
            : null}
        </Collapse>
      </div>
    )
  }
}
