import React from 'react'
import { useCookies } from 'react-cookie'
import classnames from 'classnames'
import { get } from 'lodash'
import {
  Button,
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core'

import { Icon } from 'components/newCommon'
import { FOLDER_NAME } from 'constants/Api'
// import { IMAGE_URL } from 'constants/Api';

const BACKGROUND_LIST = [
  {
    isLight: false,
    url: 'bg1.jpg',
  },
  {
    isLight: false,
    url: 'bg2.jpg',
  },
  {
    isLight: true,
    url: 'bg3.jpg',
  },
  {
    isLight: true,
    url: 'bg4.jpg',
  },
  {
    isLight: true,
    url: 'bg5.jpg',
  },
  {
    isLight: true,
    url: 'bg6.jpg',
  },
  {
    isLight: false,
    url: 'bg7.jpg',
  },
  {
    isLight: false,
    url: 'bg8.jpg',
  },
  {
    isLight: true,
    url: 'bg9.jpg',
  },
  {
    isLight: false,
    url: 'bg10.jpg',
  },
  {
    isLight: false,
    url: 'bg11.jpg',
  },
  {
    isLight: true,
    url: 'bg12.jpg',
  },
]

const Background = () => {
  // Set Background QLCV
  const [cookies, setCookie] = useCookies(['background_image'])

  if (!get(cookies, 'background_image')) {
    setCookie('background_image', {
      url: BACKGROUND_LIST[0].url,
      is_light: BACKGROUND_LIST[0].isLight,
    })
  }

  const changeBackground = (image, isLight) => {
    setCookie('background_image', {
      url: image,
      is_light: isLight,
    })
  }

  return (
    <span className="ml10">
      <Popover
        popoverClassName="popover-background-list cpc-popover no-arrow mt5"
        position={Position.BOTTOM_RIGHT}
        interactionKind={PopoverInteractionKind.CLICK}
        content={
          <div className="background-list">
            {BACKGROUND_LIST.map((elm, index) => {
              let background = FOLDER_NAME
                ? `/backgrounds/${FOLDER_NAME}/thumbnail-${elm.url}`
                : `/backgrounds/thumbnail-${elm.url}`
              return (
                <div key={index} className="background-list-item">
                  <span
                    style={{ backgroundImage: `url(${background})` }}
                    className={classnames({
                      active:
                        get(cookies, 'background_image.url', '') === elm.url,
                    })}
                    onClick={() => changeBackground(elm.url, elm.isLight)}
                  />
                </div>
              )
            })}
          </div>
        }
      >
        <Tooltip
          position={Position.TOP_RIGHT}
          content={<span className="font-size-12">Cập nhật hình nền</span>}
        >
          <Button
            className={classnames('cpc-button', 'no-padding', 'rounded')}
            intent={Intent.PRIMARY}
          >
            <Icon classIcon="icon-settings" />
          </Button>
        </Tooltip>
      </Popover>
    </span>
  )
}

export default Background
