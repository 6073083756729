import React from 'react'
import { Intent, Checkbox, Button } from '@blueprintjs/core'
import classnames from 'classnames'
import * as MESSAGER from '../../constants/MessageForm'

const PATH = '/he-thong/do-khan/chinh-sua/:id'

class FormAddUrgencyCategory extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    doKhanId: '',
    tenDoKhan: '',
    maDoKhan: '',
    isActive: true,
    isLoading: false,
    error: {},
    path: null,
    stt: null,
    isOpenPopup: false,
    priorities: null,
    isLoadingData: {
      tenDoKhan: true,
      maDoKhan: true,
      isActive: true,
    },
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    } else if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    }
  }

  componentDidMount() {
    if (this.props.match.path !== PATH) {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          tenDoKhan: false,
          maDoKhan: false,
          isActive: false,
        },
      })
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.priorities !== this.props.priorities) {
      if (!nextProps.priorities) {
        return
      }
      this.setState(
        {
          doKhanId: nextProps.priorities.doKhanId || '',
          tenDoKhan: nextProps.priorities.tenDoKhan || '',
          maDoKhan: nextProps.priorities.maDoKhan || '',
          isActive: nextProps.priorities.isActive ? true : false,
          priorities: nextProps.priorities,
          stt: nextProps.priorities.stt,
        },
        () => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              tenDoKhan: false,
              maDoKhan: false,
              isActive: false,
            },
          })
        }
      )
    }

    if (
      nextProps.priorities === this.props.priorities &&
      this.props.match.path !== PATH
    ) {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          tenDoKhan: false,
          maDoKhan: false,
          isActive: false,
        },
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const error = {}

    if (this.state.maDoKhan.trim().length === 0) {
      error.maDoKhan = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenDoKhan.trim().length === 0) {
      error.tenDoKhan = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    this.setState({ isLoading: true })
    if (this.props.priorities) {
      this.setState({ error: {} })
      this.props.actions
        .updateCommonFetchPriorities({
          do_khan_id: this.state.doKhanId,
          ten_do_khan: this.state.tenDoKhan,
          ma_do_khan: this.state.maDoKhan,
          is_active: this.state.isActive,
          stt: this.state.stt,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.setState({ error: {} })
      this.props.actions
        .postCommonFetchPriorities({
          ma_do_khan: this.state.maDoKhan,
          ten_do_khan: this.state.tenDoKhan,
          is_active: this.state.isActive,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="maSo"
                      >
                        Mã độ khẩn
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          name="maDoKhan"
                          autoFocus={true}
                          onChange={this.handleChange}
                          value={this.state.maDoKhan}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập mã độ khẩn"
                        />
                        {this.state.error.maDoKhan !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maDoKhan}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="moTa"
                      >
                        Tên độ khẩn
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          name="tenDoKhan"
                          onChange={this.handleChange}
                          value={this.state.tenDoKhan}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập tên độ khẩn"
                        />
                        {this.state.error.tenDoKhan !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenDoKhan}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sudung"
                      >
                        Sử dụng
                      </label>
                      <div className="input-form-inline">
                        <Checkbox
                          name="isActive"
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          onChange={this.handleChange}
                          checked={this.state.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading === true ? true : false}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-main-color"
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined
                  }
                  onClick={this.handleSubmit}
                  loading={this.state.isLoading}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default FormAddUrgencyCategory
