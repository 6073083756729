import React, { memo } from 'react'
import { connect } from 'react-redux'
import { Cookies, withCookies } from 'react-cookie'
import classNames from 'classnames'
import { Popover, Position } from '@blueprintjs/core'

import { IMAGE_URL } from 'constants/Api'
import { UserRole } from 'components/common'

const cookies = new Cookies()

const HeaderInfo = ({ history, user, isLeftNav }) => {
  const isLight = cookies.get('home_background_image')?.is_light

  const defaultAvatar = () => (
    <div
      className="wrap-info__avatar mr15"
      style={{
        backgroundImage: `url(${
          user.anhDaiDien
            ? `${IMAGE_URL}${user.anhDaiDien}?preventCache=${Math.random()}`
            : '/images/default_avatar.png'
        })`,
      }}
    />
  )

  return (
    <div className={'wrap-info'}>
      {isLeftNav && defaultAvatar()}
      <div className="wrap-info__description ml0">
        {isLeftNav && (
          <p
            className={classNames('wrap-info__description__large', {
              'color-light': isLight,
            })}
          >
            <span>Xin chào, {user?.tenNhanVien}!</span>
            <div className="ml10" style={{ display: 'inline-flex' }}>
              <Popover
                position={Position.BOTTOM}
                content={<UserRole history={history} />}
              >
                <i className="icon-arrow-dropdown size-icon-6 cursor-pointer"></i>
              </Popover>
            </div>
          </p>
        )}
        <p
          className={classNames({
            'color-light': isLight,
            'opacity-07': isLeftNav,
            'font-size-24 font-weight-600': !isLeftNav,
          })}
        >
          Bảng thông tin hoạt động của anh/ chị
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const user = state?.auth?.user
  return {
    user,
  }
}

export default withCookies(connect(mapStateToProps, null)(memo(HeaderInfo)))
