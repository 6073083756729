import React, { Component } from 'react'

import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
const DEFAULT_PAGE_NAME = 'Chỉnh sửa quy trình'
const PAGE_ICON = 'icon-quan-ly-quy-trinh'
class EditProcedurePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageName: DEFAULT_PAGE_NAME,
      isWaitingSubmit: false,
    }
  }

  toggleAllowGoback = () => {
    const { isWaitingSubmit } = this.state
    this.setState({ isWaitingSubmit: !isWaitingSubmit })
  }

  changePageName = name => {
    this.setState({
      pageName: name,
    })
  }

  render() {
    const { isWaitingSubmit } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <ContentHeader
              pageIcon={PAGE_ICON}
              pageName={this.state.pageName}
              showGoBackButton
              disabledGoBack={isWaitingSubmit}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

export default EditProcedurePage
