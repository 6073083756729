import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Types from '../../constants/Api'

class AssignmentHistoryItem extends Component {
  static propTypes = {
    assignmentHistory: PropTypes.object.isRequired,
  }

  state = {
    chuTri: '',
    dsPhoiHop: '',
    dsXemDeBiet: '',
  }

  componentDidMount() {
    if (this.props.assignmentHistory) {
      let chuTri = ''
      let dsPhoiHop = []
      let dsXemDeBiet = []

      if (this.props.assignmentHistory.chuTri) {
        chuTri = this.props.assignmentHistory.chuTri.maPhongBan
      }

      if (this.props.assignmentHistory.phoiHop) {
        this.props.assignmentHistory.phoiHop.forEach(item => {
          dsPhoiHop.push(item.maPhongBan)
        })
      }

      if (this.props.assignmentHistory.xemDeBiet) {
        this.props.assignmentHistory.xemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.maPhongBan)
        })
      }

      if (this.props.assignmentHistory.donViChuTri) {
        chuTri = this.props.assignmentHistory.donViChuTri.tenVietTat
      }

      if (this.props.assignmentHistory.donViPhoiHop) {
        this.props.assignmentHistory.donViPhoiHop.forEach(item => {
          dsPhoiHop.push(item.tenVietTat)
        })
      }

      if (this.props.assignmentHistory.donViXemDeBiet) {
        this.props.assignmentHistory.donViXemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.tenVietTat)
        })
      }

      this.setState({
        chuTri,
        dsPhoiHop: dsPhoiHop.join(', '),
        dsXemDeBiet: dsXemDeBiet.join(', '),
      })
    }
  }

  render() {
    return (
      <div className="item-content-history job-information-form">
        <div className="time-create">
          {this.props.assignmentHistory.ngayTao &&
            moment(this.props.assignmentHistory.ngayTao).format(
              'DD/MM/YYYY HH:mm'
            )}
        </div>
        <div className="avatar-user">
          <span
            className="avatar"
            style={{
              backgroundImage: `url(${
                this.props.assignmentHistory.nguoiChiDao &&
                this.props.assignmentHistory.nguoiChiDao.anhDaiDien
                  ? Types.IMAGE_URL +
                    this.props.assignmentHistory.nguoiChiDao.anhDaiDien
                  : '/images/default_avatar.png'
              })`,
            }}
          ></span>
          <span className="name_user">
            {' '}
            {this.props.assignmentHistory.nguoiChiDao
              ? this.props.assignmentHistory.nguoiChiDao.tenNhanVien
              : ''}
          </span>
        </div>
        {this.props.assignmentHistory.loaiChiDao === 4 && (
          <div className="info-content">
            <ul>
              <li className="remove-assignment">
                <span className="style-icon pt-icon pt-icon-trash"></span>
                <div className="content-detail">
                  <span className="title-bold">Hủy chỉ đạo</span>
                </div>
              </li>
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 2 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chidaobosung"></span>
                <div className="content-detail">
                  <span className="title-bold">Chỉ đạo bổ sung: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.noiDung}{' '}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-Phoihop"></span>
                <div className="content-detail">
                  <span className="title-bold">Bổ sung phối hợp: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.phoiHop.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.phoiHop.length === i + 1 ? item.maPhongBan : item.maPhongBan + ','} </span>
                    ))
                  } */}
                    {this.state.dsPhoiHop}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-Xemdebiet"></span>
                <div className="content-detail">
                  <span className="title-bold">Xem để biết: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.xemDeBiet.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.xemDeBiet.length === i + 1 ? item.maPhongBan : item.maPhongBan + ','} </span>
                    ))
                  } */}
                    {this.state.dsXemDeBiet}
                  </span>
                </div>
              </li>
              {/* <li>
              <span className="style-icon icon-Cacdonvitructhuoc"></span>
              <div className="content-detail">
                <span className="title-bold">Các đơn vị trực thuộc: </span>
                <span className="detail">
                  { this.props.assignmentHistory.donViTrucThuoc.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.donViTrucThuoc.length === i + 1 ? item.tenVietTat : item.tenVietTat + ','} </span>
                    ))
                  }
                </span>
              </div>
            </li> */}
              <li>
                <span className="style-icon icon-calendar"></span>
                <div className="content-detail">
                  <span className="title-bold">Hạn giải quyết: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.hanGiaiQuyet &&
                      moment(this.props.assignmentHistory.hanGiaiQuyet).format(
                        'DD/MM/YYYY'
                      )}{' '}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 0 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chidao"></span>
                <div className="content-detail">
                  <span className="title-bold">Chỉ đạo: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.noiDung}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-setting"></span>
                <div className="content-detail">
                  <span className="title-bold">Chủ trì: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.chuTri ? this.props.assignmentHistory.chuTri.maPhongBan : '' } */}
                    {this.state.chuTri}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-Phoihop"></span>
                <div className="content-detail">
                  <span className="title-bold">Bổ sung phối hợp: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.phoiHop.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.phoiHop.length === i + 1 ? item.maPhongBan : item.maPhongBan + ','} </span>
                    ))
                  } */}
                    {this.state.dsPhoiHop}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-Xemdebiet"></span>
                <div className="content-detail">
                  <span className="title-bold">Xem để biết: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.xemDeBiet.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.xemDeBiet.length === i + 1 ? item.maPhongBan : item.maPhongBan + ','} </span>
                    ))
                  } */}
                    {this.state.dsXemDeBiet}
                  </span>
                </div>
              </li>
              {/* <li>
              <span className="style-icon icon-Cacdonvitructhuoc"></span>
              <div className="content-detail">
                <span className="title-bold">Các đơn vị trực thuộc: </span>
                <span className="detail">
                  { this.props.assignmentHistory.donViTrucThuoc.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.donViTrucThuoc.length === i + 1 ? item.tenVietTat : item.tenVietTat + ','} </span>
                    ))
                  }
                </span>
              </div>
            </li> */}
              <li>
                <span className="style-icon icon-calendar"></span>
                <div className="content-detail">
                  <span className="title-bold">Hạn giải quyết: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.hanGiaiQuyet &&
                      moment(this.props.assignmentHistory.hanGiaiQuyet).format(
                        'DD/MM/YYYY'
                      )}{' '}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 3 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chidao"></span>
                <div className="content-detail">
                  <span className="title-bold">Sửa chỉ đạo: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.noiDung}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-setting"></span>
                <div className="content-detail">
                  <span className="title-bold">Chủ trì: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.chuTri ? this.props.assignmentHistory.chuTri.maPhongBan : '' } */}
                    {this.state.chuTri}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-Phoihop"></span>
                <div className="content-detail">
                  <span className="title-bold">Bổ sung phối hợp: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.phoiHop.map((item, i) => (
                      <span key={i}> { this.props.assignmentHistory.phoiHop.length === i + 1 ? item.maPhongBan : item.maPhongBan + ','} </span>
                      ))
                    } */}
                    {this.state.dsPhoiHop}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-Xemdebiet"></span>
                <div className="content-detail">
                  <span className="title-bold">Xem để biết: </span>
                  <span className="detail">
                    {/* { this.props.assignmentHistory.xemDeBiet.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.xemDeBiet.length === i + 1 ? item.maPhongBan : item.maPhongBan + ','} </span>
                    ))
                  } */}
                    {this.state.dsXemDeBiet}
                  </span>
                </div>
              </li>
              {/* <li>
              <span className="style-icon icon-Cacdonvitructhuoc"></span>
              <div className="content-detail">
                <span className="title-bold">Các đơn vị trực thuộc: </span>
                <span className="detail">
                  { this.props.assignmentHistory.donViTrucThuoc.map((item, i) => (
                    <span key={i}> { this.props.assignmentHistory.donViTrucThuoc.length === i + 1 ? item.tenVietTat : item.tenVietTat + ','} </span>
                    ))
                  }
                </span>
              </div>
            </li> */}
              <li>
                <span className="style-icon icon-calendar"></span>
                <div className="content-detail">
                  <span className="title-bold">Hạn giải quyết: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.hanGiaiQuyet &&
                      moment(this.props.assignmentHistory.hanGiaiQuyet).format(
                        'DD/MM/YYYY'
                      )}{' '}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 1 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chuyenchidao"></span>
                <div className="content-detail">
                  <span className="detail">Chuyển chỉ đạo: </span>
                  <span className="title-bold">
                    {this.props.assignmentHistory.pbChiDaoChuyenDen &&
                      this.props.assignmentHistory.pbChiDaoChuyenDen.maPhongBan}
                  </span>
                </div>
              </li>
              <li>
                <span className="style-icon icon-content"></span>
                <div className="content-detail">
                  <span className="title-bold">Nội dung:</span>
                  <span className="detail textarea-content">
                    {' '}
                    {this.props.assignmentHistory.noiDung}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default AssignmentHistoryItem
