import axios from 'axios'
import querystring from 'querystring'
import * as Actions from '../actions'
import {
  AUTH_API_URL,
  API_URL,
  CHAT_API_URL,
  UPLOAD_API,
  FILE_URL,
  FILE_INFO_URL,
  IMAGE_URL,
  QLLH_URL,
  // MAINTENANCE_URL,
  API_CONNECTED_UNIT_API,
} from '../constants/Api'
import { camelizeKeys } from 'humps'
import { getActionTypes } from 'redux-axios-middleware'
import { normalize } from 'normalizr'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const apiClients = {
  default: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  auth: {
    client: axios.create({
      baseURL: AUTH_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformRequest: [
        data => {
          return querystring.stringify(data)
        },
      ],
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  profile: {
    client: axios.create({
      baseURL: CHAT_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformRequest: [
        data => {
          return querystring.stringify(data)
        },
      ],
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  fileServer: {
    client: axios.create({
      baseURL: FILE_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
    }),
  },
  image: {
    client: axios.create({
      baseURL: IMAGE_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
    }),
  },
  download: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
    }),
  },
  downloadFromChat: {
    client: axios.create({
      baseURL: CHAT_API_URL,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
    }),
  },
  upload: {
    client: axios.create({
      baseURL: UPLOAD_API,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformRequest: [
        data => {
          let formData = new FormData()

          for (var e in data) {
            if (data[e] && data[e].name) {
              formData.append(e, data[e], data[e].name)
            }
          }
          return formData
        },
      ],
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  binaryFile: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: [
        function (data) {
          let blob = new Blob([data], { type: 'application/pdf' })
          return blob
        },
      ],
    }),
  },
  fileInfo: {
    client: axios.create({
      baseURL: FILE_INFO_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  connectedUnit: {
    client: axios.create({
      baseURL: API_CONNECTED_UNIT_API,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  formDataGetFileBinary: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformRequest: [
        data => {
          let formData = new FormData()
          for (var e in data) {
            if (data[e]) {
              formData.append(e, data[e])
            }
          }

          return formData
        },
      ],
      transformResponse: function (data) {
        return data
      },
    }),
  },
  defaultV2: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: function (data) {
        return data
      },
    }),
  },
  defaultFormData: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: function (data) {
        return data
      },
    }),
  },
  meeting: {
    client: axios.create({
      baseURL: QLLH_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
  mockApi: {
    client: axios.create({
      baseURL: 'https://jsonblob.com/',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(
            typeof data !== 'object' ? JSON.parse(data) : data
          )
        },
      ],
    }),
  },
}

export const apiMiddlewareConfig = {
  interceptors: {
    request: [
      function ({ getState, dispatch, getSourceAction }, req) {
        // req: contains information about request object
        if (cookies.get('authState') && cookies.get('authState').roleId) {
          req.headers.ChucDanhId = cookies.get('authState').roleId
        }
        return req
      },
    ],
  },

  onSuccess: ({ action, next, response }, options) => {
    if (action.schema) {
      response.data = normalize(
        typeof response.data !== 'object'
          ? JSON.parse(response.data)
          : response.data,
        action.schema
      )
    }
    const nextAction = {
      type: getActionTypes(action, options)[1],
      payload: response,
      meta: {
        previousAction: action,
      },
    }
    next(nextAction)
    return nextAction
  },
  onError: ({ action, next, error, dispatch }, options) => {
    if (error.response && error.response.status === 401) {
      let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
      let user_login = localStorage.getItem('user_login')
      localStorage.clear()
      localStorage.setItem('user_login', user_login)
      localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
      return dispatch(Actions.authLogout())
    }

    // if (
    //   error.response &&
    //   error.response.status &&
    //   error.response.status !== 504 &&
    //   error.response.status.toString().indexOf(50) > -1
    // ) {
    //   return fetch(MAINTENANCE_URL)
    //     .then(function (response) {
    //       return response.json()
    //     })
    //     .then(function (myJson) {
    //       if (myJson) {
    //         window.location.replace('/')
    //       }
    //     })
    // }

    let errorObject
    if (!error.response) {
      errorObject = {
        data: error.message,
        status: 0,
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('HTTP Failure in Axios', error)
      }
      // fetch(MAINTENANCE_URL)
      //   .then(function (response) {
      //     return response.json()
      //   })
      //   .then(function (myJson) {
      //     if (myJson) {
      //       window.location.replace('/')
      //     }
      //   })
    } else {
      errorObject = error
    }
    const nextAction = {
      type: getActionTypes(action, options)[2],
      error: errorObject,
      meta: {
        previousAction: action,
      },
    }

    next(nextAction)
    return nextAction
  },
}
