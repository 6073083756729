import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { Checkbox, Classes, Button, Intent, Tooltip } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'
import { withCookies } from 'react-cookie'

import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import { Icon } from 'components/newCommon'
import { Table, Pagination } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import { getMyFavoriteGroups, putFavoriteGroup } from 'actions/task'
import FavoriteGroupForm from './Form/FavoriteGroupForm'
import DeleteFavorite from './Confirm/DeleteFavorite'
import BackgroundSetting from './Settings/Background'

const PAGE_SIZE = 10
const PAGE_NAME = 'Quản lý nhóm thường giao việc'
const pageActions = {
  taoMoi: 'them-moi',
  chinhSua: 'chinh-sua',
  xoa: 'xoa',
}

const FavoriteGroup = ({ history, match, allCookies = {} }) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { action: currentAction, id: currentId } = match.params
  const mainPathname = useMemo(
    () => '/quan-ly-cong-viec/nhom-thuong-giao-viec',
    []
  )
  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
    }),
    []
  )

  const [fetching, setFetching] = useState(true)
  const [fetchingFail, setFetchingFail] = useState(false)
  const [favoriteGroup, setFavoriteGroup] = useState([])
  const [checking, setChecking] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [totalPage, setTotalPage] = useState(1)

  // Thao tác nhóm thường giao việc
  const goToAction = useCallback(
    (id, action) => () => {
      const url = `${mainPathname}/${action}/${id}`
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== currentId)
        return history.replace(url)
    },
    [history, currentAction, mainPathname, currentId]
  )

  // Cập nhật trạng thái sử dụng hoặc không sử dụng
  const changeCheckBox = record => e => {
    const { checked } = e.target
    const newRecord = { ...record, isActive: checked }
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => mountedSet(setChecking, true),
      action: () => putFavoriteGroup(toDecamelizeKeys(newRecord)),
      afterResponse: () =>
        mountedSet(setFavoriteGroup, (oldFavoriteGroup = []) =>
          oldFavoriteGroup.map(elm =>
            elm.id === newRecord.id ? newRecord : elm
          )
        ),
      afterAction: () => mountedSet(setChecking, false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  // Get danh sách nhóm thường giao việc
  const _fetchList = useCallback(requestFilter => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getMyFavoriteGroups(requestFilter),
      afterResponse: ({ items = [], totalCount = 0 }) => {
        mountedSet(setFavoriteGroup, items)
        mountedSet(setTotalPage, totalCount)
      },
      afterAction: () => mountedSet(setFetching, false),
      afterError: () => mountedSet(setFetchingFail, true),
    })
  }, [])

  const changeFilter =
    (name, fetch = true) =>
    value => {
      const newFilter = { ...filter, [name]: value }
      mountedSet(setFilter, newFilter)
      if (fetch) {
        _fetchList(newFilter)
      }
    }

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (get(filter, 'page', 1) - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'name',
      width: 50,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Nhóm thường giao việc',
      render: ({ name }) => name,
    },
    {
      key: 'suDung',
      width: 10,
      headClass: 'uppercase',
      className: 'element-center align-center',
      title: 'Sử dụng',
      dataIndex: 'isActive',
      render: (value, record) => (
        <Checkbox
          checked={!!value}
          className={csx(Classes.MINIMAL, 'mb0 inline-block')}
          disabled={
            checking ||
            currentAction === pageActions.taoMoi ||
            currentAction === pageActions.chinhSua
          }
          onChange={changeCheckBox(record)}
        />
      ),
    },
    {
      key: 'thaoTac',
      className: 'element-center align-center',
      width: 5,
      dataIndex: 'id',
      render: value => (
        <>
          <Tooltip
            content={
              <span className="font-size-12">
                Chỉnh sửa nhóm thường giao việc
              </span>
            }
          >
            <Icon
              classIcon="icon-but"
              className={csx(
                'size-icon-12',
                'mr10',
                'icon-color-primary',
                'has-event'
              )}
              onClick={goToAction(value, 'chinh-sua')}
            />
          </Tooltip>
          <Tooltip
            content={
              <span className="font-size-12">Xóa nhóm thường giao việc</span>
            }
          >
            <Icon
              classIcon="icon-bin"
              className={csx('size-icon-12', 'icon-color-danger', 'has-event')}
              onClick={goToAction(value, 'xoa')}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  const openCreate = useCallback(() => {
    const url = `/quan-ly-cong-viec/nhom-thuong-giao-viec/${pageActions.taoMoi}`
    if (!currentAction) return history.push(url)
    if (currentAction) return history.replace(url)
  }, [currentAction, history])

  const actionBlock = useMemo(() => {
    const actionOverlays = {
      [pageActions.taoMoi]: FavoriteGroupForm,
      [pageActions.chinhSua]: FavoriteGroupForm,
      [pageActions.xoa]: DeleteFavorite,
    }
    const ActionOverlay = actionOverlays[currentAction]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        onClose={history.goBack}
        reloadList={() => _fetchList(filter)}
      />
    )
  }, [currentAction, history, _fetchList, filter])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchList(filter)
  }, [_fetchList, filter])

  return (
    <MasterLayout typeSidebar="taskManager" newLayout>
      <div style={{ flex: 1, padding: 40, position: 'relative' }}>
        <header className="d-flex mb20">
          <b
            className={csx('font-size-20 font-weight-600', {
              'color-light': get(
                allCookies,
                'background_image.is_light',
                false
              ),
            })}
            style={{ marginRight: 'auto' }}
          >
            {PAGE_NAME}
          </b>
          <span style={{ marginLeft: 'auto' }}>
            <Tooltip
              content={
                <span className="font-size-12">Tạo nhóm thường giao việc</span>
              }
            >
              <Button
                className={csx('cpc-button', 'no-padding', 'rounded')}
                intent={Intent.PRIMARY}
                onClick={openCreate}
              >
                <Icon classIcon="icon-Plus" />
              </Button>
            </Tooltip>
            <BackgroundSetting />
          </span>
        </header>
        <Table
          className="cpc-table-radius"
          autoHide={false}
          columns={columns}
          data={favoriteGroup}
          loading={fetching}
          error={fetchingFail}
          tableMinWidth={400}
        />
        <div className="element-flex-end w-100 mt15">
          <Pagination
            total={totalPage}
            current={get(filter, 'page', 1)}
            pageSize={PAGE_SIZE}
            onChange={changeFilter('page')}
          />
        </div>
      </div>
      {actionBlock}
    </MasterLayout>
  )
}

export default withCookies(memo(FavoriteGroup))
