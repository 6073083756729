import React, { useState } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import QuaTrinhXuLyItem from './QuaTrinhXuLyItem'
import QuaTrinhXuLyDonViCon from './QuaTrinhXuLyDonVi'

const QuaTrinhXuLyDonVi = ({
  data,
  hasChild = false,
  tokenDO,
  id,
  idDv,
  childData = [],
}) => {
  const [isOpenGroup] = useState(false)
  const [childrenData] = useState(null)
  return (
    <div className="font-roboto">
      <div
        className={classNames('do-collapse-title mt10', { pl20: !hasChild })}
      >
        {hasChild && (
          <span
            className={classNames(
              'pt-icon font-size-13 wh-16 mr5 color-blue cursor-pointer',
              {
                'pt-icon-chevron-up': isOpenGroup,
                'pt-icon-chevron-down': !isOpenGroup,
              }
            )}
          />
        )}
        <p className="mr10 font-weight-600 color-text">
          {data?.tENDV || data?.tenDv || ''}
        </p>
      </div>
      {!isEmpty(childrenData) && isOpenGroup && (
        <div className="pl15">
          {childrenData?.lANHDAO?.map?.((item, index) => (
            <QuaTrinhXuLyItem
              key={index}
              hasChild={item.isChildren}
              isLeader={item?.isChidao ?? false}
              id={id}
              idDv={idDv}
              tokenDO={tokenDO}
              itemData={item}
            />
          ))}
          {childrenData?.pHONGBAN?.map?.((item, index) => (
            <QuaTrinhXuLyItem
              key={index}
              hasChild={item.isChildren}
              isLeader={item?.isChidao ?? false}
              id={id}
              idDv={idDv}
              tokenDO={tokenDO}
              itemData={item}
            />
          ))}
        </div>
      )}
      <div className="ml15">
        {size(childData) !== 0 &&
          isOpenGroup &&
          childData?.map((item, index) => (
            <QuaTrinhXuLyDonViCon
              key={index}
              id={id}
              idDv={item?.idDv}
              hasChild={true}
              tokenDO={tokenDO}
              data={item}
            />
          ))}
      </div>
    </div>
  )
}

export default QuaTrinhXuLyDonVi
