import { useState, useEffect, useMemo, useCallback } from 'react'

import requestAction from 'helpers/request'
import Utils from 'helpers/utils'

const useList = ({ actions }) => {
  const filterKeys = useMemo(() => {
    return {
      textSearch: 'textSearch',
      from: 'from',
      to: 'to',
    }
  }, [])

  const [isFetching, setIsFetching] = useState(false)
  const [filterData, setFilterData] = useState(null)
  const [listData, setListData] = useState(null)
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  })
  const [deleteData, setDeleteData] = useState(null)

  const limit = pagination?.limit

  const fetchList = useCallback(
    (page = 1, params = null) => {
      setIsFetching(true)
      let paramsTemp = params
      let pageTemp = page
      const filterStore = Utils.preData('general-notifications')
      const pageStore = Utils.preData('general-notifications-page')
      if (filterStore && !params) {
        paramsTemp = filterStore
      }

      if (pageStore && !params) {
        pageTemp = pageStore
      }

      const trimText =
        paramsTemp?.[filterKeys.textSearch] &&
        paramsTemp?.[filterKeys.textSearch].trim()
          ? paramsTemp?.[filterKeys.textSearch].trim()
          : undefined
      const newParams = {
        textSearch: trimText,
        fromDate: paramsTemp?.[filterKeys.from]
          ? paramsTemp?.[filterKeys.from]
          : undefined,
        toDate: paramsTemp?.[filterKeys.to]
          ? paramsTemp?.[filterKeys.to]
          : undefined,
        page: pageTemp,
        limit: limit,
      }
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => actions.getDSThongBaoChung(newParams),
        afterResponse: response => {
          Utils.preData('general-notifications', paramsTemp)
          Utils.preData('general-notifications-page', pageTemp)
          setPagination(prev => ({
            ...prev,
            page: pageTemp,
          }))
          setFilterData(paramsTemp)
          setListData(response?.result)
        },
        afterAction: () => {
          setIsFetching(false)
        },
      })
    },
    [actions, limit, filterKeys]
  )

  const onChangeFilter = useCallback(
    ({ name, isReFetch = true, isMultipleKey = false }) =>
      value => {
        let temp = filterData
        if (name === 'PAGE') {
          Utils.preData('general-notifications-page')
          return fetchList(value, temp)
        } else if (name === 'RESET') {
          Utils.preData('general-notifications')
          Utils.preData('general-notifications-page')
          temp = {}
        } else if (isMultipleKey) {
          temp = {
            ...temp,
            ...value,
          }
        } else {
          temp = {
            ...temp,
            [name]: value,
          }
        }

        setFilterData(temp)
        if (isReFetch) {
          return fetchList(1, temp)
        }
      },
    [filterData, fetchList]
  )

  useEffect(() => {
    fetchList()
    return () => {
      setListData(null)
    }
  }, [fetchList])

  return {
    filterKeys,
    filterData,
    listData,
    pagination,
    isFetching,
    deleteData,
    onChangeFilter,
    setDeleteData,
  }
}

export default useList
