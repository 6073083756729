import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Authorization, Permission } from '../../components/auth'

import Pagination from '../Pagination'

class ListFooter extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
    type: PropTypes.string,
  }

  render() {
    const AuthElement = Authorization.Element

    const { current, total, type, onChange } = this.props

    if ((total || 0) < 2) {
      return null
    }

    return (
      <div className="footer-list p-0">
        {type !== 'EVN' && (
          <AuthElement permission={[Permission.CONGVANDEN_QUANLY]}>
            <div
              className={classnames('note-issues', {
                'note-issues-none': !!total,
              })}
              style={{ padding: '10px 10px 0' }}
            >
              <span className="name-note">Chú Thích</span>
              <ul className="list-note">
                <li className="note-item">
                  <span className="pt-icon-star pt-icon check-star"></span>Công
                  văn mới
                </li>
              </ul>
            </div>
          </AuthElement>
        )}

        <Pagination current={current} total={total} onChange={onChange} />
      </div>
    )
  }
}

export default ListFooter
