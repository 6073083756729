import React from 'react'
import PropTypes from 'prop-types'
import { LoadingItem } from '../common/'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { MESSAGE_NOT_FOUND, MESSAGE_ERROR } from '../../constants/MessageForm'

const DragHandle = SortableHandle(() => (
  <span className="icon-move-drap"></span>
))

const SortableItem = SortableElement(({ value, stt, handleClickPopup }) => (
  <div className="table-rows">
    <div className="table-cols-content width-col-5">
      <DragHandle />
    </div>
    <div className="table-cols-content width-col-5">
      <span className="order-content">{stt}</span>
    </div>
    {/* <div className="table-cols-content width-col-10">
      <span className="text-tree-push">{value.maNhanVien}</span>
    </div> */}
    <div className="table-cols-content width-col-25">
      <span className="text-tree-push">{value.tenNhanVien}</span>
    </div>
    <div className="table-cols-content width-col-20">
      <span className="order-content">{value.chucVu}</span>
    </div>
    <div className="table-cols-content width-col-20">
      <span className="text-content">{value.username}</span>
    </div>
    <div className="table-cols-content width-col-20">
      <span className="text-content">{value.tenDoiTuong}</span>
    </div>
    <div className="table-cols-content width-col-5">
      <span className="pt-popover-target">
        <Popover
          popoverClassName="pt-popover-content-sizing pt-minimal"
          position={Position.BOTTOM_RIGHT}
          interactionKind={PopoverInteractionKind.HOVER}
          openOnTargetFocus={true}
          content={
            <div className="menu-popover">
              <Link
                className="item"
                to={`/he-thong/nguoi-dung/chinh-sua/${value.nhanVienId}`}
              >
                <span className="pt-icon pt-icon-annotation"></span>
                <span className="name_action">Chỉnh sửa</span>
              </Link>
              <span
                className="item"
                onClick={handleClickPopup.bind(this, value.chucDanhId)}
              >
                <span className="pt-icon pt-icon-trash"></span>
                <span className="name_action">Xóa</span>
              </span>
            </div>
          }
        >
          <span className="icon-More"></span>
        </Popover>
      </span>
    </div>
  </div>
))

const SortableList = SortableContainer(({ items, handleClickPopup }) => {
  return (
    <div className="sortableList">
      {items.map((item, k) => (
        <SortableItem
          stt={k + 1}
          key={k}
          index={k}
          value={item}
          handleClickPopup={handleClickPopup}
        />
      ))}
    </div>
  )
})

class ListDecentralizationUser extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      nhanVienPhongBanId: id,
    })
  }

  handleSubmitRemoveItem() {
    if (this.state.nhanVienPhongBanId) {
      this.props.onSubmit && this.props.onSubmit(this.state.nhanVienPhongBanId)
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    isEmpty: PropTypes.bool,
    departments: PropTypes.array,
    users: PropTypes.array,
  }

  static defaultProps = {
    departments: [],
    users: [],
  }

  state = {
    users: [],
    isActive: this.props.auth.mainDepartmentId,
    isOpenPopup: false,
    nhanVienPhongBanId: null,
    isEmpty: false,
    isLoading: true,
  }

  componentWillReceiveProps(props) {
    if (props.DepartmentId !== this.props.DepartmentId) {
      this.setState({ isActive: props.DepartmentId })
    }

    if (props.users.length === 0) {
      this.setState({ users: [] })
    }

    if (props.users !== this.props.users) {
      clearTimeout(this.loadUsers)
      this.updateUser(props.users)
    }
  }

  updateUser(users) {
    if (users.length === 0 && this.props.isEmpty === true) {
      return (this.loadUsers = setTimeout(() => {
        this.setState({
          isEmpty: true,
          isLoading: false,
        })
      }, 500))
    }

    this.loadUsers = setTimeout(() => {
      this.setState({
        users: users,
        isEmpty: false,
        isLoading: false,
      })
    }, 500)
  }

  handleClickDepartment(id, e) {
    e.preventDefault()
    this.setState({ isActive: id })
    this.props.onClickDepartment && this.props.onClickDepartment(id)
  }

  renderSubItem(items) {
    return (
      <ul className="sublist">
        {items &&
          items.map(item => {
            return (
              <li className="subitem" key={item.phongBanId}>
                <div
                  className={classnames('main-content', {
                    active: this.state.isActive === item.phongBanId,
                  })}
                >
                  <span className="left-icon icon-Back"></span>
                  <a
                    href="/#"
                    className="text-link"
                    onClick={this.handleClickDepartment.bind(
                      this,
                      item.phongBanId
                    )}
                  >
                    <span className="text-content">{item.tenPhongBan}</span>
                  </a>
                </div>
                {item.dsPhongBanCon && this.renderSubItem(item.dsPhongBanCon)}
              </li>
            )
          })}
      </ul>
    )
  }

  handleSort({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      this.setState(
        {
          users: arrayMove(this.state.users, oldIndex, newIndex),
        },
        () => {
          this.props.onSort && this.props.onSort(this.state.users)
        }
      )
    }
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    return (
      <div className="container-list-permission-user">
        <div className="cols-group-content decentralized-users-content">
          <div className="aside-col-content">
            <div className="aside-nav">
              <div className="head-content-aside">
                <h3 className="main-heading heading-14">Phòng ban</h3>
              </div>
              <div className="aside-main-content">
                <div className="aside-list-content">
                  <ul className="departments-list">
                    {this.props.isLoading && this.state.isLoading ? (
                      <div className="table-loading">
                        <span className="colum-loading">
                          <LoadingItem />
                        </span>
                      </div>
                    ) : (
                      this.props.departments.map(item => (
                        <li className="item-list" key={item.phongBanId}>
                          <div
                            className={classnames('main-content', {
                              active: this.state.isActive === item.phongBanId,
                            })}
                          >
                            <a
                              href="/#"
                              className="text-link"
                              onClick={this.handleClickDepartment.bind(
                                this,
                                item.phongBanId
                              )}
                            >
                              <span className="text-content">
                                {item.tenPhongBan}
                              </span>
                            </a>
                          </div>
                          {item.dsPhongBanCon &&
                            this.renderSubItem(item.dsPhongBanCon)}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-main-content listrole-container decentralized-users-main">
            <div className="table-container table-subcollap-container">
              <div className="table-display-container">
                <div className="table-display">
                  {!this.state.isEmpty &&
                    !this.props.isEmpty &&
                    !this.props.isLoading &&
                    !this.props.isError && (
                      <div className="table-display table-head-container">
                        <div className="table-cols-content width-col-5">
                          <span className="head-label"></span>
                        </div>
                        <div className="table-cols-content width-col-5">
                          <span className="head-label">STT</span>
                        </div>
                        {/* <div className="table-cols-content width-col-15">
                        <span className="head-label">Mã nhân viên</span>
                      </div> */}
                        <div className="table-cols-content width-col-25">
                          <span className="head-label">Tên Nhân viên</span>
                        </div>
                        <div className="table-cols-content width-col-20">
                          <span className="head-label">Chức vụ</span>
                        </div>
                        <div className="table-cols-content width-col-20">
                          <span className="head-label">User</span>
                        </div>
                        <div className="table-cols-content width-col-20">
                          <span className="head-label">Quyền</span>
                        </div>
                        <div className="table-cols-content width-col-5"></div>
                      </div>
                    )}
                  {this.props.isLoading ? (
                    <div className="table-loading-system">
                      {ItemLoading}
                      {ItemLoading}
                      {ItemLoading}
                    </div>
                  ) : (
                    !this.props.isError && (
                      <div className="table-body-container">
                        <SortableList
                          items={this.state.users}
                          onSortEnd={this.handleSort.bind(this)}
                          handleClickPopup={this.handleClickPopup}
                          lockAxis="y"
                          useDragHandle={true}
                          helperClass="SortableHelper"
                        />
                      </div>
                    )
                  )}
                </div>
                {this.state.isEmpty &&
                  this.props.isEmpty &&
                  !this.props.isLoading &&
                  !this.props.isError && (
                    <div className="error-page-container">
                      <div className="error-content">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/empty-default.png'
                          }
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">{MESSAGE_NOT_FOUND}</p>
                      </div>
                    </div>
                  )}
                {this.props.isError && !this.props.isLoading && (
                  <div className="error-page-container">
                    <div className="error-content">
                      <img
                        src={process.env.PUBLIC_URL + '/images/Server.png'}
                        alt="error"
                        className="img-thumb-error"
                      />
                      <p className="description-note">{MESSAGE_ERROR}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.XOA_NGUOI_DUNG}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitRemoveItem}
        >
          {POPUP.TEXT_SUBMIT_BODY}
        </PopupConfirm>
      </div>
    )
  }
}

export default ListDecentralizationUser
