import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup, Button, Intent } from '@blueprintjs/core'
import { PopupConfirm } from '../common/'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import classnames from 'classnames'
import includes from 'lodash/includes'

class Report extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    reportWorkPersonal: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickPopupDelete = this.handleClickPopupDelete.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
  }

  state = {
    valueRadio: '2',
    isAdd: [''],
    phieuGiaoViecId: this.props.work.phieuGiaoViecId,
    thucHien: '',
    attachFiles: [],
    errors: [],
    error: {},
    isOpenPopup: false,
    dataSelect: '',
    congVanDi: undefined,
    fileEdit: [],
    attachFileName: [],
    isOpenPopupDelete: false,
    sizeFile: [],
    isLoading: {
      isLoadingSubmit: false,
      isLoadingRemove: false,
    },
    attachFilesProgress: [0],
  }

  getUploadProgress = (name, i) => progress => {
    let state = this.state
    if (!isNaN(i) && name) {
      state[name][i] = Math.floor((progress.loaded * 100) / progress.total)
    }
    this.setState(state)
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ error: {} })
    const error = {}

    if (
      this.props.work &&
      this.props.work.baoCaoLai &&
      this.state.thucHien.trim().length === 0
    ) {
      error.thucHien = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({
      error: error,
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })

    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
      })
      this.props.onSubmit(
        'BaoCao',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
      return
    }

    const objectFile = []
    let files = []
    let attachFilesProgress = []

    if (this.state.attachFiles.length) {
      attachFilesProgress = Array(this.state.attachFiles.length).fill(0)
      await this.setState({ attachFilesProgress })
      try {
        await Promise.all(
          this.state.attachFiles.map(async (item, i) => {
            const data = await this.props.actions.fileUpload(
              item,
              this.getUploadProgress('attachFilesProgress', i)
            )
            return files.push({
              file_id: data.payload.data.result[0].id,
              kieu_file: data.payload.data.result[0].extension,
              ten_file: data.payload.data.result[0].originalName,
              url: data.payload.data.result[0].path,
              kich_thuoc: data.payload.data.result[0].length,
            })
          })
        )
      } catch (e) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        return this.props.onSubmit(
          'BaoCao',
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      }
    }

    files.map(item => objectFile.push(item))
    this.state.fileEdit.map(item => objectFile.push(item))
    delete this.state.attachFiles
    delete this.state.fileEdit
    if (this.props.reportWorkPersonal) {
      this.props.actions
        .updateReportWorkPersonal(this.state.phieuGiaoViecId, {
          phieu_giao_viec_id: this.state.phieuGiaoViecId,
          trang_thai: Number(this.state.valueRadio),
          thuc_hien: this.state.thucHien,
          cong_van_di_id: this.state.congVanDi && this.state.congVanDi.value,
          ds_file_dinh_kem: objectFile,
        })
        .then(
          res => {
            if (res.error) {
              return this.handleSubmit(
                'BaoCao',
                MESSAGE.TOATS_MESSAGE_FAIL,
                Intent.DANGER,
                false
              )
            } else if (res.payload && res.payload.status === 200) {
              this.props.onSubmit(
                'BaoCao',
                MESSAGE.TEXT_UPDATE_REPORT_WORK_SUCCESS,
                Intent.SUCCESS,
                true
              )
            }
          },
          () => {
            return this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
          }
        )
    } else {
      this.props.actions
        .postWorkReport(this.state.phieuGiaoViecId, {
          phieu_giao_viec_id: this.state.phieuGiaoViecId,
          trang_thai: Number(this.state.valueRadio),
          thuc_hien: this.state.thucHien,
          cong_van_di_id: this.state.congVanDi && this.state.congVanDi.value,
          ds_file_dinh_kem: objectFile,
        })
        .then(
          res => {
            if (res.error) {
              return this.handleSubmit(
                'BaoCao',
                MESSAGE.TOATS_MESSAGE_FAIL,
                Intent.DANGER,
                false
              )
            } else if (res.payload && res.payload.status === 200) {
              this.props.onSubmit(
                'BaoCao',
                MESSAGE.TEXT_REPORT_WORK_SUCCESS,
                Intent.SUCCESS,
                true
              )
            }
          },
          () => {
            return this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
          }
        )
    }
  }

  handleSelect = val => {
    this.setState({ congVanDi: val })
  }

  handleRadio = e => {
    this.setState({ valueRadio: e.target.value })
  }

  handleFile = (i, e) => {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    let reader = new FileReader()
    let file = e.target.files[0]

    const errors = this.state.errors
    const attachFiles = e.target.value
    const extension =
      attachFiles &&
      attachFiles
        .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    const fileEdit = this.state.fileEdit
    const sizeFile = file && file.size
    const typesFile = [
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'ppsx',
      'pps',
    ]
    if (!includes(typesFile, extension)) {
      errors[i] = MESSAGE.ValidateForm.FILE_TYPE(typesFile)
    } else if (sizeFile && sizeFile > 1024 * 1024 * 25) {
      errors[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    } else {
      delete errors[i]
      fileEdit.splice(i, 1)
    }

    this.setState({ errors, fileEdit })

    if (!errors[i]) {
      reader.onloadend = () => {
        state[name][id] = file
        state.attachFileName[i] = file.name
        state.sizeFile[i] = file.size
        this.setState(state)
      }
    } else {
      state.attachFiles.splice(i, 1)
      state.sizeFile.splice(i, 1)
      this.setState({ state })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleEventAddFile = () => {
    this.setState({
      isAdd: this.state.isAdd.concat(this.state.isAdd.length + 1),
    })
  }

  handleEventRemoveFile = i => {
    const state = this.state
    if (i !== 0) {
      state.isAdd.splice(i, 1)
    } else {
      if (state.isAdd.length > 1) {
        state.isAdd.splice(i, 1)
      } else {
        state.isAdd[i] = ''
      }
    }

    state.attachFiles.splice(i, 1)
    state.errors.splice(i, 1)
    state.fileEdit.splice(i, 1)
    state.attachFileName.splice(i, 1)
    state.sizeFile.splice(i, 1)

    this.setState(state)
  }

  handleClickPopupDelete = () => {
    this.setState({
      isOpenPopupDelete: !this.state.isOpenPopupDelete,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopupDelete: false,
      isLoading: {
        ...this.state.isLoading,
        isLoadingRemove: true,
      },
    })

    this.props.actions
      .deleteWorkReport(this.props.reportWorkPersonal.phieuGiaoViecId)
      .then(
        res => {
          if (res.payload && res.payload.status === 200) {
            this.props.onSubmit(
              'XoaBaoCao',
              MESSAGE.TEXT_DELETE_REPORT_WORK_SUCCESS,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.props.onSubmit(
              'XoaBaoCao',
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          }
        },
        () => {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingRemove: false,
            },
          })
        }
      )
  }

  componentDidMount() {
    if (this.props.reportWorkPersonal) {
      this.setState({
        thucHien:
          this.props.reportWorkPersonal.thucHien !== null
            ? this.props.reportWorkPersonal.thucHien
            : '',
        valueRadio: String(this.props.reportWorkPersonal.trangThai),
        phieuGiaoViecId: this.props.reportWorkPersonal.phieuGiaoViecId,
        isLoading: {
          isLoadingRemove: true,
          isLoadingSubmit: true,
        },
      })

      this.props.reportWorkPersonal.dsVanBanTraLoi.forEach(item => {
        if (item.congVanDi !== null && item.congVanDi.congVanDiId) {
          this.setState({
            congVanDi: {
              value: item.congVanDi.congVanDiId,
              label: item.congVanDi.soCongVan,
            },
          })
        }
      })

      const attachFileName = []
      const fileEdit = []
      const isAdd = []

      this.props.reportWorkPersonal.dsVanBanTraLoi.forEach((item, i) => {
        if (item.documentStore !== null) {
          fileEdit.push({
            file_id: item.documentStore.fileId,
            kich_thuoc: item.documentStore.kichThuoc,
            ten_file: item.documentStore.tenFile,
            url: item.documentStore.url,
            kieu_file: item.documentStore.kieuFile,
          })
          attachFileName.push(item.documentStore && item.documentStore.tenFile)
          isAdd.push(i)
        }
      })
      if (isAdd.length === 0) {
        isAdd.push(1)
      }
      this.setState({
        fileEdit,
        isAdd,
        attachFileName,
        isLoading: {
          isLoadingSubmit: false,
          isLoadingRemove: false,
        },
      })
    }
  }

  getOptions = (input, callback) => {
    if (input.length > 2) {
      if (this.promiseGetEmail) {
        clearTimeout(this.promiseGetEmail)
      }
      this.promiseGetEmail = setTimeout(() => {
        this.props.actions
          .searchSendDocument(`${input}`)
          .then(response => {
            return response.payload.data.result.items
          })
          .then(json => {
            const data = json.map(item => ({
              value: item.congVanDiId,
              label: item.soCongVan,
            }))
            callback(null, {
              options: data,
              complete: true,
            })
            this.setState({ dataSelect: data })
          })
      }, 800)
    } else if (input.length < 3) {
      callback(null, {
        options: null,
        complete: true,
      })
    } else {
      callback(null, {
        options: null,
        complete: true,
      })
    }
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="name-heading" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">
            {this.props.reportWorkPersonal ? 'Sửa/Hủy báo cáo' : 'Báo cáo'}
          </h4>
        </div>
        <div className="form-container container-detail form-report-work">
          <div className="pt-form-group">
            <label className="pt-label">Tình trạng</label>
            <div className="pt-form-content">
              <RadioGroup
                selectedValue={this.state.valueRadio}
                onChange={this.handleRadio.bind(this)}
              >
                <Radio
                  label="Đang thực hiện"
                  value="1"
                  className="pt-large pt-inline name-radio"
                />
                <Radio
                  label="Đã hoàn thành"
                  value="2"
                  className="pt-large pt-inline name-radio"
                />
              </RadioGroup>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <label className="pt-label">
              Cách thực hiện
              {this.props.work && this.props.work.baoCaoLai && (
                <span className="pt-text-muted required-input">*</span>
              )}
            </label>
            <div className="pt-form-content">
              <div className="pt-form-content">
                <textarea
                  name="thucHien"
                  onChange={this.handleChange}
                  rows="4"
                  className="pt-input pt-fill"
                  placeholder="Nhập nội dung…"
                  dir="auto"
                  autoFocus={true}
                  value={this.state.thucHien}
                ></textarea>
                <div className="pt-form-helper-text pt-report-work">
                  {this.state.error.thucHien !== undefined && (
                    <div className="pt-form-helper-text pt-report-work">
                      {this.state.error.thucHien}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <label className="pt-label">
              Văn bản trả lời
              {/* { this.props.work.baoCaoLai === true &&
                    <span className="pt-text-muted required-input">*</span>
                  } */}
            </label>
            <div className="pt-form-content">
              <div className="pt-form-content select2-custom-iconsearch">
                <Select.Async
                  name="form-field-name"
                  value={this.state.congVanDi}
                  loadOptions={this.getOptions}
                  onChange={this.handleSelect}
                  onCloseResetsInput={true}
                  onBlurResetsInput={true}
                  autoload={false}
                  placeholder="Nhập số công văn"
                  clearValueText="Xóa dữ liệu"
                  searchPromptText="Nhập số công văn...."
                  loadingPlaceholder="Đang tìm kiếm...."
                  noResultsText="Không tìm thấy dữ liệu..."
                  arrowRenderer={() => {}}
                  ignoreAccents={false}
                />
              </div>
            </div>
          </div>
          {this.state.isAdd.map((item, i) => (
            <div className="pt-form-group pt-intent-danger" key={i}>
              <label className="pt-label">
                {i === 0 && <span>File đính kèm</span>}
              </label>
              <div className="pt-form-content">
                <div
                  className={classnames('pt-form-content', {
                    'upload-have-close':
                      i > 0 ||
                      (this.state.attachFiles && this.state.attachFiles[0]),
                  })}
                >
                  <label className="pt-file-upload">
                    <input
                      type="file"
                      name={`attachFiles-${i}`}
                      onChange={this.handleFile.bind(this, i)}
                      disabled={
                        this.state.isLoading &&
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined
                      }
                      onClick={event => {
                        event.target.value = null
                      }}
                    />
                    <span className="pt-file-upload-input">
                      {this.state.attachFileName[i]
                        ? this.state.attachFileName[i]
                        : 'Chọn file đính kèm'}
                    </span>
                  </label>
                  {(i > 0 ||
                    (i === 0 &&
                      ((this.state.attachFiles && this.state.attachFiles[0]) ||
                        (this.state.fileEdit && this.state.fileEdit[0])))) &&
                    this.state.isLoading &&
                    Object.values(this.state.isLoading).find(
                      item => item === true
                    ) === undefined && (
                      <span
                        onClick={this.handleEventRemoveFile.bind(this, i)}
                        className="icon-bc icon-close icon-remove-file"
                      ></span>
                    )}
                  {this.state.sizeFile[i] !== undefined &&
                    this.state.errors[i] === undefined && (
                      <div className="pt-form-helper-text pt-form-helper-infomation">
                        Kích thước file đã upload:
                        <span className="size-file">
                          {' '}
                          {Tool.Utils.getFileSize(this.state.sizeFile[i])}
                        </span>
                      </div>
                    )}
                  {Object.values(this.state.isLoading).find(
                    item => item === true
                  ) !== undefined &&
                    this.state.attachFilesProgress &&
                    this.state.attachFilesProgress[i] > 0 && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {this.state.attachFilesProgress[i]}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{
                              width: this.state.attachFilesProgress[i] + '%',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  <div className="pt-form-helper-text">
                    {this.state.errors[i] !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.errors[i]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="pt-form-group">
            <div className="pt-form-content">
              <div className="pt-form-content">
                <Button
                  className="btn-add-file"
                  onClick={this.handleEventAddFile.bind(this)}
                >
                  <span>Thêm file đính kèm</span>
                  <span className="icon-bc icon-Plus"></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          {this.props.reportWorkPersonal && (
            <Button
              onClick={this.handleClickPopupDelete}
              className="pt-button pull-left btn-cancel btn-cancel-steering"
              loading={
                this.state.isLoading &&
                this.state.isLoading.isLoadingRemove === true
              }
              disabled={
                this.state.isLoading &&
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              Hủy báo cáo
              <span className=" pt-icon pt-icon-cross"></span>
            </Button>
          )}
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={
              this.state.isLoading &&
              Object.values(this.state.isLoading).find(
                item => item === true
              ) !== undefined
            }
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-main-color"
            loading={
              this.state.isLoading &&
              this.state.isLoading.isLoadingSubmit === true
            }
            disabled={
              this.state.isLoading &&
              Object.values(this.state.isLoading).find(
                item => item === true
              ) !== undefined
            }
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopupDelete}
          onClose={this.handleClickPopupDelete}
          title={POPUP.HUY_BAO_CAO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitRemoveItem}
        >
          {POPUP.COMFIRM_HUY_BAO_CAO}
        </PopupConfirm>
      </div>
    )
  }
}

export default Report
