import React from 'react'
import PropTypes from 'prop-types'
import Route from 'react-router/Route'
import withRouter from 'react-router/withRouter'
import ConnectedSwitch from './connectedSwitch'
import QueryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { get } from 'lodash'

const RenderRoutes = ({ routes, auth, location }) => {
  // Nhận query params từ url hoặc từ app desktop;
  const query = QueryString.parse(get(location, 'search', ''))
  let token = undefined
  const { type, chucdanhid: roleId, accessToken, workid } = query
  if (accessToken) {
    const { expiresIn, tokenType, refreshToken } = query
    token = {
      accessToken,
      expiresIn,
      tokenType,
      refreshToken,
    }
  }

  if (!routes) {
    return null
  }

  routes.forEach(route => {
    if (!route.requireLogin || !route.routes) {
      return
    }
    const requireLogin = route.requireLogin
    route.routes.forEach(route => (route.requireLogin = requireLogin))
  })

  return (
    <ConnectedSwitch>
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={props => {
            if (
              route?.privateRouter &&
              (token || (route.requireLogin && !auth.token)) &&
              get(location, 'pathname', '') !== route.requireLogin
            ) {
              return (
                <Redirect
                  to={{
                    pathname: route.requireLogin,
                    state: { from: route.path, token, type, roleId, workid },
                  }}
                />
              )
            } else {
              return <route.component {...props} route={route} />
            }
          }}
        />
      ))}
    </ConnectedSwitch>
  )
}

RenderRoutes.propTypes = {
  routes: PropTypes.array.isRequired,
  parent: PropTypes.array,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ auth: state.auth })

export default withRouter(connect(mapStateToProps, null)(RenderRoutes))
