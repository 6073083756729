import { useState, useEffect, useCallback } from 'react'

const useDetail = ({ id, actions }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [detail, setDetail] = useState(false)

  const getDetail = useCallback(() => {
    if (!id) {
      return
    }

    setIsFetching(true)
    actions.fetchNotificationItem(id).then(res => {
      setIsFetching(false)
      const data = res?.payload?.data?.result
      setDetail(data)
    })
  }, [id, actions])

  useEffect(() => {
    getDetail()
  }, [getDetail])

  return {
    isFetching,
    detail,
  }
}

export default useDetail
