import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LoginForm } from '../../components/auth'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { COPYRIGHT, FOLDER_NAME, COMPANY_NAME } from '../../constants/Api'
import * as AuthActions from '../../actions'

class Login extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  }

  render() {
    const bg = `/config/${FOLDER_NAME}/images/background-login.png`
    const logo = `/config/${FOLDER_NAME}/images/logo.svg`
    return (
      <div
        className="page-login-wrapper"
        style={{
          backgroundImage: `url('${bg}')`,
        }}
      >
        <div className="page-login-block">
          <h1 className="page-login-logo">
            <img src={logo} alt={COMPANY_NAME} />
          </h1>
          <LoginForm actions={this.props.actions} />
        </div>
        <div className="page-login-copyright">{COPYRIGHT}</div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AuthActions, dispatch),
})

export default connect(null, mapDispatchToProps)(Login)
