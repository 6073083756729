import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { PopupConfirm } from '../common/'
import { Authorization, Permission } from '../auth'
import * as POPUP from '../../constants/Popup'
import classnames from 'classnames'
import { Intent } from '@blueprintjs/core'
import {
  CHINH_SUA_DE_XUAT_THU_HOI_THANH_CONG,
  CHINH_SUA_DE_XUAT_THU_HOI_KHONG_THANH_CONG,
  TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG,
  TEXT_DE_XUAT_THUHOI_CONGVAN_THANHCONG,
  TEXT_THUHOICONGVAN_KHONGTHANHCONG,
  TEXT_THUHOICONGVAN_THANHCONG,
} from '../../constants/MessageForm'
import { isValidCondition } from '../../helpers/Helper'
import { get } from 'lodash'

const typeRequest = {
  CHINH_SUA_DE_XUAT: 1, // Chỉnh sửa đề xuất
  HUY_DE_XUAT: 2, // Hủy đề xuất
}

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRedirect: false,
      isOpenPopup: false,
      isOpenPopupHuyYeuCau: false,
      isOpenPopupDongYThuHoi: false,
      isLoadingButton: false,
      checkHienThiHuyDeXuatVaChinhSuaDeXuat: false,
      isShowActionDongYThuHoi: false,
      textLyDoThuHoi: '',
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleChinhSuaLyDoDeXuatThuHoi =
      this.handleChinhSuaLyDoDeXuatThuHoi.bind(this)
    this.handleSubmitChinhSuaLyDoDeXuatThuHoi =
      this.handleSubmitChinhSuaLyDoDeXuatThuHoi.bind(this)
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  componentDidMount() {
    const { item, auth } = this.props
    if (item.lyDoThuHoi !== '') {
      this.setState({
        textLyDoThuHoi: item.lyDoThuHoi,
      })
    }

    if (
      (get(item, 'nguoiDeXuat') &&
        auth &&
        auth.user &&
        auth.user.dsChucDanh &&
        auth.user.dsChucDanh.filter(
          elm => elm.id === item.nguoiDeXuat.chucDanhId
        ).length !== 0) ||
      isValidCondition(auth.permission, [Permission.DONGY_THUHOI], 'AND')
    ) {
      this.setState({
        checkHienThiHuyDeXuatVaChinhSuaDeXuat: true,
      })
    }

    // Kiểm tra hiển thị đồng ý thu hồi
    if (
      isValidCondition(auth.permission, [Permission.DONGY_THUHOI], 'AND') &&
      item &&
      item.trangThai === 0 &&
      get(item.nguoiKy, 'nhanVienId') === get(auth.user, 'nhanVienId')
    ) {
      this.setState({
        isShowActionDongYThuHoi: true,
      })
    }
  }

  handleClick = (e, trangThai) => {
    e.preventDefault()
    if (trangThai === 0) {
      this.setState({
        isRedirect: true,
      })
    }
  }

  handleChinhSuaLyDoDeXuatThuHoi = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleHuyDeXuatThuHoi = () => {
    this.setState({
      isOpenPopupHuyYeuCau: !this.state.isOpenPopupHuyYeuCau,
    })
  }

  handleSubmitHuyDeXuatThuHoiCongVan = async () => {
    try {
      this.setState({
        isLoadingButton: true,
      })
      const params = {
        updateType: typeRequest.HUY_DE_XUAT,
      }
      const dataParams = {
        cong_van_di_id: this.props.item.congVanDiId,
        ly_do_thu_hoi: undefined,
      }
      const data = await this.props.actions.putCapNhatDeXuatThuHoi(
        params,
        dataParams
      )
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      }

      const thongBaoThanhCong = this.props.actions.commonAddToasterMessage({
        message: TEXT_DE_XUAT_THUHOI_CONGVAN_THANHCONG,
        intent: Intent.SUCCESS,
      })
      if (thongBaoThanhCong) {
        this.props.reloadData && this.props.reloadData()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.props.actions.getTongSoCongVanThuHoi()
      this.setState({
        isLoadingButton: false,
        isOpenPopupHuyYeuCau: !this.state.isOpenPopupHuyYeuCau,
      })
    }
  }

  handleDongYThuHoi = () => {
    this.setState({
      isOpenPopupDongYThuHoi: !this.state.isOpenPopupDongYThuHoi,
    })
  }

  handleSubmiDongYThuHoi = async () => {
    try {
      this.setState({
        isLoadingButton: true,
      })
      const data = await this.props.actions.putDongYThuHoiCongVanDi(
        this.props.item.id
      )
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TEXT_THUHOICONGVAN_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      }

      const thongBaoThanhCong = this.props.actions.commonAddToasterMessage({
        message: TEXT_THUHOICONGVAN_THANHCONG,
        intent: Intent.SUCCESS,
      })
      if (thongBaoThanhCong) {
        this.props.reloadData && this.props.reloadData()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: TEXT_THUHOICONGVAN_KHONGTHANHCONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.props.actions.getTongSoCongVanThuHoi()
      this.setState({
        isOpenPopupDongYThuHoi: !this.state.isOpenPopupDongYThuHoi,
        isLoadingButton: false,
      })
    }
  }

  handleSubmitChinhSuaLyDoDeXuatThuHoi = async lyDo => {
    try {
      this.setState({
        isLoadingButton: true,
        textLyDoThuHoi: lyDo,
      })
      const params = {
        updateType: typeRequest.CHINH_SUA_DE_XUAT,
      }
      const dataParams = {
        cong_van_di_id: this.props.item.congVanDiId,
        ly_do_thu_hoi: lyDo,
      }
      const data = await this.props.actions.putCapNhatDeXuatThuHoi(
        params,
        dataParams
      )
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: CHINH_SUA_DE_XUAT_THU_HOI_KHONG_THANH_CONG,
          intent: Intent.DANGER,
        })
      }

      const thongBaoThanhCong = this.props.actions.commonAddToasterMessage({
        message: CHINH_SUA_DE_XUAT_THU_HOI_THANH_CONG,
        intent: Intent.SUCCESS,
      })
      if (thongBaoThanhCong) {
        this.props.reloadData && this.props.reloadData()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: CHINH_SUA_DE_XUAT_THU_HOI_KHONG_THANH_CONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.props.actions.getTongSoCongVanThuHoi()
      this.setState({
        isLoadingButton: false,
        isOpenPopup: !this.state.isOpenPopup,
      })
    }
  }

  render() {
    const { item, number } = this.props
    const { textLyDoThuHoi } = this.state
    const { checkHienThiHuyDeXuatVaChinhSuaDeXuat, isShowActionDongYThuHoi } =
      this.state
    if (!item || !number) {
      return
    }

    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/cong-van/cong-van-di-thu-hoi/chi-tiet/${item.congVanDiId}/${item.id}`}
        />
      )
    }

    // Check hiển thị menu action
    let isShowAllMenu = false
    if (checkHienThiHuyDeXuatVaChinhSuaDeXuat || isShowActionDongYThuHoi) {
      isShowAllMenu = true
    }

    return (
      <tr
        className={classnames('table-row-middle', {
          'tr-default': item && item.trangThai !== 0,
        })}
      >
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          {number > 9 ? number : '0' + number}
        </td>
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          {item.ngayCongVan
            ? moment(item.ngayCongVan).format('DD/MM/YYYY')
            : ''}
        </td>
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          {item.soCongVan ? item.soCongVan : ''}
        </td>
        <td
          onClick={e => this.handleClick(e, item.trangThai)}
          className="textarea-content"
        >
          {item.trichYeu ? item.trichYeu : ''}
        </td>
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          <div className="thong-tin-thu-hoi">
            {item.nguoiDeXuat && (
              <div className="user-box">
                <span className="name">{item.nguoiDeXuat.tenNhanVien}</span>
                <span className="line">-</span>
              </div>
            )}
            {item.ngayTao && moment(item.ngayTao).isValid() && (
              <span className="date">
                {moment(item.ngayTao).format('DD/MM/YYYY')}
              </span>
            )}
            {item.lyDoThuHoi && <p className="content">{item.lyDoThuHoi}</p>}
          </div>
        </td>
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          {item.phongBanLap && item.phongBanLap.maPhongBan
            ? item.phongBanLap.maPhongBan
            : ''}
        </td>
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          {item.nguoiKy && item.nguoiKy.tenNhanVien
            ? item.nguoiKy.tenNhanVien
            : ''}
        </td>
        <td onClick={e => this.handleClick(e, item.trangThai)}>
          {item.trangThai === 0 ? (
            <span className="status-job no-process">Chờ xử lý</span>
          ) : (
            <span className="status-job approved">Đã thu hồi</span>
          )}
        </td>
        <td>
          {item.trangThai === 0 && isShowAllMenu && (
            <div>
              <Authorization.Element
                orPermission={[
                  Permission.CONGVANDI_QUANLY,
                  Permission.DEXUAT_THUHOI,
                  Permission.DONGY_THUHOI,
                ]}
              >
                <Popover
                  popoverClassName="pt-popover-content-sizing pt-minimal"
                  position={Position.BOTTOM_RIGHT}
                  interactionKind={PopoverInteractionKind.HOVER}
                  openOnTargetFocus={true}
                  content={
                    <div className="menu-popover">
                      {isValidCondition(
                        this.props.auth.permission,
                        [Permission.DEXUAT_THUHOI],
                        'AND'
                      ) && (
                        <span
                          className="item popover-item"
                          onClick={this.handleChinhSuaLyDoDeXuatThuHoi}
                        >
                          <span className="icon icon-s-edit"></span>
                          <span className="name">Chỉnh sửa</span>
                        </span>
                      )}
                      {isShowActionDongYThuHoi && (
                        <span
                          className="item popover-item"
                          onClick={this.handleDongYThuHoi}
                        >
                          <span className="icon icon-Bao_Cao font-11"></span>
                          <span className="name">Đồng ý thu hồi</span>
                        </span>
                      )}
                      <span
                        className="item popover-item"
                        onClick={this.handleHuyDeXuatThuHoi}
                      >
                        <span className="icon icon-trash"></span>
                        <span className="name">Hủy đề xuất</span>
                      </span>
                    </div>
                  }
                >
                  <span className="icon-More"></span>
                </Popover>
              </Authorization.Element>
              {this.state.isOpenPopupHuyYeuCau && (
                <PopupConfirm
                  isOpen={this.state.isOpenPopupHuyYeuCau}
                  onClose={this.handleHuyDeXuatThuHoi}
                  title={POPUP.HUY_DE_XUAT_THU_HOI}
                  text={POPUP.TEXT_BUTTON_SUBMIT}
                  onSubmit={this.handleSubmitHuyDeXuatThuHoiCongVan}
                  isLoading={this.state.isLoadingButton}
                >
                  {POPUP.TEXT_SUBMIT_BODY}
                </PopupConfirm>
              )}
              {/* Đồng ý thu hồi */}
              {this.state.isOpenPopupDongYThuHoi && (
                <PopupConfirm
                  isOpen={this.state.isOpenPopupDongYThuHoi}
                  onClose={this.handleDongYThuHoi}
                  title={POPUP.THU_HOI_CONG_VAN}
                  text={POPUP.TEXT_BUTTON_SUBMIT}
                  onSubmit={this.handleSubmiDongYThuHoi}
                  isLoading={this.state.isLoadingButton}
                >
                  {POPUP.COMFIRM_DONG_Y_THU_HOI_CONG_VAN}
                </PopupConfirm>
              )}
              <PopupConfirm
                isOpen={this.state.isOpenPopup}
                textarea={true}
                onClose={this.handleChinhSuaLyDoDeXuatThuHoi}
                title={POPUP.DE_XUAT_THU_HOI}
                text={POPUP.TEXT_BUTTON_SUBMIT}
                onSubmit={this.handleSubmitChinhSuaLyDoDeXuatThuHoi}
                showTextWarning={false}
                acceptSpace={true}
                isLoading={this.state.isLoadingButton}
                textLyDo={textLyDoThuHoi}
              >
                {POPUP.TEXT_SUBMIT_BODY}
              </PopupConfirm>
            </div>
          )}
        </td>
      </tr>
    )
  }
}

ListItem.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
}

export default ListItem
