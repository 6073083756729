import { Input } from 'components/newCommon'
import React from 'react'
import ItemCheckbox from './components/ItemCheckbox'
import ItemCheckboxToggle from './components/ItemCheckboxToggle'
import { SelectTree } from 'components/common'
import * as Types from 'constants/Api'
import { Tooltip } from '@blueprintjs/core'
import { isEmpty } from 'lodash'

const ChonDsNguoiThamGia = ({
  dsNguoiThamGiaSelected,
  handleRemoveNguoiThamGia,
  treeChonNguoiThamGia,
  handleChangeTextsearch,
  textSearch,
  handleToggle,
  idsToggle,
  handleCheckboxChange,
  dsDonViPhongBanSelected,
  units,
  setDonViSearch,
  donViSearch,
  handleRemoveAllNguoiThamGia,
}) => {
  // Render tree chọn người tham gia
  const renderTree = data => {
    if (!data) return null
    return data?.map((item, index) => (
      <ItemCheckboxToggle
        key={index}
        title={item?.tenDonVi || item?.tenPhongBan}
        handleToggle={handleToggle}
        data={item}
        idsToggle={idsToggle}
        handleCheckboxChange={handleCheckboxChange}
        checked={
          item?.phongBanId
            ? dsDonViPhongBanSelected?.includes(item?.phongBanId)
            : dsDonViPhongBanSelected?.includes(item?.donViId)
        }
      >
        {item?.dsPhongBan && renderTree(item?.dsPhongBan)}
        {item?.dsPhongBanCon && renderTree(item?.dsPhongBanCon)}
        {item?.dsNhanVien &&
          item?.dsNhanVien.map((nv, index) => (
            <ItemCheckbox
              key={index}
              title={nv?.tenNhanVien}
              data={nv}
              handleCheckboxChange={handleCheckboxChange}
              checked={dsNguoiThamGiaSelected
                ?.map(item => item?.nhanVienId)
                ?.includes(nv?.nhanVienId)}
            />
          ))}
        {item?.dsDonViCon && renderTree(item?.dsDonViCon)}
      </ItemCheckboxToggle>
    ))
  }

  // Tìm kiếm người tham gia
  const searchEmployees = (data, textSearch, donViIdSearch) => {
    if (!textSearch && !donViIdSearch) return data

    const textSearchLower = textSearch
      ? textSearch?.trim()?.toLowerCase()
      : null

    const filterRecursive = (items, donViId) => {
      return items
        .map(item => {
          let newItem = { ...item }

          // Nếu có đơn vị ID được cung cấp, kiểm tra đơn vị ID
          if (donViId && item.donViId !== donViId) {
            return null // Không phù hợp đơn vị ID, trả về null
          }

          if (item.dsNhanVien && textSearchLower) {
            newItem.dsNhanVien = item.dsNhanVien.filter(nv =>
              nv.tenNhanVien.toLowerCase().includes(textSearchLower)
            )
          }
          if (item.dsPhongBan) {
            newItem.dsPhongBan = filterRecursive(
              item.dsPhongBan,
              donViId
            ).filter(
              pb => pb && (pb.dsNhanVien.length > 0 || pb.dsPhongBan.length > 0)
            )
          }
          if (item.dsPhongBanCon) {
            newItem.dsPhongBanCon = filterRecursive(
              item.dsPhongBanCon,
              donViId
            ).filter(
              pb =>
                pb && (pb.dsNhanVien.length > 0 || pb.dsPhongBanCon.length > 0)
            )
          }
          if (item.dsDonViCon) {
            newItem.dsDonViCon = filterRecursive(
              item.dsDonViCon,
              donViId
            ).filter(
              dv =>
                dv &&
                (dv.dsNhanVien.length > 0 ||
                  dv.dsPhongBan.length > 0 ||
                  dv.dsDonViCon.length > 0)
            )
          }
          return newItem
        })
        .filter(
          item =>
            item &&
            ((item.dsNhanVien && item.dsNhanVien.length > 0) ||
              (item.dsPhongBan && item.dsPhongBan.length > 0) ||
              (item.dsPhongBanCon && item.dsPhongBanCon.length > 0) ||
              (item.dsDonViCon && item.dsDonViCon.length > 0))
        )
    }

    return filterRecursive(data, donViIdSearch)
  }

  return (
    <div className="select-list">
      <div className="select-list-from">
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 form-item">
            <SelectTree
              data={units}
              type="Unit"
              permission={false}
              value={donViSearch}
              placeholder="Chọn đơn vị"
              handleChange={value => {
                if (!!value) {
                  setDonViSearch(value)
                }
              }}
              className="tree-custom-meeting"
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 form-item">
            <Input
              className="cpc-input-search"
              leftIconName="search"
              onChange={value => handleChangeTextsearch(value)}
              placeholder="Tìm kiếm người tham gia"
            />
          </div>
        </div>
        {isEmpty(treeChonNguoiThamGia) ||
        isEmpty(
          searchEmployees(treeChonNguoiThamGia, textSearch, donViSearch)
        ) ? (
          <div className="error-page-container" style={{ paddingTop: '140px' }}>
            <div className="error-content">
              <p className="description-note">Không có dữ liệu</p>
            </div>
          </div>
        ) : (
          <div className="parent-data-list">
            {textSearch || donViSearch
              ? renderTree(
                  searchEmployees(treeChonNguoiThamGia, textSearch, donViSearch)
                )
              : renderTree(treeChonNguoiThamGia)}
          </div>
        )}
      </div>
      <div className="select-list-to">
        <p className="select-list-to__title">
          <span>Đã chọn: {dsNguoiThamGiaSelected?.length || 0}</span>
          {dsNguoiThamGiaSelected?.length > 0 && (
            <Tooltip
              content={<span className="font-size-12">Xóa tất cả</span>}
              className="remove-all-tooltip"
            >
              <span
                className="pt-icon icon-close-light"
                onClick={() => handleRemoveAllNguoiThamGia()}
              ></span>
            </Tooltip>
          )}
        </p>
        <div className="list-selected-wrapper">
          <div className="list-selected">
            {dsNguoiThamGiaSelected?.map((item, index) => (
              <div key={index} className="item-selected">
                <div className="item-selected__left">
                  {item?.anhDaiDien ? (
                    <div
                      className="selection-item-avatar"
                      style={{
                        backgroundImage: `url(${
                          item?.anhDaiDien
                            ? `${Types.IMAGE_URL}${item?.anhDaiDien}`
                            : '/images/default_avatar.png'
                        })`,
                      }}
                    ></div>
                  ) : (
                    <div className="avatar-text">
                      {item?.tenNhanVien?.substring(0, 1)}
                    </div>
                  )}
                  <span className="item-selected-title">
                    {item?.tenNhanVien}
                  </span>
                </div>
                <div
                  className="action-icon"
                  onClick={() => handleRemoveNguoiThamGia(item?.nhanVienId)}
                >
                  <span className="pt-icon icon-delete-v2"></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChonDsNguoiThamGia
