import React from 'react'
import {
  Legend,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts'

class CircleChart extends React.Component {
  render() {
    const style = {
      top: 50,
      left: '85%',
      lineHeight: '30px',
    }
    return (
      <div>
        <ResponsiveContainer width="70%" height={200}>
          <PieChart>
            <Tooltip />
            <Pie
              data={this.props.data}
              innerRadius={55}
              outerRadius={70}
              labelLine={false}
              paddingAngle={1}
            >
              {this.props.data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
            <Legend
              iconType="circle"
              iconSize={10}
              width={240}
              height={240}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default CircleChart
