import { useState, useEffect, useMemo, useCallback } from 'react'

import variables from 'constants/variables'
import {
  TOATS_MESSAGE_FAIL,
  TOATS_MESSAGE_SUCCESS,
  TOATS_ERROR_SYSTEM,
} from 'constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import { settupType } from 'helpers/file'

const useCreate = ({ id, actions, history }) => {
  const size = 1024 * 1024 * 5
  const formKeys = useMemo(() => {
    return {
      tieuDe: 'tieuDe',
      noiDung: 'noiDung',
      isActive: 'isActive',
    }
  }, [])

  const [isFetching, setIsFetching] = useState(false)
  const [filesData, setFilesData] = useState([])
  const [textFileError, setTextFileError] = useState('')
  const [formData, setFormData] = useState({
    isActive: true,
  })
  const [errorData, setErrorData] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleValidate = useCallback(() => {
    let error = {}
    let countError = 0
    if (!formData?.[formKeys.tieuDe]) {
      countError += 1
      error.tieuDe = 'Vui lòng nhập tiêu đề!'
    }

    if (!formData?.[formKeys.noiDung]) {
      countError += 1
      error.noiDung = 'Vui lòng nhập nội dung!'
    }

    setErrorData(error)
    if (countError === 0) {
      return true
    }

    return false
  }, [formData, formKeys])

  const onSubmit = useCallback(async () => {
    try {
      if (handleValidate()) {
        setIsSubmit(true)
        const fileUpload = filesData?.filter(f => !f?.id)
        Promise.all([
          filesData?.filter(f => f?.id),
          fileUpload?.length > 0 ?? false
            ? actions.fileUpload(fileUpload, null).then(res => {
                return res?.payload?.data?.result
              })
            : [],
        ]).then(res => {
          if (res) {
            const dataRequest = {
              id: id ?? undefined,
              tieu_de: formData?.[formKeys.tieuDe]
                ? formData?.[formKeys.tieuDe]
                : undefined,
              noi_dung: formData?.[formKeys.noiDung]
                ? formData?.[formKeys.noiDung]
                : undefined,
              is_active: formData?.[formKeys.isActive]
                ? formData?.[formKeys.isActive]
                : false,
              ds_file_dinh_kem: [...res[0], ...res[1]],
              don_vi_id: null,
            }
            if (id) {
              actions.notificationUpdate(dataRequest).then(res => {
                setIsSubmit(false)
                if (
                  !res ||
                  (res && (res?.error || res?.payload?.data?.result === false))
                ) {
                  return actions.commonAddToasterMessage({
                    message: TOATS_MESSAGE_FAIL,
                    intent: Intent.DANGER,
                  })
                }

                actions.commonAddToasterMessage({
                  message: TOATS_MESSAGE_SUCCESS,
                  intent: Intent.SUCCESS,
                })
                return history.push('/he-thong/thong-bao')
              })
              return
            } else {
              actions.notificationCreate(dataRequest).then(res => {
                setIsSubmit(false)
                if (
                  !res ||
                  (res && (res?.error || res?.payload?.data?.result === false))
                ) {
                  return actions.commonAddToasterMessage({
                    message: TOATS_MESSAGE_FAIL,
                    intent: Intent.DANGER,
                  })
                }

                actions.commonAddToasterMessage({
                  message: TOATS_MESSAGE_SUCCESS,
                  intent: Intent.SUCCESS,
                })
                return history.push('/he-thong/thong-bao')
              })
              return
            }
          }
        })
      }
    } catch (error) {
      return actions.commonAddToasterMessage({
        message: TOATS_ERROR_SYSTEM,
        intent: Intent.DANGER,
      })
    }
  }, [actions, id, formKeys, filesData, formData, history, handleValidate])

  const onChangeForm = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeFiles = e => {
    setTextFileError('')
    const files = e.target?.files
    let dataFiles = []
    Array.from(files).forEach(item => {
      const originalSize = item.size
      const type = item?.type
      if (settupType()?.some(s => s === type)) {
        setTextFileError(variables.VALIDATE.FILE_TYPE_NOT_ALLOW(settupType()))
        return
      } else if (originalSize > size) {
        setTextFileError(variables.VALIDATE.FILE_SIZE_ALLOW(5))
        return
      } else {
        dataFiles.push(item)
      }
    })
    setFilesData(prev => [...prev, ...dataFiles])
  }

  const onRemoveFile = index => {
    setFilesData(prev => prev?.filter((_, i) => i !== index))
  }

  const getDetail = useCallback(() => {
    if (!id) {
      return
    }

    setIsFetching(true)
    actions.fetchNotificationItem(id).then(res => {
      setIsFetching(false)
      const data = res?.payload?.data?.result
      setFormData({
        [formKeys.tieuDe]: data?.tieuDe,
        [formKeys.noiDung]: data?.noiDung,
        [formKeys.isActive]: data?.isActive,
      })
      setFilesData(data?.dsFileDinhKem)
    })
  }, [id, formKeys, actions])

  useEffect(() => {
    getDetail()
  }, [getDetail])

  return {
    formKeys,
    formData,
    filesData,
    textFileError,
    isFetching,
    isSubmit,
    errorData,
    setFormData,
    onChangeForm,
    onChangeFiles,
    onRemoveFile,
    onSubmit,
  }
}

export default useCreate
