import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage, SelectTree } from '../../components/common'
import { ListRoleCategory } from '../../components/System'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from '../../actions'
import { Utils } from '../../helpers'

const LINK = '/he-thong/vai-tro/them-moi'

class RoleCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  state = {
    donViId: this.props.auth.mainUnitId,
    isRedirect: false,
    isLoading: true,
    role: [],
    unitSelect: [],
    isError: false,
  }

  componentDidMount() {
    this.props.actions.getCategoryUnitMultiLevel().then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        return this.setState({ isError: true, isLoading: false })
      }
    })

    let donViId = Utils.getData('Role', {})

    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    this.setState({ donViId }, () => {
      this.props.actions.getCommonFetchRole(donViId).then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.role.items !== this.props.role.items) {
      this.setState(
        {
          role: nextProps.role.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }

    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({ unitSelect: nextProps.unitSelect.items })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.donViId !== this.state.donViId &&
      prevState.isRedirect !== this.state.isRedirect
    ) {
      this.props.actions.getCommonFetchRole(this.state.donViId).then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
        Utils.saveData('Role', this.state.donViId)
        if (this.state.isRedirect) {
          this.setState({ isRedirect: false })
        }
      })
    }
  }

  handleChange(e) {
    if (e !== undefined) {
      this.setState({
        donViId: e,
        isRedirect: true,
        isLoading: true,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Danh mục vai trò"
          iconPage="icon-Danh_Muc_Vai_Tro"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div className="listrole-container">
          <div className="search-container search-group-container">
            <div className="row seach-form">
              <div className="col-xs-12 col-sm-12 col-sm-12 end-xs">
                <form
                  className="form-search-basic"
                  onSubmit={this.handleSubmit}
                >
                  <div className="search-header-group form-container search-system-module">
                    <div className="search-system-tree">
                      <label className="pt-label pt-label-inline">
                        <span className="text-label">Đơn vị</span>
                      </label>
                      <SelectTree
                        data={this.state.unitSelect}
                        type="Unit"
                        permission={false}
                        value={
                          this.state.unitSelect.length > 0
                            ? this.state.donViId
                            : ''
                        }
                        handleChange={this.handleChange}
                        isLoading={
                          this.state.unitSelect.length > 0 ? false : true
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ListRoleCategory
            items={this.state.role}
            actions={this.props.actions}
            auth={this.props.auth}
            donViId={this.state.donViId}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          ></ListRoleCategory>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  role: {
    ...state.role,
    items: state.common.role,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleCategoryPage)
