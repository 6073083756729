import React, { memo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { FILE_URL } from 'constants/Api'
import { Utils } from 'helpers'
import { MasterLayout } from 'components/layout'
import Title from 'components/ui/title'
import UIInput from 'components/ui/input'
import UICheckbox from 'components/ui/checkbox'
import UIInputUpload from 'components/ui/input-upload'
import UIButton from 'components/ui/button'
import UIEditor from 'components/ui/editor'
import UIFile from 'components/ui/file'
import { fileUpload, commonAddToasterMessage } from 'actions/index'
import {
  notificationCreate,
  notificationUpdate,
  fetchNotificationItem,
} from 'actions/notifications'

import useCreate from './hooks/use-create'

const CreateGeneralNotifications = ({ auth, history, match, actions }) => {
  const token = auth?.token?.accessToken
  const id = match.params?.id
  const {
    formKeys,
    formData,
    filesData,
    textFileError,
    isFetching,
    isSubmit,
    errorData,
    onChangeForm,
    onChangeFiles,
    onRemoveFile,
    onSubmit,
  } = useCreate({
    id,
    history,
    actions,
  })

  return (
    <MasterLayout typeSidebar="sidebarSystem">
      <div className="mt5">
        <Title
          name={id ? 'Chỉnh sửa thông báo' : 'Tạo mới thông báo'}
          icon="icon2-bell"
        />
        <div className="border mt10">
          <div className="bg-white ph15 pv20">
            <UIInput
              label="Tiêu đề"
              placeholder="Nhập tiêu đề..."
              maxLength={255}
              hasRequire
              error={errorData?.[formKeys.tieuDe]}
              value={formData?.tieuDe || ''}
              onChange={e => onChangeForm(formKeys.tieuDe, e?.target?.value)}
            />
            {!isFetching && (
              <div className="mt15">
                <UIEditor
                  label="Nội dung"
                  hasRequire
                  error={errorData?.[formKeys.noiDung]}
                  value={formData.noiDung || ''}
                  readOnly={isFetching}
                  onChange={value => onChangeForm(formKeys.noiDung, value)}
                />
              </div>
            )}
            <div className="mt15">
              <UIInputUpload
                label="File đính kèm"
                error={textFileError}
                onChange={onChangeFiles}
              />
              <div className="list-file">
                {filesData?.map((item, index) => {
                  const size = Utils.getFileSize(item?.size)
                    ? ` - ${Utils.getFileSize(item?.size)}`
                    : ''
                  if (!item?.id) {
                    return (
                      <UIFile
                        key={index}
                        className="mt10"
                        name={`${item?.name}${size}`}
                        extension={item?.type}
                        hasClose
                        onRemove={() => onRemoveFile(index)}
                      />
                    )
                  }
                  return (
                    <UIFile
                      key={index}
                      className="mt10"
                      name={`${item?.name}${size}`}
                      extension={item?.extension}
                      hasClose
                      onClick={() =>
                        window.open(
                          `${FILE_URL}/api/file/get/${item?.id}?BearerToken=${token}`
                        )
                      }
                      onRemove={() => onRemoveFile(index)}
                    />
                  )
                })}
              </div>
            </div>
            <div className="mt15">
              <UICheckbox
                label="Hiển thị"
                checked={formData?.isActive}
                onChange={e =>
                  onChangeForm(formKeys.isActive, e.target.checked)
                }
              />
            </div>
          </div>
          <div
            className={
              'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15 pv20'
            }
          >
            <UIButton
              icon="icon-back"
              text="QUAY LẠI"
              theme="grey-border"
              isReverse
              onClick={() => history.goBack()}
            />
            <UIButton
              icon="icon-save"
              text="LƯU"
              color="blue"
              isLoading={isSubmit}
              isDisabled={isFetching || isSubmit}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fileUpload,
      notificationCreate,
      notificationUpdate,
      fetchNotificationItem,
      commonAddToasterMessage,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(CreateGeneralNotifications))
)
