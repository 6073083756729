import React from 'react'
import classNames from 'classnames'
import { Intent, Position, Tooltip } from '@blueprintjs/core'
import size from 'lodash/size'
import TextTruncate from 'react-text-truncate'

const TruncateTextTooltip = ({
  className = null,
  line = 3,
  element = 'div',
  content = '',
  isDisabled = false,
}) => {
  let isHasFullText = false
  if (content?.length <= 1) {
    return content
  }

  const contentArr = content.split(' ')
  if (contentArr && size(contentArr) !== 0 && contentArr[0].length > 30) {
    isHasFullText = true
  }

  return (
    <div className="cursor-pointer">
      <Tooltip
        isDisabled={isDisabled || false}
        tooltipClassName={classNames('truncate-text-tooltip', {
          'truncate-text-tooltip__break': isHasFullText,
        })}
        content={content}
        position={Position.TOP_LEFT}
        intent={Intent.NONE}
      >
        <TextTruncate
          line={line}
          element={element}
          truncateText="…"
          text={content}
          containerClassName={classNames(className, {
            'hidden-full-text': isHasFullText,
          })}
        />
      </Tooltip>
    </div>
  )
}

export default TruncateTextTooltip
