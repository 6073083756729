import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Checkbox } from '@blueprintjs/core'

const UICheckbox = ({
  name,
  label,
  theme,
  isDisabled,
  hasRequire,
  checked,
  onChange,
}) => {
  return (
    <div
      className={classNames('ui-checkbox', {
        [`theme-${theme}`]: theme,
      })}
    >
      <Checkbox
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
      />
      {label ? (
        <label
          className={classNames('ui-checkbox-label', {
            required: hasRequire,
          })}
        >
          <span>{label}</span>
        </label>
      ) : (
        ''
      )}
    </div>
  )
}

UICheckbox.defaultProps = {
  name: '',
  label: '',
  theme: 'default',
  hasRequire: false,
  checked: false,
  onChange: () => {},
}

UICheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.string,
  hasRequire: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default UICheckbox
