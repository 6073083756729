import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { LoadingItem } from '../common/'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
// import { QLCV_FILE_URL } from 'constants/Api';

class DetailFileDinhKem extends Component {
  getHref = ({ migrated, url, fileId }, token) => {
    // const { isQLCV = false } = this.props;
    // if (isQLCV) {
    //   return `${QLCV_FILE_URL}${url}`;
    // }
    return `${Types.FILE_URL}/api/file/get/${fileId}?BearerToken=${token}`
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    if (
      this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined
    ) {
      return <LoadingItem />
    }

    const { label = 'File đính kèm' } = this.props

    return this.props.className === '' ||
      this.props.className !== 'data-column-file' ? (
      // Danh sach file dang su dung o cac component khac
      <div className="detail-info-container section-box-info">
        <div className="list-items">
          <div className="row item-value">
            <div className="col-xs-3 left-content">
              <span className="label-text">
                {label} ({this.props.receiveDocument.fileDinhKem.length}):
              </span>
            </div>
            <div className="col-xs-9 right-content">
              <Scrollbars
                autoHeight
                autoHeightMin={0}
                autoHeightMax={this.props.maxHeightScroll}
              >
                <div className="detail">
                  {this.props.receiveDocument.fileDinhKem.map((value, item) => {
                    let extensionTK = null
                    const fileDinhKem = value.fileName
                    if (fileDinhKem) {
                      extensionTK = fileDinhKem
                        .slice(
                          ((value.fileName.lastIndexOf('.') - 1) >>> 0) + 2
                        )
                        .toLowerCase()
                    }

                    return (
                      <div className="info-file" key={item}>
                        <span className="info">
                          <a
                            style={{
                              backgroundImage: `url(${Tool.Utils.typeFile(
                                extensionTK
                              )})`,
                            }}
                            className="link-file pt5 pb5"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={this.getHref(value, token)}
                          >
                            {value.tenFile} -{' '}
                            {Tool.Utils.getFileSize(value.kichThuoc)}
                          </a>
                        </span>
                      </div>
                    )
                  })}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    ) : (
      // Danh sach file su dung tai component moi
      <div className="data-column-items">
        <label>
          {this.props.title} ({this.props.receiveDocument.fileDinhKem.length})
        </label>
        <div className="content">
          <div className="detail-file">
            <Scrollbars
              autoHeight
              autoHeightMin={0}
              autoHeightMax={this.props.maxHeightScroll}
            >
              <div className="detail">
                {this.props.receiveDocument.fileDinhKem.map((value, item) => {
                  let extensionTK = null
                  const fileDinhKem = value.fileName
                  if (fileDinhKem) {
                    extensionTK = fileDinhKem
                      .slice(((value.fileName.lastIndexOf('.') - 1) >>> 0) + 2)
                      .toLowerCase()
                  }
                  return (
                    <div key={item} className="file-items">
                      <a
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extensionTK
                          )})`,
                        }}
                        className="link-file pt5 pb5"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.getHref(value, token)}
                      >
                        {value.tenFile} -{' '}
                        {Tool.Utils.getFileSize(value.kichThuoc)}
                      </a>
                    </div>
                  )
                })}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    )
  }
}

DetailFileDinhKem.defaultProps = {
  className: '',
  title: 'File đính kèm',
  maxHeightScroll: 200,
}

export default DetailFileDinhKem
