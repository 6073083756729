import ExecutingView from './ExecutingView'
import User from './User'

const FlowdocumentReceivedocumentSchema = {
  result: {
    chiDao: ExecutingView,
    nguoiTaoCongVan: User,
  },
}

export default FlowdocumentReceivedocumentSchema
