import React from 'react'
import {
  HeadingTableSort,
  EmptyDataTable,
  ErrorLoadingTable,
  LoadingTable,
} from '../../../components/common'
import { Link, withRouter } from 'react-router-dom'
import { headers } from '../../../constants/Table'
import { Collapse } from '@blueprintjs/core'
import { get } from 'lodash'

const GROUP_DETAILS_PATH = id =>
  `/he-thong/don-vi/quy-trinh/${id}/nhom-thanh-vien`
const GROUP_DETAILS_DTXD_PATH = id =>
  `/he-thong/don-vi/quy-trinh-dtxd/${id}/nhom-thanh-vien`
const DETAILS_PATH = '/he-thong/don-vi/quy-trinh'

class UsingGroupList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data || [],
      isDataEmpty: props.data && !props.data.length,
    }
  }

  goToDetails = procedureId => {
    this.props.history.push(`${DETAILS_PATH}/${procedureId}`)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
        isDataEmpty: !nextProps.data.length,
      })
    }
  }

  render() {
    const { isLoading, isError } = this.props
    const { data, isDataEmpty } = this.state
    return (
      <div className="table-scroll">
        <div className="new-table-style">
          <header>
            <HeadingTableSort data={headers.procedureUsingGroup} />
          </header>
          <main>
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              data.map((item, index) => (
                <ListItem
                  key={index}
                  i={index + 1}
                  data={item}
                  goToDetails={this.goToDetails}
                />
              ))
            )}
          </main>
        </div>
      </div>
    )
  }
}

class ListItem extends React.Component {
  constructor() {
    super()
    this.state = {
      isShowCollapse: false,
    }
  }
  toggleCollapse = () =>
    this.setState({ isShowCollapse: !this.state.isShowCollapse })
  render() {
    const { i, data } = this.props
    const { isShowCollapse } = this.state
    const hasList = !!get(data, 'dsQuyTrinh', []).length
    return (
      hasList && (
        <section className="row">
          <main className="row parent">
            <span className="cell cell-width-5 center-text">
              {isShowCollapse ? (
                <i
                  className="icon-round-delete-button has-action"
                  onClick={this.toggleCollapse}
                />
              ) : (
                <i
                  className="icon-rounded-add-button has-action"
                  onClick={this.toggleCollapse}
                />
              )}
            </span>
            <span className="cell cell-width-5">{i < 10 ? '0' + i : i}</span>
            <span className="cell cell-width-90">{data.tenDanhMuc || ''}</span>
          </main>
          <Collapse isOpen={isShowCollapse}>
            {data.dsQuyTrinh.map((childItem, index) => (
              <main
                className="row child has-action margin-collapse-10"
                key={index}
              >
                <div
                  className="cell cell-width-65"
                  onClick={() => this.props.goToDetails(childItem.quyTrinhId)}
                >
                  {childItem.tenQuyTrinh || ''}
                </div>
                <div
                  className="cell cell-width-20 center-text"
                  onClick={() => this.props.goToDetails(childItem.quyTrinhId)}
                >
                  {childItem.nhomThanhVien || 0}
                </div>
                <div className="cell cell-width-5 center-text">
                  {childItem.xacDinhThanhPhanKyDuyet === 9 && (
                    <Link
                      to={GROUP_DETAILS_DTXD_PATH(childItem.id)}
                      style={{ textDecoration: 'none' }}
                    >
                      <i className="icon-add" />
                    </Link>
                  )}
                </div>
                <div className="cell cell-width-5 center-text">
                  {(childItem.xacDinhThanhPhanKyDuyet === 0 ||
                    childItem.xacDinhThanhPhanKyDuyet === 9) && (
                    <Link
                      to={GROUP_DETAILS_PATH(childItem.id)}
                      style={{ textDecoration: 'none' }}
                    >
                      <i className="icon-add-user" />
                    </Link>
                  )}
                </div>
              </main>
            ))}
          </Collapse>
        </section>
      )
    )
  }
}

export default withRouter(UsingGroupList)
