import {
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
  API_REQUEST_ERROR,
  SET_DO_TOKEN,
  SET_DO_PHONGBAN_NOIBO,
  RESET_DO,
  GET_LIST_QUAN_HE_DONVI_VANBAN_DO,
} from '../constants/ActionTypes'
import { API_URL, QLCV_API_URL } from '../constants/Api'
import { store } from '../index'

const taskRequest = ({
  type: reduxSuccessType = API_REQUEST_SUCCESS,
  tokenRequest,
  isNotToken = false,
  baseURL,
  client,
  endpoint,
  method = 'GET',
  params = {},
  data,
  ...otherOptions
}) => {
  const { dispatch, getState } = store
  const token = getState()?.auth?.token || null
  const headers = {
    ...(tokenRequest
      ? {
          Authorization: `${tokenRequest.tokenType} ${tokenRequest.accessToken}`,
        }
      : !isNotToken && token
      ? {
          Authorization: `${token.tokenType} ${token.accessToken}`,
        }
      : {}),
  }

  return dispatch({
    types: [API_REQUEST_SEND, reduxSuccessType, API_REQUEST_ERROR],
    payload: {
      client,
      request: {
        ...otherOptions,
        baseURL,
        url: endpoint,
        method,
        headers,
        params,
        data,
      },
    },
  })
}

export const getDOToken = username => {
  return taskRequest({
    type: SET_DO_TOKEN,
    baseURL: API_URL,
    endpoint: '/api/doffice/auth/token',
    isNotToken: true,
    params: {
      username,
    },
  })
}

export const getDOPhongBanNoiBo = params => {
  return taskRequest({
    type: SET_DO_PHONGBAN_NOIBO,
    baseURL: API_URL,
    endpoint: '/api/doffice/danhmuc/phongbannoibo',
    params,
  })
}

export const getDOCongVan = id => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: '/api/doffice/vanban/vbde/detail',
    params: {
      idVB: id,
    },
  })
}

export const getDOCongViec = id => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: `/api/works-doffice/${id}`,
  })
}

export const getDODonViIdByUsername = username => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    isNotToken: true,
    endpoint: '/api/eoffice/donviid-by-username',
    params: {
      username,
    },
  })
}

export const getPhongBanThucHienNotToken = donViId => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: '/api/works-doffice/department',
    params: {
      donViId,
    },
  })
}

export const getDonViTrucThuocNotToken = donViId => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    endpoint: '/api/works-doffice/organization',
    params: {
      donViId,
    },
  })
}

export const putDOGiaoViec = (workItemId, username, data) => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    method: 'PUT',
    endpoint: `/api/works-doffice-assign/${workItemId}`,
    params: {
      username,
    },
    data,
  })
}

export const getDOWorkKey = params => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    isNotToken: true,
    endpoint: '/api/works-doffice-key',
    params,
  })
}

export const postDOWork = data => {
  return taskRequest({
    baseURL: QLCV_API_URL,
    method: 'POST',
    isNotToken: true,
    endpoint: '/api/works-doffice',
    data,
  })
}

export const getPhongBanDetail = ({ id }) => {
  return taskRequest({
    baseURL: API_URL,
    endpoint: `/v1/congviec/PhongBanDetail/${id}`,
  })
}

export const getQuanHeDonViVanBan = ({ id }) => {
  return taskRequest({
    baseURL: API_URL,
    type: GET_LIST_QUAN_HE_DONVI_VANBAN_DO,
    endpoint: `/api/doffice/vanban/quanhevanbandonvi?idVanban=${id}`,
  })
}

export const resetDO = () => {
  const { dispatch } = store
  return dispatch({
    type: RESET_DO,
  })
}
