import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilterNew extends Component {
  static propTypes = {
    status: PropTypes.array,
    objects: PropTypes.array,
    relativeUnits: PropTypes.array,
    departments: PropTypes.array,
    documentType: PropTypes.array,
    documentCabinetStatus: PropTypes.array,
    leaderDepartments: PropTypes.array,
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }

  state = {
    isOpen: false,
    filter: {
      textSearch: '',
      ngayCongVan: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      status: '',
      loaiCongVan: '',
      doiTuongCongVanId: '',
      phongBanThucHienId: '',
      lanhDaoChiDaoId: '',
    },
  }

  clearFilter() {
    let phongBanThucHienId =
      this.props.isHasPermission === false
        ? this.props.auth.mainDepartmentId
        : ''

    this.setState(
      {
        filter: {
          textSearch: '',
          ngayCongVan: {
            from: moment().subtract(1, 'months'),
            to: moment(),
          },
          status: '',
          loaiCongVan: '',
          doiTuongCongVanId: '',
          phongBanThucHienId: phongBanThucHienId,
          lanhDaoChiDaoId: '',
        },
      },
      () => {
        const filter = {
          ...this.state.filter,
          ngayCongVan: {
            from: this.state.filter.ngayCongVan.from
              ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.ngayCongVan.to
              ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
              : '',
          },
        }

        this.props.clearFilterColumn &&
          this.props.clearFilterColumn(filter, true)
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
    })
    this.props.handleDateChange && this.props.handleDateChange(name, pos, e)
  }

  componentWillReceiveProps(nextProps) {
    const ngayCongVan = nextProps.filter.ngayCongVan
    if (nextProps.filter !== this.props.filter) {
      this.setState({
        filter: {
          textSearch: nextProps.filter.textSearch || '',
          ngayCongVan: {
            from:
              ngayCongVan &&
              ngayCongVan.from &&
              ngayCongVan.from.trim().length > 0
                ? moment(ngayCongVan.from)
                : undefined,
            to:
              ngayCongVan && ngayCongVan.to && ngayCongVan.to.trim().length > 0
                ? moment(ngayCongVan.to)
                : undefined,
          },
          status: nextProps.filter.status || '',
          loaiCongVan: nextProps.filter.loaiCongVan || '',
          doiTuongCongVanId: nextProps.filter.doiTuongCongVanId || '',
          phongBanThucHienId: nextProps.filter.phongBanThucHienId || '',
          lanhDaoChiDaoId: nextProps.filter.lanhDaoChiDaoId || '',
        },
      })
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCongVan: {
        from: this.state.filter.ngayCongVan.from
          ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCongVan.to
          ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
          : '',
      },
    }

    this.props.clearFilterColumn && this.props.clearFilterColumn(filter, false)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch py-12 px-sm-15"
      >
        <div className="row">
          <div className="col-575-12 col-xs-6 col-md-4">
            <div>
              <label className="search-label">Thời gian (Ngày công văn)</label>
              <div className="mt5 form-input-filter date-picker-inline border-radius-3 ">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.from}
                  selectsStart
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayCongVan.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.to}
                  selectsEnd
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayCongVan.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-575-12 col-xs-6 col-md-2 mt10 mt-576-0">
            <div>
              <label className="search-label">Loại công văn</label>
              <div className="mt5 form-input-filter select-arrow border-radius-3">
                <select
                  className="input border-transparent appearance-none"
                  name="loaiCongVan"
                  onChange={this.handleInputChange}
                  value={this.state.filter.loaiCongVan}
                >
                  <option value="">Tất cả</option>
                  {this.props.documentType.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent pr-40 input-search-filter"
                placeholder="Tìm kiếm Số đến, Số CV, Trích yếu (hoặc Số CV:Trích yếu)"
                type="text"
                dir="auto"
                name="textSearch"
                onChange={this.handleInputChange}
                value={this.state.filter.textSearch}
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 mr10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
            <Button
              onClick={this.handleClick.bind(this)}
              className="btn-icon flex-solid ml0 pt-intent-btn-white"
            >
              <span
                className={`pt-icon ${
                  this.state.isOpen
                    ? 'pt-icon-double-chevron-up'
                    : 'pt-icon-double-chevron-down'
                } mr0`}
              ></span>
            </Button>
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className="row mt15 ml0 mr0">
            <div className="col-575-12 col-xs-6 col-sm-3 pr-sm-0 pl-sm-0 mt10 mt-576-0">
              <div>
                <label className="search-label">Trình trạng</label>
                <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
                  <select
                    className="input border-transparent appearance-none"
                    name="status"
                    onChange={this.handleInputChange}
                    value={this.state.filter.status}
                  >
                    <option value="">Tất cả</option>
                    {this.props.status.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-575-12 col-xs-6 col-sm-3 pr-sm-0 pl-sm-0 mt10 mt-576-0">
              <div>
                <label className="search-label">Đối tượng</label>
                <div className="mt5 form-input-filter select-arrow border-left-sm-transparent">
                  <select
                    className="input border-transparent appearance-none"
                    name="doiTuongCongVanId"
                    onChange={this.handleInputChange}
                    value={this.state.filter.doiTuongCongVanId}
                  >
                    <option value="">Tất cả</option>
                    {this.props.objects.map((item, i) => (
                      <option key={i} value={item.doiTuongCongVanId}>
                        {item.tenDoiTuongCongVan}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-575-12 col-xs-6 col-sm-3 pr-sm-0 pl-sm-0 mt10 mt-sm-0">
              <div>
                <label className="search-label">Lãnh đạo chỉ đạo</label>
                <div className="mt5 form-input-filter select-arrow border-left-sm-transparent">
                  <select
                    className="input border-transparent appearance-none"
                    name="lanhDaoChiDaoId"
                    onChange={this.handleInputChange}
                    value={this.state.filter.lanhDaoChiDaoId}
                  >
                    <option value="">Tất cả</option>
                    {this.props.leaderDepartments.map((item, i) => (
                      <option key={i} value={item.phongBanId}>
                        {item.maPhongBan}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-575-12 col-xs-6 col-sm-3 pr-sm-0 pl-sm-0 mt10 mt-sm-0">
              <div>
                <label className="search-label">Phòng ban thực hiện</label>
                <div className="mt5 form-input-filter select-arrow border-left-sm-transparent border-radius-right-3">
                  <select
                    className="input border-transparent appearance-none"
                    name="phongBanThucHienId"
                    onChange={this.handleInputChange}
                    value={this.state.filter.phongBanThucHienId}
                    disabled={!this.props.isHasPermission}
                  >
                    {!this.props.removeDepartmentAll && (
                      <option value="">Tất cả</option>
                    )}
                    {this.props.departments.map((item, i) => (
                      <option key={i} value={item.phongBanId}>
                        {item.maPhongBan}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </form>
    )
  }
}

export default ListFilterNew
