import React, { PureComponent } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import PropTypes from 'prop-types'
import csx from 'classnames'
import { isEqual, size, compact, get } from 'lodash'
import { STATUS_HSCV } from '../../constants/Api'
import { Fragment } from '../common'

class JobProjectTree extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: get(this.props, 'data', []),
      showList: {},
      listChecked: [],
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (size(data)) {
      this.setState({ data: this.checkShow(data) })
    }
  }

  checkShow = (list = [], disabled = []) => {
    const { auth } = this.props
    const roleUserId = get(auth, 'roleId')
    const newList = list.reduce((result, item) => {
      if (
        [
          get(item, 'nguoiChiuTrachNhiem') || {},
          ...(get(item, 'dsNguoiPhoiHop') || []),
        ]
          .map(v => get(v, 'chucDanhId'))
          .includes(roleUserId) === false &&
        item.loai === 1
      ) {
        return result
      }

      const child = this.checkShow(get(item, 'dsHoSoCongViecCon', []), disabled)
      if (!item.loai && !size(child)) {
        return result
      }

      return [
        ...result,
        {
          ...item,
          dsHoSoCongViecCon: child,
        },
      ]
    }, [])

    return newList
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.data, prevProps.data) &&
      size(this.state.listChecked)
    ) {
      this.setState({ listChecked: [] })
    }
    if (
      !isEqual(this.props.data, prevProps.data) ||
      !isEqual(this.props.listDisable, prevProps.listDisable)
    ) {
      this.setState({
        data: this.checkShow(this.props.data, this.props.listDisable),
      })
    }
  }

  checkDisable = item => {
    const { auth, listDisable } = this.props
    const roleUserId = get(auth, 'roleId')
    if (listDisable.includes(item.id)) {
      return true
    } else if (
      ![STATUS_HSCV.CHUA_NOP, STATUS_HSCV.TRA_LAI].includes(
        get(item, 'trangThai', 0)
      )
    ) {
      return true
    } else if (
      [
        get(item, 'nguoiChiuTrachNhiem') || {},
        ...(get(item, 'dsNguoiPhoiHop') || []),
      ]
        .map(v => get(v, 'chucDanhId'))
        .includes(roleUserId) === false &&
      item.loai === 1
    ) {
      return true
    }

    return false
  }

  toggleShow = id => () => {
    this.setState(prev => ({
      showList: {
        ...prev.showList,
        [id]: !prev.showList[id],
      },
    }))
  }

  toggleCheck = async e => {
    const { onCheck } = this.props
    const { value, checked } = e.target
    await this.setState(prev => ({
      listChecked: checked
        ? compact([...prev.listChecked, value])
        : prev.listChecked.filter(v => v !== value),
    }))

    onCheck(this.state.listChecked)
  }

  renderSub(items) {
    const { showList, listChecked } = this.state
    return items.map(item => {
      const child =
        !!size(get(item, 'dsHoSoCongViecCon', [])) &&
        get(item, 'dsHoSoCongViecCon', [])
      return (
        <div key={item.id} className="list-select-sub">
          {child && (
            <div
              className="pt-save-document"
              onClick={this.toggleShow(item.id)}
            >
              <span
                className={csx('pt-icon pull-left', {
                  'pt-icon-chevron-up': showList[item.id],
                  'pt-icon-chevron-down': !showList[item.id],
                })}
              />
            </div>
          )}
          <label className="pt-control pt-checkbox pt-checkbox-save-document">
            {!child && (
              <Fragment>
                <input
                  type="checkbox"
                  onChange={this.toggleCheck}
                  value={item.id}
                  disabled={this.checkDisable(item)}
                  checked={listChecked.includes(item.id)}
                />
                <span className="pt-control-indicator" />
              </Fragment>
            )}
            <span className="name_checkbox">{item.ten}</span>
          </label>
          {child && showList[item.id] && this.renderSub(child)}
        </div>
      )
    })
  }

  render() {
    const { showList, listChecked, data } = this.state
    return (
      <div className="group-save-document">
        <Scrollbars
          autoHideTimeout={100}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={324}
          autoHideDuration={200}
        >
          {data.map(item => {
            const child =
              !!size(get(item, 'dsHoSoCongViecCon', [])) &&
              get(item, 'dsHoSoCongViecCon', [])
            return (
              <div key={item.id} className="selectbox-group">
                <div className="list-select-document">
                  <div
                    className={csx('checkbox-primary name_checkbox', {
                      'not-icon-chevron': !child,
                    })}
                  >
                    {child && (
                      <div
                        className="pt-save-document"
                        onClick={this.toggleShow(item.id)}
                      >
                        <span
                          className={csx('pt-icon pull-left', {
                            'pt-icon-chevron-up': showList[item.id],
                            'pt-icon-chevron-down': !showList[item.id],
                          })}
                        />
                      </div>
                    )}
                    <label className="pt-control pt-checkbox pt-checkbox-save-document">
                      {!child && (
                        <Fragment>
                          <input
                            type="checkbox"
                            onChange={this.toggleCheck}
                            value={item.id}
                            disabled={this.checkDisable(item)}
                            checked={listChecked.includes(item.id)}
                          />
                          <span className="pt-control-indicator" />
                        </Fragment>
                      )}
                    </label>
                    <span className="name_checkbox">{item.ten}</span>
                  </div>
                  {child && showList[item.id] && this.renderSub(child)}
                </div>
              </div>
            )
          })}
        </Scrollbars>
      </div>
    )
  }
}

JobProjectTree.propTypes = {
  data: PropTypes.array.isRequired,
  listDisable: PropTypes.array,
  onCheck: PropTypes.func,
}

JobProjectTree.defaultProps = {
  data: [],
  listDisable: [],
  onCheck: () => {},
}

export default JobProjectTree
