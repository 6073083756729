import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItemPhieuGoiYeuCau,
} from '../../components/RequestComment'
import { GridViewFilter, HeadingPage } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import { Button, Intent } from '@blueprintjs/core'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import moment from 'moment'
import FileDownload from 'file-saver'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false
class ListPhieuYeuCauGopY extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
      isShow: false,
      isYeuCauCanXuLy: true,
      listStatus: [
        {
          name: 'LĐPB Chưa duyệt',
          value: 0,
        },
        {
          name: 'TK Chưa duyệt',
          value: 4,
        },
        {
          name: 'LĐĐV chưa duyệt',
          value: 2,
        },
        {
          name: 'Đang góp ý',
          value: 3,
        },
        {
          name: 'Đã hoàn thành',
          value: 9,
        },
        {
          name: 'Không duyệt',
          value: 1,
        },
      ],
      exporting: false,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleClickChoXuLy = this.handleClickChoXuLy.bind(this)
    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    requestComments: PropTypes.object.isRequired,
  }

  async componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData('ListPhieuYeuCauGopYPage')

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.isYeuCauCanXuLy =
        typeof preData.canXuLy === 'undefined'
          ? true
          : preData.canXuLy
          ? true
          : false
      state.filterColumn = {
        ...preData.filterColumn,
        soKyHieu:
          preData.filterColumn && preData.filterColumn.soKyHieu
            ? preData.filterColumn.soKyHieu
            : '',
        trichYeu:
          preData.filterColumn && preData.filterColumn.trichYeu
            ? preData.filterColumn.trichYeu
            : '',
        ngayLap:
          preData.filterColumn && preData.filterColumn.ngayLap
            ? moment(preData.filterColumn.ngayLap).format('YYYY-MM-DD')
            : null,
        thoiHan:
          preData.filterColumn && preData.filterColumn.thoiHan
            ? moment(preData.filterColumn.thoiHan).format('YYYY-MM-DD')
            : null,
        nguoiLap:
          preData.filterColumn && preData.filterColumn.nguoiLap
            ? preData.filterColumn.nguoiLap
            : '',
        tinhTrang:
          preData.filterColumn && preData.filterColumn.tinhTrang
            ? preData.filterColumn.tinhTrang
            : '',
      }
    }
    // else {
    //   state.filter.ngayLap = {
    //     from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    //     to: moment().format('YYYY-MM-DD')
    //   }
    // }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
    await this.props.actions.getNumberHomePage()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.requestComments &&
      this.props.requestComments.list &&
      this.props.requestComments.list.total &&
      this.state.page >
        Math.ceil(
          this.props.requestComments.list.total / Types.PAGINATION_LIMIT
        )
    ) {
      this.setState(
        {
          page: Math.ceil(
            this.props.requestComments.list.total / Types.PAGINATION_LIMIT
          ),
        },
        () => {
          this.clickPagination(this.state.page)
        }
      )
    }
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort, canXuLy = this.state.isYeuCauCanXuLy) {
    const filter = Object.assign({}, this.state.filter, e)

    const filterColumn = {
      ...this.state.filterColumn,
      soKyHieu:
        this.state.filterColumn && this.state.filterColumn.soKyHieu
          ? this.state.filterColumn.soKyHieu
          : '',
      trichYeu:
        this.state.filterColumn && this.state.filterColumn.trichYeu
          ? this.state.filterColumn.trichYeu
          : '',
      ngayLap:
        this.state.filterColumn && this.state.filterColumn.ngayLap
          ? moment(this.state.filterColumn.ngayLap).format('YYYY-MM-DD')
          : null,
      thoiHan:
        this.state.filterColumn && this.state.filterColumn.thoiHan
          ? moment(this.state.filterColumn.thoiHan).format('YYYY-MM-DD')
          : null,
      nguoiLap:
        this.state.filterColumn && this.state.filterColumn.nguoiLap
          ? this.state.filterColumn.nguoiLap
          : '',
      tinhTrang:
        this.state.filterColumn && this.state.filterColumn.tinhTrang
          ? this.state.filterColumn.tinhTrang
          : '',
    }

    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ListPhieuYeuCauGopYPage', {
        canXuLy,
        filter,
        page,
        sort,
        filterColumn,
      })
      // tạm thời để refresh = false
      this.props.actions
        .getDsYeuCauGopY(
          { filter },
          page,
          sort,
          canXuLy,
          REFRESH_FALSE,
          filterColumn
        )
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          let object = false
          if (
            Object.values(filterColumn).find(item => item !== null) !==
            undefined
          ) {
            object = true
          }
          this.setState({ isLoading: false, isShow: object })
          // this.props.actions.refreshSendDocument(false);
        })
    })
  }

  async handleSubmitRemoveItem(lyDo, id) {
    let total = 0
    let page = this.state.page
    if (
      this.props.requestComments &&
      this.props.requestComments.list &&
      this.props.requestComments.list.total
    ) {
      total = this.props.requestComments.list.total
    }

    await this.setState({ isLoadingXoaPhieuYeuCau: true })
    await this.props.actions
      .requestCommentDelete(id, lyDo)
      .then(res => {
        if (res.error) {
          this.setState({ isLoadingXoaPhieuYeuCau: false })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_XOAPHIEUYEUCAUGOPY_KHONGTHANHCONG,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        this.submitFilter(this.state.filter, page, this.state.sort)
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_XOAPHIEUYEUCAUGOPY_THANHCONG,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.setState({ isLoadingXoaPhieuYeuCau: false })
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_XOAPHIEUYEUCAUGOPY_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    if (name === 'trichYeu') {
      return this.setState({
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    } else {
      this.setState(
        {
          isShow: true,
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (name === 'tinhTrang') {
            this.submitFilter()
          }
        }
      )
    }
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
      })
    }
    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  handleClickChoXuLy = () => {
    if (this.state.isYeuCauCanXuLy) {
      return this.setState(
        {
          isYeuCauCanXuLy: false,
          filter: {
            ...this.state.filter,
            textSearch: '',
            ngayLap: {
              from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
              to: moment().format('YYYY-MM-DD'),
            },
          },
        },
        () => {
          this.submitFilter(
            this.state.filter,
            Types.PAGE,
            this.state.sort,
            this.state.isYeuCauCanXuLy
          )
        }
      )
    }
    return this.setState(
      {
        isYeuCauCanXuLy: true,
        filter: {
          ...this.state.filter,
          textSearch: '',
          ngayLap: {
            from: undefined,
            to: undefined,
          },
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          Types.PAGE,
          this.state.sort,
          this.state.isYeuCauCanXuLy
        )
      }
    )
  }

  handleExport() {
    let { filter, sort, isYeuCauCanXuLy, filterColumn } = this.state
    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportDsYeuCauGopY(
          { filter },
          sort,
          isYeuCauCanXuLy,
          REFRESH_FALSE,
          filterColumn
        )
        .then(res => {
          if (res.error || !res.payload || !res.payload.data) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          FileDownload.saveAs(
            res.payload.data,
            `danh-sach-phieu-yeu-cau-gop-y.xls`
          )
        })
        .finally(() => {
          this.setState({
            exporting: false,
          })
        })
    })
  }
  render() {
    const { isLoading, isEmpty, isError, page } = this.state

    return (
      <MasterLayout typeSidebar="utilities">
        <div className="page-utilities-container">
          {/* Heading */}
          <HeadingPage
            namePage="Danh sách phiếu yêu cầu góp ý"
            iconPage="icon-icon-yeu-cau-gop-y"
          >
            <div className="button-heading-right pull-right">
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={this.state.isEmpty}
              />
            </div>
          </HeadingPage>

          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              signNumberDepartmentLeaders={this.props.signUserList}
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              location={this.props.location}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
              isYeuCauCanXuLy={this.state.isYeuCauCanXuLy}
              onClickChoXuLy={this.handleClickChoXuLy}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.requestComments.list.total /
                      Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                page={this.state.page}
                headers={headers.listPhieuYeuCauGopY}
                itemShape={ListItemPhieuGoiYeuCau}
                data={this.props.requestComments.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isError={this.state.isError}
                readOnly={this.props.readOnly}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                filterColumn={this.state.filterColumn}
                onRemoveText={this.onRemoveText}
                phongBanLapId={this.props.departments}
                nguoiKyId={this.props.signUserList}
                auth={this.props.auth}
                onSubmit={this.submitFilter}
                pageCount={Math.ceil(
                  this.props.requestComments.list.total / Types.PAGINATION_LIMIT
                )}
                isType={false}
                isShow={this.state.isShow}
                tinhTrang={this.state.listStatus}
              />

              {!isLoading && !isEmpty && !isError && (
                <Pagination
                  current={page}
                  total={Math.ceil(
                    this.props.requestComments.list.total /
                      Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
  requestComments: {
    ...state.requestComments,
    list: {
      ...state.requestComments.list,
      items: state.requestComments.list.items,
    },
  },
  numberSideBar: state.common.numberSideBar,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListPhieuYeuCauGopY)
