export const Status = {
  common: {
    0: 'Chưa duyệt',
    1: 'Không duyệt',
    2: 'LĐPB CT đã duyệt',
    3: 'LĐPB PH đã duyệt',
    4: 'TK LĐĐV đã duyệt',
    5: 'LĐĐV đã duyệt',
    6: 'Đã cấp số',
    7: 'Đã phát hành',
    8: 'LĐ PB PH ĐV Cấp trên đã duyệt',
    9: 'Thư ký LĐ ĐV Cấp trên đã duyệt',
    10: 'LĐ PH đã duyệt',
    20: 'LĐPB PH đang duyệt',
    21: 'LĐPB PH Cấp trên đang duyệt',
    30: 'LĐ ĐL PH đang duyệt',
    31: 'LĐ ĐL PH đã duyệt',
  },
  special: {
    8: 'TK LĐĐV PH đã duyệt',
    9: 'LĐ ĐV PH đã duyệt',
  },
  kySoTT: {
    0: 'Chưa duyệt',
    1: 'Không duyệt',
    2: 'Đang duyệt',
    6: 'Đã duyệt',
    7: 'Đã phát hành',
  },
}

export const ApprovalDefined = {
  'no-process': [0],
  'no-approval': [1],
  process: [6, 7],
  approved: [2, 3, 4, 5, 8, 9, 10, 21, 20, 30, 31],
}

export const trangThaiHoSoCongViec = [
  {
    value: '0',
    name: 'Chưa nộp hồ sơ',
  },
  {
    value: '1',
    name: 'LĐPB Chờ duyệt',
  },
  {
    value: '2',
    name: 'LĐPB Đã duyệt',
  },
  {
    value: '3',
    name: 'VT Đã tiếp nhận',
  },
  {
    value: '4',
    name: 'Đã lưu hồ sơ',
  },
  {
    value: '5',
    name: 'Trả lại',
  },
]

export const trangThaiHoSoCongViecDonVi = [
  {
    value: '0',
    name: 'Chưa nộp hồ sơ',
  },
  {
    value: '1',
    name: 'LĐPB Chờ duyệt',
  },
  {
    value: '2',
    name: 'Chờ tiếp nhận',
  },
  {
    value: '3',
    name: 'LĐVP chờ duyệt',
  },
  {
    value: '4',
    name: 'Đã lưu hồ sơ',
  },
  {
    value: '5',
    name: 'Trả lại',
  },
]

export const trangThaiHoSoCongViecDonViVanThu = [
  {
    value: '2',
    name: 'Chờ tiếp nhận',
  },
  {
    value: '3',
    name: 'VT đã tiếp nhận',
  },
  {
    value: '4',
    name: 'Đã lưu hồ sơ',
  },
  {
    value: '5',
    name: 'Trả lại',
  },
]

export const trangThaiHoSoCongViecDonViLĐVP = [
  {
    value: '3',
    name: 'LĐVP chờ duyệt',
  },
  {
    value: '4',
    name: 'Đã lưu hồ sơ',
  },
  {
    value: '5',
    name: 'Trả lại',
  },
]
