import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { intersectionBy, isEmpty } from 'lodash'
import { Permission } from '../auth'
import { DetailFileDinhKem, FileList } from '../common/'
import { Utils } from 'helpers'
const UNIT = 1
const DEPARTMENT = 2

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    let permission = []
    switch (parseInt(props.type, 10)) {
      case UNIT:
        permission.push(Permission.TUTAILIEUDONVI_QUANLY)
        break
      case DEPARTMENT:
        permission.push(Permission.TUTAILIEUPHONGBAN)
        break
      default:
    }
    this.state = {
      permission,
      showBoxAction: false,
    }
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  render() {
    const { auth, stateLocation, data } = this.props
    let isCheckSuaTaiLieu = true
    // Check nếu người chịu trách nhiệm hoặc người phối hơp thì sẽ được chỉnh sửa tài liệu
    if (
      (stateLocation.trangThai === 0 || stateLocation.trangThai === 5) &&
      ((!isEmpty(stateLocation) &&
        stateLocation.nguoiChiuTrachNhiem &&
        stateLocation.nguoiChiuTrachNhiem.chucDanhId === auth.roleId) ||
        (!isEmpty(stateLocation) &&
          stateLocation.dsNguoiPhoiHop &&
          stateLocation.dsNguoiPhoiHop.length !== 0 &&
          intersectionBy(
            [{ chucDanhId: auth.roleId }],
            stateLocation.dsNguoiPhoiHop,
            'chucDanhId'
          ).length !== 0 &&
          !isEmpty(data.nguoiLuu) &&
          data.nguoiLuu.chucDanhId === auth.roleId))
    ) {
      isCheckSuaTaiLieu = false
    }

    const { fileDinhKemBenNgoai = [] } = this.props.data

    return (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        {this.props.data &&
          this.props.data.fileDinhKem &&
          this.props.data.fileDinhKem.length > 0 && (
            <DetailFileDinhKem
              receiveDocument={{
                fileDinhKem: this.props.data.fileDinhKem,
              }}
              auth={this.props.auth}
              label="File đính kèm từ công văn"
            />
          )}

        {!!fileDinhKemBenNgoai.length && (
          <DetailFileDinhKem
            receiveDocument={{
              fileDinhKem: fileDinhKemBenNgoai,
            }}
            auth={auth}
            label="File đính kèm từ bên ngoài"
            isQLCV
          />
        )}

        {Utils.checkBanTongHop(this.props.auth.mainDepartmentId) &&
          this.props.data.fileThamKhao &&
          this.props.data.fileThamKhao.length !== 0 && (
            <div className="pt10 pl20 pb10 pr20">
              <label className="pt-label label-text-detail">
                File tham khảo:
              </label>
              <div className="pt-form-content">
                <div className="ml10">
                  <FileList
                    auth={this.props.auth}
                    receiveDocument={{
                      fileThamKhao: this.props.data.fileThamKhao || [],
                    }}
                    field="fileThamKhao"
                  />
                </div>
              </div>
            </div>
          )}
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button
              className="click-item"
              onClick={this.showBoxAction.bind(this)}
            >
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row list-all-action">
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickChinhSuaTaiLieu}
                disabled={isCheckSuaTaiLieu}
              >
                <span className="pt-icon pt-icon-annotation icon-cpc"></span>
                <span className="action-name">Sửa tài liệu</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickThongTinTaiLieu}
              >
                <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                <span className="action-name">Thông tin tài liệu</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button className="click-item" onClick={this.props.sendToChat}>
                <i className="icon-speech-bubble icon-cpc"></i>
                <span className="action-name">Gửi Chat</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickSendMail}
              >
                <i className="icon-Gmail icon-cpc"></i>
                <span className="action-name">Gửi email</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DetailBoxAction.propTypes = {
  auth: PropTypes.object.isRequired,
  stateLocation: PropTypes.object,
}

export default DetailBoxAction
