import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Popover, Position } from '@blueprintjs/core'
import { get } from 'lodash'
import classnames from 'classnames'

import { Input } from '../index'

const DropdownWrapper = ({
  label,
  onClear,
  isRequired = false,
  children = null,
  disabled = false,
  inputClearable,
  inputIconClassName,
  inputWrapperClassName = '',
  inputWarning,
  inputType = 'text',
  inputStyle = {},
  onDropdownVisibleChange = () => {},
  open = false,
  isModal = false,
  placeholder = '',
  value: valueDisplay = '',
  refuseClose = () => false,
  popoverPosition = Position.BOTTOM_LEFT,
  popoverStretch = true,
  popoverHeight = 'unset',
  popoverClassName,
  rightElement = null,
  isShowArrowDropdown = false,
  wrapperStyle = {},
  textLabel = null,
  className = '',
  textWarning,
}) => {
  const inputWrapperRef = useRef()
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [isOpen, _setIsOpen] = useState(open)
  const [popoverWidth, _setPopoverWidth] = useState(0)

  const openDropdown = useCallback(() => {
    if (disabled) return
    if (popoverStretch && inputWrapperRef.current && !popoverWidth) {
      const { width } = inputWrapperRef.current.getBoundingClientRect()
      _mountedSet(_setPopoverWidth, width)
    }
    _mountedSet(_setIsOpen, true)
    onDropdownVisibleChange(true)
  }, [
    onDropdownVisibleChange,
    inputWrapperRef,
    popoverWidth,
    disabled,
    popoverStretch,
  ])

  const closeDropdown = useCallback(
    e => {
      if (refuseClose(e)) return
      _mountedSet(_setIsOpen, false)
      onDropdownVisibleChange(false)
    },
    [onDropdownVisibleChange, refuseClose]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setIsOpen, open)
  }, [open])

  return (
    <div className={className}>
      {textLabel && (
        <div className="align-center" style={{ minHeight: 30 }}>
          <label className={classnames({ asterisk: isRequired })}>
            {textLabel}
          </label>
        </div>
      )}
      <Popover
        content={
          <div
            style={{
              minWidth: popoverWidth,
              maxHeight: popoverHeight,
              overflowY: 'auto',
            }}
          >
            {children}
          </div>
        }
        isOpen={isOpen}
        isModal={isModal}
        onClose={closeDropdown}
        position={popoverPosition}
        popoverClassName={classnames(
          'cpc-popover no-arrow m0',
          popoverClassName
        )}
        rootElementTag="div"
      >
        <Input
          onClear={onClear}
          label={label}
          clearable={inputClearable}
          disabled={disabled}
          iconClassName={inputIconClassName}
          onWrapperClick={openDropdown}
          placeholder={placeholder}
          readOnly
          rightElement={rightElement}
          value={valueDisplay ?? ''}
          warning={inputWarning}
          wrapperRef={inputWrapperRef}
          wrapperClassName={inputWrapperClassName}
          type={inputType}
          style={inputStyle}
          wrapperStyle={wrapperStyle}
          arrowDropdown={
            isShowArrowDropdown ? (
              <i
                className={classnames(
                  {
                    'icon-arrow-dropup icon-arrow-dropup2': isOpen,
                    'icon-arrow-dropdown icon-arrow-dropup2': !isOpen,
                  },
                  'size-icon-6',
                  'ml10'
                )}
                onClick={openDropdown}
              />
            ) : null
          }
        />
      </Popover>
      {textWarning && <p className="font-size-13 color-red">{textWarning}</p>}
    </div>
  )
}

export default memo(DropdownWrapper)
