import React, { useMemo, useState, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import size from 'lodash/size'
import moment from 'moment'

import { MasterLayout } from 'components/layout'
import { HeadingPage, GridViewFilter } from 'components/common'
import * as Actions from 'actions'
import { PAGINATION_LIMIT } from 'constants/Api'
import Pagination from 'components/Pagination'
import Filter from './filter'

const TimKiemCongVanDO = ({
  auth,
  history,
  typeSidebar,
  tokenDO,
  listLanhDao,
  listPhongBan,
  listDonVi,
  listSoVanBan,
  listCongVan,
  listPhongBanXuLy,
  listChuyenVienXuLy,
  danhSachCongVanDO,
}) => {
  const filterKeys = {
    nam: 'NAM',
    loaiCongVan: 'LoaiVB',
    mainKeyWord: 'MainKeyWord',
    idSoVB: 'ID_SO_VB',
    idLoaiVB: 'ID_LOAI_VB',
    idLanhDaoChiDao: 'IDLanhDaoChiDao',
    idPhongBanBanHanh: 'IDPhongBanBanHanh',
    nguoiKyVB: 'NguoiKyVB',
    noiGui: 'NoiGui',
    noiNhan: 'NoiNhan',
    dateFrom: 'DateFrom',
    dateTo: 'DateTo',
    idChuyenVienXL: 'IDChuyenVienXL',
    idPhongBanChuTri: 'IDPhongBanChuTri',
    soDen: 'SO_DEN',
    kyHieu: 'KY_HIEU',
  }
  const DEFAULT_FILTER = useMemo(
    () => ({
      [filterKeys?.idChuyenVienXL]: 0,
      [filterKeys?.idPhongBanBanHanh]: 0,
      [filterKeys?.idPhongBanChuTri]: 0,
      [filterKeys?.idLoaiVB]: 0,
      [filterKeys?.idSoVB]: 0,
      [filterKeys?.loaiCongVan]: 'all',
      [filterKeys?.mainKeyWord]: '',
      [filterKeys?.nam]: 2023,
      [filterKeys?.dateFrom]: undefined,
      [filterKeys?.dateTo]: undefined,
      [filterKeys?.kyHieu]: '',
    }),
    [filterKeys]
  )
  const [isError] = useState(false)
  const [isFetching] = useState(false)
  const [filter] = useState(DEFAULT_FILTER)
  const [pagination] = useState({
    page: 1,
    size: 10,
  })
  const [dataSource, setDataSource] = useState([])

  const handlePagination = useCallback(
    value => {
      const { size } = pagination
      const firstItem = (value - 1) * size
      const lastItem = value * size
      const newData = danhSachCongVanDO.slice(firstItem, lastItem)
      setDataSource(newData)
    },
    [pagination, danhSachCongVanDO]
  )

  const renderHeader = useMemo(() => {
    return [
      {
        classNames: 'column-3',
        style: [],
        value: '',
        permission: [],
        typeGridView: null,
        nameGridView: '',
      },
      {
        classNames: 'column-10',
        style: [],
        value: 'Số kí hiệu',
        permission: [],
        typeGridView: '',
        nameGridView: 'kYHIEU',
      },
      {
        classNames: 'column-35',
        style: [],
        value: 'Trích Yếu / Tiêu đề',
        permission: [],
        typeGridView: null,
        nameGridView: 'tieuDe',
      },
      {
        classNames: 'column-13',
        style: [],
        value: 'Nơi ban hành',
        permission: [],
        typeGridView: null,
        nameGridView: 'noiBanHanh',
      },
      {
        classNames: 'column-13',
        style: [],
        value: 'Ngày VB',
        permission: [],
        typeGridView: null,
        nameGridView: 'ngayVB',
      },
      {
        classNames: 'column-7',
        style: [],
        value: '',
        permission: [],
        typeGridView: null,
        nameGridView: 'thaoTac',
      },
    ]
  }, [])

  const goChiTiet = id => {
    return history.push(`/tien-ich/tim-kiem-cong-van-do/chi-tiet/${id}`)
  }

  const renderRow = ({
    item: { iDVB, kYHIEU, tRICHYEU, nOIBANHANH, nGAYVB },
    number: index,
  }) => {
    return (
      <tr>
        <td className="cursor-default">{index > 9 ? index : '0' + index}</td>
        <td>{kYHIEU}</td>
        <td>{tRICHYEU}</td>
        <td>{nOIBANHANH}</td>
        <td>{nGAYVB ? moment(nGAYVB).format('DD/MM/YYYY') : ''}</td>
        <td>
          <div onClick={e => e.stopPropagation()}>
            <div
              className="color-blue pointer center"
              onClick={() => goChiTiet(iDVB)}
            >
              <span>Chi tiết</span>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  const totalData = size(danhSachCongVanDO ?? []) ?? 0
  return (
    <MasterLayout typeSidebar={typeSidebar}>
      <HeadingPage
        namePage="Tìm kiếm công văn D-Office"
        iconPage="icon-search-data"
      ></HeadingPage>
      <div>
        <Filter
          listLanhDao={listLanhDao}
          listPhongBan={listPhongBan}
          listDonVi={listDonVi}
          listSoVanBan={listSoVanBan}
          listCongVan={listCongVan}
          listPhongBanXuLy={listPhongBanXuLy}
          listChuyenVienXuLy={listChuyenVienXuLy}
          filterKeys={filterKeys}
          filter={filter}
        />
        <div
          className="table-container"
          style={{
            paddingBottom: 0,
            border: 0,
            background:
              Math.ceil(totalData / PAGINATION_LIMIT) > 1 ? '#fff' : '#eff3f5',
          }}
        >
          <GridViewFilter
            classNames={'pt-table fix-table-before'}
            page={pagination?.page ?? 0}
            headers={renderHeader}
            itemShape={renderRow}
            data={dataSource}
            isEmpty={totalData === 0}
            isLoading={isFetching}
            isError={isError}
            pageCount={Math.ceil(totalData / PAGINATION_LIMIT)}
          />

          {!isFetching && !isError && totalData > 10 && (
            <Pagination
              current={pagination?.page ?? 0}
              total={Math.ceil(totalData / PAGINATION_LIMIT)}
              onChange={handlePagination}
            />
          )}
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: state?.auth,
  typeSidebar: state?.common?.typeSidebar,
  tokenDO: state?.doffice?.toKenDO,
  listLanhDao: state?.doffice?.listLanhDao,
  listPhongBan: state?.doffice?.listPhongBan,
  listDonVi: state?.doffice?.listDonVi,
  listSoVanBan: state?.doffice?.listSoVanBan,
  listCongVan: state?.doffice?.listCongVan,
  listPhongBanXuLy: state?.doffice?.listPhongBanXuLy,
  listChuyenVienXuLy: state?.doffice?.listChuyenVienXuLy,
  danhSachCongVanDO: state?.doffice?.danhSachCongVan,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TimKiemCongVanDO)
