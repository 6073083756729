import React, { memo } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import csx from 'classnames'

import { Card, Row, UserCard, Icon } from '../index'
import { convertStringWithNameTag } from 'helpers/string'
import { Action } from 'constants/MessageForm'

const Comment = ({
  allowAction = false,
  className,
  user,
  thoiGian,
  noiDung = '',
  onDelete = () => {},
  onEdit = () => {},
  isRetrieval = false,
  isEdited = false,
}) => {
  return (
    <Card paddingVertical className={csx('comment-card', className)}>
      <Row className="mb10">
        <UserCard
          needBaseUrl
          src={get(user, 'avatar')}
          name={get(user, 'fullName')}
          department={get(user, 'department.shortName')}
          unit={get(user, 'organization.shortName')}
        />
      </Row>
      <Row className="mb5">
        {isRetrieval ? (
          <span className="text-thuhoi">{Action.COMMENT} đã được thu hồi</span>
        ) : (
          <span
            className="comment-content"
            dangerouslySetInnerHTML={{
              __html: convertStringWithNameTag(noiDung),
            }}
          />
        )}
      </Row>
      <Row>
        <span className="comment-time">
          {moment(thoiGian).format('HH:mm DD/MM/YYYY')}
          {!isRetrieval && isEdited && '- Đã chỉnh sửa'}
        </span>
      </Row>

      {allowAction && (
        <div className="box-action-comment">
          <Icon
            classIcon="icon-but"
            className={csx(
              'size-icon-12',
              'mr10',
              'icon-color-primary',
              'has-event'
            )}
            onClick={onEdit}
          />
          <Icon
            classIcon="icon-bin"
            className={csx('size-icon-12', 'icon-color-danger', 'has-event')}
            onClick={onDelete}
          />
        </div>
      )}
    </Card>
  )
}

export default memo(Comment)
