import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  documentList: {
    page: 1,
    total: 0,
    items: [],
  },
}

export default function alertMistakes(state = initialState, action) {
  switch (action.type) {
    case types.ALERT_MISTAKE_GET_LIST:
      if (action.payload.config.params.loai === 2) {
        return {
          ...state,
          list: {
            page: action.payload.data.result.result.page,
            total: action.payload.data.result.result.total,
            items:
              action.payload.data.result &&
              action.payload.data.result.result &&
              action.payload.data.result.result.items
                ? action.payload.data.result.result.items
                : [],
          },
        }
      }

      if (action.payload.config.params.loai === 1) {
        return {
          ...state,
          documentList: {
            page: action.payload.data.result.result.page,
            total: action.payload.data.result.result.total,
            items:
              action.payload.data.result &&
              action.payload.data.result.result &&
              action.payload.data.result.result.items
                ? action.payload.data.result.result.items
                : [],
          },
        }
      }

      break
    default:
      return state
  }
}
