import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import { Link, Redirect } from 'react-router-dom';
import classnames from 'classnames'
import { isNumber, isEmpty } from 'lodash'

const DEFAULT_ID = '00000000-0000-0000-0000-000000000000'
const TOTAL_NAME = 'Tổng cộng'
class ListItem extends Component {
  constructor(props) {
    super(props)
    this.convertNumber = this.convertNumber.bind(this)
    this.redirectToStatisticByUnit = this.redirectToStatisticByUnit.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (isNumber(number)) {
      return number
    }

    return 0
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled })
  }

  redirectToDocumentNotSave = data => {
    if (isEmpty(data)) {
      return
    }

    const id = data.id
    const phongBanId = data.phongBanId
    const { filter } = this.props
    let type = 'ca-nhan'
    let phongBanPathUrl = `/${phongBanId}`
    if (data.loai === 1) {
      type = 'phong-ban'
      phongBanPathUrl = ''
    }

    return (
      this.props.history &&
      this.props.history.push({
        pathname: `/tien-ich/thong-ke-tu-tai-lieu/chi-tiet/${type}/${id}${phongBanPathUrl}`,
        state: {
          start_date: filter.start_date,
          end_date: filter.end_date,
        },
      })
    )
  }

  redirectToStatisticByUnit = item => {
    if (!item || !item.id) {
      return
    }

    let typePath = 'phong-ban'
    if (Number(item.loai) === 0) {
      typePath = 'don-vi'
    }

    return (
      this.props.history &&
      this.props.history.push({
        pathname: `/tien-ich/thong-ke-tu-tai-lieu/danh-sach/${typePath}/${item.id}`,
        state: {
          isCheck: true,
        },
      })
    )
  }

  render() {
    let { item, number, auth } = this.props
    if (!item) {
      return
    }

    const isTotalRaw = item.id === DEFAULT_ID && item.name === TOTAL_NAME
    const isCheckClickRow = !isTotalRaw && (item.loai === 0 || item.loai === 1)
    const isCheckClickChuaLuuTu =
      auth.mainDepartmentId === item.id ||
      (item.phongBanId === auth.mainDepartmentId && item.loai === 2) ||
      (item.loai === 2 &&
        item.phongBanId === auth.mainDepartmentId &&
        !!auth.permission.includes('SUPERADMIN'))

    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td
          onClick={
            isCheckClickRow
              ? this.redirectToStatisticByUnit.bind(this, item)
              : null
          }
          className={classnames('gerenal-list-utilities', {
            'over-time': isCheckClickRow,
          })}
        >
          <span className="hover-over-time">{item.name}</span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVan)}
        </td>
        <td
          className={classnames('gerenal-list-utilities text-center', {
            'over-time': isCheckClickChuaLuuTu,
          })}
          onClick={
            isCheckClickChuaLuuTu
              ? () => this.redirectToDocumentNotSave(item)
              : null
          }
        >
          <span className="hover-over-time">
            {this.convertNumber(item.tongSoCongVanChuaLuuTtl)}
          </span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanDaLuuTtl)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tyLeLuuTuTaiLieu)}
        </td>
      </tr>
    )
  }
}

export default ListItem
