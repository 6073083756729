import React, { memo, useState, useEffect, useMemo, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'
import { Button } from '@blueprintjs/core'

import { CircleLoader } from 'components/newCommon2'
import { Card, Loading, Icon, UserCard, Scrollbar } from 'components/newCommon'
import { EmptyDataTable } from 'components/common'
import requestAction from 'helpers/request'
import { getAccept } from 'actions/task'
const dateFormat = 'DD/MM/YYYY HH:mm'

const AcceptHistory = ({ onClose = () => {}, match }) => {
  const { actionId, id } = match.params
  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
    }),
    []
  )
  const [fetching, setFetching] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [data, setData] = useState([])
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [isLoading, setIsLoading] = useState(false)

  const onShowMore = useCallback(() => {
    if (size(data) >= totalData) {
      return
    }

    let workId = actionId
    if (!actionId) {
      workId = id
    }

    const page = filter?.page ? Number(filter.page) : 0
    const params = {
      workItemId: workId,
      page: page + 1,
      limit: 10,
    }

    requestAction({
      codeCheck: false,
      showToast: true,
      beforeAction: () => {
        setIsLoading(true)
      },
      action: () => getAccept(params),
      afterResponse: response => {
        setFilter(page + 1)
        setData(prev => uniqBy([...prev, ...(response?.items || [])], 'id'))
      },
      afterAction: () => {
        setIsLoading(false)
      },
    })
  }, [id, actionId, filter, totalData, data])

  const cardItem = useMemo(
    () => (value, index) => {
      return (
        <Card className={'font-size-13 p15 mt10'} bordered key={index}>
          <div className="d-flex justify-content-space-between align-items-center">
            <UserCard
              needBaseUrl
              src={value?.acceptor?.avatar}
              name={value?.acceptor?.name}
            />
            <span>
              {value?.creationTime
                ? moment(value?.creationTime).format(dateFormat)
                : '-'}
            </span>
          </div>
          {value?.description && (
            <div className="mt10 ml45">{value?.description}</div>
          )}
          <div className="d-flex justify-content-space-between ml45">
            <p className="mt10">
              <span className="mr5" style={{ color: 'rgba(51, 51, 51, 0.65)' }}>
                Thực hiện:
              </span>
              <span
                className="font-weight-500"
                style={{ color: 'rgba(49, 67, 75, 0.85)' }}
              >
                {value?.mainResponsibility?.name}
              </span>
            </p>
            <p className="mt10">
              <span className="mr5" style={{ color: 'rgba(51, 51, 51, 0.65)' }}>
                Tiến độ:
              </span>
              <span
                className="font-weight-500"
                style={{ color: 'rgba(49, 67, 75, 0.85)' }}
              >
                {value?.progress || value?.progress === 0
                  ? `${value.progress}%`
                  : '-'}
              </span>
            </p>
          </div>
        </Card>
      )
    },
    []
  )

  useEffect(() => {
    let workId = actionId
    if (!actionId) {
      workId = id
    }

    if (workId) {
      const params = {
        workItemId: workId,
        page: 1,
        limit: 10,
      }
      requestAction({
        codeCheck: false,
        showToast: true,
        beforeAction: () => {
          setFetching(true)
        },
        action: () => getAccept(params),
        afterResponse: response => {
          setData(response?.items || [])
          setTotalData(response?.totalCount || 0)
        },
        afterAction: () => {
          setFetching(false)
        },
      })
    }
  }, [id, actionId])

  return (
    <div className={classNames('cpc-side-action', 'open', 'large-size')}>
      {fetching ? (
        <Loading />
      ) : (
        <div>
          <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
            <span>Lịch sử xác nhận</span>
            <Icon
              classIcon="icon-Huy"
              className={'has-event font-size-12 ml10'}
              onClick={onClose}
            />
          </h1>
          <div className="pt5 pr15 pl15">
            {!fetching && size(data) !== 0 && (
              <Scrollbar maxHeight={window.innerHeight - 172}>
                {data?.map((elm, index) => cardItem(elm, index))}
                {size(data) !== 0 &&
                  totalData !== 0 &&
                  size(data) < totalData && (
                    <div className="text-center">
                      <Button
                        className={classNames(
                          'cpc-button cpc-button-link',
                          'no-background',
                          'uppercase',
                          'font-size-12',
                          'min-width-100 mh10'
                        )}
                        disabled={isLoading || false}
                        onClick={onShowMore}
                      >
                        Xem thêm
                        {isLoading && (
                          <span
                            className="ml5"
                            style={{
                              position: 'relative',
                              borderRadius: 3,
                            }}
                          >
                            <CircleLoader fill="#ddd" size={20} />
                          </span>
                        )}
                      </Button>
                    </div>
                  )}
              </Scrollbar>
            )}
            {!fetching && size(data) === 0 && (
              <EmptyDataTable className={'small'} />
            )}
          </div>
          <div className="element-center ph15">
            <Button
              className={classNames(
                'cpc-button',
                'btn-cancel',
                'uppercase',
                'font-size-13',
                'ph10',
                'min-width-100'
              )}
              onClick={onClose}
            >
              <Icon classIcon="icon-back" className="mr5" />
              Quay lại
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(memo(AcceptHistory))
