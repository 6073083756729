import React, { memo, useState } from 'react'
import { connect } from 'react-redux'
import { get, size, compact, indexOf } from 'lodash'
import classnames from 'classnames'
import { Checkbox } from '@blueprintjs/core'

import { STATUS_HSCV } from 'constants/Api'
import { Empty, Loading } from 'components/newCommon'
import Truncate from './Truncate'

const JobProjectTree = ({
  loadingTree = false,
  typeCabinet = null,
  data = [],
  listChecked = [],
  modelTree = 'child',
  modelTreeName = 'ten',
  modelCodeName = 'maKyHieu',
  roleUserId = null,
  listDisable = [],
  onCheck = () => {},
  allowParentChecked = false,
}) => {
  const [showList, setShowList] = useState({})
  const [checkedArray, setCheckedArray] = useState(listChecked)

  const toggleCheck = e => {
    let { value, checked } = e.target
    let checkResult = checked
      ? compact([...checkedArray, value])
      : checkedArray.filter(v => v !== value)
    setCheckedArray(checkResult)
    onCheck(checkResult)
  }

  const toggleShow = id => {
    setShowList(prevData => ({
      ...prevData,
      [id]: !showList[id],
    }))
  }

  const checkDisable = item => {
    if (listDisable.includes(item.id)) {
      return true
    } else if (
      ![STATUS_HSCV.CHUA_NOP, STATUS_HSCV.TRA_LAI].includes(
        get(item, 'trangThai', 0)
      )
    ) {
      return true
    } else if (
      [
        get(item, 'nguoiChiuTrachNhiem') || {},
        ...(get(item, 'dsNguoiPhoiHop') || []),
      ]
        .map(v => get(v, 'chucDanhId'))
        .includes(roleUserId) === false &&
      item.loai === 1
    ) {
      return true
    }

    return false
  }

  const renderSub = items => {
    return items.map(item => {
      const child = !!size(get(item, modelTree, [])) && get(item, modelTree, [])
      return (
        <div key={item.id} className="task-document-cabinet__tree__subitem">
          <div className="task-document-cabinet__tree__subitem--row">
            {child && (
              <div
                className="task-document-cabinet__tree__item--toggle"
                onClick={() => toggleShow(item.id)}
              >
                <span
                  className={classnames('pt-icon', {
                    'pt-icon-chevron-up': showList[item.id],
                    'pt-icon-chevron-down': !showList[item.id],
                  })}
                />
              </div>
            )}
            {(allowParentChecked || !child) && (
              <Checkbox
                className="cpc-checkbox"
                value={item.id}
                onChange={toggleCheck}
                checked={checkedArray.includes(item.id)}
                disabled={
                  typeCabinet && typeCabinet === '4'
                    ? checkDisable(item)
                    : indexOf(listDisable, item.id) !== -1
                }
              />
            )}
            <span
              className={classnames('task-document-cabinet__tree__name', {
                'task-document-cabinet__tree__name--nomal':
                  !child || typeCabinet !== '4',
              })}
            >
              {item[modelCodeName] && (
                <span className="keycode">{item[modelCodeName]}</span>
              )}
              <Truncate text={item[modelTreeName]} />
            </span>
          </div>
          {child && showList[item.id] && renderSub(child)}
        </div>
      )
    })
  }

  // useEffect(() => {
  //   mounted.current = true;
  //   return () => (mounted.current = false);
  // }, []);

  return (
    <div className="task-document-cabinet__tree">
      {loadingTree && <Loading column={1} />}
      {!loadingTree &&
        (!!size(data) ? (
          data.map(item => {
            const child =
              !!size(get(item, modelTree, [])) && get(item, modelTree, [])
            return (
              <div key={item.id} className="task-document-cabinet__tree__group">
                <div
                  className={classnames('task-document-cabinet__tree__item', {
                    'not-icon-chevron': !child,
                  })}
                >
                  {child && (
                    <div
                      className="task-document-cabinet__tree__item--toggle"
                      onClick={() => toggleShow(item.id)}
                    >
                      <span
                        className={classnames('pt-icon', {
                          'pt-icon-chevron-up': showList[item.id],
                          'pt-icon-chevron-down': !showList[item.id],
                        })}
                      />
                    </div>
                  )}
                  {(allowParentChecked || !child) && (
                    <Checkbox
                      className="cpc-checkbox"
                      value={item.id}
                      onChange={toggleCheck}
                      checked={checkedArray.includes(item.id)}
                      disabled={
                        typeCabinet && typeCabinet === '4'
                          ? checkDisable(item)
                          : indexOf(listDisable, item.id) !== -1
                      }
                    />
                  )}
                  <span
                    className={classnames('task-document-cabinet__tree__name', {
                      'task-document-cabinet__tree__name--nomal':
                        !child || typeCabinet !== '4',
                    })}
                  >
                    {item[modelCodeName] && (
                      <span className="keycode">{item[modelCodeName]}</span>
                    )}
                    <Truncate text={item[modelTreeName]} />
                  </span>
                </div>
                {child && showList[item.id] && renderSub(child)}
              </div>
            )
          })
        ) : (
          <Empty />
        ))}
    </div>
  )
}

const mapStateToProps = state => ({
  roleUserId: get(state, 'auth.roleId'),
})

export default connect(mapStateToProps)(memo(JobProjectTree))
