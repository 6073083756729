import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/Opinion'
import { GridViewFilter } from '../../components/common'
import { connect } from 'react-redux'
import { Intent, Position, Toaster } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import Pagination from 'components/Pagination'

class OpinionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      listStatus: [
        {
          name: 'Chưa duyệt',
          value: 0,
        },
        {
          name: 'Không duyệt',
          value: 1,
        },
        {
          name: 'Đang lấy ý kiến',
          value: 2,
        },
        {
          name: 'Hoàn tất',
          value: 6,
        },
      ],
      isEmpty: false,
      page: 1,
      isLoading: false,
      isNeedOpinion: true,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soKyHieu: null,
      isError: false,
      departments: [],
      isLoadingXoaYKien: false,
    }
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickNeedOpinion = this.handleClickNeedOpinion.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
  }
  static propTypes = {
    list: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }
  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }
  async componentDidMount() {
    await this.setState({
      isLoading: true,
    })
    if (this.props.location.state && this.props.location.state.saved) {
      this.toaster.show({
        message: 'Lưu thành công!',
        intent: Intent.SUCCESS,
        autoFocus: true,
        timeout: 2000,
      })
    }
    let preData = Tool.Utils.preData('OpinionList')
    let state = this.state
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.isNeedOpinion = preData.canXuLy
      state.filterColumn = {
        ...preData.filterColumn,
        ngayLap:
          preData.filterColumn && preData.filterColumn.ngayLap
            ? moment(preData.filterColumn.ngayLap)
            : null,
      }
    }
    if (!state.isNeedOpinion) {
      state.filter.ngayLap = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }
    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.submitFilter({}, page, this.state.sort, this.state.isNeedOpinion)
    this.setState({ page })
  }

  handleClickNeedOpinion = () => {
    if (this.state.isNeedOpinion) {
      return this.setState(
        {
          isNeedOpinion: false,
          filter: {
            ...this.state.filter,
            textSearch: '',
            ngayLap: {
              from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
              to: moment().format('YYYY-MM-DD'),
            },
          },
        },
        () => {
          this.submitFilter(
            this.state.filter,
            Types.PAGE,
            this.state.sort,
            this.state.isNeedOpinion
          )
        }
      )
    }
    this.setState(
      {
        isNeedOpinion: true,
        filter: {
          ...this.state.filter,
          textSearch: '',
          ngayLap: {
            from: undefined,
            to: undefined,
          },
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          Types.PAGE,
          this.state.sort,
          this.state.isNeedOpinion
        )
      }
    )
  }

  submitFilter(e = {}, page = 1, sort, canXuLy = this.state.isNeedOpinion) {
    const filter = Object.assign({}, this.state.filter, e)
    const filterColumn = {
      ...this.state.filterColumn,
      soKyHieu: _.get(this.state.filterColumn, 'soKyHieu', null),
      trichYeu: _.get(this.state.filterColumn, 'trichYeu', null),
      ngayLap: this.state.filterColumn.ngayLap
        ? moment(this.state.filterColumn.ngayLap).format('YYYY-MM-DD')
        : null,
      nguoiLap: _.get(this.state.filterColumn, 'nguoiLap', null),
      tinhTrang: _.get(this.state.filterColumn, 'tinhTrang', null),
    }
    let isCallRequestAgain = false
    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('OpinionList', {
        filter,
        page,
        sort,
        canXuLy,
        filterColumn,
      })
      // tạm thời để refresh bang false
      this.props.actions
        .opinionSendGet(
          { filter },
          page,
          sort,
          canXuLy,
          Types.PAGINATION_LIMIT,
          filterColumn
        )
        .then(res => {
          if (
            res &&
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort, canXuLy)
          }
          let isEmpty = false
          let isError = false
          if (
            res &&
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (
            res &&
            (res.error || (res.payload && res.payload.status !== 200))
          ) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          if (isCallRequestAgain === false) {
            this.setState({ isLoading: false, isNeedOpinion: canXuLy })
          }
          this.props.actions.refreshOpinion(false)
        })
    })
  }

  handleSubmitRemoveItem = async (lydo, id) => {
    let total = 0
    let page = this.state.page
    if (this.props.list && this.props.list && this.props.list.total) {
      total = this.props.list.total
    }
    await this.setState({ isLoadingXoaYKien: true })
    let params = {
      id: id,
      lyDoXoa: lydo,
    }
    await this.props.actions
      .deleteOpinion(params)
      .then(res => {
        if (res.error) {
          this.setState({ isLoadingXoaYKien: false })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }
        if (page === 0) {
          page = 1
        }
        this.props.actions.refreshOpinion(true)
        this.submitFilter(
          this.state.filter,
          page,
          this.state.sort,
          this.state.isNeedOpinion
        )
        this.setState({ isLoadingXoaYKien: false })
        this.props.actions.getNumberPhieuYKien()
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.setState({ isLoadingXoaYKien: false })
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.setState({ sort })
    this.submitFilter(
      this.state.filter,
      this.state.page,
      sort,
      this.state.isNeedOpinion
    )
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }
    this.setState({
      isRedirect: true,
      redirectTo: `/hoi-dong-thanh-vien/lay-y-kien/chi-tiet/${data.phieuLayYKienId}`,
      fileId: data.fileId,
      soKyHieu: data.soKyHieu,
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (name === 'tinhTrang') {
          this.submitFilter(
            this.state.filter,
            this.state.page,
            this.state.sort,
            this.state.isNeedOpinion
          )
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e ? e : undefined,
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          this.state.page,
          this.state.sort,
          this.state.isNeedOpinion
        )
      }
    )
  }

  async clearFilterColumn(filter, type = null) {
    let isNeedOpinion = this.state.isNeedOpinion
    if (type === 'reset') {
      this.props.actions.getNumberSignNumberPage()
      isNeedOpinion = true
    }
    this.submitFilter(filter, this.state.page, this.state.sort, isNeedOpinion)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter(
          this.state.filter,
          this.state.page,
          this.state.sort,
          this.state.isNeedOpinion,
          this.state.filterColumn
        )
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soKyHieu: this.state.soKyHieu,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }
    return (
      <MasterLayout typeSidebar="signNumber">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-bubble-cuoc-tro-chuyen icon-heading"></span>
                  <span className="text-heading">Danh sách ý kiến</span>
                </h3>
              </div>
            </div>
          </div>
          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              staffs={this.props.staffs}
              status={this.state.listStatus}
              onSubmit={this.handleSubmit}
              isNeedOpinion={this.state.isNeedOpinion}
              onClickNeedOpinion={this.handleClickNeedOpinion}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
            />
            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                type="cong-van-di"
                page={this.state.page}
                headers={headers.OpinionList}
                itemShape={ListItem}
                data={this.props.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onClickDetail={this.handleClickDetail}
                match={this.props.match}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                auth={this.props.auth}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                tinhTrang={this.state.listStatus}
                phongBanLapId={this.state.departments}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                isLoadingXoaYKien={this.state.isLoadingXoaYKien}
              />
              {!this.state.isEmpty &&
                !this.state.isError &&
                !this.state.isLoading && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.list.total / Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
          <Toaster
            className="message-popup-delete"
            position={Position.TOP_RIGHT}
            ref={this.refHandlers.toaster}
          />
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
  list: state.opinions.list,
  auth: state.auth,
  staffs: state.common.staffs,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(OpinionList)
