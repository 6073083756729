import { get } from 'lodash'

import {
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
  API_REQUEST_ERROR,
} from '../constants/ActionTypes'
import { API_URL } from '../constants/Api'
import { store } from '../index'

const taskRequest = ({
  type: reduxSuccessType = API_REQUEST_SUCCESS,
  baseURL = API_URL,
  client,
  endpoint,
  method = 'GET',
  params = {},
  data,
  ...otherOptions
}) => {
  const { dispatch, getState } = store
  const token = get(getState(), 'auth.token', {})
  return dispatch({
    types: [API_REQUEST_SEND, reduxSuccessType, API_REQUEST_ERROR],
    payload: {
      client,
      request: {
        ...otherOptions,
        baseURL,
        url: endpoint,
        method,
        headers: {
          Authorization: `${token.tokenType} ${token.accessToken}`,
        },
        params,
        data,
      },
    },
  })
}

// TTL - TIM kiem Cong Van
export const getDsCongVan = params => {
  return taskRequest({
    endpoint: '/api/hosocongviec/tailieu',
    params,
  })
}

export const getThuMucPath = hoSoId => {
  return taskRequest({
    endpoint: `/api/hosocongviec/${hoSoId}/duongdan`,
  })
}
