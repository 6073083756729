import {
  API_REQUEST_ERROR,
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
} from '../constants/ActionTypes'

export const fetchReadDepartment = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/phongban`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: {
          page: 1,
          limit: 0,
          isAll: false,
        },
      },
    },
  })
