import moment from 'moment'

export const documentType = [
  {
    label: 'Văn bản đến',
    value: 'vbde',
  },
  {
    label: 'Văn bản đi',
    value: 'vbdi',
  },
  {
    label: 'Văn bản nội bộ',
    value: 'vbnb',
  },
]

export const yearsOptions = () => {
  const years = []
  const minYear = 2023
  const currentyear = moment().year()
  for (let i = minYear; i <= currentyear; i++) {
    years.push({
      value: i,
      label: i,
    })
  }
  return years.reverse()
}
