export const TEXT_BUTTON_SUBMIT = 'Có'
export const TEXT_BUTTON_CANCEL = 'Không'
export const TEXT_BUTTOM_CLOSE = 'Đóng'
export const TEXT_BUTTOM_ADD = 'Thêm'
export const TEXT_BUTTOM_EDIT = 'Lưu'
export const TEXT_BODY = 'Anh/chị có muốn hủy thao tác này?'
export const TEXT_SUBMIT_BODY = 'Anh/chị có muốn thực hiện thao tác này?'
export const TEXT_SUBMIT_BODY_ALERTMISTAKE_DOCUMENT =
  'Lưu ý thực hiện thao tác này sẽ chỉ đạo lại từ đầu'
export const TEXT_SUBMIT_BODY_ALERTMISTAKE_WORK =
  'Lưu ý thực hiện thao tác này sẽ giao việc lại từ đầu'
export const TEXT_GUI_YEU_CAU_THU_HOI =
  'Anh/Chị có chắc chắn yêu cầu Văn thư thu hồi văn bản này không?'
export const TEXT_TITLE_GUI_YEU_CAU_THU_HOI = 'Yêu cầu thu hồi văn bản'

// Title message
// Cong van den
export const HUY_TAO_CONG_VAN_DEN = 'Hủy tạo công văn đến'
export const HUY_SUA_CONG_VAN_DEN = 'Hủy sửa công văn đến'
export const XOA_CONG_VAN_DEN = 'Xóa công văn đến'

export const HUY_GUI_CHI_DAO = 'Hủy gửi chỉ đạo'
export const HUY_GUI_DE_XUAT_CHI_DAO = 'Hủy gửi đề xuất chỉ đạo'
export const HUY_SUA_DE_XUAT_CHI_DAO = 'Hủy sửa đề xuất chỉ đạo'
export const HUY_LUU_TU_TAI_LIEU = 'Hủy lưu tủ tài liệu'
export const HUY_GUI_EMAIL = 'Hủy gửi mail'
export const HUY_CHI_DAO = 'Hủy chỉ đạo'
export const HUY_CHUYEN_CHI_DAO = 'Hủy chuyển chỉ đạo'
export const HUY_GIAO_LAI = 'Chỉ đạo lại'
export const HUY_BAO_NHAM_CONFIRM = 'Anh/chị có muốn hủy báo nhầm?'

// Cong viec
export const HUY_GUI_GIAO_VIEC = 'Hủy gửi giao việc'
export const HUY_BAO_CAO = 'Hủy báo cáo'
export const HUY_GIAO_VIEC = 'Hủy giao việc'
export const HUY_TAO_CONG_VAN_DI_KY_SO = 'Hủy tạo công văn đi ký số'
export const HUY_UY_QUYEN = 'Hủy ủy quyền'
export const BAO_NHAM_CAP_TREN = 'Báo nhầm cấp trên'
export const HUY_GIAO_VIEC_LAI = 'Giao việc lại'
export const COMFIRM_HUY_BAO_CAO = 'Anh/chị có muốn hủy báo cáo?'

// Ky so
export const XOA_KY_SO_CONG_VAN_DI = 'XOA_KY_SO_CONG_VAN_DI'
export const XOA_VAN_BAN_KY_SO = 'Xóa văn bản ký số'
export const HUY_GUI_GOP_Y = 'Hủy gửi góp ý'
export const HUY_SUA_GOP_Y = 'Hủy sửa góp ý'
export const HUY_GUI_CAP_SO = 'Hủy gửi cấp số'
export const CANH_BAO_XOA_VAN_BAN_KY_SO =
  'Lãnh đạo Phòng ban đã ký duyệt văn bản này. Anh/Chị có muốn xóa văn bản này không?'
export const XAC_NHAN_SO_VAN_BAN_TRUNG =
  'Số văn bản này đã được cấp. Anh/chị có muốn cấp lại số văn bản này không?'
export const KY_SO = 'Ký số'
export const CAP_SO = 'Cấp số'
export const XAC_NHAN_THAY_DOI_DU_AN = 'Anh/chị có muốn thay đổi dự án?'
export const CAP_NHAT_DU_AN = 'Thay đổi dự án'

// Cong van di
export const XOA_CONG_VAN_DI = 'Xóa công văn đi'
export const HUY_TAO_CONG_VAN_DI = 'Hủy tạo công văn đi'
export const HUY_SUA_CONG_VAN_DI = 'Hủy sửa công văn đi'
export const HUY_GUI_BO_SUNG = 'Hủy gửi bổ sung'
export const THU_HOI_CONG_VAN = 'Thu hồi công văn'
export const GUI_LAI = 'Gửi lại'
export const XEM_THUC_HIEN = 'Xem thực hiện'
export const XAC_NHAN_SO_CONG_VAN_TRUNG =
  'Số công văn này đã tồn tại. Anh/Chị có muốn nhập công văn với số này không?'
export const TAO_CONG_VAN_DI = 'Tạo công văn đi'
export const SUA_CONG_VAN_DI = 'Sửa công văn đi'
export const GUI_LAI_EVN = 'Gửi lại EVN'
export const XAC_NHAN_GUI_LAI_EVN =
  'Gửi EVN không thành công. Anh/Chị có muốn gửi lại không?'
export const THU_HOI_VAN_BAN = 'Thu hồi văn bản'
export const THU_HOI_VAN_BAN_CONG_VAN = 'Thu hồi văn bản công văn đi'
export const HUY_DE_XUAT_THU_HOI = 'Hủy đề xuất thu hồi'
export const DE_XUAT_THU_HOI = 'Đề xuất thu hồi'
export const LY_DO_THU_HOI = 'Lý do thu hồi'
export const COMFIRM_DONG_Y_THU_HOI_CONG_VAN =
  'Anh/Chị có đồng ý thu hồi công văn này?'
export const THONG_BAO = 'Thông báo'
export const THONG_BAO_CONG_VAN_DEN_THU_HOI =
  'Công văn này đã được thu hồi. Anh/Chị không thể xem chi tiết của công văn.'

// Cong van noi bo
export const XOA_CONG_VAN_NOI_BO = 'Thu hồi văn bản công văn nội bộ'
export const HUY_TAO_CONG_VAN_NOI_BO = 'Hủy tạo công văn nội bộ'
export const HUY_SUA_CONG_VAN_NOI_BO = 'Hủy sửa công văn nội bộ'

// Khac
export const THEM_MOI_NGAN_TU = 'Thêm mới ngăn tủ'
export const CHINH_SUA_NGAN_TU = 'Chỉnh sửa ngăn tủ'

// He thong
export const XOA_VAI_TRO = 'Xóa vai trò người dùng'
export const XOA_NGUOI_DUNG = 'Xóa người dùng'
export const HUY_TAO_NGUOI_DUNG = 'Hủy tạo người dùng'
export const HUY_SUA_NGUOI_DUNG = 'Hủy sửa người dùng'
export const XOA_DANH_MUC = 'Xóa danh mục'
export const HUY_TAO_SO_LUU = 'Hủy tạo số lưu'
export const HUY_SUA_SO_LUU = 'Hủy sửa số lưu'
export const HUY_TAO_DO_BAO_MAT = 'Hủy tạo độ bảo mật'
export const HUY_SUA_DO_BAO_MAT = 'Hủy sửa độ bảo mật'
export const HUY_TAO_LOAI_DOI_TUONG = 'Hủy tạo loại đối tượng'
export const HUY_SUA_LOAI_DOI_TUONG = 'Hủy sửa loại đối tượng'
export const HUY_TAO_DO_KHAN = 'Hủy tạo độ khẩn'
export const HUY_SUA_DO_KHAN = 'Hủy sửa độ khẩn'
export const HUY_TAO_CHUC_VU = 'Hủy tạo chức vụ'
export const HUY_SUA_CHUC_VU = 'Hủy sửa chức vụ'
export const HUY_TAO_DON_VI = 'Hủy tạo đơn vị'
export const HUY_SUA_DON_VI = 'Hủy sửa đơn vị'
export const HUY_TAO_PHONG_BAN = 'Hủy tạo phòng ban'
export const HUY_SUA_PHONG_BAN = 'Hủy sửa phòng ban'
export const HUY_VAI_TRO = 'Hủy tạo vai trò'
export const HUY_SUA_VAI_TRO = 'Hủy sửa vai trò'
export const XOA_DU_AN = 'Xóa quy trình'
export const XOA_DON_VI = 'Xóa đơn vị'
//Chuyen nham
export const HUY_CHUYEN_NHAM = 'Huỷ báo nhầm'
// Thong bao
export const XOA_THONG_BAO = 'Xóa thông báo'
export const XOA_TAI_LIEU = 'Xóa tài liệu'
export const XOA_THUMUC_OR_HOSOCONGVIEC = 'Xóa thư mục/hồ sơ công việc'
// Van ban so hoa
export const XOA_VAN_BAN_SO_HOA = 'Xoá văn bản số hoá'

//phieu yeu cau gop y
export const XOA_PHIEU_YEU_CAU_GOP_Y = 'Xóa phiếu yêu cầu góp ý'
export const SUA_PHIEU_YEU_CAU_GOP_Y = 'Sửa phiếu yêu cầu góp ý'
export const HUY_XAC_NHAN_YEU_CAU_GOP_Y = 'Hủy xác nhận phiếu yêu cầu góp ý'
export const COMFIRM_HUY_YEU_CAU_GOP_Y =
  'Anh/chị có muốn hủy xác nhận phiếu yêu cầu góp ý?'

export const HOAN_THANH_YEU_CAU_GOP_Y = 'Hoàn thành phiếu yêu cầu góp ý'
export const COMFIRM_HOAN_THANH_CAU_GOP_Y =
  'Anh/chị có muốn hoàn thành phiếu yêu cầu góp ý?'

export const HUY_XAC_NHAN_GOP_Y = 'Hủy xác nhận phiếu góp ý'
export const COMFIRM_HUY_GOP_Y = 'Anh/chị có muốn hủy xác nhận phiếu góp ý?'

export const HUY_CHI_DAO_GOP_Y = 'Hủy chỉ đạo góp ý'
export const COMFIRM_HUY_CHI_DAO_GOP_Y = 'Anh/chị có muốn hủy chỉ đạo góp ý?'

export const HUY_GIAO_GOP_Y = 'Hủy giao góp ý'
export const COMFIRM_HUY_GIAO_GOP_Y = 'Anh/chị có muốn hủy giao góp ý?'

export const HUY_GOP_Y = 'Hủy góp ý'
export const COMFIRM_HUY_GOP_Y_THUC_HIEN = 'Anh/chị có muốn hủy góp ý?'

export const XAC_NHAN_PHIEU_GOI_YEU_CAU_GOP_Y =
  'Xác nhận phiếu gửi yêu cầu góp ý'
export const XAC_NHAN_PHIEU_GOP_Y = 'Xác nhận phiếu góp ý'
export const COMFIRM_DONG_Y_XAC_NHAN = 'Anh/chị có muốn xác nhận đồng ý?'
export const COMFIRM_DONG_KHONG_DONG_Y_XAC_NHAN =
  'Anh/chị có muốn xác nhận không đồng ý?'

export const SUA_GOP_Y = 'Sửa góp ý'
export const COMFIRM_SUA_GOP_Y =
  'Phiếu yêu cầu góp ý này đã được duyệt, Anh/Chị có đồng ý lưu và hủy mọi trạng thái duyệt trước đó không ?'

// Phieu y kien
export const XOA_PHIEU_Y_KIEN_PAGE = 'XOA_PHIEU_Y_KIEN'
export const XOA_PHIEU_Y_KIEN = 'Xóa phiếu ý kiến'
export const CANH_BAO_XOA_PHIEU_Y_KIEN =
  'Anh/chị có muốn thực hiện thao tác này?'
// Service
export const XOA_SERVICE = 'Xóa service'

export const XOA_THOIGIANLUU = 'Xóa thời hạn bảo quản'

// Quản lý công việc
export const DELETE_TAG_TITLE = 'Xóa loại công việc'
export const DELETE_TAG_CONTENT =
  'Anh/Chị chắc chắn muốn xóa loại công việc này?'
export const DELETE_FAVORITE_GROUP_TITLE = 'Xóa nhóm thường giao việc'
export const DELETE_FAVORITE_GROUP_CONTENT =
  'Anh/Chị chắc chắn muốn xóa nhóm thường giao việc này?'
export const DELETE_BANNER_ARTICLE = 'Xóa banner / bài viết'
export const DELETE_TODO_TITLE = 'Xóa bước thực hiện'
export const DELETE_TODO = 'Anh/Chị chắc chắn muốn xóa bước thực hiện này?'
export const DELETE_TEMPLATE_TITLE = 'Xóa mẫu'
export const DELETE_TEMPLATE = 'Anh/Chị chắc chắn muốn xóa mẫu này?'
export const DELETE_MEETING_TITLE = 'Xóa thông báo kết luận cuộc họp'
export const DELETE_MEETING_CONTENT =
  'Anh/Chị chắc chắn muốn xóa thông báo kết luận cuộc họp này?'
export const APPROVE_SECRETARY_TITLE = 'Duyệt công việc'
export const NOT_APPROVE_TITLE = 'Hủy duyệt công việc'
export const APPROVE_SECRETARY_CONTENT =
  'Anh/Chị có muốn ký duyệt công việc này không?'
export const NOT_APPROVE_CONTENT =
  'Anh/Chị có muốn hủy duyệt công việc này không?'
export const DELETE_WORK_MEETING_TITLE = 'Xóa kết luận'
export const DELETE_WORK_MEETING_CONTENT = 'Anh/Chị muốn xóa kết luận này?'
// Lịch họp
export const XOA_LICH_HOP = 'Xóa lịch họp'
export const HUY_LICH_HOP = 'Hủy lịch họp'
export const KET_THUC_LICH_HOP = 'Kết thúc lịch họp'
