import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilterAssigned extends Component {
  constructor(props) {
    super(props)

    this.handleDateChange = this.handleDateChange.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  static propTypes = {
    status: PropTypes.array.isRequired,
    staffs: PropTypes.array.isRequired,
  }

  state = {
    filter: {
      textSearch: '',
      ngayGiaoViec: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
    },
  }

  componentWillReceiveProps(props) {
    const { filter } = props

    if (filter !== this.props.filter) {
      const { ngayGiaoViec } = filter
      this.setState({
        filter: {
          textSearch: filter.textSearch || '',
          ngayGiaoViec: {
            from:
              ngayGiaoViec &&
              ngayGiaoViec.from &&
              ngayGiaoViec.from.trim().length > 0
                ? moment(ngayGiaoViec.from)
                : undefined,
            to:
              ngayGiaoViec &&
              ngayGiaoViec.to &&
              ngayGiaoViec.to.trim().length > 0
                ? moment(ngayGiaoViec.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter(e) {
    const filter = {
      textSearch: '',
      ngayGiaoViec: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
    }
    this.setState(
      {
        filter,
      },
      () => {
        const filter = {
          ...this.state.filter,
          ngayGiaoViec: {
            from: this.state.filter.ngayGiaoViec.from
              ? moment(this.state.filter.ngayGiaoViec.from).format('YYYY-MM-DD')
              : undefined,
            to: this.state.filter.ngayGiaoViec.to
              ? moment(this.state.filter.ngayGiaoViec.to).format('YYYY-MM-DD')
              : undefined,
          },
        }
        this.props.clearFilterColumn &&
          this.props.clearFilterColumn(filter, true)
        this.props.actions.getNumberWorkPage()
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? e : undefined,
          },
        },
      },
      () => {
        this.props.handleDateChange(name, pos, e)
      }
    )
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayGiaoViec: {
        from: this.state.filter.ngayGiaoViec.from
          ? moment(this.state.filter.ngayGiaoViec.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayGiaoViec.to
          ? moment(this.state.filter.ngayGiaoViec.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.clearFilterColumn && this.props.clearFilterColumn(filter, false)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch py-12 px-sm-15"
      >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div>
              <label className="search-label">Thời gian (Ngày giao việc)</label>
              <div className="mt5 form-input-filter date-picker-inline border-radius-3 ">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayGiaoViec.from}
                  selectsStart
                  startDate={this.state.filter.ngayGiaoViec.from}
                  endDate={this.state.filter.ngayGiaoViec.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayGiaoViec',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayGiaoViec.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayGiaoViec.to}
                  selectsEnd
                  startDate={this.state.filter.ngayGiaoViec.from}
                  endDate={this.state.filter.ngayGiaoViec.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayGiaoViec',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayGiaoViec.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`col-xs-12 mt20 mt-md-0 align-items-end col-md-8`}>
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                onChange={this.handleInputChange}
                placeholder="Tìm kiếm Số CV, Trích yếu (hoặc Số CV:Trích yếu)"
                value={this.state.filter.textSearch}
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilterAssigned
