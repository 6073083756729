import React, { useRef, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FOLDER_NAME } from 'constants/Api'
import HeaderMenu from './header-v2-menu'
import HeaderUserInfo from './header-v2-user-info'
import Notifications from 'components/layout/components/notifications'
import { authLogout } from 'actions/index'

const Header = ({ actions, history, menu, onToggleMobileMenu }) => {
  const toggleRef = useRef(null)
  const buttonRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const onLogout = () => {
    let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
    let user_login = localStorage.getItem('user_login')
    localStorage.clear()
    localStorage.setItem('user_login', user_login)
    localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
    sessionStorage.clear()
    actions.authLogout()
  }

  const onToggle = () => {
    setIsOpen(prev => !prev)
  }

  const handleClickOutside = event => {
    if (
      toggleRef.current &&
      buttonRef.current &&
      !toggleRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('resize', handleResize)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <header className="header-v2">
      <div className="header-v2-wrapper">
        <button
          ref={buttonRef}
          className={classNames('header-v2-menu-mobile-toggle', {
            open: isOpen,
          })}
          onClick={onToggleMobileMenu}
        >
          <span className="icon2-arrow-left"></span>
        </button>
        <div className="logo">
          <img
            src={`/config/${FOLDER_NAME}/images/logo.svg?cache=1`}
            alt="logo"
          />
        </div>
        <button
          ref={buttonRef}
          className={classNames('header-v2-mobile-toggle', {
            open: isOpen,
          })}
          onClick={onToggle}
        >
          <span className="icon2-menu"></span>
        </button>
        {/* Hiển thị thông báo trên mobile */}
        {windowWidth <= 1200 && <Notifications history={history} />}
        <div
          ref={toggleRef}
          className={classNames('header-v2-menu-wrapper', {
            open: isOpen,
          })}
        >
          <HeaderMenu history={history} dataSource={menu} />
          {/* Hiển thị thông báo trên desktop */}
          {windowWidth > 1200 && <Notifications history={history} />}
          <HeaderUserInfo history={history} onLogout={onLogout} />
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  history: null,
  menu: [],
  onToggleMobileMenu: () => {},
}

Header.propTypes = {
  history: PropTypes.object,
  menu: PropTypes.array,
  onToggleMobileMenu: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ authLogout }, dispatch),
})

export default connect(null, mapDispatchToProps)(Header)
