import React, { memo, useEffect, useRef, useState } from 'react'
import { Classes, Button, Intent, Radio, RadioGroup } from '@blueprintjs/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { get, find } from 'lodash'
import csx from 'classnames'

import { Dialog, Loading, Empty } from 'components/newCommon'

const TEMPLATE_TITLE = 'Chọn từ mẫu có sẵn'

const Templates = ({ isOpen, onClose, onConfirm }) => {
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [templates] = useState([])
  const [selected, setSelected] = useState()
  const [setDeleteId] = useState()

  const [fetching] = useState(false)
  const [hasNextPage] = useState(true)
  const [isEmpty] = useState(false)
  const [firstLoading] = useState(true)

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: fetching,
    hasNextPage: isOpen && hasNextPage,
  })

  const _onConfirm = () => {
    const template = find(templates, { id: selected })
    onConfirm(template)
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={TEMPLATE_TITLE}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={_onConfirm}
        width={600}
        contentMaxHeight={300}
        cancelLeftIcon={<i className="icon-back mr5" />}
        cancelText="Đóng"
        confirmDisabled={!selected}
        confirmRightIcon={<i className="icon-save ml5" />}
        confirmText="Lưu"
      >
        <div ref={rootRef}>
          <RadioGroup
            onChange={({ currentTarget: { value } }) =>
              _mountedSet(setSelected, value)
            }
            selectedValue={selected}
            className="cpc-radio-group"
          >
            {firstLoading && <Loading />}
            {isEmpty && <Empty />}

            {templates.map(({ id, title }) => (
              <Radio className="cpc-radio" value={id} key={id}>
                <div className="d-flex align-item-center w-100">
                  <span className="flex-auto font-size-13">{title}</span>
                  <Button
                    className={csx(Classes.MINIMAL, 'cpc-button')}
                    intent={Intent.DANGER}
                    iconName="trash"
                    onClick={e => {
                      e.stopPropagation()
                      _mountedSet(setDeleteId, id)
                    }}
                  />
                </div>
              </Radio>
            ))}

            {/* important */}
            {hasNextPage && <span ref={sentryRef} />}
          </RadioGroup>
        </div>
      </Dialog>
    </>
  )
}

export default memo(Templates)
