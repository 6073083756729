import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { MasterLayout } from '../../components/layout'
import {
  ListFooter,
  ListItem,
} from '../../components/ListGerenal/GerenalDocument'
import { GridView } from '../../components/common'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import HeadingPanel from 'components/common/HeadingPanel'

class ListGerenalDocumentOverTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phongBanId: this.props.match.params.id,
      ngayChiDao: {
        from: '',
        to: '',
      },
      trangThaiThucHien: false,
      isEmpty: false,
      isLoading: false,
      page: 1,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
  }

  static propTypes = {
    departmentWorks: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let state = this.state

    state.phongBanId = this.props.match.params.id

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.ngayChiDao
    ) {
      state.ngayChiDao = {
        from: this.props.history.location.ngayChiDao.from,
        to: this.props.history.location.ngayChiDao.to,
      }
      state.trangThaiThucHien = this.props.history.location.type
    } else {
      state.ngayChiDao = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }
    this.setState(state, () => {
      this.clickPagination({ selected: 0 })
    })
  }

  clickPagination(e) {
    let state = {
      page: e.selected + 1,
    }

    this.setState(state, () => {
      this.submitFilter(this.state.page, this.state.sort)
    })
  }

  submitFilter(page = 1, sort) {
    this.setState({ page, isLoading: true }, () => {
      this.props.actions
        .getGerenalDocumentOverTime(
          {
            phongBanId: this.state.phongBanId,
            ngayChiDao: this.state.ngayChiDao,
            trangThaiThucHien: this.state.trangThaiThucHien,
          },
          page,
          sort
        )
        .then(res => {
          if (!res.error && res.payload.data.result.result.total === 0) {
            return this.setState({ isEmpty: true })
          }

          this.setState({ isEmpty: false })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.page, sort)
  }

  handleClickGoBack = () => {
    this.props.history.goBack()
    Tool.Utils.saveData('isLoadPage', true)
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div>
          <HeadingPanel
            className="mb10"
            goBack={this.handleClickGoBack}
            leftTitle="Tổng hợp công văn quá hạn"
            leftIcon="icon-Chua_Giao"
          />
          {/* Table List */}
          <div className="page-list-container">
            <div className="table-container">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table'}
                page={this.state.page ? this.state.page : 1}
                headers={headers.listGerenalDocument}
                itemShape={ListItem}
                data={this.props.departmentWorks.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onHandleClickItem={this.HandleClickItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
              />
              {!this.state.isLoading && (
                <ListFooter
                  forcePage={this.state.page - 1}
                  pageCount={Math.ceil(
                    this.props.departmentWorks.list.total /
                      Types.PAGINATION_LIMIT
                  )}
                  onPageClick={this.clickPagination}
                  isEmpty={this.state.isEmpty}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  typeSidebar: state.common.typeSidebar,

  departmentWorks: {
    ...state.departmentWorks,
    list: {
      ...state.departmentWorks.list,
      items: state.departmentWorks.list.items.map(
        id => state.entities.departmentWorks[id]
      ),
    },
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListGerenalDocumentOverTime)
