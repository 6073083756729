import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ListFilter, ListItem } from '../../components/listContact'
import * as Actions from '../../actions'
import { Utils } from '../../helpers'

class ListContact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isEmpty: false,
      isLoading: true,
      users: [],
      donViId: this.props.auth.mainUnitId,
      filter: {
        isLanhDao: false,
      },
      phongBanId: this.props.auth.mainDepartmentId,
      sort: undefined,
      isDepartment: false,
      isUnit: false,
      isSort: false,
      isValue: false,
      checkDepartment: false,
      isError: false,
    }

    this.handleChangeUnit = this.handleChangeUnit.bind(this)
    this.handleClickDepartment = this.handleClickDepartment.bind(this)
    this.disableLoading = this.disableLoading.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  componentDidMount() {
    let donViId = Utils.getData('Contact-Unit', {})
    let phongBanId = Utils.getData('Contact-Department', {})

    if (donViId === undefined) {
      donViId = this.state.donViId
    }

    if (phongBanId === undefined) {
      phongBanId = this.state.phongBanId
    }

    this.setState({ donViId, phongBanId }, () => {
      this.props.actions.getCategoryUnitMultiLevel()

      this.props.actions.getCategoryDepartmentMultiLevel(donViId).then(res => {
        if (
          res.payload &&
          res.payload.data.result &&
          res.payload.data.result.length === 0
        ) {
          this.setState({ checkDepartment: true })
        }

        if (
          res.payload &&
          res.payload.data &&
          res.payload.data.result.length > 0
        ) {
          this.props.actions
            .getListContact(phongBanId, this.state.filter, this.state.sort)
            .then(resList => {
              if (
                resList.error ||
                (resList.payload && resList.payload.status !== 200)
              ) {
                this.setState({ isError: true })
              }
            })
        }

        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true })
        }
        this.disableLoading()
      })
    })
  }

  componentWillReceiveProps(props) {
    if (props.users.items !== this.props.users.items) {
      this.setState(
        {
          users: props.users.items,
        },
        () => {
          this.disableLoading()
        }
      )
    }
  }

  componentDidUpdate(prevState, prevProps) {
    if (
      prevState.donViId !== this.state.donViId &&
      this.state.isUnit === true
    ) {
      this.setState({ isUnit: false })
      return this.props.actions
        .getCategoryDepartmentMultiLevel(this.state.donViId)
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result.length === 0
          ) {
            this.setState({ checkDepartment: true })
          }

          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result.length > 0
          ) {
            let phongBanId = Utils.getData('Contact-Department', {})
            let check =
              res.payload.data.result.find(
                item => phongBanId === item.phongBanId
              ) !== undefined
            Utils.saveData(
              'Contact-Department',
              check ? phongBanId : res.payload.data.result[0].phongBanId
            )
            this.props.actions
              .getListContact(
                res.payload.data.result[0].phongBanId,
                this.state.filter,
                this.state.sort
              )
              .then(resList => {
                if (
                  resList.error ||
                  (resList.payload && resList.payload.status !== 200)
                ) {
                  this.setState({ isError: true })
                }
              })
            this.setState({
              phongBanId: check
                ? phongBanId
                : res.payload.data.result[0].phongBanId,
              checkDepartment: false,
            })
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true })
          }
          this.disableLoading()
        })
    }

    if (
      prevState.phongBanId !== this.state.phongBanId &&
      this.state.isDepartment === true
    ) {
      this.setState({ isDepartment: false })
      return this.props.actions
        .getListContact(
          this.state.phongBanId,
          this.state.filter,
          this.state.sort
        )
        .then(resList => {
          if (
            resList.error ||
            (resList.payload && resList.payload.status !== 200)
          ) {
            this.setState({ isError: true })
          }
        })
        .then(() => {
          this.disableLoading()
        })
    }

    if (prevState.filter !== this.state.filter && this.state.isValue === true) {
      this.setState({ isValue: false })
      return this.props.actions
        .getListContact(
          this.state.phongBanId,
          this.state.filter,
          this.state.sort
        )
        .then(resList => {
          if (
            resList.error ||
            (resList.payload && resList.payload.status !== 200)
          ) {
            this.setState({ isError: true })
          }
        })
        .then(() => {
          this.disableLoading()
        })
    }

    if (prevState.sort !== this.state.sort && this.state.isSort === true) {
      this.setState({ isSort: false })
      return this.props.actions
        .getListContact(
          this.state.phongBanId,
          this.state.filter,
          this.state.sort
        )
        .then(resList => {
          if (
            resList.error ||
            (resList.payload && resList.payload.status !== 200)
          ) {
            this.setState({ isError: true })
          }
        })
        .then(() => {
          this.disableLoading()
        })
    }
  }

  handleChangeUnit(e) {
    Utils.saveData('Contact-Unit', e)
    this.setState(
      {
        donViId: e,
        users: [],
        isUnit: true,
        isLoading: true,
        sort: undefined,
      },
      () => {
        this.props.actions.clearUsers(e)
      }
    )
  }

  handleClickDepartment(id) {
    Utils.saveData('Contact-Department', id)
    this.setState({
      phongBanId: id,
      users: [],
      isLoading: true,
      isDepartment: true,
      sort: undefined,
    })
  }

  handleRadio(value) {
    this.setState({
      users: [],
      isLoading: true,
      sort: undefined,
      filter: {
        isLanhDao: value,
      },
    })

    if (this.state.checkDepartment) {
      this.setState({ isUnit: true })
    } else {
      this.setState({ isValue: true })
    }
  }

  handleSort(sort) {
    this.setState({
      users: [],
      isSort: true,
      isLoading: true,
      sort: sort,
    })
  }

  disableLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 500)
  }

  render() {
    return (
      <MasterLayout typeSidebar="utilities">
        <div className="listDecentralizationUser-container listContact-container">
          <HeadingPage namePage="Danh bạ" iconPage="icon-Danh_Ba"></HeadingPage>
          <div className="page-list-container page-list-contact-container">
            <ListFilter
              units={this.props.unit.items}
              onChange={this.handleChangeUnit}
              defaultValue={this.state.donViId}
              isLanhDao={this.state.filter.isLanhDao}
              onChangeRadio={this.handleRadio}
            />
            <ListItem
              onClickDepartment={this.handleClickDepartment}
              departments={this.props.departments.items}
              users={this.state.users}
              isLoading={this.state.isLoading}
              isEmpty={this.state.users.length === 0}
              onSubmit={this.handleSubmitRemoveItem}
              auth={this.props.auth}
              DepartmentId={this.state.phongBanId}
              onSort={this.handleSort}
              isLanhDao={this.state.filter.isLanhDao}
              isError={this.state.isError}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  users: {
    ...state.contact,
    items: state.contact.listContact,
  },

  departments: {
    ...state.category,
    items: state.category.categoryDepartmentMultiLevel,
  },

  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListContact)
