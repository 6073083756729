import { schema } from 'normalizr'

const ExecutingViewInternalDocument = new schema.Entity(
  'executingViews',
  {},
  {
    idAttribute: executingViews => executingViews.congViecPhongBanId,
  }
)

export default ExecutingViewInternalDocument
