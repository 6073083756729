import React, {
  Fragment,
  memo,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { Intent } from '@blueprintjs/core'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { getDsCongViecKanban, showToast } from 'actions/task'
import { KanbanList } from './_components/KanbanList'
import { Filter } from '../Filter'
import { TaskContext } from '../AllTask'
import { get } from 'lodash'
import requestAction from 'helpers/request'
import {
  sortWorkItem,
  patchTienDoKanban,
  getThongKeTrangThaiCongViec,
} from 'actions/task'
import {
  Action,
  Toast,
  DROP_DRAP_KANBAN_FAILED,
  TEXT_HOANTHANH_CONGVIEC,
} from 'constants/MessageForm'
import {
  ASSIGN_TYPE,
  STATISTIC_WORK_RESPONSIBILITY,
  WORK_STATUS,
} from 'constants/Enum'
import { reorder, move } from 'helpers/dragdrop'

const actionEnum = {
  chiTiet: 'chi-tiet',
}
const titles = {
  NEW: 'chưa thực hiện',
  DOING: 'đang thực hiện',
  DONE: 'đã thực hiện',
}

const classes = {
  NEW: 'open',
  DOING: 'progress',
  DONE: 'done',
}

const tienDoTheoTrangThai = {
  NEW: 0,
  DOING: 50,
  DONE: 100,
}

const Kanban = ({ match, history, currentUserId }) => {
  const { type: taskType, id: currentId, action: currentAction } = match.params
  const mainPathname = useMemo(
    () => `/quan-ly-cong-viec/${taskType}`,
    [taskType]
  )

  const {
    kanbanData = [],
    filter,
    reloadKanbanData = () => {},
    detail: currentTaskDetail,
    setDetail,
    kanbanAsRead,
  } = useContext(TaskContext)

  const goToAction = useCallback(
    (id, action, status) => {
      const url = `${mainPathname}/${action}/${id}`
      if (action === actionEnum.chiTiet) kanbanAsRead(id, status)
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== currentId)
        return history.replace(url)
    },
    [history, currentAction, mainPathname, currentId, kanbanAsRead]
  )

  const [data, setData] = useState([])

  // Cập nhật lại orderIndex của workItem sau khi sort hoặc cập nhật tiến độ thành công
  const updateAfterSortOrUpdateProgress = useCallback(
    workItem => {
      const tempData = [...data]
      const columnIndex = tempData.findIndex(
        item => item.status === workItem.status
      )

      if (columnIndex < 0) {
        return
      }

      const columnData = tempData[columnIndex]
      let columnItems = columnData.items

      const workItemIndex = columnItems.findIndex(
        item => item.id === workItem.id
      )

      if (workItemIndex < 0) {
        return
      }

      columnItems[workItemIndex].orderIndex = workItem.orderIndex
      columnData.items = columnItems
      tempData[columnIndex] = columnData

      setData(tempData)
    },
    [data, setData]
  )

  const handleUpdateProgress = useCallback(
    (id, data) => {
      requestAction({
        action: () => patchTienDoKanban(id, data),
        afterResponse: response => {
          // Get thống kê trạng thái công việc
          getThongKeTrangThaiCongViec({
            responsibilityType: STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME,
          })
          updateAfterSortOrUpdateProgress(response)

          if (id === currentTaskDetail.id) {
            const { status, progress } = data
            setDetail(prevDetail => ({ ...prevDetail, status, progress }))
          }
        },
        afterError: () => {
          reloadKanbanData()
        },
        errorMessage: Toast.FAIL(Action.UPDATE),
        successMessage:
          data.status === WORK_STATUS.HOAN_THANH
            ? TEXT_HOANTHANH_CONGVIEC
            : Toast.SUCCESS(Action.UPDATE),
      })
    },
    [
      reloadKanbanData,
      updateAfterSortOrUpdateProgress,
      setDetail,
      currentTaskDetail.id,
    ]
  )

  const handleSortWotkItem = useCallback(
    (id, data) => {
      requestAction({
        action: () => sortWorkItem(id, data),
        afterResponse: response => {
          updateAfterSortOrUpdateProgress(response)
        },
        afterError: () => {
          reloadKanbanData()
        },
        errorMessage: Toast.FAIL(Action.SORT),
      })
    },
    [reloadKanbanData, updateAfterSortOrUpdateProgress]
  )

  const loadMore = (status, limit) => {
    requestAction({
      showToast: false,
      action: () => getDsCongViecKanban({ ...filter, status, limit }),
      afterResponse: response => {
        setData(prevData => {
          let newData = [...prevData]
          const findIndex = prevData.findIndex(item => item.status === status)
          if (findIndex > -1) {
            newData[findIndex] = response[0]
          }
          return newData
        })
      },
    })
  }

  const onDragEnd = result => {
    const { source, destination, draggableId } = result

    if (!destination) {
      return
    }

    if (result.type === 'COLUMN') {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const tempData = [...data]
      const index = tempData.findIndex(
        item => item.status === source.droppableId
      )

      if (index < 0) {
        return
      }

      const columnData = tempData[index]
      let columnItems = columnData.items

      columnItems = reorder(columnItems, source.index, destination.index)

      // Get dữ liệu để sắp xếp
      const afterItem = columnItems[destination.index - 1]
      const beforeItem = columnItems[destination.index + 1]
      const sortData = {
        after_order_index: afterItem ? afterItem.orderIndex : null,
        before_order_index: beforeItem ? beforeItem.orderIndex : null,
      }

      handleSortWotkItem(draggableId, sortData)

      columnData.items = columnItems
      tempData[index] = columnData

      return setData(tempData)
    }

    const tempData = [...data]
    const sourceColumnIndex = tempData.findIndex(
      item => item.status === source.droppableId
    )
    const destColumnIndex = tempData.findIndex(
      item => item.status === destination.droppableId
    )

    if (sourceColumnIndex < 0 || destColumnIndex < 0) {
      return
    }

    const sourceColumnData = tempData[sourceColumnIndex]
    let sourceColumnItems = sourceColumnData.items
    const destColumnData = tempData[destColumnIndex]
    let destColumnItems = destColumnData.items

    // Kiểm tra xem người dùng có quyền kéo thả thay đổi trạng thái
    // (Chỉ được kéo thay đổi trạng thái nếu công việc đó giao cho chính mình)
    const currentItem = sourceColumnItems[source.index]
    if (
      !currentItem?.mainResponsibility ||
      currentItem?.mainResponsibility?.type !== ASSIGN_TYPE.CA_NHAN ||
      currentItem?.mainResponsibility?.refId !== currentUserId
    ) {
      return showToast({
        message: DROP_DRAP_KANBAN_FAILED,
        intent: Intent.WARNING,
      })
    }

    const [sourceList, destList] = move(
      sourceColumnItems,
      destColumnItems,
      source,
      destination
    )

    // Get dữ liệu để sắp xếp
    const afterItem = destList[destination.index - 1]
    const beforeItem = destList[destination.index + 1]
    const sortData =
      afterItem || beforeItem
        ? {
            after_order_index: afterItem ? afterItem.orderIndex : null,
            before_order_index: beforeItem ? beforeItem.orderIndex : null,
          }
        : null

    // Cập nhật tiến độ cho công việc hiện tại
    currentItem.progress = tienDoTheoTrangThai[destination.droppableId]
    currentItem.status = destination.droppableId
    const updateProgressData = {
      status: currentItem.status,
      progress: currentItem.progress,
      sort_item: sortData,
    }

    // Gọi cập nhật tiến độ và sort
    handleUpdateProgress(draggableId, updateProgressData)

    // Cập nhật lại state
    sourceColumnData.items = sourceList
    tempData[sourceColumnIndex] = sourceColumnData

    destList[destination.index] = currentItem
    destColumnData.items = destList
    tempData[destColumnIndex] = destColumnData

    setData(tempData)
  }

  useEffect(() => {
    setData(kanbanData)
  }, [kanbanData])

  return (
    <Fragment>
      <Filter />
      <div className="lists-wrapper">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {provided => (
              <div
                className="cpc-kanban-wrapper"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {data?.map((item, index) => (
                  <KanbanList
                    onItemClick={goToAction}
                    list={item.items}
                    classTitle={classes[item.status]}
                    currentUserId={currentUserId}
                    title={titles[item.status]}
                    key={item.status}
                    listKey={item.status}
                    index={index}
                    total={item.totalCount}
                    loadFunc={loadMore}
                    pageTypes={taskType}
                  />
                ))}
                {provided.placeholder}
                {/* {provided.placeholder}
                <div className="cpc-kanban-column cpc-kanban-column-add">
                  {!toggle && <h3
                    aria-label={`quote list`}
                    className={csx(
                      'cpc-kanban-card',
                      'cpc-kanban-add',
                    )}
                    onClick={changeToggle}
                  >
                    <span className="icon-s-plus" />
                    <span className="title-name">Thêm cột mới</span>
                  </h3>}
                  {toggle && <div className="cpc-kanban-form">
                    <input placeholder="Nhập tiêu đề" className="input-form" />
                    <div className="cpc-kanban-footer">
                      <button className="button">
                        Thêm cột mới
                      </button>
                      <button className="button button-close" onClick={changeToggle}>
                        <span className="icon-Huy" />
                      </button>
                    </div>
                  </div>}
                </div> */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  currentUserId: get(state, 'auth.user.nhanVienId'),
})

export default withRouter(connect(mapStateToProps)(memo(Kanban)))
