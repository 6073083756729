import React from 'react'
import { SelectTree } from '../common/'
import { Radio, RadioGroup } from '@blueprintjs/core'

class ListFilter extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeRadio = this.handleChangeRadio.bind(this)
  }

  state = {
    filter: {
      donViId: this.props.defaultValue || '',
    },
    valueRadio: false,
    unitSelect: [],
    isLoading: true,
    isEmpty: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        filter: {
          donViId: nextProps.defaultValue || '',
        },
      })
    }

    if (nextProps.units !== this.props.units) {
      this.setState({ unitSelect: nextProps.units }, () => {
        this.setState({ isLoading: false })
      })
    }

    if (this.props.isLanhDao || !this.props.isLanhDao) {
      this.setState({
        valueRadio: this.props.isLanhDao,
      })
    }
  }

  handleChange(e) {
    if (e !== undefined) {
      this.props.onChange && this.props.onChange(e)
    }
  }

  handleChangeRadio(e) {
    if (e.target.type === 'radio') {
      this.props.onChangeRadio &&
        this.props.onChangeRadio(e.target.value === 'true' ? true : false)
    }
  }

  render() {
    return (
      <div className="search-container search-group-container">
        <div className="row seach-form">
          <RadioGroup
            selectedValue={this.state.valueRadio}
            onChange={this.handleChangeRadio}
            className="col-xs-12 col-sm-4 col-md-3 col-lg-3 search-header-left-content search-header-radio-group-content start-xs"
          >
            <Radio
              label="Tất cả"
              value={false}
              className="pt-large pt-inline name-radio radio-inline"
            />
            <Radio
              label="Lãnh đạo"
              value={true}
              className="pt-large pt-inline name-radio radio-inline"
            />
          </RadioGroup>
          <div className="col-xs-12 col-sm-8 col-md-9 col-lg-9 end-xs">
            <div className="search-header-group form-container form-search-basic">
              <div className="select-filter-header">
                <label className="pt-label pt-label-inline">
                  <span className="text-label">Đơn vị</span>
                </label>
                <SelectTree
                  data={this.state.unitSelect}
                  type="Unit"
                  permission={false}
                  value={!this.state.isLoading ? this.state.filter.donViId : ''}
                  handleChange={this.handleChange}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListFilter
