import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import { Checkbox, Tooltip } from '@blueprintjs/core'
//import { FILE_URL } from '../../../constants/Api';

const LINE_HEIGHT = 18

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClickComplete = this.handleClickComplete.bind(this)
    this.handleReadMore = this.handleReadMore.bind(this)
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  state = {
    isOpenPopup: false,
    isEnabled: false,
    isDone: false,
    readMore: {
      trichYeu: false,
      giaoViec: false,
    },
  }

  handleClick = (e, showSection = 'noContent') => {
    if (!this.props.item) {
      return
    }
    this.props.onClickDetail &&
      this.props.onClickDetail({
        phieuGiaoViecId: this.props.item.phieuGiaoViecId,
        soCongVan: this.props.item.soCongVan,
        fileId: this.props.item.fileId,
        showSection: showSection,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
    this.props.onHandleSubmitRemoveItem(this.props.item.congViecId)
  }

  handleClickComplete = () => {
    if (!this.state.isDone) {
      this.setState(
        { isDone: true },
        () => {
          this.props.onClickComplete &&
            this.props.onClickComplete(this.props.item.phieuGiaoViecId)
        },
        () => {
          this.setState({ isDone: false })
        }
      )
    }
  }

  componentDidMount() {
    if (
      this.props.list.find(
        item =>
          item.cong_viec_phong_ban_id === this.props.item.congViecPhongBanId
      )
    ) {
      this.setState({ isEnabled: true })
    }
    let newReadMore = { ...this.state.readMore }
    if (this.trichYeu.offsetHeight / LINE_HEIGHT > 5) {
      newReadMore = {
        ...newReadMore,
        trichYeu: true,
      }
    }
    if (this.giaoViec.offsetHeight / LINE_HEIGHT > 4) {
      newReadMore = {
        ...newReadMore,
        giaoViec: true,
      }
    }
    this.setState({
      readMore: newReadMore,
    })
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled })
    let type = ''
    let id = null
    if (this.props.item) {
      type = 'cong_van_den_id'
      id = this.props.item.congVanDenId
      if (this.props.item.congVanNoiBoId) {
        type = 'cong_van_noi_bo_id'
        id = this.props.item.congVanNoiBoId
      }
    }

    return this.props.onCheck && this.props.onCheck(this.props.item, id, type)
  }

  handleReadMore(name) {
    let newReadMore = { ...this.state.readMore }
    if (newReadMore[name]) {
      newReadMore = {
        ...newReadMore,
        [name]: false,
      }
      this.setState({ readMore: newReadMore })
    } else {
      this.handleClick()
    }
  }

  render() {
    const { item, number, type } = this.props
    //const token = this.props.auth && this.props.auth.token && this.props.auth.token.accessToken ? this.props.auth.token.accessToken : '';
    //let fileUrl = `${FILE_URL}/api/file/get/${item.fileId}?BearerToken=${token}`;

    if (!item) {
      return
    }

    let giaoViecMoi = false
    if (
      this.props.item &&
      !this.props.item.congVanDenId &&
      !this.props.item.congVanDiId &&
      !this.props.item.congVanNoiBoId
    ) {
      giaoViecMoi = true
    }

    return (
      <tr>
        {type === 'chua-thuc-hien' && (
          <td>
            <Checkbox
              checked={this.state.isEnabled}
              onChange={this.handleEnabledChange}
              disabled={giaoViecMoi}
            />
          </td>
        )}
        <td onClick={this.handleClick}>
          {number && number > 9 ? number : '0' + number}
        </td>
        {type !== 'da-thuc-hien' && (
          <td>
            {item && item.canBaoCao === false && (
              <Tooltip
                content={
                  <span className="font-size-14">Hoàn tất công việc</span>
                }
              >
                <span
                  className="status-complete"
                  onClick={this.handleClickComplete}
                >
                  <div
                    className={classnames({
                      'loading-view-work': this.state.isDone,
                      'icon-Hoan_Thanh': !this.state.isDone,
                    })}
                  >
                    {this.state.isDone && <span>&nbsp;&nbsp;</span>}
                  </div>
                </span>
              </Tooltip>
            )}
            {item && item.canBaoCao === true && (
              <Tooltip
                content={
                  <span className="font-size-14">Báo cáo công việc</span>
                }
              >
                <span
                  className="status-complete"
                  onClick={() => this.handleClick(this, 'baoCao')}
                >
                  <div className="icon-Giao-viec-moi">
                    {this.state.isDone && <span>&nbsp;&nbsp;</span>}
                  </div>
                </span>
              </Tooltip>
            )}
          </td>
        )}
        <td onClick={this.handleClick}>
          {item && item.daLuuTuTaiLieu && item.daLuuTuTaiLieu === true ? (
            <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet"></span>
          ) : (
            <span>&nbsp;</span>
          )}
        </td>
        <td onClick={this.handleClick}>
          {item && item.baoNham && (
            <span className="icon-type-work icon-attention-warning"></span>
          )}
        </td>
        <td onClick={this.handleClick}>{item && item.soCongVan}</td>
        <td className="textarea-content" onClick={this.handleClick}>
          <div ref={el => (this.trichYeu = el)}>
            <p
              className={classnames({
                // 'overflow-hidden-90px': this.state.readMore.trichYeu
              })}
            >
              {item && item.trichYeu ? item.trichYeu : ''}
            </p>
            {/* {this.state.readMore.trichYeu && <i className="icon-Group-8" onClick={this.handleReadMore.bind(this, 'trichYeu')}></i>} */}
          </div>
        </td>
        <td onClick={this.handleClick}>
          <p className="container-text-content">
            {item &&
              item.giaoViec &&
              item.giaoViec.nguoiGiaoViec &&
              item.giaoViec.nguoiGiaoViec.tenNhanVien}
          </p>
          <p className="container-text-content">
            {item && item.giaoViec && item.giaoViec.ngayGiaoViec
              ? moment(item.giaoViec.ngayGiaoViec).format('DD/MM/YYYY HH:mm')
              : ''}
          </p>
          <div className="assign-content textarea-content">
            <div ref={el => (this.giaoViec = el)}>
              <p
                className={classnames({
                  // 'overflow-hidden-72px': this.state.readMore.giaoViec
                })}
              >
                {item && item.giaoViec && item.giaoViec.noiDung
                  ? item.giaoViec.noiDung
                  : ''}
              </p>
              {/* {this.state.readMore.giaoViec && <i className="icon-Group-8" onClick={this.handleReadMore.bind(this, 'giaoViec')}></i>} */}
            </div>
          </div>
        </td>
        <td onClick={this.handleClick}>
          {item && item.hanGiaiQuyet
            ? moment(item.hanGiaiQuyet).format('DD/MM/YYYY')
            : ''}
        </td>
        {type && type !== 'da-thuc-hien' && (
          <td style={{ textAlign: 'center' }} onClick={this.handleClick}>
            <span
              className={classnames('deadline-date', {
                'style-qh': item && item.soNgayConLai < 0,
                'style-gqh':
                  item && (item.soNgayConLai === 0 || item.soNgayConLai === 1),
                'style-color': item && item.soNgayConLai > 1,
              })}
            >
              {item.soNgayConLai}
            </span>
          </td>
        )}
        <td
          onClick={this.handleClick}
          // className="text-nowrap"
        >
          {item && item.coQuanBanHanh}
        </td>
        <td
          onClick={this.handleClick}
          className={classnames('text-nowrap', {
            'text-nowrap-primary':
              item.chuTri && item.pbChuTri && this.props.isCVCN,
          })}
        >
          {item && item.chuTri === false ? 'Phối hợp' : 'Chủ trì'}
        </td>
        <td onClick={this.handleClick} className="text-center-col">
          {item && item.canBaoCao === true ? (
            <span className="icon-Bao_Cao"></span>
          ) : (
            ''
          )}
        </td>
      </tr>
    )
  }
}

export default ListItem
