import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { SecurityLevelCategory } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const LINK = '/he-thong/do-bao-mat/them-moi'

class SecurityLevelCategoryPage extends React.Component {
  state = {
    securities: [],
    isLoading: true,
    isError: false,
  }

  componentDidMount() {
    this.props.actions.commonFetchSecurities(null, true).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.securities.items !== this.props.securities.items) {
      this.setState(
        {
          securities: nextProps.securities.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Danh mục độ mật"
          iconPage="icon-flag"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div className="listrole-container">
          <SecurityLevelCategory
            items={this.state.securities}
            actions={this.props.actions}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          ></SecurityLevelCategory>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  securities: {
    ...state.securities,
    items: state.common.securities,
  },

  auth: {
    ...state.auth,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityLevelCategoryPage)
