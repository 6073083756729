import { schema } from 'normalizr'

const DocumentCabinet = new schema.Entity(
  'documentsCabinet',
  {},
  {
    idAttribute: documentCabinet => documentCabinet.id,
  }
)

export default DocumentCabinet
