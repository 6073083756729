import {
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
  CHAT_COUNT,
} from 'constants/ActionTypes'

export const getTinNhanChuaDoc = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, CHAT_COUNT, API_REQUEST_SUCCESS],
    payload: {
      client: 'profile',
      request: {
        url: '/api/tinnhan/chuadoc',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })
