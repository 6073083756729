import React from 'react'
import { Button } from '@blueprintjs/core'
import DatePicker from 'react-datepicker'
import { get } from 'lodash'

const datePickerConfig = {
  readOnly: true,
  dateFormat: 'DD/MM/YYYY',
  peekNextMonth: true,
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
  className: 'input-datepicker input-icon-datepicker',
  popperPlacement: 'auto',
  popperModifiers: {
    offset: {
      enabled: true,
      offset: '5px, 10px',
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
}

class ListFilter extends React.PureComponent {
  submit = e => {
    e && e.preventDefault()
    const { submit } = this.props
    submit && submit()
  }

  render() {
    const { match, loading, date, dateChange } = this.props
    const id = get(match, 'params.id', null)

    return (
      <div className="search-container">
        <form
          className="search-list-utilities search-utilities-nodropdown"
          onSubmit={this.submit}
        >
          <div className="search-advanced seach-form-container">
            <div className="row row-box-container">
              <div className="col-xs-12 search-padding0">
                <div className="group-datetime pull-left">
                  <label className="pt-label label-datepicker">Thời gian</label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      {...datePickerConfig}
                      isClearable={!!date.start && !id}
                      selectsStart
                      startDate={date.start}
                      endDate={date.end}
                      maxDate={date.end}
                      selected={date.start}
                      value={date.start}
                      onChange={date => dateChange('start', date)}
                      placeholderText="Từ ngày"
                      disabled={!!id || loading}
                    />
                    <DatePicker
                      {...datePickerConfig}
                      isClearable={!!date.end && !id}
                      selectsEnd
                      startDate={date.start}
                      endDate={date.end}
                      minDate={date.start}
                      selected={date.end}
                      onChange={date => dateChange('end', date)}
                      value={date.end}
                      placeholderText="Đến ngày"
                      disabled={!!id || loading}
                    />
                  </div>
                </div>
                {!get(match, 'params.id') && (
                  <div className="group-btn-search pull-right">
                    <Button
                      rightIconName="pt-icon pt-icon-search"
                      className="btn-item btn-search"
                      text="Thống kê"
                      loading={loading}
                      type="submit"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ListFilter
