import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { PopupConfirm } from '../common/';
import classnames from 'classnames'
import { Intent } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
// import {Authorization, Permission} from '../auth';
import _ from 'lodash'

class DonViNhanList extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func,
    infoSendAdditional: PropTypes.object,
    revokeId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleExecutingView = this.handleExecutingView.bind(this)
    this.resendEVN = this.resendEVN.bind(this)
  }

  state = {
    isOpenPopup: false,
    titlePopUp: '',
    congVanDenId: null,
    donViId: null,
    congVanDiId: this.props.revokeId,
    isResendEVN: false,
    isLoading: false,
  }

  handleClickPopup = (type, id) => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })

    switch (type) {
      case 'THCV':
        this.setState({
          titlePopUp: POPUP.THU_HOI_CONG_VAN,
          donViId: id,
        })
        break

      case 'GL':
        this.setState({
          titlePopUp: POPUP.GUI_LAI,
          donViId: id,
        })
        break

      case 'GL_EVN':
        this.setState({
          titlePopUp: POPUP.GUI_LAI,
          isResendEVN: true,
        })
        break

      default:
    }
  }

  handleExecutingView(id) {
    this.props.onClickExecutingViewReceiveDocument &&
      this.props.onClickExecutingViewReceiveDocument(id)
  }

  handleExecutingViewEvn(id) {
    this.props.onClickExecutingViewEvn && this.props.onClickExecutingViewEvn(id)
  }

  resendEVN() {
    this.setState({ isLoading: true }, () => {
      this.props.actions
        .resendEVNSendDocument(this.state.congVanDiId)
        .then(res => {
          if (!res || res.error || !res.payload || res.payload.status !== 200) {
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GuiLai',
                isSuccess: false,
                message: MESSAGE.TEXT_GUILAI_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            )
          }
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiLai',
              isSuccess: true,
              message: MESSAGE.TEXT_GUILAI_THANHCONG,
              intent: Intent.SUCCESS,
            })
        })
        .catch(e => {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiLai',
              isSuccess: false,
              message: MESSAGE.TEXT_GUILAI_KHONGTHANHCONG,
              intent: Intent.DANGER,
            })
          )
        })
        .then(() => {
          this.setState({ isLoading: false, isOpenPopup: false })
        })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.revokeId) {
      this.setState({ congVanDiId: nextProps.revokeId })
    }
  }

  render() {
    const count =
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.guiEvn === true
        ? 1
        : 0
    let danhSachDonVi = []
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViNhan
    ) {
      danhSachDonVi = _.concat(
        danhSachDonVi,
        this.props.infoSendAdditional.dsDonViNhan
      )
    }
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViTrucThuoc
    ) {
      danhSachDonVi = _.concat(
        danhSachDonVi,
        this.props.infoSendAdditional.dsDonViTrucThuoc
      )
    }
    if (
      this.props.infoSendAdditional &&
      this.props.infoSendAdditional.dsDonViKhac
    ) {
      danhSachDonVi = _.concat(
        danhSachDonVi,
        this.props.infoSendAdditional.dsDonViKhac
      )
    }
    // const checkTongCongTy = this.props.auth.user && this.props.auth.user.dsChucDanh && this.props.auth.user.dsChucDanh.filter(item => item.donVi.donViCha === null);
    return (
      <div
        className={classnames(
          'history-steering container-form-action list-receivingunit',
          {
            'history-steering-showmobile container-form-action list-receivingunit':
              this.props.isShowMobile,
          }
        )}
      >
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Danh sách các đơn vị nhận</h4>
        </div>
        <div className="form-container container-detail">
          <div className="table-container table-list-receivingunit">
            <div className="table-scroll">
              <table className="pt-table table-center-td cursor-default">
                <thead className="table-heading">
                  <tr className="row-item">
                    <th className="colum-first">STT</th>
                    <th className="colum-second">ĐƠN VỊ</th>
                    <th></th>
                    {/* <th className="colum-fixed-width">Xem THỰC HIỆN</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.props.infoSendAdditional &&
                    this.props.infoSendAdditional.guiEvn === true && (
                      <tr className="row-item">
                        <td className="cell-content colum-first colmn-none-cursor">
                          01
                        </td>
                        <td className="cell-content colum-second colmn-none-cursor">
                          EVN
                        </td>
                        <td></td>
                        {/* <Authorization.Element permission={[Permission.CONGVANDI_QUANLY]}>
                      <td className="cell-content colum-fixed-width">
                        { this.props.infoSendAdditional.vanBanEvnId !== null &&
                          <span className="onclick-confirm" title="" onClick={this.handleExecutingViewEvn.bind(this, this.props.revokeId)}>
                            <span className="hover-icon icon-visible"></span>
                          </span>
                        }
                      </td>
                      </Authorization.Element> */}
                      </tr>
                    )}
                  {danhSachDonVi.map((item, i) => (
                    <tr className="row-item" key={i}>
                      <td className="cell-content colum-first">
                        {count + i + 1 < 10
                          ? '0' + (count + i + 1)
                          : count + i + 1}
                      </td>
                      <td className="cell-content colum-second">
                        {' '}
                        {item.tenVietTat}{' '}
                      </td>
                      {/* <td className="cell-content colum-fixed-width">
                        { item.congVanDenId !== null ? (
                        <span className="onclick-confirm" title="Xem thực hiện" onClick={this.handleExecutingView.bind(this, item.congVanDenId)}>
                          <span className="hover-icon icon-visible"></span>
                        </span>
                          ) : ''
                        }
                      </td> */}
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DonViNhanList
