import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../../helpers/'

class ListFilterAssigned extends Component {
  static propTypes = {
    status: PropTypes.array.isRequired,
    staffs: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    dateUpdate: moment(),
    isOpen: false,
    workExpiringSoon: 0,
    workExpired: 0,
    filter: {
      quaHan: false,
      ganQuaHan: false,
      textSearch: '',
      ngayGiaoViec: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      hanGiaiQuyet: {
        from: '',
        to: '',
      },
      trangThai: undefined,
      nguoiDuocGiaoId: undefined,
    },
    isActions: {
      quaHan: undefined,
      ganQuaHan: undefined,
    },
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
    dealineFromToString: '',
    dealineToToString: '',
  }

  componentWillReceiveProps(props) {
    const { filter, workExpiringSoon, workExpired } = props

    if (props) {
      this.setState({
        workExpiringSoon: workExpiringSoon || 0,
        workExpired: workExpired || 0,
      })
    }

    if (filter) {
      const { ngayGiaoViec, hanGiaiQuyet } = filter
      this.setState({
        filter: {
          quaHan: filter.quaHan || false,
          ganQuaHan: filter.ganQuaHan || false,
          textSearch: filter.textSearch || '',
          ngayGiaoViec: {
            from:
              ngayGiaoViec &&
              ngayGiaoViec.from &&
              ngayGiaoViec.from.trim().length > 0
                ? moment(ngayGiaoViec.from)
                : '',
            to:
              ngayGiaoViec &&
              ngayGiaoViec.to &&
              ngayGiaoViec.to.trim().length > 0
                ? moment(ngayGiaoViec.to)
                : '',
          },
          hanGiaiQuyet: {
            from:
              hanGiaiQuyet &&
              hanGiaiQuyet.from &&
              hanGiaiQuyet.from.trim().length > 0
                ? moment(hanGiaiQuyet.from)
                : '',
            to:
              hanGiaiQuyet &&
              hanGiaiQuyet.to &&
              hanGiaiQuyet.to.trim().length > 0
                ? moment(hanGiaiQuyet.to)
                : '',
          },
          trangThai: filter.trangThai || '',
          nguoiDuocGiaoId: filter.nguoiDuocGiaoId || '',
        },
        dateFromToString:
          ngayGiaoViec &&
          ngayGiaoViec.from &&
          ngayGiaoViec.from.trim().length > 0
            ? moment(ngayGiaoViec.from)
            : '',
        dateToToString:
          ngayGiaoViec && ngayGiaoViec.to && ngayGiaoViec.to.trim().length > 0
            ? moment(ngayGiaoViec.to)
            : '',
        dealineFromToString:
          hanGiaiQuyet &&
          hanGiaiQuyet.from &&
          hanGiaiQuyet.from.trim().length > 0
            ? moment(hanGiaiQuyet.from)
            : '',
        dealineToToString:
          hanGiaiQuyet && hanGiaiQuyet.to && hanGiaiQuyet.to.trim().length > 0
            ? moment(hanGiaiQuyet.to)
            : '',
      })
    }
  }

  clearFilter(e) {
    const filter = {
      quaHan: false,
      ganQuaHan: false,
      textSearch: '',
      ngayGiaoViec: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      hanGiaiQuyet: {
        from: '',
        to: '',
      },
      trangThai: '',
      nguoiDuocGiaoId: '',
      isActions: {
        quaHan: undefined,
        ganQuaHan: undefined,
      },
    }
    this.setState(
      {
        filter,
        dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        dateToToString: moment().format('DD/MM/YYYY'),
        dealineFromToString: '',
        dealineToToString: '',
      },
      () => {
        this.handleSubmit()
        this.props.actions.getNumberWorkPage()
      }
    )
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayGiaoViec: {
        from: this.state.filter.ngayGiaoViec.from
          ? moment(this.state.filter.ngayGiaoViec.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayGiaoViec.to
          ? moment(this.state.filter.ngayGiaoViec.to).format('YYYY-MM-DD')
          : '',
      },
      hanGiaiQuyet: {
        from: this.state.filter.hanGiaiQuyet.from
          ? moment(this.state.filter.hanGiaiQuyet.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.hanGiaiQuyet.to
          ? moment(this.state.filter.hanGiaiQuyet.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  filterWork(type, e) {
    e.preventDefault()
    if (type === 'quaHan') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            quaHan: !this.state.filter.quaHan,
            ganQuaHan: false,
          },
        },
        () => {
          this.handleSubmit()
        }
      )
    } else if (type === 'ganQuaHan') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            quaHan: false,
            ganQuaHan: !this.state.filter.ganQuaHan,
          },
        },
        () => {
          this.handleSubmit()
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.workExpired !== this.props.workExpired) {
      this.setState({ workExpired: this.props.workExpired })
    }

    if (prevProps.workExpiringSoon !== this.props.workExpiringSoon) {
      this.setState({ workExpiringSoon: this.props.workExpiringSoon })
    }
    if (prevState.filter !== this.state.filter) {
      if (this.state.filter.quaHan === true) {
        this.setState({
          isActions: {
            quaHan: true,
            ganQuaHan: false,
          },
        })
      } else if (this.state.filter.ganQuaHan === true) {
        this.setState({
          isActions: {
            quaHan: false,
            ganQuaHan: true,
          },
        })
      } else if (
        this.state.filter.quaHan === false &&
        this.state.filter.ganQuaHan === false
      ) {
        this.setState({
          isActions: {
            quaHan: false,
            ganQuaHan: false,
          },
        })
      }
    }
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  render() {
    const check =
      (isNaN(this.state.workExpired) || this.state.workExpired < 1) &&
      (isNaN(this.state.workExpiringSoon) || this.state.workExpiringSoon < 1)
    return (
      <div className="search-container search-work">
        <form className="form-search-basic" onSubmit={this.handleSubmit}>
          <div
            className={classnames('seach-form', {
              'search-work-unfulfilled': this.props.status !== 'da-giao',
            })}
          >
            <div className="row end-xs">
              {/* khi nào mở action get number thì xoá cái false && đi */}
              {false && !check && (
                <div className="col-xs-12 col-sm-3 col-md-3 statistic-work-left-content">
                  <div className="statistic-work">
                    <div className="row">
                      {!isNaN(this.state.workExpired) &&
                        this.state.workExpired > 0 && (
                          <div className="item">
                            <div
                              className={classnames('item-status', {
                                'active-filter-qh':
                                  this.state.isActions.quaHan === true,
                              })}
                              onClick={this.filterWork.bind(this, 'quaHan')}
                            >
                              <span className="number number-past">
                                {/*this.state.workExpired > 99 ? '99+' : this.state.workExpired*/}
                              </span>
                              <span className="past-deadline">
                                Công việc <br /> quá hạn
                              </span>
                            </div>
                          </div>
                        )}

                      {!isNaN(this.state.workExpiringSoon) &&
                        this.state.workExpiringSoon > 0 && (
                          <div className="item">
                            <div
                              className={classnames('item-status', {
                                'active-filter-gqh':
                                  this.state.isActions.ganQuaHan === true,
                              })}
                              onClick={this.filterWork.bind(this, 'ganQuaHan')}
                            >
                              <span className="number number-near">
                                {/*this.state.workExpiringSoon > 99 ? '99+' : this.state.workExpiringSoon*/}
                              </span>
                              <span className="near-deadline">
                                Công việc <br /> gần quá hạn
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}

              <div
                className={classnames('col-xs-12 search-header-right-content', {
                  'col-md-12 col-sm-12': check,
                  'col-md-9 col-sm-9': !check,
                })}
              >
                <div className="search-header-group pull-right">
                  <div className="search-filter-date">
                    <div className="group-datetime">
                      <label className="pt-label-inline">
                        <span className="text-label"> Từ ngày </span>
                      </label>
                      <div className="daterangepicker-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          isClearable={
                            this.state.dateFromToString ? true : false
                          }
                          selected={this.state.filter.ngayGiaoViec.from}
                          selectsStart
                          startDate={this.state.filter.ngayGiaoViec.from}
                          endDate={this.state.filter.ngayGiaoViec.to}
                          onChange={this.handleDateChange.bind(
                            this,
                            'ngayGiaoViec',
                            'from',
                            'dateFromToString'
                          )}
                          value={this.state.dateFromToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateFromToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayGiaoViec',
                              'from',
                              'dateFromToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Từ ngày"
                          maxDate={this.state.filter.ngayGiaoViec.to}
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="group-datetime">
                      <label className="pt-label-inline">
                        <span className="text-label"> Đến ngày </span>
                      </label>
                      <div className="daterangepicker-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          isClearable={this.state.dateToToString ? true : false}
                          selected={this.state.filter.ngayGiaoViec.to}
                          selectsEnd
                          startDate={this.state.filter.ngayGiaoViec.from}
                          endDate={this.state.filter.ngayGiaoViec.to}
                          onChange={this.handleDateChange.bind(
                            this,
                            'ngayGiaoViec',
                            'to',
                            'dateToToString'
                          )}
                          value={this.state.dateToToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateToToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayGiaoViec',
                              'to',
                              'dateToToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Đến ngày"
                          minDate={this.state.filter.ngayGiaoViec.from}
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="search-filter-select">
                    <div className="filter-search-list">
                      <div className="search-keyword position-gridview">
                        <input
                          autoFocus={false}
                          autoComplete="off"
                          style={{ paddingRight: 35 }}
                          name="textSearch"
                          className="search"
                          type="text"
                          onChange={this.handleInputChange}
                          placeholder="Tìm số cv, trích yếu (hoặc số cv:trích yếu)..."
                          value={this.state.filter.textSearch}
                        />
                        {this.state.filter.textSearch && (
                          <i
                            className="icon-close pt-close-gridview-search"
                            onClick={this.removeTextSearch.bind(
                              this,
                              'textSearch'
                            )}
                            style={{ right: 15 }}
                          />
                        )}
                      </div>
                      <div className="btn-reload-group">
                        <Button className="btn-search" type="submit">
                          <span className="pt-icon pt-icon-search"></span>
                        </Button>
                        <Button
                          type="button"
                          className="btn-reload"
                          onClick={this.clearFilter}
                        >
                          <span className="icon-Loading"></span>
                        </Button>
                        <Button
                          type="submit"
                          className="btn-toogle"
                          onClick={this.handleClick}
                        >
                          <span
                            className={classnames('pt-icon', {
                              'pt-icon-double-chevron-up': this.state.isOpen,
                              'pt-icon-double-chevron-down': !this.state.isOpen,
                            })}
                          ></span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="search-advanced">
              <div className="row end-xs">
                {/* <div className="group-datetime">
                  <label className="pt-label label-datepicker">
                    Giao việc
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={this.state.dateFromToString ? true : false}
                      selected={this.state.filter.ngayGiaoViec.from}
                      name="ngayGiaoViec"
                      selectsStart
                      startDate={this.state.filter.ngayGiaoViec.from}
                      endDate={this.state.filter.ngayGiaoViec.to}
                      onChange={this.handleDateChange.bind(this, 'ngayGiaoViec', 'from', 'dateFromToString')}
                      value={this.state.dateFromToString}
                      onChangeRaw={(e) => this.handleChangeRaw('dateFromToString', e.target.value)}
                      onBlur={e => this.focousOut('ngayGiaoViec', 'from', 'dateFromToString', e.target.value)}
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.ngayGiaoViec.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport'
                        }
                      }}
                    />
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={this.state.dateToToString ? true : false}
                      selected={this.state.filter.ngayGiaoViec.to}
                      name="ngayGiaoViec"
                      selectsEnd
                      startDate={this.state.filter.ngayGiaoViec.from}
                      endDate={this.state.filter.ngayGiaoViec.to}
                      onChange={this.handleDateChange.bind(this, 'ngayGiaoViec', 'to', 'dateToToString')}
                      value={this.state.dateToToString}
                      onChangeRaw={(e) => this.handleChangeRaw('dateToToString', e.target.value)}
                      onBlur={e => this.focousOut('ngayGiaoViec', 'to', 'dateToToString', e.target.value)}
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.ngayGiaoViec.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport'
                        }
                      }}
                    />
                  </div>
                </div> */}
                <div className="group-datetime">
                  <label className="pt-label label-datepicker">
                    Hạn giải quyết
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={
                        this.state.dealineFromToString ? true : false
                      }
                      selected={this.state.filter.hanGiaiQuyet.from}
                      name="hanGiaiQuyet"
                      selectsStart
                      startDate={this.state.filter.hanGiaiQuyet.from}
                      endDate={this.state.filter.hanGiaiQuyet.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'from',
                        'dealineFromToString'
                      )}
                      value={this.state.dealineFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw(
                          'dealineFromToString',
                          e.target.value
                        )
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'from',
                          'dealineFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.hanGiaiQuyet.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      readOnly={this.props.readOnly}
                      isClearable={this.state.dealineToToString ? true : false}
                      selected={this.state.filter.hanGiaiQuyet.to}
                      name="hanGiaiQuyet"
                      selectsEnd
                      startDate={this.state.filter.hanGiaiQuyet.from}
                      endDate={this.state.filter.hanGiaiQuyet.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'to',
                        'dealineToToString'
                      )}
                      value={this.state.dealineToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw(
                          'dealineToToString',
                          e.target.value
                        )
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'to',
                          'dealineToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.hanGiaiQuyet.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Trạng thái
                    <div className="pt-select">
                      <select
                        name="trangThai"
                        onChange={this.handleInputChange}
                        value={this.state.filter.trangThai}
                      >
                        <option value="">Tất cả</option>
                        {this.props.status.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Người chủ trì
                    <div className="pt-select">
                      <select
                        name="nguoiDuocGiaoId"
                        onChange={this.handleInputChange}
                        value={this.state.filter.nguoiDuocGiaoId}
                      >
                        <option value="">Tất cả</option>
                        {this.props.staffs.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilterAssigned
