import React from 'react'
import FileDownload from 'file-saver'
import moment from 'moment'
import { Intent, Button } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { MasterLayout } from '../../components/layout'
import { GridViewMultipleHeaders } from '../../components/common'
import {
  ListFilter,
  ListItemUnit,
} from '../../components/Utilities/StatisticalSignNumber/'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import HeadingPanel from 'components/common/HeadingPanel'

class ListStatisticSignNumberUnitDetailPage extends React.Component {
  constructor(props) {
    super(props)

    const id =
      this.props.match && this.props.match.params && this.props.match.params.id

    this.state = {
      filter: {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loaiCongVan: Types.TYPE_ALL_SIGN_NUMBER,
      },
      page: 1,
      isShowMessengStatistics: true,
      exporting: false,
      isLoading: false,
      isEmpty: false,
      isError: false,
      id,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      'ListStagisticalNumber',
      null,
      'thong-ke-ky-so'
    )

    if (preData) {
      state.filter = preData.filter
      this.setState(state)
    }

    if (this.props.location && this.props.location.state) {
      let state = this.state
      const { isCheck } = this.props.location.state
      if (isCheck) {
        this.submitFilter(state.filter, state.page)
      }
    }

    let isSubmitStatisticSignNumber = Tool.Utils.getData(
      'isSubmitStatisticSignNumber'
    )
    if (isSubmitStatisticSignNumber) {
      Tool.Utils.saveData('isSubmitStatisticSignNumber', false)
      this.props.history.replace({
        state: {
          isCheck: true,
        },
      })
      this.submitFilter(this.state.filter)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match !== this.props.match
    ) {
      this.setState({ id: nextProps.match.params.id })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      this.submitFilter(this.state.filter)
    }
  }

  submitFilter(e = {}) {
    let state = this.state
    state.filter = Object.assign({}, state.filter, e)
    state.isShowMessengStatistics = false
    state.isLoading = true
    let filterSubmit = {
      ...state.filter,
      donViId: state.id,
    }
    this.setState(state, () => {
      const filter = Object.assign({}, this.state.filter, e)
      Tool.Utils.preData('ListStagisticalNumber', { filter }, 'thong-ke-ky-so')
      this.props.actions
        .getStatisticsSignNumberDepartments(
          filterSubmit,
          this.props.config.refreshStatistic
        )
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.total === 0
          ) {
            isEmpty = true
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .finally(() => {
          this.props.actions.refreshStatistic(true)
          this.setState({ isLoading: false })
        })
    })
  }

  handleExport() {
    let { filter, id } = this.state
    if (!id) {
      return
    }
    filter = {
      ...filter,
      donViId: id,
    }
    if (
      !filter.startDate ||
      !filter.endDate ||
      filter.loaiCongVan === undefined ||
      filter.loaiCongVan === null
    ) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_TIME,
        intent: Intent.WARNING,
      })
    }

    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportStatisticsSignNumber(
          filter,
          Types.TYPE_STATISTIC_SIGN_NUMER_DEPARTMENTS
        )
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData('ListStagisticalNumber')
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-ky-so-${moment(preData.filter.startDate).format(
              'L'
            )}-${moment(preData.filter.endDate).format('L')}.xls`
          )
        })
        .finally(() => {
          this.setState({ exporting: false })
        })
    })
  }

  handleClickGoBack = async () => {
    Tool.Utils.saveData('isSubmitStatisticSignNumber', true)
    await this.props.actions.refreshStatistic(false)
    this.props.history && this.props.history.goBack()
  }

  render() {
    const isLoading =
      Object.values(this.state.isLoading).findIndex(item => item === true) > -1
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container page-statistic-signnumber">
          <HeadingPanel
            goBack={this.state.id ? this.handleClickGoBack : null}
            leftTitle="Thống kê ký số"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.isExporting}
                disabled={
                  this.state.isShowMessengStatistics ||
                  this.state.isLoading ||
                  this.state.isEmpty
                }
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              isLoading={isLoading}
              filter={this.state.filter}
              isForChild={typeof this.state.id === 'string'}
            />
            <div className="table-container table-multiple-header">
              <GridViewMultipleHeaders
                classNames="pt-table"
                page={this.state.page}
                headers={headers.generalSignNumberUnit}
                itemShape={ListItemUnit}
                data={this.props.statisticSignNumber.departments.list || []}
                isLoading={this.state.isLoading.units}
                isEmpty={
                  this.state.isEmpty.units ||
                  this.props.statisticSignNumber.units.total === 0
                }
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError.units}
                type={Types.TYPE_STATISTIC_SIGN_NUMER_UNITS}
                colSpanLoading={10}
                actions={this.props.actions}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  statisticSignNumber: state.statisticSignNumber,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStatisticSignNumberUnitDetailPage)
