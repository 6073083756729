import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/InternalDocument'
import { Intent } from '@blueprintjs/core'
import { GridViewFilter } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../../components/auth'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { GUID_EMPTY } from 'constants/Enum'
import { get } from 'lodash'

import Pagination from 'components/Pagination'

const CV_NOI_BO_DI = true
const CV_NOI_BO_DEN = false
const PHONG_BAN_LAP = true
const PHONG_BAN_NHAN = false
const DON_VI = 2
const TAT_CA = 0
class ListInternalDocument extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      sort: undefined,
      isLoading: true,
      isEmpty: false,
      page: 1,
      isError: false,
      filterColumn: {
        phongBanLapId: GUID_EMPTY,
        phongBanNhanId: GUID_EMPTY,
      },
      documentType: 'cvNoiBoDi',
      isDisablePBLap:
        props.auth &&
        !Authorization.vaild(
          props.auth.permission,
          [Permission.SUPERADMIN, Permission.ADMIN],
          true
        )
          ? true
          : false,
      isDisablePBNhan: false,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  componentDidMount() {
    let preData = Tool.Utils.preData('ListInternalDocument')
    let state = { ...this.state }
    if (preData) {
      state.documentType = preData.documentType
      state.isDisablePBNhan = preData.isDisablePBNhan
      state.isDisablePBLap = preData.isDisablePBLap
      state.filter = preData.filter
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : null,
        ngayGui:
          preData.filterColumn && preData.filterColumn.ngayGui
            ? moment(preData.filterColumn.ngayGui)
            : null,
      }

      this.setState(state, () => {
        this.handleFilterColumn(preData.documentType, this.state.page)
      })
    } else {
      state.filter.ngayCongVan = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
      this.handleFilterColumn('cvNoiBoDi')
    }
  }

  handleFilterColumn = (type, page = 1) => {
    if (type === 'cvNoiBoDi') {
      this.props.actions
        .fetchInternalDepartments(CV_NOI_BO_DI, PHONG_BAN_LAP)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result[0]
          ) {
            let filterColumn = this.state.filterColumn
            let phongBanLapId =
              this.props.auth &&
              !Authorization.vaild(
                this.props.auth.permission,
                [Permission.SUPERADMIN, Permission.ADMIN],
                true
              )
                ? res.payload.data.result[0].phongBanId
                : get(filterColumn, 'phongBanLapId', GUID_EMPTY) !== GUID_EMPTY
                ? filterColumn.phongBanLapId
                : GUID_EMPTY
            this.setState(
              {
                filterColumn: {
                  ...this.state.filterColumn,
                  phongBanNhanId: GUID_EMPTY,
                  phongBanLapId,
                },
              },
              () => {
                this.clickPagination(page)
              }
            )
          }
        })
      this.props.actions.fetchInternalDepartments(CV_NOI_BO_DI, PHONG_BAN_NHAN)
    } else {
      this.props.actions.fetchInternalDepartments(CV_NOI_BO_DEN, PHONG_BAN_LAP)
      this.props.actions
        .fetchInternalDepartments(CV_NOI_BO_DEN, PHONG_BAN_NHAN)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result[0]
          ) {
            let filterColumn = this.state.filterColumn
            let phongBanNhanId =
              this.props.auth &&
              !Authorization.vaild(
                this.props.auth.permission,
                [Permission.SUPERADMIN, Permission.ADMIN],
                true
              )
                ? res.payload.data.result[0].phongBanId
                : get(filterColumn, 'phongBanNhanId', GUID_EMPTY) !== GUID_EMPTY
                ? filterColumn.phongBanNhanId
                : GUID_EMPTY
            this.setState(
              {
                filterColumn: {
                  ...this.state.filterColumn,
                  phongBanLapId: GUID_EMPTY,
                  phongBanNhanId,
                },
              },
              () => {
                this.clickPagination()
              }
            )
          }
        })
    }
  }

  clickPagination(page = 1) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    const documentType = this.state.documentType
    const filterColumn = {
      ...this.state.filterColumn,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      phongBanLapId:
        this.state.filterColumn.phongBanLapId &&
        (this.state.filterColumn.phongBanLapId !== GUID_EMPTY ||
          this.state.filterColumn.phongBanLapId !== '')
          ? this.state.filterColumn.phongBanLapId
          : null,
      phongBanNhanId:
        this.state.filterColumn.phongBanNhanId &&
        (this.state.filterColumn.phongBanNhanId !== GUID_EMPTY ||
          this.state.filterColumn.phongBanNhanId !== '')
          ? this.state.filterColumn.phongBanNhanId
          : null,
    }

    const filterColumn1 = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayGui: this.state.filterColumn.ngayGui
        ? moment(this.state.filterColumn.ngayGui).format('YYYY-MM-DD')
        : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan.trim()
          : null,
      phongBanLapId:
        this.state.filterColumn.phongBanLapId &&
        this.state.filterColumn.phongBanLapId !== GUID_EMPTY
          ? this.state.filterColumn.phongBanLapId
          : null,
      phongBanNhanId:
        this.state.filterColumn.phongBanNhanId &&
        this.state.filterColumn.phongBanNhanId !== GUID_EMPTY
          ? this.state.filterColumn.phongBanNhanId
          : null,
    }
    const isSearchNoiBoDi =
      this.state.documentType === 'cvNoiBoDi' ? true : false
    const { isDisablePBNhan, isDisablePBLap } = this.state
    this.setState({ filter, filterColumn, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ListInternalDocument', {
        filter,
        page,
        sort,
        filterColumn,
        documentType,
        isDisablePBNhan,
        isDisablePBLap,
      })
      this.props.actions
        .internalDocumentGet(
          { filter },
          page,
          sort,
          this.props.config.refresh,
          filterColumn1,
          isSearchNoiBoDi
        )
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshPage(false)
        })
    })
  }

  handleSubmitRemoveItem(id, lyDo) {
    let total = 0
    let page = this.state.page
    if (
      this.props.listInternalDocuments &&
      this.props.listInternalDocuments.list &&
      this.props.listInternalDocuments.list.total
    ) {
      total = this.props.listInternalDocuments.list.total
    }

    const params = {
      ly_do_xoa: lyDo,
    }
    this.props.actions
      .deleteDocumentInternal(id, params)
      .then(res => {
        if (res && res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_THUHOICONGVAN_THANHCONG,
            intent: Intent.SUCCESS,
          })
        }

        if (total > 0) {
          total -= 1
        }

        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        this.props.actions.refreshPage(true)
        this.submitFilter(this.state.filter, page, this.state.sort)
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_THUHOICONGVAN_THANHCONG,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_THUHOICONGVAN_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  onChangeDateGridViewFilter = (name, e) => {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.clickPagination()
      }
    )
  }

  onChangeGridViewFilter = e => {
    const value = e.target.value
    const name = e.target.name
    if (name === 'soDen' && !isNaN(value)) {
      return this.setState({
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    }

    if (name !== 'soDen') {
      this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (name === 'phongBanNhanId' || name === 'phongBanLapId') {
            this.clickPagination()
          }
        }
      )
    }
  }

  onRemoveText = name => {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.clickPagination()
      }
    )
  }

  clearFilterColumn = async (filter, check) => {
    if (check && this.props.auth) {
      if (
        Authorization.vaild(
          this.props.auth.permission,
          [Permission.SUPERADMIN, Permission.ADMIN],
          true
        )
      ) {
        await this.setState({
          filterColumn: {
            phongBanLapId: GUID_EMPTY,
            phongBanNhanId: GUID_EMPTY,
          },
        })
      } else {
        await this.setState({
          filterColumn: {
            phongBanLapId:
              this.state.documentType === 'cvNoiBoDi'
                ? this.state.filterColumn.phongBanLapId
                : GUID_EMPTY,
            phongBanNhanId:
              this.state.documentType === 'cvNoiBoDi'
                ? GUID_EMPTY
                : this.state.filterColumn.phongBanNhanId,
          },
        })
      }
    }

    await this.submitFilter(filter)
  }

  removeTextSearch = name => {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange = (name, pos, e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.clickPagination()
      }
    )
  }

  changeDocumentType = type => {
    if (this.state.documentType !== type) {
      const isAdmin =
        this.props.auth &&
        !Authorization.vaild(
          this.props.auth.permission,
          [Permission.SUPERADMIN, Permission.ADMIN],
          true
        )
          ? true
          : false
      let isDisablePBLap = true
      let isDisablePBNhan = true
      if (type === 'cvNoiBoDi') {
        isDisablePBLap = isAdmin
        isDisablePBNhan = false
      } else {
        isDisablePBLap = false
        isDisablePBNhan = isAdmin
      }

      this.setState(
        {
          documentType: type,
          isDisablePBLap: isDisablePBLap,
          isDisablePBNhan: isDisablePBNhan,
        },
        () => {
          this.handleFilterColumn(type, 1)
        }
      )
    }
  }

  groupDeparment = arr => {
    if (!arr) {
      return
    }

    let newArr = [
      {
        donViId: null,
        level: 0,
        maPhongBan: null,
        phongBanId: GUID_EMPTY,
        stt: 0,
        tenPhongBan: null,
        tenVietTat: 'Tất cả',
      },
    ]
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (arr.filter(pb => pb.donViId === item.donViId).length === 1) {
        newArr.push(item)
      } else if (arr.filter(pb => pb.donViId === item.donViId).length > 1) {
        let index = newArr.findIndex(
          pb => pb.donViId === item.donViId && !pb.phongBanId
        )
        if (index === -1) {
          newArr.push({
            donViId: item.donViId,
            tenVietTat: item.tenVietTat,
            level: null,
            maPhongBan: null,
            phongBanId: null,
            stt: null,
            tenPhongBan: null,
            dsPhongBanCon: [item],
          })
        } else {
          newArr[index] = {
            ...newArr[index],
            dsPhongBanCon: [...newArr[index].dsPhongBanCon, item],
          }
        }
      }
    }
    // Danh sách các phòng ban nhận trong đơn vị
    // của user đang đăng nhập phải hiển thị cùng hàng với các đơn vị, điện lực khác
    if (newArr[1] && newArr[1].dsPhongBanCon) {
      let [all, , ...rest] = newArr
      newArr = [all, ...newArr[1].dsPhongBanCon, ...rest]
    }
    return newArr
  }

  onChangeSelectTreeFilter = (name, value) => {
    if (!value) {
      return
    }

    if (value === TAT_CA && value !== DON_VI) {
      return this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: GUID_EMPTY,
          },
        },
        () => {
          this.clickPagination()
        }
      )
    } else if (value !== 0 && value !== 2) {
      this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          this.clickPagination()
        }
      )
    }
  }

  render() {
    const AuthElement = Authorization.Element

    return (
      <MasterLayout typeSidebar="documentary">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-Cong_van_noi_bo icon-heading"></span>
                  <span className="text-heading">Công văn nội bộ</span>
                </h3>
                <AuthElement permission={[Permission.CONGVANNOIBO_QUANLY]}>
                  <Link
                    to="/cong-van/cong-van-noi-bo/them-moi"
                    className="btn-action pull-right hidden-btn"
                  >
                    <span className="name-btn">NHẬP CÔNG VĂN</span>
                    <span className="pt-icon pt-icon-small-plus"></span>
                  </Link>
                </AuthElement>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              filter={this.state.filter}
              departments={this.props.departments}
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
              documentType={this.state.documentType}
              changeDocumentType={this.changeDocumentType}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.listInternalDocuments.list.total /
                      Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                page={this.state.page}
                pageCount={Math.ceil(
                  this.props?.listInternalDocuments?.list?.total /
                    Types.PAGINATION_LIMIT
                )}
                headers={headers.listInternalDocument}
                itemShape={ListItem}
                data={this.props.listInternalDocuments.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                phongBanLapId={this.groupDeparment(
                  this.props.phongBanLapCVNoiBo
                )}
                isDisablePBLap={this.state.isDisablePBLap}
                phongBanNhanId={this.groupDeparment(
                  this.props.phongBanNhanCVNoiBo
                )}
                isDisablePBNhan={this.state.isDisablePBNhan}
                filterColumn={this.state.filterColumn}
                onRemoveText={this.onRemoveText}
                onSubmit={this.submitFilter}
                isEmpty={this.state.isEmpty}
                isError={this.state.isError}
                onFilter={this.handleSort}
                onChangeSelectTree={this.onChangeSelectTreeFilter}
                documentType="internal"
                internalDocumentType={this.state.documentType}
                listTenDonVi={this.props.listTenDonVi}
                quyensMenu={this.props.quyensMenu}
              />

              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props?.listInternalDocuments?.list?.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = (state, props) => {
  let listTenDonVi = {}
  const listPhongBan =
    state.common.phongBanNhanCVNoiBo &&
    state.common.phongBanNhanCVNoiBo.length > 1
      ? state.common.phongBanNhanCVNoiBo
      : state.common.phongBanLapCVNoiBo
  const mainUnitId = props.auth ? props.auth.mainUnitId : ''
  if (listPhongBan) {
    for (let i = 0; i < listPhongBan.length; i++) {
      const item = listPhongBan[i]
      if (mainUnitId && item.donViId !== mainUnitId) {
        listTenDonVi = {
          ...listTenDonVi,
          [item.phongBanId]: item.tenVietTat,
        }
      }
    }
  }
  return {
    config: state.common.config,

    listInternalDocuments: {
      ...state.listInternalDocuments,
      list: {
        ...state.listInternalDocuments.list,
        items: state.listInternalDocuments.list.items.map(
          id => state.entities.internalDocuments[id]
        ),
      },
    },
    phongBanLapCVNoiBo: state.common.phongBanLapCVNoiBo
      ? state.common.phongBanLapCVNoiBo
      : [],
    phongBanNhanCVNoiBo: state.common.phongBanNhanCVNoiBo
      ? state.common.phongBanNhanCVNoiBo
      : [],
    listTenDonVi,
    quyensMenu: state.common.quyensMenu,
  }
}

ListInternalDocument.propTypes = {
  listInternalDocuments: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListInternalDocument)
