import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        textSearch: '',
        ngayCapNhat: {
          from: moment().subtract(1, 'months'),
          to: moment(),
        },
      },
    }

    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentWillReceiveProps(props) {
    if (props.filter !== this.props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayCapNhat: {
            from:
              props.filter.ngayCapNhat &&
              props.filter.ngayCapNhat.from &&
              props.filter.ngayCapNhat.from.trim().length > 0
                ? moment(props.filter.ngayCapNhat.from)
                : undefined,
            to:
              props.filter.ngayCapNhat &&
              props.filter.ngayCapNhat.to &&
              props.filter.ngayCapNhat.to.trim().length > 0
                ? moment(props.filter.ngayCapNhat.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter(e) {
    if (this.props.match.params.status === 'chua-xu-ly') {
      const filter = {
        textSearch: '',
        ngayCapNhat: {
          from: undefined,
          to: undefined,
        },
      }

      return this.setState(
        {
          filter,
        },
        () => {
          this.props.actions.getNumberDocumentPage()
          // this.props.actions.getNumberEVNDocument()
          const filter = {
            ...this.state.filter,
            ngayCapNhat: {
              from: this.state.filter.ngayCapNhat.from
                ? moment(this.state.filter.ngayCapNhat.from).format(
                    'YYYY-MM-DD'
                  )
                : '',
              to: this.state.filter.ngayCapNhat.to
                ? moment(this.state.filter.ngayCapNhat.to).format('YYYY-MM-DD')
                : '',
            },
          }
          this.props.clearFilterColumn &&
            this.props.clearFilterColumn(filter, true)
        }
      )
    }

    const filter = {
      textSearch: '',
      ngayCapNhat: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
    }

    this.setState(
      {
        filter,
      },
      () => {
        const filter = {
          ...this.state.filter,
          ngayCapNhat: {
            from: this.state.filter.ngayCapNhat.from
              ? moment(this.state.filter.ngayCapNhat.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.ngayCapNhat.to
              ? moment(this.state.filter.ngayCapNhat.to).format('YYYY-MM-DD')
              : '',
          },
        }
        this.props.clearFilterColumn &&
          this.props.clearFilterColumn(filter, true)
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
    })
    this.props.handleDateChange && this.props.handleDateChange(name, pos, e)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCapNhat: {
        from: this.state.filter.ngayCapNhat.from
          ? moment(this.state.filter.ngayCapNhat.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCapNhat.to
          ? moment(this.state.filter.ngayCapNhat.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.clearFilterColumn && this.props.clearFilterColumn(filter, false)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.status !== this.props.match.params.status) {
      this.clearFilter()
    }
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch py-12 px-sm-15"
      >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div>
              <label className="search-label">
                {this.props.match &&
                this.props.match.params.status === 'chua-xu-ly'
                  ? 'Thời gian (Ngày đến)'
                  : 'Thời gian (Ngày công văn)'}
              </label>
              <div className="mt5 form-input-filter date-picker-inline border-radius-3">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCapNhat.from}
                  selectsStart
                  startDate={this.state.filter.ngayCapNhat.from}
                  endDate={this.state.filter.ngayCapNhat.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCapNhat',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayCapNhat.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCapNhat.to}
                  selectsEnd
                  startDate={this.state.filter.ngayCapNhat.from}
                  endDate={this.state.filter.ngayCapNhat.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCapNhat',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayCapNhat.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                onChange={this.handleInputChange}
                placeholder="Tìm kiếm Số đến, Số CV, Trích yếu (hoặc Số CV:Trích yếu)"
                value={this.state.filter.textSearch}
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilter
