import React, { Component } from 'react'

import { Button } from '@blueprintjs/core'
import classnames from 'classnames'

const DEFAULT_FILTER = {
  textFilter: '',
  selectFilter: '-1',
}
class ListFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...DEFAULT_FILTER,
    }
  }

  handleSubmit = e => {
    const { textFilter, selectFilter } = this.state
    const { onSearch } = this.props
    if (e) {
      e.preventDefault()
    }
    if (onSearch && typeof onSearch === 'function') {
      onSearch({ textFilter, selectFilter })
    }
  }

  handleInputChange = e => {
    if (e.key === 'Enter') {
      return this.handleSubmit(e)
    }
    this.setState({
      textFilter: e.target.value,
    })
  }

  handleClearText = () =>
    this.setState({
      textFilter: '',
    })

  handleClearFilter = async e => {
    const { onRemoveFilter } = this.props
    e.preventDefault()
    await this.setState({ ...DEFAULT_FILTER })
    if (onRemoveFilter && typeof onRemoveFilter === 'function') {
      onRemoveFilter(null, true)
    }
  }

  handleSelect = async ({ target: { value = '-1' } }) => {
    const { onDropdownSelect } = this.props
    await this.setState({ selectFilter: value })
    const { textFilter, selectFilter } = this.state
    if (onDropdownSelect && typeof onDropdownSelect === 'function') {
      onDropdownSelect({ textFilter, selectFilter })
    }
  }

  render() {
    const {
      inputPlaceholder,
      hasDropdownFilter,
      dropdownDefaultText,
      dropdownData,
      dropdownLoading,
      paddingClass,
    } = this.props
    const { textFilter, selectFilter } = this.state
    return (
      <form
        onSubmit={this.handleSubmit}
        className={classnames('search-container list-filter', {
          'pt30 pb30 px-sm-15': !paddingClass,
          [paddingClass]: paddingClass,
        })}
      >
        <div className="row">
          {!!hasDropdownFilter && (
            <div className="dropdown-filter">
              <div
                className={`form-input-filter border-radius-3 ${
                  !dropdownLoading ? 'select-arrow' : 'loading-input'
                }`}
              >
                <select
                  className="input border-transparent appearance-none"
                  value={selectFilter}
                  onChange={this.handleSelect}
                  disabled={dropdownLoading}
                >
                  <option default value={-1}>
                    {dropdownDefaultText || 'Vui lòng chọn'}
                  </option>
                  {!!dropdownData &&
                    !!dropdownData.length &&
                    dropdownData.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          <div className={`text-filter align-items-end`}>
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent pr-40 input-search-filter"
                name="textSearch"
                type="text"
                value={textFilter}
                onChange={this.handleInputChange}
                placeholder={inputPlaceholder}
                maxLength={255}
                onKeyDown={this.handleInputChange}
              />
              {!!textFilter && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.handleClearText}
                />
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0" />
              </Button>
            </div>
            <Button
              onClick={this.handleClearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0" />
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilter
