import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { Cookies } from 'react-cookie'
import classNames from 'classnames'

import { SITE_KEY } from 'constants/Api'

const CONGVANDEN = '1'
const CONGVIEC_PHONGBAN = '2'
const CONGVIEC_CANHAN = '3'
const KYSO = '4'
const KYSO_NOIBO = '5'
const CONGVANDI_THUHOI = '10'
const KYSO_TAPTRUNG = '6'
const HO_SO_CONG_VIEC = '11'
const QUANLY_CONGVIEC = '100'
class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isViewPassword: false,
      username: '',
      password: '',
      isLoading: false,
      countError: 0,
      reReCaptcha: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAuth = this.handleAuth.bind(this)
    this.handlePermission = this.handlePermission.bind(this)
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this)
  }

  componentDidMount = () => {
    this.getMyLocation()
  }

  componentWillMount = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.token
    ) {
      const { token, type, roleId, workid } = this.props.location.state
      this.props.actions.authLogout()
      this.props.actions.setTokenWithQuery(token)

      this.handleAuth({})
        .then(async res => {
          if (!roleId) {
            return this.handlePermission(res, type)
          }

          await this.props.actions.switchRoleUser({ roleId })
          await this.props.actions.userPermission(roleId)
        })
        .then(() => {
          let loginDirect = '/'
          let typeSidebar = 'utilities'
          switch (type) {
            case CONGVANDEN:
              loginDirect = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
              typeSidebar = 'documentary'
              break
            case CONGVIEC_PHONGBAN:
              loginDirect = '/cong-viec/phong-ban/danh-sach/chua-giao'
              typeSidebar = 'work'
              break
            case CONGVIEC_CANHAN:
              loginDirect = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
              typeSidebar = 'work'
              break
            case KYSO:
              loginDirect = '/ky-so/cong-van-di/danh-sach'
              typeSidebar = 'signNumber'
              break
            case KYSO_NOIBO:
              loginDirect = '/ky-so/cong-van-noi-bo/danh-sach'
              typeSidebar = 'signNumber'
              break
            case CONGVANDI_THUHOI:
              loginDirect = '/cong-van/cong-van-di-thu-hoi/danh-sach'
              typeSidebar = 'documentary'
              break
            case KYSO_TAPTRUNG:
              loginDirect = '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach'
              typeSidebar = 'signNumber'
              break
            case HO_SO_CONG_VIEC:
              loginDirect = '/ho-so-cong-viec/danh-sach/cho-xu-ly'
              typeSidebar = 'documentsCabinet'
              break
            case QUANLY_CONGVIEC:
              if (workid) {
                loginDirect = `/quan-ly-cong-viec/duoc-giao/chi-tiet/${workid}`
              } else {
                loginDirect = '/quan-ly-cong-viec/duoc-giao/'
              }

              typeSidebar = 'taskManager'
              break
            default:
          }
          this.props.actions.commonSaveTypeSidebar(typeSidebar)
          return this.props.history.push(loginDirect)
        })
    }
  }

  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition(
        position => {
          localStorage['latitude'] = position.coords.latitude
          localStorage['longitude'] = position.coords.longitude
        },
        error => {
          this.setState({ latitude: '', longitude: '' })
          localStorage['latitude'] = ''
          localStorage['longitude'] = ''
        }
      )
    }
  }

  generateUUID() {
    let d = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = ~~((d + Math.random() * 16) % 16)
        d = ~~(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
    return uuid
  }

  messageConvert = error => {
    if (error?.data === 'Network Error') {
      return 'Mất kết nối internet, vui lòng thử lại'
    } else if (
      error?.response?.data?.errorDescription === 'invalid_username_or_password'
    ) {
      return 'Tài khoản đăng nhập hoặc mật khẩu không đúng'
    } else if (!error?.response?.data?.errorDescription) {
      return 'Đăng nhập không thành công, vui lòng thử lại'
    }

    return error?.response?.data?.errorDescription
  }

  get_device() {
    var module = {
      options: [],
      header: [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera,
      ],
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],

      init: function () {
        var agent = this.header.join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser)
        return { os: os, browser: browser }
      },
      matchItem: function (string, data) {
        var i = 0,
          j = 0,
          regex,
          regexv,
          match,
          matches,
          version
        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i')
          match = regex.test(string)
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
            matches = string.match(regexv)
            version = ''
            if (matches) {
              if (matches[1]) {
                matches = matches[1]
              }
            }
            if (matches) {
              matches = matches.split(/[._]+/)
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.'
                } else {
                  version += matches[j]
                }
              }
            } else {
              version = '0'
            }
            return {
              name: data[i].name,
              version: parseFloat(version),
            }
          }
        }
        return { name: 'unknown', version: 0 }
      },
    }
    var e = module.init(),
      debug = ''
    debug += '' + e.os.name + ' '
    debug += '' + e.os.version + ', '
    debug += '' + e.browser.name + ''
    return debug
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      error: '',
    })
  }

  handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }

    const { reReCaptcha } = this.state
    this.setState({ isLoading: true }, () => {
      this.props.actions
        .authLogin({
          username: this.state.username,
          password: this.state.password,
          r: reReCaptcha ? reReCaptcha : null,
        })
        .then(this.handleAuth)
    })
  }

  handleAuth = async res => {
    let countError = this.state.countError
    if (res.error) {
      countError++
      return this.setState({
        error: this.messageConvert(res?.error),
        isLoading: false,
        countError,
      })
    }

    return await this.props.actions.userAuthenticated().then(res => {
      if (res.error || !res.payload.data.result.result) {
        return this.setState({
          isLoading: false,
          error: 'Đăng nhập không thành công, vui lòng thử lại',
        })
      }
      this.handlePermission(res)
    })
  }

  checkLoginLanDau(username) {
    // check login lan dau
    const cookies = new Cookies()
    const checkin_maxacnhan_cookies = cookies.get('checkin_maxacnhan_cookies')
      ? cookies.get('checkin_maxacnhan_cookies')
      : ''
    // const isNewLogin = username !== localStorage['user_login']
    localStorage['user_login'] = username
    let MA_XACNHAN = localStorage['checkin_maxacnhan']
    if (
      checkin_maxacnhan_cookies === undefined ||
      checkin_maxacnhan_cookies === '' ||
      checkin_maxacnhan_cookies === null ||
      checkin_maxacnhan_cookies === 'null'
    ) {
      cookies.set('checkin_maxacnhan_cookies', this.generateUUID(), {
        path: '/',
      })
    }

    if (
      MA_XACNHAN === undefined ||
      MA_XACNHAN === '' ||
      MA_XACNHAN === null ||
      MA_XACNHAN === 'null'
    ) {
      localStorage['checkin_maxacnhan'] = this.generateUUID()
      MA_XACNHAN = localStorage['checkin_maxacnhan']
    }
  }

  async handlePermission(res, type) {
    if (
      !res ||
      res.error ||
      !res.payload.data ||
      !res.payload.data.result ||
      !res.payload.data.entities.users
    ) {
      return this.setState({
        isLoading: false,
        error: 'Đăng nhập không thành công, vui lòng thử lại',
      })
    }

    const user = res.payload.data.entities.users[res.payload.data.result.result]
    const defaultApartmend = user.dsChucDanh.find(
      item => item.phongBanChinh === true
    )
    this.checkLoginLanDau(user.username)
    const actions = this.props.actions
    actions.commonFetchPriorities()
    actions.commonFetchSecurities()
    actions.commonFetchObjects()
    actions.commonFetchDepartmentsLeader()
    await actions.userPermission(defaultApartmend.id).then(async resPers => {
      if (resPers.error) {
        return this.setState({
          isLoading: false,
          error: 'Đăng nhập không thành công, vui lòng thử lại',
        })
      }

      let loginDirect = '/'
      let typeSidebar = 'HomePage'

      !type && actions.commonSaveTypeSidebar(typeSidebar)
      !type && this.props.history.push(loginDirect)
    })
  }

  resetRecaptcha() {
    if (this.recaptchaRef) {
      this.recaptchaRef.reset()
    }
  }

  onChangeRecaptcha = value => {
    if (value) {
      this.setState(
        {
          reReCaptcha: value,
        },
        () => {
          this.handleSubmit()
        }
      )
    }
  }

  asyncScriptOnLoad = () => {}

  onErrored = () => {}

  handleViewPassword = () => {
    this.setState({
      isViewPassword: !this.state.isViewPassword,
    })
  }

  render() {
    const isViewPassword = this.state.isViewPassword
    return (
      <form className="page-login-form" onSubmit={this.handleSubmit}>
        <div className="page-login-form-control">
          <label htmlFor="username">Tên truy cập</label>
          <input
            type="text"
            id="username"
            name="username"
            onChange={this.handleChange}
            placeholder="Nhập tên truy cập"
            autoFocus={true}
          />
        </div>
        <div className="page-login-form-control mt25">
          <label htmlFor="password">Mật khẩu</label>
          <div className="input-password">
            <input
              type={isViewPassword ? 'text' : 'password'}
              id="password"
              name="password"
              onChange={this.handleChange}
              placeholder="Nhập mật khẩu"
            />
            <span
              className={classNames('security-eye', {
                'icon2-eye-default': isViewPassword,
                'icon2-security-eye': !isViewPassword,
              })}
              onClick={this.handleViewPassword}
            />
          </div>
          {this.state.error && (
            <div className="page-login-form-text-error">{this.state.error}</div>
          )}
        </div>
        {this.state.countError >= 5 && SITE_KEY && (
          <div className="recaptcha">
            <ReCAPTCHA
              hl="vi"
              ref={e => (this.recaptchaRef = e)}
              sitekey={SITE_KEY}
              asyncScriptOnLoad={this.asyncScriptOnLoad}
              onErrored={this.onErrored}
              onChange={this.onChangeRecaptcha}
            />
          </div>
        )}
        <div className="page-login-form-action-control">
          <button
            type="submit"
            disabled={
              !this.state.username.trim() ||
              !this.state.password.trim() ||
              this.state.countError >= 5 ||
              this.state.isLoading
            }
          >
            <span>Đăng nhập</span>
            <div className="icon">
              {this.state.isLoading && (
                <span className="icon2-loading loading"></span>
              )}
            </div>
          </button>
        </div>
      </form>
    )
  }
}

LoginForm.propTypes = {
  actions: PropTypes.object.isRequired,
}

export default withRouter(LoginForm)
