import React, { Component } from 'react'
import { Button, Checkbox, Intent } from '@blueprintjs/core'
import { MasterLayout } from 'components/layout'
import { commonAddToasterMessage } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, size, isEmpty } from 'lodash'
import { Upload, FileGroup } from 'components/newCommon'
import { imagesTypes, allowImagesFileTypes } from 'constants/fileTypes'
import {
  postUpload,
  postSliders,
  putSliders,
  getChiTietSlider,
} from 'actions/task'
import { Action, Toast, ValidateForm } from 'constants/MessageForm'

const TAO_BANNER = 'Tạo mới banner / bài viết'
const SUA_BANNER = 'Chỉnh sửa banner / bài viết'
class UpdateBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: get(this.props.match, 'params.id', undefined),
      data: {
        isActive: true,
      },
      filesUpload: [],
      filesOld: null,
      error: {},
      isLoadingButton: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    let id = this.state.id
    if (!id) {
      return
    }

    const response = await getChiTietSlider(id)
    if (get(response, 'payload.data.result')) {
      this.setState({
        data: {
          ...get(response, 'payload.data.result'),
          file: get(response, 'payload.data.result.file.id', null),
        },
        filesOld: get(response, 'payload.data.result.file', null),
      })
    }
  }

  handleInputChange = e => {
    const { data } = this.state
    const name = e.target.name
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    })
  }

  // Change file
  changeFile = filesUpload => {
    // Kiểm tra dung lượng có lớn hơn 5MB
    let filesCheck = []
    let newError = ValidateForm.IS_REQUIRED
    let filesOld = this.state.filesOld
    if (size(filesUpload) && filesUpload[0].size <= 5242880) {
      filesCheck = filesUpload
      filesOld = null
      newError = undefined
    }

    this.setState({
      filesUpload: filesCheck,
      filesOld,
      error: {
        ...this.state.error,
        file: newError,
      },
    })
  }

  checkData = () => {
    const { data, filesUpload } = this.state
    let result = true
    let newError = {}
    if (isEmpty(get(data, 'title'))) {
      result = false
      newError.title = ValidateForm.IS_REQUIRED
    }

    if (!size(filesUpload) && !get(data, 'file')) {
      result = false
      newError.file = ValidateForm.IS_REQUIRED
    }

    this.setState({
      error: newError,
    })

    return result
  }

  // Upload file
  upload = async () => {
    let { filesUpload, data } = this.state
    let uploadFiles = Object.values(filesUpload).flat()
    let responseFileId = get(data, 'file', null)
    if (!size(uploadFiles)) {
      return responseFileId
    }

    let result = await postUpload(filesUpload)
    if (
      get(result, 'payload.data.result') &&
      get(result, 'payload.data.result').length !== 0
    ) {
      responseFileId = get(result, 'payload.data.result[0].id')
    }

    return responseFileId
  }

  // Submit dữ liệu
  handleSubmit = async () => {
    const { data, id } = this.state
    const checkData = this.checkData()
    if (!checkData) {
      return
    }

    try {
      this.setState({ isLoadingButton: true })
      let fileId = await this.upload()
      let dataParams = {
        id: get(data, 'id', null),
        title: get(data, 'title'),
        file: fileId,
        url: get(data, 'url'),
        is_active: get(data, 'isActive'),
      }
      let response = null
      if (!id) {
        response = await postSliders(dataParams)
      } else {
        response = await putSliders(dataParams)
      }

      if (response) {
        this.props.actions.commonAddToasterMessage({
          message: Toast.SUCCESS(Action.CREATE),
          intent: Intent.SUCCESS,
        })
        this.goBack()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: Toast.FAIL(Action.CREATE),
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
    }
  }

  // Xóa file cũ khi cập nhật dữ liệu form
  removeFile = () => {
    this.setState({
      filesOld: null,
      data: {
        ...this.state.data,
        file: null,
      },
    })
  }

  goBack = () => {
    return this.props.history.push(
      '/he-thong/quan-ly-cong-viec/banner/danh-sach'
    )
  }

  render() {
    const { data, filesUpload, filesOld, error, isLoadingButton } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <div className="heading-block">
              <span className="headding-inline">
                <span className="icon-headding icon-files-image"></span>
                <span className="text-headding">
                  {this.state.id ? SUA_BANNER : TAO_BANNER}
                </span>
              </span>
            </div>
            <div className="systerm-main-content">
              <div className="form-container">
                <form className="main-form">
                  <div className="form-block place-block">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label className="label required">Tiêu đề</label>
                          <input
                            type="text"
                            name="title"
                            className="input-control"
                            placeholder="Nhập tiêu đề"
                            onChange={this.handleInputChange}
                            value={get(data, 'title', '')}
                          />
                          {get(error, 'title') && (
                            <div className="form-helper-text">
                              {error.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label className="label">Link bài viết</label>
                          <input
                            type="text"
                            name="url"
                            className="input-control"
                            placeholder="Nhập link"
                            onChange={this.handleInputChange}
                            value={get(data, 'url', '')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-xs-12">
                        <div className="form-group">
                          <div className="label-instruction mb10">
                            <label className="label required mb5">
                              File đính kèm
                            </label>
                            <span className="instruction">{`(chỉ hỗ trợ định dạng .jpg, .png dung lượng <5MB)`}</span>
                          </div>
                          <Upload
                            accept={imagesTypes.toString()}
                            allowFileTypes={allowImagesFileTypes}
                            onChange={this.changeFile}
                            isOnly
                            fileList={filesUpload || []}
                            maxSize={5}
                          />
                          <FileGroup
                            list={!isEmpty(filesOld) ? [filesOld] : []}
                            onClose={this.removeFile}
                            showClose
                          />
                          {get(error, 'file') && (
                            <div className="form-helper-text">{error.file}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12">
                        <div className="form-group form-group-inline mt40">
                          <label className="label mr10">Hiển thị</label>
                          <Checkbox
                            name="isActive"
                            checked={get(data, 'isActive')}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-block">
                    <div className="buttton-action-footer">
                      <Button
                        type="button"
                        onClick={this.goBack}
                        className="pt-button button-default"
                        loading={isLoadingButton}
                        disabled={isLoadingButton}
                      >
                        <span className="pt-icon icon-back ml5"></span>
                        <span className="text-content mr15">Quay lại</span>
                      </Button>
                      <Button
                        type="button"
                        onClick={this.handleSubmit}
                        className="pt-button btn-main-color"
                        loading={isLoadingButton}
                        disabled={isLoadingButton}
                      >
                        <span className="text-content ml5">Lưu</span>
                        <span className="pt-icon icon-save mr15"></span>
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(UpdateBanner)
