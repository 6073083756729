import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
// import AssignmentInformation from './AssignmentInformation';
// import AssignmentHistory from './AssignmentHistory';
import classnames from 'classnames'
import { Authorization, Permission } from '../auth'
// import InformationSteering from '../common/InformationSteering';
import { LoadingItem } from '../common/'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'
import { FileList } from '../../components/common'

const CHUA_GIAO = 0
const DA_GIAO = 1

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.openPopup = this.openPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleSubmitAlertMistake = this.handleSubmitAlertMistake.bind(this)
  }
  static propTypes = {
    assignmentInformation: PropTypes.object,
    archives: PropTypes.array.isRequired,
    onClickSaveDocument: PropTypes.func,
    onClickSendMail: PropTypes.func,
    onClickAssign: PropTypes.func.isRequired,
    onHandleSubmit: PropTypes.func.isRequired,
    onClickExecutingView: PropTypes.func.isRequired,
    work: PropTypes.object,
    actions: PropTypes.object.isRequired,
    disableAlertMistake: PropTypes.bool,
  }

  state = {
    showBoxAction: false,
    isSubmit: false,
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  handleClickComplete(id) {
    this.setState({ isSubmit: true }, () => {
      this.props.handleClickComplete && this.props.handleClickComplete(id)
    })
  }

  openPopup(e, i) {
    if (i === 1) {
      this.setState({
        isOpenPopup: true,
        isCheck: false,
      })
    }
    if (i === 2) {
      this.setState({
        isOpenPopup: true,
        isCheck: true,
      })
    }
  }

  closePopup() {
    this.setState({
      isOpenPopup: false,
    })
  }

  handleSubmitAlertMistake(lyDo) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.onHandleSubmitRemoveItem &&
          this.props.onHandleSubmitRemoveItem(2, lyDo)
      }
    )
  }

  handleSubmitRemoveItem = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.onHandleSubmitRemoveItem &&
          this.props.onHandleSubmitRemoveItem(1, null)
      }
    )
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    let chucDanhId = this.props.auth ? this.props.auth.roleId : null
    if (
      this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined
    ) {
      return <LoadingItem />
    }
    return (
      <div className="list-action">
        <div>
          <p className="title">Thực Hiện</p>
          <span
            className="icon-More show-th-mobile"
            onClick={this.showBoxAction.bind(this)}
          ></span>
          {this.props.work &&
            this.props.work.fileDinhKemEvn &&
            this.props.work.fileDinhKemEvn.length > 0 && (
              <div className="detail-info-container section-box-info">
                <div className="list-items">
                  <div className="row item-value">
                    <div className="col-xs-3 left-content">
                      <span className="label-text">
                        File đính kèm ({this.props.work.fileDinhKemEvn.length}):
                      </span>
                    </div>
                    <div className="col-xs-9 right-content">
                      <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={150}
                      >
                        <span className="detail">
                          {this.props.work.fileDinhKemEvn.map((value, item) => {
                            let extensionTK = null
                            const fileDinhKem = value.fileName
                            if (fileDinhKem) {
                              extensionTK = fileDinhKem
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  {!value.migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extensionTK
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    >
                                      {value.tenFile} -{' '}
                                      {Tool.Utils.getFileSize(value.kichThuoc)}
                                    </a>
                                  )}
                                </span>
                              </div>
                            )
                          })}
                        </span>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {Tool.Utils.checkBanTongHop(this.props.auth.mainDepartmentId) &&
            this.props.filesThamKhao &&
            this.props.filesThamKhao.length !== 0 && (
              <div className="pl20 pr20">
                <label className="pt-label label-text-detail">
                  File tham khảo ({this.props.filesThamKhao.length}):
                </label>
                <div className="pt-form-content">
                  <div className="pl10">
                    <FileList
                      auth={this.props.auth}
                      receiveDocument={{
                        fileThamKhao: this.props.filesThamKhao,
                      }}
                      field="fileThamKhao"
                      maxHeightScroll={150}
                    />
                  </div>
                </div>
              </div>
            )}
          <div
            className={classnames('box-action', {
              'box-action-open': this.state.showBoxAction,
            })}
          >
            <div className="close-mobile-menu-detail">
              <button onClick={this.showBoxAction.bind(this)}>
                <span className="pt-icon pt-icon-cross"></span>
              </button>
            </div>
            {/*Action Menu Chua Giao*/}
            {this.props.work &&
              this.props.work.trangThai === CHUA_GIAO &&
              !this.props.status && (
                <div className="row list-action-detail">
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickInformationAssign}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Thông tin chỉ đạo</span>
                      </button>
                    </div>
                  )}

                  <Authorization.Element
                    permission={[Permission.CONGVIEC_GIAOVIEC]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickAssign}
                      >
                        <i className="icon-Giao_Viec icon-cpc"></i>
                        <span className="action-name">Giao việc</span>
                      </button>
                    </div>
                  </Authorization.Element>

                  {this.props.departmentWorks &&
                    this.props.departmentWorks.item &&
                    this.props.departmentWorks.item.congViecPhongBanId &&
                    this.props.departmentWorks.item.trangThai === 0 && (
                      <Authorization.Element
                        permission={[Permission.CONGVIEC_PHONGBAN_HOANTAT]}
                      >
                        <div className="col-xs-4 col-sm-6 col-md-6 item">
                          <button
                            className="click-item"
                            disabled={this.state.isSubmit}
                            onClick={this.handleClickComplete.bind(
                              this,
                              this.props.departmentWorks.item.congViecPhongBanId
                            )}
                          >
                            <i className="icon-cpc icon-edit-line icon-done-work"></i>
                            <span className="action-name">
                              Hoàn tất công việc
                            </span>
                          </button>
                        </div>
                      </Authorization.Element>
                    )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSaveDocument}
                      >
                        <i className="icon-Luututailieu icon-cpc"></i>
                        <span className="action-name">Lưu tủ tài liệu</span>
                      </button>
                    </div>
                  )}
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickExecutingView}
                    >
                      <i className="icon-Xemthuchien icon-cpc"></i>
                      <span className="action-name">Xem thực hiện</span>
                    </button>
                  </div>

                  {!this.props.work.baoNham &&
                    (!this.props.work.congVanNoiBoId ||
                      (this.props.work.congVanNoiBoId &&
                        this.props.work.phieuGiaoViecChinhId)) &&
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    ) && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickAlertMistake}
                        >
                          <i className="icon-attention-warning icon-cpc"></i>
                          <span className="action-name">Báo nhầm</span>
                        </button>
                      </div>
                    )}

                  {this.props.work.baoNham &&
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    ) && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickCancelAlertMistake}
                        >
                          <i className="icon-attention-warning icon-cpc"></i>
                          <span className="action-name">Hủy báo nhầm</span>
                        </button>
                      </div>
                    )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSendMail}
                      >
                        <i className="icon-Gmail icon-cpc"></i>
                        <span className="action-name">Gửi email</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickInformationWork}
                      >
                        <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                        <span className="action-name">Thông tin giao việc</span>
                      </button>
                    </div>
                  )}
                  {(this.props.work.congVanNoiBoId ||
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    )) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={
                          this.props.work.congVanNoiBoId
                            ? this.props.onClickFlowdocumentary
                            : this.props.onClickFlowDocument
                        }
                      >
                        <i className="icon-organization icon-cpc"></i>
                        <span className="action-name">Luồng công văn</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.sendToChat}
                      >
                        <i className="icon-speech-bubble icon-cpc"></i>
                        <span className="action-name">Gửi Chat</span>
                      </button>
                    </div>
                  )}
                </div>
              )}
            {/*Action Menu Da Giao*/}
            {this.props.work &&
              this.props.work.trangThai === DA_GIAO &&
              !this.props.status && (
                <div className="row list-action-detail">
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickInformationWork}
                      >
                        <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                        <span className="action-name">Thông tin giao việc</span>
                      </button>
                    </div>
                  )}
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickExecutingView}
                    >
                      <i className="icon-Xemthuchien icon-cpc"></i>
                      <span className="action-name">Xem thực hiện</span>
                    </button>
                  </div>

                  {/* TODO: Sử dụng field nguoiGiaoId trong chi tiết CVPB để  kiểm tra */}
                  {this.props.work.nguoiGiaoId === chucDanhId && (
                    <Authorization.Element
                      permission={[Permission.CONGVIEC_GIAOVIEC]}
                    >
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickUpdateAssignment}
                        >
                          <i className="icon-Giao_Viec icon-cpc"></i>
                          <span className="action-name">Sửa/Hủy giao việc</span>
                        </button>
                      </div>
                    </Authorization.Element>
                  )}

                  {/* TODO: Sử dụng field nguoiGiaoId trong chi tiết CVPB để  kiểm tra */}
                  {this.props.work.nguoiGiaoId && (
                    <Authorization.Element
                      permission={[Permission.CONGVIEC_GIAOVIEC]}
                    >
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickAdditionalAssign}
                        >
                          <i className="icon-Giao_Viec icon-cpc"></i>
                          <span className="action-name">Giao việc bổ sung</span>
                        </button>
                      </div>
                    </Authorization.Element>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickInformationAssign}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Thông tin chỉ đạo</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSaveDocument}
                      >
                        <i className="icon-Luututailieu icon-cpc"></i>
                        <span className="action-name">Lưu tủ tài liệu</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSendMail}
                      >
                        <i className="icon-Gmail icon-cpc"></i>
                        <span className="action-name">Gửi email</span>
                      </button>
                    </div>
                  )}
                  {(this.props.work.congVanNoiBoId ||
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    )) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={
                          this.props.work.congVanNoiBoId
                            ? this.props.onClickFlowdocumentary
                            : this.props.onClickFlowDocument
                        }
                      >
                        <i className="icon-organization icon-cpc"></i>
                        <span className="action-name">Luồng công văn</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.sendToChat}
                      >
                        <i className="icon-speech-bubble icon-cpc"></i>
                        <span className="action-name">Gửi Chat</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

            {this.props.status && (
              <div className="row list-action-detail">
                {this.props.work.nguoiGiaoId === chucDanhId && (
                  <Authorization.Element
                    permission={[Permission.CONGVIEC_GIAOVIEC]}
                  >
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickUpdateAssignment}
                      >
                        <i className="icon-Giao_Viec icon-cpc"></i>
                        <span className="action-name">Giao lại</span>
                      </button>
                    </div>
                  </Authorization.Element>
                )}
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={() => this.openPopup(this, 1)}
                  >
                    <span
                      ref={el => {
                        if (el) {
                          el.style.setProperty(
                            'font-family',
                            'Icons20',
                            'important'
                          )
                        }
                      }}
                      className="pt-icon pt-icon-trash icon-cpc"
                    ></span>
                    <span className="action-name">Huỷ báo nhầm</span>
                  </button>
                </div>
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={() => this.openPopup(this, 2)}
                  >
                    <span className="pt-icon icon-attention-warning icon-cpc"></span>
                    <span className="action-name">Báo nhầm cấp trên</span>
                  </button>
                </div>
              </div>
            )}
            {/* <div className="col-xs-4 col-sm-6 col-md-6 item">
                <a className="click-item" onClick={this.props.onClickAssignmentHistory}>
                  <i className="icon-Lichsuchidao icon-cpc"></i>
                  <span className="action-name">Lịch sử chỉ đạo</span>
                </button>
              </div> */}
            {/* onClick={this.props.onClickSaveDocument} */}

            {/* <div className="col-xs-4 col-sm-6 col-md-6 item">
                <a  className="click-item" onClick={this.props.onClickDetailInfo}>
                  <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                  <span className="action-name">Thông tin công văn</span>
                </button>
              </div> */}

            {/* <div className="col-xs-4 col-sm-6 col-md-6 item history-steering-mobile">
                <a className="click-item" onClick={this.props.onClickReportWork}>
                  <i className="icon-Ban_Bao_Cao icon-cpc"></i>
                <span className="action-name">Bản báo cáo công việc</span>
                </button>
              </div> */}

            {/* TODO: Chuyển sang gọi api get Thông tin chỉ đạo khi click action */}
            {/* { this.props.informationSteering &&
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button className="click-item" onClick={this.props.onClickInformationAssign}>
                    <i className="icon-Chidao icon-cpc"></i>
                  <span className="action-name">Thông tin chỉ đạo</span>
                  </button>
                </div>
              } */}

            {/* TODO: Chuyển sang gọi api get Thông tin giao việc khi click action */}
            {/* { this.props.assignmentInformation && this.props.work && this.props.work.trangThai !== CHUA_GIAO &&
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button className="click-item" onClick={this.props.onClickInformationWork}>
                    <i className="icon-Thong_Tin_Giao_Viec color-main icon-cpc"></i>
                  <span className="action-name">Thông tin giao việc</span>
                  </button>
                </div>
              } */}
          </div>
        </div>

        {/* this.props.isLoadingData &&
          !this.props.isLoadingData.assignmentInformation &&
          this.props.assignmentInformation &&
          this.props.assignmentInformation.hasOwnProperty('phieuGiaoViecId') &&
          <AssignmentInformation
            assignmentInformation={this.props.assignmentInformation}
            onHandleSubmit={this.props.onHandleSubmit}
            type={this.props.type}
            auth={this.props.auth}
            isLeaderUnit={this.props.isLeaderUnit}
          />
        */}

        {/* { this.props.assignmentHistory &&
          <AssignmentHistory
            assignmentHistory={this.props.assignmentHistory}
            work={this.props.work}
            actions={this.props.actions}
          />
        } */}
        {/* this.props.isLoadingData &&
          !this.props.isLoadingData.informationSteering &&
          this.props.informationSteering &&
          this.props.informationSteering.hasOwnProperty('chiDaoId') &&
          <InformationSteering
            isShowMobile={this.props.showInformationSteering}
            closeModulesDetail={this.closeModulesDetail}
            informationSteering={this.props.informationSteering}
          />
        */}
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          textarea={this.state.isCheck ? true : false}
          onClose={this.closePopup}
          isCheck={true}
          title={
            this.state.isCheck ? POPUP.BAO_NHAM_CAP_TREN : POPUP.HUY_CHUYEN_NHAM
          }
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={
            this.state.isCheck
              ? this.handleSubmitAlertMistake
              : this.handleSubmitRemoveItem
          }
          isLoading={this.state.isLoading}
        >
          {POPUP.HUY_BAO_NHAM_CONFIRM}
        </PopupConfirm>
      </div>
    )
  }
}

export default DetailBoxAction
