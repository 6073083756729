import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { UnitCategory } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Utils } from '../../helpers'

const LINK = '/he-thong/don-vi/them-moi'
const LINK_REDIRECT = '/he-thong/don-vi/danh-muc'
class UnitCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitRemove = this.handleSubmitRemove.bind(this)
    this.handleSaveHeader = this.handleSaveHeader.bind(this)
  }

  state = {
    Unit: [],
    header: [{ id: '', tenVietTat: 'Danh mục' }],
    isRedirect: false,
    isLoading: true,
    isError: false,
  }

  componentDidMount() {
    let header = Utils.getData('UnitCategoryPage-Header', [])

    if (header === undefined) {
      header = this.state.header
    }

    this.setState({ header }, () => {
      if (this.props.match.params.id === undefined) {
        return this.props.actions.getCategoryUnit(true).then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
      }
    })

    if (this.props.match.params.id !== undefined) {
      let id = header[header.length - 1].id

      if (!id) {
        id = this.props.match.params.id
      }
      this.props.actions.getCategoryUnitChild(id, true).then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
        let header = Utils.getData('UnitCategoryPage-Header', [])
        if (res.payload && res.payload.data.result.items.length === 0) {
          header.splice(header.length - 1)
          this.setState({ header })
          Utils.saveData('UnitCategoryPage-Header', header)
          if (header.length === 1) {
            this.props.history.push(`${LINK_REDIRECT}`)
            return this.props.actions.getCategoryUnit(true).then(res => {
              if (res.error || (res.payload && res.payload.status !== 200)) {
                this.setState({ isError: true, isLoading: false })
              }
            })
          }
          this.props.history.push(
            `${LINK_REDIRECT}/${header[header.length - 1].id}`
          )
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.unitChild.items !== this.props.unitChild.items) {
      if (this.props.unitChild.items.length === 0) {
        return
      }

      const header = this.state.header
      if (
        header.find(
          item =>
            item.id === this.props.unitChild.items[0].donViCha &&
            item.tenVietTat === this.props.unitChild.items[0].tenVietTatDonViCha
        ) === undefined
      ) {
        header.push({
          id: this.props.unitChild.items[0].donViCha,
          tenVietTat: this.props.unitChild.items[0].tenVietTatDonViCha,
        })
      }
      Utils.saveData('UnitCategoryPage-Header', header)
      this.setState({
        Unit: this.props.unitChild.items,
        header,
        isLoading: false,
      })
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.actions
        .getCategoryUnitChild(this.props.match.params.id, true)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
          if (res.payload && res.payload.data.result.items.length === 0) {
            this.props.history.goBack()
          }
        })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unit.items !== this.props.unit.items) {
      this.setState(
        {
          Unit: nextProps.unit.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    }

    if (nextProps.unit.items === this.props.unit.items) {
      this.setState({ isLoading: false })
    }
  }

  handleSubmit(id) {
    this.setState({ isLoading: true })
  }

  handleSaveHeader(header) {
    this.setState({ header })
  }

  handleRemoveHeader(i) {
    const header = this.state.header

    header.splice(i + 1)

    this.setState({ header, isLoading: true })
    Utils.saveData('UnitCategoryPage-Header', header)
  }

  handleSubmitRemove() {
    this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Danh mục đơn vị"
          iconPage="icon-departments"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div className="dispatch-code-container"></div>
        <div className="listrole-container">
          <ul className="pt-breadcrumbs breadcrumb-path">
            {this.state.header.map((item, i) => (
              <li key={i}>
                <Link
                  className={classnames('pt-breadcrumb', {
                    'pt-breadcrumb-current': this.state.header.length === i + 1,
                  })}
                  to={`${LINK_REDIRECT}/${this.state.header[i].id}`}
                  onClick={this.handleRemoveHeader.bind(this, i)}
                >
                  {this.state.header[i].tenVietTat}
                </Link>
              </li>
            ))}
          </ul>
          <UnitCategory
            items={this.state.Unit}
            actions={this.props.actions}
            onSubmit={this.handleSubmit}
            onRemove={this.handleSubmitRemove}
            match={this.props.match}
            saveHeader={this.handleSaveHeader}
            history={this.props.history}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          ></UnitCategory>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  unit: {
    ...state.categoryUnit,
    items: state.category.categoryUnit,
  },

  unitChild: {
    ...state.categoryUnitChild,
    items: state.category.categoryUnitChild,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UnitCategoryPage)
