import * as types from '../constants/ActionTypes'
const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
}

export default function departmentWorksAssigned(state = initialState, action) {
  switch (action.type) {
    case types.DEPARTMENT_WORK_ASSIGNED:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    default:
      return state
  }
}
