import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Tooltip } from '@blueprintjs/core'

import variables from 'constants/variables'
import { ResetButton, SearchInput, DatePicker } from 'components/newCommon'

const Filter = ({ filterKeys, dataValue, onChangeFilter }) => {
  return (
    <div className="bg-white ui-filter-layout">
      <div>
        <div className="date-block">
          <DatePicker
            placeholderText="Từ ngày"
            isClearable={true}
            selectsStart
            startDate={
              dataValue?.[filterKeys.from]
                ? moment(dataValue?.[filterKeys.from])
                : undefined
            }
            endDate={
              dataValue?.[filterKeys.to]
                ? moment(dataValue?.[filterKeys.to])
                : undefined
            }
            selected={
              dataValue?.[filterKeys.from]
                ? moment(dataValue?.[filterKeys.from])
                : undefined
            }
            maxDate={
              dataValue?.[filterKeys.to]
                ? moment(dataValue?.[filterKeys.to])
                : undefined
            }
            onChange={value => {
              const newValue = value
                ? value.format(variables.DATE_FORMAT.DATE)
                : undefined
              onChangeFilter({ name: filterKeys.from })(newValue)
            }}
          />
          <DatePicker
            placeholderText="Đến ngày"
            isClearable={true}
            selectsEnd
            startDate={
              dataValue?.[filterKeys.from]
                ? moment(dataValue?.[filterKeys.from])
                : undefined
            }
            endDate={
              dataValue?.[filterKeys.to]
                ? moment(dataValue?.[filterKeys.to])
                : undefined
            }
            selected={
              dataValue?.[filterKeys.to]
                ? moment(dataValue?.[filterKeys.to])
                : undefined
            }
            minDate={
              dataValue?.[filterKeys.from]
                ? moment(dataValue?.[filterKeys.from])
                : undefined
            }
            onChange={value => {
              const newValue = value
                ? value.format(variables.DATE_FORMAT.DATE)
                : undefined
              onChangeFilter({ name: filterKeys.to })(newValue)
            }}
          />
        </div>
      </div>
      <div className="grow-1 search-submit">
        <SearchInput
          placeholder="Nhập tiêu đề"
          onChange={onChangeFilter({
            name: filterKeys.textSearch,
            isReFetch: false,
          })}
          onSearch={onChangeFilter({ name: filterKeys.textSearch })}
          value={dataValue?.[filterKeys.textSearch]}
          leftIcon="icon2-search"
        />
        <Tooltip content={<span className="font-size-12">Làm mới</span>}>
          <ResetButton onClick={onChangeFilter({ name: 'RESET' })} />
        </Tooltip>
      </div>
    </div>
  )
}

Filter.propTypes = {
  filterKeys: PropTypes.object,
  dataValue: PropTypes.object,
  onChangeFilter: PropTypes.func,
}

Filter.defaultProps = {
  filterKeys: null,
  dataValue: null,
  onChangeFilter: () => {},
}

export default Filter
