import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NotFound } from '../common'
import moment from 'moment'
import * as Types from '../../constants/Api'
import classnames from 'classnames'
import { Collapse } from '@blueprintjs/core'
import FlowReceiveComment from './FlowReceiveComment'

class FlowRequestComments extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    flowRequestComment: PropTypes.object.isRequired,
    onClickDepartment: PropTypes.func,
  }

  state = {
    isOpen: {
      gopyBan: true,
      gopyDonVi: true,
    },
  }

  async handleClick(item) {
    const isLayYKienGoi = this.props.type === 'layYKienGoi'
    if (item === 'gopyBan' || item === 'gopyDonVi') {
      this.setState({
        isOpen: {
          ...this.state.isOpen,
          [item]: !this.state.isOpen[item],
        },
      })
    } else {
      //bổ sung phiếu gởi mới xem được luồng của đơn vị khác isLayYKienGoi== true
      if (
        !this.state.isOpen[item.id] &&
        item.tinhTrang > 1 &&
        item.loai === 1 &&
        (isLayYKienGoi ||
          (item.phongBan &&
            item.phongBan.phongBanId === this.props.auth.user.mainDepartmentId))
      ) {
        ;(await this.props.onClickDepartment) &&
          this.props.onClickDepartment(item.id)
      }
      if (
        !this.state.isOpen[item.id] &&
        item.tinhTrang > 0 &&
        item.loai === 2 &&
        (isLayYKienGoi ||
          (item.donVi &&
            item.donVi.donViId === this.props.auth.user.mainUnitId))
      ) {
        ;(await this.props.onClickDepartment) &&
          this.props.onClickDepartment(item.id)
      }
      await this.setState({
        isOpen: {
          ...this.state.isOpen,
          [item.id]: !this.state.isOpen[item.id],
        },
      })
    }
  }

  componentDidMount() {
    let phieuNhanPhongBan =
      this.props.flowRequestComment &&
      this.props.flowRequestComment.dsPhongBan.filter(
        item =>
          item.phongBan &&
          item.phongBan.phongBanId === this.props.auth.user.mainDepartmentId &&
          item.tinhTrang > 1
      )
    let phieuNhanDonVi =
      this.props.flowRequestComment &&
      this.props.flowRequestComment.dsDonVi.filter(
        item =>
          item.donVi.donViId === this.props.auth.user.mainUnitId &&
          item.tinhTrang > 0
      )
    if (phieuNhanPhongBan && phieuNhanPhongBan.length > 0) {
      this.handleClick(phieuNhanPhongBan[0])
    }
    if (phieuNhanDonVi && phieuNhanDonVi.length > 0) {
      this.handleClick(phieuNhanDonVi[0])
    }
  }

  render() {
    const flowReceiveComment = this.props.flowReceiveComment
    const flowRequestCommentBan =
      this.props.flowRequestComment && this.props.flowRequestComment.dsPhongBan
    const flowRequestCommentDonVi =
      this.props.flowRequestComment && this.props.flowRequestComment.dsDonVi
    const nguoiLap =
      this.props.flowRequestComment && this.props.flowRequestComment.nguoiLap
    const lanhDaoPhongBanChuTriDuyet =
      this.props.flowRequestComment &&
      this.props.flowRequestComment.lanhDaoPhongBanChuTriDuyet
    const lanhDaoDonViDuyet =
      this.props.flowRequestComment &&
      this.props.flowRequestComment.lanhDaoDonViDuyet
    const thuKyDuyet =
      this.props.flowRequestComment && this.props.flowRequestComment.thuKyDuyet
    const dsThuKyDuyet =
      this.props.flowRequestComment &&
      this.props.flowRequestComment.dsThuKyDuyet
    const isLayYKienGoi = this.props.type === 'layYKienGoi'
    const mainUnitId = this.props.auth.user.mainUnitId
    const mainDepartmentId = this.props.auth.user.mainDepartmentId
    return (
      <div className="container-form-action">
        {!this.props.hiddenName && (
          <div className="heading">
            <span className="click-go-back" onClick={this.props.onClickGoBack}>
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Luồng góp ý</h4>
          </div>
        )}
        <div className="form-container container-detail">
          {!flowRequestCommentBan && !flowRequestCommentBan ? (
            <NotFound />
          ) : (
            <div className="view-job-done">
              {nguoiLap && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            nguoiLap.anhDaiDien
                              ? Types.IMAGE_URL + nguoiLap.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">{nguoiLap.tenNhanVien}</span>
                    </div>
                  </div>
                  <div className="info-job">
                    {nguoiLap.thoiGian && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {nguoiLap.thoiGian &&
                            ' ' +
                              moment(nguoiLap.thoiGian).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {lanhDaoPhongBanChuTriDuyet && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            lanhDaoPhongBanChuTriDuyet.anhDaiDien
                              ? Types.IMAGE_URL +
                                lanhDaoPhongBanChuTriDuyet.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {lanhDaoPhongBanChuTriDuyet.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {lanhDaoPhongBanChuTriDuyet.thoiGian && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {lanhDaoPhongBanChuTriDuyet.thoiGian &&
                            ' ' +
                              moment(
                                lanhDaoPhongBanChuTriDuyet.thoiGian
                              ).format('DD/MM/YYYY HH:mm')}
                        </span>
                        <span className="content">
                          {lanhDaoPhongBanChuTriDuyet.trangThai === 1
                            ? ' -  Không đồng ý'
                            : lanhDaoPhongBanChuTriDuyet.trangThai === 2
                            ? '- Đồng ý '
                            : ''}
                        </span>
                        <span className="content">
                          {lanhDaoPhongBanChuTriDuyet.yKien
                            ? `  -  ${lanhDaoPhongBanChuTriDuyet.yKien}`
                            : ''}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {thuKyDuyet && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            thuKyDuyet.anhDaiDien
                              ? Types.IMAGE_URL + thuKyDuyet.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {thuKyDuyet.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {thuKyDuyet.thoiGian && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {thuKyDuyet.thoiGian &&
                            ' ' +
                              moment(thuKyDuyet.thoiGian).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                        </span>
                        <span className="content">
                          {thuKyDuyet.trangThai === 1
                            ? ' -  Không đồng ý'
                            : thuKyDuyet.trangThai === 2
                            ? '- Đồng ý '
                            : ''}
                        </span>
                        <span className="content">
                          {thuKyDuyet.yKien ? `  -  ${thuKyDuyet.yKien}` : ''}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {dsThuKyDuyet && dsThuKyDuyet.length > 0 && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${'/images/default_avatar.png'})`,
                        }}
                      ></span>
                      <span className="name_user">Thư ký lãnh đạo đơn vị</span>
                    </div>
                  </div>
                </div>
              )}
              {lanhDaoDonViDuyet && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            lanhDaoDonViDuyet.anhDaiDien
                              ? Types.IMAGE_URL + lanhDaoDonViDuyet.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {lanhDaoDonViDuyet.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {lanhDaoDonViDuyet.thoiGian && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {lanhDaoDonViDuyet.thoiGian &&
                            ' ' +
                              moment(lanhDaoDonViDuyet.thoiGian).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                        </span>
                        <span className="content">
                          {lanhDaoDonViDuyet.trangThai === 1
                            ? '- Không đồng ý'
                            : lanhDaoDonViDuyet.trangThai === 2
                            ? '- Đồng ý'
                            : ''}
                        </span>
                        <span className="content">
                          {lanhDaoDonViDuyet.yKien
                            ? `  -  ${lanhDaoDonViDuyet.yKien}`
                            : ''}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {flowRequestCommentBan && flowRequestCommentBan.length > 0 && (
                <div className="box-level-primary item-box">
                  <div
                    className="drop-down-sub"
                    onClick={this.handleClick.bind(this, 'gopyBan')}
                  >
                    <div className="click-avatar">
                      <span
                        className={classnames('pt-icon click-dropdown', {
                          'pt-icon-chevron-up': this.state.isOpen['gopyBan'],
                          'pt-icon-chevron-down': !this.state.isOpen['gopyBan'],
                        })}
                      ></span>
                      <span className="name-department">
                        Góp ý của các ban:
                      </span>
                    </div>
                  </div>
                  <Collapse isOpen={this.state.isOpen['gopyBan']}>
                    {flowRequestCommentBan
                      ? flowRequestCommentBan.map((item, i) => {
                          let flowReceiveCommentItem
                          let phongBanIdItem = item.phongBan
                            ? item.phongBan.phongBanId
                            : ''
                          if (flowReceiveComment) {
                            flowReceiveCommentItem = flowReceiveComment[item.id]
                          }

                          return (
                            <div className="box-level-sub item-box" key={i}>
                              <div
                                className="drop-down-sub"
                                onClick={this.handleClick.bind(this, item)}
                              >
                                <div className="click-avatar">
                                  {item.tinhTrang !== 1 &&
                                    item.tinhTrang !== 0 && (
                                      <span
                                        className={classnames(
                                          'pt-icon click-dropdown',
                                          {
                                            'pt-icon-chevron-up':
                                              (isLayYKienGoi ||
                                                mainDepartmentId ===
                                                  phongBanIdItem) &&
                                              this.state.isOpen[item.id],
                                            'pt-icon-chevron-down':
                                              (isLayYKienGoi ||
                                                mainDepartmentId ===
                                                  phongBanIdItem) &&
                                              !this.state.isOpen[item.id],
                                          }
                                        )}
                                      ></span>
                                    )}
                                  <span className="name-department">
                                    {item.phongBan
                                      ? item.phongBan.maPhongBan
                                      : ''}{' '}
                                  </span>
                                  <span className="name-department pull-right">
                                    {(isLayYKienGoi ||
                                      mainDepartmentId === phongBanIdItem) &&
                                      item.thoiGianHoanThanh &&
                                      ' ' +
                                        moment(item.thoiGianHoanThanh).format(
                                          'DD/MM/YYYY HH:mm'
                                        )}
                                  </span>
                                </div>
                              </div>
                              {flowReceiveCommentItem && (
                                <Collapse isOpen={this.state.isOpen[item.id]}>
                                  <FlowReceiveComment
                                    auth={this.props.auth}
                                    onClickDepartment={
                                      this.props.onClickDepartment
                                    }
                                    flowReceiveComment={flowReceiveCommentItem}
                                    daTrinhLanhDao={
                                      item.phongBanTrinhId !== null
                                    }
                                    actions={this.props.actions}
                                  />
                                </Collapse>
                              )}
                            </div>
                          )
                        })
                      : []}
                  </Collapse>
                </div>
              )}
              {flowRequestCommentDonVi &&
                flowRequestCommentDonVi.length > 0 && (
                  <div className="box-level-primary item-box">
                    <div
                      className="drop-down-sub"
                      onClick={this.handleClick.bind(this, 'gopyDonVi')}
                    >
                      <div className="click-avatar">
                        <span
                          className={classnames('pt-icon click-dropdown', {
                            'pt-icon-chevron-up':
                              this.state.isOpen['gopyDonVi'],
                            'pt-icon-chevron-down':
                              !this.state.isOpen['gopyDonVi'],
                          })}
                        ></span>
                        <span className="name-department">
                          Góp ý của các đơn vị:
                        </span>
                      </div>
                    </div>
                    <Collapse isOpen={this.state.isOpen['gopyDonVi']}>
                      {flowRequestCommentDonVi
                        ? flowRequestCommentDonVi.map((item, i) => {
                            let flowReceiveCommentItem
                            let maDonViItem = item.donVi
                              ? item.donVi.donViId
                              : ''
                            if (flowReceiveComment) {
                              flowReceiveCommentItem =
                                flowReceiveComment[item.id]
                            }

                            return (
                              <div className="box-level-sub item-box" key={i}>
                                <div
                                  className="drop-down-sub"
                                  onClick={this.handleClick.bind(this, item)}
                                >
                                  <div className="click-avatar">
                                    {item.tinhTrang !== 0 && (
                                      <span
                                        className={classnames(
                                          'pt-icon click-dropdown',
                                          {
                                            'pt-icon-chevron-up':
                                              (isLayYKienGoi ||
                                                mainUnitId === maDonViItem) &&
                                              this.state.isOpen[item.id],
                                            'pt-icon-chevron-down':
                                              (isLayYKienGoi ||
                                                mainUnitId === maDonViItem) &&
                                              !this.state.isOpen[item.id],
                                          }
                                        )}
                                      ></span>
                                    )}
                                    <span className="name-department">
                                      {item.donVi ? item.donVi.tenVietTat : ''}
                                    </span>
                                    <span className="name-department pull-right">
                                      {(isLayYKienGoi ||
                                        mainUnitId === maDonViItem) &&
                                        item.thoiGianHoanThanh &&
                                        ' ' +
                                          moment(item.thoiGianHoanThanh).format(
                                            'DD/MM/YYYY HH:mm'
                                          )}
                                    </span>
                                  </div>
                                </div>
                                {flowReceiveCommentItem && (
                                  <Collapse isOpen={this.state.isOpen[item.id]}>
                                    <FlowReceiveComment
                                      auth={this.props.auth}
                                      onClickDepartment={
                                        this.props.onClickDepartment
                                      }
                                      flowReceiveComment={
                                        flowReceiveCommentItem
                                      }
                                      actions={this.props.actions}
                                    />
                                  </Collapse>
                                )}
                              </div>
                            )
                          })
                        : []}
                    </Collapse>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default FlowRequestComments
