import React from 'react'
import ReactDOM from 'react-dom'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'

import { MasterLayout } from '../../components/layout'
import {
  DetailBoxActionProject,
  UpdateHistoryDetail,
} from '../../components/SignNumber'
import {
  DetailFileContent,
  LoadingLine,
  DetailFileContentV2,
} from 'components/common'
import * as Actions from '../../actions'
import { variables } from 'constants/variables'

const ACTIONS_VIEW = {
  TAO_CONG_VIEC: 'TAO_CONG_VIEC',
}

class DetailSignNumberProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mainUnitId:
        this.props.auth && this.props.auth.user
          ? this.props.auth.user.mainUnitId
          : null,
      showListAction: true,
      showDetailInfo: false,
      signNumberProjectId: this.props.match.params.id,
      signNumberProject: null,
      file: null,
      feedbacks: null,
      isLoadingData: {
        signNumberProject: true,
        feedbacks: true,
        historyItemSignNumber: false,
        historyItemFeedbacks: false,
        file: true,
        documentNumber: true,
        historyList: false,
      },
      listHistoryUpdate: null,
      isShowHistoryDetail: false,
      historyItem: {
        signNumberProject: null,
        feedbacks: null,
        time: null,
      },
      isLoadingMigratedFile: true,
      fileFromList: null, // File từ danh sách
      documentNumberFromList: null, // Số công văn từ danh sách
      isOpenHistoryList: false,
      isLoadedApi: {
        historyList: false,
      },
      boxMenuFixTop: false,
      showSection: null,
      documentDetail: null,
    }

    this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.handleClickShowHistoryDetail =
      this.handleClickShowHistoryDetail.bind(this)
    this.handleClickGoback = this.handleClickGoback.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleGetHistoryList = this.handleGetHistoryList.bind(this)
    this.handleRemoveFileInfo = this.handleRemoveFileInfo.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  static propTypes = {
    signNumberProject: PropTypes.object.isRequired,
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll)
    // Nếu có fileId, soCongVan lấy từ danh sách
    if (this.props.location && this.props.location.state) {
      // Gọi api get thông tin file => state.fileFromList
      let fileFromList = null
      let documentNumberFromList = null
      let isLoadingData = this.state.isLoadingData

      if (this.props.location.state.fileId) {
        await this.props.actions
          .getFileInformation(this.props.location.state.fileId)
          .then(res => {
            if (
              res &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result
            ) {
              fileFromList = res.payload.data.result
              isLoadingData = {
                ...isLoadingData,
                file: false,
              }
            }
          })
      }

      // Lấy số công văn từ danh sách
      if (this.props.location.state.soKyHieu) {
        documentNumberFromList = this.props.location.state.soKyHieu
        isLoadingData = {
          ...isLoadingData,
          documentNumber: false,
        }
      }

      if (fileFromList || documentNumberFromList) {
        await this.setState({
          fileFromList,
          documentNumberFromList,
          isLoadingData,
        })
      }
    }

    // Chi tiết ký số dự án
    await this.props.actions
      .signNumberProjectGetDetail(this.state.signNumberProjectId)
      .then(res => {
        if (
          this.props.signNumberProject &&
          this.props.signNumberProject.item &&
          res.payload &&
          res.payload.status === 200
        ) {
          this.setState({
            signNumberProject: this.props.signNumberProject.item,
            file: this.props.signNumberProject.item.fileNoiDung,
            isLoadingData: {
              ...this.state.isLoadingData,
              signNumberProject: false,
              file: false,
              documentNumber: false,
            },
          })
        } else {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              signNumberProject: false,
              file: false,
              documentNumber: false,
            },
          })
        }
      })

    // Danh sách góp ý
    await this.props.actions
      .signNumberProjectFeedbackGet(this.state.signNumberProjectId)
      .then(res => {
        if (
          this.props.signNumberProject &&
          this.props.signNumberProject.listFeedbacks &&
          res.payload &&
          res.payload.status === 200
        ) {
          this.setState(
            {
              feedbacks: this.props.signNumberProject.listFeedbacks.items,
            },
            () => {
              this.setState({
                isLoadingData: {
                  ...this.state.isLoadingData,
                  feedbacks: false,
                },
              })
            }
          )
        } else {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              feedbacks: false,
            },
          })
        }
      })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleGetHistoryList = async () => {
    await this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        historyList: true,
      },
      isOpenHistoryList: !this.state.isOpenHistoryList,
    })

    let isLoadedApi = this.state.isLoadedApi
    if (
      this.state.signNumberProject &&
      this.state.signNumberProject.vanBanGocId &&
      isLoadedApi &&
      isLoadedApi.historyList === false
    ) {
      await this.props.actions
        .signNumberProjectUpdateHistoryGet(
          this.state.signNumberProjectId,
          this.state.signNumberProject.vanBanGocId
        )
        .then(() => {
          if (
            this.props.signNumberProject &&
            this.props.signNumberProject.listHistoryUpdate &&
            this.props.signNumberProject.listHistoryUpdate.items
          ) {
            this.setState({
              listHistoryUpdate:
                this.props.signNumberProject.listHistoryUpdate.items,
            })
            isLoadedApi = {
              ...isLoadedApi,
              historyList: true,
            }
          }
        })
    }

    await this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        historyList: false,
      },
      isLoadedApi,
    })
  }

  handleSubmit = async params => {
    if (params.isSuccess !== undefined && params.title) {
      if (params.isSuccess === false && params.message && params.intent) {
        return this.props.actions.commonAddToasterMessage({
          message: params.message,
          intent: params.intent,
        })
      }
      if (params.message && params.intent) {
        this.props.actions.commonAddToasterMessage({
          message: params.message,
          intent: params.intent,
        })
      }

      // Nếu ký sim thành công thì redirect về danh sách tương ứng
      if (params.title === 'DongYKySoBangSIM' && this.props.history) {
        this.goBack()
      }

      let state = this.state

      if (params.title !== 'GopY') {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            signNumberProject: true,
            feedbacks: true,
          },
        })
        await this.props.actions
          .signNumberProjectGetDetail(this.state.signNumberProjectId)
          .then(() => {
            if (
              this.props.signNumberProject &&
              this.props.signNumberProject.item
            ) {
              state.file = this.props.signNumberProject.item.fileNoiDung
              state.fileFromList = this.props.signNumberProject.item.fileNoiDung
              state.signNumberProject = this.props.signNumberProject.item
            }
            state.isLoadingData.signNumberProject = false
            this.setState(state, () => {
              this.handleRemoveFileInfo()
            })
          })
          .catch(error => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                signNumberProject: false,
              },
            })
          })
        this.props.actions
          .signNumberProjectFeedbackGet(this.state.signNumberProjectId)
          .then(() => {
            if (
              this.props.signNumberProject &&
              this.props.signNumberProject.listFeedbacks &&
              this.props.signNumberProject.listFeedbacks.items
            ) {
              this.setState(
                {
                  feedbacks: this.props.signNumberProject.listFeedbacks.items,
                },
                () => {
                  this.setState({
                    isLoadingData: {
                      ...this.state.isLoadingData,
                      feedbacks: false,
                    },
                  })
                }
              )
            }
          })
          .catch(error => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                feedbacks: false,
              },
            })
          })
      } else {
        return this.props.actions
          .signNumberProjectFeedbackGet(this.state.signNumberProjectId)
          .then(() => {
            if (
              this.props.signNumberProject &&
              this.props.signNumberProject.listFeedbacks &&
              this.props.signNumberProject.listFeedbacks.items
            ) {
              this.setState({
                feedbacks: this.props.signNumberProject.listFeedbacks.items,
                isLoadingData: {
                  ...this.state.isLoadingData,
                  feedbacks: false,
                },
              })
            }
          })
          .catch(error => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                feedbacks: false,
              },
            })
          })
      }
    }
  }

  handleClickGoback = () => {
    this.setState({ isShowHistoryDetail: false })
  }

  handleClickShowHistoryDetail = (id, timeUpdate) => {
    if (!id || !timeUpdate) {
      return
    }
    const historyNode = ReactDOM.findDOMNode(this.refs.historyNode)

    this.setState(
      {
        isShowHistoryDetail: true,
        isLoadingData: {
          ...this.state.isLoadingData,
          historyItemSignNumber: true,
          historyItemFeedbacks: true,
        },
        historyItem: {
          ...this.state.historyItem,
          time: timeUpdate,
        },
      },
      () => {
        historyNode.scrollIntoView({
          alignToTop: true,
          block: 'start',
          inline: 'start',
        })
      }
    )

    this.props.actions
      .signNumberProjectGetDetail(id)
      .then(() => {
        if (this.props.signNumberProject && this.props.signNumberProject.item) {
          this.setState({
            historyItem: {
              ...this.state.historyItem,
              signNumberProject: this.props.signNumberProject.item,
            },
            isLoadingData: {
              ...this.state.isLoadingData,
              historyItemSignNumber: false,
            },
          })
        }
      })
      .catch(error => {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            historyItemSignNumber: false,
          },
        })
      })
    this.props.actions
      .signNumberProjectFeedbackGet(id)
      .then(() => {
        if (
          this.props.signNumberProject &&
          this.props.signNumberProject.listFeedbacks &&
          this.props.signNumberProject.listFeedbacks.items
        ) {
          this.setState({
            historyItem: {
              ...this.state.historyItem,
              feedbacks: this.props.signNumberProject.listFeedbacks.items,
            },
            isLoadingData: {
              ...this.state.isLoadingData,
              historyItemFeedbacks: false,
            },
          })
        }
      })
      .catch(error => {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            historyItemFeedbacks: false,
          },
        })
      })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showReportWork: !this.state.showReportWork,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  goBack() {
    this.props.history.push('/ky-so/du-an/danh-sach')
  }

  handleRemoveFileInfo = () => {
    if (this.props.location && this.props.location.state) {
      this.props.history.replace({
        state: {
          ...this.props.location.state,
          fileId: null,
        },
      })
    }
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    let { signNumberProject } = this.props
    if (signNumberProject && signNumberProject.item) {
      let fileNoiDung = signNumberProject.item.fileNoiDung
        ? signNumberProject.item.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = signNumberProject.item.kySoId
      let infoGetFileDinhKemType = 'KY_SO_DU_AN'
      const url = `?fileId=${fileNoiDung}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const signNumber = props?.signNumberProject?.item
    if (signNumber) {
      const documentDetail = {
        kySoMoRongId: signNumber?.kySoId || null,
        trichYeu: signNumber?.trichYeu || null,
        fileThamKhao: signNumber?.fileNoiDung || null,
        fileDinhKemEvn: signNumber?.dsFileDinhKem || [],
      }
      this.setState({ documentDetail })
    }
  }

  goGiaoViecPhienBanMoi = () => {
    this.setState({
      showSection: ACTIONS_VIEW.TAO_CONG_VIEC,
    })
  }

  goBackGiaoViecPhienBanMoi = () => {
    this.setState({
      showSection: null,
    })
  }

  render() {
    const { showSection } = this.state
    // Nếu có file từ danh sách thì dùng file, soCongVan từ danh sách,
    // ngược lại dùng file, soCongVan get từ api chi tiết
    let fileDetail = this.state.fileFromList || this.state.file
    let documentNumberDetail = this.state.documentNumberFromList

    if (
      !this.state.documentNumberFromList &&
      this.props.signNumberProject &&
      this.props.signNumberProject.item &&
      this.props.signNumberProject.item.soKyHieu
    ) {
      documentNumberDetail = this.props.signNumberProject.item.soKyHieu
    }

    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="detail-receive-document" ref="historyNode">
          {this.state.isLoadingData &&
          this.state.isLoadingData.documentNumber === true ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">Số ký hiệu:</span>
                <span className="code">{documentNumberDetail}</span>
              </span>
            </div>
          )}
          <div className="detail-container detail-container-sign-number">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(fileDetail) && (
                  <DetailFileContentV2
                    auth={this.props.auth.user}
                    actions={this.props.actions}
                    file={fileDetail}
                  />
                )}
              </div>
              <div
                className="box-right"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="bg-white">
                  {this.state.isShowHistoryDetail === false && !showSection && (
                    <DetailBoxActionProject
                      auth={this.props.auth.user}
                      feedbacks={this.state.feedbacks}
                      signNumberProject={this.state.signNumberProject}
                      onSubmit={this.handleSubmit}
                      actions={this.props.actions}
                      isLoadingData={this.state.isLoadingData}
                      onClickShowHistoryDetail={
                        this.handleClickShowHistoryDetail
                      }
                      listHistoryUpdate={this.state.listHistoryUpdate}
                      isShowHistoryDetail={this.state.isShowHistoryDetail}
                      readOnly={this.props.readOnly}
                      onGetHistoryList={this.handleGetHistoryList}
                      isOpenHistoryList={this.state.isOpenHistoryList}
                      type="signNumberProject"
                      onRemoveFileInfo={this.handleRemoveFileInfo}
                      sendToChat={this.sendToChat}
                    />
                  )}
                  {this.state.isShowHistoryDetail === true && !showSection && (
                    <UpdateHistoryDetail
                      historyItem={this.state.historyItem}
                      onClickGoBack={this.handleClickGoback}
                      auth={this.props.auth.user}
                      isLoadingData={this.state.isLoadingData}
                      type="signNumberProject"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  signNumberProject: {
    ...state.signNumberProjects,
    item: state.signNumberProjects.detailId
      ? state.entities.signNumberProjects[state.signNumberProjects.detailId]
      : {},
    listFeedbacks: {
      ...state.signNumberProjects.listFeedbacks,
      items: state.signNumberProjects.listFeedbacks.items.map(
        item => state.entities.signNumberFeedbacks[item]
      ),
    },
    listHistoryUpdate: state.signNumberProjects.listHistoryUpdate,
  },

  auth: {
    ...state,
    user: state.auth,
  },

  readOnly: state.common.enableReadOnly,

  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailSignNumberProjectPage)
