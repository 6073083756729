import { CHECKBOX_REQUIRED } from '../../constants/MessageForm'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import {
  EmptyDataTable as EmptyData,
  ErrorLoadingTable as ErrorLoading,
  LoadingLine,
} from './index'
import React, { Component } from 'react'

import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  commonAddToasterMessage,
  getCategoryUnitMultiLevel,
} from '../../actions'
import classnames from 'classnames'
import Tree, { TreeNode } from 'rc-tree'
import Permission from '../auth/Permission'

let unitHasFetchEmployee = []
const addUnitHasFetch = id => unitHasFetchEmployee.push(id)
const alreadyFetch = id => unitHasFetchEmployee.includes(id)

class EmployeeSelectDialogV2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isEmptyData: false,
      isError: false,
      selectList: [],
      defaultSelectKeyList: [],
      unitList: [],
    }
  }

  fetchUnit = async () => {
    const { fetchUnit, auth } = this.props
    const isSuper = _.get(auth, 'permission', []).includes(
      Permission.SUPERADMIN
    )
    let params = []
    if (!isSuper && _.get(auth, 'mainUnitId')) {
      params.push(_.get(auth, 'mainUnitId'))
    }
    if (_.isEmpty(this.state.unitList)) {
      try {
        const response = await fetchUnit(...params)
        const status = !!(_.get(response, 'payload.status') === 200)
        if (!status) {
          return this.setState({ isError: true })
        }
        this.setState({ unitList: _.get(response, 'payload.data.result', []) })
      } catch (error) {
        this.setState({ isError: true })
      } finally {
        this.setState({ isLoading: false })
      }
    }
  }

  setEmployee = (unitList, unitId, dsPhongBan) => {
    return unitList.map(item => {
      if (item.donViId === unitId) {
        return { ...item, dsPhongBan }
      }
      if (!_.isEmpty(item.dsDonViCon)) {
        return {
          ...item,
          dsDonViCon: this.setEmployee(item.dsDonViCon, unitId, dsPhongBan),
        }
      }
      return item
    })
  }

  setSelectKey = (unitId, departmentId, employee) => {
    const value = {}
    !!unitId && (value.don_vi_id = unitId)
    !!departmentId && (value.phong_ban_id = departmentId)
    if (!!employee) {
      value.nhan_vien_id = employee.nhanVienId
      value.chuc_danh_id = _.get(employee.dsChucDanh, '[0].id')
    }
    return JSON.stringify(value)
  }

  disablePhongBan = phongBan => {
    const { totalNhanVien = 0, soPhongBanCon = 0, phongBanId } = phongBan
    const { defaultSelectKeyList } = this.state
    let childTotalSelect = 0
    const totalSelect = defaultSelectKeyList
      .map(key => JSON.parse(key.slice(3)))
      .filter(i => i.phong_ban_id === phongBanId).length
    if (soPhongBanCon > 0 && phongBan.dsPhongBanCon) {
      childTotalSelect = phongBan.dsPhongBanCon
        .map(item => (this.disablePhongBan(item) ? item.totalNhanVien : 0))
        .reduce((a, b) => a + b, 0)
    }

    return !!(totalNhanVien && totalNhanVien === totalSelect + childTotalSelect)
  }

  onCheck = selectList => {
    let employeeSelectList = []
    selectList.forEach(key => {
      const [type, value] = key.split('@')
      if (type === 'NV') {
        employeeSelectList.push(JSON.parse(value))
      }
    })
    this.setState({ selectList: employeeSelectList })
  }

  onExpand = (expandList, { node, expanded }) => {
    const type = _.get(node, 'props.eventKey').slice(0, 2)
    if (type !== 'DV') {
      return
    }
    const unitId = _.get(node, 'props.eventKey').slice(3)
    if (expanded && unitId && !alreadyFetch(unitId)) {
      addUnitHasFetch(unitId)
    }
  }

  onSubmit = () => {
    const { onSubmit, showToast } = this.props
    const { selectList } = this.state
    if (!_.isEmpty(selectList)) {
      !!onSubmit && onSubmit(selectList)
      return this.setState({ selectList: [] })
    }
    showToast({
      message: CHECKBOX_REQUIRED('thành viên'),
      intent: Intent.DANGER,
    })
  }

  componentDidMount() {
    this.fetchUnit()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultSelectList !== this.props.defaultSelectList) {
      const convertList = nextProps.defaultSelectList
        ? nextProps.defaultSelectList.map(
            item =>
              `NV@${JSON.stringify({
                don_vi_id: item.donViId,
                phong_ban_id: item.phongBanId,
                nhan_vien_id: item.nhanVienId,
                chuc_danh_id: item.chucDanhId,
              })}`
          )
        : []
      this.setState({ defaultSelectKeyList: convertList })
    }
  }

  childNode = (child, type, hideCheckbox, props) => {
    let key, title, disable
    // eslint-disable-next-line default-case
    switch (type) {
      case 'DV':
        key = `DV@${child.donViId}`
        title = child.tenVietTat
        break
      case 'PB':
        key = `PB@${child.phongBanId}`
        title = child.maPhongBan
        disable = this.disablePhongBan(child)
        break
      case 'NV':
        key = `NV@${this.setSelectKey(...props, child)}`
        title = child.tenNhanVien
        disable = _.get(this.state, 'defaultSelectKeyList', []).includes(key)
        break
    }
    return (
      <TreeNode
        className={classnames('employee-select-tree-item', {
          hideCheckbox,
          'disabled-tree-node': disable,
        })}
        key={key}
        title={title}
        disableCheckbox={disable}
      >
        {!_.isEmpty(child.dsNhanVien) &&
          child.dsNhanVien.map(nhanVien =>
            this.childNode(nhanVien, 'NV', false, [
              child.donViId,
              child.phongBanId,
            ])
          )}
        {!_.isEmpty(child.dsPhongBanCon) &&
          child.dsPhongBanCon.map(phongBan => this.childNode(phongBan, 'PB'))}
        {!_.isEmpty(child.dsPhongBan) &&
          child.dsPhongBan.map(phongBan => this.childNode(phongBan, 'PB'))}
        {!_.isEmpty(child.dsDonViCon) &&
          child.dsDonViCon.map(childUnit =>
            this.childNode(childUnit, 'DV', true)
          )}
        {type === 'DV' && (
          <TreeNode
            key={`${key}-hidden`}
            className="employee-select-tree-item display-none"
          />
        )}
      </TreeNode>
    )
  }

  setDefaultSelect = () => {
    const { defaultSelectKeyList } = this.state
    return defaultSelectKeyList.filter(key =>
      unitHasFetchEmployee.some(unit => key.includes(unit))
    )
  }

  render() {
    const { isDialogOpen: isOpen = false, closeDialog, title } = this.props
    const { isLoading, isError, unitList } = this.state
    const defaultSelect = this.setDefaultSelect()

    return (
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title || ''}
        className="size-container-dialog employee-dialog"
      >
        <div
          className={classnames(
            'container-UpdateDrawers',
            'wrapper-content-dialog',
            'employee-dialog-content'
          )}
        >
          {isLoading ? (
            <LoadingLine />
          ) : isError ? (
            <ErrorLoading />
          ) : _.isEmpty(unitList) ? (
            <EmptyData />
          ) : (
            <Tree
              className="employee-select-tree"
              onCheck={this.onCheck}
              onExpand={this.onExpand}
              selectable={false}
              checkable
              defaultCheckedKeys={defaultSelect}
            >
              {unitList.map(childUnit => this.childNode(childUnit, 'DV', true))}
            </Tree>
          )}
        </div>
        <div className="pt-dialog-footer dialog-footer-employee">
          <div className="pt-dialog-footer-actions">
            <div className="box-button">
              <Button
                className="button-blue btn-save-employee"
                text="Lưu"
                intent={Intent.PRIMARY}
                onClick={this.onSubmit}
                disabled={isLoading || isError}
              />
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  unitList: state.category.categoryUnitMultiLevel,
  auth: state.auth,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  fetchUnit: bindActionCreators(getCategoryUnitMultiLevel, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispathToProps
)(EmployeeSelectDialogV2)
