import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import SelectTree from './SelectTree'

class FilterTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: props.isLoading !== undefined ? props.isLoading : true,
    }
  }

  onSubmitForm = e => {
    e.preventDefault()
    this.props.onSubmit && this.props.onSubmit()
  }

  componentWillReceiveProps(props) {
    if (props.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: props.isLoading,
      })
    }
  }

  expandYears = (start = '2020') => {
    const years = []
    const dateStart = moment(start, 'YYYY')
    const dateEnd = moment().startOf('years')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(dateStart.format('YYYY'))
      dateStart.add(1, 'year')
    }
    return years
  }

  render() {
    const { data } = this.props
    return (
      data &&
      data.length !== 0 &&
      data
        .filter(
          item =>
            !this.props[item.nameHide] || this.props[item.nameHide] !== true
        )
        .map((elm, index) => {
          switch (elm.typeGridView) {
            case 'input':
              return (
                <div
                  key={index}
                  className={`cpc-sortable-body--cell ${elm.classNames}`}
                >
                  <div className="cpc-sortable-filter--box">
                    {(!this.props[elm.nameFilterHide] ||
                      this.props[elm.nameFilterHide] !== true) && (
                      <div>
                        <input
                          disabled={this.props[elm.nameDisabledFilter] || false}
                          type="text"
                          name={elm.nameGridView}
                          maxLength={255}
                          placeholder="Nhập"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.onSubmitForm(e)
                            }
                          }}
                          onChange={this.props.onChange}
                          value={
                            (this.props.filterColumn &&
                              this.props.filterColumn[elm.nameGridView]) ||
                            ''
                          }
                        />
                        {this.props.filterColumn &&
                          this.props.filterColumn[elm.nameGridView] && (
                            <i
                              className="icon-close pt-close-gridview"
                              onClick={() => {
                                this.props.onRemoveText([elm.nameGridView])
                              }}
                            />
                          )}
                      </div>
                    )}
                  </div>
                </div>
              )
            case 'select':
              if (elm.nameGridView === 'nam') {
                return (
                  <div
                    key={index}
                    className={`cpc-sortable-body--cell ${elm.classNames}`}
                  >
                    <div className="cpc-sortable-filter--box">
                      {(!this.props[elm.nameFilterHide] ||
                        this.props[elm.nameFilterHide] !== true) && (
                        <div>
                          <select
                            className="select-content"
                            onChange={this.props.onChange}
                            name={elm.nameGridView}
                            value={
                              (this.props.filterColumn &&
                                this.props.filterColumn[elm.nameGridView]) ||
                              ''
                            }
                            disabled={this.props[elm.nameDisabledFilter]}
                          >
                            <option value="">Tất cả</option>
                            {this.expandYears().map((item, i) => (
                              <option key={i} value={item}>
                                Năm {item}
                              </option>
                            ))}
                          </select>
                          <span className="icon-select-down"></span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
              if (elm.dataSelect && elm.dataSelect.length !== 0) {
                // Filter select có dữ liệu là dataSelect
                return (
                  <div
                    key={index}
                    className={`cpc-sortable-body--cell ${elm.classNames}`}
                  >
                    <div className="cpc-sortable-filter--box">
                      {(!this.props[elm.nameFilterHide] ||
                        this.props[elm.nameFilterHide] !== true) && (
                        <div>
                          <select
                            disabled={this.props[elm.nameDisabledFilter]}
                            onChange={this.props.onChange}
                            name={elm.nameGridView}
                            value={
                              (this.props.filterColumn &&
                                this.props.filterColumn[elm.nameGridView]) ||
                              ''
                            }
                          >
                            {elm.dataSelect &&
                              elm.dataSelect.map((item, i) => (
                                <option key={i} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          <span className="icon-select-down"></span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else {
                // Filter select có dữ liệu được lấy từ api
                if (
                  elm.nameGridView === 'phongBanLapId' &&
                  !this.props.onChangeSelectTree
                ) {
                  return (
                    <div
                      key={index}
                      className={`cpc-sortable-body--cell ${elm.classNames}`}
                    >
                      <div className="cpc-sortable-filter--box">
                        {(!this.props[elm.nameFilterHide] ||
                          this.props[elm.nameFilterHide] !== true) && (
                          <div>
                            <select
                              className="select-content"
                              onChange={this.props.onChange}
                              name={elm.nameGridView}
                              value={
                                (this.props.filterColumn &&
                                  this.props.filterColumn[elm.nameGridView]) ||
                                ''
                              }
                              disabled={
                                this.props.isDisablePBLap ||
                                this.props[elm.nameDisabledFilter]
                              }
                            >
                              <option value="">Tất cả</option>
                              {this.props[elm.nameGridView] &&
                                this.props[elm.nameGridView]
                                  .filter(s => s.phongBanLanhDao === false)
                                  .map((item, i) => (
                                    <option key={i} value={item.phongBanId}>
                                      {item.maPhongBan}
                                    </option>
                                  ))}
                            </select>
                            <span className="icon-select-down"></span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
                if (
                  (elm.nameGridView === 'phongBanLapId' ||
                    elm.nameGridView === 'phongBanNhanId') &&
                  this.props[elm.nameGridView].length > 0 &&
                  this.props.onChangeSelectTree
                ) {
                  return (
                    <div
                      key={index}
                      className={`cpc-sortable-body--cell ${elm.classNames}`}
                    >
                      <div className="cpc-sortable-filter--box">
                        {(!this.props[elm.nameFilterHide] ||
                          this.props[elm.nameFilterHide] !== true) && (
                          <SelectTree
                            disabled={this.props[elm.nameDisabledFilter]}
                            isDisabled={
                              elm.nameGridView === 'phongBanLapId'
                                ? this.props.isDisablePBLap
                                : this.props.isDisablePBNhan
                            }
                            data={this.props[elm.nameGridView]}
                            className="table-row-select-tree"
                            type="selectTreeFilter"
                            name={elm.nameGridView}
                            handleChange={this.props.onChangeSelectTree}
                            value={
                              (this.props.filterColumn &&
                                this.props.filterColumn[elm.nameGridView]) ||
                              '00000000-0000-0000-0000-000000000000'
                            }
                          />
                        )}
                      </div>
                    </div>
                  )
                }
                if (elm.nameGridView === 'coQuanBanHanhId') {
                  return (
                    <div
                      key={index}
                      className={`cpc-sortable-body--cell ${elm.classNames}`}
                    >
                      <div className="cpc-sortable-filter--box">
                        {(!this.props[elm.nameFilterHide] ||
                          this.props[elm.nameFilterHide] !== true) && (
                          <div>
                            <select
                              disabled={this.props[elm.nameDisabledFilter]}
                              onChange={this.props.onChange}
                              name={elm.nameGridView}
                              value={
                                (this.props.filterColumn &&
                                  this.props.filterColumn[elm.nameGridView]) ||
                                ''
                              }
                            >
                              <option value="">Tất cả</option>
                              {this.props[elm.nameGridView] &&
                                this.props[elm.nameGridView].map((item, i) => (
                                  <option key={i} value={item.donViId}>
                                    {item.tenVietTat}
                                  </option>
                                ))}
                              <option value="#">Khác</option>
                            </select>
                            <span className="icon-select-down"></span>
                            {!this.state.isLoading &&
                              this.props.filterColumn &&
                              this.props.filterColumn.coQuanBanHanhId &&
                              this.props.filterColumn.coQuanBanHanhId ===
                                '#' && (
                                <input
                                  style={{
                                    left: 0,
                                    position: 'absolute',
                                    bottom: -35,
                                    width: '100%',
                                  }}
                                  name="coQuanBanHanh"
                                  maxLength={255}
                                  onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                      this.onSubmitForm(e)
                                    }
                                  }}
                                  onChange={this.props.onChange}
                                  value={
                                    (this.props.filterColumn &&
                                      this.props.filterColumn.coQuanBanHanh) ||
                                    ''
                                  }
                                />
                              )}
                            {elm.nameGridView === 'coQuanBanHanhId' &&
                              !this.state.isLoading &&
                              this.props.filterColumn &&
                              this.props.filterColumn.coQuanBanHanh &&
                              this.props.filterColumn[elm.nameGridView] ===
                                '#' && (
                                <i
                                  className="icon-close pt-close-gridview-cqbh"
                                  onClick={() => {
                                    this.props.onRemoveText('coQuanBanHanh')
                                  }}
                                ></i>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
                return (
                  <div
                    key={index}
                    className={`cpc-sortable-body--cell ${elm.classNames}`}
                  >
                    <div className="cpc-sortable-filter--box">
                      {(!this.props[elm.nameFilterHide] ||
                        this.props[elm.nameFilterHide] !== true) && (
                        <div>
                          <select
                            disabled={this.props[elm.nameDisabledFilter]}
                            onChange={this.props.onChange}
                            name={elm.nameGridView}
                            value={
                              (this.props.filterColumn &&
                                this.props.filterColumn[elm.nameGridView]) ||
                              ''
                            }
                          >
                            <option value="">Tất cả</option>
                            {this.props[elm.nameGridView] &&
                              this.props[elm.nameGridView].map((item, i) => (
                                <option key={i} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          <span className="icon-select-down"></span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            case 'date':
              return (
                <div
                  key={index}
                  className={`cpc-sortable-body--cell ${elm.classNames}`}
                >
                  <div className="cpc-sortable-filter--box">
                    {(!this.props[elm.nameFilterHide] ||
                      this.props[elm.nameFilterHide] !== true) && (
                      <DatePicker
                        className="input-icon-datepicker"
                        disabled={this.props[elm.nameDisabledFilter]}
                        readOnly
                        isClearable={window.innerWidth >= 530 ? true : false}
                        selected={
                          (this.props.filterColumn &&
                            this.props.filterColumn[elm.nameGridView]) ||
                          null
                        }
                        onChange={this.props.onChangeDate.bind(
                          this,
                          elm.nameGridView
                        )}
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Nhập"
                        popperPlacement="bottom-start"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                        value={
                          (this.props.filterColumn &&
                            this.props.filterColumn[elm.nameGridView]) ||
                          ''
                        }
                      />
                    )}
                  </div>
                </div>
              )
            default:
              return (
                <div
                  key={index}
                  className={`cpc-sortable-body--cell ${elm.classNames}`}
                >
                  <div className="cpc-sortable-filter--box"></div>
                </div>
              )
          }
        })
    )
  }
}

FilterTable.defaultProps = {
  data: [],
}

FilterTable.propTypes = {
  data: PropTypes.array,
}

export default FilterTable
