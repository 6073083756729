// TODO: Hien thi popup confirm doi du an
import React from 'react'
import DragProjectDigitalSignature from './DragProjectDigitalSignature'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import { filter, isNumber, uniq, compact, isEqual, isEmpty } from 'lodash'

import * as Tool from '../../helpers'
import * as MESSAGE from '../../constants/MessageForm'
import * as Types from '../../constants/Api'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'
import { PrevewFileTrinhKy } from '../SignNumber'

const GIOI_HAN_KY_TU_SO_KY_HIEU = 50
class InputFormSignNumberProject extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleContentFileChange = this.handleContentFileChange.bind(this)
    this.handleAttachFileChange = this.handleAttachFileChange.bind(this)
    this.handleRemoveAttachFile = this.handleRemoveAttachFile.bind(this)
    this.handleAttachFileChange = this.handleAttachFileChange.bind(this)
    this.handleChangeProcedure = this.handleChangeProcedure.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    let duAnId = ''
    if (props.projects && props.projects[0] && props.projects[0].duAnId) {
      duAnId = props.projects[0].duAnId
    }

    this.state = {
      isOpenPopup: false,
      // file noi dung
      contentFile: null,
      contentFileName: '',
      contentFileSize: null,
      contentFileProgress: null,
      // file tham khao
      attachQty: [0],
      attachFiles: [],
      attachFileNames: [],
      attachFileSizes: [],
      attachFileErrors: [],
      error: {},
      donViId: '',
      duAnId,
      soKyHieu: '',
      tenThanhPho: this.props.cityName || '',
      trichYeu: '',
      fileNoiDung: null,
      fileThamKhao: [],
      dsBuoc: [],
      isLoading: false,
      // duAnId luu tam thoi de thay doi
      projectIdtmp: null,
      isChangedProcedure: false,
      isChangeFileUpdate: false,
      isOpenDialog: false,
    }
  }

  static propTypes = {
    projects: PropTypes.array.isRequired,
    isSubmitFail: PropTypes.bool.isRequired,
    cityName: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isLoadingData: PropTypes.object.isRequired,
    handlePreview: PropTypes.func,
    fileTrinhKy: PropTypes.object,
  }

  handleClickPopup = e => {
    let projectIdtmp = null
    // Neu thay doi du an
    if (e && this.state.isOpenPopup === false) {
      projectIdtmp = e.target.value
    }
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      projectIdtmp,
    })
  }

  handleChangeProject = () => {
    let duAnId = this.state.duAnId
    if (this.state.projectIdtmp) {
      duAnId = this.state.projectIdtmp
    }
    this.setState(
      {
        isOpenPopup: false,
        duAnId,
      },
      () => {
        this.props.onChangeProject &&
          this.props.onChangeProject(this.state.projectIdtmp)
      }
    )
  }

  handleAddAttachFile = () => {
    let attachQty = this.state.attachQty
    if (attachQty) {
      attachQty.push(attachQty.length)
    }
    this.setState({ attachQty })
  }

  handleRemoveAttachFile = i => {
    let state = this.state
    if (i !== 0) {
      state.attachQty.splice(i, 1)
    }

    state.attachFiles.splice(i, 1)
    state.attachFileNames.splice(i, 1)
    state.attachFileSizes.splice(i, 1)
    state.attachFileErrors.splice(i, 1)
    state.fileThamKhao.splice(i, 1)
    this.setState(state)
  }

  handleInputChange = e => {
    let error = this.state.error
    let name = e.target.name
    let value = e.target.value
    delete error[name]
    this.setState(
      {
        [name]: value,
        error,
      },
      () => {
        if (name === 'duAnId') {
          this.props.onChangeProject && this.props.onChangeProject(value)
        }
      }
    )
  }

  handleContentFileChange = e => {
    if (!e) {
      return
    }

    // Xóa error.contentFile
    const name = e.target.name
    let state = this.state
    delete state.error.contentFile

    // Validate file
    let reader = new FileReader()
    let file = e.target.files && e.target.files[0]
    const contentFile = e.target.value
    const extension = Tool.Utils.getExtensionFile(contentFile)
    let sizeFile = file && file.size

    if (!contentFile && state.contentFileName.length === 0) {
      state.error.contentFile = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (!extension || extension !== 'pdf') {
      state.error.contentFile = MESSAGE.TEXT_TYPE_FILE_UPLOAD_SIGNNUMBER
    } else if (!sizeFile || sizeFile > Types.MAX_SIZE_FILE) {
      state.error.contentFile = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    }

    this.setState(state)

    // Đọc file nếu file hợp lệ
    if (!state.error.contentFile && file) {
      reader.onload = () => {
        state[name] = file
        state.contentFileSize = sizeFile
        state.contentFileName = file.name
        delete state.fileNoiDung
        state.isChangeFileUpdate = this.props.isUpdate
        this.setState(state)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleAttachFileChange = e => {
    if (!e) {
      return
    }

    // Xóa message error
    const [name, i] = e.target.name.split('-')
    let state = this.state
    state.attachFileErrors.splice(i, 1)

    let reader = new FileReader()
    let file = e.target.files && e.target.files[0]

    // Validate file
    const attachFile = e.target.value
    const extension = Tool.Utils.getExtensionFile(attachFile)
    // let sizeFile = file && file.size;

    const extensionSupportArray = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
    if (extensionSupportArray.indexOf(extension) === -1) {
      state.attachFileErrors[i] = MESSAGE.TEXT_TYPE_FILE_UPLOAD_REFERENCES
    }
    // TODO: Không giới giạn dung lượng file tham khảo
    // else if (!sizeFile || sizeFile > Types.MAX_SIZE_FILE) {
    //   state.attachFileErrors[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE;
    // }

    this.setState(state)

    if (!state.attachFileErrors[i]) {
      reader.onload = () => {
        state[name][i] = file
        state.attachFileNames[i] = file.name
        state.attachFileSizes[i] = file.size
        state.fileThamKhao.splice(i, 1)
        this.setState(state)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleChangeProcedure = (data, isChangedData) => {
    let isChangedProcedure = false
    if (isChangedData === true) {
      isChangedProcedure = true
    }
    if (data) {
      this.setState({ dsBuoc: data, isChangedProcedure })
    }
  }

  // Kiểm tra dữ liệu form
  checkFormValidate = () => {
    let error = {}
    let { contentFileSize } = this.state
    if (!this.state.duAnId || this.state.duAnId.trim().length === 0) {
      error.duAnId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.trichYeu || this.state.trichYeu.trim().length === 0) {
      error.trichYeu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.contentFileName ||
      this.state.contentFileName.trim().length === 0
    ) {
      error.contentFile = MESSAGE.TEXT_MESSAGE_DEFAULT
      contentFileSize = null
    }

    let data = this.state.dsBuoc || []
    let dsBuoc = []
    let errorMembers = []
    let dsTruongKy = []
    let countUserProgression = 0
    let countStepTypeWayHasSign = 0
    let countStepTypeWayProgression = 0
    errorMembers = Array(data.length).fill(undefined)
    data.forEach((step, index) => {
      if (!step) {
        return false
      }

      let stepItem = {
        buoc: index + 1,
        hinh_thuc: step.hinhThuc,
        thoi_han: step.thoiHan,
        trang_ky: step.trangKy ? step.trangKy : 0,
      }
      if (isNumber(step.hinhThuc) === false) {
        errorMembers[index] = MESSAGE.REQUIRED_SIGN_WAY
      }

      if (
        step.hinhThuc === Types.HTK_KY_NHAY_CO_CHU_KY &&
        isNumber(step.trangKy) === false
      ) {
        errorMembers[index] = MESSAGE.REQUIRED_PAGE_SIGN
      }

      if (!step.dsThanhVien || step.dsThanhVien.length < 1) {
        errorMembers[index] = MESSAGE.VALIDATE_MEMBERS_PROJECT
      }

      if (step.hinhThuc === Types.HTK_KY_CO_DAU) {
        countStepTypeWayHasSign++
      }

      if (step.hinhThuc === Types.HTK_CAP_SO) {
        countStepTypeWayProgression++
      }

      if (step && step.dsThanhVien) {
        let dsThanhVien = []
        step.dsThanhVien.forEach((subItem, subIndex) => {
          let newObj = {
            buoc: index + 1,
            hinh_thuc: step.hinhThuc,
            chuc_danh_id: subItem.chucDanhId,
            nhan_vien_id: subItem.nhanVienId,
            thanh_vien_id: subItem.thanhVienId,
            truong_ky: subItem.truongKy,
            ten_nhan_vien: subItem.tenNhanVien,
          }
          dsThanhVien.push(newObj)
          dsTruongKy.push(subItem.truongKy)
          if (subItem.truongKy === 'socv, ngaycv') {
            countUserProgression++
          }
        })
        stepItem = {
          ...stepItem,
          ds_thanh_vien: dsThanhVien,
        }
      }
      dsBuoc.push(stepItem)
    })

    dsTruongKy = filter(dsTruongKy, item => item !== '')
    let messageToaster = null
    if (countUserProgression > 1) {
      messageToaster = MESSAGE.UNIQ_USER_PROGRESSION
    }

    if (countStepTypeWayProgression > 0 && countStepTypeWayHasSign === 0) {
      messageToaster = MESSAGE.REQUIRED_CONDITION_SIGN_WAY_PROGRESSION
    }

    if (uniq(dsTruongKy).length < dsTruongKy.length) {
      messageToaster = MESSAGE.UNIQ_SIGN_FIELD
    }

    if (!dsBuoc || !dsBuoc.length || compact(errorMembers).length > 0) {
      error.dsBuoc = MESSAGE.VALIDATE_MEMBERS_PROJECT
    }

    if (Object.keys(error).length !== 0 || messageToaster !== null) {
      this.setState(
        {
          error,
          contentFileSize,
          errorMembers,
        },
        () => {
          this.props.onSubmit && this.props.onSubmit({}, false, messageToaster)
        }
      )
      return {
        isCheck: false,
        data: null,
      }
    } else {
      this.setState({
        error,
        contentFileSize,
        errorMembers,
        attachFileErrors: [],
      })
      return {
        isCheck: true,
        data: {
          dsBuoc,
        },
      }
    }
  }

  handleSubmit = async () => {
    // Validate
    if (this.state.isLoading === true) {
      return
    }

    await this.setState({ isLoading: true })
    const response = this.checkFormValidate()
    if (!isEmpty(response) && !response.isCheck) {
      return this.setState({ isLoading: false })
    }

    const { data } = response
    const dataRequest = {
      du_an_id: this.state.duAnId,
      so_ky_hieu: this.state.soKyHieu,
      ten_thanh_pho: this.state.tenThanhPho,
      trich_yeu: this.state.trichYeu,
      file_noi_dung: this.state.fileNoiDung,
      file_tham_khao: this.state.fileThamKhao,
      ds_buoc: data.dsBuoc,
      files: {
        contentFile: this.state.contentFile,
        attachFiles: this.state.attachFiles,
      },
    }
    const success =
      this.props.onSubmit && this.props.onSubmit(dataRequest, true, null)
    if (!!!success) {
      return this.setState({ isLoading: false })
    }
  }

  handlePreview = async () => {
    const { isOpenDialog, isLoading } = this.state
    let newOpenDialog = !isOpenDialog
    let loading = isLoading
    if (!isOpenDialog) {
      const response = this.checkFormValidate()
      if (!isEmpty(response) && !response.isCheck) {
        return this.setState({ isLoading: false })
      }

      const { data } = response
      const dataRequest = {
        du_an_id: this.state.duAnId,
        so_ky_hieu: this.state.soKyHieu,
        ten_thanh_pho: this.state.tenThanhPho,
        trich_yeu: this.state.trichYeu,
        file_noi_dung: this.state.fileNoiDung,
        file_tham_khao: this.state.fileThamKhao,
        ds_buoc: data.dsBuoc,
        files: {
          contentFile: this.state.contentFile,
          attachFiles: this.state.attachFiles,
        },
      }
      if (this.props) {
        const responsePreview = await this.props.handlePreview(dataRequest)
        if (responsePreview) {
          loading = false
        }
      }
    }

    this.setState({
      isOpenDialog: newOpenDialog,
      isLoading: loading,
    })
  }

  componentWillReceiveProps(nextProps) {
    let state = this.state
    if (
      nextProps.projects &&
      isEqual(nextProps.projects, this.props.projects) === false &&
      nextProps.projects[0] &&
      nextProps.projects[0].duAnId
    ) {
      state.duAnId = nextProps.projects[0].duAnId
    }
    if (
      nextProps.cityName &&
      isEqual(nextProps.cityName, this.props.cityName) === false
    ) {
      state.tenThanhPho = nextProps.cityName
    }
    if (nextProps.isSubmitFail === true) {
      state.isLoading = false
    }
    if (
      nextProps.isUpdate === true &&
      nextProps.isUpdate !== this.props.isUpdate
    ) {
      state.isChangedProcedure = true
    }
    this.setState(state)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isUpdate &&
      this.props.signNumberProject &&
      this.props.signNumberProject !== prevProps.signNumberProject
    ) {
      let state = this.state
      state.duAnId = this.props.signNumberProject.duAnId
      state.soKyHieu = this.props.signNumberProject.soKyHieu || ''
      state.trichYeu = this.props.signNumberProject.trichYeu || ''
      state.tenThanhPho = this.props.signNumberProject.tenThanhPho || ''
      if (this.props.signNumberProject.fileNoiDung) {
        state.contentFileName = this.props.signNumberProject.fileNoiDung.tenFile
        state.fileNoiDung = Tool.Utils.linkObjectKey(
          this.props.signNumberProject.fileNoiDung
        )
      }
      if (this.props.signNumberProject.fileThamKhao) {
        let attachFiles = []
        let attachFileNames = []
        let attachQty = []
        this.props.signNumberProject.fileThamKhao.forEach((item, index) => {
          attachFiles.push(Tool.Utils.linkObjectKey(item))
          attachFileNames.push(item.tenFile)
          attachQty.push(index)
        })
        state.fileThamKhao = attachFiles
        state.attachFileNames = attachFileNames
        state.attachQty = attachQty
      }
      state.dsBuoc = this.props.signNumberProject.stepsFromEdit
      this.setState(state)
    }
  }

  render() {
    const { fileTrinhKy } = this.props
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label" htmlFor="duAn">
                        Tờ trình, phiếu trình
                      </label>
                      <div className="pt-form-content">
                        <div className="pt-select">
                          <select
                            className={classnames('pt-input', {
                              'loading-input':
                                this.props.isLoadingChangeProject ||
                                (this.props.isLoadingData &&
                                  this.props.isLoadingData.duAnId),
                            })}
                            name="duAnId"
                            value={this.state.duAnId}
                            onChange={
                              this.state.isChangedProcedure
                                ? this.handleClickPopup
                                : this.handleInputChange
                            }
                            disabled={
                              this.props.isLoadingChangeProject ||
                              (this.props.isLoadingData &&
                                this.props.isLoadingData.duAnId) ||
                              (this.props.isUpdate === true &&
                                this.state.isChangeFileUpdate === false)
                            }
                          >
                            {this.props.projects &&
                              this.props.projects.map((item, i) => (
                                <option value={item.duAnId} key={i}>
                                  {item.tenDuAn}
                                </option>
                              ))}
                          </select>
                        </div>
                        {this.state.error.duAnId !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.duAnId}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label" htmlFor="soKyHieu">
                        Số ký hiệu
                        {/* <span className="pt-text-muted required-input">*</span> */}
                      </label>
                      <div className="pt-form-content">
                        <input
                          className="pt-input"
                          type="text"
                          name="soKyHieu"
                          placeholder="Nhập số ký hiệu..."
                          value={this.state.soKyHieu}
                          onChange={this.handleInputChange}
                          maxLength={GIOI_HAN_KY_TU_SO_KY_HIEU}
                        />
                        {this.state.error.soKyHieu !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.soKyHieu}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label" htmlFor="tenThanhPho">
                        Tên thành phố
                      </label>
                      <div className="pt-form-content">
                        <input
                          className={classnames('pt-input', {
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.tenThanhPho,
                          })}
                          type="text"
                          name="tenThanhPho"
                          placeholder="Nhập tên thành phố..."
                          value={this.state.tenThanhPho}
                          onChange={this.handleInputChange}
                          disabled={
                            this.props.isLoadingData &&
                            this.props.isLoadingData.tenThanhPho
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label" htmlFor="trichYeu">
                        Trích yếu
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content">
                        <textarea
                          className="pt-input pt-fill"
                          rows="3"
                          name="trichYeu"
                          value={this.state.trichYeu}
                          onChange={this.handleInputChange}
                          placeholder=" Nhập nội dung..."
                        />
                        {this.state.error.trichYeu !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.trichYeu}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label" htmlFor="fileNoiDungTrinhKy">
                        File nội dung trình ký{' '}
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="form-item file-box pt-form-content">
                        <label className="pt-file-upload">
                          <input
                            className="pt-input"
                            type="file"
                            name="contentFile"
                            onChange={this.handleContentFileChange}
                            onClick={e => {
                              e.target.value = null
                            }}
                            disabled={this.state.isLoading}
                          />
                          <span className="pt-file-upload-input">
                            {this.state.contentFileName ||
                              'Chọn file nội dung trình ký'}
                          </span>
                        </label>
                        {this.state.contentFileSize && (
                          <p className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file đã upload:
                            <span className="size-file">
                              {' '}
                              {Tool.Utils.getFileSize(
                                this.state.contentFileSize
                              )}
                            </span>
                          </p>
                        )}
                        {this.state.error.contentFile !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.contentFile}
                          </div>
                        )}
                        {this.state.isLoading &&
                          this.props.contentFileProgress &&
                          this.props.contentFileProgress > 0 && (
                            <div className="progress-upload-content">
                              <span className="percent-content">
                                {this.props.contentFileProgress}%
                              </span>
                              <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                <div
                                  className="pt-progress-meter"
                                  style={{
                                    width: this.props.contentFileProgress + '%',
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label" htmlFor="cacFileThamKhao">
                        Các file tham khảo
                      </label>
                      {this.state.attachQty.map((item, i) => (
                        <div
                          key={i}
                          className={classnames(
                            'form-item file-box pt-form-content',
                            {
                              'file-box-margin': this.state.attachQty && i >= 1,
                            }
                          )}
                        >
                          <label className="pt-file-upload">
                            <input
                              className="pt-input"
                              type="file"
                              name={`attachFiles-${i}`}
                              onChange={this.handleAttachFileChange}
                              onClick={e => {
                                e.target.value = null
                              }}
                              disabled={this.state.isLoading}
                            />
                            <span className="pt-file-upload-input">
                              {this.state.attachFileNames &&
                              this.state.attachFileNames[i]
                                ? this.state.attachFileNames[i]
                                : 'Chọn file đính kèm'}
                            </span>
                          </label>
                          {!this.state.isLoading &&
                            (i > 0 ||
                              (i === 0 &&
                                ((this.state.fileThamKhao &&
                                  this.state.fileThamKhao[0]) ||
                                  (this.state.attachFiles &&
                                    this.state.attachFiles[0])))) && (
                              <span
                                className="icon-bc icon-close icon-remove-file"
                                onClick={this.handleRemoveAttachFile.bind(
                                  this,
                                  i
                                )}
                              ></span>
                            )}
                          {this.state.attachFileSizes[i] !== undefined && (
                            <p className="pt-form-helper-text pt-form-helper-infomation">
                              Kích thước file đã upload:
                              <span className="size-file">
                                {' '}
                                {Tool.Utils.getFileSize(
                                  this.state.attachFileSizes[i]
                                )}
                              </span>
                            </p>
                          )}
                          {this.state.attachFileErrors[i] !== undefined && (
                            <div className="pt-form-helper-text">
                              {this.state.attachFileErrors[i]}
                            </div>
                          )}
                          {this.state.isLoading &&
                            this.props.attachFilesProgress &&
                            this.props.attachFilesProgress[i] > 0 && (
                              <div className="progress-upload-content">
                                <span className="percent-content">
                                  {this.props.attachFilesProgress[i]}%
                                </span>
                                <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                  <div
                                    className="pt-progress-meter"
                                    style={{
                                      width:
                                        this.props.attachFilesProgress[i] + '%',
                                    }}
                                  ></div>
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="form-group-item">
                      <button
                        className="pt-button btn-add-more"
                        type="button"
                        onClick={this.handleAddAttachFile}
                        disabled={this.state.isLoading}
                      >
                        <span className="text-content">Thêm file đính kèm</span>
                        <span className="icon-bc icon-Plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*form-subblock*/}
            </div>
            <DragProjectDigitalSignature
              onSubmit={this.props.onSubmit}
              onPassData={this.handleChangeProcedure}
              membersProject={this.props.membersProject}
              isChangedProcedure={this.state.isChangedProcedure}
              isLoadingChangeProject={this.props.isLoadingChangeProject}
              isLoadingData={
                this.props.isLoadingData && this.props.isLoadingData.duAnId
              }
              errorMembers={this.state.errorMembers}
              isLoading={this.state.isLoading}
              isUpdate={this.props.isUpdate}
              stepsFromEdit={this.props.stepsFromEdit}
              stepIsSigning={
                this.props.signNumberProject
                  ? this.props.signNumberProject.buoc
                  : null
              }
              statusSign={
                this.props.signNumberProject &&
                this.props.signNumberProject.tinhTrang
              }
              isChangeFileUpdate={this.state.isChangeFileUpdate}
            />
            <div className="bottom-form" id="groupButton">
              <div className="buttton-action-footer">
                <Button
                  className="pt-button pt-button pt-button btn-cancel"
                  type="button"
                  onClick={this.props.onClickCancel}
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back" />
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  onClick={this.handlePreview}
                  className="pt-button btn-save full-btn-right mr20"
                  disabled={
                    this.state.isLoading === true ||
                    this.state.contentFileName === ''
                  }
                  loading={this.state.isLoading}
                >
                  <span className="text-content">Preview</span>
                  <span className="pt-icon icon-Xemthuchien font-size-10"></span>
                </Button>
                <Button
                  className="pt-button pt-button pt-button btn-main-color"
                  type="button"
                  onClick={this.handleSubmit}
                  loading={this.state.isLoading}
                  disabled={
                    Object.values(this.props.isLoadingData).find(
                      item => item === true
                    ) !== undefined || this.state.isLoading === true
                  }
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save" />
                </Button>
              </div>

              <div className="buttton-actionfooter-fixtop buttton-action-footer">
                <Button
                  className="pt-button pt-button pt-button btn-cancel"
                  type="button"
                  onClick={this.props.onClickCancel}
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back" />
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  className="pt-button btn-main-color"
                  onClick={this.handlePreview}
                  disabled={
                    this.state.isLoading === true ||
                    this.state.contentFileName === ''
                  }
                  loading={this.state.isLoading}
                >
                  <span className="pt-icon icon-Xemthuchien font-size-10"></span>
                  <span className="text-content">Preview</span>
                </Button>
                <Button
                  className="pt-button pt-button pt-button btn-main-color"
                  type="button"
                  onClick={this.handleSubmit}
                  loading={this.state.isLoading}
                  disabled={
                    Object.values(this.props.isLoadingData).find(
                      item => item === true
                    ) !== undefined || this.state.isLoading === true
                  }
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save" />
                </Button>
              </div>
            </div>
          </form>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.CAP_NHAT_DU_AN}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleChangeProject}
          >
            {POPUP.XAC_NHAN_THAY_DOI_DU_AN}
          </PopupConfirm>
          <PrevewFileTrinhKy
            isOpenDialog={this.state.isOpenDialog}
            closeDialog={this.handlePreview}
            file={fileTrinhKy}
          />
        </div>
      </div>
    )
  }
}

export default InputFormSignNumberProject
