import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExecutingViewBoxItem from '../common/ExecutingViewBoxItem'
import NotFound from '../common/NotFound'

class ExecutingView extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    executingViews: PropTypes.array,
  }

  render() {
    return (
      <div className="">
        <div className="container-form-action">
          <div className="heading">
            <span className="click-go-back" onClick={this.props.onClickGoBack}>
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Xem thực hiện</h4>
          </div>
          <div className="form-container container-detail view-job-done">
            {(!this.props.executingViews ||
              (this.props.executingViews &&
                this.props.executingViews.length === 0)) && <NotFound />}
            {this.props.executingViews &&
              this.props.executingViews.map((item, i) => (
                <ExecutingViewBoxItem
                  isInternalDoc={true}
                  item={item}
                  key={i}
                  auth={this.props.auth}
                  actions={this.props.actions}
                />
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default ExecutingView
