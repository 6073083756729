import React from 'react'
import { Link } from 'react-router-dom'

class SubGroupItem extends React.Component {
  render() {
    return (
      <div className="collapse-block subcollapse-block">
        <div className="collapse-heading-content">
          <h4 className="subtable-title">
            <Link
              className="link"
              to={`/he-thong/nguoi-dung/phong-ban/${this.props.phongBanId}`}
            >
              <span className="pt-icon icon-Back"></span>
              <span className="full-name">{this.props.name}</span>
            </Link>
          </h4>
          <span className="btn-icon-collapse">
            <span className="pt-icon pt-icon-chevron-up"></span>
          </span>
        </div>
        <div className="subcollapse-panel-content">
          <div className="table-display">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default SubGroupItem
