import { schema } from 'normalizr'

const DocumentCabinetCategory = new schema.Entity(
  'documentCabinetCategories',
  {},
  {
    idAttribute: documentCabinetCategory => documentCabinetCategory.id,
  }
)

export default DocumentCabinetCategory
