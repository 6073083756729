import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import React from 'react'
import LichLamViec from './LichLamViec'

const LichHopCaNhanPage = props => {
  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <HeadingPage
        namePage="Lịch họp cá nhân"
        iconPage="icon2-date"
        nameButton="Tạo lịch"
      />
      <div className="meeting-calendar">
        <div className="meeting-tabs-content">
          <LichLamViec type="CA_NHAN" />
        </div>
      </div>
    </MasterLayout>
  )
}

export default LichHopCaNhanPage
