import React from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { SelectTree } from '../common/'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../actions'
import DepartmentItem from './DepartmentItem'

const LIMIT_USER_MOVE = 50
const OUT_OF_LIMIT_MESSAGE = `Anh/Chị chỉ được chọn tối đa ${LIMIT_USER_MOVE} nhân viên!`
class MoveUsersUnit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      phongBanTree: {},
      users: [],
      donViData: [],
      donViId: this.props.auth.mainUnitId,
      donViChuyenDenId: this.props.auth.mainUnitId,
      phongBan: [],
      phongBanChecked: [],
      userChecked: [],
      donViChecked: [],
      phongBanId: null,
      phongBanChuyenDen: [],
      phongBanChuyenDenId: null,
      isOutOfLimit: false,
      isLoading: {
        phongBan: true,
        phongBanChuyenDen: true,
        submit: false,
      },
    }
  }

  componentDidMount() {
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined
    if (permission) {
      this.props.actions.getCategoryUnitMultiLevel()
    } else {
      this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
    }
    this.getDepartment()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.userChecked !== this.state.userChecked) {
      this.scrollBar.scrollToBottom()
      let donViChecked = []
      this.state.userChecked.forEach(nhanVienId => {
        let user = this.state.users.find(nv => nv.nhanVienId === nhanVienId)
        if (donViChecked.findIndex(id => id === user.donViId) === -1) {
          donViChecked.push(user.donViId)
        }
      })
      this.setState({
        donViChecked,
      })
      if (this.state.userChecked.length > LIMIT_USER_MOVE) {
        await this.setState({
          isOutOfLimit: true,
        })
      } else {
        await this.setState({
          isOutOfLimit: false,
        })
      }
      if (this.state.isOutOfLimit === true) {
        this.props.actions.commonAddToasterMessage({
          message: OUT_OF_LIMIT_MESSAGE,
          intent: Intent.DANGER,
        })
      }
    }
  }

  getDepartment = async (id, key) => {
    if (id && key) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          [key]: true,
        },
      })
    }
    let donViId = id ? this.state[id] : this.state.donViId
    this.props.actions.getCategoryDepartmentMultiLevel(donViId).then(res => {
      if (res && res.error) {
        return
      } else if (res.payload && res.payload.data.result) {
        const data = res.payload.data.result
        let phongBanData = this.getPhongBanData(data, donViId)
        let donViData = [...this.state.donViData]
        if (donViData.filter(item => item.donViId === donViId).length === 0) {
          donViData.push({
            donViId: donViId,
            dsPhongBan: phongBanData,
          })
        }
        if (!key && !id) {
          this.setState({
            donViData: donViData,
            phongBan: data,
            phongBanId: data.length > 0 ? data[0].phongBanId : null,
            phongBanChuyenDen: data,
            phongBanChuyenDenId: data.length > 0 ? data[0].phongBanId : null,
            isLoading: {
              ...this.state.isLoading,
              phongBan: false,
              phongBanChuyenDen: false,
            },
          })
        } else {
          this.setState({
            donViData: donViData,
            [key]: data,
            [`${key}Id`]: data.length > 0 ? data[0].phongBanId : null,
            isLoading: {
              ...this.state.isLoading,
              [key]: false,
            },
          })
        }
      }
    })
  }

  getPhongBanData = (arr, donViId) => {
    let phongBanData = []
    let phongBanConData = []
    arr.forEach(item => {
      phongBanData.push({ ...item, donViId })
      if (item.dsPhongBanCon)
        phongBanConData = this.getPhongBanData(item.dsPhongBanCon, donViId)
      phongBanConData.forEach(pbCon => {
        if (
          phongBanData.findIndex(pb => pb.phongBanId === pbCon.phongBanId) ===
          -1
        ) {
          phongBanData.push(pbCon)
        }
      })
    })
    return phongBanData
  }

  addUserToTree = users => {
    let usersList = [...this.state.users]
    users.forEach(item => {
      if (
        this.state.users.findIndex(
          user => user.nhanVienId === item.nhanVienId
        ) === -1
      ) {
        usersList.push({
          ...item,
          maPhongBan: this.getMaPhongBan(item.phongBanId),
          donViId: this.state.donViId,
          tenVietTat: this.getTenVietTat(
            this.props.unitSelect.items,
            this.state.donViId
          ),
        })
      }
    })
    this.setState({
      users: usersList,
    })
  }

  handleChange = (type, e) => {
    if (e !== undefined) {
      this.setState(
        {
          [type]: e,
        },
        () => {
          if (type === 'donViChuyenDenId') {
            this.getDepartment('donViChuyenDenId', 'phongBanChuyenDen')
          } else if (type === 'donViId') {
            this.getDepartment('donViId', 'phongBan')
          }
        }
      )
    }
  }

  addToPhongBanChecked = async phongBanId => {
    if (typeof phongBanId === 'object') {
      let dsIdPhongBanUnchecked = []
      phongBanId.forEach(item => {
        if (this.state.phongBanChecked.findIndex(id => id === item) === -1) {
          dsIdPhongBanUnchecked.push(item)
        }
      })
      this.setState(state => ({
        phongBanChecked: [...state.phongBanChecked, ...dsIdPhongBanUnchecked],
      }))
    } else {
      let newPhongBanChecked = [...this.state.phongBanChecked]
      if (
        this.state.phongBanChecked.filter(item => item === phongBanId)
          .length === 0
      ) {
        newPhongBanChecked.push(phongBanId)
      }
      await this.setState({
        phongBanChecked: newPhongBanChecked,
      })
    }
  }

  removeFromPhongBanChecked = phongBanId => {
    if (typeof phongBanId === 'object') {
      let newPBChecked = [...this.state.phongBanChecked]
      phongBanId.forEach(item => {
        if (this.state.phongBanChecked.findIndex(id => id === item) !== -1) {
          newPBChecked = newPBChecked.filter(id => id !== item)
        }
      })
      this.setState(state => ({ phongBanChecked: newPBChecked }))
    } else {
      this.setState(state => ({
        phongBanChecked: state.phongBanChecked.filter(
          item => item !== phongBanId
        ),
      }))
    }
  }

  addToUserChecked = async nhanVienId => {
    if (typeof nhanVienId === 'object') {
      let dsIdNhanVienUnchecked = []
      nhanVienId.forEach(item => {
        if (this.state.userChecked.findIndex(id => id === item) === -1) {
          dsIdNhanVienUnchecked.push(item)
        }
      })
      this.setState(state => ({
        userChecked: [...state.userChecked, ...dsIdNhanVienUnchecked],
      }))
    } else {
      let newUserChecked = [...this.state.userChecked]
      if (
        this.state.userChecked.filter(item => item === nhanVienId).length === 0
      ) {
        newUserChecked.push(nhanVienId)
        await this.setState({ userChecked: newUserChecked })
      }
    }
  }

  removeFromUserChecked = async nhanVienId => {
    if (typeof nhanVienId === 'object') {
      let newUserChecked = [...this.state.userChecked]
      nhanVienId.forEach(item => {
        if (this.state.userChecked.findIndex(id => id === item) !== -1) {
          newUserChecked = newUserChecked.filter(id => id !== item)
        }
      })
      this.setState(state => ({ userChecked: newUserChecked }))
    } else {
      let newUserChecked = this.state.userChecked.filter(
        item => item !== nhanVienId
      )
      await this.setState({
        userChecked: newUserChecked,
      })
    }
  }

  handleSubmit = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        submit: true,
      },
    })
    let data = {
      don_vi_id: this.state.donViChuyenDenId,
      phong_ban_id: this.state.phongBanChuyenDenId,
      ds_nhan_vien_id: this.state.userChecked,
      ds_don_vi_chuyen_di_id: this.state.donViChecked,
    }
    this.props.actions.moveUser(data).then(res => {
      this.setState(
        {
          isLoading: {
            ...this.state.isLoading,
            submit: false,
          },
        },
        () => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            let result = res.payload.data.result
            if (result && result.length > 0) {
              if (result.length === this.state.userChecked.length) {
                this.props.actions.commonAddToasterMessage({
                  message: `Di chuyển nhân viên thành công (${result.length}/${this.state.userChecked.length})`,
                  intent: Intent.SUCCESS,
                })
              } else {
                this.props.actions.commonAddToasterMessage({
                  message: `Di chuyển nhân viên thành công (${result.length}/${this.state.userChecked.length}). Lưu ý: Nhân viên có thể chưa hoàn thành hết công việc cá nhân.`,
                  intent: Intent.WARNING,
                })
              }
              this.props.handleGoBack()
            } else {
              this.props.actions.commonAddToasterMessage({
                message: `Di chuyển nhân viên thất bại. Lưu ý: Nhân viên có thể chưa hoàn thành hết công việc cá nhân.`,
                intent: Intent.DANGER,
              })
            }
          } else {
            this.props.actions.commonAddToasterMessage({
              message: 'Di chuyển nhân viên thất bại!',
              intent: Intent.DANGER,
            })
          }
        }
      )
    })
  }

  getTenVietTat = (arr, donViId) => {
    let tenVietTat = ''
    arr.some(item => {
      if (item.dsDonViCon) {
        tenVietTat = this.getTenVietTat(item.dsDonViCon, donViId)
      }
      if (item.donViId === donViId) {
        tenVietTat = item.tenVietTat
      }
      if (tenVietTat) {
        return true
      } else return false
    })
    if (tenVietTat) return tenVietTat
  }

  getMaPhongBan = phongBanId => {
    let donVi = this.state.donViData.find(
      dv => dv.donViId === this.state.donViId
    )
    let phongBan = donVi.dsPhongBan.find(item => item.phongBanId === phongBanId)
    return phongBan.maPhongBan
  }

  render() {
    let departmentItemList = []
    let donVi = this.state.donViData.filter(
      item => item.donViId === this.state.donViId
    )
    let dsPhongBan =
      donVi && donVi[0] && donVi[0].dsPhongBan ? donVi[0].dsPhongBan : []
    let dsUserChecked = []
    if (dsPhongBan && dsPhongBan.length > 0) {
      dsPhongBan.forEach(
        item =>
          !item.phongBanCha &&
          departmentItemList.push(
            <DepartmentItem
              dsPhongBan={dsPhongBan}
              userChecked={this.state.userChecked}
              phongBanChecked={this.state.phongBanChecked}
              usersList={this.state.users}
              addUserToTree={this.addUserToTree}
              actions={this.props.actions}
              key={item.phongBanId}
              isFirstChild={departmentItemList.length === 0}
              item={item}
              type="phongBan"
              addToPhongBanChecked={this.addToPhongBanChecked}
              removeFromPhongBanChecked={this.removeFromPhongBanChecked}
              addToUserChecked={this.addToUserChecked}
              removeFromUserChecked={this.removeFromUserChecked}
              level="1"
            />
          )
      )
    }
    this.state.userChecked.length > 0 &&
      this.state.userChecked.forEach(item => {
        let user = this.state.users.find(user => user.nhanVienId === item)
        dsUserChecked.push(user)
      })
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="row m0">
              <div className="col-xs-4 p0 border-right">
                <div className="p20">
                  <SelectTree
                    data={this.props.unitSelect.items}
                    type="Unit"
                    permission={false}
                    value={
                      this.props.unitSelect.items.length > 0
                        ? this.state.donViId
                        : ''
                    }
                    handleChange={this.handleChange.bind(this, 'donViId')}
                  />
                </div>
                <div className="vh-65 path-save border-top">
                  <Scrollbars
                    autoHide
                    autoHideTimeout={100}
                    autoHideDuration={200}
                  >
                    {departmentItemList}
                  </Scrollbars>
                </div>
              </div>
              <div className="col-xs-8 d-flex flex-column p0">
                <h2 className="h2 font-bold p20 font-size-18">
                  Danh sách nhân viên cần di chuyển (
                  <span
                    className={`${
                      this.state.isOutOfLimit ? 'color-red' : 'color-blue'
                    }`}
                  >
                    {dsUserChecked.length}/{LIMIT_USER_MOVE}
                  </span>
                  )
                </h2>
                <div className="col-xs">
                  <Scrollbars
                    ref={el => (this.scrollBar = el)}
                    autoHide
                    autoHideTimeout={100}
                    autoHideDuration={200}
                  >
                    <div className="row m0">
                      {dsUserChecked &&
                        dsUserChecked.map(item => (
                          <div key={item.nhanVienId} className="col-xs-6 p0">
                            <div className="d-flex pl10 pr10 pt5 pb5 middle-xs between-xs bg-grey m5">
                              <div className="font-size-14">
                                <p className="font-bold">{item.tenNhanVien}</p>
                                <p className="text-secondary">
                                  {item.maPhongBan} - {item.tenVietTat}
                                </p>
                              </div>
                              <span
                                onClick={() =>
                                  this.removeFromUserChecked(item.nhanVienId)
                                }
                                className="pt-icon pt-icon-trash cursor-pointer"
                              ></span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Scrollbars>
                </div>
                <div className="pl20 pb20 pr20 pt10 border-top">
                  <div className="d-flex flex-column mb15">
                    <label className="search-label mb10 font-size-14">
                      Đơn vị chuyển đến<span className="required-input">*</span>
                    </label>
                    <SelectTree
                      data={this.props.unitSelect.items}
                      type="Unit"
                      permission={false}
                      value={
                        this.props.unitSelect.items.length > 0
                          ? this.state.donViChuyenDenId
                          : ''
                      }
                      handleChange={this.handleChange.bind(
                        this,
                        'donViChuyenDenId'
                      )}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="search-label mb10 font-size-14">
                      Phòng ban chuyển đến
                      <span className="required-input">*</span>
                    </label>
                    <SelectTree
                      data={
                        this.state.phongBanChuyenDen
                          ? this.state.phongBanChuyenDen
                          : []
                      }
                      type="Department"
                      permission={false}
                      value={
                        this.state.phongBanChuyenDen.length > 0
                          ? this.state.phongBanChuyenDenId
                          : ''
                      }
                      handleChange={this.handleChange.bind(
                        this,
                        'phongBanChuyenDenId'
                      )}
                      isLoading={this.state.isLoading.phongBanChuyenDen}
                      isGetTenPhongBan={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  onClick={this.props.handleGoBack}
                  type="button"
                  className="pt-button pt-button btn-cancel"
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  disabled={
                    this.state.userChecked.length === 0 ||
                    this.state.isOutOfLimit ||
                    this.state.isLoading.submit
                  }
                  onClick={this.handleSubmit}
                  type="button"
                  className="pt-button pt-button btn-main-color"
                  loading={this.state.isLoading.submit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  departments: {
    ...state.category,
    items: state.category.categoryDepartmentMultiLevel,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoveUsersUnit)
