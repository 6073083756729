import React from 'react'
import csx from 'classnames'
import { get } from 'lodash'
import { Position, Tooltip } from '@blueprintjs/core'

import { Progress } from 'components/newCommon2'
import {
  Col,
  Row,
  PrioritizeFlag,
  StatusDate,
  TagGroup,
  Icon,
} from 'components/newCommon'
import { CardProfile } from 'components/newCommon2'
import { ASSIGN_TYPE, WORK_TYPE } from 'constants/Enum'

const types = {
  toiGiao: 'toi-giao',
  duocGiao: 'duoc-giao',
  theoDoi: 'theo-doi',
}

export const KanbanItem = ({
  pageTypes,
  item,
  onItemClick,
  isProcessing,
  isCompleted,
}) => {
  const getDeadline = () => {
    return item?.deadline
  }

  return (
    <div
      onClick={() => {
        onItemClick(item?.id, 'chi-tiet', item?.status)
      }}
    >
      <Row className={csx('mb5')}>
        <Col grid="fiveSixth" className={'title-content'}>
          <span
            className={csx({
              'text-color medium':
                pageTypes === types?.theoDoi && !item?.isRead,
            })}
          >
            {item?.title}
          </span>
        </Col>
        <Col grid="oneSixth" className={csx('text-right', 'p0')}>
          <PrioritizeFlag type={item?.priority} />
        </Col>
      </Row>
      <Row className={csx('mb5')}>
        <TagGroup
          tags={item?.workItemTag?.map(({ name, backgroundColor }) => ({
            name: name || '',
            color: backgroundColor || '',
          }))}
          preChildren={
            <div>
              {item?.isDaLuuTuPhongBan && (
                <Tooltip
                  content={
                    <span className="font-size-12">
                      Công việc đã lưu tủ tài liệu
                    </span>
                  }
                  position={Position.BOTTOM}
                >
                  <Icon
                    classIcon="icon-Save-DocumentCabinet"
                    className={csx(
                      'icon-color-primary',
                      'size-icon-18',
                      'mr10 mb5',
                      'd-block'
                    )}
                  />
                </Tooltip>
              )}
              {item?.workType === WORK_TYPE.EOFFICE && (
                <Tooltip
                  content={
                    <span className="font-size-13">
                      Công việc Ký số, Công văn
                    </span>
                  }
                  position={Position.BOTTOM}
                  tar
                >
                  <Icon
                    classIcon="icon2-e-office-label"
                    className={csx(
                      'icon-color-primary',
                      'size-icon-18',
                      'mr10 mb5',
                      'd-block'
                    )}
                  />
                </Tooltip>
              )}
              {item?.workType === WORK_TYPE.MEETING && (
                <Tooltip
                  content={
                    <span className="font-size-12">
                      Công việc Thông báo KLCH
                    </span>
                  }
                  position={Position.BOTTOM}
                >
                  <Icon
                    classIcon="icon2-clipboard"
                    className={csx(
                      'icon-color-yellow',
                      'size-icon-18',
                      'mr10 mb5',
                      'd-block'
                    )}
                  />
                </Tooltip>
              )}
              {item?.workType === WORK_TYPE.DOFFICE && (
                <Tooltip
                  content={
                    <span className="font-size-12">Công việc D-Office</span>
                  }
                  position={Position.BOTTOM}
                >
                  <Icon
                    classIcon="icon2-d-office"
                    className={csx(
                      'icon-color-danger',
                      'size-icon-18',
                      'mr10 mb5',
                      'd-block'
                    )}
                  />
                </Tooltip>
              )}
            </div>
          }
        />
      </Row>
      <Row className={csx('mb5', 'mt10')}>
        <Col className={csx('p0', 'd-flex', 'align-item-center')}>
          <StatusDate
            date={getDeadline()}
            isCompleted={isCompleted}
            showIcon
            descriptionSize={11}
            descriptionInline
            descriptionFlex
            centerFlex
          />
        </Col>
      </Row>
      <Row className="align-item-center">
        <Col grid="threeQuarters" className={csx('p0')}>
          {get(item, 'mainResponsibility.type') === ASSIGN_TYPE.CA_NHAN ? (
            <CardProfile profile={item?.mainResponsibility} />
          ) : (
            <span className="font-size-13">
              {item?.mainResponsibility?.shortName}
            </span>
          )}
        </Col>
        <Col
          grid="quarterSmFull"
          className={csx('p0', 'd-flex', 'justify-end')}
        >
          {isProcessing && (
            <div style={{ width: 32 }}>
              <Progress progress={get(item, 'progress', 0)} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}
