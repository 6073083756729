import { useState, useEffect, useCallback } from 'react'

const useNotification = ({ actions }) => {
  const [isLast, setIsLast] = useState(false)
  const [isFetch, setIsFetch] = useState(false)
  const [listData, setListData] = useState(null)

  const getList = useCallback(
    (page = 1) => {
      setIsFetch(true)
      actions
        .getNotificationList({
          page: page,
          limit: 10,
        })
        .then(res => {
          if (res?.error) {
            setIsLast(true)
            return
          }

          setIsFetch(false)
          setIsLast(res?.payload?.data?.result?.items?.length === 0 ?? false)
          if (page > 1) {
            setListData(prev => ({
              ...prev,
              page: res?.payload?.data?.result?.page,
              items: [...prev.items, ...res?.payload?.data?.result?.items],
            }))
          } else {
            setListData(res?.payload?.data?.result)
          }
        })
    },
    [actions]
  )

  const onItemClick = useCallback(
    data => {
      actions
        .postNotificationRead({
          thongBaoChungId: data?.id,
        })
        .then(res => {
          if (res?.payload?.data?.result) {
            actions.fetchNotificationCounter()
            setListData(prev => ({
              ...prev,
              items: prev?.items?.map(e =>
                e?.id === data?.id ? { ...e, daDoc: true } : e
              ),
            }))
          }
        })
    },
    [actions]
  )

  const onReadAll = useCallback(() => {
    actions.postNotificationReadAll().then(res => {
      if (res?.payload?.data?.result) {
        actions.fetchNotificationCounter()
        setListData(prev => ({
          ...prev,
          items: prev?.items?.map(e => ({ ...e, daDoc: true })),
        }))
      }
    })
  }, [actions])

  useEffect(() => {
    getList()
  }, [getList])

  return { isFetch, listData, isLast, getList, onItemClick, onReadAll }
}

export default useNotification
