import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import classNames from 'classnames'
import DanhSachLichHop from './DanhSachLichHop'
import * as Types from 'constants/Api'
import moment from 'moment'
import * as MESSAGER from 'constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import LichLamViecQuanLy from './LichLamViecQuanLy'

const LINK = '/lich-hop/quan-ly-lich-hop/them-moi'
const QuanLyLichHopPage = props => {
  const [tab, setTab] = useState('list')
  const [textSearch, setTextSearch] = useState('')
  const [cuocHopId, setCuocHopId] = useState('')
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false)
  const [isOpenPopupConfirmCancel, setIsOpenPopupConfirmCancel] =
    useState(false)
  const [isOpenPopupKetThuc, setIsOpenPopupKetThuc] = useState(false)
  const [filter, setFilter] = useState({
    page: props.dsLichHop.page || 1,
    limit: Types.PAGINATION_LIMIT,
    fromDate: undefined,
    toDate: undefined,
    theLoaiId: undefined,
    mucDoId: undefined,
    chuTriId: undefined,
    trangThai: undefined,
  })

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeTab = key => {
    setTab(key)
  }

  const handleClickPage = page => {
    setFilter({
      ...filter,
      page: page,
    })
  }

  const handleChangeFilter = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleClearFilter = () => {
    setFilter({
      page: 1,
      limit: Types.PAGINATION_LIMIT,
      fromDate: undefined,
      toDate: undefined,
      theLoaiId: '',
      mucDoId: '',
      chuTriId: '',
      trangThai: '',
    })
    setTextSearch('')
  }

  const hanldeChangeTextSearch = value => {
    setTextSearch(value)
  }

  const handleClickSearch = () => {
    handleGetDsLichHop(true)
    setFilter({
      ...filter,
      page: 1,
    })
  }

  const handleClickPopup = id => {
    setCuocHopId(id)
    setIsOpenPopupConfirm(!isOpenPopupConfirm)
  }

  const handleClickPopupCancel = id => {
    setCuocHopId(id)
    setIsOpenPopupConfirmCancel(!isOpenPopupConfirmCancel)
  }

  const handleClickPopupKetThuc = id => {
    setCuocHopId(id)
    setIsOpenPopupKetThuc(!isOpenPopupKetThuc)
  }

  const handleGetDsLichHop = isSearch => {
    setIsLoading(true)
    const filterNew = {
      ...filter,
      textSearch: textSearch?.trim() || undefined,
      fromDate: filter?.fromDate
        ? moment(filter?.fromDate).format('YYYY-MM-DD')
        : null,
      toDate: filter?.toDate
        ? moment(filter?.toDate).format('YYYY-MM-DD')
        : null,
      theLoaiId: filter?.theLoaiId || undefined,
      mucDoId: filter?.mucDoId || undefined,
      chuTriId: filter?.chuTriId || undefined,
      trangThai: filter?.trangThai || undefined,
      page: isSearch ? 1 : filter?.page,
    }

    props.actions
      .getDanhSachLichHop(filterNew)
      .then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          setIsError(true)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSubmitRemoveItem = () => {
    if (cuocHopId) {
      props.actions.deleteLichHop(cuocHopId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
    setIsOpenPopupConfirm(false)
  }

  const handleSubmitCancelItem = () => {
    if (cuocHopId) {
      props.actions.putHuyLichHop(cuocHopId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_CANCLE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_CANCLE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
    setIsOpenPopupConfirmCancel(false)
  }

  const handleSubmitKetThucItem = () => {
    if (cuocHopId) {
      props.actions.putKetThucCuocHop(cuocHopId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_STOP_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_STOP_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
    setIsOpenPopupKetThuc(false)
  }

  const handleSubmitGuiCuocHop = id => {
    if (id) {
      props.actions.putGuiLichHop(id).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_SEND_MAIL_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_SEND_MAIL_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
  }

  const renderContentTab = tab => {
    if (tab === 'calendar') {
      return <LichLamViecQuanLy type="QUAN_LY" history={props.history} />
    } else {
      return (
        <DanhSachLichHop
          listData={props?.dsLichHop?.items}
          dsLoaiCuocHopCombobox={props?.dsLoaiCuocHopCombobox}
          dsCapDoCuocHopCombobox={props?.dsCapDoCuocHopCombobox}
          isError={isError}
          isLoading={isLoading}
          current={filter?.page}
          filter={filter}
          total={Math.ceil(props?.dsLichHop?.total / Types.PAGINATION_LIMIT)}
          handleClickPage={handleClickPage}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          handleChangeFilter={handleChangeFilter}
          hanldeChangeTextSearch={hanldeChangeTextSearch}
          handleClearFilter={handleClearFilter}
          handleClickSearch={handleClickSearch}
          handleClickPopup={handleClickPopup}
          handleClickPopupCancel={handleClickPopupCancel}
          handleSubmitRemoveItem={handleSubmitRemoveItem}
          handleSubmitCancelItem={handleSubmitCancelItem}
          handleSubmitKetThucItem={handleSubmitKetThucItem}
          isOpenPopupConfirm={isOpenPopupConfirm}
          isOpenPopupConfirmCancel={isOpenPopupConfirmCancel}
          handleSubmitGuiCuocHop={handleSubmitGuiCuocHop}
          handleClickPopupKetThuc={handleClickPopupKetThuc}
          isOpenPopupKetThuc={isOpenPopupKetThuc}
          history={props.history}
        />
      )
    }
  }

  useEffect(() => {
    props.actions.getDanhSachLoaiCuocHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
    props.actions.getDanhSachCapDoCuocHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        this.setState({ isError: true, isLoading: false })
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetDsLichHop()
    // eslint-disable-next-line
  }, [filter])

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <HeadingPage
        namePage="Quản lý lịch họp"
        iconPage="icon2-date"
        showButton={true}
        linkButton={LINK}
        nameButton="Tạo lịch"
      />
      <div className="meeting-calendar">
        <div className="meeting-tabs">
          <div
            className={classNames('meeting-item', { active: tab === 'list' })}
            onClick={() => handleChangeTab('list')}
          >
            <span className="meeting-item__icon pt-icon icon-list"></span>
            <span className="meeting-item__text">DANH SÁCH</span>
          </div>
          <div
            className={classNames('meeting-item', {
              active: tab === 'calendar',
            })}
            onClick={() => handleChangeTab('calendar')}
          >
            <span className="meeting-item__icon pt-icon icon-calendar-3"></span>
            <span className="meeting-item__text">LỊCH THÁNG</span>
          </div>
        </div>
        <div className="meeting-tabs-content">{renderContentTab(tab)}</div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  dsLichHop: {
    ...state.dsLichHop,
    page: state.lichHop.dsLichHop?.page,
    items: state.lichHop.dsLichHop?.items,
    total: state.lichHop.dsLichHop?.total,
  },
  dsLoaiCuocHopCombobox: state?.lichHop?.dsLoaiCuocHop?.map(item => ({
    value: item?.theLoaiId,
    name: item?.ten,
  })),
  dsCapDoCuocHopCombobox: state?.lichHop?.dsCapDoCuocHop?.map(item => ({
    value: item?.mucDoId,
    name: item?.ten,
  })),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuanLyLichHopPage)
