import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import moment from 'moment'
class ThongTinTaiLieu extends Component {
  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const { data } = this.props
    const extension =
      data &&
      data.fileNoiDung &&
      data.fileNoiDung.tenFile &&
      data.fileNoiDung.tenFile
        .slice(((data.fileNoiDung.tenFile.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin tài liệu</h4>
        </div>
        <div className="form-container section-box-info">
          <div className="detail-info-container">
            <div className="list-items">
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Tên tài liệu:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">{data.tenTaiLieu}</span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Số công văn:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">{data.soCongVan}</span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Ngày công văn:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {data.ngayCongVan &&
                      moment(data.ngayCongVan).format('DD/MM/YYYY')}
                  </span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Cơ quan ban hành</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">{data.coQuanBanHanh}</span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Ghi chú:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content textarea-content">
                    {data.ghiChu}
                  </span>
                </div>
              </div>
              {data.tenNganTu && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Lưu vào:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">{data.tenNganTu}</span>
                  </div>
                </div>
              )}
              {this.props.path && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">Đường dẫn:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content">{this.props.path}</span>
                  </div>
                </div>
              )}
              {data.fileNoiDung && (
                <div className="row item-value">
                  <div className="col-xs-3 left-content">
                    <span className="label-text">File nội dung:</span>
                  </div>
                  <div className="col-xs-9 right-content">
                    <span className="content-file-info">
                      <span className="name-file">
                        {!data.fileNoiDung.migrated && (
                          <a
                            style={{
                              backgroundImage: `url(${Tool.Utils.typeFile(
                                extension
                              )})`,
                            }}
                            className="link-file"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${Types.FILE_URL}/api/file/get/${data.fileNoiDung.fileId}?BearerToken=${token}`}
                          >
                            {data.fileNoiDung.tenFile} -{' '}
                            {data.fileNoiDung.kichThuocStr}
                          </a>
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ThongTinTaiLieu.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ThongTinTaiLieu
