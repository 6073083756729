import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactQuill from 'react-quill'

const UIEditor = ({
  name,
  label,
  placeholder,
  theme,
  hasRequire,
  readOnly,
  error,
  value,
  onChange,
}) => {
  return (
    <div
      className={classNames('ui-editor', {
        [`theme-${theme}`]: theme,
      })}
    >
      {label ? (
        <label
          className={classNames('ui-editor-label', {
            required: hasRequire,
          })}
        >
          <span>{label}</span>
        </label>
      ) : (
        ''
      )}
      <div className="ui-editor-content" id="quill-container">
        <ReactQuill
          key={1}
          value={value || ''}
          onChange={onChange}
          modules={{
            toolbar: [
              [{ header: 1 }, { header: 2 }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              ['link', 'video'],
              [{ color: [] }, { background: [] }],
              ['clean'],
            ],
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            'header',
            'size',
            'bold',
            'italic',
            'underline',
            'link',
            'image',
            'color',
            'background',
            'video',
          ]}
          placeholder={placeholder}
          theme="snow"
          bounds={'#quill-container'}
          readOnly={readOnly}
        />
      </div>
      {error ? <p className="ui-editor-error">{error}</p> : ''}
    </div>
  )
}

UIEditor.defaultProps = {
  name: '',
  label: '',
  placeholder: 'Nhập nội dung...',
  theme: 'default',
  error: '',
  hasRequire: false,
  readOnly: false,
  value: '',
  onChange: () => {},
}

UIEditor.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.string,
  error: PropTypes.string,
  hasRequire: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes?.string,
  onChange: PropTypes.func,
}

export default UIEditor
