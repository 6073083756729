import React from 'react'
import { MasterLayout } from '../../components/layout'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent, Checkbox } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const PATH = '/ky-so-mo-rong/du-an/chinh-sua/:id'
const PUSH = '/ky-so-mo-rong/du-an/danh-sach'
const THONG_TIN_DU_AN = 'Thông tin quy trình'

class DetailProjectCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      boxMenuFixTop: false,
      projectInfo: {
        maDuAn: '',
        tenDuAn: '',
        suDung: true,
        donVi: '',
      },
      memberList: [],
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    const DUAN_ID = this.props.match.params.id
    this.props.actions.getProjectInfo(DUAN_ID).then(res => {
      if (res.payload && res.payload.data && res.payload.data.result) {
        let { projectInfo } = this.state
        let { result } = res.payload.data
        projectInfo.maDuAn = result.maDuAn
        projectInfo.tenDuAn = result.tenDuAn
        projectInfo.suDung = result.isActive
        projectInfo.donVi = result.tenVietTat
        this.setState({
          projectInfo,
          memberList: result.dsThanhVien,
        })
      }
    })
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.history.push(PUSH)
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    if (this.props.match.path === PATH) {
      return this.props.history.goBack()
    }

    this.props.history.push(PUSH)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  goBack() {
    this.props.history.goBack()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const DUAN_ID = this.props.match.params.id
    let { projectInfo } = this.state
    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="detail-receive-document">
          <div className="dispatch-code">
            <button
              onClick={this.goBack.bind(this)}
              type="button"
              className={classnames(
                'pt-button pt-button-back button-back-fixed mt5',
                {
                  'fixed-button-back':
                    this.state.boxMenuFixTop &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  'fixed-button-hasSidebar':
                    this.state.boxMenuFixTop &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                }
              )}
            >
              <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
              <span className="text-content">Quay lại</span>
            </button>
            <span className="number-code mt5">
              <span
                style={{ color: '#195FFF', lineHeight: '43px' }}
                className="name"
              >
                {THONG_TIN_DU_AN}
              </span>
            </span>
            <Link
              to={`/ky-so-mo-rong/du-an/chinh-sua/${DUAN_ID}`}
              className="btn-action pull-right hidden-btn"
            >
              <span className="name-btn">CHỈNH SỬA</span>
              <span className="pt-icon pt-icon-annotation"></span>
            </Link>
          </div>
        </div>
        <div className="systerm-main-content">
          <div className="form-container form-inline-container systerm-form-container">
            <div className="main-form systerm-form p30">
              <div className="form-block">
                <div className="form-subblock has-uline">
                  <div className="form-row">
                    <div className="form-cols form-col5 pr0 pl0">
                      <div className="form-group-item pt-form-group pt-intent-danger align-item-center">
                        <label className="pt-label pt-label-inline">
                          Mã quy trình:{' '}
                        </label>
                        <div className="input-form-inline pt-form-content">
                          <p className="font-size-14">{projectInfo.maDuAn}</p>
                        </div>
                      </div>
                    </div>
                    <div className="form-cols form-col5 pr0 pl0">
                      <div className="form-group-item pt-form-group pt-intent-danger align-item-center">
                        <label className="pt-label pt-label-inline">
                          Tên quy trình:{' '}
                        </label>
                        <div className="input-form-inline pt-form-content">
                          <p className="font-size-14">{projectInfo.tenDuAn}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-cols form-col5 pr0 pl0">
                      <div className="form-group-item pt-form-group pt-intent-danger align-item-center">
                        <label className="pt-label pt-label-inline">
                          Sử dụng:{' '}
                        </label>
                        <div className="input-form-inline pt-form-content">
                          <Checkbox
                            name="suDung"
                            className="system-checkbox mt0"
                            checked={projectInfo.suDung}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-cols form-col5 pr0 pl0">
                      <div className="form-group-item pt-form-group pt-intent-danger align-item-center">
                        <label className="pt-label pt-label-inline">
                          Đơn vị:{' '}
                        </label>
                        <div className="input-form-inline pt-form-content">
                          <p className="font-size-14">{projectInfo.donVi}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-subblock">
                  <div className="form-row">
                    <div className="form-cols form-col5 pr0 pl0">
                      <div className="form-group-item pt-form-group pt-intent-danger align-item-center">
                        <label className="pt-label pt-label-inline">
                          THÀNH VIÊN
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="member-list-box p15">
                <p>Danh sách</p>
                {this.state.memberList &&
                  this.state.memberList.length !== 0 &&
                  this.state.memberList.map((item, index) => (
                    <div key={index} className="item">
                      <p className="name">{item.tenNhanVien}</p>
                      <p className="unit">
                        {item.maPhongBan} - {item.tenVietTat}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailProjectCategoryPage)
