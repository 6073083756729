import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link, Redirect } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import moment from 'moment'
import { Authorization, Permission } from '../auth'
import * as POPUP from '../../constants/Popup'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClickShowDepartment = this.handleClickShowDepartment.bind(this)
  }

  state = {
    isRedirect: false,
    isOpenPopup: false,
    dsPhongBanNhan: [],
    isShowCollapse: false,
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = (lyDo, e) => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    this.props.onHandleSubmitRemoveItem(this.props.item.congVanNoiBoId, lyDo)
  }

  handleClickShowDepartment = () => {
    this.setState({ isShowCollapse: !this.state.isShowCollapse })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item) {
      const dsPhongBanNhan = []
      nextProps.item.phongBanNhan &&
        nextProps.item.phongBanNhan.forEach(item => {
          dsPhongBanNhan.push(
            `${item.maPhongBan}${
              this.props.listTenDonVi &&
              this.props.listTenDonVi[item.phongBanId]
                ? ` - ${this.props.listTenDonVi[item.phongBanId]}`
                : ''
            }`
          )
        })
      nextProps.item.phongBanCapTren &&
        nextProps.item.phongBanCapTren.forEach(item => {
          dsPhongBanNhan.push(
            `${item.maPhongBan}${
              this.props.listTenDonVi &&
              this.props.listTenDonVi[item.phongBanId]
                ? ` - ${this.props.listTenDonVi[item.phongBanId]}`
                : ''
            }`
          )
        })
      nextProps.item.phongBanDonViKhacNotIncludeDonVi &&
        nextProps.item.phongBanDonViKhacNotIncludeDonVi.forEach(item => {
          dsPhongBanNhan.push(
            `${item.maPhongBan}${
              this.props.listTenDonVi &&
              this.props.listTenDonVi[item.phongBanId]
                ? ` - ${this.props.listTenDonVi[item.phongBanId]}`
                : ''
            }`
          )
        })
      this.setState({ dsPhongBanNhan })
    }
  }

  componentDidMount() {
    if (this.props.item) {
      const dsPhongBanNhan = []
      this.props.item.phongBanNhan &&
        this.props.item.phongBanNhan.forEach(item => {
          dsPhongBanNhan.push(
            `${item.maPhongBan}${
              this.props.listTenDonVi &&
              this.props.listTenDonVi[item.phongBanId]
                ? ` - ${this.props.listTenDonVi[item.phongBanId]}`
                : ''
            }`
          )
        })
      this.props.item.phongBanCapTren &&
        this.props.item.phongBanCapTren.forEach(item => {
          dsPhongBanNhan.push(
            `${item.maPhongBan}${
              this.props.listTenDonVi &&
              this.props.listTenDonVi[item.phongBanId]
                ? ` - ${this.props.listTenDonVi[item.phongBanId]}`
                : ''
            }`
          )
        })
      this.props.item.phongBanDonViKhacNotIncludeDonVi &&
        this.props.item.phongBanDonViKhacNotIncludeDonVi.forEach(item => {
          dsPhongBanNhan.push(
            `${item.maPhongBan}${
              this.props.listTenDonVi &&
              this.props.listTenDonVi[item.phongBanId]
                ? ` - ${this.props.listTenDonVi[item.phongBanId]}`
                : ''
            }`
          )
        })
      this.setState({ dsPhongBanNhan })
    }
  }

  componentWillUnmount() {
    this.setState({
      isRedirect: false,
      isOpenPopup: false,
      dsPhongBanNhan: [],
      isShowCollapse: false,
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/cong-van/cong-van-noi-bo/chi-tiet/${this.props.item.congVanNoiBoId}`}
        />
      )
    }

    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>
          {moment(this.props.item.ngayGui).format('DD/MM/YYYY HH:mm')}
        </td>
        <td onClick={this.handleClick}>
          {moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>{this.props.item.soCongVan}</td>
        <td onClick={this.handleClick} className="textarea-content">
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClickShowDepartment}>
          {this.props.item.phongBanLap.maPhongBan}
        </td>
        <td onClick={this.handleClickShowDepartment}>
          {this.state.isShowCollapse && (
            <div className="department-collapse-box">
              {this.state.dsPhongBanNhan.map((content, i) => (
                <p className="department-collapse-item" key={i}>
                  {content}
                </p>
              ))}
            </div>
          )}
          {!this.state.isShowCollapse && (
            <div className="department-collapse-box">
              <span className="department-collapse-item">
                {this.state.dsPhongBanNhan[0]}
              </span>
              {this.state.dsPhongBanNhan.length > 1 && (
                <span className="count-department">
                  (+{this.state.dsPhongBanNhan.length - 1})
                </span>
              )}
            </div>
          )}
        </td>
        <Authorization.Element permission={[Permission.CONGVANNOIBO_QUANLY]}>
          {!(this.props.internalDocumentType === 'cvNoiBoDen') ? (
            <td>
              <Popover
                popoverClassName="pt-popover-content-sizing pt-minimal"
                position={Position.BOTTOM_RIGHT}
                interactionKind={PopoverInteractionKind.HOVER}
                openOnTargetFocus={true}
                content={
                  <div className="menu-popover">
                    <Link
                      className="item"
                      to={`/cong-van/cong-van-noi-bo/chinh-sua/${this.props.item.congVanNoiBoId}`}
                    >
                      <span className="pt-icon pt-icon-annotation"></span>
                      <span className="name_action">Chỉnh sửa</span>
                    </Link>
                    <span className="item" onClick={this.handleClickPopup}>
                      <span className="pt-icon pt-icon-trash"></span>
                      <span className="name_action">Thu hồi</span>
                    </span>
                  </div>
                }
              >
                <span className="icon-More"></span>
              </Popover>
              <PopupConfirm
                isOpen={this.state.isOpenPopup}
                onClose={this.handleClickPopup}
                title={POPUP.XOA_CONG_VAN_NOI_BO}
                text={POPUP.TEXT_BUTTON_SUBMIT}
                onSubmit={this.handleSubmitRemoveItem}
                showTextWarning={false}
                textarea
                textPlaceholder={'Nhập nội dung lý do thu hồi...'}
              >
                {POPUP.TEXT_SUBMIT_BODY}
              </PopupConfirm>
            </td>
          ) : (
            <td></td>
          )}
        </Authorization.Element>
      </tr>
    )
  }
}

export default ListItem
