import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../../components/layout'
import { ListFilter, ListItem } from '../../../components/congvandenthuhoi'
import { GridViewFilter, HeadingPage } from '../../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../../constants/Table'
import { Button } from '@blueprintjs/core'
import * as Actions from '../../../actions'
import * as Types from '../../../constants/Api'
import * as Tool from '../../../helpers'
import moment from 'moment'
import { Intent } from '@blueprintjs/core'
import FileDownload from 'file-saver'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false
class CongVanDenThuHoiDanhDach extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      isLoading: false,
      isLoadingButton: false,
      isDisableButton: false,
      isEmpty: false,
      page: 1,
      isError: false,
      isShow: false,
      congVanDenThuHoi: {
        list: {},
      },
    }
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    let state = this.state
    let preData = Tool.Utils.preData('CongVanDenThuHoiDanhDach')
    if (preData) {
      state.filter = preData.filter
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        soDen:
          preData.filterColumn && preData.filterColumn.soDen
            ? preData.filterColumn.soDen
            : '',
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : undefined,
        soCongVan:
          preData.filterColumn && preData.filterColumn.soCongVan
            ? preData.filterColumn.soCongVan
            : '',
        trichYeu:
          preData.filterColumn && preData.filterColumn.trichYeu
            ? preData.filterColumn.trichYeu
            : '',
        coQuanBanHanhId:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId
            ? preData.filterColumn.coQuanBanHanhId
            : '',
        coQuanBanHanh:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId === '#'
            ? preData.filterColumn.coQuanBanHanh
            : null,
      }
    } else {
      state.filter = {
        ngayCongVan: {
          from: undefined,
          to: undefined,
        },
      }
    }
    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props &&
      this.props.list &&
      this.props.list.total &&
      this.state.page >
        Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT)
    ) {
      this.setState(
        { page: Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) },
        () => {
          this.clickPagination(this.state.page)
        }
      )
    }
  }

  clickPagination(page = 1) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : undefined,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      phongBanLapId:
        this.state.filterColumn.phongBanLapId &&
        this.state.filterColumn.phongBanLapId !== '#'
          ? this.state.filterColumn.phongBanLapId
          : null,
      nguoiKyId:
        this.state.filterColumn.nguoiKyId &&
        this.state.filterColumn.nguoiKyId !== '#'
          ? this.state.filterColumn.nguoiKyId
          : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? this.state.filterColumn.coQuanBanHanh
          : null,
    }
    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('CongVanDenThuHoiDanhDach', {
        filter,
        page,
        sort,
        filterColumn,
      })
      // tạm thời để refresh = false
      this.props.actions
        .getListCongVanDenBiThuHoi(
          { filter },
          page,
          sort,
          REFRESH_FALSE,
          filterColumn
        )
        .then(res => {
          let isEmpty = false
          let isError = false
          let congVanDenThuHoi = {}
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }
          if (res.error || (res && res.payload && res.payload.status !== 200)) {
            isError = true
          } else {
            congVanDenThuHoi = {
              list: {
                page: res.payload.data.result.page,
                total: res.payload.data.result.total,
                items: res.payload.data.result.items,
              },
            }
          }

          this.setState({
            congVanDenThuHoi,
            isEmpty,
            isError,
          })
        })
        .then(() => {
          let object = false
          if (
            Object.values(filterColumn).find(item => item !== null) !==
            undefined
          ) {
            object = true
          }
          this.setState({ isLoading: false, isShow: object })
          this.props.actions.refreshRevokeDocument(false)
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  onChangeGridView = e => {
    const value = e.target.value
    const name = e.target.name
    if (name === 'soCongVan' || name === 'trichYeu') {
      return this.setState({
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    }
    if (name !== 'soCongVan' || name !== 'trichYeu') {
      this.setState(
        {
          isShow: true,
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (
            name === 'phongBanLapId' ||
            name === 'nguoiKyId' ||
            name === 'coQuanBanHanhId'
          ) {
            return this.submitFilter()
          }
        }
      )
    }
  }

  onChangeDateGridView = (name, e) => {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
        filter: {},
      })
    }
    await this.submitFilter(filter)
  }

  handleSubmitUpdateItem = () => {}

  onRemoveText(name) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter(this.state.filter, this.state.page, this.state.sort)
      }
    )
  }

  handleExport = () => {
    const { filter, page, sort } = this.state
    try {
      this.setState({ exporting: true })
      const filterColumn = {
        ...this.state.filterColumn,
        ngayCongVan: this.state.filterColumn.ngayCongVan
          ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
          : undefined,
        soCongVan:
          this.state.filterColumn.soCongVan &&
          this.state.filterColumn.soCongVan !== ''
            ? this.state.filterColumn.soCongVan
            : null,
        trichYeu:
          this.state.filterColumn.trichYeu &&
          this.state.filterColumn.trichYeu !== ''
            ? this.state.filterColumn.trichYeu
            : null,
        phongBanLapId:
          this.state.filterColumn.phongBanLapId &&
          this.state.filterColumn.phongBanLapId !== '#'
            ? this.state.filterColumn.phongBanLapId
            : null,
        nguoiKyId:
          this.state.filterColumn.nguoiKyId &&
          this.state.filterColumn.nguoiKyId !== '#'
            ? this.state.filterColumn.nguoiKyId
            : null,
      }
      this.props.actions
        .congVanDenThuHoiXuatExcel({ filter }, page, sort, filterColumn)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }

          FileDownload.saveAs(res.payload.data, 'cong-van-den-bi-thu-hoi.xls')
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    } catch (error) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Không xuất được file.',
        intent: Intent.DANGER,
      })
    }
  }

  render() {
    let { isLoadingButton, isDisableButton } = this.state
    return (
      <MasterLayout typeSidebar="documentary">
        <HeadingPage namePage="Công văn đến bị thu hồi" iconPage="icon-revoke">
          <div className="button-heading-right pull-right">
            <Button
              rightIconName="pt-icon icon-In_So_Cong_Van"
              className="btn-action btn-top-heading color-white btn-min-width-130px"
              text="Xuất excel"
              onClick={this.handleExport}
              loading={isLoadingButton}
              disabled={isDisableButton}
            />
          </div>
        </HeadingPage>
        <div>
          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />
            <div
              className="table-container table-container-full"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                coQuanBanHanhId={this.props.relativeUnits}
                page={this.state.page}
                headers={headers.CongVanDenThuHoiDanhDach}
                itemShape={ListItem}
                data={this.props.list.items}
                onHandleSubmitUpdateItem={this.handleSubmitUpdateItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isError={this.state.isError}
                readOnly={this.props.readOnly}
                onChange={this.onChangeGridView}
                onChangeSelect={this.onChangeSelectGridViewFilter}
                onChangeDate={this.onChangeDateGridView}
                filterColumn={this.state.filterColumn}
                onRemoveText={this.onRemoveText}
                auth={this.props.auth}
                onSubmit={this.submitFilter}
                pageCount={Math.ceil(
                  this.props.list.total / Types.PAGINATION_LIMIT
                )}
                isType={false}
                isShow={this.state.isShow}
              />
              {!this.state.isEmpty &&
                !this.state.isError &&
                !this.state.isLoading && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props?.list?.total / Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

CongVanDenThuHoiDanhDach.propTypes = {
  list: PropTypes.object,
}

const mapStateToProps = state => ({
  auth: state.auth,
  relativeUnits: state.common.relativeUnits,
  config: state.common.config,
  list: state.congVanDenThuHoi.list,
  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CongVanDenThuHoiDanhDach)
