import React, { useRef, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import {
  Popover,
  Tooltip,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import { checkScroll, isValidCondition } from 'helpers/Helper'
import { Permission } from 'components/auth'
import {
  fetchNotificationCounter,
  getNotificationList,
  postNotificationRead,
  postNotificationReadAll,
} from 'actions/notifications'
import NotificationItem from './notification-item'
import useNotification from '../hooks/use-notification'

const Notifications = ({ history, actions, auth, counter }) => {
  const scrollRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isFetch, listData, isLast, getList, onItemClick, onReadAll } =
    useNotification({
      actions,
    })

  const onScrollFrame = useCallback(
    scroll => {
      if (isFetch || isLast) {
        return
      }

      if (checkScroll(scroll)) {
        getList(listData?.page + 1)
      }
    },
    [isFetch, listData, isLast, getList]
  )

  return (
    <div
      className={classNames('right-header-group', {
        active: isOpen,
      })}
    >
      <Popover
        popoverClassName="pt-minimal layout-notifications-popover"
        position={Position.BOTTOM_RIGHT}
        interactionKind={PopoverInteractionKind.CLICK}
        openOnTargetFocus={true}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={
          <div className="layout-notifications-layout">
            <div className="layout-notifications-layout-header d-flex align-items-center justify-content-space-between border-bottom ph15 pv20 gap-20">
              {isValidCondition(auth.permission, [
                Permission.HETHONG,
                Permission.THONGBAO_QUANLY,
              ]) ? (
                <div
                  className="d-flex align-items-center gap-5 cursor-pointer"
                  onClick={() => history.push('/he-thong/thong-bao')}
                >
                  <span
                    className="icon2-megaphone color-white"
                    style={{ fontSize: '21px', marginBottom: '1px' }}
                  ></span>
                  <span className="text-15-700-18 color-white">
                    Quản lý thông báo
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-5">
                  <span
                    className="icon2-megaphone color-white"
                    style={{ fontSize: '21px', marginBottom: '1px' }}
                  ></span>
                  <span className="text-15-700-18 color-white">Thông báo</span>
                </div>
              )}
              <Tooltip
                position={Position.TOP}
                content={<span className="font-size-12">Xem tất cả</span>}
              >
                <span
                  className="icon2-check-dup font-size-10 color-white cursor-pointer"
                  onClick={onReadAll}
                />
              </Tooltip>
            </div>
            <Scrollbars
              ref={scrollRef}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={490}
              onScrollFrame={onScrollFrame}
            >
              <div className="layout-notifications-content">
                {listData?.items &&
                  listData.items?.map((item, index) => (
                    <NotificationItem
                      key={index}
                      auth={auth}
                      dataSource={item}
                      onClick={onItemClick}
                    />
                  ))}
              </div>
            </Scrollbars>
          </div>
        }
      >
        <div onClick={() => setIsOpen(true)} className="notificaion-menu">
          <span
            className={classNames('icon2-megaphone layout-notifications-icon')}
          />
          <span className="notifications-title-menu">Thông báo</span>
          {!!counter && (
            <span className="notificaion-count">
              {counter >= 100 ? `99+` : counter}
            </span>
          )}
        </div>
      </Popover>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchNotificationCounter,
      getNotificationList,
      postNotificationRead,
      postNotificationReadAll,
    },
    dispatch
  ),
})

const mapStateToProps = state => ({
  auth: state.auth,
  counter: state.notifications.counter,
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
