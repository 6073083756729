import React, { memo, useRef, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { deleteFavoriteGroup } from 'actions/task'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import {
  DELETE_FAVORITE_GROUP_TITLE,
  DELETE_FAVORITE_GROUP_CONTENT,
  TEXT_BUTTON_SUBMIT,
} from 'constants/Popup'

const DeleteFavorite = ({
  match,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const { id } = match.params
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const deleteTask = () => {
    requestAction({
      beforeAction: () => _mountedSet(setLoading, true),
      action: () => deleteFavoriteGroup(id),
      afterResponse: () => {
        _mountedSet(setShowDialog, false)
        reloadList()
        onClose()
      },
      afterAction: () => _mountedSet(setLoading, false),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      // confirmDanger
      confirmText={TEXT_BUTTON_SUBMIT}
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteTask}
      onClose={onClose}
      title={DELETE_FAVORITE_GROUP_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{DELETE_FAVORITE_GROUP_CONTENT}</Card>
    </Dialog>
  )
}

export default memo(withRouter(DeleteFavorite))
