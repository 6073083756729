import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import { LoadingItem, NotFound } from '../../components/common/'

class ListReceivedDepartments extends Component {
  constructor(props) {
    super(props)
    this.handleClickArrow = this.handleClickArrow.bind(this)
    this.state = {
      isOpen: false,
      isLoading: false,
      data: [],
    }
  }

  static propTypes = {
    signNumber: PropTypes.object.isRequired,
  }

  handleClickArrow = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      let data = []
      const mainUnitShortName =
        this.props.signNumber &&
        this.props.signNumber.donVi &&
        this.props.signNumber.donVi.tenVietTat
      let departmentsName = []
      // phong ban chuyen den
      if (this.props.signNumber && this.props.signNumber.chuyenDen) {
        this.props.signNumber.chuyenDen.forEach(department => {
          if (department.maPhongBan) {
            departmentsName.push(department.maPhongBan.trim())
          }
        })
        data.push({
          unit: mainUnitShortName,
          department: departmentsName.join(', '),
        })
      }
      // phong ban cap tren chuyen den
      if (this.props.signNumber && this.props.signNumber.chuyenDenCapTren) {
        this.props.signNumber.chuyenDenCapTren.forEach(unit => {
          let departmentsName = []
          if (unit && unit.dsPhongBan) {
            unit.dsPhongBan.forEach(department => {
              if (department.maPhongBan) {
                departmentsName.push(department.maPhongBan.trim())
              }
            })
            data.push({
              unit: unit.tenVietTat,
              department: departmentsName.join(', '),
            })
          }
        })
      }
      // phong ban don vi chuyen den
      if (
        this.props.signNumber &&
        this.props.signNumber.chuyenDenPhongBanDonViKhac
      ) {
        this.props.signNumber.chuyenDenPhongBanDonViKhac.forEach(unit => {
          let departmentsName = []
          if (unit && unit.dsPhongBan) {
            unit.dsPhongBan.forEach(department => {
              if (department.maPhongBan) {
                departmentsName.push(department.maPhongBan.trim())
              }
            })
            data.push({
              unit: unit.tenVietTat,
              department: departmentsName.join(', '),
            })
          }
        })
      }
      this.setState({ data }, () => {
        this.setState({ isLoading: false })
      })
    })
  }

  render() {
    return (
      <div className="history-steering-box history-edit-document">
        <div className="heading">
          <h3 className="name-heading">
            <span className="icon-listing"></span>
            <span onClick={this.handleClickArrow} className="text-content">
              Phòng ban chuyển đến
            </span>
            <span
              onClick={this.handleClickArrow}
              className={classnames('name_group_unit', {
                'pt-icon pt-icon-chevron-up': this.state.isOpen,
                'pt-icon pt-icon-chevron-down': !this.state.isOpen,
              })}
            ></span>
          </h3>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className="list-history-document">
            {this.state.isLoading && <LoadingItem />}
            {!this.state.isLoading && (
              <div className="detail-status-box">
                {(!this.state.data || this.state.data.length === 0) && (
                  <NotFound />
                )}
                {this.state.data && this.state.data.length > 0 && (
                  <div className="table-status table-scroll table-scroll-hidden table-departments">
                    <div className="heading-table">
                      <div className="cell-table colum-stt">
                        <p className="name-heading-table">STT</p>
                      </div>
                      <div className="cell-table column-department">
                        <p className="name-heading-table">Đơn vị</p>
                      </div>
                      <div className="cell-table">
                        <p className="name-heading-table">Phòng ban</p>
                      </div>
                    </div>
                    {this.state.data.map((item, i) => {
                      return (
                        <div className="row-table" key={i}>
                          <div className="cell-table">
                            <p className="content">
                              {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                            </p>
                          </div>
                          <div className="cell-table">
                            <p className="content">{item.unit}</p>
                          </div>
                          <div className="cell-table">
                            <p className="content">{item.department}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default ListReceivedDepartments
