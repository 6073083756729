// import { Permission } from '../../components/auth';
export const headers = {
  ListBanner: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Tiêu đề',
      sort: null,
      permission: [],
      typeGridView: null,
      nameGridView: 'title',
    },
    {
      classNames: 'column-10 center',
      style: [],
      value: 'Hiển thị',
      sort: null,
      permission: [],
      typeGridView: null,
      nameGridView: 'IsActive',
    },
    {
      classNames: 'column-3 center',
      style: [],
      value: '',
      sort: null,
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
  ],
  priority: [
    {
      classNames: 'column-3',
      value: 'Stt',
    },
    {
      classNames: 'column-13',
      value: 'Tên',
    },
    {
      classNames: 'column-10 center',
      value: 'Sử dụng',
    },
  ],
}
