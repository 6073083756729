import { schema } from 'normalizr'

const ExecutingView = new schema.Entity(
  'executingViews',
  {},
  {
    idAttribute: executingViews => executingViews.chiDaoId,
  }
)

export default ExecutingView
