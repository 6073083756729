import * as types from '../constants/ActionTypes'

const initialState = {
  list: [],
}

export default function assignmentInformation(state = initialState, action) {
  switch (action.type) {
    case types.WORK_ASSIGNMENT_INFORMATION:
      return {
        ...state,
        list:
          action.payload.data.result &&
          action.payload.data.result.result &&
          action.payload.data.result.result.items
            ? action.payload.data.result.result.items
            : [],
      }
    default:
      return state
  }
}
