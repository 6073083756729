import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import { Tab2, Checkbox } from '@blueprintjs/core'
import { get, size, find, some, unionBy } from 'lodash'
import csx from 'classnames'

import { Card, Tabs, Empty, Scrollbar } from 'components/newCommon'
import DropdownWrapper from '../DropdownWrapper'
import RadioItem from './Radio/RadioItem'
import CheckboxItem from './Checkbox/CheckboxItem'

export const SelectContext = React.createContext({})

const tabKeys = {
  phongBan: 'PHONG_BAN',
  thuongGiao: 'NHOM_THUONG_GIAO_VIEC',
}

const UserTree = ({
  allowSelectAll = false,
  dataSource,
  favoriteDataSource,
  defaultLabel = '',
  disabledValue,
  emptyDropdownPlaceholder = false,
  multiple = false,
  mainOnly = false,
  onChange = () => {},
  onClear = () => {},
  onShowCollapse = () => {},
  value,
  showDefaultLabelOnNotSelected = false,
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFuntion, state) =>
    !!get(mounted, 'current', false) && setFuntion(state)

  const [selected, setSelected] = useState([])
  const [showKeys, setShowKeys] = useState([])
  const [dropdownOpen, _setDropdownOpen] = useState(false)
  const [disabledList, setDisabledList] = useState([])

  const [label, setLabel] = useState('')
  const [showList, setShowList] = useState([])

  const [selectTab, setSelectTab] = useState(tabKeys.phongBan)

  const source = {
    [tabKeys.phongBan]: dataSource,
    [tabKeys.thuongGiao]: favoriteDataSource,
  }[selectTab]

  const _flatData = useCallback((list = []) => {
    return list.reduce((result, cur) => {
      return [...result, cur, ..._flatData(cur.children || [])]
    }, [])
  }, [])

  const _getLabel = useCallback(() => {
    if (!size(selected)) {
      return _mountedSet(
        setLabel,
        showDefaultLabelOnNotSelected ? defaultLabel : ''
      )
    }

    const labelList = unionBy(_flatData(source), 'value.id')
      .filter(item => some(selected, item.value))
      .map(item => item.label)

    _mountedSet(
      setLabel,
      size(labelList)
        ? labelList.join(', ')
        : size(defaultLabel)
        ? defaultLabel
        : label
    )
  }, [
    _flatData,
    source,
    selected,
    defaultLabel,
    showDefaultLabelOnNotSelected,
    label,
  ])

  const _toggleDropdown = useCallback(open => {
    if (open) {
      _mountedSet(setShowKeys, [])
    }
    _mountedSet(_setDropdownOpen, open)
  }, [])

  const _onChange = (list, value) => {
    _mountedSet(setSelected, list)
    !multiple && _toggleDropdown(false)
    onChange(multiple ? list : list[0], value)
  }

  const _toggleShow = useCallback(
    value => e => {
      e.preventDefault()
      if (showKeys.includes(value)) {
        return _mountedSet(
          setShowKeys,
          showKeys.filter(key => key !== value)
        )
      }
      _mountedSet(setShowKeys, [...showKeys, value])

      if (selectTab === tabKeys.phongBan) {
        onShowCollapse(value)
      }
      onShowCollapse(value)
    },
    [showKeys, onShowCollapse, selectTab]
  )

  const updateSelectedOnCollapse = useCallback(() => {
    if (!multiple) return

    _mountedSet(setSelected, prevSelected =>
      prevSelected.reduce((result, select) => {
        const selectSource = find(dataSource, { value: select }) || {}
        const { children = [] } = selectSource
        return [
          ...result,
          ...(size(children) ? children.map(child => child.value) : [select]),
        ]
      }, [])
    )
  }, [dataSource, multiple])

  const toggleCheckAll = e => {
    const { checked } = e.target
    _onChange(checked ? source.map(i => i.value) : [])
  }

  const renderTree = () => {
    if (!size(source)) {
      return emptyDropdownPlaceholder && <Empty />
    }
    return (
      <>
        {multiple && allowSelectAll && selectTab === tabKeys.phongBan && (
          <Card className="cpc-list-item" paddingHorizontal>
            <Checkbox
              className={csx(
                'cpc-checkbox',
                'pt10 pb10',
                'font-size-13',
                'font-weight-500'
              )}
              indeterminate={
                dataSource
                  .map(data => data.value)
                  .some(data => some(selected, data)) &&
                !dataSource
                  .map(data => data.value)
                  .every(data => some(selected, data))
              }
              checked={dataSource
                .map(data => data.value)
                .every(data => some(selected, data))}
              onChange={toggleCheckAll}
            >
              Chọn tất cả
            </Checkbox>
          </Card>
        )}

        <Scrollbar maxHeight={300}>
          <div className="cpc-list">
            {showList.map(item => {
              return multiple ? (
                <CheckboxItem {...item} key={get(item, 'value.id')} />
              ) : (
                <RadioItem {...item} key={get(item, 'value.id')} />
              )
            })}
          </div>
        </Scrollbar>
      </>
    )
  }

  const changeTab = key => {
    _mountedSet(setShowKeys, [])
    _mountedSet(setSelectTab, key)
  }

  const tabs = [
    {
      id: tabKeys.phongBan,
      name: 'Phòng ban',
      content: renderTree(),
    },
    {
      id: tabKeys.thuongGiao,
      name: 'Nhóm thường giao việc',
      content: renderTree(),
    },
  ]

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(setShowList, source || [])
  }, [source])

  useEffect(() => {
    updateSelectedOnCollapse()
  }, [updateSelectedOnCollapse])

  useEffect(() => {
    const newSelected = multiple ? value || [] : value ? [value] : []
    _mountedSet(setSelected, newSelected)
  }, [value, multiple])

  useEffect(() => {
    _getLabel()
  }, [_getLabel])

  useEffect(() => {
    _mountedSet(setDisabledList, disabledValue || [])
  }, [disabledValue])

  useEffect(() => {
    if (dropdownOpen) {
      _mountedSet(setSelectTab, tabKeys.phongBan)
    }
  }, [dropdownOpen])

  return (
    <DropdownWrapper
      {...props}
      onClear={() => _onChange([])}
      onDropdownVisibleChange={_toggleDropdown}
      open={dropdownOpen}
      value={label}
    >
      <SelectContext.Provider
        value={{
          selected,
          onChange: _onChange,
          showKeys,
          toggleShow: _toggleShow,
          disabledList,
        }}
      >
        <Card className="popup no-border">
          {mainOnly ? (
            renderTree()
          ) : (
            <Tabs contentBordered scrollable onChange={changeTab}>
              {tabs.map(tab => (
                <Tab2
                  key={tab.id}
                  id={tab.id}
                  title={tab.name}
                  panel={tab.content}
                />
              ))}
            </Tabs>
          )}
        </Card>
      </SelectContext.Provider>
    </DropdownWrapper>
  )
}

export default memo(UserTree)
