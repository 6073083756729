import React, { memo } from 'react'
import csx from 'classnames'
import { get } from 'lodash'

const types = {
  NEW: {
    color: 'new',
    text: 'Chưa thực hiện',
  },
  DOING: {
    color: 'process',
    text: 'Đang thực hiện',
  },
  DONE: {
    color: 'done',
    text: 'Đã thực hiện',
  },
  APPROVED: {
    color: 'done',
    text: 'Đã duyệt',
  },
  DONE_SLOW: {
    color: 'red',
    text: 'Hoàn thành chậm',
  },
  WAITING_APPROVAL: {
    color: 'orange',
    text: 'Chờ duyệt',
  },
  DELIVERED: {
    color: 'blue',
    text: 'Đã chuyển',
  },
}

const StatusTag = ({ type = 'default', className, text = '' }) => {
  if (type === 'default') {
    return null
  }

  return (
    <span
      className={csx(
        'cpc-progress-label',
        get(types, `[${type}].color`),
        'uppercase',
        className
      )}
    >
      {text || get(types, `[${type}].text`)}
    </span>
  )
}

export default memo(StatusTag)
