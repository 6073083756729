import React, { memo, useCallback, useEffect, useState, useRef } from 'react'
import {
  Button,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import { get } from 'lodash'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

import {
  Card,
  DatePicker,
  Row,
  Col,
  ResetButtonOld,
  SearchInputOld,
  Table,
  Input,
} from 'components/newCommon'
import { getDsCongVan, getThuMucPath } from 'actions/tutailieu'
import requestAction from 'helpers/request'
import { Utils } from 'helpers'

const DEFAULT_FILTER = {
  page: 1,
  filter: {
    textSearch: undefined,
    ngayCongVan: {
      from: undefined,
      to: undefined,
    },
  },
  filterColumn: {
    soCongVan: undefined,
    ngayCongVan: undefined,
    tenTaiLieu: undefined,
    coQuanBanHanh: undefined,
    nguoiLuu: undefined,
  },
  sorter: undefined,
}

const DATE_FORMAT = 'DD/MM/YYYY'
const REQUEST_DATE_FORMAT = 'YYYY-MM-DD'

const Index = ({
  toggleShowDocumentSearch,
  moveToDetails,
  history,
  location,
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState([])

  const [totalPage, setTotalPage] = useState(1)
  const [filter, setFilter] = useState({})

  const onChangePage = page => {
    const newFilter = {
      ...filter,
      page,
    }
    setFilter(newFilter)
    _fetchDsCongVan(Utils.linkObjectKey(newFilter))
  }

  const changeFilter =
    (name, fetch = false) =>
    value => {
      const newFilter = {
        ...filter,
        filter: {
          ...get(filter, 'filter', {}),
          [name]: value,
        },
      }

      setFilter(newFilter)
      if (fetch) {
        _fetchDsCongVan(Utils.linkObjectKey(newFilter))
      }
    }

  const changeDate = name => value => {
    const newFilter = {
      ...filter,
      filter: {
        ...get(filter, 'filter', {}),
        ngayCongVan: {
          ...get(filter, 'filter.ngayCongVan', {}),
          [name]: value ? value.format(REQUEST_DATE_FORMAT) : undefined,
        },
      },
    }

    setFilter(newFilter)
    _fetchDsCongVan(Utils.linkObjectKey(newFilter))
  }

  const changeFilterColumn =
    (name, fetch = false) =>
    value => {
      const newFilter = {
        ...filter,
        filterColumn: {
          ...filter.filterColumn,
          [name]: value,
        },
      }

      setFilter(newFilter)
      if (fetch) {
        _fetchDsCongVan(Utils.linkObjectKey(newFilter))
      }
    }

  const _fetchDsCongVan = useCallback((params = {}) => {
    requestAction({
      codeCheck: false,
      showToast: false,
      beforeAction: () => setFetching(true),
      action: () => getDsCongVan({ ...params }),
      afterResponse: ({ items = [], total }) => {
        _mountedSet(setData, items)
        _mountedSet(setTotalPage, Math.ceil(total / 10))
      },
      afterAction: () => setFetching(false),
    })
  }, [])

  const _getThuMucPath = useCallback(id => {
    requestAction({
      codeCheck: false,
      showToastSucess: false,
      action: () => getThuMucPath(id),
      errorMessage: 'Không lấy được đường dẫn.',
      afterResponse: result => {
        if (result) {
          _mountedSet(setData, prev =>
            prev.map(item =>
              item.hoSoCongViecId === id
                ? {
                    ...item,
                    path: result,
                  }
                : item
            )
          )
        } else {
          throw new Error()
        }
      },
    })
  }, [])

  const _moveToFolder = id => {
    history.push({
      pathname: `/ho-so-cong-viec/chi-tiet/phong-ban/${id}`,
      state: {
        prevPath: location.pathname,
        store: {
          key: 'List-CabinetDocuments-showDocumentSearch',
          value: true,
        },
      },
    })
  }

  const onSort = value => {
    const newFilter = {
      ...filter,
      sorter: value,
    }

    setFilter(newFilter)
    _fetchDsCongVan(Utils.linkObjectKey(newFilter))
  }

  const _fetchDsCongVanWithCurrentFilter = () =>
    _fetchDsCongVan(Utils.linkObjectKey(filter))

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    const prevFilter =
      Utils.getData('TTL-HSCV-TimKiemCongVan') || DEFAULT_FILTER
    setFilter(prevFilter)
    _fetchDsCongVan(Utils.linkObjectKey(prevFilter))
  }, [_fetchDsCongVan])

  useEffect(() => {
    Utils.saveData('TTL-HSCV-TimKiemCongVan', filter)
  }, [filter])

  const columns = [
    {
      key: 'soCongVan',
      className: 'column-5',
      title: 'Số công văn',
      sortKey: 'soCongVan',
      dataIndex: 'soCongVan',
      filter: (
        <Input
          clearable
          placeholder="Nhập"
          onChange={changeFilterColumn('soCongVan')}
          onSend={_fetchDsCongVanWithCurrentFilter}
          onClear={changeFilterColumn('soCongVan', true)}
          value={get(filter, 'filterColumn.soCongVan')}
        />
      ),
    },
    {
      key: 'ngayCongVan',
      className: 'column-5',
      title: 'Ngày công văn',
      sortKey: 'ngayCongVan',
      dataIndex: 'ngayCongVan',
      filter: (
        <DatePicker
          onChange={value => {
            const newValue = value
              ? value.format(REQUEST_DATE_FORMAT)
              : undefined
            changeFilterColumn('ngayCongVan', true)(newValue || undefined)
          }}
          selected={
            get(filter, 'filterColumn.ngayCongVan')
              ? moment(get(filter, 'filterColumn.ngayCongVan'))
              : undefined
          }
        />
      ),
      render: value => (value ? moment(value).format(DATE_FORMAT) : ''),
    },
    {
      key: 'tenTaiLieu',
      width: 25,
      className: 'column-30',
      title: 'Tên tài liệu/Trích yếu',
      dataIndex: 'tenTaiLieu',
      filter: (
        <Input
          clearable
          placeholder="Nhập"
          onChange={changeFilterColumn('tenTaiLieu')}
          onSend={_fetchDsCongVanWithCurrentFilter}
          onClear={changeFilterColumn('tenTaiLieu', true)}
          value={get(filter, 'filterColumn.tenTaiLieu')}
        />
      ),
    },
    {
      key: 'coQuanBanHanh',
      className: 'column-5',
      title: 'Cơ quan ban hành',
      sortKey: 'coQuanBanHanh',
      dataIndex: 'coQuanBanHanh',
      filter: (
        <Input
          clearable
          placeholder="Nhập"
          onChange={changeFilterColumn('coQuanBanHanh')}
          onSend={_fetchDsCongVanWithCurrentFilter}
          onClear={changeFilterColumn('coQuanBanHanh', true)}
          value={get(filter, 'filterColumn.coQuanBanHanh')}
        />
      ),
    },
    {
      key: 'nguoiLuu',
      className: 'column-5',
      title: 'Người lưu',
      sortKey: 'tenNhanVien',
      dataIndex: 'nguoiLuu.tenNhanVien',
      filter: (
        <Input
          clearable
          placeholder="Nhập"
          onChange={changeFilterColumn('nguoiLuu')}
          onSend={_fetchDsCongVanWithCurrentFilter}
          onClear={changeFilterColumn('nguoiLuu', true)}
          value={get(filter, 'filterColumn.nguoiLuu')}
        />
      ),
    },
    {
      key: 'folder',
      className: 'column-3 center',
      render: ({ hoSoCongViecId, path }) => (
        <span onClick={e => e.stopPropagation()}>
          <Popover
            position={Position.LEFT}
            interactionKind={PopoverInteractionKind.CLICK}
            content={
              <div className="menu-popover">
                {path && (
                  <div
                    className="item"
                    onClick={() => _moveToFolder(hoSoCongViecId)}
                  >
                    <span className="name_action">{path}</span>
                  </div>
                )}
              </div>
            }
            popoverDidOpen={() =>
              !path ? _getThuMucPath(hoSoCongViecId) : null
            }
          >
            <span className="icon-folder size-icon-14 align-flex-start" />
          </Popover>
        </span>
      ),
    },
  ]

  const startDate = get(filter, 'filter.ngayCongVan.from')
    ? moment(get(filter, 'filter.ngayCongVan.from'))
    : undefined
  const endDate = get(filter, 'filter.ngayCongVan.to')
    ? moment(get(filter, 'filter.ngayCongVan.to'))
    : undefined
  const datePickerProps = {
    rounded: false,
    wrapperClassName: 'flex-auto',
    startDate,
    endDate,
  }

  return (
    <div>
      <Card padding>
        <Button
          iconName="pt-icon-chevron-left"
          text="QUAY LẠI"
          className="mr20 cpc-button default-back"
          onClick={toggleShowDocumentSearch}
        />
        <b>Tìm kiếm công văn đã lưu</b>
      </Card>
      <Card padding borderTop>
        <Row gutter>
          <Col grid="oneThird">
            <label className="search-label mb5">
              Thời gian (Ngày công văn)
            </label>
            <div className="d-flex">
              <DatePicker
                {...datePickerProps}
                className="border-radius-lelf-3"
                placeholderText="Từ ngày"
                onChange={changeDate('from')}
                selected={startDate}
                maxDate={endDate}
              />
              <DatePicker
                {...datePickerProps}
                className="border-radius-right-3"
                placeholderText="Đến ngày"
                onChange={changeDate('to')}
                selected={endDate}
                minDate={startDate}
              />
            </div>
          </Col>
          <Col grid="twoThirds" className="d-flex align-flex-end">
            <div className="flex-auto d-flex">
              <SearchInputOld
                placeholder="Tìm kiếm theo số công văn, tên tài liệu"
                onChange={changeFilter('textSearch')}
                onClear={changeFilter('textSearch', true)}
                onSearch={_fetchDsCongVanWithCurrentFilter}
                value={get(filter, 'filter.textSearch')}
                className="mr20"
              />
              <ResetButtonOld
                onClick={() => {
                  setFilter(DEFAULT_FILTER)
                  _fetchDsCongVan(Utils.linkObjectKey(DEFAULT_FILTER))
                }}
              />
            </div>
          </Col>
        </Row>
      </Card>

      <Table
        autoHide={false}
        loading={fetching}
        columns={columns}
        data={data}
        pagination={{
          current: get(filter, 'page', 1),
          total: totalPage,
        }}
        onChangePage={onChangePage}
        onRowClick={({ id }) => moveToDetails(id)}
        onSort={onSort}
        sortValue={get(filter, 'sorter')}
      />
    </div>
  )
}

export default withRouter(memo(Index))
