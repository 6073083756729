import * as types from '../constants/ActionTypes'

const initialState = {
  items: [],
  items_internalDocument: [],
  items_sendDocument: {},
  flowDocument_ReceiveDocument: {},
  flowDocuments: {},
  flowSendDocument: {},
}

export default function executingViews(state = initialState, action) {
  switch (action.type) {
    case types.EXECUTING_VIEW:
      return {
        ...state,
        items:
          action.payload.data.result &&
          action.payload.data.result.result &&
          action.payload.data.result.result.items
            ? action.payload.data.result.result.items
            : [],
      }

    case types.DOCUMENT_INTERNAL_EXECUTINGVIEW:
      return {
        ...state,
        items_internalDocument:
          action.payload.data.result &&
          action.payload.data.result.result &&
          action.payload.data.result.result.items
            ? action.payload.data.result.result.items
            : [],
      }

    case types.SEND_DOCUMENT_EXECUTING_VIEW:
      return {
        ...state,
        items_sendDocument: action.payload.data.result,
      }

    case types.FLOWDOCUMENTARY_RECEIVEDOCUMENT:
      return {
        ...state,
        flowDocument_ReceiveDocument: action.payload.data.result.result,
        flowDocuments: {
          ...state.flowDocuments,
          [action.payload.data.result.result.chiDao]:
            action.payload.data.result.result,
        },
      }
    case types.FLOWDOCUMENTARY_RECEIVEDOCUMENT_RESET:
      return initialState
    case types.FLOWDOCUMENTARY_MORE_RECEIVEDOCUMENT:
      return {
        ...state,
        flowDocuments: {
          ...state.flowDocuments,
          [action.payload.data.result.result.congVanDenId]:
            action.payload.data.result.result,
        },
      }
    case types.FLOWDOCUMENTARY_MORE_RECEIVEDOCUMENT_RESET:
      return initialState
    case types.FLOWDOCUMENTART_SENDDOCUMENT:
      return {
        ...state,
        flowSendDocument: action.payload.data.result,
      }

    case types.DOCUMENT_RECEIVE_DELETE_ASSIGNMENT_HISTORY:
    case types.DOCUMENT_RECEIVE_UPDATE_ASSIGNMENT_HISTORY:
    case types.DOCUMENT_RECEIVE_SEND_ASSIGNMENT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
