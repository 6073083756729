import React from 'react'
import { Button } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FileDownload from 'file-saver'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'

import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItem,
} from '../../components/Utilities/DocumentRecovery'
import { GridView } from '../../components/common'
import * as Actions from '../../actions'
import { headers } from '../../constants/Table'
import * as Tool from '../../helpers'
import HeadingPanel from 'components/common/HeadingPanel'

class ListDocumentRecovery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      page: 1,
      isEmpty: false,
      isLoading: false,
      isError: false,
      isShowMessengStatistics: true,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      `ListDocumentRecovery`,
      null,
      'thong-ke-thu-hoi-cong-van'
    )

    if (preData) {
      state.filter = preData.filter
    } else if (this.props.match && !this.props.match.params.id) {
      state.filter = {
        start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      if (this.props.match && this.props.match.params.id) {
        const filter = {
          start_date: this.state.filter.start_date,
          end_date: this.state.filter.end_date,
          don_vi_id: this.props.match.params.id,
        }
        this.setState({ filter }, () => {
          this.submitFilter(this.state.filter)
        })
      } else {
        let isLoadPage = Tool.Utils.getData('isLoadPage')
        if (isLoadPage) {
          const filter = {
            start_date: this.state.filter.start_date,
            end_date: this.state.filter.end_date,
          }
          this.setState({ filter }, () => {
            Tool.Utils.saveData('isLoadPage', false)
            this.submitFilter(this.state.filter)
          })
        } else {
          const filter = {
            start_date: this.state.filter.start_date,
            end_date: this.state.filter.end_date,
          }
          this.setState({ filter })
        }
      }
    })
  }

  submitFilter(e = {}) {
    const filter = Object.assign({}, this.state.filter, e)
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(
          `ListDocumentRecovery`,
          { filter },
          'thong-ke-thu-hoi-cong-van'
        )
        this.props.actions
          .getDocumentRecovery(filter, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(true)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleExport() {
    const { filter } = this.state

    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportDocumentRecovery(filter)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }

          FileDownload.saveAs(res.payload.data, 'thu-hoi-cong-van.xls')
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    })
  }

  goBack = async () => {
    await this.props.actions.refreshStatistic(false)
    Tool.Utils.saveData('isLoadPage', true)
    this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.id
                ? this.goBack
                : null
            }
            leftTitle="Thống kê thu hồi công văn"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={this.state.isShowMessengStatistics}
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container page-list-GeneralDocumentary">
            <ListFilter
              signNumberDepartmentLeaders={
                this.props.signNumberDepartmentLeaders
              }
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              filter={this.state.filter}
              readOnly={this.props.readOnly}
              isLoading={this.state.isLoading}
              match={this.props.match}
            />

            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={this.state.page}
                headers={headers.documentRecovery}
                itemShape={ListItem}
                data={this.props.documentRecovery.items}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError}
                isShowMessengStatistics={this.state.isShowMessengStatistics}
                actions={this.props.actions}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  documentRecovery: {
    ...state.documentRecovery,
    items: state.common.documentRecovery,
  },

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDocumentRecovery)
