import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { commonSaveTypeSidebar } from 'actions/index'

const HeaderMenuItem = ({ history, actions, dataSource, onClick }) => {
  const onMenuClick = e => {
    e.preventDefault()
    if (dataSource && dataSource?.typeSidebar) {
      actions.commonSaveTypeSidebar(dataSource.typeSidebar)
    }

    onClick()
    if (dataSource && dataSource?.path && history) {
      return history.push(dataSource.path)
    }
  }

  const isActive = useMemo(() => {
    const pathname = history.location.pathname
    let result = pathname === dataSource.path
    if (dataSource?.links.length > 0) {
      for (let i = 0; i < dataSource.links.length; i++) {
        const link =
          pathname[
            pathname.lastIndexOf(dataSource.links[i]) +
              dataSource.links[i].length
          ]
        if (
          pathname === dataSource.links[i] ||
          (pathname.lastIndexOf(dataSource.links[i]) === 0 && link === '/')
        ) {
          result = true
          break
        }
      }
    }

    return result
  }, [history, dataSource])

  return (
    <li
      className={classNames('item', {
        active: isActive,
      })}
      onClick={onMenuClick}
    >
      <div className={'icon-box'}>
        <span
          className={classNames('icon', {
            [`${dataSource.icon}`]: dataSource?.icon,
          })}
        />
        {dataSource?.newStatus ? (
          <span className="counter">{dataSource?.newStatus}</span>
        ) : (
          ''
        )}
      </div>
      <span className="name">{dataSource?.name}</span>
    </li>
  )
}

HeaderMenuItem.defaultProps = {
  auth: null,
  dataSource: [],
  onClick: () => {},
}

HeaderMenuItem.propTypes = {
  auth: PropTypes.object,
  dataSource: PropTypes.array,
  onClick: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonSaveTypeSidebar,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(HeaderMenuItem)
