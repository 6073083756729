import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DetailInfoPersonal extends Component {
  static propTypes = {
    work: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin công văn</h4>
        </div>
        <div className="form-container section-box-info">
          <div className="detail-info-container">
            <div className="list-items">
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Số công văn:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.work && this.props.work.soCongVan}
                  </span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Trích yếu:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content textarea-content">
                    {this.props.work && this.props.work.trichYeu}
                  </span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Chỉ đạo:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.work &&
                      this.props.work.chiDao &&
                      this.props.work.chiDao.noiDung}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DetailInfoPersonal
