import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Popover,
  Position,
  PopoverInteractionKind,
  Intent,
} from '@blueprintjs/core'
import { PopupConfirm } from 'components/common'
import {
  DELETE_BANNER_ARTICLE,
  TEXT_BUTTON_SUBMIT,
  TEXT_SUBMIT_BODY,
} from 'constants/Popup'
import { get } from 'lodash'
import { deleteSliders, putSliders } from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // random: Math.random(),
      data: get(props, 'item'),
      isOpenPopup: false,
      isLoadingButton: false,
    }
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  // componentDidMount() {
  //   this.setState({
  //     random: Math.random(),
  //   });
  // }

  componentWillReceiveProps(props) {
    this.setState({
      data: get(props, 'item'),
    })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  clickCheckBox = e => {
    const value = e.target.checked
    this.setState(
      {
        data: {
          ...this.state.data,
          isActive: value,
        },
      },
      () => {
        this.handleUpdateStatus()
      }
    )
  }

  handleSubmitRemoveItem = async id => {
    try {
      this.setState({ isLoadingButton: true })
      const response = await deleteSliders(id)
      if (response) {
        this.props.actions.commonAddToasterMessage({
          message: Toast.SUCCESS(Action.DELETE),
          intent: Intent.SUCCESS,
        })
        this.props && this.props.onHandleSubmitRemoveItem(id)
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: Toast.FAIL(Action.DELETE),
        intent: Intent.DANGER,
      })
    } finally {
      this.handleClickPopup()
      this.setState({ isLoadingButton: false })
    }
  }

  // Cập nhật trạng thái checkbox
  handleUpdateStatus = async () => {
    const { data } = this.state
    try {
      let dataParams = {
        id: get(data, 'id', null),
        title: get(data, 'title'),
        file: get(data, 'file.id'),
        url: get(data, 'url'),
        is_active: get(data, 'isActive'),
      }
      const response = await putSliders(dataParams)
      if (response) {
        this.props.actions.commonAddToasterMessage({
          message: Toast.SUCCESS(Action.UPDATE),
          intent: Intent.SUCCESS,
        })
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: Toast.FAIL(Action.UPDATE),
        intent: Intent.DANGER,
      })
    }
  }

  goEdit = () => {
    this.props.history.push(
      `/he-thong/quan-ly-cong-viec/banner/chinh-sua/${this.props.item.id}`
    )
  }

  render() {
    const { data, isLoadingButton, isOpenPopup } = this.state
    const { number } = this.props
    if (!data || !number) {
      return
    }

    return (
      <tr>
        <td>{number > 9 ? number : '0' + number}</td>
        <td>
          <span>{get(data, 'title')}</span>
          {/* <div className="avatar-background--box">
            <div
              className="avatar-background"
              style={{
                backgroundImage: `url(${
                  data.anhDaiDien
                    ? Types.IMAGE_URL +
                      data.anhDaiDien +
                      `?preventCache=${this.state.random}`
                    : '/images/default_avatar.png'
                })`,
              }}
            />
          </div> */}
        </td>
        <td>
          <div className="center pointer">
            <Checkbox
              checked={get(data, 'isActive', false)}
              onChange={this.clickCheckBox}
              className="checkbox-inline"
            />
          </div>
        </td>
        <td>
          <Popover
            popoverClassName="pt-popover-content-sizing pt-minimal"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                <span className="item popover-item" onClick={this.goEdit}>
                  <span className="icon icon-s-edit"></span>
                  <span className="name">Chỉnh sửa</span>
                </span>
                <span
                  className="item popover-item"
                  onClick={this.handleClickPopup}
                >
                  <span className="icon icon-s-delete"></span>
                  <span className="name">Xóa</span>
                </span>
              </div>
            }
          >
            <span className="icon-More font-size-24"></span>
          </Popover>
          <PopupConfirm
            isOpen={isOpenPopup}
            onClose={this.handleClickPopup}
            title={DELETE_BANNER_ARTICLE}
            text={TEXT_BUTTON_SUBMIT}
            onSubmit={() => this.handleSubmitRemoveItem(data.id)}
            isLoading={isLoadingButton}
          >
            {TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

ListItem.propTypes = {
  item: PropTypes.object,
  number: PropTypes.number,
}

export default ListItem
