import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    total: 0,
    items: [],
  },
  digitizing: [],
}

export default function digitalDocuments(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENT_RECEIVE_FETCH_DIGITAL_DOCUMENTS:
      return {
        ...state,
        list: {
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }
    default:
      return state
  }
}
