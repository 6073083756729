import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  static propTypes = {
    objects: PropTypes.array,
    departments: PropTypes.array,
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  state = {
    dateUpdate: moment(),
    filter: {
      textSearch: '',
      ngayCongVan: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      phongBanId: '',
      doiTuongId: '',
    },
  }

  clearFilter(e) {
    this.setState(
      {
        filter: {
          textSearch: '',
          ngayCongVan: {
            from: moment().subtract(1, 'months'),
            to: moment(),
          },
          phongBanId: '',
          doiTuongId: '',
        },
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  handleDateChange(name, pos, e, value) {
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
    })
  }

  componentWillReceiveProps(props) {
    const { filter } = props

    if (filter) {
      const { ngayCongVan } = filter
      this.setState({
        filter: {
          textSearch: filter.textSearch || '',
          ngayCongVan: {
            from:
              ngayCongVan &&
              ngayCongVan.from &&
              ngayCongVan.from.trim().length > 0
                ? moment(ngayCongVan.from)
                : '',
            to:
              ngayCongVan && ngayCongVan.to && ngayCongVan.to.trim().length > 0
                ? moment(ngayCongVan.to)
                : '',
          },
          phongBanId: filter.phongBanId || '',
          doiTuongId: filter.doiTuongId || '',
        },
      })
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCongVan: {
        from: this.state.filter.ngayCongVan.from
          ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCongVan.to
          ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  render() {
    return (
      <form
        className="search-container search-DocumentarySearch py-12 px-sm-15 mg-bt-20"
        onSubmit={this.handleSubmit}
      >
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div>
              <label className="search-label">Thời gian (Ngày công văn)</label>
              <div className="mt5 form-input-filter date-picker-inline border-radius-3 ">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.from}
                  selectsStart
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayCongVan.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.to}
                  selectsEnd
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayCongVan.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-575-12 col-xs-6 col-md-2 mt10 mt-md-0">
            <div>
              <label className="search-label">Đối tượng</label>
              <div className="mt5 form-input-filter select-arrow border-radius-3 ">
                <select
                  className="input border-transparent appearance-none"
                  name="doiTuongId"
                  onChange={this.handleInputChange}
                  value={this.state.filter.doiTuongId}
                >
                  <option value="">Tất cả</option>
                  {this.props.objects.map((item, i) => (
                    <option key={i} value={item.doiTuongCongVanId}>
                      {item.tenDoiTuongCongVan}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-575-12 col-xs-6 col-md-2 mt10 mt-md-0">
            <div>
              <label className="search-label">Phòng ban</label>
              <div className="mt5 form-input-filter select-arrow border-radius-3 ">
                <select
                  className="input border-transparent appearance-none"
                  name="phongBanId"
                  onChange={this.handleInputChange}
                  value={this.state.filter.phongBanId}
                >
                  <option value="">Tất cả</option>
                  {this.props.departments.map((item, i) => (
                    <option key={i} value={item.phongBanId}>
                      {item.maPhongBan}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 ">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent pr-40"
                placeholder="Tìm kiếm Số CV, Trích yếu (hoặc Số CV:Trích yếu)..."
                type="text"
                dir="auto"
                name="textSearch"
                onChange={this.handleInputChange}
                value={this.state.filter.textSearch}
              />
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              type="button"
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilter
