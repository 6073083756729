import classNames from 'classnames'
import { get, size } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Cookies, withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { bindActionCreators } from 'redux'

import { Position, Tooltip } from '@blueprintjs/core'
import { getDashboard } from 'actions'
import { getDSPhieuLayYKien, getDanhSachSlider } from 'actions/task'
import { LoadingItem } from 'components/common'
import { FE_CHAT_URL, FILE_URL, FOLDER_NAME } from 'constants/Api'
import { MasterLayout } from '../../components/layout'
import Background from './Home/Background'
import HeaderInfo from './HomeCard/HeaderInfo'

const cookies = new Cookies()

var settings = {
  arrows: false,
  centerPadding: '0px',
  dots: true,
  fade: false,
  speed: 1000,
  autoPlaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  infinite: true,
}
class HomePage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isLoading: false,
      dataStatistics: size(props.statisticsHomePage)
        ? props.statisticsHomePage
        : [],
      dataSliders: [],
      isNewHomePage: false,
      isLeftNav: false,
    }
    this.handleOpenChat = this.handleOpenChat.bind(this)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const quyensMenu = props.quyensMenu
    if (size(props.statisticsHomePage)) {
      this.setState({
        dataStatistics: props.statisticsHomePage,
      })
    }

    if (cookies.get('theme_menu') && cookies.get('theme_menu') === 'VERTICAL') {
      this.setState({ isLeftNav: true })
    } else {
      this.setState({ isLeftNav: false })
    }

    if (quyensMenu && size(quyensMenu) !== 0) {
      this.setState({ isNewHomePage: true })
    }
  }

  getDataSlider = async () => {
    let params = {
      page: 1,
      limit: 100,
      sort: 'id',
    }
    const response = await getDanhSachSlider(params)
    this.setState({
      dataSliders: get(response, 'payload.data.result.items', []),
    })
  }

  goLinkSlider = elm => {
    if (!!get(elm, 'url')) {
      window.open(get(elm, 'url'), 'eoffice-slider-url')
    }

    return
  }

  componentDidMount() {
    this.getDataSlider()
    getDSPhieuLayYKien({
      page: 1,
      limit: 10,
      canXuLy: true,
    })
    this.props.actions.getDashboard({
      limit: 10,
    })
  }

  handleOpenChat() {
    const { auth } = this.props

    const url =
      FE_CHAT_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`
    window.open(url, 'eoffice-chat')
  }

  render() {
    const bgImage = `/backgrounds/bg1.jpg`
    const { dataSliders, isLoading, isNewHomePage, isLeftNav } = this.state
    const { auth, history } = this.props
    const token = get(auth, 'token.accessToken', '')
    return (
      <MasterLayout typeSidebar="HomePage">
        <div
          ref={el => (this.header = el)}
          className="dashboard-wrapper"
          style={{
            backgroundImage: `url(${
              get(cookies.get('background_image'), 'url')
                ? FOLDER_NAME
                  ? `/backgrounds/${FOLDER_NAME}/${get(
                      cookies.get('background_image'),
                      'url'
                    )}`
                  : `/backgrounds/${get(
                      cookies.get('background_image'),
                      'url'
                    )}`
                : bgImage
            })`,
          }}
        >
          <div
            className={classNames('dashboard-container', {
              'dashboard-container-new': isNewHomePage,
            })}
          >
            <div className="wrap-header__info mb20">
              <div>
                {(isNewHomePage || (isLeftNav && !isNewHomePage)) && (
                  <HeaderInfo history={history} isLeftNav={isLeftNav} />
                )}
              </div>
              <div>
                {((isNewHomePage && isLeftNav) ||
                  (!isNewHomePage && isLeftNav)) && (
                  <>
                    <Tooltip
                      position={Position.BOTTOM}
                      content={
                        <span className="font-size-12">Truy cập Chat</span>
                      }
                    >
                      <button
                        className="background-home-icon chat ml15"
                        onClick={this.handleOpenChat}
                      >
                        <span className="icon-chat"></span>
                      </button>
                    </Tooltip>
                  </>
                )}
                <Tooltip
                  position={Position.BOTTOM}
                  content={<span className="font-size-12">Cài đặt</span>}
                >
                  <Background
                    classNameIcon={
                      isNewHomePage || (isLeftNav && !isNewHomePage)
                        ? 'background-home-icon setting'
                        : ''
                    }
                  />
                </Tooltip>
              </div>
            </div>
            <div className="home-row">
              <div className="home-col">
                <div className="home-left--block">
                  {isLoading && (
                    <div className="loading-container">
                      <LoadingItem />
                      <LoadingItem />
                      <LoadingItem />
                    </div>
                  )}
                  {!isLoading && dataSliders.length !== 0 && (
                    <div className="slider-block">
                      <Slider {...settings}>
                        {dataSliders.map((elm, index) => {
                          let bgImageSlider = `${FILE_URL}/api/file/get/${get(
                            elm,
                            'file.id'
                          )}?BearerToken=${token}`
                          return (
                            <div key={index} className="pointer">
                              <div className="slider-item">
                                <div
                                  className="slider-item-image"
                                  style={{
                                    backgroundImage: `url('${bgImageSlider}')`,
                                  }}
                                  onClick={() => this.goLinkSlider(elm)}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

HomePage.propTypes = {
  auth: PropTypes.object,
  statisticsHomePage: PropTypes.array,
  history: PropTypes.object.isRequired,
  quyensMenu: PropTypes.array,
}

const mapStateToProps = state => ({
  auth: state.auth,
  statisticsHomePage: state.TaskManager.statisticsHomePage,
  numberSideBar: state.common.numberSideBar,
  statisticsDashboard: state?.TaskManager?.statisticsDashboard,
  quyensMenu: state.common.quyensMenu,
  listDashboard: state.dashboard?.listDashboard,
  listPhieuLayYKien: state.dashboard?.listPhieuLayYKien,
  listLayYKienDonVi: state.dashboard?.listLayYKienDonVi,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDashboard,
    },
    dispatch
  ),
})

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
)
