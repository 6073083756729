import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import csx from 'classnames'
import { get } from 'lodash'

import DropdownWrapper from './DropdownWrapper'
import { Card } from '../index'

const Select = ({
  heightMax = 350,
  dataSource = [],
  onChange: selectChange = () => {},
  placeholder = '',
  value: selectValue,
  clearable = true,
  disabled = false,
  styleInput = null,
  isShowRightElement = true,
  inputClassName = '',
  dropdownClassName = '',
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [selected, _setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    _mountedSet(_setDropdownOpen, open)
  }, [])

  const _onChange = useCallback(
    value => () => {
      _mountedSet(_setSelected, value)
      _toggleDropdown(false)
      selectChange(value)
    },
    [selectChange, _toggleDropdown]
  )

  const _setValue = useCallback(() => {
    _mountedSet(_setSelected, selectValue)
  }, [selectValue])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _setValue()
  }, [_setValue])

  return (
    <DropdownWrapper
      clearable={clearable}
      onClear={_onChange(undefined)}
      onDropdownVisibleChange={_toggleDropdown}
      open={dropdownOpen}
      placeholder={placeholder}
      value={dataSource
        .filter(({ value }) => selected === value)
        .map(({ label }) => label)
        .join(', ')}
      disabled={disabled}
      styleInput={styleInput}
      isShowRightElement={isShowRightElement}
      inputClassName={inputClassName}
    >
      <Scrollbars autoHeight autoHeightMax={heightMax}>
        <Card className="popup" mainClassName={`cpc-list ${dropdownClassName}`}>
          {dataSource.map(({ value, label, color }) => (
            <Card
              className={csx('cpc-list-item', 'cpc-dropdown-item', {
                active: selected === value,
              })}
              onClick={_onChange(value)}
              key={value}
              style={{ color: color ? color : '' }}
            >
              {label}
            </Card>
          ))}
        </Card>
      </Scrollbars>
    </DropdownWrapper>
  )
}

export default memo(Select)
