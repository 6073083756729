import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'

class DetailInfo extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  render() {
    let tinhTrang = ''
    if (this.props.item.tinhTrang === -1) {
      tinhTrang = 'Chưa duyệt'
    } else if (this.props.item.tinhTrang === 0) {
      tinhTrang = 'Không duyệt'
    } else if (this.props.item.tinhTrang === 1) {
      tinhTrang = 'LĐPB CT đã duyệt'
    } else if (this.props.item.tinhTrang === 2) {
      tinhTrang = 'LĐPB PH đã duyệt'
    } else if (this.props.item.tinhTrang === 3) {
      tinhTrang = 'LĐĐV đã duyệt'
    } else if (this.props.item.tinhTrang === 4) {
      tinhTrang = 'TK LĐĐV đã duyệt'
    } else if (this.props.item.tinhTrang === 5) {
      tinhTrang = 'Đã cấp số'
    } else {
      tinhTrang = 'Đã phát hành'
    }

    return (
      <div
        className={classnames('info-top', {
          'info-top-open': this.props.showDetailInfo,
        })}
      >
        <div className="heading">
          <span
            className="click-go-back"
            onClick={this.props.closeShowDetailInfo}
          >
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin công văn</h4>
        </div>
        <p className="number-code">
          <span className="name">Số ký hiệu:</span>
          <span className="code">{this.props.item.soKyHieu}</span>
        </p>
        <div className="list-info-document list-info-sign-number">
          <ul>
            <li className="item-btn item-detail-sign-number-info">
              <label className="pt-label pt-inline label-item">
                Ngày lập:
                <span className="pt-info">
                  {moment(this.props.item.ngayLap).format('DD/MM/YYYY')}
                </span>
              </label>
            </li>
            <li className="item-btn item-detail-sign-number-info">
              <label className="pt-label pt-inline label-item">
                Người lập:
                <span className="pt-info">
                  {this.props.item.nguoiLap
                    ? this.props.item.nguoiLap.tenNhanVien
                    : null}
                </span>
              </label>
            </li>
            <li className="item-btn item-detail-sign-number-info">
              <label className="pt-label pt-inline label-item">
                Tình trạng:
                <span className="pt-info text-status">{tinhTrang}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default DetailInfo
