import React, { memo } from 'react'
import { Dialog as PDialog, Button, Intent } from '@blueprintjs/core'
import csx from 'classnames'

import { Card } from '../index'

const Dialog = ({
  children = null,
  isOpen = false,
  title = '',
  content = null,
  onClose = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  cancelClassName = '',
  cancelDanger = false,
  cancelDisabled = false,
  cancelLeftIcon = null,
  cancelRightIcon = null,
  cancelText = 'Hủy',
  confirmClassName = '',
  confirmDanger = false,
  confirmDisabled = false,
  confirmRightIcon = null,
  confirmText = 'Xác nhận',
  width = 350,
  contentMaxHeight = 'unset',
  contentPadding = true,
  footerPadding,
  textCenter = false,
  buttonUppercase = false,
  loading = false,
}) => {
  return (
    <PDialog
      isOpen={isOpen}
      onClose={!loading ? onClose : null}
      title={title}
      className="cpc-confirm"
      style={{ width }}
    >
      <Card
        className={csx({
          'mt10 mb10': contentPadding,
          'pl20 pr20': contentPadding,
          'text-center': textCenter,
        })}
        style={{
          overflowY: 'scroll',
          maxHeight: contentMaxHeight,
        }}
      >
        {content}
        {children}
      </Card>
      <Card
        bordered
        className={csx('confirm-buttons', {
          'pv20 ph10': !footerPadding,
          footerPadding,
        })}
      >
        <Button
          className={csx(
            'cpc-button',
            {
              default: !cancelDanger,
              transparent: cancelDanger,
              uppercase: buttonUppercase,
            },
            cancelClassName
          )}
          intent={cancelDanger ? Intent.DANGER : Intent.NONE}
          onClick={onCancel}
          disabled={loading || cancelDisabled}
        >
          {cancelLeftIcon}
          {cancelText}
          {cancelRightIcon}
        </Button>
        <Button
          className={csx(
            'cpc-button',
            {
              uppercase: buttonUppercase,
            },
            confirmClassName
          )}
          intent={confirmDanger ? Intent.DANGER : Intent.PRIMARY}
          onClick={onConfirm}
          disabled={confirmDisabled}
          loading={loading}
        >
          {confirmText}
          {confirmRightIcon}
        </Button>
      </Card>
    </PDialog>
  )
}

export default memo(Dialog)
