import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import ReactDatePicker from 'react-daterange-picker'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import moment from 'moment'

import { Input } from 'components/newCommon'

const DatePicker = ({
  disabled = false,
  popoverPosition = Position.BOTTOM_LEFT,
  placeholder = 'Chọn thời gian',
  leftIcon = 'icon2-date-frames',
  valueDisplay = {},
  today = moment(),
  classNamePicker = '',
  classNameInput = '',
  clearable = false,
  minimumDate = null,
  onChange: dateChange = () => {},
  value = null,
}) => {
  const [isOpen, _setIsOpen] = useState(false)
  const [valueDate, setValueDate] = useState(value || today)

  const [valueInput, setValueInput] = useState(null)
  const _toggleOpen = isOpen => {
    _setIsOpen(isOpen)
  }

  const onSelect = date => {
    setValueDate(date)
    setValueInput(
      moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : ''
    )
    dateChange(date)
    _toggleOpen(false)
  }

  useEffect(() => {
    setValueInput(
      moment(valueDisplay).isValid()
        ? moment(valueDisplay).format('DD/MM/YYYY')
        : ''
    )
  }, [valueDisplay])

  return (
    <Popover
      content={
        <ReactDatePicker
          className={classnames('cpc-datepicker-range', classNamePicker)}
          value={valueDate}
          onSelect={onSelect}
          selectionType="single"
          minimumDate={minimumDate}
          // maximumDate={maximumDate}
        />
      }
      isOpen={isOpen}
      interactionKind={PopoverInteractionKind.CLICK}
      onClose={() => _toggleOpen(false)}
      rootElementTag="div"
      popoverClassName="cpc-popover no-arrow mt5 ph20 pv10"
      position={popoverPosition}
    >
      <Input
        className={classnames(classNameInput)}
        disabled={disabled}
        leftIcon={leftIcon}
        readOnly
        placeholder={placeholder}
        clearable={clearable}
        value={valueInput}
        onClick={() => _toggleOpen(!isOpen)}
        onClear={() => onSelect(null)}
      />
    </Popover>
  )
}

export default DatePicker
