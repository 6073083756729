import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { AddProjectCategory } from '../../components/SignNumber'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'

const PATH = '/ky-so-mo-rong/du-an/chinh-sua/:id'
const PUSH = '/ky-so-mo-rong/du-an/danh-sach'
const SUA_DU_AN = 'Sửa quy trình'
const TAO_DU_AN = 'Tạo quy trình'

class AddProjectCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    donViId: this.props.auth.mainUnitId,
    project: null,
    unitSelect: [],
    departments: [],
    users: [],
  }

  componentDidMount() {
    let donViId = Utils.getData('Project-Unit', {})

    if (donViId === undefined) {
      donViId = this.state.donViId
    }

    if (this.props.match.params && this.props.match.params.id) {
      this.props.actions
        .getProjectItem(this.props.match.params.id)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            this.setState({ project: res.payload.data.result })
          }
        })
    }

    this.setState({ donViId }, () => {
      this.props.actions.getCategoryUnitMultiLevel()
      if (this.props.match.path !== PATH) {
        this.props.actions
          .getCategoryDepartmentMultiLevel(donViId, false)
          .then(res => {
            if (
              res.payload &&
              res.payload.data.result &&
              res.payload.data.result.length > 0
            ) {
              const phongBanId = this.props.auth.mainDepartmentId
              const check =
                res.payload.data.result.find(
                  item => phongBanId === item.phongBanId
                ) !== undefined
              this.props.actions.commonFetchStaffByDepartmentId(
                check ? phongBanId : res.payload.data.result[0].phongBanId
              )
            }
          })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({ unitSelect: nextProps.unitSelect.items })
    }

    if (nextProps.departments.items !== this.props.departments.items) {
      this.setState({ departments: nextProps.departments.items })
    }

    if (nextProps.users.items !== this.props.users.items) {
      this.setState({ users: nextProps.users.items })
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.history.push(PUSH)
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    if (this.props.match.path === PATH) {
      return this.props.history.goBack()
    }

    this.props.history.push(PUSH)
  }

  render() {
    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={this.state.project ? SUA_DU_AN : TAO_DU_AN}
              iconPage="icon-Tao_Du_An"
            />
            <AddProjectCategory
              unit={this.state.unitSelect}
              departments={this.state.departments}
              users={this.state.users}
              actions={this.props.actions}
              auth={this.props.auth}
              onSubmit={this.handleSubmit}
              match={this.props.match}
              project={this.state.project}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },

  users: {
    ...state.common,
    items: state.common.staffs,
  },

  departments: {
    ...state.categoryDepartmentMultiLevel,
    items: state.category.categoryDepartmentMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProjectCategoryPage)
