import React, { useEffect, useState, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { IMAGE_URL, CABINET_DEPARTMENT } from 'constants/Api'
import Permission from 'components/auth/Permission'
import ChangePassword from './components/change-password'
import {
  setCabinetType,
  commonSaveTypeSidebar,
  switchRoleUser,
  userPermission,
} from 'actions/index'

const HeaderUserInfo = ({ auth, history, actions, onLogout }) => {
  const user = auth?.user
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowChangePassword, setIsShowChangePassword] = useState(false)

  const handleChoseRole = id => {
    if (id === auth.roleId) {
      return
    }

    actions.switchRoleUser({ roleId: id })
    actions.userPermission(id).then(res => {
      let redirectTo = '/'
      let typeSidebar = 'HomePage'
      if (res?.payload?.data?.result?.items) {
        const permission = res.payload.data.result.items.map(
          item => item.maQuyen
        )
        if (permission.indexOf(Permission.CONGVANDEN) !== -1) {
          redirectTo = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
          typeSidebar = 'documentary'
        } else {
          if (permission.indexOf(Permission.CONGVIEC_PHONGBAN) !== -1) {
            redirectTo = '/cong-viec/phong-ban/danh-sach/chua-giao'
            typeSidebar = 'work'
          } else if (permission.indexOf(Permission.CONGVIEC_CANHAN) !== -1) {
            redirectTo = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
            typeSidebar = 'work'
          }
        }

        if (permission.indexOf(Permission.TUTAILIEUPHONGBAN_QUANLY) !== -1) {
          actions.setCabinetType(CABINET_DEPARTMENT)
        }
      }

      actions.commonSaveTypeSidebar(typeSidebar)
      if (history?.location && redirectTo === history?.location?.pathname) {
        return window.location.reload()
      }

      history.push(redirectTo)
    })
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  const avatarUrl = useMemo(() => {
    return user && user.anhDaiDien
      ? `${IMAGE_URL}${user.anhDaiDien}?preventCache=${Math.random()}`
      : '/images/default_avatar.png'
  }, [user])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} className="header-v2-user-info">
      <button onClick={toggleDropdown} className="user-info cursor-pointer">
        <div
          className="avatar"
          style={{
            backgroundImage: `url(${avatarUrl})`,
          }}
        ></div>
        <span className="name">{user.tenNhanVien}</span>
        <span
          className={classNames('arrow', {
            'icon2-arrow-down': !isOpen,
            'icon2-arrow-up': isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div className="header-v2-user-info-menu">
          <p className="text-14-700-17">Chức danh</p>
          <ul className="user-info-role">
            {user?.dsChucDanh?.map((item, index) => (
              <li
                key={index}
                className={classNames({
                  active: auth?.roleId === item.id,
                })}
                onClick={() => handleChoseRole(item.id)}
              >
                <div className="user-info-role-name">
                  <span>
                    {item?.chucVu ? item.chucVu.tenChucVu + ' ' : ' '}
                    {item?.phongBan ? item.phongBan.maPhongBan : ''}
                  </span>
                  <span className="icon2-check"></span>
                </div>
              </li>
            ))}
          </ul>
          <p
            className="d-flex gap-8 m0 mt15 ph15 border-top cursor-pointer"
            onClick={() => {
              setIsShowChangePassword(true)
              setIsOpen(false)
            }}
          >
            <span className="icon-lock color-main"></span>
            <span className="text-14-700-17">Đổi mật khẩu</span>
          </p>
          <p
            className="d-flex gap-8 m0 pt15 border-top cursor-pointer"
            onClick={onLogout}
          >
            <span className="icon-logout color-main"></span>
            <span className="text-14-700-17">Đăng xuất</span>
          </p>
        </div>
      )}
      {isShowChangePassword && (
        <ChangePassword
          onClose={() => setIsShowChangePassword(false)}
          logout={onLogout}
        />
      )}
    </div>
  )
}

HeaderUserInfo.defaultProps = {
  auth: null,
  history: null,
  onLogout: () => {},
}

HeaderUserInfo.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
  onLogout: PropTypes.func,
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { setCabinetType, commonSaveTypeSidebar, switchRoleUser, userPermission },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserInfo)
