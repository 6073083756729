import React from 'react'
import { MasterLayout } from '../../components/layout'
import { FormAddRegencyCategory } from '../../components/System'
import { HeadingPage } from '../../components/common'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'

const SUA_CHUC_VU = 'Sửa chức vụ'
const THEM_MOI_CHUC_VU = 'Thêm mới chức vụ'

class AddRegencyCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    chucVuId: this.props.match.params.id,
    position: null,
    unitSelect: [],
  }

  componentDidMount() {
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (this.props.match.params && this.props.match.params.id) {
      this.props.actions.getCommonFetchPositionItem(this.state.chucVuId)
    }

    if (permission) {
      this.props.actions.getCategoryUnitMultiLevel()
    } else {
      this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.position.item !== this.props.position.item) {
      this.setState({ position: nextProps.position.item })
    }

    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({ unitSelect: nextProps.unitSelect.items })
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    return this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={this.state.position ? SUA_CHUC_VU : THEM_MOI_CHUC_VU}
              iconPage="icon-position-user"
            />
            <FormAddRegencyCategory
              actions={this.props.actions}
              position={this.state.position}
              unitSelect={this.state.unitSelect}
              auth={this.props.auth}
              onSubmit={this.handleSubmit}
              match={this.props.match}
              history={this.props.history}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  position: {
    ...state.positionItem,
    item: state.common.positionItem,
  },

  auth: {
    ...state.auth,
    user: state.auth,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRegencyCategoryPage)
