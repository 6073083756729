import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { ListItemRolePermissions } from '../../components/System'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { SelectTree } from '../../components/common/'
import * as Actions from '../../actions'
import { Utils } from '../../helpers'
import { MESSAGE_NOT_FOUND, MESSAGE_ERROR } from '../../constants/MessageForm'

class RolePermissionsPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  state = {
    categoryRole: [],
    donViId: this.props.auth.mainUnitId,
    isLoading: true,
    isEmpty: false,
    unitSelect: [],
    isError: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categoryRole.items !== this.props.categoryRole.items) {
      this.setState({
        categoryRole: nextProps.categoryRole.items,
        isLoading: false,
      })
      if (nextProps.categoryRole.items.length === 0) {
        this.setState({ isEmpty: true })
      }
      if (nextProps.categoryRole.items.length > 0) {
        this.setState({ isEmpty: false })
      }
    } else {
      this.setState({ isLoading: false })
    }

    if (nextProps.unit.items !== this.props.unit.items) {
      this.setState({ unitSelect: nextProps.unit.items })
    }
  }

  componentDidMount() {
    let donViId = Utils.getData('RolePermissionPage', {})

    if (donViId === undefined) {
      donViId = this.state.donViId
    }

    this.setState({ donViId }, () => {
      this.props.actions.getCategoryUnitMultiLevel().then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
      })
      this.props.actions.getRole().then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
      })
      if (this.props.auth.mainUnitId) {
        this.props.actions.getCategoryCommonFetchRole(donViId).then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
      }
    })
  }

  handleChange(e) {
    if (e !== undefined) {
      this.setState(
        {
          donViId: e,
          isLoading: true,
        },
        () => {
          Utils.saveData('RolePermissionPage', e)
          this.props.actions.getCategoryCommonFetchRole(e).then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
        }
      )
    }
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Phân quyền vai trò"
          iconPage="icon-decentralization"
        />
        <div className="decentralization-container">
          <div className="page-list-container page-list-role-container">
            <div className="search-container search-group-container">
              <div className="row seach-form">
                <div className="col-xs-12 col-sm-12 col-sm-12 end-xs">
                  <form className="form-search-basic">
                    <div className="search-header-group form-container search-system-module">
                      <div className="search-system-tree">
                        <label className="pt-label-inline">
                          <span className="text-label">Đơn vị</span>
                        </label>
                        <SelectTree
                          data={this.state.unitSelect}
                          type="Unit"
                          permission={false}
                          value={
                            this.state.unitSelect.length > 0
                              ? this.state.donViId
                              : ''
                          }
                          handleChange={this.handleChange}
                          isLoading={
                            this.state.unitSelect.length > 0 ? false : true
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="table-container">
              {this.state.isLoading ? (
                <div className="table-loading-system">
                  {ItemLoading}
                  {ItemLoading}
                  {ItemLoading}
                </div>
              ) : (
                !this.state.isEmpty &&
                !this.state.isError && (
                  <div className="table-scroll-fixed">
                    <table className="pt-table">
                      <thead>
                        <tr>
                          <th
                            style={{
                              minWidth: '60px',
                              width: '60px',
                              textAlign: 'center',
                              paddingLeft: '10px',
                            }}
                            className="table-scroll-fixed--col"
                          >
                            <span className="head-label colum-stt">STT</span>
                          </th>
                          <th className="table-scroll-fixed--col table-scroll-fixed--col2">
                            <span className="head-label">Mã Quyền</span>
                          </th>
                          {this.state.categoryRole.map((item, i) => (
                            <th className="colum-role" key={i}>
                              <span className="head-label">
                                {item.tenDoiTuong}
                              </span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <ListItemRolePermissions
                        donViId={this.state.donViId}
                        maQuyen={this.props.getRole.items}
                        categoryRole={this.state.categoryRole}
                        actions={this.props.actions}
                      />
                    </table>
                  </div>
                )
              )}
              {this.state.isEmpty &&
                !this.state.isLoading &&
                !this.state.isError && (
                  <div className="error-page-container">
                    <div className="error-content">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/empty-default.png'
                        }
                        alt="search-result-not-found"
                        className="img-thumb-error"
                      />
                      <p className="description-note">{MESSAGE_NOT_FOUND}</p>
                    </div>
                  </div>
                )}
              {this.state.isError && !this.state.isLoading && (
                <div className="error-page-container">
                  <div className="error-content">
                    <img
                      src={process.env.PUBLIC_URL + '/images/Server.png'}
                      alt="error"
                      className="img-thumb-error"
                    />
                    <p className="description-note">{MESSAGE_ERROR}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  categoryRole: {
    ...state.categoryRole,
    items: state.common.categoryRole,
  },

  auth: {
    ...state.auth,
    user: state.auth,
  },

  getRole: {
    ...state.getRole,
    items: state.common.getRole.map(item => item.maQuyen),
  },

  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RolePermissionsPage)
