import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  listMemberByProject: {
    items: [],
  },
  listFeedbacks: {
    page: 1,
    total: 0,
    items: [],
  },
  listHistoryUpdate: {
    items: [],
  },
}

export default function signNumberProjects(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_NUMBER_PROJECT_GET_MEMBER_BY_ID:
      return {
        ...state,
        listMemberByProject: {
          items: action.payload.data.result.result.items || [],
        },
      }

    case types.SIGN_NUMBER_PROJECTS_GET:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.SIGN_NUMBER_PROJECTS_GET_DETAIL:
    case types.SIGN_NUMBER_PROJECTS_GET_DETAIL_UPDATE:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_GET:
      return {
        ...state,
        listFeedbacks: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.SIGN_NUMBER_PROJECTS_UPDATE_HISTORY_GET:
      return {
        ...state,
        listHistoryUpdate: {
          items: action.payload.data.result.items,
        },
      }

    default:
      return state
  }
}
