import React from 'react'
import { MasterLayout } from '../../components/layout'
import { InputForm } from '../../components/SendDocument'
import {
  DigitizingList,
  DigitizingItem,
  SignNumberList,
  SignNumberItem,
  ConvergeSignatureItem,
  LoadingItem,
  ConvergeSignatureList,
} from '../../components/common'
import {
  DetailFileContent,
  DetailFileDinhKem,
  FileList,
} from '../../components/common'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import * as Type from '../../constants/Api'
import * as Tool from '../../helpers/'
import * as MESSAGER from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import { intersectionBy, concat } from 'lodash'

const UNITID = null
const ISALL = false
const REFRESH_SIGN_NUMER = true

class InputSendDocumentPage extends React.Component {
  static propTypes = {
    objects: PropTypes.array.isRequired,
    securities: PropTypes.array.isRequired,
    priorities: PropTypes.array.isRequired,
    saveCodes: PropTypes.array.isRequired,
    receiveUnits: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    digitalDocuments: PropTypes.object.isRequired,
    leaderDepartments: PropTypes.array.isRequired,
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  constructor(props, context) {
    super(props, context)
    this.handleDigitalChange = this.handleDigitalChange.bind(this)
    this.handleShowDetailSignNumber = this.handleShowDetailSignNumber.bind(this)
    this.handleShowDetailDigital = this.handleShowDetailDigital.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSignNumberChange = this.handleSignNumberChange.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleLoadSubmit = this.handleLoadSubmit.bind(this)
  }

  state = {
    isShow: false,
    isShowDetail: false,
    digitalDocumentIdSelected: '',
    digitalDocuments: [],
    signNumberDocuments: [],
    ksttDocuments: [],
    dataList: [],
    status: null,
    digitalId: '',
    signNumberId: '',
    itemId: '',
    digitalFile: null,
    signNumberFile: null,
    signNumberAttachFileList: [],
    fileDefault: {
      fileName: '20171005083456899_9a217faa-b823-4290-bce6-779ee83f2c2d.docx',
    },
    soLuuId: null,
    isUpdate: false,
    sendDocumentId: '',
    sendDocument: null,
    isLoadingData: {
      soLuuId: true,
      // doKhanId: true,
      // doBaoMatId: true,
      phongBanLapId: true,
      nguoiKyId: true,
      // doiTuongCongVanId: true,
      digital: false,
      signNumber: false,
      sendDocument: true,
      receiveUnits: true,
      attachedUnits: true,
      otherUnits: true,
      // connectedUnit: true,
      // connectedUnitEVN: true,
      convergeSignature: false,
    },
    digitalFileURL: null,
    isLoadingFile: false,
    isFailLoadingFile: false,
    isLoadedData: {
      digital: false,
      signNumber: false,
      convergeSignature: false,
    },
    isSubmitSuccess: false,
    isLoadingSubmit: false,
    isShowAttachedUnits: true,
    isShowOtherUnits: true,
    isShowConnectedUnit: true,
    isShowConnectedUnitEVN: true,
    templateData: {},
    convergeSignatureDataList: [],
    isConvergeSignatureImport: false,
    isCheckCongVanKySo: false,
    filesThamKhaoTuKySo: [],
  }

  handleLoadSubmit = () => {
    this.setState({
      isLoadingSubmit: !this.state.isLoadingSubmit,
    })
  }

  handleDigitalChange = async e => {
    if (this.state.isLoadedData && this.state.isLoadedData.digital === false) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          digital: true,
        },
        isShow: !this.state.isShow,
        status: 'digital',
      })
    }

    if (this.state.isLoadedData && this.state.isLoadedData.digital === false) {
      return this.props.actions
        .receiveDocumentFetchDigitalDocuments(Type.DIGITAL_SEND_DOCUMENT)
        .then(res => {
          let digitalDocuments = []
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.entities &&
            res.payload.data.entities.digitalDocuments
          ) {
            digitalDocuments = res.payload.data.entities.digitalDocuments
          }
          this.setState({ digitalDocuments })
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              digital: false,
            },
            dataList: this.props.digitalDocuments.list,
            isLoadedData: {
              ...this.state.isLoadedData,
              digital: true,
            },
          })
        })
        .catch(e => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              digital: false,
            },
            status: 'digital',
          })
        })
    }

    return this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        digital: false,
      },
      isShow: !this.state.isShow,
      dataList: this.props.digitalDocuments.list,
      status: 'digital',
      signNumberAttachFileList: [],
      filesThamKhaoTuKySo: [],
    })
  }

  handleClickGoBack(e) {
    this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        soLuuId: false,
        // doKhanId: false,
        // doBaoMatId: false,
        phongBanLapId: false,
        nguoiKyId: false,
        // doiTuongCongVanId: false,
        digital: false,
        signNumber: false,
        sendDocument: false,
        receiveUnits: false,
        attachedUnits: false,
        otherUnits: false,
        connectedUnit: false,
        connectedUnitEVN: false,
        convergeSignature: false,
      },
      isShowDetail: false,
    })
  }

  handleSignNumberChange = async e => {
    if (
      this.state.isLoadedData &&
      this.state.isLoadedData.signNumber === false
    ) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          signNumber: true,
        },
        isShow: !this.state.isShow,
        status: 'signNumber',
      })
    }

    if (
      this.state.isLoadedData &&
      this.state.isLoadedData.signNumber === false
    ) {
      return this.props.actions
        .releasedSignNumberGet(REFRESH_SIGN_NUMER)
        .then(res => {
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.entities &&
            res.payload.data.entities.signNumbers
          ) {
            this.setState({
              signNumberDocuments: res.payload.data.entities.signNumbers,
            })
          }
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              signNumber: false,
            },
            dataList: this.props.signNumbers.list.items,
            isLoadedData: {
              ...this.state.isLoadedData,
              signNumber: true,
            },
          })
        })
        .catch(e => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              signNumber: false,
            },
            isShow: !this.state.isShow,
            status: 'signNumber',
          })
        })
    }

    return this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        signNumber: false,
      },
      isShow: !this.state.isShow,
      dataList: this.props.signNumbers.list.items,
      status: 'signNumber',
      signNumberAttachFileList: [],
      filesThamKhaoTuKySo: [],
    })
  }

  handleShowDetailSignNumber = async (id, fileId, title, chucDanhId) => {
    let signNumberFile = null
    let signNumberId = ''
    let signNumberAttachFileList = []
    let isConvergeSignatureImport = false
    if (fileId) {
      await this.props.actions.getFileInformation(fileId).then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          signNumberFile = res.payload.data.result
        }
      })
    }

    await this.props.actions.getDsFileDinhKem(id).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items
      ) {
        signNumberAttachFileList = res.payload.data.result.items
      }
    })

    let dsQuyen = []
    let filesThamKhaoTuKySo = []
    if (title === 'SignNumber') {
      await this.props.actions.userPermissionDocument(chucDanhId).then(res => {
        if (
          res &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.items &&
          res.payload.data.result.items.length !== 0
        ) {
          dsQuyen = res.payload.data.result.items
        }
      })

      if (
        intersectionBy(dsQuyen, [{ maQuyen: 'HDTV_LAYYKIEN' }], 'maQuyen')
          .length !== 0
      ) {
        await this.props.actions.getFileThamKhaoTuKySo(id).then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.items
          ) {
            filesThamKhaoTuKySo = res.payload.data.result.items
          }
        })
      }
    }

    if (title === 'SignNumber') {
      signNumberId = id
    }

    if (title === 'ConvergeSignature') {
      signNumberId = id
      isConvergeSignatureImport = true
    }

    this.setState({
      isShowDetail: !this.state.isShowDetail,
      itemId: id,
      signNumberFile,
      signNumberAttachFileList,
      digitalFile: null,
      signNumberId,
      digitalId: null,
      isConvergeSignatureImport,
      isCheckCongVanKySo: true,
      filesThamKhaoTuKySo,
    })
  }

  handleShowDetailDigital(digitalDocumentId, fileName) {
    let digitalId = digitalDocumentId
    let digitalFile = null
    this.setState({ isLoadingFile: true })
    if (fileName && fileName.trim().length > 0) {
      this.props.actions
        .digitalDocumentsFetchItem(digitalDocumentId)
        .then(res => {
          if (res && res.payload && res.payload.data) {
            let objectUrl = URL.createObjectURL(res.payload.data)
            digitalFile = Tool.Utils.blobToFile(res.payload.data, fileName)
            this.setState({
              digitalFile,
              digitalFileURL: objectUrl,
              isLoadingFile: false,
            })
          } else {
            this.setState({ isLoadingFile: false, isFailLoadingFile: true })
          }
        })
        .catch(e => {
          this.setState({ isLoadingFile: false, isFailLoadingFile: true })
        })
    }

    this.setState({
      isShowDetail: !this.state.isShowDetail,
      itemId: digitalDocumentId,
      digitalId,
      signNumberFile: null,
      signNumberId: null,
      isConvergeSignatureImport: false,
      isCheckCongVanKySo: false,
    })
  }

  handleSubmit = async (msg, intent, isSuccess, soLuuId) => {
    if (isSuccess && isSuccess === true) {
      if (!this.props.config.refreshSendDocument) {
        this.props.actions.refreshSendDocument(true)
      }
      if (soLuuId) {
        Tool.Utils.saveData('soLuuId-congVanDi', soLuuId)
      }

      if (this.state.isUpdate) {
        await this.setState({ sendDocument: null })
        this.props.actions.commonAddToasterMessage({
          message: msg,
          intent: intent,
        })
        this.props.actions.refreshPage(true)
        return this.props.history.goBack()
      }

      // CHANGED: Tao thanh cong thi reset form
      let isLoadingData = {
        ...this.state.isLoadingData,
        soLuuId: true,
      }

      if (
        this.state.digitalId &&
        this.state.status === 'digital' &&
        !this.state.signNumberId
      ) {
        isLoadingData = {
          ...this.state.isLoadingData,
          digital: true,
        }
      }

      if (
        !this.state.digitalId &&
        this.state.status === 'signNumber' &&
        this.state.signNumberId
      ) {
        isLoadingData = {
          ...this.state.isLoadingData,
          signNumber: true,
        }
      }

      await this.setState({
        sendDocument: null,
        isSubmitSuccess: true,
        isShowDetail: false,
        isLoadingData,
        isLoadingSubmit: true,
      })

      // CHANGED: show message
      if (msg && intent) {
        await this.props.actions.commonAddToasterMessage({
          message: msg,
          intent: intent,
        })
      }

      // CHANGED: Goi lai api get soLuu
      await this.props.actions
        .commonFetchSaveCodes(Type.PAGE, UNITID, ISALL, Type.SAVECODE_SEND)
        .then(() => {
          let soLuuId = Tool.Utils.getData('soLuuId-congVanDi')
          if (this.props.saveCodes) {
            if (
              this.props.saveCodes.find(item => item.soLuuId === soLuuId) ===
              undefined
            ) {
              soLuuId = null
            }
          }
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              soLuuId: false,
            },
            soLuuId,
          })
        })

      if (
        this.state.digitalId &&
        this.state.status === 'digital' &&
        !this.state.signNumberId
      ) {
        // CHANGED: Load lai danh sach so hoa
        await this.props.actions
          .receiveDocumentFetchDigitalDocuments(Type.DIGITAL_SEND_DOCUMENT)
          .then(res => {
            let digitalDocuments = []
            let dataList = []
            if (
              res.payload &&
              res.payload.data &&
              res.payload.data.entities &&
              res.payload.data.entities.digitalDocuments
            ) {
              digitalDocuments = res.payload.data.entities.digitalDocuments
            }
            if (
              this.props.digitalDocuments &&
              this.props.digitalDocuments.list
            ) {
              dataList = this.props.digitalDocuments.list
            }
            this.setState({ digitalDocuments, dataList })
          })
          .finally(() => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                digital: false,
              },
            })
          })
      }

      if (
        this.state.signNumberId &&
        this.state.status === 'signNumber' &&
        !this.state.digitalId
      ) {
        // CHANGED: Load lai danh sach ky so
        await this.props.actions
          .releasedSignNumberGet(REFRESH_SIGN_NUMER)
          .then(res => {
            let signNumberDocuments = []
            let dataList = []
            if (
              res.payload &&
              res.payload.data &&
              res.payload.data.entities &&
              res.payload.data.entities.signNumbers
            ) {
              signNumberDocuments = res.payload.data.entities.signNumbers
            }
            if (
              this.props.signNumbers &&
              this.props.signNumbers.list &&
              this.props.signNumbers.list.items
            ) {
              dataList = this.props.signNumbers.list.items
            }
            this.setState({ signNumberDocuments, dataList })
          })
          .finally(() => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                signNumber: false,
              },
            })
          })
      }
    } else {
      await this.props.actions.commonAddToasterMessage({
        message: msg,
        intent: intent,
      })
    }
    this.setState({ isLoadingSubmit: false, isSubmitSuccess: false })
  }

  componentWillMount() {
    if (this.props.match.path === '/cong-van/cong-van-di/chinh-sua/:id') {
      this.setState({
        isUpdate: true,
        sendDocumentId: this.props.match.params.id,
      })
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          sendDocument: false,
        },
      })
    }
  }

  componentDidMount() {
    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }

    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }

    this.props.actions
      .commonFetchSaveCodes(Type.PAGE, UNITID, ISALL, Type.SAVECODE_SEND)
      .then(() => {
        let soLuuId = Tool.Utils.getData('soLuuId-congVanDi')
        if (this.props.saveCodes) {
          if (
            this.props.saveCodes.find(item => item.soLuuId === soLuuId) ===
            undefined
          ) {
            soLuuId = null
          }
        }
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            soLuuId: false,
          },
          soLuuId,
        })
      })

    let isShowAttachedUnits = true
    let isShowOtherUnits = true
    let isShowConnectedUnit = true
    let isShowConnectedUnitEVN = true
    if (this.props.auth && this.props.auth.mainUnitId) {
      // Thông tin đơn vị
      this.props.actions
        .getCommonFetchUnitItem(this.props.auth.mainUnitId)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            isShowAttachedUnits = res.payload.data.result.guiDonViTrucThuoc
            isShowOtherUnits = res.payload.data.result.guiDonViKhac
            isShowConnectedUnit = res.payload.data.result.guiDonViLienThong
            isShowConnectedUnitEVN =
              res.payload.data.result.guiDonViLienThongEvn
          }
          this.setState({
            isShowAttachedUnits,
            isShowOtherUnits,
            isShowConnectedUnit,
            isShowConnectedUnitEVN,
          })
        })

      // Đơn vị nhận
      this.props.actions
        .commonFetchReceiveUnits(this.props.auth.mainUnitId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              receiveUnits: false,
            },
          })
        })

      // Người ký
      this.props.actions.commonFetchSignUserList().then(() => {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            nguoiKyId: false,
          },
        })
      })

      // Đơn vị trực thuộc
      this.props.actions
        .commonFetchAttachedUnits(this.props.auth.mainUnitId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              attachedUnits: false,
            },
          })
        })
    }

    // Phòng ban
    this.props.actions.commonFetchDepartments().then(() => {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          phongBanLapId: false,
        },
      })
    })

    // Đơn vị khác
    if (isShowOtherUnits === true) {
      this.props.actions.getOtherUnit(this.props.auth.mainUnitId).then(() => {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            otherUnits: false,
          },
        })
      })
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          otherUnits: false,
        },
      })
    }

    // Đơn vị liên thông
    if (isShowConnectedUnit === true) {
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          connectedUnit: false,
        },
      })
    }

    // Đơn vị liên thông EVN
    if (isShowConnectedUnitEVN === true) {
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          connectedUnitEVN: false,
        },
      })
    }

    // Chi tiết công văn đi
    if (this.state.sendDocumentId) {
      this.props.actions
        .sendDocumentDetailItem(this.state.sendDocumentId)
        .then(res => {
          let state = this.state
          if (res.payload && res.payload.data && res.payload.data.result) {
            state.sendDocument = res.payload.data.result
            state.isLoadingData.sendDocument = false
          }
          this.setState(state)
        })
    }
  }

  componentWillUnmount() {
    this.setState({ digitalDocuments: [], signNumberDocuments: [] })
  }

  onDocumentComplete = pages => {
    this.setState({ page: 1, pages })
  }

  onPageComplete = page => {
    this.setState({ page })
  }

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 })
  }

  handleNext = () => {
    this.setState({ page: this.state.page + 1 })
  }

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className="btn-pager previous" onClick={this.handlePrevious}>
        <span className="click-renderPagination pt-icon pt-icon-circle-arrow-left"></span>
      </li>
    )
    if (page === 1) {
      previousButton = (
        <li className="btn-pager previous disabled">
          <span className="click-renderPagination pt-icon pt-icon-circle-arrow-left"></span>
        </li>
      )
    }
    let nextButton = (
      <li className="btn-pager next" onClick={this.handleNext}>
        <span className="click-renderPagination pt-icon pt-icon-circle-arrow-right"></span>
      </li>
    )
    if (page === pages) {
      nextButton = (
        <li className="btn-pager next disabled">
          <span className="click-renderPagination pt-icon pt-icon-circle-arrow-right"></span>
        </li>
      )
    }
    return (
      <nav>
        <ul className="pager paper-viewfile">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    )
  }

  handleSubmitRemoveItem(id) {
    if (id) {
      this.setState(
        {
          isLoadingData: {
            ...this.state.isLoadingData,
            digital: true,
          },
        },
        () => {
          this.props.actions.deleteDigitizingItem(id).then(res => {
            if (res.payload && res.payload.data.result) {
              this.props.actions
                .receiveDocumentFetchDigitalDocuments(
                  Type.DIGITAL_SEND_DOCUMENT
                )
                .then(res => {
                  let digitalDocuments = []
                  let dataList = []
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.entities &&
                    res.payload.data.entities.digitalDocuments
                  ) {
                    digitalDocuments =
                      res.payload.data.entities.digitalDocuments
                  }
                  if (
                    this.props.digitalDocuments &&
                    this.props.digitalDocuments.list
                  ) {
                    dataList = this.props.digitalDocuments.list
                  }
                  this.setState({ digitalDocuments, dataList })
                })
                .then(() => {
                  this.setState({
                    isLoadingData: {
                      ...this.state.isLoadingData,
                      digital: false,
                    },
                  })
                })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            }
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
              intent: Intent.DANGER,
            })
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                digital: false,
              },
            })
          })
        }
      )
    }
  }

  render() {
    // let pagination = null;
    // if (this.state.pages) {
    //   pagination = this.renderPagination(this.state.page, this.state.pages);
    // }

    // Nếu donVi.getDOnViTrucThuoc === false
    // => gọp ds đơn vị trực thuộc vào ds đơn vị nhận
    // và không hiển thị đơn vị trực thuộc
    let receiveUnits = this.props.receiveUnits
    if (this.state.isShowAttachedUnits === false) {
      receiveUnits = concat(this.props.receiveUnits, this.props.attachedUnits)
    }
    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="form-input-receive-document">
          <div
            className={classnames({
              'detail-receive-document': this.state.isShowDetail,
            })}
          >
            {this.state.isShow && (
              <div className="heading-page">
                <h3 className="text-heading-page">
                  <span className="icon-Nhap_cong_van_di icon-heading"></span>
                  <span className="text-heading">Nhập Công Văn đi</span>
                </h3>
              </div>
            )}
            {!this.state.isShowDetail && (
              <div
                className={classnames('row', {
                  'center-xs': !this.state.isShow,
                  'format-center': !this.state.isShow,
                })}
              >
                <div
                  className={classnames({
                    'col-xs-12 col-sm-12 col-md-7 padding-0':
                      this.state.isShow && !this.state.isShowDetail,
                    'col-xs-12 col-sm-12 col-md-9 form-width-90 padding-0':
                      !this.state.isShow && !this.state.isShowDetail,
                  })}
                >
                  {!this.state.isShow && (
                    <div className="heading-page bottom-add-receive-document">
                      <h3 className="pull-left text-heading-page">
                        <span className="icon-Nhap_cong_van_di icon-heading"></span>
                        <span className="text-heading">
                          {this.state.isUpdate
                            ? 'Sửa công văn đi'
                            : 'Nhập công văn đi'}
                        </span>
                      </h3>
                      <div className="pull-right group-button-head">
                        {!this.state.isUpdate && (
                          <Button
                            type="submit"
                            onClick={this.handleSignNumberChange}
                            className="btn-digitizing-cvd"
                            disabled={
                              (this.state.isLoadingData &&
                                this.state.isLoadingData.signNumber === true) ||
                              this.state.isLoadingSubmit === true
                            }
                          >
                            Nhập từ ký số
                          </Button>
                        )}
                        {!this.state.isUpdate &&
                          Type.WEB_NAME !== 'Darsitec' && (
                            <Button
                              type="submit"
                              onClick={this.handleDigitalChange}
                              className="btn-digitizing-cvd"
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData.digital === true) ||
                                this.state.isLoadingSubmit === true
                              }
                            >
                              Nhập từ số hoá
                            </Button>
                          )}
                      </div>
                    </div>
                  )}

                  <InputForm
                    isShow={this.state.isShow}
                    objects={this.props.objects}
                    saveCodes={this.props.saveCodes}
                    priorities={this.props.priorities}
                    securities={this.props.securities}
                    receiveUnits={receiveUnits}
                    departments={this.props.departments}
                    attachedUnits={this.props.attachedUnits}
                    otherUnits={
                      this.props.otherUnits && this.props.otherUnits.items
                        ? this.props.otherUnits.items
                        : []
                    }
                    connectedUnit={
                      this.props.connectedUnit && this.props.connectedUnit.items
                        ? this.props.connectedUnit.items
                        : []
                    }
                    connectedUnitEVN={
                      this.props.connectedUnitEVN &&
                      this.props.connectedUnitEVN.items
                        ? this.props.connectedUnitEVN.items
                        : []
                    }
                    actions={this.props.actions}
                    onSubmit={this.handleSubmit}
                    onLoadSubmit={this.handleLoadSubmit}
                    soLuuId={this.state.soLuuId}
                    digitalId={this.state.digitalId}
                    signNumberId={this.state.signNumberId}
                    signUserList={this.props.signUserList}
                    signNumberDocuments={this.state.signNumberDocuments}
                    digitalDocuments={this.state.digitalDocuments}
                    ksttDocuments={this.state.ksttDocuments.find(
                      item => item.vanBanKySoId === this.state.itemId
                    )}
                    isUpdate={this.state.isUpdate}
                    sendDocumentId={this.state.sendDocumentId}
                    sendDocument={this.state.sendDocument}
                    isLoadingData={this.state.isLoadingData}
                    digitalFile={this.state.digitalFile}
                    signNumberFile={
                      this.state.signNumberFile &&
                      Tool.Utils.linkObjectKey(this.state.signNumberFile)
                    }
                    mainUnitId={this.props.auth && this.props.auth.mainUnitId}
                    parentUnitId={
                      this.props.auth && this.props.auth.parentUnitId
                    }
                    readOnly={this.props.readOnly}
                    isSubmitSuccess={this.state.isSubmitSuccess}
                    isShowAttachedUnits={this.state.isShowAttachedUnits}
                    isShowOtherUnits={this.state.isShowOtherUnits}
                    isShowConnectedUnit={this.state.isShowConnectedUnit}
                    isShowConnectedUnitEVN={this.state.isShowConnectedUnitEVN}
                    isAssign={
                      this.state.sendDocument &&
                      this.state.sendDocument.daChiDao
                    }
                    templateData={this.state.templateData}
                    auth={this.props.auth}
                    leaderDepartments={this.props.leaderDepartments}
                    status={this.state.status}
                    quyensMenu={this.props?.quyensMenu || []}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-5">
                  {this.state.isLoadingData &&
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined &&
                    (!this.state.sendDocumentId ||
                      this.state.sendDocumentId.trim().length === 0) &&
                    this.state.status && (
                      <div className="loading-container">
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                      </div>
                    )}
                  {(!this.state.isLoadingData ||
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) === undefined) &&
                    this.state.isShow &&
                    this.state.sendDocumentId.trim().length === 0 &&
                    this.state.status === 'signNumber' && (
                      <SignNumberList
                        sizeHeight={1107}
                        list={this.state.dataList}
                        onClickHidden={this.handleSignNumberChange}
                        onClickShowDetail={this.handleShowDetailSignNumber}
                      />
                    )}
                  {(!this.state.isLoadingData ||
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) === undefined) &&
                    this.state.isShow &&
                    this.state.sendDocumentId.trim().length === 0 &&
                    this.state.status === 'digital' && (
                      <DigitizingList
                        sizeHeight={1107}
                        list={this.state.dataList}
                        onClickHidden={this.handleDigitalChange}
                        onClickShowDetail={this.handleShowDetailDigital}
                        onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                      />
                    )}
                  {this.state.isShow &&
                    this.state.status === 'convergeSignature' && (
                      <ConvergeSignatureList
                        sizeHeight={1107}
                        list={this.state.convergeSignatureDataList}
                        onClickShowDetail={this.handleShowDetailSignNumber}
                      />
                    )}
                </div>
              </div>
            )}

            {this.state.isShowDetail && (
              <div className="detail-container">
                <div className="row">
                  <div
                    className={classnames('box-left', {
                      'box-left-addSignNumber': this.state.isShowDetail,
                    })}
                  >
                    <div className="pd-none">
                      {/* Nhap cong van di tu ky so */}
                      <InputForm
                        isShow={this.state.isShow}
                        objects={this.props.objects}
                        saveCodes={this.props.saveCodes}
                        priorities={this.props.priorities}
                        securities={this.props.securities}
                        receiveUnits={receiveUnits}
                        departments={this.props.departments}
                        attachedUnits={this.props.attachedUnits}
                        otherUnits={
                          this.props.otherUnits && this.props.otherUnits.items
                            ? this.props.otherUnits.items
                            : []
                        }
                        connectedUnit={
                          this.props.connectedUnit &&
                          this.props.connectedUnit.items
                            ? this.props.connectedUnit.items
                            : []
                        }
                        connectedUnitEVN={
                          this.props.connectedUnitEVN &&
                          this.props.connectedUnitEVN.items
                            ? this.props.connectedUnitEVN.items
                            : []
                        }
                        actions={this.props.actions}
                        onSubmit={this.handleSubmit}
                        onLoadSubmit={this.handleLoadSubmit}
                        soLuuId={this.state.soLuuId}
                        digitalId={this.state.digitalId}
                        signNumberId={this.state.signNumberId}
                        signUserList={this.props.signUserList}
                        signNumberDocuments={this.state.signNumberDocuments}
                        digitalDocuments={this.state.digitalDocuments}
                        ksttDocuments={this.state.ksttDocuments.find(
                          item => item.vanBanKySoId === this.state.itemId
                        )}
                        isUpdate={this.state.isUpdate}
                        sendDocumentId={this.state.sendDocumentId}
                        sendDocument={this.state.sendDocument}
                        isLoadingData={this.state.isLoadingData}
                        digitalFile={this.state.digitalFile}
                        signNumberFile={
                          this.state.signNumberFile &&
                          Tool.Utils.linkObjectKey(this.state.signNumberFile)
                        }
                        mainUnitId={
                          this.props.auth && this.props.auth.mainUnitId
                        }
                        parentUnitId={
                          this.props.auth && this.props.auth.parentUnitId
                        }
                        readOnly={this.props.readOnly}
                        isSubmitSuccess={this.state.isSubmitSuccess}
                        isShowAttachedUnits={this.state.isShowAttachedUnits}
                        isShowOtherUnits={this.state.isShowOtherUnits}
                        isShowConnectedUnit={this.state.isShowConnectedUnit}
                        isShowConnectedUnitEVN={
                          this.state.isShowConnectedUnitEVN
                        }
                        isAssign={
                          this.state.sendDocument &&
                          this.state.sendDocument.daChiDao
                        }
                        templateData={this.state.templateData}
                        auth={this.props.auth}
                        checkCongVanKySo={this.state.isCheckCongVanKySo}
                        leaderDepartments={this.props.leaderDepartments}
                        status={this.state.status}
                        filesThamKhaoTuKySo={this.state.filesThamKhaoTuKySo}
                        quyensMenu={this.props?.quyensMenu || []}
                      />
                    </div>
                  </div>
                  <div
                    className={classnames('box-right', {
                      'box-right-addSignNumber': this.state.isShowDetail,
                    })}
                  >
                    <div className="viewfile-digitizing-container">
                      <div className="container-form-action">
                        <div className="heading-goback">
                          <span
                            className="left-heading-goback"
                            onClick={this.handleClickGoBack}
                          >
                            <span className="pt-icon pt-icon-arrow-left"></span>
                            <span className="text-back">Trở lại danh sách</span>
                          </span>
                        </div>
                        {/* View file ky so */}
                        {this.state.signNumberAttachFileList &&
                          this.state.signNumberAttachFileList.length > 0 && (
                            <DetailFileDinhKem
                              receiveDocument={{
                                fileDinhKem:
                                  this.state.signNumberAttachFileList,
                              }}
                              auth={this.props.auth}
                            />
                          )}
                        {Tool.Utils.checkBanTongHop(
                          this.props.auth.mainDepartmentId
                        ) &&
                          this.state.filesThamKhaoTuKySo &&
                          this.state.filesThamKhaoTuKySo.length !== 0 && (
                            <div className="pt10 pl20 pb10 pr20">
                              <label className="pt-label label-text-detail">
                                File tham khảo (
                                {this.state.filesThamKhaoTuKySo.length}):
                              </label>
                              <div className="pt-form-content">
                                <div className="ml10">
                                  <FileList
                                    auth={this.props.auth}
                                    receiveDocument={{
                                      filesThamKhao:
                                        this.state.filesThamKhaoTuKySo || [],
                                    }}
                                    field="filesThamKhao"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.signNumberFile &&
                          this.state.signNumberId &&
                          this.state.signNumberId.length > 0 && (
                            <DetailFileContent
                              file={
                                this.state.signNumberFile
                                  ? this.state.signNumberFile
                                  : this.state.fileDefault
                              }
                              actions={this.props.actions}
                              ref={this.refHandlers.detailFileContent}
                              auth={this.props.auth}
                            />
                          )}

                        {/* View file so hoa */}
                        {this.state.isLoadingFile && (
                          <div className="loader-line"></div>
                        )}
                        {this.state.digitalFileURL &&
                          this.state.digitalId &&
                          this.state.digitalId.toString().length > 0 && (
                            <div className="iframe-container">
                              <div className="embed-container">
                                <iframe
                                  frameBorder="0"
                                  scrolling="no"
                                  src={this.state.digitalFileURL}
                                  title="File"
                                />
                              </div>
                            </div>
                          )}
                        {!this.state.isLoadingFile &&
                          this.state.isFailLoadingFile &&
                          this.state.digitalId &&
                          this.state.digitalId.toString().length > 0 && (
                            <div
                              className="cpc-error"
                              style={{ height: '300px' }}
                            >
                              <div className="cpc-text">
                                Có lỗi: không thể tải tài liệu.
                              </div>
                            </div>
                          )}

                        <div className="list-digitizing detail-digitizing">
                          {this.state.status === 'signNumber' && (
                            <SignNumberItem
                              item={
                                this.state.signNumberDocuments[
                                  this.state.itemId
                                ]
                              }
                              onClickShowDetail={
                                this.handleShowDetailSignNumber
                              }
                            />
                          )}
                          {this.state.status === 'digital' && (
                            <DigitizingItem
                              item={
                                this.state.digitalDocuments[this.state.itemId]
                              }
                              onClickShowDetail={this.handleShowDetailDigital}
                              onHandleSubmitRemoveItem={
                                this.handleSubmitRemoveItem
                              }
                            />
                          )}
                          {this.state.status === 'convergeSignature' && (
                            <ConvergeSignatureItem
                              item={this.state.ksttDocuments.find(
                                item => item.vanBanKySoId === this.state.itemId
                              )}
                              onClickShowDetail={
                                this.handleShowDetailSignNumber
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  config: state.common.config,

  objects: state.common.objects,

  securities: state.common.securities,

  priorities: state.common.priorities,

  saveCodes: state.common.saveCodes,

  departments: state.common.departments,

  digitalDocuments: {
    ...state.digitalDocuments,
    list: state.digitalDocuments.list.items.map(
      id => state.entities.digitalDocuments[id]
    ),
  },

  signUserList: state.common.signUserList,

  signNumbers: {
    ...state.signNumbers,
    list: {
      ...state.signNumbers.list,
      items: state.signNumbers.list.items.map(
        id => state.entities.signNumbers[id]
      ),
    },
  },

  sendDocuments: {
    ...state.sendDocuments,
    item: state.sendDocuments.detailId
      ? state.entities.sendDocuments[state.sendDocuments.detailId]
      : {},
  },

  attachedUnits: state.common.attachedUnits,

  otherUnits: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
  connectedUnit: {
    items: state.category.categoryConnectedUnit,
  },
  connectedUnitEVN: {
    items: state.category.categoryConnectedUnitEVN,
  },
  receiveUnits: state.common.receiveUnits,

  readOnly: state.common.enableReadOnly,
  leaderDepartments: state.common.leaderDepartments,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputSendDocumentPage)
