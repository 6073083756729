import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@blueprintjs/core'
import { Authorization } from '../auth'
import classnames from 'classnames'
import {
  MESSAGE_NOT_FOUND,
  MESSAGE_ERROR,
  MESSAGE_REQUIRED_STATISTICS,
} from '../../constants/MessageForm'

function handleStyle(options = []) {
  let data = {}
  for (var i = 0; i <= options.length - 1; i++) {
    data[options[i]['type']] = options[i]['value']
  }
  return data
}

class GridView extends Component {
  constructor(props) {
    super(props)
    this.handleCheckall = this.handleCheckall.bind(this)
    this.state = {
      isEnabledAll: this.props.isEnabledAll,
      sort: {},
      isLoading: props.isLoading !== undefined ? props.isLoading : true,
    }
  }

  static propTypes = {
    classNames: PropTypes.string,
    headers: PropTypes.array.isRequired,
    itemShape: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    style: PropTypes.array,
    match: PropTypes.object,
    page: PropTypes.number.isRequired,
    type: PropTypes.any,
    onCheckAll: PropTypes.func,
    onClickComplete: PropTypes.func,
    onClickDetail: PropTypes.func,
    isEmpty: PropTypes.bool,
    auth: PropTypes.object,
  }

  static defaultProps = {
    checkList: {},
  }

  componentWillReceiveProps(props) {
    if (props.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: props.isLoading,
      })
    }
  }

  handleClickSort(i) {
    let state = this.state
    for (var v in state.sort) {
      if (v === i) {
        continue
      }

      delete state.sort[v]
    }

    state.sort[i] = !state.sort[i] || state.sort[i] === 'ASC' ? 'DESC' : 'ASC'
    this.setState(state, () => {
      let sort

      for (let sortField in this.state.sort) {
        sort = `${sortField} ${this.state.sort[sortField]}`
      }

      this.props.onFilter && this.props.onFilter(sort)
    })
  }

  handleCheckall = e => {
    this.props.handleCheckAll && this.props.handleCheckAll(e)
  }

  render() {
    let imageUrl = process.env.PUBLIC_URL + '/images/empty-default.png'
    if (this.props.isError === true) {
      imageUrl = process.env.PUBLIC_URL + '/images/Server.png'
    }
    if (this.props.isShowMessengStatistics === true) {
      imageUrl = process.env.PUBLIC_URL + '/images/calendar.png'
    }
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    return (
      <div
        className={classnames('table-scroll', {
          'table-scroll-hidden':
            this.props.isEmpty === true ||
            this.props.isError === true ||
            this.props.isShowMessengStatistics === true,
        })}
      >
        <table className={this.props.classNames}>
          {this.props.headers.length > 0 && (
            <thead>
              <tr>
                {this.props.headers.map((header, i) => {
                  let itemStyle = handleStyle(header.style)
                  if (header.classNames.length > 0) {
                    return (
                      <Authorization.Element
                        key={i}
                        permission={header.permission || []}
                      >
                        <th className={header.classNames} style={itemStyle}>
                          <span className="sort-colum">
                            <span className="text-sort">
                              {this.props.headerName &&
                              this.props.headerName[header.valueCustom]
                                ? this.props.headerName[header.valueCustom]
                                : header.value}
                            </span>
                            {header.sort && (
                              <span
                                className={`pt-icon ${
                                  this.state.sort[header.sort] &&
                                  this.state.sort[header.sort] === 'DESC'
                                    ? 'pt-icon-chevron-down'
                                    : 'pt-icon-chevron-up'
                                }`}
                                onClick={this.handleClickSort.bind(
                                  this,
                                  header.sort
                                )}
                              ></span>
                            )}
                          </span>
                          {header.checkAll ? (
                            <Authorization.Element
                              permission={this.props.actionPermissions}
                            >
                              <Checkbox
                                onChange={this.handleCheckall.bind(this)}
                                checked={this.props.isCheckAll}
                              />
                            </Authorization.Element>
                          ) : null}
                        </th>
                      </Authorization.Element>
                    )
                  }

                  return (
                    <Authorization.Element
                      key={i}
                      permission={header.permission || []}
                    >
                      <th key={i} style={itemStyle}>
                        <span className="text-sort">
                          <span className="text-sort">
                            {this.props.headerName &&
                            this.props.headerName[header.valueCustom]
                              ? this.props.headerName[header.valueCustom]
                              : header.value}
                          </span>
                          {header.sort && (
                            <span
                              className={`pt-icon ${
                                this.state.sort[header.sort] &&
                                this.state.sort[header.sort] === 'DESC'
                                  ? 'pt-icon-chevron-down'
                                  : 'pt-icon-chevron-up'
                              }`}
                              onClick={this.handleClickSort.bind(
                                this,
                                header.sort
                              )}
                            ></span>
                          )}
                        </span>
                        {header.checkAll ? (
                          <Authorization.Element
                            permission={this.props.actionPermissions}
                          >
                            <Checkbox
                              onChange={this.handleCheckall.bind(this)}
                              checked={this.props.isCheckAll}
                            />
                          </Authorization.Element>
                        ) : null}
                      </th>
                    </Authorization.Element>
                  )
                })}
              </tr>
            </thead>
          )}
          {this.state.isLoading ? (
            <tbody>
              <tr className="table-loading">
                <td
                  className="colum-loading"
                  colSpan={this.props.headers.length}
                >
                  {ItemLoading}
                  {ItemLoading}
                  {ItemLoading}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {!this.props.isShowMessengStatistics &&
                !this.props.isEmpty &&
                !this.props.isError &&
                this.props.data &&
                this.props.data.length > 0 &&
                this.props.data.map((item, i) => {
                  if (!item) {
                    return null
                  }
                  let check = false

                  if (
                    item.id &&
                    this.props.checkList &&
                    this.props.checkList[item.id]
                  ) {
                    check = true
                  }

                  if (
                    item.congVanDenId &&
                    this.props.checkList &&
                    this.props.checkList[item.congVanDenId]
                  ) {
                    check = true
                  }

                  if (
                    this.props.listCheckIds &&
                    this.props.listCheckIds.findIndex(id => id === item.id) !==
                      -1
                  ) {
                    check = true
                  }

                  return (
                    <this.props.itemShape
                      type={this.props.type ? this.props.type : ''}
                      item={item}
                      number={(this.props.page - 1) * 10 + i + 1}
                      key={i}
                      onHandleSubmitRemoveItem={
                        this.props.onHandleSubmitRemoveItem
                      }
                      match={this.props.match}
                      onCheck={this.props.onCheck}
                      list={this.props.list}
                      checked={check}
                      onClickDetail={this.props.onClickDetail}
                      mainDepartmentId={
                        this.props.mainDepartmentId
                          ? this.props.mainDepartmentId
                          : null
                      }
                      onClickComplete={this.props.onClickComplete}
                      auth={this.props.auth}
                      history={this.props.history}
                      actions={this.props.actions}
                      filter={this.props.filter}
                      phongBanId={
                        this.props.phongBanId ? this.props.phongBanId : null
                      }
                      handleCheckBox={this.props.handleCheckBox}
                      actionPermissions={this.props.actionPermissions}
                      isShowCheckBox={this.props.isShowCheckBox}
                      onHandleSubmitUpdateItem={
                        this.props.onHandleSubmitUpdateItem
                      }
                    />
                  )
                })}

              {this.props.isEmpty === true ||
              this.props.isError === true ||
              this.props.isShowMessengStatistics === true ? (
                <tr className="no-border-bt">
                  <td
                    colSpan={this.props.headers.length}
                    className="error-table-hover"
                  >
                    <div className="error-page-container">
                      <div className="error-content">
                        <img
                          src={imageUrl}
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">
                          {this.props.isShowMessengStatistics
                            ? MESSAGE_REQUIRED_STATISTICS
                            : this.props.isError === true
                            ? MESSAGE_ERROR
                            : MESSAGE_NOT_FOUND}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          )}
        </table>
      </div>
    )
  }
}

export default GridView
