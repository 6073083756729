import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Icon,
  Classes,
  Intent,
  FormGroup,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import {
  get,
  toPairs,
  size,
  isEqual,
  differenceBy,
  uniqBy,
  isEmpty,
  isString,
} from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

import { Permission } from 'components/auth'
import {
  Card,
  Collapse,
  Row,
  Col,
  RemindSelect,
  Upload,
  Input,
  Select,
  CheckboxSelect,
  FileGroup,
} from 'components/newCommon'
import { FormDatePicker, DateAdvanced } from 'components/newCommon2'
import {
  saveTree,
  getMyFavoriteGroups,
  getPhongBanNhanVien,
  postCongViec,
  getDsTag,
  postUpload,
} from 'actions/task'
import { Action, Toast, ValidateForm } from 'constants/MessageForm'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import { priorities } from 'constants/task'
import { UNSET, WORK_TYPE } from 'constants/Enum'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { RESPONSIBILITY_TYPE, REFERENCE_TYPE } from 'constants/Enum'
import TemplateDialog from 'containers/pages/TaskManager/Form/_components/Templates'
import PhongBanVaDonViThucHien from 'containers/pages/TaskManager/Form/_components/PhongBanVaDonViThucHien'

const convertFileNewProps = file => ({
  id: file.fileId,
  name: file.tenFile,
  mime: file.kieuFile,
  size: file.kichThuoc,
  url: file.url,
  showClose: false,
})

const TaskCreate = ({
  roleId,
  hasShowAll,
  mainDepartmentId,
  documentDetail,
  history,
  mainUnitId,
  dataAutoToggleDefault,
  dataTreeStore,
  isNotWork = false,
  goBack = () => {},
}) => {
  const TEMPLATE_TITLE = 'Chọn từ mẫu có sẵn'
  const dateTimeRequest = 'YYYY-MM-DD HH:mm'
  const dateRequest = 'YYYY-MM-DD'
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [showExtra, setShowExtra] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [files, setFiles] = useState([])
  const [tags, setTags] = useState([])
  const [showTemplate, setShowTemplate] = useState(false)
  const [favoriteGroups, setFavoriteGroups] = useState([])
  const [dataAutoToggle, setDataAutoToggle] = useState([])

  // Ẩn/hiện danh sách mẫu đã lưu
  const toggleShowTemplate = () => {
    mountedSet(setShowTemplate, !showTemplate)
  }

  const toggleCollapse = () => {
    mountedSet(setShowExtra, !showExtra)
  }

  const _changeFormValue = useCallback(({ name, value, error }) => {
    mountedSet(setFormData, prevFormData => ({
      ...prevFormData,
      [name]: {
        value,
        error,
      },
    }))
  }, [])

  const changeField = name => value => {
    _changeFormValue({ name, value })
  }

  const changeFile = fileList => {
    mountedSet(setFiles, fileList)
  }

  const removeFile = removeFile => {
    if (removeFile.id) {
      mountedSet(setFormData, prevForm => ({
        ...prevForm,
        files: prevForm.files.filter(file => !isEqual(file, removeFile)),
      }))
    } else {
      mountedSet(
        setFiles,
        files.filter(elm => elm.uid !== removeFile.uid)
      )
    }
  }

  const _validateRequired = (name, key) => {
    const valueName = get(
      formData,
      key ? `${name}.${key}` : `${name}.value`,
      ''
    )
    if (isEmpty(valueName) || (isString(valueName) && !valueName.trim())) {
      _changeFormValue({ name, error: ValidateForm.IS_REQUIRED })
      return false
    }

    return true
  }

  const _validateTitle = () => {
    return _validateRequired('title')
  }

  const _validate = () => {
    let success = true
    let isHasChuTri = true
    if (!_validateTitle()) {
      success = false
    }

    if (!_validateRequired(CHU_TRI, 'id')) {
      isHasChuTri = false
    }

    if (!success || !isHasChuTri) {
      throw new Error('warning')
    }
  }

  const _upload = async () => {
    if (!size(files)) {
      return []
    }

    let responseFiles = []
    const success = await requestAction({
      action: () => postUpload(files),
      afterResponse: (result = []) => {
        responseFiles = result.map(file => file.id)
      },
      showToastSucess: false,
      codeCheck: false,
      errorMessage: Toast.FAIL(Action.UPLOAD),
    })

    if (!success) {
      throw new Error()
    }

    return responseFiles
  }

  const _convertDataType = (values, type) => ({
    responsibilityId: get(values, 'id'),
    assignType: get(values, 'type'),
    responsibilityType: type,
  })

  const _getData = async () => {
    const newData = {
      title: get(formData, 'title.value', '').trim(),
      workUserTags: get(formData, 'tags.value', []).map(item => ({
        workTagId: item,
      })),
      description: get(formData, 'description.value'),
      priority:
        get(formData, 'priority.value') !== UNSET
          ? get(formData, 'priority.value')
          : undefined,
      referenceFileIds: (get(formData, 'referenceFile')
        ? [get(formData, 'referenceFile')]
        : []
      ).map(file => file.id),
      isSaveTemplate: get(formData, 'isSaveTemplate.value', false),
      requireReport: formData?.requireReport?.value || false,
    }

    if (documentDetail?.congViecPhongBanId) {
      newData.referenceId = documentDetail.congViecPhongBanId
      newData.workType = WORK_TYPE.EOFFICE
      newData.referenceType = REFERENCE_TYPE.CONG_VIEC
    } else if (documentDetail?.congVanId) {
      newData.referenceId = documentDetail.congVanId
      newData.workType = WORK_TYPE.EOFFICE
      newData.referenceType = REFERENCE_TYPE.CONG_VAN_DEN
    } else if (documentDetail?.kySoId) {
      newData.referenceId = documentDetail.kySoId
      newData.workType = WORK_TYPE.EOFFICE
      newData.referenceType = REFERENCE_TYPE.CONG_VAN_KY_SO
    } else if (documentDetail?.kySoMoRongId) {
      newData.referenceId = documentDetail.kySoMoRongId
      newData.workType = WORK_TYPE.EOFFICE
      newData.referenceType = REFERENCE_TYPE.KY_SO_MO_RONG
    } else if (documentDetail?.kySoTapTrungId) {
      newData.referenceId = documentDetail.kySoTapTrungId
      newData.workType = WORK_TYPE.EOFFICE
      newData.referenceType = REFERENCE_TYPE.KY_SO_TAP_TRUNG
    }

    const deadline = get(formData, 'deadline.value')
    if (deadline) {
      newData.deadline = moment(deadline).startOf('day').format(dateRequest)
    }

    const executionDate = get(formData, 'executionDate.value')
    if (executionDate) {
      newData.executionDate = moment(executionDate).format(dateRequest)
    }

    const reminder = get(formData, 'reminder.value')
    if (reminder) {
      newData.workReminder = {
        type: reminder.type,
        reminderTime: moment(reminder.date).format(dateTimeRequest),
      }
    }

    const executor = get(formData, CHU_TRI)
      ? [
          {
            responsibilityId: get(formData, `${CHU_TRI}.id`),
            assignType: get(formData, `${CHU_TRI}.type`),
            responsibilityType: RESPONSIBILITY_TYPE.CHU_TRI,
          },
        ]
      : []
    const coordination = get(formData, PHOI_HOP, [])?.map(elm =>
      _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
    )
    const watcher = get(formData, THEO_DOI, [])?.map(elm =>
      _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
    )

    newData.workAssignments = [...executor, ...coordination, ...watcher]
    const attachmentFileIds = await _upload()
    newData.attachmentFileIds = [
      ...get(formData, 'attachmentFilesFromDocument').map(file => file.id),
      ...attachmentFileIds,
    ]

    return [toDecamelizeKeys(newData)]
  }

  const createTask = e => {
    requestAction({
      logError: true,
      beforeAction: () => {
        mountedSet(setSubmitLoading, true)
        _validate()
      },
      action: async () => {
        const data = await _getData()
        return postCongViec(data)
      },
      afterResponse: () => {
        if (isNotWork) {
          return goBack(e)
        }

        return history.push('/cong-viec/phong-ban/danh-sach/chua-giao')
      },
      afterAction: () => mountedSet(setSubmitLoading, false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => mountedSet(setTags, items),
    })
  }, [])

  const _setFormDataFromDocument = useCallback(() => {
    if (!documentDetail) {
      return
    }

    const { trichYeu, hanGiaiQuyet, fileThamKhao, fileDinhKemEvn } =
      documentDetail
    mountedSet(setFormData, prevFormData => ({
      ...prevFormData,
      title: { value: trichYeu },
      executionDate: {
        value: moment(),
      },
      deadline: hanGiaiQuyet ? moment(hanGiaiQuyet) : moment().add(7, 'days'),
      allowChangeDeadline: hanGiaiQuyet
        ? moment(hanGiaiQuyet) > moment()
        : true,
      maxDeadline: hanGiaiQuyet ? moment(hanGiaiQuyet) : null,
      referenceFile: fileThamKhao && convertFileNewProps(fileThamKhao),
      attachmentFilesFromDocument: (fileDinhKemEvn || []).map(
        convertFileNewProps
      ),
    }))
  }, [documentDetail])

  const onChangeRadio = useCallback(
    values => {
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      setFormData(prev => ({
        ...prev,
        [CHU_TRI]: values,
        [PHOI_HOP]: !isPhongBanDonVi
          ? prev?.[PHOI_HOP]?.filter(e => e.id !== values?.id)
          : isPhongBanDonVi
          ? prev?.[PHOI_HOP]?.filter(
              e => e.parentId === values?.id && !e.isLanhDaoPhongBan
            )
          : prev?.[PHOI_HOP],
        [THEO_DOI]: !isPhongBanDonVi
          ? prev?.[THEO_DOI]?.filter(e => e.id !== values?.id)
          : isPhongBanDonVi
          ? prev?.[THEO_DOI]?.filter(
              e => e.parentId === values?.id && !e.isLanhDaoPhongBan
            )
          : prev?.[THEO_DOI],
      }))
    },
    [setFormData]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, event, isIndeterminate) => {
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      let treeItem = []
      if (isPhongBanDonVi) {
        treeItem =
          dataTreeStore?.find(e => e?.id === values?.id)?.children || []
        setDataAutoToggle([values?.id])
      }

      setFormData(prev => {
        let newData = []
        let oldData = prev?.[name] || []
        newData = [...oldData, values]
        if (isPhongBanDonVi) {
          if (!isChecked) {
            newData = differenceBy(newData || [], [...treeItem, values], 'id')
          } else {
            if (!hasShowAll) {
              newData = [...newData, ...(treeItem || [])]
              newData = differenceBy(newData || [], [values], 'id')
            }
          }

          const dataChuTri = prev?.[CHU_TRI] ? [prev?.[CHU_TRI]] : []
          const dataPhoiHop = prev?.[PHOI_HOP] || []
          const dataTheoDoi = prev?.[THEO_DOI] || []
          if (name === PHOI_HOP && !hasShowAll) {
            newData = differenceBy(
              newData,
              [...dataChuTri, ...dataTheoDoi],
              'id'
            )
          } else if (name === THEO_DOI && !hasShowAll) {
            newData = differenceBy(
              newData,
              [...dataChuTri, ...dataPhoiHop],
              'id'
            )
          }

          if (
            (name === PHOI_HOP || name === THEO_DOI) &&
            size(dataChuTri) !== 0 &&
            dataChuTri?.some(
              e =>
                e?.id === values?.id &&
                (e?.type === PHONG_BAN || e?.type === DON_VI)
            )
          ) {
            newData = newData?.filter(
              e =>
                e.parentId !== values?.id ||
                (e.parentId === values?.id && !e?.isLanhDaoPhongBan)
            )
          }
        } else {
          if (!isChecked) {
            newData = differenceBy(newData || [], [values], 'id')
          }
        }

        newData = differenceBy(newData, [{ id: roleId }], 'id')

        const chuTri =
          name !== CHU_TRI && prev?.[CHU_TRI]?.id === values?.id
            ? null
            : prev?.[CHU_TRI]
        const phoiHop =
          name === PHOI_HOP
            ? uniqBy(newData, 'id')
            : prev?.[PHOI_HOP]?.filter(e => e.id !== values?.id) || []
        const theoDoi =
          name === THEO_DOI
            ? uniqBy(newData, 'id')
            : prev?.[THEO_DOI]?.filter(e => e.id !== values?.id) || []

        return {
          ...prev,
          [CHU_TRI]: chuTri,
          [PHOI_HOP]: phoiHop,
          [THEO_DOI]: theoDoi,
        }
      })
    },
    [setFormData, setDataAutoToggle, hasShowAll, dataTreeStore, roleId]
  )

  const handleCheckActive = useCallback(
    (key, record) => {
      let isChecked = false
      const childrenItem =
        dataTreeStore?.find(e => e?.id === record?.id)?.children || []
      const dataKey = formData?.[key]?.findIndex(e => e?.id === record?.id)
      if (size(formData?.[key]) !== 0) {
        const taskItemDataKey = formData?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          (size(childrenItem) !== 0 &&
            size(childrenItem) === size(taskItemDataKey)) ||
          dataKey === 0 ||
          (dataKey && dataKey !== -1)
        ) {
          isChecked = true
        }
      }

      return isChecked
    },
    [formData, dataTreeStore]
  )

  const handleCheckIndeterminate = useCallback(
    (name, record) => {
      let isChecked = false
      if (
        (record?.type === PHONG_BAN || record?.type === DON_VI) &&
        formData?.[name]?.[0]?.parentId === record?.id
      ) {
        isChecked = true
      }

      return isChecked
    },
    [formData]
  )

  const handleCheckDisable = useCallback(
    (key, record) => {
      let isDisable = false
      if ((key === PHOI_HOP || key === THEO_DOI) && roleId === record?.id) {
        isDisable = true
      }

      const dataChuTri = formData?.[CHU_TRI] || null
      if (
        (key === PHOI_HOP || key === THEO_DOI) &&
        !isEmpty(dataChuTri) &&
        record?.parentId === dataChuTri?.id &&
        record?.isLanhDaoPhongBan
      ) {
        isDisable = true
      }

      return isDisable
    },
    [formData, roleId]
  )

  const column = [
    {
      title: 'Phòng ban/đơn vị',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: record => {
        const formItem = formData?.[CHU_TRI]?.id === record?.id
        let isChecked = false
        if (formItem) {
          isChecked = true
        }

        if ((!hasShowAll && record?.id === mainDepartmentId) || !record.type) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(record)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: record => {
        const isDisable = handleCheckDisable(PHOI_HOP, record)
        const isChecked = handleCheckActive(PHOI_HOP, record)
        const isIndeterminate = handleCheckIndeterminate(PHOI_HOP, record)
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable || false}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, e, isIndeterminate)
            }
          />
        )
      },
    },
    {
      title: 'TD',
      render: record => {
        const isDisable = handleCheckDisable(THEO_DOI, record)
        const isChecked = handleCheckActive(THEO_DOI, record)
        const isIndeterminate = handleCheckIndeterminate(THEO_DOI, record)
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable || false}
            onChange={e =>
              onChangeCheckbox(THEO_DOI, record, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const convertUsers = useCallback(
    values => ({
      ...values,
      id: values?.chucDanhId,
      type: 'PERSONAL',
      name: values?.tenNhanVien,
      tenChucVu: values?.chucVu,
      maChucVu: values?.maChucVu,
      parentId: values?.parentId,
    }),
    []
  )

  const fetchPhongBanDonVi = useCallback(async () => {
    let params = {
      donViId: mainUnitId,
      phongBanId: mainDepartmentId,
      limit: 9999,
    }
    await requestAction({
      showToast: false,
      successCode: 200,
      beforeAction: () => {
        setIsLoadingTree(true)
      },
      action: () => getPhongBanNhanVien(params),
      afterResponse: response => {
        let newData = []
        if (response?.items) {
          newData = response?.items?.map(elm => {
            return {
              id: elm?.phongBanId,
              type: convertType('PB'),
              name: elm?.maPhongBan,
              code: elm?.maPhongBan,
              parentId: elm?.donViId,
              children:
                elm?.nhanViens?.map(u =>
                  convertUsers({ ...u, parentId: elm?.phongBanId })
                ) || [],
            }
          })
        }

        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree(newData),
        })
      },
      afterAction: () => {
        setIsLoadingTree(false)
      },
    })
  }, [mainUnitId, mainDepartmentId, convertUsers])

  const _fetchFavoriteGroup = useCallback(() => {
    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        mountedSet(
          setFavoriteGroups,
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(({ id, name, avatar, departmentId }) => ({
              id,
              parentId: departmentId,
              name,
              type: convertType('PERSONAL'),
              avatar,
            })),
          }))
        )
      },
    })
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchFavoriteGroup()
  }, [_fetchFavoriteGroup])

  useEffect(() => {
    fetchPhongBanDonVi()
  }, [fetchPhongBanDonVi])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  useEffect(() => {
    _setFormDataFromDocument()
  }, [_setFormDataFromDocument])

  return (
    <>
      <Card className="cpc-form">
        <Card
          padding
          mainClassName={classNames(
            'element-space-between',
            'align-center',
            'font-size-17'
          )}
        >
          <Icon
            className={classNames('back-button', {
              'cursor-not-allowed': submitLoading,
            })}
            iconName="arrow-left"
            onClick={goBack}
          />
          Tạo mới công việc
          <span />
        </Card>
        <Card padding borderTop>
          <FormGroup
            label="Tiêu đề"
            requiredLabel
            helperText={get(formData, 'title.error')}
          >
            <div className="button-float-right">
              <Button
                className={classNames(
                  Classes.MINIMAL,
                  'cpc-button',
                  'font-size-12',
                  'uppercase'
                )}
                intent={Intent.PRIMARY}
                onClick={toggleShowTemplate}
              >
                {TEMPLATE_TITLE}
                <Icon iconName="import" className="size-icon-11 ml5" />
              </Button>
            </div>
            <Input
              maxLength={500}
              onChange={changeField('title')}
              placeholder="Nhập tiêu đề"
              value={get(formData, 'title.value')}
              type="textarea"
              style={{
                minWidth: '100%',
                minHeight: 55,
                maxHeight: 105,
              }}
            />
          </FormGroup>
          <div className="mb15" style={{ display: 'block' }}>
            <Checkbox
              className={classNames(Classes.SMALL, 'mb0')}
              checked={get(formData, 'requireReport.value', false)}
              onChange={({ target: { checked } }) =>
                changeField('requireReport')(checked)
              }
            >
              <span className="font-size-13 font-weight-300">
                Yêu cầu báo cáo Hoàn thành
              </span>
            </Checkbox>
          </div>
          <PhongBanVaDonViThucHien
            className="mb10"
            isRequired={true}
            isLoading={isLoadingTree}
            column={column}
            data={dataTreeStore}
            dataFavorite={favoriteGroups}
            isDisplayFavoriteGroup={!hasShowAll}
            dataAutoToggle={[...dataAutoToggleDefault, ...dataAutoToggle]}
            warning={get(formData, `${CHU_TRI}.error`)}
            isHideChild={hasShowAll}
          />
          <Row gutterVertical className="mb10">
            <Col grid="half">
              <FormDatePicker
                textLabel="Ngày bắt đầu"
                inputClearable
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                onChange={value => {
                  changeField('executionDate')(value)
                }}
                minimumDate={moment().toDate()}
                maximumDate={
                  get(formData, 'deadline.value')
                    ? get(formData, 'deadline.value').toDate()
                    : undefined
                }
                placeholder="Chọn ngày bắt đầu"
                popoverStretch={false}
                selected={get(formData, 'executionDate.value')}
              />
            </Col>
            <Col grid="half">
              <DateAdvanced
                textLabel="Hạn thực hiện"
                inputClearable={get(formData, 'allowChangeDeadline')}
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                placeholder="Chọn hạn thực hiện"
                popoverStretch={false}
                selected={get(formData, 'deadline.value')}
                minimumDate={
                  get(formData, 'executionDate.value')
                    ? get(formData, 'executionDate.value').toDate()
                    : moment().toDate()
                }
                popoverPosition={Position.TOP_RIGHT}
                onChange={value => {
                  changeField('deadline')(value)
                }}
              />
            </Col>
          </Row>
          <FormGroup label="Mô tả">
            <Input
              maxLength={500}
              onChange={changeField('description')}
              placeholder="Nhập mô tả"
              value={get(formData, 'description.value', '')}
              type="textarea"
              style={{
                minWidth: '100%',
                minHeight: 105,
              }}
            />
          </FormGroup>

          <Button
            className={classNames(
              Classes.MINIMAL,
              'cpc-button',
              'cpc-form-button-left',
              'mb10',
              'font-size-14 font-weight-600 color-black'
            )}
            rightIconName={showExtra ? 'chevron-up' : 'chevron-down'}
            text="Thông tin bổ sung"
            onClick={toggleCollapse}
          />

          {/* Thông tin bổ sung */}
          <Collapse isOpen={showExtra}>
            <Row gutterVertical>
              <Col grid="half">
                <FormGroup label="Mức độ ưu tiên">
                  <Select
                    dataSource={toPairs(priorities).map(([value, label]) => ({
                      value,
                      label,
                    }))}
                    onChange={changeField('priority')}
                    value={get(formData, 'priority.value')}
                    placeholder="Chọn"
                  />
                </FormGroup>
              </Col>
              <Col grid="half">
                <FormGroup label="Nhắc việc">
                  <RemindSelect
                    deadline={get(formData, 'deadline.value')}
                    onChange={changeField('reminder')}
                    value={get(formData, 'reminder.value')}
                    placeholder="Chọn"
                    popoverPosition={Position.BOTTOM_RIGHT}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup label="Loại công việc (tags)">
              <CheckboxSelect
                placeholder="Chọn"
                onChange={changeField('tags')}
                dataSource={tags.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                inputHeight={36}
                value={get(formData, 'tags.value', [])}
              />
            </FormGroup>
            {get(formData, 'referenceFile') && (
              <FormGroup label="File công văn" className="mb10">
                <FileGroup list={[get(formData, 'referenceFile')]} />
              </FormGroup>
            )}
            {!!size(get(formData, 'attachmentFilesFromDocument')) && (
              <FormGroup label="File đính kèm từ công văn" className="mb10">
                <FileGroup
                  list={get(formData, 'attachmentFilesFromDocument')}
                />
              </FormGroup>
            )}
            {!!size(files) && (
              <FormGroup label="File đính kèm từ bên ngoài" className="mb10">
                <FileGroup list={files} onClose={removeFile} showClose />
              </FormGroup>
            )}
            <FormGroup className="mb10">
              <Upload
                disabled={false}
                accept={documentTypes.toString()}
                fileList={files || []}
                onChange={changeFile}
                textSumary={
                  <p className="mt5 font-style-italic font-size-12">
                    Tải file có dung lượng tối đa 100MB, định dạng .pdf, .docs,
                    .doc, .xls, .xlsx, .ppt, .pptx
                  </p>
                }
                isShowList={false}
                allowFileTypes={allowDocumentFileTypes}
              />
            </FormGroup>
            <Checkbox
              className="font-size-14"
              onChange={({ target: { checked } }) =>
                changeField('isSaveTemplate')(checked)
              }
              checked={get(formData, 'isSaveTemplate.value', false)}
            >
              Lưu mẫu
            </Checkbox>
          </Collapse>
        </Card>
        <Card padding borderTop className="cpc-footer-button-group">
          <Button
            className="cpc-button default"
            disabled={submitLoading}
            onClick={goBack}
          >
            <i className="icon-back mr5" />
            ĐÓNG
          </Button>
          <Button
            className="cpc-button"
            intent={Intent.PRIMARY}
            disabled={submitLoading}
            loading={submitLoading}
            onClick={createTask}
          >
            LƯU
            <i className="icon-save ml5" />
          </Button>
        </Card>
      </Card>

      {/* Dialog chọn mẫu có sẵn */}
      <TemplateDialog
        isOpen={showTemplate}
        onClose={() => toggleShowTemplate()}
        onConfirm={values => {
          toggleShowTemplate()
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  let dataAutoToggleDefault = []
  const hasShowAll = get(state, 'auth.permission', []).includes(
    Permission.CONGVANDEN_CHIDAO
  )
  if (!hasShowAll) {
    dataAutoToggleDefault = [get(state, 'auth.mainDepartmentId')]
  }

  return {
    roleId,
    dataTreeStore: get(state, 'common.dataTree'),
    hasShowAll,
    dataAutoToggleDefault,
    mainUnitId: get(state, 'auth.mainUnitId'),
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    mainDepartment: state?.auth?.user?.dsChucDanh?.find(
      elm => elm?.id === roleId
    ),
  }
}

export default withRouter(connect(mapStateToProps)(memo(TaskCreate)))
