export default {
  width: '100%',
  control: {
    backgroundColor: '#fff',
    fontSize: 13,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      minHeight: 30,
    },
    highlighter: {
      padding: '8px 10px',
      border: '1px solid transparent',
      borderRadius: '3px',
      width: '100%',
    },
    input: {
      padding: '8px 10px',
      border: '1px solid transparent',
      borderRadius: '3px',
      width: '100%',
      lineHeight: '18px',
    },
  },

  '&singleLine': {
    highlighter: {
      minHeight: '30px',
      lineHeight: '30px',
      paddingLeft: '4px',
      paddingRight: '10px',
      border: '1px inset transparent',
      borderRadius: '3px',
      width: '100%',
    },
    input: {
      minHeight: '30px',
      paddingLeft: '4px',
      paddingRight: '10px',
      border: '1px solid transparent',
      borderRadius: '3px',
      width: '100%',
    },
  },

  suggestions: {
    borderRadius: '3px',
    width: '100%',
    bottom: '100%',
    top: 'auto',
    left: '0px',
    list: {
      border: '1px solid rgb(223, 230, 234)',
      fontSize: 13,
      borderRadius: '3px',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgb(223, 230, 234)',
      '&focused': {
        backgroundColor: '#ddd',
      },
    },
  },
}
