import React, { memo } from 'react'
import ReactPaginate from 'react-paginate'
import { Icon } from '@blueprintjs/core'

const Pagination = ({
  onChange = () => {},
  current = 1,
  total = 1,
  hideOnSinglePage = true,
}) => {
  const pageChange = ({ selected }) => {
    onChange(selected + 1)
  }

  if (hideOnSinglePage && total === 1) {
    return null
  }

  return (
    <ReactPaginate
      forcePage={current - 1}
      pageCount={total}
      containerClassName="cpc-pagination-old"
      onPageChange={pageChange}
      previousLabel={<Icon iconName="arrow-left" iconSize="13" />}
      nextLabel={<Icon iconName="arrow-right" iconSize="13" />}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
    />
  )
}

export default memo(Pagination)
