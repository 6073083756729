import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import { Authorization, Permission } from '../auth'
import { LoadingItem, DetailFileDinhKem } from '../common/'
import variables from '../../constants/variables'
import { FileList } from '../../components/common'
import { Utils } from 'helpers'
import { isValidCondition } from 'helpers/Helper'

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBoxAction: false,
      pageMenuAction: this.props.pageMenuAction || 'CONGVANDI',
    }
  }

  static propTypes = {
    onClickSendAdditional: PropTypes.func,
    onClickExecutingView: PropTypes.func,
    onClickRestoreArchive: PropTypes.func,
    executingViews: PropTypes.object,
    actions: PropTypes.object.isRequired,
    onClickExecutingViewReceiveDocument: PropTypes.func,
    onClickFlowdocumentary: PropTypes.func.isRequired,
    pageMenuAction: PropTypes.string,
  }

  showBoxAction(e) {
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  checkPhongBanLap = () => {
    let checkPhongBanLap = []
    const { sendDocument, auth } = this.props
    if (get(sendDocument.phongBanLap, 'phongBanId')) {
      checkPhongBanLap =
        auth.user &&
        auth.user.dsChucDanh &&
        auth.user.dsChucDanh.filter(
          element =>
            element.phongBanId === get(sendDocument.phongBanLap, 'phongBanId')
        )
      if (
        isValidCondition(auth.permission, [Permission.DEXUAT_THUHOI], 'AND') &&
        checkPhongBanLap.length !== 0
      ) {
        return true
      }
    }

    return false
  }

  render() {
    const MenuDetail = [
      ...[
        {
          onClick: this.props.onClickSendAdditional,
          icon: 'icon-gan-tag',
          name: 'Gửi bổ sung',
          permission: [Permission.CONGVANDI_QUANLY],
          isShow: true,
          disabled: false,
          pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
        },
      ],
      {
        onClick: this.props.onClickDetailInfo,
        icon: 'icon-Thong_Tin_Giao_Viec color-main',
        name: 'Thông tin công văn',
        permission: [],
        isShow: true,
        disabled: false,
        pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
      },
      {
        onClick: this.props.onClickRestoreArchive,
        icon: 'icon-listing',
        name: 'Danh sách các đơn vị nhận',
        permission: [],
        isShow: true,
        disabled: false,
        pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
      },
      {
        onClick: this.props.onClickFlowdocumentary,
        icon: 'icon-organization',
        name: 'Luồng công văn',
        permission: [],
        isShow: true,
        disabled: false,
        pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
      },
      {
        onClick: this.props.onClickSendMail,
        icon: 'icon-Gmail',
        name: 'Gửi email',
        permission: [],
        isShow: true,
        disabled: false,
        pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
      },
      {
        onClick: this.props.sendToChat,
        icon: 'icon-speech-bubble',
        name: 'Gửi Chat',
        permission: [],
        isShow: true,
        disabled: false,
        pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
      },
      ...[
        {
          onClick: this.props.onClickDeXuatThuHoi,
          icon: 'icon-recalled icon-thuhoi',
          name: 'Đề xuất thu hồi',
          permission: [Permission.DEXUAT_THUHOI],
          isShow: this.checkPhongBanLap(),
          disabled: this.props.isCheckDeXuatThuHoi,
          pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDI,
        },
      ],
      // {
      //   onClick: this.props.onClickThuHoiCongVan,
      //   icon: 'icon-recalled icon-thuhoi',
      //   name: 'Thu hồi',
      //   permission: [Permission.CONGVANDI_QUANLY],
      //   isShow: this.props.isCheckThuHoi,
      //   disabled: false,
      //   pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDITHUHOI
      // },
      {
        onClick: this.props.onClickRestoreArchive,
        icon: 'icon-listing',
        name: 'Danh sách các đơn vị nhận',
        permission: [],
        isShow: true,
        disabled: false,
        pageMenuAction: variables.PAGEMENUDETAIL.CONGVANDITHUHOI,
      },
    ]
    return this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined ? (
      <LoadingItem />
    ) : (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        {this.state.pageMenuAction === variables.PAGEMENUDETAIL.CONGVANDI &&
          this.props.sendDocument &&
          this.props.sendDocument.fileDinhKem &&
          this.props.sendDocument.fileDinhKem.length > 0 && (
            <DetailFileDinhKem
              receiveDocument={{
                fileDinhKem: this.props.sendDocument.fileDinhKem,
              }}
              auth={this.props.auth}
            />
          )}
        {/* Get danh sách files tham khảo từ hội đồng thành viên */}
        {Utils.checkBanTongHop(this.props.auth.mainDepartmentId) &&
          this.props.filesThamKhao &&
          this.props.filesThamKhao.length !== 0 && (
            <div className="pl20 pr20">
              <label className="pt-label label-text-detail">
                File tham khảo ({this.props.filesThamKhao.length}):
              </label>
              <div className="pt-form-content">
                <div className="pl10">
                  <FileList
                    auth={this.props.auth}
                    receiveDocument={{
                      fileThamKhao: this.props.filesThamKhao,
                    }}
                    field="fileThamKhao"
                  />
                </div>
              </div>
            </div>
          )}
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button
              className="click-item"
              onClick={this.showBoxAction.bind(this)}
            >
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row">
            {MenuDetail.filter(
              item =>
                item.isShow === true &&
                item.pageMenuAction === this.state.pageMenuAction
            ).map((item, key) => {
              return (
                <Authorization.Element permission={item.permission} key={key}>
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      disabled={item.disabled}
                      className="click-item"
                      onClick={item.onClick}
                    >
                      <i className={`icon-cpc ${item.icon}`}></i>
                      <span className="action-name">{item.name}</span>
                    </button>
                  </div>
                </Authorization.Element>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default DetailBoxAction
