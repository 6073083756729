import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExecutingViewSubItem from './ExecutingViewSubItem'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import moment from 'moment'
import * as Types from '../../constants/Api'
import ExecutingViewBoxItem from './ExecutingViewBoxItem'
class ExecutingViewBoxItems extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpen: true,
  }

  handleClick = () => {
    if (
      (this.props.item &&
        this.props.item.dsCongViecCaNhan &&
        this.props.item.dsCongViecCaNhan.length > 0) ||
      (this.props.item &&
        this.props.item.dsCongViecPhongBan &&
        this.props.item.dsCongViecPhongBan.length > 0)
    ) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  render() {
    const { item, auth } = this.props
    return (
      <div className="box-level-sub item-box">
        <div className="item-user">
          <div className="drop-down-sub">
            <div className="click-avatar" onClick={this.handleClick}>
              <div className="avatar-user">
                <span
                  className={classnames('pt-icon click-dropdown', {
                    'pt-icon-chevron-up drop-down-items':
                      ((item.dsCongViecCaNhan &&
                        item.dsCongViecCaNhan.length > 0) ||
                        (item.dsCongViecPhongBan &&
                          item.dsCongViecPhongBan.length > 0)) &&
                      this.state.isOpen,
                    'pt-icon-chevron-down drop-down-items':
                      ((item.dsCongViecCaNhan &&
                        item.dsCongViecCaNhan.length > 0) ||
                        (item.dsCongViecPhongBan &&
                          item.dsCongViecPhongBan.length > 0)) &&
                      !this.state.isOpen,
                  })}
                ></span>
                <span
                  className="avatar"
                  style={{
                    backgroundImage: `url(${
                      item.nguoiGiaoViec && item.nguoiGiaoViec.anhDaiDien
                        ? Types.IMAGE_URL + item.nguoiGiaoViec.anhDaiDien
                        : '/images/default_avatar.png'
                    })`,
                  }}
                ></span>
                <span className="name_user">
                  {item.nguoiGiaoViec && item.nguoiGiaoViec.tenNhanVien}
                </span>
              </div>
            </div>
          </div>
          <div className="info-job">
            {item.ngayGiaoViec && (
              <span className="">
                <span className="title">Ngày:</span>
                <span className="content">
                  {item.ngayGiaoViec &&
                    ' ' +
                      moment(item.ngayGiaoViec).format('DD/MM/YYYY HH:mm') +
                      ' '}
                </span>
              </span>
            )}
            {item.noiDung && (
              <span className="">
                <span className="title">- Nội dung giao việc:</span>
                <span className="content textarea-content">
                  {item.noiDung ? ' ' + item.noiDung : ''}
                </span>
              </span>
            )}
          </div>
          <Collapse isOpen={this.state.isOpen}>
            {item.dsCongViecPhongBan.map((item, i) => (
              <ExecutingViewBoxItem
                item={item}
                key={i}
                auth={auth}
                actions={this.props.actions}
                type={'dsCongViecPhongBan'}
              />
            ))}
            {item.dsCongViecCaNhan.map((item, i) => (
              <ExecutingViewSubItem
                item={item}
                key={i}
                auth={auth}
                actions={this.props.actions}
                type={'dsCongViecPhongBan'}
              />
            ))}
            {item.dsCaNhanXemDeBiet &&
              item.dsCaNhanXemDeBiet.map((item, i) => (
                <div className="box-level-sub item-box" key={i}>
                  <div className="avatar-user user-only-see">
                    <span
                      className="avatar"
                      style={{
                        backgroundImage: `url(${
                          item.anhDaiDien
                            ? Types.IMAGE_URL + item.anhDaiDien
                            : '/images/default_avatar.png'
                        })`,
                      }}
                    ></span>
                    <span className="name_user">
                      {item.tenNhanVien + ' '}
                      <span className="role">(Xem để biết)</span>
                    </span>
                  </div>
                </div>
              ))}
          </Collapse>
        </div>
      </div>
    )
  }
}

export default ExecutingViewBoxItems
