import React, { Component } from 'react'
import classnames from 'classnames'

export default class UsingGroupDetail extends Component {
  constructor() {
    super()
    this.state = {
      isShowCollapse: true,
    }
  }

  toggleShowCollapse = () =>
    this.setState({
      isShowCollapse: !this.state.isShowCollapse,
    })

  render() {
    const { group, index, deleteGroup } = this.props
    const { isShowCollapse } = this.state
    return (
      <div>
        <div className="group-header">
          <div className="group-name primary-color">
            NHÓM {index + 1}
            <span
              className={classnames('pt-icon  collapse-btn', {
                'pt-icon-chevron-up': isShowCollapse,
                'pt-icon-chevron-down': !isShowCollapse,
              })}
              onClick={this.toggleShowCollapse}
            />
          </div>
          <div className="action-button-group">
            <button
              className="action-button delete-btn"
              onClick={() => deleteGroup(group.nhomId)}
            >
              XÓA NHÓM
            </button>
          </div>
        </div>
        <hr className="group-end-line" />
      </div>
    )
  }
}
