import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { get } from 'lodash'

import { PopupConfirm } from '../../common/'
import * as POPUP from '../../../constants/Popup'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isRedirect: false,
    isOpenPopup: false,
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    const id = this.props.item && this.props.item.congVanCongViecId
    if (id) {
      this.props.onHandleSubmitRemoveItem &&
        this.props.onHandleSubmitRemoveItem(id)
    }
  }

  render() {
    const { auth } = this.props
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: `/cong-van/cong-van-den/chi-tiet/${this.props.item.congVanCongViecId}`,
            state: {
              status: 'chuyen-nham',
            },
          }}
        />
      )
    }
    const { item } = this.props
    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>
          {item &&
            item.dsBaoNham &&
            item.dsBaoNham[0] &&
            item.dsBaoNham[0].ngayBaoNham &&
            moment(item.dsBaoNham[0].ngayBaoNham).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>{item && item.soCongVan}</td>
        <td onClick={this.handleClick} className="textarea-content">
          {item && item.trichYeu}
        </td>
        <td onClick={this.handleClick}>{item && item.coQuanBanHanh}</td>
        <td onClick={this.handleClick}>
          {item &&
            item.dsBaoNham.map((value, i) => (
              <p key={i}>
                <span className="info-alertMistake">{value.nguoiBaoNham}</span>{' '}
                - {moment(value.ngayBaoNham).format('DD/MM/YYYY')}:{' '}
                {value.noiDung}
              </p>
            ))}
        </td>
        <td>
          {!get(auth, 'permission', []).includes('CONGVANDEN_DEXUATCHIDAO') && (
            <Popover
              popoverClassName="pt-popover-content-sizing pt-minimal"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <div className="item" onClick={this.handleClickPopup}>
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Huỷ báo nhầm</span>
                  </div>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          )}
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.HUY_CHUYEN_NHAM}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitRemoveItem}
          >
            {POPUP.HUY_BAO_NHAM_CONFIRM}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

export default ListItem
