import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import ReactDatePicker from 'react-daterange-picker'
import { get } from 'lodash'
import csx from 'classnames'
import moment from 'moment'

import DropdownWrapper from './DropdownWrapper'

const dateFormat = 'DD/MM/YYYY'

const DatePicker = ({
  dataSource = [],
  onChange: selectChange = () => {},
  selected: selectValue,
  minimumDate = null,
  maximumDate = null,
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [selected, _setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    _mountedSet(_setDropdownOpen, open)
  }, [])

  const _onChange = useCallback(
    value => {
      _mountedSet(_setSelected, value)
      _toggleDropdown(false)
      selectChange(value)
    },
    [selectChange, _toggleDropdown]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      onClear={() => _onChange(null)}
      value={selected ? moment(selected).format(dateFormat) : ''}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
    >
      <ReactDatePicker
        className={csx('cpc-datepicker-range')}
        value={selected || moment()}
        onSelect={_onChange}
        selectionType="single"
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      />
    </DropdownWrapper>
  )
}

export default memo(DatePicker)
