import React, { memo, useState, useMemo } from 'react'
import classNames from 'classnames'
import moment from 'moment/moment'

import { Collapse, Icon } from 'components/newCommon'
import TieuDeXuLy from './TieuDeXuLy'
import QuaTrinhXuLyItemChild from './QuaTrinhXuLyItem'

const QuaTrinhXuLyItem = ({
  itemData,
  id,
  idDv,
  tokenDO,
  hasChild = false,
  isLeader = false,
}) => {
  const [isOpenGroup] = useState(false)
  const [childrenData] = useState([])

  const renderChild = useMemo(
    () => (value, index) => {
      if (!value?.isChildren) {
        return <TieuDeXuLy key={index} className="mt10" item={value} />
      }

      return (
        <QuaTrinhXuLyItemChild
          key={index}
          hasChild={value.isChildren}
          isLeader={value?.isChidao ?? false}
          id={id}
          idDv={idDv}
          tokenDO={tokenDO}
          itemData={value}
        />
      )
    },
    [id, tokenDO, idDv]
  )

  return (
    <div className="mt10 font-roboto">
      <div className={classNames('do-collapse-title pl20')}>
        <span
          className={classNames('pt-icon font-size-13 wh-16 mr5', {
            'color-blue cursor-pointer': hasChild,
            'pt-icon-chevron-up': isOpenGroup && hasChild,
            'pt-icon-chevron-down': !isOpenGroup && hasChild,
          })}
          style={{ position: 'absolute', left: 0, top: '2px' }}
        />
        <p className="chuc_vu mr10">{itemData?.tENPB || ''}</p>
        <p
          className={classNames('vai_tro mr10', {
            red: itemData?.mAXULY === 'Chủ trì' && itemData?.kEYXULY,
          })}
        >
          ({itemData?.mAXULY || ''})
        </p>
        {itemData?.tTXULY === 'Hoàn thành' && (
          <div className="circle-border mr10">
            <span className="checkmark"></span>
          </div>
        )}
        {itemData?.tTXULY === 'Đã chuyển' && (
          <Icon classIcon="icon2-hand" className="mr10" />
        )}
        <p className="thoi_gian">
          {itemData?.nGAYTAO
            ? moment(itemData?.nGAYTAO)
                .subtract(7, 'hours')
                .format('DD/MM/YYYY HH:mm')
            : ''}
        </p>
        {itemData?.nDUNGXULY && (
          <div
            className={'mt5 font-roboto'}
            style={{ minWidth: '100%' }}
            dangerouslySetInnerHTML={{
              __html: itemData?.nDUNGXULY,
            }}
          />
        )}
      </div>
      {hasChild && isOpenGroup && (
        <Collapse isOpen={isOpenGroup}>
          <div className={'pl20'}>
            {childrenData?.map?.((item, index) => renderChild(item, index))}
          </div>
        </Collapse>
      )}
    </div>
  )
}

export default memo(QuaTrinhXuLyItem)
