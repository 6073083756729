import { Authorization, Permission } from '../auth'
import {
  MESSAGE_ERROR,
  MESSAGE_NOT_FOUND,
  MESSAGE_REQUIRED_STATISTICS,
} from '../../constants/MessageForm'
import React, { Component } from 'react'
import { Checkbox } from '@blueprintjs/core'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import SelectTree from './SelectTree'
import classnames from 'classnames'
import { isEmpty, size } from 'lodash'
import moment from 'moment'
import { Select } from 'components/newCommon'

function handleStyle(options = []) {
  let data = {}
  for (var i = 0; i <= options.length - 1; i++) {
    data[options[i]['type']] = options[i]['value']
  }
  return data
}

class GridViewFilter extends Component {
  constructor(props) {
    super(props)
    this.handleCheckall = this.handleCheckall.bind(this)
    this.onSubmitForm = this.onSubmitForm.bind(this)
    this.state = {
      disabledSelect: true,
      hiddenOption: false,
      isEnabledAll: this.props.isEnabledAll,
      sort: {},
      isLoading: props.isLoading !== undefined ? props.isLoading : true,
    }
  }

  static defaultProps = {
    checkList: {},
  }

  handleCheckall = e => {
    this.setState({
      isEnabled: true,
    })
  }

  componentDidMount() {
    if (this.props.auth && this.props.auth.permission.length <= 0) {
      return
    }

    let state = this.state

    if (
      Authorization.vaild(this.props.auth && this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ])
    ) {
      state.disabledSelect = false
    }

    if (
      Authorization.vaild(this.props.auth && this.props.auth.permission, [
        Permission.CONGVANDEN_CHUYENCHIDAO,
      ])
    ) {
      state.disabledSelect = false
      state.hiddenOption = true
    }

    this.setState(state)
  }

  // componentDidUpdate(prevProps, prevState){
  //   if(prevProps.data !== this.props.data && !prevProps.isLoading){
  //     this.setState({
  //       isLoading: prevProps.isLoading
  //     });
  //   }
  // }

  componentWillReceiveProps(props) {
    if (props.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: props.isLoading,
      })
    }
  }

  handleClickSort(i) {
    let state = this.state

    for (var v in state.sort) {
      if (v === i) {
        continue
      }

      delete state.sort[v]
    }

    state.sort[i] = !state.sort[i] || state.sort[i] === 'ASC' ? 'DESC' : 'ASC'
    this.setState(state, () => {
      let sort

      for (let sortField in this.state.sort) {
        sort = `${sortField} ${this.state.sort[sortField]}`
      }

      this.props.onFilter && this.props.onFilter(sort)
    })
  }

  handleCheckall = e => {
    if (this.props.TTL && this.props.handleCheckAll) {
      return this.props.handleCheckAll()
    }

    this.props.onCheckAll && this.props.onCheckAll(e.target.checked)
  }

  onSubmitForm(e) {
    e.preventDefault()

    if (
      this.props.match &&
      this.props.match.path === '/tu-tai-lieu/danh-sach'
    ) {
      return this.props.onSubmitFilter && this.props.onSubmitFilter()
    }
    this.props.onSubmit && this.props.onSubmit()
  }

  changeSelect = (name, value) => {
    const convertData = {
      target: {
        name: name,
        value: value,
      },
    }

    this.props && this.props.onChange(convertData)
  }

  render() {
    let imageUrl = process.env.PUBLIC_URL + '/images/empty-default.png'
    if (this.props.isError === true) {
      imageUrl = process.env.PUBLIC_URL + '/images/Server.png'
    }
    if (this.props.isShowMessengStatistics === true) {
      imageUrl = process.env.PUBLIC_URL + '/images/calendar.png'
    }
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    const AuthElement = Authorization.Element
    return (
      <div
        className={classnames('table-scroll item-gridview-filter', {
          'table-scroll-hidden':
            this.state.isLoading === true ||
            this.props.isEmpty === true ||
            this.props.isError === true ||
            this.props.isShowMessengStatistics === true,
          'item-gridview-filter-TTL':
            this.props.TTL &&
            (this.state.isLoading === true ||
              this.props.isEmpty === true ||
              this.props.isError === true),
        })}
      >
        <form>
          <table
            className={classnames(`${this.props.classNames}`, {
              'fix-table-before':
                (this.props.isEmpty === true ||
                  this.props.isError === true ||
                  (this.props.isType && this.props.isCVDQL) ||
                  this.props.pageCount > 1) &&
                !this.state.isLoading,
            })}
            style={{ position: 'relative' }}
          >
            {this.props.headers.length > 0 &&
              !this.props.isError &&
              !this.props.isShowMessengStatistics && (
                <thead>
                  <tr>
                    {this.props.headers.map((header, i) => {
                      let itemStyle = handleStyle(header.style)
                      return (
                        <Authorization.Element
                          key={i}
                          permission={header.permission || []}
                        >
                          <th
                            className={header.classNames || ''}
                            style={itemStyle}
                          >
                            <span className="sort-colum">
                              <span className="text-sort">{header.value}</span>
                              {header.sort && (
                                <span
                                  className={`pt-icon ${
                                    this.state.sort[header.sort] &&
                                    this.state.sort[header.sort] === 'DESC'
                                      ? 'pt-icon-chevron-down'
                                      : 'pt-icon-chevron-up'
                                  }`}
                                  onClick={this.handleClickSort.bind(
                                    this,
                                    header.sort
                                  )}
                                ></span>
                              )}
                            </span>
                            {header.checkAll &&
                            this.props.data &&
                            this.props.data.length > 0 ? (
                              <Authorization.Element
                                permission={this.props.actionPermissions}
                              >
                                <Checkbox
                                  onChange={this.handleCheckall.bind(this)}
                                  checked={this.props.isCheckAll}
                                  disabled={this.props.isCheckAllData}
                                />
                              </Authorization.Element>
                            ) : null}
                          </th>
                        </Authorization.Element>
                      )
                    })}
                  </tr>
                  <tr>
                    {this.props.headers.map((header, i) => {
                      let itemStyle = handleStyle(header.style)
                      if (header.typeGridView) {
                        if (header.typeGridView === 'input') {
                          return (
                            <Authorization.Element
                              key={i}
                              permission={header.permission || []}
                            >
                              <th
                                className={`position-gridview ${
                                  header.classNames || ''
                                }`}
                                style={itemStyle}
                              >
                                <input
                                  name={header.nameGridView}
                                  maxLength={255}
                                  style={{ width: '100%' }}
                                  className="pt-input custom-grid-filter padding-right-input-grid-filter"
                                  placeholder="Nhập"
                                  onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                      this.onSubmitForm(e)
                                    }
                                  }}
                                  onChange={this.props.onChange}
                                  value={
                                    (this.props.filterColumn &&
                                      this.props.filterColumn[
                                        header.nameGridView
                                      ]) ||
                                    ''
                                  }
                                />
                                {this.props.filterColumn &&
                                  this.props.filterColumn[
                                    header.nameGridView
                                  ] && (
                                    <i
                                      className="icon-close pt-close-gridview"
                                      onClick={() => {
                                        this.props.onRemoveText([
                                          header.nameGridView,
                                        ])
                                      }}
                                    ></i>
                                  )}
                              </th>
                            </Authorization.Element>
                          )
                        }
                        if (header.typeGridView === 'date') {
                          return (
                            <Authorization.Element
                              key={i}
                              permission={header.permission || []}
                            >
                              <th
                                className={`daterangepicker-gridview-filter ${
                                  header.classNames || ''
                                }`}
                                style={itemStyle}
                              >
                                <div className="daterangepicker-group daterangepicker-gridview daterangepicker-gridview-custom">
                                  <DatePicker
                                    readOnly
                                    isClearable={
                                      window.innerWidth >= 530 ? true : false
                                    }
                                    selected={
                                      (this.props.filterColumn &&
                                        this.props.filterColumn[
                                          header.nameGridView
                                        ]) ||
                                      null
                                    }
                                    onChange={this.props.onChangeDate.bind(
                                      this,
                                      header.nameGridView
                                    )}
                                    format="DD/MM/YYYY"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="input-datepicker input-icon-datepicker gridview-filter custom-grid-filter"
                                    placeholderText="Nhập"
                                    popperPlacement="auto"
                                    style={{ height: '30 !ipmportand' }}
                                    popperModifiers={{
                                      offset: {
                                        enabled: true,
                                        offset: '5px, 10px',
                                      },
                                      preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: 'viewport',
                                      },
                                    }}
                                    value={
                                      (this.props.filterColumn &&
                                        this.props.filterColumn[
                                          header.nameGridView
                                        ]) ||
                                      ''
                                    }
                                    maxDate={
                                      !isEmpty(this.props.maxDate)
                                        ? moment(this.props.maxDate)
                                        : undefined
                                    }
                                  />
                                  {header.nameGridView === 'coQuanBanHanhId' &&
                                    !this.state.isLoading &&
                                    this.props.filterColumn &&
                                    this.props.filterColumn.coQuanBanHanh &&
                                    this.props.filterColumn[
                                      header.nameGridView
                                    ] === '#' && (
                                      <i
                                        className="icon-close pt-close-gridview-cqbh"
                                        onClick={() => {
                                          this.props.onRemoveText(
                                            'coQuanBanHanh'
                                          )
                                        }}
                                      ></i>
                                    )}
                                  {header.nameGridView ===
                                    'phongBanTrinhId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      disabled={this.state.disabledSelect}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props.match &&
                                        this.props.match.params.status !==
                                          'dang-xu-ly' && (
                                          <option value="00000000-0000-0000-0000-000000000000">
                                            Chưa
                                          </option>
                                        )}
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.phongBanId}
                                          >
                                            {item.maPhongBan}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'vaiTro' && (
                                    <select
                                      className="custom-grid-filter"
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      name={header.nameGridView}
                                      onChange={this.props.onChange}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      <option value={true}>Chủ trì</option>
                                      <option value={false}>Phối hợp</option>
                                    </select>
                                  )}
                                  {header.nameGridView === 'canBaoCao' && (
                                    <select
                                      className="custom-grid-filter"
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      name={header.nameGridView}
                                      onChange={this.props.onChange}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      <option value={true}>Có</option>
                                      <option value={false}>Không</option>
                                    </select>
                                  )}
                                  {header.nameGridView === 'trangThai' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option key={i} value={item.value}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {(header.nameGridView === 'chuTri' ||
                                    header.nameGridView === 'nguoiGiaoId') && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.chucDanhId}
                                          >
                                            {item.tenNhanVien}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'chuTriId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.chucDanhId}
                                          >
                                            {item.tenNhanVien}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'phongBanLapId' &&
                                    !this.props.onChangeSelectTree && (
                                      <select
                                        style={{
                                          width: '100%',
                                          background: '#fff',
                                        }}
                                        className="select-content"
                                        onChange={this.props.onChange}
                                        name={header.nameGridView}
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn[
                                              header.nameGridView
                                            ]) ||
                                          ''
                                        }
                                        disabled={this.props.isDisablePBLap}
                                      >
                                        <option value="">Tất cả</option>
                                        {this.props[header.nameGridView] &&
                                          this.props[header.nameGridView]
                                            .filter(
                                              s => s.phongBanLanhDao === false
                                            )
                                            .map((item, i) => (
                                              <option
                                                key={i}
                                                value={item.phongBanId}
                                              >
                                                {item.maPhongBan}
                                              </option>
                                            ))}
                                      </select>
                                    )}
                                  {header.nameGridView === 'phongBanNhanId' &&
                                    !this.props.onChangeSelectTree && (
                                      <select
                                        style={{
                                          width: '100%',
                                          background: '#fff',
                                        }}
                                        className="select-content"
                                        onChange={this.props.onChange}
                                        name={header.nameGridView}
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn[
                                              header.nameGridView
                                            ]) ||
                                          ''
                                        }
                                        disabled={this.props.isDisablePBNhan}
                                      >
                                        <option value="">Tất cả</option>
                                        {this.props[header.nameGridView] &&
                                          this.props[header.nameGridView]
                                            .length !== 0 &&
                                          this.props[header.nameGridView].map(
                                            (item, i) => (
                                              <option
                                                key={i}
                                                value={item.phongBanId}
                                              >
                                                {item.maPhongBan}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    )}
                                  {header.nameGridView === 'nguoiKyId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView] &&
                                        this.props[header.nameGridView]
                                          .length !== 0 &&
                                        this.props[header.nameGridView].map(
                                          (item, i) => (
                                            <option
                                              key={i}
                                              value={item.chucDanhId}
                                            >
                                              {item.tenNhanVien}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'trangThaiXDB' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      name="trangThai"
                                      onChange={this.props.onChange2}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn.trangThai) ||
                                        ''
                                      }
                                    >
                                      <option value={2}>Tất cả</option>
                                      <option value={0}>Chưa đọc</option>
                                      <option value={1}>Đã đọc</option>
                                    </select>
                                  )}
                                  {(header.nameGridView === 'phongBanLapId' ||
                                    header.nameGridView === 'phongBanNhanId') &&
                                    this.props[header.nameGridView].length >
                                      0 &&
                                    this.props.onChangeSelectTree && (
                                      <SelectTree
                                        isDisabled={
                                          header.nameGridView ===
                                          'phongBanLapId'
                                            ? this.props.isDisablePBLap
                                            : this.props.isDisablePBNhan
                                        }
                                        data={this.props[header.nameGridView]}
                                        className="select-tree-filter"
                                        dropdownClassName="dropdown-select-tree"
                                        type="selectTreeFilter"
                                        name={header.nameGridView}
                                        handleChange={
                                          this.props.onChangeSelectTree
                                        }
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn[
                                              header.nameGridView
                                            ]) ||
                                          ''
                                        }
                                      />
                                    )}
                                </div>
                              </th>
                            </Authorization.Element>
                          )
                        }
                        if (header.typeGridView === 'select') {
                          return (
                            <Authorization.Element
                              key={i}
                              permission={header.permission || []}
                            >
                              <th
                                className={`position-gridview ${
                                  header.classNames || ''
                                }`}
                                style={{ ...itemStyle, position: 'relative' }}
                              >
                                <div className="select pt-select gridview-filter-select">
                                  {header.nameGridView ===
                                    'coQuanBanHanhId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option key={i} value={item.donViId}>
                                            {item.tenVietTat}
                                          </option>
                                        )
                                      )}
                                      <option value="#">Khác</option>
                                    </select>
                                  )}
                                  {header.nameGridView === 'coQuanBanHanhId' &&
                                    !this.state.isLoading &&
                                    this.props.filterColumn &&
                                    this.props.filterColumn.coQuanBanHanhId &&
                                    this.props.filterColumn.coQuanBanHanhId ===
                                      '#' && (
                                      <input
                                        style={{
                                          left: 0,
                                          position: 'absolute',
                                          bottom: -35,
                                          width: '100%',
                                        }}
                                        className="pt-input custom-grid-filter padding-right-input-grid-filter"
                                        name="coQuanBanHanh"
                                        maxLength={255}
                                        onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                            this.onSubmitForm(e)
                                          }
                                        }}
                                        onChange={this.props.onChange}
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn
                                              .coQuanBanHanh) ||
                                          ''
                                        }
                                      />
                                    )}
                                  {header.nameGridView === 'coQuanBanHanhId' &&
                                    !this.state.isLoading &&
                                    this.props.filterColumn &&
                                    this.props.filterColumn.coQuanBanHanh &&
                                    this.props.filterColumn[
                                      header.nameGridView
                                    ] === '#' && (
                                      <i
                                        className="icon-close pt-close-gridview-cqbh"
                                        onClick={() => {
                                          this.props.onRemoveText(
                                            'coQuanBanHanh'
                                          )
                                        }}
                                      ></i>
                                    )}
                                  {header.nameGridView ===
                                    'phongBanTrinhId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      disabled={this.state.disabledSelect}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props.match &&
                                        this.props.match.params.status !==
                                          'dang-xu-ly' && (
                                          <option value="00000000-0000-0000-0000-000000000000">
                                            Chưa
                                          </option>
                                        )}
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.phongBanId}
                                          >
                                            {item.maPhongBan}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'vaiTro' && (
                                    <div>
                                      {header.dataSelect &&
                                      !!size(header.dataSelect) ? (
                                        <Select
                                          placeholder="Chọn"
                                          dataSource={header.dataSelect.map(
                                            elm => {
                                              return {
                                                value: elm.value,
                                                label: elm.name,
                                                color: elm.color,
                                              }
                                            }
                                          )}
                                          value={
                                            (this.props.filterColumn &&
                                              this.props.filterColumn[
                                                header.nameGridView
                                              ]) ||
                                            ''
                                          }
                                          clearable={false}
                                          styleInput={{
                                            ...(this.props.styleFilter &&
                                            this.props.styleFilter[
                                              header.nameGridView
                                            ]
                                              ? this.props.styleFilter[
                                                  header.nameGridView
                                                ]
                                              : null),
                                          }}
                                          isShowRightElement={false}
                                          inputClassName="input-select"
                                          onChange={value =>
                                            this.changeSelect(
                                              header.nameGridView,
                                              value
                                            )
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="custom-grid-filter"
                                          style={{
                                            ...(this.props.styleFilter &&
                                            this.props.styleFilter[
                                              header.nameGridView
                                            ]
                                              ? this.props.styleFilter[
                                                  header.nameGridView
                                                ]
                                              : null),
                                            width: '100%',
                                            background: '#fff',
                                          }}
                                          name={header.nameGridView}
                                          onChange={this.props.onChange}
                                          value={
                                            (this.props.filterColumn &&
                                              this.props.filterColumn[
                                                header.nameGridView
                                              ]) ||
                                            ''
                                          }
                                        >
                                          {[
                                            { value: '', name: 'Tất cả' },
                                            { value: true, name: 'Chủ trì' },
                                            { value: false, name: 'Phối hợp' },
                                          ].map((elm, index) => (
                                            <option
                                              key={index}
                                              value={elm.value}
                                            >
                                              {elm.name}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    </div>
                                  )}
                                  {header.nameGridView === 'canBaoCao' && (
                                    <select
                                      className="custom-grid-filter"
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      name={header.nameGridView}
                                      onChange={this.props.onChange}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      <option value={true}>Có</option>
                                      <option value={false}>Không</option>
                                    </select>
                                  )}
                                  {header.nameGridView === 'trangThai' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option key={i} value={item.value}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {(header.nameGridView === 'chuTri' ||
                                    header.nameGridView === 'nguoiGiaoId') && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.chucDanhId}
                                          >
                                            {item.tenNhanVien}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'chuTriId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.chucDanhId}
                                          >
                                            {item.tenNhanVien}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'phongBanLapId' &&
                                    !this.props.onChangeSelectTree && (
                                      <select
                                        style={{
                                          width: '100%',
                                          background: '#fff',
                                          fontSize: '13px',
                                        }}
                                        className="select-content"
                                        onChange={this.props.onChange}
                                        name={header.nameGridView}
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn[
                                              header.nameGridView
                                            ]) ||
                                          ''
                                        }
                                        disabled={this.props.isDisablePBLap}
                                      >
                                        <option value="">Tất cả</option>
                                        {this.props[header.nameGridView] &&
                                          this.props[header.nameGridView]
                                            .filter(
                                              s => s.phongBanLanhDao === false
                                            )
                                            .map((item, i) => (
                                              <option
                                                key={i}
                                                value={item.phongBanId}
                                              >
                                                {item.maPhongBan}
                                              </option>
                                            ))}
                                      </select>
                                    )}
                                  {header.nameGridView === 'phongBanNhanId' &&
                                    !this.props.onChangeSelectTree && (
                                      <select
                                        style={{
                                          width: '100%',
                                          background: '#fff',
                                        }}
                                        className="select-content"
                                        onChange={this.props.onChange}
                                        name={header.nameGridView}
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn[
                                              header.nameGridView
                                            ]) ||
                                          ''
                                        }
                                        disabled={this.props.isDisablePBNhan}
                                      >
                                        <option value="">Tất cả</option>
                                        {this.props[header.nameGridView] &&
                                          this.props[header.nameGridView].map(
                                            (item, i) => (
                                              <option
                                                key={i}
                                                value={item.phongBanId}
                                              >
                                                {item.maPhongBan}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    )}
                                  {header.nameGridView === 'nguoiKyId' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                        fontSize: '13px',
                                      }}
                                      className="select-content"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option
                                            key={i}
                                            value={item.chucDanhId}
                                          >
                                            {item.tenNhanVien}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                  {header.nameGridView === 'trangThaiXDB' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      name="trangThai"
                                      onChange={this.props.onChange2}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn.trangThai) ||
                                        ''
                                      }
                                    >
                                      <option value={2}>Tất cả</option>
                                      <option value={0}>Chưa đọc</option>
                                      <option value={1}>Đã đọc</option>
                                    </select>
                                  )}
                                  {(header.nameGridView === 'phongBanLapId' ||
                                    header.nameGridView === 'phongBanNhanId') &&
                                    this.props[header.nameGridView].length >
                                      0 &&
                                    this.props.onChangeSelectTree && (
                                      <SelectTree
                                        isDisabled={
                                          header.nameGridView ===
                                          'phongBanLapId'
                                            ? this.props.isDisablePBLap
                                            : this.props.isDisablePBNhan
                                        }
                                        data={this.props[header.nameGridView]}
                                        className="select-tree-filter"
                                        dropdownClassName="dropdown-select-tree"
                                        type="selectTreeFilter"
                                        name={header.nameGridView}
                                        handleChange={
                                          this.props.onChangeSelectTree
                                        }
                                        value={
                                          (this.props.filterColumn &&
                                            this.props.filterColumn[
                                              header.nameGridView
                                            ]) ||
                                          ''
                                        }
                                      />
                                    )}
                                  {header.nameGridView === 'tinhTrang' && (
                                    <select
                                      style={{
                                        width: '100%',
                                        background: '#fff',
                                      }}
                                      className="select-content custom-grid-filter"
                                      onChange={this.props.onChange}
                                      name={header.nameGridView}
                                      value={
                                        (this.props.filterColumn &&
                                          this.props.filterColumn[
                                            header.nameGridView
                                          ]) ||
                                        ''
                                      }
                                    >
                                      <option value="">Tất cả</option>
                                      {this.props[header.nameGridView].map(
                                        (item, i) => (
                                          <option key={i} value={item.value}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                </div>
                              </th>
                            </Authorization.Element>
                          )
                        }
                      }

                      return (
                        <Authorization.Element
                          key={i}
                          permission={header.permission || []}
                        >
                          <th
                            className={header.classNames || ''}
                            style={itemStyle}
                          ></th>
                        </Authorization.Element>
                      )
                    })}
                  </tr>
                </thead>
              )}
            {this.state.isLoading ? (
              <tbody>
                <tr className="table-loading">
                  <td
                    className="colum-loading"
                    colSpan={this.props.headers.length}
                  >
                    {ItemLoading}
                    {ItemLoading}
                    {ItemLoading}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {!this.props.isShowMessengStatistics &&
                  !this.props.isEmpty &&
                  !this.props.isError &&
                  this.props.data &&
                  this.props.data.length > 0 &&
                  this.props.data.map((item, i) => {
                    if (!item) {
                      return null
                    }
                    let check = false

                    if (
                      item.id &&
                      this.props.checkList &&
                      this.props.checkList[item.id]
                    ) {
                      check = true
                    }

                    if (
                      item.congVanDenId &&
                      this.props.checkList &&
                      this.props.checkList[item.congVanDenId]
                    ) {
                      check = true
                    }
                    if (
                      this.props.TTL &&
                      this.props.listCheckIds &&
                      this.props.listCheckIds.findIndex(
                        id => id === item.id
                      ) !== -1
                    ) {
                      check = true
                    }

                    return (
                      <this.props.itemShape
                        type={this.props.type ? this.props.type : ''}
                        item={item}
                        number={(this.props.page - 1) * 10 + i + 1}
                        key={i}
                        onHandleSubmitRemoveItem={
                          this.props.onHandleSubmitRemoveItem
                        }
                        match={this.props.match}
                        onCheck={this.props.onCheck}
                        list={this.props.list}
                        checked={check}
                        onClickDetail={this.props.onClickDetail}
                        mainDepartmentId={
                          this.props.mainDepartmentId
                            ? this.props.mainDepartmentId
                            : null
                        }
                        onClickComplete={this.props.onClickComplete}
                        auth={this.props.auth}
                        history={this.props.history}
                        actions={this.props.actions}
                        filter={this.props.filter}
                        phongBanId={
                          this.props.phongBanId ? this.props.phongBanId : null
                        }
                        actionPermissions={this.props.actionPermissions}
                        isShowCheckBox={this.props.isShowCheckBox}
                        isLoadingThuHoi={this.props.isLoadingThuHoi}
                        internalDocumentType={this.props.internalDocumentType}
                        listTenDonVi={this.props.listTenDonVi}
                        isCanCheckDocument={this.props.isCanCheckDocument}
                        reloadData={this.props.reloadData}
                        isCheckAllData={this.props.isCheckAllData}
                        keyTabTuTaiLieu={this.props.keyTabTuTaiLieu}
                        page={this.props.page}
                        filterColumn={this.props.filterColumn}
                        isCVCN={this.props.isCVCN}
                        quyensMenu={this.props.quyensMenu}
                      />
                    )
                  })}

                {!this.props.isShowMessengStatistics &&
                  !this.props.isEmpty &&
                  !this.props.isError &&
                  this.props.data &&
                  this.props.data.length > 0 &&
                  this.props.isType === 'CVD' &&
                  this.props.pageCount <= 1 && (
                    <AuthElement permission={[Permission.CONGVANDEN_QUANLY]}>
                      <tr
                        className="no-border-bt"
                        style={{ background: '#fff' }}
                      >
                        <td
                          colSpan={this.props.headers.length}
                          className="error-table-hover"
                        >
                          <div
                            className={classnames('note-issues', {
                              'note-issues-none': this.state.pageCount,
                            })}
                          >
                            <span className="name-note">Chú Thích</span>
                            <ul className="list-note">
                              <li className="note-item">
                                <span className="pt-icon-star pt-icon check-star"></span>
                                Công văn mới
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </AuthElement>
                  )}
                {!this.props.isShowMessengStatistics &&
                  !this.props.isEmpty &&
                  !this.props.isError &&
                  this.props.data &&
                  this.props.data.length > 0 &&
                  this.props.isType === 'CVPB' && (
                    <tr
                      className="footer-list note-list-workPage no-border-bt footer-gridview"
                      style={{ background: '#fff' }}
                    >
                      <td
                        colSpan={this.props.headers.length}
                        className="error-table-hover"
                      >
                        <div
                          className={classnames('note-issues note-work', {
                            'note-issues-none': this.state.pageCount,
                          })}
                        >
                          <span className="name-note">Chú Thích</span>
                          <ul className="list-note">
                            {!this.props.isCVPBDG && !this.props.isCVPBCG && (
                              <li className="note-item">
                                <span className="pt-icon pt-icon-symbol-square style-pbct"></span>
                                <span className="text-qh">
                                  Công việc phòng ban chủ trì
                                </span>
                              </li>
                            )}
                            <li className="note-item">
                              <span className="pt-icon pt-icon-symbol-square style-qh"></span>
                              <span className="text-qh">Công việc quá hạn</span>
                            </li>
                            <li className="note-item">
                              <span className="pt-icon pt-icon-symbol-square style-gqh"></span>
                              <span className="text-qh">
                                Công việc gần quá hạn
                              </span>
                            </li>
                            <li className="note-item">
                              <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet"></span>
                              <span className="text-qh">Đã lưu TTL</span>
                            </li>
                            <li className="note-item">
                              <span className="icon-attention-warning style-icon-DocumentCabinet"></span>
                              <span className="text-qh">Đã báo nhầm</span>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )}
                {this.props.isEmpty === true ||
                this.props.isError === true ||
                this.props.isShowMessengStatistics === true ||
                (this.props.data && this.props.data.length === 0) ? (
                  <tr className="no-border-bt">
                    <td
                      colSpan={this.props.headers.length}
                      className="error-table-hover"
                    >
                      <div className="error-page-container">
                        <div className="error-content">
                          <img
                            src={imageUrl}
                            alt="search-result-not-found"
                            className="img-thumb-error"
                          />
                          <p className="description-note">
                            {this.props.isShowMessengStatistics
                              ? MESSAGE_REQUIRED_STATISTICS
                              : this.props.isError === true
                              ? MESSAGE_ERROR
                              : MESSAGE_NOT_FOUND}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            )}
          </table>
        </form>
      </div>
    )
  }
}

GridViewFilter.defaultProps = {
  maxDate: '',
}

GridViewFilter.propTypes = {
  classNames: PropTypes.string,
  headers: PropTypes.array.isRequired,
  itemShape: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  style: PropTypes.array,
  match: PropTypes.object,
  page: PropTypes.number.isRequired,
  type: PropTypes.any,
  onCheckAll: PropTypes.func,
  onClickComplete: PropTypes.func,
  onClickDetail: PropTypes.func,
  isEmpty: PropTypes.bool,
  auth: PropTypes.object,
  maxDate: PropTypes.string,
}

export default GridViewFilter
