import React, { memo, useState, useEffect } from 'react'
import csx from 'classnames'
import DatePicker from 'react-datepicker'
import { datePickerConfig } from '../../../constants/config'

const CustomDatepicker = ({
  className,
  selected: dateSelected,
  onChange: dateOnChange = () => {},
  noIcon = false,
  wrapperClassName,
  rounded = true,
  ...otherProps
}) => {
  const [selected, setSelected] = useState()

  const _onChange = date => {
    if (!otherProps.disabled) {
      setSelected(date)
      dateOnChange(date)
    }
  }

  useEffect(() => {
    setSelected(dateSelected)
  }, [dateSelected])

  return (
    <div className={wrapperClassName}>
      <DatePicker
        {...datePickerConfig}
        {...otherProps}
        selected={selected}
        onChange={_onChange}
        className={csx(
          datePickerConfig.className,
          {
            'input-icon-datepicker': !noIcon,
            'border-radius-3': rounded,
          },
          className
        )}
      />
    </div>
  )
}

export default memo(CustomDatepicker)
