import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Checkbox } from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import { get, size, isEqual } from 'lodash'
import classNames from 'classnames'

import { Card, Input } from '../index'
import { checkSearchNoAccents } from 'helpers/string'
import DropdownWrapper from './DropdownWrapper'
import { usePrevious } from 'helpers/customHooks'

const SelectSearch = ({
  inputLeftIcon,
  isMutiple = true,
  dataSource,
  onChange = () => {},
  onClose = () => {},
  onClear = () => {},
  placeholder = '',
  selectedAllText,
  value: selectedValue,
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [showList, setShowList] = useState([])
  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [prevSelected, setPrevSelected] = useState(false)

  const prevDropdownOpen = usePrevious(dropdownOpen)

  const toggleCheck =
    ({ value, ...props }, isMutiple = true) =>
    e => {
      const { checked } = e.target
      const newValue = { value, ...props }
      let newSelected = [newValue]
      if (isMutiple) {
        newSelected = checked
          ? [...selected, newValue]
          : selected.filter(select => select?.value !== value)
      }

      mountedSet(setSelected, newSelected)
      onChange(newSelected)
      if (!isMutiple) {
        mountedSet(setDropdownOpen, false)
      }
    }

  const _onClose = useCallback(() => {
    mountedSet(setSearch, '')
    mountedSet(setShowList, dataSource)

    if (!isEqual(prevSelected.sort(), selected.sort())) {
      onClose(selected)
    }
  }, [onClose, prevSelected, selected, dataSource])

  const _toggleDropdown = useCallback(
    open => {
      mountedSet(setDropdownOpen, open)
      if (!open) {
        _onClose()
      }
    },
    [_onClose]
  )

  const _onClear = () => {
    mountedSet(setSelected, [])
    onClear([])
  }

  const changeSearch = (text = '') => {
    mountedSet(setSearch, text)
    mountedSet(
      setShowList,
      dataSource.filter(({ label }) => checkSearchNoAccents(label, text))
    )
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setShowList, dataSource || [])
  }, [dataSource])

  useEffect(() => {
    mountedSet(setSelected, selectedValue || [])
  }, [selectedValue])

  useEffect(() => {
    if (prevDropdownOpen !== dropdownOpen && dropdownOpen) {
      mountedSet(setPrevSelected, selectedValue || [])
    }
  }, [prevDropdownOpen, dropdownOpen, selectedValue])

  return (
    <DropdownWrapper
      onClear={_onClear}
      value={
        !!size(dataSource)
          ? selectedAllText && size(selected) === size(dataSource)
            ? selectedAllText
            : dataSource
                .filter(({ value }) => selected.some(e => e?.value === value))
                .map(({ label }) => label)
                .join(', ')
          : undefined
      }
      placeholder={placeholder}
      className="w-100"
      leftIcon={inputLeftIcon}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
    >
      <Card className="popup pl10 pr10 pb5">
        <Card paddingVertical>
          <Input
            className="cpc-input-search"
            leftIconName="search"
            onChange={changeSearch}
            placeholder="Tìm kiếm"
            value={search}
          />
        </Card>
        <Scrollbars autoHeight autoHeightMax={300}>
          <Card>
            {showList.map((elm, index) => (
              <Card key={index} className="mt5 mb5">
                {isMutiple ? (
                  <Checkbox
                    checked={selected.some(e => e?.value === elm?.value)}
                    className={classNames('cpc-checkbox', 'font-size-13')}
                    label={elm?.label}
                    onChange={toggleCheck(elm, isMutiple)}
                  />
                ) : (
                  <span
                    className="font-size-13 pointer"
                    onClick={toggleCheck(elm, isMutiple)}
                  >
                    {elm?.label}
                  </span>
                )}
              </Card>
            ))}
          </Card>
        </Scrollbars>
      </Card>
    </DropdownWrapper>
  )
}

export default memo(SelectSearch)
