import { schema } from 'normalizr'

const SignNumberProject = new schema.Entity(
  'signNumberProjects',
  {},
  {
    idAttribute: signNumberProject => signNumberProject.kySoId,
  }
)

export default SignNumberProject
