import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Intent } from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import { FILE_URL } from '../../constants/Api'
import 'react-rangeslider/lib/index.css'
import NotFound from './NotFound'
import pdfSupport from 'browser-pdf-support'

const MIN_ZOOM_LEVEL = 1
const MAX_ZOOM_LEVEL = 300
const ZOOM_RATIO = 1.007
const ZOOM_BUTTON_INCREMENT_SIZE = 50

class DetailFileContent extends Component {
  scrollPages = []
  pagesLoaded = 2

  static propTypes = {
    actions: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    onClickShowInfoMobile: PropTypes.func,
  }

  static defaultProps = {
    onClickShowInfoMobile: () => {},
  }

  refHandlers = {
    documentRef: (ref: DetailFileContent) => (this.documentRef = ref),
  }

  state = {
    total: 0,
    current: 0,
    formCurrent: 0,
    pages: {},
    zoomLevel: MIN_ZOOM_LEVEL,
    fullScreen: false,
    offsetX: 0,
    offsetY: 0,
    error: null,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    documentWidth: 0,
    pagesLoaded: 2,
    loadingTopbar: true,
    fileName: null,
    fileId: this.props.file && this.props.file.fileId,
    migratedPage: 0,
    migratedPages: 0,
    messageErrorMigratedFile: null,
    statusErrorMigratedFile: null,
    fileMigrated: null,
  }

  constructor(props) {
    super(props)
    this.handleResizeWidow = this.handleResizeWidow.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleZoomOut = this.handleZoomOut.bind(this)
    this.handleZoomIn = this.handleZoomIn.bind(this)
    this.handleFullScreen = this.handleFullScreen.bind(this)
    this.handleMoveStart = this.handleMoveStart.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleMoveEnd = this.handleMoveEnd.bind(this)
    this.handlePointerEvent = this.handlePointerEvent.bind(this)
    this.handleTouch = this.handleTouch.bind(this)
    this.handleScrollbars = this.handleScrollbars.bind(this)
    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.handleGetErrorPdf = this.handleGetErrorPdf.bind(this)
    this.handlePreviousMigrated = this.handlePreviousMigrated.bind(this)
    this.handleNextMigrated = this.handleNextMigrated.bind(this)
  }

  componentWillMount() {
    this.timeout = null

    // Used in panning zoomed images
    this.moveStartX = 0
    this.moveStartY = 0
    this.moveStartOffsetX = 0
    this.moveStartOffsetY = 0
    this.currentAction = 'none'

    this.touches = {
      touchstart: { x: -1, y: -1 },
      touchmove: { x: -1, y: -1 },
      touchend: false,
      xDirection: 'undetermined',
      yDirection: 'undetermined',
    }
  }

  componentDidMount() {
    window.addEventListener('pointerdown', this.handlePointerEvent)
    if (this.fileContent) {
      this.fileContent.addEventListener('pointermove', this.handlePointerEvent)
    }
    window.addEventListener('pointerup', this.handlePointerEvent)
    window.addEventListener('pointercancel', this.handlePointerEvent)
    window.addEventListener('resize', this.handleResizeWidow)

    const filename =
      this.props.file && this.props.file.fileName
        ? this.props.file.fileName
        : this.props.file.url
        ? this.props.file.url.substring(
            this.props.file.url.lastIndexOf('/') + 1
          )
        : ''
    const fileId =
      this.props.file && this.props.file.fileId ? this.props.file.fileId : null
    this.setState({
      fileName: filename,
      documentWidth: this.documentRef.offsetWidth,
      fileId,
    })

    !this.props.file.migrated &&
      this.props.actions.fileTotalPages(filename).then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          const total = res.payload.data.result
          return this.setState(
            {
              total: total,
              loadingTopbar: false,
            },
            () => {
              this.viewByPage(2)
              this.props.onFinishLoadingFile && this.props.onFinishLoadingFile()
            }
          )
        }
        this.setState({
          loadingTopbar: false,
          error: 'Có lỗi: không thể tải tài liệu.',
        })
        this.props.onFinishLoadingFile && this.props.onFinishLoadingFile()
      })

    if (this.props.file && this.props.file.migrated === true) {
      this.props.actions
        .documentFetchMigratedFile(FILE_URL, this.props.file.url)
        .then(res => {
          let statusErrorMigratedFile = null
          let fileMigrated = null
          let messageErrorMigratedFile = null
          if (res.payload && res.payload.data) {
            fileMigrated = URL.createObjectURL(res.payload.data)
          }
          if (res.error && res.error.response && res.error.response.status) {
            statusErrorMigratedFile = res.error.response.status
            messageErrorMigratedFile = 'Có lỗi: không thể tải tài liệu.'
          }
          this.setState(
            {
              fileMigrated,
              statusErrorMigratedFile,
              messageErrorMigratedFile,
              loadingTopbar: false,
            },
            () => {
              this.props.onFinishLoadingFile && this.props.onFinishLoadingFile()
            }
          )
        })
        .catch(error => {
          this.setState(
            {
              messageErrorMigratedFile: 'Có lỗi: không thể tải tài liệu.',
              loadingTopbar: false,
            },
            () => {
              this.props.onFinishLoadingFile && this.props.onFinishLoadingFile()
            }
          )
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevProps.file, this.props.file) &&
      !this.props.file.migrated
    ) {
      window.addEventListener('pointerdown', this.handlePointerEvent)
      if (this.fileContent) {
        this.fileContent.addEventListener(
          'pointermove',
          this.handlePointerEvent
        )
      }
      window.addEventListener('pointerup', this.handlePointerEvent)
      window.addEventListener('pointercancel', this.handlePointerEvent)
      window.addEventListener('resize', this.handleResizeWidow)

      const filename =
        this.props.file && this.props.file.fileName
          ? this.props.file.fileName
          : this.props.file.url
          ? this.props.file.url.substring(
              this.props.file.url.lastIndexOf('/') + 1
            )
          : ''
      const fileId =
        this.props.file && this.props.file.fileId
          ? this.props.file.fileId
          : null
      this.setState({
        filename,
        documentWidth: this.documentRef.offsetWidth,
        loadingTopbar: true,
        fileId,
      })
      this.props.actions
        .fileTotalPages(filename)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            const total = res.payload.data.result
            return this.setState(
              {
                total: total,
                loadingTopbar: false,
                pages: {},
              },
              () => {
                this.viewByPage(1)
              }
            )
          }
          this.setState({
            loadingTopbar: false,
            error: 'Có lỗi: không thể tải tài liệu.',
          })
        })
        .catch(() => {
          this.setState({
            loadingTopbar: false,
            error: 'Có lỗi: không thể tải tài liệu.',
          })
        })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pointerdown', this.handlePointerEvent)
    if (this.fileContent) {
      this.fileContent.removeEventListener(
        'pointermove',
        this.handlePointerEvent
      )
    }
    window.removeEventListener('pointerup', this.handlePointerEvent)
    window.removeEventListener('pointercancel', this.handlePointerEvent)
    window.removeEventListener('resize', this.handleResizeWidow)
  }

  handleGetErrorPdf = error => {
    let messageErrorMigratedFile = null
    if (error && error.name) {
      switch (error.name) {
        case 'MissingPDFException':
          messageErrorMigratedFile = 'File không tồn tại.'
          break
        default:
          messageErrorMigratedFile = 'Có lỗi: không thể tải tài liệu.'
      }
    }
    return this.setState({ messageErrorMigratedFile })
  }

  async handleFullScreen(force = null) {
    await this.setState({
      fullScreen:
        force === true || force === false ? force : !this.state.fullScreen,
    })
    await this.handleResizeWidow()
    document.body.style.overflow = 'visible'
  }

  handleSubmit(e) {
    e.preventDefault()
    if (
      isNaN(this.state.formCurrent) ||
      this.state.formCurrent < 1 ||
      this.state.formCurrent > this.state.total
    ) {
      this.props.actions.commonAddToasterMessage({
        message: `Chỉ được phép nhập giá trị từ 1 đến ${this.state.total}`,
        intent: Intent.WARNING,
      })
      return
    }
    this.viewByPage(parseInt(this.state.formCurrent, 10))
  }

  handleChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleZoomIn() {
    this.changeZoom(this.state.zoomLevel + ZOOM_BUTTON_INCREMENT_SIZE)
  }

  handleZoomOut() {
    this.changeZoom(this.state.zoomLevel - ZOOM_BUTTON_INCREMENT_SIZE)
  }

  handlePrevious() {
    if (this.state.current <= 1) {
      return
    }

    this.changeZoom(MIN_ZOOM_LEVEL)
    this.viewByPage(this.state.current - 1)
  }

  handleNext() {
    if (this.state.current >= this.state.total) {
      return
    }

    this.changeZoom(MIN_ZOOM_LEVEL)
    this.viewByPage(this.state.current + 1)
  }

  async handleSliderChange(value) {
    await this.setState({
      current: parseInt(value, 10),
    })
    this.viewByPage(this.state.current)
  }

  handleTouch(e) {
    e.preventDefault()
    if (typeof e !== 'undefined') {
      if (typeof e.touches !== 'undefined') {
        const touch = e.touches[0]
        switch (e.type) {
          case 'touchstart':
          case 'touchmove':
            this.touches[e.type].x = touch.pageX
            this.touches[e.type].y = touch.pageY
            break
          case 'touchend':
            this.touches[e.type] = true
            if (
              this.touches.touchstart.x > -1 &&
              this.touches.touchmove.x > -1
            ) {
              this.touches.xDirection =
                this.touches.touchstart.x < this.touches.touchmove.x
                  ? 'right'
                  : 'left'
              this.touches.yDirection =
                this.touches.touchstart.y < this.touches.touchmove.y
                  ? 'down'
                  : 'up'

              if (this.touches.yDirection === 'up') {
                this.handleNext()
              } else {
                this.handlePrevious()
              }
            }
            break
          default:
            break
        }
      }
      return false
    }
  }

  handleResizeWidow() {
    const values =
      this.scrollElement && this.scrollElement.getValues()
        ? this.scrollElement.getValues()
        : null
    const current = this.getCurentPages(values)

    this.setState({
      current,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      documentWidth: this.documentRef.offsetWidth,
    })
  }

  getCurentPages = values => {
    let pageHeights = 0
    let i = 1

    if (this.scrollPages && values) {
      while (
        this.scrollPages[i] &&
        values.scrollTop >= pageHeights + this.scrollPages[i] / 2
      ) {
        pageHeights += this.scrollPages[++i]
      }
    }

    return i > this.state.total ? this.state.total : i
  }

  viewByPage(page = 1) {
    if (page < 1) {
      return
    }

    this.setState({ current: page === 2 ? 1 : page })

    const pages = this.state.pages
    const preload = p => {
      if (p < 1 || p > this.state.total) {
        return
      }

      if (!pages[p] || !pages[p].data || pages[p].index !== p) {
        return this.getFileByPage(p)
      }
    }

    for (let i = 1; i <= page; i++) {
      preload(i)
    }

    this.pagesLoaded = page
  }

  async getFileByPage(page = 1) {
    const pages = this.state.pages

    if (pages[page] && pages[page].loaded === true) {
      return false
    }

    if (!pages[page]) {
      pages[page] = { loaded: false }
    }
    pages[page].index = page
    await this.setState({ pages })
    await this.props.actions
      .fileViewFile(this.props.file.fileId, page)
      .then(res => {
        pages[page].loaded = true

        if (page > this.pagesLoaded) {
          this.pagesLoaded = page
        }

        if (!res) return this.setState({ pages })

        if (res.error || !res.payload || !res.payload.data) {
          return (pages[page].error = 'Có lỗi: không thể tải trang này')
        }

        const imgBase64 = new Buffer(res.payload.data, 'binary').toString(
          'base64'
        )
        pages[page].data = imgBase64
      })
    return this.setState({ pages })
  }

  async handleScrollbars(values) {
    const nextPage = this.pagesLoaded + 1
    if (!this.props.file || this.props.file.migrated) {
      return
    }

    const current = this.getCurentPages(values)

    if (current !== this.state.current) {
      this.setState({ current })
    }

    if (
      Object.values(this.state.pages).length >= this.state.total ||
      nextPage > this.state.total
    ) {
      return
    }

    if (values.top < 0.95) {
      return
    }

    if (this.state.pages[nextPage]) {
      return
    }

    await this.setState({ loading: true })
    await this.getFileByPage(nextPage)
    await this.setState({ loading: false })
  }

  handleDownload(id) {
    return this.props.actions.downloadFile(id)
  }

  // Functions for migrated
  onDocumentComplete = migratedPages => {
    this.setState({ migratedPage: 1, migratedPages })
  }

  onPageComplete = migratedPage => {
    this.setState({ migratedPage })
  }

  handlePreviousMigrated = () => {
    this.setState({ migratedPage: this.state.migratedPage - 1 })
  }

  handleNextMigrated = () => {
    this.setState({ migratedPage: this.state.migratedPage + 1 })
  }

  renderPagination = (migratedPage, migratedPages) => {
    let previousButton = (
      <li
        className="item-btn btn-pager previous"
        onClick={this.handlePreviousMigrated}
      >
        <span className="click-renderPagination pt-icon pt-icon-circle-arrow-left view-full-icon"></span>
      </li>
    )
    if (migratedPage === 1) {
      previousButton = (
        <li className="item-btn btn-pager previous disabled">
          <span className="click-renderPagination pt-icon pt-icon-circle-arrow-left view-full-icon"></span>
        </li>
      )
    }
    let nextButton = (
      <li className="item-btn btn-pager next" onClick={this.handleNextMigrated}>
        <span className="click-renderPagination pt-icon pt-icon-circle-arrow-right view-full-icon"></span>
      </li>
    )
    if (migratedPage === migratedPages) {
      nextButton = (
        <li className="item-btn btn-pager next disabled">
          <span className="click-renderPagination pt-icon pt-icon-circle-arrow-right view-full-icon"></span>
        </li>
      )
    }
    return (
      <nav className="migrated-file-buttons">
        <ul className="pager paper-viewfile">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    )
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const hasPDFViewer = pdfSupport()
    let fileUrl = `${FILE_URL}/api/file/get/${this.state.fileId}?BearerToken=${token}`

    // migrated file
    let pagination = null
    if (this.state.migratedPages) {
      pagination = this.renderPagination(
        this.state.migratedPage,
        this.state.migratedPages
      )
    }

    let extension = null
    if (this.props.file && this.props.file.url) {
      extension = this.props.file.url
        .slice(((this.props.file.url.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    }

    return (
      <div
        className={classnames('view-document', {
          'cpc-view-fullscreen': this.state.fullScreen,
          'cpc-mobile': this.isMobileScreen(),
          'fixed-viewfile':
            !this.state.fullScreen &&
            this.props.boxMenuFixTop &&
            !this.props.enableScrollFixedMenu &&
            window.innerWidth >= 1024 &&
            window.location.href.indexOf('chi-tiet') > -1,
          'fixed-viewfile-hasSidebar':
            !this.state.fullScreen &&
            this.props.boxMenuFixTop &&
            this.props.enableScrollFixedMenu &&
            window.innerWidth >= 1024 &&
            window.location.href.indexOf('chi-tiet') > -1,
        })}
        style={{
          display:
            this.isMobileScreen() && !this.state.fullScreen ? 'none' : 'block',
        }}
        ref={this.refHandlers.documentRef}
      >
        <div className="action-top">
          <div className="action-mobile-document">
            <div className="row">
              <div className="col-xs-6">
                <span
                  onClick={() => {
                    this.handleFullScreen(false)
                  }}
                >
                  <i className="icon-expand-arrows view-full-icon"></i>
                </span>
                {pagination}
                <span className="count-page">
                  {this.state.current}/{this.state.total}
                </span>
                <span
                  className="icon-Thong_Tin_Giao_Viec info-icon"
                  onClick={this.props.onClickShowInfoMobile}
                />
              </div>
            </div>
          </div>
          <ul className="button-list">
            {this.state.total &&
            !this.props.file.migrated &&
            (extension !== 'pdf' || !hasPDFViewer) ? (
              <li className="item-btn">
                Trang&nbsp;
                <span className="count-page line-right">
                  {this.state.current}/{this.state.total}
                </span>
              </li>
            ) : null}
            {this.props.file && (
              <li className="item-btn">
                <a
                  href={fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="line-right"
                >
                  <i className="pt-icon icon-File_Tham_Khao"></i>
                  <span className="dowload">XEM VĂN BẢN GỐC</span>
                </a>
              </li>
            )}
            {!this.props.file.migrated &&
            (extension !== 'pdf' || !hasPDFViewer) ? (
              <li className="item-btn">
                <span onClick={this.handleZoomOut} className="line-right">
                  <i className="icon-zoom-out"></i>
                </span>
              </li>
            ) : null}
            {!this.props.file.migrated &&
            (extension !== 'pdf' || !hasPDFViewer) ? (
              <li className="item-btn">
                <span onClick={this.handleZoomIn} className="line-right">
                  <i className="icon-zoom-in"></i>
                </span>
              </li>
            ) : null}
            {this.props.file.migrated && extension === 'pdf' && pagination}
            <li className="item-btn">
              <span onClick={this.handleFullScreen}>
                <i
                  className={classnames({
                    'icon-expand-arrows': !this.state.fullScreen,
                    'icon-minimize': this.state.fullScreen,
                  })}
                ></i>
              </span>
            </li>
          </ul>
          {this.state.loadingTopbar ? (
            <div className="loader-line"></div>
          ) : null}
        </div>

        {this.props.file.migrated && extension !== 'pdf' && (
          <NotFound
            message={
              'Đây là file từ hệ thống cũ chuyển qua. Vui lòng chọn "Tải file về máy" để xem file'
            }
          />
        )}
        {this.props.file.migrated &&
          extension === 'pdf' &&
          this.state.messageErrorMigratedFile &&
          this.state.statusErrorMigratedFile !== 404 && (
            <NotFound message={this.state.messageErrorMigratedFile} />
          )}
        {this.props.file.migrated &&
          extension === 'pdf' &&
          this.state.messageErrorMigratedFile &&
          this.state.statusErrorMigratedFile === 404 && <NotFound />}

        {this.props.file.migrated &&
          extension === 'pdf' &&
          !this.state.messageErrorMigratedFile &&
          this.state.fileMigrated &&
          !this.isMobileScreen() && (
            <div className="iframe-container">
              <div className="embed-container">
                <iframe
                  frameBorder="0"
                  scrolling="no"
                  src={fileUrl}
                  title="File"
                  allowFullScreen
                />
              </div>
            </div>
          )}
        {!this.props.file.migrated &&
          hasPDFViewer &&
          extension === 'pdf' &&
          !this.isMobileScreen() && (
            <div className="iframe-container">
              <div className="embed-container">
                <iframe
                  frameBorder="0"
                  scrolling="no"
                  src={fileUrl}
                  title="File"
                  allowFullScreen
                />
              </div>
            </div>
          )}
        {!this.props.file.migrated &&
          (extension !== 'pdf' || !hasPDFViewer || this.isMobileScreen()) && (
            <div
              className="body-view-file-mobile"
              ref={e => (this.fileContent = e)}
            >
              <Scrollbars
                style={{ width: '100%', height: '100%' }}
                onScrollFrame={this.handleScrollbars}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={this.documentHeight()}
                autoHide
                ref={e => (this.scrollElement = e)}
              >
                {this.state.error && <NotFound message={this.state.error} />}
                <div
                  style={
                    this.state.zoomLevel !== MIN_ZOOM_LEVEL
                      ? this.imageStyle()
                      : {}
                  }
                >
                  {Object.values(this.state.pages).map((content, i) => (
                    <div key={i}>
                      {content.data && (
                        <img
                          src={`data:image/png;base64,${content.data}`}
                          alt=""
                          ref={e => {
                            if (e && e.clientHeight) {
                              this.scrollPages[i + 1] = e.clientHeight
                            }
                          }}
                        />
                      )}
                      {!this.state.loading && content.loaded === false && (
                        <div
                          className="cpc-loading"
                          style={{ height: this.documentHeight() }}
                        >
                          <div className="spinner">
                            <div className="bounce1" />
                            <div className="bounce2" />
                            <div className="bounce3" />
                          </div>
                        </div>
                      )}
                      {content.error && (
                        <div
                          className="cpc-error"
                          style={{ height: this.documentHeight() }}
                        >
                          <NotFound message={content.error} />
                        </div>
                      )}
                    </div>
                  ))}
                  {this.state.loading &&
                    Object.values(this.state.pages).length < this.state.total &&
                    this.pagesLoaded + 1 < this.state.total && (
                      <div className="cpc-loading" style={{ height: 100 }}>
                        <div className="spinner">
                          <div className="bounce1" />
                          <div className="bounce2" />
                          <div className="bounce3" />
                        </div>
                      </div>
                    )}
                </div>
              </Scrollbars>
            </div>
          )}
      </div>
    )
  }

  changeZoom(zoomLevel, clientX, clientY) {
    const nextZoomLevel = Math.max(
      MIN_ZOOM_LEVEL,
      Math.min(MAX_ZOOM_LEVEL, zoomLevel)
    )

    if (nextZoomLevel === this.state.zoomLevel) {
      return
    } else if (nextZoomLevel === MIN_ZOOM_LEVEL) {
      return this.setState({
        zoomLevel: nextZoomLevel,
        offsetX: 0,
        offsetY: 0,
      })
    }

    // https://github.com/fritz-c/react-image-lightbox
    // const imageBaseSize = this.getBestImageForType('mainSrc');
    const imageBaseSize = {
      width: 773,
      height: 1000,
    }
    if (imageBaseSize === null) {
      return
    }

    const currentZoomMultiplier = this.getZoomMultiplier()
    const nextZoomMultiplier = this.getZoomMultiplier(nextZoomLevel)

    // // Default to the center of the image to zoom when no mouse position specified
    // const boxRect = this.getLightboxRect();
    const boxRect = {
      width: 555,
      height: 707,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }
    const pointerX =
      typeof clientX !== 'undefined'
        ? clientX - boxRect.left
        : boxRect.width / 2
    const pointerY =
      typeof clientY !== 'undefined'
        ? clientY - boxRect.top
        : boxRect.height / 2

    const currentImageOffsetX =
      (boxRect.width - imageBaseSize.width * currentZoomMultiplier) / 2
    const currentImageOffsetY =
      (boxRect.height - imageBaseSize.height * currentZoomMultiplier) / 2

    const currentImageRealOffsetX = currentImageOffsetX - this.state.offsetX
    const currentImageRealOffsetY = currentImageOffsetY - this.state.offsetY

    const currentPointerXRelativeToImage =
      (pointerX - currentImageRealOffsetX) / currentZoomMultiplier
    const currentPointerYRelativeToImage =
      (pointerY - currentImageRealOffsetY) / currentZoomMultiplier

    const nextImageRealOffsetX =
      pointerX - currentPointerXRelativeToImage * nextZoomMultiplier
    const nextImageRealOffsetY =
      pointerY - currentPointerYRelativeToImage * nextZoomMultiplier

    const nextImageOffsetX =
      (boxRect.width - imageBaseSize.width * nextZoomMultiplier) / 2
    const nextImageOffsetY =
      (boxRect.height - imageBaseSize.height * nextZoomMultiplier) / 2

    let nextOffsetX = nextImageOffsetX - nextImageRealOffsetX
    let nextOffsetY = nextImageOffsetY - nextImageRealOffsetY

    this.setState({
      zoomLevel: nextZoomLevel,
      offsetX: nextOffsetX,
      offsetY: nextOffsetY,
    })
  }

  imageStyle() {
    const zoomMultiplier = this.getZoomMultiplier()
    const transform = this.getTransform({
      x: this.state.offsetX,
      y: this.state.offsetY,
      zoom: zoomMultiplier,
    })

    return {
      ...transform,
      cursor: this.state.zoomLevel === MIN_ZOOM_LEVEL ? 'auto' : 'move',
    }
  }

  getTransform({ x = null, y = null, zoom = null }) {
    const transforms = []
    if (x !== null || y !== null) {
      transforms.push(`translate3d(${x || 0}px,${y || 0}px,0)`)
    }

    if (zoom !== null) {
      transforms.push(`scale3d(${zoom},${zoom},1)`)
    }

    return {
      transform: transforms.length === 0 ? 'none' : transforms.join(' '),
    }
  }

  handlePointerEvent(event) {
    switch (event.type) {
      case 'pointerdown':
        this.handleMoveStart(event)
        break
      case 'pointermove':
        event.preventDefault()
        this.handleMove(event)
        break
      case 'pointerup':
      case 'pointercancel':
        this.handleMoveEnd(event)
        break
      default:
        break
    }
  }

  // Handle move start over the lightbox container
  // This happens:
  // - On a mouseDown event
  // - On a touchstart event
  handleMoveStart({ x: clientX, y: clientY }) {
    this.moveStartX = clientX
    this.moveStartY = clientY
    this.moveStartOffsetX = this.state.offsetX
    this.moveStartOffsetY = this.state.offsetY
    this.currentAction = 'move'
  }

  // Handle dragging over the lightbox container
  // This happens:
  // - After a mouseDown and before a mouseUp event
  // - After a touchstart and before a touchend event
  handleMove({ x: clientX, y: clientY }) {
    if (
      this.currentAction !== 'move' ||
      this.state.zoomLevel === MIN_ZOOM_LEVEL
    ) {
      return
    }

    const newOffsetX = -(this.moveStartX - clientX) + this.moveStartOffsetX
    const newOffsetY = -(this.moveStartY - clientY) + this.moveStartOffsetY
    if (
      this.state.offsetX !== newOffsetX ||
      this.state.offsetY !== newOffsetY
    ) {
      this.setState({
        offsetX: newOffsetX,
        offsetY: newOffsetY,
      })
    }
  }

  handleMoveEnd() {
    this.moveStartX = 0
    this.moveStartY = 0
    this.moveStartOffsetX = 0
    this.moveStartOffsetY = 0
    this.currentAction = 'none'
  }

  getLightboxRect() {
    return {
      width: 555,
      height: 707,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }
  }

  getZoomMultiplier(zoomLevel = this.state.zoomLevel) {
    return ZOOM_RATIO ** zoomLevel
  }

  isMobileScreen() {
    return this.state.screenWidth < 1024
  }

  isScreenPortrait() {
    return this.state.screenWidth < this.state.screenHeight
  }

  documentHeight() {
    return this.state.fullScreen
      ? window.innerHeight - 57
      : (this.state.documentWidth / 3) * 4
  }
}

export default DetailFileContent
