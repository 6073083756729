import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../../components/layout'
import { HeadingTableSort, PopupConfirm } from '../../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../../constants/Table'
import * as MESSAGE from '../../../constants/MessageForm'
import * as POPUP from '../../../constants/Popup'
import * as Actions from '../../../actions'
import {
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
  Checkbox,
} from '@blueprintjs/core'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import {
  MESSAGE_NOT_FOUND,
  MESSAGE_ERROR,
} from '../../../constants/MessageForm'
import { get } from 'lodash'

const LINK = '/he-thong/thoi-gian-luu/chinh-sua'
class ThoiGianLuuDanhSach extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenPopup: false,
      isLoadingButton: false,
      sort: undefined,
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
      isMove: false,
    }
  }

  componentDidMount() {
    this.submitFilter()
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      thoiHanLuuId: id,
    })
  }

  submitFilter = async () => {
    this.setState({ isLoading: true })
    try {
      const params = {
        isAll: true,
      }
      await this.props.actions.getThoiGianLuuDanhSach(params).then(res => {
        let isEmpty = false
        let isError = false
        if (
          res &&
          !res.error &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.items.length === 0
        ) {
          isEmpty = true
        }

        if (res.error || (res.payload && res.payload.status !== 200)) {
          isError = true
        }

        let data = []
        if (
          res &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.items !== 0
        ) {
          data = res.payload.data.result.items
        }

        this.setState({ isEmpty, isError, items: data })
      })
    } catch (error) {
      this.setState({ isError: true })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleSubmitRemoveItem = async () => {
    this.setState({ isLoadingButton: true })
    try {
      await this.props.actions
        .deleteThoiGianLuu(this.state.thoiHanLuuId)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.XOA_THOIGIANLUU_KHONGTHANHCONG,
              intent: Intent.DANGER,
            })
          }

          this.submitFilter()
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.XOA_THOIGIANLUU,
            intent: Intent.SUCCESS,
          })
        })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.XOA_THOIGIANLUU_KHONGTHANHCONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.handleClickPopup()
      this.setState({ isLoadingButton: false })
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState(
        {
          items: arrayMove(this.state.items, oldIndex, newIndex),
          isMove: true,
        },
        () => {
          const array = []
          this.state.items.map(item => array.push(item.thoiHanLuuId))
          if (array.length > 0 && this.state.isMove === true) {
            this.props.actions.putThoiGianLuuSapXepDanhSach(array).then(res => {
              if (
                res.payload &&
                res.payload.data.result &&
                res.payload.data.result === true
              ) {
                this.props.actions
                  .commonFetchSecurities(null, true)
                  .then(res => {
                    if (
                      res.error ||
                      (res.payload && res.payload.status !== 200)
                    ) {
                      this.setState({ isError: true })
                    }
                  })
                this.setState({ isMove: false })
                return this.props.actions.commonAddToasterMessage({
                  message: MESSAGE.TOATS_MESSAGE_SUCCESS,
                  intent: Intent.SUCCESS,
                })
              } else {
                this.setState({
                  isMove: false,
                  items: arrayMove(this.state.items, newIndex, oldIndex),
                })
                return this.props.actions.commonAddToasterMessage({
                  message: MESSAGE.TOATS_MESSAGE_FAIL,
                  intent: Intent.DANGER,
                })
              }
            })
          }
        }
      )
    }
  }

  handleEnabledChange = async (e, id) => {
    let target = e.target
    let checked = target.checked
    try {
      const params = {
        is_active: checked,
      }
      await this.props.actions
        .putThoiGianLuuCapNhatTrangThai(id, params)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }

          this.submitFilter()
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
    } catch (error) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        intent: Intent.DANGER,
      })
    }
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    // icon move
    const DragHandle = SortableHandle(() => (
      <span className="icon-move-drap"></span>
    ))
    // items move
    const SortableItem = SortableElement(({ i, value }) => {
      return (
        <div className="table-rows">
          <div className="table-cols-content width-col-5">
            <DragHandle />
          </div>
          <div className="table-cols-content width-col-5 center">
            {i < 10 ? '0' + i : i}
          </div>
          <div className="table-cols-content width-col-45">
            {get(value, 'tenThoiHanLuu')}
          </div>
          <div className="table-cols-content width-col-20 center">
            <span className="text-content">
              <Checkbox
                className="checkbox-item"
                onChange={e => this.handleEnabledChange(e, value.thoiHanLuuId)}
                defaultChecked={get(value, 'isActive')}
              />
            </span>
          </div>
          <div className="table-cols-content width-col-5 center">
            <Popover
              popoverClassName="pt-popover-content-sizing"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Link className="item" to={`${LINK}/${value.thoiHanLuuId}`}>
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                  <span
                    className="item"
                    onClick={() => this.handleClickPopup(value.thoiHanLuuId)}
                  >
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          </div>
        </div>
      )
    })
    // list foreach
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="sortableList">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              i={index + 1}
            />
          ))}
        </div>
      )
    })
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="heading-page">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="text-heading-page">
                <span className="icon-thoigianluu icon-heading"></span>
                <span className="text-heading">Danh mục thời hạn bảo quản</span>
              </h3>
              <Link
                to="/he-thong/thoi-gian-luu/them-moi"
                className="btn-action pull-right hidden-btn btn-minwidth-auto"
              >
                <span className="name-btn">Tạo mới</span>
                <span className="pt-icon pt-icon-small-plus"></span>
              </Link>
            </div>
          </div>
        </div>
        <div className="listrole-container">
          <div className="page-list-container page-list-role-container">
            <div className="table-container table-subcollap-container pb0">
              <div className="table-scroll">
                <div className="collapse-panel-content">
                  <div className="table-display">
                    <div className="table-display-container">
                      <HeadingTableSort data={headers.ThoiGianLuuDanhSach} />
                      <div className="table-body-container">
                        {this.state.isLoading ? (
                          <div className="table-loading-system">
                            {ItemLoading}
                            {ItemLoading}
                            {ItemLoading}
                          </div>
                        ) : (
                          !this.state.isError &&
                          !this.state.isEmpty && (
                            <SortableList
                              helperClass="SortableHelper"
                              items={this.state.items || []}
                              onSortEnd={this.onSortEnd}
                              useDragHandle={true}
                              lockAxis="y"
                            />
                          )
                        )}
                        {/* Dữ liệu rỗng */}
                        {this.state.isEmpty &&
                          !this.state.isLoading &&
                          !this.state.isError && (
                            <div className="error-page-container">
                              <div className="error-content">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    '/images/empty-default.png'
                                  }
                                  alt="search-result-not-found"
                                  className="img-thumb-error"
                                />
                                <p className="description-note">
                                  {MESSAGE_NOT_FOUND}
                                </p>
                              </div>
                            </div>
                          )}
                        {/* Lỗi dữ liệu */}
                        {this.state.isError &&
                          !this.props.isLoading &&
                          !this.state.isEmpty && (
                            <div className="error-page-container">
                              <div className="error-content">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    '/images/Server.png'
                                  }
                                  alt="error"
                                  className="img-thumb-error"
                                />
                                <p className="description-note">
                                  {MESSAGE_ERROR}
                                </p>
                              </div>
                            </div>
                          )}
                        <PopupConfirm
                          isOpen={this.state.isOpenPopup}
                          isLoading={this.state.isLoadingButton}
                          onClose={this.handleClickPopup}
                          title={POPUP.XOA_THOIGIANLUU}
                          text={POPUP.TEXT_BUTTON_SUBMIT}
                          onSubmit={this.handleSubmitRemoveItem}
                        >
                          {POPUP.TEXT_SUBMIT_BODY}
                        </PopupConfirm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThoiGianLuuDanhSach)
