import React, { memo } from 'react'
import { Icon, Tooltip, Intent, Position } from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import withRouter from 'react-router/withRouter'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { get, size } from 'lodash'
import csx from 'classnames'

import { isValidCondition } from 'helpers/Helper'
import { Authorization } from '../auth'
import { Icon as Icon2 } from 'components/newCommon'
import { Badge } from 'components/newCommon'

const AuthElement = Authorization.Element

const Sidebar = memo(
  ({
    auth,
    menu = [],
    isMobileMenuShown,
    closeMenuMobile,
    isMenuShown,
    toggleMenu,
    match,
    counters,
  }) => {
    const checkActive = (arrayPath, link) => {
      if (
        size(arrayPath) &&
        !!arrayPath.find(elm => link.lastIndexOf(elm) === 0)
      ) {
        return true
      }
    }

    const checkNotPermission = notPermission => {
      if (isValidCondition(auth.permission, notPermission, 'OR')) {
        return true
      }

      return false
    }

    return (
      <div
        className={csx('sidebar-menu-wrapper', {
          'sidebar-mobile-open': isMobileMenuShown,
        })}
      >
        <Scrollbars autoHide autoHideTimeout={100} autoHideDuration={200}>
          <div className="menu">
            {/* Icon close for mobile view */}
            <div className="icon-close-menu">
              <Icon iconName="cross" onClick={closeMenuMobile} />
            </div>
            {/* Icon toggle for pc view */}
            <Icon2
              classIcon="icon2-menu"
              className="sandwich-menu has-action"
              onClick={toggleMenu}
            />
            {/* Menu */}
            <ul className="tree">
              {menu.map(({ permission = [], subMenu = [] }, index) => (
                <AuthElement key={index} orPermission={permission}>
                  <ul className="treeview-item">
                    {subMenu.map(
                      (
                        {
                          permission: subPermission = [],
                          notPermission,
                          nameMenu,
                          path,
                          links = [],
                          icon,
                          badgeType,
                          badgeKey,
                        },
                        subIndex
                      ) => (
                        <AuthElement key={subIndex} permission={subPermission}>
                          {size(notPermission) ? (
                            <div>
                              {!checkNotPermission(notPermission) && (
                                <Tooltip
                                  tooltipClassName="tooltip-sidebar"
                                  intent={Intent.PRIMARY}
                                  position={Position.RIGHT}
                                  isDisabled={isMenuShown}
                                  content={nameMenu}
                                >
                                  <li
                                    className={csx('item-menu', {
                                      active:
                                        [path, ...links].includes(match?.url) ||
                                        checkActive(
                                          [path, ...links],
                                          match?.url
                                        ),
                                    })}
                                  >
                                    <Link
                                      className={csx('link-url')}
                                      to={path}
                                      onClick={
                                        isMobileMenuShown
                                          ? closeMenuMobile
                                          : null
                                      }
                                    >
                                      <span
                                        className={csx('icon-sidebar', icon)}
                                      />
                                      <span className={csx('name-menu')}>
                                        {nameMenu}
                                      </span>

                                      <Badge
                                        className="cpc-badge-sidebar"
                                        count={get(counters, badgeKey)}
                                        type={badgeType || !isMenuShown}
                                      />
                                    </Link>
                                  </li>
                                </Tooltip>
                              )}
                            </div>
                          ) : (
                            <Tooltip
                              tooltipClassName="tooltip-sidebar"
                              intent={Intent.PRIMARY}
                              position={Position.RIGHT}
                              isDisabled={isMenuShown}
                              content={nameMenu}
                            >
                              <li
                                className={csx('item-menu', {
                                  active:
                                    [path, ...links].includes(match?.url) ||
                                    checkActive([path, ...links], match?.url),
                                })}
                              >
                                <Link
                                  className={csx('link-url')}
                                  to={path}
                                  onClick={
                                    isMobileMenuShown ? closeMenuMobile : null
                                  }
                                >
                                  <span className={csx('icon-sidebar', icon)} />
                                  <span className={csx('name-menu')}>
                                    {nameMenu}
                                  </span>

                                  <Badge
                                    className="cpc-badge-sidebar"
                                    count={get(counters, badgeKey)}
                                    type={badgeType || !isMenuShown}
                                  />
                                </Link>
                              </li>
                            </Tooltip>
                          )}
                        </AuthElement>
                      )
                    )}
                  </ul>
                </AuthElement>
              ))}
            </ul>
          </div>
        </Scrollbars>
      </div>
    )
  }
)

const mapStateToProps = state => ({
  counters: state?.counters,
  auth: get(state, 'auth'),
})

export default withRouter(connect(mapStateToProps)(Sidebar))
