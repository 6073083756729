import React, { memo } from 'react'
import { Tabs2 } from '@blueprintjs/core'
import csx from 'classnames'

const CustomTabs = ({
  children = null,
  className,
  contentBordered = false,
  scrollable = false,
  fixHeader = false,
  ...props
}) => {
  return (
    <Tabs2
      {...props}
      className={csx(
        'cpc-tabs',
        {
          'content-border': contentBordered,
          scrollable: scrollable,
          'fix-border-bottom': fixHeader,
        },
        className
      )}
    >
      {children}
    </Tabs2>
  )
}

export default memo(CustomTabs)
